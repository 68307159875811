'use strict';

angular.module('transport.configuration.transportStopAdd',
    [
        'transport.services.transportConfigurationStopsService',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService'
    ])
    .component('transportStopAdd',
    {
        bindings: {
            resolve: '<',
            modalInstance: '<'
        },
        templateUrl: '/Scripts/app/transport/configuration/stops/transport-stop-add.template.html',
        controller: [
            '$window',
            'transportConfigurationStopsService',
            'simpleAlertService',
            'moduleService',
            '$timeout',
            function transportStopAddController($window, transportConfigurationStopsService, simpleAlertService, moduleService, $timeout) {

                this.$window = $window;
                this.isSubmitted = false;
                this.stopsAdded = false;
                this.isGPStrackingEnabled = false;

                var resetSubmitButtons = function () {
                    $timeout(function () {
                        this.isSubmitted = false;
                    }.bind(this), 200);

                }.bind(this);

                this.transportStop = {};
                this.markerPins = [];
                this.mapHome = null;

                this.$onInit = function () {
                    this.transportStop = { name: "", active: false };
                    moduleService.getModules(this.$window.EveryBuddy.CurrentOrganisationId)
                        .then(function (responseData)
                        {
                            this.isGPStrackingEnabled = responseData.transportModule.isGPSTrackingEnabled;
                            if(responseData.transportModule.defaultLatitude) {
                                this.mapHome = {
                                    lat: responseData.transportModule.defaultLatitude,
                                    lng: responseData.transportModule.defaultLongitude,
                                    zoom: 15
                                }
                            }
                        }.bind(this)
                    );
                }.bind(this);

                this.cancel = function () {
                    if (this.stopsAdded) {
                        closeModal();
                    } else {
                        this.modalInstance.dismiss('cancel');
                    }
                };

                var closeModal = function () {
                    this.modalInstance.close();
                }.bind(this);

                var alertAndResetOkButton = function (message) {
                    simpleAlertService.errorAlert(message);
                    resetSubmitButtons();
                };

                this.addTransportStop = function (saveAndAddAnother)
                {
                    transportConfigurationStopsService.addStop(this.transportStop)
                        .then(function (responseData)
                        {
                            if (responseData && responseData.name)
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Saved',
                                    message: 'SB_Transport_Stop_Added',
                                    messageTranslationParameters: { transportStop: responseData.name },
                                    staticBackdrop: true
                                });

                                if (saveAndAddAnother)
                                {
                                    this.transportStop = {};
                                    this.stopsAdded = true;
                                    resetSubmitButtons();
                                }
                                else
                                {
                                    closeModal();
                                }
                            }
                            else
                            {
                                alertAndResetOkButton();
                            }
                        }.bind(this))
                        .catch(function (responseData) {

                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message) {
                                message = { message: responseData.data.Message };
                            }

                            alertAndResetOkButton(message);
                        });
                }.bind(this);

                this.updateMap = function() {
                    if(this.transportStop.latitude && this.transportStop.longitude) {
                        this.markerPins = [{
                            id: '0',
                            lat: this.transportStop.latitude,
                            lng: this.transportStop.longitude,
                            draggable: true,
                        }];
                    }
                }

                this.onPinDrop = function(e) {
                    this.transportStop.latitude = Math.round(e.lat * 1000000)/1000000;
                    this.transportStop.longitude = Math.round(e.lng * 1000000)/1000000;
                    this.updateMap();
                }

            }
        ]
    });
