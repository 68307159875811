"use strict";

angular.module('ccaavailableModule').component('ccaAvailableDashboard', {
    templateUrl: "/Scripts/app/ccaAvailable/Dashboard/cca-available-dashboard.template.html",
    controller: [
        '$scope',
        '$state',
        '$filter',
        '$translate',
        'blobStoragePhotosService',
        'organisationAvailableRepository',
        'availableRepository',
        'ccaActionsService',
        function ($scope, $state, $filter, $translate, blobStoragePhotosService, organisationAvailableRepository, availableRepository, ccaActionsService) {

            $scope.loadingError = false;

            $scope.locations = [];
            $scope.locations.push({ id: undefined, title:  'SB_All'});

            organisationAvailableRepository.get()
                .then(function (organisation) { $scope.organisation = organisation; });

            availableRepository.getLocations()
                .then(function(data)
                {
                    var newItems = [];
                    for (var key in data)
                    {
                        newItems.push({ id: key, title: data[key] });
                    }

                    newItems = $filter('orderBy')(newItems, 'title');

                    for (var i = 0; i < newItems.length; i++) {
                        $scope.locations.push(newItems[i]);
                    }

                    $scope.locationNames = data;
                });

            $scope.locationFilter = function(location)
            {
                if (location.id === undefined)
                    return false;

                if (!$scope.signUps)
                    return false; // don't omit anything as signups aren't loaded

                for (var i = $scope.signUps.length; i--;)
                {
                    if (parseInt($scope.signUps[i].displayLocationId) === parseInt(location.id))
                    {
                        return false;
                    }
                }

                return true;
            };

            availableRepository.getSignUpTypes()
                .then(function(data)
                {
                    $scope.signUpTypes = [];
                    $scope.signUpTypes.push({ id: undefined, title: 'SB_All' });
                    var newItems = [];
                    for (var key in data) { newItems.push({ id: key, title: data[key] }); }
                    newItems = $filter('orderBy')(newItems, 'title');
                    $scope.signUpTypes = $scope.signUpTypes.concat(newItems);
                });

            $translate.onReady().then(function () {

                availableRepository.get()
                .then(function(signUps)
                {

                    if (!signUps || signUps.length == 0) {
                        $scope.signUps = [];
                        $scope.loadingError = true;
                        return;
                    }
                    $scope.signUps = ccaActionsService.addActionsToSignUps(signUps);
                    blobStoragePhotosService.addStudentPhotoUrlsToNestedArrays($scope.signUps).then(function (array) {
                        $scope.signUps = array;
                    });
                    $scope.truncateDescriptions();
                    $scope.applyFilter();

                });

            });

            $scope.truncateDescriptions = function() {
                $scope.signUps.forEach(function (signUp, i) {
                    var maxLength = 150;
                    var descTrunc;
                    if (signUp.description.indexOf('</p>')) {
                        descTrunc = signUp.description.split('</p>')[0].replace('<p>','');
                    } else {
                        descTrunc = signUp.description;
                    }
                    if (descTrunc.length > maxLength) {
                        descTrunc = descTrunc.substring(0,maxLength) + '...';
                    }
                    $scope.signUps[i].description = descTrunc;
                });
            };

            $scope.searchFilters = {};

            $scope.applyFilter = function()
            {
                $scope.filteredSignUps = $filter('filter')($scope.signUps, $scope.searchFilters);
            };
            $scope.ccaDetails = function(signUp) {
                $state.go('ccaDetails', { signUpId: signUp.ccaSignUpId });
            };
            $scope.confirm = function(signUp) {
                $state.go('ccaReview', { signUpReviewId: signUp.ccaSignUpId });
            };

            $scope.requestToJoin = function(signUp) {
                $state.go('ccaToJoin', { signUpId: signUp.ccaSignUpId });
            };

            $scope.ccaSelection = function (signUp, pupilId)
            {
                if (signUp.isTimeSlot)
                {
                    $state.go('ccaSelectionParentsEvening', { signUpId: signUp.ccaSignUpId, pupilId: pupilId });
                }
                else
                {
                     $state.go('ccaSelection', { signUpId: signUp.ccaSignUpId, pupilId: pupilId });
                }

            };

            {   // Date pickers
                $scope.signUpsFrom = moment().add(-2, 'months').toDate();
                $scope.signUpsTo = moment().add(1, 'years').toDate();
                $scope.formats = ['dd-MMMM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'shortDate', 'dd-MMM-yyyy', 'dd MMM yyyy'];
                $scope.format = 'dd MMM yyyy';
                $scope.altInputFormats = ['M!/d!/yyyy'];
                $scope.openFrom = function () {
                    $scope.popupFrom.opened = true;
                };

                $scope.popupFrom = {
                    opened: false
                };

                $scope.openTo = function () {
                    $scope.popupTo.opened = true;
                };

                $scope.popupTo = {
                    opened: false
                };
            }

        }
    ]});


    angular.module('ccaavailableModule').filter('filterMultiple', [
        '$filter', function($filter) {
            return function(items, keyObj) {
                var filterObj = {
                    data: items,
                    filteredData: [],
                    applyFilter: function(obj, key) {
                        var fData = [];
                        if (this.filteredData.length === 0)
                            this.filteredData = this.data;
                        if (obj) {
                            var fObj = {};
                            if (!angular.isArray(obj)) {
                                fObj[key] = obj;
                                fData = fData.concat($filter('filter')(this.filteredData, fObj));
                            } else if (angular.isArray(obj)) {
                                if (obj.length > 0) {
                                    for (var i = 0; i < obj.length; i++) {
                                        if (angular.isDefined(obj[i])) {
                                            fObj[key] = obj[i];
                                            fData = fData.concat($filter('filter')(this.filteredData, fObj));
                                        }
                                    }
                                }
                            }
                            this.filteredData = fData;
                        }
                    }
                };
                if (keyObj) {
                    angular.forEach(keyObj, function(obj, key) {
                        filterObj.applyFilter(obj, key);
                    });
                }
                return filterObj.filteredData;
            };
        }
    ]);