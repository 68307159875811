<div class="page-loader" *ngIf="!hasLoaded">
    <div>
        <div *ngIf="title">
            <img class="page-loader__logo fade-in"
                 *ngIf="imgUrl"
                [src]="imgUrl"
                [alt]="title">
            <h3 *ngIf="!imgUrl"
                [innerHTML]="title"></h3>
        </div>
        <div class="mt-5 p-large">
            <sb-icon name="loading"
                     class="text-secondary"></sb-icon>
        </div>
    </div>
</div>