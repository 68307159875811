<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <sb-modal-header *ngIf="message?.title"
                             [modalTitle]="message.title"
                             (onClose)="message.cancel('close')"
                             [headerClass]="message.isDanger ? 'danger' : null"></sb-modal-header>
            <div *ngIf="message?.type === 'confirm'"
                 class="modal-body">
                <div [innerHTML]="message.text | translate: message.textTranslationParameters"></div>
                <sb-check-box *ngIf="message.checkBoxText"
                            [(model)]="checkBoxValue"
                            [label]="message.checkBoxText"></sb-check-box>
                <div *ngIf="message.warningText" class="mt-4 alert alert-warning">{{message.warningText | translate }}
                </div>
            </div>
            <div class="modal-footer">
                <sb-button [buttonClass]="message.isDanger ? 'danger' : 'success'"
                           (onClick)="message.confirm(checkBoxValue)"
                           [message]="message.okButtonText || 'SB_OK'"></sb-button>
                <sb-button buttonClass="warning"
                           (onClick)="message.cancel('cancel')"
                           [message]="message.cancelButtonText || 'SB_Cancel'"></sb-button>
            </div>
        </div>
    </div>
</div>
