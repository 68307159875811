'use strict';

angular.module('reception.components.receptionConsentEventInvites',
    [
        'reception.components.receptionConsentEventInvites',
    ]).component('receptionConsentEventInvites', {
        templateUrl: '/Scripts/app/reception/components/reception-consent-event-invites.template.html',
        bindings: {
            eventId: '<',
        },
        controller: [
            function receptionConsentEventInvitesController()
            {
            }
        ]
    });