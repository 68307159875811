angular.module('insurance.components.insuranceEditor',
        [
            'ui.tinymce',
            'pascalprecht.translate',

            'shared.directives.sbRadioButton',
            'shared.directives.sbCurrencyInput',
            'shared.components.sbDatePicker',
            'shared.components.sbIcon',
            'shared.components.sbTermPicker',
            'shared.services.saveChangesPromptService',
            'shared.services.simpleAlertService',
            'shared.services.tinymceConfigHelper',

            'insurance.services.insuranceService',
            'corporateContacts.components.corporateContactPickerField',
            'financialProducts.components.feeSummary'
        ])
    .component('insuranceEditor',
        {
            bindings: {
                teamId: '<',
                insuranceId: '<',
                insuranceData: '<',
                onChange: '&',
                onDelete: '&'
            },
            controller: [
                '$window',
                '$transitions',
                'insuranceService',
                'tinymceConfigHelper',
                'simpleAlertService',
                'saveChangesPromptService',
                function (
                    $window,
                    $transitions,
                    insuranceService,
                    tinymceConfigHelper,
                    simpleAlertService,
                    saveChangesPromptService)
                {
                    var loading = 0;
                    this.insurance = null;

                    this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({
                        fonts: false,
                        justify: false,
                        tables: false,
                        hyperlinks: false
                    });
                    this.currencySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                    this.insuranceTypes = [];
                    this.insuranceStatuses = [];

                    this.hasUnsavedChanges = { flag: false };

                    var cleanInsurance = 'null';

                    this.isLoading = function()
                    {
                        return loading > 0;
                    };

                    saveChangesPromptService.setup(
                        $transitions,
                        this.hasUnsavedChanges,
                        'SB_leave_without_saving_changes',
                        ''); // not using the timeout so don't bother setting this

                    this.$doCheck = function () {
                        // has anything actually changed from the db reference copy?
                        var currentInsuranceJson = saveChangesPromptService.toJsonForComparison(this.insurance);

                        if (currentInsuranceJson !== cleanInsurance) {
                            console.log('insurance.components.insuranceEditor/$doCheck/changes found');
                            this.hasUnsavedChanges.flag = true;
                        }
                        else {
                            console.log('insurance.components.insuranceEditor/$doCheck/no changes');
                            this.hasUnsavedChanges.flag = false;
                        }
                    };

                    this.$onDestroy = function () {
                        saveChangesPromptService.reset();
                    };

                    this.loading++;  // we're loading until insuranceData is set, or an id is set
                    this.$onChanges = function(changes)
                    {
                        if (changes.insuranceData && changes.insuranceData.currentValue) {
                            // the data has already been loaded
                            saveChangesPromptService.reset();

                            this.insurance = changes.insuranceData.currentValue;
                            cleanInsurance = saveChangesPromptService.toJsonForComparison(this.insurance);
                            this.loading--;
                        }
                        else if (changes.insuranceId && changes.insuranceId.currentValue) {
                            // we need to load the data
                            insuranceService.getInsuranceDetails(changes.teamId.currentValue,
                                    changes.insuranceId.currentValue)
                                .then(function(data)
                                {
                                    saveChangesPromptService.reset();

                                    if (!data.isError)
                                    {
                                        this.insurance = data;

                                        cleanInsurance = saveChangesPromptService.toJsonForComparison(this.insurance);
                                    }
                                    this.loading--;
                                }.bind(this));
                        }
                    };

                    this.loading++;
                    insuranceService.getInsuranceLookups()
                        .then(function(data)
                        {
                            this.insuranceTypes = data.insuranceTypes;
                            this.insuranceStatuses = data.insuranceStatuses;
                            this.loading--;
                        }.bind(this));

                    this.save = function()
                    {
                        this.loading++;
                        insuranceService.saveInsuranceDetails(this.insurance)
                            .then(function(data) {
                                if (!data.isError) {
                                    if (this.onChange && typeof (this.onChange()) === 'function') {
                                        this.onChange()(this.insurance, data.id);
                                    }

                                    this.insurance.id = data.id;
                                    this.insurance.dts = data.dts;

                                    cleanInsurance = saveChangesPromptService.toJsonForComparison(this.insurance);
                                    this.hasUnsavedChanges.flag = false;

                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Saved',
                                        message: 'SB_Successfully_Saved'
                                    });
                                } else {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Error_Saving',
                                        message: 'SB_Error_Saving_Message'
                                    });
                                }
                                this.loading--;
                            }.bind(this));
                    }.bind(this);

                    this.delete = function()
                    {
                        if (this.insurance.id === 0)
                        {
                            this.insurance.isDeleted = new Date();

                            if (this.onDelete && typeof (this.onDelete()) === 'function') {
                                this.onDelete()(this.insurance);
                            }
                            return;
                        }

                        var popupInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Delete',
                            message: 'Are you sure you wish to delete this?',
                            okButtonText: 'SB_Yes',
                            cancelButtonText: 'SB_No'
                        });

                        popupInstance.result
                            .then(function()
                            {
                                this.loading++;
                                insuranceService.deleteInsuranceDetails(this.insurance.id)
                                    .then(function (data) {
                                        if (!data.isError) {
                                            this.insurance.isDeleted = new Date();

                                            if (this.onDelete && typeof this.onDelete() === 'function') {
                                                this.onDelete()(this.insurance);
                                            }

                                            cleanInsurance = saveChangesPromptService.toJsonForComparison(this.insurance);
                                            this.hasUnsavedChanges.flag = false;
                                        }
                                        this.loading--;
                                    }.bind(this));
                            }.bind(this))
                            .catch(function() {});

                    }.bind(this);

                    this.termOfCoverUpdated = function(newValue)
                    {
                        this.insurance.termOfCover = newValue;
                    }.bind(this);
                }],
            templateUrl: '/Scripts/app/insurance/components/insurance-editor.template.html'
        });
