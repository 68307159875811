angular.module('settingsModule')
    .component('attendanceScanningSettings',
        {
            templateUrl: '/Scripts/app/settings/components/onboarding/onboarding-attendance-scanning-settings.template.html',
            controller: class AttendanceScanningSettings {
                $window: any;
                attendanceScanningSettingsService: any;
                attendanceScanningEnabledStatusEnum: any;
                attendanceScanningAccessLevelEnum: any;
                attendanceScanningAccessScanningTypeEnum: any;

                roleTypes: any;

                isLoading: boolean;

                settings: any;

                scanningDisabled: boolean;

                busMonitorAllDisabled: boolean;
                coachAllDisabled: boolean;

                enabledOptions: any[] = [
                    {
                        label: 'SB_Attendance_Scanning_Off',
                        value: 0
                    },
                    {
                        label: 'SB_Attendance_Scanning_On_All',
                        value: 1
                    },
                    {
                        label: 'SB_Attendance_Scanning_On_Transport',
                        value: 2
                    }
                ];

                scanningTypeOptions: any[] = [
                    {
                        label: 'SB_Attendance_Scanning_QR_Code',
                        value: 0
                    },
                    {
                        label: 'SB_Attendance_Scanning_RFID',
                        value: 1
                    }
                ];

                static $inject = ['$window', 'attendanceScanningSettingsService', 'attendanceScanningEnabledStatusEnum', 'attendanceScanningAccessLevelEnum', 'attendanceScanningAccessScanningTypeEnum'];

                constructor($window, attendanceScanningSettingsService, attendanceScanningEnabledStatusEnum, attendanceScanningAccessLevelEnum, attendanceScanningAccessScanningTypeEnum) {
                    this.$window = $window;
                    this.attendanceScanningSettingsService = attendanceScanningSettingsService;
                    this.attendanceScanningEnabledStatusEnum = attendanceScanningEnabledStatusEnum;
                    this.attendanceScanningAccessLevelEnum = attendanceScanningAccessLevelEnum;
                    this.attendanceScanningAccessScanningTypeEnum = attendanceScanningAccessScanningTypeEnum;
                    this.roleTypes = $window.EveryBuddy.Enums.RoleTypes;
                }

                $onInit() {
                    this.isLoading = true;
                    this.attendanceScanningSettingsService.getSettings().then(res => {
                        this.settings = res.data;
                        this.onEnabledStatusClick({ value: res.data.enabledStatusId });
                        this.isLoading = false;
                    });
                }

                getRoleAccessChecked(roleId, accessLevelId): boolean
                {
                    if (!this.settings) return false;

                    const roleAccess = this.settings.roleAccess
                        .find(role => role.roleId === roleId && role.accessLevelId === accessLevelId);

                    return !!roleAccess;
                }

                setRoleAccess(roleId, accessLevelId)
                {
                    const roleAccess = this.settings.roleAccess
                        .find(role => role.roleId === roleId);

                    if (roleAccess) {
                        roleAccess.accessLevelId = accessLevelId;
                    }
                }

                updateSettings()
                {
                    this.attendanceScanningSettingsService
                        .updateSettings(this.settings)
                        .finally(() => this.isLoading = false);
                }

                onEnabledStatusClick(option)
                {
                    // TODO: Add enums for enabled status and role type.
                    if (option.value === this.attendanceScanningEnabledStatusEnum.Disabled) {
                        this.scanningDisabled = true;
                        this.settings.scanningTypeId = this.attendanceScanningAccessScanningTypeEnum.QRCode;
                        this.settings.roleAccess.forEach(role => role.accessLevelId = this.attendanceScanningAccessLevelEnum.None);
                    } else {
                        this.scanningDisabled = false;
                    }

                    if (option.value === this.attendanceScanningEnabledStatusEnum.EnabledTransportEvents) {
                        const busMonitorRole = this.settings.roleAccess
                            .find(role => role.roleId === this.$window.EveryBuddy.Enums.RoleTypes.TransportBusMonitor);
                        const coachRole = this.settings.roleAccess
                            .find(role => role.roleId === this.$window.EveryBuddy.Enums.RoleTypes.ExternalCoachAccess);

                        if (busMonitorRole?.accessLevelId > this.attendanceScanningAccessLevelEnum.TransportOnly) {
                            busMonitorRole.accessLevelId = this.attendanceScanningAccessLevelEnum.TransportOnly;
                        }

                        if (coachRole) {
                            coachRole.accessLevelId = this.attendanceScanningAccessLevelEnum.None;
                        }

                        this.busMonitorAllDisabled = true;
                        this.coachAllDisabled = true;
                    } else {
                        this.busMonitorAllDisabled = false;
                        this.coachAllDisabled = false;
                    }
                }
        }
    });
