angular.module('subjectClass.services.subjectClassService', [])
    .factory('subjectClassService', [
        '$http', '$cacheFactory',
        function ($http, $cacheFactory) {

            var urlBase = '/webapi/WebSubjectClass/';

            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('subjectClassServiceCache');

            repository.getAllForLookupCached = function (includeOnlineMeetingId) {
                return this.getAllForLookup(includeOnlineMeetingId, true);
            };

            repository.getAllForLookup = function (includeOnlineMeetingId = false, useCache = false) {
                var config = { params: { includeOnlineMeetingId: includeOnlineMeetingId } };
                if (useCache) {
                    config.cache = repoCache;
                }

                return $http.get(urlBase + 'GetAllForSimpleLookup', config)
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getPeopleInSubjectClass = function (subjectClassId) {

                return $http.get(urlBase + 'GetPeopleInSubjectClass', { params: { subjectClassId: subjectClassId }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.subjectsEnabledForOrganisation = function () {
                return $http.post(urlBase + 'SubjectsAvailable', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            }

            return repository;
        }
    ]);
