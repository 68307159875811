import { HttpWebApiService } from './http-web-api.service';
import { Injectable } from '@angular/core';
import { apis, controllerTypes } from '@sb-shared/models/request-settings';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {

  constructor(private http: HttpWebApiService){}

  getTwitterHandles() {
    return this.http.get('organisation/Twitter', {
            controllerType: controllerTypes.User,
            api: apis.Core
    });
  }

  getTwitterUrl(handle: string) {
      return `https://www.twitter.com/${handle}`;
  }
}
