angular.module('sysadmin.services.organisationLabelService', [])
    .service('organisationLabelService', class OrganisationLabelService {

        $http: any;
        httpCoreApi: any;
        settings: any;
        baseUrlSlug: any = 'OrganisationLabel';

        static $inject = ['$http', 'httpCoreApi'];

        constructor($http, httpCoreApi) {
            this.$http = $http;
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.SysAdmin
            }
        }

        regenerateOrgLabels(organisationId: number) {
            return this.httpCoreApi.put(`${this.baseUrlSlug}/RegenerateOrgLabels`, {}, {
                ...this.settings,
                urlParams: {
                    organisationId: organisationId
                }
            });
        }
    })
