'use strict';

angular.module('balanceAdmin.services.balanceAdminService', [])
    .factory('balanceAdminService', [
        '$http',
        function ($http) {

            var urlApiBase = '/webapi/WebBalanceAdministration/';

            var repo = {};

            repo.getAllBalances = function () {

                return $http.get(urlApiBase + 'GetAllBalances')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repo.getAccounts = function (accountTypeId, pageSize, currentPage, orderBy, orderDescending, search, includeLeavers, includeCurrent) {
                return $http.get(urlApiBase + 'GetAccountSummaries',
                    {
                        
                        params: {
                            accountType: accountTypeId,
                            pageSize: pageSize,
                            currentPage: currentPage,
                            orderBy: orderBy,
                            orderDescending: orderDescending || false,
                            search: search || '',
                            includeLeavers: includeLeavers,
                            includeCurrent: includeCurrent
                        }
                    })
                    .then(function (data) {
                        return data.data;
                    });
            };

            repo.getTransactions = function (accountTypeId, accountOwnerId, includeSource, from, to) {

                var params = {
                    accountType: accountTypeId,
                    accountOwnerId: accountOwnerId,
                    includeSource: includeSource
                };

                if (from)
                    params.from = from;

                if (to)
                    params.to = to;

                return $http.get(urlApiBase + 'GetTransactionsForAccount',
                    {
                        params: params
                    })
                    .then(function (data) {
                        return data.data;
                    });
            };

            repo.getChargesForAccount = function (accountOwnerId, transactionsFrom, transactionsTo) {
                var params = {
                    accountOwnerId: accountOwnerId,
                    transactionsFrom: transactionsFrom,
                    transactionsTo: transactionsTo
                };

                return $http.get(urlApiBase + 'GetChargeTransactionsForAccount',
                    {
                        params: params
                    })
                    .then(function (data) {
                        return data.data;
                    });
            }

            repo.getChargesForEvent = function (eventId) {
                var params = {
                    eventId: eventId
                };

                return $http.get(urlApiBase + 'GetChargeTransactionsForEvent',
                    {
                        params: params
                    })
                    .then(function (data) {
                        return data.data;
                    });
            }

            repo.raiseCharge = function (transaction) {
                return $http.post(urlApiBase + 'RaiseChargeForAccount', transaction)
                    .then(function (data) {
                        return data.data;
                    });
            };

            repo.raiseCredit = function (transaction) {
                return $http.post(urlApiBase + 'RaiseCreditForAccount', transaction)
                    .then(function (data) {
                        return data.data;
                    });
            };

            repo.refundTransaction = function (refund) {
                return $http.post(urlApiBase + 'RefundTransaction', refund)
                    .then(function (data) {
                        return data.data;
                    });
            };

            repo.isAdvancedFeeAdmin = function () {
                return $http.get(urlApiBase + 'IsAdvancedFeeAdmin')
                    .then(function (data) {
                        return data.data;
                    });
            };

            repo.getBalanceForAccountType = function (accountOwnerId, accountType) {
                return $http.get(urlApiBase + 'GetBalanceForSpecificAccount',
                    {
                        params:
                        {
                            accountOwnerId: accountOwnerId,
                            accountType: accountType
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repo.getGroupSummariesAsync = function (from, to, accountOwnerPersonId) {
                return $http
                    .get(urlApiBase + 'GetGroupSummariesAsync', {
                        params:
                        {
                            from: from,
                            to: to,
                            accountOwnerPersonId: accountOwnerPersonId > 0 ? accountOwnerPersonId : null
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getPupilSummariesAsync = function (from, to) {
                return $http
                    .get(urlApiBase + 'GetPupilSummariesAsync', {
                        params:
                        {
                            from: from,
                            to: to
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getGroupFeeSummariesAsync = function (from, to, accountOwnerPersonId) {
                return $http
                    .get(urlApiBase + 'GetGroupFeeSummariesAsync', {
                        params:
                        {
                            from: from,
                            to: to,
                            accountOwnerPersonId: accountOwnerPersonId > 0 ? accountOwnerPersonId : null
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getEventSummariesForGroupAsync = function (groupId, isTeam, from, to, accountOwnerPersonId) {
                return $http
                    .get(urlApiBase + 'GetEventSummariesForGroupAsync', {
                        params:
                        {
                            groupId: groupId,
                            isTeam: isTeam,
                            from: from,
                            to: to,
                            accountOwnerPersonId: accountOwnerPersonId > 0 ? accountOwnerPersonId : null
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getEventAttendeeSummariesForEventAsync = function (calendarEventId) {
                return $http
                    .get(urlApiBase + 'GetEventAttendeeSummariesForEventAsync', {
                        params:
                        {
                            calendarEventId: calendarEventId
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getTransactionsForPersonForSpecificEventAsync = function (accountOwnerId, calendarEventId) {

                var arrangeTransactionsForDisplay = function (transactions) {
                    var charges = [],
                        i = 0;

                    for (i = transactions.length; i--;) {

                        // only interested in charges in this outer loop,
                        // skip anything related to a charge.
                        if (transactions[i].relatedTransactionId) {
                            continue;
                        }

                        var thisCharge = transactions[i];
                        thisCharge.credits = [];

                        var amountAvailableToRefund = thisCharge.debitAmount;

                        var totalCredit = 0;
                        var totalDebit = 0;
                        var totalAdjustment = 0;
                        var totalTransfer = 0;

                        // now populate all the related payments for each of the charges
                        for (var j = transactions.length; j--;) {
                            var thisCredit = transactions[j];

                            if (thisCredit.transactionId === thisCharge.transactionId || (thisCredit.relatedTransactionId && thisCredit.relatedTransactionId === thisCharge.transactionId)) {

                                var transactionCopy = JSON.parse(JSON.stringify(thisCredit));

                                // sort the columns aaaat
                                switch (transactionCopy.paymentMethodId) {
                                    case 23: // refund
                                        amountAvailableToRefund -= thisCredit.creditAmount;
                                        transactionCopy.adjustmentAmount = transactionCopy.creditAmount;
                                        transactionCopy.transferAmount = 0;
                                        transactionCopy.creditAmount = 0;
                                        break;
                                    case 24: // internal transfer
                                        transactionCopy.transferAmount = transactionCopy.debitAmount;
                                        transactionCopy.adjustmentAmount = 0;
                                        transactionCopy.debitAmount = 0;
                                        break;
                                    case 31: // legacy refund
                                        transactionCopy.adjustmentAmount = 0;
                                        transactionCopy.transferAmount = transactionCopy.debitAmount;
                                        transactionCopy.creditAmount = 0;
                                        transactionCopy.debitAmount = 0;
                                        break;
                                    default:
                                        transactionCopy.adjustmentAmount = 0;
                                        transactionCopy.transferAmount = 0;
                                        break;
                                }

                                thisCharge.credits.push(transactionCopy);
                                totalCredit += transactionCopy.creditAmount;
                                totalDebit += transactionCopy.debitAmount;
                                totalAdjustment += transactionCopy.adjustmentAmount;
                                totalTransfer += transactionCopy.transferAmount;
                            }
                        }

                        thisCharge.creditAmount = totalCredit;
                        thisCharge.debitAmount = totalDebit;
                        thisCharge.adjustmentAmount = totalAdjustment;
                        thisCharge.transferAmount = totalTransfer;
                        thisCharge.amountAvailableToRefund = amountAvailableToRefund.toFixed(2);
                        thisCharge.balance = (totalCredit - totalTransfer) - (totalDebit - totalAdjustment);

                        charges.push(thisCharge);
                    }

                    return charges;
                }

                return $http
                    .get(urlApiBase + 'GetTransactionsForPersonForSpecificEventAsync', {
                        params:
                        {
                            accountOwnerPersonId: accountOwnerId,
                            calendarEventId: calendarEventId
                        }
                    })
                    .then(function (response) {
                        return arrangeTransactionsForDisplay(response.data);
                    });
            };

            repo.sendPaymentChase = function (calendarEventId, message) {
                var request = {
                    calendarEventId: calendarEventId,
                    message: message
                };

                return $http
                    .post(urlApiBase + 'SendPaymentChase', request)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.recordManualTransactionByEvent = function (manualTransactionByEventRequest) {

                return $http
                    .post(urlApiBase + 'RecordManualTransactionByEvent', manualTransactionByEventRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.recordManualTransactionByPupil = function (manualTransactionByPupilRequest) {

                return $http
                    .post(urlApiBase + 'RecordManualTransactionByPupil', manualTransactionByPupilRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getManualTransactionReceipt = function (personEventFeeSummaries) {

                return $http
                    .post(urlApiBase + 'GetManualTransactionReceipt', personEventFeeSummaries)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.refundTransaction = function (transactionId, amountToRefund, description, refundCreditAction, creditTypeId) {

                var refundRequest = {
                    transactionId: transactionId,
                    amountToRefund: amountToRefund,
                    description: description,
                    refundCreditAction: refundCreditAction,
                    creditTypeId: creditTypeId
                };

                return $http
                    .post(urlApiBase + 'RefundTransaction', refundRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.sendFeesAllocatedEmailToParentOfPupil = function (pupilId, calendarEventId, transactionId) {

                var params = { pupilId: pupilId, calendarEventId: calendarEventId, transactionId: transactionId };

                return $http
                    .post(urlApiBase + 'SendFeesAllocatedEmailToParentOfPupil', params)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.massAdjustment = function (adjustments, description, refundCreditAction, creditTypeId) {

                var massAdjustRequest = {
                    adjustments: adjustments,
                    description: description,
                    refundCreditAction: refundCreditAction,
                    creditTypeId: creditTypeId
                };

                return $http
                    .post(urlApiBase + 'MassAdjustment', massAdjustRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.quickCharge = function (quickChargeRequest) {
                return $http
                    .post(urlApiBase + 'CreateFeeEventWithCharges', quickChargeRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.isParentBalanceAvailable = function (pupilId, ccaSignUpId) {
                return $http.get(urlApiBase + 'IsParentBalanceAvailable',
                    {
                        params:
                        {
                            pupilId: pupilId,
                            ccaSignUpId: ccaSignUpId
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getChargeableParents = function (pupilId, ccaSignUpId) {

                return $http.get(urlApiBase + 'GetChargeableParents',
                    {
                        params: {
                            pupilId: pupilId,
                            ccaSignUpId: ccaSignUpId
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repo.getVisiblePaymentMethods = function () {

                return $http.get(urlApiBase + 'GetVisiblePaymentMethods')
                    .then(function (response) {
                        return response.data;
                    });
            };

            return repo;

        }
    ]);