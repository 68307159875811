'use strict';

angular.module('settingsModule')
    .component('onlineParentMeetings',
        {
            templateUrl: '/Scripts/app/settings/components/onboarding/onboarding-online-parents-meeting.template.html',
            bindings: {
                close: '&',
                dismiss: '&'
            },
            controller: class OnlineParentMeetings {

                onlineMeetingService: any;
                isLoading: boolean;
                onlineData: any;
                selectedValue: number;

                static $inject = ['onlineMeetingService'];

                constructor(onlineMeetingService) {
                    this.onlineMeetingService = onlineMeetingService;
                }

                $onInit() {
                    this.isLoading = true;
                    this.onlineMeetingService.getOrganisationDefaultOnlinePTCOptions().then(onlineData => {
                        this.onlineData = onlineData.data;
                        this.isLoading = false;
                    });
                }

                updateOnlineMeetingOptions(option) {
                    this.onlineData.filter(function (val) {
                        val.isDefault = true;
                        return val !== option;
                    })
                    .forEach(function (val) {
                        val.isDefault = false;
                    });
                }

                saveOnlineMeetingOptions() {
                    this.isLoading = true;

                    var selectedOption = this.onlineData.filter(option => option.isDefault === true);
                    if (selectedOption.length != 1) {
                        this.isLoading = false;
                        return;
                    }

                    this.onlineMeetingService.updateOrganisationDefaultOnlinePTCOption(selectedOption[0]).then(onlineData =>
                    {
                        this.isLoading = false;
                    });
                }
        }
    });
