'use strict';

angular.module('team.components.ccaTeamBulkCreatorFilter',
    [
        'shared.directives.sbCheckBox',
        'angularjs-dropdown-multiselect',
    ])
    .component('ccaTeamBulkCreatorFilter',
        {
            bindings: {
                subjectNames: '<',
                yearNames: '<',
                teacherNames: '<',
                searchFilters: '<',
                resetForm: '&'
            },
            templateUrl: '/Scripts/app/teams/components/cca-team-bulk-creator-filter.template.html',
            controller:
                [
                    '$filter',
                    '$translate',
                    function ($filter, $translate) {
                        this.$onChanges = function (changes) {
                        }

                        this.dropdownTranslations = {
                            checkAll: $filter('translate')('SB_Check_All'),
                            uncheckAll: $filter('translate')('SB_UnCheck_All'),
                            searchPlaceholder: $filter('translate')('SB_Search'),
                            buttonDefaultText: $filter('translate')('SB_Select')
                        };

                        this.dropdownEventEdit = {
                            onSelectionChanged: function () {
                                this.resetForm();
                            }.bind(this)
                        }

                        this.teacherDropdownSettings = {
                            checkBoxes: true,
                            enableSearch: true,
                            scrollable: true,
                            idProp: "teacherName",
                            displayProp: "teacherName",
                            smartButtonMaxItems: 3
                        };

                        this.groupDropdownSettings = {
                            checkBoxes: true,
                            enableSearch: true,
                            scrollable: true,
                            idProp: "subjectName",
                            displayProp: "subjectName",
                            smartButtonMaxItems : 3
                        };

                        this.yearDropdownSettings = {
                            checkBoxes: true,
                            enableSearch: true,
                            scrollable: true,
                            idProp: "schoolYearGroupName",
                            displayProp: "schoolYearGroupName",
                            smartButtonMaxItems: 3
                        };
                    }
                ]
        });