import { Component, Input, SimpleChanges } from '@angular/core';
import { MomentOrDate, momentToDate } from '@sb-helpers';
import { DateFormats } from '@sb-shared/globals/date-formats';

@Component({
  selector: 'sb-stacked-date',
  templateUrl: './stacked-date.component.html',
  styleUrls: ['./stacked-date.component.scss']
})
export class StackedDateComponent {

  @Input() date: MomentOrDate;
  @Input() includeTime: boolean;
  dateFormatted: Date;
  DateFormatTime = DateFormats.Time;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.date?.currentValue) {
      this.dateFormatted = momentToDate(changes.date.currentValue);
    }
  }
}
