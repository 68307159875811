angular
    .module('cca.services.ccaService', [
        'cca.constants',
        'events.constants',
    ])
    .service('ccaService', class CcaService {

        $http: any;
        eventCategorySystemUsageTypeEnum: any;
        bookingStatusEnum: any;

        static $inject = ['$http', 'eventCategorySystemUsageTypeEnum', 'bookingStatusEnum'];

        constructor($http, eventCategorySystemUsageTypeEnum, bookingStatusEnum)
        {
            this.$http = $http;
            this.eventCategorySystemUsageTypeEnum = eventCategorySystemUsageTypeEnum;
            this.bookingStatusEnum = bookingStatusEnum;
        }

        getDuplicateOganisers(signUpGroups) {
            const organiserIds = [];
            const duplicateOrganisers = [];
            for (let i in signUpGroups) {
                const organiserId = signUpGroups[i].organiserId;
                if (organiserIds.indexOf(organiserId) == -1) {
                    organiserIds.push(organiserId);
                    continue;
                }

                // this is a duplicate, is it their first?
                const organiserName = signUpGroups[i].organiserName;
                if (duplicateOrganisers.indexOf(organiserName) == -1) {
                    duplicateOrganisers.push(signUpGroups[i].organiserName);
                }
            }

            return duplicateOrganisers;
        };

        getGroupAvailabilitySummary(group) {
            const conditions: any[] = [];
            const requirementGroupIds = [];
            let finalString = '';

            const loopThroughCollection = collection => {
                for (let i = 0; i < collection.length; i++) {
                    const thisGroup = collection[i];

                    if (!conditions[thisGroup.requirementGroupId] || !(conditions[thisGroup.requirementGroupId] instanceof Array))
                        conditions[thisGroup.requirementGroupId] = [];

                    conditions[thisGroup.requirementGroupId].push(thisGroup.name);

                    if (requirementGroupIds.indexOf(+thisGroup.requirementGroupId) === -1) {
                        requirementGroupIds.push(+thisGroup.requirementGroupId);
                    }
                }
            };

            loopThroughCollection(group.ccaTeamClubs);
            loopThroughCollection(group.ccaTeamTeams);
            loopThroughCollection(group.ccaTeamSubjectClasses);

            requirementGroupIds.sort((a, b) => { return +b < +a ? 1 : 0 });

            let index = undefined;
            let requirementGroupId = undefined;
            let conditionsArray = undefined;
            let arrLength = undefined;
            let i = 0;

            // loop through all the ANDs
            for (index in requirementGroupIds) {
                requirementGroupId = requirementGroupIds[index];

                if (+requirementGroupId < 0)
                    continue;

                if (finalString.length > 0)
                    finalString += '<br />AND ';

                conditionsArray = conditions[+requirementGroupId];

                arrLength = conditionsArray.length;

                finalString += 'Must be in; ';
                for (i = 0; i < arrLength; i++) {
                    finalString += conditionsArray[i];

                    if (i + 1 < conditionsArray.length) finalString += ' or ';
                }
            }

            // now loop through all the NOTs
            for (index in requirementGroupIds) {
                requirementGroupId = requirementGroupIds[index];

                if (+requirementGroupId >= 0)
                    continue;

                if (finalString.length > 0)
                    finalString += '<br />AND ';

                conditionsArray = conditions[requirementGroupId];

                arrLength = conditionsArray.length;
                finalString += 'Must NOT be in; ';
                for (i = 0; i < arrLength; i++) {
                    finalString += conditionsArray[i];

                    if (i + 1 < conditionsArray.length) finalString += ' or ';
                }
            }

            return finalString;
        };

        checkOverlap(event, pupil, otherEvent, onlyClash: boolean) {

            // Ignore any block bookings which aren't individual sessions
            if (event.remainingSessionEvents || otherEvent.remainingSessionEvents) {
                return false;
            }

            // Ignore fee only events
            if (event.systemUsageTypeId === this.eventCategorySystemUsageTypeEnum.Fee || otherEvent.systemUsageTypeId === this.eventCategorySystemUsageTypeEnum.Fee) {
                return false;
            }

            // Return if same event and checking for clashes only
            if (onlyClash && ((event.calendarEventId || event.parentCalendarEventId || event.id) === (otherEvent.calendarEventId || otherEvent.parentCalendarEventId || otherEvent.id)))
            {
                return false;
            }

            // Check pupil is matched, with method for this depending on new/existing event
            const pupilMatch = (otherEvent.pupils !== undefined && otherEvent.pupils.some(function(otherSignUpPupil) {
                return otherSignUpPupil.personId === pupil.personId && otherSignUpPupil.selectionCount === 1 && !otherSignUpPupil.isDeleted;
            })) || (otherEvent.personId === pupil.personId);
            if (!pupilMatch) {
                return false;
            }

            // Check for overlap
            const hours = onlyClash ? 0 : 2;
            const otherStartCutoff = moment(otherEvent.eventDate || otherEvent.from).add(-hours, 'hours');
            const otherEndCutoff = moment(otherEvent.eventEndDate || otherEvent.to).add(hours, 'hours');

            const from = moment(event.from);
            const to = moment(event.to);
            const overlap = from.isBefore(otherEndCutoff) && otherStartCutoff.isBefore(to);
            return overlap;
        };

        applySelectionToBlockEvents(events) {
            return events.map(event => {
                if (event.remainingSessionEvents) {
                    event.remainingSessionEvents = event.remainingSessionEvents.map(session => {
                        return {...session, pupils: event.pupils}
                    })
                }
                return event;
            });
        }

        getBookingExpiryInfo(availableEvents)
        {
            let oldestBookingExpiryUtc = null;

            let bookingExpiryInfo =
            {
                oldestBookingExpiry: null,
                timeRemaining: null
            };

            // NB. Pupil ExpiryTimestamp is stored in the DB in UTC. We're only interested in bookings
            // that are not timed-out, removed, cancelled or deleted.
            if (Array.isArray(availableEvents))
            {
                availableEvents.forEach((signUpEvent) =>
                {
                    signUpEvent.pupils.forEach((pupil) =>
                    {
                        if (pupil.expiryTimestampUtc != null &&
                            pupil.status != this.bookingStatusEnum.Confirmed &&
                            pupil.status != this.bookingStatusEnum.Removed &&
                            pupil.status != this.bookingStatusEnum.TimedOut &&
                            pupil.status != this.bookingStatusEnum.Cancelled &&
                            pupil.status != this.bookingStatusEnum.Deleted)
                        {
                            if (oldestBookingExpiryUtc == null)
                            {
                                oldestBookingExpiryUtc = pupil.expiryTimestampUtc;
                            }
                            else if (moment(pupil.expiryTimestampUtc).isBefore(moment(oldestBookingExpiryUtc)))
                            {
                                oldestBookingExpiryUtc = pupil.expiryTimestampUtc;
                            }
                        }
                    });
                });
            }

            if (oldestBookingExpiryUtc != null)
            {
                let nowUtc = moment.utc();
                let expiry = moment.utc(oldestBookingExpiryUtc);

                // We indicate to the user that they have 5 minutes less than they actually do.
                let diffInSeconds = expiry.diff(nowUtc, 'seconds') - 300;

                // Alex set as any to
                let timeRemaining = moment.utc() as any;
                timeRemaining = moment(timeRemaining).set('minute', 0);
                timeRemaining = moment(timeRemaining).set('second', 0);

                if (diffInSeconds > 0)
                {
                    timeRemaining = moment(timeRemaining).add('seconds', diffInSeconds).toDate();
                }

                bookingExpiryInfo.oldestBookingExpiry = oldestBookingExpiryUtc;
                bookingExpiryInfo.timeRemaining = timeRemaining;
            }

            return bookingExpiryInfo;
        };

        timeOutSelectedStudentBookings(timeOutBookingsRequest)
        {
            return this.$http.put('/CcaAvailable/TimeOutSelectedStudentBookings', timeOutBookingsRequest)
                .then(response =>
                {
                    return response.data;
                });
        };

        // strips down the bookings dto for network performance (and to avoid json serialisation exception on server-side).
        simplifyBookingsDto(dto) {
            if (!dto) {
                return dto;
            }

            return dto.map(x => ({
                calendarEventId: x.calendarEventId,
                pupils: x.pupils.map(y => ({
                    personId: y.personId,
                    selectionCount: y.selectionCount
                }))
            }));
        }
    }
);
