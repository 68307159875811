'use strict';

angular.module('cca.components.ccaGeneratingAdditionalEventsDialog',
    [
        'shared.components.sbModalHeader',
    ])
    .component('ccaGeneratingAdditionalEventsDialog',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/cca/components/cca-generating-additional-events-dialog.template.html',
            controller: [
                function ccaGeneratingAdditionalEventsDialogController()
                {
                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    this.ok = function ()
                    {
                        closeModal();
                    }

                    this.cancel = function ()
                    {
                        closeModal();
                    }
                }
            ]
        });
