angular.module('shared.directives.fixedTableHeaders', [])
    .directive('fixedTableHeaders', [
        '$timeout',
        function ($timeout) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    $timeout(function () {
                        /**
                         * scroll handle
                         * @param {event} e -- scroll event
                         */
                        function scrollHandle(e) {

                            var rect = element[0].getBoundingClientRect();

                            // Calculate heights of combined 'sticky' elements as these
                            // need to sit above the header

                            var stickToYPosition = 0;

                            var stickyEls = document.getElementsByClassName('sticky');

                            // Loop through the selected elements
                            for (var i = 0; i < stickyEls.length; i++) {

                                // Add the height of this element
                                stickToYPosition += stickyEls[i].offsetHeight;
                            }

                            var scrollTop = (rect.top < 0) ? Math.abs(rect.top) + stickToYPosition : 0; // this.scrollTop;

                            // console.log(scrollTop);

                            var headerEl = element[0].querySelector('thead');

                            headerEl.style.transform = 'translateY(' + scrollTop + 'px)';

                            if (scrollTop > 0) {
                                headerEl.classList.add("static");
                            } else {
                                headerEl.classList.remove("static");
                            }
                        }
                        window.addEventListener('scroll', scrollHandle);
                    }, 0);
                }
            };
        }]);