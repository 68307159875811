import { Component, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'sb-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {

  @Input() chartClass: string;
  @Input() chartOption: EChartsOption;
  @Output() onChartInit: EventEmitter<any> = new EventEmitter();
  chartInstance: any;

  onEchartInit(chartInstance) {
    this.chartInstance = chartInstance;
    if (this.chartOption) {
      this.chartInstance.setOption(this.chartOption);
    }
    this.onChartInit.emit(this.chartInstance);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chartInstance && changes.chartOption?.currentValue) {
      this.chartInstance.setOption(this.chartOption);
    }
  }

}
