// start:ng42.barrel
import './pupilTransportPreference.service';
import './transport-change-request.service';
import './transport-configuration-features.service';
import './transport-configuration-method.service';
import './transport-configuration-routes.service';
import './transport-configuration-stops.service';
import './transport-permission-to-walk.service';
import './transportDirections.service';
import './transportOperatingDays.service';
import './transportRouteUpdates.service';
import './transportTimeSlots.service';
import './transportTimetableCalendars.service';
import './transportTimetables.service';
// end:ng42.barrel

