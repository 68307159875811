'use strict';

angular.module('shared.controllers.teamClubPickerPopupController', [
    'shared.components.sbModalHeader'])
    .controller('teamClubPickerPopupController', [
        '$scope',
        '$uibModalInstance',
        'teamIds',
        'clubIds',
        'subjectClassIds',
        'showSubjects',
        'excludedTeamIds',
        'excludedClubIds',
        'excludedSubjectClassIds',
        function ($scope,
            $uibModalInstance,
            teamIds,
            clubIds,
            subjectClassIds,
            showSubjects,
            excludedTeamIds,
            excludedClubIds,
            excludedSubjectClassIds
            ) {


            $scope.teamIds = teamIds;
            $scope.clubIds = clubIds;
            $scope.subjectClassIds = subjectClassIds;
            $scope.showSubjects = showSubjects;

            $scope.excludedTeamIds = excludedTeamIds;
            $scope.excludedClubIds = excludedClubIds;
            $scope.excludedSubjectClassIds = excludedSubjectClassIds;

            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }
    ]);
