angular.module('sysadmin.components.editOrganisationDetails', [
    'sysadmin.services.sysAdminService',
    'sysadmin.services.organisationDetailService',
    'shared.services.toastService',
    'colours.services.colourService',
    'shared.components.sbIcon',
    'shared.components.sbWizard',
    'shared.constants'])
    .component('editOrganisationDetails', {
        templateUrl: '/Scripts/app/sysadmin/components/edit-organisation-details.template.html',
        bindings: {
            organisation: '<',
            isExisting: '<',
            dismiss: '&',
            close: '&',
            onSave: '&'
        },
        controller: class EditOrganisationDetailsCtrl
        {
            // Dependencies
            $filter: any;
            sysAdminService: any;
            organisationDetailService: any;
            toastService: any;
            colourService: any;
            formElements: any;

            // Bindings
            dismiss: any;
            organisation: any;
            onSave: any;

            // Variables
            isSaving: boolean;
            tabs: any;
            organisationData: any;
            currencies: any;
            paymentMethods: Array<any>;
            externalIntegrations: Array<any>;
            externalManagementSystems: Array<any>;
            browserColours: any;
            isDisabled: any;
            isExisting: boolean;

            static $inject = ['$filter', 'sysAdminService', 'organisationDetailService', 'toastService', 'colourService', 'formElements'];

            constructor($filter, sysAdminService, organisationDetailService, toastService, colourService, formElements)
            {
                this.$filter = $filter;
                this.sysAdminService = sysAdminService;
                this.organisationDetailService = organisationDetailService;
                this.toastService = toastService;
                this.colourService = colourService;
                this.formElements = formElements;
            }

            $onInit()
            {
                this.isSaving = false;
                this.isDisabled = false;

                // Create new tab object for each new data to trigger onChanges in child component
                this.organisationDetailService.getPaymentMethods()
                    .then(res =>
                    {
                        this.updateTabs(2, 'paymentMethodId', res.data);
                    });

                this.organisationDetailService.getCurrencies()
                    .then(res =>
                    {
                        this.currencies = res.data;
                        this.currencies.forEach(item =>
                        {
                            item.name = (item.isO4217Code + ' / ' + item.symbol);
                        });
                        this.updateTabs(2, 'isoCurrency', this.currencies);
                        this.updateUseDefaultCurrencySymbol();
                    });

                this.organisationDetailService.getExternalIntegrations()
                    .then(res =>
                    {
                        this.updateTabs(2, 'integrationId', res.data);
                    });

                this.organisationDetailService.getExternalManagementSystems()
                    .then(res =>
                    {
                        this.updateTabs(2, 'externalManagementSystemId', res.data);
                    });

                this.organisationDetailService.getTimezones()
                    .then(res =>
                    {
                        this.updateTabs(0, 'ianaTimeZoneName', res.data);
                    });

                this.organisationDetailService.getFallbackLabelOrganisations()
                    .then(res =>
                    {
                        this.updateTabs(0, 'fallbackLabelOrganisationId', res.data);
                    });

                const schoolTypes = [
                    { label: 'SB_Type_Junior', id: 1 },
                    { label: 'SB_Type_Senior_Both', id: 2 }
                ];

                const registrationTypes = [
                    { label: 'SB_SysAdmin_SelectOption_RegistrationTypeYouthOnly', id: 1 },
                    { label: 'SB_SysAdmin_SelectOption_RegistrationTypeAdultOnly', id: 2 },
                    { label: 'SB_SysAdmin_SelectOption_RegistrationTypeAnyone', id: 3 }
                ];

                const twitterPattern = '^[A-Za-z0-9_]*$';

                this.tabs = [
                    {
                        name: 'SB_Organisation_Info',
                        iconName: 'school',
                        image: 'education',
                        fields: [
                            {
                                label: 'SB_SysAdmin_Label_isTest',
                                type: this.formElements.Alert,
                                id: 'isTest',
                                displayIf: [{ property: 'isTest', values: [true]}]
                            },
                            {
                                label: 'SB_Title',
                                type: this.formElements.Text,
                                id: 'title',
                                isRequired: true,
                                placeholder: 'SB_Organisation_Name',
                                maxlength: 100,
                                cols: 5
                            },
                            {
                                label: 'SB_Legal_Name_Different',
                                type: this.formElements.Text,
                                id: 'legalName',
                                maxlength: 100,
                                cols: 5
                            },
                            {
                                label: 'SB_Type',
                                type: this.formElements.Select,
                                options: schoolTypes,
                                id: 'linkedAccountUsageTypeId',
                                isRequired: true,
                                cols: 4
                            },
                            {
                                label: 'SB_Timezone',
                                type: this.formElements.VisualSelect,
                                id: 'ianaTimeZoneName',
                                loadOptions: true,
                                idProp: 'iana',
                                isRequired: true,
                                cols: 6
                            },
                            {
                                label: 'SB_Main_Email',
                                type: this.formElements.Email,
                                id: 'contact-mainEmailAddress',
                                cols: 5,
                                maxlength: 150
                            },
                            {
                                label: 'SB_Accounts_Email',
                                type: this.formElements.Email,
                                id: 'contact-accountsEmailAddress',
                                tooltip: 'Email used for accounts',
                                cols: 5,
                                maxlength: 150
                            },
                            {
                                label: 'SB_Company_Registration_Number',
                                type: this.formElements.Text,
                                id: 'companyRegNumber',
                                cols: 5,
                                maxlength: 100
                            },
                            {
                                label: 'SB_Tax_Number',
                                type: this.formElements.Text,
                                id: 'taxNumber',
                                cols: 5,
                                maxlength: 100
                            },
                            {
                                label: 'SB_Tax_Percent_Rate',
                                type: this.formElements.Number,
                                id: 'taxPercentRate',
                                cols: 5,
                                maxlength: 100,
                                isRequiredAllowZero: true
                            },
                            {
                                label: 'SB_SysAdmin_Label_AllowRegistrations',
                                type: this.formElements.Switch,
                                id: 'allowExternalRegistration',
                                trueLabel: 'SB_Yes',
                                falseLabel: 'SB_No',
                                default: false,
                                cols: 10,
                                tooltip: 'SB_SysAdmin_Tooltip_AllowRegistrations'
                            },
                            {
                                label: 'SB_SysAdmin_Label_RegistrationType',
                                type: this.formElements.Select,
                                options: registrationTypes,
                                id: 'organisationRegistrationTypeId',
                                requiredIf: [
                                    { property: 'allowExternalRegistration', values: [true] }
                                ],
                                cols: this.isExisting ? 3 : 5,
                                enableIf: [{ property: 'allowExternalRegistration', values: [true] }],
                                default: 1,
                            },
                            {
                                label: 'SB_SysAdmin_Label_RegistrationDefaultYearGrade',
                                type: this.formElements.Select,
                                id: 'newRegistrationYearGroupId',
                                loadOptions: true,
                                idProp: 'id',
                                requiredIf: [
                                    { property: 'allowExternalRegistration', values: [true] }
                                ],
                                cols: 3,
                                isHidden: !this.isExisting,
                                enableIf: [{ property: 'allowExternalRegistration', values: [true] }]
                            },
                            {
                                label: 'SB_SysAdmin_Label_NewYearGrade',
                                type: this.formElements.Text,
                                id: 'registrationNewYearGroupName',
                                requiredIf: [
                                    { property: 'allowExternalRegistration', values: [true] },
                                    { property: 'newRegistrationYearGroupId', values: [-1] }
                                ],
                                cols: this.isExisting ? 3 : 5,
                                maxLength: 100,
                                enableIf: [
                                    { property: 'allowExternalRegistration', values: [true] },
                                    { property: 'newRegistrationYearGroupId', values: [-1] }
                                ],
                            },
                            {
                                label: 'SB_Fallback_Label',
                                type: this.formElements.Select,
                                loadOptions: true,
                                id: 'fallbackLabelOrganisationId',
                                idProp: 'organisationId',
                                cols: 5,
                                isRequired: true
                            },
                            {
                                label: 'SB_Trial_Question',
                                type: this.formElements.Switch,
                                id: 'isTrial',
                                trueLabel: 'SB_Yes',
                                falseLabel: 'SB_No',
                                default: false,
                                cols: 5,
                            },
                            {
                                id: 'isNewOrganisation',
                                type: this.formElements.Hidden
                            },
                        ]
                    },
                    {
                        name: 'SB_Contact',
                        iconName: 'contacts',
                        image: 'contact_us',
                        fields: [
                            {
                                label: 'SB_Address_Line_1',
                                type: this.formElements.Text,
                                id: 'contact-address1',
                                cols: 5,
                                maxlength: 150
                            },
                            {
                                label: 'SB_Address_Line_2',
                                type: this.formElements.Text,
                                id: 'contact-address2',
                                cols: 5,
                                maxlength: 150
                            },
                            {
                                label: 'SB_City',
                                type: this.formElements.Text,
                                id: 'contact-city',
                                cols: 5,
                                maxlength: 100
                            },
                            {
                                label: 'SB_State',
                                type: this.formElements.Text,
                                id: 'contact-state',
                                cols: 5,
                                maxlength: 100
                            },
                            {
                                label: 'SB_PostCode',
                                type: this.formElements.Text,
                                id: 'contact-postalCode',
                                cols: 5,
                                maxlength: 50
                            },
                            {
                                label: 'SB_Country',
                                type: this.formElements.Country,
                                id: 'contact-countryId',
                                cols: 5,
                                requiredIf: [{ property: 'contact' }],
                            },
                            {
                                label: 'SB_Telephone',
                                type: this.formElements.Tel,
                                id: 'contact-telephoneNumber',
                                cols: 5,
                                relatedValueController: 'contact-countryId'
                                // min/max length handled by component
                            },
                            {
                                label: 'SB_Website',
                                type: this.formElements.Url,
                                cols: 5,
                                id: 'contact-website',
                                maxlength: 150,
                                placeholder: 'https://www.organisation.com'
                            },
                            {
                                label: 'SB_Twitter_Handle',
                                type: this.formElements.Twitter,
                                id: 'contact-mainTwitterHandle',
                                cols: 5,
                                maxlength: 50,
                                pattern: twitterPattern
                            },
                            {
                                label: 'SB_Activites_Twitter_Handle',
                                type: this.formElements.Twitter,
                                id: 'contact-activitiesTwitterHandle',
                                cols: 5,
                                maxlength: 50,
                                pattern: twitterPattern
                            },
                            {
                                label: 'SB_Other_Twitter_Handle',
                                type: this.formElements.Twitter,
                                id: 'contact-otherTwitterHandle',
                                cols: 5,
                                maxlength: 50,
                                pattern: twitterPattern
                            }
                        ]
                    },
                    {
                        name: 'SB_Integrations',
                        iconName: 'integration',
                        image: 'server',
                        fields: [
                            {
                                label: 'SB_Fees',
                                type: this.formElements.SubHeading
                            },
                            {
                                label: 'SB_Payment_Method',
                                type: this.formElements.Select,
                                id: 'paymentMethodId',
                                cols: 5,
                                loadOptions: true,
                            },
                            {
                                label: 'SB_Currency',
                                type: this.formElements.Select,
                                id: 'isoCurrency',
                                idProp: 'isO4217Code',
                                isRequired: true,
                                cols: 5
                            },
                            {
                                label: 'SB_Use_Default_Currency_Symbol',
                                type: this.formElements.Switch,
                                id: 'useDefaultCurrencySymbol',
                                cols: 5,
                                trueLabel: 'SB_Yes',
                                falseLabel: 'SB_No',
                                default: true
                            },
                            {
                                label: 'SB_Currency_Symbol',
                                type: this.formElements.Text,
                                id: 'currencyDisplaySymbol',
                                displayIf: [{ property: 'useDefaultCurrencySymbol', values: [false] }],
                                cols: 5,
                                isRequired: true,
                                maxlength: 3
                            },
                            {
                                label: 'SB_Pupils',
                                type: this.formElements.SubHeading
                            },
                            {
                                label: 'SB_External_Integration',
                                type: this.formElements.Select,
                                id: 'integrationId',
                                cols: 5,
                                loadOptions: true
                            },
                            {
                                label: 'SB_External_Management_System',
                                type: this.formElements.Select,
                                options: this.externalManagementSystems,
                                id: 'externalManagementSystemId',
                                cols: 5,
                                loadOptions: true
                            },
                            {
                                label: 'SB_Kbl_Id',
                                type: this.formElements.Number,
                                id: 'kbl_Id',
                                cols: 5
                            }
                        ]
                    },
                    {
                        name: 'SB_Style',
                        iconName: 'style',
                        image: 'work_in_progress',
                        fields: [
                            {
                                type: this.formElements.Colours,
                                id: 'colours',
                            }
                        ],
                        isHidden: this.isExisting
                    },
                ];

                if (typeof this.organisationData === 'undefined')
                {
                    var regYearGroups = [{ id: -1, name: 'SB_Create_New' }];
                    this.updateTabs(0, 'newRegistrationYearGroupId', regYearGroups);
                    this.organisationData =
                    {
                        isNewOrganisation: true,
                        newRegistrationYearGroupId: -1
                    };
                }
            }

            selectRegistrationYearGroupCols()
            {
                if (this.isExisting &&
                    typeof this.organisationData !== 'undefined' &&
                    this.organisationData.newRegistrationYearGroupId != -1)
                {
                    return 6;
                }

                return 3;
            }

            updateUseDefaultCurrencySymbol()
            {
                if (this.currencies && this.organisationData)
                {
                    const matchedCurrencySymbols = this.currencies.filter(
                        currency =>
                        {
                            return currency.isO4217Code == this.organisationData.isoCurrency;
                        }
                    );
                    this.organisationData.useDefaultCurrencySymbol = this.organisationData !== undefined && matchedCurrencySymbols.length > 0 && matchedCurrencySymbols[0] && this.organisationData.currencyDisplaySymbol == matchedCurrencySymbols[0].symbol;
                }
            }

            $onChanges(changes)
            {
                if (changes.organisation && changes.organisation.currentValue)
                {
                    this.organisationDetailService.getOrganisation(this.organisation.organisationId)
                        .then(res =>
                        {
                            this.organisationData = res;
                            if (this.organisationData)
                            {
                                // Create UI values from data
                                this.organisationData.colours = this.colourService.convertColours(this.organisationData);
                                this.updateUseDefaultCurrencySymbol();
                                this.isDisabled = false;

                                this.organisationData.isNewOrganisation = false;
                            }

                            this.organisationDetailService.getSchoolYearGroups(this.organisation.organisationId)
                                .then(res =>
                                {
                                    res.data.splice(0, 0, { id: -1, name: 'SB_Create_New' });
                                    this.updateTabs(0, 'newRegistrationYearGroupId', res.data);
                                });
                        })
                        .catch(err =>
                        {
                            // Disable form on error to avoid overwriting wrong org with data
                            this.organisationData = {};
                            this.isDisabled = true;
                            console.log(err);
                            this.toastService.getError();
                        })
                }
            };

            updateTabs(tab, fieldId, data)
            {
                const newTabs = [].concat(this.tabs);
                let field = newTabs[tab].fields.find(field => field.id == fieldId);
                if (field)
                {
                    field.options = data;
                }
                this.tabs = newTabs;
            }

            close()
            {
                this.dismiss('cancel');
            };

            save()
            {
                this.isSaving = true;
                const params = { ...this.organisationData };

                // Adjust colour values to comply with back end names/structure
                params.darkColour = this.colourService.hexToColour(params.colours.primaryColour);
                params.lightColour = this.colourService.hexToColour(params.colours.secondaryColour);
                delete params.colours;

                // Set curency symbol
                const matchedCurrencies = this.currencies.filter(currency =>
                {
                    return currency.isO4217Code == params.isoCurrency;
                });

                if (params.useDefaultCurrencySymbol && matchedCurrencies.length > 0)
                {
                    params.currencyDisplaySymbol = matchedCurrencies[0].symbol;
                }

                // Add any missing fields
                this.tabs.forEach(tab =>
                {
                    if (tab.fields)
                    {
                        tab.fields.forEach(field =>
                        {
                            /* Set empty string values of a numeric option select to null to keep back end happy.
                               Cannot get type from first option as this could be a '' blank option even if property is number */
                            if (field.id && params[field.id] === '' && field.options && field.options[field.options.length - 1] && typeof field.options[field.options.length - 1][field.idProp] === 'number')
                            {
                                params[field.id] = null;
                            }
                        });
                    }
                });

                if (this.organisation)
                {
                    this.organisationDetailService.updateOrganisation(this.organisation.organisationId, params)
                        .then(() =>
                        {
                            this.isSaving = false;
                            this.onSave();
                        })
                        .catch(error =>
                        {
                            console.log(error)
                            this.isSaving = false;
                        });
                } else
                {
                    this.organisationDetailService.createOrganisation(params)
                        .then(() =>
                        {
                            this.toastService.saveSuccess('SB_Add_Organisation_Success_Message')
                            this.isSaving = false;
                            this.close();
                        })
                        .catch(error =>
                        {
                            console.log(error)
                            this.toastService.saveError('SB_Add_Organisation_Error_Message');
                            this.isSaving = false;
                        });
                }
            };

            updateData(newData: any)
            {
                this.organisationData = newData;
                // Delete contact object if all properties are falsy
                if (this.organisationData.contact)
                {
                    const hasContactInfo = Object.keys(this.organisationData.contact).some((k) => { return this.organisationData.contact[k]; });
                    if (!hasContactInfo)
                    {
                        delete this.organisationData.contact;
                    }
                }
            }
        }
    });
