angular.module("cca.components.dailyPreferenceSelector",
    [
        'pascalprecht.translate',
        'ui.bootstrap',
        'shared.components.sbIcon',
        'shared.components.sbAvatar',
        'shared.services.simpleAlertService'
    ])
    .component("dailyPreferenceSelector",
        {
            templateUrl: "/Scripts/app/cca/components/daily-preference-selector.template.html",
            bindings: {

                joinRequestMode: '<',

                ignoreDayOfWeek: '<',
                preferenceSelectionScope: '<',
                selectionPreferenceTypeId: '<',
                allowedPreferenceCount: '<',
                canSave: '<',
                ignoreInformationOnlyFlag: '<',
                organisation: '<', // Added so that we can show currency symbol

                groups: '<', // one way binding- however remember this is a pointer, so attribute changes will still be reflected on the parent

                selectionsReadOnly: '<',
                isStaff: '<',
                hasBulkPhotos: '<',
                onUpdate : '&'
            },
            controller: [
                '$translate',
                '$filter',
                'simpleAlertService',
                function ($translate, $filter, simpleAlertService) {

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    this.accordianDisabled = false;
                    this.accordianOpen = [];
                    this.textFilter = '';

                    for (var i = 1; i <= 7; i++) {
                        this.accordianOpen[i] = false;
                    }

                    // given a parent or child of a linked group of groups, find the parent group id
                    var getRootGroupIdForLinkedGroup = function (groupId) {
                        var rootGroupId = groupId;
                        for (var i = this.groups.length; i--;) {
                            var group = this.groups[i];
                            if (group.id === groupId) {
                                // we found it!
                                if (group.linkedParentTeamId
                                ) // it's not the parent, so we set the parent id to the one we're interested in
                                {
                                    return group.linkedParentTeamId;
                                }
                            }
                        }
                        return rootGroupId;
                    }.bind(this);

                    // get all groups in a linked group including the parent
                    var getAllLinkedGroupPupils = function (groupId, pupilId) {
                        var result = [];

                        var rootGroupId = getRootGroupIdForLinkedGroup(groupId);

                        // get all groups in this linked group, including the parent
                        for (var i = this.groups.length; i--;) {
                            if (this.groups[i].linkedParentTeamId === rootGroupId ||
                                this.groups[i].id === rootGroupId) {
                                for (var j = this.groups[i].pupils.length; j--;) {
                                    if (this.groups[i].pupils[j].personId === pupilId) {
                                        // the pupil bit is the bit we actually want
                                        result.push(this.groups[i].pupils[j]);
                                    }
                                }
                            }
                        }

                        return result;
                    }.bind(this);

                    this.$onChanges = function (changes) {
                        var i = 0;
                        var j = 0;
                        if (changes.ignoreDayOfWeek && changes.ignoreDayOfWeek.currentValue !== undefined) {

                            if (this.ignoreDayOfWeek) {
                                for (i in this.accordianOpen) {
                                    this.accordianOpen[i] = true;
                                }
                                this.accordianDisabled = true;
                            }

                        }

                        if (changes.groups && changes.groups.currentValue) {

                            var linkedGroupParents = [];

                            for (i = this.groups.length; i--;) {
                                var group = this.groups[i];
                                var rootGroupId = getRootGroupIdForLinkedGroup(group.id);

                                group.links = [];

                                for (j = this.groups.length; j--;) {
                                    var linkedGroup = this.groups[j];
                                    if (linkedGroup.id !== group.id && (linkedGroup.linkedParentTeamId === rootGroupId || linkedGroup.id === rootGroupId)) {
                                        group.links.push({
                                            dayofWeek: linkedGroup.dayofWeek,
                                            ccaEndHour: linkedGroup.ccaEndHour,
                                            ccaEndMin: linkedGroup.ccaEndMin,
                                            ccaStartHour: linkedGroup.ccaStartHour,
                                            ccaStartMin: linkedGroup.ccaStartMin
                                        });
                                    }
                                }

                                group.links = $filter('orderBy')(group.links, ['dayofWeek', 'ccaStartHour', 'ccaEndMin'], true);

                                group.isLinked = group.links.length > 0;
                            }
                        }
                    };

                    var confirmLinkedGroupsThenExecuteCallback = function (signUpGroup, pupil, callback) {
                        var eventList = '<br /><br />';

                        for (var i = signUpGroup.links.length; i--;) {
                            var link = signUpGroup.links[i];
                            eventList += '<strong>' + signUpGroup.title + '</strong> ' +
                                ' (' + pupil.firstName + ' ' + pupil.lastName + ') ' +
                                $translate.instant('SB_WeekShortDay' + (link.dayofWeek % 7)) + ' ' +
                                ("00" + link.ccaStartHour).slice(-2) +
                                ':' +
                                ("00" + link.ccaStartMin).slice(-2) +
                                ' - ' +
                                ("00" + link.ccaEndHour).slice(-2) +
                                ':' +
                                ("00" + link.ccaEndMin).slice(-2) +
                                '<br />';
                        }
                        eventList += '<br />';

                        var messageBody = $translate.instant('SB_Body_Cca_Preference_Is_Linked',
                            {
                                eventListHtml: '###list###'
                            });

                        messageBody = messageBody.replace('###list###', eventList);

                        var alertRef = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Cca_Preference_Is_Linked',
                            translateMessage: false,
                            message: messageBody,
                            okButtonText: 'SB_OK',
                            cancelButtonText: 'SB_Cancel'
                        });

                        alertRef
                            .result
                            .then(function () {
                                callback();
                            })
                            .catch(function () {
                                console.log('dialog cancelled');
                            });
                    }.bind(this);

                    this.askToJoinClicked = function (event, signUpGroup, pupil) {
                        var performChange = function () {

                            var askToJoin = true;

                            if (pupil.askToJoin) {
                                askToJoin = false;
                            }

                            var pupilsToUpdate = getAllLinkedGroupPupils(signUpGroup.id, pupil.personId);

                            for (var i = pupilsToUpdate.length; i--;) {
                                pupilsToUpdate[i].askToJoin = askToJoin;
                            }

                            if (this.onUpdate) {
                                this.onUpdate();
                            }

                        }.bind(this);

                        if (signUpGroup.isLinked) {
                            event.preventDefault();

                            confirmLinkedGroupsThenExecuteCallback(signUpGroup, pupil, performChange);

                        } else {

                            performChange();
                        }

                    }.bind(this);

                    // disable, or toggle the state of a preference box (and any linked groups boxes as these must all be done together)
                    this.preferenceClicked = function (event, signUpGroup, pupil, preferenceLevel) {
                        var performChange = function () {
                            if (pupil.preferenceLevel === preferenceLevel) {
                                preferenceLevel = 0;
                            }

                            var pupilsToUpdate = getAllLinkedGroupPupils(signUpGroup.id, pupil.personId);

                            for (var i = pupilsToUpdate.length; i--;) {
                                pupilsToUpdate[i].preferenceLevel = preferenceLevel;
                            }

                            if (this.onUpdate) {
                                this.onUpdate();
                            }

                        }.bind(this);

                        if (signUpGroup.isLinked) {
                            event.preventDefault();

                            confirmLinkedGroupsThenExecuteCallback(signUpGroup, pupil, performChange);

                        } else {
                            performChange();
                        }

                    }.bind(this);

                    this.getDaysOfWeekForEvent = function (rootGroupId) {
                        var result = [];
                        for (var i = this.groups.length; i--;) {
                            var g = this.groups[i];
                            if (g.id === rootGroupId || g.linkedParentTeamId === rootGroupId) {
                                if (result.indexOf(g.dayofWeek) === -1)
                                    result.push(g.dayofWeek);
                            }
                        }
                        return result;
                    };

                    // check if another preference of this level has been already been set up the same day (or week, if weekly container)
                    this.othersChecked = function (personId, level, dayofWeek, currentGroup) {
                        var i = 0;
                        var group = null;

                        // dig out the parent id, then we can do all checks on the basis of this group, or one of its children
                        var rootGroupId = getRootGroupIdForLinkedGroup(currentGroup.id);

                        //days of week
                        var daysOfWeek = this.getDaysOfWeekForEvent(rootGroupId);

                        for (i = this.groups.length; i--;) {
                            group = this.groups[i];

                            if (
                                group.id !== rootGroupId &&
                                group.linkedParentTeamId !== rootGroupId &&

                                (
                                    // group.dayofWeek === dayofWeek ||
                                    daysOfWeek.indexOf(group.dayofWeek) > -1 ||
                                    this.preferenceSelectionScope === window.EveryBuddy.Enums.PreferenceSelectionScope.Weekly
                                )
                            ) {
                                for (var j = group.pupils.length; j--;) {
                                    var pupil = group.pupils[j];
                                    if (pupil.personId === personId && pupil.preferenceLevel === level) {
                                        return true;
                                    }
                                }
                            }
                        }

                        if (this.selectionPreferenceTypeId === 1 && level === 1) {

                            for (i = this.groups.length; i--;) {
                                group = this.groups[i];
                                if (
                                    (
                                        group.id === rootGroupId ||
                                        group.linkedParentTeamId === rootGroupId
                                    ) &&
                                    group.size !== 0 &&
                                    group.placesConfirmed > group.size
                                ) {
                                    return true;
                                }
                            }

                        }

                        return false;
                    }.bind(this);

                    this.costType = function (costType) {

                        if (this.ignoreDayOfWeek)
                            return '';

                        switch (costType) {
                            case 1:
                                return 'SB_Per_term';
                            case 2:
                                return 'SB_Per_session';
                            case 3:
                                return 'SB_One_off';
                            default:
                                return 'SB_Free';
                        }
                    }.bind(this);


                    this.clearAll = function () {
                        var alertRef = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Clear_all_selections',
                            message: 'SB_Body_Clear_all_selections',
                            okButtonText: 'SB_OK',
                            cancelButtonText: 'SB_Cancel'
                        });

                        alertRef
                            .result
                            .then(function () {
                                for (const group of this.groups.filter(group => !group.informationOnlyTeam)) {
                                    for (const pupil of group.pupils) {
                                        pupil.preferenceLevel = 0;
                                    }
                                }
                            }.bind(this))
                            .catch(function () {
                                console.log('dialog cancelled');
                            });
                    }.bind(this);

                    this.groupsFilter = function (item) {
                        if (this.textFilter == '') {
                            return true;
                        }
                        if (item.title.toLowerCase().includes(this.textFilter.toLowerCase())) {
                                return item;
                        }
                    }.bind(this);

                    this.getAvailablePlaces = function(signUpGroup) {
                        if (signUpGroup && signUpGroup.pupils && signUpGroup.placesConfirmed && typeof signUpGroup.placesConfirmed == 'number') {
                            var additionalPlaces = signUpGroup.pupils.filter(function(pupil) {
                                return pupil.preferenceLevel === 1;
                            }).length;
                            return signUpGroup.size - (signUpGroup.placesConfirmed + additionalPlaces);
                        }
                    };

                    this.disableChoose = function(signUpGroup, pupil) {
                        if (signUpGroup && pupil) {
                            return (!this.ignoreInformationOnlyFlag && signUpGroup.informationOnlyTeam) || (pupil.preferenceLevel !== 1 && (this.getAvailablePlaces(signUpGroup) < 1 && signUpGroup.size > 0));
                        }
                    }.bind(this);

                    this.hideClearAll = function() {
                        return this.groups?.every(signupGroup => signupGroup.informationOnlyTeam);
                    }

                    this.fcfsDuplicates = (signUpGroup, pupil) => {
                        if (!signUpGroup || !pupil || this.selectionPreferenceTypeId !== 1 || pupil.preferenceLevel !== 0) {
                            return false;
                        }
                        let linkedDuplicates = false;
                        if (signUpGroup.isLinked) {
                            // check linked events for duplicates
                            linkedDuplicates = signUpGroup.links.some(link => {
                                return this.pupilSelectedOnDay(link.dayofWeek, pupil);
                            });
                        }
                        // Check this group for duplicates
                        return linkedDuplicates || this.pupilSelectedOnDay(signUpGroup.dayofWeek, pupil);
                    }

                    this.pupilSelectedOnDay = (dayofWeek, pupil) => {
                        return this.groups.filter(group => {
                            const matchedPupils = group.pupils.filter(groupPupil => {
                                return groupPupil.personId === pupil.personId &&
                                groupPupil.preferenceLevel === 1;
                            });
                            return matchedPupils.length > 0 && group.dayofWeek === dayofWeek;
                        }).length > 0;
                    };

                    this.waitingListAvailable = () => {
                        return this.allowedPreferenceCount > 1 || this.isStaff;
                    };
                }
            ]
        });
