'use strict';

angular.module('transport.services.transportTimetableCalendarsService', [])
    .factory('transportTimetableCalendarsService', [
        '$http',
        function ($http)
        {
            var urlBase = '/webapi/WebOrganisationTransportTimetableCalendar/';
            var archivingUrlBase = '/webapi/WebOrganisationTransportArchiving/';
            var service = {};

            service.addTransportTimetableCalendar = function (transportTimetableCalendar)
            {
                return $http.post(urlBase + 'AddTransportTimetableCalendar', transportTimetableCalendar)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.editTransportTimetableCalendar = function (transportTimetableCalendar)
            {
                return $http.post(urlBase + 'EditTransportTimetableCalendar', transportTimetableCalendar)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportTimetableCalendars = function (isDeletedCalendars)
            {
                return $http.get(urlBase + 'GetTransportTimetableCalendars',
                    {
                        params:
                        {
                            isDeletedCalendars: isDeletedCalendars
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getCurrentTransportTimetableCalendarsForTimeSlot = function (timeSlotId)
            {
                return $http.get(urlBase + 'GetCurrentTransportTimetableCalendarsForTimeSlot',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportTimetableCalendar = function (timetableCalendarId)
            {
                return $http.get(urlBase + 'GetTransportTimetableCalendar',
                    {
                        params:
                        {
                            timetableCalendarId: timetableCalendarId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.validateDateRangeForOverlaps = function (validationRequest)
            {
                return $http.post(urlBase + 'ValidateDateRangeForOverlaps', validationRequest)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportTimetableCalendarArchivingInfo = function (transportTimetableCalendarId)
            {
                return $http.get(archivingUrlBase + 'GetTransportTimetableCalendarArchivingInfo', { params: { transportTimetableCalendarId: transportTimetableCalendarId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.archiveTimetableCalendar = function (transportTimetableCalendar)
            {
                return $http.put(archivingUrlBase + 'ArchiveTimetableCalendar', { Id: transportTimetableCalendar.id })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            return service;
        }
    ]);