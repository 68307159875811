'use strict';

angular.module('balanceAdmin.massAdjustmentGroup.components.massAdjustmentGroupDetail',
    [
        'balanceAdmin.components.massAdjustmentQuickButtons'
    ])
    .component('massAdjustmentGroupDetail',
        {
            bindings: {
                isTeam: '<',
                eventId: '<',
                quickAdjust: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/massAdjustmentGroup/components/mass-adjustment-group-detail.template.html',
            controller: [
                '$filter',
                '$uibModal',
                'balanceAdminService',
                'simpleAlertService',
                function (
                    $filter,
                    $uibModal,
                    balanceAdminService,
                    simpleAlertService) {

                    this.charges = [];
                    this.isLoading = true;
                    this.sortType = 'transactionDate';
                    this.sortReverse = false;
                    this.refundClassification = 1;
                    this.description = '';
                    this.adjustVal = null;
                    this.adjustmentSubmitted = false;
                    this.adjustmentSubmitConfirmed = false;

                    this.$onChanges = function (changes) {
                        if (changes.eventId && this.eventId) {
                            this.refresh();
                        }

                        if (changes.quickAdjust && this.quickAdjust) {
                            this.quickAdjust.fn = this.showQuickAdjust;
                        }
                    }.bind(this);

                    this.refresh = function () {
                        this.isLoading = true;
                        this.charges = [];

                        // Get event charges
                        balanceAdminService.getChargesForEvent(this.eventId)
                            .then(function (charges) {
                                this.charges = charges;
                                this.isLoading = false;
                            }.bind(this))
                            .catch(function (err) {
                                console.log(err);
                                simpleAlertService.errorAlert({ message: 'Unable to load event charges' });
                            });
                    }.bind(this);

                    this.showQuickAdjust = function () {

                        var quickAdjustModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'massAdjustmentQuickButtons',
                            size: 'lg'
                        });

                        quickAdjustModal.result
                            .then(function (data) {
                                if (data.adjustOption == 1) { // custom amount
                                    this.adjustByValue(data.customValue);
                                }
                                else if (data.adjustOption == 2) { // clear total fee
                                    this.adjustByMaxAvailable();
                                }
                                else { // clear outstanding charges
                                    this.adjustByChargeOutstanding();
                                }
                            }.bind(this))
                            .catch(function () {
                            });

                    }.bind(this);

                    this.adjustByMaxAvailable = function () {
                        var filteredCharges = $filter('filter')(this.charges, { eventId: this.selectedEventId });
                        angular.forEach(filteredCharges, function (charge) {
                            charge.newAdjustment = charge.availableToAdjust;
                        });
                    }.bind(this);

                    this.adjustByChargeOutstanding = function () {
                        var filteredCharges = $filter('filter')(this.charges, { eventId: this.selectedEventId });
                        angular.forEach(filteredCharges, function (charge) {
                            charge.newAdjustment = charge.chargeOutstanding;
                        });
                    }.bind(this);

                    this.adjustByValue = function (customValue) {
                        if (typeof customValue === 'undefined') {
                            return;
                        }

                        var filteredCharges = $filter('filter')(this.charges, { eventId: this.selectedEventId });
                        angular.forEach(filteredCharges, function (charge) {
                            // cap adjustment at the maximum available
                            charge.newAdjustment = customValue <= charge.availableToAdjust
                                ? customValue
                                : charge.availableToAdjust;
                        }.bind(this));
                    }.bind(this);

                    this.confirmAdjustments = function () {
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Confirm',
                            message: 'SB_Confirm_Adjustments',
                            windowSize: 'sm',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel'
                        });

                        modalInstance.result
                            .then(function () {
                                this.adjustmentSubmitConfirmed = true;
                                this.submitAdjustments()
                                    .then(function () {
                                        this.adjustmentSubmitted = false;
                                    }.bind(this));
                            }.bind(this), function () {
                                this.adjustmentSubmitted = false;
                            }.bind(this));
                    }

                    this.submitAdjustments = function () {
                        var adjustments = [];
                        var filteredCharges = $filter('filter')(this.charges, { eventId: this.selectedEventId });
                        angular.forEach(filteredCharges, function (charge) {
                            adjustments.push(
                                {
                                    transactionId: charge.id,
                                    amountToAdjust: charge.newAdjustment
                                });
                        });

                        return balanceAdminService.massAdjustment(adjustments,
                            this.description,
                            1, // always refund to the wallet account
                            this.refundClassification)
                            .then(function (massAdjustResponse) {
                                this.refundClassification = 1;
                                this.description = '';
                                this.adjustVal = null;
                                this.adjustmentSubmitConfirmed = false;

                                if (!massAdjustResponse || !massAdjustResponse.failedAdjustments || massAdjustResponse.failedAdjustments.length > 0) {
                                    simpleAlertService.errorAlert({
                                        message: 'SB_Error_Saving'
                                    });
                                    this.refresh();
                                    return;
                                }

                                if (massAdjustResponse.failedAdjustments.length == 0) {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Success',
                                        message: 'SB_Success'
                                    });
                                    this.refresh();
                                    return;
                                }
                            }.bind(this), function () {
                                this.adjustmentSubmitConfirmed = false;

                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_Saving_Message'
                                });
                            });
                    }.bind(this);
                }
            ]
        });