angular.module('settingsModule')
    .component('settingsDashboard',
        {
            templateUrl: '/Scripts/app/settings/components/dashboard/settings-dashboard.template.html',
            controller: class SettingsDashboardCtrl {

                $state: any;
                iconService: any;
                serviceManager: any;

                menuItems: Array<any>;

                static $inject = ['$state', 'iconService', 'serviceManager'];

                constructor($state, iconService, serviceManager) {
                    this.$state = $state;
                    this.iconService = iconService;
                    this.serviceManager = serviceManager;
                }

                    $onInit()
                    {
                        this.menuItems = [
                            {
                                uisref: '.onboarding',
                                title: 'SB_Main_Settings',
                                icon: this.iconService.getIcon('mainSettings')
                            },
                            {
                                uisref: '.staffRoles',
                                title: 'SB_Staff_Roles',
                                icon: this.iconService.getIcon('staffroles')
                            },
                            {
                                uisref: '.linksPortalAdmin',
                                title: 'SB_Portal_Links',
                                icon: this.iconService.getIcon('portal')
                            },
                            {
                                uisref: '.fariaOne',
                                title: 'FariaOne',
                                icon: this.iconService.getIcon('integration')
                            }
                        ]

                        this.serviceManager.getServiceManager().then(res =>
                        {
                            if (res?.data?.url)
                            {
                                this.menuItems.push(
                                {
                                    externalHref: res.data.url,
                                    title: 'SB_Service_Manager',
                                    icon: this.iconService.getIcon('cog')
                                });
                            }
                        });

                        if (this.menuItems) {
                            this.$state.go(this.menuItems[0].uisref);
                        };

                    }

                }
            }
        );
