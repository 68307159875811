'use strict';

angular.module('receptionModule').component('receptionUsersPupilMembership', {
    templateUrl: '/Scripts/app/reception/controllers/user.membership.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        selectedPerson: '<'
    },
    controller:
    [
        '$scope',
        '$filter',
        '$uibModal',
        'receptionService',
        'simpleAlertService',
        function (
            $scope,
            $filter,
            $uibModal,
            receptionService,
            simpleAlertService) {

            this.$onChanges = (changes) => {
                if (changes.selectedPerson && changes.selectedPerson.currentValue) {
                    $scope.person = this.selectedPerson;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.membership = null;

            $scope.membershipStatuses = [
                {
                    id: 1,
                    name: 'Pending'
                },
                {
                    id: 2,
                    name: 'Active'
                },
                {
                    id: 3,
                    name: 'Expired'
                },
                {
                    id: 4,
                    name: 'Cancelled'
                },
                {
                    id: 5,
                    name: 'Removed'
                }
            ];

            $scope.$watch('membership.organisationPackageId', function (newValue, oldValue) {
                if ($scope.membership !== null &&
                    $scope.membership.packageStartDate !== null &&
                    $scope.membership.packageStartDate !== 'undefined' &&
                    newValue !== 'undefined') {
                    var matches = $filter('filter')($scope.organisationPackages, { organisationPackageId: newValue });
                    if (matches.length > 0) {
                        $scope.selectedPackage = matches[0];
                    }
                }
            });

            receptionService.getPupilMembership($scope.person.personData.id)
                .then(function (data) {
                    $scope.organisationPackages = data.packages;
                    $scope.availablePeople = data.possiblePeople;
                    $scope.organisationSlots = data.slots;

                    // Turn date strings into JS date objects ready for the date picker.
                    angular.forEach(data.personPackages, function(pp) {
                        pp.nextRenewalDate = pp.nextRenewalDate
                            ? new Date(pp.nextRenewalDate.substring(0, 10))
                            : '';

                        pp.packageStartDate = pp.packageStartDate
                            ? new Date(pp.packageStartDate.substring(0, 10))
                            : '';
                    });
                    $scope.personPackages = data.personPackages;

                    $scope.personPackageSlots = data.personPackageSlots;
                    $scope.agebands = data.ageBands;
                })
                .catch(function () {
                    simpleAlertService.errorAlert({
                        message: 'SB_Error_loading'
                    });
                });

            };

            $scope.addMembership = function () {
                $scope.membership = {
                    personId: $scope.person.personData.id,
                    initialCharge: 0,
                    showIntial: true,
                    packageStartDate: new Date()
                };
            };

            $scope.showPersonPackage = function (packageDetails) {
                $scope.membership = packageDetails;
            };

            $scope.updateMembership = function () {

                // make sure the time's are all set to midday
                if ($scope.membership.nextRenewalDate) {
                    $scope.membership.nextRenewalDate.setHours(12);
                }
                if ($scope.membership.packageStartDate) {
                    $scope.membership.packageStartDate.setHours(12);
                }

                receptionService.savePupilMembership($scope.membership)
                    .then(function (data) {
                        $scope.personPackages = data;
                        $scope.membership = null;
                    })
                    .catch(function () {
                        simpleAlertService.errorAlert({
                            message: 'SB_Error_Saving_Message'
                        });
                    });
            };
        }
    ]});
