'use strict';

angular.module('transport.configuration.timetable.transportTimetableAdd',
    [
        'transport.services.transportConfigurationMethodService',
        'transport.services.transportTimeSlotsService',
        'transport.services.transportDirectionsService',
        'transport.services.transportOperatingDaysService',
        'transport.services.transportTimetablesService',
        'transport.services.transportTimetableCalendarsService',
        'transport.services.transportConfigurationRoutesService',
        'shared.directives.sbCheckBox',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.iconService',
        'shared.services.simpleAlertService',
    ])
    .component('transportTimetableAdd',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
                addingNewTimetable: '='
            },
            templateUrl: '/Scripts/app/transport/configuration/timetable/transport-timetable-add.template.html',
            controller: [
                '$timeout',
                '$scope',
                'personService',
                'transportTimetablesService',
                'transportTimetableCalendarsService',
                'transportConfigurationMethodService',
                'transportTimeSlotsService',
                'transportDirectionsService',
                'transportOperatingDaysService',
                'iconService',
                'simpleAlertService',
                function transportTimetableAddController($timeout,
                                                         $scope,
                                                         personService,
                                                         transportTimetablesService,
                                                         transportTimetableCalendarsService,
                                                         transportConfigurationMethodService,
                                                         transportTimeSlotsService,
                                                         transportDirectionsService,
                                                         transportOperatingDaysService,
                                                         iconService,
                                                         simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;
                    this.timeslotDirectionName = null;
                    this.busMonitors = [];
                    this.methodSupportsRoutes = false;

                    this.changesMade = function ()
                    {
                        this.anyChanges = true;
                    };

                    var closeModal = function (responseDto)
                    {
                        this.modalInstance.close(responseDto);
                    }.bind(this);

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportMethods = null;
                    this.transportTimeSlots = null;
                    this.transportDirections = null;
                    this.transportTimetableCalendars = null;

                    transportConfigurationMethodService.getActiveMethods().then(function (data)
                    {
                        this.transportMethods = data;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    transportTimeSlotsService.getActiveTransportTimeSlots().then(function (data)
                    {
                        this.transportTimeSlots = data;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    transportDirectionsService.getTransportDirections().then(function (data)
                    {
                        this.transportDirections = data;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    transportTimetableCalendarsService.getTransportTimetableCalendars()
                    .then(function (data)
                    {
                        this.transportTimetableCalendars = data;
                    }.bind(this))
                    .catch(function (error)
                    {
                        simpleAlertService.errorAlert({ message: error.data.Message });
                    }.bind(this));

                    transportOperatingDaysService.getTransportOperatingDays().then(function (data)
                    {
                        var allTransportOperatingDays = data;
                        var activeTransportOperatingDays = [];
                        allTransportOperatingDays.forEach(function (day)
                        {
                            if (day.isActive)
                            {
                                activeTransportOperatingDays.push(day);
                            }
                        }.bind(this));

                        this.transportTimetable.transportOperatingDays = activeTransportOperatingDays;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    personService.getBusMonitors().then(function (data)
                    {
                        this.busMonitors = data;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    this.$onInit = function ()
                    {
                        this.transportTimetable =
                            {
                                transportMethodId: null,
                                transportTimeSlotId: null,
                                transportDirectionId: null,
                                transportTimetableCalendarId: null,
                                isActive: true,
                                transportOperatingDays: [],
                                transportOperatingDayIds: [],
                                transportRoutes: [],
                                transportRouteIds: [],
                                busMonitorPersonId: null
                            };
                    }.bind(this);

                    this.selectedMethodIconName = '';

                    this.methodSelected = function ()
                    {
                        var selectedMethod = this.transportMethods.find(function (method)
                        {
                            return method.id == this.transportTimetable.transportMethodId;
                        }.bind(this));

                        if (typeof selectedMethod !== 'undefined')
                        {
                            this.selectedMethodIconName = iconService.getIcon(selectedMethod.sbIconName);
                            this.methodSupportsRoutes = selectedMethod.supportRoutes;
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.transportTimetable =
                            {
                                transportMethodId: null,
                                transportTimeSlotId: null,
                                transportDirectioId: null,
                                transportTimetableCalendarId: null,
                                isActive: false,
                                busMonitorPersonId: null
                            };

                        this.modalInstance.dismiss('cancel');
                    };

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.createTransportTimetable = function ()
                    {
                        this.transportTimetable.transportOperatingDayIds = [];
                        this.transportTimetable.transportOperatingDays.forEach(function (day)
                        {
                            if (day.isActive)
                            {
                                this.transportTimetable.transportOperatingDayIds.push(day.id);
                            }
                        }.bind(this));

                        transportTimetablesService.createTransportTimetable(this.transportTimetable)
                            .then(function (responseDto)
                            {
                                this.transportTimetable =
                                    {
                                        transportMethodId: null,
                                        transportTimeSlotId: null,
                                        transportDirectioId: null,
                                        transportTimetableCalendarId: null,
                                        isActive: false,
                                        transportOperatingDays: [],
                                        transportOperatingDayIds: []
                                };

                                closeModal(responseDto);
                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });
                    }.bind(this);

                    $scope.$watch('$ctrl.transportTimetable["transportTimeSlotId"]',
                        function (newValue, oldValue)
                        {
                            if (newValue === oldValue)
                            {
                                return;
                            }

                            if (typeof newValue !== 'undefined')
                            {
                                var selectedTimeslotId = newValue;
                                for (var ts = 0; ts < this.transportTimeSlots.length; ts++)
                                {
                                    if (this.transportTimeSlots[ts].id == selectedTimeslotId)
                                    {
                                        for (var d = 0; d < this.transportDirections.length; d++)
                                        {
                                            if (this.transportDirections[d].id == this.transportTimeSlots[ts].transportDirectionId)
                                            {
                                                this.timeslotDirectionName = this.transportDirections[d].directionName;
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                        }.bind(this), true);
                }
            ]
        });
