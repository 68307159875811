

<div class="fake-select"
     [ngClass]="{'disabled-area': isDisabled, 'fake-select-colour-icons' : isColourIcons}"
     (keyup)="keyUp($event)"
     *ngIf="isReady"
     id="fakeSelectId"
     (blur)="onBlur()"
     [formGroup]="form">
     <div class="form-control"
          [ngClass]="{ 'fake-select__invalid' : isRequired && !model}"
          (click)="clickSelect()"
          id="{{selectId}}"
          #select>
          <div class="fake-select__option"
               *ngIf="selectedItem">
               <img [src]="getFullImgUrl(selectedItem)"
                    *ngIf="selectedItem[imgProp] && isReady"
                    alt="{{selectedItem[altProp]}}">
               <sb-icon *ngIf="selectedItem[sbIconProp]"
                         [name]="selectedItem[sbIconProp]"
                         [hasColor]="isColourIcons"
                         [overrideStyle]="selectedItem[iconStyleProp]"
                         [class]="selectedItem[iconClassProp]"
                         [isActivity]="selectedItem.iconIsActivity"></sb-icon>
               <i *ngIf="selectedItem[iconClassProp]"
               class="{{selectedItem[iconClassProp]}}"></i>
               <span *ngIf="!hideSelectedLabel"
                    [innerHTML]="selectedItem[selectedLabelProp] | translate"
                    class="text-capitalize"></span>
          </div>
          <div [innerHTML]="'SB_Select' | translate"
               *ngIf="!selectedItem"></div>
          <sb-icon name="caretDown"
                    class="unclickable"></sb-icon>
     </div>
     <div class="fake-select__open"
          id="{{selectInnerId}}"
          #selectInner
          *ngIf="show">
          <div class="fake-select__search"
               [hidden]="!showSearch">
               <input type="text"
                    class="form-control w-100"
                    id="{{searchInputId}}"
                    #searchInput
                    [(ngModel)]="searchText"
                    (focus)="onFocusSearch()"
                    placeholder="{{'SB_Search' | translate}}"
                    autocomplete="nope">
          </div>
          <div class="fake-select__list"
               [ngClass]="{'fake-select__list-grid' : isGrid}">
               <ng-template ngFor let-item [ngForOf]="filteredOptions()"  let-i="index">
                    <div class="form-control-static fake-select__option"
                         [ngClass]="{'fake-select__highlighted' : highlightedOptionId === item[idProp]}"
                         (click)="onClickItem(item[idProp], $event)"
                         (mouseover)="highlightedOptionId = item[idProp]"
                         id="fakeSelect-{{id}}-{{item[idProp]}}">
                         <img *ngIf="item[imgProp]"
                              [src]="getFullImgUrl(item)">
                         <sb-icon *ngIf="item[sbIconProp]"
                              [name]="item[sbIconProp]"
                              [hasColor]="isColourIcons"
                              [overrideStyle]="item[iconStyleProp]"
                              [class]="item[iconClassProp]"
                              [isActivity]="item.iconIsActivity"></sb-icon>
                         <i *ngIf="item[iconClassProp]"
                         class="{{item[iconClassProp]}}"></i>
                              <span [innerHTML]="item[labelProp] | translate"
                                   class="text-capitalize"></span>
                    </div>
                    <div *ngIf="showOptionDivider(i)">
                         <hr>
                    </div>
               </ng-template>
          </div>
     </div>
     <input hidden [ngModel]="model" [formControlName]="id">
</div>
<sb-icon name="loading" *ngIf="isLoading || !isReady"
class="pt-5 pl-3"></sb-icon>