import { ZendeskArticle } from './../../models/zendesk-article';
import { ZendeskService } from './../../services/zendesk.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sb-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  article: ZendeskArticle; 

  constructor(private zendesk: ZendeskService) { }

  ngOnInit(): void {
    this.zendesk.getArticle().subscribe(article => {
      this.article = article;
    })
  }

}
