angular.module('dashboard.components.portalPage', [
    'shared.directives.sbLoading',
    'shared.components.sbTitleBar',
    'shared.services.portalService',
])
    .component('portalPage',
        {
            templateUrl: '/Scripts/app/dashboard/components/portal-page.template.html',
            controller: class PortalPageCtrl {

                $state: any;
                $location: any;
                $window: any;
                $timeout: any;
                portalService: any;
                $sce: any;

                page: any;
                isLoading: boolean = true;

                static $inject = ['portalService', '$state', '$location', '$window', '$timeout', '$sce'];

                constructor (portalService, $state, $location, $window, $timeout, $sce)
                {
                    this.$state = $state;
                    this.$location = $location;
                    this.$window = $window;
                    this.$timeout = $timeout;
                    this.portalService = portalService;
                    this.$sce = $sce;
                }

                $onInit() {
                    const contentId = this.$state.params.pageId;
                    if (contentId) {
                        this.portalService.getContent(contentId).then(res => {
                            if (res.data) {
                                this.page = res.data;
                                // TODO: Remove timeout and test when migrating to Angular 12, or even hybrid
                                this.$timeout(() => {
                                    this.$window.scrollTo(0, 0);
                                }, 150)
                            }
                            else {
                                this.isLoading = false;
                                // Return to portal if page not found
                                this.$state.go('portal');
                            }
                            });
                    }
                    else {
                        // Return to portal if no page id
                        this.$state.go('portal');
                    }
                }

                getContent() {
                    return this.$sce.trustAsHtml(this.page?.content);
                }
            }
        });
