angular.module('settingsModule.services.attendanceScanningSettingsService', [
    'shared.services.httpCoreApi'
])
    .service('attendanceScanningSettingsService', class AttendanceScanningSettingsService {
        httpCoreApi: any;

        userSettings: any;
        adminSettings: any;

        baseUrl: string = 'OrganisationAttendanceScanningSettings';

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;

            this.userSettings = {
                controllerType: this.httpCoreApi.controllerTypes.User
            }

            this.adminSettings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            }
        }

        getSettings() {
            return this.httpCoreApi.get(this.baseUrl, this.userSettings);
        }

        updateSettings(settings) {
            return this.httpCoreApi.put(this.baseUrl, settings, this.adminSettings)
        }

        getTabVisible() {
            return this.httpCoreApi.get(`${this.baseUrl}/TabVisible`, this.adminSettings);
        }
    });
