'use strict';

angular.module('sysadmin.components.calendarEventTypes', [
    'sysadmin.services.calendarEventTypesService',
    'shared.components.sbOnOffSwitch',
    'shared.services.toastService',
]).component('calendarEventTypes', {
    templateUrl: '/Scripts/app/sysadmin/components/calendar-event-types.template.html',
    bindings: {
        organisation: '<'
    },
    controller: [
        '$scope',
        '$timeout',
        'eventSectionEnum',
        'calendarEventTypesService',
        'toastService',
        function calendarEventTypesController($scope, $timeout, eventSectionEnum, calendarEventTypesService, toastService)
        {
            var self = this;
            this.calendarEventTypes = [];
            this.eventSections = [];
            this.anyChanges = false;
            this.isAddEventCollapsed = true;

            this.$onChanges = function (changes)
            {
                if (typeof self.organisation !== 'undefined' && self.organisation != null)
                {
                    self.loadCalendarEventTypes();
                }
            };

            this.loadCalendarEventTypes = function ()
            {
                calendarEventTypesService.getCalendarEventTypes(this.organisation.organisationId).then(function (data)
                {
                    this.eventSections = data;

                    this.eventSectionsForAdd = [];
                    for (let s = 0; s < this.eventSections.length; s++) {
                        let section = this.eventSections[s];
                        if (section.id == eventSectionEnum.System) {
                            continue;
                        }

                        this.eventSectionsForAdd.push(
                            {
                                id: section.id,
                                name: section.name,
                            });
                    }
                    this.calendarEventTypes = [];

                    this.eventSections.forEach((eventSection) => {
                        eventSection.calendarEventTypes.forEach((calendarEventType => {
                            calendarEventType.isEnabledForOrganisation = true;
                            this.calendarEventTypes.push(calendarEventType);
                        }));
                    });

                    this.newCalendarEventType =
                    {
                        organisationId: this.organisation.organisationId,
                        eventSectionId: null,
                        title: '',
                        isSportEvent: false,
                        isPtcEvent: false,
                        isFeeEvent: false,
                        isTransportEvent: false,
                        isOnlineEvent: false
                    };

                }.bind(this), function ()
                {
                        toastService.error();
                }.bind(this));

                this.anyChanges = false;
            }.bind(this);

            this.saveCalendarEventTypes = function ()
            {
                calendarEventTypesService.updateCalendarEventTypes(this.organisation.organisationId, self.calendarEventTypes)
                    .then(function (data)
                    {
                        this.loadCalendarEventTypes();
                    }.bind(this))
                    .catch(function (responseData)
                    {
                        if (responseData && responseData.data && responseData.data.Message) {
                            toastService.saveError(responseData.data.Message);
                        }
                        else {
                            toastService.saveError();
                        }

                    }.bind(this));
            };

            this.createNewCalendarEventType = function ()
            {
                calendarEventTypesService.createCalendarEventType(this.newCalendarEventType)
                    .then(function (data)
                    {
                        toastService.saveSuccess('SB_Calendar_Event_Type_Added');

                        this.loadCalendarEventTypes();
                        this.anyChanges = false;

                        this.newCalendarEventType =
                        {
                            organisationId: this.organisation.organisationId,
                            eventSectionId: null,
                            title: '',
                            isSportEvent: false,
                            isPtcEvent: false,
                            isFeeEvent: false,
                            isTransportEvent: false,
                            isOnlineEvent: false
                        };
                    }.bind(this), function (responseData)
                    {
                        if (responseData && responseData.data && responseData.data.Message) {
                            toastService.saveError(responseData.data.Message);
                        }
                        else {
                            toastService.saveError();
                        }

                    }.bind(this));
            }.bind(this);

            this.changesMade = function ()
            {
                this.anyChanges = true;
            }

            this.cancelAdd = function ()
            {
                this.newCalendarEventType.title = '';
                this.newCalendarEventType.eventSectionId = null,
                this.anyChanges = false;

            }.bind(this);
        }
    ]
});
