angular.module('receptionModule').component('receptionUsersPermissionToWalk', {
    templateUrl: '/Scripts/app/reception/components/reception-users-permission-to-walk.template.html',
    bindings: {
        person: '<'
    },
    controller: class ReceptionUsersPermissionToWalkCtrl {

        receptionService: any;
        person: any;
        student: any;
        isLoading: boolean;

        static $inject = ['receptionService'];

        constructor(receptionService) {
            this.receptionService = receptionService;
        }

        $onChanges(changes) {
            if (changes?.person?.currentValue?.personData?.id) {
                this.isLoading = true;
                this.receptionService.getPersonPermissionToWalk(this.person.personData.id)
                    .then(res => {
                        this.student = res.data;
                        this.isLoading = false;
                    })
            }
        }
    }
})
