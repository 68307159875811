angular.module('shared.services.moduleService', [
    'shared.services.toastService',
])
    .service('moduleService', class ModuleService
    {
        httpCoreApi: any;
        $document: any;
        toastService: any;
        $http: any;

        docStyle: any;
        legacyUrlModuleBase: any;

        static $inject = ['$document', '$http', 'httpCoreApi'];

        constructor($document, $http, httpCoreApi) {
            this.$document = $document;
            this.$http = $http;
            this.httpCoreApi = httpCoreApi;
            this.docStyle = this.$document[0].documentElement.style;
            this.legacyUrlModuleBase = '/webapi/WebModule/';
        }

        getModuleMenuItems(moduleTypeId) {
            return this.$http.get(this.legacyUrlModuleBase + 'GetModuleMenuItems', { params: { moduleTypeId: moduleTypeId } })
                .then(function (response) {
                    return response.data;
                });
        };

        getActiveModuleMenuItemsForRole = function (moduleTypeId) {
            return this.$http.get(this.legacyUrlModuleBase + 'GetActiveModuleMenuItemsForRole', {
                params: {
                    moduleTypeId: moduleTypeId
                }
            })
            .then(function (response) {
                return response.data;
            });
        };

        getModules = function (organisationId) {
            return this.$http.get(this.legacyUrlModuleBase + 'GetModules', { params: { organisationId: organisationId } })
                .then(function (response) {
                    return response.data;
                });
        };

        getIsTransportModuleActive = function () {
            return this.$http.get(this.legacyUrlModuleBase + 'GetIsTransportModuleActive')
                .then(function (response) {
                    return response.data;
                });
        };

        getAllowMinutesOnRouteStop = function () {
            return this.$http.get(this.legacyUrlModuleBase  + 'GetAllowMinutesOnRouteStop')
                .then(function (response) {
                    return response.data;
                });
        };

        saveAllowMinutesOnRouteStop = function (allowRouteStopTimingRequestDto) {
            return this.$http.post(this.legacyUrlModuleBase + 'SaveAllowMinutesOnRouteStop', allowRouteStopTimingRequestDto)
                .then(function (response) {
                    return response.data;
                });
        };

        getCommunicationModule() {

            let settings = {
                controllerType: this.httpCoreApi.controllerTypes.User
            };

            return this.httpCoreApi.get('Module/CommunicationModule', { ...settings,  })
                .then(res => {
                    return res.data
                });
        };
    }
);
