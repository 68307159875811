import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { Language } from './../models/language';
import { LanguageCodeService } from './language-code.service';
import { CurrentLangService } from './current-lang.service';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  cachedLanguage: Subject<Language> = new Subject<Language>();
  cookieName = 'Anonymous_Default_Language';
  systemSettingName = 'Site_Default_Language';
  defaultLanguage = 'en-gb';

  genericLanguageCode$: Observable<string>;

  constructor(
    private translate: TranslateService,
    private user: UserService,
    private cookie: CookieService,
    private languageCodeService: LanguageCodeService,
    private currentLangService: CurrentLangService
  ) { }

  getLanguageCode(): Observable<string> {
    return this.user.getCurrentUser().pipe(
      map(user => {
        let language = this.defaultLanguage;
        if (user) {
          // Logged in
          language = user.userLang;
        }
        else {
          // Not logged in (unexpected case).
          try
          {
              language = JSON.parse(this.cookie.get(this.cookieName));
          }
          catch(ex)
          {
            language = 'en-gb';
          }

        }

        return language;
      }),
      shareReplay(1)
    );
  }

  getCurrentLanguage(): Observable<Language> {
    return forkJoin([
      this.languageCodeService.getLanguageCodesForOrganisation(),
      this.getLanguageCode()
    ]).pipe(
      map(([languages, currentLanguage]: [Language[], string]) => {
        return this.findLanguageByCode(languages, currentLanguage);
      })
    )
  }

  setCurrentLanguage(): Observable<any[]> {
    return this.getCurrentLanguage()
      .pipe(
        first(),
        switchMap(currentLanguage => this.update(currentLanguage, false))
      );
  }

  private findLanguageByCode(languages: Language[], languageCode: string): Language {
    // Selected language
    return languages.find(language => this.formatLanguage(language) === languageCode)
      // Default language
      || languages.find(language => language.isDefault);
  }

  getGenericLanguageCode(): Observable<string> {
    // Return user generic language, such as 'en' as opposed to 'en-gb';
    this.genericLanguageCode$ ??= this.getLanguageCode().pipe(
      map(languageCode => {
        return languageCode.split('-')[0];
      }),
      shareReplay(1)
    );

    return this.genericLanguageCode$;
  }

  update(language: Language, save = true) {
    const formattedLanguage = this.formatLanguage(language);

    const observables: Observable<any>[] = [
        this.applyTranslations(formattedLanguage)
    ];

    this.cachedLanguage.next(language);

    this.currentLangService.setLang(language);

    if (save) {
        // Save in cookie in case of logging out
        this.cookie.set(this.cookieName, JSON.stringify(formattedLanguage));
        // Only save to user settings if logged in
        if (window.EveryBuddy.DisplayInUserLanguage) {
            observables.push(
                this.user.updateSetting(this.systemSettingName, formattedLanguage, { showSuccessToast: false })
            );
        }
    }

    return forkJoin(observables);
  }

  getScriptDirection() {
    return this.cachedLanguage.pipe(map(language => {
      return language.scriptDirection || 'ltr';
    }))
  }

  formatLanguage(locale: Language) {
    return locale.languageCode + '-' + locale.countryCode;
  }

  applyTranslations(countryLanguage: string): Observable<any> {
    return this.translate.use(countryLanguage);
  }
}
