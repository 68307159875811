angular.module('sysadmin.components.languages', [
    'sysadmin.services.languagesService',
    'shared.components.sbOnOffSwitch',
    'shared.directives.sbRadioButton',
]).component('languages', {
    templateUrl: '/Scripts/app/sysadmin/components/languages.template.html',
    bindings: {
        organisation: '<',
    },
    controller: class LanguageCtrl {
        // Dependencies
        languagesService: any;
        //Bindings
        organisation: any;
        // Variables
        isLoading: boolean = false;
        processing: number = 0;
        languages: any[] = [];
        defaultLanguage: number;

        static $inject = ['languagesService'];

        constructor(languagesService) {
            this.languagesService = languagesService;
        }


        $onChanges(changes)
        {
            if (changes.organisation?.currentValue)
            {
                this.getLanguages();
            }
        };

        getLanguages() {
            this.isLoading = true;
            this.languagesService.getLanguages(this.organisation.organisationId)
                .then(data => {
                    this.languages = data.data;
                    if (this.languages) {
                        this.defaultLanguage = this.languages.find(language => {
                            return language.isDefault;
                        }).id;
                    } else {
                        this.languages = [];
                    }
                })
                .catch(() => {

                })
                .finally(() => {
                    this.isLoading = false;
                })
        }

        toggleLanguage(language) {
            if (language.id && typeof language.id === 'number') {
                this.processing ++;
                if (language.isActive) {
                    this.languagesService.addLanguage(this.organisation.organisationId, language.id)
                    .then(() => {
                        // Set default if none already
                        if (!this.defaultLanguage) {
                            this.setDefaultLanguage(language.id);
                        }
                    })
                    .catch(() => {
                        this.getLanguages();
                    })
                    .finally(() => {
                        this.processing --;
                    })
                }
                else {
                    this.languagesService.removeLanguage(this.organisation.organisationId, language.id)
                    .catch(() => {
                        this.getLanguages();
                    })
                    .finally(() => {
                        this.processing --;
                    })
                }
            }
        }

        setDefaultLanguage(languageId: number) {
            const savedDefault = this.languages.find(language => {
                return language.isDefault;
            });
            if (savedDefault?.id === languageId) {
                return;
            }
            const setDefault = () => {
                this.defaultLanguage = languageId;
                this.languages.forEach(item => {
                    item.isDefault = item.id === this.defaultLanguage;
                })
            }
            this.processing ++;
            this.languagesService.setDefaultLanguage(this.organisation.organisationId, languageId)
                .then(() => {
                    setDefault();
                })
                .catch(() => {
                    if (savedDefault.id) {
                        this.defaultLanguage = savedDefault.id;
                    }
                })
                .finally(() => {
                    this.processing --;
                })
        }

        disableToggle(language) {
            return this.processing ||
            (language.isActive && this.defaultLanguage === language.id);
        }
    }
});
