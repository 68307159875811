angular.module('shared.directives.cloakTranslate', [])
    .directive('cloakTranslate', ['$translate', '$rootScope', '$timeout', function ($translate, $rootScope, $timeout) {
        return {
            link: function (scope, element) {

                element.addClass('ng-cloak');

                var listenerLoadingEnd = function () {
                    element.removeClass('ng-cloak');                   
                };
                 
                $rootScope.$on('$translateLoadingEnd', listenerLoadingEnd);

           
            }
        };
    }])