'use strict';

angular.module('balanceAdmin.summary.components.sendChase',
    [
        'balanceAdmin.services.balanceAdminService',
        'balance.constants',
        'person.services.personPickerService',
        'shared.services.simpleAlertService'
    ])
    .component('sendChase',
        {
            bindings: {
                calendarEventId: '<',
                eventName: '<',
                onChaseRequestSent: '&'
            },
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/send-chase.template.html',
            controller: [
                'balanceAdminService',
                'simpleAlertService',
                function (
                    balanceAdminService,
                    simpleAlertService)
                {

                    this.chaseMessage = '';

                    this.send = function () {

                        balanceAdminService.sendPaymentChase(this.calendarEventId, this.chaseMessage)
                            .then(function () {
                                this.onChaseRequestSent()();
                            }.bind(this))
                            .catch(function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert({ message: err });
                            });

                    }.bind(this);

                }
            ]
        });