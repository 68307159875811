'use strict';

angular.module('reception.components.receptionEventsAutoNotify',
    [
    'events.components.autoNotifyEvent',
    ]).component('receptionEventsAutoNotify', {
    templateUrl: '/Scripts/app/reception/components/reception-events-auto-notify.template.html',
    bindings: {
        numberAutoNotified: '<',
        onRefresh: '&'
    },
    controller: [
        function receptionEventsAutoNotifyController()
        {
        }
    ]
});