angular.module('shared.components.sbSideMenuNavPill', [])
    .component('sbSideMenuNavPill', {
        templateUrl: '/Scripts/app/shared/components/sb-side-menu-nav-pill.template.html',
        bindings: {
            menuItems: '<'
        },
        controller: class SbSideMenuNavPillCtrl {

            $state: any;
            $transitions: any;
            menuItems: any;

            static $inject = ['$state', '$transitions'];

            constructor($state, $transitions) {
                this.$state = $state;
                this.$transitions = $transitions;
            }

            $onInit() {
                if (!this.menuItems || !this.menuItems.items) {
                    return;
                }

                // No state set, or no state match found, navigate to menu item marked as active.
                angular.forEach(this.menuItems.items, item => {
                    if (item.activeMenu) {
                        this.$state.go(item.route, item.params);
                        return;
                    };
                });

                // Selected element needs to check routing, in case redirected to a page, rather than rely on click event
                this.$transitions.onSuccess({}, $transition => {
                    if (this.menuItems && this.menuItems.items) {
                        // Reset state of all items
                        angular.forEach(this.menuItems.items, item => {
                            item.activeMenu = false;
                        });

                        // Set the active pill
                        const activeItem = this.menuItems.items.filter(item => {
                            return item.route === $transition._targetState._identifier ||
                                // Check relative links
                                $transition._targetState._definition.parent.name + item.route === $transition._targetState._identifier;
                        })[0]
                        if (activeItem) {
                            activeItem.activeMenu = true;
                        }
                    }
                });
            };

            $onChanges(changes) {
                if (changes.menuItems?.currentValue) {
                    this.menuItems.items.forEach(item => {
                        /* There are edge cases where this might not work, but it's a quick fix before
                        migrating to Angular 12 */
                        item.activeMenu = this.$state.current.name.indexOf(item.route) > -1;
                    });
                }
            }
        }
    });
