'use strict';

angular.module('transport.dashboard.transportDashboard',
    [])
    .component('transportDashboard',
        {
            bindings: {
                moduleConfiguration: '<',
                moduleConfigurationMenu: '<',
            },
            controllerAs: 'ctrl',
            templateUrl: '/Scripts/app/transport/dashboard/transport-dashboard.template.html',
            controller: [
                '$scope',
                '$filter',
                '$state',
                'iconService',
                'simpleAlertService',
                function ($scope, $filter, $state, iconService, simpleAlertService) {


                    var filterAndAddIconMenu = function (moduleConfigurationMenu) {

                        if (!moduleConfigurationMenu) { return; };

                        angular.forEach(moduleConfigurationMenu, function (value) {
                            if (value.icon) {
                                value.icon = iconService.getIcon(value.icon)
                            };
                        });

                        return $filter('orderBy')(moduleConfigurationMenu, 'sortOrder');

                    };

                    this.$onInit = function () {
                        $scope.menuItems = [];

                        if (!this.moduleConfiguration || !this.moduleConfiguration.transportModule || !this.moduleConfiguration.transportModule.isActive){
                            simpleAlertService.errorAlert('SB_Not_Active');
                            return;
                        };

                        if (this.moduleConfiguration.transportModule.isForInfoOnly && this.moduleConfiguration.transportModule.upsell) {
                            $scope.upsellContent = this.moduleConfiguration.transportModule.upsell;
                            return;
                        };

                        $scope.moduleConfiguration = this.moduleConfiguration.transportModule;

                        if (!this.moduleConfigurationMenu || this.moduleConfigurationMenu.length == 0) {
                            simpleAlertService.errorAlert('SB_Feature_Only_Available_With_Elevated_Permissions');
                            return;
                        };

                        $scope.menuItems = filterAndAddIconMenu(this.moduleConfigurationMenu);

                        if ($scope.menuItems) {

                            var selectedRoute;

                            angular.forEach($scope.menuItems, function (value) {
                                if (value.isSelectedOnLoad && value.uisref && value.isSelectedOnLoad === true) {
                                    selectedRoute = value.uisref;
                                }
                            });

                            //if not set then just pick the first one
                            if (!selectedRoute) { selectedRoute = $scope.menuItems[0].uisref };

                            $state.go(selectedRoute);
                        };

                    }.bind(this);

                }
            ]
        });
