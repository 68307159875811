angular.module('tuitionFeesModule').constant('payerTypesEnum',
{
    Any: {
        id: 0,
        name: 'SB_Any',
        isOption: false
    },
    Family: {
        id: 1,
        name: 'SB_Parent',
        isOption: true
    },
    Corporate: {
        id: 2,
        name: 'SB_Corporate',
        isOption: true
    }
});