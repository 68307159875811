'use strict';

angular.module('transport.configuration.transportTimeslots', [
    'transport.services.transportTimeSlotsService',
    'transport.services.transportDirectionsService',
    'shared.filters.decoded',
    'shared.components.sbOnOffSwitch',
    'shared.components.sbSubmitButton',
    'angularjs-dropdown-multiselect',
]).component('transportTimeslots', {
    templateUrl: '/Scripts/app/transport/configuration/timeSlots/transport-timeslots.template.html',
    bindings: {
    },
    controller: [
        '$uibModal',
        'transportTimeSlotsService',
        'simpleAlertService',
        function transportTimeslotsController($uibModal,
                                              transportTimeSlotsService,
                                              simpleAlertService)
        {
            this.$onInit = function ()
            {
                this.transportTimeSlots = null;

                this.loadTransportTimeSlots();

            }.bind(this);

            //Table Sorting
            this.sortType = 'startTimeFormatted';
            this.sortReverse = false;

            this.loadTransportTimeSlots = function ()
            {
                transportTimeSlotsService.getTransportTimeSlots().then(function (data)
                {
                    this.transportTimeSlots = data;

                }.bind(this), function (error)
                {
                    simpleAlertService.errorAlert(error);

                }.bind(this));

            }.bind(this);

            this.addTimeSlot = function ()
            {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimeslotAdd',
                    size: 'lg',
                    backdrop: 'static'
                });

                modalInstance.result.then(
                    () => this.loadTransportTimeSlots(),
                    () => {}
                );
            };

            this.editTimeSlot = function (timeSlot)
            {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimeslotEdit',
                    size: 'lg',
                    backdrop: 'static',
                    resolve:
                    {
                        params: function ()
                        {
                            return {
                                timeSlot: timeSlot
                            }
                        }
                    }
                });

                modalInstance.result.then(
                    () => this.loadTransportTimeSlots(),
                    () => {}
                );
            };
        }
    ]
});
