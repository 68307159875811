'use strict';

// AngularJs profile popup controller. Only used as hook to trigger Angular profile up - see
// SubsBuddy.Web\Scripts\Angular12\schoolsbuddy\src\app\core\components\profile-popup\profile-popup.component.ts
// Angular profile immediately closes this popup.
angular.module('profile.controllers.profilePopupController', [])
    .controller('profilePopupController', ['$scope', '$uibModalInstance',
        function ($scope, $uibModalInstance) {
            $scope.closePopup = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }
    ]);