import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { LocalStorageService } from '@sb-shared/services/local-storage.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth/services/auth.service';
import { PaymentsService } from '@sb-shared/services/payments.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { environment } from 'src/environments/environment';
import { Organisation } from '@sb-shared/models/organisation';
import { forkJoin, Subscription } from 'rxjs';
import { UserRegistrationService } from '@sb-shared/services/user-registration.service';
import { MenuService } from '@sb-shared/services/menu.service';
import { LocaleService } from '@sb-shared/services/locale.service';
import { ComponentBase } from '@sb-shared/components/component-base/component-base';

@Component({
  selector: 'sb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ComponentBase implements OnInit, OnDestroy {
  static selector = 'appRoot';
  title = 'schoolsbuddy';
  organisationLoaded = false;
  isLogged: boolean;
  currentOrganisation: Organisation;
  private subscriptions: Subscription = new Subscription();


  constructor(
    private router: Router,
    private auth: AuthService,
    private storage: LocalStorageService,
    private paymentsService: PaymentsService,
    private organisationService: OrganisationService,
    @Inject(DOCUMENT) private document: Document,
    private userRegistrationService: UserRegistrationService,
    // though it isn't used in this file, MenuService needs to be injected early in the application lifecyle to ensure its
    // subscription to the router events doesn't come too late and potentially miss events in the base routing module, e.g. 404
    private menuService: MenuService,
    private localeService: LocaleService
  ) { super() }

  loading = true;

  ngOnInit() {
    this.paymentsService.loadPaymentProviderExternalScripts();

    this.auth.init().then(() => {
      if (this.auth.isLoggedIn()) {
        this.subscriptions.add(
          forkJoin([
            // Getting the org does some stuff that's needed for the angularjs components including some setting of variables
            // on the window. So we need to ensure this is called before the app is loaded proper.
            this.organisationService.getCurrentOrganisation(),
            this.userRegistrationService.verifyRegistrationIncomplete(),
            this.localeService.setCurrentLanguage()
          ])
            .pipe(
              takeUntil(this.destroyed$)
            )
            .subscribe({
              next: ([organisation, isRegistrationIncomplete, _]: [Organisation, boolean, any[]]) => {
                if (isRegistrationIncomplete) {
                  this.userRegistrationService.redirectToCompleteRegistration();
                }
                else {
                  this.addOrgStyle(organisation);
                  this.router.initialNavigation();
                }
              },
              error: (err) => {
                // Could be attempted access to unauthorised organisation, force logout.
                console.error('Security issue on login, will force logout.', err)
                this.auth.logout();
              },
              complete: () => {
                this.loading = false
              }
            }));
      }
    })

    // Store route params for global access by AngularJS
    this.router.events
      .pipe(
        filter(e => (e instanceof ActivationStart)),
        map(e => e instanceof ActivationStart ? e.snapshot.params : {})
      )
      .subscribe(params => {
        const ng1StateParamsStorageName = 'ng1StateParams';

        // Give precedence to current params over those cached in local storage
        const stateParams = { ...this.storage.get(ng1StateParamsStorageName), ...params };
        this.storage.set(ng1StateParamsStorageName, stateParams);
        // delete window.EveryBuddy.ng1StateParams;
      })
  }

  addOrgStyle(organisation: Organisation) {
    const orgCssUrl = `${environment.blobBase}orgcss/org-${organisation.id}.css`;

    const style = this.document.createElement('link');
    style.rel = 'stylesheet';
    style.href = orgCssUrl;

    this.document.head.appendChild(style);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }
}
