angular.module('cca.components.ccaGenerateAdditionalEventsGroupList',
    [
        'pascalprecht.translate',
        'ui.bootstrap',
        'cca.constants',
        'cca.components.ccaAddAdditionalEventsDialog',
        'shared.components.sbIcon',
        'shared.services.simpleAlertService'
    ])
    .component('ccaGenerateAdditionalEventsGroupList',
        {
            templateUrl: '/Scripts/app/cca/components/cca-generate-additional-events-group-list.template.html',
            bindings: {
                signUp: '<',
                groups: '<',
                pageSize: '<',
                initialPage: '<',
                sortType: '<',
                sortReverse: '<',
                filter: '<',
                showPagination: '<',
                loading: '<',
                onPageChanged: '&'
            },
            controller: ['$filter', '$uibModal', 'dateTextService',
                function ($filter, $uibModal, dateTextService)
                {
                    this.currentPage = null;
                    this.signUpGroups = [];
                    this.anyGroupsSelected = false;
                    this.selectedGroups = [];

                    this.$onChanges = function (changes)
                    {
                        if (changes.initialPage)
                        {
                            this.currentPage = this.initialPage;
                        }

                        if (changes.groups && changes.groups.currentValue)
                        {
                            var groups = changes.groups.currentValue;
                            groups.filter(function (group)
                            {
                                var signUpGroup =
                                {
                                        id: group.id,
                                        ccaSignUpId: group.ccaSignUpId,
                                        title: group.title,
                                        description: group.description,
                                        statusId: group.statusId,
                                        organiserName: group.organiserName,
                                        genderTitle: group.genderTitle,
                                        availabilitySummary: group.availabilitySummary,
                                        dayofWeek: group.dayofWeek,
                                        linkedGroups: [],
                                        ccaStartHour: group.ccaStartHour,
                                        ccaStartMin: group.ccaStartMin,
                                        ccaEndHour: group.ccaEndHour,
                                        ccaEndMin: group.ccaEndMin,
                                        location: group.location,
                                        isSelected: false
                                };

                                if (group.linkedGroups !== 'undefined')
                                {
                                    for (var i = 0; i < group.linkedGroups.length; i++)
                                    {
                                        var linkedGroup = group.linkedGroups[i];
                                        var linkedGroupFullyLoaded =
                                            {
                                                id: linkedGroup.id,
                                                ccaSignUpId: group.ccaSignUpId,
                                                title: group.title,
                                                description: group.description,
                                                statusId: group.statusId,
                                                dayofWeek: linkedGroup.dayofWeek,
                                                ccaStartHour: linkedGroup.ccaStartHour,
                                                ccaStartMin: linkedGroup.ccaStartMin,
                                                ccaEndHour: linkedGroup.ccaEndHour,
                                                ccaEndMin: linkedGroup.ccaEndMin,
                                                location: linkedGroup.location
                                        };

                                        signUpGroup.linkedGroups.push(linkedGroupFullyLoaded);
                                    };
                                }

                                this.signUpGroups.push(signUpGroup);

                            }.bind(this));
                        }
                    }.bind(this);

                    this.maxNumberOfPages = window.EveryBuddy.PaginationMaxNumberOfPages;

                    this.dateText = function (dayNumber)
                    {
                        return dateTextService.getShortDayText(dayNumber);
                    };

                    this.toggleSelectAllGroups = function (selectAll)
                    {
                        if (selectAll)
                        {
                            this.signUpGroups.filter(function (group)
                            {
                                group.isSelected =  group.statusId != 7; // Only select a group if its status is not "Extending".
                            });

                            this.groupSelectionChanged();
                            return;
                        }

                        this.signUpGroups.filter(function (group)
                        {
                            group.isSelected = false;
                        });

                        this.groupSelectionChanged();
                    }.bind(this);

                    this.nextClicked = function ()
                    {
                        this.selectedGroups = this.signUpGroups.filter(function (group)
                        {
                            return group.isSelected;
                        }.bind(this));

                        if (this.selectedGroups.length == 0)
                        {
                            return;
                        }

                        this.selectedGroups = $filter('filter')(this.selectedGroups, this.filter);

                        var addAdditionalEventsDialog = $uibModal.open({
                            component: 'ccaAddAdditionalEventsDialog',
                            size: 'lg',
                            backdrop: 'static', // prevent modal from closing on backdrop click
                            resolve: {
                                selectedGroups: function () { return this.selectedGroups; }.bind(this)
                            }
                        });

                        addAdditionalEventsDialog.result
                            .then(function (response)
                            {
                                if (response.status == 'confirmed')
                                {
                                    this.selectedGroups.filter(function (group)
                                    {
                                        group.isSelected = false;
                                        group.statusId = 7; // "Extending"
                                    }.bind(this));

                                    this.groupSelectionChanged();
                                }

                            }.bind(this))
                            .catch(function ()
                            {
                            });

                    }.bind(this);

                    this.groupSelectionChanged = function ()
                    {
                        this.selectedGroups = this.signUpGroups.filter(function (group)
                        {
                            return group.isSelected;
                        }.bind(this));

                        this.anyGroupsSelected = this.selectedGroups.length > 0;
                    }.bind(this);
                }
            ]
        });