'use strict';

angular.module('shared.components.sbProfileInfo', [])
    .component('sbProfileInfo',
        {
            bindings: {
                personId: '<',
                initialProfileRoute: '@',
                onClose: '&',
            },
            templateUrl: '/Scripts/app/shared/components/sb-profile-info.template.html',
            controller: class SbProfileInfoComponent {
                $uibModal: any;
                $scope: any;
                personId: number;
                initialProfileRoute: string;
                onClose: any;

                static $inject = ['$uibModal', '$scope'];

                constructor($uibModal, $scope) {
                    this.$uibModal = $uibModal;
                    this.$scope = $scope;
                }

                profilePopup() {
                    if (this.personId) {

                        //copied from profliePopupButton

                        this.$scope.subPersonId = this.personId;
                        this.$scope.initialRoute = this.initialProfileRoute;

                        // pull a full instance of the profile screen into a popup!
                        var modalInstance = this.$uibModal
                            .open({
                                animation: true,
                                templateUrl: '/Scripts/app/profile/controllers/profile-popup.template.html',
                                controller: 'profilePopupController',
                                size: 'lg',
                                scope: this.$scope
                            });

                        modalInstance.result.then(function () {
                            this.handlePopupClosed();
                        }.bind(this), function () {
                            this.handlePopupClosed();
                        }.bind(this));
                    }
                }

                handlePopupClosed() {
                    if (typeof this.onClose() === 'function') {
                        this.onClose()(this.personId);
                    }
                }
            }
        }
    );
