'use strict';

angular.module('team.components.ccaTeamBulkCreatorBySubject',
    [
        'angular.filter',
        'subjectClass.services.subjectClassService',
        'ccaSignUp.services.signUpRepository',
        'shared.directives.sbCheckBox',
        'shared.components.sbSubmitButton',
        'team.directives.ccaTeamBulkCreatedList',
        'subjectClass.components.subjectClassMemberListPopupComponent'
    ])
    .component('ccaTeamBulkCreatorBySubject',
        {
            bindings: {
                ccaSignUp: '=',
                ccaSignUpType: '=',
                sports: '=',
                dismissHandler: '&',
                generateFn: '<' // put generate function in here
            },
            templateUrl: '/Scripts/app/teams/components/cca-team-bulk-creator-by-subject.template.html',
            controller: [
                '$uibModal',
                '$filter',
                '$rootScope',
                'subjectClassService',
                'signUpRepository',
                'groupByFilter',
                '$timeout',
                function ($uibModal, $filter, $rootScope, subjectClassService, signUpRepository, groupByFilter, $timeout) {

                    var pleaseWaitModalInstance = null;

                    this.resetSubmitButtons = function () {

                        $timeout(function () {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.selectedCount = 0;
                    this.createdGroups = [];
                    this.subjects = {};
                    this.staff = {};
                    this.isSubmitted = false;
                    this.searchFilters = {
                        groupDropdownSelected: [],
                        yearDropdownSelected: [],
                        teacherNameDropdownSelected: [],
                        text: ""
                    };
                    this.progress = { current: 0, target: 0 };

                    this.$onChanges = function (changes) {
                        if (changes.generateFn) {
                            this.generateFn.fn = this.generateGroups;
                            this.generateFn.disabled = true;
                            this.generateFn.generated = false;
                        }
                    }

                    this.teacherTooltip = function () {
                        if (!this.ccaSignUpType || !this.ccaSignUpType.title)
                        {
                            return '';
                        }
                        return $filter('translate')('SB_Bulk_Teacher_Title_Tooltip', { ccaSignupTitle: this.ccaSignUpType.title });
                    }

                    this.initSubjectClasses = function () {
                        subjectClassService
                            .getAllForLookup(true)
                            .then(function (data) {
                                this.subjectClasses = data;
                                for (var i = this.subjectClasses.length; i--;) {
                                    if (!this.subjects.hasOwnProperty(this.subjectClasses[i].id)) {
                                        this.subjects[this.subjectClasses[i].id] =
                                            this.subjectClasses[i].title + ' ' +
                                            this.subjectClasses[i].schoolYearGroupName + ' ' +
                                            this.subjectClasses[i].subjectName;
                                    }

                                    if (!this.staff.hasOwnProperty(this.subjectClasses[i].teacherId)) {
                                        this.staff[this.subjectClasses[i].teacherId] =
                                        {
                                            organiserId: this.subjectClasses[i].teacherId,
                                            name: this.subjectClasses[i].teacherName,
                                            onlineMeetingId: this.subjectClasses[i].onlineMeetingId
                                        };
                                    }
                                }

                                this.setUpFilters();
                            }.bind(this));
                    }.bind(this);

                    this.initSubjectClasses();

                    this.setUpFilters = function () {

                        this.subjectNames = [];
                        this.yearNames = [];
                        this.teacherNames = [];

                        var uniqueSubjectClasses = $filter('unique')(this.subjectClasses, 'subjectName');
                        var uniqueYearNames = $filter('unique')(this.subjectClasses, 'schoolYearGroupName');
                        var uniqueTeacherNames = $filter('unique')(this.subjectClasses, 'teacherName');

                        uniqueSubjectClasses.forEach(function (item) {
                            this.subjectNames.push({ subjectName: item.subjectName });
                        }.bind(this));

                        uniqueYearNames.forEach(function (item) {
                            this.yearNames.push({ schoolYearGroupName: item.schoolYearGroupName });
                        }.bind(this));

                        uniqueTeacherNames.forEach(function (item) {
                            this.teacherNames.push({ teacherName: item.teacherName });
                        }.bind(this));

                        $filter('orderBy')(this.subjectNames, 'subjectName');
                        $filter('orderBy')(this.yearNames, 'schoolYearGroupName');
                        $filter('orderBy')(this.teacherNames, 'teacherName');

                    }.bind(this);

                    this.search = function (item) {

                        var matchedByGroup = true;
                        var matchedByYear = true;
                        var matchedByTeacherName = true;
                        var textMatch = (this.searchFilters.text === '') ||
                            (item.teacherName && item.teacherName.toLowerCase().indexOf(this.searchFilters.text.toLowerCase()) > -1) ||
                            (item.title && item.title.toLowerCase().indexOf(this.searchFilters.text.toLowerCase()) > -1) ||
                            (item.schoolYearGroupName && item.schoolYearGroupName.toLowerCase().indexOf(this.searchFilters.text.toLowerCase()) > -1) ||
                            (item.subjectName && item.subjectName.toLowerCase().indexOf(this.searchFilters.text.toLowerCase()) > -1);

                        if (this.searchFilters.groupDropdownSelected && this.searchFilters.groupDropdownSelected.length > 0) {
                            matchedByGroup = ($filter('filter')(this.searchFilters.groupDropdownSelected, { id: item.subjectName }, true)) != 0;
                        };

                        if (this.searchFilters.yearDropdownSelected && this.searchFilters.yearDropdownSelected.length > 0) {
                            matchedByYear = ($filter('filter')(this.searchFilters.yearDropdownSelected, { id: item.schoolYearGroupName }, true)) != 0;
                        };

                        if (this.searchFilters.teacherNameDropdownSelected && this.searchFilters.teacherNameDropdownSelected.length > 0) {
                            matchedByTeacherName = ($filter('filter')(this.searchFilters.teacherNameDropdownSelected, { id: item.teacherName }, true)) != 0;
                        };

                        return textMatch && matchedByGroup && matchedByYear && matchedByTeacherName;

                    }.bind(this);

                    this.resetForm = function () {

                        //set back to 0 and un-select all checkboxes
                        for (var i = this.subjectClasses.length; i--;) {
                            this.subjectClasses[i].selected = false;
                        }
                        for (var i = this.createdGroups.length; i--;) {
                            this.createdGroups[i].selected = false;
                        }

                        this.selectedCount = 0;
                        this.generateFn.disabled = true;

                    }.bind(this);

                    var getGroupCreationHandlerClosure = function (group) {
                        return function (returnedGroup) {
                            group.id = returnedGroup.id;
                            this.createdGroups.push(group);

                            this.progress.current++;

                            if (this.progress.current === this.progress.target) {
                                this.generateFn.generated = true;
                                pleaseWaitModalInstance.close();
                                this.initSubjectClasses();
                                $("html,body").animate({ scrollTop: 0 }, "slow");
                            }
                        }.bind(this);
                    }.bind(this);

                    this.resetGroups = function () {
                        this.createdGroups = [];
                    }.bind(this);


                    this.updateSelectionCount = function (isSelected) {
                        var increment = isSelected ? 1 : -1;
                        this.selectedCount += increment;
                        this.generateFn.disabled = this.selectedCount == 0;

                    }.bind(this);

                    this.updateSelectionCountWithDigest = function (model) {
                        var currentValue = model.selected;

                        // Force a digest cycle so that the angular check box is updated
                        // via 'label for' mark-up.
                        $timeout(function () {
                            // Make sure the click actually selected it (e.g. click and drag won't select)
                            if (model.selected != currentValue) {
                                this.updateSelectionCount(model.selected);
                                $rootScope.$apply();
                            }
                        }.bind(this), 0);
                    }.bind(this);

                    this.generateGroups = function () {

                        var groupedData = groupByFilter(this.subjectClasses, 'teacherId');

                        this.progress.current = 0;
                        this.progress.target = 0;

                        // try and find the parents evening sportid
                        var sportId = 0;

                        if (this.sports && this.sports.length > 0) {
                            for (var i = this.sports.length; i--;) {
                                if (this.sports[i].type === 3) {
                                    sportId = this.sports[i].id;
                                    break;
                                }
                            }

                            if (sportId === 0) {
                                // shouldn't happen if the org has been correctly set up
                                sportId = this.sports[0].id;
                            }
                        }

                        for (var teacherId in groupedData) {
                            if (groupedData.hasOwnProperty(teacherId)) {
                                var newGroup = {
                                    ccaTeamClubs: [],
                                    ccaTeamTeams: [],
                                    ccaTeamSubjectClasses: [],
                                    ccaSignUpId: this.ccaSignUp.ccaSignUpId,
                                    genderTypeId: 3,
                                    allowJoinRequests: false,
                                    title: '',
                                    sportId: sportId,
                                    organiserPersonId: teacherId
                                };

                                var nothingSelected = true;
                                var subjectNames = '';
                                var subjectNamesUsed = [];

                                for (var i = groupedData[teacherId].length; i--;)
                                {
                                    if (groupedData[teacherId][i].selected)
                                    {
                                        newGroup.ccaTeamSubjectClasses.push({
                                            requirementGroupId: 0,
                                            id: groupedData[teacherId][i].id,
                                            title: groupedData[teacherId][i].title
                                        });

                                        var subjectName = groupedData[teacherId][i].subjectName;

                                        var existingSubjectNames = subjectNamesUsed.filter(function (existingSubjectName)
                                        {
                                            return subjectName == existingSubjectName;
                                        });

                                        if (!!subjectName && existingSubjectNames.length == 0)
                                        {
                                            subjectNames += subjectName + ', ';
                                            subjectNamesUsed.push(subjectName);
                                        }

                                        nothingSelected = false;
                                    }
                                }

                                if (!nothingSelected)
                                {
                                    subjectNames = subjectNames.substring(0, subjectNames.length - 2);
                                    newGroup.title = this.staff[teacherId].name + ' ' + subjectNames + ' ' + this.ccaSignUpType.title;

                                    this.progress.target++;
                                    signUpRepository.postAndFetchGroup(newGroup)
                                        .then(getGroupCreationHandlerClosure(newGroup));
                                }
                            }
                        }

                        if (this.progress.target == 0) {
                            return false;
                        }

                        pleaseWaitModalInstance = $uibModal.open({
                            animation: true,
                            backdrop: 'static', // prevent modal from closing on backdrop click
                            component: 'progressPopupComponent',
                            size: 'sm',
                            resolve: {
                                message: function () { return 'SB_Generating_teachers'; },
                                progress: function () { return this.progress; }.bind(this)
                            }
                        });

                        return true;
                    }.bind(this);

                    this.showMembers = function (subjectClassId, subjectClassTitle) {
                        $uibModal.open({
                            animation: true,
                            component: 'subjectClassMemberListPopupComponent',
                            size: 'md',
                            resolve: {
                                subjectClassId: function () { return subjectClassId; },
                                subjectClassTitle: function () { return subjectClassTitle; }
                            }
                        });
                    };

                    this.toggleAllSubjectClasses = function (isSelected) {

                        this.resetForm();

                        if (!isSelected) {
                            this.resetSubmitButtons();
                            return;
                        }; //exit out if un-selecting as reset called

                        var itemsToSelect = $filter('filter')(this.subjectClasses, this.search);

                        for (var i = itemsToSelect.length; i--;) {

                            var classItem = itemsToSelect[i];
                            if (classItem.selected != isSelected)
                            {
                                classItem.selected = isSelected;
                            };

                            this.updateSelectionCount(classItem.selected);
                        };

                        this.resetSubmitButtons();

                    }.bind(this);
                }
            ]
        });
