<button class="border-{{summary.class || 'light'}} text-{{summary.class}}"
        [ngClass]="{'border-thick' : isSelected}"
        role="button"
        (click)="click()">
        <div class="space-between align-center">
            <div>
                <h3 [innerHTML]="summary.name | translate"></h3>
                <h2 *ngIf="summary.count !== undefined || summary.total">
                    <span *ngIf="summary.count !== undefined"
                          [innerHTML]="summary.count"></span>
                    <span *ngIf="summary.count && summary.total"
                          class="p-15"> / </span>
                    <span *ngIf="summary.total"
                            [innerHTML]="summary.total"
                            class="p-15"></span>
                </h2>
            </div>
            <sb-icon *ngIf="summary.iconName"
                     [name]="summary.iconName"
                     overrideStyle="regular"></sb-icon>
        </div>
        <div class="summary__progress"
            *ngIf="summary.total">
            <ngb-progressbar [type]="summary.class"
                             [value]="(summary.count / summary.total) * 100"
                             height="10px"></ngb-progressbar>
        </div>
</button>