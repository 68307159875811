'use strict';

angular.module('sysadmin.components.sysAdminForms', [])
    .component('sysAdminForms', {
        templateUrl: '/Scripts/app/sysadmin/components/sysadminforms.html',
        controller: [
        '$scope', '$state', '$filter', '$http', '$window', '$timeout', 'sysAdminService', 'organisationService',
        function ($scope, $state, $filter, $http, $window, $timeout, sysAdminService, organisationService) {

            $scope.lookupTypes = [];
            $scope.usageItems = [
                { id: 1, name: 'Registration' },
                { id: 2, name: 'Person' },
                { id: 4, name: 'Club' },
                { id: 8, name: 'Team' },
                { id: 16, name: 'Event' },
                { id: 32, name: 'Organisation' },
                { id: 64, name: 'Youth Registration' },
                { id: 128, name: 'Container' }
        ];

            $scope.gridOptions = {
                onRegisterApi: function( gridApi ) {
                    $scope.gridApi = gridApi;
                },
                enableCellEditOnFocus : true,
                enableColumnMenus : false,
                enableFiltering : false,
                enableSorting: false,
                columnDefs : [
                    { name: 'label', displayName: 'Label', enableCellEdit: true, width: 200 },
                    {
                        name: 'fieldType',
                        displayName: 'Field Type',
                        cellFilter: 'mapFieldType',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: 'ui-grid/dropdownEditor',
                        editDropdownValueLabel: 'name',
                        editDropdownOptionsArray: [
                            { id: 1, name: 'Text' },
                            { id: 2, name: 'Number' },
                            { id: 3, name: 'Date' },
                            { id: 4, name: 'Boolean' },
                            { id: 5, name: 'Email' },
                            { id: 6, name: 'Notes' },
                            { id: 7, name: 'Lookup Data' }
                        ]
                    },
                    { name: 'displayOrder', displayName: 'Order', enableCellEdit: true, type: 'number' },
                    {
                        name: 'isMandatory',
                        displayName: 'Required',
                        cellFilter: 'mapMandatory',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: 'ui-grid/dropdownEditor',
                        editDropdownValueLabel: 'name',
                        editDropdownOptionsArray: [
                            { id: 1, name: 'Optional' },
                            { id: 2, name: 'Required' }
                        ]
                    },
                    {
                        name: 'isLockedOnEntry',
                        displayName: 'Locked',
                        cellFilter: 'mapLocked',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: 'ui-grid/dropdownEditor',
                        editDropdownValueLabel: 'name',
                        editDropdownOptionsArray: [
                            { id: 1, name: 'Locked' },
                            { id: 2, name: 'Updatable' }
                        ]
                    },
                    {
                        name: 'fieldReferenceId',
                        displayName: 'Look Up',
                        cellFilter: 'mapFieldReference:this',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: 'ui-grid/dropdownEditor',
                        editDropdownIdLabel: 'organisationReferenceTypeId',
                        editDropdownValueLabel: 'title'
                    },
                    { name: 'toolTip', displayName: 'Tooltip', enableCellEdit: true, width: 200 }
                ]
            };

            $scope.sortType = 'title';
            $scope.sortReverse = false;

            sysAdminService.getForms().then(function(data) {
                    $scope.forms = data;
                },
                function(error) {
                    return error;
                });

            organisationService.getOrganisations().then(function(data) {
                    $scope.organisations = data;
                },
                function(error) {
                    return error;
                });

            $scope.addForm = function() {
                $scope.selectForm = {
                    organisationId: 0,
                    title: '',
                    description: '',
                    iconName: '',
                    maxOccurences: 0,
                    isVisible: true,
                    isDeleted: false,
                    allowedUsageFlag: 0
                };
            };
            $scope.showForm = function(aForm) {
                $scope.selectForm = null;
                sysAdminService.getForm(aForm.organisationFormId)
                    .then(function (data) {

                        $scope.selectForm = data.form;
                        $scope.gridOptions.data = data.fields;
                        $scope.lookupTypes = data.lookupTypes;
                        $scope.gridOptions.columnDefs[5].editDropdownOptionsArray = $scope.lookupTypes;

                        if (data.fields.length === 0) {
                            $scope.gridOptions.data.push(
                                {
                                    label: '',
                                    toolTip: '',
                                    fieldReferenceId: 0,
                                    fieldType: 1,
                                    displayOrder: 1,
                                    isMandatory: 1,
                                    validationPattern: '',
                                    valueReferenceId: 0,
                                    valueReferenceDisplayField: 0,
                                    isLockedOnEntry: 2
                                });
                        }


                        //$scope.apply;
                        //$scope.gridApi.core.handleWindowResize();
                    },
                    function(error) {
                        return error;
                    });

            };
            $scope.updateForm = function() {
                sysAdminService.postFormDefinition($scope.selectForm)
                    .then(function(formProcessed) {
                            $scope.selectForm = formProcessed.form;
                            $scope.forms = formProcessed.forms;
                        }
                    );
            };

            $scope.saveFields = function () {
                var data = { organisationFormId: $scope.selectForm.organisationFormId, fields: $scope.gridOptions.data }
                sysAdminService.postFormFields(data)
                    .then(function (fieldsProcessed) {
                        $scope.selectForm = fieldsProcessed.form;
                        $scope.gridOptions.data = fieldsProcessed.fields;
                    }
                    );
            };

            $scope.isAllowed = function(usageFlag) {
                if ($scope.selectForm) {

                    var usage = parseInt(usageFlag, 10);

                    var andresult = (parseInt($scope.selectForm.allowedUsageFlag, 10) & usage);

                    return andresult === usage;
                }

                return false;
            };

            $scope.checkIsAllowed = function(usageFlag) {
                if ($scope.isAllowed(usageFlag)) {
                    $scope.selectForm.allowedUsageFlag -= usageFlag;
                } else {
                    $scope.selectForm.allowedUsageFlag += usageFlag;
                }
            };

            $scope.addField = function() {
                $scope.gridOptions.data.push(
                {
                    label: '',
                    toolTip: '',
                    fieldReferenceId: 0,
                    fieldType: 1,
                    displayOrder: $scope.gridOptions.data.length+1,
                    isMandatory: 1,
                    validationPattern: '',
                    valueReferenceId: 0,
                    valueReferenceDisplayField: 0,
                    isLockedOnEntry: 2
                });
            };
        }
    ]})
    .filter('mapFieldType',
        function() {
            var hash = {
                1: 'Text',
                2: 'Number',
                3: 'Date',
                4: 'Boolean',
                5: 'Email',
                6: 'Notes',
                7: 'Lookup Data'
        };

            return function(input) {
                if (!input) {
                    return '';
                } else {
                    return hash[input];
                }
            };
        })
    .filter('mapMandatory',
        function() {
            var hash = {
                1: 'Optional',
                2: 'Required'
            };

            return function(input) {
                if (!input) {
                    return '';
                } else {
                    return hash[input];
                }
            };
        })
    .filter('mapFieldReference',
        function () {
            return function (input, context) {
                var fieldLevel = (context.editDropdownOptionsArray === undefined) ? context.col.colDef : context;
                var map = fieldLevel.editDropdownOptionsArray;
                var initial = context.row.entity[context.col.field];
                if (typeof map !== 'undefined') {
                    var idField = fieldLevel.editDropdownIdLabel;
                    var valueField = fieldLevel.editDropdownValueLabel;
                    for (var i = 0; i < map.length; i++) {
                        if (map[i][idField] === input) {
                            return map[i][valueField];
                        }
                    }
                }
                else if (initial) {
                    return initial;
                }
                return '';
            };
        })
    .filter('mapLocked',
        function() {
            var hash = {
                1: 'Locked',
                2: 'Updatable'
            };

            return function(input) {
                if (!input) {
                    return '';
                } else {
                    return hash[input];
                }
            };
        });