'use strict';

angular.module('diary.transport.diaryTransportChangeRequest',
    [
        'shared.components.sbTextarea',
        'shared.components.sbModalHeader',
        'shared.components.sbSubmitButton',
        'shared.services.portalChangeRequestService',
        'shared.services.simpleAlertService',
        'shared.services.deviceService',
        'transport.services.changeRequestService',
    ])
    .component('diaryTransportChangeRequest',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/diary/transport/diary-transport-change-request.template.html',
            controller: [
                '$timeout',
                'portalChangeRequestService',
                'simpleAlertService',
                'changeRequestService',
                'deviceService',
                function ($timeout, portalChangeRequestService, simpleAlertService, changeRequestService, deviceService)
                {
                    this.userControl = {};
                    this.isSubmitted = false;
                    this.currentPage = "ChangeRequest";

                    var resetSubmitButtons = function () {
                        $timeout(function () {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    var closeModal = function (updateDiaryView) {
                        this.modalInstance.close(updateDiaryView);
                    }.bind(this);

                    this.useRouteStopDateTime = false;
                    this.routeStopDateTime = null;
                    this.isSchoolbound = false;

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve && changes.resolve.currentValue)
                        {
                            this.pupilChangeRequestOptions = changes.resolve.currentValue.pupilChangeRequestOptions;

                            this.isSchoolbound = changes.resolve.currentValue.isSchoolbound;
                            this.routeStopDateTime = changes.resolve.currentValue.routeStopDateTime;

                            if (this.pupilChangeRequestOptions.timeSlots && this.pupilChangeRequestOptions.timeSlots.length > 0)
                            {
                                this.pupilChangeRequestOptions.timeSlots.forEach(function (timeSlot)
                                {
                                    var isCurrentTimeslot = timeSlot.transportChangeRequestOptions.some(function (option)
                                    {
                                        return option.isCalendarEvent;
                                    });
                                    if (isCurrentTimeslot)
                                    {
                                        timeSlot.isCalendarEvent = true;
                                    }
                                });
                            }
                        }
                    }.bind(this);

                    this.save = function ()
                    {
                        var diaryTransportChangeRequest =
                        {
                            calendarEventTransportAttendeeId: this.pupilChangeRequestOptions.calendarEventTransportAttendeeId,
                            details: this.userControl.details,
                            timeSlotId: this.userControl.timeSlotId,
                            selectedOption: this.userControl.selectedOption,
                            fromRouteStopId: null
                        };

                        // Set the From route stop id if available
                        changeRequestService.setFromRouteStopId(diaryTransportChangeRequest, this.pupilChangeRequestOptions);

                        portalChangeRequestService.addChangeRequest(diaryTransportChangeRequest).then(function (responseData) {
                            if (responseData && responseData.referenceNumber) {

                                const modalInstance = simpleAlertService.simpleAlert({
                                    title: 'SB_Transport_Change_Request_Completed',
                                    message: 'SB_Transport_Change_Request_Completed_Message',
                                    messageTranslationParameters: { referenceNumber: responseData.referenceNumber},
                                    staticBackdrop: true
                                });

                                modalInstance.result.then(() => {
                                    deviceService.triggerModalCloseInMobileApp();
                                });

                                closeModal(true);
                            }
                            else {
                                simpleAlertService.errorAlert();
                                resetSubmitButtons();
                            }
                        })
                        .catch(function (responseData) {

                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message) {
                                message = { message: responseData.data.Message };
                            }

                            simpleAlertService.errorAlert(message);

                            resetSubmitButtons();
                        });

                    }.bind(this);

                    this.cancel = function () {
                        this.modalInstance.close('cancel');
                        deviceService.triggerModalCloseInMobileApp();
                    };

                    this.resetForm = function () {
                        if (this.userControl) {
                            this.userControl = {};
                        };
                    }.bind(this);

                    this.selectOption = function (transportChangeRequestOption, timeSlotId ) {
                        if (!transportChangeRequestOption.isCalendarEvent) {
                            this.userControl.selectedOption = transportChangeRequestOption;
                            this.userControl.timeSlotId = timeSlotId.id
                        }
                    }.bind(this);

                    this.isSelectedOption = function (transportChangeRequestOption, timeSlot) {
                        return (timeSlot.id === this.userControl.timeSlotId) && (angular.equals(this.userControl.selectedOption, transportChangeRequestOption));
                    }.bind(this);
                }
            ]
        });
