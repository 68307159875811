import { Injectable } from '@angular/core';
import { ToastService } from '@sb-core/services/toast.service';
import { EventFieldOptionsConfigTypes } from '@sb-events/constants/event-field-options-config-types';
import { formElements } from '@sb-shared/constants/shared.constant';
import { CharacterLimits } from '@sb-shared/globals/character-limits';
import { MemberType, MemberTypes } from '@sb-shared/globals/member-types';
import { TableColumns } from '@sb-shared/models/UI/table';
import { WizardFieldOptionsConfig, WizardTabs } from '@sb-shared/models/UI/wizard-tabs';
import { LegacyReceptionPersonCreateUpdate } from '@sb-shared/models/legacy-reception-person-create-update';
import { LocationDetails } from '@sb-shared/models/location-details';
import { HttpRequestSettings, apis } from '@sb-shared/models/request-settings';
import { ArrayService } from '@sb-shared/services/array.service';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CcaSignUpService } from './../../cca-sign-up/services/cca-sign-up.service';
import { ReceptionLocation } from './../models/reception-location';

@Injectable({
  providedIn: 'root'
})
export class ReceptionService {
  private locationTypes$: Observable<any[]>;

  config: HttpRequestSettings = {
    api: apis.Reception
  };

  constructor(
    private http: HttpWebApiService,
    private array: ArrayService,
    private ccaSignUp: CcaSignUpService,
    private toastService: ToastService
  ) {}

  getBoolSetting(settingName) {
    return this.http.get('GetBoolSetting', { ...this.config, params: { settingName: settingName } });
  }

  getTabVisibility() {
    return this.http.post('GetTabVisibility', {}, this.config);
  }

  // Locations

  getAllLocations(includeDeleted?: boolean): Observable<ReceptionLocation[]> {
    return this.http.get('GetAllLocations', { ...this.config, params: { includeDeleted: includeDeleted || false } });
  }

  getLocation(locationId: number): Observable<LocationDetails> {
    return this.http.get('GetLocation', {
      ...this.config,
      params: { hash_id: new Date().getTime(), locationId: locationId }
    });
  }

  saveLocation(location: ReceptionLocation) {
    return this.http.post('SaveLocation', { location: location }, { ...this.config, successMessage: 'Location saved' });
  }

  getLookupData() {
    return this.http.get('GetLookupData', { ...this.config, shareReplay: 1 });
  }

  getAttendeeFormData(personId: number, formId: number, calendarEventAttendeeId: number): Observable<any> {
    return this.http.get('GetAttendeeFormData',  { ...this.config, params: { personId: personId, formId: formId, calendarEventAttendeeId: calendarEventAttendeeId } });
  }

  getEventFormData(calendarEventId: number): Observable<any> {
    return this.http.get('GetEventFormData',  { ...this.config, params: { calendarEventId: calendarEventId } });
  }

  GetTeamFormData(teamId: number): Observable<any> {
    return this.http.get('GetTeamFormData',  { ...this.config, params: { teamId: teamId } });
  }

  saveAttendeeFormData(formId: number, personId: number, calendarEventId: number, calendarEventAttendeeId: number, capturedData: any): Observable<any> {
    return this.http.post('SaveAttendeeFormData', { formId: formId, personId: personId, calendarEventId: calendarEventId, calendarEventAttendeeId: calendarEventAttendeeId, capturedData: capturedData },  { ...this.config });
  }

  getLocationWizardTabs(): WizardTabs {
    const locationTypeOptionsConfig: WizardFieldOptionsConfig = {
      type: EventFieldOptionsConfigTypes.LocationType,
      getOptionsFn: () => this.getLocationTypes()
    };

    return this.array.addIdsToArray([
      {
        name: 'SB_Details',
        iconName: 'location-item',
        image: 'location_review',
        formGroupName: 'details',
        fields: [
          {
            label: 'SB_Name',
            type: formElements.Text,
            id: 'name',
            isRequired: true,
            minlength: CharacterLimits.StandardMinText,
            maxlength: CharacterLimits.Medium,
            cols: 10
          },
          {
            label: 'SB_Description',
            type: formElements.Content,
            rows: 5,
            id: 'description',

            maxlength: CharacterLimits.Medium
          },
          {
            label: 'SB_Home_Away',
            type: formElements.SimpleSelect,
            options: [
              { name: 'SB_Home', id: 0 },
              { name: 'SB_Away', id: 1 }
            ],
            id: 'homeAway',
            isRequired: true,
            cols: 5
          },
          {
            label: 'SB_Location_Type',
            type: formElements.SimpleSelect,
            id: 'locationTypeId',
            loadOptions: true,
            optionsConfig: locationTypeOptionsConfig,
            cols: 5
          }
        ]
      },
      {
        name: 'SB_Address',
        iconName: 'location-address',
        image: 'location_tracking',
        formGroupName: 'address',
        fields: [
          {
            label: 'SB_Address',
            type: formElements.Text,
            id: 'address',
            isRequired: false,
            minlength: CharacterLimits.StandardMinText,
            maxlength: CharacterLimits.Medium,
            cols: 10
          },
          {
            label: 'SB_City',
            type: formElements.Text,
            id: 'town',
            isRequired: false,
            minlength: CharacterLimits.StandardMinText,
            maxlength: CharacterLimits.Medium,
            cols: 5
          },
          {
            label: 'SB_County',
            type: formElements.Text,
            id: 'county',
            isRequired: false,
            minlength: CharacterLimits.StandardMinText,
            maxlength: CharacterLimits.Medium,
            cols: 5
          },
          {
            label: 'SB_PostCode',
            type: formElements.Text,
            id: 'postCode',
            cols: 5,
            minlength: CharacterLimits.StandardMinText,
            maxlength: CharacterLimits.ExtraShort
          },
          {
            label: 'SB_Telephone',
            type: formElements.Text,
            id: 'telephone',
            cols: 5,
            minlength: CharacterLimits.StandardMinText,
            maxlength: CharacterLimits.ExtraShort
          }
        ]
      }
    ]) as WizardTabs;
  }

  getLocationTypes() {
    return this.locationTypes$ ??= this.ccaSignUp
      .getLocationTypes()
      .pipe(shareReplay(1));
  }

  // Users
  addPerson(personData: LegacyReceptionPersonCreateUpdate) {
    return this.http.post('AddPerson', { personData: personData }, { ...this.config, showSuccessToast: false })
      .pipe(
        map((data) => {
          if (data?.msg != 'OK') {
            this.toastService.saveError(data?.msg);
            return null;
          }
          this.toastService.saveSuccess();
          return data;
        })
      );
  }

  savePerson(personData: LegacyReceptionPersonCreateUpdate) {
    return this.http.post('SavePerson', { personData: personData }, { ...this.config, showSuccessToast: false })
      .pipe(
        map((data) => {
          if (data?.msg != 'OK') {
            this.toastService.saveError(data?.msg);
            return null;
          }
          this.toastService.saveSuccess();
          return data;
        })
      );
  }

  deletePerson(personId: number) {
    return this.http
      .post('DeletePerson', null, { ...this.config, params: { personId: personId }, showSuccessToast: false })
      .pipe(
        map((data) => {
          if (!data?.personId && data?.msg != 'OK') {
            this.toastService.saveError(data?.msg);
            return null;
          }
          this.toastService.saveSuccess();
          return data;
        })
      );
  }

  restorePerson(personId: number, includeLinks: boolean) {
    return this.http
      .post('RestorePerson', null, {
        ...this.config,
        params: { personId: personId, includeLinks: includeLinks },
        showSuccessToast: false
      })
      .pipe(
        map((data) => {
          if (!data?.personId && data?.msg != 'OK') {
            this.toastService.saveError('SB_Restore_Failed');
            return null;
          }
          this.toastService.saveSuccess();
          return data;
        })
      );
  }

  updateOnlineMeetingUrl({ onlineMeetingId, staffPersonId }: { onlineMeetingId: string; staffPersonId: number }) {
    const payload = { staffPersonId, onlineMeetingId };
    const showSuccessToast = true;
    const config: HttpRequestSettings = { ...this.config, showSuccessToast };

    return this.http.post(`SetUserOnlineMeetingUrl`, payload, config);
  }

  getUserTypes(): TableColumns {
    return Object.values(MemberTypes)
      // Skip all
      .splice(1)
      .map((type: MemberType) => ({
        id: type.altId,
        iconTooltip: type.labelSingle,
        iconName: type.iconName,
        name: type.labelSingle,
        label: null
      }))
  }
}
