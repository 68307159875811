'use strict';

angular.module('transport.configuration.timetableCalendar.transportTimetableCalendarAdd',
    [
        'transport.services.transportTimetableCalendarsService',
        'shared.components.sbOnOffSwitch',
        'shared.services.iconService',
        'shared.services.simpleAlertService',
        'shared.components.sbDateRangePicker',
        'shared.constants'
    ])
    .component('transportTimetableCalendarAdd',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/transport/configuration/timetableCalendar/transport-timetable-calendar-add.template.html',
            controller: [
                '$timeout',
                'dateRangeLists',
                'transportTimetableCalendarsService',
                'simpleAlertService',
                function transportTimetableCalendarAddController($timeout,
                                                                dateRangeLists,
                                                                transportTimetableCalendarsService,
                                                                simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;
                    this.addingNewDateRange = false;
                    this.dateRangeLists = dateRangeLists;
                    this.fromDate = moment().startOf('day');
                    this.toDate = moment().endOf('day');
                    this.today = moment().startOf('day');
                    this.newDateRangeId = -1;
                    this.anyOverlapsExist = false;

                    this.formatDate = function (date, endOfDay)
                    {
                        return endOfDay ? date.format('YYYY-MM-DDT23:59:59') : date.format('YYYY-MM-DD');
                    }

                    this.dateRangeSelected = function ()
                    {
                        var newDateRange =
                        {
                            id: this.newDateRangeId,
                            fromDate: this.formatDate(this.fromDate),
                            toDate: this.formatDate(this.toDate, true),
                            overlapError: false,
                            isEditable: true,
                        };

                        var validationRequest =
                        {
                            id: this.newDateRangeId,
                            fromDate: this.formatDate(this.fromDate),
                            toDate: this.formatDate(this.toDate, true),
                            dateRanges: []
                        };

                        for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                        {
                            var dateRange = Object.assign({}, this.transportTimetableCalendar.dateRanges[i])

                            validationRequest.dateRanges.push(dateRange);
                        }

                        transportTimetableCalendarsService.validateDateRangeForOverlaps(validationRequest)
                            .then(function (responseDto)
                            {
                                if (responseDto && responseDto.overlapsExist)
                                {
                                    newDateRange.overlapError = true;
                                }

                                this.transportTimetableCalendar.dateRanges.push(newDateRange);

                                this.newDateRangeId--;
                                this.fromDate = moment().startOf('day').toDate();
                                this.toDate = moment().endOf('day').toDate();
                                this.addingNewDateRange = false;

                                this.setAnyOverlapsExist();

                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });

                    }.bind(this);

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.$onInit = function ()
                    {
                        this.transportTimetableCalendar =
                        {
                            name: null,
                            dateRanges: [],
                            isDefault: false
                        };
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.transportTimetableCalendar =
                        {
                            name: null,
                            dateRanges: [],
                            isDefault: false
                        };

                        this.modalInstance.dismiss('cancel');
                    };

                    this.addTransportTimetableCalendar = function ()
                    {
                        if (this.transportTimetableCalendar.name &&
                            this.transportTimetableCalendar.dateRanges.length > 0)
                        {
                            var newTransportTimetableCalendar =
                            {
                                name: this.transportTimetableCalendar.name,
                                isDefault: this.transportTimetableCalendar.isDefault,
                                dateRanges: []
                            };

                            for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                            {
                                var dateRange = Object.assign({}, this.transportTimetableCalendar.dateRanges[i])

                                newTransportTimetableCalendar.dateRanges.push(dateRange);
                            }

                            transportTimetableCalendarsService.addTransportTimetableCalendar(newTransportTimetableCalendar)
                                .then(function (responseDto)
                                {
                                    if (responseDto && responseDto.name)
                                    {
                                        simpleAlertService.simpleAlert({
                                            title: 'SB_Saved',
                                            message: 'SB_Transport_Timetable_Calendar_Added',
                                            messageTranslationParameters: { name: responseDto.name },
                                            staticBackdrop: true
                                        });

                                        this.modalInstance.close(responseDto);
                                    }
                                    else
                                    {
                                        alertAndResetOkButton();
                                    }
                                }.bind(this))
                                .catch(function (responseData)
                                {
                                    var message = {};

                                    if (responseData && responseData.data && responseData.data.Message)
                                    {
                                        message = { message: responseData.data.Message };
                                    }

                                    alertAndResetOkButton(message);
                                });
                        }
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.showAddDateRange = function ()
                    {
                        this.addingNewDateRange = true;

                    }.bind(this);

                    this.deleteDateRange = function (dateRangeToDelete)
                    {
                        this.transportTimetableCalendar.dateRanges =
                        this.transportTimetableCalendar.dateRanges.filter(function (dateRange)
                        {
                            return dateRange.id != dateRangeToDelete.id;
                        });
                    }.bind(this);

                    this.cancelAddDateRange = function ()
                    {
                        this.fromDate = moment().startOf('day');
                        this.toDate = moment().endOf('day');
                        this.addingNewDateRange = false;
                    }.bind(this);

                    this.setAnyOverlapsExist = function ()
                    {
                        for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                        {
                            var dateRange = this.transportTimetableCalendar.dateRanges[i];

                            if (typeof dateRange.overlapError !== 'undefined' &&
                                dateRange.overlapError)
                            {
                                this.anyOverlapsExist = true;
                                return;
                            }
                        }

                        this.anyOverlapsExist = false;
                    }
                }
            ]
        });
