'use strict';

angular.module('reception.components.receptionLocationManage', [
    'reception.services.receptionService'
])
.component('receptionLocationManage', {
templateUrl: '/Scripts/app/reception/components/reception-location-manage.template.html',
bindings: {
    selectedLocation: '<',
    onSelectedLocationUpdated: '&'
},
controller: [
    '$scope', '$uibModal', 'receptionService',
function ($scope, $uibModal, receptionService) {

    this.$onChanges = (changes) => {
        if (changes.selectedLocation && changes.selectedLocation.currentValue) {
            $scope.selectedLocation = this.selectedLocation;
        }
    };

    this.$onInit = () => { // Not indented to avoid merge issues

    receptionService
        .getLocationTypes()
        .then(function (locationTypes) {
            $scope.locationTypes = locationTypes;
        });


    $scope.homeAways = [
        {
            id: '0',
            name: 'Home'
        },
        {
            id: '1',
            name: 'Away'
        }
    ];

    }

    $scope.deleteLocation = () => {
        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'confirmDeleteLocationModal.html',
            controller: 'ConfirmModalInstanceCtrl',
            size: 'sm'

        });

        modalInstance.result.then(() => {
            $scope.loadingALocation = true;
            receptionService.postDeleteLocation($scope.selectedLocation.id)
                .then(() => {
                    $scope.loadingALocation = false;

                    if (typeof this.onSelectedLocationUpdated === 'function') {
                      this.onSelectedLocationUpdated($scope.selectedLocation);
                    }

                });
        }, () => null);
    };

    $scope.restoreLocation = () => {
        var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'confirmRestoreLocationModal.html',
            controller: 'ConfirmModalInstanceCtrl',
            size: 'sm'

        });

        modalInstance.result.then(() => {
            $scope.loadingALocation = true;
            receptionService.postRestoreLocation($scope.selectedLocation.id)
                .then(() => {
                    $scope.loadingALocation = false;

                    if (typeof this.onSelectedLocationUpdated === 'function') {
                      this.onSelectedLocationUpdated($scope.selectedLocation);
                    }
                });
        }, () => null);
    };

    $scope.updateLocation = () => {
        $scope.loadingALocation = true;
        receptionService
            .postLocation($scope.selectedLocation.details)
            .then(() => {
                $scope.loadingALocation = false;

                if (typeof this.onSelectedLocationUpdated === 'function') {
                  this.onSelectedLocationUpdated($scope.selectedLocation);
                }
            });
        }
    }
]});