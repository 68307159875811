angular.module('sysadmin.components.generateLabels', [
    'sysadmin.components.organisationQueueRunButton',
    'sysadmin.services.organisationLabelService'
]).component('generateLabels', {
    templateUrl: '/Scripts/app/sysadmin/components/generate-labels.template.html',
    bindings: {
        organisation: '<',
    },
    controller: class GenerateLabels {
        // Dependencies
        organisationLabelService: any;

        //Bindings
        organisation: any;

        static $inject = ['organisationLabelService'];

        constructor(organisationLabelService) {
            this.organisationLabelService = organisationLabelService;
        }

        generateLabels() {
            return this.organisationLabelService.regenerateOrgLabels(this.organisation.organisationId);
        }
    }
});
