import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '@sb-shared/services/logging.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private logging: LoggingService) {
        super();
    }

    handleError(error: Error) {
        const isTranslation = error.message.indexOf('Missing label: ') > -1;
        if (isTranslation) {
            // Don't crowd console with errors if just translate
            console.warn(error);
        } else {
            console.error(error);
        }
        this.logging.logException(error); // Manually log exception
    }
}
