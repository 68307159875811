<div ngbDropdown>
    <div class="align-center"
         [ngClass]="computedClass"
         [ngbTooltip]="tooltipText"
         placement="bottom"
         ngbDropdownToggle>
        <sb-icon *ngIf="iconName"
                 name="{{iconName}}"
                 [ngClass]="{'mr-1' : message}"></sb-icon>
        <span [ngClass]="{'hidden-xs' : messageXs}">{{isMessageTranslated ? (message | translate: messageTranslationParameters) : message}}</span>
        <span *ngIf="messageXs" class="hidden-sm hidden-md hidden-lg">{{isMessageTranslated ? (messageXs | translate: messageTranslationParameters) : messageXs}}</span>
        <sb-button *ngIf="options"
                   iconStyle="solid"
                   iconName="chevronDown"
                   buttonClass="link"
                   [isAutoSize]="true"></sb-button>
    </div>
    <div *ngIf="options"
         ngbDropdownMenu>
        <div *ngFor="let option of visibleOptions()"
             ngbDropdownItem
             aria-labelledby="dropDown"
             class="option"
             (click)="selectOption(option.id)">
            <sb-badge [message]="option.name"
                      [badgeClass]="option.class"
                      [isOption]="true"></sb-badge>
        </div>
    </div>
</div>
