import { UiClasses } from '../../types/ui-classes';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Options } from '@sb-shared/models/UI/filter';

@Component({
  selector: 'sb-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  @Input() message: string;
  @Input() messageXs: string;
  @Input() messageTranslationParameters: any;
  @Input() isMessageTranslated = true;
  @Input() badgeClass: UiClasses = 'primary';
  @Input() customClasses = '';
  @Input() iconName: string;
  @Input() isOutline: boolean;
  @Input() isLarge: boolean;
  @Input() options: Options;
  @Input() selectedOptionId: number;
  @Input() tooltipText: string;
  @Input() isOption: boolean;
  @Input() isRounded: boolean;
  @Input() isBold: boolean;
  @Input() allCaps: boolean;

  @Output() selectedOptionIdChange: EventEmitter<any> = new EventEmitter();

  computedClass: string;

  constructor() {}

  ngOnInit() {
    this.computeClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.badgeClass?.currentValue ||
        changes.customClasses?.currentValue ||
        changes.isLarge?.currentValue ||
        changes.options) {
      this.computeClass();
    }
  }

  computeClass() {
    const styleClass = `badge ${(this.isOutline ? `bg-transparent text-${this.badgeClass} border-${this.badgeClass}` : `badge-${this.badgeClass}`)} ${this.customClasses}`;
    const sizeClass = this.isLarge ? ' badge-lg' : '';
    const defaultCursorClass = !this.options && !this.isOption ? ' non-dropdown' : '';
    const isRoundClass = this.isRounded ? ' badge--rounded' : '';
    const isBoldClass = this.isBold ? ' badge--bold' : '';
    const allCapsClass = this.allCaps ? ' badge--allcaps' : '';

    this.computedClass = `${styleClass}${sizeClass}${defaultCursorClass}${isRoundClass}${isBoldClass}${allCapsClass}`;
  }

  selectOption(optionId: any) {
    this.selectedOptionIdChange.emit(optionId);
  }

  visibleOptions() {
    return this.options.filter(option => option.id !== -1 && option.id !== this.selectedOptionId);
  }

}
