import { Component, Input, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sb-icon-img',
  templateUrl: './icon-img.component.html',
  styleUrls: ['./icon-img.component.scss']
})
export class IconImgComponent {

  @Input() type = '';
  @Input() category = '';
  @Input() name = '';
  @Input() imgUrl = '';
  fullImgUrl: string;

  types: any = {
    Sebo: {
        format: 'png'
    }
  } ;
  format: any;

  ngOnChanges(changes: SimpleChanges) {
    // Default to Sebo
    const defaultType = this.types.Sebo;
    const typeString = this.type || 'Sebo';
    const selectedType = this.types[this.type] || defaultType;


    // Get format specifically set, else get based on type
    const format = this.format || selectedType.format;

    const cdnBase = environment.cdnBase;

    // Generate url
    const urlSlug = this.imgUrl || `${typeString}/${this.category ? this.category + '/' : ''}${this.name}.${format}`;
    this.fullImgUrl = `${cdnBase}img/icons/${urlSlug}`;
  }

}
