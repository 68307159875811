<div class="toast-container">
    <ngb-toast *ngFor="let toastItem of toast.toasts"
                [class]="toastItem.toastClass"
                [autohide]="true"
                [delay]="toastItem.timeout || 5000"
                (hidden)="toast.remove(toastItem)">
        <sb-alert [alertClass]="toastItem.toastClass"
                  [customClasses]="toastItem.additionalClasses"
                  [alertTitle]="toastItem.toastTitle"
                  [message]="toastItem.content"
                  [routerLink]="toastItem.routerLink"
                  (onDismiss)="toast.remove(toastItem)"></sb-alert>
    </ngb-toast>
</div>