<div *ngIf="location?.name">
    <div class="row align-center">
        <div class="col-sm-3 col-sm-rtl-push-9">
            <label class="control-label" [innerHTML]="'SB_Name' | translate"></label>
        </div>
        <div class="col-sm-9 col-sm-rtl-pull-3">
            <div class="form-control-static">
                {{location.name}}
            </div>            
        </div>
    </div>
</div>
<div *ngIf="location?.address">
    <div class="row align-center">
        <div class="col-sm-3 col-sm-rtl-push-9">
            <label class="control-label" [innerHTML]="'SB_Address' | translate"></label>
        </div>
        <div class="col-sm-9 col-sm-rtl-pull-3">
            <div class="form-control-static">
                {{location.address}}
            </div>
        </div>
    </div>   
</div>
<div *ngIf="location?.postCode">
    <div class="row align-center">
        <div class="col-sm-3 col-sm-rtl-push-9">
            <label class="control-label" [innerHTML]="'SB_PostCode' | translate"></label>
        </div>
        <div class="col-sm-9 col-sm-rtl-pull-3">
            <div class="form-control-static">
                {{location.postCode}}
            </div>
        </div>
    </div>
</div>
<div *ngIf="mapsUrl?.value">
    <div class="row align-center">
        <div class="col-sm-3 col-sm-rtl-push-9">
            <label class="control-label"
                   [innerHTML]="mapsUrl.label| translate"></label>
        </div>
        <div class="col-sm-9 col-sm-rtl-pull-3">
            <div class="form-control-static">
                <sb-button buttonClass="secondary"
                        message="SB_Find"
                        iconName="followLink"
                        (onClick)="openMap()"></sb-button>
            </div>
        </div>
    </div>
</div>
