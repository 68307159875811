'use strict';

angular.module('transport.changeRequests.transportReviewChangeRequest', [
    'shared.components.sbIcon',
    'shared.components.sbModalHeader',
    'shared.services.iconService',
])
    .component('transportReviewChangeRequest', {
        templateUrl: '/Scripts/app/transport/changeRequests/transport-review-change-request.template.html',
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
        controller: [
            '$timeout',
            '$filter',
            'simpleAlertService',
            'iconService',
            'changeRequestService',
            function transportPendingRequests($timeout, $filter, simpleAlertService, iconService, changeRequestService) {

                this.isSubmitted = false;

                //object to hold the user's selections for method, route and stop.
                this.timeSlotOptions = {};

                var sbTransportIcons = iconService.getTransportIcons();

                var resetSubmitButtons = function () {
                    $timeout(function () {
                        this.isSubmitted = false;
                    }.bind(this), 200);

                }.bind(this);

                var closeModal = function (updateView) {
                    this.modalInstance.close(updateView);
                }.bind(this);

                //sets the controls for the already selected change request option
                var setModelForControls = function () {
                    //This is an array of operating days and we're only expecting 1 back here
                    if (this.timeSlotTimeTableByOperatingDay.length == 1)
                    {
                        this.timeSlotTimeTableByOperatingDay = this.timeSlotTimeTableByOperatingDay[0]

                        if (!this.changeRequest.transportOptionChangeTo.transportChangeRequestOption.isOtherOption)
                        {
                            //set the model values for the drop down that the User request change "To"
                            var selectedMethod = $filter('filter')(this.timeSlotTimeTableByOperatingDay.methods, { id: this.changeRequest.transportOptionChangeTo.transportChangeRequestOption.id }, true);
                            if (selectedMethod.length === 1)
                            {
                                this.timeSlotOptions.method = selectedMethod[0];
                                if (this.timeSlotOptions.method.supportRoutes)
                                {
                                    //set route and route stops
                                    var route = $filter('filter')(this.timeSlotOptions.method.routes,
                                        {
                                            timetableRouteId: this.changeRequest.transportOptionChangeTo.transportChangeRequestOption.timetableRoute.timetableRouteId
                                        }, true);

                                    //set route stop
                                    if (route.length === 1)
                                    {
                                        this.timeSlotOptions.route = route[0];

                                        if (route[0].routeStops.length > 0)
                                        {
                                            var routeStop = $filter('filter')(route[0].routeStops, {
                                                routeStopId: this.changeRequest.transportOptionChangeTo.transportChangeRequestOption.timetableRoute.timetableRouteStop.routeStopId
                                            }, true);

                                            if (routeStop.length === 1)
                                            {
                                                this.timeSlotOptions.routeStop = routeStop[0];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }.bind(this);

                this.$onChanges = function (changes)
                {
                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.timeSlotTimeTableByOperatingDay = changes.resolve.currentValue.timeSlotTimeTableByOperatingDay
                        this.changeRequest = changes.resolve.currentValue.changeRequest;

                        if (this.changeRequest && this.timeSlotTimeTableByOperatingDay)
                        {
                            setModelForControls();
                        }

                    }
                }.bind(this);

                this.resetModel = function ()
                {
                    delete this.timeSlotOptions.route;
                    delete this.timeSlotOptions.routeStop;
                }.bind(this);

                this.close = function ()
                {
                    closeModal();
                }.bind(this);

                this.onHold = function ()
                {
                    var onHoldRequest =
                    {
                        id: this.changeRequest.id,
                        adminResponseReason: this.changeRequest.adminResponseReason
                    };

                    changeRequestService.onHoldChangeRequest(onHoldRequest).then(function (responseData)
                    {
                        if (responseData && responseData.referenceNumber)
                        {

                            simpleAlertService.simpleAlert(
                                {
                                    title: 'SB_Transport_Change_Request_OnHold',
                                    message: 'SB_Transport_Change_Request_OnHold_Message',
                                    messageTranslationParameters: { referenceNumber: responseData.referenceNumber },
                                    staticBackdrop: true
                                });

                            closeModal(true);
                        }
                        else
                        {
                            simpleAlertService.errorAlert();
                            resetSubmitButtons();
                        }
                    })
                        .catch(function (responseData)
                        {
                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            simpleAlertService.errorAlert(message);

                            resetSubmitButtons();
                        });
                }.bind(this);

                this.reject = function () {

                    var rejectRequest = {
                        id: this.changeRequest.id,
                        adminResponseReason: this.changeRequest.adminResponseReason,
                        transportOptionChangeTo: this.changeRequest.transportOptionChangeTo
                    };

                    changeRequestService.rejectChangeRequest(rejectRequest).then(function (responseData)
                    {
                        if (responseData && responseData.referenceNumber) {

                            simpleAlertService.simpleAlert({
                                title: 'SB_Transport_Change_Request_Rejected',
                                message: 'SB_Transport_Change_Request_Rejected_Message',
                                messageTranslationParameters: { referenceNumber: responseData.referenceNumber },
                                staticBackdrop: true
                            });

                            closeModal(true);
                        }
                        else {
                            simpleAlertService.errorAlert();
                            resetSubmitButtons();
                        }
                    })
                    .catch(function (responseData) {

                        var message = {};

                        if (responseData && responseData.data && responseData.data.Message) {
                            message = { message: responseData.data.Message };
                        }

                        simpleAlertService.errorAlert(message);

                        resetSubmitButtons();
                    });
                }.bind(this);

                this.approve = function ()
                {
                    var approveRequest =
                    {
                        id: this.changeRequest.id,
                        adminResponseReason: this.changeRequest.adminResponseReason,
                        timetableId: this.timeSlotOptions.method.timetableId
                    };

                    if (this.timeSlotOptions.method.supportRoutes)
                    {
                        approveRequest.timetableRouteId = this.timeSlotOptions.route.timetableRouteId;
                        approveRequest.routeStopId = this.timeSlotOptions.routeStop.routeStopId;
                    }

                    changeRequestService.approveChangeRequest(approveRequest)
                        .then(function (responseData)
                        {
                            if (responseData && responseData.referenceNumber)
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Transport_Change_Request_Approve',
                                    message: 'SB_Transport_Change_Request_Approve_Message',
                                    messageTranslationParameters: { referenceNumber: responseData.referenceNumber },
                                    staticBackdrop: true
                                });

                                closeModal(true);
                            }
                            else
                            {
                                simpleAlertService.errorAlert();
                                resetSubmitButtons();
                            }
                        })
                        .catch(function (responseData)
                        {
                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            simpleAlertService.errorAlert(message);

                            resetSubmitButtons();
                        });
                }.bind(this);

                this.filterIcon = function (method) {
                    var icons = $filter('filter')(sbTransportIcons, { 'iconName': method.sbIconName }, true);

                    if (icons.length > 0) {
                        return {
                            'iconName': icons[0].iconName,
                            'displayName': $filter('translate')(icons[0].displayName),
                            'unicode': icons[0].unicode
                        };
                    };

                    return {
                        'iconName': method.sbIconName,
                        'displayName': $filter('translate')(method.name),
                        'unicode': ''
                    };
                }.bind(this);

                this.displayLabelforRoute = function (route) {

                    var routeLabel = route.name;

                    if (route.timetableRouteName) {
                        routeLabel += '- ' + route.timetableRouteName
                    }
                    if (route.code) {
                        routeLabel += ' (' + route.code + ')'
                    }

                    return routeLabel;
                };

                this.displayLabelforStop = function (stop) {

                    var stopLabel = stop.name;

                    if (stop.zip) {
                        stopLabel += ' (' + stop.zip + ')'
                    }

                    return stopLabel;
                };

            }
        ]
    });