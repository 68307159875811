'use strict';

angular.module('ccasignupModule').component('ccaGenerateFees', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-generate-fees.template.html',
    controller: [
    '$scope',
    '$state',
    'signUpRepository',
    'simpleAlertService',
    function ($scope, $state, signUpRepository, simpleAlertService) {

        $scope.loading = {};

        $scope.generate = { eventName: "" };
        $scope.generateButtonDisabled = false;
        var signUpId = $state.params.signUpId;

        signUpRepository.getSignUp(signUpId).then(function(signUp) {
            $scope.signUp = signUp;
            $scope.generate.eventName = signUp.title + " - Term Fees";
        });

        $scope.sortType = "title"; // set the default sort type
        $scope.sortReverse = false; // set the default sort order

        signUpRepository.getSignUpFeeGroups(signUpId).then(function (signUpGroups) {
            $scope.loading = null;
            $scope.groups = signUpGroups;
            $scope.overallTotal = 0;

            for (var i = 0; i < $scope.groups.length; i++) {
                var item = $scope.groups[i];
                var itemFee = item.totalFee * 1;
                $scope.overallTotal += itemFee;
            }
        });

        $scope.generateFees = function () {

            $scope.generateButtonDisabled = true;

            var modalInstance = simpleAlertService.simpleAlert({
                title: 'SB_Confirm_Fee_Generation',
                message: 'SB_generate_fees_explanation',
                okButtonText: 'SB_Generate',
                cancelButtonText: 'SB_Cancel',
                windowSize: 'sm'
            });

            modalInstance.result.then(function() {
                $scope.groups = null;

                var pleaseWaitModal = simpleAlertService.pleaseWaitModal({
                    message:'SB_Please_wait_generating_fees'
                });

                signUpRepository.postGenerateFees($state.params.signUpId, $scope.generate.eventName)
                    .then(function () {
                        pleaseWaitModal.close();

                        var confirmationModal = simpleAlertService.simpleAlert({
                            title: 'SB_Fees_Generated',
                            message: 'SB_Fees_Generated'
                        });

                        confirmationModal.result.then(function () {
                            $state.go('ccaDetails.groups');
                        });
                    })
                    .catch(function () {
                        simpleAlertService.errorAlert();
                        $scope.generateButtonDisabled = false;
                    });
            }, function () {
                $scope.generateButtonDisabled = false;
            });

        };
    }
]});