"use strict";

angular.module('receptionModule').component("receptionEventsMedical", {
    templateUrl: '/Scripts/app/reception/controllers/reception-events-medical.template.html?v=', // + window.EveryBuddy.Version,
    controller:
    [
        "$scope", "$state",
        function ($scope, $state) {

        }
    ]});