angular.module('sysadmin.services.emailFooterService', [])
.service('emailFooterService', class EmailFooterService {

    httpCoreApi: any;
    settings: any;
    baseUrlSlug: string = 'EmailFooterTemplate';

    static $inject = ['httpCoreApi'];

    constructor(httpCoreApi) {
        this.httpCoreApi = httpCoreApi;
        this.settings = {
            controllerType: this.httpCoreApi.controllerTypes.SysAdmin
        }
    }

    getEmailFooter(organisationId: number) {
        return this.httpCoreApi.get(this.baseUrlSlug, {
            ...this.settings,
            orgId: organisationId
        });
    }

    createEmailFooter(organisationId: number, footer: string) {
        return this.httpCoreApi.post(this.baseUrlSlug, {
            HTMLFooterTemplate: footer
        }, {
            ...this.settings,
            orgId: organisationId
        });
    }

    updateEmailFooter(organisationId: number, footer: string) {
        return this.httpCoreApi.put(this.baseUrlSlug, {
            HTMLFooterTemplate: footer
        }, {
            ...this.settings,
            orgId: organisationId
        });
    }

});
