angular.module('shared.directives.sbLoading', [
    'shared.components.sbIcon',
    'shared.components.sbAlert',
    'shared.services.iconService'])
    .directive('sbLoading', [function () {

        //we may want to extend this to report back a loading error from XHR
        function controller() {
        };
        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/shared/directives/sb-loading.template.html',
            scope: {},
            bindToController: {
                hasLoaded: '<',
                loadFailureMessage :'<'
            },
            controller: controller,
            controllerAs: 'ctrl'
        };
    }]);