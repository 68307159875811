angular.module('messaging.components.messageDetails', [])
  .component('messageDetails', {
    bindings: {
      resolve: '<',
      dismiss: '&',
      close: '&',
    },
    templateUrl: '/Scripts/app/messaging/components/message-details.template.html',
    controller: class MessageDetails {

      // Dependencies
      messageDetail: any;
      $sce: any;
      ng1AuthService: any;

      // Bindings
      resolve: any;
      dismiss: any;

      static $inject = ['$sce', 'ng1AuthService'];

      constructor($sce, ng1AuthService) {
        this.$sce = $sce;
        this.ng1AuthService = ng1AuthService;
      }

      $onInit() {
        // Set values from resolve
        this.messageDetail = this.resolve.messageDetail;
      }

      close() {
        this.dismiss('cancel');
      };

      trustHtml(html) {
        return this.$sce.trustAsHtml(html);
      };

      getAttachHref = (resourcePath) => {
        return `${window.EveryBuddy.WebAPI}/Chat/GetBlobAttachment?Resource=${resourcePath}&id=${this.messageDetail.id}`;
      }
    }
  })
