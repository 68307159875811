angular.module('balanceModule').component('balanceDashboard', {
    templateUrl: '/Scripts/app/balance/components/balance-dashboard.template.html',
    controller: class BalanceDashboardCtrl {
        // Dependencies
        $state: any;
        $filter: any;
        $translate: any;
        $q: any;
        $document: any;
        $window: any;
        organisationService: any;
        blobStoragePhotosService: any;
        balanceRepository: any;
        arrayService: any;
        searchFilterTypes: any;
        dateRangeLists: any;
        balanceSharedService: any;
        paymentsService: any;

        // UI Properties
        isLoading: boolean;
        isLoadingPayment: boolean;
        getLabelTagForCreditType: any;
        tabs: any;
        tab: number;
        transCardViewId: number;
        currencyDisplaySymbol: string;
        hasPaymentProviders: boolean;
        sortCardsOptions: any;
        sortCardsType: any;
        isoCurrency: any;
        pageTitle: string;

        // Data
        organisation: any;
        transactions: Array<any>;
        events: Array<any>;
        teams: Array<any>;
        people: Array<any>;
        clubs: Array<any>;
        transactionDays: Array<any>;
        postPaymentData: any;
        paymentSessionId: any;
        paymentSignature: any;

        // Filters/ordering
        searchFilters: any;
        eventsFrom: any;
        eventsTo: any;
        sortType: string;
        sortReverse: boolean;

        // PDF generation
        exportTitle: string;
        exportTableHeaders: any;
        exportInfo: any;
        exportData: any;

        static $inject = ['$state', '$filter', '$translate', '$q', '$document', '$window', 'organisationService', 'blobStoragePhotosService', 'balanceRepository', 'arrayService', 'searchFilterTypes', 'dateRangeLists', 'balanceSharedService', 'paymentsService'];

        constructor (
            $state,
            $filter,
            $translate,
            $q,
            $document,
            $window,
            organisationService,
            blobStoragePhotosService,
            balanceRepository,
            arrayService,
            searchFilterTypes,
            dateRangeLists,
            balanceSharedService,
            paymentsService) {
                this.$state = $state;
                this.$filter = $filter;
                this.$translate = $translate;
                this.$q = $q;
                this.$document = $document;
                this.$window = $window;
                this.organisationService = organisationService;
                this.blobStoragePhotosService = blobStoragePhotosService;
                this.balanceRepository = balanceRepository;
                this.arrayService = arrayService;
                this.searchFilterTypes = searchFilterTypes;
                this.dateRangeLists = dateRangeLists;
                this.balanceSharedService = balanceSharedService;
                this.paymentsService = paymentsService;
                this.getLabelTagForCreditType = balanceSharedService.getLabelTagForCreditType;
                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
            }

        resetSearchFilters() {
            this.searchFilters = { eventId: 0, teamId: 0, personId: 0, clubId: 0 };
            if (this.transactions) {
                this.getTransactions();
            }
        };

        $onInit() {
            this.tabs = { Outstanding: 1, Historic: 2 };
            this.resetSearchFilters();
            this.resetTransCardViewId();

            // Default to events from last three years.
            // As we are showing unpaid by default we cannot have a situation whereby
            // a parent's unpaid debt is hidden by date filters. Three years should catch this.
            this.eventsFrom = moment().startOf('day').add(-3, 'years');
            this.eventsTo = moment().endOf('day').add(1, 'years');



            this.organisationService.getOrganisation()
                .then(data => {
                    this.organisation = data;


                });

            this.sortType = 'eventDate';
            this.sortReverse = false;

            this.$translate.onReady().then(() => {

                this.sortCardsOptions = [
                    { id: 'eventDate', name: this.$filter('translate')('SB_Event_Date')},
                    { id: 'latestTransactionDate', name: this.$filter('translate')('SB_Latest_Transaction')}
                ];

                this.sortCardsType = this.sortCardsOptions[0];

                this.isLoading = true;

                this.paymentsService.organisationHasPaymentProviders()
                    .then(hasPaymentProviders =>
                        {
                            this.isLoading = false;
                            this.hasPaymentProviders = hasPaymentProviders;
                            this.getTransactions();
                        });


                this.pageTitle = this.$filter('translate')('SB_Balance');

                this.exportTableHeaders = [
                    this.$filter('translate')('SB_Latest_Transaction'),
                    this.$filter('translate')('SB_Group'),
                    this.$filter('translate')('SB_Club'),
                    this.$filter('translate')('SB_Event'),
                    this.$filter('translate')('SB_Person'),
                    this.$filter('translate')('SB_Paid'),
                ];

            });
        }

        setTab(tab) {
            this.tab = tab;
            this.getDays();
        };

        resetTransCardViewId() {
            this.transCardViewId = 0;
        };


        getDays() {

            let transactionList = [].concat(this.transactionList());

            angular.forEach(transactionList, (trans => {
                trans.day = this.$filter('date')(trans[this.sortCardsType.id], 'dd MMMM yyyy');
            }));
            this.transactionDays = this.arrayService.groupByDate(transactionList, this.sortCardsType.id, true);

            // Set export info

            var fromDate = this.$filter('date')(this.eventsFrom.toDate(), 'dd MMM yyyy');
            var toDate = this.$filter('date')(this.eventsTo.toDate(), 'dd MMM yyyy');

            this.exportTitle = this.$filter('translate')('SB_Transactions') + ' (' + fromDate + '\xa0 \u2013 \xa0' + toDate + ')';

            this.exportInfo = [];
            this.exportData = [];

            // Get ordered list of transactions
            transactionList = this.$filter('orderBy')(transactionList, this.sortCardsType.id);

            angular.forEach(transactionList, (trans => {
                if (trans.summaryCreditAmount > 0) {
                    var decimal = (this.$filter('number')(trans.summaryDebitAmount, 2)) - (this.$filter('number')(trans.summaryDebitAmount, 0));
                    trans.summaryDebitAmountDecimal = decimal.toString().substring(2);
                    this.exportData.push([
                        this.$filter('date')(trans.latestTransactionDate, 'dd MMM yyyy HH:mm'),
                        trans.teamName,
                        trans.clubName,
                        trans.eventName,
                        trans.capturedPersonName ? trans.capturedPersonName : '',
                        this.currencyDisplaySymbol + trans.summaryCreditAmount,
                    ]);
                }
            }));

            var totalRow =
                [
                    this.$filter('translate')('SB_Total'),
                    '',
                    '',
                    '',
                    '',
                    this.currencyDisplaySymbol + this.$filter('number')(this.$filter('sumByKey')(transactionList, 'summaryCreditAmount'), 2)
                ]
            this.exportData.push(totalRow);

            if (this.searchFilters.teamId) {
                this.exportInfo.push({ label: this.$filter('translate')('SB_Group'), text: this.teams.filter(item => { return item.id == this.searchFilters.teamId; })[0].name});
            }
            if (this.searchFilters.clubId) {
                this.exportInfo.push({label: this.$filter('translate')('SB_Club'), text: this.clubs.filter(item => { return item.id == this.searchFilters.clubId; })[0].name});
            }
            if (this.searchFilters.eventId) {
                this.exportInfo.push({label: this.$filter('translate')('SB_Event'), text: this.events.filter(item => { return item.id == this.searchFilters.eventId; })[0].name});
            }
            if (this.searchFilters.personId) {
                this.exportInfo.push({label: this.$filter('translate')('SB_Person'), text: this.people.filter(item => { return item.id == this.searchFilters.personId; })[0].name});
            }
        };

        getTab() {
            if (!this.tab || (this.tab == this.tabs.Outstanding && this.outstandingTransactions().length == 0)) {
                this.tab = this.outstandingTransactions().length > 0 ? this.tabs.Outstanding : this.tabs.Historic;
            }
        };

        onChangeFilters() {
            this.getTab();
            this.getDays();
        };

        getTransactions() {

            this.isLoading = true;

            var deferred = this.$q.defer();
            this.balanceRepository.get(this.eventsFrom, this.eventsTo).then(data => {

                this.isoCurrency = data.isoCurrency;

                if (data.transactions.length > 0) {
                    var minMax = this.findMinMaxTransactionDates(data.transactions);
                    this.searchFilters.eventsFrom = minMax.Min;
                    this.searchFilters.eventsTo = minMax.Max;
                }

                this.blobStoragePhotosService.addStudentPhotoUrlsToArray(data.transactions, 'capturedPersonId').then(array => {
                    this.transactions = array;
                    this.events = data.events;

                    this.events = data.events;

                    //append date string to name
                    for (var i = 0; i < this.events.length; i++) {
                        if (0 !== this.events[i].id) {
                            this.events[i].name += ' (' + this.$filter('date')(this.events[i].referenceDate, 'dd MMM yy') + ')';
                        }
                    }

                    this.teams = data.teams;
                    this.people = data.people;
                    this.clubs = data.clubs;

                    this.transactions.forEach(trans => {

                        if (trans.relatedTransactions.length > 0) {
                            var orderedRelated = this.$filter('orderBy')(trans.relatedTransactions, 'transactionDate');
                            trans.latestTransactionDate = orderedRelated[orderedRelated.length - 1].transactionDate;
                        } else {
                            trans.latestTransactionDate = trans.transactionDate;
                        }

                    });

                    this.onChangeFilters();
                    this.isLoading = false;

                    deferred.resolve();

                });

            }).catch(e => { deferred.reject(e); });

            return deferred.promise;

        };

        pay(isAll) {

            this.isLoadingPayment = true;

            var paymentsDue;
            if (isAll) {
                paymentsDue = this.outstandingTransactions();
            }
            else {
                paymentsDue = this.selectedTransactions();
            }

            var payments = paymentsDue.map(a => {
                return {
                    calendarEventId: a.calendarEventId,
                    owningPersonId: a.owningPersonId,
                    transactionId: a.transactionId
                };
            });

            this.balanceRepository
                .post(payments)
                .then(data => {
                    this.postPaymentData = data;

                    this.$state.go('payment',
                        {
                            payments: this.postPaymentData,
                            paymentSessionId: this.paymentSessionId,
                            paymentSignature: this.paymentSignature
                        });
                    this.isLoadingPayment = false;
                });
        };

        search(item) {

            var match;

            if (this.searchFilters.eventId == 0 &&
                this.searchFilters.teamId == 0 &&
                this.searchFilters.clubId == 0 &&
                this.searchFilters.personId == 0 &&
                this.sortCardsType.id == 'eventDate'
                ) {
                    match = true;
                }
            else {
                var eventMatch = this.searchFilters.eventId === 0;
                if (this.searchFilters.eventId > 0 && item.calendarEventId === this.searchFilters.eventId) {
                    eventMatch = true;
                }
                var teamMatch = this.searchFilters.teamId === 0;
                if (this.searchFilters.teamId > 0 && item.teamId === this.searchFilters.teamId) {
                    teamMatch = true;
                }
                var clubMatch = this.searchFilters.clubId === 0;
                if (this.searchFilters.clubId > 0 && item.clubId === this.searchFilters.clubId) {
                    clubMatch = true;
                }
                var peopleMatch = this.searchFilters.personId === 0;
                if (this.searchFilters.personId > 0 && item.capturedPersonId === this.searchFilters.personId) {
                    peopleMatch = true;
                }

                match = (eventMatch && teamMatch && peopleMatch && clubMatch);
            }

            //always need to filter by from to dates
            if (match) {
                if (new Date(this.searchFilters.eventsFrom).valueOf() <= new Date(item.transactionDate).valueOf() &&
                    new Date(this.searchFilters.eventsTo).valueOf() >= new Date(item.transactionDate).valueOf()
                ) {
                    match = true;
                } else {
                    match = false;
                }
            }

            return match;
        };

        findMinMaxTransactionDates(array) {

            var minMax = { Min: null, Max: null };

            var transactionDateSortedArray = array.sort((a, b) => {
                return new Date(a.transactionDate).valueOf() - new Date(b.transactionDate).valueOf()
            });

            minMax.Min = transactionDateSortedArray[0].transactionDate;
            minMax.Max = transactionDateSortedArray[transactionDateSortedArray.length - 1].transactionDate;

            return minMax;
        };

        refresh() {

            if (!this.transactions || this.transactions.length === 0) {
                this.getTransactions();
                return;
            }

            var minMax = this.findMinMaxTransactionDates(this.transactions);

            // we want to get all the transactions from the server if the date filters fall outside
            // the present data set
            if (new Date(minMax.Min).valueOf() > new Date(this.eventsFrom).valueOf() || new Date(minMax.Max).valueOf() < new Date(this.eventsTo).valueOf()) {
                this.getTransactions();
            } else {
                this.searchFilters.eventsFrom = this.eventsFrom;
                this.searchFilters.eventsTo = this.eventsTo;
                this.onChangeFilters();
            }
        };

        openCard(id) {
            const yOffset = 250;
            const scrollSpeed = 200;
            this.transCardViewId = id;
            var element = angular.element(document.getElementById('trans' + id));
            this.$document.scrollToElement(element, yOffset, scrollSpeed);
        };

        // Transaction list filtered by search filters

        filteredTransactions() {
            if (this.transactions) {
                return this.transactions.filter(item => {
                    return this.search(item);
                });
            }
            return [];
        };

        // Filtered list further filtered by event or transaction mode

        transactionList() {
            if (this.tab == this.tabs.Outstanding) {
                return this.outstandingTransactions();
            } else if (this.sortCardsType.id == 'latestTransactionDate'){
                return this.filteredTransactions().filter(trans => {
                    return trans.summaryCreditPaymentsOnly > 0;
                });
            } else {
                return this.filteredTransactions();
            }
        };

        // Filtered list further filtered to just outstanding transactions

        outstandingTransactions() {
            if (this.filteredTransactions()) {
                return this.filteredTransactions().filter(trans => {
                    return trans.outstandingBalance < 0;
                });
            }
            return [];
        };

        selectedTransactions() {
            return this.$filter('filter')(this.outstandingTransactions(), { checked: true });
        }
    }
});
