angular.module('shared.services.fileService', [
    'ngFileSaver',
    'shared.services.toastService',
    'shared.file.constants'])
    .service('fileService',
        class FileService {

            $filter: any;
            $rootScope: any;
            $q: any;
            $window: any;
            cachedLookupService: any;
            organisationService: any;
            FileSaver: any;
            fileTypeEnum: any;
            languageFormats: any;
            toastService: any;
            ng1PrinceService: any;

            userName: any;
            organisation: any;
            brandColor: string;
            base64Logo: string;

            static $inject = ['$filter', '$rootScope', '$q', '$window', 'cachedLookupService', 'organisationService', 'FileSaver', 'fileTypeEnum', 'languageFormats', 'toastService', 'ng1PrinceService'];

            constructor($filter, $rootScope, $q, $window, cachedLookupService, organisationService, FileSaver, fileTypeEnum, languageFormats, toastService, ng1PrinceService) {
                this.$filter = $filter;
                this.$rootScope = $rootScope;
                this.$q = $q;
                this.$window = $window;
                this.cachedLookupService = cachedLookupService;
                this.organisationService = organisationService;
                this.FileSaver = FileSaver;
                this.fileTypeEnum = fileTypeEnum;
                this.languageFormats = languageFormats;
                this.toastService = toastService;
                this.ng1PrinceService = ng1PrinceService;
                this.initService();
            }

            initService() {
                // Get organisation info to add to document
                this.organisationService.getOrganisation()
                    .then(organisation => {
                        this.organisation = organisation;
                        this.brandColor = '#' + (this.organisation.darkColour ? this.organisation.darkColour : '000');

                        // Check organisation has a logo defined before loading.
                        if (this.organisation.webLogo) {
                            this.convertImgToDataUrlViaCanvas(this.$window.EveryBuddy.BlobBase + this.$window.EveryBuddy.LogosPath + this.organisation.webLogo, (base64_data) => {
                                this.base64Logo = base64_data;
                            }, 1000);
                        }

                    })
                    .catch(error => {
                        console.log(error);
                    });

                this.cachedLookupService.fullName().then(name => {
                    this.userName = name;
                });

            }

            convertImgToDataUrlViaCanvas(url: string, callback: any, max_dimension: number = 0) {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    let dataURL;
                    var ch = img.height;
                    var cw = img.width;
                    // limit size of base64 representation ?
                    if (max_dimension > 0) {
                        if (ch>cw && ch>max_dimension) {
                            cw = cw * max_dimension / ch;
                            ch = max_dimension;
                        }
                        else if (cw>max_dimension) {
                            ch = ch * max_dimension / cw;
                            cw = max_dimension;
                        }
                    }
                    canvas.height = ch;
                    canvas.width = cw;
                    // draw image at correct size over a white background in case logo has transparent areas
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, cw, ch);
                    ctx.drawImage(img, 0, 0, cw, ch);
                    dataURL = canvas.toDataURL('image/jpeg', 0.9);
                    callback(dataURL);
                };
                img.src = url;
            }

            getFileTypeEnum() {
                return this.fileTypeEnum;
            }

            getLanguageFormat() {
                return this.languageFormats.filter(format => {
                    return format.id == this.$rootScope.scriptBrowserLanguageCode;
                })[0];
            }

            saveFile(content, fileTypeEnum, fileName) {
                var deferred = this.$q.defer();

                if (fileTypeEnum === this.fileTypeEnum.PDF) {
                    this.ng1PrinceService.exportDataPdf(content, fileName, deferred);
                } else {
                    let formattedContent;
                    if (fileTypeEnum === this.fileTypeEnum.CSV) {
                        formattedContent = this.buildCSV(content.data);
                    } else {
                        formattedContent = content.data;
                    }

                    const data = new Blob([formattedContent], { type: fileTypeEnum.mime });
                    this.FileSaver.saveAs(data, fileName + fileTypeEnum.format);
                    deferred.resolve(true);
                }
                return deferred.promise;
            }

            buildCSV(contentData) {
                let csv = '';
                const format = string => {
                    return '"' + string.replaceAll('"', '""') + '"';
                }
                for (let i = 0; i < contentData.length; i++) {
                    const contentRow = Array.isArray(contentData[i]) ? contentData[i] : contentData[i].content;
                    if (Array.isArray(contentRow)) {
                        contentRow.forEach((cell, cellIndex) => {
                            if (!cell) {
                                return;
                            }
                            if (typeof cell === 'string') {
                                contentRow[cellIndex] = format(cell);
                            }
                            else if (cell.nonData) {
                                // Remove decorative content from CSV
                                contentRow[cellIndex] = '';
                            }
                            else if (cell.content) {
                                // Extract text
                                if (cell.content.nonData) {
                                    contentRow[cellIndex] = '';
                                }
                                else {
                                    contentRow[cellIndex] = format(cell.content);
                                }
                            }
                        })
                        // Only create new row if there is content for it
                        if (contentRow.some(cell => { return cell !== '' })) {
                            const row = contentRow.join(',');
                            csv = csv + row + '\n';
                        }
                    }
                }
                return csv;
            };

            // Legacy method uses pdfmake (now on server)
            buildWithPdfMake(pages, fileName, config) {
                var deferred = this.$q.defer();

                const languageFormat = this.getLanguageFormat();
                // Reverse content for right-to-left
                if (languageFormat && languageFormat.isRtl) {
                    pages.list.forEach(page => {
                        page.tableWidths.reverse();
                        page.data.forEach(item => {
                            if (Array.isArray(item)) {
                                item.reverse();
                            }
                        });
                    });
                }

                const body = [];
                const addHeadingsToBody = obj => {
                    if (obj.headingContent) {
                        if (obj.headingContent[0] || obj.headingContent[1]) {
                            const headerStyle = obj.list ? 'docheader' : 'pageheader';
                            const headerRightStyle = obj.list ? 'docheaderRight' : 'pageheaderRight';
                            if (languageFormat && languageFormat.isRtl) {
                                body.push({
                                    columns: [
                                        { text: this.$filter('translate')(obj.headingContent[1]) || '', style: headerStyle, width: '30%' },
                                        { text: this.$filter('translate')(obj.headingContent[0]) || '', style: headerRightStyle, width: '70%' }]
                                });
                            } else {
                                body.push({
                                    columns: [
                                        { text: this.$filter('translate')(obj.headingContent[0]) || '', style: headerStyle, width: '70%' },
                                        { text: this.$filter('translate')(obj.headingContent[1]) || '', style: headerRightStyle, width: '30%' }]
                                });
                            }
                        }
                        if (obj.headingContent[2]) {
                            body.push({ text: this.$filter('translate')(obj.headingContent[2]) || '', style: 'pagesubheaderRight' });
                        }
                    }
                }
                const addInfoToBody = obj => {
                    if (obj.info) {
                        obj.info.forEach(item => {
                            body.push({
                                text: [{ text: this.$filter('translate')(item.label) + ':', style: 'bold' }, ' ', item.text, '\n\n'],
                            });
                        });
                    }
                }
                if (pages) {
                    pages.list.forEach((page, i) => {
                        const rows = [];
                        // Add top-level details to first page
                        if (i === 0) {
                            // Show logo for case of showOrgInfo - other info to come later
                            if (pages.showOrgInfo || pages.showUserInfo) {
                                const imageColumn = pages.showOrgInfo && this.base64Logo ?
                                    { image: this.base64Logo, fit: [250, 150], style: 'logo', width: '30%' } :
                                    { text: '', width: '30%' };
                                body.push({
                                    columns: [
                                        imageColumn,
                                        { text: pages.showUserInfo && this.userName ? this.userName : '', style: 'pageheaderRight', width: '70%' }]
                                });
                                body.push();
                            }
                            // Add top-level headings to first page
                            addHeadingsToBody(pages);
                            // Add other info to body
                            addInfoToBody(pages);
                        }
                        //Add page-specific details to page
                        // Headings
                        addHeadingsToBody(page);
                        // Info
                        addInfoToBody(page);
                        // Create register table
                        page.data.forEach((row, i) => {
                            let rowStyle = row.style || '';
                            const rowContent = row.content || row;
                            const rowSettings = row.settings || {};
                            // If first row (table header)
                            let tableSpan = page.tableWidths.length;
                            if (i == 0) {
                                const header = [];
                                rowContent.forEach(cell => {
                                    if (!cell.onlyData) {
                                        header.push({ text: (cell.content || cell).toString(), style: 'th' });
                                    }
                                });
                                rows.push(header);
                                // If notes columns
                            } else if (rowSettings.isNoteColumns) {
                                const notesArea = [{
                                    columns: [],
                                    colSpan: tableSpan
                                }];
                                const col1 = {
                                    width: '50%',
                                    text: rowContent[0],
                                    style: 'td'
                                };
                                const col2 = {
                                    width: '50%',
                                    text: rowContent[1],
                                    style: 'td'
                                };
                                notesArea[0].columns.push(col1);
                                notesArea[0].columns.push(col2);
                                rows.push(notesArea);
                                // If marker for signing area, append that content
                            } else if (rowContent == 'signingArea') {
                                const signingArea: any = [{
                                    columns: [],
                                    colSpan: tableSpan
                                }];
                                for (let i = 0; i < (tableSpan - 1); i++) {
                                    signingArea.push('');
                                }
                                // Included signature space if configured
                                if (config?.s.isIncluded) {
                                    const col = {
                                        width: '50%',
                                        text: this.$filter('translate')('SB_Signed') + '\n\n',
                                        style: 'signingArea'
                                    };
                                    signingArea[0].columns.push(col);
                                }
                                // Included time in/out space if configured
                                if (config?.t.isIncluded) {
                                    const col1 = {
                                        width: '25%',
                                        text: this.$filter('translate')('SB_Time_In') + '\n\n',
                                        style: 'signingArea'
                                    };
                                    const col2 = {
                                        width: '25%',
                                        text: this.$filter('translate')('SB_Time_Out') + '\n\n',
                                        style: 'signingArea'
                                    };
                                    signingArea[0].columns.push(col1);
                                    signingArea[0].columns.push(col2);
                                }
                                rows.push(signingArea);
                                // Else just add row and cells content per data passed in
                            } else {
                                let row = [];
                                let rowCols = 0;
                                rowContent.forEach((cell, i) => {
                                    if (!cell.onlyData) {
                                        const rowLength = rowContent.map(cell => { return cell.colspan || 1 }).reduce((a, b) => a + b, 0);
                                        const defaultColSpan = page.tableWidths.length - (rowLength - 1);
                                        const cellColSpan = cell.colspan || (i == 0 ? defaultColSpan : 1);
                                        /* If cell data is array (used for the case of student name/details), first item (name) is put in bold,
                                        rest is formatted as small text) */
                                        if (Array.isArray(cell)) {
                                            const cellContent = [{ text: cell[0].toString(), style: 'tdLabel' }, { text: cell[1].toString(), style: 'tdSmall' }];
                                            // Put on one line if enough space, otherwise two lines
                                            row.push(!page.isTransport || !config?.n.isIncluded ? { text: cellContent, style: 'td' } : cellContent);
                                            // If object with content and style, forat accordingly
                                        } else if (cell.content) {
                                            row.push({ text: cell.content.toString(), style: cell.style, colSpan: cellColSpan });
                                            // Otherwise just shown as a standard td
                                        } else {
                                            if (i == 0 && pages.boldFirstColumn) {
                                                rowStyle = 'tdLabel';
                                            }
                                            row.push({ text: cell.toString(), style: rowStyle, colSpan: cellColSpan });
                                        }
                                        rowCols += cellColSpan;
                                    }
                                });
                                // Add extra empty cells if space left over (cells added above with wider colSpan will replace these but they need to be added)
                                for (let i = 0; i < page.tableWidths.length - rowCols; i++) {
                                    row.push('');
                                }
                                rows.push(row);
                            }
                        });
                        // Append the table to the body
                        body.push({
                            style: 'table',
                            table: {
                                widths: page.tableWidths,
                                body: rows,
                                headerRows: 1,
                                dontBreakRows: true
                            },
                            pageBreak: i < pages.list.length - 1 ? 'after' : ''
                        });
                    });
                }
                // Styling and other file settings
                let align = 'left';
                let alignReverse = 'right';
                if (languageFormat) {
                    align = languageFormat.isRtl ? 'right' : 'left';
                    alignReverse = languageFormat.isRtl ? 'left' : 'right';
                }
                // Colours
                const black = '#000000';
                const grey = '#555555';
                const greyLight = '#999999';
                const greyLighter = '#D3D3D3';
                const docDefinition = {
                    defaultStyle: {
                        font: 'ArialUnicode',
                        alignment: align
                    },
                    info: {
                        title: fileName
                    },
                    pageOrientation: pages.isLandscape ? 'landscape' : 'portrait',
                    content: body,
                    styles: {
                        docheader: {
                            bold: true,
                            color: this.brandColor,
                            fontSize: 18,
                            alignment: 'left',
                            margin: [0, 0, 0, 20]
                        },
                        docheaderRight: {
                            bold: true,
                            color: this.brandColor,
                            fontSize: 18,
                            alignment: 'right',
                            margin: [0, 0, 0, 20]
                        },
                        pageheader: {
                            bold: true,
                            color: black,
                            fontSize: 16,
                            alignment: 'left',
                            margin: [0, 0, 0, 20]
                        },
                        pageheaderRight: {
                            bold: true,
                            color: black,
                            fontSize: 16,
                            alignment: 'right',
                            margin: [0, 0, 0, 20]
                        },
                        pagesubheaderRight: {
                            alignment: alignReverse,
                            margin: [0, 0, 0, 20]
                        },
                        sectionTitle: {
                            bold: true,
                            color: black,
                            fontSize: 13,
                            margin: [2, 4, 2, 4]
                        },
                        subsectionTitle: {
                            bold: true,
                            color: black,
                            fontSize: 11,
                            margin: [2, 3, 2, 3]
                        },
                        table: {
                            color: grey,
                            fontSize: 10
                        },
                        th: {
                            margin: [1, 5, 1, 5],
                            bold: true,
                            color: black,
                        },
                        td: {
                            margin: [1, 2, 1, 2]
                        },
                        tdLabel: {
                            margin: [1, 2, 1, 2],
                            bold: true
                        },
                        tdLabelLg: {
                            margin: [1, 2, 1, 2],
                            bold: true,
                            fontSize: 12,
                        },
                        tdSmall: {
                            margin: [1, 5, 1, 5],
                            fontSize: 9
                        },
                        subheader: {
                            fontSize: 11,
                            color: black,
                            bold: true
                        },
                        bold: {
                            bold: true
                        },
                        signingArea: {
                            color: greyLight,
                            fontSize: 11,
                            margin: [1, 5, 1, 5]
                        },
                        logo: {
                            margin: [0, 0, 0, 20]
                        },
                        muted: {
                            color: greyLighter
                        }
                    }
                };
                // Create the PDF
                if (!docDefinition || !body) {
                    deferred.resolve(false);
                    return deferred.promise;
                }

                try {
                    this.ng1PrinceService.generateWithPdfMake(docDefinition, fileName, deferred);
                }
                catch (err) {
                    this.toastService.error('SB_Error_Loading');
                    console.log(err);
                    deferred.resolve(false);
                }

                return deferred.promise;
            }
        }
    );
