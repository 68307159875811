angular
    .module('transport.services.transportConfigurationStopsService', [])
    .factory('transportConfigurationStopsService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebOrganisationTransportStops/';
            var archivingUrlBase = '/webapi/WebOrganisationTransportArchiving/';

            var service = {};

            service.getTransportStop = function (transportStopId)
            {
                return $http.get(urlBase + 'GetTransportStop', { params: { transportStopId: transportStopId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getStops = function ()
            {
                return $http.get(urlBase + 'GetStops')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.addStop = function (transportStop)
            {
                return $http.post(urlBase + 'AddStop', transportStop)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.editStop = function (transportStop)
            {
                return $http.put(urlBase + 'EditStop', transportStop)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportStopArchivingInfo = function (transportStopId)
            {
                return $http.get(archivingUrlBase + 'GetTransportStopArchivingInfo', { params: { transportStopId: transportStopId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.archiveStop = function (transportStop)
            {
                return $http.put(archivingUrlBase + 'ArchiveStop', { Id: transportStop.id } )
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            return service;
        }
    ]);