import { Injectable } from '@angular/core';
import { StringService } from '@sb-shared/services/string.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  constructor(private string: StringService) { }

  capitaliseObjectKeys(obj: any) {
    if (typeof obj !== 'object') {
      return obj;
    }
    let key;
    const keys = Object.keys(obj);
    let n = keys.length;
    const newobj: any = {}
    while (n--) {
      key = keys[n];
      const newKey = this.string.capitalise(key);
      newobj[newKey] = obj[key];
    }
    return newobj;
  }
}
