'use strict';

angular.module('reception.controllers.addTaskPopupController', [
    'shared.components.sbModalHeader'])
    .controller('addTaskPopupController', [
        '$scope', '$uibModalInstance', 'teamId', 'teamName', 'taskId',
        function ($scope, $uibModalInstance, teamId, teamName, taskId) {

            $scope.teamId = teamId;
            $scope.teamName = teamName;
            $scope.taskId = taskId;

            $scope.newTask = null;

            $scope.changeHandler = function(newTask)
            {
                console.log('change handler fired');

                $scope.newTask = newTask;
                $uibModalInstance.close($scope.newTask);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

        }
    ]);