'use strict';

angular.module('transport.configuration.routes.transportRouteStops',
    [
        'shared.directives.sbLoading',
        'shared.components.sbOnOffSwitch',
        'shared.components.sbAlert',
        'shared.services.moduleService',
        'shared.services.simpleAlertService',
        'transport.services.transportConfigurationStopsService',
    ])
    .component('transportRouteStops',
    {
        bindings: {
            resolve: '<',
            route: '<',
            areAllStopsInactive: '&',
        },
        templateUrl: '/Scripts/app/transport/configuration/routes/transport-route-stops.template.html',
        controller: [
            '$filter',
            '$timeout',
            'transportConfigurationStopsService',
            'simpleAlertService',
            'moduleService',
            function transportRouteAddController($filter, $timeout, transportConfigurationStopsService, simpleAlertService, moduleService)
            {
                this.onIsActiveChanged = function ()
                {
                    $timeout(function ()
                    {
                        this.areAllStopsInactive()(this._routeStopsManager._routeStops);
                        this._routeStopsManager.getTotalRouteTime();
                    }.bind(this), 500);

                }.bind(this);

                //Object to manage route stops functionality with sorting
                var routeStopsManager = function (route, transportStops, allowMinutesOnRouteStopDto)
                {
                    this._transportRoute = route;
                    this._transportStops = transportStops;
                    this._availableStops = [];
                    this._totalRouteTime = 0;
                    this._routeStops = route.routeStops;

                    this.getRouteStops = function ()
                    {
                        return this._routeStops;
                    }.bind(this);

                    this.firstStop = function ()
                    {
                        if (typeof this._routeStops !== 'undefined' && this._routeStops != null)
                        {
                            return this._routeStops[0];
                        }
                    }.bind(this);

                    this.lastStop = function ()
                    {
                        if (typeof this._routeStops !== 'undefined' && this._routeStops != null)
                        {
                            return this._routeStops[this._routeStops.length];
                        }
                    }.bind(this);

                    this.sortByStopOrderAsc = function ()
                    {
                        $filter('orderBy')(this._routeStops, 'stopOrder');
                    }.bind(this);

                    this.moveUp = function (stop)
                    {
                        stop.estimatedMinutesToStop = null;

                        if (this._routeStops.indexOf(stop) == 1)
                        {
                            this._routeStops[0].estimatedMinutesToStop = null;
                        }
                        else
                        {
                            this._routeStops[this._routeStops.indexOf(stop) - 1].estimatedMinutesToStop = null;
                        }

                        this.getTotalRouteTime();

                        this.move(stop, -1);
                        this.resetStopsDisplayOrder();
                    }.bind(this);

                    this.moveDown = function (stop)
                    {
                        stop.estimatedMinutesToStop = null;

                        if (this._routeStops.indexOf(stop) == 0)
                        {
                            this._routeStops[1].estimatedMinutesToStop = null;
                        }
                        else
                        {
                            this._routeStops[this._routeStops.indexOf(stop) + 1].estimatedMinutesToStop = null;
                        }

                        this.getTotalRouteTime();

                        this.move(stop, 1);
                        this.resetStopsDisplayOrder();
                    }.bind(this);

                    this.removeStop = function (stop)
                    {
                        this._routeStops.splice(this._routeStops.indexOf(stop), 1);
                        this.resetStopsDisplayOrder();
                        this.filterAvailableStops();
                        this.getTotalRouteTime();
                    }.bind(this);

                    this.move = function (element, delta)
                    {
                        var arrayToSort = this._routeStops;
                        var index = arrayToSort.indexOf(element);

                        var newIndex = index + delta;

                        if (newIndex < 0 || newIndex == arrayToSort.length)
                        {
                            return; //Already at the top or bottom.
                        }

                        // Sort the indexes using a function because the standard array.sort() function
                        // converts array contents to strings first, which does not work for numeric sorting.
                        var indexes = [index, newIndex].sort(function (a, b)
                        {
                            return a - b;
                        });

                        arrayToSort.splice(indexes[0], 2, arrayToSort[indexes[1]], arrayToSort[indexes[0]]); //Replace from lowest index, two elements, reverting the order

                    }.bind(this);

                    this.resetStopsDisplayOrder = function ()
                    {
                        this._routeStops.forEach(function (item)
                        {
                            item.stopOrder = this._routeStops.indexOf(item);

                            if (allowMinutesOnRouteStopDto.allowMinutesOnRouteStop && item.stopOrder == 0)
                            {
                                item.estimatedMinutesToStop = 0;  //shouldn't be set to positive value on stop 1 (item.stopOrder == 0)
                            }
                        }.bind(this));
                    }.bind(this);

                    this.getAvailableStops = function ()
                    {
                        this.filterAvailableStops();
                        return this._availableStops;
                    }.bind(this);

                    this.getNumberOfAvailableStops = function ()
                    {
                        return this._availableStops.length;
                    };

                    this.filterAvailableStops = function ()
                    {
                        this._availableStops = [];

                        if (typeof this._transportStops !== 'undefined' && this._transportStops != null)
                        {
                            this._transportStops.forEach(function (item)
                            {
                                if (typeof this._routeStops !== 'undefined' && this._routeStops != null)
                                {
                                    var alreadyAssignedAsRoute = $filter('filter')(this._routeStops, { "transportStopId": item.id });

                                    if (typeof alreadyAssignedAsRoute === 'undefined' || alreadyAssignedAsRoute.length === 0)
                                    {
                                        this._availableStops.push(item);
                                    }
                                }
                            }.bind(this));
                        }
                    }.bind(this);

                    this.defaultValueMinutes = function ()
                    {
                        if (!allowMinutesOnRouteStopDto.allowMinutesOnRouteStop) { return null };

                        return this._routeStops.length > 0 ? 5 : 0;

                    }.bind(this);


                    this.addStop = function (stop)
                    {
                        if (typeof this._routeStops === 'undefined')
                        {
                            this._routeStops = [];
                        }

                        this._routeStops.push(
                        {
                            transportStopId: stop.id,
                            stopOrder: this.lastStop() + 1,
                            estimatedMinutesToStop: null,
                            isActive: false,
                            name: stop.name,
                            isStopActive: stop.active
                        });

                        this.resetStopsDisplayOrder();
                        this.filterAvailableStops();

                    }.bind(this);

                    // A route stop can only be moved up if it's included on the route, it's not the first stop and
                    // if more than one stop is included on the route.
                    this.allowMoveUp = function (stop)
                    {
                        return stop.isActive && !this.isFirstIncludedStop(stop) && this.numberOfActiveStops() > 1;
                    }.bind(this);

                    // A route stop can only be moved down if it's included on the route, it's not the last stop and
                    // if more than one stop is included on the route.
                    this.allowMoveDown = function (stop)
                    {
                        return stop.isActive && !this.isLastIncludedStop(stop) && this.numberOfActiveStops() > 1;
                    }.bind(this);

                    this.isLastIncludedStop = function (stop)
                    {
                        for (var i = this._routeStops.length - 1; i >= 0; i--)
                        {
                            if (this._routeStops[i].isActive && this._routeStops[i] == stop)
                            {
                                return true;
                            }
                            else if (this._routeStops[i].isActive)
                            {
                                return false;
                            }
                        };

                        return false;
                    }.bind(this);

                    this.isFirstIncludedStop = function (stop)
                    {
                        for (var i = 0; i <= this._routeStops.length - 1; i++)
                        {
                            if (this._routeStops[i].isActive && this._routeStops[i] == stop)
                            {
                                return true;
                            }
                            else if (this._routeStops[i].isActive)
                            {
                                return false;
                            }
                        };

                        return false;
                    }.bind(this);

                    this.numberOfActiveStops = function ()
                    {
                        var numberOfActiveStops = 0;

                        for (var i = 0; i <= this._routeStops.length - 1; i++)
                        {
                            if (this._routeStops[i].isStopActive && this._routeStops[i].isActive)
                            {
                                numberOfActiveStops++;
                            }
                        };

                        return numberOfActiveStops;
                    }

                    this.getTotalRouteTime = function ()
                    {
                        this._totalRouteTime = 0;

                        if (this.numberOfActiveStops() <= 1)
                        {
                            return;
                        }

                        this._routeStops.forEach(function (item)
                        {
                            if (typeof item.estimatedMinutesToStop != 'undefined' && item.estimatedMinutesToStop != null &&
                                item.isStopActive &&
                                item.isActive &&
                                !this.isFirstIncludedStop(item))
                            {
                                this._totalRouteTime += item.estimatedMinutesToStop;
                            }
                        }.bind(this));
                    }

                };

                this.$onChanges = function (changes)
                {
                    if (changes.route && typeof changes.route.currentValue !== 'undefined')
                    {
                        transportConfigurationStopsService.getStops()
                            .then(function (data)
                            {
                                this._routeStopsManager = new routeStopsManager(changes.route.currentValue, data, this.allowMinutesOnRouteStopDto );
                                this._routeStopsManager.sortByStopOrderAsc();
                                this.areAllStopsInactive()(this._routeStopsManager._routeStops);
                                this._routeStopsManager.getTotalRouteTime();
                            }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                            }.bind(this))
                    }
                }.bind(this);

                this.$onInit = function ()
                {
                    this.allowMinutesOnRouteStopDto = {};

                    moduleService.getAllowMinutesOnRouteStop()
                        .then(function (allowMinutesOnRouteStopDto) {
                            this.allowMinutesOnRouteStopDto = allowMinutesOnRouteStopDto;
                        }.bind(this));
                }.bind(this);
            }
        ]
    });
