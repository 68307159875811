angular.module('clubs.services.clubService', [])
    .factory("clubService", [
        "$http", "$q", "$cacheFactory", "$httpParamSerializerJQLike",
        function ($http, $q, $cacheFactory, $httpParamSerializerJQLike) {

            var urlBase = "/webapi/WebClub/";

            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('clubServiceCache');

            repository.getAllForLookup = function () {

                return $http.get(urlBase + "GetAllForSimpleLookup", { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });

            };

            return repository;
        }
    ]);