import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@sb-shared/shared.module';
import { ROLES_DEPS } from './role-dependencies';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sb-user-roles-editor',
  templateUrl: './user-roles-editor.component.html',
  styleUrls: ['./user-roles-editor.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule]
})
export class UserRolesEditorComponent implements OnInit, OnChanges {
  @Input() userRolesFilter;
  @Input() disabled: boolean;
  @Input() selectedRoles: { name: string; }[];
  @Output() selectionChanged = new EventEmitter<unknown>();
  rolesForm: FormGroup;
  rolesOptions: { name: string, id: number, level?: number, dep?: string, childrenLength?: number }[];
  private readonly rolesDep: ({ roles, dep } | never)[] = [...ROLES_DEPS];

  constructor(private fb: FormBuilder, private translate: TranslateService) { }

  getControl(name: string) {
    return this.rolesForm.get(name);
  }

  ngOnInit(): void {
    const selected = this.userRolesFilter.filter(({ originalValue }) => originalValue).map(({ name }) => name);

    this.rolesOptions = this.getSortedRolesOptions();
    this.rolesForm = this.fb.group({ roles: new FormArray([]) });
    selected.forEach((name) => {
      (this.rolesForm.get('roles') as FormArray).push(new FormControl(name));
    })
  }

  getSortedRolesOptions(): { name: string; id: number; level?: number; }[] {
    const rolesOptions = this.userRolesFilter.map(({ name, id }) => {
      const level = this.getDepLevel(name);
      const dep = this.rolesDep?.find(({ roles }) => roles.includes(name as never))?.dep;
      const childrenLength = this.rolesDep?.find(({ dep }) => dep === name)?.roles.length;

      return { name, id, level, dep, childrenLength };
    });

    if (this.rolesDep?.length) {
      rolesOptions.sort((a, b) => {
        return a.level - b.level;
      });

      this.sortRolesOptions(rolesOptions);
    }

    return rolesOptions;
  }

  sortRolesOptions(rolesOptions) {
    rolesOptions.forEach((role, idx, arr) => {
      if (role.dep) {
        const depIdx = arr.findIndex(({ name }) => name === role.dep);
        const rest = arr.splice(idx);
        const insertIdx = arr.findIndex(({ dep }, i) => i > depIdx && dep !== role.dep);

        arr.splice(insertIdx === -1 ? arr.length : insertIdx, 0, role);
        arr.push(...rest.filter(item => item !== role));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userRolesFilter) {
      this.syncSelections(changes.userRolesFilter);
    }
  }

  onCheckBoxChanged({ name }: { name: string }) {
    if (this.rolesDep?.length) {
      this.handleDeps(name);
    }

    this.toggleCheckbox(name);
  }

  getOptionLabel(option) {
    let label = this.translate.instant(option.name);

    if (option.childrenLength) {
      label += ' <span>(' + option.childrenLength + ')</span>';
    }

    return label;
  }

  private getDepLevel(name: string): number | null {
    if (!this.rolesDep?.length) { return null; }
    let level = 0;
    let depRule = this.rolesDep.find(({ roles }) => roles.includes(name as never));

    while (depRule) {
      name = depRule.dep;
      depRule = this.rolesDep.find(({ roles }) => roles.includes(name as never));
      level++;
    }

    return level;
  }

  private syncSelections(userRolesFilter) {
    if (!this.rolesForm) { return; }

    const { currentValue } = userRolesFilter;
    const selected = currentValue.filter(({ value }) => value).map(({ name }) => name);

    selected.filter(name => !this.rolesForm.get('roles').value.includes(name)).forEach((name) => {
      this.toggleCheckbox(name);
    });
    this.rolesForm.get('roles').value.filter(name => !selected.includes(name)).forEach((name) => {
      this.toggleCheckbox(name);
    });
  }

  private handleDeps(name: string) {
    const changeResult = !this.rolesForm.get('roles').value.includes(name);
    const roles = changeResult ? this.getAllParentRoles(name) : this.getAllChildRoles(name);

    roles.forEach((name) => {
      this.setCheckbox(name, changeResult);
    });
  }

  private getAllChildRoles(name: string): string[] {
    const result = [];
    let depsChildRules = this.rolesDep.filter(({ dep }) => dep === name);

    while (depsChildRules.length) {
      const roles = depsChildRules.map(({ roles }) => roles).flat();

      result.push(...roles);
      depsChildRules = this.rolesDep.filter(({ dep }) => roles.includes(dep as never));
    }

    return result;
  }

  private getAllParentRoles(name: string): string[] {
    const result = [];
    let depsParentRules = this.rolesDep.filter(({ roles }) => roles.includes(name as never));

    while (depsParentRules.length) {
      const deps = depsParentRules.map((rule) => rule.dep);

      result.push(...deps);
      depsParentRules = deps.map(name => this.rolesDep.find(({ roles }) => roles.includes(name as never))).filter(val => !!val);
    }

    return result;
  }

  private toggleCheckbox(name: string) {
    const selectedRoles = (this.rolesForm.get('roles') as FormArray);

    if (!selectedRoles.value.includes(name)) {
      selectedRoles.push(new FormControl(name));
    } else {
      const idx = selectedRoles.value.indexOf(name);
      selectedRoles.removeAt(idx);
    }

    this.selectionChanged.emit(selectedRoles.value);
  }

  private setCheckbox(name: string, forcedValue: boolean) {
    const selectedRoles = (this.rolesForm.get('roles') as FormArray);
    const idx = selectedRoles.value.indexOf(name);

    if (forcedValue && idx === -1) {
      selectedRoles.push(new FormControl(name));
    } else if (!forcedValue && idx > -1) {
      const idx = selectedRoles.value.indexOf(name);

      selectedRoles.removeAt(idx);
    }
  }
}
