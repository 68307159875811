angular.module('balanceAdmin.summary.components.balanceAdminSummaryWidget',
        [
            'balanceAdmin.services.balanceAdminService',
            'balance.services.balanceService',
            'shared.services.mathService'
        ])
    .component('balanceAdminSummaryWidget',
        {
            bindings: {
                type: '<',
                isDashboard: '<',
                lastUpdated: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/balance-admin-summary-widget.template.html',
            controller: ['$state', 'balanceAdminService', 'mathService',
                function ($state, balanceAdminService, mathService )
                {

                    this.$onChanges = function (changes) {
                        if (changes.lastUpdated.currentValue) {
                            this.getBalances();
                        }
                    };

                    this.isLoading = true;

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    var formatAmount = function (amount) {
                        if (amount) {
                            amount = mathService.roundNumber(amount, 2).toFixed(2);
                            if (amount < 0) {
                                return amount.toString().replace('-', '-' + this.currencyDisplaySymbol);
                            }
                            return this.currencyDisplaySymbol + amount;
                        }
                    }.bind(this);

                    this.goToAccountSummaries = function() {
                        if (!this.type) {
                            $state.go('feesDashboard.accountSummaries');
                        }
                    };
                    this.goToCreditAccountSummaries = function() {
                        if (!this.type) {
                            $state.go('feesDashboard.creditAccountSummaries');
                        }
                    };
                    this.goToVoucherAccountSummaries = function() {
                        if (!this.type) {
                            $state.go('feesDashboard.voucherAccountSummaries');
                        }
                    };

                    this.getBalances = function() {
                        balanceAdminService.getAllBalances().then(function(data) {
                            this.balances = data;
                            this.studentBalanceFormatted = formatAmount(this.balances.studentBalance);
                            this.creditBalanceFormatted = formatAmount(this.balances.creditBalance);
                            this.voucherBalanceFormatted = formatAmount(this.balances.voucherBalance);
                            this.isLoading = false;
                        }.bind(this));
                    };
                    this.getBalances();

                    this.imgBase = window.EveryBuddy.CdnBase + 'img/icons/Sebo/features/';
                }
            ]
        });
