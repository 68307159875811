import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventDetails } from '@sb-events/models/event-detail';
import { asUtc } from '@sb-helpers';
import { Buttons } from '@sb-shared/models/UI/buttons';
import { RegisterStateService } from '@sb-shared/services/register-state.service';
import { Subject, merge, takeUntil } from 'rxjs';
import { DiaryEvent } from 'src/app/diary/models/diary-event';
import { DiaryService } from 'src/app/diary/services/diary.service';
import { ComponentBase } from '../component-base/component-base';

@Component({
  selector: 'sb-diary-item-detail',
  templateUrl: './diary-item-detail.component.html',
  styleUrls: ['./diary-item-detail.component.scss']
})
export class DiaryItemDetailComponent extends ComponentBase implements OnChanges, OnDestroy {

  @Input() event: Partial<DiaryEvent>;
  @Input() isSelected: boolean;
  @Input() stateClass: string;
  @Input() stateIcon: string;
  @Input() stateLabel: string;
  @Input() eventDetails: Partial<EventDetails>;
  isLoadingDetails?: boolean = false;
  buttons: Buttons;
  dayLabel: string;
  timesLabel: string;
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  private eventDestroyed$ = new Subject<void>();

  constructor(
    private diary: DiaryService,
    private router: Router,
    public registerState: RegisterStateService,
    public translate: TranslateService
  ) { super() }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      // * Set day and times visible to user * //
      this.dayLabel = this.diary.getDayLabel(this.event as DiaryEvent);
      this.timesLabel = this.diary.getTimesLabel(this.event as DiaryEvent);

      this.getEventDetails();
    }
  }

  getEventDetails() {
    const openEvent = (route: string, modalId?: number) => {
      this.router.navigate(['Diary', this.event?.personId, 'events', this.event?.id, route], { queryParams: { modalId: modalId } });
    }

    if (!this.eventDetails) {
      this.eventUnsubscribe();
      this.isLoadingDetails = true;
      this.diary.getEventDetailForPerson({ eventId: this.event?.id, personId: this.event?.personId })
        .pipe(takeUntil(merge(this.eventDestroyed$, this.destroyed$)))
        .subscribe(eventDetails => {
          this.eventDetails = eventDetails;
          this.isLoadingDetails = false;

          // * Define buttons * //
          if (!!this.event.id && !!eventDetails) {
            this.buttons = [];

            // Join button if organiser
            if (this.event?.isOrganiser) {
              this.buttons.push({
                message: 'SB_Register',
                onClick: () => openEvent('register')
              });
            }

            // Join button for video calls
            if (this.event?.isOnlineParentTeacherConference &&
              new Date(asUtc(eventDetails.onlineRoomStartDateTimeUtc as unknown as string)).getTime() <= Date.now() &&
              new Date(asUtc(eventDetails.onlineRoomEndDateTimeUtc as unknown as string)).getTime() > Date.now()
            ) {
              this.buttons.push({
                message: 'SB_Diary_Video_Open',
                iconName: 'video',
                onClick: () => openEvent('video')
              });
            }

            // Add change request
            if (this.event?.transportAttendee?.showAddChangeRequest) {
              this.buttons.push({
                message: 'SB_Change',
                onClick: () => openEvent('info', 1)
              });
            }

            // View change request
            if (this.event?.transportAttendee?.showViewChangeRequest) {
              this.buttons.push({
                message: 'SB_View_Change_Request',
                onClick: () => openEvent('info', 2)
              });
            }

            if (this.event?.pendingInvite) {
              // Invite response button
              this.buttons.push({
                message: 'SB_Respond',
                onClick: () => this.router.navigate(['Invites', this.event?.personId, this.event?.id])
              });
            } else {
              // Details button for all events
              this.buttons.push({
                message: 'SB_Details',
                onClick: () => openEvent('info')
              });
            }


            // Set first button as primary
            this.buttons[0].buttonClass = 'primary';
          }
        });
    }
  }

  hideEventDetails() {
    return !this.isLoadingDetails &&
      !this.eventDetails?.keyInfo &&
      !this.eventDetails?.eventLocation &&
      !this.eventDetails?.transportTimetableRouteTimeline &&
      !this.eventDetails?.organiserName &&
      !this.eventDetails?.onlineMeetingFormatLabel
  }

  onClickItem() {
    if (this.onClick.observers.length > 0) {
      this.onClick.emit();
      this.buttons[0].onClick();
    }
  }

  openLocation() {

  }

  getStopName() {
    return this.eventDetails?.transportTimetableRouteTimeline?.stops.find(stop => stop.isSelected)?.name;
  }

  private eventUnsubscribe() {
    this.eventDestroyed$.next();
    this.eventDestroyed$.complete();
    this.eventDestroyed$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventUnsubscribe();
  }

}
