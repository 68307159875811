export const CharacterLimits = {
  SingleCharacter: 1,
  StandardMinText: 3,
  StandardMinContent: 10,
  ExtraShort: 40,
  Short: 50,
  Medium: 100,
  Long: 255,
  ExtraLong: 500,
  Content: 10000
} as const;
