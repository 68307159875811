'use strict';

angular.module('events.components.autoNotifyEvent',
    [
    ])
    .component('autoNotifyEvent', {
    templateUrl: '/Scripts/app/events/components/auto-notify-event.template.html',
    bindings: {
        numberOfPeopleNotified: '<',
        onRefresh: '&'
    },
    controller: [
        function autoNotifyEventController() { }
    ]
});