'use strict';

angular.module('shared.services.mathService', [])
    .service('mathService', ['$window',
        function ($window) {

            var service = this;

            // requires big.js

            const Big = $window.Big;

            if (typeof Big === 'undefined') {
                console.error('missing Big.Js reference on the page');
            } else {
                // set some defaults for the "bigjs" library (http://mikemcl.github.io/big.js/#rm)
                /*
                0	Rounds towards zero.
                    I.e.truncate, no rounding.ROUND_DOWN
                1	Rounds towards nearest neighbour.
                    If equidistant, rounds away from zero.ROUND_HALF_UP
                2	Rounds towards nearest neighbour.
                    If equidistant, rounds towards even neighbour.ROUND_HALF_EVEN
                3	Rounds away from zero.ROUND_UP
                */
                Big.RM = 1; // rounding mode ROUND_HALF_UP
                Big.DP = 2;
            }

            service.roundNumber = function (number, decimalPlaces) {
                var bigNumber = null;
                try {
                    bigNumber = new Big(number);
                }
                catch (err) {
                    //problem parsing number
                    console.warn('cannot parse number for rounding- passing back');
                    return number;
                }

                var roundedNumber = bigNumber.round(decimalPlaces);

                return parseFloat(roundedNumber);
            };
        }]);
