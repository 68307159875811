'use strict';

angular.module('reception.components.receptionEventsNotifyOnly',
    [
        'events.components.notifyOnlyEvent',
    ]).component('receptionEventsNotifyOnly', {
        templateUrl: '/Scripts/app/reception/components/reception-events-notify-only.template.html',
        bindings: {
            eventId: '<',
            numberOfFutureEventsInSeries: '<',
        },
        controller: [
            function receptionEventsNotifyOnlyController()
            {
            }
        ]
    });