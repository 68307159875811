'use strict';

angular.module('shared.services.balanceSharedService',
    ['shared.constants'])
    .service('balanceSharedService', ['creditTypeEnum',
        function (creditTypeEnum) {

            var service = this;

            service.arrangeTransactionData = function (transactions, openingBalance, includeSource, accountOwnerId) {

                var i = 0;
                var j = 0;
                var k = 0;

                transactions.push({
                    id: 0,
                    accountOwnerId: accountOwnerId,
                    balance: openingBalance,
                    credit: 0.0,
                    debit: 0.0,
                    reference: '',
                    description: '',
                    date: '1900-01-01T00:00:00',
                    isOpeningBalance: true
                });

                for (i = transactions.length; i--;) {

                    var parentTransaction = transactions[i];
                    parentTransaction.relatedTransactions = [];
                    parentTransaction.sourceTransactions = [];
                    parentTransaction.sourceTransactionIds = [];
                    parentTransaction.date = moment(parentTransaction.date).toDate();

                    // now whip back through all the transactions looking for anything which is related to parentTransaction
                    for (j = transactions.length; j--;) {
                        if ((transactions[j].relatedTransactionId === parentTransaction.id &&
                            transactions[j].accountOwnerId === accountOwnerId) ||
                            (parentTransaction.relatedTransactionId === transactions[j].id &&
                                transactions[j].accountOwnerId === accountOwnerId)) {
                            parentTransaction.relatedTransactions.push(transactions[j]);
                        }

                        if (includeSource &&
                            parentTransaction.accountOwnerId === accountOwnerId &&
                            transactions[j].relatedTransactionId &&
                            ((transactions[j].sourceTransactionId === parentTransaction.id) ||
                                (parentTransaction.sourceTransactionId === transactions[j].id))) {
                            parentTransaction.sourceTransactionIds.push(transactions[j].relatedTransactionId);
                        }
                    }

                    if (includeSource) {
                        // go through again and get anything related to the source transaction
                        for (j = transactions.length; j--;) {
                            if (parentTransaction.accountOwnerId === accountOwnerId &&
                                (parentTransaction.sourceTransactionIds.indexOf(transactions[j].id) > -1) ||
                                (parentTransaction.sourceTransactionIds.indexOf(transactions[j].relatedTransactionId) > -1)) {
                                parentTransaction.sourceTransactions.push(transactions[j]);
                            }
                        }
                    }

                    // lets check to see if this row has a balance or just a "provisional balance"
                    if (transactions[i].balancePendingUpdate) {
                        // it is!  let's walk back through the transactions until we find the last stable balance,
                        // (in reality, this will probably only involve walking back one step)
                        // then walk forwards applying credits and debits to try and come up with a provisional balance

                        console.log('balance.components.currentUsertransactionView/found transaction with balancePendingUpdate', transactions[i]);

                        var balance = openingBalance;

                        if (i > 0) {
                            for (j = i - 1; j >= 0; j--) {
                                if (!transactions[j].balancePendingUpdate) {
                                    // we have ourselves a winner!

                                    balance = transactions[j].balance;
                                    break;
                                }
                            }
                        }

                        // now walk from j back to i, filling in the balances as we go
                        // stay with me- I'm getting a nose bleed just writing this..
                        for (k = j + 1; k <= i; k++) {
                            balance += transactions[k].credit;
                            balance -= transactions[k].debit;
                            transactions[k].balance = balance;
                        }

                        break;
                    }
                }

                return transactions;

            };

            service.copyTransactionsWhichShouldBeVisible = function (source, dest, fromDate, toDate) {

                for (var i = 0; i < source.length; i++) {

                    var currentTransactionDate = source[i].date;

                    if ((currentTransactionDate > fromDate || source[i].isOpeningBalance) &&
                        currentTransactionDate < toDate) {
                        dest.push(source[i]);
                    }
                }
            };

            service.getLabelTagForCreditType = function (creditTypeId) {
                switch (creditTypeId) {
                    case creditTypeEnum.None:
                        return 'SB_None';

                    case creditTypeEnum.Cancellation:
                        return 'SB_Cancellation';

                    case creditTypeEnum.Correction:
                        return 'SB_Correction';

                    case creditTypeEnum.WriteOff:
                        return 'SB_Write_Off';

                    case creditTypeEnum.Discount:
                        return 'SB_Discount';

                    default:
                        return 'SB_None';
                }
            }

        }
    ]);
