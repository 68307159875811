import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sb-on-off-switch',
  templateUrl: './on-off-switch.component.html',
  styleUrls: ['./on-off-switch.component.scss']
})
export class OnOffSwitchComponent {

  @Input() model: boolean;
  @Output() modelChange = new EventEmitter<boolean>();
  // @Input() show: boolean; Removed for Angular 12
  @Input() label: string;
  @Input() isDisabled: boolean; // Renamed for Angular 12
  @Input() isChecked: boolean;
  @Input() isRequired: boolean;
  @Input() switchClass: string;
  @Input() id: string;
  @Input() trueLabel: string;
  @Input() falseLabel: string;
  @Input() disabledMessage: string;
  @Input() description: string;

  onCheckboxClick() {
    this.model = !this.model;
    this.modelChange.emit(this.model);
  }
}
