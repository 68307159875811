'use strict';

angular.module('shared.components.sbSearchFilterItem', [])
    .component('sbSearchFilterItem',
    {
        bindings: {
            name: '<'
        },
        transclude: true,
        templateUrl: '/Scripts/app/shared/components/sb-search-filter-item.template.html',
        controller: class SbSearchFilterItemCtrl {},
    })