angular.module('corporateContacts.components.corporateContactList',
    [
        'corporateContacts.services.corporateContactsService',
        'shared.components.sbIcon',
        'shared.components.sbColumnHeader',
        'pascalprecht.translate'
    ])
    .component('corporateContactList',
    {
        bindings: {
            updatedContact: '<',
            onSelect: '&',
            selectedContactId: '<',
            contactType: '<'
        },
        templateUrl: '/Scripts/app/corporateContacts/components/corporate-contact-list.template.html',
        controller: [
            'corporateContactsService',
            function corporateContactListController(corporateContactsService) {

                var loading = 0;

                this.contacts = [];
                this.selectedContact = null;
                this.sortType = 'name'; // set the default
                this.user_filter = { name: undefined };

                var self = this;

                this.isLoading = function() { return self.loading > 0; };

                this.$onChanges = function (changes) {

                    if (changes.contactType)
                    {
                        var contactType = typeof (changes.contactType.currentValue) === 'number'
                            ? changes.contactType.currentValue
                            : 0;

                        corporateContactsService.getAllContacts(contactType)
                            .then(function(data)
                            {
                                self.contacts = data;

                                if (self.selectedContactId)
                                {
                                    for (var i = self.contacts.length; i--;)
                                    {
                                        if (self.contacts[i].id === self.selectedContactId)
                                        {
                                            self.selectedContact = self.contacts[i];
                                            break;
                                        }
                                    }
                                }

                                self.loading--;
                            });
                    }

                    if (changes.selectedContactId && changes.selectedContactId.currentValue)
                    {
                        for (var i = self.contacts.length; i--;) {
                            if (self.contacts[i].id === changes.selectedContactId.currentValue) {
                                self.selectedContact = self.contacts[i];
                                return;
                            }
                        }
                    }

                    if (changes.updatedContact && changes.updatedContact.currentValue)
                    {
                        var contact = changes.updatedContact.currentValue;

                        // do something with

                        for (var i = self.contacts.length; i--;) {
                            if (self.contacts[i].id === contact.id) {
                                self.contacts[i].name = contact.name;
                                self.contacts[i].typeId = contact.typeId;
                                self.contacts[i].isDeleted = contact.isDeleted;
                                self.selectedContact = self.contacts[i];
                                return;
                            }
                        }

                        // this looks like an add- do we have a place holder for this?
                        for (var i = self.contacts.length; i--;) {
                            if (self.contacts[i].id === 0)
                            {
                                self.contacts[i].id = contact.id;
                                self.contacts[i].name = contact.name;
                                self.contacts[i].typeId = contact.typeId;
                                self.contacts[i].isDeleted = contact.isDeleted;
                                self.selectedContact = self.contacts[i];
                                return;
                            }
                        }

                        // no place holder, just add it to the list
                        var newContact = {
                            id: contact.id,
                            name: contact.name,
                            typeId: contact.typeId,
                            isDeleted: contact.isDeleted
                        };

                        self.contacts.push(newContact);
                        self.selectedContact = newContact;
                    }
                };



                this.selectContact = function (contact) {
                    self.selectedContact = contact;

                    self.onSelect()(contact);
                };
            }
        ]
    });