// start:ng42.barrel
import './groups';
import './message-center.controller';
import './reception-attendance.controller';
import './reception-dashboard.controller';
import './reception-events-details.controller';
import './reception-events-formtags.controller';
import './reception-events-guardians.controller';
import './reception-events-medical.controller';
import './reception-events.controller';
import './reception-joinrequests.controller';
import './reception-reggroups.controller';
import './reception-staff-schedule.controller';
import './reception-tasks.controller';
import './reception-users-diary.controller';
import './reception-users.controller';
import './receptionreports-controller_v1';
import './status365';
import './user.linked.controller';
import './user.membership.controller';
// end:ng42.barrel

