'use strict';

angular.module('shared.services.moduleSetupService', [
    'shared.services.authInterceptor',
    'shared.services.dateTimeInterceptor',
    'shared.services.urlInterceptor'
])
    .service('moduleSetupService', ['$injector', '$rootScope', 'tmhDynamicLocale',
        function ($injector, $rootScope, tmhDynamicLocale) {
            var service = this;

            service.setupHttpProvider = function (provider, version) {
                provider.useApplyAsync(true);
                if (!provider.defaults.headers.get) {
                    provider.defaults.headers.get = {};
                }

                // provider.defaults.headers.get['Cache-Control'] = 'no-cache';
                // provider.defaults.headers.get['Pragma'] = 'no-cache';
                provider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

                // add the 401 catch to push back to the login screen
                provider.interceptors.push('authInterceptor');
                // add interceptor for UTC <-> local time conversions
                provider.interceptors.push('dateTimeInterceptor');
                // add interceptor for url for Angular 12
                provider.interceptors.push('urlInterceptor');

                // add cache busting for html templates
                // https://stackoverflow.com/questions/44901237/angular-template-versioning
                provider.interceptors.push(['$q', function ($q) {
                    return {
                        'request': function (request) {
                            if (
                                request.url.substr(-5) === '.html' // only html's with no version already appended
                                && request.url.indexOf('/') > -1 // exclude templates with no slashes (paths (those included on the page)
                                && request.url.indexOf('uib/') === -1 // exclude uib templates
                                && (request.url.substring(0, 1) === '/' || request.url.indexOf('://') > -1) // exclude templates that don't start with / or contain a protocol
                            ) {
                                request.params = {
                                    v: version
                                };
                            }
                            return $q.resolve(request);
                        }
                    };
                }]);
            };

            service.setupTranslations = function (translateProvider) {
                const translateService = $injector.get('translateService');
                const currentLangService = $injector.get('currentLangService');

                translateService.onLangChange.subscribe(langChangeEvent => {
                    translateProvider.translations(langChangeEvent.lang, langChangeEvent.translations);
                    translateProvider.use(langChangeEvent.lang);
                });

                currentLangService.currentLang.subscribe(language => {
                    $rootScope.scriptDirection = language.scriptDirection;
                    $rootScope.scriptBrowserLanguageCode = language.languageCode + '_' + language.countryCode.toUpperCase();
                    $rootScope.scriptLanguage = language.languageTitle;
                    tmhDynamicLocale.set(language.languageTag)
                        .then(function () {
                            $rootScope.scriptLanguageLastUpdated = new Date();
                        });
                });
            }

            return service;
        }
    ]);
