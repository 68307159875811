"use strict";

angular.module('ccasignupModule').component("ccaLocations", {
    bindings: {
        isAA: '<'
    },
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-locations.template.html',
    controller: [
    "$scope", "$location", "$state", '$filter', "$uibModal", "signUpRepository", function ($scope, $location, $state, $filter, $uibModal, signUpRepository) {

        $scope.currentPath = $state.current;
        $scope.signUp = {};

        var signUpId = $state.params.signUpId;

        $scope.sortType = "name"; // set the default sort type
        $scope.sortReverse = false; // set the default sort order

        signUpRepository.getSignUp(signUpId).then(function(signUp) { $scope.signUp = signUp; });

        signUpRepository.getAllLocations().then(function(locations) { $scope.locations = locations; });

        $scope.newLocation = function ()
        {
            if (this.isAA)
            {
                $state.go("ccaDetailsAA.ccaLocationManage", { locationId: 0, isAA: this.isAA });
            }
            else
            {
                $state.go("ccaDetails.ccaLocationManage", { locationId: 0 });
            }
        }.bind(this);

        $scope.deleteLocation = function (locationId)
        {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: "confirmDeleteLocationModal.html",
                controller: "ConfirmDeleteModalInstanceCtrl",
                size: "sm"
            });

            modalInstance.result
                .then(function ()
                {
                    $scope.groups = null;

                    signUpRepository.postDeleteLocation(locationId)
                        .then(function ()
                        {
                            signUpRepository.getAllLocations()
                                .then(function (locations)
                                {
                                    $scope.locations = locations;
                                })}
                        );
                }, function ()
                {
                });
        };

        $scope.editLocation = function (locationId) {
            if (this.isAA) {
                $state.go("ccaDetailsAA.ccaLocationManage", { locationId: locationId, isAA: this.isAA });
            } else {
                $state.go("ccaDetails.ccaLocationManage", { locationId: locationId });
            }
        }.bind(this);

        $scope.locationTypeText = function (typeId) {
            switch (typeId) {
                case 0:
                    return 'SB_Home';
                case 1:
                    return 'SB_Away';
                default:
                    return 'SB_Home';
            }
        };
    }
]});

angular.module('ccasignupModule').controller("ConfirmDeleteModalInstanceCtrl", [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss("cancel");
    };
}]);
