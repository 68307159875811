angular.module('sysadmin.components.organisationQueueRunButton', [
    'sysadmin.services.recentQueueRunService',
    'shared.components.sbSubmitButton',
    'shared.services.simpleAlertService'
]).component('organisationQueueRunButton', {
    templateUrl: '/Scripts/app/sysadmin/components/organisation-queue-run-button.template.html',
    bindings: {
        organisationId: '<',
        queueName: '@',
        buttonText: '@',
        // The func passed here should always return a promise. This allows the component to wait for the func to be queued
        // before checking status.
        onClick: '&',
        onSetDisplayText: '&'
    },
    controller: class OrganisationQueueRunButton {
        // Dependencies
        recentQueueRunService: any;
        queueRunStatusEnum: any;
        simpleAlertService: any;

        //Bindings
        organisationId: number;
        queueName: string;
        buttonText: string;
        onClick: any;
        onSetDisplayText: any;

        // Variables
        statusText: string;
        statusDate: Date;
        textClasses: string;
        currentStatusId: number;
        messageId: string;
        showConfirmation: boolean;
        stopCheck: boolean;

        // Constants
        readonly CHECK_INTERVAL_MS = 5000;

        static $inject = ['recentQueueRunService', 'queueRunStatusEnum', 'simpleAlertService'];

        constructor(recentQueueRunService, queueRunStatusEnum, simpleAlertService) {
            this.recentQueueRunService = recentQueueRunService;
            this.queueRunStatusEnum = queueRunStatusEnum;
            this.simpleAlertService = simpleAlertService;
        }

        $onChanges(changes) {
            if (changes.organisationId && changes.organisationId.currentValue != null)
            {
                this.reset();
            }
        }

        reset() {
            this.statusText = null;
            this.statusDate = null;
            this.currentStatusId = null;
            this.messageId = null;
            this.showConfirmation = true;
            this.stopCheck = false;

            this.doCheck();
        }

        callOnSetDisplayText() {
            if (this.onSetDisplayText && typeof (this.onSetDisplayText()) === 'function') {
                this.onSetDisplayText()(this.statusText);
            }
        }

        doCheck() {
            this.recentQueueRunService.getRecentQueueRun(this.organisationId, this.queueName).then(response => {
                const queueRun = response.data;
                if (queueRun && (queueRun.statusId !== this.currentStatusId || queueRun.messageId !== this.messageId)) {
                    this.setDisplayText(queueRun);
                    this.callOnSetDisplayText();
                }

                this.currentStatusId = queueRun?.statusId;

                if (!queueRun || (
                    (queueRun.statusId === this.queueRunStatusEnum.Failed || queueRun.statusId === this.queueRunStatusEnum.Successful)
                        && queueRun.messageId !== this.messageId
                )) {
                    this.stopCheck = true;
                    this.showConfirmation = false;
                    this.messageId = queueRun?.messageId;
                }

                if (!this.stopCheck) {
                    setTimeout(() => this.doCheck(), this.CHECK_INTERVAL_MS);
                }
            });
        }

        setDisplayText(queueRun: any) {
            switch (queueRun.statusId) {
                case this.queueRunStatusEnum.Queued:
                    this.statusText = 'SB_Queued_With_Time';
                    this.statusDate = queueRun.queueTimeUtc;
                    this.textClasses = null;
                    break;
                case this.queueRunStatusEnum.Running:
                    this.statusText = 'SB_Running_With_Time';
                    this.statusDate = queueRun.startTimeUtc;
                    this.textClasses = null;
                    break;
                case this.queueRunStatusEnum.Failed:
                    this.statusText = 'SB_Failed_With_Time';
                    this.statusDate = queueRun.endTimeUtc;
                    this.textClasses = 'text-danger';
                    break;
                case this.queueRunStatusEnum.Successful:
                    this.statusText = 'SB_Completed_With_Time';
                    this.statusDate = queueRun.endTimeUtc;
                    this.textClasses = 'text-success';
                    break;
            }
        }

        buttonClick() {
            if (this.showConfirmation) {
                this.simpleAlertService.simpleAlert({
                    title: 'SB_Org_Queue_Run',
                    message: 'SB_Org_Queue_Run_Confirm',
                    okButtonText: 'SB_Yes',
                    cancelButtonText: 'SB_Cancel',
                    windowSize: 'md'
                }).result.then(() => this.doClick());
            } else {
                this.doClick();
            }
        }

        doClick() {
            this.stopCheck = false;
            this.showConfirmation = true;
            this.onClick().then(() => this.doCheck());
        }
    }
});
