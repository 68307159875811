

angular.module('ccaavailableModule').component('ccaAvailableConfirmation', {
    templateUrl: "/Scripts/app/ccaAvailable/Details/cca-available-confirmation.template.html",
    controller:   [
        '$scope',
        '$state',
        '$filter',
        '$interval',
        '$window',
        '$timeout',
        'availableRepository',
        'organisationAvailableRepository',
        'simpleAlertService',
        'groupService',
        'paymentsService',
        'paymentSessionTypeEnum',
        'diaryService',
        'personService',
        'blobStoragePhotosService',
        'ccaService',
        'toastService',
        'eventCategorySystemUsageTypeEnum',
        function (
            $scope,
            $state,
            $filter,
            $interval,
            $window,
            $timeout,
            availableRepository,
            organisationAvailableRepository,
            simpleAlertService,
            groupService,
            paymentsService,
            paymentSessionTypeEnum,
            diaryService,
            personService,
            blobStoragePhotosService,
            ccaService,
            toastService,
            eventCategorySystemUsageTypeEnum) {

            organisationAvailableRepository.get().then(function (organisation) { $scope.organisation = organisation; });

            const eventStatusEnum = $window.EveryBuddy.Enums.CalendarEventViewAttendeeStatus;
            $scope.signUp = {};
            $scope.userIsInTeamWhichAllowsVoucherPayments = false;
            $scope.adjacentEventsLoaded = false;

            $scope.timer = null;
            $scope.oldestBookingExpiry = null;

            $scope.currencyDisplaySymbol =  $window.EveryBuddy.Constants.CurrencyDisplaySymbol;

            $scope.hasBookingSelectionTimedOut = false;

            $scope.loading = 1;

            paymentsService.organisationHasPaymentProviders()
                .then((hasPaymentProviders => {
                    $scope.hasPaymentProviders = hasPaymentProviders;

                    availableRepository.getSignUp($state.params.signUpId)
                    .then(function (signUp) {
                        $scope.signUp = signUp;

                        if (!$scope.signUp.limitVoucherPaymentsToGroup)
                        {
                            $scope.userIsInTeamWhichAllowsVoucherPayments = true;
                        } else
                        {
                            groupService.areAnyOfCurrentPersonsChildrenInTeam($scope.signUp.limitVoucherPaymentsToGroup)
                                .then(function(answer)
                                {
                                    $scope.userIsInTeamWhichAllowsVoucherPayments = answer;
                                });
                        }

                        availableRepository
                        .getSignUpBookedEvents($state.params.signUpId)
                        .then(function (eventsData) {
                            $scope.pupils = eventsData.pupils;

                            $scope.signUpEvents = [];

                            // for booking confirmation, we are only interested in events in reserved state
                            $scope.signUpEvents = eventsData.availableEvents.filter(function(signUpEvent) {
                                return signUpEvent
                                    .pupils
                                    .filter(function(signUpEventPupil) {
                                        return signUpEventPupil.status === 1; // Reserved
                                    })
                                    .length > 0;
                            });

                            const hasNonFeeEvents = $scope.signUpEvents.some(event => {
                                return event.systemUsageTypeId !== eventCategorySystemUsageTypeEnum.Fee;
                            })

                            $scope.minutesUntilBookingExpiry = eventsData.minutesUntilBookingExpiry;

                            if ($scope.signUpEvents.length > 0)
                            {
                                let bookingExpiryInfo = ccaService.getBookingExpiryInfo($scope.signUpEvents);
                                $scope.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;
                                $scope.timeRemaining = bookingExpiryInfo.timeRemaining;

                                $scope.loading += 1;
                                $scope.otherEvents = [];

                                if ($scope.signUp.isTimeSlot || !hasNonFeeEvents)
                                {
                                        $scope.initEvents();
                                        $scope.loading -= 1;
                                        return;
                                }

                                // Get date range to look up existing events for
                                const fromDates = $scope.signUpEvents.map(event => {return event.from}).sort((a, b) => b - a);
                                const toDates = $scope.signUpEvents.map(event => {return event.to}).sort((a, b) => b - a);
                                const start = moment.utc(fromDates[0]).subtract(2, 'hours').toDate();
                                const end = moment.utc(toDates[toDates.length - 1]).add(2, 'hours').toDate();

                                // Look up diary events
                                personService.getCurrentPersonId().then(currentPersonId => {
                                    diaryService.getEventsForPerson(
                                        currentPersonId,
                                        start,
                                        end,
                                        false,
                                        false,
                                        false,
                                        -1)
                                        .then(data =>
                                        {
                                            $scope.otherEvents = data.filter(event => {
                                                // Return diary events which are actually happening
                                                // TODO: Show all but format differently in UI
                                                const status = event.statusId;
                                                return !event.isCancelled &&
                                                (status === eventStatusEnum.Selected ||
                                                status === eventStatusEnum.Notified ||
                                                status === eventStatusEnum.Attending);
                                            });
                                            // Set events as isMultiDay if so
                                            $scope.otherEvents.forEach(event => {
                                                const otherStart = moment(event.eventDate);
                                                const otherEnd = moment(event.eventEndDate);
                                                event.isMultiDay = !otherStart.isSame(otherEnd, 'day');
                                            });
                                            $scope.initEvents();
                                        })
                                        .finally(() => {
                                            $scope.loading -= 1;
                                        })
                                });
                            }
                        });
                    });
                }))
                .catch(err =>
                    {
                        simpleAlertService.errorAlert(err);
                    })
                .finally(() => {
                    $scope.loading -= 1;
                })

            $scope.createPaymentSession = function (providerId, amountToPayWithCredits)
            {
                $scope.loading += 1;

                let bookingExpiryInfo = ccaService.getBookingExpiryInfo($scope.signUpEvents);
                $scope.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;
                $scope.timeRemaining = bookingExpiryInfo.timeRemaining;

                var signatureRequest = {
                    paymentSessionType: paymentSessionTypeEnum.Booking,
                    paymentProvider: providerId,
                    useCreditBalance: (amountToPayWithCredits && amountToPayWithCredits > 0),
                    creditBalanceAmount: amountToPayWithCredits,
                    ccaSignUpId: $state.params.signUpId,
                    paymentSessionRequestItems: []
                };

                for (var i = $scope.signUpEvents.length; i--;) {

                    var thisEvent = $scope.signUpEvents[i];

                    for (var j = thisEvent.pupils.length; j--;) {

                        var thisPupil = thisEvent.pupils[j];

                        if (thisPupil.selectionCount === 1 && thisPupil.status === 1) {

                            signatureRequest.paymentSessionRequestItems.push({
                                calendarEventId: thisEvent.calendarEventId,
                                owningPersonId: thisPupil.personId
                            });
                        }
                    }
                }

                return paymentsService
                    .createPaymentSession(signatureRequest)
                    .then(function(data)
                    {
                        if (!data.error) {
                            $scope.paymentSessionId = data.sid;
                            $scope.paymentSignature = data.sig;
                            return data;
                        }
                        else {
                            throw { isError: true, message: data.error };
                        }
                    })
                    .finally(() => {
                        $scope.loading -= 1;
                    })
            };

            $scope.initEvents = () =>
            {
                $scope.signUpEvents = ccaService.applySelectionToBlockEvents($scope.signUpEvents);
                $scope.loading += 1;
                $scope.hasClashes = false;
                $scope.hasSelectedPupils = false;
                const newEvents = [];
                const addAdjacentEvents = event =>
                {
                    event.hasClashes = false;
                    event.displayedPupils = [];
                    event.selectedPupils = [];
                    event.otherPupils = [];
                    // Get adjacent events for each pupil
                    const newEventPupils = [];
                    event.pupils.forEach(pupil =>
                    {
                        const existingAdjacentEvents = [];
                        let newAdjacentEvents = [];
                        const isSelected = pupil.selectionCount === 1 && pupil.status === 1;
                        // Check existing events for adjacents/clashes
                        const checkOverlap = (otherEvent, onlyClash: boolean) => { return !$scope.signUp.isTimeSlot && ccaService.checkOverlap(event, pupil, otherEvent, onlyClash) };
                        $scope.otherEvents.forEach(otherEvent =>
                        {
                            const adjacent = checkOverlap(otherEvent, false);
                            if (adjacent)
                            {
                                const adjacentEvent = { ...otherEvent };
                                // Check if adjacent event is a clash with new event
                                adjacentEvent.isClash = checkOverlap(adjacentEvent, true) && isSelected;
                                existingAdjacentEvents.push(adjacentEvent);
                                if (adjacentEvent.isClash)
                                {
                                    // Update clashes data
                                    pupil.hasClashes = true;
                                    event.hasClashes = true;
                                    event.isExpanded = true;
                                    $scope.hasClashes = true;
                                }
                            }
                        });
                        // Check other new events for adjacents/clashes
                        $scope.signUpEvents.forEach(otherSignUpEvent =>
                        {
                            const addToAdjacentEvents = otherEvent =>
                            {
                                const adjacent = checkOverlap(otherEvent, false);
                                const signUpClash = checkOverlap(otherEvent, true) && isSelected;
                                if (adjacent)
                                {
                                    // Add new event to the list of events to show alongside adjacent events
                                    let parentEvent = {
                                        from: null,
                                        to: null
                                    };
                                    if (otherEvent.parentCalendarEventId)
                                    {
                                        parentEvent = $scope.signUpEvents.filter(signUpEvent =>
                                        {
                                            return signUpEvent.calendarEventId === otherEvent.parentCalendarEventId;
                                        })[0];
                                    }
                                    const newAdajcentEvent = {
                                        parentCalendarEventId: otherEvent.parentCalendarEventId,
                                        id: otherEvent.id || otherEvent.calendarEventId,
                                        eventDate: otherEvent.from,
                                        eventEndDate: otherEvent.to,
                                        title: otherEvent.title,
                                        ownerName: otherEvent.teamTitle,
                                        isNew: true,
                                        isCurrentEvent: otherEvent.calendarEventId === event.calendarEventId,
                                        isClash: signUpClash,
                                        parentFrom: parentEvent.from,
                                        parentTo: parentEvent.to
                                    }
                                    newAdjacentEvents.push(newAdajcentEvent);
                                    if (signUpClash)
                                    {
                                        pupil.hasClashes = true;
                                        event.hasClashes = true;
                                        event.isExpanded = true;
                                        $scope.hasClashes = true;
                                    }
                                }
                            }
                            addToAdjacentEvents(otherSignUpEvent);
                            if (otherSignUpEvent.remainingSessionEvents)
                            {
                                otherSignUpEvent.remainingSessionEvents.forEach(otherSession =>
                                {
                                    addToAdjacentEvents(otherSession);
                                })
                            }
                        });
                        const adjacentEvents = existingAdjacentEvents.concat(newAdjacentEvents);
                        // Push pupil to displayed, selected list or other list
                        pupil.adjacentEvents = $filter('orderBy')(adjacentEvents, 'eventDate');
                        if (isSelected || pupil.isDeleted)
                        {
                            event.displayedPupils.push({ ...pupil });
                            if (isSelected)
                            {
                                event.selectedPupils.push({ ...pupil });
                                $scope.hasSelectedPupils = true;
                            }
                        }
                        else if (adjacentEvents.length > 0)
                        {
                            event.otherPupils.push({ ...pupil });
                            event.othersIsCollapsed = true;
                        }
                        newEventPupils.push({ ...pupil });
                    }); event.pupils = newEventPupils;
                    return event;
                }

                $scope.signUpEvents.forEach(event =>
                {
                    let newEvent;
                    event.isFeeOnly = event.systemUsageTypeId === eventCategorySystemUsageTypeEnum.Fee;
                    if (event.remainingSessionEvents)
                    {
                        let sessions = event.remainingSessionEvents;
                        sessions.forEach(session =>
                        {
                            session.pupils = event.pupils;
                            session = addAdjacentEvents(session);
                        });
                        newEvent = {
                            ...event,
                            displayedPupils: event.pupils.filter(pupil =>
                            {
                                return pupil.selectionCount === 1;
                            }),
                            remainingSessionEvents: sessions
                        };
                        newEvent.hasClashes = newEvent.remainingSessionEvents.some(session =>
                        {
                            return session.hasClashes;
                        });
                        newEvent.isExpanded = newEvent.isExpanded || newEvent.hasClashes;
                    }
                    else
                    {
                        newEvent = addAdjacentEvents({ ...event });
                    }
                    const formatDate = date => { return $filter('date')(date, 'EEE dd MMM yyyy') };
                    newEvent.dateLabel = formatDate(event.from) + (moment(event.from).isSame(event.to, 'day') ? '' : ' - ' + formatDate(event.to));
                    newEvent.dateCount = $scope.otherEvents.filter(otherEvent => { return $scope.isSameDay(event, otherEvent) }).length;
                    newEvents.push(newEvent);
                });

                $scope.signUpEvents = [].concat(newEvents);
                $scope.adjacentEventsLoaded = true;
                blobStoragePhotosService.addStudentPhotoUrlsToNestedArrays($scope.signUpEvents).then(array =>
                {
                    $scope.signUpEvents = array;
                });

                $scope.loading -= 1;
                $scope.calculateTotalPayment();
                $scope.calculateTotalVoucherPayment();

                if ($scope.hasSelectedPupils)
                {
                    // We need to get booked events here so that the booking expiry time remaining is accurate.
                    // This is to cater for a user deleting all bookings and then undoing one or more of those deletions.
                    availableRepository
                        .getSignUpBookedEvents($state.params.signUpId)
                        .then(function (eventsData)
                        {
                            let signUpEvents = [];

                            signUpEvents = eventsData.availableEvents.filter(function (signUpEvent)
                            {
                                return signUpEvent
                                    .pupils
                                    .filter(function (signUpEventPupil)
                                    {
                                        return signUpEventPupil.status === 1; // Reserved
                                    })
                                    .length > 0;
                            });

                            let bookingExpiryInfo = ccaService.getBookingExpiryInfo(signUpEvents);
                            $scope.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;
                            $scope.timeRemaining = bookingExpiryInfo.timeRemaining;
                        });
                }
            }

            $scope.recordPaymentProviderlessTransaction = function (amountToPayWithCredits) {

                console.log('recordPaymentProviderlessTransaction');

                $scope.saving = true;

                var signatureRequest = {
                    paymentSessionType: paymentSessionTypeEnum.Booking,
                    paymentProvider: 0,
                    useCreditBalance: (amountToPayWithCredits && amountToPayWithCredits > 0),
                    creditBalanceAmount: amountToPayWithCredits,
                    ccaSignUpId: $state.params.signUpId,
                    paymentSessionRequestItems: []
                };

                for (var i = $scope.signUpEvents.length; i--;) {

                    var thisEvent = $scope.signUpEvents[i];

                    for (var j = thisEvent.pupils.length; j--;) {

                        var thisPupil = thisEvent.pupils[j];

                        if (thisPupil.selectionCount === 1 && thisPupil.status === 1) {

                            signatureRequest.paymentSessionRequestItems.push({
                                calendarEventId: thisEvent.calendarEventId,
                                owningPersonId: thisPupil.personId
                            });
                        }
                    }
                }

                return paymentsService
                    .submitPaymentProviderlessTransaction(signatureRequest)
                    .then(function (data) {
                        if (data.success) {
                            $state.go('paymentComplete',
                            {
                                isSuccess: data.success,
                                message: data.message,
                                merchantReference: data.merchantReference
                            });
                        }
                        else {
                            throw { isError: true, message: data.message, translationParams: data.additionalData };
                        }
                    });
            };

            $scope.costText = function (ccaPrice) {

                if (ccaPrice === 0 || ccaPrice === "0" || ccaPrice === "") {
                    return $scope.currencyDisplaySymbol + '0.00';
                }

                return $scope.currencyDisplaySymbol + $filter('number')(ccaPrice, 2);
            };

            $scope.recordPaymentFromToken = function (token, merchantReference) {
                $scope.checkingOut = true;

                return paymentsService.chargeProviderWithToken(token, merchantReference)
                    .then(function (data) {
                        if (!!data.redirectUrl) {
                            // a redirect probably due to a 3D Secure payment.
                            window.location.href = data.redirectUrl;
                            return;
                        }

                        $state.go('paymentComplete',
                        {
                            isSuccess: data.success,
                            message: data.message,
                            merchantReference: merchantReference
                        });

                        return data;
                    })
                    .catch(function (err) {
                        console.error('problem charging provider with token', err);
                        throw err;
                    });
            };

            $scope.recordPaymentCancelled = function()
            {
                $scope.checkingOut = false;
                paymentsService.cancelPaymentSession($scope.paymentSessionId);
            };

            $scope.totalPayment = null;

            $scope.calculateTotalPayment = function () {
                var total = 0;
                if ($scope.signUpEvents)
                {
                    for (var i = 0; i < $scope.signUpEvents.length; i++)
                    {
                        var item = $scope.signUpEvents[i];
                        try
                        {
                            if (item.transactionApplyTypeId === 2)
                            {
                                var paymentAmount = item.upfrontCostPerPerson * 1;

                                for (var j = 0; j < item.pupils.length; j++)
                                {
                                    if (
                                        item.pupils[j].selectionCount === 1 && item.pupils[j].status === 1 // reserved
                                    )
                                    {
                                        total += paymentAmount;
                                    }
                                }
                            }
                        } catch (err){ }
                    }
                }
                $scope.totalPayment = total;
            };

            $scope.totalVoucherPayment = null;

            $scope.calculateTotalVoucherPayment = function () {
                var total = 0;
                if ($scope.signUpEvents) {
                    for (var i = 0; i < $scope.signUpEvents.length; i++) {
                        var item = $scope.signUpEvents[i];
                        try {
                            if (item.transactionApplyTypeId === 2) {
                                var paymentAmount = item.costPerPerson * 1;

                                for (var j = 0; j < item.pupils.length; j++) {
                                    if (
                                        item.pupils[j].selectionCount === 1 && item.pupils[j].status === 1 // reserved
                                    ) {
                                        total += paymentAmount;
                                    }
                                }
                            }
                        } catch (err) { }
                    }
                }
                $scope.totalVoucherPayment = total;
            };

            $scope.allFree = function()
            {
                if ($scope.signUpEvents)
                {
                    for (var i = 0; i < $scope.signUpEvents.length; i++)
                    {
                        var item = $scope.signUpEvents[i];
                        try
                        {
                            var upfrontCostPerPerson = item.upfrontCostPerPerson * 1;
                            var costPerPerson = item.costPerPerson * 1;

                            if (upfrontCostPerPerson > 0 || costPerPerson > 0)
                            {
                                for (var j = 0; j < item.pupils.length; j++)
                                {
                                    if (
                                        item.pupils[j].selectionCount === 1 && item.pupils[j].status === 1 // reserved
                                    )
                                    {
                                        return false;
                                    }
                                }
                            }
                        } catch (err)
                        {
                            return true;
                        }
                    }
                }
                return true;
            };

            $scope.isSameDay = (event1, event2) => {
                return moment(event1.from || event1.eventDate).isSame(event2.from || event2.eventDate, 'day') && moment(event1.to || event1.eventEndDate).isSame(event2.to || event2.eventEndDate, 'day');
            }

            $scope.eventCostText = event => {
                // Get total cost label
                let label = '';
                if ($scope.signUp.isPTC) return '';
                if (event.upfrontCostPerPerson > 0 || event.costPerPerson > 0) {
                    label = $filter('translate')('SB_Upfront_Cost_With_Outstanding_Balance', { upfrontcost: $scope.costText(event.upfrontCostPerPerson), outstandingbalance: $scope.costText(event.costPerPerson - event.upfrontCostPerPerson)});
                }
                else {
                    label = $filter('translate')('SB_Free_No_Charge');
                }
                const pupilCount = event.pupils.filter(pupil => {return pupil.selectionCount === 1 && pupil.status === 1 }).length;
                return {
                    description: label,
                    cost: `${$scope.currencyDisplaySymbol}${pupilCount * event.upfrontCostPerPerson}`,
                    sum: pupilCount > 1 && event.upfrontCostPerPerson > 0 ? `${$scope.currencyDisplaySymbol}${event.upfrontCostPerPerson} x ${pupilCount}` : ''
                };
            }

            $scope.clashGridRow = (pupil, currentEvent) => {
                if (!pupil.hasClashes) {
                    return null;
                }
                const clashEvents = pupil.adjacentEvents.filter(event => {return event.isClash || event.isCurrentEvent});
                if (clashEvents[0] && clashEvents[0].id !== currentEvent.id) {
                    return null;
                }
                const rowStart = pupil.adjacentEvents.map(event => {return event.id}).indexOf(currentEvent.id) + 1;
                const rowEnd = rowStart + clashEvents.length;
                return rowStart + '/' + rowEnd;
            }

            $scope.toggleBooking = (pupil, anEvent) => {
                $scope.processedBooking = anEvent.calendarEventId + '-' + pupil.personId;
                return availableRepository
                    .postToggleBooking(pupil, anEvent)
                    // As this is page-specific UI, apply toasts here
                    .then((data) => {
                        pupil.status ? toastService.deleteSuccess() : toastService.saveSuccess();
                        $scope.loading += 1;
                        $scope.signUpEvents.forEach(event => {
                            if (event.calendarEventId === anEvent.calendarEventId) {
                                event.pupils.forEach(eventPupil => {
                                    if (eventPupil.personId === pupil.personId) {
                                        eventPupil.status = eventPupil.status ? 0 : 1;
                                        eventPupil.isDeleted = eventPupil.isDeleted ? false : true;
                                    }
                                });
                            }
                        });
                        $scope.initEvents();
                        return data;
                    })
                    .catch(()=> {
                        toastService.saveError();
                    })
                    .finally(() => {
                        $scope.processedBooking = '';
                        $scope.loading -= 1;
                    });
            }

            $scope.isProcessingBooking = (anEvent, pupil) => {
                return $scope.processedBooking === anEvent.calendarEventId + '-' + pupil.personId ||
                $scope.processedBooking && $scope.signUp.restrictClashes;
            }

            $scope.eventHasPupilClashesFilter = (anEvent, personId) => {
                return anEvent => {
                    return anEvent.pupils.some(eventPupil => {
                        eventPupil.personId === personId && eventPupil.hasClashes;
                    });
                }
            }

            $scope.pupilFilter = (pupil, personId) => {
                return pupil.personId === personId;
            }

            $scope.isClash = anEvent => {
                return anEvent.isClash;
            }

            $scope.uniqueBlockClashes = (event, pupil) => {
                const clashes = [];
                event.remainingSessionEvents.forEach(session => {
                    session.pupils.find(sessionPupil => {
                        return sessionPupil.personId == pupil.personId;
                    })
                    .adjacentEvents.forEach(adjacentEvent => {
                        if (!adjacentEvent.isClash) {
                            // Exclude non-clashes
                            return;
                        }
                        if (!adjacentEvent.parentCalendarEventId) {
                            // Include non-block events and return early
                            clashes.push(adjacentEvent);
                            return;
                        }
                        const isUnique = !clashes.some(clash => {
                            return clash.parentCalendarEventId === adjacentEvent.parentCalendarEventId;
                        })
                        if (isUnique) {
                            // Include unique clash events
                            clashes.push(adjacentEvent);
                        }
                    })
                })
                return clashes;
            }

            $scope.showSessionAdjacents = (session, remainingSessionEvents, personId) => {
                return session => {
                    if (!session.isClash) {
                        return false;
                    }
                    if (!session.parentCalendarEventId) {
                        return true;
                    }
                    const clashList = [];
                    remainingSessionEvents.forEach(dayEvent => {
                        dayEvent.pupils.forEach(pupil => {
                                if (pupil.personId === personId) {
                                    pupil.adjacentEvents.forEach(adjacentEvent => {
                                        if (adjacentEvent.isClash) {
                                            clashList.push(adjacentEvent);
                                        }
                                    });
                                }
                            });
                    });
                    return clashList[0] && clashList[0].id === session.id;
                }
            }

            $scope.showNoClashesMessage = () => {
                const hasNonFeeEvents = $scope.signUpEvents.some(event => {
                    return event.systemUsageTypeId !== eventCategorySystemUsageTypeEnum.Fee;
                })
                return !$scope.signUp.isTimeSlot && !$scope.hasClashes && $scope.loading == 0 && hasNonFeeEvents;
            }

            $scope.clearDeleted = () => {
                $scope.signUpEvents = $scope.signUpEvents
                    .map(event => {
                        const unDeletedPupils = event.displayedPupils.filter(pupil => {
                            return !pupil.isDeleted;
                        });
                        return {
                            ...event,
                            pupils: unDeletedPupils,
                            displayedPupils: unDeletedPupils
                        }
                    })
                    .filter(event => {
                        return event.displayedPupils.length > 0;
                    })
            }

            $scope.formatTitle = event => {
                return `${event.title} (${event.ownerName || event.teamTitle})`;
            }

            $scope.orderedEvents = () => {
                return $scope.signUpEvents.sort((a, b) => {
                    return b.isFeeOnly - a.isFeeOnly || b.dayFrom - a.dayFrom;
                })
            }

            $scope.showDateHeader = index => {
                const event = $scope.orderedEvents()[index];
                const previousEvent = $scope.orderedEvents()[index - 1];
                const newDay = (index === 0 || !$scope.isSameDay(event, previousEvent)) && !event.isFeeOnly;
                const firstNonFee = index > 0 && !event.isFeeOnly && previousEvent.isFeeOnly;
                return newDay || firstNonFee;
            }

            $scope.bookingTimeOutCallback = () => {
                $scope.hasBookingSelectionTimedOut = true;
            }
        }

    ]});

angular.module('ccaavailableModule').filter('sumByKey', ['$filter', function ($filter) {
    return function (data, key) {
        if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
            return 0;
        }

        var sum = 0;
        angular.forEach(data, function (obj, objKey) {
            sum += parseFloat(obj[key]);
        });

        return sum;
    };
}]);
