import { FroalaOptions } from './froala-editor';

// Based on our previous TinyMce options
export interface EditorConfig {
    // fonts: boolean;
    fontSize?: boolean;
    fontFamily?: boolean;
    formatting?: boolean;
    mobileFormatting?: boolean;
    lists?: boolean;
    mobileLists?: boolean;
    justify?: boolean;
    tables?: boolean;
    hyperlinks?: boolean;
    imageUpload?: boolean;
    videoUpload?: boolean;
    undo?: boolean;
    fontSelection?: string;
    editHtml?: boolean;
    height?: number;
    forceLinkPrefix?: boolean;
    fullScreen?: boolean;
    froalaOptions?: Partial<FroalaOptions>;
}

export const defaultEditorConfig: EditorConfig = {
    // fonts: true,
    fontSize: false,
    fontFamily: false,
    formatting: true,
    mobileFormatting: true, // Amended per above
    lists: true,
    mobileLists: false,
    justify: true,
    tables: true,
    hyperlinks: true,
    // No images and videos until configured
    imageUpload: false,
    videoUpload: false,
    undo: true,
    fontSelection: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
    editHtml: true,
    height: 104,
    forceLinkPrefix: true, // prefixes links with http if no existing protocol prefix.
    fullScreen: false
}