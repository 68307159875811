angular
    .module('teams.services.teamNotesService', [])
    .factory('teamNotesService', [
        '$http', '$q', '$cacheFactory', '$httpParamSerializerJQLike',
        function ($http, $q, $cacheFactory, $httpParamSerializerJQLike) {

            var urlBase = '/webapi/WebTeamNotes/';

            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('teamNotesServiceCache');

            repository.clear = function () {
                repoCache.removeAll();
            };

            repository.getNotesForTeam = function (teamId) {
                return $http.get(urlBase + 'GetNotesForTeam', { params: { teamId: teamId }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.addNote = function (note) {
                return $http.post(urlBase + 'AddNote', note)
                    .then(function (response) {
                        repository.clear();

                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };
            
            return repository;
        }
    ]);