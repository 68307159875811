import { Injectable } from '@angular/core';
import { IconDictionary, TransportIcons } from '@sb-shared/constants/icon.constants';
import { OSType } from '@sb-shared/enums/os-type.enum';
import { Platform } from '@sb-shared/enums/platform.enum';
import { Icons } from '@sb-shared/models/icon';
import { apis, controllerTypes } from '@sb-shared/models/request-settings';
import { SbIcon } from '@sb-shared/models/UI/sb-icon';
import { Observable } from 'rxjs';
import { HttpWebApiService } from './http-web-api.service';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  iconDictionary: any = IconDictionary;

  platformIcons: { [name: string]: SbIcon } = {
    Web: { name: 'web', tooltip: 'SB_Web', class: 'text-secondary' },
    Apple: { name: 'apple', tooltip: 'SB_Apple', class: 'text-iphone' },
    Android: { name: 'android', tooltip: 'SB_Android', class: 'text-android' }
  };

  constructor(private http: HttpWebApiService) {
    // Add transport icons to main dictionary
    TransportIcons.forEach(transportIcon => {
      this.iconDictionary[transportIcon.iconName] = 'fal fa-' + transportIcon.faIcon;
    });
  }

  // Transport icons define extra information

  icons() {
    return this.iconDictionary;
  }

  getIcon(iconName: string): string {
    return this.iconDictionary[iconName];
  }

  // New Faria menu

  getStyledIcon(item: any, isActive: boolean, isMain?: boolean) {
    const thinIconClass = 'fal';
    const otherIconClasses = ['fad', 'fas'];
    if (
      !otherIconClasses.some(iconClass => {
        return item.icon?.indexOf(`${iconClass} `) === 0;
      })
    ) {
      return `${thinIconClass} ${item.icon}`;
    }
    let newIcon = item.icon;
    otherIconClasses.forEach(iconClass => {
      newIcon = newIcon.replace(`${iconClass} `, `${thinIconClass} `);
    });
    return newIcon;
  }

  getTransportIcons() {
    return TransportIcons;
  }

  getTransportIcon(iconId: string) {
    const transportIcon = TransportIcons.filter(icon => {
      return icon.id === iconId;
    });
    if (transportIcon.length === 1) {
      return transportIcon[0];
    }
    return null;
  }

  getIcons(): Observable<Icons> {
    return this.http.get('Icon', { controllerType: controllerTypes.User, api: apis.Core });
  }

  // Get list of icons indicated by given platforms number (Person.Platforms field)
  getActivePlatformIcons(platforms: number) {
    const icons: SbIcon[] = [];

    const matchPlatform = (p: number, value: Platform) => p >= value;

    if (matchPlatform(platforms, Platform.Web)) {
      icons.push(this.platformIcons.Web);
    }
    if (matchPlatform(platforms, Platform.Apple)) {
      icons.push(this.platformIcons.Apple);
    }
    if (matchPlatform(platforms, Platform.Android)) {
      icons.push(this.platformIcons.Android);
    }
    return icons;
  }

  // Get icon indicated by last platform value.
  getLastActivePlatformIcon(lastPlatform: OSType) {
    switch (lastPlatform) {
      case OSType.Web:
        return this.platformIcons.Web;
      case OSType.Apple:
        return this.platformIcons.Apple;
      case OSType.Android:
        return this.platformIcons.Android;
    }

    return null;
  }
}
