"use strict";

angular.module('group.directives.linkedGroupEditor', [
    'person.services',
    'groups.services.groupService',
    'shared.components.sbModalHeader'])
    .directive('linkedGroupEditor', [
        '$window',
        '$timeout',
        '$uibModal',
        '$templateRequest',
        '$sce',
        '$compile',
        '$q',
        'personService',
        'groupService',
        function ($window, $timeout, $uibModal, $templateRequest, $sce, $compile, $q, personService, groupService)
        {
            var addDateDisplayFields = function(items)
            {
                angular.forEach(items,
                    function(item)
                    {
                        if (item.day)
                        {
                            if (item.day > 0)
                            {
                                item.dayDisplay = moment().isoWeekday(item.day).format('ddd');
                            }
                        }

                        if (item.time)
                        {
                            item.timeDisplay = moment('1/1/1 ' + item.time).format('HH:mm');
                        }
                    });
            };

            function link($scope) {
                $scope.$watch('personId', function (newValue) {

                    if (!$scope.personId)
                        return;

                    groupService.getPersonGroups($scope.personId)
                        .then(function (data) {
                            $scope.persongroups = data;

                            addDateDisplayFields($scope.persongroups);

                        });
                });
            }

            function controller($scope, personService, groupService) {

                $scope.persongroupsSortType = "name";
                $scope.persongroupssortReverse = false;
                $scope.persongroups = null;

                $scope.canAddGroups = function () {
                    return $scope.isOrganisationAdmin === true;
                };
                $scope.canDeleteGroups = function (editable) {
                    return $scope.isOrganisationAdmin === true && editable === true;
                };

                $scope.personName = null;

                $scope.loadName = function()
                {
                    var deferred = $q.defer();

                    if ($scope.personName === null)
                    {
                        $scope.personName = {
                            firstName: '',
                            lastName: ''
                        };

                        personService.getName($scope.personId)
                            .then(function(data)
                            {
                                if (!data.isError && data !== null)
                                {
                                    $scope.personName.firstName = data.firstName;
                                    $scope.personName.lastName = data.lastName;
                                }
                                deferred.resolve();
                            });
                    } else
                    {
                        deferred.resolve();
                    }

                    return deferred.promise;
                };

                $scope.deletePersonFromGroup = function (persongroup) {
                    $scope.selectedGroup = persongroup;
                    $scope.loadName();
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: "confirmDeletePersonFromGroupModal.html",
                        controller: "ConfirmModalInstanceCtrl",
                        size: "sm",
                        scope: $scope
                    });

                    modalInstance.result.then(function () {
                        groupService.removePersonGroup($scope.selectedGroup.teamId, $scope.personId)
                            .then(function (data) {
                                groupService.getPersonGroups($scope.personId).then(function (data) {
                                    $scope.persongroups = data;

                                    addDateDisplayFields($scope.persongroups);
                                });
                            });
                    },
                        function () {
                        });
                };

                $scope.addPersonGroup = function () {

                    if (!$scope.canAddGroups()) {
                        console.warn('unauthorised add attempted');
                        return;
                    }

                    $scope.loadName();

                    groupService.getPersonAvailableGroups($scope.personId).then(function (data) {
                        $scope.toAdd = { teamId: null };

                        $scope.availableGroups = data;

                        angular.forEach($scope.availableGroups,
                            function (item) {

                                var scheduleInfo = '';

                                if (item.dayOfWeek) {
                                    if (item.dayOfWeek > 0) {
                                        scheduleInfo += moment().isoWeekday(item.dayOfWeek).format('ddd');
                                    }
                                    else if (item.dayOfWeek === 0) {
                                        scheduleInfo += 'All';
                                    }
                                }

                                if (item.startTime) {
                                    if (scheduleInfo.length > 0)
                                        scheduleInfo += ' ';

                                    scheduleInfo += moment('1/1/1 ' + item.startTime).format('HH:mm');
                                }

                                if (scheduleInfo.length > 0) {
                                    item.name += ' - ' + scheduleInfo;
                                }
                            });

                        var modalInstance = $uibModal.open({
                            animation: $scope.animationsEnabled,
                            templateUrl: "addPersonToGroupModal.html",
                            controller: "ConfirmModalInstanceCtrl",
                            size: "md",
                            scope: $scope
                        });

                        modalInstance.result.then(function () {
                            groupService.addPersonToGroup($scope.toAdd.teamId, $scope.personId, $scope.roleId)
                                .then(function (data) {
                                    groupService.getPersonGroups($scope.personId).then(function (data) {
                                        $scope.persongroups = data;

                                        addDateDisplayFields($scope.persongroups);
                                    });
                                });
                        },
                            function () {
                            });
                    });
                };

                $scope.exportReport = function () {
                    $window.location = `${$window.EveryBuddy.WebAPI}/${groupService.getPersonGroupsCsvUrl($scope.personId)}`;
                };

                $scope.printReport = function () {

                    // make sure we have the name loaded first
                    $scope.loadName().then(function() {
                        var templateUrl = $sce.getTrustedResourceUrl('/Scripts/app/group/directives/linked-group-editor.print.html?v=' + $window.EveryBuddy.Version);

                        $templateRequest(templateUrl).then(function (template) {
                            template = '<div>' + template + '</div>';

                            var compiledPrintView = $compile(template)($scope);
                            $timeout(function () {
                                var myWindow = $window.open('', '', 'width=800, height=600');
                                myWindow.document.write(compiledPrintView[0].innerHTML);
                            }, 1000);  // wait for a short while,Until all scope data is loaded If any complex one
                        }, function () {
                            // An error has occurred
                        });
                    });
                };
            }

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/group/directives/linked-group-editor.template.html?v=' + $window.EveryBuddy.Version,
                scope: {
                    personId: '=',
                    isOrganisationAdmin: '=',
                    isStaff: '<',
                    roleId: '='
                },
                controller: ['$scope', 'personService', 'groupService', controller],
                link: link
            };
        }]);
