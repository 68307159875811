<div *ngIf="dropdownItems; else nonDropdownButton" ngbDropdown #dropdownMenu="ngbDropdown"
    [autoClose]="true"
    display="dynamic">
    <button *ngIf="!isVisibleFunction || isVisibleFunction({ dataItem: dataItem })"
            [ngClass]="computedClass"
            [disabled]="isDisabled || isLoading"
            (mouseenter)="onButtonHover()"
            type="buttonType"
            id="{{buttonId}}"
            [ngbTooltip]="tooltipText | translate"
            [placement]="tooltipPlacement"
            [attr.title]="isMessageTranslated ? (message | translate:messageTranslationParameters) : message"
            ngbDropdownToggle>
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
    <div
         ngbDropdownMenu (click)="dropdownMenu.close()"
         [attr.aria-labelledby]="'dropdown ' + message"
         [ngClass]="dropdownClasses">
         <ng-content select="[dropdownContent]"></ng-content>
         <div *ngFor="let item of dropdownItems; let dropdownItemIndex = index"
              class="p-1"
              [ngClass]="{'active' : item.isActive}">
            <div role="separator"
                 class="divider"
                 *ngIf="dropdownItemIndex > 0 && item.groupId !== dropdownItems[dropdownItemIndex - 1].groupId"></div>
            <ng-container *ngIf="!item.isVisibleFunction || item.isVisibleFunction({ dataItem: dataItem, dropdownItemIndex: dropdownItemIndex })"
                          [ngTemplateOutlet]="item.route ? hasRoute : item.externalUrl ? hasExternalUrl : noRouteOrExternalUrl"></ng-container>
            <ng-template #hasRoute>
                <a [routerLink]="item.route"
                   ngbDropdownItem
                   class="btn btn-link button__dropdown-item"
                   [ngClass]="{'button__dropdown-item--active' : item.isActive}"
                   [ngbTooltip]="item.message | translate:item.messageTranslationParameters"
                   [placement]="tooltipPlacement">
                    <ng-container *ngTemplateOutlet="dropDownInnerContent; context: {item}"></ng-container>
                </a>
            </ng-template>
            <ng-template #hasExternalUrl>
                <a [href]="item.externalUrl"
                   ngbDropdownItem
                   class="btn btn-link button__dropdown-item"
                   [ngClass]="{'button__dropdown-item--active' : item.isActive}"
                   [ngbTooltip]="item.message | translate:item.messageTranslationParameters"
                   [placement]="tooltipPlacement">
                    <ng-container *ngTemplateOutlet="dropDownInnerContent; context: {item}"></ng-container>
                </a>
            </ng-template>
            <ng-template #noRouteOrExternalUrl>
                <button (click)="onDropdownItemClick(dropdownItemIndex)"
                        ngbDropdownItem
                        class="btn btn-link button__dropdown-item"
                        [ngClass]="{'button__dropdown-item--active' : item.isActive}"
                        [ngbTooltip]="item.message | translate:item.messageTranslationParameters"
                        [placement]="tooltipPlacement">
                    <ng-container *ngTemplateOutlet="dropDownInnerContent; context: {item}"></ng-container>
                </button>
            </ng-template>
         </div>
      </div>
</div>

<ng-template #dropDownInnerContent let-item="item">
    <sb-icon *ngIf="item.iconName"
             name="{{item.iconName}}"
             [class]="item.iconClasses"
             class="dropdown-icon"
             [overrideStyle]="item.iconStyle"></sb-icon>
    <i *ngIf="iClass"
       class="mr-1"
       [ngClass]="iClass"></i>
    <img *ngIf="item.imgUrl"
         [src]="item.imgUrl"/>
    <span *ngIf="item.message"
          [ngClass]="{'ml-2' : item.iconName}">
        <span [ngClass]="{'hidden-xs' : item.xsIconOnly || item.messageXs}">{{item.message | translate:item.messageTranslationParameters}}</span>
        <span *ngIf="item.messageXs" class="hidden-sm hidden-md hidden-lg">{{item.messageXs | translate:item.messageTranslationParameters}}</span>
    </span>
</ng-template>

<ng-template #nonDropdownButton>
    <button [ngClass]="computedClass"
            [disabled]="isDisabled || isLoading"
            (click)="onButtonClick()"
            (mouseenter)="onButtonHover()"
            type="buttonType"
            id="{{buttonId}}"
            [ngbTooltip]="isMessageTranslated ? ((tooltipText || message) | translate:messageTranslationParameters) : (tooltipText || message)"
            [placement]="tooltipPlacement">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
</ng-template>

<ng-template #buttonContent>
    <span *ngIf="!isLoading"
        [ngClass]="{'align-center justify-center' : !note}">
        <sb-icon *ngIf="iconName"
                    name="{{iconName}}"
                    [class]="iconClasses"
                    [overrideStyle]="iconStyle"
                    [size]="iconSize"></sb-icon>
        <i *ngIf="iClass"
            class="mr-1"
            [ngClass]="iClass"></i>
        <img *ngIf="imgUrl"
                [src]="imgUrl"/>
        <span *ngIf="message"
                [ngClass]="{'ml-2' : iconName}"
                [style.font-size]="fontSizeStyle">
            <span [ngClass]="{'hidden-xs' : xsIconOnly || messageXs}">{{isMessageTranslated ? (message | translate:messageTranslationParameters) : message}}</span>
            <span *ngIf="messageXs" class="hidden-sm hidden-md hidden-lg">{{isMessageTranslated ? (messageXs | translate:messageTranslationParameters) : messageXs}}</span>
        </span>
        <div *ngIf="note"
                class="text-muted mt-1"
                [innerHTML]="note"></div>
    </span>
    <sb-icon *ngIf="isLoading"
                name="loading"></sb-icon>
    <ng-content></ng-content>
</ng-template>
