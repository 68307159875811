angular.module('shared.services.countryService', [])
    .service('countryService', class CountryService {

        $cacheFactory: any;
        httpCoreApi: any;
        settings: any;

        phoneCodes: Array<any>;
        repoCache: any;

        static $inject = ['$cacheFactory', 'httpCoreApi'];

        constructor($cacheFactory, httpCoreApi) {
            this.$cacheFactory = $cacheFactory;
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.SysAdmin
            }
            this.repoCache = $cacheFactory('countryServiceCache');
        }

        getCountries() {
          return this.httpCoreApi.get('Country', { ...this.settings, config: { cache: this.repoCache } });
        }

    });
