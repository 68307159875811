import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AvatarButtonAction } from '@sb-shared/constants/shared.constant';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { BlobStoragePhotoService } from '@sb-shared/services/blob-storage-photo.service';
import { ImageService } from '@sb-shared/services/image.service';
import { StringService } from '@sb-shared/services/string.service';
import { UserService } from '@sb-shared/services/user.service';
import { Sizes } from '@sb-shared/types/sizes';
import { ToastService } from './../../../core/services/toast.service';
import { ProfileDataService } from './../../../profile/services/profile-data.service';

@Component({
  selector: 'sb-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  constructor(
    private blobStoragePhotos: BlobStoragePhotoService,
    private image: ImageService,
    private profileData: ProfileDataService,
    private router: Router,
    private toast: ToastService,
    private user: UserService,
    private string: StringService) { }

  @Input() personId: number;
  @Input() photoUrl: string;
  @Input() fullName: string;
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() otherName = '';
  @Input() isLoading: boolean;
  @Input() isSoloPhoto: boolean;
  @Input() actionType: AvatarButtonAction = AvatarButtonAction.CustomLink;
  @Input() isOrg: boolean;
  @Input() size: Sizes;
  @Input() showTooltip: boolean;
  @Output() onClose = new EventEmitter<null>();
  @Output() onClick = new EventEmitter<null>();
  isExternal: boolean;
  imgUrlFound: boolean;

  ngOnInit(): void {

    this.user.isExternal().subscribe(value => {
      this.isExternal = value;
    });

    this.imgUrlFound = false;

    if ((!this.firstName || !this.lastName) && this.fullName) {
      let splitName;
      if (this.fullName.includes(CommonChars.Comma)) {
        // handle case of format: [last name], [first names]
        splitName = this.fullName.split(', ');
        this.firstName = splitName[1];
        this.lastName = splitName[0];
      } else {
        // handle case of format: [first names] [last name]
        splitName = this.fullName.split(' ');
        this.firstName = splitName[0];
        this.lastName = splitName[splitName.length - 1];
      }
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    // If photo not already found or new user
    if (!this.imgUrlFound || (changes.personId && changes.personId.currentValue)) {
      // If a photourl from bulk (not solo) is not being passed in: look up photoUrl from personId, then check it
      if (this.isSoloPhoto && Number.isInteger(this.personId)) {
        this.imgUrlFound = false;
        this.blobStoragePhotos.getPhotoUrlsForStudents([this.personId]).subscribe(data => {
          if (data[0] && data[0].thumbnail) {
            this.photoUrl = this.size == 'xl' ? data[0].oneHundredPx : data[0].thumbnail;
            this.checkImage();
          }
        });
      } else if (this.photoUrl) // Else check the photoUrl being passed in (if ready)
      {
        this.checkImage();
      }
    }
  }

  checkImage() {
    return this.image.isImage(this.photoUrl).then((isFound: boolean) => {
      this.imgUrlFound = isFound;
    });
  }

  showDefaultCursor() {
    return !this.personId || this.actionType === AvatarButtonAction.None;
  }

  profilePopup() {
    if (this.actionType !== AvatarButtonAction.ProfilePopup) { return; }

    if (this.isExternal === undefined) {
      // Edge case user has clicked before isExternal cache loaded
      return;
    }

    if (this.isExternal) {
      // Block external user from viewing pupil profile
      this.toast.warning('SB_Access_Restricted');
      return;
    }

    if (this.personId) {

      //copied from profliePopupButton

      // pull a full instance of the profile screen into a popup!

      this.profileData.setSelectedPersonById(this.personId);
      this.router.navigate([{ outlets: { profile: ['profile'] } }]);

    }
  }

  handlePopupClosed() {
    this.profileData.setSelectedPersonById(0);

    // Invoke any close event handler provided by the caller
    this.onClose.emit();
  }

  formatName() {
    return this.string.formatName(this.lastName, this.firstName, this.otherName);
  }
}
