'use strict';

angular.module('transport.reports.transportReports', [])
    .component('transportReports',
        {
            bindings: {},
            templateUrl: '/Scripts/app/transport/reports/components/transport-reports.template.html',
            controllerAs: 'ctrl',
            controller: [
                function () { }
            ]
        }
    );