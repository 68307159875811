'use strict';

angular.module('shared.services.portalChangeRequestService', [])
    .factory('portalChangeRequestService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebChangeRequest/';

            var service = {};

            service.getChangeRequestOptions = function (calendarEventTransportAttendeeId) {
                return $http.get(urlBase + 'GetOptions',{
                        params: {
                            calendarEventTransportAttendeeId: calendarEventTransportAttendeeId
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.addChangeRequest = function (changeRequest) {
                return $http.post(urlBase + 'AddChangeRequest', changeRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getChangeRequest = function (calendarEventTransportAttendeeId) {
                return $http.get(urlBase + 'GetChangeRequest', {
                    params: {
                        calendarEventTransportAttendeeId: calendarEventTransportAttendeeId
                    }
                })
                .then(function (response) {
                    return response.data;
                });
            };

            service.cancelChangeRequest = function (changeRequest) {
                return $http.put(urlBase + 'CancelChangeRequest', changeRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.parentGetAllowParentChangeRequests = function () {
                return $http.get(urlBase + 'GetAllowParentChangeRequests')
                    .then(function (response) {
                        return response.data;
                    });
            };

            return service;
        }
    ]);