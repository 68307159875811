export const EventFieldIds = {
    EventType: 'calendarEventCategoryTypeId',
    Title: 'title',
    Staff: 'newGroupStaffIds',
    NotifyType: 'notifyTypeId',
    SelectionType: 'availabilityRequirement',
    Team: 'teamId',
    Location: 'locationId',
    NewGroupLocation: 'newGroupLocation',
    Date: 'date',
    MeetingLocation: 'meetingLocationId',
    MeetingTime: 'meetingTime',
    PickupLocation: 'pickupLocationId',
    PickupTime: 'pickupTime',
    CustomForm: 'customFormId',
    CustomFormSwitch: 'customFormSwitch',
    PortalLink: 'portalLinkId',
    RepeatType: 'repeatTypeId',
    RepeatEnd: 'repeatEnd',
    RepeatEventCount: 'repeatEventCount',
    NoOfPlacesSwitch: 'noOfPlacesSwitch'
}
