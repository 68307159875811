import { tap } from 'rxjs/operators';
import { ToastService } from '@sb-core/services/toast.service';
import { User } from '@sb-shared/models/user';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Injectable } from '@angular/core';
import { HttpRequestSettings, apis } from '@sb-shared/models/request-settings';
import { ChatSessionTypeId } from '../enums/chat-session-type';
import { AccessRequestEmailsRequest } from '../models/requests/access-request-emails-request';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  urlBase = 'WebMessageCenter/';

  config: HttpRequestSettings = {
    api: apis.Web,
    successCallback: () => this.toast.emailSent()
  }

  activationStates = {
    Active: 'SB_Active',
    NotLoggedIn: 'SB_Not_logged_in',
    Inactive: 'SB_Inactive'
  }

  constructor(
    private http: HttpWebApiService,
    private toast: ToastService) {
  }

  sendAccessRequestEmails(request: AccessRequestEmailsRequest): Observable<any> {
    return this.http.post(`${this.urlBase}SendAccessRequestEmails`, request, this.config);
  }

  getActivationLabel(person) {
    if (person) {
      const status = person.personStatus;
      if (status === this.activationStates.Active) {
        return 'SB_Send_Password_Reset';
      }
      if (status === this.activationStates.NotLoggedIn) {
        return 'SB_Resend_Welcome_Message';
      }
      if (status === this.activationStates.Inactive) {
        return 'SB_Send_Welcome_Message';
      }
    }
    return '';
  }

  sendAccessRequestEmailsForOrganisation(organisationId: number, request: AccessRequestEmailsRequest): Observable<any> {
    if (request) {
      return this.http.post(`${this.urlBase}SendAccessRequestEmailsForOrganisation?organisationId=${organisationId}`, request, this.config);
    }

    return null;
  }

  getAccessRequestEmailsRequestForUsers(users: User[]): AccessRequestEmailsRequest {
    const request = new AccessRequestEmailsRequest();

    for (const user of users) {
      if (user.isStaff) {
        switch (user.personStatus) {
          case this.activationStates.Active:
            request.staffToResetPassword.push(user.id);
            break;
          case this.activationStates.NotLoggedIn:
            request.staffToReInvite.push(user.id);
            break;
          case this.activationStates.Inactive:
            request.staffToWelcome.push(user.id);
            break;
        }
      }

      if (user.isParent) {
        switch (user.personStatus) {
          case this.activationStates.Active:
            request.parentsToResetPassword.push(user.id);
            break;
          case this.activationStates.NotLoggedIn:
            request.parentsToReInvite.push(user.id);
            break;
          case this.activationStates.Inactive:
            request.parentsToWelcome.push(user.id);
            break;
        }
      }

      if (user.isPupil) {
        switch (user.personStatus) {
          case this.activationStates.Active:
            request.pupilsToResetPassword.push(user.id);
            break;
          case this.activationStates.NotLoggedIn:
            request.pupilsToReInvite.push(user.id);
            break;
          case this.activationStates.Inactive:
            request.pupilsToWelcome.push(user.id);
            break;
        }
      }
    }

    return request;
  }

  sendSingleAccessRequestEmail(person: User, orgId?: number) {
    const request: AccessRequestEmailsRequest = this.getAccessRequestEmailsRequestForUsers([person]);

    if (orgId) {
      return this.sendAccessRequestEmailsForOrganisation(
        orgId,
        request
      );
    }
    else {
      return this.sendAccessRequestEmails(request);
    }
  }

  getChatFilters(isStaff, isParent) {
    const chatFilters = [
      ChatSessionTypeId.Notice,
      ChatSessionTypeId.News,
      ChatSessionTypeId.MatchReport,
      ChatSessionTypeId.Homework
    ];

    if (isStaff) {
      chatFilters.push(ChatSessionTypeId.StaffNotice);
    }

    if (isParent || isStaff) {
      chatFilters.push(ChatSessionTypeId.ParentNotice);
    }

    return chatFilters;
  }
}
