'use strict';

angular.module('shared.services.installationSettingsService', [])
    .factory('installationSettingsService', [
        '$http',
        function ($http)
        {
            var urlInstallationSettingsBase = '/webapi/WebInstallationSettings/';
            var service = {};

            service.getMapsUrl = function ()
            {
                return $http.get(urlInstallationSettingsBase + 'GetMapsUrl')
                    .then(function (response)
                    {
                        var url = response.data.mapsURL;
                        var isBaidu = url.indexOf('map.baidu.com') !== -1;
                        return { value: url, label: isBaidu ? '百度地图' : 'SB_Google_Maps', isBaidu: isBaidu };
                    });
            };

            service.getDefaultColours = function ()
            {
                return $http.get(urlInstallationSettingsBase + 'GetDefaultColours')
                    .then(function (response)
                    {
                        var colours = response.data;
                        return { primaryColour: colours.defaultPrimaryColour, secondaryColour: colours.defaultSecondaryColour };
                    });
            };

            return service;
        }
    ]);