angular.module('ccaavailableModule', [

    'tmh.dynamicLocale',

    'ui.bootstrap',

    'payments.components.paymentProviderButtons',
    'angular.filter',

    'cca.components.dailyPreferenceSelector',
    'cca.components.ccaGroupInstantBookSelection',
    'cca.services.ccaService',
    'ccaAvailable.services.availableRepository',
    'ccaAvailable.services.organisationAvailableRepository',
    'ccaAvailable.services.ccaActionsService',
    'ccaAvailable.transport.timeslot.ccaTimeslot',

    'events.components.bookingCountdown',
    'events.directives.timeSlottedEventPicker',
    'events.constants',

    'groups.services.groupService',

    'payments.constants',
    'payments.components.paymentResult',
    'payments.services.paymentsService',
    'person.services',

    'shared.components.sbIcon',
    'shared.components.sbColumnHeader',
    'shared.components.sbAvatar',
    'shared.components.sbModalHeader',
    'shared.components.sbLabelList',
    'shared.components.sbTitleBar',

    'shared.controllers.confirmationPopup',

    'shared.directives.fixedFirstColumn',
    'shared.directives.fixedTableHeaders',
    'shared.directives.updateLanguage',
    'shared.directives.cloakTranslate',
    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlPlaceholder',
    'shared.directives.ngBindHtmlTitleAttr',
    'shared.components.sbSubmitButton',

    'shared.filters.decoded',

    'shared.services.authInterceptor',
    'shared.services.simpleAlertService',
    'shared.services.blobStoragePhotosService',
    'shared.services.toastService',

    'userForm.components.capture',
    'userForm.components.tabcapture',
    'ng1StateDefinitionModule'
]);

angular.module('ccaavailableModule').constant('EventCategorySystemUsageType', {
    None: 0,
    CCA: 1,
    Membership: 2,
    Fee: 4,
    ParentsEvening: 8,
    Task: 16,
    Transport: 32
});

angular.module('ccaavailableModule').config([
    'ng1StateDefinitionProvider',
    function(ng1StateDefinitionProvider){

        const ng1State = ng1StateDefinitionProvider.$get();

        const moduleName = 'CcaAvailable';

        ng1State.state(moduleName, 'ccaAvailable', {
            url: ''
        })
        ng1State.state(moduleName, 'ccaDetails', {
            url: '/Details/:signUpId'
        })
        ng1State.state(moduleName, 'ccaReview', {
            url: '/Review/:signUpReviewId'
        })
        ng1State.state(moduleName, 'ccaToJoin', {
            url: '/ToJoin/:signUpId'
        })

        ng1State.state(moduleName, 'ccaSelection', {
            url: '/Selection/:signUpId/:pupilId'
        })

        ng1State.state(moduleName, 'ccaSelectionParentsEvening', {
            url: '/SelectionParentsEvening/:signUpId/:pupilId'
        })

        ng1State.state(moduleName, 'ccaConfirmation', {
            url: '/Confirmation/:signUpId'
        })
        ng1State.state(moduleName, 'paymentComplete', {
            url: '/paymentComplete',
            component: 'paymentResult',
            // params: {
            //     isSuccess: null,
            //     message: null,
            //     merchantReference: null,
            //     items: null
            // },
            // resolve: {
            //     isSuccess: function ($transition$) {
            //         return $transition$.params().isSuccess;
            //     },
            //     message: function ($transition$) {
            //         return $transition$.params().message;
            //     },
            //     merchantReference: function ($transition$) {
            //         return $transition$.params().merchantReference;
            //     },
            //     items: function ($transition$) {
            //         return $transition$.params().items;
            //     }
            // }
        })
    }
])

.filter('removeHTMLTags', function () {
    return function (text) {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
})


.component('ng1CcaAvailable', {
      template: '<cca-available-dashboard></cca-available-dashboard>',
    })
.component('ng1CcaAvailableDetails', {
      template: '<cca-available-details></cca-available-details>',
    })
.component('ng1CcaAvailableReview', {
      template: '<cca-review></cca-review>',
    })
.component('ng1CcaAvailableToJoin', {
      template: '<cca-to-join></cca-available-to-join>',
    })
.component('ng1CcaAvailableSelection', {
      template: '<cca-available-selection></cca-available-selection>',
    })
.component('ng1CcaAvailableSelectionParentsEvening', {
      template: '<cca-available-selection-parents-evening></cca-available-selection-parents-evening>',
    })
.component('ng1CcaAvailableConfirmation', {
      template: '<cca-available-confirmation></cca-available-confirmation>',
    })
.component('ng1CcaAvailablePaymentComplete', {
    template: '<payment-result></payment-result>'
    })
