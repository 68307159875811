'use strict';

angular.module('profile.components.linkedProfiles', [])
    .component('linkedProfiles', {
        templateUrl: '/Scripts/app/profile/components/linked-profiles.template.html',
        bindings: {
            selectedPerson: '<',
            isOrganisationAdmin: '<',
            isPopup: '<',
            organisationTypeId: '<'
        },
        controller: [
            '$scope', '$uibModal',
            function ($scope, $uibModal) {

                this.onViewButtonClicked = (item) => {

                    $scope.subPersonId = item.thisPersonId;

                    // pull a full instance of the profile screen into a popup!
                    $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: '/Scripts/app/profile/controllers/profile-popup.template.html',
                        controller: 'profilePopupController',
                        size: 'lg',
                        scope: $scope
                    });
                }
            }
        ]
    });