import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option, Options } from '@sb-shared/models/UI/filter';

@Component({
  selector: 'sb-record-type-selector',
  templateUrl: './record-type-selector.component.html',
  styleUrls: ['./record-type-selector.component.scss']
})
export class RecordTypeSelectorComponent {

  @Input() options: Options = [];
  @Input() model: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Input() id: string;
  @Input() form: UntypedFormGroup;
  
  change(optionId: any) {
    this.model = optionId;
    this.modelChange.emit(this.model);
  }

  sortedOptions(): Options {
    return this.options.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  getIconName(option: Option) {
    // Show set iconName, if some options have iconName but some missing, add ellipsis to missing ones
    return option.iconName || (this.options.some(option => option.iconName) ? 'ellipsis' : '');
  }

  hideIcons() {
    return this.options.every(option => option.iconName === undefined);
  }

}
