'use strict';

angular.module('diary.components.diaryEventList', [
    'angular-clipboard',
    'pascalprecht.translate',
    'duScroll',

    'diary.services.diaryService',
    'diary.components.icalLinkPopup',
    'diary.transport.diaryTransportChangeRequest',
    'diary.transport.diaryTransportChangeRequestDetails',

    'shared.directives.sbCheckBox',
    'shared.components.sbSearchFilter',
    'shared.components.sbSearchFilterItem',
    'shared.directives.sbLoading',
    'shared.components.sbDatePicker',
    'shared.components.sbStackedDate',
    'shared.components.sbIcon',
    'shared.components.sbAvatar',
    'shared.services.cachedLookupService',
    'shared.services.portalChangeRequestService',
    'shared.services.registerStateService',
    'shared.services.searchService',
    'shared.services.settingService',
    'shared.services.dateTimeService',
    'shared.services.deviceService',
    'shared.services.blobStoragePhotosService',
    'shared.constants',
])
    .component('diaryEventList',
        {
            bindings: {
                personId: '<',
                highlightInvitations: '<',
                excludeInvitations: '<',
                excludeDiary: '<',
                rowsClickable: '<',
                onRowSelected: '&',
                // these flags refer to the subject person who's diary we're viewing, not the currently logged in user doing the viewing
                // though these can and will often be the same person..
                isStaff: '<',
                isParent: '<',
                showFilters: '<',
                maxEventCount: '@',
                hideMainTitle: '<',
                promoteSubTitlesToMain: '<',
                isDashboard: '<',
                isUsersDiary: '<',
                onLoadStart: '&',
                onLoadEnd: '&',
                onClickAddEvent: '&'
            },
            templateUrl: '/Scripts/app/diary/components/diary-event-list.template.html',
            controller: ['$uibModal',
                '$filter',
                '$interval',
                '$translate',
                '$scope',
                '$window',
                '$timeout',
                '$rootScope',
                '$attrs',
                'diaryService',
                'settingService',
                'cachedLookupService',
                'registerStateService',
                'searchFilterTypes',
                'dateRangeLists',
                'searchService',
                'portalChangeRequestService',
                'simpleAlertService',
                'toastService',
                'dateTimeService',
                'blobStoragePhotosService',
                function ($uibModal,
                    $filter,
                    $interval,
                    $translate,
                    $scope,
                    $window,
                    $timeout,
                    $rootScope,
                    $attrs,
                    diaryService,
                    settingService,
                    cachedLookupService,
                    registerStateService,
                    searchFilterTypes,
                    dateRangeLists,
                    searchService,
                    portalChangeRequestService,
                    simpleAlertService,
                    toastService,
                    dateTimeService,
                    blobStoragePhotosService)
                {
                    this.getDateLabels = function(date) {
                        return dateTimeService.getDateLabels(date);
                    };

                    this.eventConfigCollapsed = true;

                    this.searchFilterTypes = searchFilterTypes;
                    this.dateRangeLists = dateRangeLists;

                    this.todayStart = moment().utc().startOf('day');
                    this.now = moment().utc();
                    this.todayEnd = moment().utc().endOf('day');
                    this.nextEventId = 0;

                    this.isViewerStaff = false; // this refers to the currently signed in user
                    this.uniqueAttendees = [];
                    this.getUniqueEventTypes = [];

                    this.includeDeclined = false;

                    this.uniqueEventTypes = [];
                    this.includedEventTypes = [];

                    this.transportStatusUpdate = null;

                    var memberEnum = $window.EveryBuddy.Enums.MemberType;

                    this.roleTypes = [{
                        id: memberEnum.All,
                        name: 'SB_Combined'
                    }];
                    this.roleType = this.roleTypes[0].id;

                    cachedLookupService.getUserAccessRoles().then(roles => {
                        this.isSwitcher = roles.length > 1;
                        if (roles.includes(memberEnum.Staff)) {
                            this.roleTypes.push({
                                id: memberEnum.Staff,
                                name: 'SB_Staff'
                            });
                        }
                        if (roles.includes(memberEnum.Parents)) {
                            this.roleTypes.push({
                                id: memberEnum.Parents,
                                name: 'SB_Parent'
                            });
                        }
                        if (roles.includes(memberEnum.Pupils)) {
                            this.roleTypes.push({
                                id: memberEnum.Pupils,
                                name: 'SB_Pupil'
                            });
                        }
                    });

                    this.isLoadingMore = false;

                    this.resetSearchFilters = function () {
                        this.includedAttendees = [].concat(this.uniqueAttendees);
                        this.includedEventTypes = [].concat(this.uniqueEventTypes);
                        this.includeDeclined = false;
                        this.onChangeFilters();
                    }.bind(this);

                    this.onChangeFilters = function() {
                        this.currentPage = 1;
                        if (this.events) {
                            this.getFutureDays();
                        }
                        this.getFilteredEvents();
                    }.bind(this);

                    this.getFilteredEvents = function () {
                        this.filteredEvents = $filter('filter')(this.events, this.search);
                    }.bind(this);

                    this.multiSelectEvents = { onSelectionChanged: function () { this.onChangeFilters(); }.bind(this)};

                    var transportConfiguration = function ()
                    {
                        var configuration = {};

                        cachedLookupService.isPupil().then(function(data)
                        {
                            if (!data.isError && data === true)
                            {
                                this.isPupil = true;
                            }
                        }.bind(this));

                        configuration.isItemTransport = function (diaryEventItem) {
                            return diaryEventItem.eventType === 'Transport';
                        };

                        configuration.showAddChangeRequest = function (diaryEventItem) {
                            return configuration.isItemTransport(diaryEventItem) &&
                                   diaryEventItem.transportAttendee &&
                                   diaryEventItem.transportAttendee.showAddChangeRequest &&
                                   !this.isPupil;
                        }.bind(this);

                        configuration.showViewChangeRequest = function (diaryEventItem) {
                            return configuration.isItemTransport(diaryEventItem) &&
                                   diaryEventItem.transportAttendee &&
                                   diaryEventItem.transportAttendee.showViewChangeRequest;
                        };

                        return configuration;

                    }.bind(this);

                    cachedLookupService.isStaff().then(function(data)
                    {
                        if (!data.isError && data === true)
                        {
                            this.isViewerStaff = true;
                        }
                    }.bind(this));

                    this.receptionRegisterStateService = registerStateService;

                    this.registerStateName = {
                        '-1': 'SB_Register_Not_Taken',
                        '0': 'SB_None',
                        '1': 'SB_Present',
                        '2': 'SB_Absent',
                        '3': 'SB_Late',
                        '4': 'SB_Excused'
                    };

                    this.$onChanges = function (changes)
                    {
                        this.from = $rootScope.diaryFrom || moment().startOf('day');
                        this.resetSearchFilters();

                        this.to = $rootScope.diaryTo || moment(this.from).add(1, 'M').endOf('day');

                        if (changes.personId && changes.personId.currentValue)
                        {
                            if (this.excludeDiary) {
                                this.refreshData(true);
                            }
                            else {
                                settingService.getEventTypesSetting()
                                .then(function (data)
                                {
                                    this.eventTypesConfig = data;
                                    this.checkEventTypeParentsEvening();
                                    this.refreshData(true);
                                }.bind(this));
                            }
                        }

                        if (!Number.isInteger(this.maxEventCount))
                        {
                            this.maxEventCount = -1;
                        }

                        this.onClickText = $attrs.onClickAddEvent && this.isStaff ? 'Add event' : null;

                    }.bind(this);

                    this.transport = new transportConfiguration();
                    this.initialLoadComplete = false;
                    this.from = null;
                    this.to = null;

                    this.includeDeclined = false;
                    this.events = undefined;
                    this.invites = null;

                    this.pupilgroupsSortType = "name";
                    this.pupilgroupssortReverse = false;
                    this.pupilgroups = null;

                    this.pageSize = 100;
                    this.currentPage = 1;

                    var loading = 0;
                    this.isLoading = function () { return loading > 0; };

                    this.refreshData = function (clearCache)
                    {

                        this.onLoadStart();
                        this.eventConfigCollapsed = true;
                        loading++;

                        this.events = undefined;

                        if (clearCache)
                        {
                            diaryService.clear();
                        }

                        // Separate lightweight request for invites only.
                        if (this.excludeDiary) {
                            diaryService.getInvitesForPerson(this.personId)
                                .then(function (data) {
                                    this.processDiaryData(data);
                                }.bind(this))
                                .catch(function (error)
                                {
                                    console.log(error)
                                    var message = { message: 'SB_GetEventsForPerson_Error' };
                                    simpleAlertService.errorAlert(message);
                                    loading--;
                                })
                                .finally(function() {
                                }.bind(this));

                            return;
                        }

                        // Full request for diary events when this.excludeDiary not set.
                        diaryService.getEventsForPerson(this.personId,
                                                        this.from,
                                                        this.to,
                                                        // !this.excludeInvitations,
                                                        // Amended for Angular 12 new diary
                                                        true,
                                                        this.eventTypesConfig.includeParentsEvening,
                                                        this.eventTypesConfig.includeTransportEvents,
                                                        this.isDashboard ? 5 : this.maxEventCount)
                            .then(function (data) {
                                this.processDiaryData(data);

                                if (this.events)
                                {
                                    let eventsToGetRouteStatusFor = this.events.filter((event) =>
                                    {
                                        return this.isTransportEvent(event) &&
                                            event.showETAWarnings &&
                                            event.routeStopId &&
                                            this.isEventToday(event);
                                    });

                                    if (eventsToGetRouteStatusFor.length > 0)
                                    {
                                        diaryService.getTransportStatusUpdatesRefreshSettings()
                                            .then(function (response)
                                            {
                                                let diaryEventListRefreshIntervalMilliseconds = response.data.diaryEventListRefreshIntervalSeconds * 1000;
                                                if (diaryEventListRefreshIntervalMilliseconds == 0)
                                                {
                                                    diaryEventListRefreshIntervalMilliseconds = 180000;
                                                }

                                                this.getTransportStatusUpdates(eventsToGetRouteStatusFor);

                                                if (this.transportStatusUpdate == null)
                                                {
                                                    this.transportStatusUpdate = $interval(() =>
                                                    {
                                                        this.getTransportStatusUpdates(eventsToGetRouteStatusFor);
                                                    }, diaryEventListRefreshIntervalMilliseconds); // Refresh transport status at a given interval, typically every 5 minutes
                                                }
                                                this.onLoadEnd({events: this.events});
                                            }.bind(this));
                                    }
                                    else {
                                        this.onLoadEnd({events: this.events});
                                    }
                                }

                            }.bind(this))
                            .catch(function (error)
                            {
                                console.log(error)
                                var message = { message: 'SB_GetEventsForPerson_Error' };
                                simpleAlertService.errorAlert(message);
                                loading--;
                            })
                            .finally(function() {
                                this.onLoadEnd({events: this.events});
                            }.bind(this));
                    }

                    this.isEventToday = ((event) =>
                    {
                        let today = moment();
                        let isToday = today.isSame(event.eventDate, 'day') &&
                            today.isSame(event.eventDate, 'month') &&
                            today.isSame(event.eventDate, 'year');
                        return isToday;
                    });

                    this.getTransportStatusUpdates = ((eventsToGetRouteStatusFor) =>
                    {
                        if (eventsToGetRouteStatusFor.length == 0)
                        {
                            return;
                        };

                        let eventStatusRequests = [];

                        eventsToGetRouteStatusFor.forEach((event) =>
                        {
                            eventStatusRequests.push(
                                {
                                    calendarEventId: event.id,
                                    studentPersonId: event.personId,
                                });
                        });

                        diaryService.getEventUpdates(eventStatusRequests)
                            .then((response) =>
                            {
                                let eventsStatuses = response.data;

                                // We're only interested in incomplete events
                                eventsStatuses = eventsStatuses.filter((eventStatus) => {
                                    return eventStatus.status != 2;
                                });

                                if (eventsStatuses && eventsStatuses.length > 0)
                                {
                                    eventsStatuses.forEach((eventStatus) => {
                                        let eventArray = eventsToGetRouteStatusFor.filter((event) => {
                                            return event.id == eventStatus.eventId &&
                                                eventStatus.studentPersonId == event.personId;
                                        });

                                        if (eventArray.length == 1) {
                                            let event = eventArray[0];

                                            let studentStopStatus = eventStatus.stops.filter((stop) => {
                                                return stop.routeStopId == event.routeStopId;
                                            });

                                            if (studentStopStatus.length > 0) {
                                                // Badge is only visible if the route is either Late or On Schedule.
                                                event.isBadgeVisible = studentStopStatus[0].isBadgeVisible;

                                                event.numberOfMinutesLate = studentStopStatus[0].numberOfMinutesLate;
                                                event.methodName = eventStatus.methodName;
                                                event.badgeClass = eventStatus.badge.class;
                                                event.badgeMessage = eventStatus.badge.label;
                                            }
                                        }
                                    });
                                }
                            },
                                function (error)
                                {
                                    toastService.error();
                                });
                    });

                    this.isTransportEvent = function (event)
                    {
                        return event.eventType == 'Transport';
                    };

                    this.processDiaryData = function (data) {
                        this.initialLoadComplete = true;

                        var events = !data.isError ? data : [];

                        events = diaryService.insertMultiDayEvents(events);

                        if (this.highlightInvitations)
                        {
                            this.invites = [];
                            for (var i = 0; i < events.length; i++)
                            {
                                if (events[i].pendingInvite && !events[i].isAllDay && !events[i].isMultiDayLast)
                                {
                                    this.invites.push(events[i]);
                                }
                            }
                            this.invites = this.invites.sort(function (a, b) { return a.eventDate - b.eventDate; });

                            // when we highlight invites, we now remove them from the main event list
                            events = events.filter(function (a) { return !a.pendingInvite; });
                        }

                        this.events = events.sort(function (a, b) { return a.eventDate - b.eventDate; });

                        if (this.events)
                        {
                            this.events.forEach(function (event)
                            {
                                var linkedEvents = this.events.filter(function (otherEvent)
                                {
                                    return otherEvent.id === event.id;
                                });

                                if (linkedEvents.length > 1)
                                {
                                    linkedEvents = $filter('orderBy')(linkedEvents, 'eventDate');
                                    event.eventFirstDate = linkedEvents[0].eventDate.toDate();
                                    event.eventLastDate = linkedEvents[linkedEvents.length - 1].eventDate.toDate();
                                }

                                if (event.singleAttendee)
                                {
                                    var seperator = '|';
                                    event.splitSingleAttendee = event.singleAttendee.split(seperator);
                                }

                            }.bind(this));
                        }

                        this.getFilteredEvents();

                        this.getUniqueAttendees();

                        var uniqueAttendeeIds = this.uniqueAttendees.map(attendee => {
                            return attendee.id;
                        });

                        blobStoragePhotosService.getPhotoUrlsForStudents(uniqueAttendeeIds).then(data => {
                            if (data.length > 0) {
                                this.events = this.events.map(event => {
                                    return {
                                        ...event,
                                        photoUrl: data.photosUrlsForStudents.find(photo => {
                                            return photo.personId === event.personId;
                                        })
                                    };
                                });
                            }
                            loading--;
                            this.getUniqueEventTypes();
                            this.getFutureDays();
                            this.loadMoreEventsEmpty = false;

                            this.scrollArea = angular.element(document.getElementById('diary-scroll-area'));
                            if (!this.isDashboard && !this.isUsersDiary) {
                                // Move footer to inside scrolling element
                                this.scrollArea.append(footer);
                                body.addClass('mb-0');
                            }
                            // Handle diary scrolling
                            this.scrollArea.on('scroll', function() {
                                if (!this.isUpdatingDateItem) {
                                    var scrolledDate;
                                    this.futureDays.forEach(function (date) {
                                        if (date.eventCount > 0) {
                                            var dateArea = angular.element(document.getElementById($filter('date')(date.value, dateElementFormat)));
                                            var dateAreaPosition = dateArea.prop('offsetTop');
                                            var scrollAreaScrollPosition = this.scrollArea.prop('scrollTop');
                                            if (scrollAreaScrollPosition >= (dateAreaPosition - this.dateElementOffset)) {
                                                scrolledDate = date.value;
                                            }
                                        }
                                    }.bind(this));
                                    if (!scrolledDate) {
                                        scrolledDate = this.futureDays[0].value;
                                    }
                                    this.selectedDateItemId = scrolledDate;
                                    $scope.$apply();
                                    var dateItems = angular.element(document.getElementById('dateItems'));
                                    var dateItem = angular.element(document.getElementById('dateItem-' + $filter('date')(scrolledDate, dateElementFormat)));
                                    dateItems.scrollLeft(dateItem.prop('offsetLeft') - 150, 100).catch(function(error) {});
                                }
                            }.bind(this));

                            this.getScrollableHeight();
                            $rootScope.diaryFrom = this.from;
                            $rootScope.diaryTo = this.to;
                        });
                    };

                    this.loadMoreEvents = function ()
                    {
                        var oldTo = moment(this.to);
                        this.to = moment(this.to).add(1, 'M').endOf('day');
                        this.isLoadingMore = true;

                        diaryService.getEventsForPerson(
                            this.personId,
                            oldTo,
                            this.to,
                            !this.excludeInvitations,
                            this.eventTypesConfig.includeParentsEvening,
                            this.eventTypesConfig.includeTransportEvents,
                            this.maxEventCount)
                            .then(function (data)
                            {
                                var events = !data.isError ? data : [];

                                events = diaryService.insertMultiDayEvents(events);

                                if (this.highlightInvitations)
                                {
                                    this.invites = [];
                                    for (var i = 0; i < events.length; i++)
                                    {
                                        if (events[i].pendingInvite && !events[i].isAllDay && !events[i].isMultiDayLast)
                                        {
                                            this.invites.push(events[i]);
                                        }
                                    }
                                    this.invites = this.invites.sort(function (a, b) { return a.eventDate - b.eventDate; });

                                    // when we highlight invites, we now remove them from the main event list
                                    events = events.filter(function (a) { return !a.pendingInvite; });
                                }

                                var newEvents = events
                                    .sort(function (a, b) { return a.eventDate - b.eventDate; });

                                if (newEvents.length > 0)
                                {
                                    this.events = this.events.concat(newEvents);

                                    this.getUniqueAttendees();
                                    this.getUniqueEventTypes();
                                    this.getFutureDays();
                                    this.loadMoreEventsEmpty = false;
                                } else
                                {
                                    this.loadMoreEventsEmpty = true;
                                }
                                this.isLoadingMore = false;
                            }.bind(this))
                            .catch(function (error)
                            {
                                var message = { message: 'SB_GetEventsForPerson_Error' };
                                simpleAlertService.errorAlert(message);
                                this.isLoadingMore = false;
                            });;
                    }.bind(this);

                    this.onSelectDateItem = function (date)
                    {
                        this.isUpdatingDateItem = true;

                        var dateArea = angular.element(document.getElementById($filter('date')(date, dateElementFormat)));

                        this.scrollArea.scrollTopAnimated(dateArea.prop('offsetTop') - (this.dateElementOffset), 200)
                            .then(function ()
                            {
                                this.isUpdatingDateItem = false;
                            }.bind(this))
                            .catch(function (error) { console.log(error) });

                        this.selectedDateItemId = date;
                    }.bind(this);

                    this.offsetInvites = function() {
                        $timeout(function(){
                            // Offset event scroll position based on any invites
                            var invitesOffset = angular.element(document.getElementById('invites')).prop('offsetHeight');
                            this.dateElementOffset = defaultDateElementOffset - (invitesOffset + 15);
                        }.bind(this));
                    }.bind(this);

                    this.isToday = function(date) {
                        var now = moment();
                        var dateMoment = moment(date);
                        return dateMoment.isSame(now, 'd');
                    };

                    this.isPast = function(date) {
                        var now = moment();
                        var dateMoment = moment(date);
                        return dateMoment.isBefore(now);
                    };

                    this.isMultiDay = function(item) {
                        return !item.eventDate.isSame(item.eventEndDate, 'day');
                    }

                    this.isInactive = function(item) {
                        return item.isCancelled || item.statusId === -1;
                    };

                    this.getFutureDays = function() {

                        var currentDate = this.from.clone();
                        this.selectedDateItemId = currentDate.toDate();

                        this.futureDays = [{ value: this.selectedDateItemId }];

                        while(currentDate.add(1, 'days').diff(this.to) < 0) {
                            this.futureDays.push({value: currentDate.clone().toDate()});
                        }

                        this.futureDays.forEach(function (day) {
                            // check how many events matched to each day
                            var dayEvents = this.events.filter(function(event) {
                                return this.search(event) && event.eventDate.format('L') == moment(day.value).format('L');
                            }.bind(this));
                            day.eventCount = dayEvents.length;
                        }.bind(this));

                    }.bind(this);

                    var body = angular.element(document).find('body');
                    var footer = angular.element(document.getElementById('footer'));
                    var dateElementFormat = 'dd-MMM-yyyy';
                    this.isUpdatingDateItem = false;
                    var defaultDateElementOffset = 60;
                    this.dateElementOffset = defaultDateElementOffset;

                    this.getScrollableHeight = function() {

                        if (!this.isDashboard) {

                            $timeout(function(){

                                var searchFilter = angular.element(document.getElementById('searchFilter'));

                                if (searchFilter && searchFilter.children().length > 0) {

                                    var headerHeight = angular.element(document).find('header').prop('offsetHeight');

                                    var searchFilterHeight = searchFilter.children()[0].offsetHeight;

                                    var windowHeight = $window.innerHeight;

                                    if (this.scrollArea) {
                                        this.scrollArea.css('height', (windowHeight - (headerHeight + searchFilterHeight + 1)) + 'px');
                                    }

                                }

                            }.bind(this));

                        }

                    }.bind(this);

                    angular.element($window).bind('resize', function(){
                        this.getScrollableHeight();
                    }.bind(this));

                    this.$onInit = function () {

                        // if (this.isDashboard) {
                        //     this.onLoadStart();
                        // }

                    }.bind(this);

                    this.checkEventTypeParentsEvening = function() {
                        this.excludeParentsEvening = !this.eventTypesConfig.includeParentsEvening;
                    }.bind(this);

                    // Put footer back where it belongs when leaving the page
                    $scope.$on("$destroy", function(){
                        if (!this.isDashboard && !this.isUsersDiary) {
                            body.append(footer);
                            body.removeClass('mb-0');
                        }
                    }.bind(this));

                    this.getUniqueAttendees = function() {
                        var lists = searchService.getMultiSelectLists(this.events, 'personId', 'attendeeName', this.uniqueAttendees, this.includedAttendees);
                        this.uniqueAttendees = lists.uniqueList;
                        this.includedAttendees = lists.selectedList;
                    }.bind(this);

                    this.getUniqueEventTypes = function() {
                        var lists = searchService.getMultiSelectLists(this.events, 'eventType', 'eventType', this.uniqueEventTypes, this.includedEventTypes);
                        this.uniqueEventTypes = lists.uniqueList;
                        this.includedEventTypes = lists.selectedList;
                    }.bind(this);

                    $translate.onReady().then(function () {
                        this.eventTypesText = $filter('translate')('SB_Event_Types');
                    }.bind(this));

                    this.updateEventTypesConfig = function() {
                        this.uniqueEventTypes = [];
                        this.includedEventTypes = [];
                        settingService.saveEventTypesSetting(JSON.stringify(this.eventTypesConfig));
                        this.checkEventTypeParentsEvening();
                        this.refreshData(true);
                    }.bind(this);

                    this.search = function (item)
                    {
                        var dateRangeMatch = moment(item.eventDate).isAfter(this.from) && moment(item.eventDate).isBefore(this.to);

                        var declinedMatch = this.includeDeclined || item.statusId != -1;

                        var includedAttendeeIds = this.includedAttendees.map(function (att) {
                            return att.id;
                        });

                        var attendeeMatch = this.includedAttendees.length == this.uniqueAttendees.length || includedAttendeeIds.includes(item.personId);

                        var includedEventTypeIds = this.includedEventTypes.map(function (item) {
                            return item.id;
                        });

                        var roleMatch = !this.roleType ||
                        (item.isOrganiser && this.roleType === memberEnum.Staff) ||
                        (item.personId && item.personId !== this.personId && this.roleType === memberEnum.Parents) ||
                        (item.personId && item.personId === this.personId && this.roleType === memberEnum.Pupils);

                        var eventTypeMatch = this.includedEventTypes.length == this.uniqueEventTypes.length || includedEventTypeIds.includes(item.eventType);
                        return declinedMatch && attendeeMatch && eventTypeMatch && dateRangeMatch && roleMatch;

                    }.bind(this);

                    $translate.onReady().then(function () {
                        this.pupilsText = $filter('translate')('SB_Pupils');
                        this.eventTypesText = $filter('translate')('SB_Event_Types');
                    }.bind(this));

                    var self = this;
                    this.icalLinkPopup = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            component: 'icalLinkPopup',
                            size: 'md',
                            controllerAs: '$ctrl',
                            resolve: {
                                personId: function()
                                {
                                    return self.personId;
                                },
                                supportLink: function() {
                                    return settingService.getPersonSetting('iCal_Explanation_SupportUrl')
                                        .then(function(result) {
                                            if (!result.isError) {
                                                return result;
                                            }
                                        }.bind(this));
                                }
                            }
                        });
                    };

                    this.hasDateChangedSinceLastRow = function (thisIndex)
                    {
                        var array = this.filteredEvents;

                        if (!array)
                            return false;

                        if (thisIndex === 0)
                            return true;

                        var indexOffset = this.pageSize * (this.currentPage - 1);
                        thisIndex += indexOffset;

                        var lastIndex = thisIndex - 1;

                        return !array[thisIndex].eventDate.isSame(array[lastIndex].eventDate, 'day');
                    };

                    this.selectEvent = function(item)
                    {
                        if (this.rowsClickable)
                        {
                            if (this.transportStatusUpdate != null)
                            {
                                $interval.cancel(this.transportStatusUpdate);
                                this.transportStatusUpdate = null;
                            }

                            this.onRowSelected()(item);
                        }
                    }.bind(this);

                    this.getStatusText = function (id)
                    {
                        return diaryService.getStatusText(id);
                    };

                    this.showLoadMore = function ()
                    {
                        return
                            this.maxEventCount != '' &&
                            this.maxEventCount > -1 &&
                            !this.isLoadingMore &&
                            !this.loadMoreEventsEmpty &&
                            this.includedAttendees.length > 0 &&
                            this.includedEventTypes.length > 0;
                    };

                    this.showDateRange = function (item) {
                        var first = moment(item.eventFirstDate);
                        var last = moment(item.eventLastDate);
                        return first && !first.isSame(last, 'day');
                    };

                    this.showChangeRequest = function (calendarEventItem) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            component: 'diaryTransportChangeRequest',
                            size: 'lg',
                            windowClass: 'modal-scroll',
                            resolve:
                            {
                                isSchoolbound: function () { return calendarEventItem.isSchoolbound; },
                                routeStopDateTime: function () { return calendarEventItem.routeStopDateTime; },
                                pupilChangeRequestOptions: function ()
                                {
                                    return portalChangeRequestService.getChangeRequestOptions(calendarEventItem.id).catch(function (error) {

                                        console.error('XHR diaryTransportChangeRequest failure : ' + error.status);

                                        var message = { message: 'SB_Error_loading' };

                                        if (error && error.data && error.data.Message) {
                                            message = { message: error.data.Message };
                                        }

                                        simpleAlertService.errorAlert(message);

                                })
                                }.bind(this)
                            }
                        });

                        modalInstance.result.then(function (updateView) {
                            if (updateView && updateView === true) {
                                this.refreshData(true);
                            }
                        }.bind(this), function () { });

                    }.bind(this);

                    this.viewChangeRequestDetails = function (transportAttendeeId) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            component: 'diaryTransportChangeRequestDetails',
                            size: 'lg',
                            windowClass: 'modal-scroll',
                            resolve:
                            {
                                diaryTransportChangeRequest: function () {
                                    return portalChangeRequestService.getChangeRequest(transportAttendeeId).catch (function (error) {

                                        console.error('XHR diaryTransportChangeRequest failure : ' + error.status);

                                        simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                                    })
                                }.bind(this)
                            }
                        });

                        modalInstance.result.then(function (updateView) {
                            if (updateView && updateView === true) {
                                this.refreshData(true);
                            }
                        }.bind(this), function () { });

                    }.bind(this);

                    this.toggleEventConfig = function() {
                        this.eventConfigCollapsed = !this.eventConfigCollapsed;
                    };

                    this.showStatus = function(item) {
                        return (!
                            item.registerState || item.registerState == 0) && item.statusId;
                    };

                    this.showChangeStatus = function(item) {
                        return item.transportAttendee && ((this.transport.showViewChangeRequest(item) && !item.transportAttendee.hideProcessingStatus) || item.transportAttendee.hasLinkedChangeRequest === true);
                    };

                    this.showStatusFilter = () => {
                        return (!this.isSwitcher && !this.isStaff) || (this.isSwitcher && this.roleType !== memberEnum.Staff);
                    };

                    this.showPupilFilter = () => {
                        return this.pupilsText && this.uniqueAttendees.length > 0 &&
                        ((!this.isSwitcher && this.isParent) || (this.isSwitcher && (!this.roleType || this.roleType === memberEnum.Parents)));
                    };

                    this.showSubMenu = () =>
                    {
                        return this.isStaff && (typeof this.isDashboard === 'undefined' || !this.isDashboard);
                    };

                    this.showVideoOpenButton = (item) => {
                        return item.isOnlineParentTeacherConference && item.eventEndDate.isAfter(moment());
                    };
                }]
        });
