import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sb-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.scss']
})
export class GreetingComponent implements OnInit {

  @Input() firstName = '';
  dayPeriod = 0;
  dayPeriods: any = [
    {
      id: 1,
      label: 'SB_Greeting_Morning'
    },{
      id: 2,
      label: 'SB_Greeting_Afternoon'
    },{
      id: 3,
      label: 'SB_Greeting_Evening'
    },
  ];
  currentPeriod = 0;

  constructor() { }

  ngOnInit(): void {
    const date = new Date();
    const hrs = date.getHours();

    let greeting;

    if (hrs < 12)
        this.currentPeriod = 1;
    else if (hrs >= 12 && hrs <= 17)
    this.currentPeriod = 2;
    else if (hrs >= 17 && hrs <= 24)
    this.currentPeriod = 3;
  }

  showGreeting(periodId: number): boolean {
    return periodId === this.currentPeriod;
  }

}
