import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTimeService } from "@sb-shared/services/date-time.service";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  constructor(private dateTime: DateTimeService) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const date = new Date(value);

    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  format(value: NgbDateStruct | null): string {
    if (!value) {
      return '';
    }
    const date = this.dateTime.convertNgbDate(value);
    return formatDate(date, 'd MMM yyyy', 'en-US');
  }
}
