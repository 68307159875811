'use strict';

angular.module('transport.configuration.timetable.cloneTimetableRoute',
    [
        'transport.services.transportTimetablesService',
        'shared.components.sbModalHeader',
        'shared.components.sbRouteTimeline',
        'shared.services.simpleAlertService',
    ])
    .component('cloneTimetableRoute',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/configuration/timetable/clone-timetable-route.template.html',
            controller: [
                '$timeout',
                'transportTimetablesService',
                'simpleAlertService',
                'iconService',
                function cloneTimetableRouteController($timeout, transportTimetablesService, simpleAlertService, iconService)
                {
                    this.isSubmitted = false;
                    this.currentStep = 1;

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve && changes.resolve.currentValue)
                        {
                            this.route = changes.resolve.currentValue.route;
                            this.transportRouteId = changes.resolve.currentValue.transportRouteId;
                            this.timetableRouteId = changes.resolve.currentValue.timetableRouteId;
                            this.timetableRouteName = changes.resolve.currentValue.timetableRouteName;
                            this.timetable = changes.resolve.currentValue.timetable;
                        }
                    }.bind(this);

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.nextStep = function (nextStep)
                    {
                        this.currentStep = nextStep;
                    }.bind(this);

                    this.confirmClone = function ()
                    {
                        if (this.newBusNumber == null || this.newBusNumber == '')
                        {
                            return false;
                        }

                        var clonedRoute =
                        {
                            timetableId: this.timetable.id,
                            transportRouteId: this.transportRouteId,
                            fromTimetableRouteId: this.timetableRouteId,
                            fromTimetableRouteName: this.timetableRouteName,
                            busNumber: this.newBusNumber
                        }

                        transportTimetablesService.cloneTimetableRoute(clonedRoute)
                            .then(function ()
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Cloned',
                                    message: 'SB_Clone_Route_Successful',
                                    messageTranslationParameters:
                                    {
                                        fromBusNumber: this.timetableRouteName,
                                        newBusNumber: this.newBusNumber
                                    },
                                    staticBackdrop: true
                                });

                                closeModal();
                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }
                                alertAndResetOkButton(message);
                            });
                    }

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };
                }
            ]
        });
