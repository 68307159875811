import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from './../models/language';
import { apis, HttpRequestSettings } from './../models/request-settings';
import { HttpWebApiService } from './http-web-api.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageCodeService {

  config: HttpRequestSettings = {
    api: apis.Web
  };
  cachedLanguages$: Observable<Language[]>;

  constructor(private http: HttpWebApiService) { }

  getLanguageCodesForOrganisation(organisationId?: number): Observable<Language[]> {
    if (this.cachedLanguages$) return this.cachedLanguages$;
    const urlRoleBase = 'WebLanguageCode/';
    const urlRoleBasePublic = 'WebLanguageCodePublic/';

    let langServiceCall: Observable<Language[]>;

    if (window.EveryBuddy.DisplayInUserLanguage)
    {
      langServiceCall = this.http.get(urlRoleBase + 'GetLanguageCodesForOrganisation', {
        ...this.config,
        orgId: organisationId
      });
    }
    else
    //use the public service if we are not logged-in
    {
      langServiceCall = this.http.get(urlRoleBasePublic + 'GetLanguageCodesForOrganisation', {
        ...this.config,
        orgId: organisationId
      });
    }

    this.cachedLanguages$ = langServiceCall
    // Add flag icon urls
    .pipe(
      map(languages => languages.map((language: Language) => {
        const countryCode = language.countryCode;
        return {
          ...language,
          languageTag: `${language.languageCode}-${countryCode}`,
          imgUrl: `${environment.cdnBase}flags/${(countryCode === 'hant' ? 'CN' : countryCode).toUpperCase()}.png`
        }
      })),
      shareReplay(1)
    );
    return this.cachedLanguages$;
  }
}
