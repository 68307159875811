'use strict';

angular.module('transport.configuration.timetable.transportTimetables', [
    'transport.configuration.timetable.transportTimetableAdd',
    'transport.configuration.timetable.transportTimetableRoutes',
]).component('transportTimetables', {
    templateUrl: '/Scripts/app/transport/configuration/timetable/transport-timetables.template.html',
    bindings: {
    },
    controller: [
        '$uibModal',
        '$scope',
        function transportTimetablesController($uibModal, $scope)
        {
            this.addTimetable = function ()
            {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimetableAdd',
                    size: 'lg',
                    backdrop: 'static'
                });

                modalInstance.result.then(
                    (responseDto) => {
                        if (responseDto.methodSupportsRoutes) {
                            var routesModalInstance = $uibModal.open(
                                {
                                    animation: true,
                                    component: 'transportTimetableRoutes',
                                    size: 'lg',
                                    backdrop: 'static',
                                    resolve:
                                    {
                                        params: function ()
                                        {
                                            return {
                                                responseDto: responseDto,
                                                isNewTimetable: true
                                            }
                                        }
                                    }
                                });

                            routesModalInstance.result.then(
                                () => $scope.$broadcast('timetablesUpdated'),
                                () => {}
                            );
                        }
                        else {
                            $scope.$broadcast('timetablesUpdated')
                        }
                    },
                    () => {}
                )
            };
        }
    ]
});
