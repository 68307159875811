import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathService {

  constructor() { }

  getPercentage(numerator: number, denominator: number) {
    return (numerator / denominator) * 100;
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }
  
}
