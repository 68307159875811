'use strict';

angular.module('diary.components.diaryEventNotifyOnly',
    [
        'events.components.notifyOnlyEvent',
    ]).component('diaryEventNotifyOnly', {
        templateUrl: '/Scripts/app/diary/components/diary-event-notify-only.template.html',
        bindings: {
            eventId: '<',
            numberOfFutureEventsInSeries: '<',
        },
        controller: [
            function diaryEventNotifyOnlyController()
            {
            }
        ]
    });