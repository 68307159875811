import { Component, OnInit } from '@angular/core';
import { RoleId } from '@sb-shared/enums/role.enum';
import { User } from '@sb-shared/models/user';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { UserService } from '@sb-shared/services/user.service';
import { ProfileDataService } from '../../services/profile-data.service';

@Component({
  selector: 'sb-profile-pupil-medical',
  templateUrl: './profile-pupil-medical.component.html',
  styleUrls: ['./profile-pupil-medical.component.scss']
})
export class ProfilePupilMedicalComponent implements OnInit {

  selectedPerson: User;
  isOrganisationAdmin: boolean;
  misManaged: boolean;

  constructor(
    private profileData: ProfileDataService,
    private userSvc: UserService,
    private orgSvc: OrganisationService
  ) { }

  ngOnInit(): void {
    this.profileData.getUserInContext().subscribe(user => {
      this.selectedPerson = user;
    });

    this.userSvc.getCurrentUser().subscribe(user => {
      this.isOrganisationAdmin = user.userRoleIds.includes(RoleId.Admin);
    });

    this.orgSvc.getCurrentOrganisation().subscribe(org => {
      this.misManaged = org.isMISManaged;
    });
  }
}
