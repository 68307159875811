'use strict';


angular.module('ccasignupModule').component('ccaExistingEventsAATimeSlotted', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-existingeventsaa-timeslotted.template.html',
    bindings: {
        onSelectEvent: '&',
        onAttendeesUpdated: '&'
    },
    controller:
    [
        '$scope',
        '$state',
        '$interval',
        'signUpRepository',
        'eventsService',
        'ccaDataProperties',
        'iconService',
        function ($scope, $state, $interval, signUpRepository, eventsService, ccaDataProperties, iconService) {
            this.$onInit = () => {  // Not indented to avoid merge issues

            $scope.signUp = {};
            $scope.groupEvents = {};
            $scope.selectedGroup = undefined;
            $scope.selectedEvent = undefined;
            $scope.selectedEventCount = 0;
            $scope.groupFilter = '';

            $scope.menuItems = [
                {
                    uisref: '.details',
                    title: 'SB_Details',
                    icon: iconService.getIcon('info')
                },
                {
                    uisref: '.attendees',
                    title: 'SB_Attendees',
                    icon: iconService.getIcon('attendance')
                },
            ];

            $scope.noteTranslationParameters = {
                value: 0
            };

            $interval(() => {
                $scope.noteTranslationParameters.value ++;
            }, 1000);

            $interval(() => {
                $scope.refreshSignUp();
            }, ccaDataProperties.DefaultRefreshTimeout);

            $scope.refreshSignUp = () => {
                if (!$scope.signUpEvents) {
                    $scope.signUpRefreshing = true;
                    signUpRepository.getSignUp($state.params.signUpId)
                        .then((signUp) => {
                            $scope.signUp = signUp;
                            if ($scope.$parent) {
                                // update parent view with any change in sign up state (generating, active etc.)
                                $scope.$parent.signUp = signUp;
                            }
                            if ($scope.eventsGenerating()) {
                                $scope.noteTranslationParameters.value = 0;
                            }
                            else {
                                $scope.getSignUpEvents();
                            }
                        })
                        .finally(() => {
                            $scope.signUpRefreshing = false;
                        });
                }
            };

            $scope.refreshSignUp();

            $scope.getSignUpEvents = () => {
                signUpRepository
                .getSignUpEventsTimeSlotted($state.params.signUpId)
                .then(function (signUpEvents) {
                    $scope.signUpEvents = signUpEvents;

                    var teamIds = [];

                    // break this down into groups with events
                    for (var i = signUpEvents.length; i--;) {
                        // we receive each event as an item, but we wish to artificially group them up by team
                        // to produce a "group -> events" heirarchy
                        var thisEvent = signUpEvents[i];

                        if (!$scope.groupEvents[thisEvent.teamId]) {
                            $scope.groupEvents[thisEvent.teamId] = {
                                name: thisEvent.eventFor,
                                teamId: thisEvent.teamId,
                                organiserId: thisEvent.owningPersonId,
                                events: []
                            };
                            teamIds.push(thisEvent.teamId);
                        }

                        // add the finished event to our collection
                        $scope.groupEvents[thisEvent.teamId].events.push({
                            title: thisEvent.title,
                            calendarEventId: thisEvent.calendarEventId,
                            eventId: thisEvent.calendarEventId,
                            justDate: thisEvent.from.split('T')[0],
                            from: thisEvent.from,
                            spacesLeft: thisEvent.size - thisEvent.attendeeCount,
                            size: thisEvent.size,
                            attendeeCount: thisEvent.attendeeCount,
                            pupils: thisEvent.pupils,
                            blockedOut: thisEvent.blockedOut
                        });
                    }

                    signUpRepository.getOrganisersForTeams(teamIds)
                        .then(function(organisers)
                        {
                            $scope.organisers = organisers;
                        });

                });
            };

            $scope.eventSelectBoxClicked = function(newValue)
            {
                if (newValue)
                    $scope.selectedEventCount++;
                else
                    $scope.selectedEventCount--;
            };

            $scope.setBlockSelected = function(blockStatus)
            {
                var selectedIds = [];

                for (var i = $scope.selectedGroup.events.length; i--;)
                {
                    if ($scope.selectedGroup.events[i].selected)
                    {
                        selectedIds.push($scope.selectedGroup.events[i].eventId);
                    }
                }

                eventsService.blockOutEvents(selectedIds, blockStatus)
                    .then(function(data)
                    {
                        if (!data.isError)
                        {
                            for (var i = $scope.selectedGroup.events.length; i--;) {
                                if ($scope.selectedGroup.events[i].selected)
                                {
                                    $scope.selectedGroup.events[i].selected = false;
                                    $scope.selectedGroup.events[i].blockedOut = blockStatus;
                                }
                            }
                        }
                    })
            };

            $scope.selectGroup = function(item)
            {
                $scope.selectedGroup = item;
                $scope.selectedEvent = undefined;
            };

            $scope.selectEvent = (anEvent) =>
            {
                $scope.selectedEvent = anEvent;
                this.onSelectEvent({event: $scope.selectedEvent});
                $scope.attendees = anEvent.pupils;
                this.onAttendeesUpdated({attendees: $scope.attendees});
                $state.go('.attendees');
            };

            $scope.deselectEvent = () =>
            {
                $scope.selectedEvent = null;
                this.onSelectEvent({event: null});
                $scope.attendees = null;
                this.onAttendeesUpdated({attendees: null});
            };

            $scope.getEventClass = function (event)
            {
                if (event.blockedOut)
                    return 'btn-warning';

                if (event === $scope.selectedEvent)
                    return 'btn-danger';

                if (event.attendeeCount > 0)
                {
                    return 'btn-success';
                }

                return 'btn-default';
            };

            // array mapping functions
            $scope.getRemainingCapacity = function (x) { return x.blockedOut ? 0 : x.size - x.attendeeCount; };
            $scope.getBookingsCount = function (x) { return x.attendeeCount; };
            $scope.getBlockedNumber = function (x) { return x.blockedOut ? 1 : 0; };

            $scope.rowGroupFilter = function(group, filterVal)
            {
                var filter = filterVal.toLowerCase();

                return !filterVal
                    || group.name.toLowerCase().indexOf(filter) > -1
                    || $scope.organisers[group.teamId].indexOf(filter) > -1;
            };

            $scope.eventsGenerating = () => {
                return $scope.signUp.status === 2 || $scope.signUp.status === 5;
            };

    }
    }
    ]});
