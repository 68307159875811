'use strict';

angular.module('ccasignupModule').component('ccaExistingEventsAADetails', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-existingeventsaa-details.template.html',
    bindings: {
        selectedEvent: '<',
    },
    controller: [
    '$scope', 'signUpRepository',
    function ($scope) {

        this.$onChanges = (changes) => {
            if (changes.selectedEvent && changes.selectedEvent.currentValue) {
                $scope.selectedEvent = this.selectedEvent;
            }
        };

        this.$onInit = () => {  // Not indented to avoid merge issues

        // Convert JS Date into YYYY-MM-DDTHH:mm:ss string format used by events table.
        function formatDate(jsDate) {
            return moment(jsDate).format('YYYY-MM-DDTHH:mm:ss');
        }

        $scope.eventUpdated = function (eventDetail) {
            // update the properties that we see in the list

            $scope.selectedEvent.from = formatDate(eventDetail.from);
            $scope.selectedEvent.to = formatDate(eventDetail.to);
            $scope.selectedEvent.size = eventDetail.size;
            $scope.selectedEvent.title = eventDetail.title;

            $scope.selectedEvent.isDeleted = eventDetail.isDeleted;
            $scope.selectedEvent.blockedOut = eventDetail.blockedOut;
        };

        $scope.relatedEventUpdated = function (eventDetail) {

            for (var i = 0; i < $scope.signUpEvents.length; i++) {
                var thisEvent = $scope.signUpEvents[i];

                if (thisEvent.calendarEventId === eventDetail.id) {
                    thisEvent.from = eventDetail.from;
                    thisEvent.to = eventDetail.to;
                    thisEvent.size = eventDetail.size;
                    thisEvent.title = eventDetail.title;
                    thisEvent.isDeleted = eventDetail.isDeleted;
                    thisEvent.blockedOut = eventDetail.blockedOut;
                    break;
                }
            }
        };
    }
    }
]});