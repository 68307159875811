import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sb-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnInit {

  @Input() model: any; // Could have a better name
  @Input() isRequired: boolean;
  @Input() maxLength: number;
  @Input() placeholder: string;
  @Input() saveLabel = 'SB_Save';
  @Input() type: string;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  modelClean: any;
  editLabel = 'SB_Edit';
  cancelLabel = 'SB_Cancel';
  isEditing = false;

  ngOnInit(): void {
      this.modelClean = this.model;
  }

  ngOnChanges(changes: SimpleChanges) {
      const newModel = changes.model?.currentValue?.model
      if (newModel) {
          this.modelClean = newModel;
      }
  }

  toggleIsEditing() {
  if (this.isEditing) {
      this.onSave.emit({value: this.model})
  }
    this.isEditing = !this.isEditing;
  }

  cancel() {
      this.model = this.modelClean;
      this.isEditing = false;
  }
}
