import { HttpRequestSettings, apis } from './../shared/models/request-settings';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  urlBase = 'WebBalance/';
  config: HttpRequestSettings = {
    api: apis.Web
  }

  constructor(private http: HttpWebApiService) { }

  getAllBalances() {
    return this.http.get(this.urlBase + 'GetAllBalances', this.config);
  }
}
