import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { formElements } from '@sb-shared/constants/shared.constant';
import { Options } from '@sb-shared/models/UI/filter';
import { CountryService } from '@sb-shared/services/country.service';
import { IconService } from '@sb-shared/services/icon.service';
import { StringService } from '@sb-shared/services/string.service';

@Component({
  selector: 'sb-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() selectOptions: any[];
  @Input() primaryOptionIds: number[];
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() id: string;
  @Input() idProp = 'id';
  @Input() labelProp = 'name';
  @Input() selectedLabelProp = 'name';
  @Input() sbIconProp = 'iconName';
  @Input() iconClassProp = 'iconClass';
  @Input() iconStyleProp = 'iconStyle';
  @Input() isColourIcons: boolean;
  @Input() imgProp: string;
  @Input() preset: any;
  @Input() isDisabled: boolean;
  @Input() isRequired: boolean;
  @Input() showSearch: boolean;
  @Input() isGrid: boolean;
  @Input() form: UntypedFormGroup;
  @Output() onChange = new EventEmitter<any>();
  @Output() onGetOptions = new EventEmitter<any[]>();

  @ViewChild('select') select: ElementRef;
  @ViewChild('selectInner') selectInner: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  options: any;
  fakeSelectId: string;
  selectId: string;
  selectInnerId: string;
  show = false;
  hideSelectedLabel: boolean;
  altProp: string;
  searchText: string;
  searchInputId: string;
  highlightedOptionId: string;
  currentIndex: number;
  isLoading: boolean;
  isReady: boolean;
  selectedItem: any;

  // Reposition dropdown in response to user actions
  @HostListener('window:scroll', ["$event"])
  @HostListener('window:resize', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onEvent() {
    this.positionInnerElement();
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
      if (!this.select?.nativeElement.contains(e.target)) {
        setTimeout(() => {
            this.show = false;
            this.searchText = '';
        }, 1);
      }
  }

  constructor(private translate: TranslateService,
    private scroller: ViewportScroller,
    private country: CountryService,
    private icon: IconService,
    private string: StringService,
    @Inject(DOCUMENT) private document: Document) {
        this.id = this.id || this.string.randomId();
    }

  ngOnInit(): void {
    this.fakeSelectId = 'fakeSelect-' + this.id;
    this.selectId = 'select-' + this.id;
    this.selectInnerId = this.selectId + '-inner';

    // Apply properties and options for presets
    if (this.preset == formElements.Country || this.preset == formElements.Tel) {
        this.primaryOptionIds = [235, 236, 46];
        this.isLoading = true;
        this.country.getCountries()
            .subscribe(() => {
              (data: Options) => {
                if (data) {
                    this.labelProp = this.preset == formElements.Country ? 'name' : 'phoneLabel';
                    this.selectOptions = data;
                    this.selectOptions.forEach(country => {
                        country.imageUrl = window.EveryBuddy.CdnBase + 'flags/' + country.twoCharCountryCode + '.png?v='  + window.EveryBuddy?.Version;
                    });
                    if (this.preset == formElements.Tel) {
                        // Add phone codes
                        this.hideSelectedLabel = true;
                        this.altProp = 'phoneCountryCode';
                    }
                    this.initSelect();
                }
                else {
                    console.log('No options found for select ' +  this.id);
                }
                this.isLoading = false;
              }
            })
            err => {
              console.log(err);
              this.isLoading = false;

            }
    }
    else if (this.preset === formElements.Icon) {
        this.icon.getIcons()
          .subscribe((data: Options) => {
                if (data) {
                    this.selectOptions = data;
                    this.isGrid = true;
                    this.iconClassProp = "fontAwesomeClassName";
                    this.isColourIcons = true;
                    this.initSelect();
                }
                else {
                    this.isLoading = false;
                }
            })
    }
    else {
        this.initSelect();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
      
      if ((changes.selectOptions && changes.selectOptions.currentValue) ||
            changes.idProp && changes.idProp.currentValue || 
            changes.labelProp && changes.labelProp.currentValue || 
            changes.iconProp && changes.iconProp.currentValue) {
          this.initSelect();
          this.positionInnerElement();
      }
      // Set default item properties if not set
      if (changes.idProp) {
          // Option id
          this.idProp = this.idProp || 'id';
      }
      if (changes.labelProp) {
          // Option label
          this.labelProp = this.labelProp || 'name';
      }
      if (changes.sbIconProp) {
          // Option icon
          this.sbIconProp = this.sbIconProp || 'iconName';
      }                  
      if (changes.iconClassProp) {
          // Option icon
          this.iconClassProp = this.iconClassProp || 'iconClass';
      }
      if (changes.imgProp) {
          // Option icon
          this.imgProp = this.imgProp || 'imageUrl';
      }
      if (changes.selectedLabelProp || changes.labelProp) {
          // Option label when selected
          if (changes.selectedLabelProp && changes.selectedLabelProp.currentValue) {
              // Keep new selectedLabelProp
          }
          else if (changes.labelProp && changes.labelProp.currentValue) {
              this.selectedLabelProp = changes.labelProp.currentValue;
          } 
          else {
              this.selectedLabelProp = this.selectedLabelProp || this.labelProp;
          }
      }
  }

  initSelect() {
      if (this.selectOptions) {
          // Reset local options and recreate with null option based on new data, idProp/labelProp/iconProp
          this.options = Array.from(this.selectOptions);
          
          this.options.forEach((option, index) => {
              option.index = index;
              if (this.preset === formElements.Tel && this.primaryOptionIds) {
                  this.options.forEach(country => {
                      country.phoneLabel = this.translate.instant(`${country.name} (+${country.phoneCountryCode})`);
                      country.isPhoneDefault = this.primaryOptionIds.includes(country[this.idProp]);
                  });
                }
          });

          if (this.primaryOptionIds) {
              const isPrimary = option => {
                return this.primaryOptionIds.includes(option[this.idProp])
              };

              this.options.sort((a, b) => {
                if (!isPrimary(a) && isPrimary(b)) {
                    return 1;
                }
                if (isPrimary(a) && !isPrimary(b)) {
                    return -1;
                }
                if (isPrimary(a) && isPrimary(b)) {
                    return this.primaryOptionIds.indexOf(a.id) > this.primaryOptionIds.indexOf(b.id) ? 1 : -1;
                }
                return a.index > b.index ? 1 : -1;
              });
          }

          // Init search
          this.searchText = '';
          this.showSearch = this.showSearch || this.options.length > 10;
          if (this.showSearch) {
              this.searchInputId = 'selectSearch-' + this.id;
          }
          this.isReady = this.options.length > 0;
          this.onGetOptions.emit(this.options);
          const selectValue = (this.options[0] && typeof this.options[0][this.idProp] === 'number') ? 0 : '';
          const existingBlankOption = this.options.filter(option => {
              return option[this.idProp] == selectValue;
          })[0];
          if (!existingBlankOption && !this.isRequired && this.preset !== formElements.Tel) {
              const blankOption = {};
              blankOption[this.labelProp] = 'SB_None';
              blankOption[this.idProp] = selectValue;
              this.options.unshift(blankOption);
              if (!this.model && !this.isRequired) {
                  this.model = selectValue;
              }
          }
          this.initSelectedItem();
      }
  }

  clickSelect() {
      // Reposition dropdown before opening
      this.show = !this.show;
      setTimeout(() => {
        this.positionInnerElement();
          if (this.show && this.showSearch) {
            this.searchInput?.nativeElement.focus();
            this.highlightedOptionId = '';
          }
      });
  }

  onClickItem(value, e) {
      e.preventDefault();
      this.selectItem(value);
  }

  selectItem(value) {
      this.model = value;
      this.show = false;
      this.searchText = '';
      this.modelChange.emit(this.id);
      this.onChange.emit({id: this.id, value: this.model});
      this.initSelectedItem();
  }

  initSelectedItem() {
    if (this.isReady) {
        return this.options.find(item => item[this.idProp] === this.model);
    }
  }

  keyUp(e) {
      if (this.isReady) {
          const filteredOptions = this.filteredOptions();
          const key = e.which;
          if (this.show) {
              if (key === 13) {
                  // Enter
                  if (filteredOptions.length === 1) {
                      const value = filteredOptions[0][this.idProp];
                      this.selectItem(value);
                      this.show = false;
                  }
                  else if (this.highlightedOptionId !== undefined) {
                      const value = this.highlightedOptionId;
                      this.selectItem(value);
                      this.show = false;
                  }
              }
              else if (key >= 37 && key <= 40) {
                  // Handle directional keys and move between items
                  e.preventDefault();
                  let change;
                  if (this.isGrid) {
                      // Move around grid
                      switch(key) {
                          case 37:
                              // Left
                              change = -1;
                              break;
                          case 38:
                              // Up
                              change = -3;
                              break;
                          case 39:
                              // Right
                              change = 1;
                              break;
                          case 40:
                              // Down
                              change = 3;
                              break;
                          default:
                              change = 0;
                        }
                  } else {
                      // Move up/down regular list
                      switch(key) {
                          case 38:
                              // Up
                              change = -1;
                              break;
                          case 40:
                              // Down
                              change = -1;
                              break;
                          default:
                              change = 0;
                          }
                  }
                  // If target item is after the end of the list, just jump to the last item
                  if (change > 0 && !filteredOptions[this.highlightedOptionId + change]) {
                      this.highlightedOptionId = filteredOptions[filteredOptions.length - 1][this.idProp];
                  }
                  // If target item is before the start of the list, just jump to the first item
                  else if (!filteredOptions[this.highlightedOptionId + change]) {
                      this.highlightedOptionId = filteredOptions[0][this.idProp];
                  }
                  // Otherwise, if an item is currently highlighted, just jump items based on change
                  else if (this.highlightedOptionId !== undefined) {
                      this.changeHighlightedOption(change);
                  }
                  // If no currently selected item, and moving down/right, just select the first item in the list
                  else if (change > 0) {
                      this.highlightedOptionId = filteredOptions[0][this.idProp];
                  }
              }
          }
      }
  }

  changeHighlightedOption(change) {
      this.filteredOptions().forEach((option, index) => {
          if (option[this.idProp] == this.highlightedOptionId) {
              this.currentIndex = index;
          }
      });
      const newOption = this.filteredOptions()[this.currentIndex + change];
      if (newOption) {
          this.highlightedOptionId = newOption[this.idProp];
          const highlightedElementId = 'fakeSelect' + '-' + this.id + '-' + this.highlightedOptionId;
          this.scroller.scrollToAnchor(highlightedElementId);
      }
  }

  filteredOptions() {
      return this.options.filter(option => {
          return (this.searchText === '' || option[this.labelProp].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) &&
          (!this.isRequired || option[this.idProp])
      });
  }

  showOptionDivider(index) {
      if (this.isGrid || this.searchText) {
          return false;
      }
      if (this.filteredOptions()[index + 1] && this.primaryOptionIds) {
          return this.primaryOptionIds.includes(this.filteredOptions()[index][this.idProp]) && 
          !this.primaryOptionIds.includes(this.filteredOptions()[index + 1][this.idProp]);
      }
      return false;
  }

  positionInnerElement() {
      // Check if in modal
      let modalOffsetTop = 0;
      let modalOffsetLeft = 0;
      const modalContent =  this.document.getElementsByClassName('modal-content');
      if (modalContent && modalContent[0]) {
          modalOffsetTop = modalContent[0].getBoundingClientRect().top;
          modalOffsetLeft = modalContent[0].getBoundingClientRect().left;
      }
      // Position dropdown on select
      const select = this.select?.nativeElement;
      const selectInner = this.selectInner?.nativeElement;
      if (select && selectInner) {
          let selectPositionTop = select.getBoundingClientRect().top - modalOffsetTop;
          const maxPositionTop = window.innerHeight - 350;
          // Make sure dropdown is not below window area
          if (selectPositionTop > maxPositionTop) {
              selectPositionTop = maxPositionTop;
          }
          const selectPositionLeft = select.getBoundingClientRect().left - modalOffsetLeft;
          const selectHeight = select.offsetHeight;
          selectInner.style.top = (selectPositionTop + selectHeight + 1).toString() + 'px';
          selectInner.style.left = selectPositionLeft.toString() + 'px';
      }
  }

  getFullImgUrl(item: any) {
      if (this.preset == formElements.Country || this.preset == formElements.Tel) {
          return item.imageUrl;
      }
      else {
          return window.EveryBuddy.CdnBase + 'img/icons/' + item[this.imgProp];
      }
  }
  
  onBlur() {
      this.show = false;
  }

  onFocusSearch() {
      setTimeout(() => {
          this.show = true;
      }, 2);
  }

}
