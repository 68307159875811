import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MomentOrDate } from '@sb-helpers';
import { DateRangeList, SearchFilterType, searchFilterFormats, searchFilterTypes } from '@sb-shared/constants/shared.constant';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { Ng1DatePickerChange } from './../../models/events/ng1-date-range-picker-change';

@Component({
  selector: 'sb-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('filterContent', { static: false }) filterContent;

  @Output() inputValueChange = new EventEmitter();
  @Output() startDateChange: EventEmitter<MomentOrDate> = new EventEmitter<MomentOrDate>();
  @Output() endDateChange: EventEmitter<MomentOrDate> = new EventEmitter<MomentOrDate>();
  @Output() searchTextChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeDateRange: EventEmitter<Ng1DatePickerChange> = new EventEmitter<Ng1DatePickerChange>();
  @Output() resetAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectDateItem = new EventEmitter();
  @Output() collapseStart: EventEmitter<void> = new EventEmitter<void>();
  @Output() collapseFinish: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitFilters: EventEmitter<void> = new EventEmitter<void>();

  @Input() searchFilterType: SearchFilterType = searchFilterTypes.TextSearch;
  @Input() label: string;
  @Input() noFilter: boolean;
  @Input() filterCount: number;
  @Input() placeholder: string;
  @Input() dateRangeList: DateRangeList;
  @Input() hideResetButton: boolean;
  @Input() noClass: boolean;
  @Input() filterLabel: string;
  @Input() filterIcon: string;
  @Input() disableFilter: boolean;
  @Input() inputValue;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() diaryPicker: boolean;
  @Input() searchText: string;
  @Input() selectedDateItemId: number;
  @Input() minDate: MomentOrDate;
  @Input() maxDate: MomentOrDate;
  @Input() showFilters: boolean;
  @Input() isSingleArea: boolean;
  @Input() pageSize: number;
  @Input() autoClose: boolean;

  isCollapsed = true;
  eventCountLabels = [1, 2, 3, 4, 5];
  customDateOptions: { autoApply: boolean, singleDatePicker: boolean, alwaysShowCalendars?: boolean };
  isEmpty: boolean;
  datesElement;
  isDatesAtStart: boolean;
  isDatesAtEnd: boolean;
  pageSizes: number[] = [5, 10, 20, 50, 100];
  searchFilterTypes = searchFilterTypes;
  searchFilterFormats = searchFilterFormats;

  constructor(
    private translate: TranslateService,
    private dateTime: DateTimeService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

    if (!this.searchFilterType) {
      console.error('Unknown search filter type');
      return;
    }
    this.placeholder = this.placeholder || (this.searchFilterType.defaultPlaceholder ? this.translate.instant(this.searchFilterType.defaultPlaceholder) : '');
    this.label = this.label || (this.searchFilterType.defaultLabel ? this.translate.instant(this.searchFilterType.defaultLabel) : '');

    // Set datepicker options based on type
    const isDateSingle = this.searchFilterType === this.searchFilterTypes.DateSingle;
    this.customDateOptions = {
      autoApply: isDateSingle,
      singleDatePicker: isDateSingle
    };
  }

  ngAfterViewInit() {
    this.isEmpty = this.filterContent?.nativeElement?.childNodes?.length === 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startDate && changes.startDate.currentValue instanceof Date) {
      this.startDate = changes.startDate.currentValue;
    }
    if (changes.endDate && changes.endDate.currentValue instanceof Date) {
      this.endDate = changes.endDate.currentValue;
    }
    if (changes.showFilters?.currentValue === true) {
      this.isCollapsed = false;
    }
    if (changes.isSingleArea?.currentValue === true) {
      this.isCollapsed = true;
    }
  }

  getDateLabels(value) {
    return this.dateTime.getDateLabels(value);
  }

  isEndOfWeek(value) {
    return this.dateTime.isEndOfWeek(value);
  }

  onChangeText() {
    // If text filter, autoupdate
    if (this.searchFilterType === searchFilterTypes.TextFilter) {
      this.inputValueChange.emit(this.inputValue);
    }
  }

  onSubmitText() {
    this.inputValueChange.emit(this.inputValue);
  }

  onChangeDate($event: Ng1DatePickerChange) {
    this.startDate = $event.startDate;
    this.endDate = $event.endDate;
    this.startDateChange.emit(this.startDate);
    this.endDateChange.emit(this.endDate);
    this.changeDateRange.emit({ startDate: this.startDate, endDate: this.endDate });
  }

  onSelectDateItem(date) {
    if (date.eventCount > 0) {
      this.selectDateItem.emit(date.value);
    }
  }

  checkDatesPosition() {
    setTimeout(() => {
      const scrollLeft = this.datesElement.prop('scrollLeft');
      this.isDatesAtStart = scrollLeft === 0;
      this.isDatesAtEnd = (scrollLeft === (this.datesElement.prop('scrollWidth') - this.datesElement.prop('offsetWidth')));
    });
  }

  onClickArrow(isForward) {
    const currentPosition = this.datesElement.prop('scrollLeft');
    const scrollDistance = (this.datesElement.prop('offsetWidth') - 100);
    const positionChange = currentPosition + (isForward ? scrollDistance : -scrollDistance);
    this.datesElement.scrollLeft(positionChange, 350)
      .then(() => {
        this.checkDatesPosition();
      });
  }

  toggleCollapse() {
    this.collapseStart.emit();
    this.isCollapsed = !this.isCollapsed;
    setTimeout(() => {
      this.collapseFinish.emit();
    }, 600);
  }

  onResetFilter() {
    this.resetAll.emit();
  }

  changePageSize(pageSize) {
    this.pageSize = parseInt(pageSize);
    this.pageSizeChange.emit(this.pageSize);
  }

  hidePageSize() {
    return !this.pageSize || !this.pageSizes.find(pageSize => pageSize === this.pageSize);
  }

  onSubmitFilters() {
    this.submitFilters.emit();
  }

  isCollapsible() {
    return !this.noFilter && !this.isEmpty && !this.showFilters && !this.isSingleArea
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.autoClose || !this.isCollapsible()) {
      return null;
    }
    if (this.elementRef.nativeElement.contains(event.target)) {
      // Clicked inside
    } else {
      // Clicked outside
      this.isCollapsed = true;
    }
  }

}
