'use strict';

angular.module('ccasignupModule')
    .component('ccaCancelOrDeleteEvents',
        {
            templateUrl: '/Scripts/app/ccaSignUp/Details/cca-cancel-or-delete-events.template.html',
            controller: class CcaCancelOrDeleteEventsCtrl
            {
                $scope: any;
                $state: any;
                $timeout: any;
                $translate: any;

                cachedLookupService: any;
                ccaService: any;
                ccaSignUpDayOfWeek: any;
                dateTextService: any;
                signUpRepository: any;
                simpleAlertService: any;

                filter: any;
                filterCount: number;
                signUpGroups: any;
                groups: any;
                filteredGroups: any;
                loading: any = {};
                signUp: any = {};
                signUpId: number;

                locations: any;
                organiserNames: any;
                daysOfWeek: any;

                initialPage: any = null;
                returnPage: any = null;

                pageSizes: any[] = [
                    { pageSize: undefined, label: 'SB_All' },
                    { pageSize: 5, label: '5' },
                    { pageSize: 10, label: '10' },
                    { pageSize: 20, label: '20' },
                    { pageSize: 50, label: '50' },
                    { pageSize: 100, label: '100' }
                ];

                pageSize: any;
                sortType: any = 'title';
                sortReverse: boolean = false;
                statuses: any[] = [{ sortOrder: 0, id: 6, label: 'SB_CcaStatus_Active' }];

                static $inject = ['$state', '$timeout', '$translate', 'cachedLookupService', 'ccaService', 'ccaSignUpDayOfWeek', 'simpleAlertService', 'dateTextService', 'signUpRepository'];

                constructor($state,
                            $timeout,
                            $translate,
                            cachedLookupService,
                            ccaService,
                            ccaSignUpDayOfWeek,
                            simpleAlertService,
                            dateTextService,
                            signUpRepository)
                {
                    this.$state = $state;
                    this.cachedLookupService = cachedLookupService;
                    this.ccaSignUpDayOfWeek = ccaSignUpDayOfWeek;
                    this.ccaService = ccaService;
                    this.dateTextService = dateTextService;
                    this.$timeout = $timeout;
                    this.$translate = $translate;
                    this.simpleAlertService = simpleAlertService;
                    this.signUpRepository = signUpRepository;

                    this.signUpId = this.$state.params.signUpId;

                    this.filter = {};

                    this.initialPage = this.$state.params.searchData && this.$state.params.searchData.currentPage || 1;
                    this.returnPage = this.initialPage;
                }

                $onInit()
                {
                    this.refreshData();
                }

                groupsFilter(group)
                {
                    if (typeof this === 'undefined' || this.filter === 'undefined')
                    {
                        return true;

                    }
                    if (this.filter?.title && group.title && group.title.toLowerCase().indexOf(this.filter?.title.toLowerCase()) === -1)
                    {
                        return false;
                    }

                    if (this.filter?.organiserName && group.organiserName && group.organiserName.toLowerCase().indexOf(this.filter?.organiserName?.toLowerCase()) === -1)
                    {
                        return false;
                    }

                    if (typeof this.filter.dayofWeek !== 'undefined' &&
                        this.filter.dayofWeek >= 0)
                    {
                        let dayMatchesFilter = false;

                        if (group.dayofWeek >= 0 &&
                            group.dayofWeek === this.filter.dayofWeek)
                        {
                            dayMatchesFilter = true;
                        }

                        if (!dayMatchesFilter &&
                            group.linkedGroups !== 'undefined' &&
                            Array.isArray(group.linkedGroups))
                        {
                            group.linkedGroups.forEach((linkedGroup) =>
                            {
                                if (linkedGroup.dayofWeek === this.filter.dayofWeek)
                                {
                                    dayMatchesFilter = true;
                                }
                            });
                        }

                        if (!dayMatchesFilter)
                        {
                            return false;
                        }
                    }

                    if (this.filter?.location && group.location && group.location.toLowerCase().indexOf(this.filter?.location.toLowerCase()) === -1)
                    {
                        return false;
                    }

                    return true;
                };

                onChangeFilter()
                {
                    let activeFilters = Object.values(this.filter).filter(val =>
                    {
                        return val !== '' && val !== 0;
                    });

                    this.filterCount = activeFilters.length;

                    if (this.filter.dayofWeek == this.ccaSignUpDayOfWeek.All.id)
                    {
                        this.filterCount--;
                    };

                    if (JSON.stringify(this.filter) !== JSON.stringify(this.$state.params.searchData.filter))
                    {
                        //Return to first page if filter changed from previous search
                        this.initialPage = this.returnPage || 1;
                        this.$state.params.searchData.filter = [];
                    }

                    this.filteredGroups = [];
                    if (this.groups)
                    {
                        this.filteredGroups = this.groups.filter(item =>
                        {
                            return this.groupsFilter(item);
                        });
                    }
                }

                dateText(dayNumber)
                {
                    if (dayNumber >= 0)
                    {
                        return this.dateTextService.getShortDayText(dayNumber);
                    }

                    if (dayNumber == this.ccaSignUpDayOfWeek.All.id) // All
                    {
                        return this.ccaSignUpDayOfWeek.All.label;
                    }
                };

                refreshData()
                {
                    this.signUpRepository
                        .getSignUp(this.signUpId)
                        .then(signUp => { this.signUp = signUp; });

                    this.signUpRepository
                        .getSignUpGroupsForCancelOrDeleteEvents(this.signUpId)
                        .then(signUpGroups =>
                        {
                            this.signUpGroups = signUpGroups;
                            this.groups = signUpGroups;

                            this.locations = [''];
                            this.organiserNames = [''];
                            this.daysOfWeek = [];

                            if (signUpGroups == null || !Array.isArray(signUpGroups))
                            {
                                return;
                            }

                            signUpGroups.forEach((signUpGroup, index) =>
                            {
                                if (signUpGroup.location !== '' && this.locations.indexOf(signUpGroup.location) === -1)
                                {
                                    this.locations.push(signUpGroup.location);
                                }

                                let organiserName = signUpGroup.organiserName.replace('(', '').replace(')', '');
                                if (this.organiserNames.indexOf(organiserName) === -1)
                                {
                                    this.organiserNames.push(organiserName);
                                }

                                if (this.daysOfWeek.indexOf(signUpGroup.dayofWeek) === -1)
                                {
                                    this.daysOfWeek.push(signUpGroup.dayofWeek);
                                }

                                signUpGroup.availabilitySummary = this.ccaService.getGroupAvailabilitySummary(signUpGroup);
                            });

                            let localeSort = function (a, b)
                            {
                                return a.localeCompare(b);
                            };

                            this.locations.sort(localeSort);
                            this.organiserNames.sort(localeSort);

                            this.daysOfWeek.push(this.ccaSignUpDayOfWeek.All.id)
                            this.daysOfWeek.sort();
                            this.filter.dayofWeek = this.ccaSignUpDayOfWeek.All.id;

                            this.onChangeFilter();
                        });
                };
            }
        });
