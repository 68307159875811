'use strict';

angular.module('diary.components.diaryConsentEventInvites',
    [
        'diary.components.diaryConsentEventInvites',
    ]).component('diaryConsentEventInvites', {
        templateUrl: '/Scripts/app/diary/components/diary-consent-event-invites.template.html',
        bindings: {
            eventId: '<',
        },
        controller: [
            function diaryConsentEventInvitesController()
            {
            }
        ]
    });