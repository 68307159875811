angular.module('corporateContacts.components.corporateContactEditor',
    [
            'contacts.components.addressEditor',
            'corporateContacts.services.corporateContactsService',
            'pascalprecht.translate',
            'ui.tinymce',
            'shared.services.tinymceConfigHelper',
            'shared.directives.sbCurrencyInput',
            'shared.services.simpleAlertService',
            'shared.components.sbIcon',
            'shared.controllers.confirmationPopup'
        ])
    .component('corporateContactEditor',
        {
            bindings: {
                contactId: '<',
                onChange: '&',
                readOnly: '<'
            },
            templateUrl: '/Scripts/app/corporateContacts/components/corporate-contact-editor.template.html',
            controller: [
                'corporateContactsService',
                'tinymceConfigHelper',
                'simpleAlertService',
                function corporateContactEditorController(corporateContactsService, tinymceConfigHelper,simpleAlertService)
                {
                    this.loading = 0;

                    this.contact = null;
                    this.contactTypes = [];
                    var self = this;

                    this.isLoading = function () { return self.loading > 0; };

                    this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                    //$cope.watch no longer supported- this is how we watch for changes (faster)
                    this.$onChanges = function(changes)
                    {
                        if (changes.contactId)
                        {
                            self.contact = null;
                            self.contactAddress = null;
                            self.loadContact();
                        }
                    };

                    self.loading++;
                    corporateContactsService.getContactTypes()
                        .then(function(data)
                        {
                            self.contactTypes = data;
                            self.loading--;
                        });

                    this.loadContact = function()
                    {
                        if (self.contactId === 0)
                        {
                            self.contact = {
                                "address": {
                                    "id": 0
                                },
                                "id": 0,
                                "name": "",
                                "typeId": 1
                            };

                        } else
                        {
                            self.loading++;

                            corporateContactsService.getById(self.contactId)
                                .then(function(data)
                                {
                                    self.contact = data;
                                    self.loading--;
                                });
                        }
                    };

                    this.save = function()
                    {
                        self.loading++;
                        corporateContactsService.saveContact(self.contact)
                            .then(function (data) {
                                self.contact = data;
                                self.loading--;

                                if (typeof (self.onChange()) === 'function' )
                                {
                                    self.onChange()(self.contact);
                                }
                            });
                    };

                    this.delete = function()
                    {
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Delete_contact',
                            message: 'SB_Confirmation_Delete_contact',
                            okButtonText: 'SB_Yes',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });


                        modalInstance
                            .result
                            .then(function () {

                                self.loading++;
                                corporateContactsService.deleteContact(self.contact.id)
                                    .then(function (data) {
                                        self.loading--;
                                        if (data) {
                                            self.contact.isDeleted = true;
                                        }

                                        if (typeof (self.onChange()) === 'function') {
                                            self.onChange()(self.contact);
                                        }
                                    });

                            });
                    }
                }
            ]
        });