import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { of, switchMap, combineLatest, Subscription, Observable, forkJoin } from 'rxjs';
import { MainMenuItem, SubMenuItem } from '@sb-shared/models/UI/menu-item';
import { CurrentLangService } from '@sb-shared/services/current-lang.service';
import { Language } from '@sb-shared/models/language';

@Injectable({
  providedIn: 'root'
})
export class DocTitleService {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private menu: MenuService,
    private title: Title,
    private translate: TranslateService,
    private organisation: OrganisationService,
    private currentLangService: CurrentLangService
  ) { }

  initialise(): void {
    this.subscriptions.add(this.organisation.getCurrentOrganisation()
      .pipe(
        switchMap(organisation => {
          const orgTitle = organisation.name;

          return combineLatest([
            of(orgTitle),
            this.menu.currentSubMenuItem,
            this.menu.currentMainMenuItem,
            // need to grab new title when lang changes
            this.currentLangService.currentLang
          ]);
        }),
        switchMap(([orgTitle, currentSubMenuItem, currentMainMenuItem]: [string, SubMenuItem, MainMenuItem, Language]) => {
          let titleObservable: Observable<string>;
  
          if (currentSubMenuItem) {
            titleObservable = this.translate.get(currentSubMenuItem.label);
          } else if (currentMainMenuItem) {
            titleObservable = this.translate.get(currentMainMenuItem.label);
          } else {
            titleObservable = of(null);
          }

          return forkJoin([
            titleObservable,
            of(orgTitle)
          ]);
        })
      ).subscribe(([title, orgTitle]: [string, string]) => {
        this.setTitle(title, orgTitle);
      })
    );
  }

  private setTitle(title: string, orgTitle: string): void {
    if (!title) {
      this.title.setTitle(orgTitle);
    } else {
      this.title.setTitle(`${title} - ${orgTitle}`);
    }
  }

  destroy() {
    this.subscriptions.unsubscribe();
  }
}
