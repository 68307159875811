import { Component, OnInit } from '@angular/core';
import { UserService } from '@sb-shared/services/user.service';
import { AlertCollapseConfig } from '../alert';

@Component({
  selector: 'sb-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {
  alertCollapseConfig: AlertCollapseConfig = { isCollapsible: true }

  constructor(
    private user: UserService) { }

  message: string;
  isLoading: boolean;

  ngOnInit(): void {
    this.getMessage();
  }

  getMessage() {
    this.isLoading = true;

    this.user.getWelcomeMessage()
      .subscribe(message => {
        this.message = message;
        this.isLoading = false;
      });
  }
}
