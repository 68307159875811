angular.module('sysadmin.components.mainSettingsContainer', [
    'organisation.components.organisationList',
    'sysadmin.components.mainSettingTypes',
    'sysadmin.components.calendarEventTypes',
    'sysadmin.components.imports',
    'sysadmin.components.domainName',
    'sysadmin.components.emailDomains',
    'sysadmin.components.editOrganisationDetails',
    'sysadmin.components.colours',
    'sysadmin.components.languages',
    'sysadmin.components.emailFooter',
    'sysadmin.components.organisationQueueRunButton',
    'sysadmin.components.generateLabels',
    'shared.components.sbSubmitButton',
    'colours.services.colourService',
    'sysadmin.services.organisationLabelService'
]).component('mainSettingsContainer', {
    templateUrl: '/Scripts/app/sysadmin/components/main-settings-container.template.html',
    controller: class MainSettingsContainer {

        // Dependencies
        $uibModal: any;
        colourService: any;
        organisationLabelService: any;

        // Variables
        organisation: any;
        selectedSettingTypeId: any;
        organisationListLastUpdated: any;
        browserColours: any;
        showCalendarEventTypes: boolean;
        showDomainName: boolean;
        showDataProvisioning: boolean;
        showModules: boolean;
        showEmailDomains: boolean;
        showColours: boolean;
        showOrganisationDetails: boolean;
        showLanguages: boolean;
        showEmailFooter: boolean;
        showGenerateLabels: boolean;
        labelGenTextShowing: boolean;
        onLabelGenTextChange: any;

        static $inject = ['$uibModal', 'colourService', 'organisationLabelService'];

        constructor($uibModal, colourService, organisationLabelService) {
            this.$uibModal = $uibModal;
            this.colourService = colourService;
            this.organisationLabelService = organisationLabelService;
            this.onLabelGenTextChange = ((text: string) => this.labelGenTextShowing = !!text).bind(this);
        }

        $onInit() {
            this.organisation = null;
            this.selectedSettingTypeId = null;
            this.organisationListLastUpdated = new Date();
            this.browserColours = this.colourService.getBrowserColours();
            this.hideAllSettings();
        }


        hideAllSettings() {
            this.showCalendarEventTypes = false;
            this.showDomainName = false;
            this.showDataProvisioning = false;
            this.showModules = false;
            this.showEmailDomains = false;
            this.showColours = false;
            this.showOrganisationDetails = false;
            this.showLanguages = false;
            this.showEmailFooter = false;
            this.showGenerateLabels = false;
        };

        showOrganisation(organisation) {
            this.organisation = organisation;
        };

        showSelectedSettingType(settingType) {
            this.selectedSettingTypeId = settingType.id;

            this.hideAllSettings();

            switch (this.selectedSettingTypeId)
            {
                case 1:
                    this.showModules = true;
                    break;
                case 2:
                    this.showDomainName = true;
                    break;
                case 3:
                    this.showColours = true;
                    break;
                case 5:
                    this.showDataProvisioning = true;
                    break;
                case 8:
                    this.showCalendarEventTypes = true;
                    break;
                case 11:
                    this.showEmailDomains = true;
                    break;
                case 12:
                    this.showOrganisationDetails = true;
                    break;
                case 13:
                    this.showLanguages = true;
                    break;
                case 14:
                    this.showEmailFooter = true;
                    break;
                case 15:
                    this.showGenerateLabels = true;
                    break;
            }
        };

        addOrganisation() {
            const modalInstance = this.$uibModal.open({
                animation: true,
                component: 'editOrganisationDetails',
                size: 'xl',
                backdrop: 'static'
            });

            modalInstance
                .result
                .then(() =>
                {
                    this.organisationListLastUpdated = new Date();
                    this.colourService.setBrowserColours(this.browserColours);
                })
                .catch(() =>
                {
                    this.colourService.setBrowserColours(this.browserColours);
                });
        };

        onUpdateOrganisation() {
            this.organisationListLastUpdated = new Date();
        }

        generateLabelsAllOrgs() {
            return this.organisationLabelService.regenerateOrgLabels(0);
        }
    }
});
