import { Component, Input } from '@angular/core';
import { DateFormats } from '@sb-shared/globals/date-formats';

@Component({
  selector: 'sb-time-badge',
  templateUrl: './time-badge.component.html',
  styleUrls: ['./time-badge.component.scss']
})
export class TimeBadgeComponent {

  @Input() startDate: any;
  @Input() endDate: any;
  @Input() isInput: boolean;

  format: string = DateFormats.Time;
}
