'use strict';

angular.module('languagecode.services.languagecodeService', [])
    .factory('languagecodeService', [
        '$http', '$window',  '$cacheFactory',
        function ($http, $window,$cacheFactory) {

            var urlRoleBase = '/webapi/WebLanguageCode/';
            var urlRoleBasePublic = '/webapi/WebLanguageCodePublic/';
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('languagecodeServiceCache');

            repository.getLanguageCodesForOrganisation = function (organisationId) {

                var langServiceCall;

                if ($window.EveryBuddy.DisplayInUserLanguage)
                {
                  langServiceCall = $http.get(urlRoleBase + 'GetLanguageCodesForOrganisation');
                }
                else
                //use the public service if we are not logged-in
                {
                  const params = {
                    organisationId: organisationId
                  };
            
                    langServiceCall = $http.get(urlRoleBasePublic + 'GetLanguageCodesForOrganisation', {
                    params: params
                  });
                }

                return langServiceCall
                    .then(function (response) {
                        return response.data;
                    });
            };

            return repository;
        }
    ]);