angular.module('shared.components.sbBadge', [
    'shared.components.sbIcon'
])
    .component('sbBadge', {
            templateUrl: '/Scripts/app/shared/components/sb-badge.template.html',
            bindings: {
                message: '@',
                messageXs: '@',
                messageTranslationParameters: '<',
                badgeClass: '@',
                icon: '@',
                time: '<'
            },
            controller: class SbBadgeCtrl {

                computedClass: string;
                badgeClass: string = 'primary';
                customClasses: string;

                constructor() {
                    this.computedClass = `badge badge-${this.badgeClass}`;
                }

                $onChanges(changes) {
                    if (changes.badgeClass?.currentValue || changes.customClasses?.currentValue) {
                        this.computedClass = 'badge badge-' + this.badgeClass + ' ' + this.customClasses;
                    }
                }
            }
    });