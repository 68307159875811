import { Injectable } from '@angular/core';
import { formElements } from '@sb-shared/constants/shared.constant';
import { CommonChars } from '@sb-shared/globals/common-chars';

@Injectable({
  providedIn: 'root'
})
export class StringService {

  containsAlphanumeric(string: string): boolean {
    const letterNumber = /^[0-9a-zA-Z]+$/;
    return string.length > 0 && string.split('').filter(char => {return char.match(letterNumber)}).length > 0;
  }

  validateUrl(url: string): boolean {
    const pattern = formElements.Url.pattern;
    return !!pattern.test(url);
  }

  shortenUrl(url: string): string {
      if (!url) {
          return '';
      }
      return url
          .replace('https://www.', CommonChars.Blank)
          .replace('http://www.', CommonChars.Blank)
          .replace('www.', CommonChars.Blank)
  }

  randomId(): string {
    return (Math.random() * 10000).toString();
  }

  formatName(lastName: string, firstName: string, otherName?: string) {
    return `${lastName}, ${firstName}` + (otherName ? ` (${otherName})` : '')
  }

  capitalise(string: string) {
    if (!string) return '';
    return string[0].toUpperCase() + string.substring(1);
  }

  formatString(str: string, ...args: any[]): string {
    return str.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined' ? args[index] : match;
    });
  }
}
