// Filters do not need to be prepared for Angular 2+ because they will need to be rewritten anyway
angular.module('balance.filters.sumByKey', [])
    .filter('sumByKey', [function () {
        return function (data, key) {
            if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
                return 0;
            }

            var sum = 0;
            angular.forEach(data, function (obj, objKey) {
                sum += parseFloat(obj[key]);
            });

            return sum;
        };
    }
]);