<div class="floating-banner"
     [style.position]="position"
     [style.top]="alignment === 'top' ? '0' : ''"
     [style.bottom]="alignment === 'bottom' ? '0' : ''"
     [ngClass]="{'floating-banner--fixed' : position === 'fixed'}">
    <div [innerHTML]="message"
         class="p-large"
         [ngClass]="'test-' + messageClass"></div>
    <sb-submit-button [message]="buttonMessage"
                      [isSubmitted]="isSubmitted"
                      customClasses="btn-lg"
                      (clickSubmit)="buttonClick()"></sb-submit-button>
</div>