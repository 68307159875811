import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Option } from '@sb-shared/models/UI/filter';

@Component({
  selector: 'sb-summary-button',
  templateUrl: './summary-button.component.html',
  styleUrls: ['./summary-button.component.scss']
})
export class SummaryButtonComponent {

  @Input() summary: Option;
  @Input() isSelected: boolean;
  @Output() onClick: EventEmitter<null> = new EventEmitter();

  click() {
    this.onClick.emit()
  }

}
