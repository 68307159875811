"use strict";

angular.module('profile.components.profileOnlineLocation', [
    'person.services',
    'person.services.staffRepository',
])
    .component('profileOnlineLocation',
        {
            templateUrl: '/Scripts/app/profile/components/profile-online-location.template.html',
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            controller: class ProfileOnlineLocationCtrl
            {
                // Dependencies
                $timeout: any;
                personService: any;
                staffRepository: any;
                toastService: any;

                // Bindings
                staffMemberId: any;
                modalInstance: any;

                // Variables
                onlineMeetingId: string;
                firstName: string;
                lastName: string;
                isSubmitted: boolean = false;

                static $inject = ['$timeout', 'personService', 'staffRepository', 'toastService'];

                constructor($timeout, personService, staffRepository, toastService)
                {
                    this.$timeout = $timeout;
                    this.personService = personService;
                    this.staffRepository = staffRepository;
                    this.toastService = toastService;
                }

                $onChanges(changes)
                {
                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.staffMemberId = changes.resolve.currentValue.staffMemberId;
                        this.getData();
                    }
                }

                getData()
                {
                    this.personService.getName(this.staffMemberId)
                        .then(nameLookup =>
                        {
                            this.firstName = nameLookup.firstName;
                            this.lastName = nameLookup.lastName;

                            let staffMemberIds = [this.staffMemberId];
                            this.staffRepository.getStaffInformationForStaffIds(staffMemberIds)
                                .then(res =>
                                {
                                    res.forEach((staffInformation) =>
                                    {
                                        this.onlineMeetingId = staffInformation.onlineMeetingId;
                                    });

                                })
                                .catch(err =>
                                {
                                    console.log(err);
                                })
                        })
                        .catch(err =>
                        {
                            console.log(err);
                        });
                }

                closeModal(response)
                {
                    this.modalInstance.close(response);
                };

                cancel()
                {
                    let response =
                    {
                        status: 'cancel'
                    };

                    this.closeModal(response);
                };

                save()
                {
                    this.staffRepository.updateOnlineMeetingId(this.staffMemberId, this.onlineMeetingId)
                        .then((result) => {
                            this.resetSubmitButtons();

                            let response =
                            {
                                status: 'saved'
                            };

                            this.closeModal(response);
                        });
                }

                resetSubmitButtons()
                {
                    this.$timeout(() =>
                    {
                        this.isSubmitted = false;
                    }, 200);

                };
            }
        });
