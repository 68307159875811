export enum EventTabId {
    Type = 1,
    Who = 2,
    GroupStaff = 3,
    Selection = 4,
    Details = 5,
    Cost = 6,
    Opponent = 7,
    Consent = 8,
    Repeat = 9,
    Confirmation = 10
  }