angular.module('mortgage.components.mortgageStatusEditor',
    [
        'mortgage.services.mortgageService',
        'shared.controllers.confirmationPopup'
    ])
    .component('mortgageStatusEditor',
    {
        bindings: {
            groupId: '<',
            onChange: '&',

        },
        controller: [
            '$uibModal',
            '$window',
            'mortgageService',
            function mortgageStatusEditorController(
                $uibModal,
                $window,
                mortgageService)
            {
                console.log('mortgage status editor loading..');
                this.loading = 0;

                this.baseData = {};

                var self = this;

                this.isLoading = function () { return self.loading > 0; }

                this.$onChanges = function (changes) {
                    if (changes.groupId && changes.groupId.currentValue >= 0) {
                        self.mortgageStatus = { };
                        self.load();
                    }
                };


                this.noneSet = function() {

                    if (self.baseData && self.baseData.mortgageStatus) {
                        for (var i = 0; i < self.baseData.mortgageStatus.length; i++) {
                            var status = self.baseData.mortgageStatus[i];
                            if (status.isSetNow === true) {
                                return false;
                            }
                        }
                    }
                    return true;
                }


                this.setStatus = function(mortgageStatus) {
                    if (mortgageStatus.isManuallySet === true) {
                        if (mortgageStatus.isSetNow) {
                            mortgageStatus.isSetNow = false;
                            mortgageStatus.checkedDate = null;
                            mortgageStatus.adminNotes = null;
                        } else {
                            mortgageStatus.isSetNow = true;
                            mortgageStatus.checkedDate = new Date();
                        }


                    } else {
                        if (!mortgageStatus.isSet) {
                            mortgageStatus.isManuallySet = true;
                            mortgageStatus.isSetNow = true;
                            mortgageStatus.checkedDate = new Date();
                        }

                    }

                }

                this.load = function () {
                    self.loading++;
                    mortgageService.getMortgageStatuses(self.groupId)
                        .then(function (data) {
                            self.loading--;
                            self.baseData = data;

                        })
                        .catch(function (error) {
                            console.error('An error occured loading group detail', error);
                            return error;
                        });
                };

                this.save = function () {
                    self.loading++;

                    mortgageService.saveMortgageStatuses(self.baseData)
                        .then(function (data) {
                            self.baseData = data;
                            self.loading--;

                            if (typeof (self.onChange()) === 'function') {
                                self.onChange()(self.baseData );
                            }
                        });
                };


            }
        ],
        templateUrl: '/Scripts/app/mortgage/components/mortgage-status-editor.template.html'
    });
