'use strict';

angular.module('transport.configuration.transportTimeslotAdd',
    [
        'ccaSignUpType.services.ccaSignUpTypeService',
        'transport.services.transportTimeSlotsService',
        'transport.services.transportDirectionsService',
        'shared.directives.sbCheckBox',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportTimeslotAdd',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/transport/configuration/timeSlots/transport-timeslot-add.template.html',
            controller: [
                '$timeout',
                'ccaSignUpTypeService',
                'transportTimeSlotsService',
                'transportDirectionsService',
                'simpleAlertService',
                function transportTimeslotAddController($timeout,
                                                        ccaSignUpTypeService,
                                                        transportTimeSlotsService,
                                                        transportDirectionsService,
                                                        simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;

                    this.populateHours = function ()
                    {
                        var i = 0;
                        var hours = [];

                        for (i = 0; i < 24; i++)
                        {
                            // populate hours, padding with leading zero.
                            hours.push({ id: i, label: ('0' + i).slice(-2) })
                        }
                        return hours;
                    }

                    this.populateMinutes = function ()
                    {
                        var i;
                        var minutes = [];

                        for (i = 0; i < 60; i++)
                        {
                            // populate minutes, padding with leading zero.
                            minutes.push({ id: i, label: ('0' + i).slice(-2) })
                        }

                        return minutes;
                    }

                    this.hourIsDisabled = function (hour)
                    {
                        return hour > -1 && (hour < this.minHour || hour > this.maxHour);
                    }

                    this.minuteIsDisabled = function (minute)
                    {
                        return minute > -1 && (minute < this.minMinute || minute > this.maxMinute);
                    }

                    this.changesMade = function ()
                    {
                        this.anyChanges = true;
                    };

                    var closeModal = function (responseDto)
                    {
                        this.modalInstance.close(responseDto);
                    }.bind(this);

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportDirections = null;

                    transportDirectionsService.getTransportDirections().then(function (data)
                    {
                        this.transportDirections = data;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    this.$onInit = function ()
                    {
                        this.newTimeSlot =
                            {
                                name: '',
                                transportDirectionId: null,
                                isActive: true,
                                isPrimary: false,
                                isSecondary: false,
                                startHour: { id: null },
                                startMinute: { id: null },
                                endHour: { id: null },
                                endMinute: { id: null },
                                displayOrder: 1,
                                ccaSignUpTypes: []
                            };

                        this.minutes = this.populateMinutes();
                        this.hours = this.populateHours();
                        this.loadCcaSignUpTypesForTransport();

                        //configure the drop down control
                        this.dropdownSettings =
                            {
                            showUncheckAll: false,
                            showCheckAll: false,
                            checkBoxes: true,
                            smartButtonMaxItems: 3,
                            smartButtonTextConverter: function (itemText) { return itemText; }
                            };

                        this.dropdownEventAdd =
                        {
                            onSelectionChanged: function ()
                            {
                            }.bind(this)
                        }

                    }.bind(this);

                    this.loadCcaSignUpTypesForTransport = function ()
                    {
                        ccaSignUpTypeService.getCcaSignUpTypesAvailableForTransport()
                        .then(function (data)
                        {
                            if (!Array.isArray(data))
                            {
                                simpleAlertService.errorAlert();
                                return;
                            }

                            this.ccaSignUpTypes = data;

                        }.bind(this), function (error)
                        {
                            simpleAlertService.errorAlert(error);
                        }.bind(this));

                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.createTransportTimeSlot = function ()
                    {
                        transportTimeSlotsService.createTransportTimeSlot(this.newTimeSlot)
                            .then(function (responseDto)
                            {
                                simpleAlertService.simpleAlert(
                                    {
                                        title: 'SB_Saved',
                                        message: 'SB_Transport_Time_Slot_Created',
                                        staticBackdrop: true
                                    });

                                closeModal(responseDto);
                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });
                    }.bind(this);
                }
            ]
        });
