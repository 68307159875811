'use strict';

angular.module('shared.components.sbExportButton', [
    'shared.services.fileService',
    'shared.constants'
]).component('sbExportButton',
    {
        bindings: {
            tableHeaders: '<',
            data: '<',
            docTitle: '<',
            subtitle: '<',
            description: '<',
            info: '<',
            showDate: '<',
            showOrgInfo: '<',
            showUserInfo: '<',
            boldFirstColumn: '<',
            appendText: '<',
            isDisabled: '<',
            isLandscape: '<',
            customLabel: '<',
            isBulk: '<',
            scrollIntoView: '<',
            usePdfMake: '<'
        },
        templateUrl: '/Scripts/app/shared/components/sb-export-button.template.html',
        controller: class SbExportButtonCtrl {

            $filter: any;
            $translate: any;
            $scope: any;
            fileService: any;

            isOpen: boolean;
            isLoading: boolean;
            fileTypes: any;
            data: any;
            tableHeaders: any;
            showDate: boolean;
            docTitle: string;
            subtitle: string;
            description: string;
            info: any;
            showOrgInfo: boolean;
            showUserInfo: boolean;
            boldFirstColumn: boolean;
            isLandscape: boolean;
            isBulk: boolean;
            scrollIntoView: boolean;
            usePdfMake: boolean;

            static $inject = ['$filter', '$translate', '$scope', 'fileService'];

            constructor($filter, $translate, $scope, fileService) {
                this.$filter = $filter;
                this.$translate = $translate;
                this.$scope = $scope; // Just used for $scope.$apply()
                this.fileService = fileService
            }

            $onInit() {
                this.isOpen = false;
                this.isLoading = false;

                const fileTypeEnum = this.fileService.getFileTypeEnum();
                this.fileTypes = Object.keys(fileTypeEnum)
                    .filter(key => fileTypeEnum[key].isExportable)
                    .reduce((result, key) => {
                        result[key] = fileTypeEnum[key];
                        return result;
                    }, {});

                this.$translate.onReady().then(() => {
                    this.tableHeaders?.forEach((header: string, index: number) => {
                        if (this.tableHeaders[index].content) {
                            this.tableHeaders[index].content = this.$filter('translate')(this.tableHeaders[index].content);
                        }
                        else {
                            this.tableHeaders[index] = this.$filter('translate')(header);
                        }
                    });
                });

            }

            saveFile(fileType) {
                this.docTitle = this.$filter('translate')(this.docTitle);

                // If table headers, add to top of data, unless bulk PDF, because we have a repeated header already in data
                const clonedData = JSON.parse(JSON.stringify(this.data));
                const contentData = this.tableHeaders && !(this.isBulk && fileType === this.fileTypes.PDF) ? [[...this.tableHeaders]].concat(clonedData) : clonedData;
                const formattedDate = this.showDate ? this.$filter('date')(new Date(), 'dd MMM yyyy') : '';
                const headings = [this.docTitle];
                if (this.subtitle) {
                    headings.push(this.subtitle);
                }
                const content = {
                    data: contentData,
                    headings: headings,
                    description: this.description,
                    info: this.info,
                    showOrgInfo: this.showOrgInfo,
                    showUserInfo: this.showUserInfo,
                    boldFirstColumn: this.boldFirstColumn,
                    isLandscape: this.isLandscape
                };
                if (fileType == this.fileTypes.PDF) {
                    this.isLoading = true;
                }

                const fileName = this.docTitle + (this.showDate ? ' ' + formattedDate : '');
                if (fileType === this.fileTypes.PDF && this.usePdfMake) {
                    const pages = this.formatPdfMakePages(content);
                    this.fileService.buildWithPdfMake(pages, fileName, {})
                        .then(() => { this.isLoading = false; });
                }
                else {
                    this.fileService.saveFile(content, fileType, fileName)
                        .then(() => { this.isLoading = false; });
                }
            };

            onToggle(open) {
                if (open && this.scrollIntoView) {
                    document.querySelector('#export-dropdown')?.scrollIntoView();
                }
            }

            // Convert PDF content into pdfmake pages format.
            formatPdfMakePages(content: any) {
                // If content not array, format to array as this function creates multi-page PDFs from arrays
                let pages = {
                    list: [],
                    info: content.topLevelInfo,
                    headingContent: content.headings || [],
                    showOrgInfo: content.showOrgInfo,
                    showUserInfo: content.showUserInfo,
                    boldFirstColumn: content.boldFirstColumn,
                    isLandscape: content.isLandscape
                };
                pages.info = content.info;
                // Handle content for each row of data
                content.data.forEach((item, index) => {
                    const itemContent = item.data || item;
                    // Push data to corresponding page (often will just be a single page when not defined)
                    const pageIndex = item.page || 0;
                    if (pageIndex !== content.data[index - 1] && !pages.list[pageIndex]) {
                        // Set page data if new page and page data not yet defined
                        const newPage = {
                            // Apply main headings if first page (plus any page-specific headings), else page-specific headings
                            headingContent: item.pageHeadings || [],
                            info: [],
                            tableWidths: [],
                            data: []
                        };
                        // Add page info
                        item.pageInfo?.forEach(item => {
                            newPage.info.push({
                                label: item.label,
                                text: item.text
                            });
                        });
                        // Add table column width for each table header cell
                        itemContent.forEach(column => {
                            if (!column.onlyData) {
                                newPage.tableWidths.push('auto');
                            }
                        })
                        // Add new page
                        pages.list[pageIndex] = newPage;
                    }
                    // Add data row to corresponding page
                    pages.list[pageIndex].data.push(itemContent);
                });

                return pages;
            };
        }
    });
