import { TransportTimetableRouteTimeline, Stop } from './../../models/transport-route';
import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sb-route-timeline',
  templateUrl: './route-timeline.component.html',
  styleUrls: ['./route-timeline.component.scss']
})
export class RouteTimelineComponent {
  
  @Input() route: TransportTimetableRouteTimeline;
  @Input() hideOtherStops: boolean;
  isModal?: boolean;
  hasSelectedStop?: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.route) {
      this.hasSelectedStop = this.route.stops.some((stop) => {
        return stop.isSelected;
      });
    }
  }

  hideStop(stop: Stop) {
    if (!this.hideOtherStops) {
      return false;
    }
    if (stop.isSelected) {
      return false;
    }
    const routeStops = this.route.stops;
    if (routeStops[0].routeStopId === stop.routeStopId) {
      return false;
    }
    if (routeStops[routeStops.length - 1].routeStopId === stop.routeStopId) {
      return false;
    }
    return true;
  }

}
