angular.module('sysadmin.services.recentQueueRunService', [])
    .service('recentQueueRunService', class RecentQueueRunService {

        $http: any;
        httpCoreApi: any;
        settings: any;
        baseUrlSlug: any = 'OrganisationQueueRun';

        static $inject = ['$http', 'httpCoreApi'];

        constructor($http, httpCoreApi) {
            this.$http = $http;
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: {
                    ...this.httpCoreApi.controllerTypes.SysAdmin
                }
            }
        }

        getRecentQueueRun(organisationId: number, queueName: string) {
            return this.httpCoreApi.get(`${this.baseUrlSlug}/${queueName}/MostRecent`, {
                ...this.settings,
                urlParams: {
                    organisationId: organisationId
                }
            });
        }
    })
