            
'use strict';

angular.module('shared.services.imageService', [])
    .service('imageService', ['$q',
        function ($q) {

            var service = this;

            service.isImage = function(src) {

                var deferred = $q.defer();

                var image = new Image();
                image.onerror = function() {
                    deferred.resolve(false);
                };
                image.onload = function() {
                    deferred.resolve(true);
                };
                image.src = src;

                return deferred.promise;
            };
        }]);