'use strict';

angular.module('receptionModule').component('receptionRegGroups',  {
    templateUrl: '/Scripts/app/reception/controllers/reception-reggroups.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        onSelectRegGroup: '&'
    },
    controller: [
    '$scope',
    '$state',
    '$window',
    '$compile',
    '$timeout',
    '$uibModal',
    'receptionService',
    'simpleAlertService',
    function (
        $scope,
        $state,
        $window,
        $compile,
        $timeout,
        $uibModal,
        receptionService,
        simpleAlertService)
    {

        $scope.showSearch = false;
        $scope.showNext = false;
        $scope.loadingNext = false;
        $scope.loadingNextDone = false;
        $scope.searchFilters = {
            schoolClassId: 0, schoolYearGroupId: 0
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'shortDate', 'dd-MMM-yyyy', 'dd MMM yyyy'];
        $scope.format = 'dd MMM yyyy';
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.openFrom = function () {
            $scope.popupFrom.opened = true;
        };

        $scope.popupFrom = {
            opened: false
        };

        $scope.groupsFrom = moment.utc().startOf('day').toDate();

        $scope.$watch('groupsFrom', function () {
            if ($scope.selectedRegGroup) {
                $scope.loadingNext = true;
                $scope.selectedRegGroup.members = null;
                receptionService.getRegGroupNextEvent($scope.selectedRegGroup.id, $scope.groupsFrom)
                    .then(function(data) {
                        $scope.selectedRegGroup.members = data;
                        $scope.loadingNextDone = true;
                        $scope.loadingNext = false;
                    },
                    function(error) {
                        $scope.loadingNext = false;
                        return error;
                    });
            }
        });

        receptionService.getRegGroups()
            .then(function (data) {
                $scope.regGroups = data;
            },
            function (error) {
                return error;
            });

        $scope.showNextRegGroupEvent = (aRegGroup) => {

            $state.go('dashboard.reggroups.specificGroup.whatsNext', { groupId: aRegGroup.id });

            $scope.selectedRegGroup = aRegGroup;
            $scope.loadingNext = true;

            receptionService.getRegGroupNextEvent($scope.selectedRegGroup.id, $scope.groupsFrom)
                .then((data) => {
                    $scope.selectedRegGroup.members = data;
                    this.onSelectRegGroup({group: $scope.selectedRegGroup});
                    $scope.loadingNextDone = true;
                    $scope.loadingNext = false;
                },
                    (error) => {
                        $scope.loadingNext = false;
                        return error;
                    });


        }

        $scope.printIt = function () {
            var table = '<div><h1>{{selectedRegGroup.name}} <span ng-bind-html="\'SB_Registration_Group\' | translate></span></h1>' +
                '<table  border="1" style="border-collapse:collapse;"  width="100%"><thead><tr><th ng-bind-html="\'SB_Name\' | translate"></th><th ng-bind-html="\'SB_Time\' | translate"></th><th ng-bind-html="\'SB_Group\' | translate"></th><th ng-bind-html="\'SB_Location\' | translate"></th></tr></thead>' +
                '<tbody><tr ng-repeat="anAttendant in selectedRegGroup.members | orderBy:\'personName\'"><td>{{anAttendant.personName}}</td><td>{{anAttendant.eventTime | date: "dd MMM HH:mm"}}</td><td>{{anAttendant.teamName}}</td><td>{{anAttendant.location}}</td></tr></tbody></table></div>'
            var compiledHTML = $compile(table)($scope);
            $timeout(function () {
                var myWindow = $window.open('', '', 'width=800, height=600');
                myWindow.document.write(compiledHTML[0].innerHTML);
                myWindow.print();
            }, 300);
        };

        $scope.exportIt = function () {
            $window.location = receptionService.getRegGroupCsvUrl($scope.selectedRegGroup.id, $scope.groupsFrom.toISOString().replace('Z', ''));
        };

        $scope.sortType = 'name';
        $scope.sortReverse = false;

        $scope.confirmDeleteRegGroup = function (aRegGroup)
        {
            if (!aRegGroup.canDelete)
            {
                return false;
            }

            var regGroupToDelete = aRegGroup;

            var confirmDeleteModalInstance = $uibModal.open(
                {
                    animation: true,
                    component: 'confirmDeleteRegGroup',
                    size: 'md',
                    backdrop: 'static',
                    resolve:
                    {
                        params: function ()
                        {
                            return { regGroup: regGroupToDelete };
                        }
                    }
                });

            confirmDeleteModalInstance.result
                .then(function (modalResponse)
                {
                    if (modalResponse == 'confirm')
                    {
                        receptionService.deleteRegGroup(aRegGroup.id)
                            .then(function (isDeletionSuccessful)
                            {
                                if (isDeletionSuccessful)
                                {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Reg_Group_Archived',
                                        message: 'SB_Reg_Group_Archived',
                                        staticBackdrop: true
                                    });

                                    $scope.regGroups = null;

                                    receptionService.getRegGroups()
                                        .then(function (data)
                                        {
                                            $scope.regGroups = data;
                                        },
                                            function (error)
                                            {
                                                return error;
                                            });
                                }
                                else
                                {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Error',
                                        message: 'SB_Reg_Group_Archive_Failed',
                                        staticBackdrop: true
                                    });
                                }
                            })
                            .catch(function (responseData)
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Error',
                                    message: 'SB_Reg_Group_Archive_Failed',
                                    staticBackdrop: true
                                });
                            });
                    }

                });
        }
    }
]});