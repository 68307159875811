export const ValidationStates = {
    Default: {},
    RequiredUntouched: {
        iconName: 'asterisk',
        textClass: 'secondary',
        label: 'Required'
    },
    RequiredTouched: {
        iconName: 'asterisk',
        textClass: 'danger',
        label: 'Required'
    },
    Invalid: {
        iconName: 'exclamation',
        textClass: 'danger',
        label: 'Invalid'
    },
    Ok: {
        iconName: 'tick',
        textClass: 'success',
        label: 'SB_Done'
    }
}