'use strict';

angular.module('transport.configuration.archiving.transportConfirmArchiving',
    [
        'shared.components.sbModalHeader',
    ])
    .component('transportConfirmArchiving',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/configuration/archiving/transport-confirm-archiving.template.html',
            controller: [
                function transportConfirmArchivingController()
                {
                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            var params = this.resolve.params;
                            this.transportItemNameToArchive = params.transportItemNameToArchive;
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss();
                    };

                    this.archive = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);
                }
            ]
        });
