angular.module('tuitionFeesModule')
    .service('billingCycleService', class BillingCycleService {

        // Dependencies
        $http: any;
        $window: any;
        httpCoreApi: any;

        // Variables
        coreApiSettings: {};

        static $inject = ['$http', '$window', 'httpCoreApi'];

        constructor($http, $window, httpCoreApi) {
            this.$http = $http;
            this.$window = $window;
            this.httpCoreApi = httpCoreApi;

            this.coreApiSettings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            }
        }

        getCycle(id: number) {
            return this.httpCoreApi.get(`BillingCycle/Cycle/${id}`, this.coreApiSettings);
        }

        getCycles() {
            return this.httpCoreApi.get('BillingCycle/Cycles', this.coreApiSettings);
        }

        addCycle(data) {
            return this.httpCoreApi.post('BillingCycle/Cycle', data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Billing_Cycle_Added'
            });
        }

        updateCycle(data) {
            return this.httpCoreApi.put('BillingCycle/Cycle', data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Billing_Cycle_Updated'
            });
        }

        deleteCycle(id) {
            return this.httpCoreApi.delete(`BillingCycle/Cycle/${id}`, null, {
                ...this.coreApiSettings,
                successMessage: 'SB_Billing_Cycle_Deleted'
            });
        }

        getStudentSearchFilters() {
            return this.httpCoreApi.get('Student/TuitionSearchFilters', this.coreApiSettings);
        }

        studentSearch(searchParameters) {
            return this.httpCoreApi.get('Student/TuitionSearch', {
                ...this.coreApiSettings,
                urlParams: searchParameters
            });
        }

        productSearch(searchParameters) {
            return this.httpCoreApi.get('BillingCycle/ProductSearch', {
                ...this.coreApiSettings,
                urlParams: searchParameters
            });
        }

        getAcademicYears(searchParameters) {
            return this.httpCoreApi.get('Organisation/AcademicYears', {
                ...this.coreApiSettings,
                urlParams: searchParameters
            });
        }

        saveBatch(cycleId, data) {
            return this.httpCoreApi.post(`BillingCycle/Cycle/${cycleId}/Batch`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Batch_Saved'
            });
        }

        getBatchesByCycle(id: number, searchParameters) {
            return this.httpCoreApi.get(`BillingCycle/Cycle/${id}/Batches`, {
                ...this.coreApiSettings,
                urlParams: searchParameters
            });
        }

        getBatchById(cycleId: number, id: number) {
            return this.httpCoreApi.get(`BillingCycle/Cycle/${cycleId}/Batch/${id}`, this.coreApiSettings);
        }

        deleteBatchById(cycleId: number, id: number) {
            return this.httpCoreApi.delete(`BillingCycle/Cycle/${cycleId}/Batch/${id}`, null, {
                ...this.coreApiSettings,
                successMessage: 'SB_Batch_Deleted'
            });
        }

        getPendingInvoicesByCycle(id: number) {
            return this.httpCoreApi.get(`BillingCycle/Cycle/${id}/Invoices`, this.coreApiSettings);
        }

        postInvoices(id: number, data: any) {
            return this.httpCoreApi.post(`BillingCycle/Cycle/${id}/Invoices`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Success',
                // successMessageParams: { code: response.data.data.code }
            });
        }

        getStudentPayers(personId: number) {
            return this.httpCoreApi.get(`Student/Payers/${personId}`, this.coreApiSettings);
        }

        deleteBatchStudent(cycleId: number, batchId: number, studentId: number) {
            return this.httpCoreApi.delete(`BillingCycle/Cycle/${cycleId}/Batch/${batchId}/Student/${studentId}`, null, {
                ...this.coreApiSettings,
                successMessage: 'SB_Batch_Student_Deleted'
            });
        }

        updateBatchStudent(cycleId: number, batchId: number, studentId: number, data: any) {
            return this.httpCoreApi.put(`BillingCycle/Cycle/${cycleId}/Batch/${batchId}/Student/${studentId}`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Batch_Student_Updated'
            });
        }

        getProductsByCycle(cycleId: number, searchParameters) {
            return this.httpCoreApi.get(`BillingCycle/Cycle/${cycleId}/Products`, {
                ...this.coreApiSettings,
                urlParams: searchParameters
            });
        }
    });
