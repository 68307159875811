import { registerLocaleData } from '@angular/common';
import localeArAe from '@angular/common/locales/ar-AE';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeTh from '@angular/common/locales/th';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import localeZHHant from '@angular/common/locales/zh-Hant';

registerLocaleData(localeArAe);
registerLocaleData(localeZh);
registerLocaleData(localeRu);
registerLocaleData(localeEs);
registerLocaleData(localeZHHant);
registerLocaleData(localeArAe);
registerLocaleData(localeKo);
registerLocaleData(localePt);
registerLocaleData(localeDe);
registerLocaleData(localeTh);
registerLocaleData(localeJa);
registerLocaleData(localeFr);
registerLocaleData(localeVi);

export const localeProviders = [
    { provide: localeArAe, useValue: 'ar-ae' },
    { provide: localeZh, useValue: 'zh-cn' },
    { provide: localeRu, useValue: 'ru-ru' },
    { provide: localeEs, useValue: 'es-es' },
    { provide: localeZHHant, useValue: 'zh-hant' },
    { provide: localeKo, useValue: 'ko-kr' },
    { provide: localePt, useValue: 'pt-pt' },
    { provide: localeDe, useValue: 'de-de' },
    { provide: localeTh, useValue: 'th-th' },
    { provide: localeJa, useValue: 'ja-jp' },
    { provide: localeFr, useValue: 'fr-fr' },
    { provide: localeVi, useValue: 'vi-vn' },
]