'use strict';


angular.module('ccasignupModule').component('ccaExistingEventsAA', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-existingeventsaa.template.html',
    bindings: {
        onSelectEvent: '&',
        onAttendeesUpdated: '&'
    },
    controller:
    [
        '$scope',
        '$state',
        '$filter',
        '$uibModal',
        '$window',
        '$compile',
        '$timeout',
        'signUpRepository',
        'eventsService',
        'iconService',
        'arrayService',
        'searchFilterTypes',
        'dateRangeLists',
        function ($scope, $state, $filter, $uibModal, $window, $compile, $timeout, signUpRepository, eventsService, iconService, arrayService, searchFilterTypes, dateRangeLists) {
            this.$onInit = () => {  // Not indented to avoid merge issues
            $scope.dateRangeLists = dateRangeLists;
            $scope.searchFilterTypes = searchFilterTypes;

            $scope.menuItems = [
                {
                    uisref: '.details',
                    title: 'SB_Details',
                    icon: iconService.getIcon('info')
                },
                {
                    uisref: '.attendees',
                    title: 'SB_Attendees',
                    icon: iconService.getIcon('attendance')
                },
            ];

            $scope.signUp = {};

            var weekAgo = moment().subtract(7,'d').startOf('day');
            var today = moment(new Date()).startOf('day');
            $scope.resetSearchFilters = () => {
                const hasEvents = $scope.signUpEvents.length > 0;
                $scope.searchFilters = {
                    seriesId: $scope.uniqueTitles[0].id,
                    teamId: $scope.uniqueTeams[0].id,
                    eventsFrom: hasEvents > 0 ? moment($scope.signUpEvents[0].from).startOf('day') : today,
                    eventsTo: hasEvents ? moment($scope.signUpEvents[$scope.signUpEvents.length - 1].to).startOf('day'): today,
                    includeFeeOnlyEvents: false
                };
            };

            signUpRepository.getSignUp($state.params.signUpId).then(function (signUp) {
                $scope.signUp = signUp;

                if ($scope.$parent) {
                    // update parent view with any change in sign up state (generating, active etc.)
                    $scope.$parent.signUp = signUp;
                }
            });

            signUpRepository
                .getSignUpEvents($state.params.signUpId)
                .then(function (signUpEvents) {

                    $scope.signUpEvents = signUpEvents;
                    var uniqueTitles = [];

                    var uniqueTitleItems = $filter('unique')($scope.signUpEvents, 'seriesId');
                    uniqueTitleItems.sort();

                    for (var i = 0; i < uniqueTitleItems.length; i++) {
                        uniqueTitles.push({
                            id: uniqueTitleItems[i].seriesId,
                            name: uniqueTitleItems[i].title
                        });
                    }

                    $scope.uniqueTitles = arrayService.generateOptions(uniqueTitles, true);

                    var uniqueTeams = [];

                    var uniqueTeamItems = $filter('unique')($scope.signUpEvents, 'teamId');
                    uniqueTeamItems.sort();

                    for (var i = 0; i < uniqueTeamItems.length; i++) {
                        uniqueTeams.push({
                            id: uniqueTeamItems[i].teamId,
                            name: uniqueTeamItems[i].eventFor
                        });
                    }

                    $scope.uniqueTeams = arrayService.generateOptions(uniqueTeams, true);

                    $scope.resetSearchFilters();
                    $scope.minDate = weekAgo;
                });

            $scope.printIt = function () {
                var table = '<div><h1>{{selectedEvent.title}}</h1>' +
                    '<p ><strong>{{selectedEvent.from | date: "EEE"}} {{selectedEvent.from | date: "dd MMM yyyy"}} {{selectedEvent.from | date: "HH:mm"}}</strong></p>' +
                    '<h3 >{{\'SB_Confirmed\' | translate}}</h3><table  border="1" style="border-collapse:collapse;"  width="100%"><thead><tr><th ng-bind-html="\'SB_Name\' | translate"></th><th ng-bind-html="\'SB_Year\' | translate"></th><th ng-bind-html="\'SB_Status\' | translate"></th></tr></thead>' +
                    '<tbody><tr ng-repeat="anAttendee in attendees | filter: {bookingStatusId: 2}"><td>{{anAttendee.lastName}}, {{anAttendee.firstName}}</td>' +
                    '<td>{{anAttendee.yearName}}</td><td></td></tr></tbody></table></div>';
                var compiledHTML = $compile(table)($scope);
                $timeout(function () {
                    var myWindow = $window.open('', '', 'width=800, height=600');
                    myWindow.document.write(compiledHTML[0].innerHTML);
                    myWindow.print();
                }, 300);  // wait for a short while,Until all scope data is loaded If any complex one
            };

            $scope.selectedEventCount = 0;
            $scope.eventSelectBoxClicked = function (newValue) {
                if (newValue)
                    $scope.selectedEventCount++;
                else
                    $scope.selectedEventCount--;
            };

            $scope.setBlockSelected = function (blockStatus) {
                var selectedIds = [];

                for (var i = $scope.signUpEvents.length; i--;) {
                    if ($scope.signUpEvents[i].selected) {
                        selectedIds.push($scope.signUpEvents[i].calendarEventId);
                    }
                }

                eventsService.blockOutEvents(selectedIds, blockStatus)
                    .then(function (data) {
                        if (!data.isError) {
                            for (var i = $scope.signUpEvents.length; i--;) {
                                if ($scope.signUpEvents[i].selected) {
                                    $scope.signUpEvents[i].selected = false;
                                    $scope.signUpEvents[i].blockedOut = blockStatus;
                                }
                            }
                        }
                    })
            };

            $scope.showAttendees = (anEvent) => {
                $scope.selectedEvent = anEvent;
                $scope.attendees = null;
                this.onSelectEvent({event: $scope.selectedEvent});
                signUpRepository.getCcaEventAttendees(anEvent.calendarEventId).then((attendees) => {
                    $state.go('.attendees');
                    $scope.attendees = attendees;
                    this.onAttendeesUpdated({attendees: attendees});
                });

            }

            $scope.pupilDelete = function (pupilId) {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'confirmDeletePupilFromEventModal.html',
                    controller: 'ConfirmDeletePupilFromEventModalInstanceCtrl',
                    size: 'sm'

                });

                modalInstance.result.then(() => {
                    signUpRepository.postDeletePupilEvent($scope.selectedEvent.calendarEventId, pupilId)
                        .then((attendees) => {
                            $scope.attendees = attendees;
                            this.onAttendeesUpdated({attendees: attendees});
                        });
                }, function () {
                });
            };

            $scope.sortType = 'from';
            $scope.sortReverse = false;

            $scope.search = function (item) {

                var seriesId = $scope.searchFilters.seriesId;
                var teamId = $scope.searchFilters.teamId;
                var includeFeeOnlyEvents = $scope.searchFilters.includeFeeOnlyEvents;

                var matchTeam = !teamId || item.teamId === teamId;

                var matchTitle = !seriesId || item.seriesId === seriesId;

                var matchDate = moment(item.from).isSameOrAfter(moment($scope.searchFilters.eventsFrom)) && moment(item.to).isSameOrBefore(moment($scope.searchFilters.eventsTo).endOf('day'));

                var matchFeeOnly = !item.isFee || includeFeeOnlyEvents;

                return matchTeam && matchTitle && matchFeeOnly && matchDate;
            };
        }

        }
    ]});

angular.module('ccasignupModule').filter('sumByKey', ['$filter', function ($filter) {
    return function (data, key) {
        if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
            return 0;
        }

        var sum = 0;
        angular.forEach(data, function (obj, objKey) {
            sum += parseFloat(obj[key]);
        });

        return sum;
    };
}]);


angular.module('ccasignupModule').controller('ConfirmDeletePupilFromEventModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
