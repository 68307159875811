import { Injectable } from '@angular/core';
import { apis, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { TimedCacheService } from '@sb-shared/services/timed-cache.service';
import { Observable } from 'rxjs';
import { Inbox } from '../models/inbox';
import { messageViewingFilters } from '../models/requests/message-viewing-filters';

@Injectable({
  providedIn: 'root'
})
export class MessageViewingService {
  config: HttpRequestSettings = {
    api: apis.Web,
    showSuccessToast: false
  }
  baseUrl = 'WebMessageViewer/';

  constructor(
    private http: HttpWebApiService,
    private timedCacheService: TimedCacheService
  ) { }

  getPersonInbox(filters: messageViewingFilters): Observable<Inbox> {
    return this.http.post(this.baseUrl + 'GetPersonInbox', filters, this.config);
  }

  getPersonInboxUnfiltered(filters: messageViewingFilters) {
    return this.http.post(this.baseUrl + 'GetPersonInboxUnfiltered', filters, this.config);
  }

  getPersonUnreadCount(): Observable<number> {
    return this.timedCacheService.getObservable(
      'getPersonUnreadCount',
      this.http.get(`${this.baseUrl}GetUnreadCount`, this.config),
      60 * 1000
    );
  }

}
