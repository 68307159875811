'use strict';

angular.module('balanceAdmin.summary.components.refundUibContainer',
    [
        'balanceAdmin.summary.components.refund',
        'balanceAdmin.services.balanceAdminService',
        'shared.components.sbModalHeader',
        'shared.services.simpleAlertService',
    ])
    .component('refundUibContainer',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/refund-uib-container.template.html',
            controller: [
                function () {

                    this.transaction = null;

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.transaction = changes.resolve.currentValue.transaction;
                        }
                    }.bind(this);

                    this.onRefundRequested = function (data) {
                        this.modalInstance.close(data);
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);

                }]
        });