import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventDetails } from '@sb-events/models/event-detail';
import { OrganisationLabelTags } from '@sb-shared/constants/organisation-label-tags.constants';
import { isSameDay } from 'date-fns';
// Event list for diary
import { DiaryEvent } from 'src/app/diary/models/diary-event';
import { DiaryService } from 'src/app/diary/services/diary.service';

@Component({
  selector: 'sb-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  @Output() onSelectEvent: EventEmitter<DiaryEvent> = new EventEmitter();
  @Input() isLoading: boolean;
  @Input() invites: DiaryEvent[];
  @Input() events: DiaryEvent[];
  eventDetails: EventDetails;
  organisationLabelTags = OrganisationLabelTags;

  constructor(private diaryService: DiaryService) {}

  ngOnInit() {
    this.diaryService.selectedEventDetail$.subscribe(eventDetails => {
      this.eventDetails = eventDetails;
    });
  }

  hasDateChangedSinceLastRow(index: number, source: DiaryEvent[]) {
    return index === 0 || !isSameDay(source[index].eventDate, source[index - 1].eventDate);
  }

  selectRow(event: DiaryEvent) {
    this.onSelectEvent.emit(event);
  }
}
