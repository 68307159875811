'use strict';

angular.module('team.components.ccaTeamBulkCreatorByRegGroup',
    [
        'angular.filter',
        'teams.services.teamService',
        'ccaSignUp.services.signUpRepository',
        'shared.directives.sbCheckBox',
        'shared.component.progressPopupComponent',
        'teams.components.teamMemberListPopupComponent'
    ])
    .component('ccaTeamBulkCreatorByRegGroup',
        {
            bindings: {
                ccaSignUp: '=',
                ccaSignUpType: '=',
                sports: '=',
                dismissHandler: '&',
                generateFn: '<' // put generate function in here
            },
            templateUrl: '/Scripts/app/teams/components/cca-team-bulk-creator-by-reggroup.template.html',
            controller: [
                '$uibModal',
                '$filter',
                '$rootScope',
                '$translate',
                'teamService',
                'signUpRepository',
                '$timeout',
                 function ($uibModal, $filter, $rootScope, $translate, teamService, signUpRepository, $timeout) {

                    var pleaseWaitModalInstance = null;
                    this.selectedCount = 0;
                    this.createdGroups = [];
                    this.regGroupNames = {};
                    this.subjectNames = [];
                    this.yearNames = [];

                    this.staff = {};

                    this.$onChanges = function (changes) {
                        if (changes.generateFn) {
                            this.generateFn.fn = this.generateGroups;
                            this.generateFn.disabled = true;
                            this.generateFn.generated = false;
                        }
                     }

                     this.teacherTooltip = function () {
                         if (!this.ccaSignUpType || !this.ccaSignUpType.title) {
                             return '';
                         }
                         return $filter('translate')('SB_Bulk_Teacher_Title_Tooltip', { ccaSignupTitle: this.ccaSignUpType.title });
                     }

                    this.progress = { current: 0, target: 0 };

                    this.initTeams = function () {
                         teamService
                             .getRegGroupTeamsAndOrganisers()
                             .then(function (data) {
                                 this.regGroups = data;

                                 for (var i = this.regGroups.length; i--;) {
                                     if (!this.regGroupNames.hasOwnProperty(this.regGroups[i].id)) {
                                         this.regGroupNames[this.regGroups[i].id] = this.regGroups[i].name;
                                     }

                                     if (!this.staff.hasOwnProperty(this.regGroups[i].organiserId)) {
                                         this.staff[this.regGroups[i].organiserId] =
                                         {
                                             organiserId: this.regGroups[i].organiserId,
                                             name: this.regGroups[i].organiserName,
                                             onlineMeetingId: this.regGroups[i].onlineMeetingId
                                         };
                                     }
                                 }

                                 this.staff[0] =
                                 {
                                     organiserId: 0,
                                     name: $translate.instant('SB_None'),
                                     onlineMeetingId: ''
                                 };

                             }.bind(this));
                     }.bind(this);

                    this.initTeams();

                    var resetForm = function () {
                        for (var i = this.regGroups.length; i--;) {
                            this.regGroups[i].selected = false;
                        }
                        for (var i = this.createdGroups.length; i--;) {
                            this.createdGroups[i].selected = false;
                        }
                    }.bind(this);

                    var getGroupCreationHandlerClosure = function (group) {
                        return function (returnedGroup) {
                            group.id = returnedGroup.id;
                            this.createdGroups.push(group);

                            this.progress.current++;

                            if (this.progress.current === this.progress.target) {
                                this.generateFn.generated = true;
                                pleaseWaitModalInstance.close();
                                resetForm();
                                $("html,body").animate({ scrollTop: 0 }, "slow");
                            }
                        }.bind(this);
                    }.bind(this);

                    this.resetGroups = function () {
                        this.createdGroups = [];
                    }.bind(this);

                    this.updateSelectionCount = function (isSelected) {
                        var increment = isSelected ? 1 : -1;
                        this.selectedCount += increment;
                        this.generateFn.disabled = this.selectedCount == 0;
                    }.bind(this);

                    this.updateSelectionCountWithDigest = function (model) {
                        var currentValue = model.selected;

                        // Force a digest cycle so that the angular check box is updated
                        // via 'label for' mark-up.
                        setTimeout(function () {
                            // Make sure the click actually selected it (e.g. click and drag won't select)
                            if (model.selected != currentValue) {
                                this.updateSelectionCount(model.selected);
                                $rootScope.$apply();
                            }
                        }.bind(this), 0);
                    }.bind(this);

                    this.generateGroups = function () {
                        this.progress.current = 0;
                        this.progress.target = 0;

                        // try and find the parents evening sportid
                        var sportId = 0;

                        if (this.sports && this.sports.length > 0) {
                            for (var i = this.sports.length; i--;) {
                                if (this.sports[i].type === 3) {
                                    sportId = this.sports[i].id;
                                    break;
                                }
                            }

                            if (sportId === 0)
                            // shouldn't happen if the org has been correctly set up
                            {
                                sportId = this.sports[0].id;
                            }
                        }

                        for (var i = this.regGroups.length; i--;) {
                            if (this.regGroups[i].selected) {
                                var newGroup = {
                                    ccaTeamClubs: [],
                                    ccaTeamTeams: [
                                        {
                                            requirementGroupId: 0,
                                            id: this.regGroups[i].id,
                                            title: this.regGroups[i].name
                                        }],
                                    ccaTeamSubjectClasses: [],
                                    ccaSignUpId: this.ccaSignUp.ccaSignUpId,
                                    genderTypeId: 3,
                                    allowJoinRequests: false,
                                    title: this.regGroups[i].name + ' ' + this.ccaSignUpType.title,
                                    sportId: sportId,
                                    organiserPersonId: this.regGroups[i].organiserId
                                };

                                this.progress.target++;

                                var loadDelayInMilliseconds = 1000;

                                (function (i, newGroup, loadDelayInMilliseconds) {
                                    $timeout(function () {

                                    signUpRepository.postAndFetchGroup(newGroup)
                                        .then(getGroupCreationHandlerClosure(newGroup));

                                    }, loadDelayInMilliseconds * i);

                                }(i, newGroup, loadDelayInMilliseconds));
                            }
                        }

                        if (this.progress.target == 0) {
                            return false;
                        }

                        pleaseWaitModalInstance = $uibModal.open({
                            animation: true,
                            backdrop: 'static', // prevent modal from closing on backdrop click
                            component: 'progressPopupComponent',
                            size: 'sm',
                            resolve: {
                                message: function () { return 'SB_Generating_teachers'; },
                                progress: function () { return this.progress; }.bind(this)
                            }
                        });

                        return true;
                    }.bind(this);

                    this.showMembers = function (teamId, teamTitle) {
                        $uibModal.open({
                            animation: true,
                            component: 'teamMemberListPopupComponent',
                            size: 'md',
                            resolve: {
                                teamId: function () { return teamId; },
                                teamTitle: function () { return teamTitle; }
                            }
                        });
                    }.bind(this);

                    this.toggleAllRegGroups = function (isSelected) {
                        for (var i = this.regGroups.length; i--;) {
                            if (this.regGroups[i].selected != isSelected) {
                                this.regGroups[i].selected = isSelected;
                                this.updateSelectionCount(isSelected);
                            }
                        }
                    }.bind(this);

                }
            ]
        });
