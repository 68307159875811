'use strict';

angular.module('ccasignupModule').component('ccaAllocations', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-signup-allocations.template.html',
    controller: [
    '$scope',
    '$state',
    '$filter',
    '$translate',
    '$uibModal',
    'dateTextService',
    'signUpRepository', function ($scope,
        $state,
        $filter,
        $translate,
        $uibModal,
        dateTextService,
        signUpRepository) {

        $scope.allocationButtonsEnabled = false;

        $scope.options = {
            overSubscribedOption: 0,
            maximumAllocationTypeId: 1,
            maximumAllocationAllowed: 0
        };

        $scope.signUp = {};

        var signUpId = $state.params.signUpId;

        $scope.sortType = 'title'; // set the default sort type
        $scope.sortReverse = false; // set the default sort order
        $scope.sortType1 = 'title'; // set the default sort type
        $scope.sortReverse1 = false; // set the default sort order

        signUpRepository.getSignUp(signUpId).then(function (signUp) {
            $scope.signUp = signUp;

            $scope.allocationButtonsEnabled = +signUp.status === 1;

            signUpRepository.getAllocationSummarySignUpGroups(signUpId).then(function (signUpGroups) {
                $scope.groups = signUpGroups;
                $scope.calculateTotals();
                $scope.selectedDepth = signUp.autoCcaAllocationDepth;
            });
        });

        $translate.onReady().then(function () {
            $scope.translatedAll = $filter('translate')('SB_All').toUpperCase();
            $scope.translatedAutomatic = $filter('translate')('SB_Automatic').toUpperCase();
            $scope.translatedManual = $filter('translate')('SB_Manual').toUpperCase();

            $scope.overSubscribedOptions = [
                {id: 0, label: $filter('translate')('SB_Random')},
                {id: 1, label: $filter('translate')('SB_First_come_first_serve')},
                {id: 2, label: $filter('translate')('SB_Stop_slash_Manual')}
            ];

            $scope.options.overSubscribedOption = $scope.overSubscribedOptions[0].id;
        });

        $scope.allocateDepth = function (depth) {

            signUpRepository.getAllocationSummarySignUpGroups(signUpId).then(function (signUpGroups) {
                $scope.groups = signUpGroups;
                $scope.calculateTotals();
                $scope.selectedDepth = depth;
            });
        };

        $scope.processDepth = function () {
            $scope.groups = null;
            signUpRepository.postProcessDepth($state.params.signUpId,
                $scope.selectedDepth,
                $scope.options.maximumAllocationTypeId,
                $scope.options.maximumAllocationAllowed)
                .then(function (updatedData) {
                    $scope.signUp = updatedData.signUp;
                    $scope.groups = updatedData.summary.data;
                    $scope.calculateTotals();
                });
        };

        $scope.clearAllocation = function (selectionType) {
            $scope.selectedSelectionType = selectionType;

            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'confirmModal.html',
                controller: 'ClearModalInstanceCtrl',
                size: 'sm'

            });

            modalInstance.result.then(function () {
                $scope.groups = null;
                signUpRepository.postClearAllocation($state.params.signUpId, selectionType)
                    .then(function (updatedData) {
                        $scope.signUp = updatedData.signUp;
                        $scope.groups = updatedData.summary.data;
                        $scope.calculateTotals();
                    });
            }, function () {
            });
        };

        $scope.fastAllocation = function () {
            $scope.groups = null;
            signUpRepository.postFastAllocation($state.params.signUpId,
                $scope.options.overSubscribedOption,
                false,
                $scope.options.maximumAllocationTypeId,
                $scope.options.maximumAllocationAllowed)
                .then(function (updatedData) {
                    $scope.signUp = updatedData.signUp;
                    $scope.groups = updatedData.summary.data;
                    $scope.calculateTotals();
                });
        };

        $scope.fastAllocationConfirmed = function () {
            $scope.groups = null;
            signUpRepository.postFastAllocation($state.params.signUpId,
                $scope.options.overSubscribedOption,
                true,
                $scope.options.maximumAllocationTypeId,
                $scope.options.maximumAllocationAllowed)
                .then(function (updatedData) {
                    $scope.signUp = updatedData.signUp;
                    $scope.groups = updatedData.summary.data;
                    $scope.calculateTotals();
                });
        };

        $scope.ccaGroupDetails = function (signUpGroupId) {
            $state.go('ccaGroupDetails', { signUpId: $state.params.signUpId, signUpGroupId: signUpGroupId });
        };

        $scope.dateText = function (dayNumber)
        {
            return dateTextService.getShortDayText(dayNumber);
        }

        $scope.statusText = function (statusId) {
            switch (statusId) {
                case 0:
                    return 'SB_Not_Started';
                case 1:
                    return 'SB_Partial';
                case 2:
                    return 'SB_Complete';
                case 3:
                    return 'SB_On_Hold';
                case 4:
                    return 'SB_Auto_Complete';
                case 5:
                    return 'SB_Archived';
                case 6:
                    return 'SB_CcaStatus_Active';
                default:
                    return 'SB_Not_Started';
            }
        };

        $scope.calculateTotals = function() {
            $scope.groups.forEach(function (group) {
                group.totalRequests = group.p1Requests + group.p2Requests + group.p3Requests + group.p4Requests + group.p5Requests;
                group.totalAllocations = group.p1Allocations + group.p2Allocations + group.p3Allocations + group.p4Allocations + group.p5Allocations;
            });
        };

    }
]});

angular.module('ccasignupModule').controller('ClearModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
