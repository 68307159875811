angular.module('messagingModule')
    .component('messageRecipients', {
        templateUrl: '/Scripts/app/messaging/components/message-recipients.template.html',
        bindings: {
            resolve: '<',
            dismiss: '&',
            close: '&',
        },
        controller: class MessageRecipients {

            // Dependencies
            allMessagesService: any;

            // Bindings
            resolve: any;
            dismiss: any;

            //Variables
            messageRecipients: [];
            totalMessageRecipients: number;
            messageId: number;
            searchText: string;
            minSubjectSearchChars: number = 3;
            searchParameters: any = {};
            isLoading: boolean;
            recipientsCurrentPage: number = 1;
            pageSize: number = 10;

            static $inject = ['allMessagesService'];

            constructor(allMessagesService) {
                this.allMessagesService = allMessagesService;
            }

            $onInit()
            {
                this.messageId = this.resolve.messageId;
                this.getRecipients();
            }

            handleRecipientSearchChange() {
                this.recipientsCurrentPage = 1;

                if (this.searchText?.length < this.minSubjectSearchChars) {
                    this.searchText = '';
                }

                this.getRecipients();
            }

            getRecipients() {
                this.isLoading = true;

                this.setSearchParameters();
                this.allMessagesService.getRecipients(this.messageId, this.searchParameters)
                .then(res => {
                    this.isLoading = false;
                    if (!res.isError) {
                        this.messageRecipients = res.data;
                        this.totalMessageRecipients = res.totalRecords;
                    }
                });
            }

            setSearchParameters() {
                this.searchParameters = {
                    pageNumber: this.recipientsCurrentPage,
                    pageSize: this.pageSize,
                    searchText: this.searchText
                };
            }

            noResultsForSearch(): boolean {
                return this.searchText?.length >= this.minSubjectSearchChars && this.messageRecipients.length == 0;
            }

            close() {
                this.dismiss('cancel');
            };
        }
    });
