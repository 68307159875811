import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ForbiddenErrorService } from '@sb-shared/services/forbidden-error.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'sb-forbidden-popup',
  templateUrl: './forbidden-popup.component.html'
})
export class ForbiddenPopupComponent implements OnInit {
    public error: HttpErrorResponse;

    @ViewChild('modal') modal: NgbModalRef;

    private opened = false;

    constructor(
        private forbiddenErrorService: ForbiddenErrorService,
        private modalService: NgbModal,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.forbiddenErrorService.errors.subscribe((error: HttpErrorResponse) => {
            if (!this.opened) {
                this.error = error;
                if (this.error.status === 403) {
                  this.router.navigate(['Dashboard'])
                }
                this.openModal();
            }
        });
    }

    getErrorTitle(): string {
        if (this.error.status === 403) {
            return 'SB_Access_Restricted';
        }

        return 'SB_Authentication_Failed';
    }

    getErrorMessage(): string {
        if (this.error.status === 403) {
            return 'SB_Restricted_Message';
        }

        return 'SB_Authentication_Failed_Message';
    }

    openModal(): void {
        this.opened = true;
        this.modalService.open(
            this.modal,
            {
                backdrop: 'static',
                size: 'md',
                keyboard: false
            }
        ).result.then();
    }

    closeModal(modal: NgbModalRef): void {
        this.opened = false;
        modal.close();
    }

    logout(): void {
        this.authService.logout();
    }
}
