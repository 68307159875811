angular.module('tuitionFeesModule')
    .component('addToCycleProducts', {
        bindings: {
            outModel: '<',
            payerModel: '<',
            payerIsEditing: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/add-to-cycle-products.template.html',
        controller: class AddToCycleProductsCtrl {

            // Bindings
            outModel: any;
            payerModel: any;
            payerIsEditing: boolean;

            // Dependencies
            billingCycleService: any;
            searchFilterTypes: any;

            // Variables
            searchParameters: any;
            productSearchResults: any;

            productSearchResultsSortType: string = 'productCode';
            productSearchResultsSortReverse: boolean = false;

            selectedProductsSortType: string = 'productCode';
            selectedProductsSortReverse: boolean = false;

            productSearchTrackingCategories: any[] = [];
            selectedProducts: any[];
            currencyDisplaySymbol: string;
            currencyExponent: number;

            static $inject = ['$window', 'billingCycleService', 'searchFilterTypes'];

            constructor($window, billingCycleService, searchFilterTypes) {
                this.billingCycleService = billingCycleService;
                this.searchFilterTypes = searchFilterTypes;

                this.resetSearchParameters();
                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
            }

            resetSearchParameters() {
                this.searchParameters = {
                    search: ''
                };
            }

            $onInit() {
                this.searchProduct();
            }

            $onChanges(changes) {
                if (changes.outModel?.currentValue) {
                    this.outModel.selectedProducts = [];
                }
                else if (changes.payerIsEditing?.currentValue !== 'undefined' && !changes.payerIsEditing.currentValue) {
                    this.productSearchResults = [];
                    this.outModel.selectedProducts = [];
                    this.onReset();
                }
            }

            searchProduct() {
                this.billingCycleService.productSearch(this.searchParameters).then(res => {
                    if (res.data) {
                        this.productSearchResults = res.data.products;
                        if (!this.productSearchResults) {
                            return;
                        }

                        this.productSearchTrackingCategories = [...Array.from(new Set(res.data.products.flatMap(x => x.trackingCategories.map(y => y.trackingCategoryName))))];

                        // splice over any already selected and arrange TCs
                        this.productSearchResults.forEach(x => {
                            const selectedProductIndex = this.outModel.selectedProducts.findIndex(p => p.id == x.id);
                            if (selectedProductIndex > -1) {
                                x.isSelected = true;
                                // copy over any custom values
                                x.discount = this.outModel.selectedProducts[selectedProductIndex].discount;
                                this.outModel.selectedProducts.splice(selectedProductIndex, 1, x);
                            }

                            x.arrangedTrackingCategories = new Array(this.productSearchTrackingCategories.length);
                            x.trackingCategories.forEach(y => {
                                const i = this.productSearchTrackingCategories.indexOf(y.trackingCategoryName);
                                x.arrangedTrackingCategories[i] = y.trackingCategoryOptionName;
                            });
                        });
                    }
                });
            }

            onReset() {
                this.resetSearchParameters();
                this.searchProduct();
            };

            selectAll() {
                this.productSearchResults.forEach((x) => { this.productSelected(x); });
            }

            productSelected(item) {
                if (!item || item.isSelected) {
                    return;
                }

                item.isSelected = true;
                item.discount = null;
                this.outModel.selectedProducts.push(item);
            }

            deleteSelectedProduct(item) {
                const selectedProductIndex = this.outModel.selectedProducts.findIndex(p => p.id == item.id);
                if (selectedProductIndex == -1) {
                    return;
                }

                item.isSelected = false;
                this.outModel.selectedProducts.splice(selectedProductIndex, 1);
            }

            deleteAllSelected() {
                this.outModel.selectedProducts.forEach(x => { x.isSelected = false; });
                this.outModel.selectedProducts = [];
            }
        }
    });
