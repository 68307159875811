<div class="input-group">
    <input type="number"
           min="0"
           max="100"
           class="form-control"
           [required]="isRequired"
           [ngModel]="modelNumber"
           (ngModelChange)="onChange($event)"
           [ngModelOptions]="{ updateOn: 'blur' }"/>
    <span class="input-group-addon"><i class="far fa-percent"></i></span>
</div>