import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-profile-sso' })
export class ProfileSsoUpgrade extends UpgradeComponent {
  @Input() emailAddress: string;
  @Input() hasSso: boolean;
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1ProfileSso', elementRef, injector);
  }
}
