export const environment = {
  production: false,
  idUrl: 'https://idtest1.schoolsbuddy.net',
  oldApiUrl: 'https://devbuddy-test.azurewebsites.net',
  apiUrl: 'https://devbuddyapi-test1.azurewebsites.net',
  blobBase: 'https://devbuddytest.blob.core.windows.net/',
  cdnBase: 'https://devbuddycdn-test1.azureedge.net/',
  loaderImg: 'SB-vertical-full-color.png',
  serverId: '2',
  froalaLicenceKey: '1CC3kA6A4A4B4B3G3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4B4G3B2G2==',
  paymentProviderConfigs: [
    {
      provider: 'Stripe',
      script: {
        url: 'https://js.stripe.com/v3/?advancedFraudSignals=false',
      }
    },
    {
      provider: 'BrainTree',
      script: {
        url: 'https://js.braintreegateway.com/web/dropin/1.21.0/js/dropin.min.js',
        integrityAttribute: 'sha384-sCbbwWTVvIbqipVITlKlgdBuYjpWAGiKzeZ5amZ09JrVyCNFDBTbfIGPbpVueypQ',
        crossOriginAttribute: 'anonymous',
      }
    },
    {
      provider: 'PaySafe',
      script: {
        url: 'https://hosted.paysafe.com/checkout/v1/latest/paysafe.checkout.min.js',
      }
    },
    {
      provider: 'CheckOut',
      script: {
        url: 'https://cdn.checkout.com/sandbox/js/checkout.js',
        crossOriginAttribute: 'anonymous',
      }
    },
  ]
};
