import { Injectable } from '@angular/core';

@Injectable()
export class LoggingService {
  constructor() {
  }

  logPageView(name?: string, url?: string) { // option to call manually
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
  }

  logException(exception: Error, severityLevel?: number) {
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
  }

}
