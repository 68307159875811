angular.module('messaging.components.allMessages', [
    'angular.filter',
    'shared.directives.sbLoading',
    'shared.components.sbDataTable',
    'shared.components.sbDateRangePicker',
    'shared.constants',
    'messaging.services.allMessagesService',
    'personPicker.constants'
])
    .component('allMessages',
        {
            templateUrl: '/Scripts/app/messaging/components/message-center-all-messages.template.html',
            controller: class AllMessagesCtrl {

                //Dependencies
                allMessagesService: any;
                searchFilterTypes: any;
                messages: any[];

                 // Variables
                isTableProcessing: boolean = false;
                selectedItem: any = null;
                selectedStop: any;
                isError: boolean;
                groupTypeClassMaps: any;
                totalMessages: number = -1;
                currentPage: number = 1;
                pageSize: number = 10;
                pageSizes = [5, 10, 20, 50, 100, 200];
                sortType: string = 'dateTimeSent';
                sortReverse: boolean = true;
                searchParameters: any = {};
                createdByPersonId: number;
                fromDate: any;
                toDate: any;
                dateRangeLists: any;
                sendersList: any[] = [];
                sendersParameters: any = {};
                $uibModal: any;
                $filter: any;
                subjectSearch: string = '';
                minSubjectSearchChars: number = 3;

                memberTypes: any;

                static $inject = ['$uibModal', '$filter', 'allMessagesService', 'dateRangeLists', 'searchFilterTypes', 'memberTypes'];

                constructor($uibModal, $filter, allMessagesService, dateRangeLists, searchFilterTypes, memberTypes) {

                    this.allMessagesService = allMessagesService;
                    this.searchFilterTypes = searchFilterTypes;

                    this.memberTypes = memberTypes;

                    this.dateRangeLists = dateRangeLists;
                    this.fromDate = moment().startOf('day');
                    this.toDate = moment().endOf('day');

                    this.groupTypeClassMaps = {
                        danger: ['Club','Year'],
                        info: ['Event'],
                        warning: ['Team'],
                        success: ['Individuals']
                    }

                    this.$uibModal = $uibModal;
                    this.$filter = $filter;
                }

                formatDate(date, endOfDay = false) {
                    return endOfDay ? date.format('YYYY-MM-DDT23:59:59') : date.format('YYYY-MM-DD');
                }

                handleSubjectSearchChange() {
                    if (this.subjectSearch?.length == 0 || this.subjectSearch?.length >= this.minSubjectSearchChars)
                    {
                        this.getMessages(true);
                    }
                }

                setSearchParameters() {
                    this.searchParameters = {};
                    this.searchParameters.pageNumber = this.currentPage;
                    this.searchParameters.pageSize = this.pageSize;
                    this.searchParameters.orderBy = this.sortType;
                    this.searchParameters.isOrderDescending = this.sortReverse;
                    this.searchParameters.fromUtc = this.formatDate(this.fromDate);
                    this.searchParameters.toUtc = this.formatDate(this.toDate, true);
                    this.searchParameters.createdByPersonId = this.createdByPersonId;

                    if (this.subjectSearch) {
                        this.searchParameters.subject = this.subjectSearch;
                    }
                }

                $onInit() {
                    this.getMessages();
                }

                getMessages(resetcurrPage: boolean = false) {

                    if (resetcurrPage)
                    {
                        this.currentPage = 1;
                    }
                    this.isTableProcessing = true;
                    this.setSearchParameters();

                    this.allMessagesService.getSentMessages(this.searchParameters)
                        .then(res => {
                            this.isTableProcessing = false;
                            if (!res.isError) {
                                this.createRecipientGroupLabelsAndClasses(res.data);
                                this.messages = res.data;
                                this.totalMessages = res.totalRecords;

                                this.getSenders();
                            }
                            else {
                                this.isError = true;
                            }
                        });
                }

                createRecipientGroupLabelsAndClasses(groups: any[]) {
                    groups.forEach(m => {
                        m.recipientGroups.forEach(r => {
                            const memberTypeLabel = r.memberTypeId && Object.values<any>(this.memberTypes).find(memberType => memberType.id == r.memberTypeId)?.label;
                            const memberTypeSuffix = memberTypeLabel ? ` (${this.$filter('translate')(memberTypeLabel)})` : '';
                            let showRecipientsInGroupCount: boolean = r.groupType == 'Individuals' ? true : false;
                            r.label = `${showRecipientsInGroupCount ? r.recipientsInGroupCount : ''} ${r.groupName}${showRecipientsInGroupCount && r.recipientsInGroupCount > 1 ? 's' : ''}${memberTypeSuffix}`;
                            r.class = Object.keys(this.groupTypeClassMaps)
                                        .find(k => this.groupTypeClassMaps[k]
                                            .some(x => x == r.groupType));
                        });
                    });
                }

                getMessage(id)
                {
                    this.allMessagesService.getMessage(id)
                        .then(res => {

                            this.$uibModal.open({
                                animation: true,
                                component: 'messageDetails',
                                size: 'xl',
                                backdrop: 'static',
                                resolve: {
                                    messageDetail: () => {
                                        return res.data;
                                    }
                                }
                            });
                        });
                }

                getSenders() {
                    this.sendersParameters = {};
                    this.sendersParameters.fromUtc = this.formatDate(this.fromDate);
                    this.sendersParameters.toUtc = this.formatDate(this.toDate, true);

                    this.allMessagesService.getSenders(this.sendersParameters)
                        .then(res => {
                            if (!res.isError) {
                                this.sendersList = res.data;
                            }
                            else {
                                this.isError = true;
                            }
                        });
                }

                loadRecipientSearch($event, messageId) {
                    $event.stopPropagation();

                    this.$uibModal.open({
                        animation: true,
                        component: 'messageRecipients',
                        size: 'md',
                        backdrop: 'static',
                        resolve: {
                            messageId: () => {
                                return messageId;
                            }
                        }
                    });
                }
            }
        });
