import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Ng1AuthService } from './ng1-auth.service';
import { Ng1PrinceService } from './ng1-prince.service';

export abstract class Ng1TeamService {
  [key: string]: any;
}

export function ng1TeamsFactory(i: any) {
  return i.get('teamService');
}

export abstract class Ng1Translate {
  onReady: any;
  [key: string]: any;
}

export function Ng1TranslateFactory(i: any) {
  return i.get('$translate');
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: Ng1TeamService,
      useFactory: ng1TeamsFactory,
      deps: ['$injector']
    },
    {
      provide: Ng1Translate,
      useFactory: Ng1TranslateFactory,
      deps: ['$injector']
    },
    Ng1PrinceService,
    Ng1AuthService
  ]
})
export class Ng1ServicesModule { }
