angular.module('person.services.staffRepository', [])
    .factory('staffRepository',
    [
        '$http', 'httpCoreApi', '$q', '$cacheFactory', '$httpParamSerializerJQLike',
        function ($http, httpCoreApi, $q, $cacheFactory, $httpParamSerializerJQLike) {
            var urlBase = '/webapi/WebStaff/';
            var urlStaffInfo = '/webapi/WebStaffInformation/';
            var repository = {};
            var repoCache = $cacheFactory('staffServiceCache');
            var httpCoreApiSettings = {
                controllerType: httpCoreApi.controllerTypes.Admin
            };
            var staffInfoBaseUrl = 'StaffInformation/';

            repository.getStaffNames = function (ids) {
                return $http.post(urlBase + 'GetStaffByIds', ids)
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getStaffForSchedule = function(startDate, endDate)
            {
                return $http.get(urlBase + 'GetStaffForSchedule', { params: { start: startDate, end: endDate } })
                    .then(function(response)
                    {
                        return response.data;
                    });
            };

            repository.getScheduleForStaffMember = function (personId, startDate, endDate)
            {
                return $http.get(urlBase + 'GetStaffMemberSchedule', { params: { personId: personId, start: startDate, end: endDate } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getAllStaff = function()
            {
                return $http.get(urlBase + 'GetAllStaff', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getStaffInformationForStaffIds = function (staffIds)
            {
                return $http.post(urlBase + 'GetStaffInformationForStaffIds', staffIds)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            repository.updateOnlineMeetingId = function (staffMemberId, onlineMeetingId)
            {
                let request =
                {
                    staffMemberId: parseInt(staffMemberId),
                    onlineMeetingId: onlineMeetingId
                };

                return httpCoreApi.put(staffInfoBaseUrl, request, {
                    ...httpCoreApiSettings
                });
            }

            repository.clearCache = function()
            {
                repoCache.removeAll();
            }
            
            return repository;
        }
]);