import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { User } from '@sb-shared/models/user';

@Directive({ selector: 'ng1-profile-pupil-medical' })
export class ProfilePupilMedicalUpgrade extends UpgradeComponent {
  @Input() selectedPerson: User;
  @Input() isOrganisationAdmin: boolean;
  @Input() misManaged: boolean;
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1ProfilePupilMedical', elementRef, injector);
  }
}
