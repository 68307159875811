import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sb-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {

  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() trueValue: any;
  @Input() controlName: string;
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() radioClass = 'success';

  check() {
    if (this.isDisabled)
    {
        return;
    }

    this.model = this.trueValue;

    // if (this.modelChange && typeof(this.modelChange) === 'function') {
      this.modelChange.emit(this.model);
    // }
  }

  getCheckedStatus() {
      if (typeof (this.isChecked) !== 'undefined')
      {
          return this.isChecked;
      }

      return this.model === this.trueValue;
  }

}
