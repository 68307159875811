// start:ng42.barrel
import './message-center-all-messages.component';
import './message-center-client.component';
import './message-center-composer-container.component';
import './message-center-container.component';
import './message-center-counts-widget.component';
import './message-center-dashboard.component';
import './message-list.component';
import './message-details.component';
import './message-recipients.component';
// end:ng42.barrel

