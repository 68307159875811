angular.module('colours.components.colourPicker', [
]).component('colourPicker', {
    templateUrl: '/Scripts/app/colours/components/colour-picker.template.html',
    bindings: {
        isDisabled: '<',
        colours: '=',
        onChange: '&',
    },
    controller: class ColourPickerCtrl {
        $document: any;
        installationSettingsService: any;
        colourService: any;

        docStyle: any;
        isDisabled: boolean;
        colours: any;
        onChange: any;

        static $inject = ['$document', 'installationSettingsService', 'colourService'];

        constructor ($document, installationSettingsService, colourService) {
            this.$document = $document;
            this.installationSettingsService = installationSettingsService;
            this.colourService = colourService;
            this.docStyle = $document[0].documentElement.style;
        }

        updateColours() {
            this.colourService.setBrowserColours(this.colours);
            this.onChange({colours: this.colours});
        };

        setDefaults() {
            this.installationSettingsService.getDefaultColours().then(defaultColours => {
                this.colours = defaultColours;
                this.updateColours();
            });
        };

        $onInit() {
            if (!this.colours) {
                this.setDefaults();
            }
        };

    }
});
