'use strict';

angular.module('reception.components.receptionTeamNotes',
    [
        'pascalprecht.translate',
        'shared.components.sbIcon',

        'teams.components.addTeamNotePopup',
        'teams.components.teamNoteEditor',
        'teams.components.teamNoteList'
    ])
    .component('receptionTeamNotes', {
        bindings: {
            teamId: '<'
        },
        templateUrl: '/Scripts/app/reception/components/reception-team-notes.template.html',
        controller: [
            '$uibModal',
            function ($uibModal) {

                this.updatedNote = false;

                this.addNote = function () {

                    var addDialog = $uibModal.open({
                        component: 'addTeamNotePopup',
                        size: 'lg',
                        resolve: {
                            teamId: function () { return this.teamId; }.bind(this)
                        }
                    });

                    addDialog.result
                        .then(function (newNote) {
                            console.log('receptionTeamNotes/dialogueResponse', newNote);
                            this.updatedNote = newNote;
                        }.bind(this))
                        .catch(function () {
                            console.log('dismissed popup');
                        });

                }.bind(this);
            }]
    });