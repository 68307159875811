"use strict";

angular.module('receptionModule').component("receptionAllGroupsFormTags", {
    templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-formtags.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        selectedGroup: '<'
    },
    controller:  [
    "$scope", "userFormService",
    function ($scope, userFormService) {

        this.$onChanges = (changes) => {
            if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                $scope.selectedGroup = this.selectedGroup;
            }
        };

        this.$onInit = () => { // Not indented to avoid merge issues

        $scope.selectedFormId = "";

        userFormService.getFormTags().then(function(data) {
                $scope.forms = data;
            },
            function(error) {
                return error;
            });

        };
    }
]});
