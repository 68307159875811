angular.module('shared.directives.ngBindHtmlTitleAttr', [])
    .directive('ngBindHtmlTitleAttr', ['$sce','$filter',
        function ($sce, $filter) {
            return {
                restrict: 'A',
                link: function ($scope, element, attrs) {
                    var div: any = document.createElement('div');

                    attrs.$observe('ngBindHtmlTitleAttr', function (value) {
                        div.innerHTML = value;                      
                        element.attr('title', $sce.trustAsHtml(div.textContent));
                    });
                }
            };
    }]);