'use strict';

angular.module('balanceAdmin.recordPayment.components.recordPaymentDetail',
    [
        'balanceAdmin.components.massAdjustmentQuickButtons'
    ])
    .component('recordPaymentDetail',
        {
            bindings: {
                eventId: '<',
                personId: '<',
                recordPaymentSubmitted: '=',
                checkoutFn: '&'
            },
            templateUrl: '/Scripts/app/balanceAdmin/recordPayment/components/record-payment-detail.template.html',
            controllerAs: 'ctrl',
            controller: [
                '$filter',
                'balanceAdminService',
                'simpleAlertService',
                'arrayService',
                function (
                    $filter,
                    balanceAdminService,
                    simpleAlertService,
                    arrayService) {

                    this.charges = null;
                    this.isLoading = true;
                    this.sortType = 'transactionDate';
                    this.sortReverse = false;

                    // search filters for the loaded, in-memory data
                    this.searchFilters = {};

                    this.selectedBalanceType = 1;

                    this.balanceTypes = [
                        {
                            id: 0,
                            name: 'SB_All'
                        },
                        {
                            id: 1,
                            name: 'SB_Unpaid'
                        },
                        {
                            id: 2,
                            name: 'SB_Partial_Paid'
                        },
                        {
                            id: 3,
                            name: 'SB_Paid'
                        }
                    ];

                    this.$onChanges = function (changes) {
                        if ((changes.eventId || changes.personId) && this.eventId == 0 && this.personId == 0) {
                            // clear out the details
                            this.charges = null;
                            return;
                        }

                        if (changes.eventId && this.eventId) {
                            this.refreshCharges(this.eventId, true);
                        }

                        if (changes.personId && this.personId) {
                            this.refreshCharges(this.personId, false);
                        }
                    }.bind(this);

                    this.refreshCharges = function (id, isEvent) {
                        this.isLoading = true;
                        this.charges = [];

                        var refreshFn = isEvent
                            ? balanceAdminService.getChargesForEvent
                            : balanceAdminService.getChargesForAccount;

                        // Get event charges
                        refreshFn(id)
                            .then(function (charges) {
                                this.charges = charges;

                                var allGroups = this.charges.map(function (charge) {
                                    return {
                                        name: charge.groupName,
                                        id: charge.groupId
                                    };
                                });

                                this.groups = arrayService.uniqueBy(allGroups,
                                    function (group) { return group.id; });

                                this.groups.unshift({
                                    name: 'SB_All'
                                });

                                var allRaisedBys = this.charges.map(function (charge) {
                                    return {
                                        name: charge.raisedByName,
                                        id: charge.raisedById
                                    };
                                });

                                this.raisedBys = arrayService.uniqueBy(allRaisedBys,
                                    function (raisedBy) { return raisedBy.id; });

                                this.raisedBys.unshift({
                                    name: 'SB_All'
                                });

                                if (isEvent) {
                                    var allAccountOwners = this.charges.map(function (charge) {
                                        return {
                                            name: charge.accountOwnerName,
                                            id: charge.accountOwnerId
                                        };
                                    });

                                    this.accountOwners = arrayService.uniqueBy(allAccountOwners,
                                        function (accountOwner) { return accountOwner.id; });

                                    this.accountOwners.unshift({
                                        name: 'SB_All'
                                    });
                                }
                                else {
                                    var allEvents = this.charges.map(function (charge) {
                                        return {
                                            name: charge.eventTitle + ' (' + $filter('date')(charge.eventDate, 'yyyy/MM/dd') + ')',
                                            id: charge.eventId
                                        };
                                    });

                                    this.events = arrayService.uniqueBy(allEvents,
                                        function (evnt) { return evnt.id; });

                                    this.events.unshift({
                                        name: 'SB_All'
                                    });
                                }

                                this.isLoading = false;
                            }.bind(this))
                            .catch(function (err) {
                                console.log(err);
                                simpleAlertService.errorAlert({ message: 'Unable to load event charges' });
                            });
                    }.bind(this);

                    this.recordPayment = function () {

                        var selectedCharges = $filter('filter')(this.charges, { checked: true });

                        if (typeof this.checkoutFn == 'function') {
                            this.checkoutFn()(selectedCharges);
                        }
                    }.bind(this);

                    this.checkBalanceType = function (charge) {
                        switch (this.selectedBalanceType) {
                            case 0:
                                // All
                                return true;
                            case 1:
                                // Unpaid
                                return charge.chargeOutstanding > 0;
                            case 2:
                                // Partial Paid
                                return charge.chargeOutstanding > 0 && charge.totalPayments > 0;
                            case 3:
                                // Paid
                                return charge.chargeOutstanding == 0;
                            default:
                                return true;
                        }
                    }.bind(this);

                    this.toggleAll = function () {

                        var filteredCharges = $filter('filter')(this.charges, this.searchFilters, true);
                        filteredCharges = $filter('filter')(filteredCharges, this.checkBalanceType);

                        for (var i = filteredCharges.length; i--;) {
                            if (filteredCharges[i].chargeOutstanding > 0) {
                                filteredCharges[i].checked = !filteredCharges[i].checked;
                            }
                        }
                    }.bind(this);
                }
            ]
        })
    .filter('sumByKey', function () {
        return function (data, key) {
            if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
                return 0;
            }

            var sum = 0;
            angular.forEach(data, function (obj, objKey) {
                sum += parseFloat(obj[key]);
            });

            return sum;
        };
    });