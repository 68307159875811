angular.module('diary.services.diaryService', [])
    .factory('diaryService', [
        '$http', '$q', '$cacheFactory', 'httpCoreApi',
        function ($http, $q, $cacheFactory, httpCoreApi) {

            var urlBase = '/webapi/WebDiary/';
            var calendarEventBase = '/webapi/WebCalendarEventView/';
            var service = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('diaryServiceCache');

            var httpCoreApiUserSettings = {
                controllerType: httpCoreApi.controllerTypes.User,
                cache: repoCache
            };

            var enums = window.EveryBuddy.Enums;

            service.eventViewEnum = {
                info: 0,
                register: 1
            };

            service.whichViewShouldEventDefaultTo = function(event)
            {
                return event.eventDate.isSameOrBefore(moment(), 'day')
                           ? service.eventViewEnum.register
                           : service.eventViewEnum.info;
            };

            service.insertMultiDayEvents = function (events) {
                // convert the dates the moment dates
                // also look for multi day events and add the fake extra rows
                var multidayEvents = [];

                for (var i = events.length; i--;) {
                    var event = events[i];

                    event.eventDate = moment(event.eventDate);
                    event.eventEndDate = moment(event.eventEndDate);

                    if (!event.eventDate.isSame(event.eventEndDate, 'day')) {
                        multidayEvents.push(event);
                    }
                }

                if (multidayEvents.length > 0) {
                    for (var i = multidayEvents.length; i--;) {
                        var endDate = multidayEvents[i].eventEndDate.clone().endOf('day');
                        for (var date = multidayEvents[i].eventDate.clone().startOf('day').add(12, 'hours').add(1, 'days');
                            date.isSameOrBefore(endDate);
                            date.add(1, 'days')) {
                            var newEvent = JSON.parse(JSON.stringify(multidayEvents[i])); //angular.copy(multidayEvents[i]);

                            newEvent.eventDate = date.clone(); //angular.copy(date);
                            newEvent.isMultiDay = true;

                            if (date.isSame(endDate, 'day')) {
                                newEvent.isMultiDayLast = true;
                            }
                            else {
                                // this isn't the final date, so set it as all day
                                newEvent.isAllDay = true;
                            }

                            events.push(newEvent);
                        }

                        multidayEvents[i].isMultiDayFirst = true;
                    }
                }

                for (var i = 0; i < events.length; i++) {
                    events[i].index = i;
                }

                return events;
            };

            service.getStatusText = function (id) {
                switch (id) {
                    case enums.CalendarEventViewAttendeeStatus.InviteesOnly:
                        return 'SB_Invitees_Only';
                    case enums.CalendarEventViewAttendeeStatus.NotSelected:
                        return 'SB_Not_Selected';
                    case enums.CalendarEventViewAttendeeStatus.Declined:
                        return 'SB_Declined';
                    case enums.CalendarEventViewAttendeeStatus.None:
                        return '';
                    case enums.CalendarEventViewAttendeeStatus.Selected:
                        return 'SB_Selected';
                    case enums.CalendarEventViewAttendeeStatus.Notified:
                        return 'SB_Notified';
                    case enums.CalendarEventViewAttendeeStatus.Attending:
                        return 'SB_Attending';
                    case enums.CalendarEventViewAttendeeStatus.HelpRequested:
                        return 'SB_Your_Help_Has_Been_Requested';
                    case enums.CalendarEventViewAttendeeStatus.AttendanceRequested:
                        return 'SB_Attendance_Requested';
                    case enums.CalendarEventViewAttendeeStatus.AvailabilityRequested:
                        return 'SB_Availability_Requested';
                    case enums.CalendarEventViewAttendeeStatus.AvailableAwaitingSelection:
                        return 'SB_Available_Awaiting_Selection';
                    case enums.CalendarEventViewAttendeeStatus.PendingSelection:
                        return 'SB_Pending_Selection';
                    case enums.CalendarEventViewAttendeeStatus.Invited:
                        return 'SB_Invited';
                }

            };

            service.clear = function()
            {
                repoCache.removeAll();
            };

            service.getInvitesForPerson =
                function (personId)
                {
                    return $http.get(urlBase + 'GetInvites',
                            {
                                cache: repoCache,
                                params: {
                                    personId: personId
                                }
                            })
                        .then(function(response) { return response.data; })
                        .catch(function(e) { return { isError: true, error: e }; });
                };


            service.getEventsForPerson =
                function (personId, fromDate, toDate, includeInvitations, isParentsEveningIncluded, isTransportIncluded, maxEventCount)
                {
                    return $http.get(urlBase + 'GetAll',
                            {
                                cache: repoCache,
                                params: {
                                    id: personId,
                                    fromDate: fromDate,
                                    toDate: toDate,
                                    hasDeclined: true,
                                    hasInvitation: includeInvitations,
                                    maxEventCount: maxEventCount || -1,
                                    isParentsEveningIncluded: isParentsEveningIncluded,
                                    isTransportIncluded: isTransportIncluded
                                }
                            })
                        .then(function(response) { return response.data; })
                        .catch(function(e) { return { isError: true, error: e }; });
                };

            service.getEventsForTeam = function(teamId, fromDate, toDate)
            {
                var deferred = $q.defer();

                $http.get(urlBase + 'GetAllForTeam',
                        {
                            cache: repoCache,
                            params: { teamId: teamId, subFromDate: fromDate, subToDate: toDate }
                        })
                    .then(function(response) { deferred.resolve(response.data); })
                    .catch(function(e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.getiCalLink = function (personId) {
                return $http.post(urlBase + 'GetICalLink', personId)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            service.getEventDetailForPerson = function (eventId, personId) {
                return $http.get(calendarEventBase + 'GetEventDetail',
                    {
                        cache: repoCache,
                        params: { personId: personId || 0, eventId: eventId }
                    })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            service.respondToInvite = function (inviteResponseDto) {
                service.clear();
                return $http.post(calendarEventBase + 'RespondToInvite', inviteResponseDto)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            service.getEventUpdates = function (eventStatusRequests)
            {
                httpCoreApiUserSettings.showSuccessToast = false;
                return httpCoreApi.post('TransportEventStatus', eventStatusRequests, httpCoreApiUserSettings);
            }

            service.getTransportStatusUpdatesRefreshSettings = function ()
            {
                return httpCoreApi.get('Module/TransportStatusUpdatesRefreshSettings', httpCoreApiUserSettings);
            }

            return service;
        }
    ]);
