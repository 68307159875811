'use strict';

angular.module('transport.configuration.routeStopTimes',
    [
        'shared.components.sbOnOffSwitch',
        'shared.services.moduleService',
        'shared.services.simpleAlertService',
    ])
    .component('routeStopTimes',
        {
            bindings: {},
            templateUrl: '/Scripts/app/transport/configuration/routeStopTimes/transport-route-stop-times.template.html',
            controller: [
                '$timeout',
                'simpleAlertService',
                'moduleService',
                function routeStopTimesController($timeout, simpleAlertService, moduleService)
                {
                    this.isSubmitted = false;

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.allowMinutesOnRouteStopDto ={};

                    this.$onInit = function ()
                    {
                        moduleService.getAllowMinutesOnRouteStop()
                            .then(function (allowMinutesOnRouteStopDto)
                            {
                                this.allowMinutesOnRouteStopDto = allowMinutesOnRouteStopDto;
                            }.bind(this));
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.saveAllowMinutesOnRouteStop = function ()
                    {
                        moduleService.saveAllowMinutesOnRouteStop(this.allowMinutesOnRouteStopDto)
                            .then(function (responseData)
                            {
                                if (!responseData) {
                                    alertAndResetOkButton();
                                    return;
                                };

                                this.allowMinutesOnRouteStopDto = responseData;

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Saved',
                                    message: 'SB_Transport_Allow_Minutes_On_Route_Stops',
                                    messageTranslationParameters: {},
                                    staticBackdrop: true
                                });

                                resetSubmitButtons();

                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });
                    }.bind(this);
                }
            ]
        });