// start:ng42.barrel
import './diary.component';
import './linked-groups.component';
import './linked-profiles.component';
import './permission-to-walk.component';
import './profile-medical.component';
import './profile-online-location.component';
import './profile-user-form.component';
import './single-sign-on.component';
import './user-form.component';
import './welcome-message.component';
import './welcome.component';
// end:ng42.barrel

