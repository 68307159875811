'use strict';

angular.module('transportModule').controller('transportConfigurationController', [
    '$scope',
        function ($scope)
        {
            this.$onInit = function ()
            {
                $scope.subMenuItems = {
                    items: [{
                        title: 'SB_Transport_Methods',
                        route: '/transportMethods',
                        activeMenu: true
                    },
                    {
                        title: 'SB_Transport_Operating_Days',
                        route: '/transportOperatingDays'
                    },
                    {
                        title: 'SB_Transport_Directions',
                        route: '/transportDirections'
                    },
                    {
                        title: 'SB_Transport_Time_Slots',
                        route: '/transportTimeslots'
                        },
                    {
                        title: 'SB_Transport_Stops',
                        route: '/transportStops'
                    },
                    {
                        title: 'SB_Transport_Routes',
                        route: '/transportRoutes'
                        },
                    {
                        title: 'SB_Transport_Timetable_Calendars',
                        route: '/transportTimetableCalendars'
                    },
                    {
                        title: 'SB_Transport_Timetables',
                        route: '/transportTimetables'
                    },
                    {
                        title: 'SB_Transport_Change_Requests',
                        route: '/transportFeatures'
                    }
                        /*,
                    {
                        title: 'SB_Transport_Route_Stop_Times',
                        route: '/transportRouteStopTimes'
                    }*/
                    ]
                };

            }.bind(this);
        }
    ]);