import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterPaths } from '@sb-shared/constants/router-paths.constants';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { profileRoutes } from './profile/profile.routing.module';
import { AboutComponent } from '@sb-core/components/about/about.component';

// Storybook doesn't like this typing
// const routes: Routes = [
const routes: Routes = [
  { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
  {
    path: 'Dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: RouterPaths.Diary,
    loadChildren: () => import('./diary/diary.module').then(m => m.DiaryModule)
  },
  {
    path: 'DiaryDetail',
    loadChildren: () => import('./diary/diary-detail.module').then(m => m.DiaryDetailModule),
    outlet: 'diary'
  },
  {
    path: 'Messaging',
    loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule)
  },
  {
    path: 'Balance',
    loadChildren: () => import('./balance/balance.module').then(m => m.BalanceModule)
  },
  {
    path: 'BalanceAdmin',
    loadChildren: () => import('./balance-admin/balance-admin.module').then(m => m.BalanceAdminModule)
  },
  {
    path: 'Reception',
    loadChildren: () => import('./reception/reception.module').then(m => m.ReceptionModule)
  },
  {
    path: 'CcaSignup',
    loadChildren: () => import('./cca-sign-up/cca-sign-up.module').then(m => m.CcaSignupModule)
  },
  {
    path: 'CcaAvailable',
    loadChildren: () => import('./cca-available/cca-available.module').then(m => m.CcaAvailableModule)
  },
  {
    path: 'Transport',
    loadChildren: () => import('./transport/transport.module').then(m => m.TransportModule)
  },
  {
    path: 'TuitionFees',
    loadChildren: () => import('./tuition-fees/tuition-fees.module').then(m => m.TuitionFeesModule)
  },
  {
    path: RouterPaths.SportsPortal,
    loadChildren: () => import('./sports-portal/sports-portal.module').then(m => m.SportsPortalModule)
  },
  {
    path: RouterPaths.TripsPortal,
    loadChildren: () => import('./trips-portal/trips-portal.module').then(m => m.TripsPortalModule)
  },
  {
    path: 'Settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'SysAdmin',
    loadChildren: () => import('./sysadmin/sysadmin.module').then(m => m.SysadminModule)
  },
  {
    path: 'Profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'Portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'Account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'Invites',
    loadChildren: () => import('./invites/invites.module').then(m => m.InvitesModule)
  },
  {
    path: 'Payments',
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'Info',
    component: AboutComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  // Profile popup routes
  ...profileRoutes.map(route => {
    return {
      ...route,
      outlet: 'profile'
    };
  }),
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
