angular.module('messaging.components.messageCenterComposerContainer',
        [
            'messaging.directives.messageComposer'
        ])
    .component('messageCenterComposerContainer',
        {
            bindings: {
                restrict: '<',
                showTags: '<'
            },
            templateUrl: '/Scripts/app/messaging/components/message-center-composer-container.template.html',
            controller: [function () {

            }]
        });