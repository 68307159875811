'use strict';

angular.module('teams.components.teamNoteList',
    [
        'messaging.services.messagingService',

        'pascalprecht.translate',

        'person.services.staffRepository',

        'shared.components.sbIcon',
        'shared.services.simpleAlertService',

        'teams.services.teamNotesService'
    ])
    .component('teamNoteList', {
        bindings: {
            teamId: '<',
            onNoteUpdated: '<'
        },
        templateUrl: '/Scripts/app/teams/components/team-note-list.template.html',
        controller: ['teamNotesService', 'staffRepository', 'simpleAlertService', 'messagingService',
            function (teamNotesService, staffRepository, simpleAlertService, messagingService) {

                this.loading = 0;

                this.sortType = 'createdDate';
                this.sortReverse = true;

                this.notes = [];
                this.staffNames = {};
                this.tags = {};
                this.tagList = [];

                this.filter = {
                    allText: ''
                };

                var filterTagList = [];

                this.tagFilter = function () {
                    return function (item) {

                        if (filterTagList.length === 0)
                            return true;

                        for (i = filterTagList.length; i--;) {
                            if (item.tagIds.indexOf(filterTagList[i]) > -1)
                                return true;
                        }

                        return false;
                    }.bind(this);
                }.bind(this);

                this.updateTagFilterList = function () {

                    filterTagList = [];

                    var i = 0;

                    for (i = this.tagList.length; i--;) {
                        if (this.tagList[i].checked) {
                            filterTagList.push(+this.tagList[i].id);
                        }
                    }

                }.bind(this);

                var setNoteSearchText = function (note) {
                    note.allText = note.title + ' ' + note.body;
                };

                var setNoteAuthorName = function (note) {
                    var staff = this.staffNames[+note.createdById];
                    if (staff) {
                        note.createByName = staff.lastName + ', ' + staff.firstName;
                    }
                    else {
                        note.createByName = 'unknown';
                    }
                }.bind(this);

                this.$onChanges = function (changes) {

                    console.log('teamNoteList/onChanges', changes);

                    if (changes.teamId && changes.teamId.currentValue) {
                        this.loadData();
                    }

                    if (changes.onNoteUpdated && changes.onNoteUpdated.currentValue) {
                        console.log('teamNoteList/onChanges/onNoteUpdated');

                        var newNote = changes.onNoteUpdated.currentValue;

                        setNoteSearchText(newNote);

                        setNoteAuthorName(newNote);

                        // something new!  add it to the list
                        this.notes.push(changes.onNoteUpdated.currentValue);
                    }
                }.bind(this);

                this.isLoading = function () {
                    return this.loading > 0;
                }.bind(this);

                this.loadData = function () {

                    console.log('teamNoteList/loadData');

                    this.loading++;

                    teamNotesService.getNotesForTeam(this.teamId)
                        .then(function (data) {

                            var staffIds = [];

                            for (var i = data.length; i--;) {
                                setNoteSearchText(data[i]);
                                staffIds.push(data[i].createdById);
                            }

                            this.notes = data;

                            this.loading+= 2;
                            staffRepository.getStaffNames(staffIds)
                                .then(function (people) {

                                    var i = 0;

                                    for (i = people.length; i--;) {
                                        this.staffNames[+people[i].id] = {
                                            firstName: people[i].fn,
                                            lastName: people[i].ln
                                        };
                                    }

                                    for (i = this.notes.length; i--;) {
                                        setNoteAuthorName(this.notes[i]);
                                    }

                                    this.loading--;
                                }.bind(this))
                                .catch(function (error) {
                                    this.loading--;
                                    simpleAlertService.errorAlert({
                                        message: 'SB_Error_occurred_try_again'
                                    });
                                    console.error(error);
                                }.bind(this));

                            messagingService.getAllTagsForReference()
                                .then(function (tags) {
                                    this.tags = tags;

                                    for (var tagId in tags) {
                                        if (tags.hasOwnProperty(tagId)) {
                                            this.tagList.push({
                                                id: tagId,
                                                name: tags[tagId],
                                                checked: false
                                            });
                                        }
                                    }

                                    this.loading--;
                                }.bind(this))
                                .catch(function (error) {
                                    this.loading--;
                                    simpleAlertService.errorAlert({
                                        message: 'SB_Error_occurred_try_again'
                                    });
                                    console.error(error);
                                }.bind(this));

                            this.loading--;
                        }.bind(this))
                        .catch(function (err) {
                            this.loading--;
                            simpleAlertService.errorAlert({
                                message: 'SB_Error_occurred_try_again'
                            });
                            console.error(err);
                        }.bind(this));

                }.bind(this);
            }]
    });