angular.module('person.services.personPickerService', [])
    .factory('personPickerService', [
        '$http',
        '$q',
        '$cacheFactory',
        '$uibModal',
        function ($http, $q, $cacheFactory, $uibModal)
        {
            var urlBase = '/webapi/WebPersonPicker/';
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('personPickerServiceCache');

            repository.openMultiPersonPicker = function (options) {
                var opts = {};

                angular.extend(
                    opts,
                    {
                        selectedPersonIds: [],
                        defaultClubId: 0,
                        defaultTeamId: 0,
                        okButtonText: 'SB_Save',
                        includeStaff: false,
                        includePupils: false,
                        hideGroupFilter: false,
                        hideYearFilter: false,
                        hideGenderFilter: false,
                        hideRemove: false,
                        filterOutExternalStaff: false,
                        overridePersonLookupService: false,
                        selectedGroupId: 0,
                        shownFromRegister: false,
                        shownFromStaffRoles: false,
                        isMassStudentAssignmentCopyFromMode: false,
                        selectedTimeSlotId: 0,
                        selectedTimetableId: 0,
                        selectedTimetableRouteId: 0,
                        timetableIdToCopyFrom: 0,
                        timetableRouteIdToCopyFrom: 0,
                        titleDetail: ''
                    },
                    options);

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static', // prevent modal from closing on backdrop click
                    templateUrl: '/Scripts/app/person/controllers/multi-person-picker-popup.template.html',
                    controller: 'multiPersonPickerPopupController',
                    size: !opts.isMassStudentAssignmentCopyFromMode ? 'md' : 'lg',
                    resolve: {
                        selectedPersonIds: function () { return opts.selectedPersonIds; },
                        defaultClubId: function () { return opts.defaultClubId; },
                        defaultTeamId: function () { return opts.defaultTeamId; },
                        okButtonText: function () { return opts.okButtonText; },
                        includeStaff: function () { return opts.includeStaff; },
                        includePupils: function () { return opts.includePupils; },
                        hideGroupFilter: function () { return opts.hideGroupFilter; },
                        hideYearFilter: function () { return opts.hideYearFilter; },
                        hideGenderFilter: function () { return opts.hideGenderFilter; },
                        hideRemove: function () { return opts.hideRemove; },
                        filterOutExternalStaff: function () { return opts.filterOutExternalStaff; },
                        overridePersonLookupService: function () { return opts.overridePersonLookupService; },
                        selectedGroupId: function () { return opts.selectedGroupId; },
                        shownFromRegister: function () { return opts.shownFromRegister; },
                        shownFromStaffRoles: function () { return opts.shownFromStaffRoles; },
                        isMassStudentAssignmentCopyFromMode: function () { return opts.isMassStudentAssignmentCopyFromMode; },
                        selectedTimeSlotId: function () { return opts.selectedTimeSlotId; },
                        selectedTimetableId: function () { return opts.selectedTimetableId; },
                        selectedTimetableRouteId: function () { return opts.selectedTimetableRouteId; },
                        timetableIdToCopyFrom: function () { return opts.timetableIdToCopyFrom; },
                        timetableRouteIdToCopyFrom: function () { return opts.timetableRouteIdToCopyFrom; },
                        titleDetail: function () { return opts.titleDetail; }
                    }
                });

                return modalInstance;
            };

            repository.clearCache = function () {

                repoCache.removeAll();

            };

            repository.getSchoolYearGroups = function() {
                return $http.get(urlBase + 'GetSchoolYearGroups', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getAutoCompletePupilsInTeam = function(teamId, pupilName) {
                return $http.get(urlBase + 'GetAutoCompletePupilsInTeam', { params: { pupilName: pupilName, teamId: teamId }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getAutoCompletePupilsInClub = function (clubId, pupilName) {
                return $http.get(urlBase + 'GetAutoCompletePupilsInClub', { params: { pupilName: pupilName, clubId: clubId }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getSchoolClasses = function() {
                return $http.get(urlBase + 'GetSchoolClasses', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getClubs = function (restrict) {
                return $http.get(urlBase + 'GetClubs', { cache: repoCache, params: { restrict: restrict } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getTeams = function (restrict) {
                return $http.get(urlBase + 'GetTeams', { cache: repoCache, params: { restrict: restrict } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getEvents = function (restrict) {
                return $http.get(urlBase + 'GetEvents', { cache: repoCache, params: { restrict: restrict } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getTeamMembers = function(restrict)
            {
                return $http.get(urlBase + 'GetTeamMembers', { cache: repoCache, params: { restrict: restrict } })
                    .then(function(response)
                    {
                        return response.data;
                    });
            };

            repository.getWholeSchoolClubId = function () {

                return $http.get(urlBase + 'GetWholeSchoolClubId')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.getPeople = function (nameSearchCriteria, howMany) {

                // do we have enough criteria to warrant doing a search
                if (nameSearchCriteria && nameSearchCriteria.replace(/\s/g, '').length > 0) {

                    return $http.post(urlBase + 'GetPeople', { searchCriteria: nameSearchCriteria, pageSize: howMany })
                        .then(function(response) {
                            return response.data;
                        });

                }
            };

            repository.getEventPeople = function (eventId) {
                return $http.get(urlBase + 'GetEventPersons', { cache: repoCache, params: { eventId: eventId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getEventGroupedPeople = function (eventId) {
                return $http.get(urlBase + 'GetEventGroupedPersons', { cache: repoCache, params: { eventId: eventId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getWholeSchoolGroups = function () {
                return $http.get(urlBase + 'getWholeSchoolGroups', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getSubjectClasses = function () {
                return $http.get(urlBase + 'getSubjectClasses', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getPeopleFiltered = function (searchOptions) {

                return $http.post(urlBase + 'GetPeopleWithFilters',
                        {
                            searchCriteria: searchOptions.nameSearchCriteria,
                            pageSize: searchOptions.pageSize,
                            currentPage: searchOptions.currentPage,
                            includePupils: searchOptions.includePupils,
                            includeParents: searchOptions.includeParents,
                            includeStaff: searchOptions.includeStaff,
                            includeDeleted: searchOptions.includeDeleted,
                            schoolYearGroupId: searchOptions.schoolYearGroupId || 0 ,
                            schoolClassId: searchOptions.schoolClassId || 0 ,
                            memberOfTeamId: searchOptions.memberOfTeamId,
                            memberOfClubId: searchOptions.memberOfClubId,
                            membershipState: searchOptions.membershipState || null,
                            genderTypeId: typeof searchOptions.genderTypeId === 'undefined' ? -1 : searchOptions.genderTypeId,
                            hasAccountCreated: typeof searchOptions.hasAccountCreated === 'undefined' ? -1 : searchOptions.hasAccountCreated,
                            hasLoggedIn: typeof searchOptions.hasLoggedIn === 'undefined' ? -1 : searchOptions.hasLoggedIn,
                            onlyShowExternalStaff: searchOptions.onlyShowExternalStaff || false,
                            filterOutExternalStaff: searchOptions.filterOutExternalStaff || false,
                            onlyShowMultiAccounts: searchOptions.onlyShowMultiAccounts || false
                        })
                    .then(function(response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getPredefinedQueryResults = function(searchOptions) {
                return $http.post(urlBase + 'GetStoredQueryResult',
                        {
                            queryId: searchOptions.queryId,
                            pageSize: searchOptions.pageSize,
                            currentPage: searchOptions.currentPage,
                            schoolYearGroupId: searchOptions.schoolYearGroupId,
                            schoolClassId: searchOptions.schoolClassId
                        })
                    .then(function(response) {
                        return response.data;
                    })
                    .catch(function(e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getRelatedPeople = function(personId, memberType) {
                return $http.get(urlBase + 'GetRelatedPeople',
                        {
                            cache: repoCache, params: { personId: personId, type: memberType }
                        })
                    .then(function(response) {
                        return response.data;
                    });
            };

            return repository;
        }
    ]);
