'use strict';

angular.module('shared.components.sbColumnHeader', [
    'ui.bootstrap',
    'shared.components.sbIcon',
    'pascalprecht.translate'
])
    .component('sbColumnHeader',
        {
            bindings: {
                label: '@',
                labelIconName: '@',
                fieldName: '@',
                sortType: '=',
                sortReverse: '=',
                toolTip: '@',
                onClick: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-column-header.template.html',
            controller: function () {

                var dirty = false;

                this.fireClickHandler = function () {

                    this.sortType = this.fieldName;
                    this.sortReverse = !this.sortReverse;

                    dirty = true;


                }.bind(this);

                this.$doCheck = function () {

                    if (dirty) {

                        if (this.onClick && this.onClick()) {
                            this.onClick()();
                        }

                        dirty = false;
                    }
                }.bind(this);
            }
        });