'use strict';

angular.module('transport.changeRequests.transportChangeRequestAdd',
    [
        'transport.services.changeRequestService',
        'transport.services.transportTimetablesService',
        'shared.components.sbIcon',
        'shared.components.sbTextarea',
        'shared.components.sbModalHeader',
        'shared.services.iconService',
        'shared.services.simpleAlertService',
    ])
    .component('transportChangeRequestAdd',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/changeRequests/transport-change-request-add.template.html',
            controller: [
                '$timeout',
                '$filter',
                'changeRequestService',
                'simpleAlertService',
                'transportTimetablesService',
                'iconService',
                function ($timeout, $filter, changeRequestService, simpleAlertService, transportTimetablesService, iconService) {
                    this.changeRequest = {};
                    this.isSubmitted = false;
                    this.currentPage = 'ChangeRequest';
                    this.timeSlotTimeTableByOperatingDay = [];

                    //object to hold the users selections for method, route and stop.
                    this.timeSlotOptions = {};

                    var sbTransportIcons = iconService.getTransportIcons();

                    var resetSubmitButtons = function () {
                        $timeout(function () {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.closeModal = function (updateParentView) {
                        this.modalInstance.close(updateParentView);
                    }.bind(this);

                    this.$onChanges = function (changes) {

                        if (changes.resolve && changes.resolve.currentValue) {

                            this.pupilChangeRequestOptions = changes.resolve.currentValue.pupilChangeRequestOptions;

                            if (this.pupilChangeRequestOptions && this.pupilChangeRequestOptions.timeSlots && this.pupilChangeRequestOptions.timeSlots.length > 0)
                            {
                                this.pupilChangeRequestOptions.timeSlots.forEach(function (timeSlot) {
                                    var isCurrentTimeslot = timeSlot.transportChangeRequestOptions.some(function (option) {
                                        return option.isCalendarEvent;
                                    });
                                    if (isCurrentTimeslot) {
                                        timeSlot.isCalendarEvent = true;
                                    }
                                });

                                return;
                            }
                        }

                        this.closeModal();
                    }.bind(this);

                    this.getTimeSlotOperatingDayOptions = function (timeSlotId, organisationOperatingDayId)
                    {
                        //Only return options for other
                        if (!this.changeRequest.selectedOption.isOtherOption)
                        {
                            return;
                        }

                        transportTimetablesService.getTimetableForTimeSlotCalendarAndEventDateGroupByDayAsync(timeSlotId,
                                                                                                              this.pupilChangeRequestOptions.calendarEventStartDateTime,
                                                                                                              organisationOperatingDayId)
                            .then(function (responseData)
                            {
                                this.timeSlotTimeTableByOperatingDay = responseData;

                                if (this.timeSlotTimeTableByOperatingDay.length !== 1)
                                {
                                    return;
                                }

                                this.timeSlotTimeTableByOperatingDay = this.timeSlotTimeTableByOperatingDay[0];

                                //set the model values for the drop down that the User request change 'To'
                                var selectedMethod = $filter('filter')(this.timeSlotTimeTableByOperatingDay.methods, { id: this.changeRequest.selectedOption.methodId }, true);
                                //set the options selected on the first view

                                if (selectedMethod.length === 1)
                                {
                                    this.timeSlotOptions.method = selectedMethod[0];

                                    if (this.timeSlotOptions.method.supportRoutes)
                                    {

                                        //set route and route stops
                                        var route = $filter('filter')(this.timeSlotOptions.method.routes,
                                            {
                                                timetableRouteId: this.changeRequest.selectedOption.timetableRoute.id
                                            }, true);

                                        //set route stop
                                        if (route.length === 1)
                                        {
                                            this.timeSlotOptions.route = route[0];

                                            if (route[0].routeStops.length > 0)
                                            {
                                                var routeStop = $filter('filter')(route[0].routeStops, {
                                                    routeStopId: this.changeRequest.selectedOption.timetableRoute.timetableRouteStop.id
                                                }, true);

                                                if (routeStop.length === 1)
                                                {
                                                    this.timeSlotOptions.routeStop = routeStop[0];
                                                }
                                            }
                                        }
                                    }
                                }
                            }.bind(this))
                            .catch(function (responseData)
                            {

                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                            });
                    }.bind(this)

                    this.approve = function ()
                    {
                        var addAndApproveRequest =
                        {
                            CalendarEventTransportAttendeeId: this.pupilChangeRequestOptions.calendarEventTransportAttendeeId,
                            adminResponseReason: this.changeRequest.adminResponseReason,
                            timeSlotId: this.changeRequest.timeSlotId,
                            selectedOption: this.changeRequest.selectedOption,
                            fromRouteStopId: null
                        };

                        //values must be set from drop downs
                        if (addAndApproveRequest.selectedOption.isOtherOption === true)
                        {
                            this.updateSelectedOptionFromDropDowns(addAndApproveRequest);
                        };

                        // Set the From route stop id if available
                        changeRequestService.setFromRouteStopId(addAndApproveRequest, this.pupilChangeRequestOptions);

                        changeRequestService.addAndApproveChangeRequest(addAndApproveRequest).then(function (responseData)
                        {
                            if (responseData && responseData.referenceNumber)
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Transport_Change_Request_Approve',
                                    message: 'SB_Transport_Change_Request_Approve_Message',
                                    messageTranslationParameters: { referenceNumber: responseData.referenceNumber },
                                    staticBackdrop: true
                                });

                                this.closeModal(true);
                            }
                            else
                            {
                                simpleAlertService.errorAlert();
                                resetSubmitButtons();
                            }
                        }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                simpleAlertService.errorAlert(message);

                                resetSubmitButtons();
                            });

                    }.bind(this);

                    this.filterIcon = function (method) {
                        var icons = $filter('filter')(sbTransportIcons, { 'iconName': method.sbIconName }, true);

                        if (icons.length > 0) {
                            return {
                                'iconName': icons[0].iconName,
                                'displayName': $filter('translate')(icons[0].displayName),
                                'unicode': icons[0].unicode
                            };
                        };

                        return {
                            'iconName': method.sbIconName,
                            'displayName': $filter('translate')(method.name),
                            'unicode': ''
                        };
                    }.bind(this);

                    this.displayLabelforRoute = function (route) {

                        var routeLabel = route.name;

                        if (route.timetableRouteName) {
                            routeLabel += '- ' + route.timetableRouteName
                        }
                        if (route.code) {
                            routeLabel += ' (' + route.code + ')'
                        }

                        return routeLabel;
                    };

                    this.displayLabelforStop = function (stop) {

                        var stopLabel = stop.name;

                        if (stop.zip) {
                            stopLabel += ' (' + stop.zip + ')'
                        }

                        return stopLabel;
                    };

                    this.resetForm = function ()
                    {
                        if (this.changeRequest)
                        {
                            this.changeRequest = {};

                            this.timeSlotOptions.route = null;
                        };
                    }.bind(this);

                    this.selectOption = function (transportChangeRequestOption, timeSlotId ) {
                        if (!transportChangeRequestOption.isCalendarEvent) {
                            this.changeRequest.selectedOption = transportChangeRequestOption;
                            this.changeRequest.timeSlotId = timeSlotId.id
                        }
                    }.bind(this);

                    this.isSelectedOption = function (transportChangeRequestOption, timeSlot) {
                        return (timeSlot.id === this.changeRequest.timeSlotId) && (angular.equals(this.changeRequest.selectedOption, transportChangeRequestOption));
                    }.bind(this);

                    this.next = function ()
                    {
                        this.getTimeSlotOperatingDayOptions(this.changeRequest.timeSlotId, this.pupilChangeRequestOptions.organisationOperatingDayId);
                        this.currentPage = 'ReviewAndConfirm';
                    }.bind(this);

                    this.backToChangeRequest = function () {
                        this.resetForm();
                        this.currentPage = 'ChangeRequest';
                    }.bind(this);

                    //update the XHR request option with changes from the drop downs, method, route and route stop
                    this.updateSelectedOptionFromDropDowns = function (addApproveChangeRequest)
                    {
                        if (!this.timeSlotTimeTableByOperatingDay || !this.timeSlotOptions.method)
                        {
                            return
                        };

                        var selectedMethods = $filter('filter')(this.timeSlotTimeTableByOperatingDay.methods, { id: this.timeSlotOptions.method.id }, true);

                        if (selectedMethods.length === 1)
                        {
                            var selectedMethod = selectedMethods[0];

                            addApproveChangeRequest.selectedOption = {
                                methodId: selectedMethod.id,
                                isCalendarEvent: false,
                                name: selectedMethod.name,
                                sbIconName: selectedMethod.sbIconName,
                                supportRoutes: selectedMethod.supportRoutes,
                                timetableId: selectedMethod.timetableId,
                                isOtherOption: false
                            };

                            if (selectedMethod.supportRoutes !== true)
                            {
                                return;
                            }

                            addApproveChangeRequest.selectedOption.timetableRoute =
                            {
                                id: this.timeSlotOptions.route.timetableRouteId,
                                name: this.timeSlotOptions.route.name,
                                timetableRouteStop: {
                                    id: this.timeSlotOptions.routeStop.routeStopId,
                                    name: this.timeSlotOptions.routeStop.name
                                }
                            }
                        }

                    }.bind(this);
                }
            ]
        });
