'use strict';

angular.module('messaging.controllers.personPickerPopup', [
    'shared.components.sbModalHeader'])
    .controller('personPickerPopupController', [
        '$scope', '$uibModalInstance', 'selected', 'restrict', 'settings',
        function ($scope, $uibModalInstance, selected, restrict, settings) {

            $scope.recipients = selected;
            $scope.restrict = restrict;
            if (settings) {
                $scope.disablePersonPicking = settings.disablePersonPicking || false;
                $scope.useSimpleAddButtonForClubs = settings.useSimpleAddButtonForClubs || false;
                $scope.useSimpleAddButtonForTeams = settings.useSimpleAddButtonForTeams || false;
                $scope.useSimpleAddButtonForEvents = settings.useSimpleAddButtonForEvents || false;
                $scope.showEvents = settings.showEvents || false;
                $scope.disableTeamPicking = settings.disableTeamPicking || false;
                $scope.disableClubPicking = settings.disableClubPicking || false;
                $scope.showEventPersonGroups = settings.showEventPersonGroups || false;
                $scope.showEventPersons = settings.showEventPersons || false;
                $scope.eventId = settings.eventId;
                $scope.showWholeSchoolGroup = settings.showWholeSchoolGroup || false;
                $scope.showSubjectClasses = settings.showSubjectClasses || false;
            }

            $scope.ok = function() {
                $uibModalInstance.close($scope.recipients);
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.close = function() {
                $uibModalInstance.dismiss('cancel');
            };

        }
]);