angular.module('receptionModule').component("receptionUsersDiary", {
    templateUrl: '/Scripts/app/reception/controllers/reception-users-diary.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        selectedPerson: '<'
    },
    controller:
    [
        "$scope",
        function ($scope) {

            this.$onChanges = (changes) => {
                if (changes.selectedPerson && changes.selectedPerson.currentValue) {
                    $scope.person = this.selectedPerson;
                }
            };
        }
    ]});