export const PersonGenders = [
    {
        id: 1, // DB = 0
        name: 'SB_Female'
    },
    {
        id: 2, // DB = 1
        name: 'SB_Male'
    },
    {
        id: 3, // DB = NULL
        name: 'SB_Gender_Not_Specified'
    }
]
