angular.module('tuitionFeesModule')
    .component('postInvoices', {
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/post-invoices.template.html',
        controller: class PostInvoicesCtrl {

            // Dependencies
            $filter: any;
            billingCycleService: any;
            simpleAlertService: any;

            // Bindings
            billingCycleIdFn: () => number;
            billingCycleRefreshFn: () => () => void;

            // Variables
            currencyDisplaySymbol: string;
            currencyExponent: number;
            invoices: any = [];
            invoiceTotal: number;
            sortType: string = 'studentPersonId';
            sortReverse: boolean = false;
            invoiceDate: any;
            reference: string;
            notes: string
            notesMaxLength: number = 250;
            isAnyIncompleteBatches: boolean = false;

            static $inject = ['$window', '$state', '$scope', '$filter', 'billingCycleService', 'simpleAlertService'];

            constructor($window, $state, $scope, $filter, billingCycleService, simpleAlertService) {
                this.billingCycleIdFn = () => $state.params.billingCycleId;

                // In case they went straight to this nested page, billing cycle id fn is not available.
                // Go back to parent so this fn is set-up correctly.
                if (!this.billingCycleIdFn()) {
                    $state.go('tuitionFees.billingCycle');
                }

                this.billingCycleRefreshFn = () => $state.params.billingCycleRefreshFn;
                this.$filter = $filter;
                this.billingCycleService = billingCycleService;
                this.simpleAlertService = simpleAlertService;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;

                $scope.$watch(() => this.billingCycleIdFn(), () => {
                    this.getPendingInvoices();
                });
            }

            getPendingInvoices() {
                this.billingCycleService.getPendingInvoicesByCycle(this.billingCycleIdFn()).then(res => {
                    if (res.data) {
                        this.invoices = res.data.invoices;
                        this.invoiceTotal = res.data.total;
                        this.isAnyIncompleteBatches = res.data.isAnyIncompleteBatches;
                    }
                });
            }

            confirmPostInvoices = function () {
                var modalInstance = this.simpleAlertService.simpleAlert({
                    title: 'SB_Warning',
                    message: 'SB_Confirm_Post_Invoices',
                    windowSize: 'sm',
                    okButtonText: 'SB_Confirm',
                    cancelButtonText: 'SB_Cancel'
                });

                modalInstance.result
                    .then(function () {
                        this.postInvoices()
                    }.bind(this));
            }

            postInvoices() {
                let m = moment.utc(this.invoiceDate.toISOString()).endOf('day');
                this.billingCycleService.postInvoices(this.billingCycleIdFn(), { dueDate: m, reference: this.reference, notes: this.notes }).then((res) => {
                    if (res.isError) {
                        return;
                    }
                    this.getPendingInvoices();
                    if (typeof this.billingCycleRefreshFn() === 'function') {
                        this.billingCycleRefreshFn()();
                    }
                });
            }
        }
    });
