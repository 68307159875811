'use strict';

angular.module('receptionModule').component('receptionAllGroupsPupils', {
  templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-pupils.template.html?v=', // + window.EveryBuddy.version
  bindings: {
    selectedGroup: '<',
  },
  controller: [
    '$scope',
    '$state',
    '$filter',
    '$translate',
    '$http',
    '$uibModal',
    '$window',
    'receptionService',
    'teamService',
    'personPickerService',
    'blobStoragePhotosService',
    function ($scope, $state, $filter, $translate, $http, $uibModal, $window, receptionService, teamService, personPickerService, blobStoragePhotosService) {
      this.$onChanges = (changes) => {
        if (changes.selectedGroup && changes.selectedGroup.currentValue) {
          $scope.selectedGroup = this.selectedGroup;
        }
      };

      this.$onInit = () => {
        // Not indented to avoid merge issues

        $scope.loadingPupils = true;
        $scope.selectedGroup.pupils = null;
        $scope.selectedGroupIdWatcher = null;

        $scope.pupilsSortType = 'name';
        $scope.pupilssortReverse = false;

        $translate.onReady().then(function () {
          $scope.translatedDelete = $filter('translate')('SB_Delete').toUpperCase();
        });

        $scope.$watch('selectedGroup', function (oldValue, newValue) {
          if ($scope.selectedGroupIdWatcher !== null) {
            $scope.selectedGroupIdWatcher();
          }

          if (newValue.id) {
            $scope.refreshData();
          } else {
            // unfortunatly, we get a selectedGroup- but then we get set another one
            // then that reference doesn't change, but the id associated with it does, not triggering
            // our outer watcher- so we need this inner watcher- we should rewrite this as a component
            // that has its own parameter of teamId, and a private scope
            $scope.selectedGroupIdWatcher = $scope.$watch('selectedGroup.id', function (oldId, newId) {
              if (newId) {
                $scope.selectedGroupIdWatcher();
                $scope.selectedGroupIdWatcher = null;
                $scope.refreshData();
              }
            });
          }
        });
      };

      $scope.refreshData = function () {
        $scope.loadingPupils = true;
        receptionService.getPupilsInGroup($scope.selectedGroup.id).then(function (data) {
          $scope.isOrganisationAdmin = data.isOrganisationAdmin;
          blobStoragePhotosService.addStudentPhotoUrlsToArray(data.pupils).then(function (array) {
            $scope.selectedGroup.pupils = array;
            $scope.loadingPupils = false;
          });
        });
      };

      $scope.addMultiplePupils = function () {
        var selectedIds = [];

        angular.forEach($scope.selectedGroup.pupils, function (item) {
          selectedIds.push(item.id);
        });

        var modalInstance = personPickerService.openMultiPersonPicker({
          selectedPersonIds: selectedIds,
          defaultClubId: 0,
          defaultTeamId: 0,
          okButtonText: 'SB_Save',
          includeStaff: false,
          includePupils: true,
          hideGroupFilter: false,
          hideYearFilter: false,
          hideGenderFilter: false,
          hideRemove: false,
          filterOutExternalStaff: false,
          overridePersonLookupService: null,
          selectedGroupId: $scope.selectedGroup.id,
        });

        modalInstance.result.then(
          function (selectedIds) {
            var pleaseWaitModalInstance = $uibModal.open({
              animation: $scope.animationsEnabled,
              backdrop: 'static', // prevent modal from closing on backdrop click
              templateUrl: '/Scripts/app/shared/controllers/please-wait-popup.template.html',
              controller: 'pleaseWaitPopupController',
              size: 'sm',
              scope: $scope,
              resolve: {
                message: function () {
                  return 'Saving changes, please wait.';
                },
              },
            });

            pleaseWaitModalInstance.rendered
              .then(
                teamService.postPupilChangesToTeam($scope.selectedGroup.id, selectedIds).then(function (data) {
                  pleaseWaitModalInstance.close();
                  if (!data.isError) {
                    // reload team data
                    $scope.refreshData();
                  } else {
                    console.error('problem saving team changes');
                  }
                })
              )
              .catch(function (err) {
                pleaseWaitModalInstance.close();
              });
          },
          function () {
            console.log('dialog cancelled');
          }
        );
      };

      $scope.addPupilGroup = function () {
        $scope.searchType = 1;
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'addPupilToGroupModal.html',
          controller: 'ConfirmModalInstanceCtrl',
          size: 'md',
          scope: $scope,
        });

        modalInstance.result.then(
          function () {
            $scope.loadingPupils = true;
            receptionService.addPersonToGroup($scope.selectedGroup.id, $scope.personItem.id, $window.EveryBuddy.Enums.RoleTypes.Pupil).then(function (data) {
              $scope.isOrganisationAdmin = data.isOrganisationAdmin;
              blobStoragePhotosService.addStudentPhotoUrlsToArray(data.pupils).then(function (array) {
                $scope.selectedGroup.pupils = array;
                $scope.loadingPupils = false;
                $scope.personItem = undefined;
              });
            });
          },
          function () {}
        );
      };

      $scope.deletePupilGroup = function (pupilgroup) {
        $scope.selectedPerson = pupilgroup;
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'confirmGroupPupilDeleteModal.html',
          controller: 'ConfirmModalInstanceCtrl',
          size: 'sm',
          scope: $scope,
        });

        modalInstance.result.then(
          function () {
            $scope.loadingPupils = true;
            receptionService.removeGroupPerson($scope.selectedGroup.id, $scope.selectedPerson.id).then(function (data) {
              $scope.isOrganisationAdmin = data.isOrganisationAdmin;
              $scope.selectedGroup.pupils = data.pupils;
              $scope.loadingPupils = false;
            });
          },
          function () {}
        );
      };

      $scope.getMatches = function (val) {
        return $http
          .get('/Reception/GetPeople', {
            params: {
              lastName: val,
              type: $scope.searchType,
              includeDeleted: false,
            },
          })
          .then(function (response) {
            response.data.forEach((item) => {
              if (item.otherName) {
                item.lastName += ` ${item.otherName}`;
              }
            });

            return response.data;
          });
      };

      $scope.onSelect = function ($item, $model, $label) {
        $scope.$item = $item;
        $scope.personItem = $item;
        $scope.$model = $model;
        $scope.$label = $label;
      };
    },
  ],
});
