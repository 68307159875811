angular.module('settingsModule')
       .component('onboardingChecklist',
       {
            templateUrl: '/Scripts/app/settings/components/onboarding/onboarding-checklist.template.html',
            controller: class OnboardingChecklistCtrl {

            $window: any;
            onboardingService: any;
            organisationService: any;
            items: any;
            logoUrl: string;
            isClub: boolean;
            isLoading: boolean;

            static $inject = ['$window', 'onboardingService', 'organisationService'];

            constructor ($window, onboardingService, organisationService) {
                this.$window = $window;
                this.onboardingService = onboardingService;
                this.organisationService = organisationService;
            }

            $onInit() {
                this.isLoading = true;
                this.organisationService.getCurrentOrganisationType().then(orgTypeId => {
                    this.isClub = this.organisationService.isClub(orgTypeId);
                    const cdnBase = this.$window.EveryBuddy.CdnBase;
                    this.logoUrl = cdnBase + `logos/${this.isClub ? 'C' : 'S'}B-vertical-full-color.png`;
                    this.isLoading = false;
                });
                this.items = this.onboardingService.getCheckListItems();
            }

        }
    });
