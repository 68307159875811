angular.module('messaging.constants', [])
    .constant('recipientGroupType', {
        Club: 0,
        Team: 1,
        Person: 2,
        Event: 3,
        WholeSchool: 4,
        SubjectClasses: 5,
    }).constant('messageMediumType', {
        Email: {
            id: 1,
            label: 'SB_Email_Iinc_Mobile_App_Notifications'
        },
        SMS: {
            id: 3,
            label: 'SB_SMS'
        }
    });