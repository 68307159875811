angular.module('tuitionFeesModule')
    .component('invoiceDetailViewItems', {
        bindings: {
            invoiceItems: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-items.template.html',
        controller: class InvoiceDetailViewItemsCtrl {

            // bindings
            invoiceItems: any[];

            // variables
            currencyDisplaySymbol: string;
            currencyExponent: number;
            showNoResult: boolean;
            sortReverse: boolean = false;
            sortType: string = 'number';

            static $inject = ['$window'];

            constructor($window) {
                this.showNoResult = false;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
            }

            $onChanges(changes) {
                if (changes.invoiceItems?.currentValue) {
                    this.showNoResult = !changes.invoiceItems?.currentValue || changes.invoiceItems?.currentValue?.length == 0;
                }
            }
        }
    });
