'use strict';

angular.module('shared.components.sbSearchFilter',
    [
        'shared.components.sbDateRangePicker',
        'shared.components.sbIcon',
        'shared.services.dateTimeService',
        'shared.constants',
        'duScroll',
    ]
)
    .component('sbSearchFilter', {
        bindings: {
            searchFilterType: '<', // see shared.constants.searchFilterTypes
            label: '<',
            noFilter: '<',
            filterCount: '<',
            placeholder: '<',
            dateRangeList: '<',
            hideResetButton: '<',
            noClass: '<',
            filterLabel: '@',
            filterIcon: '@',
            disableFilter: '<',
            inputValue: '=',
            startDate: '=',
            endDate: '=',
            diaryPicker: '<',
            searchText: '=',
            dateItems: '<',
            selectedDateItemId: '<',
            showShareButton: '<',
            minDate: '<',
            maxDate: '<',
            actionText: '<',
            pageSize: '=',
            onChange: '&',
            onReset: '&',
            onSelectDateItem: '&',
            onClickShare: '&',
            onCollapseStart: '&',
            onCollapseFinish: '&',
            onChangePageSize: '&',
            onClickAction: '&'
        },
        templateUrl: '/Scripts/app/shared/components/sb-search-filter.template.html',
        transclude: true,
        controller: class SbSearchFilter {

            // Dependencies
            $translate: any;
            $filter: any;
            $transclude: any;
            $timeout: any;
            dateTimeService: any;
            formElements: any;

            // Bindings
            pageSize: number;
            onChange: any;
            onSelectDateItem: any;
            onCollapseStart: any;
            onCollapseFinish: any;
            onChangePageSize: any;

            // Variables
            isCollapsed = true;
            eventCountLabels = [1, 2, 3, 4, 5];
            searchFilterType: any;
            placeholder: string;
            label: string;
            customDateOptions: any;
            isEmpty: boolean;
            datesElement: any; // Make Angular 2+ element later
            isDatesAtStart: boolean;
            isDatesAtEnd: boolean;
            pageSizes: any[];

            static $inject = ['$translate', '$filter', '$transclude', '$timeout', 'dateTimeService', 'formElements'];

            constructor($translate, $filter, $transclude, $timeout, dateTimeService, formElements) {
                this.$translate = $translate;
                this.$filter = $filter;
                this.$transclude = $transclude;
                this.$timeout = $timeout;
                this.dateTimeService = dateTimeService;
                this.formElements = formElements;
            }

            $onInit() {
                this.$translate.onReady().then(() => {

                    if (!this.searchFilterType) {
                        console.error('Unknown search filter type');
                        return;
                    }
                    this.placeholder = this.placeholder || this.$filter('translate')(this.searchFilterType.defaultPlaceholder);
                    this.label = this.label || this.$filter('translate')(this.searchFilterType.defaultLabel);

                    // Set datepicker options based on type
                    this.customDateOptions = {
                        autoApply: this.searchFilterType.id == 'dateSingle',
                        singleDatePicker: this.searchFilterType.id == 'dateSingle'
                    };
                });

                this.$transclude(clone => {
                    this.isEmpty = !clone[0];
                });
                if (this.pageSize) {
                    this.pageSizes = [ 5, 10, 20, 50, 100 ];
                }
            }

            getDateLabels(value) {
                return this.dateTimeService.getDateLabels(value);
            };

            isEndOfWeek(value) {
                return this.dateTimeService.isEndOfWeek(value);
            };

            // If text filter, autoupdate
            updateText() {
                if (this.searchFilterType && this.searchFilterType.id == 'textFilter') {
                    setTimeout(() => {
                        this.onChange();
                    });
                }
            };

            selectDateItem(date) {
                if (date.eventCount > 0) {
                    this.onSelectDateItem()(date.value);
                }
            };

            checkDatesPosition() {
                this.$timeout(() => {
                    var scrollLeft = this.datesElement.prop('scrollLeft');
                    this.isDatesAtStart = scrollLeft === 0;
                    this.isDatesAtEnd = (scrollLeft === (this.datesElement.prop('scrollWidth') - this.datesElement.prop('offsetWidth')));
                });
            };

            onInitDateItems() {
                this.$timeout(() => {
                    this.datesElement = angular.element(document.getElementById('dateItems'));
                    this.datesElement.on('scroll', () => {
                        this.checkDatesPosition();
                    });
                    this.checkDatesPosition();
                });
            };

            onClickArrow(isForward) {
                var currentPosition = this.datesElement.prop('scrollLeft');
                var scrollDistance = (this.datesElement.prop('offsetWidth') - 100);
                var positionChange = currentPosition + (isForward ? scrollDistance : -scrollDistance);
                this.datesElement.scrollLeft(positionChange, 350)
                .then(() =>
                {
                    this.checkDatesPosition();
                })
                .catch(error => {});
            };

            toggleCollapse() {
                this.onCollapseStart();
                this.isCollapsed = !this.isCollapsed;
                this.$timeout(() => {
                    this.onCollapseFinish();
                }, 600);
            };

            changePageSize() {
                this.onChangePageSize({value: this.pageSize});
            }
        }
    });
