'use strict';

angular.module('transport.configuration.timetableCalendar.transportTimetableCalendarList', [
    'transport.services.transportTimetableCalendarsService',
    'shared.filters.decoded',
]).component('transportTimetableCalendarList', {
    templateUrl: '/Scripts/app/transport/configuration/timetableCalendar/transport-timetable-calendar-list.template.html',
    bindings: {
    },
    controller: [
        'transportTimetableCalendarsService',
        'simpleAlertService',
        '$uibModal',
        '$scope',
        function transportTimetableCalendarListController(transportTimetableCalendarsService, simpleAlertService, $uibModal, $scope)
        {
            $scope.$on('timetableCalendarsUpdated', () => {
                this.loadTimetableCalendars();
            });

            this.$onInit = function ()
            {
                this.loadTimetableCalendars();
            }.bind(this);

            this.loadTimetableCalendars = () => {
                this.transportTimetableCalendars = null;

                transportTimetableCalendarsService.getTransportTimetableCalendars()
                    .then(function (data)
                    {
                        this.transportTimetableCalendars = data;
                    }.bind(this))
                    .catch(function (error)
                    {
                        simpleAlertService.errorAlert({ message: error.data.Message });
                    }.bind(this));
            }

            this.editTimetableCalendar = function (timetableCalendar)
            {
                var timetableCalendarModalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimetableCalendarEdit',
                    size: 'lg',
                    backdrop: 'static',
                    resolve:
                    {
                        params: function ()
                        {
                            return {
                                responseDto: timetableCalendar
                            }
                        }
                    }
                });

                timetableCalendarModalInstance.result.then(
                    () => this.loadTimetableCalendars(),
                    () => {}
                );
            }
        }
    ]
});
