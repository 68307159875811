angular.module('transport.services.transportRouteUpdatesService', [
        'shared.services.httpCoreApi'
])
    .service('transportRouteUpdatesService', class TransportRouteUpdatesService {

        httpCoreApi: any;

        urlBase: string;
        config: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.urlBase = 'TransportEventStatus/';
            this.config = {
                controllerType: httpCoreApi.controllerTypes.Admin
            };
        }

        getLiveRouteData() {
          return this.httpCoreApi.get(this.urlBase + 'LiveRouteData', this.config);
        }

        getRouteUpdateDetails(eventId: number) {
          return this.httpCoreApi.get(this.urlBase + 'LiveRouteEventData', {
            ...this.config,
            urlParams: {
              eventId: eventId
            }});
        }

});
