'use strict';

angular.module('ccasignupModule').controller('ccaSignupModuleForInfoOnly', {
    templateUrl: '/Scripts/app/ccaSignUp/SignUpModule/cca-signup-module-for-info-only.template.html',
    controller: [
    '$scope',
    '$state',
    'organisationMenuService',
    function ($scope, $state, organisationMenuService)
    {
        this.ccaSignUpTypeId = $state.params.moduleSignUpTypeId;
        $scope.upsell = '';

        organisationMenuService.getForInfoOnlyCcaSignupType(this.ccaSignUpTypeId)
            .then(function (data)
            {
                $scope.upsell = data.upsell;

            }.bind(this));
    }
]});