angular.module('tuitionFeesModule')
    .component('invoiceDetailViewAddNote', {
        bindings: {
            invoiceId: '<',
            fncSuccessAction: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-add-note.template.html',
        controller: class InvoiceDetailViewAddNoteCtrl {
            // Bindings
            invoiceId: number;
            fncSuccessAction: any

            // Dependencies
            $timeout: any;
            invoiceService: any;

            // Variables
            noteMinLength: number;
            noteMaxLength: number;
            isSubmitted: boolean;
            invoiceNote: string;

            static $inject = ['$timeout', 'invoiceService'];

            constructor($timeout, invoiceService) {
                this.$timeout = $timeout;
                this.invoiceService = invoiceService;

                this.noteMinLength = 4;
                this.noteMaxLength = 800;
                this.isSubmitted = false;
            }

            $onChanges(changes) {
                if (changes.invoiceId) {
                    this.invoiceNote = '';
                }
            }

            resetSubmit() {
                this.$timeout(function () {
                    this.isSubmitted = false;
                }.bind(this), 100);
            }

            addNote() {
                this.isSubmitted = true;

                let payload = {
                    invoiceNote: this.invoiceNote,
                };

                this.invoiceService.addNote(this.invoiceId, payload).then(res => {
                    if (res?.isSuccess && this.fncSuccessAction) {
                        this.$timeout(function () {
                            this.invoiceNote = '';
                            this.fncSuccessAction(this.invoiceId);
                        }.bind(this), 100);
                    }

                    this.resetSubmit();
                });
            }
        }
    });
