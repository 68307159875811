'use strict';

angular.module('group.directives.multiPersonPicker', [
    'angular.filter',
    'pascalprecht.translate',
    'tmh.dynamicLocale',

    'person.services',
    'person.services.personPickerService',

    'shared.components.sbIcon',
    'shared.directives.sbRadioButton',
    'shared.services.deviceService'
]).directive('multiPersonPicker', [
    '$filter',
    '$timeout',
    '$window',
    'personService',
    'personPickerService',
    'pupilTransportPreferenceService',
    'simpleAlertService',
    'transportTimeSlotsService',
    'deviceService',
    function ($filter, $timeout, $window, personService, personPickerService, pupilTransportPreferenceService, simpleAlertService, transportTimeSlotsService, deviceService) {

        function link($scope)
        {
            $scope.loading = 4;

            $scope.$watch('selectedPersonIds',
                function (newValue) {
                    if (!newValue)
                        return;

                    $scope.getMissingNames(newValue);
                });
        }

        function controller($scope) {

            deviceService.calculateAppHeight();
            angular.element($window).bind('resize', () => {
                deviceService.calculateAppHeight();
            });
            angular.element($window).bind('blur', () => {
                deviceService.calculateAppHeight();
            });

            $scope.loading = 4;
            $scope.loadingSearchResults = false;
            $scope.clubs = [];
            $scope.schoolYearGroups = [];
            $scope.schoolClasses = [];
            $scope.teams = [];
            $scope.anyChanges = false;

            $scope.availablePeopleCurrentPage = 1;
            $scope.selectedPeopleCurrentPage = 1;
            $scope.pageSize = 10;
            $scope.maxNumberOfPages = $scope.isMassStudentAssignmentCopyFromMode ? 6 : 4;

            $scope.years = [];
            $scope.classes = [];

            $scope.nameDictionary = [];
            $scope.availablePeople = [];
            $scope.availablePeopleRaw = [];
            $scope.searchParameters = {
                nameSearchCriteria: '',
                memberOfClubId: 0,
                memberOfTeamId: 0,
                pageSize: 99999,
                availablePeopleCurrentPage: 0,
                includePupils: $scope.includePupils,
                includeStaff: $scope.includeStaff,
                includeParents: false,
                genderTypeId: 3,
                filterOutExternalStaff: $scope.filterOutExternalStaff
            };

            $scope.selectDaysText = $filter('translate')('SB_Select_Days');

            $scope.checkboxModel = {
                isDeletedCalendars: false
            };

            $scope.isCalendarLoading = false;

            // initial load of drop downs

            personPickerService.getSchoolYearGroups()
                .then(function (data)
                {
                    $scope.schoolYearGroups = data;

                    for (var i = data.length; i--;)
                    {
                        $scope.years[data[i].id] = data[i].n;
                    }

                    $scope.loading--;
                });

            personPickerService.getSchoolClasses()
                .then(function (data)
                {
                    $scope.schoolClasses = data;

                    for (var i = data.length; i--;)
                    {
                        $scope.classes[data[i].id] = data[i].n;
                    }

                    $scope.loading--;
                });

            if (!$scope.isMassStudentAssignmentCopyFromMode)
            {
                personPickerService.getClubs(false)
                    .then(function (data)
                    {
                        $scope.clubs = data;
                        $scope.loading--;
                    });

                personPickerService.getTeams(false)
                    .then(function (data)
                    {
                        $scope.teams = data;

                        angular.forEach($scope.teams,
                            function (item)
                            {
                                var scheduleInfo = '';

                                if (item.dayOfWeek)
                                {
                                    if (item.dayOfWeek > 0)
                                    {
                                        scheduleInfo += moment().isoWeekday(item.dayOfWeek).format('ddd');
                                    }
                                    else if (item.dayOfWeek === 0)
                                    {
                                        scheduleInfo += 'All';
                                    }
                                }

                                if (item.startTime)
                                {
                                    if (scheduleInfo.length > 0)
                                        scheduleInfo += ' ';

                                    scheduleInfo += moment('1/1/1 ' + item.startTime).format('HH:mm');
                                }

                                if (scheduleInfo.length > 0)
                                {
                                    item.name += ' - ' + scheduleInfo;
                                }
                            });

                        $scope.loading--;
                    });
            }

            $scope.isCalendarLoadingCheck = (result) => {
                $scope.isCalendarLoading = result;
            }

            $scope.isLoading = function ()
            {
                if ($scope.loading < 0)
                {
                    $scope.loading = 0;
                }

                return $scope.loading > 0;
            };

            if ($scope.isMassStudentAssignmentCopyFromMode)
            {
                $scope.loading = 1;

                var selectTimeSlotText = $filter('translate')('SB_Please_Select_Timeslot');
                var selectTimeSlot =
                {
                    id: 0,
                    timeslotAndDirectionName: selectTimeSlotText
                };

                transportTimeSlotsService.getActiveTransportTimeSlots().then(function (data)
                {
                    $scope.transportTimeSlots = data;

                    $scope.transportTimeSlots.unshift(selectTimeSlot);

                    $scope.selectedTimeSlot =
                    {
                        id: 0,
                        timeslotAndDirectionName: selectTimeSlotText
                    };

                    $timeout(function ()
                    {
                        $('#btnAccordion1').click();
                    }, 200);

                    $timeout(function ()
                    {
                        $scope.loading = 0;
                    }, 200);

                }, function (error)
                {
                    simpleAlertService.errorAlert(error);
                });
            }

            $scope.timeSlotChanged = function ()
            {
                $scope.setSelectedTimeSlotName();
            }

            $scope.setSelectedTimeSlotName = function ()
            {
                for (var i = 0; i < $scope.transportTimeSlots.length; i++)
                {
                    if ($scope.transportTimeSlots[i].id == $scope.selectedTimeSlot.id)
                    {
                        $scope.selectedTimeSlot =
                        {
                            id: $scope.selectedTimeSlot.id,
                            name: $scope.transportTimeSlots[i].timeslotAndDirectionName
                        };

                        $scope.selectedTimeSlotId = $scope.selectedTimeSlot.id;

                        $scope.timetable = null;
                        $scope.timetableRoute = null;
                        $scope.selectionDescription = '';
                        $scope.uniqueOperatingDays = [];
                        $scope.selectedOperatingDays = [];
                        $scope.availablePeople = [];

                        // Expand the Transport Selection accordion if a time slot has been selected
                        if ($scope.selectedTimeSlot.id > 0)
                        {
                            $timeout(function ()
                            {
                                $('#btnAccordion2').click();
                            }, 200);
                        }

                        break;
                    }
                }
            };

            $scope.addPerson = function (person)
            {
                // ensure they aren't already selected
                var index = $scope.selectedPersonIds.indexOf(person.id);
                if (index === -1)
                {
                    $scope.nameDictionary[person.id] = {
                        firstName: person.fn,
                        lastName: person.ln,
                        otherName: person.on,
                        yg: person.yg,
                        cg: person.cg,
                        ar: true
                    };

                    $scope.selectedPersonIds.push(person.id);

                    $scope.anyChanges = true;

                    // remove this person from the available people list now it's selected
                    var availableIndex = -1;
                    for (var i = $scope.availablePeople.length; i--;)
                    {
                        if ($scope.availablePeople[i].id === person.id)
                        {
                            availableIndex = i;
                            break;
                        }
                    }
                    if (availableIndex > -1)
                    {
                        $scope.availablePeople.splice(availableIndex, 1);
                    }
                }
                $scope.onChangeSelectedPersonIds();
            };

            $scope.removePerson = function (personId)
            {
                if (personId === $scope.lockedPersonId) {
                    return;
                }
                // find the one we are removing
                var index = $scope.selectedPersonIds.indexOf(personId);

                if (index > -1)
                {
                    $scope.selectedPersonIds.splice(index, 1);
                    $scope.anyChanges = true;
                }

                $scope.buildFilteredSearchResults();
                $scope.onChangeSelectedPersonIds();
            };

            $scope.addAll = function ()
            {
                for (var i = $scope.availablePeople.length; i--;)
                {
                    $scope.addPerson($scope.availablePeople[i]);
                }
                $scope.onChangeSelectedPersonIds();
            };

            $scope.removeAll = function ()
            {
                $scope.selectedPersonIds = $scope.lockedPersonId ? [$scope.lockedPersonId] : [];
                $scope.buildFilteredSearchResults();
                $scope.anyChanges = true;
                $scope.onChangeSelectedPersonIds();
            };

            $scope.search = function () {
                if ($scope.isMassStudentAssignmentCopyFromMode || $scope.loadingSearchResults) return;

                $scope.availablePeopleCurrentPage = 1;

                $scope.availablePeople = [];
                $scope.availablePeopleRaw = [];
                $scope.loadingSearchResults = true;

                if ($scope.onLoadingStateChanged && $scope.onLoadingStateChanged() !== undefined) {
                    $scope.onLoadingStateChanged()(true);
                }

                var personLookupCallback = function (data) {
                    if (!data.isError) {
                        $scope.availablePeopleRaw = data.results;
                        $scope.buildFilteredSearchResults();
                    }

                    $scope.loadingSearchResults = false;

                    if ($scope.onLoadingStateChanged && $scope.onLoadingStateChanged() !== undefined) {
                        $scope.onLoadingStateChanged()(false);
                    }
                };

                if ($scope.overridePersonLookupService) {
                    $scope.overridePersonLookupService.getPeopleFiltered($scope.searchParameters)
                        .then(personLookupCallback);
                } else {
                    personPickerService.getPeopleFiltered($scope.searchParameters)
                        .then(personLookupCallback);
                }
            };

            $scope.buildFilteredSearchResults = function ()
            {
                var filteredResults = [];

                // ensure we don't get anyone we already have in the list
                angular.forEach($scope.availablePeopleRaw,
                    function (item)
                    {
                        if ($scope.selectedPersonIds.indexOf(item.id) === -1)
                        {
                            filteredResults.push(item);
                        }
                    });
                $scope.availablePeople = filteredResults;
            };

            $scope.selectedPersonSortFunction = function (id) {
                if ($scope.nameDictionary[id]) {
                    return $scope.nameDictionary[id].lastName + ' ' + $scope.nameDictionary[id].firstName;
                }

                return 0;
            };

            $scope.renderSchoolData = function (cg, yg) {
                var result = '';

                if ($scope.years && $scope.years[yg])
                    result += '(' + $scope.years[yg] + ') ';

                if ($scope.classes && $scope.classes[cg])
                    result += $scope.classes[cg];

                return result;
            };

            $scope.getMissingNames = function (ids) {

                var idsToLookup = [];

                // who are we actually missing?
                angular.forEach(ids,
                    function (id) {
                        if ($scope.nameDictionary[id] === undefined) {
                            idsToLookup.push(id);
                        }
                    });

                if (idsToLookup.length > 0)
                {
                    $scope.loading++;

                    // look up the names of these people
                    personService.getNames(idsToLookup, $scope.selectedGroupId)
                        .then(function (data)
                        {
                            angular.forEach(data,
                                function (item)
                                {
                                    $scope.nameDictionary[item.id] =
                                    {
                                        firstName: item.firstName,
                                        lastName: item.lastName,
                                        otherName: item.otherName,
                                        yg: item.schoolYearGroupId,
                                        cg: item.schoolClassId,
                                        ar: item.allowRemovalFromGroup && !$scope.shownFromRegister
                                    };

                                    if (!item.allowRemovalFromGroup) {
                                        $scope.hideRemove = true;
                                    }
                                });
                            $scope.loading--;
                        })
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert();
                        });
                }
            };

            $scope.okButtonClick = function () {
                $scope.okButtonCallback()();
            };

            $scope.cancelButtonClick = function ()
            {
                if ($scope.cancelButtonCallback && typeof ($scope.cancelButtonCallback()) === 'function')
                {
                    $scope.cancelButtonCallback()();
                }
            };

            // Load initial search result list of not in transport "copy from" mode
            if (!$scope.isMassStudentAssignmentCopyFromMode)
            {
                $scope.search();
            }

            $scope.dropdownSettings =
            {
                buttonClasses: 'btn btn-default pl-2 pr-2',
                checkBoxes: true,
                keyboardControls: true,
                showUncheckAll: true,
                showCheckAll: true,
                smartButtonMaxItems: 3,
                smartButtonTextConverter: function (itemText) { return itemText; }
            };

            $scope.onTransportSelectionMade = function (transportSelection)
            {
                $scope.timetable = transportSelection.timetable;
                $scope.timetableRoute = transportSelection.route;
                $scope.timetableIdToCopyFrom = transportSelection.timetable.id;
                $scope.timetableRouteIdToCopyFrom = transportSelection.route.id;

                $scope.selectionDescription = transportSelection.selectionDescription;
                $scope.uniqueOperatingDays = [];

                var operatingDayItems = $filter('unique')($scope.timetable.operatingDays, 'operatingDayId');

                for (var i = 0; i < operatingDayItems.length; i++)
                {
                    if (operatingDayItems[i].operatingDayId && operatingDayItems[i].operatingDayId > 0)
                    {
                        $scope.uniqueOperatingDays.push(
                            {
                                label: $filter('translate')(operatingDayItems[i].translatedDayName),
                                id: operatingDayItems[i].operatingDayId
                            });
                    }
                }

                $scope.selectedOperatingDays = [].concat($scope.uniqueOperatingDays);

                // Collapse the Transport Selection accordion
                $timeout(function ()
                {
                    $('#btnAccordion2').click();
                }, 200);

                $scope.loadPupilPreferences();
            };

            $scope.operatingDaySelectionChanged =
            {
                onSelectionChanged: function ()
                {
                    for (var i = 0; i < $scope.selectedOperatingDays.length; i++)
                    {
                        for (var u = 0; u < $scope.uniqueOperatingDays.length; u++)
                        {
                            if ($scope.uniqueOperatingDays[u].id == $scope.selectedOperatingDays[i].id)
                            {
                                $scope.selectedOperatingDays[i].label = $scope.uniqueOperatingDays[u].label;
                            }
                        }
                    }

                    $scope.loadPupilPreferences();
                }
            };

            $scope.loadPupilPreferences = function ()
            {
                if (typeof $scope.selectedTimeSlot.id !== 'undefined' && $scope.selectedTimeSlot.id > 0 &&
                    typeof $scope.timetable !== 'undefined' && $scope.timetable != null && typeof $scope.timetable.id !== 'undefined')
                {
                    var timetableRouteId = null;
                    if ($scope.timetableRoute != null)
                    {
                        timetableRouteId = $scope.timetableRoute.id;
                    }

                    pupilTransportPreferenceService.loadPupilPreferences($scope.timetable.id, timetableRouteId)
                        .then(function (timetablePupilsPreferencesResponse)
                        {
                            $scope.preferencesResponse = timetablePupilsPreferencesResponse;
                            $scope.selectedPupils = timetablePupilsPreferencesResponse.timetablePupilPreferences;

                            var filterByOperatingDays = $scope.selectedOperatingDays.length != $scope.uniqueOperatingDays.length;

                            $scope.availablePeople = [];
                            $scope.availablePeopleRaw = [];
                            angular.forEach($scope.selectedPupils,
                                function (selectedPupil)
                                {
                                    var includeStudent = true;

                                    var alreadySelected = $scope.selectedPersonIds.filter(function (selectedPersonId)
                                    {
                                        return selectedPupil.personId == selectedPersonId;
                                    });

                                    if (alreadySelected.length > 0)
                                    {
                                        includeStudent = false;
                                    }

                                    if (includeStudent && filterByOperatingDays)
                                    {
                                        var anyMatchingDays = false;

                                        for (var i = 0; i < selectedPupil.pupilPreferenceOperatingDays.length; i++)
                                        {
                                            var pupilOperatingDay = selectedPupil.pupilPreferenceOperatingDays[i];

                                            if (pupilOperatingDay.isDaySelected)
                                            {
                                                for (var j = 0; j < $scope.selectedOperatingDays.length; j++)
                                                {
                                                    if (pupilOperatingDay.organisationOperatingDayId == $scope.selectedOperatingDays[j].id)
                                                    {
                                                        anyMatchingDays = true;
                                                        break;
                                                    }
                                                }
                                            }

                                            if (anyMatchingDays)
                                            {
                                                break;
                                            }
                                        }

                                        if (!anyMatchingDays)
                                        {
                                            includeStudent = false;
                                        }
                                    }

                                    if (includeStudent)
                                    {
                                        var item =
                                        {
                                            id: selectedPupil.personId,
                                            fn: selectedPupil.pupilFirstName,
                                            ln: selectedPupil.pupilLastName,
                                            on: selectedPupil.pupilOtherName,
                                            cg: selectedPupil.schoolClassId,
                                            yg: selectedPupil.schoolYearGroupId
                                        };

                                        $scope.availablePeopleRaw.push(item);
                                        $scope.availablePeople.push(item);
                                    }
                                });

                        }.bind(this))
                        .catch(function (responseData)
                        {
                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            simpleAlertService.errorAlert(message);
                        });
                }
            };
            $scope.onChangeSelectedPersonIds = ()  => {
                $scope.selectedPersonIdsChange({
                    value: {
                        ids: $scope.selectedPersonIds,
                        options: $scope.selectedPersonIds.map(item => {
                            return {
                                id: item,
                                name: $scope.nameDictionary[item].lastName + ', ' + $scope.nameDictionary[item].firstName
                            };
                        })
                    }
                });
            }
        };

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/person/directives/multi-person-picker.template.html',
            scope: {
                defaultClubId: '<',
                defaultTeamId: '<',
                selectedPersonIds: '=',
                selectedPersonIdsChange: '&',
                includeStaff: '=',
                includePupils: '=',
                selectedPersons: '<',

                // parameters for extra ok button to be shown- good if this is being used in a popup window
                showOkButton: '<',
                cancelButtonCallback: '&',
                okButtonCallback: '&',
                okButtonText: '@',
                hideGroupFilter: '=',
                hideYearFilter: '=',
                hideGenderFilter: '=',
                hideRemove: '=',
                shownFromRegister: '=',
                shownFromStaffRoles: '=',
                filterOutExternalStaff: '=',
                isMassStudentAssignmentCopyFromMode: '=',
                selectedTimeSlotId: '<',
                selectedTimetableId: '<',
                selectedTimetableRouteId: '<',
                timetableIdToCopyFrom: '=',
                timetableRouteIdToCopyFrom: '=',
                selectedTimetableCalendarName: '=',
                isLoadingComplete: '=',

                overridePersonLookupService: '=',
                selectedGroupId: '=',
                onLoadingStateChanged: '&',
                // Angular 12 use
                lockedPersonId: '<'
            },
            controller: ['$scope', controller],
            link: link
        };

    }
]);
