<div class="planner-checkbox-container"
     [ngClass]="{'planner-checkbox-container--reversed' : isReversed}"
     (click)="toggle()">
    <span class="planner-checkbox"
          [class.disabled]="isDisabled"
          [ngClass]="isCheckboxChecked() ? 'planner-checkbox--' + this.checkClass : ''"
          [attr.id]="'chksp_' + controlName"
          [ngbTooltip]="tooltip | translate"
          placement="bottom"
          container="body"></span>
    <input type="checkbox"
           [(ngModel)]="model"
           [attr.id]="label"
           [attr.name]="controlName"
           [disabled]="isDisabled"
           hidden />
    <div *ngIf="label"
         [innerHTML]="label | translate"
         class=></div>
</div>