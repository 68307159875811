'use strict';

angular
    .module('shared.services.dateTimeInterceptor', [])
    .service('dateTimeInterceptor', [
        '$filter',
        'dateTextService',
        function ($filter, dateTextService) {
            var whitelist = {
                // case-sensitive, keep alphabetical please!
                '/Balance/GetTransactions': [
                    'eventsFrom',
                    'eventsTo',
                    'eventDateUtc',
                ],
                '/BalanceAdmin/ChaseEvent': [
                    'eventsFrom',
                    'eventsTo',
                    'eventDateUtc',
                ],
                '/BalanceAdmin/GetEvents': ['fromUtc'],
                '/BalanceAdmin/GetEventTransactions': [
                    'eventsFrom',
                    'eventsTo',
                    'eventDateUtc',
                ],
                '/BalanceAdmin/GetPersonTransactions': [
                    'eventsFrom',
                    'eventsTo',
                    'eventDateUtc',
                ],
                '/BalanceAdmin/GetTransactionSummary': [
                    'eventsFrom',
                    'eventsTo',
                    'eventDateUtc',
                ],
                '/CcaAvailable/CancelBooking': ['fromUtc', 'toUtc'],
                '/CcaAvailable/GetSignUp': ['openDateUtc', 'closeDateUtc'],
                '/CcaAvailable/GetSignUpBookedEvents': ['fromUtc', 'toUtc'],
                '/CcaAvailable/GetSignUpEvents': ['fromUtc', 'toUtc'],
                '/CcaAvailable/GetSignUps': ['openDateUtc', 'closeDateUtc'],
                '/CcaAvailable/ToggleBooking': ['fromUtc', 'toUtc'],
                '/CcaAvailable/ToggleBookings': [
                    'fromUtc',
                    'toUtc',
                    'startDateTimeUtc',
                ],
                '/CcaAvailable/SaveBookings': ['startDateTimeUtc'],
                '/CcaSignUp/GetCcaStaffAttendees': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetSignUpBookedEvents': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetSignUpEvents': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetSignUpEventsForPupil': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetSignUpEventsTimeSlotted': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetSignUps': [
                    'signUpsFrom',
                    'signUpsTo',
                    'openDateUtc',
                    'closeDateUtc',
                ],
                '/CcaSignUp/GetSignUpsAvailableToCopy': [
                    'openDateUtc',
                    'closeDateUtc',
                ],
                '/CcaSignUp/GetStaffWithAllEvents': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetStaffWithBookedEvents': ['fromUtc', 'toUtc'],
                '/CcaSignUp/PopulateGroupCcaNamesAsync': ['LastEventDateUtc'],
                '/CcaSignUp/SaveAndFetchSignUpGroup': ['LastEventDateUtc'],
                '/CcaSignUp/SaveBookings': [
                    'fromUtc',
                    'toUtc',
                    'startDateTimeUtc',
                ],
                '/CcaSignUp/SaveSignUpGroup': ['LastEventDateUtc'],
                '/CcaSignUp/SaveSignUpGroupEventAndReturnSavedEntity': [
                    'signUpOpen',
                    'signUpClose',
                ],
                '/CcaSignUp/StaffDeletePupilEvent': ['fromUtc', 'toUtc'],
                '/CcaSignUp/GetSignUpGroupsWithEventsForDeletionOrCancellation':
                    ['eventDateUtc', 'eventEndDateUtc'],
                '/Reception/GetAttendanceGroups': ['eventsFrom', 'eventsTo'],
                '/Reception/GetEventSummary': [
                    'eventsFrom',
                    'eventsTo',
                    'fromUtc',
                    'toUtc',
                    'eventsFromUtc',
                    'eventsToUtc',
                ],
                '/Reception/GetLocationEvents': ['fromUtc', 'toUtc'],
                '/Reception/GetPersonData': ['lastAccessedUtc'],
                '/Reception/GetRegGroupNextEventData': [
                    'fromDate',
                    'eventTimeUtc',
                ],
                '/Reception/GetNextEventData': [
                    'fromDate',
                    'eventTime',
                    'eventTimeUtc',
                ],
                '/webapi/WebBalanceAdministration/GetGroupSummariesAsync': [
                    'from',
                    'to',
                ],
                '/webapi/WebBalanceAdministration/GetGroupFeeSummariesAsync': [
                    'from',
                    'to',
                    'minEventDateUtc',
                ],
                '/webapi/WebBalanceAdministration/GetEventSummariesForGroupAsync':
                    ['from', 'to', 'eventDateUtc'],
                '/webapi/WebBalanceAdministration/GetPupilSummariesAsync': [
                    'from',
                    'to',
                ],
                '/webapi/WebBalanceAdministration/GetChargeTransactionsForAccount':
                    ['eventFromUtc'],
                '/webapi/WebBalanceAdministration/GetAccountSummaries': [
                    'transactionDateUtc',
                ],
                '/webapi/WebCalendarEventCreationRule/GetCalendarEventCreationRulesForSignUp':
                    ['signUpOpenUtc', 'signUpCloseUtc'],
                '/webapi/WebCalendarEventCreationRule/GetCalendarEventCreationRulesForSignUpToPublish':
                    ['signUpOpenUtc', 'signUpCloseUtc'],
                '/webapi/WebCalendarEventView/GetEventDetail': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'signUpOpenUtc',
                    'signUpCloseUtc',
                    'routeStopDateTimeUtc',
                    'pickupAtUtc',
                ],
                '/webapi/WebCcaPreferenceTeam/GenerateAdditionalEventsForActiveGroupsDatesAndDays':
                    ['FromDateUtc', 'ToDateUtc'],
                '/webapi/WebCcaSignUp/GetSignUp': [
                    'openDateUtc',
                    'closeDateUtc',
                ],
                '/webapi/WebCcaSignUp/SaveSignUp': ['openDate', 'closeDate'],
                '/webapi/WebCcaSignUpCloneContainer/CloneAsync': [
                    'openDate',
                    'closeDate',
                ],
                '/webapi/WebChangeRequest/GetChangeRequest': [
                    'calendarEventStartDateTimeUtc',
                    'createdDateTimeUtc',
                    'stopDateTimeUtc',
                ],
                '/webapi/WebChangeRequest/GetOptions': [
                    'calendarEventStartDateTimeUtc',
                    'stopDateTimeUtc',
                ],
                '/webapi/WebDiary/GetAll': [
                    'fromDate',
                    'toDate',
                    'eventDateUtc',
                    'eventEndDateUtc',
                    'routeStopDateTimeUtc',
                ],
                '/webapi/WebDiary/GetInvites': [
                    'eventDateUtc',
                    'eventEndDateUtc',
                ],
                '/webapi/WebDiary/GetAllForTeam': [
                    'subFromDate',
                    'subToDate',
                    'eventDateUtc',
                    'eventEndDateUtc',
                ],
                '/webapi/WebEventRegisters/GetRegisterData': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'transportAttendeeRouteStopTimeUtc',
                    'timeStampUtc',
                    'absenteeAlertSentUtc',
                ],
                '/webapi/WebEventRegisters/GetOfflineRegistersData': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'eventStartTimeUtc',
                    'eventEndTimeUtc',
                    'transportAttendeeRouteStopTimeUtc',
                    'absenteeAlertSentUtc',
                ],
                '/webapi/WebEventRegisters/GetRegisterNextEventData': [
                    'eventStartTimeUtc',
                    'eventEndTimeUtc',
                ],
                '/webapi/WebEventRegisters/GetRegisterPreviousEventData': [
                    'eventStartTimeUtc',
                    'eventEndTimeUtc',
                ],
                '/webapi/WebEventRegisters/SaveRegisterAttendeeAsync': [
                    'registerUpdatedDateTimeUtc',
                ],
                '/webapi/WebEvents/EventsDelete': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'signUpOpenUtc',
                    'signUpCloseUtc',
                    'pickupAtUtc',
                ],
                '/webapi/WebEvents/GetDetails': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'signUpOpenUtc',
                    'signUpCloseUtc',
                    'pickupAtUtc',
                ],
                '/webapi/WebEvents/GetEventBasics': ['fromUtc'],
                '/webapi/WebEvents/GetStudentsForNotifyOnlyEvent': [
                    'confirmedTimestampUtc',
                    'lastUpdatedUtc',
                ],
                '/webapi/WebEvents/GetConsentEventInvitesData': [
                    'consentDeadlineUtc',
                ],
                '/webapi/WebEvents/OtherDeletableEventsInSeries': [
                    'fromUtc',
                    'toUtc',
                ],
                '/webapi/WebEvents/Restore': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'signUpOpenUtc',
                    'signUpCloseUtc',
                    'pickupAtUtc',
                ],
                '/webapi/WebEvents/Update': [
                    'from',
                    'to',
                    'meetingAt',
                    'signUpOpen',
                    'signUpClose',
                    'pickupAt',
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'signUpOpenUtc',
                    'signUpCloseUtc',
                    'pickupAtUtc',
                ],
                '/webapi/WebEvents/UpdateLinked': [
                    'fromUtc',
                    'toUtc',
                    'meetingAtUtc',
                    'signUpOpenUtc',
                    'signUpCloseUtc',
                    'pickupAtUtc',
                    'signUpOpen',
                    'signUpClose',
                    'pickupAt',
                ],
                '/webapi/WebGroup/GetFeeOnlyEventsForGroup': ['createdDateUtc'],
                '/webapi/WebGroup/GetGroupsWithChargeEventsByPartialNameByDateRange':
                    ['eventFrom', 'eventTo'],
                '/webapi/WebGroup/GetChargeEventsByGroup': ['eventDateUtc'],
                '/webapi/WebMessageViewer/GetAdminInbox': ['saUtc'],
                '/webapi/WebMessageViewer/GetAdminInboxNewOnly': ['saUtc'],
                '/webapi/WebMessageViewer/GetSpecificMessage': [
                    'saUtc',
                    'roUtc',
                    'soUtc',
                    'aoUtc',
                    'coUtc',
                ],
                '/webapi/WebMessageViewer/GetPersonInboxUnfiltered': [
                    'saUtc',
                    'roUtc',
                    'soUtc',
                    'aoUtc',
                    'coUtc',
                ],
                '/webapi/WebMessageViewer/GetPersonInbox': [
                    'saUtc',
                    'roUtc',
                    'soUtc',
                    'aoUtc',
                    'coUtc',
                ],
                '/webapi/WebMessageViewer/StarMessage': [
                    'readOnUtc',
                    'starredOnUtc',
                    'acknowledgedOnUtc',
                    'archivedOnUtc',
                ],
                '/webapi/WebMessageViewer/ArchiveMessage': [
                    'readOnUtc',
                    'starredOnUtc',
                    'acknowledgedOnUtc',
                    'archivedOnUtc',
                ],
                '/webapi/WebOrganisationChangeRequest/GetPendingChangeRequestsForApproval':
                    [
                        'calendarEventStartDateTimeUtc',
                        'createdDateTimeUtc',
                        'lastModifiedDateTimeUtc',
                    ],
                '/webapi/WebOrganisationChangeRequest/GetFutureChangeRequestsApprovedOrRejected':
                    [
                        'calendarEventStartDateTimeUtc',
                        'createdDateTimeUtc',
                        'lastModifiedDateTimeUtc',
                    ],
                '/webapi/WebOrganisationChangeRequest/GetTransportDiaryEventsByPupilName':
                    ['eventDateUtc', 'eventEndDateUtc', 'routeStopDateTimeUtc'],
                '/webapi/WebOrganisationTransportMethod/GetMethods': [
                    'createDateTimeUtc',
                ],
                '/webapi/WebOrganisationTransportRoutes/GetRoutes': [
                    'createDateTimeUtc',
                ],
                '/webapi/WebOrganisationTransportStops/GetStops': [
                    'createDateTimeUtc',
                ],
                '/webapi/WebOrganisationTransportTimetableCalendar/GetTransportTimetableCalendars':
                    ['fromDateUtc', 'toDateUtc'],
                '/webapi/WebOrganisationTransportTimetableCalendar/GetTransportTimetableCalendar':
                    ['fromDateUtc', 'toDateUtc'],
                '/webapi/WebOrganisationTransportTimetableCalendar/AddTransportTimetableCalendar':
                    ['fromDate', 'toDate'],
                '/webapi/WebOrganisationTransportTimetableCalendar/EditTransportTimetableCalendar':
                    ['fromDate', 'toDate'],
                '/webapi/WebOrganisationTransportTimetableCalendar/ValidateDateRangeForOverlaps':
                    ['fromDate', 'toDate'],
                '/webapi/WebPersonPicker/GetPeopleWithFilters': ['liUtc'],
                '/webapi/WebTasks/GetAllForCaseId': [
                    'createdUtc',
                    'deadlineUtc',
                ],
                '/webapi/WebTasks/GetAllTasks': [
                    'startDate',
                    'endDate',
                    'createdUtc',
                    'deadlineUtc',
                ],
                '/webapi/WebTasks/GetById': ['createdUtc', 'deadlineUtc'],
                '/webapi/WebTasks/MarkCompleted': [
                    'created',
                    'createdUtc',
                    'deadline',
                    'deadlineUtc',
                ],
                '/webapi/WebTasks/Save': [
                    'created',
                    'deadline',
                    'createdUtc',
                    'deadlineUtc',
                ],
                '/webapi/WebTeam/GetTeamAttendance': [
                    'eventsFrom',
                    'eventsTo',
                    'fromUtc',
                ],
                '/webapi/WebTeam/GetTeamAttendanceReport': [
                    'eventsFrom',
                    'eventsTo',
                    'fromUtc',
                ],
                '/webapi/WebStaff/GetStaffForSchedule': ['start', 'end'],
                '/webapi/WebStaff/GetStaffMemberSchedule': [
                    'start',
                    'end',
                    'eventDateUtc',
                    'eventEndDateUtc',
                ],
            };

            // Apply whitelist for Angular12 app
            if (window.EveryBuddy.IsAngular12) {
                for (const key in whitelist) {
                    whitelist[window.EveryBuddy.WebAPI + key] = whitelist[key];
                }
            }

            var regexWhitelist = [
                {
                    rx: /\/api\/Admin\/\d+\/Balance\/GetMoniesReceived/,
                    props: [
                        'transFromDateUtc',
                        'transToDateUtc',
                        'transactionDateUtc',
                        'eventDateUtc',
                    ],
                },
                {
                    rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Batches/,
                    props: ['createdDateTimeUtc', 'createdDateTime', 'orderBy'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Batch\/\d+/,
                    props: ['createdDateTimeUtc'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Invoices/,
                    props: ['dueDate'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Communication\/AllMessages/,
                    props: ['dateTimeSentUtc'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Products/,
                    props: ['lastInvoicedDateTimeUtc'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Invoice\/Invoices/,
                    props: [
                        'dueDateUtc',
                        'invoiceDateUtc',
                        'overdueReminderSentDateUtc',
                        'invoiceDate',
                        'dueDate',
                        'orderBy',
                    ],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Invoice$/,
                    props: ['dateTimeUtc'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Invoice\/\d+$/,
                    props: ['dateTimeUtc', 'datePaidUtc', 'dateIssuedUtc'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Invoice\/\d+\/Payment/,
                    props: ['datePaid'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Invoice\/\d+\/CreditNote/,
                    props: ['dateIssued'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Student\/TuitionSearch/,
                    props: ['enrollmentFrom', 'enrollmentTo'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/TransportEventStatus\/LiveRouteData/,
                    props: ['routeStartTimeUtc'],
                },
                {
                    rx: /\/api\/Admin\/\d+\/TransportEventStatus\/LiveRouteEventData/,
                    props: [
                        'routeStartDateTimeUtc',
                        'expectedStopTimeUtc',
                        'loggedTimeUtc',
                        'scheduledStopTimeUtc',
                    ],
                },
                {
                    rx: /\/api\/Admin\/\d+\/Events\/ConsentEventSettings/,
                    props: ['consentDeadline'],
                },
            ];

            var whitelistKeys = Object.keys(whitelist);
            function stripUrl(url) {
                try {
                    var uri = new URL(url);
                    return uri.pathname;
                } catch {
                    if (url.indexOf('?') >= 0) {
                        return url.split('?')[0];
                    }

                    return url;
                }
            }

            this.request = function (config) {
                // Remove query string (and for core API, origin)
                let url = stripUrl(config.url);

                // Conversions to query params and request data
                if (whitelistKeys.indexOf(url) > -1) {
                    convertToUTC(whitelist[url], config.params);
                    convertToUTC(whitelist[url], config.data);
                } else {
                    var regexMatch = regexWhitelist.find((x) => x.rx.test(url));
                    if (regexMatch) {
                        convertToUTC(regexMatch.props, config.params);
                        convertToUTC(regexMatch.props, config.data);
                    }
                }

                return config;
            };

            this.response = function (response) {
                var url = stripUrl(response.config.url);

                if (whitelistKeys.indexOf(url) > -1) {
                    convertToLocal(whitelist[url], response.data);
                } else {
                    var regexMatch = regexWhitelist.find((x) => x.rx.test(url));
                    if (regexMatch) {
                        convertToLocal(regexMatch.props, response.data);
                    }
                }

                response.config.headers.pragma = undefined;

                return response;
            };

            function convertToLocal(keys, input) {
                if (!angular.isObject(input)) {
                    return;
                }

                angular.forEach(input, function (value, key) {
                    if (keys.indexOf(key) > -1) {
                        // Convert UTC to local time for the current organisation.
                        if (input[key] === null) {
                            input[key.slice(0, -3)] = null;
                            delete input[key];
                            return;
                        }

                        // handle arrays of dates
                        if (angular.isArray(input[key])) {
                            var outputArray = (input[key.slice(0, -3)] = []);
                            angular.forEach(input[key], function (date) {
                                var result = utcToOrgTimeString(date);

                                outputArray.push(result);
                            });
                        } else {
                            input[key.slice(0, -3)] = utcToOrgTimeString(input[key]);
                        }

                        delete input[key];
                    } else if (angular.isObject(value)) {
                        convertToLocal(keys, value);
                    }
                });
            }

            function convertToUTC(keys, input) {
                if (!angular.isObject(input)) {
                    return;
                }

                angular.forEach(input, function (value, key) {
                    if (keys.indexOf(key) > -1) {
                        if (key == 'orderBy') {
                            // special case for orderBy, do not convert property name to orderByUtc
                            // instead, append Utc to property value if that value is in whitelist.
                            var orderBy = input['orderBy'];
                            if (orderBy && keys.indexOf(orderBy) > -1) {
                                input['orderBy'] = orderBy + 'Utc';
                            }
                            return;
                        }

                        // Convert local time to UTC for the current organisation.
                        if (!input[key]) {
                            input[key + 'Utc'] = null;
                            delete input[key];
                            return;
                        }

                        var isoDate = input[key];
                        if (isoDate instanceof Date) {
                            isoDate = input[key].toISOString();
                        } else if (moment.isMoment(input[key])) {
                            isoDate = input[key].format('YYYY-MM-DDTHH:mm:ss');
                        }

                        // remove trailing Z otherwise Web API controllers will automatically apply time offset to convert to a local time
                        isoDate = isoDate.replace('Z', '');

                        // console.log('Converting ' + isoDate + ' to UTC');
                        input[key + 'Utc'] = utcToOrgTimeString(isoDate, true);
                        delete input[key];
                    } else if (angular.isObject(value)) {
                        convertToUTC(keys, value);
                    }
                });
            }

            function utcToOrgTimeString(inputDate, isRevert = false) {
                if (!inputDate.endsWith('Z')) {
                    inputDate += 'Z';
                }
                let offset = dateTextService.getOrgTimezoneText(new Date(inputDate));

                if (isRevert) {
                    const init = offset[0];
                    const sign = init === '-' ? '+' : '-';
                    offset = sign + offset.slice(1);
                }

                return $filter('date')(
                    inputDate,
                    'yyyy-MM-ddTHH:mm:ss',
                    offset
                );
            }
        },
    ]);
