'use strict';

angular.module('shared.components.sbTimePicker', [
    'shared.services.cachedLookupService'
]).component('sbTimePicker',
    {
        bindings: {
            // object { h: x, m: y }, Model Hour and Minute as object.
            modelHourMinute: '=',
            // object { h: x, m: y }, only allows values greater than or equal to this value
            minHourMinute: '<',
            // object { h: x, m: y }, only allows values less than or equal to this value
            maxHourMinute: '<',
            // increment between values in the minutes selection.
            minuteStep: '<',
            required: '<',
            readonly: '<',
            disabled: '<',
            hoursOptionCount: '<',
            onChange: '&'
        },
        templateUrl: '/Scripts/app/shared/components/sb-time-picker.template.html',
        controller:
            function () {

                this.hourIsDisabled = function (hour) {
                    return hour > -1 && (hour < this.minHour || hour > this.maxHour);
                }

                this.minuteIsDisabled = function (minute) {
                    return minute > -1 && (minute < this.minMinute || minute > this.maxMinute);
                }

                this.$onInit = function () {

                    var i = 0;
                    this.minutes = [];
                    this.hours = [];
                    this.hoursOptionCount = this.hoursOptionCount || 24;

                    this.modelHour = null;
                    this.modelMinute = null;

                    this.minHour = 0;
                    this.maxHour = this.hoursOptionCount - 1;
                    this.minMinute = -1;
                    this.maxMinute = 60;

                    for (i = 0; i < this.hoursOptionCount; i++) {
                        // populate hours, padding with leading zero.
                        this.hours.push({ id: i, label: ('0' + i).slice(-2) })
                    }

                    if (typeof this.minuteStep === 'undefined') {
                        // Default minute step to single increment.
                        this.minuteStep = 1;
                    }

                    if (typeof this.required === 'undefined') {
                        // Required by default.
                        this.required = true;
                    }

                    if (typeof this.modelHourMinute === 'undefined') {
                        // Default to unselected, else 00:00.
                        this.modelHourMinute = this.required
                            ? { h: 0, m: 0}
                            : { h: -1, m: -1};
                    }

                    for (i = 0; i < 60; i += this.minuteStep) {
                        // populate minutes, padding with leading zero.
                        this.minutes.push({ id: i, label: ('0' + i).slice(-2) })
                    }

                    if (!this.required) {
                        this.hours.unshift({ id: -1, label: ('  ')});
                        this.minutes.unshift({ id: -1, label: ('  ')});
                    }
                };

                // Fired on digest cycle.
                this.$doCheck = function ()
                {
                    if (typeof this.modelHourMinute !== 'undefined' &&
                        this.modelHourMinute !== null &&
                        (this.modelHour === null ||
                            this.modelMinute === null ||
                            this.modelHour.id !== this.modelHourMinute.h ||
                            this.modelMinute.id != this.modelHourMinute.m))
                    {
                        // External change to model value.
                        this.modelHour = { id: this.modelHourMinute.h };
                        this.modelMinute = { id: this.modelHourMinute.m };
                        this.handleChange();
                    }
                };

                // Fired on any change to time selection.
                this.handleChange = function ()
                {
                    if (!this.modelHour || !this.modelMinute)
                    {
                        // not ready yet
                        return;
                    }

                    // Update external model values.
                    this.modelHourMinute = { h: this.modelHour.id, m: this.modelMinute.id };

                    // Make time adjustments if necessary (due to min/max constraint)
                    if (typeof this.minHourMinute !== 'undefined' && this.minHourMinute !== null)
                    {
                        this.checkValuesWithinMin();
                        this.updateDisabledHoursMin();
                    }

                    if (typeof this.maxHourMinute !== 'undefined' && this.maxHourMinute !== null)
                    {
                        this.checkValuesWithinMax();
                        this.updateDisabledHoursMax();
                    }

                    if (typeof this.onChange() !== 'undefined' && this.modelHourMinute)
                    {
                        this.onChange()();
                    }
                }

                // Updates the range of disabled hours and minutes due to minimum constraint.
                this.updateDisabledHoursMin = function () {
                    if (!this.modelHourMinute) {
                        // not ready yet
                        return;
                    }

                    // Disable hours under min hours.
                    this.minHour = this.minHourMinute.h;

                    // If hours and minimum hours are the same,
                    // disable minutes outside minimum minutes.
                    if (this.modelHourMinute.h === this.minHourMinute.h) {
                        this.minMinute = this.minHourMinute.m;
                    }
                    else {
                        this.minMinute = -1;
                    }
                }

                // Updates the range of disabled hours and minutes due to maximum constraint.
                this.updateDisabledHoursMax = function () {
                    if (!this.modelHourMinute) {
                        // not ready yet
                        return;
                    }

                    // Disable hours over max hours.
                    this.maxHour = this.maxHourMinute.h;

                    // If hours and maximum hours are the same,
                    // disable minutes outside maximum minutes.
                    if (this.modelHourMinute.h === this.maxHourMinute.h) {
                        this.maxMinute = this.maxHourMinute.m;
                    }
                    else {
                        this.maxMinute = 60;
                    }
                }

                this.checkValuesWithinMin = function () {
                    var dirty = false;

                    if (this.modelHour && this.modelMinute) {
                        // Minimum may exceed current value, in which case we set our value to minimum.
                        if (this.modelHourMinute.h < this.minHourMinute.h) {
                            this.modelHour = { id: this.minHourMinute.h };
                            dirty = true;
                        }

                        if (this.modelHourMinute.h <= this.minHourMinute.h &&
                            this.modelHourMinute.m < this.minHourMinute.m) {
                            this.modelMinute = { id: this.minHourMinute.m };
                            dirty = true;
                        }

                        if (dirty) {
                            this.modelHourMinute = { h: this.modelHour.id, m: this.modelMinute.id };
                        }
                    }
                }

                this.checkValuesWithinMax = function () {
                    var dirty = false;

                    if (this.modelHour && this.modelMinute) {
                        // Maximum may be under current value, in which case we set our value to maximum.
                        if (this.modelHourMinute.h > this.maxHourMinute.h) {
                            this.modelHour = { id: this.maxHourMinute.h };
                            dirty = true;
                        }

                        if (this.modelHourMinute.h >= this.maxHourMinute.h &&
                            this.modelHourMinute.m > this.maxHourMinute.m) {
                            this.modelMinute = { id: this.maxHourMinute.m };
                            dirty = true;
                        }

                        if (dirty) {
                            this.modelHourMinute = { h: this.modelHour.id, m: this.modelMinute.id };
                        }
                    }
                }

                // Fired on any changes to one-way bindings.
                this.$onChanges = function (changes) {
                    if (changes.minHourMinute && changes.minHourMinute.currentValue) {
                        this.checkValuesWithinMin();
                        this.updateDisabledHoursMin();
                    }

                    if (changes.maxHourMinute && changes.maxHourMinute.currentValue) {
                        this.checkValuesWithinMax();
                        this.updateDisabledHoursMax();
                    }
                };
            }
    });