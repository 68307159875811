angular.module('tuitionFeesModule')
    .component('addToCycle', {
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/add-to-cycle.template.html',
        controller: class AddToCycleCtrl {

            // Dependencies
            billingCycleService: any;
            payerTypesEnum: any;

            // Bindings
            billingCycleIdFn: () => number;
            billingCycleRefreshFn: () => () => void;

            // Variables
            productsModel: any = {};
            studentsModel: any = {};
            payerModel: any = {};
            currencyDisplaySymbol: string;
            currencyExponent: number;
            isBatchSubmitted: boolean = false;
            locationChangeStartListener: any; // deregistration function for listener.

            static $inject = ['$rootScope', '$window', '$state', 'billingCycleService', 'payerTypesEnum'];

            constructor($rootScope, $window, $state, billingCycleService, payerTypesEnum) {
                this.billingCycleIdFn = () => $state.params.billingCycleId;

                // In case they went straight to this nested page, billing cycle id fn is not available.
                // Go back to parent so this fn is set-up correctly.
                if (!this.billingCycleIdFn()) {
                    $state.go('tuitionFees.billingCycle');
                }

                this.billingCycleRefreshFn = () => $state.params.billingCycleRefreshFn;
                this.billingCycleService = billingCycleService;
                this.payerTypesEnum = payerTypesEnum;
                this.payerModel = {
                    payerTypeId1: payerTypesEnum.Family.id,
                    isEditing: true
                }

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;

                $window.onbeforeunload = function (event) {
                    // show unload warning if one or more students/products are selected.
                    if (!this.checkPendingChanges()) {
                        return '';
                    }

                    event.preventDefault();

                    // NB: including custom string here even though browsers have not been displaying them for some time now.
                    return event.returnValue = 'Any unsaved batch students/products will be lost. Are you sure?';
                }.bind(this);

                // Clear the unload when navigating away from change.
                this.locationChangeStartListener = $rootScope.$on('$locationChangeStart', function () {
                    $window.onbeforeunload = null;
                });
            }

            checkPendingChanges() {
                if (!this.productsModel.selectedProducts || !this.studentsModel.selectedStudents) {
                    return false;
                }

                return this.studentsModel.selectedStudents.length > 0 || this.productsModel.selectedProducts.length > 0;
            }

            selectionsInvalid() {
                if (typeof this.billingCycleIdFn != 'function') {
                    return true;
                }

                let billingCycleId = this.billingCycleIdFn();
                if (!billingCycleId || billingCycleId == 0) {
                    return true;
                }

                if (!this.productsModel.selectedProducts || !this.studentsModel.selectedStudents) {
                    return true;
                }

                // one or more discounts are invalid.
                if (this.productsModel.selectedProducts.some(x => typeof x.discount == 'undefined' || (x.discount && x.discount > x.price))) {
                    return true;
                }

                return this.studentsModel.selectedStudents.length == 0 || this.productsModel.selectedProducts.length == 0;
            }

            billingTotal() {
                if (this.selectionsInvalid()) {
                    return 0;
                }

                let v = 0;
                let productTotal = this.productsModel.selectedProducts.reduce((a, c) => a + (c.price - (c.discount || 0)), v);
                return productTotal * this.studentsModel.selectedStudents.length;
            }

            applyDisabled() {
                // Now we allow items with negative product value, billing total could be negative. We won't allow this.
                return this.selectionsInvalid() || this.billingTotal() < 0;
            }

            saveBatch() {
                // resolve selected payer
                this.studentsModel.selectedStudents.forEach(x => {
                    let payer = x.payers.find(y => y.id == x.payerId);
                    if (payer) {
                        x.payerIsPersonBillingContact = payer.isPersonBillingContact;
                    }
                });

                let dto = {
                    isCorporatePayer: this.payerModel.payerTypeId1 == this.payerTypesEnum.Corporate.id,
                    students: this.studentsModel.selectedStudents.map(x => ({ id: x.id, payerId: x.payerId, payerIsPersonBillingContact: x.payerIsPersonBillingContact })), // isPersonBillingContact not set for corporate payers.
                    products: this.productsModel.selectedProducts.map(x => ({ billingProductId: x.id, discount: parseFloat(x.discount) }))
                };

                this.billingCycleService.saveBatch(this.billingCycleIdFn(), dto).then(() => {
                    this.isBatchSubmitted = false;

                    this.productsModel.selectedProducts?.forEach(x => { x.isSelected = false; });
                    this.productsModel = {};

                    this.studentsModel.selectedStudents?.forEach(x => { x.isSelected = false; });
                    this.studentsModel = {};

                    if (typeof this.billingCycleRefreshFn() === 'function') {
                        this.billingCycleRefreshFn()();
                    }
                }).catch(() => {
                    this.isBatchSubmitted = false;
                });
            }
        }
    });
