'use strict';

angular.module('profile.services.idpExternalService', [])
    .factory('idpExternalService', [
        '$http',
        function ($http) {

            var service = this;
            var urlBase = window.EveryBuddy.IdUrl + "/api/UserExternalProvider";

            service.getUsersSSOProviderOptions = function (email) {
                return $http.get(urlBase,
                    {
                        withCredentials: true,
                        params:
                        {
                            ServerId: window.EveryBuddy.ServerId,
                            OrganisationId: window.EveryBuddy.CurrentOrganisationId,
                            Email: email
                        }
                    }).then(function (response) {
                        return response.data;
                    });

            };

            service.removeUserSSOLink = function ( requestDto ) {
                return $http.post(urlBase + '/RemoveExternalCallback', requestDto, { withCredentials: true})
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.hasSingleSignOnEnabled = function () {
                return $http.get(urlBase + '/GetSingleSignOnEnabled',
                    {
                        params:
                        {
                            ServerId: window.EveryBuddy.ServerId,
                            OrganisationId: window.EveryBuddy.CurrentOrganisationId
                        },
                        withCredentials: true,
                        cache : true
                    }).then(function (response) {
                        return response.data;
                    });
            }

            return service;

        }]);
