angular.module('shared.services.cachedLookupService', [])
    .factory('cachedLookupService', [
        '$http', '$q', '$window', '$cacheFactory',
        function ($http, $q, $window, $cacheFactory) {
            var urlBase = '/webapi/WebSystem/';
            var settingUrlBase = '/webapi/WebSettings/';
            var repo: any = {};

            var httpCache = $cacheFactory('cachedLookupServiceCache');

            var valueCache = {};

            /// valueName = ...
            /// valueSetPrefix = to prevent namespace clashes- what if two services have the same property name?  this fixes that
            /// callback = if the value isn't in the cache, where do I find it?
            var getCacheValue = function (valueName, valueSetPrefix, callback) {
                if (valueCache.hasOwnProperty(valueSetPrefix + '.' + valueName)) {
                    return $q.when(valueCache[valueSetPrefix + '.' + valueName]);
                }
                else {
                    return callback().then(function (response) {
                        for (var key in response.data) {
                            // copy everything into the cache

                            valueCache[valueSetPrefix + '.' + key] = response.data[key];
                        }

                        return valueCache[valueSetPrefix + '.' + valueName];
                    });
                }
            };

            var getValueListFromValueCache = function (valueNameArray, valueSetPrefix) {
                var values = {};

                for (var i = valueNameArray.length; i--;) {
                    var valueName = valueNameArray[i];

                    values[valueName] = valueCache[valueSetPrefix + '.' + valueName];
                }

                return values;
            };

            var getCacheValues = function (valueNames, valueSetPrefix, callback) {
                // check the collection is actually loaded
                var collectionLoaded = false;
                for (var key in valueCache) {
                    if (valueCache.hasOwnProperty(key) && key.indexOf(valueSetPrefix + '.') > -1) {
                        collectionLoaded = true;
                        break;
                    }
                }

                if (!collectionLoaded) {
                    return callback().then(function (response) {
                        for (var key in response.data) {
                            // copy everything into the cache

                            valueCache[valueSetPrefix + '.' + key] = response.data[key];
                        }

                        var values = getValueListFromValueCache(valueNames, valueSetPrefix);

                        return values;
                    });
                }
                else {
                    var values = getValueListFromValueCache(valueNames, valueSetPrefix);

                    return $q.when(values);
                }
            };

            repo.getSystemSetting = function (settingName) {

                return getCacheValue(settingName,
                    'GetAllSettings',
                    function () { return $http.get(settingUrlBase + 'GetAllSettings', { cache: httpCache }); });
            };

            repo.getSystemSettings = function (settingNamesArray) {
                return getCacheValues(settingNamesArray,
                    'GetAllSettings',
                    function () { return $http.get(settingUrlBase + 'GetAllSettings', { cache: httpCache }); });
            };

            repo.saveUserSettings = function (newSettings) {

                var settingName = null;
                // figure out which ones have changed?
                var valueSetPrefix = 'GetAllSettings';

                for (settingName in newSettings) {

                    if (newSettings.hasOwnProperty(settingName) && valueCache[valueSetPrefix + '.' + settingName] && valueCache[valueSetPrefix + '.' + settingName].toString() === newSettings[settingName].toString()) {
                        delete newSettings[settingName];
                    }
                }

                var hasProperties = false;
                for (settingName in newSettings) {
                    if (newSettings.hasOwnProperty(settingName)) {
                        hasProperties = true;
                        break;
                    }
                }

                if (!hasProperties) {
                    console.warn('no settings to update');
                    return;
                }

                // set the new properties early to try and prevent any weird race conditions
                for (var currentSettingName in newSettings) {

                    valueCache[valueSetPrefix + '.' + currentSettingName] = newSettings[currentSettingName];
                }

                // at this point we should just be posting whats changed
                $http.post(settingUrlBase + 'SaveUserSettings', newSettings)
                    .then(function () {
                        //need to delete service get settings endpoint from the cache
                        //bit draconian to remove all settings but the cache
                        //is per url
                        httpCache.remove(settingUrlBase + 'GetAllSettings');
                    });
            };

            // we'll cache all user roles
            var allRoles = [
                'isStaff', 'isParent', 'isPupil', 'isTeacher', 'isExternal', 'isAdmin', 'isOrganisationSuperAdmin',
                'isFeesAdmin', 'isAdvancedFeeAdmin', 'isCommunicationAdmin', 'isCCAAdmin', 'isAbsenteeAlerts',
                'isMembership', 'isNewRegistrations', 'isTransportAdmin', 'isTransportAdvancedAdmin', 'isTransportBusMonitor'
            ];

            var transportArchiveRoles = ['isOrganisationSuperAdmin', 'isTransportAdvancedAdmin'];

            repo.getRoles = function () {
                return getCacheValues(allRoles,
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.getUserAccessRoles = () => {
                var userRoles: unknown[] = [];
                var memberEnum = $window.EveryBuddy.Enums.MemberType;
                return repo.getRoles().then(roles => {
                    if (roles.isStaff) {
                        userRoles.push(memberEnum.Staff);
                    }
                    if (roles.isParent) {
                        userRoles.push(memberEnum.Parents);
                    }
                    if (roles.isPupil) {
                        userRoles.push(memberEnum.Pupils);
                    }
                    return userRoles;
                });
            };

            repo.isStaff = function () {
                return getCacheValue('isStaff',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            var externalRoles = ['isExternal', 'isTransportBusMonitor'];

            repo.isExternal = function () {
                return getCacheValues(externalRoles,
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); })
                    .then(function (currentPerson) {
                        return currentPerson.isExternal || currentPerson.isTransportBusMonitor;
                    });
            };

            repo.isPupil = function () {
                return getCacheValue('isPupil',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.isParent = function () {
                return getCacheValue('isParent',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.isOrganisationAdmin = function () {
                return getCacheValue('isAdmin',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.isTeacher = function () {
                return getCacheValue('isTeacher',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.isOrganisationSuperAdmin = function () {
                return getCacheValue('isOrganisationSuperAdmin',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.isTransportAdvancedAdmin = function () {
                return getCacheValue('isTransportAdvancedAdmin',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.isOrganisationSuperAdminOrTransportAdvancedAdmin = function () {
                return getCacheValues(transportArchiveRoles,
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); })
                    .then(function (data) {
                        var canArchive: any = false;
                        angular.forEach(transportArchiveRoles, function (transportArchiveRole) {
                            canArchive |= data[transportArchiveRole];
                        });
                        return canArchive;
                    });
            };

            repo.isTransportBusMonitor = function () {
                return getCacheValue('isTransportBusMonitor',
                    'GetCurrentPersonRoles',
                    function () { return $http.get(urlBase + 'GetCurrentPersonRoles', { cache: httpCache }); });
            };

            repo.getNameEmail = function () {
                return getCacheValues(['personId', 'name', 'emailAddress'],
                    'GetCurrentPersonNameEmail',
                    function () { return $http.get(urlBase + 'GetCurrentPersonNameEmail', { cache: httpCache }); });
            };

            repo.currentPersonId = function () {
                return getCacheValue('personId',
                    'GetCurrentPersonNameEmail',
                    function () { return $http.get(urlBase + 'GetCurrentPersonNameEmail', { cache: httpCache }); });
            };

            repo.fullName = function () {
                return getCacheValue('name',
                    'GetCurrentPersonNameEmail',
                    function () { return $http.get(urlBase + 'GetCurrentPersonNameEmail', { cache: httpCache }); });
            };

            repo.emailAddress = function () {
                return getCacheValue('emailAddress',
                    'GetCurrentPersonNameEmail',
                    function () { return $http.get(urlBase + 'GetCurrentPersonNameEmail', { cache: httpCache }); });
            };

            repo.siteInstance = function () {
                return getCacheValue('siteName',
                    'GetSiteInstance',
                    function () { return $http.get(urlBase + 'GetSiteInstance', { cache: httpCache }); });
            };

            repo.getGroupFeature = function (featureName) {
                return getCacheValue(featureName, 'WebTeam',
                    function () {
                        return $http.get('/webapi/WebTeam/GetTabVisibility', { cache: httpCache });
                    });
            };

            repo.balanceAvailable = function () {
                return getCacheValue('balanceAvailable',
                    'SystemFunctions',
                    function () {
                        return $http.get(urlBase + 'OrganisationFlags', { cache: httpCache });
                    });
            };

            repo.bookingsOpen = function () {
                return getCacheValue('bookingsOpen',
                    'SystemFunctions',
                    function () {
                        return $http.get(urlBase + 'OrganisationFlags', { cache: httpCache });
                    });
            };

            repo.currencyDisplaySymbol = function () {
                return getCacheValue('currencyDisplaySymbol',
                    'SystemFunctions',
                    function () {
                        return $http.get(urlBase + 'OrganisationFlags', { cache: httpCache });
                    });
            };

            repo.isoCurrency = function () {
                return getCacheValue('isoCurrency',
                    'SystemFunctions',
                    function () {
                        return $http.get(urlBase + 'OrganisationFlags', { cache: httpCache });
                    });
            };

            repo.organisationTypeId = function () {
                return getCacheValue('organisationTypeId',
                    'SystemFunctions',
                    function () {
                        return $http.get(urlBase + 'OrganisationFlags', { cache: httpCache });
                    });
            };

            repo.canSaveProfile = function (person, loggedInPersonId, seenInMIS, loggedInUserIsParentOfUser, allowEditSelf) {
                return $q(function (resolve) {
                    var promises = [];

                    promises.push(repo.isOrganisationSuperAdmin());
                    promises.push(repo.organisationTypeId());

                    $q.all(promises).then(function (responses) {

                        var isSuperAdmin = responses[0];
                        var organisationTypeId = responses[1];
                        var isEditingSelf = repo.isEditingSelf(person, loggedInPersonId);

                        if (!person) {
                            resolve(false);
                        }

                        if (allowEditSelf && isEditingSelf && !seenInMIS && person.isStaff) {
                            resolve(true);
                        }

                        if (isSuperAdmin && !person.isStaff && (person.isPupil || person.isParent)) {
                            resolve(true);
                        }

                        if (organisationTypeId == $window.EveryBuddy.Enums.OrganisationTypes.School) {
                            resolve(false);
                        }

                        // clubs - allow parent to edit self or their child
                        if (allowEditSelf && ((isEditingSelf && person.isParent) || loggedInUserIsParentOfUser)) {
                            resolve(true);
                        }

                        resolve(false);
                    }, function (error) {
                        console.error(`CanSaveProfile error ${error}`);
                    });
                });
            };

            repo.isEditingSelf = function (person, loggedInPersonId) {
                if (!person || !person.personData) {
                    return false;
                }

                return loggedInPersonId === person.personData.id;
            };

            return repo;

        }
    ]);
