'use strict';

angular.module('transport.configuration.transportMethodEdit',
    [
        'transport.services.transportConfigurationMethodService',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportMethodEdit',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/configuration/methods/transport-method-edit.template.html',
            controller: [
                '$scope',
                '$timeout',
                '$uibModal',
                'cachedLookupService',
                'transportConfigurationMethodService',
                'simpleAlertService',
                'iconService',
                function transportMethodEditController($scope,
                                                       $timeout,
                                                       $uibModal,
                                                       cachedLookupService,
                                                       transportConfigurationMethodService,
                                                       simpleAlertService,
                                                       iconService)
                {
                    this.isSubmitted = false;
                    this.isArchiveOptionAvailable = false;
                    this.numberOfTimetablesWhereUsed = 0;

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportMethod = {};
                    this.transportMethodArchivingInfo = {};

                    this.sbTransportIcons = iconService.getTransportIcons();

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            var params = this.resolve.params;
                            this.transportMethod = params.transportMethod;
                            this.loadTransportMethod(this.transportMethod);
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.methodIconName = '';

                    this.loadTransportMethod = function (transportMethod)
                    {
                        transportConfigurationMethodService.getTransportMethod(transportMethod.id)
                            .then(function (responseData)
                            {
                                this.transportMethod = responseData;
                                this.methodIconName = iconService.getIcon(this.transportMethod.sbIconName);

                                transportConfigurationMethodService.getTransportMethodArchivingInfo(transportMethod.id)
                                    .then(function (responseData)
                                    {
                                        this.transportMethodArchivingInfo = responseData;

                                        this.transportMethodArchivingInfo.timetableCalendarsWhereUsed.forEach(function (calendar)
                                        {
                                            this.numberOfTimetablesWhereUsed += calendar.timetables.length;
                                        }.bind(this));

                                        cachedLookupService.isOrganisationSuperAdminOrTransportAdvancedAdmin()
                                            .then(function (response)
                                            {
                                                this.isArchiveOptionAvailable = response == 1;
                                            }.bind(this))
                                            .catch(function (responseData)
                                            {
                                                var message = {};

                                                if (responseData && responseData.data && responseData.data.Message)
                                                {
                                                    message = { message: responseData.data.Message };
                                                }

                                                alertAndResetOkButton(message);
                                            }.bind(this));

                                    }.bind(this))
                                    .catch(function (responseData)
                                    {
                                        var message = {};

                                        if (responseData && responseData.data && responseData.data.Message)
                                        {
                                            message = { message: responseData.data.Message };
                                        }

                                        alertAndResetOkButton(message);
                                    }.bind(this))

                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            }.bind(this));
                    }.bind(this);

                    this.saveTransportMethod = function ()
                    {
                        var saveRequest =
                        {
                            id: this.transportMethod.transportMethodId,
                            name: this.transportMethod.name,
                            active: this.transportMethod.active,
                            sbIconName: this.transportMethod.sbIconName,
                            supportRoutes: this.transportMethod.supportRoutes,
                            alwaysOfferChangeRequest: this.transportMethod.alwaysOfferChangeRequest
                        };

                        transportConfigurationMethodService.editTransportMethod(saveRequest)
                            .then(function (responseData)
                            {
                                if (responseData && responseData.name)
                                {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Saved',
                                        message: 'SB_Transport_Method_Saved',
                                        messageTranslationParameters: { transportMethod: responseData.name },
                                        staticBackdrop: true
                                    });

                                    closeModal();
                                }
                                else
                                {
                                    alertAndResetOkButton();
                                }
                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            }.bind(this));
                    }.bind(this);

                    $scope.$watch('$ctrl.transportMethod["sbIconName"]',
                        function (newValue, oldValue)
                        {
                            if (newValue === oldValue)
                            {
                                return;
                            }

                            if (typeof newValue !== 'undefined')
                            {
                                this.methodIconName = iconService.getIcon(newValue);
                            }
                        }.bind(this), true);

                    this.selectIcon = function(iconName)
                    {
                        this.transportMethod.sbIconName = iconName;
                        this.iconSelectOpen = false;
                    }.bind(this);

                    this.archiveTransportMethod = function ()
                    {
                        var transportItemNameToArchive = this.transportMethod.name;

                        var confirmArchiveModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportConfirmArchiving',
                                size: 'md',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return { transportItemNameToArchive: transportItemNameToArchive };
                                    }
                                }
                            });

                        confirmArchiveModalInstance.result
                            .then(
                                () => {
                                    transportConfigurationMethodService.archiveMethod(this.transportMethod)
                                        .then(function (responseData)
                                        {
                                            if (responseData && responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Transport_Method_Archived',
                                                    message: 'SB_Transport_Method_Archived',
                                                    staticBackdrop: true
                                                });

                                                closeModal();
                                            }
                                            else if (responseData && !responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Error',
                                                    message: 'SB_Transport_Archive_Operation_Failed',
                                                    staticBackdrop: true
                                                });

                                                closeModal();
                                            }
                                            else
                                            {
                                                alertAndResetOkButton();
                                            }
                                        })
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        });

                                    resetSubmitButtons();
                                },
                                () => resetSubmitButtons()
                            );
                    }.bind(this);
                }
            ]
        });
