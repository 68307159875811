angular.module('balance.components.currentUserTransactionViewUibContainer',
    [
        'balance.components.currentUserTransactionView',
        'shared.components.sbModalHeader',
        'angularjs-dropdown-multiselect',
    ])
    .component('currentUserTransactionViewUibContainer',
        {
            bindings: {
                resolve: '<',
                close: '&'
            },
            templateUrl: '/Scripts/app/balance/components/current-user-transaction-view-uib-container.template.html',
            controller: class CurrentUserTransactionViewUibContainerCtrl {

                accountTypeId: number;

                constructor() {
                    this.accountTypeId = 0;
                }

                $onChanges(changes) {
                    if (changes.resolve && changes.resolve.currentValue) {
                        this.accountTypeId = changes.resolve.currentValue.accountTypeId;
                    }
                };

                close () {
                    this.close();
                };
            }
        });