'use strict';

angular.module('shared.components.sbAvatar', [
    'shared.services.imageService',
    'shared.services.blobStoragePhotosService',
]).component('sbAvatar',
    {
        restrict: 'E',
        bindings: {
            personId: '<',
            photoUrl: '<',
            fullName: '<',
            firstName: '<',
            lastName: '<',
            isLoading: '<',
            isSoloPhoto: '<',
            noAction: '<',
            initialProfileRoute: '@',
            size: '@',
            onClose: '&',
            onClick: '&',
        },
        templateUrl: '/Scripts/app/shared/components/sb-avatar.template.html',
        controllerAs: 'ctrl',
        controller: ['$uibModal', '$scope','imageService', 'blobStoragePhotosService', 'cachedLookupService', 'toastService',
            function ($uibModal, $scope, imageService, blobStoragePhotosService, cachedLookupService, toastService) {

                this.$onInit = function() {
                    cachedLookupService.isExternal().then(value => {
                        this.isExternal = value;
                    });

                    this.imgUrlFound = false;

                    if ((!this.firstName || !this.lastName) && this.fullName) {
                        var splitName;
                        if (this.fullName.includes(',')){
                            // handle case of format: [last name], [first names]
                            splitName = this.fullName.split(', ');
                            this.firstName = splitName[1];
                            this.lastName = splitName[0];
                        } else {
                            // handle case of format: [first names] [last name]
                            splitName = this.fullName.split(' ');
                            this.firstName = splitName[0];
                            this.lastName = splitName[splitName.length - 1];
                        }
                    }

                }.bind(this);

                this.$onChanges = function (changes)
                {
                    // If photo not already found or new user
                    if (!this.imgUrlFound || (changes.personId && changes.personId.currentValue))
                    {
                        // If a photourl from bulk (not solo) is not being passed in: look up photoUrl from personId, then check it
                        if (this.isSoloPhoto && Number.isInteger(this.personId))
                        {
                            this.imgUrlFound = false;
                            blobStoragePhotosService.getPhotoUrlsForStudents([this.personId]).then(function (data)
                            {
                                if (data.photosUrlsForStudents[0] && data.photosUrlsForStudents[0].thumbnail)
                                {
                                    this.photoUrl = this.size == 'xl' ? data.photosUrlsForStudents[0].oneHundredPx : data.photosUrlsForStudents[0].thumbnail;
                                    this.checkImage();
                                }
                            }.bind(this));
                        } else if (typeof this.photoUrl !== 'undefined' && this.photoUrl != '') // Else check the photoUrl being passed in (if ready)
                        {
                            this.checkImage();
                        }
                    }
                }.bind(this);

                this.checkImage = function ()
                {
                    imageService.isImage(this.photoUrl).then(function (isFound)
                    {
                        this.imgUrlFound = isFound;
                    }.bind(this));
                }.bind(this);

                this.profilePopup = function () {

                    if (this.isExternal === undefined) {
                        // Edge case user has clicked before isExternal cache loaded
                        return;
                    }

                    if (this.isExternal === 1) {
                        // Block external user from viewing pupil profile
                        toastService.warning('SB_Access_Restricted');
                        return;
                    }

                    if (this.personId && !this.noAction) {

                        // These are displayed in template and extracted by the Angular 'interceptor' profile-popup.component.ts
                        $scope.subPersonId = this.personId;
                        $scope.initialRoute = this.initialProfileRoute;

                        // pull a full instance of the profile screen into a popup!
                        var modalInstance = $uibModal
                            .open({
                                animation: true,
                                templateUrl: '/Scripts/app/profile/controllers/profile-popup.template.html',
                                controller: 'profilePopupController',
                                size: 'lg',
                                scope: $scope
                            });

                        modalInstance.result.then(function() {
                            this.handlePopupClosed();
                        }.bind(this), function() {
                            this.handlePopupClosed();
                        }.bind(this));
                    }
                }.bind(this);

                this.handlePopupClosed = function () {
                    if (typeof this.onClose() === 'function') {
                        this.onClose()(this.personId);
                    }
                }.bind(this);

         }]
    });