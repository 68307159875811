'use strict';

angular.module('transport.configuration.transportStopEdit',
    [
        'shared.components.sbIcon',
        'shared.components.sbOnOffSwitch',
        'shared.components.sbModalHeader',
        'shared.services.simpleAlertService',
        'transport.services.transportConfigurationStopsService'
    ])
    .component('transportStopEdit',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/configuration/stops/transport-stop-edit.template.html',
            controller: [
                '$window',
                '$timeout',
                '$uibModal',
                'cachedLookupService',
                'transportConfigurationStopsService',
                'simpleAlertService',
                'moduleService',
                function transportStopEditController($window, $timeout, $uibModal, cachedLookupService, transportConfigurationStopsService, simpleAlertService, moduleService)
                {
                    this.$window = $window;
                    this.isSubmitted = false;
                    this.showRoutesWarning = false;
                    this.isArchiveOptionAvailable = false;
                    this.isGPStrackingEnabled = false;

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportStop = {};
                    this.transportStopArchivingInfo = {};
                    this.markerPins = [];
                    this.mapHome = null;

                    this.$onInit = function () {
                        moduleService.getModules(this.$window.EveryBuddy.CurrentOrganisationId)
                            .then(function (responseData)
                            {
                                this.isGPStrackingEnabled = responseData.transportModule.isGPSTrackingEnabled;
                                if(responseData.transportModule.defaultLatitude) {
                                    this.mapHome = {
                                        lat: responseData.transportModule.defaultLatitude,
                                        lng: responseData.transportModule.defaultLongitude,
                                        zoom: 15
                                    }
                                }
                            }.bind(this)
                        );
                    }.bind(this);

                        this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            var params = this.resolve.params;
                            this.loadTransportStop(params.transportStop);
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.loadTransportStop = function (transportStop)
                    {
                        transportConfigurationStopsService.getTransportStop(transportStop.id)
                            .then(function (responseData)
                            {
                                this.transportStop = responseData;
                                this.updateMap();

                                transportConfigurationStopsService.getTransportStopArchivingInfo(transportStop.id)
                                    .then(function (responseData)
                                    {
                                        this.transportStopArchivingInfo = responseData;

                                        cachedLookupService.isOrganisationSuperAdminOrTransportAdvancedAdmin()
                                            .then(function (response)
                                            {
                                                this.isArchiveOptionAvailable = response == 1;
                                            }.bind(this))
                                            .catch(function (responseData)
                                            {
                                                var message = {};

                                                if (responseData && responseData.data && responseData.data.Message)
                                                {
                                                    message = { message: responseData.data.Message };
                                                }

                                                alertAndResetOkButton(message);
                                            }.bind(this));

                                    }.bind(this))
                                    .catch(function (responseData)
                                    {
                                        var message = {};

                                        if (responseData && responseData.data && responseData.data.Message)
                                        {
                                            message = { message: responseData.data.Message };
                                        }

                                        alertAndResetOkButton(message);
                                    }.bind(this))

                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            }.bind(this));
                    }.bind(this);

                    this.editTransportStop = function ()
                    {
                        transportConfigurationStopsService.editStop(this.transportStop)
                            .then(function (responseData)
                            {
                                if (responseData && responseData.name)
                                {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Saved',
                                        message: 'SB_Transport_Stop_Saved',
                                        messageTranslationParameters: { transportStop: responseData.name },
                                        staticBackdrop: true
                                    });

                                    closeModal();
                                }
                                else
                                {
                                    alertAndResetOkButton();
                                }
                            })
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });
                    }.bind(this);

                    this.archiveTransportStop = function ()
                    {
                        var transportItemNameToArchive = this.transportStop.name;

                        var confirmArchiveModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportConfirmArchiving',
                                size: 'md',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return { transportItemNameToArchive: transportItemNameToArchive };
                                    }
                                }
                            });

                        confirmArchiveModalInstance.result
                            .then(
                                () => {
                                    transportConfigurationStopsService.archiveStop(this.transportStop)
                                        .then(function (responseData)
                                        {
                                            if (responseData && responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Transport_Stop_Archived',
                                                    message: 'SB_Transport_Stop_Archived',
                                                    staticBackdrop: true
                                                });


                                                closeModal();
                                            }
                                            else if (responseData && !responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Error',
                                                    message: 'SB_Transport_Archive_Operation_Failed',
                                                    staticBackdrop: true
                                                });


                                                closeModal();
                                            }
                                            else
                                            {
                                                alertAndResetOkButton();
                                            }
                                        })
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        });

                                    resetSubmitButtons();
                                },
                                () => resetSubmitButtons()
                            );
                    }.bind(this);

                    this.setShowRoutesWarning = function ()
                    {
                        $timeout(function ()
                        {
                            this.showRoutesWarning = !this.transportStop.active && this.transportStopArchivingInfo.routesWhereUsed.length > 0;
                        }.bind(this), 200);

                    }.bind(this);

                    this.goToRoute = function (routeId)
                    {
                        var transportRoute =
                        {
                            id: routeId
                        };

                        var editRouteModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportRouteEdit',
                                size: 'lg',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return { transportRoute: transportRoute };
                                    }
                                }
                            });

                        editRouteModalInstance.result
                            .then(function ()
                            {
                                transportConfigurationStopsService.getTransportStopArchivingInfo(this.transportStop.id)
                                    .then(function (responseData)
                                    {
                                        this.transportStopArchivingInfo = responseData;

                                    }.bind(this))
                                    .catch(function (responseData)
                                    {
                                        var message = {};

                                        if (responseData && responseData.data && responseData.data.Message)
                                        {
                                            message = { message: responseData.data.Message };
                                        }

                                        alertAndResetOkButton(message);
                                    }.bind(this))
                            }.bind(this),
                            function ()
                            {
                            });
                    }

                    this.updateMap = function() {
                        if(this.transportStop.latitude && this.transportStop.longitude) {
                            this.markerPins = [{
                                id: this.transportStop.id,
                                lat: this.transportStop.latitude,
                                lng: this.transportStop.longitude,
                                draggable: true,
                            }];
                        }
                    }

                    this.onPinDrop = function(e) {
                        this.transportStop.latitude = Math.round(e.lat * 1000000)/1000000;
                        this.transportStop.longitude = Math.round(e.lng * 1000000)/1000000;
                        this.updateMap();
                    }

                }
            ]
        });
