'use strict';

angular.module('balanceAdmin.components.newTransactionUibContainer',
    [
        'balanceAdmin.components.newTransaction',
        'balanceAdmin.services.balanceAdminService',
        'balance.constants',
        'shared.components.sbModalHeader',
        'shared.services.simpleAlertService'
    ])
    .component('newTransactionUibContainer',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/components/new-transaction-uib-container.template.html',
            controller: [
                'financialTransactionTypeEnum',
                function (financialTransactionTypeEnum) {

                    this.accountTypeId = 0;
                    this.accountOwnerId = 0;
                    this.transactionType = 0;
                    this.calendarEventId = 0;
                    this.groupId = 0;
                    this.isTeam = false;
                    this.title = '';
                    this.debitTransactionVerb;
                    this.maximumAmount = 0;

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.accountTypeId = changes.resolve.currentValue.accountTypeId;
                            this.accountOwnerId = changes.resolve.currentValue.accountOwnerId;
                            this.transactionType = changes.resolve.currentValue.transactionType;
                            this.accountHolderName = changes.resolve.currentValue.accountHolderName;
                            this.calendarEventId = changes.resolve.currentValue.calendarEventId;
                            this.groupId = changes.resolve.currentValue.groupId;
                            this.isTeam = changes.resolve.currentValue.isTeam;
                            this.maximumAmount = changes.resolve.currentValue.maximumAmount;
                            this.allowOverdraft = changes.resolve.currentValue.allowOverdraft;

                            switch (this.transactionType) {
                                case financialTransactionTypeEnum.Debit:
                                    this.debitTransactionVerb = this.accountTypeId === 2 // Wallet
                                        ? 'Withdrawal'
                                        : 'Charge';
                                    break;
                                case financialTransactionTypeEnum.Credit:
                                    this.debitTransactionVerb = 'Credit';
                                    break;
                            }

                            this.title = this.accountHolderName
                            ? 'SB_Raise_' + this.debitTransactionVerb + '_For_x'
                            : 'SB_Raise_' + this.debitTransactionVerb;
                        }
                    }.bind(this);

                    this.onTransactionSaved = function (data) {
                        this.modalInstance.close(data);
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);

                }]
        });