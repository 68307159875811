'use strict';

angular.module('shared.services.tinymceConfigHelper', [])
    .service('tinymceConfigHelper',
        function () {
            var service = this;

            service.getTinyMceConfig = function (props) {
                var opts = {};

                angular.extend(
                    opts, // dest
                    {
                        fonts: true,
                        fontSize: false,
                        fontFamily: false,
                        mobileFormatting: false,
                        formatting: true,
                        lists: true,
                        mobileLists: false,
                        justify: true,
                        tables: true,
                        hyperlinks: true,
                        images: true,
                        undo: true,
                        fontSelection: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
                        editHtml: true,
                        height: 104,
                        forceLinkPrefix: true // prefixes links with http if no existing protocol prefix.
                    }, // defaults
                    props); // passed options

                var pluginList = 'paste';
                var toolbar = '';

                if (opts.fonts) {
                    toolbar += 'formatselect';
                }

                if (opts.fontFamily)
                {
                    if (toolbar !== '')
                    {
                        toolbar += ' |';
                    }
                    toolbar += ' fontselect';
                }

                if (opts.fontSize)
                {
                    if (toolbar !== '')
                    {
                        toolbar += ' |';
                    }
                    toolbar += ' fontsizeselect';
                }

                if (opts.formatting) {
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' bold italic underline strikethrough blockquote';
                } else if (opts.mobileFormatting) {
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' bold';
                }

                if (opts.lists) {
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }

                    pluginList += ' lists';
                    toolbar += ' bullist numlist';
                } else if (opts.mobileLists) {
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }

                    pluginList += ' lists';
                    toolbar += ' bullist';
                }

                if (opts.images)
                {
                    if (toolbar !== '')
                    {
                        toolbar += ' |';
                    }

                    pluginList += ' image';
                    toolbar += ' image';

                    pluginList += ' autoresize';
                }

                if (opts.justify) {
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' alignleft aligncenter alignright alignjustify indent outdent';
                }


                if (opts.hyperlinks) {
                    pluginList += ' link email';
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' email link unlink';
                }

                if (opts.tables) {
                    pluginList += ' table';
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol ';
                }

                if (opts.undo) {
                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' undo redo';
                }

                if (opts.editHtml) {
                    pluginList += ' code';

                    if (toolbar !== '') {
                        toolbar += ' |';
                    }
                    toolbar += ' code';
                }

                const isNgHybrid = window.EveryBuddy.IsAngular12;
                const ngHybridLocation = '/assets/scripts/tinymce/ng1/';
                const ngHybridPlugins = {};
                if (isNgHybrid) {
                    pluginList.split(' ').forEach(pluginName => {
                        ngHybridPlugins[pluginName] = `${ngHybridLocation}plugins/${pluginName}/plugin.min.js`;
                    });
                }

                var options =
                {
                    branding: false,
                    plugins: pluginList,
                    toolbar: toolbar,
                    menubar: false,
                    block_formats: opts.fontSelection,
                    paste_use_dialog: false,
                    paste_auto_cleanup_on_paste: true,
                    paste_convert_headers_to_strong: true,
                    paste_strip_class_attributes: 'all',
                    paste_remove_spans: true,
                    paste_remove_styles: true,
                    paste_retain_style_properties: '',
                    link_assume_external_targets: false,
                    remove_script_host: false,
                    convert_urls: false, //https://www.drupal.org/forum/support/post-installation/2007-05-22/how-do-i-stop-tinymce-from-replacing-absolute-links-with
                    height: opts.height,
                    images_upload_url: opts.imagesUploadUrl,
                    images_upload_handler: opts.imagesUploadHandler,
                    images_upload_base_path: opts.imagesUploadBasePath,
                    images_reuse_filename: opts.imagesReuseFilename,
                    document_base_url: isNgHybrid ? ngHybridLocation : '',
                    theme_url: isNgHybrid ? ngHybridLocation + 'themes/modern/theme.min.js' : '',
                    skin_url: isNgHybrid ? ngHybridLocation + 'skins/lightgray/' : '',
                    external_plugins: isNgHybrid ? ngHybridPlugins : ''
                };

                // Prefix any links with http:// if no existing protocol prefix.
                // Not sure why/if we need this code, and in some cases we definitely
                // don't want it, e.g. link in an email tepmlate starts with a template tag
                // that includes the protocol - we don't want to end up with two!
                // Eventually would like to remove this.
                if (opts.forceLinkPrefix) {
                    options.setup = function (ed) {
                        ed.on('SaveContent', function (ed, o) {
                            var links = ed.target.dom.select('a');
                            ed.target.dom.setAttrib(links, 'target', '_blank');

                            for (var i = links.length; i--;) {
                                var href = links[i].getAttribute('data-mce-href');
                                if (href.search('://') < 0 && href.search('mailto:') < 0) {
                                    href = 'http://' + href;
                                    ed.target.dom.setAttrib(links[i], 'href', href);
                                    ed.target.dom.setAttrib(links[i], 'data-mce-href', href);
                                }
                            }

                            ed.content = ed.target.getContent();
                        });
                    }
                }

                return options;
            }
        });