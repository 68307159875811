angular.module('ccaSignUp.services.organisationRepository', [])
    .factory('organisationRepository',
        [
            '$http', '$q', '$cacheFactory', 'httpCoreApi',
            function ($http, $q, $cacheFactory, httpCoreApi) {
                var repoCache = $cacheFactory('ccaSignUp.services.organisationRepositoryCache');
                var urlBase = '/CcaSignUp/';
                var ccaSignUpTypeBaseUrl = 'CcaSignUpType';
                var organisationRepository = {};

                var httpCoreApiSettings = {
                    controllerType: httpCoreApi.controllerTypes.Admin,
                    cache: repoCache
                };

                organisationRepository.get = function () {
                    var deferred = $q.defer();
                    $http.get(urlBase + 'GetOrganisation' + '?hash_id=' + (new Date().getTime()))
                        .then(function (data) { deferred.resolve(data.data); })
                        .catch(function (err) { deferred.reject; });
                    return deferred.promise;
                };

                organisationRepository.getCcaType = (ccaSignUpTypeId) => {
                    return httpCoreApi.get(`${ccaSignUpTypeBaseUrl}/${ccaSignUpTypeId}`, httpCoreApiSettings);
                };

                organisationRepository.getFinance = function () {
                    var deferred = $q.defer();
                    $http.get(urlBase + 'GetFinance' + '?hash_id=' + (new Date().getTime()))
                        .then(function (data) { deferred.resolve(data.data); })
                        .catch(function (err) { deferred.reject; });
                    return deferred.promise;
                };

                return organisationRepository;
            }
        ]);