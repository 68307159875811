'use strict';

angular.module('transport.changeRequests.changeRequestsApprovedOrRejected', [
    'transport.changeRequests.transportReviewChangeRequest',
    'shared.components.sbColumnHeader',
    'shared.components.sbIcon',
    'shared.components.sbTextarea',
    'shared.components.sbAvatar',
    'shared.services.blobStoragePhotosService'
])
    .component('changeRequestsApprovedOrRejected', {
        templateUrl: '/Scripts/app/transport/changeRequests/transport-change-requests-approved-rejected.template.html',
        bindings: {
            transportChangeRequests : '<',
            onChangeRequestsUpdated: '<'
        },
        controller: [
            '$uibModal',
            'blobStoragePhotosService',
            function changeRequestsApprovedOrRejected($uibModal, blobStoragePhotosService)
            {
                this.$onInit = function () {
                    this.isDisabled = false;

                    if (this.transportChangeRequests && this.transportChangeRequests.length > 0) {
                        blobStoragePhotosService.addStudentPhotoUrlsToArray(this.transportChangeRequests, 'pupilPersonId').then(function (array) {
                            this.transportChangeRequests = array;
                        }.bind(this));
                    }
                }.bind(this);

                this.viewReviewChangeRequest = function (changeRequest)
                {
                    this.isDisabled = true;
                    var modalInstance = $uibModal.open({
                        animation: true,
                        backdrop: 'static',
                        component: 'transportReviewChangeRequest',
                        size: 'lg',
                        windowClass: 'modal-scroll',
                        resolve:
                        {
                            changeRequest: function () { return changeRequest; }.bind(this)
                        }
                    });

                    modalInstance.result.then(function () {
                        this.isDisabled = false;

                        this.onChangeRequestsUpdated.emit();
                    }.bind(this), function () { });
                }.bind(this);

                this.viewAddChangeRequest = function () {
                    this.isDisabled = true;

                    var modalInstance = $uibModal.open({
                        animation: true,
                        backdrop: 'static',
                        component: 'studentPickerWithDiaryEvents',
                        size: 'lg',
                        windowClass: 'modal-scroll',
                    });

                    modalInstance.result.then(function () {
                        this.isDisabled = false;

                        this.onChangeRequestsUpdated.emit();

                    }.bind(this), function () { });

                }.bind(this);
            }
        ]
});
