'use strict';

angular.module('balanceAdmin.summary.components.refund',
    [
        'balanceAdmin.services.balanceAdminService',
        'balance.constants',
        'person.services.personPickerService',
        'shared.directives.sbRadioButton',
        'shared.services.simpleAlertService'
    ])
    .component('refund',
        {
            bindings: {
                transaction: '<',
                onRefundRequested: '&'
            },
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/refund.template.html',
            controller: [
                'balanceAdminService',
                'simpleAlertService',
                function (
                    balanceAdminService,
                    simpleAlertService) {

                    this.refundClassification = 1;
                    this.amountToRefund = 0.0;
                    this.description = '';
                    this.refundIssued = false;

                    this.$onChanges = function (changes) {
                        if (changes.transaction && changes.transaction.currentValue) {
                            this.amountToRefund = changes.transaction.currentValue.amountAvailableToRefund;
                        }
                    }.bind(this);

                    this.issueRefund = function () {

                        // disable the button to prevent double submissions.
                        this.refundIssued = true;

                        if (+this.amountToRefund > +this.transaction.amountAvailableToRefund) {
                            simpleAlertService.errorAlert({
                                message: 'SB_Cannot_refund_more_than_the_original_transaction_value'
                            });

                            return;
                        }

                        balanceAdminService.refundTransaction(
                            this.transaction.transactionId,
                            this.amountToRefund,
                            this.description,
                            1, // always refund to the wallet account
                            this.refundClassification)
                            .then(function (data) {
                                console.log(data);
                                this.onRefundRequested()(data);
                            }.bind(this))
                            .catch(function (err) {
                                simpleAlertService.errorAlert();
                                console.error(err);
                            });

                    }.bind(this);

                }
            ]
        });