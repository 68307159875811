import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Confirmation, ConfirmationMessage } from './../models/UI/confirmation';

@Injectable()
/**
 * The service to provide messages to display dialog via `ConfirmDialogComponent`
 */
export class ConfirmDialogService {
  private subject = new Subject<ConfirmationMessage>();

  /**
   * To pop up a confirmation dialog with the given params.
   * @param config Message to display in the dialog.
   * @param confirm The function to invoke when user clicks the confirm button in the dialog UI.
   * @param cancel The function to invoke when user clicks the cancel button in the dialog UI.
   */
  confirmThis(
    config: Confirmation,
    confirm: (isCheckBoxSelected?: boolean) => void,
    cancel: (message?: string) => void
  ) {
    this.setConfirmation(config, confirm, cancel);
  }

  private setConfirmation(
    config: Confirmation,
    confirm: (isCheckBoxSelected?: boolean) => void,
    cancel: (message?: string) => void
  ) {
    const { subject } = this;

    this.subject.next({
      ...config,
      type: 'confirm',
      confirm(isCheckBoxSelected: boolean): any {
        subject.next(null); // This will close the modal
        confirm(isCheckBoxSelected);
      },
      cancel(message?: string): any {
        subject.next(null);
        cancel(message);
      }
    });
  }

  /**
   * Gets the observable for confirmation message. This is used by `ConfirmDialogComponent`.
   * It subscribes this observable to pop up a dialog in UI.
   * @returns The observable for confirmation message
   */
  getMessage(): Observable<ConfirmationMessage> {
    return this.subject.asObservable();
  }
}
