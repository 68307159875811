'use strict';

angular.module('transport.configuration.timetable.transportTimetableRoutes',
    [
        'transport.services.transportTimetablesService',
        'transport.services.transportConfigurationRoutesService',
        'shared.directives.sbCheckBox',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.components.sbRouteTimeline',
        'shared.components.sbTimePicker',
        'shared.controllers.confirmationPopup',
        'shared.services.simpleAlertService',
        'shared.services.portalTimetableRouteService',
    ])
    .component('transportTimetableRoutes',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/transport/configuration/timetable/transport-timetable-routes.template.html',
            controller: [
                '$filter',
                '$timeout',
                '$translate',
                '$uibModal',
                'personService',
                'simpleAlertService',
                'transportTimetablesService',
                'transportConfigurationRoutesService',
                'portalTimetableRouteService',
                function transportTimetableAddController($filter,
                                                         $timeout,
                                                         $translate,
                                                         $uibModal,
                                                         personService,
                                                         simpleAlertService,
                                                         transportTimetablesService,
                                                         transportConfigurationRoutesService,
                                                         portalTimetableRouteService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;
                    this.availableRoutes = [];
                    this.sortType = 'routeName';
                    this.sortReverse = false;
                    this.isNewTimetable = false;
                    this.busMonitors = [];

                    this.changesMade = function ()
                    {
                        this.anyChanges = true;
                    };

                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.availableTransportRoutes = null;

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            personService.getBusMonitors().then(function (data)
                            {
                                this.busMonitors = data;

                                var params = this.resolve.params;
                                this.timetable = params.responseDto;
                                this.isNewTimetable = params.isNewTimetable;

                                this.loadRoutes();

                            }.bind(this), function (error)
                            {
                                simpleAlertService.errorAlert(error);
                            }.bind(this));
                        }
                    }.bind(this);

                    this.loadRoutes = function ()
                    {
                        this.transportRoutes = null;
                        this.availableRoutes = [];

                        if (typeof this.timetable.transportDirectionId !== 'undefined' &&
                            this.timetable.transportDirectionId > 0 &&
                            typeof this.timetable.transportMethodId !== 'undefined' &&
                            this.timetable.transportMethodId > 0)
                        {
                            transportConfigurationRoutesService
                                .getRouteSummariesByTransportMethodAndTimeslot(this.timetable.transportMethodId,
                                                                               this.timetable.transportTimeSlotId,
                                                                               this.timetable.id)
                                    .then(function (data)
                                    {
                                        if (data.length > 0)
                                        {
                                            data.forEach(function (routeSummary)
                                            {
                                                if (routeSummary.isActive)
                                                {
                                                    var availableRoute =
                                                    {
                                                        routeId: routeSummary.id,
                                                        routeName: routeSummary.name,
                                                        startStopName: routeSummary.startStopName,
                                                        endStopName: routeSummary.endStopName,
                                                        numberOfStops: routeSummary.numberOfStops,
                                                        timetableRouteName: routeSummary.timetableRouteName,
                                                        isActive: routeSummary.isTimetableRouteActive,
                                                        busMonitorPersonId: routeSummary.busMonitorPersonId,
                                                        capacity: routeSummary.capacity,
                                                        originalCapacity: routeSummary.capacity,
                                                        highestNumberOfStudentPreferencesForAnyDay: routeSummary.highestNumberOfStudentPreferencesForAnyDay,
                                                        startTimeHoursMinutes: routeSummary.startTimeHoursMinutes,
                                                        estimatedArrivalTime: routeSummary.estimatedArrivalTime,
                                                        totalStopMinutes: routeSummary.totalStopMinutes,
                                                        latestStartTime: routeSummary.latestStartTime,
                                                        isLocked: routeSummary.highestNumberOfStudentPreferencesForAnyDay > 0,
                                                        timetableRouteId: routeSummary.timetableRouteId,
                                                        isRouteTrackingEnabled: routeSummary.isRouteTrackingEnabled,
                                                    };

                                                    this.availableRoutes.push(availableRoute);
                                                }
                                            }.bind(this));
                                        }

                                    }.bind(this), function (error)
                                    {
                                        simpleAlertService.errorAlert(error);
                                    }.bind(this));
                        }
                    }.bind(this);

                    this.cancel = function (doRefresh)
                    {
                        if (doRefresh) {
                            this.modalInstance.close();
                        } else {
                            this.modalInstance.dismiss('cancel');
                        }
                    };

                    this.checkCapacity = function (capacity, route, highestNumberOfStudentPreferencesForAnyDay)
                    {
                        if (typeof capacity !== 'undefined' && capacity != null && capacity < 0)
                        {
                            route.capacity = route.originalCapacity;
                            var messageText = $filter('decoded')($translate.instant('SB_Route_Capacity_Negative_Error'));
                            simpleAlertService.errorAlert({ message: messageText });
                            return;
                        }

                        if (typeof capacity !== 'undefined' && capacity != null &&
                            typeof highestNumberOfStudentPreferencesForAnyDay !== 'undefined' && highestNumberOfStudentPreferencesForAnyDay != null)
                        {
                            if (capacity < highestNumberOfStudentPreferencesForAnyDay)
                            {
                                route.capacity = route.originalCapacity;

                                var messageText = $filter('decoded')($translate.instant('SB_Route_Capacity_Error',
                                    {
                                        highestNumberOfStudents: highestNumberOfStudentPreferencesForAnyDay
                                    }));

                                simpleAlertService.errorAlert({ message: messageText });
                            }
                        }
                    }

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.showCapacityChangedWarning = function ()
                    {
                        var showCapacityChangedWarning = false;
                        for (var i = 0; i < this.availableRoutes.length; i++)
                        {
                            var route = this.availableRoutes[i];
                            if (route.include)
                            {
                                if (typeof route.capacity !== 'undefined' &&
                                    (typeof route.originalCapacity === 'undefined' ||
                                    route.capacity != route.originalCapacity))
                                {
                                    showCapacityChangedWarning = true;
                                    break;
                                }
                            }
                        };

                        return showCapacityChangedWarning;
                    }

                    this.requestCloneRoute = function (route)
                    {
                        if (!route.isActive || typeof route.timetableRouteId === 'undefined' || route.timetableRouteId == null)
                        {
                            return false;
                        }

                        var cloneRouteModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'cloneTimetableRoute',
                                size: 'lg',
                                backdrop: 'static',
                                resolve:
                                {
                                    route: function () { return portalTimetableRouteService.getRouteTimeline(this.timetable.id, route.timetableRouteId); }.bind(this),
                                    transportRouteId: function () { return route.routeId; },
                                    timetableRouteId: function () { return route.timetableRouteId; },
                                    timetableRouteName: function () { return route.timetableRouteName; },
                                    timetable: function () { return this.timetable; }.bind(this)
                                }
                            });

                        cloneRouteModalInstance.result
                            .then(function ()
                            {
                                this.loadRoutes();
                            }.bind(this),
                            function ()
                            {
                            });

                    }.bind(this);

                    this.saveTimetableRoutes = function ()
                    {
                        if (this.showCapacityChangedWarning())
                        {
                            var modalInstance = simpleAlertService.simpleAlert({
                                title: 'SB_Confirm',
                                message: 'SB_Capacities_Will_Be_Changed_Message',
                                windowSize: 'sm',
                                okButtonText: 'SB_Yes',
                                cancelButtonText: 'SB_No'
                            });

                            modalInstance.result
                                .then(function ()
                                {
                                    this.performSave();
                                }.bind(this), function ()
                                    {
                                        resetSubmitButtons();
                                }.bind(this));
                        }
                        else
                        {
                            this.performSave();
                        }
                    }.bind(this);

                    this.performSave = function ()
                    {
                        var saveRequest =
                        {
                            timetableId: this.timetable.id,
                            timetableRoutes: []
                        };

                        this.availableRoutes.forEach(function (route)
                        {
                            if (!route.timetableRouteName){
                                route.timetableRouteName = '';
                            }

                            if (!route.capacity) {
                                route.capacity = null;
                            }

                            saveRequest.timetableRoutes.push(
                            {
                                routeId: route.routeId,
                                timetableRouteName: route.timetableRouteName,
                                timetableRouteId: route.timetableRouteId,
                                busMonitorPersonId: route.busMonitorPersonId,
                                capacity: route.capacity,
                                startTimeHoursMinutes: route.startTimeHoursMinutes,
                                isActive: route.isActive,
                                isRouteTrackingEnabled: route.isRouteTrackingEnabled,
                            });
                        }.bind(this));

                        transportTimetablesService.saveTimetableRoutes(saveRequest)
                            .then(function ()
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Saved',
                                    message: 'SB_Transport_Timetable_Routes_Saved',
                                    staticBackdrop: true
                                });

                                closeModal();
                            }.bind(this))
                            .catch(function (responseData)
                            {
                                let message = {};
                                if (responseData && responseData.data && responseData.data.Message) {
                                    message = {message: responseData.data.Message};

                                    var messageParts = responseData.data.Message.split('|validationParameter|');
                                    if (messageParts.length === 2) {
                                        message = {
                                            message: messageParts[0],
                                            messageTranslationParameters: { timetableRouteName: messageParts[1] }
                                        }
                                    }
                                }
                                alertAndResetOkButton(message);
                            });
                    }.bind(this);

                    this.isModalOpen = false;
                    this.showRouteTimeline = function (route) {

                        if (!route.timetableRouteId || !this.timetable.id) {
                            simpleAlertService.errorAlert('SB_Error_loading');
                        } else {
                            this.isModalOpen = true;
                            var showRouteTimelineModalInstance = $uibModal.open({
                                animation: true,
                                backdrop: 'static',
                                component: 'sbRouteTimeline',
                                size: 'md',
                                windowClass: 'modal-scroll',
                                keyboard: false,
                                resolve:
                                {
                                    route: function ()
                                    {
                                        return portalTimetableRouteService.getRouteTimeline(this.timetable.id, route.timetableRouteId);
                                    }.bind(this)
                                }
                            });

                            showRouteTimelineModalInstance.result.then(function () {
                                this.isModalOpen = false;
                            }.bind(this));
                        }
                    }.bind(this);

                    this.updateEstimatedArrivalTime = function (route)
                    {
                        $timeout(function ()
                        {
                            if (typeof route === 'undefined' ||
                                typeof route.startTimeHoursMinutes === 'undefined' ||
                                typeof route.totalStopMinutes === 'undefined' ||
                                route.startTimeHoursMinutes == null ||
                                route.totalStopMinutes == null)
                            {
                                return;
                            }

                            var estimatedEndTime = new Date();

                            estimatedEndTime.setHours(route.startTimeHoursMinutes.h);
                            estimatedEndTime.setMinutes(route.startTimeHoursMinutes.m);
                            estimatedEndTime.setTime(estimatedEndTime.getTime() + (route.totalStopMinutes * 60 * 1000));

                            var hours = estimatedEndTime.getHours() < 10 ? '0' + estimatedEndTime.getHours() : estimatedEndTime.getHours();
                            var minutes = estimatedEndTime.getMinutes() < 10 ? '0' + estimatedEndTime.getMinutes() : estimatedEndTime.getMinutes();
                            route.estimatedArrivalTime = hours + ':' + minutes;
                        }.bind(this), 200);
                    };

                    this.includeRouteChanged = ((route) => {
                            route.isRouteTrackingEnabled = route.isActive;
                    });
                }
            ]
        });
