// DELETE ME
'use strict';

angular.module('receptionModule').component('receptionUsers', {
    templateUrl: '/Scripts/app/reception/controllers/reception-users.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        onSelectPerson: '&'
    },
    controllerAs: 'ctrl',
    controller:
    [
        '$scope',
        '$state',
        '$filter',
        '$http',
        '$uibModal',
        '$window',
        '$translate',
        '$timeout',
        'moduleService',
        'receptionService',
        'personPickerService',
        'teamService',
        'iconService',
        'cachedLookupService',
        'messagingService',
        'simpleAlertService',
        'blobStoragePhotosService',
        'toastService',
        'personService',
        'searchFilterTypes',
        'memberTypes',
        'attendanceScanningSettingsService',
        function ($scope,
            $state,
            $filter,
            $http,
            $uibModal,
            $window,
            $translate,
            $timeout,
            moduleService,
            receptionService,
            personPickerService,
            teamService,
            iconService,
            cachedLookupService,
            messagingService,
            simpleAlertService,
            blobStoragePhotosService,
            toastService,
            personService,
            searchFilterTypes,
            memberTypes,
            attendanceScanningSettingsService,
        ) {
            $scope.addMode = false;
            $scope.isSuperAdmin = false;
            $scope.canSaveProfile = false;
            $scope.loading = false;
            $scope.sortType = 'lastName';
            $scope.sortReverse = false;
            $scope.misManaged = false;
            $scope.locked = true;
            $scope.systemEmailChecked = false;
            $scope.showHouseClass = true;
            $scope.membershipEnabled = false;
            $scope.isStatus365 = null;
            $scope.isTransportModuleActive = false;
            $scope.personChanges = false;

            $scope.isSubmitted = false;

            var resetSubmitButtons = function ()
            {
                $timeout(function ()
                {
                    $scope.isSubmitted = false;
                }.bind(this), 200);

            }.bind(this);

            $scope.stats = { selectedCount: 0 };

            $scope.sendAccessRequestEmailPrompt = '';

            $scope.menuItems = [];

            cachedLookupService.isOrganisationAdmin()
                .then(function (value) { $scope.isAdmin = value; });

            cachedLookupService.isOrganisationSuperAdmin()
                .then(function (value) { $scope.isSuperAdmin = value; });

            personService.getCurrentPersonId()
                .then(function (data) {
                    $scope.loggedInPersonId = data;
                });

            attendanceScanningSettingsService.getSettings().then(res => {
                $scope.showAttendanceScanningCode = res.data.enabledStatusId > 0;
                $scope.showRfid = res.data.scanningTypeId === 1;
            });

            moduleService.getIsTransportModuleActive()
                .then(function (response)
                {
                    $scope.isTransportModuleActive = response;
                });

            $scope.searchFilterTypes = searchFilterTypes;

            $scope.schoolYearGroups = [{ id: 0, name: 'SB_All' }];
            $scope.schoolClasses = [{ id: 0, name: 'SB_All' }];

            $translate.onReady().then(function ()
            {
                $scope.translatedDelete = $filter('translate')('SB_Delete').toUpperCase();
            });

            $scope.maxNumberOfPages = $window.EveryBuddy.PaginationMaxNumberOfPages;

            $scope.predefinedQuery = {
                queryId: 0,
                pageSize: 20,
                currentPage: 1,
                schoolYearGroupId: 0,
                schoolClassId: 0
            };
            $scope.resetSearchParameters = function () {
                $scope.searchParameters = {
                    nameSearchCriteria: '',
                    pageSize: 20,
                    currentPage: 1,
                    includePupils: true,
                    includeParents: true,
                    includeStaff: true,
                    includeDeleted: false,
                    schoolYearGroupId: 0,
                    schoolClassId: 0,
                    membershipState: null,
                    memberOfTeamId: 0,
                    memberOfClubId: 0,
                    genderTypeId: -1,
                    hasAccountCreated: -1,
                    hasLoggedIn: -1,
                    onlyShowExternalStaff: false,
                    activationState: 0,
                    onlyShowMultiAccounts : false,
                };
                $scope.showOnlineMeetingId = false;
            };
            $scope.onReset = function() {
                $scope.resetSearchParameters();
                $scope.searchPerson();
            };
            $scope.teams = [];
            $scope.yearNames = [];
            $scope.checkFlag = function (value, flag) {
                return value & flag;
            };

            $scope.memberTypes = memberTypes;

            $scope.membershipStateOptions = [
                { id: null, name: 'SB_Any' },
                { id: 'stateNone', name: 'SB_None' },
                { id: 'stateActive', name: 'SB_ACTIVE' },
                { id: 'stateInactive', name: 'SB_INACTIVE' }
            ];

            $scope.activationStateOptions = [
                { id: null, name: 'SB_All' },
                { id: 1, name: 'SB_Welcome_Message_Not_Sent' },
                { id: 2, name: 'SB_Welcome_Message_Sent_But_User_Not_Active' },
                { id: 3, name: 'SB_Active_Users' }
            ];

            $scope.pageSizes = [
                { id: 99999, name: 'SB_All' },
                { id: 20, name: '20' },
                { id: 50, name: '50' },
                { id: 100, name: '100' },
            ];

            $scope.resetSearchParameters();

            $scope.usingPredefinedQuery = false;
            $scope.predefinedQueryResults = [];
            $scope.predefinedQueryResultsPageCount = 0;
            $scope.predefinedQueryResultsRowCount = 0;

            $scope.staffIncludedInSearch = false;

            $scope.isSendingActivation = false;
            $scope.singleActivationSent = false;

            $scope.toggleAll = function () {
                for (var i = $scope.searchResults.length; i--;) {
                    $scope.searchResults[i].selected = !$scope.searchResults[i].selected;
                }

                $scope.selectedItem();
            };

            $scope.toggleshowOnlineMeetingId = function () {
                $scope.showOnlineMeetingId = !$scope.showOnlineMeetingId;
            };

            $scope.loadPredefinedResults = function (preserveCurrentPage) {

                if (!preserveCurrentPage) {
                    $scope.predefinedQuery.currentPage = 1;
                }
                $scope.searchResultsLoading = true;

                var predefinedQuery = Object.assign({}, $scope.predefinedQuery);
                predefinedQuery.currentPage -= 1;

                personPickerService
                    .getPredefinedQueryResults(predefinedQuery)
                    .then(function (response) {

                        $scope.searchResultsLoading = false;

                        if (response.isError) {
                            $scope.predefinedQueryResults = [];
                            console.error('Search failed', response.errorData);
                        } else {
                            $scope.predefinedQueryResults = [];
                            $scope.predefinedQueryResults = response.results;
                            $scope.predefinedQueryResultsPageCount = Math.ceil(response.totalRows / $scope.predefinedQuery.pageSize) - 1;
                            $scope.predefinedQueryResultsRowCount = response.totalRows;
                        }
                    });
            };

            $scope.searchResultsLoading = false;
            $scope.searchResults = [];
            $scope.searchResultsPageCount = 0;
            $scope.searchResultsRowCount = 0;

            $scope.sendAccessRequestEmails = function () {
                var pupilsToWelcome = [],
                    pupilsToResetPassword = [],
                    pupilsToReInvite = [],
                    parentsToWelcome = [],
                    parentsToResetPassword = [],
                    parentsToReInvite = [],
                    staffToWelcome = [],
                    staffToResetPassword = [],
                    staffToReInvite = [];

                for (var i = $scope.searchResults.length; i--;) {
                    var thisPerson = $scope.searchResults[i];

                    // Put selected people in appropriate list:
                    // * Invite, re-invite or reset password.
                    // * Staff, parent or pupil audience.
                    // For users with multiple user type e.g. staff and parent,
                    // priority order is staff > parent > pupil.
                    if (thisPerson.selected) {
                        switch (+$scope.searchParameters.activationState) {
                            case 1: // SB_Welcome_Message_Not_Sent
                                if (thisPerson.st) {
                                    staffToWelcome.push(thisPerson.id);
                                }
                                else if (thisPerson.pa) {
                                    parentsToWelcome.push(thisPerson.id);
                                }
                                else {
                                    pupilsToWelcome.push(thisPerson.id);
                                }
                                break;
                            case 2: // SB_Welcome_Message_Sent_But_User_Not_Active
                                if (thisPerson.st) {
                                    staffToReInvite.push(thisPerson.id);
                                }
                                else if (thisPerson.pa) {
                                    parentsToReInvite.push(thisPerson.id);
                                }
                                else {
                                    pupilsToReInvite.push(thisPerson.id);
                                }
                                break;
                            case 3: // SB_Active_Users
                                if (thisPerson.st) {
                                    staffToResetPassword.push(thisPerson.id);
                                }
                                else if (thisPerson.pa) {
                                    parentsToResetPassword.push(thisPerson.id);
                                }
                                else {
                                    pupilsToResetPassword.push(thisPerson.id);
                                }
                                break;
                        }
                    }
                }

                messagingService.sendAccessRequestEmails(pupilsToWelcome,
                    pupilsToResetPassword,
                    pupilsToReInvite,
                    parentsToWelcome,
                    parentsToResetPassword,
                    parentsToReInvite,
                    staffToWelcome,
                    staffToResetPassword,
                    staffToReInvite)
                    .then(function (data) {
                        for (var i = $scope.searchResults.length; i--;) {
                            $scope.searchResults[i].selected = false;
                        }
                        $scope.stats.selectedCount = 0;

                        simpleAlertService.simpleAlert({
                            title: 'SB_Sent',
                            message: 'SB_Messages_have_been_sent'
                        });
                    });
            };

            $scope.sendSingleAccessRequestEmail = function(person) {
                $scope.isSendingActivation = true;
                person.id = person.personData.id;
                messagingService.sendSingleAccessRequestEmail(person)
                    .then(function() {
                        $scope.isSendingActivation = false;
                        $scope.singleActivationSent = true;
                        $scope.personChanges = true;
                    })
                    .catch(function() {
                        $scope.isSendingActivation = false;
                    });
            };

            $scope.selectedItem = function () {
                $timeout(function () {
                    var count = 0;
                    for (var i = $scope.searchResults.length; i--;) {
                        var thisPerson = $scope.searchResults[i];

                        if (thisPerson.selected) {
                            count++;
                        }
                    }
                    $scope.stats.selectedCount = count;
                },
                    0);
            };

            $scope.searchPerson = function (preserveCurrentPage)
            {
                $scope.stats.selectedCount = 0;

                // show option to show/hide online meeting ids if staff were included in search.
                $scope.staffIncludedInSearch = $scope.searchParameters.includeStaff;

                if (!preserveCurrentPage)
                {
                    $scope.searchParameters.currentPage = 1;
                }

                switch (+$scope.searchParameters.activationState)
                {
                    case 1:
                        $scope.sendAccessRequestEmailPrompt = 'SB_Send_Welcome_Message';
                        $scope.searchParameters.hasAccountCreated = 0;
                        $scope.searchParameters.hasLoggedIn = -1;
                        break;
                    case 2:
                        $scope.sendAccessRequestEmailPrompt = 'SB_Resend_Welcome_Message';
                        $scope.searchParameters.hasAccountCreated = 1;
                        $scope.searchParameters.hasLoggedIn = 0;
                        break;
                    case 3:
                        $scope.sendAccessRequestEmailPrompt = 'SB_Send_Password_Reset';
                        $scope.searchParameters.hasAccountCreated = 1;
                        $scope.searchParameters.hasLoggedIn = 1;
                        break;
                    default:
                        $scope.searchParameters.hasAccountCreated = -1;
                        $scope.searchParameters.hasLoggedIn = -1;
                }

                $scope.searchResultsLoading = true;

                var searchParameters = Object.assign({}, $scope.searchParameters);
                searchParameters.currentPage -= 1;

                personPickerService
                    .getPeopleFiltered(searchParameters)
                    .then(function (response)
                    {
                        $scope.searchResultsLoading = false;

                        if (response.isError)
                        {
                            $scope.searchResults = [];
                            console.error('Search failed', response.errorData);
                            toastService.error();
                        } else
                        {
                            $scope.searchResultsPageCount = Math.ceil(response.totalRows / $scope.searchParameters.pageSize) - 1;
                            $scope.searchResultsRowCount = response.totalRows;

                            $scope.searchResults = response.results;

                            blobStoragePhotosService.addStudentPhotoUrlsToArray($scope.searchResults)
                            .then(function (array)
                            {
                                $scope.searchResults = array;
                            });
                        }
                    }).catch(() => {
                        toastService.error();
                        $scope.searchResultsLoading = false;
                    });
            };

            $scope.searchPerson();
            $scope.goToPrevPage = function () {
                if ($scope.usingPredefinedQuery
                    ? $scope.predefinedQuery.currentPage === 1
                    : $scope.searchParameters.currentPage === 1)
                    return;

                if ($scope.usingPredefinedQuery) {
                    $scope.predefinedQuery.currentPage--;
                    $scope.loadPredefinedResults(true);
                } else {

                    $scope.searchParameters.currentPage--;
                    $scope.searchPerson(true);

                }
            };
            $scope.goToNextPage = function () {
                if ($scope.singPredefinedQuery
                    ? $scope.predefinedQuery.currentPage >= $scope.predefinedQueryResultsPageCount
                    : $scope.searchParameters.currentPage >= $scope.searchResultsPageCount)
                    return;

                if ($scope.usingPredefinedQuery) {
                    $scope.predefinedQuery.currentPage++;
                    $scope.loadPredefinedResults(true);
                } else {
                    $scope.searchParameters.currentPage++;
                    $scope.searchPerson(true);
                }
            };
            $scope.goToPage = function (pageNumber) {
                if ($scope.usingPredefinedQuery) {
                    $scope.predefinedQuery.currentPage = pageNumber;
                    $scope.loadPredefinedResults(true);
                } else {
                    $scope.searchParameters.currentPage = pageNumber;
                    $scope.searchPerson(true);
                }
            };

            $scope.range = function (min, max, step) {
                step = step || 1;
                var input = [];
                for (var i = min; i <= max; i += step) {
                    input.push(i);
                }
                return input;
            };

            $scope.clearSelectedPerson = function (changes) {
                // Reload search results when submitting user, or when returning if email has been sent
                $scope.person = undefined;
                $scope.singleActivationSent = false;
                if ($scope.personChanges || changes) {
                    $scope.searchPerson();
                }
            };

            $scope.checkFlag = function (value, flag) {
                return value & flag;
            };

            teamService.getAllForLookup(false)
                .then(function (data) {
                    $scope.teams = data;
                    $scope.teams.splice(0, 0, { id: null, name: 'SB_All' });
                });

            receptionService.getLookupData().then(function (data) {
                $scope.showHouseClass = (data.organisationTypeId === 2);
                $scope.organisationTypeId = data.organisationTypeId;
                $scope.organisationRegistrationTypeId = data.organisationRegistrationTypeId;
                $scope.misManaged = data.misManaged;
                $scope.isOrganisationAdmin = data.isOrganisationAdmin;
                $scope.schoolFieldsAvailableFlags = data.schoolFieldsAvailableFlags;
                $scope.membershipEnabled = data.membershipsUsed;
                $scope.hideStaffGenders = data.hideStaffGenders;
                $scope.permissionToWalkAvailable = data.permissionToWalkAvailable;

                $scope.schoolYearGroups = data.schoolYearGroups;

                for (var i = $scope.schoolYearGroups.length; i--;) {
                    $scope.yearNames[$scope.schoolYearGroups[i].id] = $scope.schoolYearGroups[i].name;
                }

                $scope.schoolYearGroups.splice(0, 0, { id: 0, name: 'SB_All' });

                $scope.schoolHouses = data.schoolHouses;
                $scope.schoolClasses = data.schoolClasses;
                $scope.schoolClasses.splice(0, 0, { id: 0, name: 'SB_All' });
                $scope.isStatus365 = data.isStatus365;

            });

            $scope.schoolYearLabel = function () {
                return $scope.organisationTypeId === $window.EveryBuddy.Enums.OrganisationTypes.School
                    ? 'SB_Year'
                    : 'SB_Region';
            };

            $scope.shouldShowYear = function () {
                if (!$scope.person || $scope.person.isStaff || $scope.person.isParent) { return false; }

                if ($scope.person.isPupil === true) {
                    return true;
                } else {
                    return ($scope.schoolFieldsAvailableFlags &
                        $window.EveryBuddy.Enums.OrganisationSchoolFieldVisibility.ShowYears) ===
                        $window.EveryBuddy.Enums.OrganisationSchoolFieldVisibility.ShowYears;
                }
            };

            $scope.shouldShowHouse = function () {
                if (!$scope.person)
                    return false;

                if ($scope.organisationTypeId === $window.EveryBuddy.Enums.OrganisationTypes.School) {
                    return $scope.person.isPupil === true;
                } else {
                    return ($scope.schoolFieldsAvailableFlags &
                        $window.EveryBuddy.Enums.OrganisationSchoolFieldVisibility.ShowHouses) ===
                        $window.EveryBuddy.Enums.OrganisationSchoolFieldVisibility.ShowHouses;
                }
            };

            $scope.shouldShowClass = function () {
                if (!$scope.person)
                    return false;

                if ($scope.organisationTypeId === $window.EveryBuddy.Enums.OrganisationTypes.School) {
                    return $scope.person.isPupil === true;
                } else {
                    return ($scope.schoolFieldsAvailableFlags &
                        $window.EveryBuddy.Enums.OrganisationSchoolFieldVisibility.ShowClasses) ===
                        $window.EveryBuddy.Enums.OrganisationSchoolFieldVisibility.ShowClasses;
                }
            };

            $scope.shouldShowPersonalFields = function () {

                if (!$scope.person)
                    return false;

                return ($scope.organisationTypeId !== $window.EveryBuddy.Enums.OrganisationTypes.School);
            };

            $scope.shouldShowAttendanceScanningCode = () => {
                return $scope.person?.isPupil === true && $scope.showAttendanceScanningCode;
            };

            $scope.attendanceScanningCodeLabel = () => {
                return $scope.showRfid
                    ? 'SB_Attendance_Scanning_RFID'
                    : 'SB_Attendance_Scanning_QR_Code';
            }

            $scope.disableAddressFields = function () {

                return $scope.organisationTypeId === $window.EveryBuddy.Enums.OrganisationTypes.School;

            };

            $scope.shouldShowColumn = function (columnName) {
                if (columnName === 'SB_Type' && $scope.predefinedQuery.queryId === '7')
                    return true;

                return false;
            };

            var setupMenu = function () {
                $scope.menuItems = [
                    {
                        uisref: '.diary',
                        title: 'SB_Diary',
                        icon: iconService.getIcon('diary')
                    },

                ];

                if (!$scope.isStatus365) {
                    $scope.menuItems.push(
                        {
                            uisref: '.linked',
                            title: 'SB_Linked',
                            icon: iconService.getIcon('linkedAccounts')
                        }
                    );
                }

                if ($scope.person.isPupil) {
                    $scope.menuItems.push({
                        uisref: '.pupilgroups',
                        title: 'SB_Groups',
                        icon: iconService.getIcon('allgroups')
                    });
                } else if ($scope.person.isStaff) {
                    $scope.menuItems.push({
                        uisref: '.staffgroups',
                        title: 'SB_Groups',
                        icon: iconService.getIcon('allgroups')
                    });
                }

                if ($scope.person.isPupil && !$scope.isStatus365) {
                    $scope.menuItems.push({
                        uisref: '.pupilmedical',
                        title: 'SB_Medical',
                        icon: iconService.getIcon('medical')
                    });
                }

                if ($scope.membershipEnabled && ($scope.person.isPupil || $scope.person.isParent === true)) {
                    $scope.menuItems.push({
                        uisref: '.pupilmembership',
                        title: 'SB_Membership',
                        icon: iconService.getIcon('membership')
                    });
                }

                if ($scope.person.forms && $scope.person.forms.length > 0) {
                    $scope.menuItems.push({
                        uisref: '.forms',
                        title: 'SB_Custom_Data',
                        icon: iconService.getIcon('forms'),
                        params: {
                            'person': $scope.person,
                            'isOrganisationAdmin': $scope.isAdmin,
                            'isOrganisationSuperAdmin': $scope.isSuperAdmin
                        }
                    });
                }

                if ($scope.person.isPupil && $scope.permissionToWalkAvailable) {
                    $scope.menuItems.push({
                        uisref: '.permissionToWalk',
                        title: 'SB_Permission_To_Walk_Tab',
                        icon: iconService.getIcon('transport-walk'),
                        params: {
                            'person': $scope.person
                        }
                    });
                }
            };

            $scope.showPerson = function (personId) {
                $scope.canSaveProfile = false;
                $scope.loadingPersonId = personId;
                $scope.person = null;
                $scope.loading = true;
                $scope.locked = true;
                $scope.personChanges = false;
                receptionService.getPerson(personId).then((data) => {
                    var tabDiaryId = 4;

                    $scope.loading = false;

                    if (!data) {
                        simpleAlertService.errorAlert({ message: 'SB_Error_Loading' });
                        console.log('Could not retrieve person data');
                        return;
                    }

                    $scope.person = data;

                    if (data.personData.dateOfBirth) {
                        $scope.person.personData.dateOfBirth = new Date(data.personData.dateOfBirth);
                    }

                    $scope.selectedSubTab = tabDiaryId;  //set default selected state for tab

                    $scope.loadingPersonId = null;

                    this.onSelectPerson({person: $scope.person});

                    cachedLookupService.canSaveProfile(
                        $scope.person,
                        $scope.loggedInPersonId,
                        $scope.person.seenInMIS,
                        false,
                        false).then(function (response)
                        {
                            $scope.canSaveProfile = response;
                            $state.go('dashboard.users.diary');
                            setupMenu();
                        });

                });
            };

            $scope.activationStates = messagingService.activationStates;

            $scope.getActivationLabel = function(person) {
                return messagingService.getActivationLabel(person);
            };

            $scope.genders = [
                {
                    id: '0',
                    name: 'SB_Female'
                },
                {
                    id: '1',
                    name: 'SB_Male'
                }
            ];

            $scope.systemEmail = function () {
                $scope.systemEmailChecked = !$scope.systemEmailChecked;

                $scope.person.emailAddress = $scope.systemEmailChecked ? 'systemgen@schoolsbuddy.dummy' : '';
            };

            $scope.addPupil = function ()
            {
                $scope.clearSelectedPerson(true);
                $scope.systemEmailChecked = false;
                $scope.addMode = true;
                var personData = {
                    title: '',
                    firstName: '',
                    lastName: '',
                    id: 0,
                    dateOfBirth: new Date(),
                    telephone: '',
                    mobile: '',
                    gender: 0
                };
                var pupilInformation = { schoolHouseId: 0, schoolClassId: 0 }; // left out schoolYearGroupId as its required

                $scope.person = {
                    personData: personData,
                    emailAddress: '',
                    isPupil: true,
                    isParent: false,
                    isStaff: false,
                    isExternal: false,
                    isExternalCoach: false,
                    isTransportBusMonitor: false,
                    misId: '',
                    pupilInformation: pupilInformation
                };
            };

            $scope.addStaff = function ()
            {
                $scope.clearSelectedPerson(true);
                $scope.systemEmailChecked = false;
                $scope.addMode = true;
                var personData = {
                    title: '',
                    firstName: '',
                    lastName: '',
                    id: 0,
                    dateOfBirth: new Date(),
                    telephone: '',
                    mobile: '',
                    gender: 0
                };
                var pupilInformation = { schoolHouseId: 0, schoolClassId: 0, schoolYearGroupId: 0 };

                $scope.person = {
                    personData: personData,
                    emailAddress: '',
                    isPupil: false,
                    isParent: false,
                    isStaff: true,
                    isExternal: false,
                    isExternalCoach: false,
                    isTransportBusMonitor: false,
                    misId: '',
                    pupilInformation: pupilInformation
                };
            };

            $scope.addExternalUser = function () {
                $scope.clearSelectedPerson(true);
                $scope.systemEmailChecked = false;
                $scope.addMode = true;

                var personData = {
                    title: '',
                    firstName: '',
                    lastName: '',
                    id: 0,
                    dateOfBirth: new Date(),
                    telephone: '',
                    mobile: '',
                    gender: 0
                };
                var pupilInformation = { schoolHouseId: 0, schoolClassId: 0, schoolYearGroupId: 0 };

                $scope.person = {
                    personData: personData,
                    emailAddress: '',
                    isPupil: false,
                    isParent: false,
                    isStaff: true,
                    isExternal: true,
                    isExternalCoach: true,
                    isTransportBusMonitor: false,
                    misId: '',
                    pupilInformation: pupilInformation
                };
            };

            $scope.savePerson = function ()
            {
                $scope.addMode = false;
                var dob = null;
                if ($scope.person.isPupil && $scope.person.personData.dateOfBirth !== null)
                {
                    dob = $filter('date')($scope.person.personData.dateOfBirth, 'yyyy-MM-dd');
                }

                var personData = {
                    id: $scope.person.personData.id,
                    title: $scope.person.personData.title,
                    firstName: $scope.person.personData.firstName,
                    lastName: $scope.person.personData.lastName,
                    nickName: $scope.person.personData.nickName,
                    emailAddress: $scope.person.emailAddress,
                    dateOfBirth: dob,
                    telephone: $scope.person.personData.telephone,
                    mobile: $scope.person.personData.mobile,
                    misId: $scope.person.misId,
                    schoolHouseId: ($scope.person.isPupil) ? $scope.person.pupilInformation.schoolHouseId : 0,
                    schoolYearGroupId: ($scope.person.isPupil) ? $scope.person.pupilInformation.schoolYearGroupId : 0,
                    schoolClassId: ($scope.person.isPupil) ? $scope.person.pupilInformation.schoolClassId : 0,
                    attendanceScanningCode: ($scope.person.isPupil) ? $scope.person.pupilInformation.attendanceScanningCode : null,
                    gender: $scope.person.personData.gender,
                    isStaff: $scope.person.isStaff,
                    isExternal: $scope.person.isExternal,
                    isExternalCoachAccess: $scope.person.isExternal && ($scope.person.isExternalCoachAccess || !$scope.isTransportModuleActive),
                    isTransportBusMonitor: $scope.person.isTransportBusMonitor,
                    isPupil: $scope.person.isPupil,
                    isParent: $scope.person.isParent,
                    Address: $scope.person.personData.address,
                    Town: $scope.person.personData.town,
                    County: $scope.person.personData.county,
                    PostCode: $scope.person.personData.postCode,
                    onlineMeetingId: ($scope.person.isStaff && $scope.person.staffInformation) ? $scope.person.staffInformation.onlineMeetingId : null
                };

                if (personData.id > 0)
                {
                    $scope.clearSelectedPerson(true);
                    $scope.loading = true;
                    receptionService.savePerson(personData)
                        .then(function (data)
                        {
                            $scope.showPerson(personData.id);
                            if (data.personId < 1 || data.msg !== 'OK')
                            {
                                resetSubmitButtons();
                                $scope.errorMessage = data.msg;
                                var modalInstance = $uibModal.open(
                                    {
                                        animation: $scope.animationsEnabled,
                                        templateUrl: 'saveErrorModal.html',
                                        controller: 'ConfirmModalInstanceCtrl',
                                        size: 'sm',
                                        scope: $scope
                                    });
                            }
                            else
                            {
                                resetSubmitButtons();
                            }
                        });
                }
                else
                {
                    receptionService.addPerson(personData)
                        .then(function (data)
                        {
                            if (data.personId > 0 && data.msg === 'OK')
                            {
                                resetSubmitButtons();
                                $scope.showPerson(data.personId);
                            }
                            else
                            {
                                resetSubmitButtons();
                                $scope.errorMessage = data.msg;
                                var modalInstance = $uibModal.open(
                                    {
                                        animation: $scope.animationsEnabled,
                                        templateUrl: 'saveErrorModal.html',
                                        controller: 'ConfirmModalInstanceCtrl',
                                        size: 'sm',
                                        scope: $scope
                                    });
                            }
                        });
                }
            };

            $scope.formActive = function (aForm)
            {
                return ($scope.selectedSubTab === (100 + aForm.organisationFormId)) ? 'buddy-nav__item--active' : '';
            };

            $scope.showForm = function (aForm)
            {
                $scope.selectedSubTab = 100 + aForm.organisationFormId;
                $scope.selectedForm = aForm;
                $scope.personFormData = null;
                $state.go('dashboard.users.forms', {}, { reload: 'dashboard.users.forms' });
            };

            $scope.deletePerson = function ()
            {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'confirmDeletePersonModal.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    size: 'md',
                    scope: $scope
                });

                modalInstance.result.then(function ()
                {
                    receptionService.deletePerson($scope.person.personData.id)
                        .then(function (data)
                        {
                            $scope.showPerson($scope.person.personData.id);
                        });
                },
                    function ()
                    {
                    });
            };

            $scope.restorePerson = function ()
            {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'confirmRestorePersonModal.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    size: 'md',
                    scope: $scope
                });

                modalInstance.result.then(function () {
                    receptionService.restorePerson($scope.person.personData.id, $scope.person.personData.restoreLinks)
                        .then(function (data) {

                            if (data.msg === 'OK') {
                                $scope.showPerson($scope.person.personData.id);
                            } else {
                                $scope.errorMessage = data.msg;
                                var modalInstance = $uibModal.open({
                                    animation: $scope.animationsEnabled,
                                    templateUrl: 'saveErrorModal.html',
                                    controller: 'ConfirmModalInstanceCtrl',
                                    size: 'sm',
                                    scope: $scope

                                });
                            }

                        });
                },
                    function () {
                    });
            };

            $scope.showUserStatus = function(person) {
                return person && (person.personStatus === 'SB_Active' || person?.personStatus === 'SB_Inactive' || person?.personStatus === 'SB_Not_logged_in');
            };

            $scope.showTitle = (person) => {
                return person?.isPupil === false || $scope.shouldShowPersonalFields();
            }

            $scope.showGender = (person) => {
                return person?.isParent === false && !(person?.isStaff === true && $scope.hideStaffGenders === true);
            }
        }
    ]});

angular.module('receptionModule').controller('ConfirmModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
