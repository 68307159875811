'use strict';

angular.module('transport.configuration.timetableCalendar.transportTimetableCalendars', [
    'transport.configuration.timetableCalendar.transportTimetableCalendarAdd',
]).component('transportTimetableCalendars', {
    templateUrl: '/Scripts/app/transport/configuration/timetableCalendar/transport-timetable-calendars.template.html',
    bindings: {
    },
    controller: [
        '$uibModal',
        '$scope',
        function transportTimetableCalendarsController($uibModal, $scope)
        {
            this.addTimetableCalendar = function ()
            {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimetableCalendarAdd',
                    size: 'lg',
                    backdrop: 'static'
                });

                modalInstance.result.then(
                    () => $scope.$broadcast('timetableCalendarsUpdated'),
                    () => {}
                );
            }.bind(this);
        }
    ]
});
