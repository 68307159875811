angular.module('ccaSignUp.services.signUpRepository', [

])
    .factory('signUpRepository',
    [
        '$http',
        '$q',
        '$cacheFactory',
        '$filter',
        'ccaService',
        function ($http, $q, $cacheFactory, $filter, ccaService) {

            var urlBase = '/CcaSignUp/';
            var urlsAPICloneBase = '/webapi/WebCcaSignUpCloneContainer/'
            var urlsAPIBase = '/webapi/WebOrganisationCcaSignUp/'  //new api admin
            var urlCcaPrefBase = '/webapi/WebCcaPreferenceTeam/';

            var signUpRepository = {};
            var repoCache = $cacheFactory('ccaSignUpServiceCache');

            signUpRepository.clearCache = function () {
                repoCache.removeAll();
            };

            this.filter = $filter;

            signUpRepository.getDaysOfWeek = function(fullDayNames)
            {
                return [
                    {
                        id: '1',
                        name: fullDayNames ? 'SB_WeekDay1' : 'SB_WeekShortDay1'
                    },
                    {
                        id: '2',
                        name: fullDayNames ? 'SB_WeekDay2' : 'SB_WeekShortDay2'
                    },
                    {
                        id: '3',
                        name: fullDayNames ? 'SB_WeekDay3' : 'SB_WeekShortDay3'
                    },
                    {
                        id: '4',
                        name: fullDayNames ? 'SB_WeekDay4' : 'SB_WeekShortDay4'
                    },
                    {
                        id: '5',
                        name: fullDayNames ? 'SB_WeekDay5' : 'SB_WeekShortDay5'
                    },
                    {
                        id: '6',
                        name: fullDayNames ? 'SB_WeekDay6' : 'SB_WeekShortDay6'
                    },
                    {
                        id: '0',
                        name: fullDayNames ? 'SB_WeekDay0' : 'SB_WeekShortDay0'
                    }
                ];
            }.bind(this);

            signUpRepository.rebuildCcaLinkedEntities = function (ccaSignUpGroup, requirementGroups) {
                ccaSignUpGroup.ccaTeamClubs = [];
                ccaSignUpGroup.ccaTeamTeams = [];
                ccaSignUpGroup.ccaTeamSubjectClasses = [];

                var i = 0;

                for (var index in requirementGroups) {
                    if (requirementGroups.hasOwnProperty(index)) {
                        var rGroup = requirementGroups[index];

                        for (i = rGroup.ccaTeamClubs.length; i--;)
                            ccaSignUpGroup.ccaTeamClubs.push({ id: rGroup.ccaTeamClubs[i], requirementGroupId: index });

                        for (i = rGroup.ccaTeamTeams.length; i--;)
                            ccaSignUpGroup.ccaTeamTeams.push({ id: rGroup.ccaTeamTeams[i], requirementGroupId: index });

                        for (i = rGroup.ccaTeamSubjectClasses.length; i--;)
                            ccaSignUpGroup.ccaTeamSubjectClasses.push({
                                id: rGroup.ccaTeamSubjectClasses[i],
                                requirementGroupId: index
                            });
                    }
                }
            };

            signUpRepository.initialiseLocalRequirementGroups = function($scope)
            {
                $scope.requirementGroups = [];
                $scope.requirementGroups[-1] = { ccaTeamClubs: [], ccaTeamTeams: [], ccaTeamSubjectClasses: [] };
                $scope.requirementGroups[0] = { ccaTeamClubs: [], ccaTeamTeams: [], ccaTeamSubjectClasses: [] };
                $scope.requirementGroups[1] = { ccaTeamClubs: [], ccaTeamTeams: [], ccaTeamSubjectClasses: [] };
            };

            signUpRepository.populateRequirementGroupsFromCcaSignup = function(signUpGroup, requirementGroups)
            {
                var i = 0;

                for (i = signUpGroup.ccaTeamClubs.length; i--;) {
                    var club = signUpGroup.ccaTeamClubs[i];

                    if (requirementGroups[club.requirementGroupId].ccaTeamClubs.indexOf(club.id) === -1) {
                        requirementGroups[club.requirementGroupId].ccaTeamClubs.push(club.id);
                    }
                }
                for (i = signUpGroup.ccaTeamTeams.length; i--;) {
                    var team = signUpGroup.ccaTeamTeams[i];

                    if (requirementGroups[team.requirementGroupId].ccaTeamTeams.indexOf(team.id) === -1) {
                        requirementGroups[team.requirementGroupId].ccaTeamTeams.push(team.id);
                    }
                }
                for (i = signUpGroup.ccaTeamSubjectClasses.length; i--;) {
                    var subjectClass = signUpGroup.ccaTeamSubjectClasses[i];

                    if (requirementGroups[subjectClass.requirementGroupId].ccaTeamSubjectClasses.indexOf(subjectClass.id) === -1) {
                        requirementGroups[subjectClass.requirementGroupId].ccaTeamSubjectClasses.push(subjectClass.id);
                    }
                }
            };

            signUpRepository.getTeamsLastEventDate = function (teamId, ccaSignUpId) {

                return $http
                    .get(urlCcaPrefBase + 'GetTeamsLastEventDate',
                    {
                         params:
                         {
                             teamId: teamId,
                             ccaSignUpId: ccaSignUpId
                         }
                    })
                    .then(function (data) { return data.data; })
                    .catch(function () { return { isError: true }; });
            };

            signUpRepository.createAdditionalEventsForActiveGroup = function (extension) {

                return $http
                    .post(urlCcaPrefBase + 'CreateAdditionalEventsForActiveGroup', extension)
                    .then(function (data) { return true; })
                    .catch(function () { return { isError: true }; });
            };

            signUpRepository.generateAdditionalEventsForActiveGroupsDatesAndDays = function (generateAdditionalEventsData)
            {
                return $http
                    .post(urlCcaPrefBase + 'GenerateAdditionalEventsForActiveGroupsDatesAndDays', generateAdditionalEventsData)
                    .then(function (data) { return true; })
                    .catch(function () { return { isError: true }; });
            };

            signUpRepository.getSignUps = function (signUpsFrom, signUpsTo, signUpTypeId, textSearch) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUps',
                    { params: { signUpsFrom: signUpsFrom, signUpsTo: signUpsTo, signUpTypeId: signUpTypeId, textSearch: textSearch, hash_id: new Date().getTime() } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getAvailableToCopy = function (signUpId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpsAvailableToCopy' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getReports = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetReports' + '?hash_id=' + (new Date().getTime()))
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getCcaForms = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetCcaForms' + '?hash_id=' + (new Date().getTime()))
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.runReport = function (reportId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'RunReport' + '?hash_id=' + (new Date().getTime()), { params: { reportId: reportId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.runParameterReport = function (reportDetails) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RunParameterReport' + '?hash_id=' + (new Date().getTime()),
                    { reportDetails: reportDetails })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getYears = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetYears' + '?hash_id=' + (new Date().getTime()))
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getLocationTypes = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLocationTypes' + '?hash_id=' + (new Date().getTime()))
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getOrganisers = function ()
            {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetOrganisers' + '?hash_id=' + (new Date().getTime()))
                    .then(function (data)
                    {
                        deferred.resolve(data.data);
                    })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getOrganisersForTeams = function (teamIds) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetOrganisersForTeams', { params: { hash_id: new Date().getTime(), teamIds: teamIds } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getOrganisersForStaffPersonIds = function (personIds)
            {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetOrganisersForStaffPersonIds', { params: { hash_id: new Date().getTime(), personIds: personIds } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSports = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSports', { cache: repoCache})
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getCalendarEventCategoryTypes = function (isPtc) {
                var deferred = $q.defer();

                // For PTC use event section type 3 (signups) and 5 (system)
                $http.get(urlBase + 'GetCalendarEventCategoryTypes', { params: { eventSection: isPtc ? [3, 5] : undefined, hash_id: new Date().getTime() } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getLocations = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLocations' + '?hash_id=' + (new Date().getTime()) )
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getAllLocations = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetAllLocations' + '?hash_id=' + (new Date().getTime()))
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getLocation = function (locationId) {
                if (locationId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLocation' + '?hash_id=' + (new Date().getTime()),
                    { params: { locationId: locationId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUp = function (signUpId)
            {
                if (signUpId === null)
                {
                    return null;
                }

                var deferred = $q.defer();
                $http.get('/webapi/WebCcaSignUp/GetSignUp' + '?hash_id=' + (new Date().getTime()), { params: { signUpId: signUpId } })
                    .then(function (data)
                    {
                        deferred.resolve(data.data);
                    })
                    .catch(deferred.reject);

                return deferred.promise;
            };

            signUpRepository.getSignUpDto = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpDto' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpGroups = function (signUpId)
            {
                if (signUpId === null)
                {
                    return null;
                }

                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    {
                        params:
                        {
                            signUpId: signUpId,
                            includeExtendingEvents: false
                        }
                    })
                    .then(function (data)
                    {
                        deferred.resolve(data.data);
                    })
                    .catch(deferred.reject);

                return deferred.promise;
            };

            signUpRepository.getSignUpGroupsForAdditionalEvents = function (signUpId)
            {
                if (signUpId === null)
                {
                    return null;
                }

                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    {
                        params:
                        {
                            signUpId: signUpId,
                            includeExtendingEvents: true
                        }
                    })
                    .then(function (data)
                    {
                        deferred.resolve(data.data);
                    })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpGroupsForCancelOrDeleteEvents = function (signUpId)
            {
                return $http.get(urlBase + 'GetSignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    {
                        params:
                        {
                            signUpId: signUpId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            signUpRepository.getNonSignUpGroups = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetNonSignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpFeeGroups = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpFeeGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpEvents = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.doEventsExistForSignUpGroupsDateRangeAndDaysOfWeek =
                function (signUpGroupIds, fromDate, toDate, daysOfWeek)
            {
                var deferred = $q.defer();
                $http.get(urlBase + 'DoEventsExistForSignUpGroupsDateRangeAndDaysOfWeek' + '?hash_id=' + (new Date().getTime()),
                    {
                        params:
                        {
                            signUpGroupIds: signUpGroupIds,
                            fromDate: fromDate,
                            toDate: toDate,
                            daysOfWeek: daysOfWeek
                        }
                    })
                    .then(function (data)
                    {
                        deferred.resolve(data.data);
                    })
                    .catch(deferred.reject);
                return deferred.promise;
                };

            signUpRepository.getSignUpGroupsWithEventsForDeletionOrCancellation =
                function (signUpGroupIds, fromDate, toDate, daysOfWeek)
                {
                    return $http.get(urlBase + 'GetSignUpGroupsWithEventsForDeletionOrCancellation' + '?hash_id=' + (new Date().getTime()),
                        {
                            params:
                            {
                                signUpGroupIds: signUpGroupIds,
                                fromDate: fromDate,
                                toDate: toDate,
                                daysOfWeek: daysOfWeek
                            }
                        });
                };

            signUpRepository.cancelOrDeleteSignUpGroupEvents
                = function (cancelOrDeleteRequest)
                {
                    return $http.put(urlBase + 'CancelOrDeleteSignUpGroupEvents' + '?hash_id=' + (new Date().getTime()), cancelOrDeleteRequest)
                        .then(function (response)
                        {
                            return response.data;
                        });
                }

            signUpRepository.getSignUpEventsTimeSlotted = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpEventsTimeSlotted' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getStaffWithBookedEvents = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetStaffWithBookedEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getStaffWithAllEvents = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetStaffWithAllEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getCcaStaffAttendees = function (owningPersonId, teamId, title, includeEmptyEvents) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetCcaStaffAttendees' + '?hash_id=' + (new Date().getTime()),
                    {
                        params: {
                            owningPersonId: owningPersonId,
                            teamId: teamId,
                            title: title,
                            includeEmptyEvents: includeEmptyEvents
                        }
                    })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpEventsForPupil = function (signUpId, pupilId, limitToEligible) {
                limitToEligible = limitToEligible ||false;

                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpEventsForPupil' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, pupilId: pupilId, limitToEligible: limitToEligible } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.saveBookings = function (bookings, pupils, signUpId, isConfirmed) {
                repoCache.removeAll();
                var deferred = $q.defer();

                // strip down the dto for network performance (and to avoid json serialisation exception on server-side).
                bookings = ccaService.simplifyBookingsDto(bookings);

                $http.post(urlBase + 'SaveBookings',
                    { dto: bookings, pupils: pupils, signUpId: signUpId, isConfirmed: isConfirmed }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postPaidBookings = function (bookings, pupils, signUpId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveConfirmedBookings', { dto: bookings, pupils: pupils, signUpId: signUpId })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            // This function is not used.
            // AvalableRepository contains a postToggleBooking function that is used for toggling Instant Book event bookings,
            // but this one is not used.
            signUpRepository.postToggleBooking = function (pupil, anEvent)
            {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'ToggleBooking', { pupilId: pupil.personId, eventId: anEvent.calendarEventId })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postToggleBookingLite = function (pupil, anEvent) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'ToggleBookingLite', {
                    pupilId: pupil.personId,
                    eventId: anEvent.calendarEventId
                })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpBookedEvents = function (signUpId, pupilId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpBookedEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, pupilId: pupilId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeletePupilEvent = function (eventId, pupilId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'DeletePupilEvent', { pupilId: pupilId, eventId: eventId }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postStaffDeletePupilEvent = function (eventId, pupilId, owningPersonId, teamId, title, includeEmpties) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'StaffDeletePupilEvent',
                    {
                        pupilId: pupilId,
                        eventId: eventId,
                        owningPersonId: owningPersonId,
                        teamId: teamId,
                        title: title,
                        includeEmpties: includeEmpties
                    })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPendingSignUpGroupEvents = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPendingSignUpGroupEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpDuplicateGroups = function (signUpId, fromSignUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpDuplicateGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, fromSignUpId: fromSignUpId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getIncompleteSignUpGroups = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetIncompleteSignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getDepthSignUpGroups = function (signUpId, depth) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetDepthSignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, depth: depth } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getAllocationSummarySignUpGroups = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetAllocationSummarySignUpGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPupilSelectionBaseData = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilSelectionBaseData' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPupilBaseData = function (signUpId, personId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilBaseData' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, personId: personId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPupilSummarySignUp = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilSummarySignUp' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPupilSelectionSignUp = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilSelectionSignUp' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getActiveSessionsSignUp = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetActiveSessionsSignUp' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSinglePupilSummarySignUp = function (signUpId, personId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSinglePupilSummarySignUp' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, personId: personId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSinglePupilSelectionSignUp = function (signUpId, personId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSinglePupilSelectionSignUp' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, personId: personId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpCounters = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpCounters' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpBlockDates = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpBlockDates' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getOrganisationBlockDates = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetOrganisationBlockDates' + '?hash_id=' + (new Date().getTime())).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpGroup = function (signUpId, signUpGroupId) {
                if (signUpId === null || signUpGroupId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroup' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, signUpGroupId: signUpGroupId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpAndGroup = function (signUpId, signUpGroupId) {
                if (signUpId === null || signUpGroupId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpAndGroup' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, signUpGroupId: signUpGroupId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpGroupPupils = function (signUpId, signUpGroupId) {
                if (signUpId === null || signUpGroupId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroupPupils' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, signUpGroupId: signUpGroupId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPupilPreferences = function (signUpId, personId)
            {
                if (signUpId === null || personId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilPreferences' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, personId: personId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getPupilInformation = function (signUpId, personId)
            {
                if (signUpId === null || personId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilInformation' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, personId: personId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getSignUpGroupEvents = function (signUpId, signUpGroupId) {
                if (signUpId === null || signUpGroupId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroupEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, signUpGroupId: signUpGroupId } }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository
                .getCcaCalendarEventCreationRule = function (signUpId, signUpGroupId, ccaCalendarEventCreationRuleId) {
                    if (signUpId === null || signUpGroupId === null) return null;
                    var deferred = $q.defer();
                    $http.get(urlBase + 'GetCcaCalendarEventCreationRule' + '?hash_id=' + (new Date().getTime()),
                        {
                            params: {
                                signUpId: signUpId,
                                signUpGroupId: signUpGroupId,
                                ccaCalendarEventCreationRuleId: ccaCalendarEventCreationRuleId
                            }
                        }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                    return deferred.promise;
                };

            signUpRepository.getCcaEventAttendees = function (eventId) {
                if (eventId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetEventAttendees' + '?hash_id=' + (new Date().getTime()),
                    { params: { eventId: eventId } }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.post = function (signUp) {
                var deferred = $q.defer();
                $http.post('/webapi/WebCcaSignUp/SaveSignUp' + '?hash_id=' + (new Date().getTime()), signUp)
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postArchiveSignUp = function (signUpId, archiveIds) {
                var deferred = $q.defer();
                $http.post(urlCcaPrefBase + 'ArchiveSignUp?hash_id=' + (new Date().getTime()) + '&signUpId=' + signUpId,
                    archiveIds).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };
            signUpRepository.postArchiveSignUpChanges = function (changes) {

                return $http.post(urlCcaPrefBase + 'ChangeGroupArchiveStatus', changes)
                    .then(function (data) {
                        return data.data;
                    })
                    .catch(function (error) {
                        return {
                            isError: true
                        };
                    });

            };
            signUpRepository.postCopyToSignUp = function (signUpId, copyIds, fromSignUpId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/CopyToSignUp' + '?hash_id=' + (new Date().getTime()),
                    { signUpId: signUpId, copyIds: copyIds, fromSignUpId: fromSignUpId }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postAddExistingGroup = function (signUpId, groupId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/AddExistingGroup' + '?hash_id=' + (new Date().getTime()),
                    { signUpId: signUpId, groupId: groupId }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postProcessDepth = function (signUpId, depth, maxAllocationType, maxAllocationAllowed) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/AllocateSignUpDepth' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        depth: depth,
                        maxAllocationType: maxAllocationType,
                        maxAllocationAllowed: maxAllocationAllowed
                    })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postFastAllocation = function (signUpId, overSubscribedOption, confirmedOnly, maxAllocationType, maxAllocationAllowed) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/FastAllocateSignUp' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        overSubscribedOption: overSubscribedOption,
                        confirmedOnly: confirmedOnly,
                        maxAllocationType: maxAllocationType,
                        maxAllocationAllowed: maxAllocationAllowed
                    })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postClearAllocation = function (signUpId, selectionType) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/ClearAllocationSignUp' + '?hash_id=' + (new Date().getTime()),
                    { signUpId: signUpId, selectionType: selectionType }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postGenerateEvents = function (signUpId, additionalInformation, sendCommunication) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/GenerateEvents' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        additionalInformation: additionalInformation,
                        sendCommunication: sendCommunication
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postGenerateSelectedEvents = function (signUpId, eventCreationRuleIds, additionalInformation, sendCommunication)
            {
                var deferred = $q.defer();
                $http.post(urlBase + 'GenerateSelectedEvents' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        eventCreationRuleIds: eventCreationRuleIds,
                        additionalInformation: additionalInformation,
                        sendCommunication: sendCommunication
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postGenerateFees = function (signUpId, eventTitle) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/GenerateFees' + '?hash_id=' + (new Date().getTime()),
                    { signUpId: signUpId, eventTitle: eventTitle }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postGroup = function (signUpGroup) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveSignUpGroup' + '?hash_id=' + (new Date().getTime()), { dto: signUpGroup })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postAndFetchGroup = function(signUpGroup) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveAndFetchSignUpGroup' + '?hash_id=' + (new Date().getTime()), { dto: signUpGroup })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.savePreferences = function (groups, signUpId, personId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SavePreferences' + '?hash_id=' + (new Date().getTime()),
                    { dto: groups, signUpId: signUpId, personId: personId }).then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };


            signUpRepository.postLocation = function (location) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveLocation' + '?hash_id=' + (new Date().getTime()), { dto: location })
                    .then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postSelection = function (signUpId, signUpGroupId, signUpGroupPupils, status) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveSignUpGroupPupils' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        signUpGroupId: signUpGroupId,
                        dto: signUpGroupPupils,
                        status: status
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postGroupStatus = function (signUpId, signUpGroupId, status) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveSignUpGroupStatus' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        signUpGroupId: signUpGroupId,
                        status: status
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postBlockdate = function (signUpId,
                ccaSignUpExcludedId,
                excludedFrom,
                excludedTo,
                reason,
                isDeleted) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveSignUpBlockdate' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        ccaSignUpExcludedId: ccaSignUpExcludedId,
                        excludedFrom: excludedFrom,
                        excludedTo: excludedTo,
                        reason: reason,
                        isDeleted: isDeleted
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeleteBlockDate = function (signUpId, ccaSignUpExcludedId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/DeleteSignUpBlockdate' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        ccaSignUpExcludedId: ccaSignUpExcludedId
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeleteGroupEvent = function (signUpId, signUpGroupId, ccaCalendarEventCreationRuleId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/DeleteSignUpGroupEvent' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        signUpGroupId: signUpGroupId,
                        ccaCalendarEventCreationRuleId: ccaCalendarEventCreationRuleId
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeleteGroupEventNoReturns = function (signUpId, signUpGroupId, ccaCalendarEventCreationRuleId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/DeleteSignUpGroupEventNoReturn' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        signUpGroupId: signUpGroupId,
                        ccaCalendarEventCreationRuleId: ccaCalendarEventCreationRuleId
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeleteSignUp = function (signUpId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/DeleteSignUp' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeleteGroup = function (signUpId, signUpGroupId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/DeleteSignUpGroup' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        signUpGroupId: signUpGroupId
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postDeleteLocation = function (locationId) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/DeleteLocation' + '?hash_id=' + (new Date().getTime()),
                    {
                        locationId: locationId
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postPupilSelection = function (signUpId, teamId, personId, add) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SavePupilSelection' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        teamId: teamId,
                        personId: personId,
                        add: add
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postPupilSummarySelection = function (signUpId, teamId, personId, add) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SavePupilSummarySelection' + '?hash_id=' + (new Date().getTime()),
                    {
                        signUpId: signUpId,
                        teamId: teamId,
                        personId: personId,
                        add: add
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postTeacherConfirm = function (groups, signUpId, personId, notes) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/ConfirmPupilSelection' + '?hash_id=' + (new Date().getTime()),
                    {
                        dto: groups,
                        signUpId: signUpId,
                        personId: personId,
                        notes: notes
                    }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.postGroupEvent = function (signUpGroupEvent) {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/SaveSignUpGroupEvent' + '?hash_id=' + (new Date().getTime()),
                    { dto: signUpGroupEvent }).then(function (data) { deferred.resolve(data.data); }).catch(deferred.reject);
                return deferred.promise;
            };

            signUpRepository.getAttendeesCsvUrl = function (calendarEventId)
            {
                return (urlBase + 'GetAttendeesCsv?calendarEventId=' + calendarEventId);
            }

            signUpRepository.getMenu= function ()
            {
                return;
            };

            signUpRepository.cloneSignUp = function (ccaSignUp)
            {
                if (ccaSignUp == null) {
                    return;
                };

                return $http.post(urlsAPICloneBase + 'CloneAsync', ccaSignUp);
            }

            signUpRepository.getTimeSlotsByCcaSignUpType = function (ccaSignUpTypeId)
            {
                return $http.get(urlsAPIBase + 'GetTimeSlotsByCcaSignUpType', {
                    params:
                    {
                        ccaSignUpTypeId: ccaSignUpTypeId
                    }
                })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            signUpRepository.getCurrentTransportTimetableCalendarsForTimeSlot = function (timeSlotId)
            {
                return $http.get(urlsAPIBase + 'GetCurrentTransportTimetableCalendarsForTimeSlot',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            signUpRepository.getSignupGroupStaffMembersFromIds = function (staffMemberIds)
            {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/GetSignupGroupStaffMembersFromIds' + '?hash_id=' + (new Date().getTime()),
                    {
                        staffMemberIds: staffMemberIds
                    })
                    .then(function (data)
                    {
                        deferred.resolve(data.data);
                    })
                    .catch(deferred.reject);

                return deferred.promise;
            };

            signUpRepository.updateDayAndTimesForGroups = function (updates)
            {
                var deferred = $q.defer();
                $http.post('/CcaSignUp/UpdateDayAndTimesForGroups' + '?hash_id=' + (new Date().getTime()),
                    {
                        updates: updates
                    })
                    .then(function (data) { deferred.resolve(data.data); })
                    .catch(deferred.reject);
                return deferred.promise;
            };

            return signUpRepository;
        }
    ]);
