angular.module('roles.components.userRolesEditorPopup', [
    'shared.components.sbModalHeader',
    'shared.directives.sbCheckBox',
    'shared.components.sbSubmitButton',
    'roles.services.rolesService',
    'sysadmin.components.userEmailLookup',
]).component('userRolesEditorPopup', {
    bindings: {
        resolve: '<', // our props hang off resolve with uibModal
        close: '&',
        dismiss: '&',
    },
    templateUrl: '/Scripts/app/roles/components/user-roles-editor-popup.template.html',
    controller: class UserRolesEditorPopupCtrl {

        // Dependencies
        resolve: any;
        dismiss: any;
        close: any;
        rolesService: any;
        messagingService: any;

        // Variables
        isSpecialUser: boolean;
        isLoadingRoles: boolean;
        originalRoles: Array<number>;
        isAddingUser: boolean;
        newUser: any;
        isSaving: boolean;
        anyChanges: boolean;
        sendEmailOnAdd: boolean;
        isSendingActivation: boolean;
        activationStates: any;
        personData: any;
        singleActivationSent: boolean;
        isLoadingPersonData: boolean;
        isPersonNotFound: boolean;
        isProcessing: boolean;
        // This component handles roles, as well as 'special' roles - eg Super User - based on resolve binding properties

        static $inject = ['rolesService', 'messagingService'];

        constructor(rolesService, messagingService) {
            this.rolesService = rolesService;
            this.messagingService = messagingService;
        }

        $onInit(): void {
            this.isAddingUser = false;
            this.newUser = {
                firstName: '',
                lastName: ''
            };
            this.setOriginalRoles();
            this.checkSpecialRole();
            this.anyChanges = false;
            this.isSendingActivation = false;
            this.sendEmailOnAdd = false;
            this.isLoadingPersonData = false;
            this.isPersonNotFound = false;
            this.isProcessing = false;
            this.activationStates = this.messagingService.activationStates;
        }

        $onChanges(changes): void {
            if (changes.resolve && changes.resolve.currentValue && changes.resolve.currentValue.state.personId) {
                this.getPersonData();
            }
        }

        ok(): void {
            this.isSaving = true;
            let rolesToAdd = [];
            let rolesToRemove = [];
            this.resolve.state.userRolesFilter.forEach(role => {
                if (role.value == true && !this.originalRoles.includes(role.id)) {
                    rolesToAdd.push(role.id);
                } else if (role.value == false && this.originalRoles.includes(role.id)) {
                    rolesToRemove.push(role.id);
                }
            })
            if (this.isAddingUser) {
                // Adding new user
                const newStaff = { ...this.newUser, isStaff: true };
                this.rolesService.addPerson(this.resolve.organisationId, newStaff)
                    .then(addRes => {
                        const handleMessage = () => {
                            if (this.sendEmailOnAdd) {
                                this.messagingService.welcomeStaff(addRes.personId, this.resolve.organisationId)
                                    .then(() => {
                                        this.isSaving = false;
                                        this.sendEmailOnAdd = false;
                                        this.close();
                                    })
                            } else {
                                this.isSaving = false;
                                this.close();
                            }
                        }
                        // Add roles to new user
                        if (rolesToAdd.length > 0) {
                            this.rolesService.updateUserRoles(this.resolve.organisationId, addRes.personId, rolesToAdd, rolesToRemove)
                                .then(() => {
                                    handleMessage();
                                })
                        } else {
                            handleMessage();
                        }
                    })
            } else {
                // Editing existing user
                this.rolesService.updateUserRoles(this.resolve.organisationId, this.resolve.state.personId, rolesToAdd, rolesToRemove)
                    .then(() => {
                        this.isSaving = false;
                        this.close();
                    })
            }
        };

        cancel(): void {
            this.dismiss();
        };

        setSpecialRoles(): void {
            this.resolve.state.userRolesFilter.forEach(role => {
                if (this.resolve.state.specialRoles.includes(role.id)) {
                    role.value = true;
                }
            });
            this.isSpecialUser = true;
            this.anyChanges = true;
            this.resolve.state.userRolesFilter = [...this.resolve.state.userRolesFilter];
        };

        rolesChanged(roles: string[]) {
            this.resolve.state.userRolesFilter.forEach(userRole => {
                userRole.value = roles.includes(userRole.name);
            });

            this.onChangeRoles();
        }

        onChangeRoles(): void {
            this.anyChanges = true;
            this.checkSpecialRole();
            this.resolve.state.userRolesFilter = [...this.resolve.state.userRolesFilter];
        };

        checkSpecialRole(): void {
            let selectedRoles = this.resolve.state.userRolesFilter.filter(role => {
                return role.value === true;
            });
            let selectedIds = selectedRoles.map(role => {
                return role.id;
            });
            this.isSpecialUser = selectedIds.includes(this.resolve.state.specialMainRole);
        }

        clearPerson(): void {
            this.resolve.state.personName = '';
            this.resolve.state.personEmail = '';
            this.resolve.state.userRolesFilter.forEach(role => {
                role.value = false;
            });
        };

        onGetPerson(personId: number) {
            if (personId) {
                this.isAddingUser = false;
                this.isLoadingRoles = true;
                this.rolesService.getUserRoles(this.resolve.organisationId, personId)
                    .then(activeRoles => {
                        this.resolve.state.userRolesFilter.forEach(role => {
                            role.value = activeRoles.data.includes(role.id);
                        });
                        this.setOriginalRoles();
                        this.checkSpecialRole();
                        this.isLoadingRoles = false;
                        this.getPersonData();
                    })
            } else {
                this.isAddingUser = true;
                this.newUser.firstName = '';
                this.newUser.lastName = '';
            }
        }

        getPersonData() {
            this.isLoadingPersonData = true;
            this.isPersonNotFound = false;
            this.isProcessing = false;
            this.rolesService.getPerson(this.resolve.organisationId, this.resolve.state.personId)
                .then(data => {
                    this.personData = data;
                    this.isLoadingPersonData = false;
                    if (!this.personData.personStatus) {
                        this.isProcessing = true;
                    }
                })
                .catch(() => {
                    this.isPersonNotFound = true;
                    this.isLoadingPersonData = false;
                })
        }

        disableRolesForm(): boolean {
            const validateName = (name) => { return typeof name == 'string' && name.length > 0 };
            return this.isLoadingRoles ||
                (!this.isAddingUser && !this.resolve.state.personId) ||
                (this.isAddingUser && (!validateName(this.newUser.firstName) || !validateName(this.newUser.lastName)))

        }

        disableSubmit(): boolean {
            return this.disableRolesForm() || (!this.isAddingUser && !this.anyChanges);
        }

        applyNewEmail(emailAddress: string): void {
            this.isAddingUser = false;
            this.newUser.emailAddress = emailAddress;
        }

        setOriginalRoles(): void {
            this.originalRoles = [];
            this.resolve.state.userRolesFilter.forEach(role => {
                if (role.value === true) {
                    this.originalRoles.push(role.id);
                }
            })
        }

        sendAccessRequestEmail(): void {
            this.isSendingActivation = true;
            const person = { ...this.personData, id: this.resolve.state.personId };
            this.messagingService.sendSingleAccessRequestEmail(person, this.resolve.organisationId)
                .then(() => {
                    this.isSendingActivation = false;
                    this.singleActivationSent = true;
                })
                .catch(() => {
                    this.isSendingActivation = false;
                });
        }

        getActivationLabel(): string {
            if (this.isAddingUser) {
                return 'SB_Send_Welcome_Message';
            }
            else if (this.isPersonNotFound) {
                return 'SB_UNAVAILABLE_TEXT';
            }
            else if (this.isProcessing) {
                return 'SB_Processing';
            }
            else {
                return this.messagingService.getActivationLabel(this.personData);
            }
        };

        isLoading(): boolean {
            return (this.isSendingActivation || this.isLoadingPersonData || !this.getActivationLabel()) && !this.isPersonNotFound;
        }

        disableAccessRequest() {
            return this.isSendingActivation || this.singleActivationSent || this.isLoadingPersonData || !this.getActivationLabel() || this.isPersonNotFound || this.isProcessing;
        }

    }
});
