angular.module('profileModule', [

    'ui.bootstrap',

    'pascalprecht.translate',
    'tmh.dynamicLocale',


    'group.directives.linkedGroupEditor',

    'profile.components.diary',
    'profile.components.linkedGroups',
    'profile.components.linkedProfiles',
    'profile.controllers.profilePopupController',
    'profile.components.profileMedical',
    'profile.components.profileUserForm',
    'profile.components.singleSignOn',
    'profile.components.welcome',
    'profile.components.profileOnlineLocation',

    'profile.components.userForm',
    'profile.components.welcomeMessage',
    'profile.components.permissionToWalk',

    'profile.directives.linkedProfileEditor',
    'profile.directives.profileMedicalEditor',
    'profile.directives.singleSignOnEditor',

    'transport.services.transportPermissionToWalkService',

    'person.services',
    'groups.services.groupService',

    'userForm.components.capture',
    'diary.components.diaryEventList',
    'organisation.services.organisationService',


    'shared.components.sbSubmitButton',

    'shared.directives.sbPrint',
    'shared.directives.updateLanguage',
    'shared.directives.cloakTranslate',
    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlPlaceholder',
    'shared.directives.ngBindHtmlTitleAttr',

    'shared.services.authInterceptor',
])
.component('ng1ProfileWelcome', {
      template: '<welcome member-type="$ctrl.memberType"></welcome>',
    })
.component('ng1ProfileLinked', {
      template: '<linked-profiles selected-person="$ctrl.selectedPerson" is-organisation-admin="$ctrl.isOrganisationAdmin" is-popup="$ctrl.isPopup" organisation-type-id="$ctrl.organisationTypeId"></linked-profiles>',
    })
.component('ng1ProfileGroups', {
      template: '<linked-groups selected-person="$ctrl.selectedPerson" is-organisation-admin="$ctrl.isOrganisationAdmin" is-staff="$ctrl.isStaff"></linked-groups>',
    })
.component('ng1ProfilePupilMedical', {
      template: '<profile-medical selected-person="$ctrl.selectedPerson" is-organisation-admin="$ctrl.isOrganisationAdmin" mis-managed="$ctrl.misManaged"></profile-medical>',
    })
.component('ng1ProfileForms', {
      template: '<profile-user-form selected-person="$ctrl.selectedPerson" is-organisation-admin="$ctrl.isOrganisationAdmin"></profile-user-form>',
    })
.component('ng1ProfileSso', {
      template: '<single-sign-on email-address="$ctrl.emailAddress" has-sso="$ctrl.hasSso"></single-sign-on>',
    })
.component('ng1ProfilePermissionToWalk', {
  template: '<permission-to-walk selected-person="$ctrl.selectedPerson"></permission-to-walk>',
})
.component('ng1ProfileDiary', {
  template: '<diary selected-person="$ctrl.selectedPerson"></diary>',
})
