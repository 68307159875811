'use strict';

angular.module('shared.services.consentEventTabsService', [])
    .service('consentEventTabsService', class ConsentEventTabsService {
        settings: any;
        baseUrl: string = 'OrganisationSettings/DefaultUseNewConsentTabsSetting';
        httpCoreApi: any;
        urlBase: string;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.User
            };
        }

        getDefaultUseNewConsentTabsSetting = function () {
            return this.httpCoreApi.get(this.baseUrl, this.settings);
        }
    });
