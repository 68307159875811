'use strict';

angular.module('profile.components.welcome', [])
    .component('welcome', {
        templateUrl: '/Scripts/app/profile/components/welcome.template.html',
        bindings: {
            memberType: '<'
        },
        controller: [
            function () {
            }
        ]
    });