import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { DataTableService } from '@sb-shared/services/data-table.service';
import { FileTypeId } from './../shared/globals/file-types';
import { tableProperties } from '@sb-shared/models/UI/table';
import { UserService } from "@sb-shared/services/user.service";
import { PdfService } from "@sb-shared/services/pdf.service";
import * as FileSaver from "file-saver-es";
import { OrganisationService } from "@sb-shared/services/organisation.service";

interface Ng1PdfObject {
  boldFirstColumn: boolean;
  data: string[][];
  description: string;
  headings: string[];
  isLandScape: boolean;
  showOrgInfo: boolean;
  showUserInfo: boolean;
}


@Injectable()
export class Ng1PrinceService {

  constructor(private dataTable: DataTableService,
    private translate: TranslateService,
    private userService: UserService,
    private pdfService: PdfService,
    private organisationService: OrganisationService) { }

  exportDataPdf(content: Ng1PdfObject, fileName: string, deferred: any) {
    let title: string;
    let subTitle: string;
    const headings = content.headings;
    this.organisationService.getCurrentOrganisation()
      .subscribe(org => {
        const digitsInfo = `1.${org.currencyExponent}-${org.currencyExponent}`;

        if (content.showUserInfo) {
          this.userService.getUserNameFormatted().subscribe(name => headings.push(name));
        }
        const translate = cell => cell ? this.translate.instant(cell) : '';
        const columns = content.data[0].map((cell, index) => {
          return {
            id: cell == tableProperties.SpecialColumns.avatar.id ? cell : index.toString(),
            name: translate(cell)
          }
        });
        const data = content.data.splice(1).map(row => row.map(cell => translate(cell)));
        if (headings) {
          title = headings[0];
          subTitle = headings[1];
        }
        const exportData = {
          title: title,
          subtitle: subTitle,
          columns: columns,
          data: data,
          rowStateColumnId: null,
          fileTypeId: FileTypeId.PDF
        }
        this.dataTable.export(exportData, digitsInfo, fileName).subscribe(() => {
          deferred.resolve(true);
        });
      });
  }

  generateWithPdfMake(data, fileName: string, deferred: any) {
    this.pdfService.generateWithPdfMake(data, fileName).subscribe((data) => {
      if (data) {
        FileSaver.saveAs(data, `${fileName}.pdf`);
      }
      deferred.resolve(true);
    });
  }
}
