angular.module('tuitionFeesModule')
    .component('invoiceDetailViewNotes', {
        bindings: {
            invoiceNotes: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-notes.template.html',
        controller: class InvoiceDetailViewNotesCtrl {

            // bindings
            invoiceNotes: any[];

            // variables
            showNoResult: boolean;

            constructor() {
                this.showNoResult = false;
            }

            $onChanges(changes) {
                if (changes.invoiceNotes?.currentValue) {
                    this.showNoResult = !changes.invoiceNotes?.currentValue || changes.invoiceNotes?.currentValue?.length == 0;
                }
            }
        }
    });