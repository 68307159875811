'use strict';

angular.module('sysadmin.services.calendarEventTypesService', [])
    .factory('calendarEventTypesService', [
        '$http', '$cacheFactory',
        function ($http)
        {
            var urlBase = '/webapi/WebMainSettings/';
            var repository = {};

            repository.getCalendarEventTypes = function (organisationId)
            {
                return $http.get(urlBase + 'GetCalendarEventTypes', { params: { organisationId: organisationId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            repository.updateCalendarEventTypes = function (organisationId, calendarEventTypes)
            {
                return $http.post(urlBase + 'UpdateCalendarEventTypes', { organisationId: organisationId, calendarEventTypes: calendarEventTypes })
                    .then(function (response) { return response.data; });
            };

            repository.createCalendarEventType = function (newCalendarEventType)
            {
                return $http.post(urlBase + 'CreateCalendarEventCategoryType', newCalendarEventType)
                    .then(function (response) { return response.data; });
            };

            return repository;
        }
    ]);