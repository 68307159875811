angular.module('sysadmin.components.colours', [
    'colours.services.colourService',
    'colours.components.colourPicker',
    'shared.filters.decoded',
    'shared.components.sbOnOffSwitch',
    'shared.services.installationSettingsService',
    'sysadmin.services.sysAdminService',
]).component('colours', {
    templateUrl: '/Scripts/app/sysadmin/components/colours.template.html',
    bindings: {
        organisation: '<',
    },
    controller: class ColoursCtrl {
        // Dependencies
        $document: any;
        $filter: any;
        $rootScope: any;
        colourService: any;
        installationSettingsService: any;
        sysAdminService: any;
        // variables
        organisation: any;
        colours: any;
        originalColours: any;
        browserColours: any;
        anyChanges: boolean;
        isLoadingColours; boolean;

        static $inject = ['$document', '$filter', '$rootScope', 'colourService', 'installationSettingsService', 'sysAdminService'];

        constructor($document, $filter, $rootScope, colourService, installationSettingsService, sysAdminService) {
            this.$document = $document;
            this.$filter = $filter;
            this.$rootScope = $rootScope;
            this.colourService = colourService;
            this.installationSettingsService = installationSettingsService;
            this.sysAdminService = sysAdminService;
        }

        $onInit() {
            this.colours = {};
            this.originalColours = {};
            // Reset colours when leaving page
            this.browserColours = this.colourService.getBrowserColours();
            this.$rootScope.$on('$locationChangeSuccess', () => {
                this.setBrowserColours(this.browserColours);
            });
        }

        $onChanges(changes)
        {
            if (changes.organisation && changes.organisation.currentValue != null)
            {
                this.getColours();
            }
        };

        saveColours()
        {
            this.colourService.updateColours(this.organisation.organisationId, this.colours)
            .then(res => {
                if (res.isSuccess) {
                    this.anyChanges = false;
                }
            })
        };

        cancel() {
            this.colours = {...this.originalColours};
            this.setBrowserColours(this.originalColours);
        }

        getColours() {
            this.isLoadingColours = true;
            // Needs a more specific endpoint
            this.colourService.getColours(this.organisation.organisationId)
            .then(data => {
                const colours = data;
                // Check for existing colours. There should always either be both or neither.
                if (colours.primaryColour) {
                    this.colours = colours;
                    this.originalColours = {...this.colours}
                } else {
                    // Else load default colours
                    this.installationSettingsService.getDefaultColours().then(defaultColours => {
                        this.colours = defaultColours;
                        this.originalColours = {...this.colours}
                    });
                }
                this.setBrowserColours(this.colours);
                this.anyChanges = false;
                this.isLoadingColours = false;
            })
            .catch(err => {
                console.log(err);
                this.isLoadingColours = false;
            });
        }

        onChangeColours (colours) {
            if (colours) {
                this.colours = {...colours}
                this.anyChanges = true;
            }
        }

        setBrowserColours(colours) {
            this.colourService.setBrowserColours(colours);
        };

    }
});
