angular.module('settingsModule')
    .component('onboarding',
        {
            templateUrl: '/Scripts/app/settings/components/onboarding/onboarding.template.html',
            controller: class OnboardingCtrl {

                $state: any;
                onboardingService: any;

                subMenuItems: any;

                static $inject = ['$state', 'onboardingService'];

                constructor ($state, onboardingService) {
                    this.$state = $state;
                    this.onboardingService = onboardingService;
                }

                $onInit() {
                    this.onboardingService.getOnboardingMenuItems().then(items => this.subMenuItems = items);
                }
            }
        });
