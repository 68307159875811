'use strict';

angular.module('balanceAdmin.massAdjustmentStudent.components.massAdjustmentStudentDetail',
    [
        'balanceAdmin.components.massAdjustmentQuickButtons',
        'shared.components.sbDateRangePicker',
        'shared.constants'
    ])
    .component('massAdjustmentStudentDetail',
        {
            bindings: {
                studentId: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/massAdjustmentStudent/components/mass-adjustment-student-detail.template.html',
            controller: [
                '$filter',
                '$uibModal',
                'balanceAdminService',
                'simpleAlertService',
                'arrayService',
                'dateRangeLists',
                function (
                    $filter,
                    $uibModal,
                    balanceAdminService,
                    simpleAlertService,
                    arrayService,
                    dateRangeLists) {

                    this.charges = [];
                    this.isLoading = true;
                    this.sortType = 'transactionDate';
                    this.sortReverse = false;
                    this.refundClassification = 1;
                    this.description = '';
                    this.adjustVal = null;
                    this.adjustmentSubmitted = false;
                    this.adjustmentSubmitConfirmed = false;
                    this.includeFullyAdjusted = false;

                    this.dateRangeLists = dateRangeLists;

                    // Default search window to last three months
                    this.transactionsFrom = moment().startOf('day').add(-3, 'months');
                    this.transactionsTo = moment().endOf('day').add(1, 'years');

                    this.$onChanges = function (changes) {
                        if (changes.studentId && this.studentId) {
                            this.refresh();
                        }
                    }.bind(this);

                    this.customFilter = function (value)
                    {
                        var transactionDate = new Date(value.transactionDate);
                        return transactionDate >= this.transactionsFrom &&
                            transactionDate <= this.transactionsTo &&
                            (this.includeFullyAdjusted || value.availableToAdjust > 0);
                    }.bind(this);

                    this.refresh = function () {
                        if (!this.transactionsFrom || !this.transactionsTo || this.transactionsFrom > this.transactionsTo) {
                            return;
                        }

                        // Date picker puts date back to start of selected day.
                        this.transactionsTo = moment.utc(this.transactionsTo).endOf('day').toDate();

                        this.isLoading = true;
                        this.charges = [];

                        // Get account charges
                        balanceAdminService.getChargesForAccount(this.studentId, this.transactionsFrom, this.transactionsTo)
                            .then(function (charges) {
                                this.charges = charges;

                                var allGroups = this.charges.map(function (charge) {
                                    return {
                                        name: charge.groupName,
                                        id: charge.groupId
                                    };
                                });

                                this.groups = arrayService.uniqueBy(allGroups,
                                    function (group) { return group.id; });

                                this.groups.unshift({
                                    name: 'SB_All'
                                });

                                this.isLoading = false;
                            }.bind(this))
                            .catch(function (err) {
                                console.log(err);
                                simpleAlertService.errorAlert({ message: 'Unable to load student charges' });
                            });
                    }.bind(this);

                    this.showQuickAdjust = function () {

                        var quickAdjustModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'massAdjustmentQuickButtons',
                            size: 'lg'
                        });

                        quickAdjustModal.result
                            .then(function (data) {
                                if (data.adjustOption == 1) { // custom amount
                                    this.adjustByValue(data.customValue);
                                }
                                else if (data.adjustOption == 2) { // clear total fee
                                    this.adjustByMaxAvailable();
                                }
                                else { // clear outstanding charges
                                    this.adjustByChargeOutstanding();
                                }
                            }.bind(this))
                            .catch(function () {
                            });

                    }.bind(this);

                    this.adjustByMaxAvailable = function () {
                        var filteredCharges = $filter('filter')(this.charges, { groupId: this.selectedGroupId });
                        angular.forEach(filteredCharges, function (charge) {
                            charge.newAdjustment = charge.availableToAdjust;
                        });
                    }.bind(this);

                    this.adjustByChargeOutstanding = function () {
                        var filteredCharges = $filter('filter')(this.charges, { groupId: this.selectedGroupId });
                        angular.forEach(filteredCharges, function (charge) {
                            charge.newAdjustment = charge.chargeOutstanding;
                        });
                    }.bind(this);

                    this.adjustByValue = function (customValue) {
                        if (typeof customValue === 'undefined') {
                            return;
                        }

                        var filteredCharges = $filter('filter')(this.charges, { groupId: this.selectedGroupId });
                        angular.forEach(filteredCharges, function (charge) {
                            // cap adjustment at the maximum available
                            charge.newAdjustment = customValue <= charge.availableToAdjust
                                ? customValue
                                : charge.availableToAdjust;
                        }.bind(this));
                    }.bind(this);

                    this.confirmAdjustments = function () {
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Confirm',
                            message: 'SB_Confirm_Adjustments',
                            windowSize: 'sm',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel'
                        });

                        modalInstance.result
                            .then(function () {
                                this.adjustmentSubmitConfirmed = true;
                                this.submitAdjustments()
                                    .then(function () {
                                        this.adjustmentSubmitted = false;
                                    }.bind(this));
                            }.bind(this), function () {
                                this.adjustmentSubmitted = false;
                            }.bind(this));
                    }

                    this.submitAdjustments = function () {
                        var adjustments = [];
                        var filteredCharges = $filter('filter')(this.charges, { groupId: this.selectedGroupId });
                        angular.forEach(filteredCharges, function (charge) {
                            adjustments.push(
                                {
                                    transactionId: charge.id,
                                    amountToAdjust: charge.newAdjustment
                                });
                        });

                        return balanceAdminService.massAdjustment(adjustments,
                            this.description,
                            1, // always refund to the wallet account
                            this.refundClassification)
                            .then(function (massAdjustResponse) {
                                this.refundClassification = 1;
                                this.description = '';
                                this.adjustVal = null;
                                this.adjustmentSubmitConfirmed = false;

                                if (!massAdjustResponse || !massAdjustResponse.failedAdjustments || massAdjustResponse.failedAdjustments.length > 0) {
                                    simpleAlertService.errorAlert({
                                        message: 'SB_Error_Saving'
                                    });
                                    this.refresh();
                                    return;
                                }

                                if (massAdjustResponse.failedAdjustments.length == 0) {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Success',
                                        message: 'SB_Success'
                                    });
                                    this.refresh();
                                    return;
                                }
                            }.bind(this), function () {
                                this.adjustmentSubmitConfirmed = false;

                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_Saving_Message'
                                });
                            });
                    }.bind(this);
                }
            ]
        });