// start:ng42.barrel
import './colours.component';
import './edit-organisation-details.component';
import './email-footer.component';
import './languages.component';
import './main-settings-container.component';
import './sysadmin-userroles.component';
import './user-email-lookup.component';
import './calendar-event-types.component';
import './domain-name.component';
import './email-domains.component';
import './generate-labels.component';
import './imports-gg4l.component';
import './imports-isams.component';
import './imports-manageBac.component';
import './imports-wonde.component';
import './imports.component';
import './main-setting-types.component';
import './modules.component';
import './organisation-queue-run-button.component';
import './sms-topup.component';
import './sysadminagebands-component_v1';
import './sysadminconfigsettings-component';
import './sysadminforms-component_v2';
import './sysadminimportdata-component_v1';
import './sysadminpackages-component_v1';
import './sysadminreferencedata-component_v1';
// end:ng42.barrel

