"use strict";

angular.module('ccasignupModule').component("ccaGroupAllocations", {
templateUrl: "/Scripts/app/ccaSignUp/Details/cca-group-allocations.template.html",
controller:
    [
        '$scope',
        '$state',
        '$filter',
        '$translate',
        '$uibModal',
        'ccaPupilPreselectionService',
        'dateTextService',
        'personPickerService',
        'signUpRepository',
        'simpleAlertService',
        'teamService',
        function ($scope,
            $state,
            $filter,
            $translate,
            $uibModal,
            ccaPupilPreselectionService,
            dateTextService,
            personPickerService,
            signUpRepository,
            simpleAlertService,
            teamService)
        {
            signUpRepository
                .getSignUp($state.params.signUpId)
                .then(function (signUp) {
                    $scope.signUp = signUp;
                });

            signUpRepository
                .getSignUpGroup($state.params.signUpId, $state.params.signUpGroupId)
                .then(function (signUpGroup) {
                    $scope.signUpGroup = signUpGroup;
                });

            $scope.otherChoices = {
                hasOtherChoices: false,
                hasOtherSelections: true,
                confirmed: true
            }

            $scope.sortByOptions = [
                {
                    id: 1,
                    label: $translate.instant('SB_Name')
                },
                {
                    id: 2,
                    label: $translate.instant('SB_Date_Confirmed')
                }
            ];

            $scope.sortByOption = 1;
            $scope.sortBy = ['preferenceLevel', 'lastName'];

            $scope.sortByChanged = function ()
            {
                if ($scope.sortByOption == 1)
                {
                    $scope.sortBy = ['preferenceLevel', 'lastName'];
                    return;
                }

                $scope.sortBy = ['preferenceLevel', 'confirmedTimestamp'];
            };

            $scope.preferenceFilters = [
                { name: "1", id: 1, isChecked: true },
                { name: "2", id: 2, isChecked: true },
                { name: "3", id: 3, isChecked: true },
                { name: "4", id: 4, isChecked: true },
                { name: "5", id: 5, isChecked: true }
            ];
            $scope.selectedPreferences = function () {

                var output = [];
                var matches = $scope.preferenceFilters.filter(function (preference) {
                    return (preference.isChecked);
                });
                for (var i = 0; i < matches.length; ++i)
                    output.push(matches[i]["name"]);
                return output;
            };
            $scope.hasOtherPreferenceCounts = function () {
                return $scope.otherChoices.hasOtherChoices ? "0" : "!-1";
            };
            $scope.hasOtherSelectionCounts = function () {
                return $scope.otherChoices.hasOtherSelections ? "0" : "!-1";
            };
            $scope.hasConfirmed = function () {
                return $scope.otherChoices.confirmed ? "!-1" : "1";
            };

            $scope.allocate = function () {

                var filteroptions = { selectionStatus: "0", preferenceLevel: $scope.selectedPreferences(), otherPreferenceCount: $scope.hasOtherPreferenceCounts(), otherSelectionCount: $scope.hasOtherSelectionCounts(), isConfirmed: $scope.hasConfirmed() };
                var filteredArray = $filter("filterMultiple")($scope.signUpGroupPupils, filteroptions);

                for (var i = 0; i < filteredArray.length; ++i) {
                    var pupil = filteredArray[i];
                    pupil.selectionStatus = 1;
                }
            };

            $scope.pupilInformation = function (personId) {
                if ($scope.selectedPupil) {
                    $scope.selectedPupil.firstName = "Loading";
                    $scope.selectedPupil.lastName = "";
                    $scope.selectedPupil.preferences = null;
                    $scope.selectedPupil.notes = "";
                    $scope.selectedPupil.year = "";
                } else {
                    $scope.selectedPupil = {};
                }

                signUpRepository
                    .getPupilPreferences($state.params.signUpId, personId)
                    .then(function (preferences) {
                        for (var i = 0; i < preferences.preferences.length; i++) {
                            var pref = preferences.preferences[i];

                            if (pref.linkedParentTeamId === null) {
                                // is this team a parent team?
                                for (var j = 0; j < preferences.preferences.length; j++) {
                                    var prefChild = preferences.preferences[j];
                                    if (prefChild.linkedParentTeamId === pref.teamId) {
                                        // we found a child
                                        if (!pref.linkedChildTeamIds)
                                            pref.linkedChildTeamIds = [];

                                        pref.linkedChildTeamIds.push(prefChild.teamId);
                                        prefChild.parentTeamId = pref.teamId;

                                        pref.isLinked = true;
                                        prefChild.isLinked = true;
                                    }
                                }
                            }
                        }

                        $scope.selectedPupil = preferences;
                    });
            };

            $scope.adjustSelection = function (pupilRecord, selectionStatus) {
                pupilRecord.selectionStatus = selectionStatus;

                /// will only get passed parent group records, so we know we can safely look for just the children
                for (var i = $scope.signUpGroupPupils.length; i--;) {
                    var otherPupilRecord = $scope.signUpGroupPupils[i];
                    if (otherPupilRecord.personId === pupilRecord.personId && otherPupilRecord.linkedParentTeamId === pupilRecord.teamId) {
                        otherPupilRecord.selectionStatus = selectionStatus;
                    }
                }
            };

            $scope.filterAllocated = function (e) {
                return e.linkedParentTeamId === 0 && e.selectionStatus !== 0;
            };

            $scope.filterRequested = function (e) {
                var selectedPreferences = $scope.selectedPreferences();

                return e.linkedParentTeamId === 0 &&
                    e.selectionStatus === 0 &&
                    selectedPreferences.indexOf(e.preferenceLevel.toString()) > -1 &&
                    (($scope.otherChoices.hasOtherChoices)
                        ? e.otherPreferenceCount === 0
                        : e.otherPreferenceCount !== -1) &&
                    (($scope.otherChoices.hasOtherSelections)
                        ? e.otherSelectionCount === 0
                        : e.otherSelectionCount !== -1) &&
                    (($scope.otherChoices.confirmed)
                        ? e.isConfirmed !== -1
                        : e.isConfirmed === 1);
            };

            $scope.addAllocation = function (teamId, personId) {
                $scope.selectedPupil.preferences = null;
                signUpRepository.postPupilSelection($state.params.signUpId, teamId, personId, true)
                    .then(function (preferences) {
                        for (var i = 0; i < $scope.signUpGroupPupils.length; i++) {
                            if ($scope.signUpGroupPupils[i].personId === personId) {
                                if (teamId === $state.params.signUpGroupId) {
                                    $scope.signUpGroupPupils[i].selectionStatus = 1;
                                } else {
                                    $scope.signUpGroupPupils[i].otherSelectionCount = $scope.signUpGroupPupils[i].otherSelectionCount + 1;

                                }
                            }
                        }
                        $scope.selectedPupil = preferences;
                    });
            };

            $scope.removeAllocation = function (teamId, personId) {
                $scope.selectedPupil.preferences = null;
                signUpRepository.postPupilSelection($state.params.signUpId, teamId, personId, false)
                    .then(function (preferences) {

                        for (var i = 0; i < $scope.signUpGroupPupils.length; i++) {
                            if ($scope.signUpGroupPupils[i].personId === personId) {
                                if (teamId === $state.params.signUpGroupId) {
                                    $scope.signUpGroupPupils[i].selectionStatus = 0;
                                } else {
                                    $scope.signUpGroupPupils[i].otherSelectionCount = $scope.signUpGroupPupils[i].otherSelectionCount - 1;
                                    if ($scope.signUpGroupPupils[i].otherSelectionCount < 0) {
                                        $scope.signUpGroupPupils[i].otherSelectionCount = 0;
                                    }

                                }
                            }
                        }


                        $scope.selectedPupil = preferences;
                    });
            };

            $scope.sortType = "dayofWeek"; // set the default sort type
            $scope.sortReverse = false; // set the default sort order
            $scope.sortType1 = "dayofWeek"; // set the default sort type
            $scope.sortReverse1 = false; // set the default sort order

            $scope.refreshData = function () {
                signUpRepository
                    .getSignUpGroupPupils($state.params.signUpId, $state.params.signUpGroupId)
                    .then(function (signUpGroupPupils) {
                        $scope.signUpGroupPupils = signUpGroupPupils;
                    });
            };
            $scope.refreshData();

            $scope.isClashing = function (dayOfWeek) {
                return $scope.signUpGroup.dayofWeek === dayOfWeek;
            };

            $scope.back = function () {
                    $state.go("ccaDetails", { signUpId: $state.params.signUpId, searchData: $state.params.searchData });
            };

            $scope.postSelection = function(value) {
                signUpRepository.postSelection($state.params.signUpId, $state.params.signUpGroupId, $scope.signUpGroupPupils, value)
                .then(function (data) {
                    $state.go("ccaDetails", { signUpId: $state.params.signUpId, searchData: $state.params.searchData });
                });
            }

            $scope.onHold = function () {
                $scope.postSelection(3);
            };

            $scope.update = function () {
                $scope.postSelection(1);
            };

            $scope.complete = function () {
                $scope.postSelection(2);
            };

            $scope.dateText = function (dayNumber)
            {
                return dateTextService.getShortDayText(dayNumber);
            }

            $scope.isLive = function (statusId) {
                return statusId === 3;
            };

            $scope.manageLivePupils = function () {

                // load the current team member ids
                teamService.getPersonIdsInTeam($state.params.signUpGroupId)
                    .then(function (data) {

                        var selectedIds = data;

                        var modalInstance = personPickerService.openMultiPersonPicker({
                            selectedPersonIds: selectedIds,
                            defaultClubId: 0,
                            defaultTeamId: 0,
                            okButtonText: 'SB_Save',
                            includeStaff: false,
                            includePupils: true,
                            hideGroupFilter: false,
                            hideYearFilter: false,
                            hideGenderFilter: false,
                            hideRemove: false,
                            filterOutExternalStaff: false
                        });

                        modalInstance.result
                            .then(
                                function (selectedIds) {
                                    var pleaseWaitModalInstance = $uibModal.open({
                                        animation: $scope.animationsEnabled,
                                        backdrop: 'static', // prevent modal from closing on backdrop click
                                        templateUrl: '/Scripts/app/shared/controllers/please-wait-popup.template.html',
                                        controller: 'pleaseWaitPopupController',
                                        size: 'sm',
                                        scope: $scope,
                                        resolve: {
                                            message: function () { return 'Saving changes, please wait.'; }
                                        }
                                    });

                                    teamService
                                        .postPupilChangesToTeam($state.params.signUpGroupId, selectedIds)
                                        .then(function (data) {
                                            pleaseWaitModalInstance.close();
                                            if (!data.isError) {

                                                // reload team data
                                                $scope.refreshData();

                                            } else {
                                                console.error('problem saving team changes');
                                            }

                                        });
                                },
                                function () {
                                    console.log('dialog cancelled');
                                });

                    });
            };

            $scope.openPreSelectStudentPicker = function () {

                ccaPupilPreselectionService.ccaSignUpTeamId = $state.params.signUpGroupId;

                var pickerInstance = personPickerService.openMultiPersonPicker({
                    selectedPupilIds: [],
                    overridePersonLookupService: ccaPupilPreselectionService
                });

                pickerInstance
                    .result
                    .then(function (data) {

                        var pleaseWaitModal = simpleAlertService.pleaseWaitModal();

                        var pupilPreferences = [];
                        for (var i = data.length; i--;) {
                            pupilPreferences.push({
                                personId: data[i],
                                preferenceLevel: 1
                            });
                        }

                        ccaPupilPreselectionService.updatePupilPreSelection(
                            $state.params.signUpId,
                            $state.params.signUpGroupId,
                            pupilPreferences)
                            .then(function () {
                                $scope.refreshData();
                                pleaseWaitModal.close();
                            })
                            .catch(function () {
                                pleaseWaitModal.close();
                                simpleAlertService.simpleAlert({ title: 'SB_Error', message: 'SB_Error_Saving' });
                            });
                    });
            };
        }
    ]});