'use strict';

angular.module('sysadmin.services.sysAdminService', [])
    .factory("sysAdminService", [
        "$http",
        function ($http) {

            var urlBase = "/SysAdmin/";
            var sysAdminRepository = {};

            sysAdminRepository.getOrganisationSettings = function (organisationId) {
                return $http
                    .get(urlBase + "GetOrganisationSettings",
                        { params: { organisationId: organisationId } })
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.updateOrganisationSettings = function (organisationId, orgSettings) {
                return $http
                    .post(urlBase + "UpdateOrganisationSettings",
                        {
                            organisationId: organisationId,
                            settings: orgSettings
                        })
                    .then(function (response) { return response.data; });
            };

            /* Forms */
            sysAdminRepository.getForms = function () {
                return $http
                    .get(urlBase + "GetForms")
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.getForm = function (formId) {
                return $http
                    .get(urlBase + "GetForm", { params: { formId: formId } })
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.postFormDefinition = function (formData) {
                return $http
                    .post(urlBase + "SaveFormDefinition", { dto: formData })
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.postFormFields = function (formFieldData) {
                return $http
                    .post(urlBase + "SaveFormFields", { dto: formFieldData })
                    .then(function (response) { return response.data; });
            };

            /* Reference Data */
            sysAdminRepository.getAllReferenceData = function () {
                return $http
                    .get(urlBase + "GetAllReferenceData")
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.getReferenceData = function (organisationReferenceTypeId) {
                return $http
                    .get(urlBase + "GetReferenceData", { params: { organisationReferenceTypeId: organisationReferenceTypeId } })
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.postReferenceData = function (referenceData) {
                return $http
                    .post(urlBase + "SaveReferenceData", { dto: referenceData })
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.postReferenceDataValues = function (referenceDataValues) {
                return $http
                    .post(urlBase + "SaveReferenceDataValues", { dto: referenceDataValues })
                    .then(function (response) { return response.data; });
            };

            /* Organisation Age Bands */
            sysAdminRepository.getAgeBands = function () {
                return $http
                    .get(urlBase + "GetAgeBands")
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.getAgeBand = function (ageBandId) {
                return $http
                    .get(urlBase + "GetAgeBand", { params: { ageBandId: ageBandId } })
                    .then(function (response) { return response.data; });
            };


            sysAdminRepository.postAgeBand = function (ageBandData) {
                return $http
                    .post(urlBase + "SaveAgeBand", { dto: ageBandData })
                    .then(function (response) { return response.data; });
            };

            /* Membership Packages */
            sysAdminRepository.getPackages = function () {
                return $http
                    .get(urlBase + "GetPackages")
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.getPackage = function (packageId) {
                return $http
                    .get(urlBase + "GetPackage", { params: { packageId: packageId } })
                    .then(function (response) { return response.data; });
            };

            sysAdminRepository.postPackage = function (packageData) {
                return $http
                    .post(urlBase + "SavePackage", { dto: packageData })
                    .then(function (response) { return response.data; });
            };

            return sysAdminRepository;
        }
    ]);