import { AttendanceScanningType } from '@sb-shared/enums/attendance-scanning-type.enum';
import { Options } from '@sb-shared/models/UI/filter';

export interface Organisation {

  id: number;
  name: string;
  currencyDisplaySymbol: string;
  currencyExponent: number;
  darkColour: string;
  lightColour: string;
  helpDocUrl: string;
  isoCurrency: string;
  isTrial: boolean;
  mobileAppLogoPath: string;
  organisationTypeId: number;
  webLogoPath: string;
  isMISManaged: boolean;
  ianaTimeZoneName: string;
  hideStaffGenders: boolean;
  schoolFieldsAvailableFlags: number;
  schoolYearGroups: Options;
  schoolHouses: Options;
  schoolClasses: Options;
  isPermissionToWalkAvailable: boolean;
  isTransportModuleActive: boolean;
  isCcaModuleActive: boolean;
  isBalanceModuleActive: boolean;
  isSubjectsEnabled: boolean;
  switchOrganisationUrl: string;
  hasUserForms: boolean;
  hasSingleSignOnEnabled: boolean; // from identity server API
  isAttendanceScanningActive: boolean;
  attendanceScanningType: AttendanceScanningType;
  allowExternalRegistration: boolean;
  receptionTabVisible: ReceptionTabVisible;
  externalCoachRoleSettings: StudentProfileCanViewSettings;
}

export interface StudentProfileCanViewSettings {
  canViewEventMedicalRecords: boolean;
  canViewEventGuardians: boolean;
  canViewStudentProfile: boolean;
  canViewCustomData: boolean;
}

interface ReceptionTabVisible {
  events: boolean;
  messageCenter: boolean;
  regGroups: boolean;
  allGroups: boolean;
  users: boolean;
  locations: boolean;
  joinRequests: boolean;
  reports: boolean;
  staffSchedule: boolean;
  attendance: boolean;
}

export enum OrganisationSchoolFieldVisibility {
  None = 0,
  ShowYears = 1,
  ShowHouses = 2,
  ShowClasses = 4
}
