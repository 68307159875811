'use strict';

angular.module('ccasignupModule').component('ccaSignUpDetailsAABulkCreateGroups', {

    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-detailsaa-bulk-create-groups.template.html',
    controller:
    [
        '$scope',
        '$state',
        'signUpRepository',
        'organisationRepository',
        function ($scope,
            $state,
            signUpRepository,
            organisationRepository) {
            $scope.signUpId = $state.params.signUpId;

            $scope.groupSource = 'subject';

            $scope.sports = [];

            $scope.generateBySubjectFn = {}; // child component puts generate function in here
            $scope.generateByRegFn = {}; // child component puts generate function in here

            signUpRepository.getSignUp($scope.signUpId).then(function (signUp) {

                $scope.ccaSignUp = signUp;

                organisationRepository.getCcaType(signUp.ccaSignUpTypeId).then(function (ccaSignUpType) {
                    $scope.ccaSignUpType = ccaSignUpType.data;
                });
            });

            signUpRepository.getSports()
                .then(function (data) {
                    $scope.sports = data;
                });

            $scope.onDismiss = function () {
                $state.go('ccaDetailsAA.groups');
            };

            $scope.onGenerate = function() {
                if ($scope.groupSource === 'subject' &&
                    typeof $scope.generateBySubjectFn.fn === 'function') {
                    $scope.generateBySubjectFn.fn();
                }
                else if (typeof $scope.generateByRegFn.fn === 'function') {
                    $scope.generateByRegFn.fn();
                }
            }

            $scope.getGenerateFn = function() {
                return $scope.groupSource === 'subject'
                    ? $scope.generateBySubjectFn
                    : $scope.generateByRegFn;
            }

            $scope.isGenerateDisabled = function() {
                var generateFn = $scope.getGenerateFn();
                if (typeof generateFn.disabled === 'boolean') {
                    return generateFn.disabled;
                }

                return false;
            }

            $scope.isGenerated = function() {
                var generateFn = $scope.getGenerateFn();
                if (typeof generateFn.generated === 'boolean') {
                    return generateFn.generated;
                }

                return false;
            }
        }
    ]});