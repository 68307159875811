angular.module('payments.components.paymentResult',
    [
        'payments.components.paymentResultDetail'
    ])
    .component('paymentResult',
        {
            templateUrl: '/Scripts/app/payments/components/payment-result.template.html',
            controllerAs: 'ctrl',
            controller: class PaymentResultComponentCtrl {

                //Dependencies
                $location: any;
                $state: any;
                $window: any;
                paymentsService: any;
                paymentTypes: any;

                //Variables
                paymentProvider: string;
                isLoading: boolean;
                couldNotRetrieveResult: boolean;
                paymentType: any;
                isSuccess: boolean;
                message: any;
                merchantReference: any;
                items: any;
                stripeSessionId: any;
                checkoutSessionId: any;

                static $inject = ['$location', '$state', '$window', 'paymentsService'];

                constructor($location, $state, $window, paymentsService) {
                    this.$location = $location
                    this.$state = $state;
                    this.$window = $window;
                    this.paymentsService = paymentsService;

                    this.isSuccess = $state.params.isSuccess;
                    this.message = $state.params.message;
                    this.merchantReference = $state.params.merchantReference;
                    this.items = $state.params.items;
                    //Errors when reference prop name with hyphens so look up by key
                    this.checkoutSessionId = $state.params['cko-session-id'];
                    this.stripeSessionId = $state.params.stripe_session_id;

                    $state.clearParam('isSuccess');
                    $state.clearParam('message');
                    $state.clearParam('merchantReference');
                    $state.clearParam('items');
                    $state.clearParam('cko-session-id');
                    $state.clearParam('stripe_session_id');
                }

                $onInit() {
                    this.paymentProvider = '';
                    this.isLoading = true;
                    this.couldNotRetrieveResult = false;

                    this.paymentTypes = {
                        UNKNOWN: 0,
                        PARENT_PAYMENT: 1,
                        PARENT_BOOKING: 2,
                        MANUAL_PAYMENT: 3
                    };

                    this.paymentType = this.paymentTypes.UNKNOWN;

                    var transactionId = this.$location.search().transactionId;
                    var redDotSessionId = this.$location.search().transaction_id;

                    if (transactionId && transactionId.length > 0) {
                        if (redDotSessionId && redDotSessionId.length > 0) {
                            return this.getPaymentSessionResult(this.$window.EveryBuddy.Enums.PaymentProvider.RedDot, transactionId);
                        }
                        return this.getPaymentSessionResult(this.$window.EveryBuddy.Enums.PaymentProvider.BlueSnap, transactionId);
                    }

                    if (this.checkoutSessionId) {
                        return this.getPaymentSessionResult(this.$window.EveryBuddy.Enums.PaymentProvider.CheckOut, this.checkoutSessionId);
                    }

                    if (this.stripeSessionId) {
                        return this.getPaymentSessionResult(this.$window.EveryBuddy.Enums.PaymentProvider.Stripe, this.stripeSessionId);
                    }

                    if (this.merchantReference) {
                        this.paymentType = this.merchantReference.charAt(0) == 'B'
                            ? this.paymentTypes.PARENT_BOOKING
                            : this.paymentTypes.PARENT_PAYMENT;
                        this.isLoading = false;
                        return;
                    }

                    if (this.items) {
                        this.paymentType = this.paymentTypes.MANUAL_PAYMENT;
                    }

                    this.isLoading = false;

                }

                getTitle = function () {
                    switch (this.paymentType) {
                        case this.paymentTypes.PARENT_PAYMENT:
                            return 'SB_Payments';
                        case this.paymentTypes.PARENT_BOOKING:
                            return 'SB_Bookings';
                        case this.paymentTypes.MANUAL_PAYMENT:
                            return 'SB_Record_Payments';
                        default:
                            return 'SB_Payments';
                    }
                };

                getSuccessMessage = function () {
                    switch (this.paymentType) {
                        case this.paymentTypes.PARENT_PAYMENT:
                            return 'SB_Thank_You_Payment';
                        case this.paymentTypes.PARENT_BOOKING:
                            return 'SB_Thank_You_Booking';
                        case this.paymentTypes.MANUAL_PAYMENT:
                            return 'SB_Payment_Recorded';
                        default:
                            return 'SB_Thank_You_Payment';
                    }
                };

                getPaymentSessionResult = function (paymentProviderId, ckoSessionId) {
                    // A payment redirect.
                    this.paymentsService.getPaymentSessionResult(paymentProviderId, ckoSessionId)
                        .then(data => {
                            if (!data) {
                                this.message = 'No payment session result';
                                this.isLoading = false;
                                return;
                            }

                            this.merchantReference = data.merchantReference;
                            this.paymentType = data.isBooking
                                ? this.paymentTypes.PARENT_BOOKING
                                : this.paymentTypes.PARENT_PAYMENT;
                            this.couldNotRetrieveResult = data.couldNotRetrieveResult;
                            this.isSuccess = data.isSuccess;
                            this.message = data.message;
                            this.bookingTimeout = data.bookingTimeout;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.error('Error retrieving payment session result', err);
                            this.message = 'Error retrieving session result';
                            this.isLoading = false;
                        });
                };

                recordAnotherPayment = function () {
                    this.$state.go('feesDashboard.recordPayment');
                }

                backToSignUps = function () {
                    this.$state.go('ccaAvailable', null, 'CcaAvailable');
                }
            }
        });
