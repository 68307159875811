'use strict';

angular.module('balanceAdmin.summary.components.sendChaseUibContainer',
    [
        'balanceAdmin.summary.components.sendChase',
        'balanceAdmin.services.balanceAdminService',
        'shared.components.sbModalHeader',
        'shared.services.simpleAlertService'
    ])
    .component('sendChaseUibContainer',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/send-chase-uib-container.template.html',
            controller: [
                function () {

                    this.eventName = '';
                    this.calendarEventId = 0;

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.eventName = changes.resolve.currentValue.eventName;
                            this.calendarEventId = changes.resolve.currentValue.calendarEventId;
                        }
                    }.bind(this);

                    this.onChaseRequestSent = function (data) {
                        this.modalInstance.close(data);
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.close();
                    }.bind(this);

                }]
        });