'use strict';

angular.module('messaging.directives.messageHistory',
        [
            'ngToast',
            'messaging.services.messagingService'

        ])
    .directive('messageHistory',
        [
            '$uibModal',
            'messagingService',
            '$window',
            '$http',

            function ($uibModal, messagingService, $window, $http) {

                function controller($scope, $window, $http) {

                    // grab the history
                    $scope.historyRecords = [];
                    $scope.currentPage = 0;
                    $scope.pageSize = 10;
                    $scope.totalPages = 0;
                    $scope.pages = [0];

                    var refresh = function() {
                        messagingService.getMessageHistory($scope.currentPage, $scope.pageSize)
                            .then(function(data) {
                                if (data.isError) {

                                } else {
                                    $scope.historyRecords = data.items;
                                    $scope.totalPages = data.totalPages;

                                    $scope.pages = [];
                                    for (var i = 0; i <= $scope.totalPages; i++) {
                                        $scope.pages.push(i);
                                    }
                                }
                            });
                    };

                    $scope.setPage = function(newPage) {
                        $scope.currentPage = newPage;
                        refresh();
                    };

                    $scope.saveFile = function(data, id) {
                        const blob = new Blob([data], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `Recipients_Message${id}.csv`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    };

                    $scope.exportReport = function(id) {
                        $http.get(`${window.EveryBuddy.WebAPI}/webapi/WebMessageCenter/GetMessageRecipientReport?messageId=${id}`)
                            .then(response => $scope.saveFile(response.data, id));
                    };

                    refresh();
                };

                return {
                    restrict: 'E',
                    templateUrl: '/Scripts/app/messaging/directives/message-history.template.html?v=' + $window.EveryBuddy.Version,
                    controller: ['$scope', '$window', '$http', controller],
                    scope: {
                        messageType: '='
                    }
                };

            }
        ]);
