import { Injectable } from "@angular/core";
import { AuthService } from "../auth/services/auth.service";

@Injectable()
export class Ng1AuthService {

  constructor(private authService: AuthService) {}

  getAccessToken() {
    return this.authService.getAccessToken();
  }

}
