import { TimeCachedObservable } from "@sb-shared/models/time-cached-observable";
import { Observable, shareReplay } from 'rxjs';
import { Injectable } from "@angular/core";
import { addMilliseconds, isAfter } from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class TimedCacheService {
  private cache: { [key: string]: TimeCachedObservable } = {};

  public getObservable<T>(key: string, observable: Observable<T>, aliveTimeMs = 60000): Observable<T> {
    const cachedObservable: TimeCachedObservable = this.cache[key];

    if (cachedObservable && isAfter(cachedObservable.expires as Date, Date.now())) {
      return cachedObservable.observable as Observable<T>;
    }

    this.cache[key] = {
      expires: addMilliseconds(Date.now(), aliveTimeMs),
      observable: observable.pipe(shareReplay(1))
    }

    return this.cache[key].observable as Observable<T>;
  }
}
