<div class="football-kit"
    [style.--kit-size]="kitCssSizeString"
    [style.--brand-primary]="primaryColour"
    [style.--brand-secondary]="secondaryColour"
    [ngClass]="'football-kit--' + styleCssString">
    <div class="football-kit__shirt"></div>
    <div class="football-kit__sleeve football-kit__sleeve--left"></div>
    <div class="football-kit__sleeve football-kit__sleeve--right"></div>
    <div class="football-kit__waist"></div>
    <!-- <div class="football-kit__shorts football-kit__shorts--left"></div>
    <div class="football-kit__shorts football-kit__shorts--right"></div> -->
</div>