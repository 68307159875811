angular.module('ccaSignUp.services.organisationMenuService', [])
    .factory("organisationMenuService",
        [
            "$http", "$q", "$cacheFactory", function ($http, $q, $cacheFactory)
            {
                var urlBase = "/CcaSignUp/";
                var repository = {};
                var repoCache = $cacheFactory('menuServiceCacheFactory');

                repository.getActiveCcaTypeMenuItems = function ()
                {
                    return $http.get(urlBase + "GetActiveCcaTypeMenuItems", { cache: repoCache })
                        .then(function (data)
                        {
                            return data.data;
                        })
                };

                repository.getForInfoOnlyCcaSignupType = function (ccaSignUpTypeId)
                {
                    if (ccaSignUpTypeId === null)
                    {
                        return null;
                    }

                    var deferred = $q.defer();
                    $http.get(urlBase + "GetForInfoOnlyCcaSignupType", { params: { ccaSignUpTypeId: ccaSignUpTypeId }, cache: repoCache})
                        .then(function (data)
                        {
                            deferred.resolve(data.data);
                        })
                        .catch(function (err)
                        {
                            deferred.reject;
                        });

                    return deferred.promise;
                };

                return repository;
            }
        ]);