'use strict';

angular.module('profile.components.profileMedical', [])
    .component('profileMedical', {
        templateUrl: '/Scripts/app/profile/components/profile-medical.template.html',
        bindings: {
            selectedPerson: '<',
            isOrganisationAdmin: '<',
            misManaged: '<',
        },
        controller: [function () {
        }]
    });

