// this is the preferred method for creating services as they are put into their own module and are more easily sharable
angular.module('corporateContacts.services.corporateContactsService', [])
    .factory('corporateContactsService', [
        '$http', '$cacheFactory',
        function ($http, $cacheFactory) {

            var urlBase = "/webapi/WebCorporateContacts/";
            var repository = {};

            var repoCache = $cacheFactory('corporateContactsCache');

            repository.clearCache = function () {
                repoCache.removeAll();
            };

            repository.getContactTypes = function () {

                return $http.get(urlBase + "GetCorporateContactTypes", { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getAllContacts = function (contactType) {

                return $http.get(urlBase + "GetAll", { params: { contactType: contactType } })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getById = function (id) {

                return $http.get(urlBase + "GetById", { params: { id : id }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.saveContact = function (contact)
            {
                return $http.post(urlBase + 'Save', contact)
                    .then(function (response) {
                        repository.clearCache();

                        return response.data;
                    });

            };

            repository.deleteContact = function(contactId)
            {
                return $http.post(urlBase + 'ContactDelete?id=' + contactId)
                    .then(function (response) {
                        repository.clearCache();

                        return response.data;
                    });
            }

            return repository;
        }
    ]);