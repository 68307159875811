angular
    .module('person.services', [])
    .factory('personService', [
        '$http', '$q', '$cacheFactory', '$httpParamSerializerJQLike', '$filter',
        function ($http, $q, $cacheFactory, $httpParamSerializerJQLike, $filter) {

            var urlBase = '/webapi/WebPerson/';
            var receptionBase = '/Reception/';
            var accountBase = '/webapi/WebAccount/';
            var service = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('personServiceCache');
            var pinCache = $cacheFactory('personServicePinCache');

            service.lookupPupilByName = function (includeDeleted, studentName) {

                var params = {
                    studentName: studentName,
                    includeDeleted: includeDeleted
                };

                return $http.get(urlBase + 'PupilNameAutoComplete', { params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getCurrentPersonId = function () {

                return $http.get(urlBase + 'GetCurrentPersonId', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });

            };

            service.getCurrentPersonFlags = function () {

                return $http.get(urlBase + 'GetCurrentPersonFlags', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getName = function (personId) {
                return $http.get(urlBase + 'GetName?personId=' + personId, { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (err) {
                        return {
                            isError: true,
                            error: err
                        };
                    });
            };

            service.getNames = function (personIds, groupId) {

                if (typeof groupId === 'undefined') {
                    groupId = -1;
                }

                return $http.post(urlBase + 'GetNames',
                    { personIds: personIds, groupId: groupId }
                ).then(function (response) {
                    return response.data;
                });
            };

            service.getPerson = function (personId, inclusionFlags) {

                var params = {
                    personId: personId
                };

                if (inclusionFlags) {
                    params.toInclude = inclusionFlags;
                }

                return $http.get(urlBase + 'GetPersonData', { cache: repoCache, params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.unlinkSingleSignOnAccountForLoggedInUser = function (providerName) {

                repoCache.removeAll();

                return $http(
                    {
                        method: 'POST',
                        url: accountBase + 'UnlinkSingleSignOnAccountForCurrentUser',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        data: $httpParamSerializerJQLike(providerName)
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.isParentOfUser = function (parentUserId, childUserId) {

                var params = {
                    parentUserId: parentUserId,
                    childUserId: childUserId
                };

                return $http.get(urlBase + 'IsParentOfUserId', { cache: repoCache, params: params })
                    .then(function (response) {
                        return response.data;
                    });

            };

            service.getLookupData = function () {
                return $http.get(receptionBase + 'GetLookupData', { cache : repoCache})
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getChildrenForParent = function (parentId) {

                var params = {
                    personId: parentId
                };

                return $http.get(urlBase + 'GetAllChildrenForParent', { params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getParentsForChild = function (childId) {

                var params = {
                    childPersonId: childId
                };

                return $http.get(urlBase + 'GetParentsForChild', { params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getContactDetailsForPerson = function (personId) {

                var params = {
                    personId: personId
                };

                return $http.get(urlBase + 'GetContactDetailsForPerson', { params: params })
                    .then(function (response) {
                        return response.data;
                    });

            };

            service.addPerson = function (personData) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'AddPerson', { personData: personData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.savePerson = function (personData) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'SavePerson', { personData: personData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.deletePerson = function (personId) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'DeletePerson', { personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.restorePerson = function (personId) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'RestorePerson', { personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.getCurrentPersonOrganisations = function () {
                return $http.get(urlBase + 'GetCurrentPersonOrganisations', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getPersonOrganisations = function (personId) {
                return $http.get(urlBase + 'GetPersonOrganisations?personId=' + personId, { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };
            /*

            EXIT CODES

            */
            service.checkParentHasSetPin = function () {
                return $http.get(urlBase + 'CheckPinSetForPerson', { cache: pinCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.savePinForPerson = function (newPin) {

                pinCache.removeAll();

                return $http.post(urlBase + 'SavePinForPerson', JSON.stringify(newPin))
                    .then(function (response) {
                        return response.data;
                    });
            };


            /*

            TRANSPORT RECORDS

            */

            service.getPupilTransport = function (personId) {

                return $http.get(receptionBase + 'GetPupilTransport', { cache: repoCache, params: { personId: personId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.savePupilTransport = function (personId, preferences) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'SavePupilTransport', { personId: personId, preferences: preferences })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.getBusMonitors = function () {
                return $http.get(urlBase + 'GetBusMonitors')
                    .then(function (response) {
                        return response.data;
                    });
            };

            /*

            MEDICAL RECORDS

            */

            service.getPupilMedical = function (personId) {

                return $http.get(receptionBase + 'GetPupilMedical', { cache: repoCache, params: { personId: personId } })
                    .then(function (response) {
                        return response.data;
                    });

            };

            service.savePupilMedical = function (personId, description, notes, medicalConditionId) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'SavePupilMedical', { personId: personId, description: description, notes: notes, medicalConditionId: medicalConditionId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            service.deletePupilMedical = function (personId, medicalConditionId) {

                repoCache.removeAll();

                var deferred = $q.defer();

                $http.post(receptionBase + 'DeletePupilMedical', { personId: personId, medicalConditionId: medicalConditionId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            /*

            LINKED PEOPLE

            */

            service.saveLink = function (linkedData) {

                repoCache.removeAll();

                var deferred = $q.defer();
                $http.post(receptionBase + 'SaveLinked', { parentLink: linkedData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            service.removeLink = function (parentId, linkedPersonId) {

                repoCache.removeAll();

                var deferred = $q.defer();
                $http.post(receptionBase + 'RemoveLink', { parentId: parentId, linkedPersonId: linkedPersonId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            service.savePupilTransportPreference = function (pupilInformation) {
                return $http.put(urlBase + 'SavePupilTransportStopPreferences', pupilInformation)
                    .then(function (response) { return response.data; });
            }

            return service;
        }
    ]);