'use strict';

angular.module('balanceAdmin.quickCharge.components.quickChargeButtons',
    ['shared.components.sbModalHeader'])
    .component('quickChargeButtons',
        {
            bindings: {
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/quickCharge/components/quick-charge-buttons.template.html',
            controller: [
                function () {
                    this.customValue = null;

                    this.quickCharge = function () {
                        this.modalInstance.close({ customValue: this.customValue });
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);
                }]
        });