
// AngularJS state handling
import './ng1-services/ng1-state-definition.service';
// Import hybrid-specific angularJS modules
import './ng1-app.module';
import './dashboard/dashboard.module';
import './portal/portal.module';
import './settings/settings.module';
import './balance/balance.module';
import './balance-admin/balance-admin.module';
import './diary/diary.module';
import './cca-available/cca-available.module';
import './cca-sign-up/cca-sign-up.module';
import './reception/reception.module';
import './transport/transport.module';
import './profile/profile.module';
import './sysadmin/sysadmin.module';
import './messaging/messaging.module';
import './tuition-fees/tuition-fees.module';
import './payments/payments.module';
import './teams/teams';
// Third party AngularJS libraries
import './ng1-thirdparty';
// Our AngularJS files
import '../../../../../app';
