'use strict';

angular.module('transport.configuration.transportOperatingDays', [
    'transport.services.transportOperatingDaysService',
    'shared.filters.decoded',
    'shared.components.sbOnOffSwitch',
    'shared.components.sbSubmitButton',
]).component('transportOperatingDays', {
    templateUrl: '/Scripts/app/transport/configuration/transport-operating-days.template.html',
    bindings: {
    },
    controller: [
        'transportOperatingDaysService',
        'simpleAlertService',
        function transportOperatingDaysController(transportOperatingDaysService, simpleAlertService)
        {
            this.anyChanges = false;
            this.isSaving = false;
            this.transportOperatingDays = null;

            this.$onInit = function ()
            {
                this.loadTransportOperatingDays();
            }.bind(this);

            this.loadTransportOperatingDays = function ()
            {
                transportOperatingDaysService.getTransportOperatingDays().then(function (data)
                {
                    this.transportOperatingDays = data;

                }.bind(this), function (error)
                {
                    simpleAlertService.errorAlert(error);
                    }.bind(this));

                this.anyChanges = false;
            }.bind(this);

            this.saveTransportOperatingDays = function ()
            {
                this.isSaving = true;

                transportOperatingDaysService.saveTransportOperatingDays(this.transportOperatingDays).then(function (data)
                {
                    this.isSaving = false;

                }.bind(this), function ()
                {
                    simpleAlertService.errorAlert();
                });
            }.bind(this);

            this.changesMade = function ()
            {
                this.anyChanges = true;
            }
        }
    ]
});
