import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileFormsComponent } from './components/profile-forms/profile-forms.component';
import { ProfileLinkedComponent } from './components/profile-linked/profile-linked.component';
import { ProfilePermissionToWalkComponent } from './components/profile-permission-to-walk/profile-permission-to-walk.component';
import { ProfileProfileComponent } from './components/profile-profile/profile-profile.component';
import { ProfileGroupsComponent } from './components/profile-groups/profile-groups.component';
import { ProfilePupilMedicalComponent } from './components/profile-pupil-medical/profile-pupil-medical.component';
import { ProfileSsoComponent } from './components/profile-sso/profile-sso.component';
import { ProfileWelcomeComponent } from './components/profile-welcome/profile-welcome.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileDiaryComponent } from './components/profile-diary/profile-diary.component';

export const profileRoutes = [
  { path: 'welcome', component: ProfileWelcomeComponent },
  { path: 'profile', component: ProfileProfileComponent },
  { path: 'diary', component:  ProfileDiaryComponent },
  { path: 'linked', component: ProfileLinkedComponent },
  { path: 'groups', component: ProfileGroupsComponent },
  { path: 'medical', component: ProfilePupilMedicalComponent },
  { path: 'userForms', component: ProfileFormsComponent },
  { path: 'singlesignon', component: ProfileSsoComponent },
  { path: 'permissiontowalk', component: ProfilePermissionToWalkComponent }
]

const routes: Routes = [
  { path: '', component: ProfileComponent,
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full'},
      ...profileRoutes
    ]
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
