angular.module('mortgage.services.mortgageService', [])
    .factory("mortgageService", [
    "$http", "$q", "$cacheFactory",
    function ($http, $q, $cacheFactory) {

        var urlBase = "/webapi/WebMortgage/";
        var repository = {};

        // cache responses- be sure to clear the cache when doing any write back operations
        var repoCache = $cacheFactory('mortgageServiceCache');

        var makeMortgageGreatAgain = function (mortgage) {

            if (!mortgage) return mortgage;

            // need to format the numbers here otherwise the dirty checking won't work

            if (mortgage.houseValue)
                mortgage.houseValue = parseFloat(Math.round(+mortgage.houseValue * 100) / 100).toFixed(2);

            if (mortgage.monthlyRepaymentInterest)
                mortgage.monthlyRepaymentInterest = parseFloat(Math.round(+mortgage.monthlyRepaymentInterest * 100) / 100).toFixed(2);

            if (mortgage.monthlyRepaymentTotal)
                mortgage.monthlyRepaymentTotal = parseFloat(Math.round(+mortgage.monthlyRepaymentTotal * 100) / 100).toFixed(2);

            if (mortgage.mortgageValue)
                mortgage.mortgageValue = parseFloat(Math.round(+mortgage.mortgageValue * 100) / 100).toFixed(2);

            if (mortgage.productFee)
                mortgage.productFee = parseFloat(Math.round(+mortgage.productFee * 100) / 100).toFixed(2);

            if (mortgage.rate)
                mortgage.rate = parseFloat(Math.round(+mortgage.rate * 100) / 100).toFixed(2);

            if (mortgage.renewalReminderDate)
                mortgage.renewalReminderDate = new Date(mortgage.renewalReminderDate.replace('Z', ''));

            if (mortgage.requestedCompletionDate)
                mortgage.requestedCompletionDate = new Date(mortgage.requestedCompletionDate.replace('Z', ''));

            if (mortgage.ercEndDate)
                mortgage.ercEndDate = new Date(mortgage.ercEndDate.replace('Z', ''));

            if (mortgage.fees) {

                if (mortgage.fees.brokerFeeDateReceived) {
                    mortgage.fees.brokerFeeDateReceived = new Date(mortgage.fees.brokerFeeDateReceived.replace('Z', ''));
                }

                if (mortgage.fees.commissionDateReceived) {
                    mortgage.fees.commissionDateReceived = new Date(mortgage.fees.commissionDateReceived.replace('Z', ''));
                }

                if (mortgage.fees.brokerFeeCharged)
                    mortgage.fees.brokerFeeCharged = parseFloat(Math.round(+mortgage.fees.brokerFeeCharged * 100) / 100).toFixed(2);
                if (mortgage.fees.brokerFeeReceived)
                    mortgage.fees.brokerFeeReceived = parseFloat(Math.round(+mortgage.fees.brokerFeeReceived * 100) / 100).toFixed(2);
                if (mortgage.fees.commissionAmountReceived)
                    mortgage.fees.commissionAmountReceived = parseFloat(Math.round(+mortgage.fees.commissionAmountReceived * 100) / 100).toFixed(2);
                if (mortgage.fees.commissionExpected)
                    mortgage.fees.commissionExpected = parseFloat(Math.round(+mortgage.fees.commissionExpected * 100) / 100).toFixed(2);
            }

            console.log('make mortgage great again', mortgage);

            return mortgage;
        };

        repository.getMortgageReference = function () {
            return $http.get(urlBase + "GetMortgageReference", { cache: repoCache })
                .then(function (response) { return response.data; })
                .catch(function (e) { return { isError: true, error: e }; });
        };

        repository.getMortgageDetails = function (teamId) {
            return $http.get(urlBase + "GetMortgage", { params: {teamId:teamId}}, { cache: repoCache })
                .then(function (response) {
                    var data = makeMortgageGreatAgain(response.data);
                    return data;
                })
                .catch(function (e) { return { isError: true, error: e }; });
        };

        
        repository.getMortgageStatuses = function (teamId) {
            return $http.get(urlBase + "GetMortgageStatuses", { params: {teamId:teamId}}, { cache: repoCache })
                .then(function (response) { return response.data; })
                .catch(function (e) { return { isError: true, error: e }; });
        };

        repository.getLatestMortgageStatus = function (teamId) {
            return $http.get(urlBase + "GetLatestMortgageStatus", { params: {teamId:teamId}})
                .then(function (response) { return response.data; })
                .catch(function (e) { return { isError: true, error: e }; });
        };


        repository.saveMortgageDetails = function (dto) {
            repoCache.removeAll();
                
            return $http.post(urlBase + "SaveMortgage", dto)
                .then(function (response) {
                    var data = makeMortgageGreatAgain(response.data);
                    return data;
                })
                .catch(function (e) { return { isError: true, error: e }; });
        };

        repository.saveMortgageStatuses = function (dto) {
            repoCache.removeAll();
                
            return $http.post(urlBase + "SaveMortgageStatuses", dto)
                .then(function (response) { return response.data; })
                .catch(function (e) { return { isError: true, error: e }; });
        };

        return repository;
    }
]);