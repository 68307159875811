angular.module('person.directives.personPickerSummary', [
    'shared.components.sbIcon'
])
    .directive('personPickerSummary', [
        function () {

            function link($scope) {
                $scope.$watch('selection',
                    function() {
                        $scope.checkForItems();
                    }, true);
            };
            function controller($scope) {

                $scope.remove = function (group, item) {
                    var index = group.indexOf(item);
                    if (index > -1) {
                        group.splice(index, 1);
                    }
                };

                $scope.noItems = true;

                $scope.checkForItems = function () {

                    var itemCount = 0;

                    if ($scope.showClubs && $scope.selection.clubs) {
                        itemCount += $scope.selection.clubs.length;
                    }

                    if ($scope.showTeams && $scope.selection.teams) {
                        itemCount += $scope.selection.teams.length;
                    }

                    if ($scope.showPeople && $scope.selection.people) {
                        itemCount += $scope.selection.people.length;
                    }

                    if ($scope.showEvents && $scope.selection.events) {
                        itemCount += $scope.selection.events.length;
                    }

                    if ($scope.selection.eventPeople && $scope.selection.eventPeople.length) {
                        itemCount += $scope.selection.eventPeople.length;
                    }

                    if ($scope.selection.eventGroupedPeople && $scope.selection.eventGroupedPeople.length) {
                        itemCount += $scope.selection.eventGroupedPeople.length;
                    }
                    if ($scope.selection.wholeSchoolGroup && $scope.selection.wholeSchoolGroup.length) {
                        itemCount += $scope.selection.wholeSchoolGroup.length;
                    }
                    if ($scope.selection.subjectClasses && $scope.selection.subjectClasses.length) {
                        itemCount += $scope.selection.subjectClasses.length;
                    }

                    $scope.noItems = itemCount < 1;

                };

                // needs changing here for lables
                $scope.memberTypeName = ['', ' (Pupils)', ' (Parents)', ' (Staff)'];

                $scope.checkForItems();
            };

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/person/directives/person-picker-summary.template.html',
                scope: {
                    selection: '=',
                    showClubs: '<',
                    showTeams: '<',
                    showPeople: '<',
                    showEvents: '<',
                    showEventPersonGroups: '<',
                    showEventPersons: '<',
                    showWholeSchoolGroup: '<',
                    showSubjectClasses: '<',
                },
                controller: ['$scope', '$filter', 'personPickerService', 'recipientSearchTypeEnum', 'memberTypes', controller],
                link: link
        };

        }
    ]);
