'use strict';

angular.module('shared.component.progressPopupComponent', [])
    .component('progressPopupComponent', {
        bindings: {
            resolve: '<',
            modalInstance: '<'
        },
        templateUrl: '/Scripts/app/shared/components/progress-popup.template.html',
        controller: [
            function () {

                this.percent = 0.0;

                this.$onChanges = function (changes) {

                    if (changes.resolve && changes.resolve.currentValue) {
                        console.log('progress ' + changes.resolve.currentValue.progress.current + '/' + changes.resolve.currentValue.progress.target)
                        this.message = changes.resolve.currentValue.message;
                        this.progress = changes.resolve.currentValue.progress;
                    }
                }.bind(this);
            }
        ]
    });