<div [class]="computedClass"
     [ngClass]="{
         'alert--dismissible' : showDismiss,
         'alert--borderless' : isBorderless,
         'alert--text-only' : isTextOnly,
         'alert-small' : isSmall
        }"
     role="alert"
     [hidden]="isHidden">
    <span>
        <div>
            <div class="alert__body">
                <div class="alert__body__inner"
                     [style.maxHeight.px]="isCollapsed ? collapseConfig.showLines * lineHeight : null"
                     [ngClass]="{'alert__body__inner--collapsed' : isCollapsed}">
                    <sb-button *ngIf="showDismiss"
                               buttonClass="link"
                               [isAutoSize]="true"
                               iconName="close"
                               iconClass="text-muted"
                               customClasses="alert-dismiss-button"
                               (onClick)="dismiss()">
                    </sb-button>
                    <div *ngIf="alertTitle"
                         [innerHTML]="alertTitle | translate"
                         class="p-15 semi-bold mb-3"></div>
                    <div #alertContent>
                        <p [ngClass]="{'hidden-xs' : messageXs}"
                           [innerHTML]="isMessageTranslated ? (message | translate: messageTranslationParameters) : message"></p>
                    </div>
                    <p *ngIf="messageXs" class="hidden-sm hidden-md hidden-lg">{{messageXs | translate:
                        messageTranslationParameters}}</p>
                    <div>
                        <ng-content></ng-content>
                    </div>
                    <div *ngIf="routerLink"
                         class="mt-4">
                        <sb-button buttonClass="link" message="SB_View" (onClick)="navigate()"></sb-button>
                    </div>
                </div>
                <div class="alert__body__note" *ngIf="note" [innerHTML]="note | translate: noteTranslationParameters">
                </div>
            </div>
            <div *ngIf="collapseConfig.isCollapsible">
                <sb-button *ngIf="alertContent.offsetHeight > lineHeight * collapseConfig.showLines"
                           [message]="(isCollapsed ? organisationLabelTags.ViewAll : organisationLabelTags.Hide) | translate"
                           (onClick)="isCollapsed = !isCollapsed"></sb-button>
            </div>
        </div>
    </span>
    <div *ngIf="rightIconName || rightLabel"
         class="alert__right"
         [ngbTooltip]="rightIconTooltip | translate"
         placement="bottom">
        <sb-icon *ngIf="rightIconName"
                 [name]="rightIconName"
                 [overlayName]="rightIconOverlayName"
                 [overlayCustomClass]="rightIconOverlayCustomClass"
                 class="alert__right-icon"></sb-icon>
        <div *ngIf="rightLabel"
             [innerHTML]="rightLabel"
             class="alert__right-label"></div>
    </div>
</div>
