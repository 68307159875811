"use strict";

angular.module('team.directives.teamJoinRequests', [
    'teams.services.teamService',
    'shared.directives.sbCheckBox',
    'shared.components.sbColumnHeader',
    'shared.components.sbModalHeader'
])
    .directive('teamJoinRequests', [
        '$window',
        '$state',
        '$uibModal',
        'teamService',
        function ($window, $state, $uibModal, teamService) {

            function link($scope) {
                $scope.$watch('teamId',
                    function (newValue) {
                        if (newValue) {
                            $scope.loadMode = 't';
                            $scope.loadJoinRequests();
                        }

                    });
                $scope.$watch('ccaSignUpId',
                    function (newValue) {
                        if (newValue) {
                            $scope.loadMode = 'c';
                            $scope.loadJoinRequests();
                        }

                    });
                $scope.$watch('organisation',
                    function (newValue) {
                        if (newValue) {
                            $scope.loadMode = 'o';
                            $scope.loadJoinRequests();
                        }

                    });
            };

            function controller($scope) {

                var loadData = function (data) {
                    $scope.loading = false;

                    if (!data.isError) {
                        $scope.joinRequestsData = data;
                    } else {
                        console.error('Failure in GetJoinRequestsForTeam', data.errorData);
                    }
                };


                $scope.joinRequestsData = [];
                $scope.loading = false;
                $scope.hideProcessed = true;
                $scope.searchText = '';

                $scope.loadJoinRequests = function () {

                    $scope.loading = true;

                    if ($scope.loadMode === 'c') {
                        teamService
                            .getJoinRequestsForCcaSignUp($scope.ccaSignUpId, !$scope.hideProcessed)
                            .then(loadData);
                    } else if ($scope.loadMode === 'o') {
                        teamService
                            .getJoinRequestsForOrganisation(!$scope.hideProcessed)
                            .then(loadData);
                    } else if ($scope.loadMode === 't') {
                        teamService
                            .getJoinRequestsForTeam($scope.teamId, !$scope.hideProcessed)
                            .then(loadData);
                    } else {
                        console.log('No mode set');
                    }

                };

                $scope.search = function(item) {
                    if ($scope.searchText === '') {
                        return true;
                    }
                    else {
                        var userMatch = (item.pupilName.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1);
                        var teamMatch = (item.teamName.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1);
                        return userMatch || teamMatch;
                    }
                };

                $scope.acceptRequest = function (joinRequest) {
                    $scope.selectedRequest = joinRequest;
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: "acceptJoinRequestModal.html",
                        controller: "ConfirmModalInstanceCtrl",
                        size: "md",
                        scope: $scope
                    });

                    modalInstance.result.then(function () {
                            $scope.loading = true;

                            teamService.acceptRequest(joinRequest.id)
                                .then(function (data) {
                                    $scope.loading = false;
                                    $scope.loadJoinRequests();
                                });
                    },
                        function () {
                        });
                }

                $scope.rejectRequest = function (joinRequest) {
                    $scope.selectedRequest = joinRequest;
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: "rejectJoinRequestModal.html",
                        controller: "ConfirmModalInstanceCtrl",
                        size: "md",
                        scope: $scope
                    });

                    modalInstance.result.then(function () {
                            $scope.loading = true;

                            teamService.rejectRequest(joinRequest.id)
                                .then(function (data) {
                                    $scope.loading = false;
                                    $scope.loadJoinRequests();
                                });
                        },
                        function () {
                        });
                }

                $scope.sortType = "requestWhen";
                $scope.sortReverse = true;
            };

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/teams/directives/team-join-requests.template.html',
                scope: {
                    teamId: '=',
                    ccaSignUpId: '=',
                    organisation: '='
                },
                controller: ['$scope', controller],
                link: link
            };
        }]);
