import { Injectable } from '@angular/core';
import { apis, controllerTypes, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { GenericObject } from '@sb-shared/models/UI/froala-editor';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HttpWebApiService } from './http-web-api.service';

@Injectable({
  providedIn: 'root'
})
export class FroalaImageUploadHttpConfigService {

  httpConfigAdmin: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.Admin
  }

  constructor(
    private authSvc: AuthService,
    private httpSvc: HttpWebApiService
  ) { }

  getImageUploadApiUrl(): string
  {
    return this.httpSvc.formatUrl('OrganisationStorage/UploadImageFromFroalaTextEditor', this.httpConfigAdmin);
  }

  getImageUploadRequestHeaders(): GenericObject<string>
  {
    const header = {
      authorization: 'Bearer ' + this.authSvc.getAccessToken()
    };

    return header;
  }
}
