'use strict';

angular.module('reports.directives.reportEmbed', [
    'pascalprecht.translate',

    'reports.services.reportService',

    'shared.components.sbIcon',
    'shared.services.cachedLookupService',
])
    .directive('reportEmbed', function () {
        return {
            controllerAs: '$ctrl',
            restrict: 'E',
            templateUrl: '/Scripts/app/reports/directives/report-embed.template.html',
            scope: {},
            bindToController: {
                reportId: '<',
                action: '='
            },
            link: function (scope, element, attrs)
            {
                scope.$watch('$ctrl.reportId', function (newValue) {
                    console.log('reportEmbed.reportIdChange', newValue);
                    if (newValue) {
                        scope.$ctrl.loadReportConfig(newValue, element);
                    }
                });

                scope.$watch('$ctrl.action', function (newValue) {
                    console.log('reportEmbed.actionChange', newValue);
                    switch (newValue) {
                        case 'print':
                            scope.$ctrl.print();
                            break;
                        case 'refresh':
                            scope.$ctrl.refresh();
                            break;
                        case 'fullScreen':
                            scope.$ctrl.fullScreen();
                            break;
                    }

                    scope.$ctrl.action = null;
                });
            },

            controller: ['$scope', '$window', 'reportService',
                function ($scope, $window, reportService)
                {
                    this.reportInstance = null;

                    this.print = function()
                    {
                        if (this.reportInstance === null)
                            return;

                        this.reportInstance.print();
                    }.bind(this);

                    this.refresh = function ()
                    {
                        if (this.reportInstance === null)
                            return;

                        this.reportInstance.reload();
                    }.bind(this);

                    this.fullScreen = function () {
                        if (this.reportInstance === null)
                            return;

                        this.reportInstance.fullscreen();
                    }.bind(this);

                    this.loadReportConfig = function (id, element) {

                        var reportContainer = $('div', element)[0];

                        reportService.getReportConfig(id)
                            .then(function (data) {

                                var models = window['powerbi-client'].models;

                                var config = {
                                    type: 'report',
                                    tokenType: models.TokenType.Embed,
                                    accessToken: data.embedToken.token,
                                    embedUrl: data.embedUrl,
                                    id: data.Id,
                                    permissions: models.Permissions.Read,
                                    settings: {
                                        filterPaneEnabled: true,
                                        navContentPaneEnabled: true
                                    }
                                };

                                if (this.reportInstance !== null)
                                {
                                    powerbi.reset(reportContainer);
                                }

                                this.reportInstance = powerbi.embed(reportContainer, config);


                                // $window.triggerResize();
                                window.dispatchEvent(new Event('resize'));

                            }.bind(this));
                    };
                }]
        };
    });