'use strict';

angular.module('balanceAdmin.quickCharge.components.quickChargeGroup',
    [
        'balanceAdmin.quickCharge.components.quickChargeButtons'
    ])
    .component('quickChargeGroup',
        {
            bindings: {
                groupId: '<',
                isTeam: '<',
                notifyLoaded: '&',
                notifyRefresh: '&',
                refreshFn: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/quickCharge/components/quick-charge-group.template.html',
            controller: [
                '$uibModal',
                '$timeout',
                'balanceAdminService',
                'groupService',
                'simpleAlertService',
                function (
                    $uibModal,
                    $timeout,
                    balanceAdminService,
                    groupService,
                    simpleAlertService) {

                    this.pupils = [];
                    this.isLoading = true;
                    this.sortType = 'name';
                    this.sortReverse = false;
                    this.eventName = '';
                    this.chargeVal = null;
                    this.chargeSubmitted = false;
                    this.chargeSubmitConfirmed = false;
                    this.includesTax = false;
                    this.sendNotification = false;

                    this.$onChanges = function (changes) {
                        if (this.refreshFn) {
                            this.refreshFn.fn = this.refresh.bind(this);
                        }

                        if (this.groupId && this.notifyRefresh()) {
                            this.notifyRefresh()(this.refresh);
                        }
                    }.bind(this);

                    this.refresh = function () {

                        this.charges = [];
                        this.isLoading = true;

                        // Get group charges
                        groupService.getPeopleInGroup(this.groupId, this.isTeam)
                            .then(function (pupils) {
                                this.pupils = pupils;

                                this.isLoading = false;
                                if (this.notifyLoaded()) {
                                    this.notifyLoaded()();
                                }
                            }.bind(this));
                    }.bind(this);

                    this.showQuickCharge = function () {

                        var quickChargeModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'quickChargeButtons',
                            size: 'md'
                        });

                        quickChargeModal.result
                            .then(function (data) {
                                this.setChargeValue(data.customValue);
                            }.bind(this))
                            .catch(function () {
                            });

                    }.bind(this);

                    this.setChargeValue = function (customValue) {
                        if (typeof customValue === 'undefined') {
                            return;
                        }
                        angular.forEach(this.pupils, function (pupil) {
                            pupil.newCharge = customValue;
                        }.bind(this));
                    }.bind(this);

                    this.confirmCharges = function () {

                        if (!this.pupils.some(function(pupil) { return pupil.newCharge > 0 })) {
                            simpleAlertService.errorAlert({
                                message: 'SB_Must_Have_At_Least_One_Charge'
                            });

                            $timeout(function(){this.chargeSubmitted = false;}.bind(this), 100);

                            return;
                        }

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Confirm',
                            message: 'SB_Confirm_Raise_Charges',
                            windowSize: 'sm',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel'
                        });

                        modalInstance.result
                            .then(function () {
                                this.chargeSubmitConfirmed = true;
                                this.submitCharges()
                                    .then(function () {
                                        this.chargeSubmitted = false;
                                    }.bind(this));
                            }.bind(this), function () {
                                this.chargeSubmitted = false;
                            }.bind(this));
                    }

                    this.submitCharges = function () {
                        var charges = [];
                        angular.forEach(this.pupils, function (pupil) {
                            charges.push(
                                {
                                    personId: pupil.id,
                                    amountToCharge: pupil.newCharge
                                });
                        });

                        var quickChargeRequest = {
                            charges: charges,
                            eventName: this.eventName,
                            includesTax: this.includesTax,
                            sendNotification: this.sendNotification,
                            groupId: this.groupId,
                            isTeam: this.isTeam
                        };

                        return balanceAdminService.quickCharge(quickChargeRequest)
                            .then(function (quickChargeResponse) {
                                this.chargeSubmitConfirmed = false;
                                this.eventName = '';

                                if (!quickChargeResponse || !quickChargeResponse.failedCharges || quickChargeResponse.failedCharges.length > 0) {
                                    simpleAlertService.errorAlert({
                                        message: 'SB_Error_Saving'
                                    });

                                    if (this.notifyRefresh()) {
                                        this.notifyRefresh()();
                                    }

                                    return;
                                }

                                if (quickChargeResponse.failedCharges.length == 0) {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Success',
                                        message: 'SB_Success'
                                    });

                                    if (this.notifyRefresh()) {
                                        this.notifyRefresh()();
                                    }

                                    return;
                                }
                            }.bind(this))
                            .catch(function () {
                                this.chargeSubmitConfirmed = false;

                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_Saving_Message'
                                });
                            }.bind(this))
                    }.bind(this);
                }
            ]
        });