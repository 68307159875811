'use strict';

angular.module('person.directives.personPicker', [
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'shared.components.sbIcon',
    'shared.directives.sbLoading',
    'person.services.personPickerService',
    'personPicker.constants',
])
    .directive('personPicker', [
        'personService', '$uibModal', '$timeout', '$filter', 'recipientSearchTypeEnum', 'memberTypes',
        function (personPickerService, $uibModal, $timeout, $filter, recipientSearchTypeEnum, memberTypes) {
            function link($scope) {

                $scope.recipientSearchEnum = recipientSearchTypeEnum;
                $scope.memberTypes = memberTypes;

                $scope.$watch('recipientSearchType',
                    function (newValue) {

                        $scope.searchCriteria = '';
                        $scope.pagination.currentPage = 1;
                        $scope.filter = {
                            n: '',
                            title: ''
                        };
                        $scope.maxPeopleResults = 100;

                        $scope.showJSSearchBoxTitle = false;
                        $scope.showSqlSearchBox = false;
                        $scope.showJSSearchBox = false;

                        switch (parseInt(newValue)) {
                            case $scope.recipientSearchEnum.Clubs:
                            case $scope.recipientSearchEnum.Teams:
                            case $scope.recipientSearchEnum.Events:
                                $scope.showJSSearchBox = true;
                                break;
                            case $scope.recipientSearchEnum.People:
                                $scope.showSqlSearchBox = true;
                                break;
                            case $scope.recipientSearchEnum.EventGroupedPersons:
                                $scope.showExtraContinueButton = false;
                                break;
                            case $scope.recipientSearchEnum.EventPersons:
                                $scope.showJSSearchBox = true;
                                $scope.showExtraContinueButton = false;
                                break;
                            case $scope.recipientSearchEnum.WholeSchool:
                                $scope.showExtraContinueButton = false;
                                break;
                            case $scope.recipientSearchEnum.SubjectClasses:
                                $scope.showJSSearchBoxTitle = true;
                                $scope.showExtraContinueButton = false;
                                break;
                        }

                        $scope.lookupItems(parseInt(newValue));

                    });

                    $scope.onChangeSearchCriteria = () => {
                        if ($scope.searchCriteria.length < 2){
                            // We don't search if less than two search characters, so we should clear results
                            $scope.items = [];
                            return;
                        }
                        if ($scope.recipientSearchType === $scope.recipientSearchEnum.People) {
                            $scope.lookupPeople();
                        }
                    };
            }

            function controller($scope, $filter, personPickerService, recipientSearchTypeEnum, memberTypes) {

                let toLoad = 0;
                let currentCollection = [];

                $scope.pagination = {
                    currentPage : 1,
                    eventsPerPage : 10
                }

                $scope.showRecipientSearchType = true;
                $scope.recipientSearchEnum = recipientSearchTypeEnum;
                $scope.memberTypes = memberTypes;

                //set a default search type??  $scope.recipientSearchType

                $scope.searchCriteria = '';

                $scope.showSqlSearchBox = false;
                $scope.showJSSearchBox = false;

                personPickerService.clearCache();

                if (!$scope.selection) {
                    $scope.selection = {
                        teams: [],
                        clubs: [],
                        people: [],
                        events: [],
                        eventPeople: [],
                        eventGroupedPeople: [],
                        subjectClasses:[],
                    };
                }

                // Items Array for table result
                $scope.items = [];
                $scope.filteredItems = [];

                $scope.lookupPeople = function () {
                    toLoad++;

                    personPickerService.getPeople($scope.searchCriteria, $scope.maxPeopleResults)
                        .then(function (data) {
                            $scope.items = data;
                            $scope.filteredItems = $scope.items;
                            toLoad--;
                        })
                        .catch(function () {
                            console.warn('problem loading results from person search');
                            toLoad--;
                        });

                };

                $scope.lookupItems = function (itemType) {

                    if (typeof $scope.restrictToOwnGroups === 'undefined') {
                        $scope.restrictToOwnGroups = true;
                    };

                    switch (itemType) {
                        case $scope.recipientSearchEnum.Clubs:
                            toLoad++;
                            currentCollection = $scope.selection.clubs;
                            $scope.items = [];
                            personPickerService.getClubs($scope.restrictToOwnGroups)
                                .then(function (data) {
                                    $scope.items = data;
                                    toLoad--;
                                });
                            break;
                        case $scope.recipientSearchEnum.Teams: // teams
                            toLoad++;
                            currentCollection = $scope.selection.teams;
                            $scope.items = [];
                            personPickerService.getTeams($scope.restrictToOwnGroups)
                                .then(function (data) {

                                    for (var i = data.length; i--;) {
                                        data[i].n = data[i].name;
                                    }

                                    $scope.items = data;

                                    toLoad--;
                                });
                            break;
                        case $scope.recipientSearchEnum.People: // people
                            currentCollection = $scope.selection.people;
                            $scope.items = [];
                            break;
                        case $scope.recipientSearchEnum.Events: // List of events
                            toLoad++;
                            currentCollection = $scope.selection.events;
                            $scope.items = [];
                            personPickerService.getEvents($scope.restrictToOwnGroups)
                                .then(function (data) {

                                    $scope.items = data;

                                    toLoad--;
                                });
                            break;
                        case $scope.recipientSearchEnum.EventPersons: // events people
                            toLoad++;
                            currentCollection = $scope.selection.eventPeople;
                            $scope.items = [];
                            personPickerService.getEventPeople($scope.eventId)
                                .then(function (data) {

                                    $scope.items = data;

                                    toLoad--;
                                });
                            break;
                        case $scope.recipientSearchEnum.EventGroupedPersons: // Event by person type group, student, parent and / or staff.
                            toLoad++;
                            $scope.items = [];
                            currentCollection = $scope.selection.eventGroupedPeople;
                            $scope.recipientSearchType = $scope.recipientSearchEnum.EventGroupedPersons;

                            personPickerService.getEventGroupedPeople($scope.eventId)
                                .then(function (data) {

                                    $scope.items = data.results;

                                    toLoad--;
                                });
                            break;
                        case $scope.recipientSearchEnum.WholeSchool:
                            toLoad++;
                            $scope.items = [];
                            currentCollection = $scope.selection.wholeSchoolGroup;
                            $scope.recipientSearchType = $scope.recipientSearchEnum.WholeSchool;

                            personPickerService.getWholeSchoolGroups()
                                .then(function (data) {

                                    $scope.items = data;

                                    toLoad--;
                                });
                            break;
                        case $scope.recipientSearchEnum.SubjectClasses:
                            toLoad++;
                            $scope.items = [];
                            currentCollection = $scope.selection.subjectClasses;
                            $scope.recipientSearchType = $scope.recipientSearchEnum.SubjectClasses;

                            personPickerService.getSubjectClasses()
                                .then(function (data) {

                                    $scope.items = data;

                                    toLoad--;
                                });
                            break;
                        default:
                            return;
                    }
                };

                $scope.addItem = function (item, memberType) {

                    var itemToAdd = {
                        id: item.id,
                        displayName: item.n,
                        memberType: parseInt(memberType)
                    };

                    var removeNonEveryoneMembers = itemToAdd.memberType === 0;
                    var itemsToRemove = [];

                    for (var i = 0; i < currentCollection.length; i++) {

                        if (currentCollection[i].id === itemToAdd.id) {

                            if (currentCollection[i].memberType === itemToAdd.memberType) {
                                // we have an exact match- bail!
                                return;
                            }

                            if (removeNonEveryoneMembers && currentCollection[i].memberType !== 0) {
                                itemsToRemove.push(currentCollection[i]);
                            }
                        }
                    }

                    if (removeNonEveryoneMembers) {
                        for (var i = 0; i < itemsToRemove.length; i++) {
                            var index = currentCollection.indexOf(itemsToRemove[i]);
                            if (index > -1) {
                                currentCollection.splice(index, 1);
                            }
                        }
                    }

                    currentCollection.push(itemToAdd);
                };

                $scope.memberTypeLabel = function (item) {
                    var label = '';
                    switch (item?.memberType)
                    {
                        case memberTypes.All.id:
                            label = memberTypes.All.label
                            break;
                        case memberTypes.Staff.id:
                            label = memberTypes.Staff.label;
                            break;
                        case memberTypes.Student.id:
                            label = memberTypes.Student.label;
                            break;
                        case memberTypes.Parent.id:
                            label = memberTypes.Parent.label;
                            break;
                        default: return '';
                    }
                    return `${$filter('translate')(label)} (${item.countOfMembers})`;
                }

                $scope.memberTypeButtonLabel = function (item) {
                    var label = '';
                    switch (item?.memberType) {
                        case memberTypes.All.id:
                            label = memberTypes.All.buttonLabel;
                            break;
                        case memberTypes.Staff.id:
                            label = memberTypes.Staff.buttonLabel;
                            break;
                        case memberTypes.Student.id:
                            label = memberTypes.Student.buttonLabel;
                            break;
                        case memberTypes.Parent.id:
                            label = memberTypes.Parent.buttonLabel;
                            break;
                        default: return '';
                    }

                    return `${$filter('translate')(label)} (${item.countOfMembers})`;
                }

                $scope.addGroupItem = function (item) {
                    //check we have members to add
                    if (item?.countOfMembers < 1) { return; }

                    //check if already exists.
                    if ($filter('filter')(currentCollection, { id: item.memberType }, true).length > 0) { return; };

                    currentCollection.push({
                        id: parseInt(item.memberType),
                        displayName: $scope.memberTypeLabel(item),
                        memberType: parseInt(item.memberType),
                        countOfMembers: item.countOfMembers ?? 0
                    });
                };

                $scope.addMemberGroup = function (item, memberType) {
                    if (!item) { return; }
                    // All Member
                    if (Array.isArray(item) && memberType === memberTypes.All.id){
                        item.forEach(member => $scope.addGroupItem(member));
                        return;
                    }

                    $scope.addGroupItem(item)
                };

                $scope.addAllClassItems = function (items) {
                    if (!items || !Array.isArray(items.reciepientTypes)) { return; }

                    items.reciepientTypes.forEach(member => $scope.addClassItem(items, member.memberType));
                };

                $scope.addClassItem = function (item, memberType) {

                    var recipientType = $filter('filter')(item.reciepientTypes, { memberType: memberType });

                    if (recipientType.length != 1 || recipientType[0].countOfMembers < 1) { return; }

                    if ($filter('filter')(currentCollection, { id: item.id, memberType: memberType }, true).length > 0) { return; };

                    currentCollection.push({
                        id: parseInt(item.id),
                        displayName: `${item.title} ${$scope.memberTypeLabel(recipientType[0])}`,
                        memberType: parseInt(memberType),
                        countOfMembers: item.countOfMembers ?? 0
                    });
                };

                $scope.addEventPerson = function (item) {

                    var itemToAdd = {
                        id: item.id,
                        displayName: item.n ?? item.fn + ' ' + item.ln,
                        memberType: parseInt(item.t)
                    };

                    for (var i = 0; i < currentCollection?.length; i++) {

                        if (currentCollection[i]?.id === itemToAdd.id) {
                            // already exists- bail!
                            return;
                        }
                    }

                    currentCollection.push(itemToAdd);
                };

                $scope.addPerson = function (item) {

                    var itemToAdd = {
                        id: item.id,
                        displayName: item.n ?? item.fn + ' ' + item.ln
                    };

                    for (var i = 0; i < currentCollection?.length; i++) {

                        if (currentCollection[i]?.id === itemToAdd.id) {
                            // already exists- bail!
                            return;
                        }
                    }

                    currentCollection.push(itemToAdd);
                };

                $scope.loading = function () {
                    return toLoad > 0;
                };

                $scope.toggleTeamExpansion = function (item) {
                    if (!item.expanded) {
                        item.expanded = true;

                        if (!item.expandedItems)
                            item.expandedItems = [];

                        if (!item.expandedItemsLoaded) {
                            // load expanded items
                            personPickerService.getRelatedPeople(item.id, item.t)
                                .then(function (data) {
                                    item.expandedItems = data;
                                    item.expandedItemsLoaded = true;
                                });
                        }

                    } else {
                        item.expanded = false;
                    }
                };

                $scope.togglePersonExpansion = function (item) {
                    if (!item.expanded) {
                        item.expanded = true;

                        if (!item.expandedItems)
                            item.expandedItems = [];

                        if (!item.expandedItemsLoaded) {
                            // load expanded items
                            personPickerService.getRelatedPeople(item.id, item.t)
                                .then(function (data) {
                                    item.expandedItems = data;
                                    item.expandedItemsLoaded = true;
                                });
                        }

                    } else {
                        item.expanded = false;
                    }
                };

                $scope.extraCloseAction = function () {
                    if (!$scope.onContinueClick() || typeof $scope.onContinueClick() !== 'function') {
                        console.error('No continue callback function defined');
                    } else {
                        $scope.onContinueClick()();
                    }
                };

                $scope.showTableRow = function(index) {
                    return index >= (($scope.pagination.currentPage - 1) * $scope.pagination.eventsPerPage)
                        && index < ($scope.pagination.currentPage * $scope.pagination.eventsPerPage);
                };
            }

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/person/directives/person-picker.template.html',
                scope: {
                    selection: '=',
                    restrictToOwnGroups: '=',
                    disablePersonPicking: '=',
                    useSimpleAddButtonForClubs: '=',
                    useSimpleAddButtonForTeams: '=',
                    useSimpleAddButtonForEvents: '=',
                    showExtraContinueButton: '=',
                    showEvents: '=',
                    onContinueClick: '&',
                    disableClubPicking: '<',
                    disableTeamPicking: '<',
                    useEventPersons: '<',
                    showEventPersonGroups: '<',
                    showEventPersons: '<',
                    eventId: '<',
                    showWholeSchoolGroup: '<',
                    showSubjectClasses: '<'
                },
                controller: ['$scope', '$filter', 'personPickerService', 'recipientSearchTypeEnum', 'memberTypes', controller],
                link: link
            };
        }
    ]);
