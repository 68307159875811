'use strict';

angular.module('balanceAdmin.recordPayment.components.recordPayment', [
    'balanceAdmin.recordPayment.components.recordPaymentFilter',
    'balanceAdmin.recordPayment.components.recordPaymentDetail',
    'balanceAdmin.recordPayment.components.recordPaymentCheckout'
])
    .component('recordPayment',
        {
            bindings: {},
            templateUrl: '/Scripts/app/balanceAdmin/recordPayment/components/record-payment.template.html',
            controllerAs: 'ctrl',
            controller: [
                '$state',
                function ($state) {
                    // Set-up our filters.
                    this.searchFilters = {
                        eventId: 0,
                        personId: 0
                    };

                    this.stages = {
                        SEARCH: 0,
                        CHECKOUT: 1,
                        COMPLETE: 2
                    };

                    this.charges = null;
                    this.paymentMethods = null;
                    this.recordPaymentSubmitted = false;
                    this.receiptData = null;

                    this.stage = this.stages.SEARCH;

                    this.checkout = function (selectedCharges) {
                        this.charges = selectedCharges;
                        this.stage = this.stages.CHECKOUT;
                    }.bind(this);

                    this.complete = function (receiptData) {
                        this.receiptData = receiptData;
                        this.stage = this.stages.COMPLETE;
                    }.bind(this);

                    this.back = function () {
                        this.stage = this.stages.SEARCH;
                        this.recordPaymentSubmitted = false;
                        this.charges = null;
                        this.paymentMethods = null;
                    }.bind(this);

                    this.startOver = function () {
                        $state.reload();
                    };
                }
            ]
        });
