import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sb-search-filter-item',
  templateUrl: './search-filter-item.component.html',
  styleUrls: ['./search-filter-item.component.scss']
})
export class SearchFilterItemComponent implements OnInit {

  @Input() name: string;
  @Input() isCheckboxList: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
