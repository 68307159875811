angular.module('settingsModule')
    .component('linksPortalAdmin',
        {
            templateUrl: '/Scripts/app/settings/components/links-portal-admin/links-portal-admin.template.html',
            controller: class LinksPortalAdminCtrl {

                // Dependencies
                $translate: any;
                $filter: any;
                $timeout: any;
                $window: any;
                $document: any;
                $uibModal: any;
                linksPortalAdminService: any;
                simpleAlertService: any;
                stringService: any;
                arrayService: any;

                // Variables
                // UI
                loading: number = 1;
                isError: boolean = false;
                showFilters: boolean = false;
                filters: any;
                titleBarButtons: any[] = [];
                isContentList: boolean = false;
                isProcessing: boolean = false;
                // Viewer type
                viewerTypes: any[] = [];
                selectedViewerType: number = 0;
                // Categories
                selectedCategory: number = 0;
                isAddingCategory: boolean = false;
                newCategoryName: string = '';
                menuCategories: any[] = [];
                editedCategory: number = 0;
                showFeatured: boolean = false;
                revertedCatName: string = '';
                // Links
                links: any[] = [];
                statusOptions: any[] = [];
                linkSortType: string = 'name';
                linkSortReverse: boolean = false;
                // Contents
                contents: any[] = [];
                authorOptions: any[] = [];
                contentSortType: string = 'name';
                contentSortReverse: boolean = false;

                static $inject = ['$translate', '$filter', '$timeout', '$window', '$document', '$uibModal', 'linksPortalAdminService', 'simpleAlertService', 'stringService', 'arrayService'];

                constructor($translate: any, $filter: any, $timeout: any, $window: any, $document: any, $uibModal: any, linksPortalAdminService: any, simpleAlertService: any, stringService: any, arrayService: any) {
                    this.$translate = $translate;
                    this.$filter = $filter;
                    this.$timeout = $timeout;
                    this.$window = $window;
                    this.$document = $document;
                    this.$uibModal = $uibModal;
                    this.linksPortalAdminService = linksPortalAdminService;
                    this.simpleAlertService = simpleAlertService;
                    this.stringService = stringService;
                    this.arrayService = arrayService;
                }

                $onInit() {
                    this.menuCategories = [];
                    this.links = [];
                    this.isError = false;
                    this.newCategoryName = '';
                    this.viewerTypes = [
                        {
                            id: 1,
                            name: 'SB_Parents'
                        },
                        {
                            id: 2,
                            name: 'SB_Pupils'
                        },
                        {
                            id: 3,
                            name: 'SB_Staff'
                        },
                        {
                            id: 4,
                            name: 'SB_Admin'
                        },
                        {
                            id: 5,
                            name: 'SB_External'
                        },
                    ];
                    this.statusOptions = [
                        {
                            id: 0,
                            name: 'SB_All'
                        },
                        {
                            id: 1,
                            name: 'SB_Link_Published_Option'
                        },
                        {
                            id: 2,
                            name: 'SB_Link_Unpublished_Option'
                        },
                    ];
                    this.resetFilters();
                    this.linksPortalAdminService.getMenuCategories()
                        .then((res: any) => {
                            this.menuCategories = res?.data || [];
                            this.isError = this.isError || res.isError;
                        })
                        .finally(() => {
                            this.loading -= 1;
                        })
                    this.getLinks();
                    this.getContents();
                    this.updateTitleBarButtons();
                }

                toggleList() {
                    this.isContentList = !this.isContentList;
                    this.updateTitleBarButtons();
                    this.resetFilters();
                    this.showFilters = false;
                }

                updateTitleBarButtons() {
                    this.titleBarButtons = [
                        {
                            message: 'SB_Add',
                            buttonClass: 'primary',
                            iconName: 'plus',
                            buttonId: 'addLink',
                            xsIconOnly: true,
                            action: this.addLink.bind(this)
                        }
                    ];
                    if (this.isContentList) {
                        this.titleBarButtons.push({
                            message: 'SB_View_Links',
                            buttonClass: 'secondary',
                            iconName: 'link',
                            buttonId: 'viewLinks',
                            xsIconOnly: true,
                            action: this.toggleList.bind(this)
                        });
                    }
                    else {
                        this.titleBarButtons.push({
                            message: 'SB_View_Content',
                            buttonClass: 'secondary',
                            iconName: 'file',
                            buttonId: 'viewContent',
                            xsIconOnly: true,
                            action: this.toggleList.bind(this)
                        })
                    }
                }

                resetFilters() {
                    this.filters = {
                        links: {
                            text: '',
                            status: 0
                        },
                        contents: {
                            text: '',
                            author: 0
                        }
                    }
                }

                // Categories

                startAddingCategory() {
                    this.isAddingCategory = true;
                    this.$timeout(() => {
                        document.getElementById('newCategoryNameInput').focus();
                    });
                }

                finishAddingCategory() {
                    this.isAddingCategory = false;
                    if (this.newCategoryName.length > 0) {
                        this.linksPortalAdminService.createMenuCategory(this.newCategoryName, this.menuCategories.length)
                            .then((res: any) => {
                                if (res.data) {
                                    this.menuCategories.push(res.data);
                                }
                            });
                    }
                    this.newCategoryName = '';
                }

                formatCatForRequest(cat: any) {
                    return {
                        id: cat.id,
                        name: cat.name,
                        isActive: cat.isActive,
                        displayOrder: cat.displayOrder
                    }
                }

                startEditingCategory(catId: number) {
                    this.revertedCatName = this.menuCategories.filter(item => { return item.id === catId })[0].name;
                    this.editedCategory = catId;
                    this.$timeout(() => {
                        this.$document.getElementById('categoryNameInput-' + catId).focus();
                    });
                }

                finishEditingCategory(catId: number) {
                    this.editedCategory = 0;
                    const cat = this.menuCategories.filter(item => { return item.id === catId })[0];
                    if (this.stringService.containsAlphanumeric(cat.name) && cat.name !== this.revertedCatName) {
                        this.linksPortalAdminService.updateMenuCategory(this.formatCatForRequest(cat))
                            .then((res: any) => {
                                if (res.isError) {
                                    this.revertCatName(catId);
                                }
                            });
                    }
                    else {
                        this.revertCatName(catId);
                    }
                }

                revertCatName(catId: number) {
                    this.menuCategories.forEach(cat => {
                        if (cat.id === catId) {
                            cat.name = this.revertedCatName;
                            this.revertedCatName = '';
                        }
                    })
                }

                increaseDisplayOrder(catId: number) {
                    if (!this.isProcessing) {
                        this.isProcessing = true;
                        this.linksPortalAdminService.increaseDisplayOrder(catId)
                            .then((res: any) => {
                                if (res.value?.data) {
                                    this.menuCategories = res.value.data;
                                }
                            })
                            .finally(() => {
                                this.isProcessing = false;
                            })
                    }
                }

                decreaseDisplayOrder(catId: number) {
                    if (!this.isProcessing) {
                        this.linksPortalAdminService.decreaseDisplayOrder(catId)
                            .then((res: any) => {
                                if (res.value?.data) {
                                    this.menuCategories = res.value.data;
                                }
                            })
                            .finally(() => {
                                this.isProcessing = false;
                            })
                    }
                }

                deleteCategory(cat: any) {
                    if (this.isCategoryEmpty(cat.id)) {
                        const confirmationModal = this.simpleAlertService.simpleAlert({
                            title: cat.name,
                            message: 'SB_Delete_Menu_Category_Confirmation',
                            okButtonText: 'SB_Delete',
                            cancelButtonText: 'SB_Cancel'
                        });
                        confirmationModal.result.then(() => {
                        this.linksPortalAdminService.deleteMenuCategory(cat.id)
                            .then((res: any) => {
                                if (res.isSuccess) {
                                    this.menuCategories = this.menuCategories.filter(item => item.id !== cat.id);
                                }
                            })
                        });
                    }
                }

                isCategoryEmpty(catId: number): boolean {
                    return this.links.filter(link => {return link.menuCategoryId === catId}).length === 0;
                };

                selectCategory(catId: number) {
                    this.selectedCategory = catId;
                    this.showFeatured = false;
                }

                // Links

                getLinks() {
                    this.loading += 1;
                    this.linksPortalAdminService.getLinks()
                    .then((res: any) => {
                        this.links = res?.data || [];
                        this.isError = this.isError || res.isError;
                        this.$translate.onReady(() => {
                            this.links.forEach(link => {
                                const linkViewers = this.viewerTypes.filter(viewer => {return link.userAccessTypeIds.includes(viewer.id)});
                                if (linkViewers.length === this.viewerTypes.length) {
                                    link.viewersString = 'SB_All_Users';
                                }
                                else {
                                    link.viewersString = '';
                                    linkViewers.forEach((viewer, index) => {
                                        link.viewersString += (this.$filter('translate')(viewer.name) + (index !== linkViewers.length -1 ? ', ' : ''));
                                    });
                                }
                                link.iconImageUrl = (`${this.$window.EveryBuddy.CdnBase}img/icons/${link.iconImageUrl}`) || '';
                                link.shortUrl = this.stringService.shortenUrl(link.linkUrl);
                            });
                        })
                    })
                    .finally(() => {
                        this.loading -= 1;
                    })
                }

                showAllLinks() {
                    this.selectedCategory = 0;
                    this.showFeatured = false;
                }

                showFeaturedLinks() {
                    this.selectedCategory = 0;
                    this.showFeatured = true;
                }

                linksFilter(item: any, catId: number) {
                    return (item: any) => {
                        // Filter by specified cat in preview or by selected cat in table
                        const filterCatId = catId || this.selectedCategory;
                        const catMatch = item.menuCategoryId === filterCatId || !this.selectedCategory && !catId ;
                        const viewerMatch = item.userAccessTypeIds.includes(this.selectedViewerType) || !this.selectedViewerType || !catId;
                        const publishedPreviewMatch = item.isPublished;
                        // Dropdown preview where we filter by category and viewer type
                        if (catId) {
                            return catMatch && viewerMatch && publishedPreviewMatch;
                        }
                        // Table preview where we also apply other filters
                        const featuredMatch = item.isFeatured || !this.showFeatured;
                        const publishedMatch = this.filters.links.status === 0 || (this.filters.links.status === 1 && item.isPublished) || (this.filters.links.status === 2 && !item.isPublished);
                        const textMatch = item.name.toLowerCase().indexOf(this.filters.links.text.toLowerCase()) !== -1 ||
                                          (item.linkUrl && item.linkUrl.toLowerCase().indexOf(this.filters.links.text.toLowerCase()) !== -1) ||
                                          (item.contentName && item.contentName.toLowerCase().indexOf(this.filters.links.text.toLowerCase()) !== -1) ||
                                          this.filters.links.text === '';
                        return catMatch && viewerMatch && featuredMatch && publishedMatch && textMatch;
                    }
                }

                openLink(link: any) {
                    if (link.isExternal) {
                        this.$window.open(link.linkUrl);
                    }
                    else {
                        this.$window.open('/Dashboard/portalPage/' + link.organisationPortalContentId);
                    }
                }

                addLink(catId: number) {
                    this.linkModal(0, catId, 0);
                }

                editLink(linkId: number) {
                    this.linkModal(linkId, 0, 0);
                }

                moveLink(linkId: number, value: number) {
                    const link = this.links.filter(link => {return link.id === linkId})[0];
                    link.displayOrder = link.displayOrder + value;
                    this.linksPortalAdminService.updateLink(link, true);
                    const adjacentLink = this.links.filter(adjacentLink => {return adjacentLink.displayOrder === link.displayOrder && adjacentLink.id !== link.id})[0];
                    if (adjacentLink) {
                        adjacentLink.displayOrder += -value;
                        this.linksPortalAdminService.updateLink(adjacentLink, true);
                    }
                }

                deleteLink(link: any) {
                    const confirmationModal = this.simpleAlertService.simpleAlert({
                        title: link.name,
                        message: 'SB_Delete_Link_Confirmation',
                        okButtonText: 'SB_Delete',
                        cancelButtonText: 'SB_Cancel'
                    });
                    confirmationModal.result.then(() => {
                    this.linksPortalAdminService.deleteLink(link.id)
                        .then((res: any) => {
                            if (res.isSuccess) {
                                this.links = this.links.filter(item => item.id !== link.id);
                            }
                        })
                    });
                }

                togglePublishLink(link: any) {
                    const confirmationModal = this.simpleAlertService.simpleAlert({
                        title: link.name,
                        message: link.isPublished ? 'SB_Unpublish_Menu_Link_Confirmation' : 'SB_Publish_Menu_Link_Confirmation',
                        okButtonText: link.isPublished ? 'SB_Link_Unpublish' : 'SB_Link_Publish',
                        cancelButtonText: 'SB_Cancel'
                    });
                    confirmationModal.result.then(() => {
                        if (link.isPublished) {
                            this.linksPortalAdminService.unPublishLink(link.id)
                                .then((res: any) => {
                                    if (res.isSuccess) {
                                        this.links.forEach(item => {
                                            if (item.id === link.id) {
                                                item.isPublished = false;
                                            };
                                        });
                                    }
                                });
                        }
                        else {
                            this.linksPortalAdminService.publishLink(link.id)
                                .then((res: any) => {
                                    if (res.isSuccess) {
                                        this.links.forEach(item => {
                                            if (item.id === link.id) {
                                                item.isPublished = true;
                                            };
                                        });
                                    }
                                });
                        }
                    });
                }

                linkModal(linkId: number, catId: number, contentId: number) {
                    const viewerTypes = this.viewerTypes;
                    const modalInstance = this.$uibModal.open({
                        animation: true,
                        component: 'editLink',
                        size: 'xl',
                        backdrop: 'static',
                        resolve: {
                            linkId: () => {
                                return linkId;
                            },
                            categoryId: () => {
                                return catId;
                            },
                            contentId: () => {
                                return contentId;
                            },
                            viewerTypes: () => {
                                return viewerTypes;
                            }
                        }
                    });

                    modalInstance
                        .result
                        .then(() =>
                        {
                            // Refresh data to make sure everything is up-to-date
                            this.getLinks();
                            this.getContents();
                        })
                        .catch(() =>
                        {
                        });
                }

                // Content

                getContents() {
                    this.loading += 1;
                    this.linksPortalAdminService.getContents()
                    .then((res:any) => {
                        if (res.data) {
                            this.contents = res.data;
                            const authors = this.contents.map(item => {
                                return item.author;
                            })
                            this.authorOptions = this.arrayService.uniqueBy(authors, (author: any) => {return author.id});
                            this.authorOptions.unshift({
                                id: 0,
                                fullName: 'SB_All_Authors'
                            })
                        }
                    })
                    .finally(()=> {
                        this.loading -=1;
                    })
                }

                contentsFilter(item: any) {
                    return (item: any) => {
                        const textFilter = this.filters.contents.text === '' || item.name.toLowerCase().indexOf(this.filters.contents.text.toLowerCase()) > -1;
                        const authorFilter = this.filters.contents.author === 0 || item.author.id === this.filters.contents.author;
                        return textFilter && authorFilter;
                    }
                }

                isContentUnused(contentId: number) {
                    return this.links.filter(link => {
                        return link.organisationPortalContentId === contentId;
                    }).length === 0;
                }

                deleteContent(content: any) {
                    const confirmationModal = this.simpleAlertService.simpleAlert({
                        title: content.name,
                        message: 'SB_Delete_Content_Confirmation',
                        okButtonText: 'SB_Delete',
                        cancelButtonText: 'SB_Cancel'
                    });
                    confirmationModal.result.then(() => {
                    this.linksPortalAdminService.deleteContent(content.id)
                        .then((res: any) => {
                            if (res.isSuccess) {
                                this.contents = this.contents.filter(item => item.id !== content.id);
                            }
                        })
                    });
                }

                // Keyboard events

                keyUp(e: any) {
                    // If adding/editing a category and Enter key pressed
                    if ((!this.isAddingCategory && !this.editedCategory) || e.which !== 13) {
                        return;
                    }
                    if (this.editedCategory) {
                        this.finishEditingCategory(this.editedCategory);
                    }
                    else if (this.isAddingCategory) {
                        this.finishAddingCategory();
                    }
                }
            }
        });
