export enum MainMenuItemId {
    None = 0,
    Dashboard = 1,
    Diary = 2,
    Messaging = 3,
    BalanceAdmin = 5,
    Balance = 6,
    Reception = 7,
    CcaSignUp = 8,
    CcaAvailable = 9,
    Transport = 10,
    Portal = 11,
    Settings = 12,
    Profile = 13,
    Help = 14,
    TuitionFees = 15,
    SportsPortal = 16,
    TripsPortal = 17,
    Invites = 18,
    SysAdmin = 19,
    SysAdminMVC = 20,
    Admin = 21,
    Organising = 22
}
