import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Injectable } from '@angular/core';
import { apis, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstallationSettingsService {

  baseUrl = 'WebInstallationSettings/';
  config: HttpRequestSettings = {
    api: apis.Web
  };

  constructor(private http: HttpWebApiService) { }

  getDefaultColours() {
    return this.http.get(this.baseUrl + 'GetDefaultColours', this.config).pipe(map(data => {
      const colours = data;
      return { primaryColour: colours.defaultPrimaryColour, secondaryColour: colours.defaultSecondaryColour };
    }))
  }

  getMapsUrl(){
    return this.http.get(this.baseUrl + 'GetMapsUrl', this.config)
      .pipe(map(response => {
          const url = response.mapsURL;
          const isBaidu = url.indexOf('map.baidu.com') !== -1;
          return { value: url, label: isBaidu ? '百度地图' : 'SB_Google_Maps', isBaidu: isBaidu };
      }));
  }
}
