"use strict";

angular.module('ccaAvailable.components.ccaAvailableSummary', [
    'ccaAvailable.services.availableRepository',
    'ccaAvailable.services.ccaActionsService',
    'shared.components.sbIcon',
    'shared.components.sbLabelList'
])
    .component('ccaAvailableSummary',
        {
            templateUrl: '/Scripts/app/ccaAvailable/Components/cca-available-summary.template.html',
            bindings: {
                onLoadStart: '&',
                onLoadEnd: '&',
                hasLoaded: '<'
            },
            controller: class CcaAvailableSummaryCtrl {

                // Dependencies
                $window: any;
                $timeout: any;
                availableRepository: any;
                ccaActionsService: any;

                // Bindings
                onLoadStart: any;
                onLoadEnd: any;
                hasLoaded: boolean;

                // Properties
                signUps: any[];
                maxSignUps: number = 4;

                static $inject = ['$window', '$timeout', 'availableRepository', 'ccaActionsService'];

                constructor ($window, $timeout, availableRepository, ccaActionsService) {
                    this.$window = $window;
                    this.$timeout = $timeout;
                    this.availableRepository = availableRepository;
                    this.ccaActionsService = ccaActionsService;
                }

                $onInit() {
                    this.onLoadStart();
                    angular.element(this.$window).bind('resize', () => {
                        this.updateMax(false);
                    });
                    this.availableRepository.get()
                        .then((signUps) => {
                            this.signUps = this.ccaActionsService.addActionsToSignUps(signUps);
                        })
                        .finally(() => {
                            this.onLoadEnd();
                        });
                };

                $onChanges(changes) {
                    if (changes.hasLoaded?.currentValue) {
                        this.updateMax(true);
                    }
                }

                triggerPrimaryAction(signUp) {
                    this.ccaActionsService.triggerPrimaryAction(signUp);
                };

                triggerAction($event, action, signUp) {
                    $event.stopPropagation();
                    action.function(signUp);
                };

                viewAllSignUps() {
                    this.ccaActionsService.viewAllSignUps();
                };

                updateMax = (isFirstLoad: boolean) => {
                    // Show max 4 or 5 signUps depending on amount of unused space
                    const defaultCount = 4;
                    if (this.signUps?.length > defaultCount) {
                        this.$timeout(() => {
                            const signUpList = angular.element(document.getElementById('signUpList'));
                            const signUpListHeight = signUpList[0].offsetHeight;
                            const signUpItems = signUpList.children();
                            if (signUpItems.length > 0) {
                                const signUpItemHeight = signUpItems[0].offsetHeight;
                                const freeSpace = signUpListHeight - (signUpItemHeight * signUpItems.length);
                                this.maxSignUps = (freeSpace - signUpItemHeight) > 1 ? 5 : defaultCount;
                            }
                            /* Unfortunately need to add a set timeout so not thrown off by twitter widget which expands once loaded,
                            until this is amended to use Twitter API and our own front end */
                        }, isFirstLoad ? 300 : 0);
                    }
                };

            }
        });
