'use strict';

angular.module('receptionModule').component('receptionGroupsDocumentStore',  {
    templateUrl: '/Scripts/app/reception/controllers/groups/reception-document-store.template.html?v=', // + window.EveryBuddy.version
    controller: [
    '$scope', '$state', '$filter', '$uibModal','cachedLookupService',
    function ($scope, $state, $filter, $uibModal,cachedLookupService) {

        $scope.updatedDocument = null;
        //$scope.fileSizeLimit = 4242880;

        cachedLookupService
            .getSystemSettings([
                'Document_FileSizeLimit'
            ])
            .then(function(settings)
            {
                $scope.fileSizeLimit = settings['Document_FileSizeLimit'];

            });



        $scope.handleDocumentSelection = function (document) {
            $scope.selectedDocument = document;
            $scope.selectedDocumentId = document.id;
        };

        $scope.add = function () {
            $scope.selectedDocument = { id: 0, fileName: '' };
            $scope.selectedDocumentId = 0;
            $scope.updatedDocument = $scope.selectedDocument;
        };

        $scope.handleDocumentUpdate = function (document) {

            $scope.updatedDocument = document;
        };
    }
]});