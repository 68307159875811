'use strict';

angular.module('shared.components.sbDateTimePicker',
    [
        'ui.bootstrap',
        'shared.services.cachedLookupService'
    ]
)
    .component('sbDateTimePicker',
        {
            bindings: {
                // moment object
                modelDateTime: '=',
                required: '<',
                // only allows values greater than or equal to this value
                minDateTime: '<',
                // only allows values less than or equal to this value
                maxDateTime: '<',
                // increment between values in the minutes selection.
                minuteStep: '<',
                readonly: '<',
                disabled: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-date-time-picker.template.html',
            controller: [
                'cachedLookupService',
                function (cachedLookupService) {

                    var i = 0;
                    this.minutes = [];
                    this.hours = [];
                    for (i = 0; i < 24; i++) {
                        // populate hours, padding with leading zero.
                        this.hours.push({ id: i, label: ('0' + i).slice(-2) })
                    }

                    this.previousInput = null;

                    this.format = '';

                    this.modelDate = '';
                    this.modelHour = null;
                    this.modelMinute = null;

                    this.minHour = 0;
                    this.maxHour = 23;
                    this.minMinute = -1;
                    this.maxMinute = 60;

                    cachedLookupService
                        .getSystemSetting('Organisation_Date_DefaultPickerFormatting')
                        .then(function (value) {
                            this.format = value;
                        }.bind(this));

                    this.open = function () {
                        this.opened = true;
                    }.bind(this);

                    this.dateOptions = {
                        formatYear: 'yy',
                        startingDay: 1
                    };

                    this.modelOptions = {
                        timezone: 'utc'
                    };

                    this.hourIsDisabled = function (hour) {
                        return hour > -1 && (hour < this.minHour || hour > this.maxHour);
                    }

                    this.minuteIsDisabled = function (minute) {
                        return minute > -1 && (minute < this.minMinute || minute > this.maxMinute);
                    }

                    this.$onInit = function () {
                        if (typeof this.minuteStep === 'undefined') {
                            // Default minute step to single increment.
                            this.minuteStep = 1;
                        }

                        if (typeof this.required === 'undefined') {
                            // Required by default.
                            this.required = true;
                        }

                        if (typeof this.modelDateTime === 'undefined') {
                            // Default to unselected, else now.
                            this.modelDateTime = this.required
                                ? moment.utc()
                                : null;
                        }

                        for (i = 0; i < 60; i += this.minuteStep) {
                            // populate minutes, padding with leading zero.
                            this.minutes.push({ id: i, label: ('0' + i).slice(-2) })
                        }

                        if (!this.required) {
                            this.hours.unshift({ id: -1, label: ('  ') });
                            this.minutes.unshift({ id: -1, label: ('  ') });
                        }
                    };

                    // Fired on digest cycle.
                    this.$doCheck = function () {

                        this.timeSelectionDisabled = !this.modelDate || this.modelDate == '';

                        if (this.previousInput === null && this.modelDateTime === null) {
                            if (this.modelHour === null || this.modelMinute === null) {
                                this.modelDate = '';
                                this.modelHour = { id: -1 };
                                this.modelMinute = { id: -1 };
                                this.handleChange();
                            }

                            return;
                        }

                        if (this.modelDateTime !== null && !moment.isMoment(this.modelDateTime)) {
                            // not a valid moment object
                            return;
                        }

                        if ((this.previousInput !== null && this.modelDateTime === null) ||
                            (this.previousInput === null && this.modelDateTime !== null) ||
                            (!this.previousInput.isSame(this.modelDateTime))) {
                            // External change to model value.
                            if (this.modelDateTime === null) {
                                this.modelDate = null;
                                this.modelHour = { id: -1 };
                                this.modelMinute = { id: -1 };
                            }
                            else {
                                this.modelDate = this.modelDateTime.toDate();
                                this.modelHour = { id: this.modelDateTime.format('HH') };
                                this.modelMinute = { id: this.modelDateTime.format('mm') };
                            }

                            this.previousInput = this.modelDateTime;
                            this.handleChange();
                        }
                    };

                    // Fired on any change to date or time selection.
                    this.handleChange = function () {
                        if (!this.modelHour && !this.modelMinute) {
                            // not ready yet
                            return;
                        }

                        // Update overall date time.
                        if (this.modelDate === null || this.modelHour.id === -1 || this.modelMinute.id === -1) {
                            this.modelDateTime = null;
                        }
                        else
                        {
                            var currentTime = new Date();
                            this.modelDateTime = moment
                                .utc(this.modelDate)
                                .hour(this.modelHour.id)
                                .minute(this.modelMinute.id);
                        }
                        
                        // Make time adjustments if necessary (due to min/max constraint)
                        if (typeof this.minDateTime !== 'undefined' && this.minDateTime !== null) {
                            this.checkValuesWithinMin();
                            this.updateDisabledHoursMin();
                        }

                        if (typeof this.maxDateTime !== 'undefined' && this.maxDateTime !== null) {
                            this.checkValuesWithinMax();
                            this.updateDisabledHoursMax();
                        }

                        if (this.onChange && typeof (this.onChange()) === 'function') {
                            this.onChange()(this.modelDateTime);
                        }
                    }

                    // Updates the range of disabled hours and minutes due to minimum constraint.
                    this.updateDisabledHoursMin = function () {
                        if (typeof this.modelDateTime === 'undefined') {
                            // not ready yet
                            return;
                        }

                        if (this.minDateTime !== null && this.minDateTime.isSame(this.modelDate, 'day')) {
                            // Disable hours under min hours.
                            this.minHour = this.minDateTime.hour();

                            // If hours and minimum hours are the same,
                            // disable minutes outside minimum minutes.
                            if (this.modelHour.id === this.minDateTime.hour()) {
                                this.minMinute = this.minDateTime.minute();
                            }
                            else {
                                this.minMinute = -1;
                            }
                        }
                        else {
                            this.minHour = 0;
                            this.minMinute = -1;
                        }
                    }

                    // Updates the range of disabled hours and minutes due to maximum constraint.
                    this.updateDisabledHoursMax = function () {
                        if (typeof this.modelDateTime === 'undefined') {
                            // not ready yet
                            return;
                        }

                        if (this.maxDateTime !== null && this.maxDateTime.isSame(this.modelDate, 'day')) {
                            // Disable hours over max hours.
                            this.maxHour = this.maxDateTime.hour();

                            // If hours and maximum hours are the same,
                            // disable minutes outside maximum minutes.
                            if (this.modelHour.id === this.maxDateTime.hour()) {
                                this.maxMinute = this.maxDateTime.minute();
                            }
                            else {
                                this.maxMinute = 60;
                            }
                        }
                        else {
                            this.maxHour = 23;
                            this.maxMinute = 60;
                        }
                    }

                    this.checkValuesWithinMin = function () {
                        if (this.modelDateTime) {
                            var dirty = false;

                            // Minimum may exceed current value, in which case we set our value to minimum.
                            if (this.modelDateTime.isBefore(this.minDateTime, 'day')) {
                                this.modelDate = this.minDateTime.toDate();
                                dirty = true;
                            }

                            if (this.modelDateTime.isSameOrBefore(this.minDateTime, 'day')) {
                                if (this.modelDateTime.hour() < this.minDateTime.hour()) {
                                    this.modelHour = { id: this.minDateTime.hour() };
                                    dirty = true;
                                }

                                if (this.modelDateTime.hour() <= this.minDateTime.hour() &&
                                    this.modelDateTime.minute() < this.minDateTime.minute()) {
                                    this.modelMinute = { id: this.minDateTime.minute() };
                                    dirty = true;
                                }
                            }

                            if (dirty) {
                                this.modelDateTime = moment.utc(this.modelDate).hour(this.modelHour.id).minute(this.modelMinute.id);
                            }
                        }
                    }

                    this.checkValuesWithinMax = function () {
                        if (this.modelDateTime) {
                            var dirty = false;

                            // Maximum may be under current value, in which case we set our value to maximum.
                            if (this.modelDateTime.isAfter(this.maxDateTime, 'day')) {
                                this.modelDate = this.maxDateTime.toDate();
                                dirty = true;
                            }

                            if (this.modelDateTime.isSameOrAfter(this.maxDateTime, 'day')) {
                                if (this.modelDateTime.hour() > this.maxDateTime.hour()) {
                                    this.modelHour = { id: this.maxDateTime.hour() };
                                    dirty = true;
                                }

                                if (this.modelDateTime.hour() >= this.maxDateTime.hour() &&
                                    this.modelDateTime.minute() > this.maxDateTime.minute()) {
                                    this.modelMinute = { id: this.maxDateTime.minute() };
                                    dirty = true;
                                }
                            }

                            if (dirty) {
                                this.modelDateTime = moment.utc(this.modelDate).hour(this.modelHour.id).minute(this.modelMinute.id);
                            }
                        }
                    }

                    // Fired on any changes to one-way bindings.
                    this.$onChanges = function (changes) {
                        if (changes.minDateTime && typeof changes.minDateTime.currentValue !== 'undefined') {
                            // date picker requires min date as js date
                            this.dateOptions.minDate = this.minDateTime === null
                                ? null
                                : this.minDateTime.toDate();
                            this.checkValuesWithinMin();
                            this.updateDisabledHoursMin();
                        }

                        if (changes.maxDateTime && typeof changes.maxDateTime.currentValue !== 'undefined') {
                            // date picker requires max date as js date
                            this.dateOptions.maxDate = this.maxDateTime === null
                                ? null
                                : this.maxDateTime.toDate();
                            this.checkValuesWithinMax();
                            this.updateDisabledHoursMax();
                        }

                        if (changes.disabled) {
                            this.dateOptions.dateDisabled = this.disabled;
                        }
                    };

                    this.opened = false;
                }
            ]

        });
