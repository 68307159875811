angular.module('shared.components.sbWizard', [
    'shared.components.sbIcon',
    'shared.components.sbOnOffSwitch',
    'shared.components.sbSelect',
    'shared.components.sbTelephoneInput',
    'shared.constants',
    'shared.services.blobImageService',
    'shared.services.stringService',
    'shared.services.twitterService',
    'colours.components.colourPicker'])
    .component('sbWizard',
    {
        bindings: {
            isModal: '<',
            hideChecks: '<',
            hideImages: '<',
            formTitle: '<',
            tabs: '<',
            parentData: '<',
            parentDataId: '<',
            onClose: '&',
            onSave: '&',
            onChangeData: '&',
            onButtonClick: '&',
            isSaving: '<',
            isLoading: '<'
        },
        templateUrl: '/Scripts/app/shared/components/sb-wizard.template.html',
        controller: class SbWizardCtrl {

            // Dependencies
            $timeout: any;
            $filter: any;
            $translate: any;
            $window; any;
            formElements: any;
            blobImageService: any;
            stringService: any;
            twitterService: any;

            // Bindings
            isModal: boolean;
            hideChecks: boolean;
            hideImages: boolean;
            title: string;
            tabs: Array<any> = [];
            parentData: any;
            onClose: any;
            onSave: any;
            onChangeData: any;
            isSaving: boolean;

            // Variables
            data: any = {};
            ui: any;
            changes: any;
            activeTabId: number = 1;
            telCodes: any;
            nestSeparator: string = '-';
            filteredTabs: Array<any>;
            tinymceOptions: any;
            expandedItemId: string = '';
            changesForParent: number = 0;

            static $inject = ['$timeout', '$filter', '$translate', '$window', 'formElements', 'blobImageService', 'stringService', 'twitterService'];

            constructor ($timeout, $filter, $translate, $window, formElements, blobImageService, stringService, twitterService) {
                this.$timeout = $timeout;
                this.$filter = $filter;
                this.$translate = $translate;
                this.$window = $window;
                this.formElements = formElements;
                this.blobImageService = blobImageService;
                this.stringService = stringService;
                this.twitterService = twitterService;
                this.changes = {};
            }

            $onInit() {
                this.$translate.onReady().then(()=> {

                    if (this.tabs) {
                        this.filterTabs();
                        this.tabs?.forEach((tab, index) => {
                            tab.id = index + 1;
                            tab.svg = this.blobImageService.getUndrawSvg(tab.image);
                        });
                        this.setTab(1);
                        const imageTabs = this.filteredTabs.filter(tab => {
                            return tab.image !== undefined;
                        })
                        if (imageTabs.length == 0) {
                            this.hideImages = true;
                        }
                        this.prepareFields();
                    }
                    if (!this.data) {
                        this.data = {};
                    }
                    this.ui = {
                        showOptions: {}
                    };
                });
            }

            $onChanges(changes) {
                const loadingFinished = changes?.isLoading?.previousValue === true && changes?.isLoading?.currentValue === false;
                if (changes?.parentData?.currentValue && changes?.parentData?.currentValue) {
                    this.data = {...this.parentData};
                    this.tabs.forEach(tab => {
                        tab.fields.forEach(field => {
                            if (field.id) {
                                // TinyMce needs to be reloaded if model is updated, so reload when finished loading
                                if (field.type === this.formElements.Content  && loadingFinished) {
                                    field.type = null;
                                    this.$timeout(() => {
                                        field.type = this.formElements.Content;
                                    })
                                }
                                const nestArray = field.id.split(this.nestSeparator);
                                if (this.data[nestArray[0]] && nestArray.length === 2) {
                                    // Handle two levels for now
                                    this.data[field.id] = this.data[nestArray[0]][nestArray[1]];
                                }
                                // Add content to elements for data table
                                if (this.data[field.id] && field.type === this.formElements.Data) {
                                    field.elements = [];
                                    Object.entries(this.data[field.id]).forEach(([key, value]) => {
                                        field.elements.push({
                                            class: 'semi-bold',
                                            html: (key || '').toString()
                                        });
                                        field.elements.push({
                                            html: (value || '').toString()
                                        });
                                    });
                                }
                            }
                        })
                    });
                }
                if (changes?.tabs?.currentValue) {
                    this.$timeout(() => {
                        this.filterTabs();
                    });
                }
                if (changes?.tabs?.currentValue || changes?.parentData?.currentValue) {
                    this.$timeout(() => {
                        this.prepareFields();
                    });

                }
            }

            filterTabs() {
                // Only show non-hidden tabs
                this.filteredTabs = this.tabs.filter(tab => {
                    return !tab.isHidden;
                });
            }

            prepareFields()
            {
                const oldData = { ...this.data };
                let changes = 0;
                this.tabs.forEach(tab =>
                {
                    tab.fields.forEach(field =>
                    {
                        if (field.default !== undefined && this.data[field.id] === undefined)
                        {
                            this.data[field.id] = field.default
                        }

                        if (!field.options)
                        {
                            return;
                        }

                        // Set select option properties for any not previously prepared (all fields onInit and some fields onChanges)
                        if (Array.isArray(field.options) && field.options.length > 0)
                        {
                            const firstOption = field.options[0];
                            if (!field.idProp)
                            {
                                const idPropSuggestions = ['id', 'value'];
                                // TODO Find out if we really want to reverse idPropSuggestions or just loop arround it in reverse?
                                idPropSuggestions.reverse().forEach(suggestion =>
                                {
                                    if (firstOption[suggestion])
                                    {
                                        field.idProp = suggestion;
                                    }
                                });
                            }
                            if (!field.labelProp)
                            {
                                const labelPropSuggestions = ['label', 'name', 'text'];
                                // TODO See other TODO about reversing above
                                labelPropSuggestions.reverse().forEach(suggestion =>
                                {
                                    if (firstOption[suggestion])
                                    {
                                        field.labelProp = suggestion;
                                    }
                                });
                            }
                            // Set checkbox list UI model if not yet defined
                            this.ui = this.ui || {};
                            if (field.type === this.formElements.CheckboxList && !this.ui[field.id] && this.data[field.id])
                            {
                                this.ui[field.id] = {};
                                field.options.forEach(option =>
                                {
                                    this.ui[field.id][option.id] === this.data[field.id].includes(option.id);
                                })
                            }
                            // Append blank option if no existing/default or blank value
                            if (field.type === this.formElements.Select)
                            {
                                const blankValue = '';
                                const existingBlankOption = field.options.filter(option =>
                                {
                                    return option[field.idProp] == blankValue || option.id == 0;
                                })[0];

                                if (!existingBlankOption)
                                {
                                    const newBlankOption = {};
                                    newBlankOption[field.idProp] = blankValue;
                                    newBlankOption[field.labelProp] = field.placeholder || this.$filter('translate')('SB_Select');
                                    field.options.splice(0, 0, newBlankOption);
                                }

                                // If no existing value, set to default if found in options, else blank option
                                if (!this.data[field.id] && this.data[field.id] !== false)
                                {
                                    setTimeout(() => {
                                        const defaultOption = field.options.filter(option =>
                                        {
                                            return option[field.idProp] == field.default;
                                        })[0];
                                        if (defaultOption)
                                        {
                                            this.data[field.id] = field.default;
                                        }
                                        else
                                        {
                                            this.data[field.id] = blankValue;
                                        }
                                    })
                                }
                            }

                            field.optionsReady = field.options !== undefined;
                        }

                        if (Array.isArray(field.options) &&
                            field.options.length == 0 &&
                            field.type === this.formElements.Select)
                        {
                            const blankValue = '';
                            field.options.push({ id: 0, label: this.$filter('translate')('SB_Shared_SelectOption_NoOptionsAvailable')});

                            this.data[field.id] = blankValue;

                            field.optionsReady = true;
                        }

                        if (oldData[field.id] !== this.data[field.id] && this.data[field.id] !== null && this.data[field.id] !== 0)
                        {
                            changes += 1;
                        }
                    });

                    if (changes)
                    {
                        // Update parent if anything has changed
                        this.updateParentData();
                    }
                });
            }

            setTab(tabId: number) {
                this.activeTabId = tabId;
                const activeTab = this.filteredTabs[tabId-1];
                if (activeTab) {
                    activeTab.isViewed = true;
                }
            }

            next() {
                this.activeTabId = this.activeTabId + 1;
            }

            back() {
                this.activeTabId = this.activeTabId - 1;
            }

            save() {
                this.onSave({data: this.data});
            }

            backDisabled() {
                // back disabled if no previous tabs, or in expanded view of form element
                return this.activeTabId == 1 || this.expandedItemId;
            }

            nextDisabled() {
                // next disabled if no more tabs, or in expanded view of form element
                return this.activeTabId == this.filteredTabs.length || this.expandedItemId;
            }

            saveDisabled() {
                // save disabled if saving, missing fields, or in expanded view of form element
                return this.isSaving || this.anyMissingFields() || this.expandedItemId;
            }

            unmatchedRequirement(req) {
                // Used in two functions below
                return (req.values && (this.data[req.property] === undefined || !req.values.includes(this.data[req.property]))) ||
                (req.property && !req.values && !this.data[req.property])
            }

            showField(field) {
                if (field.isHidden)
                {
                    return false;
                }

                // Show a field if requirements to show it are matched
                const expandedMatched = !this.expandedItemId || this.expandedItemId === field.id;
                if (!field.displayIf) {
                    return expandedMatched;
                }
                const unmatched = field.displayIf.filter(req => {
                    return this.unmatchedRequirement(req);
                }).length;
                return unmatched === 0 && expandedMatched;
            }

            enableField(field) {
                // Enable a field if requirements to show it are matched
                if (!field.enableIf) {
                    return true;
                }
                const unmatched = field.enableIf.filter(req => {
                    return this.unmatchedRequirement(req);
                }).length;
                return unmatched === 0;
            };

            selectedTel(value) {
                if (value) {
                    return this.telCodes.filter(item => {
                        return item.code === this.data[value + '_code'];
                    })[0];
                }
            };

            selectTel(value, fieldId) {
                this.data[fieldId + '_code'] = value;
                this.toggleDisplay(fieldId);
                this.updateParentData();
            };

            selectFake(value, fieldId) {
                this.data[fieldId] = value;
                this.toggleDisplay(fieldId);
                this.updateParentData();
            };

            toggleDisplay(fieldId) {
                this.ui.showOptions[fieldId] = !this.ui.showOptions[fieldId];
            };

            change(fieldId: string, value: any) {
            // Triggered when checking a particular input
                // If need to update value, often this will be done automatically with ng-model
                if (value) {
                    this.data[fieldId] = value;
                }
                this.$timeout(() => {
                    // Set field as changes
                    this.changes[fieldId] = true;
                    this.filteredTabs.forEach(tab => {
                        tab.fields.forEach(tabField => {
                            if (tabField.id) {
                                // If others fields which are controlled by this field have not been changed, overwrite their values(s)
                                if (!this.changes[tabField.id]) {
                                    if (tabField.valueController === fieldId) {
                                        this.data[tabField.id] = this.data[fieldId];
                                    }
                                    if (tabField.relatedValueController === fieldId && this.data[fieldId] !== '' && this.data[fieldId] !== 0) {
                                        this.data[tabField.id + '_relatedProperty'] = this.data[fieldId];
                                    }
                                }
                                // Handle nested properties
                                const nestArray = tabField.id.split(this.nestSeparator);
                                if (nestArray.length === 2) {
                                    // Handle two levels for now
                                    const nest0 = nestArray[0];
                                    if (!this.data[nest0]) {
                                        this.data[nest0] = {};
                                    }
                                    this.data = {...this.data, [nest0]: {...this.data[nest0], [nestArray[1]] : this.data[tabField.id]}}
                                }
                            }
                        });
                    });
                    this.updateParentData();
                });
            };

            toggleCheckbox(fieldId, optionId) {
                // Handle data array when checking/unchecking corresponding checkbox
                this.data[fieldId] = this.data[fieldId] || [];
                const index = this.data[fieldId].indexOf(optionId);
                if (index > -1) {
                    this.data[fieldId].splice(index, 1);
                }
                else {
                    this.data[fieldId].push(optionId);
                }
                this.change(fieldId, null);
            }

            isTabDone(tab) {
                /* Check if tabs with required fields are complete and whether optional
                tabs have been viewed by the user */
                
                return tab.isViewed && !tab.fields.some(field => this.isMissing(field));
            };

            anyMissingFields() {
                /* Check all tabs for missing fields to add validation of fake selects.
                This is different from isTabDone() because you shouldn't need to click on
                optional tabs to submit the form */
                if (!this.filteredTabs) {
                    return false;
                }
                return this.filteredTabs.some(tab => {
                    return tab.fields.some(field => {
                        return this.isMissing(field);
                    });
                });
            }

            isMissing(field) {
                // Check if field is required and missing
                return (!this.data[field.id] || (Array.isArray(this.data[field.id]) && this.data[field.id].length === 0))
                    && this.isFieldRequired(field);
            }

            isFieldRequired(field: any): boolean {
                const requiredIf = field.requiredIf;

                if (requiredIf && Array.isArray(requiredIf)) {
                    for (let i = 0; i < requiredIf.length; i++) {
                        const item = requiredIf[i];

                        if (Array.isArray(item.values)) {
                            let unmatched = false;
                            for (let j = 0; j < requiredIf.length; j++) {
                                if (this.unmatchedRequirement(requiredIf[j])) {
                                    unmatched = true;
                                    break;
                                }
                            }

                            if (!unmatched) {
                                return true;
                            }
                        }
                        // Check if related field exists and value is matched (if one is set)
                        else if (this.data[item.property] && (!item.value || this.data[item.property] == item.value)) {
                            return true;
                        }
                    }
                }

                return (field.isRequired || (field.isRequiredAllowZero && this.data[field.id] == null)) && this.showField(field);
            }

            updateParentData() {
                // Remove flattened data when passing back up
                const unFlattenedData = { ...this.data};
                this.filteredTabs?.forEach(tab => {
                    tab.fields.forEach(field => {
                        if (field.id) {
                            const nestArray = field.id.split(this.nestSeparator);
                            if (nestArray.length === 2) {
                                delete unFlattenedData[field.id];
                            }
                        }
                    })
                });
                // Only update parent once (eg in case of one change triggering another)
                this.changesForParent += 1;
                this.$timeout(() => {
                    if (this.changesForParent) {
                        this.onChangeData({data: unFlattenedData});
                        this.changesForParent = 0;
                    }
                });
            }

            checkUrl(url: string): boolean {
                return this.stringService.validateUrl(url);
            }

            openUrl(url: string) {
                this.$window.open(url);
            }

            openTwitterHandle(handle: string) {
                this.$window.open(this.twitterService.getTwitterUrl(handle));
            }

            getDataStyle(field) {
                // Get style of data element
                let columnsString = '';
                const columnCount = field.dataCols || 2;
                for (let i = 0; i < columnCount; i++) {
                    columnsString += (i > 0 ? ' ' : '') + '1fr';
                }
                return {
                    'grid-template-columns': columnsString
                }
            }

            getButtonClass(fieldId, option) {
                return `btn btn-${(option.buttonClass || 'info') +
                (option.customClass ? (' ' + option.customClass) : '') +
                (this.data[fieldId] === option.value ? ' active' : '')}`;
            }

        }
});
