angular.module('balance.services.balanceService', [])
    .service('balanceService', class BalanceService {
        $http: any;
        $q: any;
        $cacheFactory: any;
        urlBase: string;
        repoCache: any;

        static $inject = ['$http', '$q', '$cacheFactory'];

        constructor ($http, $q, $cacheFactory) {
            this.$http = $http;
            this.$q = $q;
            this.$cacheFactory = $cacheFactory;
            this.urlBase = '/webapi/WebBalance/';
            this.repoCache = $cacheFactory('balanceServiceCache');
        }

            clearCache = function () {
                this.repoCache.removeAll();
            };

            getAllBalances = function () {

                return this.$http.get(this.urlBase + 'GetAllBalances')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            getBalanceForAccountType = function (accountType) {

                return this.$http.get(this.urlBase + 'GetBalanceForAccount', { params: { accountType: accountType } })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            getTransactions = function (accountTypeId, includeSource, from, to) {

                const params = {
                    accountType: accountTypeId,
                    includeSource: includeSource,
                    from: '',
                    to: ''
                };

                if (from)
                    params.from = from;

                if (to)
                    params.to = to;

                return this.$http.get(this.urlBase + 'GetTransactionsForAccount',
                    {
                        params: params
                    })
                    .then(function (data) {
                        return data.data;
                    });
            };

        }
    );
