import { Injectable } from '@angular/core';
import { EventDetails } from '@sb-events/models/event-detail';
import { DateFormats } from '@sb-shared/globals/date-formats';
import { apis, controllerTypes, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { BlobStoragePhotoService } from '@sb-shared/services/blob-storage-photo.service';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { UiClasses } from '@sb-shared/types/ui-classes';
import { isBefore, isToday, startOfTomorrow } from 'date-fns';
import { BehaviorSubject, Observable, of, timer, map, share, switchMap } from 'rxjs';
import { EventView } from '../enums/event-view';
import { DiaryEvent } from '../models/diary-event';
import { EventUpdateInformation } from '../models/event-update-information';
import { GetEventDetailsForPerson, GetEventsForPersonRequest } from '../models/http/get-events-for-person';
import { TransportEventStatus } from '../models/transport-event-status';
import { TransportEventStatusRequest } from '../models/transport-event-status-request';
import { TransportUpdateRefreshSettings } from '../models/transport-update-refresh-settings';
import { EventTypeId } from '@sb-events/enums/event-types';
import { CalendarEventTypes } from '@sb-events/models/calendar-event-type';

@Injectable({
  providedIn: 'root'
})
export class DiaryService {
  selectedEventDetail$: Observable<EventDetails>;
  isEventSelected$: Observable<boolean>;
  onEventDetailUpdatedSubj$: BehaviorSubject<{ eventId: number }> = new BehaviorSubject(null);
  config: HttpRequestSettings = {
    api: apis.Web
  };
  coreConfig: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.User
  };
  coreDiaryBaseUrl = 'Diary';
  diaryUrlBase = 'WebDiary/';
  calendarEventUrlBase = 'WebCalendarEventView/';
  private selectedEventDetailSubj$: BehaviorSubject<EventDetails> = new BehaviorSubject(null);

  constructor(
    private http: HttpWebApiService,
    private dateTime: DateTimeService,
    private blobPhotoStorage: BlobStoragePhotoService
  ) {
    this.selectedEventDetail$ = this.selectedEventDetailSubj$.asObservable();
    this.isEventSelected$ = this.selectedEventDetail$.pipe(
      map(event => !!event),
      share()
    );
  }

  setSelectedEventDetail(value: EventDetails) {
    this.selectedEventDetailSubj$.next(value);
  }

  propagateEventDetailUpdated(eventId: number) {
    this.onEventDetailUpdatedSubj$.next({ eventId });
  }

  updateEventIsCancelled({ ...args }: { isCancelled: boolean }) {
    this.selectedEventDetailSubj$.next({ ...this.selectedEventDetailSubj$.value, ...args });
  }

  // TODO: Does this need caching?
  getEventsForPerson(
    data: GetEventsForPersonRequest,
    includeInStatusRequestFilter?: (diaryEvents: DiaryEvent[]) => DiaryEvent[]
  ): Observable<DiaryEvent[]> {
    return this.http
      .get(this.diaryUrlBase + 'GetAll', {
        ...this.config,
        params: data
      })
      .pipe(
        switchMap(events => {
          return this.blobPhotoStorage
            .addStudentPhotoUrlsToArray(events)
            .pipe(map(eventsWithPhotos => this.dateTime.sortByDate<DiaryEvent>(eventsWithPhotos, 'eventDate')));
        }),
        switchMap(events => {
          const eventsToGetRouteStatusFor: DiaryEvent[] = this.getEventsToGetRouteStatusFor(
            includeInStatusRequestFilter ? includeInStatusRequestFilter(events) : events
          );

          if (eventsToGetRouteStatusFor.length) {
            return this.getRepeatTransportEventStatusRequest(
              refreshSettings => refreshSettings.diaryEventListRefreshIntervalSeconds || 180,
              eventsToGetRouteStatusFor.map(event => ({ calendarEventId: event.id, studentPersonId: event.personId }))
            ).pipe(
              map(transportEventStatuses => this.mapTransportEventStatusesToDiaryEvents(events, transportEventStatuses))
            );
          }

          return of(events);
        })
      );
  }

  private getRepeatTransportEventStatusRequest(
    getIntervalSecondsFunc: (refreshSettings: TransportUpdateRefreshSettings) => number,
    requests: TransportEventStatusRequest[]
  ): Observable<TransportEventStatus[]> {
    return this.getTransportStatusUpdatesRefreshSettings().pipe(
      switchMap(refreshSettings => {
        const intervalMs = getIntervalSecondsFunc(refreshSettings) * 1000;

        return timer(0, intervalMs).pipe(switchMap(() => this.getTransportEventStatus(requests)));
      })
    );
  }

  private getEventsToGetRouteStatusFor(events: DiaryEvent[]): DiaryEvent[] {
    return events.filter(
      event =>
        event.eventType === 'Transport' &&
        event.showETAWarnings &&
        event.routeStopId &&
        this.isToday(event.eventDateUtc)
    );
  }

  private isToday(date: string | Date): boolean {
    if (typeof date === 'string' && !date.endsWith('Z')) {
      date += 'Z';
    }
    const eventDate = new Date(date);
    const now = new Date();

    return eventDate.toDateString() === now.toDateString();
  }

  private mapTransportEventStatusesToDiaryEvents(
    events: DiaryEvent[],
    transportEventStatuses: TransportEventStatus[]
  ): DiaryEvent[] {
    for (const transportEventStatus of transportEventStatuses) {
      const event = events.find(
        event => event.id === transportEventStatus.eventId && event.personId === transportEventStatus.studentPersonId
      );

      if (event) {
        const studentStopStatus = transportEventStatus.stops?.find(stop => stop.routeStopId === event.routeStopId);

        if (studentStopStatus?.isBadgeVisible) {
          event.badgeClass = transportEventStatus.badge?.class as UiClasses;
          event.badgeMessage = transportEventStatus.badge?.label;
        }
      }
    }

    return events;
  }

  /**
   * Get necessary information to update the event item in the event list when the event detail is updated
   * @param eventId the event id
   * @param personId the client person id
   * @returns event information
   */
  getInfoForSingleEvent(eventId: number): Observable<EventUpdateInformation> {
    return this.http.get(`${this.coreDiaryBaseUrl}/updatedEventInformation`, {
      ...this.coreConfig,
      params: { eventId: eventId }
    });
  }

  getEventDetailForPerson(data: GetEventDetailsForPerson): Observable<EventDetails> {
    return this.http
      .get(this.calendarEventUrlBase + 'GetEventDetail', {
        ...this.config,
        params: data
      })
      .pipe(
        switchMap((eventDetails: EventDetails) => {
          if (this.shouldGetEventDetailTransportUpdates(eventDetails)) {
            return this.getRepeatTransportEventStatusRequest(
              refreshSettings => refreshSettings.diaryEventDetailRefreshIntervalSeconds || 180,
              [{ calendarEventId: eventDetails.calendarEventId, studentPersonId: eventDetails.personId }]
            ).pipe(
              map(transportEventStatuses =>
                this.mapTransportEventStatusesToEventDetail(eventDetails, transportEventStatuses)
              )
            );
          }

          return of(eventDetails);
        })
      );
  }

  shouldGetEventDetailTransportUpdates(eventDetails: EventDetails): boolean {
    return (
      eventDetails.eventType === 'Transport' &&
      eventDetails.showETAWarnings &&
      eventDetails.transportTimetableRouteTimeline &&
      eventDetails.transportTimetableRouteTimeline.stops.length > 0 &&
      this.isToday(eventDetails.fromUtc)
    );
  }

  mapTransportEventStatusesToEventDetail(
    eventDetails: EventDetails,
    transportEventStatuses: TransportEventStatus[]
  ): EventDetails {
    if (!transportEventStatuses.length) {
      return eventDetails;
    }

    const transportEventStatus = transportEventStatuses[0];
    const studentStop = eventDetails.transportTimetableRouteTimeline?.stops?.find(stop => stop.isSelected);
    const studentStopStatus = transportEventStatus.stops?.find(
      stop => stop.isSelected && stop.routeStopId === studentStop.routeStopId
    );

    if (studentStop && studentStopStatus) {
      studentStop.isLate = studentStopStatus.isLate;
      studentStop.isBadgeVisible = studentStopStatus.isBadgeVisible;
      studentStop.numberOfMinutesLate = studentStopStatus.numberOfMinutesLate;
      studentStop.isMinutesLateMessageVisible = studentStopStatus.isMinutesLateMessageVisible;
      studentStop.methodName = transportEventStatus.methodName;
      studentStop.badgeClass = transportEventStatus.badge.class as UiClasses;
      studentStop.badgeMessage = transportEventStatus.badge.label;
    }

    return eventDetails;
  }

  getInvites(
    personId: number,
    includeDeclined: boolean = false,
    includeExpired: boolean = false,
    includeFull: boolean = false
  ) {
    return this.http.get(this.diaryUrlBase + 'GetInvites', {
      ...this.config,
      params: {
        personId: personId,
        includeDeclined: includeDeclined,
        includeExpired: includeExpired,
        includeFull: includeFull
      }
    });
  }

  getDayLabel(event: DiaryEvent): string {
    return isToday(event.eventDateUtc) ? 'SB_Today' : this.dateTime.formatDate(event.eventDate, 'EEEE');
  }

  getTimesLabel(event: DiaryEvent): string {
    return this.getTimesLabelFromDates(event.eventDate, event.eventEndDate);
  }

  getTimesLabelFromDates(startDate: Date, endDate: Date) {
    return `${this.formatDate(startDate)}${endDate ? ` &#x2013; ${this.formatDate(endDate)}` : ''}`;
  }

  private formatDate(date: Date) {
    return this.dateTime.formatDate(date, DateFormats.Time);
  }

  whichViewShouldEventDefaultTo(event: DiaryEvent) {
    const isBeforeTomorrow = isBefore(new Date(event.eventDate), startOfTomorrow());

    return isBeforeTomorrow ? EventView.register : EventView.info;
  }

  getICalLink(personId: number) {
    return this.http.post(this.diaryUrlBase + 'GetICalLink', personId, { ...this.config, showSuccessToast: false });
  }

  getCalendarEventTypes() {
    return this.http.get(`${this.coreDiaryBaseUrl}/CalendarEventTypes`, this.coreConfig);
  }

  dismissEvent(eventId: number) {
    return this.http.put(`${this.coreDiaryBaseUrl}/DismissEvent?eventId=` + eventId, {}, this.coreConfig);
  }

  getCompetitionTypes(): Observable<CalendarEventTypes> {
    return this.getCalendarEventTypes().pipe(
      map(types => types.find(type => type.id === EventTypeId.Sports).calendarEventTypes)
    );
  }

  getTransportStatusUpdatesRefreshSettings(): Observable<TransportUpdateRefreshSettings> {
    return this.http.get('Module/TransportStatusUpdatesRefreshSettings', this.coreConfig);
  }

  getTransportEventStatus(
    transportEventStatusRequests: TransportEventStatusRequest[]
  ): Observable<TransportEventStatus[]> {
    return this.http.post('TransportEventStatus', transportEventStatusRequests, {
      ...this.coreConfig,
      showSuccessToast: false
    });
  }
}
