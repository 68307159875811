angular.module('payments.components.paymentResultDetail', [
    'payments.services.paymentsService',
]).component('paymentResultDetail',
    {
        bindings: {
            merchantReference: '<',
            items: '<'
        },
        templateUrl: '/Scripts/app/payments/components/payment-result-detail.template.html',
        controllerAs: 'ctrl',
        controller: class paymentResultDetailCtrl {

            // Dependencies
            $timeout: any;
            $paymentsService: any;
            paymentsService: any;

            // Variables
            loadFailureMessage: any;
            retriesRemaining: number;
            retryInterval: number;
            retryFailed: boolean;
            charges: any;
            merchantReference: any;
            items: any;

            static $inject = ['$timeout', 'paymentsService'];

            constructor($timeout, paymentsService) {
                this.$timeout = $timeout;
                this.paymentsService = paymentsService;
            }

            $onInit() {
                this.loadFailureMessage = null;
                this.retriesRemaining = 3;
                this.retryInterval = 10000;
                this.retryFailed = false;
                this.getReceipt();
            }

            retryGetReceipt() {
                if (this.retriesRemaining == 0) {
                    this.retryFailed = true;
                    return;
                }

                this.retriesRemaining--;
                this.$timeout(() => {
                    this.getReceipt();
                }, this.retryInterval);
            };

            getReceipt() {
                this.charges = null;
                if (this.merchantReference) {
                    this.paymentsService.getPaymentReceiptByMerchantReference(this.merchantReference)
                        .then(data => {
                            if (data == null) {
                                this.charges = [];
                                this.retryGetReceipt();
                            }
                            else {
                                this.charges = data;
                            }
                        })
                        .catch(err => {
                            console.log('Error getting payment receipt:', err);
                            this.loadFailureMessage = 'Could not get payment details';
                        });
                }

                if (this.items) {
                    this.paymentsService.getPaymentReceiptByTransactions(this.items)
                        .then(data => {
                            if (data == null) {
                                this.charges = [];
                                this.retryGetReceipt();
                            }
                            else {
                                this.charges = data;
                            }
                        })
                        .catch(err => {
                            console.log('Error getting payment receipt:', err);
                            this.loadFailureMessage = 'Could not get payment details';
                        });
                }
            }
        }
    });
