angular.module('transport.configuration.transportFeatures',
    [
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
        'transport.services.configurationFeaturesService',
    ])
    .component('transportFeatures',
        {
            bindings: {},
            templateUrl: '/Scripts/app/transport/configuration/features/transport-configuration-features.template.html',
            controller: class TransportConfigurationFeaturesCtrl {

                // Dependencies
                $timeout: any
                simpleAlertService: any;
                configurationFeaturesService: any;

                // Variables

                isChangeRequestConfigurationSubmitted: boolean = false;
                isFeaturesConfigurationSubmitted: boolean = false;
                allowParentChangeRequests: boolean = false;
                changeRequestTimeLimitMinutes: number = 60;
                featuresConfiguration: any;
                isLoading: boolean = true;
                markerPins: any = [];

                static $inject = ['$timeout', 'simpleAlertService', 'configurationFeaturesService'];

                constructor($timeout, simpleAlertService, configurationFeaturesService)
                {
                    this.$timeout = $timeout;
                    this.simpleAlertService = simpleAlertService;
                    this.configurationFeaturesService = configurationFeaturesService;
                }

                resetSubmitFeaturesButton() {
                    this.$timeout(() =>
                    {
                        this.isFeaturesConfigurationSubmitted = false;
                    }, 200);
                };

                $onInit() {
                    this.getFeaturesConfiguration();
                };

                // Features object will likely later include various properties (including change requests once moved).
                // Can then all be loaded and saved in one go.
                getFeaturesConfiguration = () => {
                    this.isLoading = true;
                    this.configurationFeaturesService.getFeaturesConfiguration()
                    .then((res) => {
                        this.featuresConfiguration = res.data;
                        this.updateMap();
                        this.isLoading = false;
                    });
                }

                saveFeaturesConfiguration = () => {
                    this.isLoading = true;
                    this.configurationFeaturesService.saveFeaturesConfiguration(this.featuresConfiguration)
                        .catch(() => {
                            this.getFeaturesConfiguration();
                        })
                        .finally(() => {
                            this.resetSubmitFeaturesButton();
                            this.isLoading = false;
                        })
                }

                updateMap = () => {
                    if(!this.featuresConfiguration.isGPSTrackingEnabled)
                        return;

                    if(this.featuresConfiguration.defaultLatitude && this.featuresConfiguration.defaultLongitude) {
                        this.markerPins = [{
                            lat: this.featuresConfiguration.defaultLatitude,
                            lng: this.featuresConfiguration.defaultLongitude,
                            draggable: true,
                        }];
                    }
                }

                onPinDrop = (e) => {
                    this.featuresConfiguration.defaultLatitude = Math.round(e.lat * 1000000)/1000000;
                    this.featuresConfiguration.defaultLongitude = Math.round(e.lng * 1000000)/1000000;
                    this.updateMap();
                }
            }
        });
