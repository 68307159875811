'use strict';

angular.module('events.components.consentEventSettingsEdit',
    [
        'events.services.eventsService',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
        'events.constants',
    ])
    .component('consentEventSettingsEdit', {
        templateUrl: '/Scripts/app/events/components/consent-event-settings-edit.template.html',
        bindings: {
            resolve: '<',
            modalInstance: '<'
        },
        controller: [
            '$filter',
            '$translate',
            '$uibModal',
            '$timeout',
            'eventsService',
            'simpleAlertService',
            'tinymceConfigHelper',
            'consentRequirementEnum',
            'notifyTypesEnum',
            function consentEventSettingsEditController($filter, $translate, $uibModal, $timeout, eventsService, simpleAlertService, tinymceConfigHelper,
                consentRequirementEnum, notifyTypesEnum)
            {
                this.isSubmitted = false;

                this.eventSettings =
                {
                    notifyPeopleTypes: [],
                    notifyTypeId: null,
                    consentRequiredFromPersonTypes: [],
                    consentRequirementTypeId: null,
                    consentMessage: null,
                    consentDeadline: null,
                    maximumPlaces: null,
                    fee: null,
                    currencySymbol: null,
                    isAdditionalSelectionRequired: false,
                    students: []
                };

                this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                $translate.onReady().then(function ()
                {
                    this.eventLabel = $filter('translate')('SB_Event');
                }.bind(this));

                this.$onChanges = function (changes)
                {
                    if (changes.resolve)
                    {
                        var params = this.resolve.params;
                        this.eventId = parseInt(params.eventId) || null;
                        this.invitesSent = params.invitesSent;

                        this.loadSettings(params.eventId);
                        this.loadNotifyPeopleTypes();
                        this.loadConsentRequiredFromPeopleTypes();
                    }
                }.bind(this);

                this.loadSettings = function (eventId)
                {
                    eventsService.getConsentEventInvitesData(eventId)
                        .then(function (response)
                        {
                            this.eventSettings.consentRequirementTypeId = response.consentRequirementTypeId;
                            this.eventSettings.notifyTypeId = response.notifyTypeId;
                            this.eventSettings.consentMessage = response.consentMessage;
                            this.eventSettings.consentDeadline = moment(response.consentDeadline ?? new Date());
                            this.eventSettings.maximumPlaces = response.maximumPlaces;
                            this.eventSettings.fee = response.fee;
                            this.eventSettings.currencySymbol = response.currencySymbol;
                            this.eventSettings.isAdditionalSelectionRequired = response.isAdditionalSelectionRequired;
                            this.eventSettings.students = response.students;

                            this.numberConsented = this.eventSettings.students.filter(function (student)
                            {
                                return student.hasConsented;
                            }).length;

                            this.numberRejected = this.eventSettings.students.filter(function (student)
                            {
                                return student.isConsentRejected;
                            }).length;

                            this.numberAwaitingResponse = this.eventSettings.students.filter(function (student)
                            {
                                return student.isConsentAwaited;
                            }).length;

                            this.showFeeChangeWarning = this.numberConsented > 0 || this.numberRejected > 0 || this.numberAwaitingResponse > 0;

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));
                }.bind(this);

                this.getNotifyTypeDisabled = function (notifyTypeId) {
                    if (this.eventSettings.consentRequirementTypeId === consentRequirementEnum.ParentOnly
                            && notifyTypeId == notifyTypesEnum.StudentOnly) {
                        return true;
                    } else if (this.eventSettings.consentRequirementTypeId === consentRequirementEnum.StudentOnly
                            && notifyTypeId == notifyTypesEnum.ParentOnly) {
                        return true;
                    }

                    return false;
                }

                this.getConsentTypeDisabled = function (consentTypeId) {
                    if (consentTypeId !== consentRequirementEnum.ParentOnly
                            && this.eventSettings.notifyTypeId == notifyTypesEnum.ParentOnly) {
                        return true;
                    } else if (consentTypeId !== consentRequirementEnum.StudentOnly
                            && this.eventSettings.notifyTypeId == notifyTypesEnum.StudentOnly) {
                        return true;
                    }

                    return false;
                }

                this.loadNotifyPeopleTypes = function ()
                {
                    eventsService.getNotifyAudienceTypes(true)
                        .then(function (response)
                        {
                            this.eventSettings.notifyPeopleTypes = [];

                            for (var i = 0; i < response.notifyAudienceTypes.length; i++)
                            {
                                // exclude none from notify types
                                if (response.notifyAudienceTypes[i].id !== 4) {
                                    this.eventSettings.notifyPeopleTypes.push(
                                        {
                                            label: response.notifyAudienceTypes[i].audienceType,
                                            id: response.notifyAudienceTypes[i].id
                                        });
                                }
                            }

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));
                }.bind(this);

                this.loadConsentRequiredFromPeopleTypes = function ()
                {
                    eventsService.getConsentRequiredFromPersonTypes(true)
                        .then(function (response)
                        {
                            this.eventSettings.consentRequiredFromPersonTypes = [];

                            for (var i = 0; i < response.consentRequiredFromPersonTypes.length; i++)
                            {
                                this.eventSettings.consentRequiredFromPersonTypes.push(
                                    {
                                        label: response.consentRequiredFromPersonTypes[i].personType,
                                        id: response.consentRequiredFromPersonTypes[i].id
                                    });
                            }

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));
                }.bind(this);

                this.save = function ()
                {
                    if (this.eventSettings === 'undefined')
                    {
                        return;
                    }

                    var eventSettingsSaveRequest =
                    {
                        eventId: this.eventId,
                        notifyTypeId: this.eventSettings.notifyTypeId,
                        consentRequirementTypeId: this.eventSettings.consentRequirementTypeId,
                        consentMessage: this.eventSettings.consentMessage,
                        consentDeadline: 
                            moment.isMoment(this.eventSettings?.consentDeadline) 
                            ? this.eventSettings?.consentDeadline.toDate()
                            : this.eventSettings.consentDeadline,
                        maximumPlaces: this.eventSettings.maximumPlaces,
                        fee: this.eventSettings.fee,
                        isAdditionalSelectionRequired: this.eventSettings.isAdditionalSelectionRequired
                    };

                    eventsService.saveConsentEventSettings(eventSettingsSaveRequest)
                        .then(function (response)
                        {
                            if (!response.isError)
                            {
                                simpleAlertService.simpleAlert(
                                    {
                                        title: 'SB_Saved',
                                        message: 'SB_Consent_Event_Settings_Saved',
                                        staticBackdrop: true
                                    });
                                this.closeModal();
                            }
                            else
                            {
                                this.resetSubmitButtons();
                            }
                        }.bind(this))
                        .catch(function (responseData)
                        {
                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            simpleAlertService.errorAlert({ message: message });
                            this.resetSubmitButtons();
                        }.bind(this));
                }.bind(this);

                this.cancel = function ()
                {
                    this.modalInstance.dismiss('cancel');
                };

                this.closeModal = function ()
                {
                    this.modalInstance.close();
                }.bind(this);

                this.resetSubmitButtons = function ()
                {
                    $timeout(function ()
                    {
                        this.isSubmitted = false;
                    }.bind(this), 200);

                }.bind(this);

                this.consentRequirementChange = function () {
                    if (this.eventSettings.consentRequirementTypeId === consentRequirementEnum.ParentOnly &&
                            this.eventSettings.notifyTypeId === notifyTypesEnum.StudentOnly) {
                        this.eventSettings.notifyTypeId = notifyTypesEnum.ParentOnly
                    }

                    if (this.eventSettings.consentRequirementTypeId === consentRequirementEnum.StudentOnly &&
                            this.eventSettings.notifyTypeId === notifyTypesEnum.ParentOnly) {
                        this.eventSettings.notifyTypeId = notifyTypesEnum.StudentOnly
                    }
                }.bind(this);
            }
        ]
    });
