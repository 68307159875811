'use strict';

angular.module('organisation.services.organisationService', [])
    .factory('organisationService', [
        '$http', '$q', '$cacheFactory', '$window',
        function ($http, $q, $cacheFactory, $window) {

            var urlOrganisationBase = '/webapi/WebOrganisation/';
            var urlPersonBase = '/webapi/WebPerson/';
            var urlOrganisationUserImportBase = '/webapi/WebOrganisationUserImport/';
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('organisationServiceCache');
            var welcomeMessageCache = $cacheFactory('welcomeMessageCache');

            repository.getOrganisations = function () {
                return $http
                    .get(urlOrganisationBase + 'GetOrganisations')
                    .then(function (response) { return response.data; });
            };

            repository.getYears = function () {
                return $http.get(urlOrganisationBase + 'GetYears', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getActivities = function () {
                return $http.post(urlOrganisationBase + 'GetActivities', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getOrganisation = function () {
                return $http.get(urlOrganisationBase + 'GetOrganisation', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getIsStatus365Staff = function () {
                return $http.get(urlOrganisationBase + 'GetIsStatus365Staff')
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getGenders = function () {
                return [
                    { id: 1, name: 'Female' },
                    { id: 2, name: 'Male' },
                    { id: 3, name: 'Any' }
                ];
            };

            repository.getCurrentOrganisationType = function () {
                return $http.get(urlPersonBase + 'GetCurrentOrganisationType', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getCurrentOrganisationTitle = function () {
                return $http.get(urlOrganisationBase + 'GetCurrentOrganisationTitle', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.isSchool = function (orgTypeId) {
                return orgTypeId === window.EveryBuddy.Enums.OrganisationTypes.School;
            };

            repository.isClub = function (orgTypeId) {
                return orgTypeId === window.EveryBuddy.Enums.OrganisationTypes.Club;
            };

            repository.getPostWelcomeRedirectUrl = function () {
                return $http.get(urlOrganisationBase + 'GetPostWelcomeRedirectUrl')
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getWelcomeMessageForCurrentOrgansation = function (audience) {
                return $http.get(urlOrganisationBase + 'GetWelcomeMessage?audience=' + audience, { cache: welcomeMessageCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getAllWelcomeMessagesForCurrentOrganisation = function () {
                return $http.get(urlOrganisationBase + 'GetAllWelcomeMessages', { cache: welcomeMessageCache })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.saveAllWelcomeMessages = function (messages) {
                welcomeMessageCache.removeAll();

                return $http.post(urlOrganisationBase + 'SaveAllWelcomeMessages', messages)
                    .then(function (response) {
                        return response.data;
                    });
            };

            /* Import Data */
            repository.getAllImportData = function () {
               return $http.get(urlOrganisationUserImportBase + 'GetAllImportData')
                    .then(function (response) { return response.data; });
            };

            repository.getLatestImportData = function () {
                return $http.get(urlOrganisationUserImportBase + 'GetLatestImportData')
                    .then(function (response) { return response.data; });
            };

            repository.getImportData = function (organisationUserImportId) {
                return $http.get(urlOrganisationUserImportBase + 'GetImportData', { params: { organisationUserImportId: organisationUserImportId } })
                    .then(function (response) { return response.data; })
            };

            repository.postImportData = function (importData) {
                return $http.post(urlOrganisationUserImportBase + 'SaveImportData', importData)
                    .then(function (response) { return response.data; });
            };

            repository.postProcessImportData = function (organisationUserImportId) {
                return $http.post(urlOrganisationUserImportBase + 'ProcessImportData', { organisationUserImportId: organisationUserImportId })
                    .then(function (response) { return response.data; });
            };

            repository.getImportLookupData = function (organisationId) {
                return $http.get(urlOrganisationUserImportBase + 'GetImportLookupData', { params: { organisationId: organisationId } })
                    .then(function (response) { return response.data; });
            };

            return repository;
        }
    ]);