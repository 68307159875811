'use strict';

angular.module('balanceAdmin.dashboard.components.feesDashboard', [])
    .component('feesDashboard',
        {
            bindings: {},
            templateUrl: '/Scripts/app/balanceAdmin/dashboard/components/fees-dashboard.template.html',
            controllerAs: 'ctrl',
            controller: [
                'organisationService',
                'iconService',
                'balanceAdminService',
                function (
                    organisationService,
                    iconService,
                    balanceAdminService) {

                    organisationService.getOrganisation()
                        .then(function (organisation) {
                            this.organisation = organisation;
                        }.bind(this))
                        .catch(function () {
                          simpleAlertService.errorAlert({
                              message: 'SB_Error_Loading'
                          });
                        });

                    this.selectedTab = 0;

                    this.menuItems = [
                        {
                            uisref: '.summary',
                            title: 'SB_Summary',
                            icon: iconService.getIcon('dashboard'),
                            sortOrder: 1
                        },
                        {
                            uisref: '.moniesReceived',
                            title: 'SB_Monies_Received_and_Refunds',
                            icon: iconService.getIcon('receipt'),
                            sortOrder: 2
                        },
                        {
                            uisref: '.recordPayment',
                            title: 'SB_Record_Payments',
                            icon: iconService.getIcon('moneyBill'),
                            sortOrder: 3
                        },
                        {
                            uisref: '.reports',
                            title: 'SB_Reports',
                            icon: iconService.getIcon('statement'),
                            sortOrder: 4
                        },
                        {
                            uisref: '.accountSummaries',
                            title: 'SB_Accounts_Overview',
                            icon: iconService.getIcon('pupilAllocationOverview'),
                            sortOrder: 5
                        },
                        {
                            uisref: '.creditAccountSummaries',
                            title: 'SB_Credit_Accounts_Overview',
                            icon: iconService.getIcon('wallet'),
                            sortOrder: 6
                        },
                        {
                            uisref: '.voucherAccountSummaries',
                            title: 'SB_Voucher_Accounts_Overview',
                            icon: iconService.getIcon('ticket'),
                            sortOrder: 7
                        },
                        {
                            uisref: '.quickCharge',
                            title: 'SB_Quick_Charge',
                            icon: iconService.getIcon('lightning'),
                            sortOrder: 8
                        },
                        {
                            uisref: '.massAdjustmentStudent',
                            title: 'SB_Mass_Adjustment_Student',
                            icon: iconService.getIcon('list'),
                            sortOrder: 9
                        },
                        {
                            uisref: '.massAdjustmentGroup',
                            title: 'SB_Mass_Adjustment_Group',
                            icon: iconService.getIcon('listBoxed'),
                            sortOrder: 10
                        }
                    ];
                }
            ]
        });