
'use strict';

angular.module('settingsModule')
    .component('fariaOne',
        {
            templateUrl: '/Scripts/app/settings/components/faria-one/faria-one.template.html',
            controller: class FariaOneCtrl {

                $window: any;
                fariaOneService: any;
                simpleAlertService: any;
                ssoProvidersClean: any[] = [];
                ssoProviders: any[] = [];
                loading: number = 1;
                isError: boolean = false;
                isProcessing = false;

                static $inject = ['$window', 'fariaOneService', 'simpleAlertService'];

                constructor ($window: any, fariaOneService: any, simpleAlertService: any) {
                    this.$window = $window;
                    this.simpleAlertService = simpleAlertService;
                    this.fariaOneService = fariaOneService;
                }

                $onInit(){
                    this.fariaOneService.getSSO().then((res: any) => {
                        this.loading -= 1;
                        if (res.data) {
                            this.ssoProvidersClean = res.data;
                            this.applyCleanSettings();
                        }
                        else {
                            this.isError = true;
                        }
                    })
                }

                toggleProvider(name: string) {
                    this.ssoProvidersClean = this.ssoProvidersClean.map(provider => {
                        return {
                            ...provider,
                            status: provider.name === name ? !provider.status : provider.status
                        }
                    });
                }

                applyCleanSettings() {
                    this.ssoProviders = JSON.parse(JSON.stringify(this.ssoProvidersClean));
                }

                onClickSwitch(provider: any) {
                    this.isProcessing = true;
                    const isSelected = provider.status;
                    const confirmation = this.simpleAlertService.simpleAlert({
                        title: `${provider.displayName} SSO`,
                        message: isSelected ? 'SB_SSO_Turn_On_Confirmation' : 'SB_SSO_Turn_Off_Confirmation',
                        okButtonText: isSelected ? 'SB_SSO_Turn_On' : 'SB_SSO_Turn_Off',
                        cancelButtonText: 'SB_Cancel',
                        windowSize: 'md',
                        headerClass: provider.className,
                    });
                    confirmation
                        .result
                            .then(() => {
                                this.isProcessing = true;
                                if (isSelected) {
                                    this.fariaOneService.turnOnSSO(provider.name)
                                        .then((res: any)=> {
                                            if (res.isSuccess) {
                                                this.toggleProvider(provider.name);
                                            }
                                        })
                                        .finally(() => {
                                            this.isProcessing = false;
                                            this.applyCleanSettings();
                                        })
                                }
                                else {
                                    this.fariaOneService.turnOffSSO(provider.name)
                                        .then((res: any)=> {
                                            if (res.isSuccess) {
                                                this.toggleProvider(provider.name);
                                            }
                                        })
                                        .finally(() => {
                                            this.isProcessing = false;
                                            this.applyCleanSettings();
                                        })
                                }
                            })
                        .catch(() => {
                            this.applyCleanSettings();
                        })
                }
            }
        });
