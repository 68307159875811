angular.module('shared.components.sbEditField', [
    'shared.components.sbIcon'
])
    .component('sbEditField',
        {
            bindings: {
                model: '<',
                isRequired: '<',
                maxLength: '<',
                placeholder: '@',
                saveLabel: '@',
                type: '@',
                onSave: '&',
            },
            templateUrl: '/Scripts/app/shared/components/sb-edit-field.template.html',
            controller: class SbActionMenuCtrl {

                // Bindings
                model: any;
                modelClean: any;
                onSave: any;
                saveLabel: 'SB_Save';
                editLabel: 'SB_Edit';
                cancelLabel: 'SB_Cancel';

                // Variables
                isEditing: boolean = false;

                $onInit() {
                    this.modelClean = this.model;
                }

                $onChanges(changes) {
                    const newModel = changes.model?.currentValue?.model
                    if (newModel) {
                        this.modelClean = newModel;
                    }
                }

                toggleIsEditing() {
                    if (this.isEditing) {
                        this.onSave({value: this.model})
                    }
                   this.isEditing = !this.isEditing;
                }

                cancel() {
                    this.model = this.modelClean;
                    this.isEditing = false;
                }
            }
        });