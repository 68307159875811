angular.module('reception.services.receptionService', [])
    .factory('receptionService', [
        '$http', '$q', '$cacheFactory', 'httpCoreApi',
        function ($http, $q, $cacheFactory, httpCoreApi) {

            var urlBase = '/Reception/';
            var receptionRepository = {};

            var hardCache = $cacheFactory('receptionLookupCache');

            const httpCoreApiConfig = {
                controllerType: httpCoreApi.controllerTypes.Admin
            };

            receptionRepository.getTabVisibility = function () {
                return $http.post(urlBase + 'GetTabVisibility')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            receptionRepository.getBoolSetting = function (settingName) {
                return $http.get(urlBase + 'GetBoolSetting', { params: { settingName: settingName } })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            receptionRepository.setBoolSetting = function (settingName, value) {
                return $http.post(urlBase + 'SetBoolSetting', { settingName: settingName, value: value })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            receptionRepository.getEventSummary = function (eventsFrom, eventsTo, includeParentsEvening, includeFeeOnlyEvents, includeTransportEvents)
            {
                var deferred = $q.defer();

                var params =
                {
                    eventsFrom: eventsFrom,
                    eventsTo: eventsTo,
                    includeDeleted: true,
                    includeParentsEvening: includeParentsEvening,
                    includeFeeOnlyEvents: includeFeeOnlyEvents,
                    includeTransportEvents: includeTransportEvents
                };

                $http.get(urlBase + 'GetEventSummary', { params: params })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getAttendanceGroups = function (eventsFrom, eventsTo, includeDeleted, includeArchived) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetAttendanceGroups', { params: { eventsFrom: eventsFrom, eventsTo: eventsTo, includeDeleted: includeDeleted, includeArchived: includeArchived } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getEventRegister = function (eventId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetRegisterData', { params: { eventId: eventId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getRegGroups = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetRegGroups')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getAllGroups = function (includeArchived, myGroups, following, showSportsPortal) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetAllGroups', { params: { includeArchived: includeArchived, myGroups: myGroups, following: following, showSportsPortal: showSportsPortal }})
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getGroupDetails = function (groupId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetGroupDetails', { params: { teamId: groupId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getRegGroupCsvUrl = function (teamId, fromDate, weekView, isRegGroup) {
                return urlBase + 'GetRegGroupCsv?teamId=' + teamId + '&fromDate=' + fromDate + '&weekView=' + weekView + '&isRegGroup=' + isRegGroup;
            };

            receptionRepository.saveGroupDetails = function (dto) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveGroupDetails', { dto: dto })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.toggleFollow = function (groupId, isFavourite) {
                var deferred = $q.defer();
                $http.post(urlBase + 'ToggleFollow', { teamId: groupId, isFavourite: isFavourite })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.archiveGroup = function (groupId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'ArchiveGroup', { teamId: groupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.reactivateGroup = function (groupId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'ReactivateGroup', { teamId: groupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.deleteRegGroup = function (regGroupId)
            {
                var deferred = $q.defer();
                $http.post(urlBase + 'DeleteRegGroup', { regGroupId: regGroupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPupilsInGroup = function (groupId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilsInGroup', { params: { teamId: groupId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPupilsInOrganisation = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilsInOrganisation')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getStaffInOrganisation = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetStaffInOrganisation')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getStaffInGroup = function (groupId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetStaffInGroup', { params: { teamId: groupId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.addStaff = function (teamId, personId, roleId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'AddStaff', { teamId: teamId, personId: personId, roleId: roleId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.addPersonToGroup = function (teamId, personId, roleId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'AddPersonToGroup', { teamId: teamId, personId: personId, roleId: roleId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getRegGroupNextEvent = function (schoolClassId, fromDate) {
                console.warn('deprecated- use the eventRegisters service');
                var deferred = $q.defer();
                $http.get(urlBase + 'GetRegGroupNextEventData', { params: { schoolClassId: schoolClassId, fromDate: fromDate } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getNextEventData = function (teamId, fromDate, weekView, isRegGroup) {
                console.warn('deprecated- use the eventRegisters service');
                var deferred = $q.defer();
                $http.get(urlBase + 'GetNextEventData', { params: { groupId: teamId, fromDate: fromDate, weekView: weekView, isRegGroup: isRegGroup} })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getRegisterNextEvent = function (eventId) {
                console.warn('deprecated- use the eventRegisters service');
                var deferred = $q.defer();
                $http.get(urlBase + 'GetRegisterNextEventData', { params: { eventId: eventId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPerson = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPersonData', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPersonGroups = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPersonGroups', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPersonPermissionToWalk = (personId) => {
                return httpCoreApi.get(`Student/Transport/Permission/${personId}`, httpCoreApiConfig);
            };

            /*
                Medical
            */
            receptionRepository.getPupilMedical = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilMedical', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.savePupilMedical = function (personId, description, notes, medicalConditionId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SavePupilMedical', { personId: personId, description: description, notes: notes, medicalConditionId: medicalConditionId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.deletePupilMedical = function (personId, medicalConditionId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'DeletePupilMedical', { personId: personId, medicalConditionId: medicalConditionId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            /*
                Membership
            */
            receptionRepository.getPupilMembership = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilMembership', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.savePupilMembership = function (membership) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SavePupilMembership', { dto: membership })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPersonAvailableGroups = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPersonAvailableGroups', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getPersonFormData = function (personId, formId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPersonFormData', { params: { personId: personId, formId: formId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.saveFormData = function (formId, personId, capturedData) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveFormData', { formId: formId, personId: personId, capturedData: capturedData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getLookupData = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLookupData', { cache: hardCache })
                    .then(function (response) {
                        deferred.resolve(response.data);
                    })
                    .catch(function (e) {
                        deferred.reject(e);
                    });
                return deferred.promise;
            };

            receptionRepository.getGroupLookupData = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetGroupLookupData')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            /*
                Location
            */
            receptionRepository.getAllLocations = function (includeDeleted) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetAllLocations', { params: { includeDeleted: includeDeleted } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getLocationTypes = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLocationTypes')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getLocation = function (locationId) {
                if (locationId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLocation' + '?hash_id=' + (new Date().getTime()),
                    { params: { locationId: locationId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.getLocationEvents = function (locationId) {
                if (locationId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetLocationEvents' + '?hash_id=' + (new Date().getTime()),
                    { params: { locationId: locationId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.postLocation = function (location) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveLocation', { location: location })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.postDeleteLocation = function (locationId)
            {
                var deferred = $q.defer();
                $http.post(urlBase + 'DeleteLocation', { locationId: locationId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.postRestoreLocation = function (locationId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RestoreLocation', { locationId: locationId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.removeLink = function (parentId, linkedPersonId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RemoveLink', { parentId: parentId, linkedPersonId: linkedPersonId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.removePersonGroup = function (teamId, personId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RemovePersonGroup', { teamId: teamId, personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.removeGroupPerson = function (teamId, personId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RemoveGroupPerson', { teamId: teamId, personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.removeAdminGroup = function (teamId, personId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RemoveAdminGroup', { teamId: teamId, personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.saveLink = function (linkedData) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveLinked', { parentLink: linkedData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.savePerson = function (personData) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SavePerson', { personData: personData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };
            receptionRepository.deletePerson = function (personId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'DeletePerson', { personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };
            receptionRepository.restorePerson = function (personId, includeLinks) {
                var deferred = $q.defer();
                $http.post(urlBase + 'RestorePerson', { personId: personId, includeLinks: includeLinks })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            receptionRepository.addPerson = function (personData) {
                var deferred = $q.defer();
                $http.post(urlBase + 'AddPerson', { personData: personData })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            return receptionRepository;
        }
    ]);