angular.module('insurance.components.insuranceContainer',
    [
        'insurance.components.insuranceEditor',
        'insurance.services.insuranceService'
    ])
    .component('insuranceContainer',
        {
            bindings: {
                teamId: '<'
            },
            controller: [
                'insuranceService',
                function (insuranceService)
                {
                    this.insurances = [];

                    this.$onChanges = function(changes)
                    {
                        if (changes.teamId && changes.teamId.currentValue)
                        {
                            insuranceService.getAllInsurancesForCase(changes.teamId.currentValue)
                                .then(function(data)
                                {
                                    if (!data.isError)
                                    {
                                        this.insurances = data;
                                    }
                                }.bind(this));
                        }
                    };

                    this.onDelete = function(insurance)
                    {
                        var found = -1;
                        for (var i = this.insurances.length; i--;)
                        {
                            if (this.insurances[i].id === insurance.id)
                            {
                                found = i;
                                break;
                            }
                        }

                        if (found > -1)
                        {
                            this.insurances.splice(found, 1);
                        }
                    }.bind(this);

                    this.onChange = function(insurance, newId)
                    {
                        var found = -1;
                        for (var i = this.insurances.length; i--;) {
                            if (this.insurances[i].id === insurance.id) {
                                found = i;
                                break;
                            }
                        }

                        if (found > -1)
                        {
                            this.insurances[found] = insurance;
                            this.insurances[found].id = newId;
                        }
                    }

                    this.addInsurance = function()
                    {
                        this.insurances.push({
                            id: 0,
                            teamId: this.teamId
                        })
                    }.bind(this);
                }],
            templateUrl: '/Scripts/app/insurance/components/insurance-container.template.html'
        });