"use strict";

angular.module('shared.directives.teamClubPicker', [
    'teams.services.teamService',
    'clubs.services.clubService',
    'subjectClass.services.subjectClassService',
    'shared.components.teamClubPickerSummary',
    'shared.components.sbButton'
])
    .filter('includeArchived', function() {
        return function (items, include) {

            var filtered = [];

            for (var i = 0; i < items.length; i++) {

                if (include)
                    filtered.push(items[i]);
                else if (!items[i].archived) {
                    filtered.push(items[i]);

                }
            }

            return filtered;
        };
    })
    .directive('teamClubPicker', [
        '$window',
        '$state',
        'teamService',
        'clubService',
        'subjectClassService',
        function ($window, $state, teamService, clubService, subjectClassService) {

            function controller($scope) {


                this.items = [];
                this.recipientSearchType = 1;
                this.loading = 2;
                this.filter = {};
                this.includeArchivedFlag = false;

                this.clubs = [];
                this.teams = [];
                this.subjectClasses = [];

                var self = this;

                clubService.getAllForLookup()
                    .then(function (data) {

                        for (var i = data.length; i--;) {
                            data[i].searchField = data[i].name;
                        }

                        self.clubs = data;
                        if (parseInt(self.recipientSearchType) === 1)
                            self.items = self.clubs;
                        self.loading --;
                    })
                    .catch(function (error) {

                        self.loading--;
                    });

                teamService.getAllForLookup(true) // include archived
                    .then(function (data) {

                        for (var i = data.length; i--;) {
                            data[i].searchField = data[i].name;
                        }

                        self.teams = data;

                        if (parseInt(self.recipientSearchType) === 2)
                            self.items = self.teams;
                        self.loading --;
                    })
                    .catch(function (error) {

                        self.loading--;
                    });

                this.loadData = function()
                {
                    switch (parseInt(self.recipientSearchType))
                    {
                        case 1: // club
                            self.items = self.clubs;
                            break;
                        case 2: // team
                            self.items = self.teams;
                            break;
                        case 3: // subject classes
                            self.items = self.subjectClasses;
                            break;
                    }
                };

                this.loadData();

                // added isXAdded for Angular 12

                this.isClubAdded = function (item){
                    return this.clubIds.indexOf(item.id) > -1;
                };

                this.addClub = function (item) {
                    if (this.isClubAdded(item))
                        return;

                    this.clubIds.push(item.id);
                    this.onChangeModel();
                };

                this.isTeamAdded = function (item){
                    return this.teamIds.indexOf(item.id) > -1;
                };

                this.addTeam = function (item) {
                    if (this.isTeamAdded(item))
                        return;

                    this.teamIds.push(item.id);
                    this.onChangeModel();
                };

                this.isSubjectClassAdded = function (item){
                    return this.subjectClassIds.indexOf(item.id) > -1;
                };

                this.addSubjectClass = function (item) {
                    if (!this.subjectClassIds)
                        return;

                        if (this.isSubjectClassAdded(item))
                        return;

                    this.subjectClassIds.push(item.id);
                    this.onChangeModel();
                };

                $scope.$watch('tcp.recipientSearchType', function (newValue, oldValue)
                {
                    self.loadData();
                    self.filter.searchField = '';
                });

                $scope.$watch('tcp.subjectsEnabled', function (newValue, oldValue)
                {
                    self.loading++;
                    subjectClassService.getAllForLookupCached()
                        .then(function (classData) {

                            for (var i = classData.length; i--;) {
                                classData[i].searchField = classData[i].schoolYearGroupName + ' ' + classData[i].subjectName + ' ' + classData[i].title + ' ' + classData[i].teacherName;
                            }

                            self.subjectClasses = classData;
                            if (parseInt(self.recipientSearchType) === 3)
                                self.items = self.subjectClasses;
                            self.loading--;
                        })
                        .catch(function (error) {

                            self.loading--;
                        });
                });

                this.filterExcludedTeamIds = function(item)
                {
                    if (!this.excludedTeamIds) return true;
                    return this.excludedTeamIds.indexOf(+item.id) === -1;
                }.bind(this);

                this.filterExcludedClubIds = function (item)
                {
                    if (!this.excludedClubIds) return true;
                    return this.excludedClubIds.indexOf(+item.id) === -1;
                }.bind(this);

                this.filterExcludedSubjectClassIds = function (item) {
                    if (!this.excludedSubjectClassIds) return true;
                    return this.excludedSubjectClassIds.indexOf(+item.id) === -1;
                }.bind(this);

            }

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/shared/directives/team-club-picker.template.html',
                scope: {},
                bindToController: {
                    teamIds: '=',
                    clubIds: '=',
                    subjectClassIds: '=',
                    subjectsEnabled: '=',

                    excludedTeamIds: '=',
                    excludedClubIds: '=',
                    excludedSubjectClassIds: '=',
                    onChangeModel: '&'
                },
                controller: ['$scope', controller],
                controllerAs: 'tcp'
            };
        }]);
