angular.module("userForm.components.tabcapture",
        [
            'userform.services.userFormService',

            'pascalprecht.translate'
        ])
    .component('userFormTabCapture',
        {
            bindings: {
                person: '<',
                formId: '<',
                printable: '=',
                hideHeading: '<',
                canSaveForm: '<',
                onChange: '&',
                captureForPeople: '<',
                ccaSignUpId: '<'    //TODO: These propteries need to be set in an object to stop race conditions
            },
            templateUrl: '/Scripts/app/user-form/components/user-form-tab-capture.template.html',

            controller: [
                '$timeout',
                'simpleAlertService',
                'userFormService',
                function userFormTabCaptureController($timeout, simpleAlertService, userFormService) {
                    this.loading = 0;
                    var self = this;

                    this.isLoading = function () { return self.loading > 0; };

                    this.$onChanges = function(changes)
                    {
                        if (changes.person || changes.formId || self.captureForPeople || self.ccaSignUpId)
                        {
                            self.loadFormData();
                        }
                    };

                    this.$doCheck = function() {
                        if (self.userForm &&  self.peopleFormData &&  self.peopleFormData.capturedData) {
                            self.dataChanged(self.allValid());
                        }
                    }

                    this.loadFormData = function() {
                                        // if both the person and selected form are selected, then we can load the data
                        if (self.person && self.formId && self.captureForPeople && self.ccaSignUpId) {

                            self.peopleFormData = null;
                            self.loading++;

                            userFormService.getPeopleFormData(self.captureForPeople, self.person.personData.id, self.formId, self.ccaSignUpId)
                                .then(function(data) {
                                    self.peopleFormData = data;
                                    self.selectedFormTitle = self.peopleFormData.title;
                                    self.dataEntry = self.peopleFormData.description;

                                    if (self.peopleFormData.completedData.length > 0) {
                                        var fields = self.peopleFormData.fields;
                                        var fieldLength = fields.length;

                                        for (var k = 0; k < self.peopleFormData.completedData.length; k++) {
                                            var aPerson = self.peopleFormData.completedData[k];
                                            if (aPerson.capturedData.length > 0) {
                                                for (var i = 0; i < fieldLength; i++) {
                                                    var aField = fields[i];
                                                    var fieldId = aField.organisationFormFieldId;
                                                    if (aField.fieldType === 4) {
                                                        for (var j = 0; j < aPerson.capturedData.length; j++) {
                                                            var captured = aPerson.capturedData[j];
                                                            if (fieldId === captured.organisationFormFieldId) {
                                                                captured.userData = (captured.userData === "True");
                                                            }
                                                        }
                                                    } else if (aField.fieldType === 3) {
                                                        for (var j = 0; j < aPerson.capturedData.length; j++) {
                                                            var captured = aPerson.capturedData[j];
                                                            if (fieldId === captured.organisationFormFieldId && !!captured.userData && captured.userData.length > 10) {
                                                                captured.userData = new Date(captured.userData.substring(0, 10));
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    self.loading--;
                                    self.dataChanged(self.allValid());
                                });
                        }

                    }

                    this.dataChanged = function() {
                        if (typeof (self.onChange()) === 'function' ) {
                            $timeout(() => {
                                self.onChange()(self.allValid(), self.peopleFormData.completedData);
                            });
                        }
                    };

                    this.allValid = function() {

                        var isValid = true;
                        for (var i = 0; i < self.captureForPeople.length; i++) {
                            var atab = self.userForm.tabs[i];
                            if (atab && atab.$invalid) {
                                isValid = false;
                                break;
                            }
                        }
                        return isValid;
                    }

                    this.fieldType = [
                        { id: 0, name: "text" },
                        { id: 1, name: "text" },
                        { id: 2, name: "nmber" },
                        { id: 3, name: "date" },
                        { id: 4, name: "checkbox" },
                        { id: 5, name: "email" },
                        { id: 6, name: "textarea" },
                        { id: 7, name: "select" }
                    ];

                    this.updateFormData = function() {
                        userFormService.saveFormData(self.formId,
                                self.person.personData.id,
                                self.peopleFormData.capturedData)
                            .then(function(data) {
                                simpleAlertService.simpleAlert({
                                    title: 'Saved',
                                    message: 'Changes saved',
                                    windowSize: 'sm'
                                });
                            });
                    };
                }
            ]
        });
