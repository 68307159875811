<div class="timeline"
     [ngClass]="{
         'timeline-has-selected-stop' : hasSelectedStop,
         'timeline--collapsed' : hideOtherStops,
         'p-4' : isModal,
         'mt-5' : !isModal
        }">
    <div>
        <div *ngFor="let stop of route.stops"
             [ngClass]="{'selected-stop' : stop.isSelected}"
             [hidden]="hideStop(stop)">
            <div>
                {{("00"+stop.timeHoursMinutes.h).slice(-2)}}:{{("00"+stop.timeHoursMinutes.m).slice(-2)}}
            </div>
            <div>
                <div class="timeline-bullet"></div>
            </div>
            <div>
                <div class="b-3">
                    <div>
                        <h3 class="semi-bold">
                            {{ stop.name }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <button *ngIf="isModal"
            class="text-muted btn-link modal-close"
            (click)="close()">
        <sb-icon name="close"></sb-icon>    
    </button> -->
</div>