angular.module('ccaAvailable.services.organisationAvailableRepository', [])
    .factory("organisationAvailableRepository", [
    "$http", "$q", function ($http, $q) {
        return {
            get: function () {
                var deferred = $q.defer();
                $http.get("/CcaAvailable/GetOrganisation")
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            }
        };
    }
]);