angular.module('payments.components.parentBalancePayment', [
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'balance.services.balanceService',
    'balance.constants',
    'balanceAdmin.services.balanceAdminService',
    'shared.filters.decoded',
    'shared.services.mathService',
    'shared.services.cachedLookupService'
]).component('parentBalancePayment',
    {
        bindings: {
            totalAmount: '<',
            pupilId: '<',
            allowPartialCredit: '<',
            isValid: '=?',
            paymentFn: '&',
            showPaymentButton: '<',
            paymentMsg: '=?',
            paymentMsgTranslationParams: '=?',
            acceptVoucherPayments: '<',
            selectedBalanceParentId: '=?',
            selectedBalanceType: '=?',
            selectedBalanceCharge: '=?'
        },
        templateUrl: '/Scripts/app/payments/components/parent-balance-payment.template.html',
        controllerAs: 'ctrl',
        controller: class ParentBalancePaymentCtrl {

            // Dependencies
            $window: any;
            mathService: any;
            cachedLookupService: any;
            balanceAdminService: any;
            financialAccountTypeEnum: any;

            // Bindings
            totalAmount: number;
            pupilId: any;
            allowPartialCredit: boolean;
            isValid: boolean;
            paymentFn: any;
            showPaymentButton: boolean;
            paymentMsg: string;
            paymentMsgTranslationParams: any;
            acceptVoucherPayments: boolean;
            selectedBalanceParentId: any;
            selectedBalanceType: any;
            selectedBalanceCharge: any;

            // Variables
            loading: number;
            loadFailureMessage: any;
            chargeableParents: any;
            allowVoucherAccountToGoOverdrawn: boolean;
            currencyDisplaySymbol: string;
            selectedBalanceParentName: any;
            parentBalanceType: any;

            static $inject = ['$window', 'mathService', 'cachedLookupService', 'balanceAdminService', 'financialAccountTypeEnum'];

            constructor(
                $window,
                mathService,
                cachedLookupService,
                balanceAdminService,
                financialAccountTypeEnum) {
                this.$window = $window;
                this.mathService = mathService;
                this.cachedLookupService = cachedLookupService;
                this.balanceAdminService = balanceAdminService;
                this.financialAccountTypeEnum = financialAccountTypeEnum;
            }

            $onInit() {

                // Init variables
                this.loading = 0;
                this.loadFailureMessage = null;

                this.allowVoucherAccountToGoOverdrawn = false;

                this.selectedBalanceParentName = null;

                this.currencyDisplaySymbol = this.$window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                this.parentBalanceType = {
                    CREDIT: this.financialAccountTypeEnum.Credit,
                    VOUCHER: this.financialAccountTypeEnum.Voucher
                };

                // default to false if not defined/null
                if (!this.allowPartialCredit) {
                    this.allowPartialCredit = false;
                }

                // Check optional properties and initialise if not provided;
                if (typeof this.isValid === 'undefined') {
                    this.isValid = false;
                }

                if (typeof this.selectedBalanceParentId === 'undefined') {
                    this.selectedBalanceParentId = null;
                }

                if (typeof this.selectedBalanceType === 'undefined') {
                    this.selectedBalanceType = null;
                }

                if (typeof this.selectedBalanceCharge === 'undefined') {
                    this.selectedBalanceCharge = null;
                }

                if (!this.paymentMsg) {
                    this.paymentMsg = 'SB_Pay';
                }

                if (typeof this.paymentMsgTranslationParams === 'undefined') {
                    this.paymentMsgTranslationParams = null;
                }

                this.cachedLookupService.getSystemSettings(['Payments_Vouchers_AllowAccountToBeOverdrawn'])
                    .then(data => {
                        this.allowVoucherAccountToGoOverdrawn = data['Payments_Vouchers_AllowAccountToBeOverdrawn'];
                    });
            }

            $onChanges(changes) {
                if (changes.totalAmount && changes.totalAmount.currentValue != null) {
                    // ensure we have at most two dps.
                    this.totalAmount = this.mathService.roundNumber(this.totalAmount, 2);

                    // remove any existing selection
                    if (this.selectedBalanceParentId) {
                        this.selectedBalanceParentId = null;
                        this.selectedBalanceParentName = null;
                        this.selectedBalanceType = null;
                        this.selectedBalanceCharge = null;
                        this.isValid = false;
                        this.paymentMsg = 'SB_Pay';
                        this.paymentMsgTranslationParams = null;
                    }
                }

                if (changes.pupilId && changes.pupilId.currentValue) {
                    this.getChargeableParents();
                }
            };

            getChargeableParents() {
                this.loadFailureMessage = null;
                this.balanceAdminService.getChargeableParents(this.pupilId)
                    .then(data => {
                        if (data) {
                            this.chargeableParents = data;
                        }
                        else {
                            console.log('XHR Error: getChargeableParents data null');
                            this.loadFailureMessage = 'SB_Problem_Loading_Parents';
                        }
                    })
                    .catch(err => {
                        console.log('XHR Error: getChargeableParents', err);
                        this.loadFailureMessage = 'SB_Problem_Loading_Parents';
                    });
            }

            // check insufficent balance, voucher must always cover 100%, credit may need to depending on property
            isBalanceSelectable(balance, balanceType) {
                // balance pending update
                if (typeof balance == 'undefined') {
                    return false;
                }

                if (balance == 0 && balanceType == this.parentBalanceType.CREDIT) {
                    return false;
                }

                if (balanceType == this.parentBalanceType.VOUCHER) {
                    // allow voucher payment if balance covers amount, or balances can go into negative.
                    return this.totalAmount <= balance || this.allowVoucherAccountToGoOverdrawn;
                }

                // allow credit payment if balance covers amount, or partial credit payment allowed.
                return this.totalAmount <= balance || this.allowPartialCredit;
            };

            getTableCellClass(parentId, balance, balanceType) {
                if (this.selectedBalanceParentId == parentId &&
                    this.selectedBalanceType == balanceType) {
                    return 'hover success';
                }

                return this.isBalanceSelectable(balance, balanceType)
                    ? 'hover warning'
                    : '';
            };

            selectBalance(parentId, parentName, balance, balanceType) {
                if (!this.isBalanceSelectable(balance, balanceType)) {
                    return;
                }

                // check for toggle
                if (this.selectedBalanceParentId == parentId && this.selectedBalanceType == balanceType) {
                    this.selectedBalanceParentId = null;
                    this.selectedBalanceParentName = null;
                    this.selectedBalanceType = null;
                    this.selectedBalanceCharge = null;
                    this.isValid = false;
                    this.paymentMsg = 'SB_Pay';
                    this.paymentMsgTranslationParams = null;
                    return;
                }

                this.selectedBalanceParentId = parentId;
                this.selectedBalanceParentName = parentName;
                this.selectedBalanceType = balanceType;

                // charge will not be total amount in case of partial credit payment.
                this.selectedBalanceCharge = balance < this.totalAmount &&
                    this.selectedBalanceType == this.parentBalanceType.CREDIT
                    ? balance
                    : this.totalAmount;

                this.isValid = true;

                this.paymentMsg = this.selectedBalanceType == this.parentBalanceType.CREDIT
                    ? 'SB_Pay_Amount_Currency_With_Credit'
                    : 'SB_Pay_Amount_Currency_With_Voucher';

                this.paymentMsgTranslationParams = {
                    amount: this.selectedBalanceCharge.toFixed(2),
                    currencySymbol: this.currencyDisplaySymbol,
                    parent: this.selectedBalanceParentName
                };

            };

            pay() {
                if (typeof this.paymentFn == 'function') {
                    this.paymentFn()(this.selectedBalanceParentId, this.selectedBalanceType, this.selectedBalanceCharge);
                }
            };
        }
    });
