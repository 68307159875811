import { Output } from '@angular/core';
import { Directive, ElementRef, EventEmitter, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-team-club-picker' })
export class TeamClubPickerUpgrade extends UpgradeComponent {
  @Input() teamIds: number[];
  @Output() teamIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() clubIds: number[];
  @Output() clubIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() subjectClassIds: number[];
  @Output() subjectClassIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() subjectsEnabled: boolean;
  // @Output() subjectsEnabledChange: EventEmitter<boolean> = new EventEmitter<number[]>();
  @Input() excludedTeamIds: number[];
  @Output() excludedTeamIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() excludedClubIds: number[];
  @Output() excludedClubIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() excludedSubjectClassIds: number[];
  @Output() excludedSubjectClassIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() onChangeModel: EventEmitter<void> = new EventEmitter<void>();
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1SbTeamClubPicker', elementRef, injector);
  }
}