// UpgradeComponent does not like 'name' property, so have created an Angular 12 version of this component
import { Component, Input, SimpleChanges } from '@angular/core';
import { IconService } from '@sb-shared/services/icon.service';
import { IconSize } from '@sb-shared/types/icon-size';
import { IconStyle } from '@sb-shared/types/icon-style';
import { UiClasses } from '@sb-shared/types/ui-classes';

@Component({
  selector: 'sb-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() name: string;
  @Input() overrideStyle: IconStyle;
  @Input() size: IconSize;
  @Input() disabled: boolean;
  @Input() altText: string;
  @Input() hasColor: boolean;
  @Input() fallbackText: string;
  @Input() overlayName: string;
  @Input() overlayCustomClass = '';
  @Input() overlayOverrideStyle: IconStyle;
  @Input() iconColour = '';
  @Input() isActivity: boolean;
  @Input() textClass: UiClasses;

  iconClass: string[];
  overlayIconClass: string[];
  isText = false;
  iconColors: any[] = [];
  style = '';

  constructor(private iconService: IconService) {}

  ngOnChanges(changes: SimpleChanges) {
    let iconClass = [];
    let overlayIconClass = [];

    if (
      changes.name?.currentValue ||
      changes.overrideStyle?.currentValue ||
      changes.isText ||
      changes.size?.currentValue ||
      changes.isActivity?.currentValue ||
      changes.textClass?.currentValue
    ) {
      iconClass = this.iconService.getIcon(this.name)?.split(' ') || this.name?.split(' ');
      if (iconClass) {
        if (this.overrideStyle) iconClass = this.getOverriddenClass(iconClass, this.overrideStyle);
        this.isText = !iconClass[0];
        if (this.hasColor) {
          this.iconColors = ['var(--brand-primary)', 'var(--brand-secondary)'];
          if (!this.isText) {
            if (this.iconColors[0]) {
              this.style = '--fa-primary-color:' + this.iconColors[0];
              this.style += ';--fa-secondary-color:' + (this.iconColors[1] ? this.iconColors[1] : this.iconColors[0]);
            }
          }
        }
        if (this.size) {
          switch (this.size.toLowerCase()) {
            case 'sm':
              iconClass.push('fa-sm');
              break;
            case 'lg':
              iconClass.push('fa-lg');
              break;
            case 'xl':
              iconClass.push('fa-xl');
              break;
          }
        }

        if (this.isActivity) {
          iconClass.push('activity-icon');
        }

        if (this.textClass) {
          iconClass.push('text-' + this.textClass);
        }

        this.iconClass = iconClass;
      }
    }

    if (changes.overlayName || changes.overlayOverrideStyle || changes.overlayCustomClass) {
      if (this.overlayName) {
        overlayIconClass = this.iconService.getIcon(this.overlayName)?.split(' ');
        if (overlayIconClass) {
          overlayIconClass = [...overlayIconClass, 'icon-overlay'];

          if (this.overlayOverrideStyle) {
            overlayIconClass = this.getOverriddenClass(overlayIconClass, this.overlayOverrideStyle);
          }

          if (this.overlayCustomClass) {
            overlayIconClass.push(this.overlayCustomClass);
          }

          this.overlayIconClass = overlayIconClass;
        } else {
          this.overlayIconClass = null;
        }
      } else {
        this.overlayIconClass = null;
      }
    }
  }

  getOverriddenClass(iconClassArray: string[], overrideStyle: IconStyle) {
    const iconClasses = ['fa', 'far', 'fal', 'fas', 'fad', 'fat'];
    const replaceClass = (newClass: string) => {
      iconClasses.forEach(oldClass => {
        const index = iconClassArray.indexOf(oldClass);
        iconClassArray[index] = newClass;
      });
    };
    switch (overrideStyle.toLowerCase()) {
      case 'regular':
        replaceClass('far');
        break;
      case 'light':
        replaceClass('fal');
        break;
      case 'solid':
        replaceClass('fas');
        break;
      case 'duotone':
        replaceClass('fad');
        break;
      case 'thin':
        replaceClass('fat');
        break;
    }
    return iconClassArray;
  }
}
