<div class="shell"
     [attr.dir]="scriptDirection$ | async"
     *ngIf="!isLoadingPage">
    <header *ngIf="!isMobileApp" class="header-fixed">
        <nav class="navbar navbar-default"
             role="navigation"
             cloak-translate>
            <div class="space-between w-100">
                <div class="align-center">
                    <div class="hamburger-container">
                        <sb-button iconName="hamburgerMenu"
                                   buttonClass="link"
                                   [isAutoSize]="true"
                                   class="hamburger"
                                   (onClick)="toggleSideMenu()"></sb-button>
                        <sb-badge *ngIf="(totalMenuBadgeCount$ | async) as count" class="menu-badge"
                                  badgeClass="danger"
                                  [isRounded]="true"
                                  [message]="count.toString()"
                                  [isMessageTranslated]="false"></sb-badge>
                                </div>
                    <div class="navbar-logo">
                        <a class="align-center"
                             [attr.title]="'MenuHome' | translate"
                             routerLink="/">
                            <img [src]="currentOrganisation.webLogoPath"
                                 [alt]="currentOrganisation.name"
                                 class="hidden-xs" />
                            <h2 class="hidden-xs">
                                {{currentOrganisation.name}}
                            </h2>
                            <img [src]="currentOrganisation.webLogoPath"
                                 class="visible-xs">
                        </a>
                    </div>
                </div>
                <div class="pl-5 pr-3">
                    <div class="row">
                        <ul class="nav navbar-nav navbar-top hidden-custom-navbar align-center">
                            <li *ngFor="let item of rightMenuItems"
                                class="p-3 hidden-xs">
                                <a *ngIf="item.route"
                                   class="btn btn-link btn-auto"
                                   [routerLink]="item.route"
                                   [ngbTooltip]="(item.label | translate)"
                                   placement="bottom"
                                   container="body">
                                    <i [class]="'fas ' + item.icon"></i>
                                </a>
                                <a *ngIf="!item.route && item.externalUrl"
                                   class="btn btn-link btn-auto"
                                   [ngbTooltip]="(item.label | translate)"
                                   placement="bottom"
                                   container="body"
                                   target="_blank"
                                   [href]="item.externalUrl">
                                    <i [class]="'fas ' + item.icon"></i>
                                </a>
                                <sb-badge *ngIf="item.count"
                                          class="menu-badge"
                                          badgeClass="danger"
                                          [isRounded]="true"
                                          [message]="item.count?.toString()"
                                          [isMessageTranslated]="false"></sb-badge>
                            </li>
                            <li>
                                <span *ngIf="currentOrganisation.isTrial" class="badge badge-square ml-3 mr-3">{{'SB_Trial' | translate}}</span>
                            </li>
                            <li>
                                <sb-locale-picker></sb-locale-picker>
                            </li>
                            <li *ngIf="(currentUser$|async)?.canSwitchUserType">
                                <sb-switcher></sb-switcher>
                            </li>
                            <li class="ml-2 mr-5"
                                [ngbTooltip]="'SB_Profile' | translate"
                                placement="bottom">
                                <ng-template #currentUserAvatar>
                                    <sb-avatar [personId]="(currentUser$|async)?.id" [firstName]="(currentUser$|async)?.firstName"
                                        [lastName]="(currentUser$|async)?.lastName" [photoUrl]="(currentUser$|async)?.photoUrl"
                                               [actionType]="AvatarButtonAction.CustomLink"></sb-avatar>
                                </ng-template>
                                <sb-button buttonClass="link"
                                           dropdownClasses="dropdown-sm-fullscreen"
                                           [isAutoSize]="true"
                                           [dropdownItems]="avatarDropdownItems">
                                    <ng-container *ngTemplateOutlet="currentUserAvatar"></ng-container>
                                    <div dropdownContent>
                                        <div ngbDropdownItem class="navbar-header__profile-button">
                                            <div class="navbar-header__profile-button-inner">
                                                <ng-container *ngTemplateOutlet="currentUserAvatar"></ng-container>
                                                <div class="navbar-header__profile-button-text">
                                                    <div class="semi-bold"
                                                         [innerHTML]="(currentUser$|async)?.firstName + ' ' + (currentUser$|async)?.lastName">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="separator"
                                             class="divider"></div>
                                    </div>
                                </sb-button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </header>
    <div class="flex">
        <div *ngIf="!isMobileApp" class="position-relative">
            <sb-side-menu [isOpen]="isSideMenuOpen"></sb-side-menu>
        </div>
        <div class="content__outer">
            <div class="content"
                 (click)="onClickContent()">
                <div [class.content__inner]="this.currentMainMenuItem?.menuItemTypeId != this.mainMenuItemId.Dashboard">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
<sb-forbidden-popup></sb-forbidden-popup>
<sb-page-loader [hasLoaded]="!isLoadingPage"></sb-page-loader>
