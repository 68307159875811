'use strict';

angular.module('events.components.confirmRemoveStudentFromEvent', [
])
    .component('confirmRemoveStudentFromEvent',
        {
            bindings: {
                modalInstance: '<',
                resolve: '<'
            },
            templateUrl: '/Scripts/app/events/components/confirm-remove-student-from-event.template.html',
            controller: [function ()
            {
                this.student = null;

                this.$onChanges = function (changes)
                {
                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.student = changes.resolve.currentValue.student;
                    }
                }.bind(this);

                this.delete = function ()
                {
                    closeModal();
                }.bind(this);

                this.cancel = function ()
                {
                    this.modalInstance.dismiss('cancel');
                }.bind(this);

                var closeModal = function ()
                {
                    this.modalInstance.close();
                }.bind(this);
            }]
        });