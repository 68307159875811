angular.module('userForm.components.bulkView',
        [
            'shared.directives.sbPrint',
            'userform.services.userFormService',
            'teams.services.teamService',
            'pascalprecht.translate'
        ])
    .component('userFormBulkView',
        {
            bindings: {
                formId: '<',
                ccaSignUpId: '<',
                calendarEventId: '<',
                teamId: '<',
                formFieldTagId: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/user-form/components/user-form-bulk-view.template.html',

            controller: [
                '$state',
                '$uibModal',
                '$window',
                '$compile',
                '$timeout',
                '$translate',
                'userFormService',
                function userFormBulkViewController(
                    $state,
                    $uibModal,
                    $window,
                    $compile,
                    $timeout,
                    $translate,
                    userFormService) {

                    this.loading = 0;
                    var self = this;

                    this.isLoading = function() { return self.loading > 0; };

                    this.printTemplate = '/Scripts/app/user-form/components/user-form-bulk-view.print.html';

                    this.$onChanges = function(changes) {
                        if (changes.ccaSignUpId || changes.formId) {
                            self.loadFormData();
                        }
                        if (changes.calendarEventId || changes.formFieldTagId) {
                            self.loadFormTagData();
                        }
                        if (changes.teamId || changes.formFieldTagId) {
                            self.loadTeamFormTagData();
                        }
                    };

                    this.exportCustomData = function() {
                        if (self.ccaSignUpId && self.formId) {
                            $window.open(
                                `${$window.EveryBuddy.WebAPI}/CCaSignUp/GetCcaSignUpFormDatarCsv?formId=${self.formId}&ccaSignUpId=${self.ccaSignUpId}`,
                                '_blank'
                            );
                        }
                        if (self.calendarEventId && self.formFieldTagId) {
                            userFormService.getEventFormTagDataCsv(self.calendarEventId, self.formFieldTagId);
                        }
                        if (self.teamId && self.formFieldTagId) {
                            userFormService.getTeamFormTagDataCsv(self.teamId, self.formFieldTagId);
                        }

                    };

                    this.loadFormData = function() {
                        if (self.ccaSignUpId && self.formId) {
                            self.loading++;
                            userFormService.getCcaSignUpFormData(self.ccaSignUpId,
                                    self.formId)
                                .then(function(data) {
                                    self.bulkFormData = data;
                                    self.processFormData();
                                    self.loading--;
                                });
                        }
                    };

                    this.loadFormTagData = function() {
                        if (self.calendarEventId && self.formFieldTagId) {
                            self.loading++;
                            userFormService.getEventFormTagData(self.calendarEventId,
                                    self.formFieldTagId)
                                .then(function(data) {
                                    self.bulkFormData = data;
                                    self.processFormData();
                                    self.loading--;
                                });
                        }
                    };

                    this.loadTeamFormTagData = function() {
                        if (self.teamId && self.formFieldTagId) {
                            self.loading++;
                            userFormService.getTeamFormTagData(self.teamId,
                                    self.formFieldTagId)
                                .then(function(data) {
                                    self.bulkFormData = data;
                                    self.processFormData();
                                    self.loading--;
                                });
                        }
                    };

                    this.processFormData = function() {
                        for (var p = 0; p < self.bulkFormData.completedData.length; p++) {
                            var capturedData = self.bulkFormData.completedData[p].capturedData;

                            if (capturedData.length > 0) {
                                for (var i = 0; i < self.bulkFormData.fields.length; i++) {
                                    var aField = self.bulkFormData.fields[i];
                                    var fieldId = aField.organisationFormFieldId;
                                    if (aField.fieldType === 4) {
                                        for (var j = 0; j < capturedData.length; j++) {
                                            var captured = capturedData[j];
                                            if (fieldId === captured.organisationFormFieldId) {
                                                captured.userData = (captured.userData === 'True');
                                            }
                                        }
                                    } else if (aField.fieldType === 3) {
                                        for (var j = 0; j < capturedData.length; j++) {
                                            var capturedDate = capturedData[j];

                                            if (fieldId === capturedDate.organisationFormFieldId &&
                                                capturedDate.userData !== null &&
                                                capturedDate.userData.length > 10) {
                                                capturedDate.userData =
                                                    new Date(capturedDate.userData.substring(0, 10))
                                                    .toDateString();
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };

                    this.fieldType = [
                        { id: 0, name: 'text' },
                        { id: 1, name: 'text' },
                        { id: 2, name: 'nmber' },
                        { id: 3, name: 'date' },
                        { id: 4, name: 'checkbox' },
                        { id: 5, name: 'email' },
                        { id: 6, name: 'textarea' },
                        { id: 7, name: 'select' }
                    ];
                }
            ]
        });
