
angular.module('profile.components.permissionToWalk', [
    'person.services',
    'shared.components.sbIcon',
    'shared.components.sbTitleBar',
    'shared.components.sbEditField',
    'shared.directives.sbCheckBox',
    'transport.services.transportPermissionToWalkService',
])
    .component('permissionToWalk',
        {
            bindings: {

            },
            templateUrl: '/Scripts/app/profile/components/permission-to-walk.template.html',
            controller: class PermissionToWalkCtrl {

                // Dependencies
                transportPermissionToWalkService: any;

                // Bindings

                // Variables
                students: any[];
                history: any[];
                historyPage: number = 1;
                loading: number = 0;
                isSaving: boolean = false;
                isSubmitted: boolean = false;

                static $inject = ['transportPermissionToWalkService'];

                constructor (transportPermissionToWalkService) {
                    this.transportPermissionToWalkService = transportPermissionToWalkService;
                }

                $onInit() {
                    this.getStudentWalkPermissions();
                }

                getStudentWalkPermissions() {
                    this.loading += 2;
                    this.transportPermissionToWalkService.getStudentWalkPermissions()
                    .then(res => {
                        this.students = res.data;
                    })
                    .finally(() => {
                        this.loading --;
                    });
                    this.transportPermissionToWalkService.getStudentWalkHistory()
                    .then(res => {
                        this.history = res.data;
                    })
                    .finally(() => {
                        this.loading --;
                    })
                };

                updateStudentWalkPermissions() {
                    this.isSaving = true;
                    this.transportPermissionToWalkService.updateStudentWalkPermissions(this.students)
                        .catch(() => {
                            this.getStudentWalkPermissions();
                        })
                        .finally(() => {
                            this.isSubmitted = false;
                            this.isSaving = false;
                        })
                }
            }

        });
