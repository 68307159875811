'use strict';

angular.module('ccasignupModule').controller('ccaDashboardController', [
    '$scope',
    'menuItems',
    '$state',
    function ($scope, menuItems, $state) {
        
        var gotoDefaultMenuItemRoute = function (menuItems) {
            if ($state.params.moduleSignUpTypeId) {
                menuItems.forEach(function(item, index) {
                    if (item.params.moduleSignUpTypeId == $state.params.moduleSignUpTypeId) {
                        $state.go(menuItems[index].uisref, menuItems[index].params);
                    }
                });
            } else if (menuItems && menuItems[0] && menuItems[0].uisref) {
                $state.go(menuItems[0].uisref, menuItems[0].params);
            } else {
                $state.go(".signUpNotFound");
            }
        }

        this.$onInit = function () {

            $scope.menuItems = menuItems;

            gotoDefaultMenuItemRoute(menuItems);

        }.bind(this);
    }
]);