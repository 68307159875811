<!-- <div class="date-range-picker-upgrade-container"> -->
<ng1-sb-date-range-picker [customOptions]="customOptions"
                                           [isRequired]="isRequired"
                                           [dateRangeList]="dateRangeList"
                                           [disabled]="disabled"
                                           [minDate]="minDate"
                                           [maxDate]="maxDate"
                                           [startDate]="startDate"
                                           [endDate]="endDate"
                                           [singleDatePicker]="singleDatePicker"
                                           [timePicker]="timePicker"
                                           [autoUpdate]="autoUpdate"
                                           (onChange)="change($event)"></ng1-sb-date-range-picker>
<!-- </div> -->
<sb-time-badge *ngIf="timePicker"
    [startDate]="startDate"
    [endDate]="singleDatePicker? null : endDate"
    [isInput]="true"
    class="mt-2"></sb-time-badge>
