import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MomentOrDate } from '@sb-helpers';
import { DateRangeList } from '@sb-shared/constants/shared.constant';
import { Ng1DatePickerChange } from '@sb-shared/models/events/ng1-date-range-picker-change';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { addMonths, subMonths } from 'date-fns';

@Component({
  selector: 'sb-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  @Input() customOptions: { autoApply: boolean, singleDatePicker: boolean, alwaysShowCalendars?: boolean };
  @Input() isRequired: boolean;
  @Input() dateRangeList: DateRangeList;
  @Input() disabled: boolean;
  @Input() minDate: MomentOrDate;
  @Input() maxDate: MomentOrDate;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() singleDatePicker: boolean;
  @Input() timePicker: boolean;
  @Input() autoUpdate: boolean;
  @Output() onChange: EventEmitter<Ng1DatePickerChange> = new EventEmitter();

  constructor(private dateTime: DateTimeService) { }

  ngOnInit(): void {
    // Default e if no range set
    if (!this.startDate) {
      this.startDate = subMonths(Date.now(), -2);
    }
    if (!this.endDate && !this.singleDatePicker) {
      this.endDate = addMonths(Date.now(), 3);
    }

    this.change({ ...this });
  }

  change({ startDate, endDate }: Ng1DatePickerChange) {
    [startDate, endDate] = [startDate, endDate].map(date => this.dateTime.removeSeconds(date));

    this.onChange.emit({ startDate, endDate });
  }

}
