import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { CurrentLangService } from '@sb-shared/services/current-lang.service';
import * as angular from 'angular';
import { AppComponent } from '../app.component';
import { Ng1AuthService } from '../ng1-services/ng1-auth.service';
import { Ng1PrinceService } from './../ng1-services/ng1-prince.service';
import { Ng1StateService } from './ng1-services/ng1-state.service';
import { Ng1ToastService } from './ng1-services/ng1-toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ForbiddenErrorService } from '@sb-shared/services/forbidden-error.service';

export const ng1AppModule = angular
  .module('ng1App', [
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'shared.directives.cloakTranslate',
    'ui.grid',
    'ui.tinymce',
    // Might want to load these separately later for performance if possible
    'dashboardModule',
    'settingsModule',
    'portalModule',
    'diaryModule',
    'ccaavailableModule',
    'ccasignupModule',
    'balanceAdminModule',
    'balanceModule',
    'transportModule',
    'profileModule',
    'receptionModule',
    'sysAdminModule',
    'messagingModule',
    'tuitionFeesModule',
    'paymentsModule',
    'shared.services.moduleSetupService'
  ])

  .config([
    '$translateProvider',
    '$httpProvider',
    'tmhDynamicLocaleProvider',
    '$locationProvider',
    'moduleSetupServiceProvider',
    function (
      $translateProvider,
      $httpProvider,
      tmhDynamicLocaleProvider,
      $locationProvider,
      moduleSetupServiceProvider
    ) {
      // Needed to add this for the Angular 12 routing to work properly
      $locationProvider.html5Mode({ enabled: true, requireBase: false });

      tmhDynamicLocaleProvider.localeLocationPattern('/assets/scripts/i18n/angular-locale_{{locale}}.js');

      // should prevent scripting without encoding the text
      $translateProvider.useSanitizeValueStrategy('escape');

      // Handle translations in one place
      const moduleSetupService = moduleSetupServiceProvider.$get();
      moduleSetupService.setupTranslations($translateProvider);

      // Set-up various angularjs http interceptors.
      moduleSetupService.setupHttpProvider($httpProvider, window.EveryBuddy.Version);
    }
  ])
  .directive(
    AppComponent.selector,
    downgradeComponent({
      component: AppComponent
    })
  )
  .factory('$state', downgradeInjectable(Ng1StateService) as any)
  .factory(
    '$transitions',
    class Ng1Transitions {
      onBefore() {
        // Do nothing
      }
    }
  )
  // Override ngToast use with our ToastService (uses ngBootstrap)
  .factory('ngToast', downgradeInjectable(Ng1ToastService) as any)
  .factory('currentLangService', downgradeInjectable(CurrentLangService) as any)
  .factory('translateService', downgradeInjectable(TranslateService) as any)
  .factory(
    '$transitions',
    class Ng1Transitions {
      onBefore() {
        // Do nothing
      }
    }
  )
  .factory('ng1PrinceService', downgradeInjectable(Ng1PrinceService) as any)
  .factory('ng1AuthService', downgradeInjectable(Ng1AuthService) as any)
  .factory('forbiddenErrorService', downgradeInjectable(ForbiddenErrorService) as any)
  .service(
    '$stateParams',
    class StateParams {
      $state: any;

      static $inject = ['$state'];
      constructor($state) {
        this.$state = $state;
      }
      get() {
        return JSON.parse(localStorage.getItem('ng1State'));
      }
    }
  )
  .service(
    '$transition$',
    class Transition {
      $state: any;
      static $inject = ['$state'];
      constructor($state) {
        this.$state = $state;
      }
      params() {
        return this.$state.params;
      }
    }
  )
  .component('ng1SbDateRangePicker', {
    bindings: {
      customOptions: '<',
      isRequired: '<',
      dateRangeList: '<',
      disabled: '<',
      minDate: '<',
      maxDate: '<',
      startDate: '<',
      endDate: '<',
      singleDatePicker: '<',
      timePicker: '<',
      autoUpdate: '<',
      onChange: '&'
    },
    template: `<sb-date-range-picker custom-options="$ctrl.customOptions"
                                       is-required="$ctrl.isRequired"
                                       date-range-list="$ctrl.dateRangeList"
                                       disabled="$ctrl.disabled"
                                       min-date="$ctrl.minDate"
                                       max-date="$ctrl.maxDate"
                                       start-date="$ctrl.startDate"
                                       end-date="$ctrl.endDate"
                                       single-date-picker="$ctrl.singleDatePicker"
                                       time-picker="$ctrl.timePicker"
                                       auto-update="$ctrl.autoUpdate"
                                       on-change="$ctrl.emitChange()"></sb-date-range-picker>`,
    controller: class SbButtonCtrl {
      onChange: any;
      startDate: any;
      endDate: any;

      emitChange() {
        this.onChange({
          startDate: this.startDate,
          endDate: this.endDate
        });
      }
    }
  })
  .component('ng1SbMultiPersonPicker', {
    bindings: {
      defaultClubId: '<',
      defaultTeamId: '<',
      selectedPersonIds: '<',
      selectedPersonIdsChange: '&',
      includeStaff: '=',
      includePupils: '=',
      selectedPersons: '<',

      // parameters for extra ok button to be shown- good if this is being used in a popup window
      showOkButton: '<',
      cancelButtonCallback: '&',
      okButtonCallback: '&',
      okButtonText: '@',
      hideGroupFilter: '=',
      hideYearFilter: '=',
      hideGenderFilter: '=',
      hideRemove: '=',
      shownFromRegister: '=',
      shownFromStaffRoles: '=',
      filterOutExternalStaff: '=',
      isMassStudentAssignmentCopyFromMode: '=',
      selectedTimeSlotId: '<',
      selectedTimetableId: '<',
      selectedTimetableRouteId: '<',
      timetableIdToCopyFrom: '=',
      timetableRouteIdToCopyFrom: '=',
      selectedTimetableCalendarName: '=',
      isLoadingComplete: '=',

      overridePersonLookupService: '=',
      selectedGroupId: '=',
      onLoadingStateChanged: '&',

      lockedPersonId: '<'
    },
    template: `<multi-person-picker selected-person-ids="$ctrl.selectedPersonIds"
                                      selected-person-ids-change="$ctrl.selectedPersonIdsChange(value)"
                                          default-club-id="$ctrl.defaultClubId"
                                          default-team-id="$ctrl.defaultTeamId"
                                          include-staff="$ctrl.includeStaff"
                                          include-pupils="$ctrl.includePupils"
                                          hide-group-filter = "$ctrl.hideGroupFilter"
                                          hide-year-filter = "$ctrl.hideYearFilter"
                                          hide-gender-filter = "$ctrl.hideGenderFilter"
                                          hide-remove = "$ctrl.hideRemove"
                                          shown-from-register = "$ctrl.shownFromRegister"
                                          shown-from-staff-roles = "$ctrl.shownFromStaffRoles"
                                          filter-out-external-staff="$ctrl.filterOutExternalStaff"
                                          override-person-lookup-service="$ctrl.overridePersonLookupService"
                                          selected-group-id="$ctrl.selectedGroupId"
                                          on-loading-state-changed="$ctrl.onLoadingStateChanged"
                                          is-mass-student-assignment-copy-from-mode="$ctrl.isMassStudentAssignmentCopyFromMode"
                                          selected-time-slot-id="$ctrl.selectedTimeSlotId"
                                          selected-timetable-id="$ctrl.selectedTimetableId"
                                          selected-timetable-route-id="$ctrl.selectedTimetableRouteId"
                                          timetable-id-to-copy-from="$ctrl.timetableIdToCopyFrom"
                                          timetable-route-id-to-copy-from="$ctrl.timetableRouteIdToCopyFrom"
                                          cancel-button-callback="$ctrl.cancel()"
                                          ok-button-callback="$ctrl.ok()"
                                          ok-button-text="{{okButtonText}}"
                                          show-ok-button="$ctrl.showOkButton"
                                          locked-person-id="$ctrl.lockedPersonId">
                                      </multi-person-picker>`
  })
  .component('ng1SbTeamClubPickerSummary', {
    bindings: {
      teamIds: '<',
      clubIds: '<',
      subjectClassIds: '<',
      showClubs: '<',
      showTeams: '<',
      showSubjectClasses: '<',
      nothingSelectedLabel: '@',
      onChangeModel: '&'
    },
    template: `<team-club-picker-summary team-ids="$ctrl.teamIds"
                                            club-ids="$ctrl.clubIds"
                                            subject-class-ids="$ctrl.subjectClassIds"
                                            show-clubs="$ctrl.showClubs"
                                            show-teams="$ctrl.showTeams"
                                            show-subject-classes="$ctrl.showSubjectClasses"
                                            nothing-selected-label="{{$ctrl.nothingSelectedLabel}}"
                                            on-change-model="$ctrl.onChangeModel()"></team-club-picker-summary>`
  })
  .component('ng1SbTeamClubPicker', {
    bindings: {
      teamIds: '=',
      clubIds: '=',
      subjectClassIds: '=',
      subjectsEnabled: '<', // Changed as don't think this needs to be two-way

      excludedTeamIds: '=',
      excludedClubIds: '=',
      excludedSubjectClassIds: '=',
      onChangeModel: '&'
    },
    template: `<team-club-picker club-ids="$ctrl.clubIds"
                                      team-ids="$ctrl.teamIds"
                                      subject-class-ids="$ctrl.subjectClassIds"
                                      subjects-enabled="$ctrl.subjectsEnabled"
                                      excluded-team-ids="$ctrl.excludedTeamIds"
                                      excluded-club-ids="$ctrl.excludedClubIds"
                                      excluded-subject-class-ids="$ctrl.excludedSubjectClassIds"
                                      on-change-model="$ctrl.onChangeModel()"></team-club-picker>`
  });
