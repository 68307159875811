angular.module('tuitionFeesModule')
    .component('invoiceDetailViewSend', {
        bindings: {
            invoice: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-send.template.html',
        controller: class InvoiceDetailViewSendCtrl {

            // bindings
            invoice: any;

            // Dependencies
            $filter: any;
            invoiceService: any;

            // variables
            otherContactId: string;

            sendOptions: any[] = [
                {
                    name: 'SB_TuitionFee_InvoiceSend_Type_Invoice',
                    key: 'sendInvoice'
                },
                {
                    name: 'SB_TuitionFee_InvoiceSend_Type_Receipts',
                    key: 'sendReceipts',
                    invoiceField: 'invoicePayments'
                },
                {
                    name: 'SB_TuitionFee_InvoiceSend_Type_CreditNotes',
                    key: 'sendCreditNotes',
                    invoiceField: 'invoiceCreditNotes'
                },
            ];

            data: any = {
                invoiceId: null,
                title: '',
                contactIds: [],
                emailAddresses: [''],
                note: '',
                sendInvoice: true,
                sendReceipts: false,
                sendCreditNotes: false
            }
            noteMaxLength: number = 200;
            isSubmitted: boolean = false;
            recipientOptions: any[];
            selectedRecipients: any[];
            multiSelectSettings: any = {
                smartButtonMaxItems: 3,
                buttonClasses: 'p-2'
            }

            static $inject = ['$filter', 'invoiceService'];

            constructor($filter, invoiceService) {
                this.$filter = $filter;
                this.invoiceService = invoiceService;
                this.otherContactId = 'other';
            }

            $onChanges(changes) {
                if (changes.invoice?.currentValue) {
                    this.data.invoiceId = this.invoice.id;
                    this.data.title = this.$filter('translate')('SB_TuitionFee_InvoiceSend_Subject_X', { number: this.invoice.invoiceNumber });
                    this.recipientOptions = [...this.invoice.contacts, {
                        label: this.$filter('translate')('SB_TuitionFee_InvoiceSend_Other_Address'),
                        id: this.otherContactId
                    }];
                    var billableRecipients = this.recipientOptions.find(contact => contact.isBilling);
                    this.selectedRecipients = billableRecipients ? [billableRecipients] : [];

                    this.sendOptions.forEach(x => {
                        if (x.invoiceField && this.invoice[x.invoiceField]) {
                            x.disabled = this.invoice[x.invoiceField].length == 0;
                        }
                    });
                }
            }

            addRecipient() {
                this.data.emailAddresses.push('');
            }

            deleteRecipient(index: number) {
                if (this.data.emailAddresses?.length <= 1) {
                    this.data.emailAddresses = [''];
                    this.clearOtherRecipients();
                }
                else {
                    this.data.emailAddresses.splice(index, 1);
                }


            }

            clearOtherRecipients() {
                if (this.data.emailAddresses?.length <= 1) {
                    this.selectedRecipients = this.selectedRecipients?.filter(r => r.id !== this.otherContactId);
                }
            }

            send() {
                let excludeOtherContact = (recipient) => {
                    return recipient?.id && recipient.id !== this.otherContactId
                }

                let excludeZeroLengthEmailAddress = (recipient) => {
                    return recipient?.length > 0;
                }

                const data = {
                    ...this.data,
                    contactIds: this.selectedRecipients?.filter(excludeOtherContact).map(recipient => recipient.id),
                    emailAddresses: this.showEmailAddressInputs()
                        ? this.data.emailAddresses.filter(excludeZeroLengthEmailAddress).map(recipient => recipient)
                        : []
                }

                this.invoiceService.sendInvoice(data.invoiceId, data)
                    .finally(() => {
                        this.isSubmitted = false;
                    })
            }

            showEmailAddressInputs() {
                return this.selectedRecipients?.find(recipient => recipient?.id && recipient.id === this.otherContactId);
            }

            hasRecipients() {
                return this.selectedRecipients?.find(address => address?.id && address.id !== this.otherContactId) || (this.showEmailAddressInputs() && this.data.emailAddresses?.find(address => address));
            }

        }
    });
