'use strict';

angular.module('cca.components.ccaGroupArchive',
    [
        'ccaSignUp.services.signUpRepository',
        'pascalprecht.translate',
        'shared.components.sbIcon',
        'shared.services.simpleAlertService'
    ])
    .component('ccaGroupArchive',
        {
            templateUrl: '/Scripts/app/cca/components/cca-group-archive.template.html',
            bindings: {
                signUpId: '<'
            },
            controller: [
                '$filter',
                'dateTextService',
                'simpleAlertService',
                'signUpRepository',
                'teamStatusEnum',
                function (
                    $filter,
                    dateTextService,
                    simpleAlertService,
                    signUpRepository,
                    teamStatusEnum) {

                    this.filter = $filter;
                    this.archiveAllChecked = false;

                    this.$onChanges = function (changes) {
                        if (changes.signUpId.currentValue) {

                            signUpRepository.getSignUp(this.signUpId)
                                .then(function (signUp) {
                                    this.signUp = signUp;
                                    this.signUp.periodTo = new Date(signUp.periodTo);
                                }.bind(this));

                            signUpRepository
                                .getSignUpGroups(this.signUpId)
                                .then(function (signUpGroups) {
                                    this.groups = signUpGroups;
                                    angular.forEach(this.groups, function (value, key) {
                                        if (value.statusId === teamStatusEnum.Archive) {
                                            value.checked = true;
                                        }
                                        else {
                                            value.checked = false;
                                        }

                                        const lastEventDate = new Date(value.lastEventDateUtc);
                                        if(lastEventDate>this.periodTo) this.periodTo=lastEventDate;

                                        saveCleanCopy();
                                    }, this.signUp);

                                    this.archiveAllChecked = this.groups.every(group => group.checked);
                                }.bind(this));
                        }
                    }.bind(this);

                    this.today = new Date();

                    this.teamStatusEnum = teamStatusEnum;



                    var cleanCopy = {};
                    var saveCleanCopy = function () {
                        cleanCopy = {};

                        for (var i = this.groups.length; i--;) {
                            cleanCopy['g' + this.groups[i].id] = this.groups[i].checked;
                        }
                    }.bind(this);

                    this.confirmArchive = function () {

                        // find out whats changed

                        var groupArchiveStates = [];
                        var archiveCount = 0;
                        var unarchiveCount = 0;

                        for (var i = this.groups.length; i--;) {
                            var thisGroup = this.groups[i];
                            if (cleanCopy['g' + thisGroup.id] !== thisGroup.checked) {
                                // a change is afoot!
                                groupArchiveStates.push({
                                    groupId: thisGroup.id,
                                    archived: thisGroup.checked
                                });
                                if (thisGroup.checked) {
                                    archiveCount++;
                                }
                                else {
                                    unarchiveCount++;
                                }
                            }
                        }

                        var changes = {
                            signUpId: this.signUpId,
                            groupArchiveStates: groupArchiveStates
                        };

                        var message = '';
                        var xValue = 0, yValue = 0;

                        if (archiveCount > 0 && unarchiveCount > 0) {
                            xValue = archiveCount;
                            yValue = unarchiveCount;

                            if (archiveCount > 1 && unarchiveCount > 1) {
                                message = 'SB_Confirm_Archive_X_Groups_Unarchive_Y_Groups';
                            }
                            else if (archiveCount > 1) {
                                message = 'SB_Confirm_Archive_X_Groups_Unarchive_Y_Group';
                            }
                            else if (unarchiveCount > 1) {
                                message = 'SB_Confirm_Archive_X_Group_Unarchive_Y_Groups';
                            }
                            else {
                                message = 'SB_Confirm_Archive_X_Group_Unarchive_Y_Group';
                            }
                        }
                        else if (archiveCount > 0) {
                            xValue = archiveCount;

                            if (archiveCount > 1) {
                                message = 'SB_Confirm_Archive_X_Groups';
                            }
                            else {
                                message = 'SB_Confirm_Archive_X_Group';
                            }
                        }
                        else if (unarchiveCount > 0) {
                            xValue = unarchiveCount;

                            if (unarchiveCount > 1) {
                                message = 'SB_Confirm_Unarchive_X_Groups';
                            }
                            else {
                                message = 'SB_Confirm_Unarchive_X_Group';
                            }
                        }
                        else {
                            // no changes here
                            message = 'SB_No_Changes_Made';
                            return;
                        }

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Confirm',
                            message: message,
                            messageTranslationParameters: { x: xValue, y: yValue },
                            windowSize: 'sm',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel'
                        });

                        modalInstance.result
                            .then(function () {
                                console.log('saving archive changes');

                                signUpRepository.postArchiveSignUpChanges(changes)
                                    .then(function (data) {

                                        if (data.isError) {
                                            console.error('isError flag set on response payload', data);
                                            simpleAlertService.errorAlert();
                                            return;
                                        }
                                        else {

                                            // update the groups with their new states
                                            for (var i = this.groups.length; i--;) {
                                                var thisGroup = this.groups[i];

                                                // find this group in the response data set
                                                for (var j = data.groupArchiveStates.length; j--;) {
                                                    if (data.groupArchiveStates[j].groupId === thisGroup.id) {

                                                        thisGroup.statusId = data.groupArchiveStates[j].archived
                                                            ? teamStatusEnum.Archive
                                                            : teamStatusEnum.Active;
                                                        thisGroup.checked = data.groupArchiveStates[j].archived;
                                                        thisGroup.lastUpdated = data.groupArchiveStates[j].lastStateChange;

                                                        break;
                                                    }
                                                }
                                            }
                                        }

                                        simpleAlertService.simpleAlert({
                                            title: 'SB_Saved',
                                            message: 'SB_Saved'
                                        });

                                        saveCleanCopy();
                                    }.bind(this))
                                    .catch(function (err) {
                                        console.error('problem reported by server during save', err);
                                        simpleAlertService.errorAlert();
                                    });
                            }.bind(this));
                    }.bind(this);

                    this.updateAll = function (archived) {
                        angular.forEach(this.groups,
                            function (value, key) {
                                value.checked = archived;
                            });
                    }.bind(this);

                    this.dateText = function (dayNumber)
                    {
                        return dateTextService.getShortDayText(dayNumber);
                    }
                }
            ]
        });
