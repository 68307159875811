<div
  class="text-warning bold"
  [innerHTML]="'SB_Capacity_Warning_Title' | translate"
></div>

<ol>
  <li class="text-info" *ngFor="let exceed of exceedCapacityByChangeRequests">
    <p
      [innerHTML]="
        'SB_Capacity_Warning_Message'
          | translate : getMessagesTranslationParams(exceed)
      "
    ></p>
    <ul>
      <li class="cr-detail" *ngFor="let item of exceed.relatedChangeRequests">
        <span
          [innerHTML]="
            'SB_Capacity_Warning_Detail'
              | translate : getDetailTranslationParams(item)
          "
        ></span
        ><span
          class="glyphicon glyphicon-info-sign"
          [ngbTooltip]="reasons"
          placement="end"
          triggers="click"
        ></span>
        <ng-template #reasons>
          <div class="reasons">
            <em *ngIf="item.reason">Reason(Request)</em>
            <div>{{ item.reason }}</div>
            <em *ngIf="item.responseReason">Reason(Response)</em>
            <div>{{ item.responseReason }}</div>
          </div>
        </ng-template>
      </li>
    </ul>
  </li>
</ol>

<p class="text-warning">{{ 'SB_Capacity_Warning_Check' | translate }}</p>
