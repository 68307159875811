'use strict';

angular.module('diary.transport.diaryTransportChangeRequestDetails',
    [
        'diary.transport.diaryTransportConfirmCancel',
        'shared.components.sbModalHeader',
        'shared.services.portalChangeRequestService',
        'shared.services.simpleAlertService',
    ])
    .component('diaryTransportChangeRequestDetails',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/diary/transport/diary-transport-change-request-details.template.html',
            controller: [
                '$timeout',
                '$uibModal',
                'portalChangeRequestService',
                'simpleAlertService',
                'deviceService',
                function ($timeout, $uibModal, portalChangeRequestService, simpleAlertService, deviceService) {
                    this.userControl = {};
                    this.isSubmitted = false;
                    this.diaryTransportChangeRequest = {};

                    var resetSubmitButtons = function () {
                        $timeout(function () {
                            this.isSubmitted = false;
                        }.bind(this), 200);
                    }.bind(this);

                    var closeModal = function (updateDiaryView) {
                        this.modalInstance.close(updateDiaryView);
                    }.bind(this);

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.diaryTransportChangeRequest = changes.resolve.currentValue.diaryTransportChangeRequest;
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        var confirmCancelModal = $uibModal.open({
                            animation: true,
                            component: 'diaryTransportConfirmCancel',
                            size: 'sm',
                            backdrop: 'static',
                            resolve:
                            {
                                changeRequest: function ()
                                {
                                    return this.diaryTransportChangeRequest;
                                }.bind(this)
                            }
                        });

                        confirmCancelModal.result.then(function () {

                            cancelConfirmed(this.diaryTransportChangeRequest);

                        }.bind(this), function ()
                        {
                            resetSubmitButtons();
                        }.bind(this));

                    }.bind(this);

                    var cancelConfirmed = function (diaryTransportChangeRequest){
                        var cancelChangeRequest = {
                            changeRequestId: diaryTransportChangeRequest.id
                        }

                        portalChangeRequestService.cancelChangeRequest(cancelChangeRequest).then(function (responseData) {
                            if (responseData && responseData.referenceNumber) {

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Transport_Change_Request_Cancelled_Confirmation_Title',
                                    message: 'SB_Transport_Change_Request_Cancelled_Confirmation_Message',
                                    messageTranslationParameters: { referenceNumber: responseData.referenceNumber },
                                    staticBackdrop: true
                                });

                                closeModal(true);
                                deviceService.triggerModalCloseInMobileApp();
                            }
                            else
                            {
                                simpleAlertService.errorAlert();
                                resetSubmitButtons();
                            }
                        })
                        .catch(function (responseData) {

                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message) {
                                message = { message: responseData.data.Message };
                            }

                            simpleAlertService.errorAlert(message);

                            resetSubmitButtons();
                        });
                    }

                    this.close = function () {
                        this.modalInstance.close('cancel');
                        deviceService.triggerModalCloseInMobileApp();
                    };
                }
            ]
        });
