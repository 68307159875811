import { UiClasses } from './../types/ui-classes';
import { Injectable } from '@angular/core';
import { UiClassValues } from '@sb-shared/globals/ui-class-values';

@Injectable({
  providedIn: 'root'
})
export class CssClassService {
  
  getCssClass(classId: number): UiClasses {
    return UiClassValues[classId] || '';
  }
}
