import { Component, Input } from '@angular/core';

@Component({
  selector: 'sb-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {

  @Input() date: Date;

}
