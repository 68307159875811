angular.module('shared.services.stringService', [])
    .service('stringService', class StringService {
    
        containsAlphanumeric(string: string) {
            const letterNumber = /^[0-9a-zA-Z]+$/;
            return string.length > 0 && string.split('').filter(char => {return char.match(letterNumber)}).length > 0;
        }

        validateUrl(url: string) {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
          return !!pattern.test(url);
        }

        shortenUrl(url: string) {
            if (!url) {
                return '';
            }
            return url
                .replace('https://www.', '')
                .replace('http://www.', '')
                .replace('www.', '')
        }
            
    })