angular.module('shared.components.subMenu', [
    'pascalprecht.translate',
    'duScroll',
    'shared.services.portalService'
])
    .component('subMenu', {

        templateUrl: '/Scripts/app/shared/components/sub-menu.template.html',
        bindings: {
            menuItems: '<',
            forceShow: '<',
            isSideMenu: '<'
        },
        controllerAs: '$ctrl',
        controller: class SubMenuCtrl {

            // Dependencies
            $state: any;
            $timeout: any;
            $translate: any;
            cachedLookupService: any;
            portalService: any;

            //Bindings
            menuItems: Array<any>;
            forceShow: boolean;
            isSideMenu: boolean;

            // Variables
            isActiveElementFound: boolean = false;
            showModules: boolean = false;
            showBack: boolean = false;
            moduleItems: any[];

            static $inject = ['$state', '$timeout', '$translate', 'cachedLookupService', 'portalService'];

            constructor($state, $timeout, $translate, cachedLookupService, portalService) {
                this.$state = $state;
                this.$timeout = $timeout;
                this.$translate = $translate;
                this.cachedLookupService = cachedLookupService;
                this.portalService = portalService;
            }

            $onInit() {
                // Move content if isSideMenu or undefined
                if (this.isSideMenu === false) {
                    return;
                }
                this.cachedLookupService.isStaff()
                .then(isStaff => {
                    this.$timeout(() => {
                        if (isStaff) {
                            this.showBack = true;
                            // Move main menu to sidemenu, and amend elements as they would be post-Angular 12
                            const moduleList = angular.element(document.getElementById('mainMenu'))[0]
                            const filteredMenuItems = moduleList ? Array.from(moduleList.children).filter((menuItem: any) => {
                                return menuItem.className === '' || menuItem.className === 'active';
                            }) : [];
                            this.moduleItems = filteredMenuItems.map((li: any) => {
                                const angularLi = angular.element(li);
                                return {
                                    isActive: Array.from(li.classList).includes('active'),
                                    title: angularLi.find('span').attr('ng-bind-html')?.substring(1).replace("' | translate", ""),
                                    iconClass: angularLi.find('i').attr('class'),
                                    href: angularLi.find('a').attr('href')
                                }
                            })
                            this.checkModules();
                        }
                    }, 100);
                });
            }

            $onChanges(changes) {
                if (changes?.menuItems?.currentValue) {
                    this.checkModules();
                }
            }

            checkModules() {
                if (this.isSideMenu === false) {
                    return;
                }
                this.$timeout(() => {
                    this.showModules = !this.menuItems || this.menuItems.length < 2;
                })
            }

            onMenuItemInit () {
                if (!this.isActiveElementFound) {
                    this.$timeout(() => {
                        const nav: any = angular.element(document.getElementById('subMenuBarInner'));
                        const selectedElement = angular.element(document.getElementsByClassName('buddy-nav__item--active'));
                        if (selectedElement.prop('offsetLeft') > 0) {
                            nav.scrollLeft(selectedElement.prop('offsetLeft') - 100, 0).catch(error => {});
                            this.isActiveElementFound = true;
                        }
                    });
                }
            };

            menuClick(item) {
                if (typeof (item.onClick) === 'function') {
                    item.onClick();
                }
            };

            isSelected(routeName)
            {
                // Remove any preceding hat (ui-router absolute routing)
                // as angular does not like this when checking state below.
                if (routeName && routeName[0] === '^')
                {
                    routeName = routeName.substr(1);
                }

                return this.$state.includes('**' + routeName + '.**') || this.$state.includes('**' + routeName);
            };

            getIcon(item) {
                if (item.uisref && this.isSelected(item.uisref)) {
                    return item.icon.replace('far', 'fas').replace('fal', 'fas');
                }
                return item.icon.replace('fas', 'fal').replace('fas', 'fal');
            };

            modulesClass() {
                return (this.showModules ? 'visible-hover' : 'hidden') + (this.menuItems?.length > 1 ? ' buddy-nav-open' : '');
            }

            openModules() {
                this.showModules = true;
                document.getElementById('subMenuBarInnerModules').focus();
            }

            closeModules() {
                this.checkModules();
            }
        }
    });
