'use strict';

angular.module('transport.configuration.transportMethods',
    [
        'transport.configuration.transportMethodEdit',
        'transport.services.transportConfigurationMethodService',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportMethods',
        {
            bindings: {},
            templateUrl: '/Scripts/app/transport/configuration/methods/transport-methods.template.html',
            controller: [
                'transportConfigurationMethodService',
                'simpleAlertService',
                '$uibModal',
                function transportMethodsController(transportConfigurationMethodService, simpleAlertService, $uibModal) {

                    this.$onInit = function ()
                    {
                        this.loadMethods();
                    }.bind(this);

                    this.loadMethods = function () {
                        this.transportMethods = null;

                        transportConfigurationMethodService.getMethods().then(function (response)
                        {
                            this.transportMethods = response;
                        }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                            }.bind(this));
                    }

                    this.add = function () {
                        var addModalInstance = $uibModal.open({
                            animation: true,
                            component: 'transportMethodAdd',
                            size: 'lg',
                            backdrop: 'static'
                        });

                        addModalInstance.result.then(
                            () => this.loadMethods(),
                            () => {}
                        );
                    };

                    this.editMethod = function (method)
                    {
                        var editModalInstance = $uibModal.open({
                            animation: true,
                            component: 'transportMethodEdit',
                            size: 'lg',
                            backdrop: 'static',
                            resolve:
                            {
                                params: function ()
                                {
                                    return { transportMethod: method };
                                }
                            }
                        });

                        editModalInstance.result.then(
                            () => this.loadMethods(),
                            () => {}
                        );
                    };

                    this.statusText = function (status)
                    {
                        return status ? 'SB_Yes' : 'SB_No';
                    }
                }
            ]
        });
