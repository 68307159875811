angular.module('payments.services.paymentsService', [])
    .service('paymentsService', class PaymentsService {
        $http: any;
        urlBase: string;

        static $inject = ['$http'];

        constructor($http) {
            this.$http = $http;
            this.urlBase = '/webapi/WebPayments/';
        }

        createPaymentSession = function (request) {

            return this.$http.post(this.urlBase + 'CreatePaymentSession', request)
                .then(response => {
                    return response.data;
                });
        };

        cancelPaymentSession = function (merchantReference) {
            return this.$http.get(this.urlBase + 'CancelPaymentSession',
                {
                    params: { 'merchantReference': merchantReference }
                })
                .then(response => {
                    return response.data;
                });
        };

        chargeProviderWithToken = function (token, merchantReference) {
            return this.$http.post(this.urlBase + 'ChargeProviderWithToken',
                {
                    token: token,
                    merchantReference: merchantReference
                })
                .then(response => {
                    return response.data;
                });
        };

        submitPaymentProviderlessTransaction = function (request) {

            return this.$http.post(this.urlBase + 'SubmitPaymentProviderlessTransaction', request)
                .then(response => {
                    return response.data;
                });
        };

        getPaymentReceiptByMerchantReference = function (merchantReference) {

            return this.$http.get(this.urlBase + 'GetReceiptByMerchantReference',
                {
                    params: { 'merchantReference': merchantReference }
                })
                .then(response => {
                    return response.data;
                });
        };

        getPaymentReceiptByTransactions = function (items) {

            return this.$http.post(this.urlBase + 'GetReceiptByTransactions',
                {
                    items: items
                })
                .then(response => {
                    return response.data;
                });
        };

        getPaymentSessionResult = function (paymentProvider, sessionId) {
            return this.$http.get(this.urlBase + 'GetPaymentSessionResult',
                {
                    params: {
                        'paymentProvider': paymentProvider,
                        'sessionId': sessionId
                    }
                })
                .then(response => {
                    return response.data;
                });
        }

        getPaymentProvidersConfigForOrganisation = () => {

            return this.$http.get(this.urlBase + 'PaymentProvidersConfigForOrganisation')
                .then(response => {
                    return response.data;
                });
        };

        organisationHasPaymentProviders = () => {
            return this.$http.get(this.urlBase + 'OrganisationHasPaymentProviders')
                .then(response => {
                    return response;
                });
        };

    });
