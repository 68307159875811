angular.module('shared.components.sbIconImg', [])
    .component('sbIconImg',
        {
            bindings: {
                type: '@',
                category: '@',
                name: '@',
                url: '@',
                fullUrl: '@',
                format: '<'
            },
            templateUrl: '/Scripts/app/shared/components/sb-icon-img.template.html',
            controller: class SbIconImgCtrl {

                // Bindings
                type: string;
                category: string;
                name: string;
                url: string;
                fullUrl: string;
                format: string;

                $window: any;
                imgUrl: string;

                types: any;

                static $inject = ['$window'];

                constructor($window) {
                    this.$window = $window;

                    this.types = {
                        Sebo: {
                            format: 'png'
                        }
                    }
                }

                $onInit() {
                    // Default to Sebo
                    const defaultType = this.types.Sebo;
                    const typeString = this.type || 'Sebo';
                    const selectedType = this.types[this.type] || defaultType;


                    // Get format specifically set, else get based on type
                    const format = this.format || selectedType.format;

                    // Generate url
                    const urlSlug = this.url || `${typeString}/${this.category ? this.category + '/' : ''}${this.name}.${format}`;
                    this.imgUrl = this.fullUrl || `${this.$window.EveryBuddy.CdnBase}img/icons/${urlSlug}`;
                }

            }
        });
