angular.module('tasks.components.taskEditor',
    [
        'tasks.services.taskService',
        'teams.services.teamService',
        'pascalprecht.translate',
        'ui.tinymce',
        'shared.services.simpleAlertService',
        'shared.services.tinymceConfigHelper',
        'shared.services.cachedLookupService',
        'shared.controllers.confirmationPopup',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'groups.services.groupService'
    ])
    .component('taskEditor',
    {
        bindings: {
            taskId: '<',
            teamName: '<',
            teamId: '<',
            onChange: '&',
            onCreate: '&',
            onGotoCaseClicked: '&'
        },
        templateUrl: '/Scripts/app/tasks/components/task-editor.template.html',
        controller: [
            '$uibModal',
            'taskService',
            'groupService',
            'tinymceConfigHelper',
            'teamService',
            'cachedLookupService',
            'simpleAlertService',
            function taskEditorController($uibModal, taskService, groupService, tinymceConfigHelper, teamService, cachedLookupService,simpleAlertService) {
                this.loading = 0;
                this.task = null;
                this.teams = [];
                this.staff = false;
                this.staffNames = {};
                this.boundToTeam = false;
                this.organisersLoading = false;
                this.currentPersonId = -1;
                this.isOrganisationAdmin = false;
                this.readOnly = true;

                var self = this;
                var initialLoad = true;

                this.isLoading = function () { return self.loading > 0; };



                this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                var checkReadOnlyStatus = function()
                {
                    if (self.task === null ||
                        self.isOrganisationAdmin ||
                        (self.task.id > 0 && self.currentPersonId === self.task.organiserId))
                    {
                        self.readOnly = false;
                    } else
                    {
                        self.readOnly = true;
                    }
                };

                cachedLookupService.currentPersonId()
                    .then(function(data)
                    {
                        self.currentPersonId = data;
                        checkReadOnlyStatus();
                    });

                cachedLookupService.isOrganisationAdmin()
                    .then(function(data)
                    {
                        self.isOrganisationAdmin = data;
                        checkReadOnlyStatus();
                    });

                //$cope.watch no longer supported- this is how we watch for changes (faster)
                this.$onChanges = function (changes) {
                    if (changes.taskId)
                    {
                        console.log('task editor- task id changed');
                        self.task = null;
                        self.loadTask();
                    }

                    if (changes.teamId)
                    {
                        if (changes.teamId.currentValue)
                        {
                            self.boundToTeam = true;
                            self.refreshStaff(self.teamId);
                        }
                    }

                    if (initialLoad)
                    {
                        initialLoad = false;

                        if (!self.boundToTeam)
                        {
                            // this isn't bound to a specific team so load all the teamId's
                            teamService.getAllForLookup(false)
                                .then(function(data)
                                {
                                    self.teams = data;
                                });
                        }
                    }
                };

                this.showGoToCaseButton = function()
                {
                    return self.onGotoCaseClicked && typeof self.onGotoCaseClicked === 'function';
                };

                this.goToCase = function()
                {
                    self.onGotoCaseClicked()(self.task);
                };

                this.loadTask = function () {
                    if (!self.taskId)
                    {
                        var middayToday = moment.utc().startOf('day').hour(12).toDate();

                        self.task = {
                            id : 0,
                            createDate : '',
                            title : '',
                            detail : '',
                            deadline: middayToday,
                            teamId: self.teamId
                        };
                    } else {
                        self.loading++;

                        taskService.getTaskById(self.taskId)
                            .then(function (data) {
                                if (!data.isError)
                                {
                                    self.task = data;
                                    self.task.deadline = moment.utc(self.task.deadline).toDate();
                                } else
                                {
                                    console.warn(data.errorData);
                                }
                                self.loading--;
                            });
                    }

                    checkReadOnlyStatus();
                };

                this.save = function () {
                    self.loading++;

                    self.task.organiserId = parseInt(self.task.organiserId, 10);

                    taskService.saveTask(self.task)
                        .then(function (data) {
                            self.task = data;
                            self.task.deadline = moment.utc(self.task.deadline).toDate();
                            self.loading--;

                            if (typeof self.onChange() === 'function') {
                                self.onChange()(self.task);
                            }
                        });
                };

                this.markCompleted = function()
                {
                    self.loading++;

                    taskService.markCompleted(self.task)
                        .then(function(data)
                        {
                            self.task = data;
                            self.loading--;

                            if (typeof self.onChange() === 'function')
                            {
                                self.onChange()(self.task);
                            }
                        });
                };

                this.markCompletedAndClone = function()
                {
                    self.loading++;

                    taskService.markCompleted(self.task)
                        .then(function (data) {
                            self.task = data;

                            if (typeof self.onChange() === 'function') {
                                self.onChange()(self.task);
                            }

                            var newTask = JSON.parse(JSON.stringify(self.task));
                            newTask.id = 0;
                            newTask.completed = false;
                            newTask.completedDate = undefined;

                            taskService.saveTask(newTask)
                                .then(function (data) {
                                    self.loading--;

                                    if (typeof self.onCreate() === 'function') {
                                        self.onCreate()(data);
                                    }
                                });

                        });
                };

                this.delete = function()
                {
                    var modalInstance = simpleAlertService.simpleAlert({
                        title: 'SB_Title_Delete_task',
                        message: 'SB_Confirmation_Delete_task',
                        okButtonText: 'SB_Yes',
                        cancelButtonText: 'SB_Cancel',
                        windowSize: 'md'
                    });

                    modalInstance
                        .result
                        .then(function()
                        {
                            self.loading++;
                            taskService.deleteTask(self.task.id)
                                .then(function(data)
                                {
                                    self.loading--;
                                    if (data)
                                    {
                                        self.task.isDeleted = true;
                                    }

                                    if (typeof self.onChange() === 'function')
                                    {
                                        self.onChange()(self.task);
                                    }
                                });
                        });
                };

                this.refreshStaff = function(teamId)
                {
                    self.staff = false;
                    self.organisersLoading = true;
                    groupService.getStaffInGroup(teamId, true)
                        .then(function(data)
                        {
                            if (!data.isError)
                            {
                                self.staff = data.admins;
                                self.staffNames = {};

                                for (var i = self.staff.length; i--;)
                                {
                                    self.staffNames[self.staff[i].id] = self.staff[i].name;
                                }
                                self.organisersLoading = false;
                            }
                        });
                };
            }
        ]
    });