angular.module('ccaSignUp.services.ccaPupilPreselectionService', [])
    .factory('ccaPupilPreselectionService', [
        '$http',
        '$q',
        '$cacheFactory', 
        '$uibModal',
        function ($http, $q, $cacheFactory, $uibModal)
        {
            var urlBase = '/webapi/WebCcaAdminPupilPreselection/';
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('ccaPupilPreselectionServiceCache');

            repository.clearCache = function () {

                repoCache.removeAll();

            };

            // interface for getPeopleFiltered needs to be consistent for the person picker- 
            // so we set the cca team here for use by that method only
            repository.ccaSignUpTeamId = -1;

            repository.getPeopleFiltered = function (searchOptions) {

                if (repository.ccaSignUpId < 0) {
                    console.error('ccaSignUpId has not been set in ccaPupilPreselectionService');
                    return;
                }
                
                return $http.post(urlBase + 'GetPeopleWithFiltersForCcaGroup',
                        {
                            searchCriteria: searchOptions.nameSearchCriteria,
                            pageSize: searchOptions.pageSize,
                            currentPage: searchOptions.currentPage,
                            schoolYearGroupId: searchOptions.schoolYearGroupId || 0 ,
                            schoolClassId: searchOptions.schoolClassId || 0 ,
                            memberOfTeamId: searchOptions.memberOfTeamId,
                            memberOfClubId: searchOptions.memberOfClubId,
                            genderTypeId: typeof searchOptions.genderTypeId === 'undefined' ? -1 : searchOptions.genderTypeId,
                            ccaTeamId: repository.ccaSignUpTeamId
                        })
                    .then(function(response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.updatePupilPreSelection = function (ccaSignUpId, ccaTeamId, pupilPreferencesArray) {

                var update = {
                    ccaSignUpId: ccaSignUpId,
                    ccaTeamId: ccaTeamId,
                    preferences: pupilPreferencesArray
                };

                return $http.post(urlBase + 'UpdatePupilPreSelectionForCcaTeam', update)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            return repository;
        }
    ]);