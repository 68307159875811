'use strict';

angular.module('calendarEventCreationRule.services.calendarEventCreationRuleService', [
])
    .factory('calendarEventCreationRuleService',
        [
            '$http',
            '$filter',
            function ($http, $filter) {
                var urlBase = '/webapi/WebCalendarEventCreationRule/';
                var repo = {};

                var parseCalendarEventCreationRulesForSignUp = function (data) {
                    for (var i = data.length; i--;) {
                        data[i].startDate = moment.utc(data[i].startDate).toDate();
                        data[i].endDate = moment.utc(data[i].endDate).toDate();

                        if (typeof data[i].signUpOpen !== 'undefined') {
                            data[i].signUpOpen = moment.utc(data[i].signUpOpen);
                        }

                        if (typeof data[i].signUpClose !== 'undefined') {
                            data[i].signUpClose = moment.utc(data[i].signUpClose);
                        }

                        if (data[i].startTime && data[i].startTime.indexOf(':') > -1) {
                            data[i].startHour = parseInt(data[i].startTime.split(':')[0]);
                            data[i].startMin = parseInt(data[i].startTime.split(':')[1]);
                        }

                        if (data[i].startTime && data[i].endTime.indexOf(':') > -1) {
                            data[i].endHour = parseInt(data[i].endTime.split(':')[0]);
                            data[i].endMin = parseInt(data[i].endTime.split(':')[1]);
                        }
                    }
                };

                repo.getCalendarEventCreationRulesForSignUp = function (signUpId) {
                    return $http.get(urlBase + 'GetCalendarEventCreationRulesForSignUp', {
                        params: {
                            signUpId: signUpId
                        }
                    })
                        .then(function (response) {
                            var data = response.data;

                            parseCalendarEventCreationRulesForSignUp(data);

                            return data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repo.getCalendarEventCreationRulesForSignUpToPublish = function (signUpId) {
                    return $http.get(urlBase + 'GetCalendarEventCreationRulesForSignUpToPublish', {
                        params: {
                            signUpId: signUpId
                        }
                    })
                        .then(function (response) {
                            var data = response.data;

                            parseCalendarEventCreationRulesForSignUp(data);

                            return data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repo.postGroupEventAndReturnSavedEntity = function (signUpGroupEvent) {

                    signUpGroupEvent.startDate = $filter('date')(signUpGroupEvent.startDate, 'yyyy-MM-dd', 'UTC');
                    signUpGroupEvent.endDate = $filter('date')(signUpGroupEvent.endDate, 'yyyy-MM-dd', 'UTC');

                    signUpGroupEvent.startTime = signUpGroupEvent.startHour + ':' + signUpGroupEvent.startMin + ':00';
                    signUpGroupEvent.endTime = signUpGroupEvent.endHour + ':' + signUpGroupEvent.endMin + ':00';

                    return $http.post("/CcaSignUp/SaveSignUpGroupEventAndReturnSavedEntity?hash_id=" + new Date().getTime(), { dto: signUpGroupEvent })
                        .then(function (response) {
                            var data = response.data;

                            data.startDate = moment.utc(data.startDate).toDate();
                            data.endDate = moment.utc(data.endDate).toDate();

                            data.signUpOpen = moment.utc(data.signUpOpen);
                            data.signUpClose = moment.utc(data.signUpClose);

                            if (data.startTime && data.startTime.indexOf(':') > -1) {
                                data.startHour = parseInt(data.startTime.split(':')[0]);
                                data.startMin = parseInt(data.startTime.split(':')[1]);
                            }

                            if (data.startTime && data.endTime.indexOf(':') > -1) {
                                data.endHour = parseInt(data.endTime.split(':')[0]);
                                data.endMin = parseInt(data.endTime.split(':')[1]);
                            }

                            return data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });

                };

                repo.selectedDaysSummary = function (daysOfWeek) {
                    var days = "";
                    if (daysOfWeek & 1) days = days + $filter('translate')('SB_WeekShortDay1'); // Mon
                    if (daysOfWeek & 2) days = days + $filter('translate')('SB_WeekShortDay2') + ' '; // Tue
                    if (daysOfWeek & 4) days = days + $filter('translate')('SB_WeekShortDay3') + ' '; // Wed
                    if (daysOfWeek & 8) days = days + $filter('translate')('SB_WeekShortDay4') + ' '; // Thur
                    if (daysOfWeek & 16) days = days + $filter('translate')('SB_WeekShortDay5') + ' '; // Fri
                    if (daysOfWeek & 32) days = days + $filter('translate')('SB_WeekShortDay6') + ' '; // Sat
                    if (daysOfWeek & 64) days = days + $filter('translate')('SB_WeekShortDay0') + ' '; // Sun

                    return days;
                };

                return repo;
            }
        ]);