import { Injectable } from '@angular/core';
import { ScriptLoaderService } from './script-loader.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private scriptSvc: ScriptLoaderService) { }

  loadPaymentProviderExternalScripts()
  {
    environment.paymentProviderConfigs.forEach(providerConfig =>
      {
        this.scriptSvc.loadJsScript(
          providerConfig.script.url,
          providerConfig.script.crossOriginAttribute ?? null,
          providerConfig.script.integrityAttribute ?? null
        )
      });
    }
}
