

angular.module('settingsModule')
    .component('onboardingBlockDates',
    {
        templateUrl: '/Scripts/app/settings/components/onboarding/onboarding-blockdates.template.html',
        controller: class OnboardingBlockDatesCtrl {

        // Dependencies
        $uibModal: any;
        $filter: any;
        blockDatesService: any;
        dateRangeLists: any;

        // Variables
        reason: string = '';
        dtFrom: any = new Date();
        dtTo: any = new Date();
        isEdit: boolean | null = null;
        blockdates: any;
        entryId: any;
        maxDate: any;
        dt: any;

        static $inject = ['$uibModal', '$filter', 'blockDatesService', 'dateRangeLists'];

        constructor ($uibModal: any, $filter: any, blockDatesService: any, dateRangeLists: any) {
            this.$uibModal = $uibModal;
            this.$filter = $filter;
            this.blockDatesService = blockDatesService;
            this.dateRangeLists = dateRangeLists;
        }

        $onInit() {
            this.reason = '';
            this.resetView()
            this.blockDatesService.getOrganisationBlockDates().then(blockdates => { this.blockdates = blockdates; });
            this.maxDate = moment().utc().add(730, 'd');
        }

        resetView() {
            this.dtFrom = moment().utc();
            this.dtTo = moment().utc();
            this.reason = '';
            this.isEdit = null;
            this.entryId = null;
        }

        newBlock() {
            this.blockdates = null;
            this.blockDatesService.saveOrganisationBlockDate(0,
                this.formatDate(this.dtFrom),
                this.formatDate(this.dtTo),
                this.reason)
                .then((blockdates: any) => {
                    this.resetView();
                    this.blockdates = blockdates;
                });
        }

        editBlock() {
            this.blockdates = null;
            this.blockDatesService.saveOrganisationBlockDate(this.entryId,
                this.formatDate(this.dtFrom),
                this.formatDate(this.dtTo),
                this.reason)
                .then((blockdates: any) => {
                    this.resetView();
                    this.blockdates = blockdates;
                });
        }

        // Formats moment object
        formatDate(date: any) {
            return date.format('YYYY-MM-DD');
        }

        cancelEdit() {
            this.resetView();
        }

        editBlockDate(index: number) {
            this.isEdit = true;
            var entry = this.blockdates[index];
            this.entryId = entry.id;
            this.reason = entry.reason;

            this.dtFrom = moment(entry.excludeFrom);
            this.dtTo = moment(entry.excludeTo);
        }

        deleteBlockDate(blockDateId: number) {
            const modalInstance = this.$uibModal.open({
                component: 'onboardingBlockDatesConfirm',
                size: 'sm'
            });

            modalInstance.result.then(()=> {
                this.blockdates = null;
                this.blockDatesService.deleteOrganisationBlockDate(blockDateId)
                    .then((blockdates: any) => {
                        this.blockdates = blockdates;
                    });
            }, () => {
            });
        };
    }
});
