angular.module('tuitionFeesModule')
    .component('tuitionFeesDashboard',
        {
            templateUrl: '/Scripts/app/tuitionFees/components/dashboard/tuition-fees-dashboard.template.html',
            controller: class TuitionFeesDashboardCtrl {

                // Dependencies
                $state: any;
                iconService: any;

                // Variables
                menuItems: Array<any>;

                static $inject = ['$state', 'iconService'];

                constructor($state, iconService) {
                    this.$state = $state;
                    this.iconService = iconService;
                }

                $onInit() {
                    this.menuItems = [
                        {
                            uisref: '.billingCycle',
                            title: 'SB_Billing_Cycle',
                            icon: this.iconService.getIcon('circle')
                        },
                        {
                            uisref: '.invoice',
                            title: 'SB_Invoices',
                            icon: this.iconService.getIcon('invoice')
                        }
                    ];

                    if (this.menuItems) {
                        this.$state.go(this.menuItems[1].uisref);
                    };

                }

            }
        }
    );
