
<div class="align-center">
     <div *ngIf="label"
          [innerHTML]="label | translate"
          class="mr-3"></div>
     <div class="switch lh-1-point-5"
          (click)="onCheckboxClick()"
          [ngClass]="'switch-' + switchClass">
          <input type="checkbox"
                    class="switch"
                    [disabled]="isDisabled"
                    [checked]="isChecked"
                    [required]="isRequired"
                    [id]="id">
          <label></label>
          <span class="pl-3" [hidden]="!isChecked" [innerHTML]="trueLabel | translate"></span>
          <span class="pl-3" [hidden]="isChecked" [innerHTML]="falseLabel | translate"></span>
     </div>
    <sb-icon *ngIf="isDisabled"
             class="ml-2 mr-1"
             name="locked"
             [ngbTooltip]="disabledMessage | translate">
    </sb-icon>
    <sb-icon *ngIf="description"
             class="ml-2"
             name="info"
             [ngbTooltip]="description | translate"></sb-icon>
</div>