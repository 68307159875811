'use strict';

angular.module('receptionModule').component('receptionTeamTasks',  {
    templateUrl:
    '/Scripts/app/reception/controllers/groups/reception-allgroups-tasks.template.html?v=', // + window.EveryBuddy.version
    bindings: {
        selectedGroup: '<'
    },
    controller: [
    '$scope', '$state', '$filter', '$uibModal',
    function ($scope, $state, $filter, $uibModal)
    {

        this.$onChanges = (changes) => {
            if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                $scope.selectedGroup = this.selectedGroup;
            }
        };

        this.$onInit = () => { // Not indented to avoid merge issues

        $scope.selectedTaskId = 0;

        }

        $scope.onTaskSelected = function (task) {
            $scope.selectedTaskId = task.id;
        };

        $scope.onTaskUpdated = function (task) {
            $scope.updatedTask = task;
        };

        $scope.onTaskCreated = function (task) {
            $scope.addTaskDialog = $uibModal.open({
                animation: true,
                templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-add-task-popup.template.html',
                controller: 'addTaskPopupController',
                size: "lg",
                scope: $scope,
                resolve: {
                    taskId: function () { return task.id; },
                    teamId: function () { return undefined; },
                    teamName: function () { return undefined; }
                }
            });

            $scope.addTaskDialog.result
                .then(
                    function (newTask) {
                        $scope.updatedTask = newTask;
                    })
                .catch(function () {
                    console.log('dismissed popup');
                });
        };
    }
]});