'use strict';

angular.module('ccasignupModule').component('ccaDetailsAA', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-detailsaa.template.html',
    bindings: {
        onSignUpLoaded: '&',
        onSignUpTypeLoaded: '&',
    },
    controller: [
    '$scope',
    '$location',
    '$state',
    'organisationRepository',
    'signUpRepository',
    'iconService',
    function ($scope, $location, $state, organisationRepository, signUpRepository, iconService) {

        this.$onInit = () => { // Not indented to avoid merge issues

        $scope.selectedTab = 0;
        $scope.signUp = {};

        $scope.menuItems = [];

        var newMenuItems = [];

        var buildMenu = function()
        {
            newMenuItems.push(
                {
                    uisref: '.groups',
                    title: ($scope.signUp.isTimeSlot) ? 'SB_Teachers' : 'SB_Groups',
                    icon: iconService.getIcon('allgroups')
                });
            newMenuItems.push(
                {
                    uisref: '.blockDates',
                    title: 'SB_Block_Dates',
                    icon: iconService.getIcon('blockdates')
                });
            newMenuItems.push(
                {
                    uisref: '.manageEvents',
                    title: $scope.signUp.isPTC ? 'SB_PTC_CreateMeetings_MenuOption' : 'SB_Create_Events',
                    icon: iconService.getIcon('createEvents')
                });
            newMenuItems.push(
                {
                    uisref: '.generateEvents',
                    title: $scope.signUp.isPTC ? 'SB_PTC_PublishMeetings_MenuOption' : 'SB_Publish_Events',
                    icon: iconService.getIcon('publishEvents')
                });

            if (!$scope.signUp.isTimeSlot)
            {
                newMenuItems.push(
                    {
                        uisref: '.existingEvents',
                        title: $scope.signUp.isPTC ? 'SB_PTC_ViewMeetingsBookings_MenuOption' : 'SB_Existing_Events',
                        icon: iconService.getIcon('existingEvents')
                    });
            }

            if ($scope.signUp.isTimeSlot)
            {
                newMenuItems.push(
                    {
                        uisref: '.existingEventsTimeSlotted',
                        title: $scope.signUp.isPTC ? 'SB_PTC_ViewMeetingsBookings_MenuOption' : 'SB_Existing_Events',
                        icon: iconService.getIcon('existingEvents')
                    });
            }

            if (!$scope.signUp.isTimeSlot)
            {
                newMenuItems.push(
                    {
                        uisref: '.addPupil',
                        title: 'SB_Manual_Booking',
                        icon: iconService.getIcon('manualBooking')
                    });
            }

            if ($scope.signUp.isTimeSlot)
            {
                newMenuItems.push(
                    {
                        uisref: '.addPupilTimeSlotted',
                        title: 'SB_Manual_Booking',
                        icon: iconService.getIcon('manualBooking')
                    });
            }

            newMenuItems.push(
                {
                    uisref: '.staffSchedule',
                    title: 'SB_Staff_Schedule',
                    icon: iconService.getIcon('staffschedule')
                });
            newMenuItems.push(
                {
                    uisref: '.locations',
                    title: 'SB_Locations',
                    icon: iconService.getIcon('locations')
                });
                newMenuItems.push(
                {
                    uisref: '.archive',
                    title: 'SB_Archive',
                    icon: iconService.getIcon('archive')
                    });

            if ($scope.signUp.hasForms)
            {
                newMenuItems.push(
                    {
                        uisref: '.customData',
                        title: 'SB_Custom_Data',
                        icon: iconService.getIcon('customForms')
                    });
            }
            // Trigger onChanges in subMenu
            $scope.menuItems = newMenuItems;
        };

        signUpRepository.getSignUp($state.params.signUpId).then((signUp) => {
            $scope.signUp = signUp;
            this.onSignUpLoaded({signUp: $scope.signUp});
            organisationRepository.getCcaType($scope.signUp.ccaSignUpTypeId).then((ccaSignUpType) => {
                $scope.ccaSignUpType = ccaSignUpType.data;
                buildMenu();
                this.onSignUpTypeLoaded({signUpType:  $scope.ccaSignUpType});
                $state.go('.groups');
            });
        });

        }

        $scope.allContainers = function() {
            $state.go('ccaSignUp', {
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        }

        $scope.update = function(ccaSignUp)
        {
            signUpRepository.post(ccaSignUp).then(function()
            {
                $location.path('/');
            });
        };

        $scope.editDetails = function () {
            $state.go('ccaEditAA', {
                signUpId: $state.params.signUpId,
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        }.bind(this);

        $scope.ccaStatusText = function (statusId) {
            switch (statusId) {
                case 1:
                    return 'SB_Managing';
                case 2:
                    return 'SB_Generating';
                case 3:
                    return 'SB_CcaStatus_Active';
                case 4:
                    return 'SB_Archived';
                case 5:
                    return 'SB_Processing';
                default:
                    return 'SB_Managing';
            }
        };
    }]});
