angular
    .module('transport.services.changeRequestService', [])
    .factory('changeRequestService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebOrganisationChangeRequest/';

            var service = {};

            service.getPendingChangeRequestsForApproval = function () {
                return $http.get(urlBase + 'GetPendingChangeRequestsForApproval')
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.rejectChangeRequest = function (changeRequest) {
                return $http.put(urlBase + 'RejectChangeRequest', changeRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.onHoldChangeRequest = function (changeRequest) {
                return $http.put(urlBase + 'OnHoldChangeRequest', changeRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.approveChangeRequest = function (changeRequest) {
                return $http.put(urlBase + 'ApproveChangeRequest', changeRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getFutureChangeRequestsApprovedOrRejected = function () {
                return $http.get(urlBase + 'GetFutureChangeRequestsApprovedOrRejected')
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.GetTransportDiaryEventsByPupilName = function (pupilSearchName) {
                var params = {
                    pupilName: pupilSearchName
                };

                return $http.get(urlBase + 'GetTransportDiaryEventsByPupilName', { params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.addAndApproveChangeRequest = function (addAndApproveRequest) {
                return $http.post(urlBase + 'AddAndApproveChangeRequest', addAndApproveRequest)
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.setFromRouteStopId = function (changeRequest, pupilChangeRequestOptions)
            {
                // Sets the From route stop id if available
                pupilChangeRequestOptions.timeSlots.forEach(function (timeSlot)
                {
                    timeSlot.transportChangeRequestOptions.forEach(function (changeRequestOption)
                    {
                        if (changeRequestOption.isCalendarEvent)
                        {
                            if (typeof changeRequestOption.timetableRoute !== 'undefined' &&
                                typeof changeRequestOption.timetableRoute.timetableRouteStop !== 'undefined')
                            {
                                changeRequest.fromRouteStopId = changeRequestOption.timetableRoute.timetableRouteStop.id;
                            }
                        }
                    });
                });
            };

            return service;
        }
    ]);