declare var Twilio: any;

angular.module('shared.components.sbTwilioVideo', [
    'shared.services.twilioService',
    'shared.services.toastService',
    'shared.services.simpleAlertService',
    'shared.services.dateTimeService',
    'shared.directives.sbLoading'
])
    .component('sbTwilioVideo',
        {
            bindings: {
                roomName: '<',
                endDate: '<',
                serverUtcTimeOffset: '<',
                onEndCall: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-twilio-video.template.html',
            controller: class SbTwilioVideoCtrl {

                $rootScope: any;
                $interval: any;
                $timeout: any;
                $window: any;
                $translate: any;
                twilioService: any;
                toastService: any;
                simpleAlertService: any;
                dateTimeService: any;
                roomName: string;
                endDate: string;
                serverUtcTimeOffset: number = 0;
                onEndCall: any;
                room: any;
                roomEnded: boolean = false;
                countdown: number;
                countdownString: string;
                dangerSeconds: number = 300; // Five mins left, show 'danger' class clock

                static $inject = ['$rootScope', '$interval', '$timeout', '$window', '$translate', 'twilioService', 'toastService', 'simpleAlertService', 'dateTimeService'];

                constructor($rootScope, $interval, $timeout, $window, $translate, twilioService, toastService, simpleAlertService, dateTimeService) {
                    this.$rootScope = $rootScope;
                    this.$interval = $interval;
                    this.$timeout = $timeout;
                    this.$window = $window;
                    this.$translate = $translate;
                    this.twilioService = twilioService;
                    this.toastService = toastService;
                    this.simpleAlertService = simpleAlertService;
                    this.dateTimeService = dateTimeService;
                }

                $onInit() {
                    this.resetCountdown();
                    if (this.countdown > 0) {
                        this.initRoom();
                    }
                    else {
                        this.roomEnded = true;
                        this.onEndCall();
                    }
                }

                async initRoom() {
                    // Whole lot of messy dom manipulation as no AngularJS library for this
                    const Video = Twilio.Video;
                    const token = await this.twilioService.getToken(this.roomName);

                    Video.connect(token, {
                        name: this.roomName,
                        audio: true,
                        video: { width: 640 },
                        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }]
                    })
                        .then(room => this.onRoomConnected(room))
                        .catch(err => {
                            console.warn(err);
                            const isPermissionDenied = err.message.includes('Permission denied');
                            const isSourceUnreadable = err.message.includes('Could not start') || err.message.includes('Device in use');
                            this.toastService.error(isPermissionDenied ? 'SB_Call_Permission_Denied' : isSourceUnreadable ? 'SB_Call_Not_Readable' : err);
                            this.onEndCall();
                        });
                }

                $onDestroy() {
                    this.disconnect();
                }

                onRoomConnected = (room) => {
                    this.room = room;

                    const localParticipantContainer = document.getElementById('twilioLocalParticipantContainer') as HTMLElement;
                    room.localParticipant.videoTracks.forEach(publication => {
                        localParticipantContainer.appendChild(publication.track.attach());
                    });

                    room.on('participantConnected', this.participantConnected);

                    room.on('participantDisconnected', this.participantDisconnected);
                    room.once('disconnected', error => this.participantDisconnected(room.localParticipant));

                    room.on('ended', () => this.roomEnded = true);
                    room.participants.forEach(participant => {
                        this.showParticipant(participant);
                    });

                    this.resetCountdown();
                    this.startCountDown();
                    const container = document.getElementById('twilioContainer');
                    const loader = document.getElementById('twilioLoading');
                    container?.classList.remove('hidden');
                    loader?.classList.add('hidden');
                }

                showParticipant = (participant) => {
                    const participantsContainer = document.getElementById('twilioVideoParticipants') as HTMLElement;
                    const participantsWaiting = document.getElementById('twilioVideoParticipantsWaiting') as HTMLElement;

                    participantsWaiting.classList.add('hidden');
                    const div = document.createElement('div');
                    div.id = participant.sid;
                    const divLabel = document.createElement('div');
                    divLabel.innerText = participant.identity;
                    const divMuted = document.createElement('i');
                    ['hidden', 'fas', 'fa-microphone-alt-slash'].forEach(item => {
                        divMuted.classList.add(item);
                    });
                    const divVideoHidden = document.createElement('div');
                    const divVideoHiddenIcon = document.createElement('i');
                    ['hidden', 'call-participant__video-disabled'].forEach(item => {
                        divVideoHidden.classList.add(item);
                    });
                    ['fas', 'fa-video-slash'].forEach(item => {
                        divVideoHiddenIcon.classList.add(item);
                    });
                    divVideoHidden.appendChild(divVideoHiddenIcon);
                    participant.on('trackSubscribed', track => this.trackSubscribed(div, track));
                    participant.on('trackUnsubscribed', () => this.trackUnsubscribed);

                    participant.tracks.forEach(publication => {
                        if (publication.isSubscribed) {
                            this.trackSubscribed(div, publication.track);
                        }
                        if (publication.kind === 'audio') {
                            divMuted.setAttribute('id', publication.trackSid);
                        }
                        if (publication.kind === 'video') {
                            divVideoHidden.setAttribute('id', publication.trackSid);
                        }
                        publication.on('subscribed', this.handleTrackDisabled);
                    });

                    participantsContainer.appendChild(div);
                    div.appendChild(divLabel);
                    div.appendChild(divVideoHidden);
                    divLabel.appendChild(divMuted);
                }

                trackSubscribed = (div, track) => {
                    div.querySelectorAll(track.kind).forEach( item => item.remove());
                    div.appendChild(track.attach());
                }

                trackUnsubscribed = (track) => {
                    track.detach().forEach(element => element.remove());
                }

                participantConnected = (participant) => {
                    this.toastService.chat(this.$translate.instant('SB_Call_Patricipant_Joined', { name: participant.identity }));
                    this.showParticipant(participant);
                }

                participantDisconnected = (participant) => {
                    this.toastService.chat(this.$translate.instant('SB_Call_Patricipant_Left', { name: participant.identity }));
                    document.getElementById(participant.sid)?.remove();

                    if (this.room.participants.size === 0) {
                        const participantsWaiting = document.getElementById('twilioVideoParticipantsWaiting') as HTMLElement;
                        participantsWaiting.classList.remove('hidden');
                    }
                    if(participant === this.room.localParticipant) {
                        this.disconnect();
                    }
                }

                handleTrackDisabled = (track) => {
                    const div = document.getElementById(track.sid);
                    if (!div) return;

                    if (track.isTrackDisabled) {
                        div.classList.remove('hidden');
                    }
                    track.on('disabled', () => {
                        div.classList.remove('hidden');
                    });
                    track.on('enabled', () => {
                        div.classList.add('hidden');
                    });
                }

                disconnect = () => {
                    if (!this.room) return;
                    this.room.localParticipant.tracks.forEach(publication => {
                        // Handle various browsers
                        if (publication.track.stop) {
                            publication.track.stop();
                        }
                        if (publication.unpublish) {
                            publication.unpublish();
                        }
                        publication.track.detach();
                    });
                    this.room.disconnect();
                    this.onEndCall();
                }

                resetCountdown = () => {
                    this.countdown = moment.utc(this.endDate).diff(moment.utc().add(this.serverUtcTimeOffset, 'seconds'), 'seconds');
                }

                decrementCountdown = () => {
                    this.resetCountdown();
                    /* Perhaps when moving to Angular 12 we can put all this countdown
                    functionality in a DateTimeService using observables */
                    this.countdownString = this.dateTimeService.secondsToClock(this.countdown);
                    if (this.countdown < 1) {
                        this.disconnect();
                        this.roomEnded = true;
                        this.$timeout(() => {
                            this.onEndCall();
                        }, 2000)
                    }
                };

                startCountDown = () => {
                    this.$interval(this.decrementCountdown, 1000, this.countdown)
                };

                onVideoStartClick = () => {
                    if (!this.room) return;
                    const localParticipantContainer = document.getElementById('twilioLocalParticipantContainer');
                    const toggleVideoButtonStart = document.getElementById('twilioToggleVideoStart');
                    const toggleVideoButtonStop = document.getElementById('twilioToggleVideoStop');
                    this.room.localParticipant.videoTracks.forEach(publication => {
                        const localParticipantVideo = localParticipantContainer?.querySelector('video');
                        publication.track.enable();
                        localParticipantVideo?.classList.remove('invisible');
                        toggleVideoButtonStop?.classList.remove('hidden');
                        toggleVideoButtonStart?.classList.add('hidden');
                    });
                }

                onVideoStopClick = () => {
                    if (!this.room) return;
                    const localParticipantContainer = document.getElementById('twilioLocalParticipantContainer');
                    const toggleVideoButtonStart = document.getElementById('twilioToggleVideoStart');
                    const toggleVideoButtonStop = document.getElementById('twilioToggleVideoStop');
                    this.room.localParticipant.videoTracks.forEach(publication => {
                        const localParticipantVideo = localParticipantContainer?.querySelector('video');
                        publication.track.disable();
                        localParticipantVideo?.classList.add('invisible');
                        toggleVideoButtonStart?.classList.remove('hidden');
                        toggleVideoButtonStop?.classList.add('hidden');
                    });
                }

                onAudioStartClick = () => {
                    if (!this.room) return;
                    const toggleAudioButtonStart = document.getElementById('twilioToggleAudioStart');
                    const toggleAudioButtonStop = document.getElementById('twilioToggleAudioStop');
                    this.room.localParticipant.audioTracks.forEach(publication => {
                        publication.track.enable();
                        toggleAudioButtonStop?.classList.remove('hidden');
                        toggleAudioButtonStart?.classList.add('hidden');
                    });
                };

                onAudioStopClick = () => {
                    if (!this.room) return;
                    const toggleAudioButtonStart = document.getElementById('twilioToggleAudioStart');
                    const toggleAudioButtonStop = document.getElementById('twilioToggleAudioStop');
                    this.room.localParticipant.audioTracks.forEach(publication => {
                        publication.track.disable();
                        toggleAudioButtonStart?.classList.remove('hidden');
                        toggleAudioButtonStop?.classList.add('hidden');
                    });
                };

                onLeaveCallClick = () => {
                    if (!this.room) return;

                    if (!this.roomEnded) {
                        const confirmation = this.simpleAlertService.simpleAlert({
                            title: 'SB_Event_Call_Leave',
                            message: 'SB_Event_Call_Leave_Confirm',
                            okButtonText: 'SB_Yes',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });
                        confirmation.result.then(() => {
                            this.disconnect();
                            this.onEndCall();
                        }, () => { });
                    }
                    else {
                        this.onEndCall();
                    }
                    return;
                };
            }
        });
