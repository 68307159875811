import { Injectable } from '@angular/core';
import { Language } from '@sb-shared/models/language';
import { ReplaySubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CurrentLangService {
    currentLang: ReplaySubject<Language> = new ReplaySubject<Language>(1);

    setLang(lang: Language): void {
        this.currentLang.next(lang);
    }
}
