<ng-template #pagination>
  <ngb-pagination
    *ngIf="(rowCount || filteredTableData?.length) > pageSize && !isPaginationDisabled"
    [collectionSize]="rowCount || filteredTableData?.length"
    [(page)]="currentPage"
    [pageSize]="pageSize"
    (pageChange)="changePage()"
    aria-label="Pagination"
    [maxSize]="paginationMaxPages"></ngb-pagination>
</ng-template>
<ng-template #searchFilter>
  <sb-search-filter
    *ngIf="searchFilterType"
    [searchFilterType]="searchFilterType"
    [dateRangeList]="dateRangeList"
    [startDate]="startDate"
    [endDate]="endDate"
    (resetAll)="onClickReset()"
    [(inputValue)]="searchFilters.textFilter"
    (inputValueChange)="changeFilters(false)"
    (changeDateRange)="dateRangeChange($event)"
    [placeholder]="searchText"
    [isSingleArea]="isFilterSingleArea"
    [hideResetButton]="isFilterHideReset || hideReset"
    [showFilters]="showFilters"
    [ngClass]="{ 'hidden-xs hidden-sm': selectedValue }"
    [filterCount]="filterCount()"
    [(pageSize)]="isPaginationDisabled ? null : pageSize"
    (pageSizeChange)="changePageSize()"
    [label]="searchFilterLabel"
    [hidden]="hideSearchFilterOnSelected && selectedValue">
    <ng-container *ngFor="let th of filterableSelectorTableColumns">
      <sb-search-filter-item
        *ngIf="!filterGroups || th.doTriggerReload"
        [name]="th.allLabel && !th.isMultiOption ? '' : th.name">
        <select
          *ngIf="!th.isMultiOption"
          [(ngModel)]="searchFilters[th.id]"
          class="form-control"
          (change)="changeFilters(th.doTriggerReload)">
          <option
            *ngFor="let option of th.options"
            [ngValue]="option.id"
            class="space-between"
            [innerHTML]="(option.name | translate) + (showOptionCount(th, option) ? ' (' + option.count + ')' : '')"
            [disabled]="option.count === 0 && !th.doTriggerReload"></option>
        </select>
        <sb-multiselect-dropdown
          *ngIf="th.isMultiOption"
          [settings]="th.dropdownSettings"
          [data]="th.options"
          [(model)]="searchFilters[th.id]"
          [allLabel]="th.allLabel"
          (modelChange)="changeFilters(th.doTriggerReload)"
          (onSelect)="changeFilters(th.doTriggerReload)"
          (onSelectAll)="changeFilters(th.doTriggerReload)"
          class="w-100"></sb-multiselect-dropdown>
        <sb-icon
          *ngIf="th.tooltip"
          name="info"
          [ngbTooltip]="th.tooltip | translate"
          placement="bottom"
          class="ml-3"></sb-icon>
      </sb-search-filter-item>
    </ng-container>
    <ng-container *ngFor="let th of filterableDateRangeTableColumns">
      <sb-search-filter-item
        *ngIf="!filterGroups || th.doTriggerReload"
        [name]="th.allLabel && !th.isMultiOption ? '' : th.name">
        <sb-date-range-picker
              [dateRangeList]="dateRangeList"
              (onChange)="onChangeDate($event, th)"
              [customOptions]="customDateOptions"
              [startDate]="th.startDate"
              [endDate]="th.endDate">
        </sb-date-range-picker>
      </sb-search-filter-item>
    </ng-container>
    <ng-container *ngFor="let th of filterableCheckBoxListTableColumns">
      <sb-search-filter-item
        *ngIf="!filterGroups || th.doTriggerReload"
        [name]="th.allLabel && !th.isMultiOption ? '' : th.name">
        <div class="filter-options-checkbox-list">
          <div *ngFor="let option of th.options">
            <sb-check-box [label]="option.name" [model]="th.id[option.id]"></sb-check-box>
          </div>
        </div>
        <sb-icon
          *ngIf="th.tooltip"
          name="info"
          [ngbTooltip]="th.tooltip | translate"
          placement="bottom"
          class="ml-3"></sb-icon>
      </sb-search-filter-item>
    </ng-container>
    <div class="filter-checkbox-field" *ngIf="filterableCheckBoxTableColumns.length > 0">
      <ng-container *ngFor="let th of filterableCheckBoxTableColumns">
        <sb-search-filter-item *ngIf="!filterGroups || th.doTriggerReload">
          <sb-check-box
            *ngIf="!th.allLabel"
            [(model)]="searchFilters[th.id]"
            (modelChange)="changeFilters(th.doTriggerReload)"
            [isChecked]="searchFilters[th.id]"
            [label]="th.filterLabel || th.name"></sb-check-box>
          <sb-on-off-switch
            *ngIf="th.allLabel"
            [(model)]="searchFilters[th.id]"
            [isChecked]="searchFilters[th.id]"
            (modelChange)="changeFilters(th.doTriggerReload)"
            [trueLabel]="th.filterLabel || th.name"
            [falseLabel]="th.allLabel">
          </sb-on-off-switch>
          <sb-icon
            *ngIf="th.tooltip"
            name="info"
            [ngbTooltip]="th.tooltip | translate"
            placement="bottom"
            class="ml-3"></sb-icon>
        </sb-search-filter-item>
      </ng-container>
    </div>
    <div *ngIf="filterGroups" class="w-100">
      <div class="mb-5">
        <sb-button message="SB_Summary" iconName="chart" buttonClass="link" (onClick)="showChart()"></sb-button>
      </div>
      <ngb-accordion #filterAccordion="ngbAccordion">
        <ngb-panel *ngFor="let group of filterGroups" [id]="group.id">
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn-link w-100 space-between" (click)="toggleFilterGroup(group.id)">
              <div class="align-center">
                <sb-icon *ngIf="group.iconName" [name]="group.iconName" [isActivity]="true" class="mr-4"></sb-icon>
                <h2 [innerHTML]="group.name | translate"></h2>
                <sb-icon [name]="opened ? 'chevronDown' : 'chevronRight'" overrideStyle="solid" class="ml-3"></sb-icon>
              </div>
              <div class="p-large text-muted">
                <span *ngIf="opened">{{ group.filteredCount }} / </span>
                <span [innerHTML]="group.count"></span>
              </div>
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div *ngFor="let filter of group.filterItems" class="search-filter-groups-item" [hidden]="filter.isHidden">
              <div class="space-between mb-4">
                <h3 [innerHTML]="filter.name | translate"></h3>
                <!-- <sb-button (onClick)="clearFilterSubGroup(group.id, filter.id)"
                                        buttonClass="link"
                                        customClasses="ml-2"
                                        [message]="'SB_Toggle_all' | translate"
                                        [isAutoSize]="true"
                                        [isDisabled]="!filter.hasSelections"></sb-button> -->
              </div>
              <div class="search-filter-groups-item__buttons">
                <sb-button
                  *ngFor="let option of filter.options"
                  [message]="option.shortName || option.name | translate"
                  [note]="(filter.otherFilterRequirements ? option.filteredCount + ' / ' : '') + option.count"
                  [isSelectable]="true"
                  [isSelected]="option.isSelected"
                  (onClick)="onToggleFilterGroupItem(group.id, filter.id, option.id)"
                  [isDisabled]="option.isDisabled"></sb-button>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </sb-search-filter>
</ng-template>
<div *ngIf="isProcessing || isParentProcessing" class="processing-spinner">
  <sb-icon name="loading" class="p-15"></sb-icon>
</div>
<div *ngIf="!isParentProcessing" [ngClass]="{ 'disabled-area': isProcessing }">
  <div
    *ngIf="summaries"
    class="table-summary p-3 pt-4 hidden-xs"
    [ngClass]="{ 'table-summary--long': summaries.length > 3 }">
    <sb-summary-button
      *ngFor="let summary of summaries"
      [summary]="summary"
      [isSelected]="searchFilters[rowStateColumnId] === summary.id"
      (onClick)="onClickSummary(summary.id)"></sb-summary-button>
  </div>
  <ng-container *ngIf="!isFilterLeftColumn" [ngTemplateOutlet]="searchFilter"></ng-container>
  <div class="master-detail mt-5" *ngIf="tables || filteredTableData">
    <ng-template #tableContainer>
      <div class="table__count" *ngIf="!isLoadingData && searchFilterType">
        <div>
          <div class="text-info" [innerHtml]="tableInfo | translate"></div>
          <ng-container *ngTemplateOutlet="pagination"></ng-container>
        </div>
        <div class="align-center" *ngIf="filteredTableData">
          <div
            *ngIf="filteredTableData.length > 0"
            [innerHTML]="resultsMessage()"
            [ngClass]="{ 'p-15': filterGroups }"></div>
          <sb-button
            *ngIf="!hideReset"
            buttonClass="link"
            customClasses="ml-2"
            [isAutoSize]="true"
            message="SB_Reset"
            (onClick)="onClickReset()"
            [isDisabled]="filterCount() === 0 && allFilterGroupsInactive()"></sb-button>
        </div>
      </div>
      <ng-container *ngIf="!isLoadingData && filteredTableData?.length > 0">
        <ng-container *ngIf="tables.length === 1">
          <ng-container
            *ngIf="currentPageData(tables[0].id) as tableData"
            [ngTemplateOutlet]="tableContent"
            [ngTemplateOutletContext]="{
              table: tables[0],
              tableData: tableData
            }">
          </ng-container>
        </ng-container>
        <ngb-accordion #tableAccordion="ngbAccordion" *ngIf="tables.length > 1">
          <ng-container *ngFor="let table of tables">
            <ngb-panel
              *ngIf="currentPageData(table.id) as tableData"
              [id]="'table-' + (table.id || '1')"
              [cardClass]="'table-single'"
              [hidden]="table.id === '-1'">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="header-container" [ngClass]="{ opened: opened, closed: !opened }">
                  <button class="btn-link w-100 space-between" ngbPanelToggle>
                    <div class="align-center">
                      <sb-icon
                        *ngIf="table.iconName || iconClass"
                        [hasColor]="true"
                        [name]="table.iconName || iconClass + table?.id"
                        [isActivity]="true"
                        class="mr-4"></sb-icon>
                      <h2 [innerHTML]="table.name"></h2>
                      <sb-icon
                        [name]="opened ? 'chevronDown' : 'chevronRight'"
                        overrideStyle="solid"
                        class="ml-3"></sb-icon>
                    </div>
                    <div class="p text-muted" [innerHTML]="tableData.length"></div>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container
                  [ngTemplateOutlet]="tableContent"
                  [ngTemplateOutletContext]="{
                    table: table,
                    tableData: tableData
                  }">
                </ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>
        </ngb-accordion>
      </ng-container>
      <ng-container *ngIf="pageSize > 10 && !isLoadingData">
        <ng-container *ngTemplateOutlet="pagination"></ng-container>
      </ng-container>
      <sb-alert
        *ngIf="tableData && !isLoadingData && filteredTableData?.length === 0"
        alertClass="info"
        message="{{ emptyMessage || 'SB_No_Results_Found' | translate }}"></sb-alert>
      <sb-alert
        *ngIf="isError"
        alertClass="danger"
        message="{{ errorMessage || 'SB_Error_Loading_Data' | translate }}"></sb-alert>
      <sb-loading [hasLoaded]="!isLoadingData && (tableData !== undefined || isError)"></sb-loading>
    </ng-template>
    <div
      *ngIf="!hideTable()"
      class="col-sm-12"
      [ngClass]="{
        'hidden-sm': selectedValue,
        'table--full-width': !isDataOnRight && !selectedValue && !placeholderText
      }"
      [style.flex-basis]="tableWidth + 'px'">
      <div class="table__inner" [ngClass]="{ 'table--filterable': isFilterLeftColumn }">
        <ng-container *ngIf="isFilterLeftColumn" [ngTemplateOutlet]="searchFilter"></ng-container>
        <ng-container *ngIf="!isDataOnRight">
          <ng-container *ngTemplateOutlet="tableContainer"></ng-container>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isDataOnRight" class="master-detail__detail">
      <sb-detail
        placeholderText="SB_Please_Make_A_Selection"
        placeholderIcon="searchSettings"
        [selectedValue]="!allFilterGroupsInactive()"
        [isLoading]="isLoadingData">
        <div *ngIf="!isLoadingData" class="flex-column w-100 pl-4 pr-4">
          <ng-container *ngTemplateOutlet="tableContainer"></ng-container>
        </div>
      </sb-detail>
    </div>
    <div
      [hidden]="!selectedValue || placeholderText || defaultValue || noContent()"
      #contentRef
      [style.flex-basis]="contentWidth + 'px'"
      class="master-detail__detail">
      <ng-content></ng-content>
    </div>
    <div *ngIf="placeholderText || selectedValue" class="master-detail__detail">
      <ng-container *ngIf="!isDataOnRight">
        <sb-detail
          *ngIf="!isLoadingValue"
          [placeholderText]="placeholderText"
          [placeholderIcon]="placeholderIcon"
          [backText]="canGoBack() ? backText : null"
          [(selectedValue)]="selectedValue"
          [isLocationChange]="isLocationChange"
          [ngClass]="{ 'hidden-xs hidden-sm': !selectedValue }">
          <!-- Using cardDetail might later want to be conditional to vary layout -->
          <sb-card-detail
            [menuItems]="menuItems"
            [selectedValue]="selectedValue"
            [showBack]="!backText && canGoBack()"
            [showBackSm]="true"
            [isRouted]="isRouted"
            [backgroundUrl]="cardBackgroundUrl"
            (onClickBack)="back()">
          </sb-card-detail>
        </sb-detail>
        <sb-loading *ngIf="isLoadingValue"></sb-loading>
      </ng-container>
    </div>
  </div>
</div>
<sb-banner
  *ngIf="hasSelectionAction()"
  [message]="bannerMessage()"
  [messageClass]="bannerMessageClass"
  [buttonMessage]="bannerButtonMessage"
  [confirmationMessage]="bannerConfirmationMessage"
  [confirmButtonMessage]="bannerConfirmButtonMessage"
  [isSubmitted]="bannerButtonIsSubmitted"
  (clickBannerButton)="bannerButtonClick()"
  position="fixed"
  alignment="bottom"></sb-banner>
<sb-confirm-dialog></sb-confirm-dialog>
<ng-template #chartModal let-modal>
  <div class="space-between mb-5">
    <h2 [innerHTML]="chart?.title | translate" class="bold"></h2>
    <sb-button
      buttonClass="link"
      (onClick)="modal.close()"
      iconName="close"
      iconClasses="p-large"
      [isAutoSize]="true"></sb-button>
  </div>
  <sb-chart [chartOption]="chart.option"></sb-chart>
</ng-template>

<ng-template #tableContent let-table="table" let-tableData="tableData">
  <div class="table-responsive mt-4">
    <div class="space-between p-4" *ngIf="note || exportSettings">
      <div>
        <div *ngIf="note" [innerHTML]="note | translate" class="text-info"></div>
      </div>
      <sb-button
        *ngIf="exportSettings"
        message="SB_Export"
        buttonClass="link"
        customClasses="mb-2"
        iconName="download"
        [dropdownItems]="exportButtons"
        dropdownPlacement="bottom-right"
        [isLoading]="isExporting"
        (onClick)="export($event)"></sb-button>
    </div>
    <table
      class="table table-striped table-align-cells-middle table-restrict"
      [ngClass]="{ 'table-hover': isRowClickable }">
      <thead *ngIf="tableColumns">
        <tr>
          <th *ngFor="let th of visibleTableColumns()">
            <div
              class="align-center"
              [ngClass]="{
                'justify-end': th.cellType === cellTypes.Currency || th.cellType === cellTypes.Numeric,
                'justify-center': th.id === specialTypes.isSelected.id
              }"
              *ngIf="th.cellType !== cellTypes.Popover">
              <div [innerHTML]="th.name | translate" *ngIf="!th.isSortable && th.name"></div>
              <sb-column-header
                *ngIf="th.isSortable"
                [label]="th.name"
                [fieldName]="th.id"
                [(sortType)]="sortType"
                (sortTypeChange)="resetFormattedData()"
                [(sortReverse)]="sortReverse"
                (sortReverseChange)="resetFormattedData()"></sb-column-header>
              <div *ngIf="th.id === specialTypes.isSelected.id && !th.name">
                <sb-check-box
                  [isChecked]="isAllSelected"
                  [isReversed]="true"
                  (click)="toggleSelectAll()"
                  [tooltip]="th.tooltip || (isAllSelected ? 'SB_UNSelect_All' : 'SB_Select_All')"
                  [isDisabled]="isColumnDisabled(th.id)"
                  [label]="selectedIds.length.toString()"></sb-check-box>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of tableData"
          [class]="getRowStateClass(row)"
          [ngClass]="{
            'row-selected': selectedValue?.id && row.id === selectedValue.id
          }"
          [ngbTooltip]="rowTooltip"
          placement="bottom">
          <td
            *ngFor="let col of visibleTableColumns()"
            class="{{ (col?.cellClass || '') + ' ' + (row[col.id]?.cellClass || '') }}"
            [ngClass]="getCellClass(col, row[col.id])"
            (click)="onTableClick(table.id, row[rowKey || 'id'], col.id)">
            <div
              *ngIf="!col.isSpecial"
              class="flex flex-wrap"
              [ngClass]="{
                'flex-column': col.items && isReadable(row[col.items[0].id]),
                'justify-end': col.cellType == cellTypes.Currency || col.cellType === cellTypes.Numeric
              }">
              <div *ngFor="let item of col.items || [col]">
                <ng-template #popContent>
                  <b [innerHTML]="col.name | translate"></b>
                  <div *ngFor="let popoverItem of getPopoverContentArray(row, item)">
                    <sb-icon [name]="popoverItem.iconName" [ngbTooltip]="popoverItem.iconTooltip | translate"></sb-icon>
                    <span [innerHTML]="popoverItem.label | translate"></span>
                    <span *ngIf="popoverItem.iconTooltip | translate"> </span>
                  </div>
                </ng-template>
                <div
                  *ngIf="showContent(item)"
                  class="align-center"
                  [ngClass]="{
                    'pointer text-primary': item.cellType === cellTypes.Popover
                  }"
                  [ngbPopover]="item.cellType === cellTypes.Popover ? popContent : null"
                  popoverClass="table-popover">
                  <span
                    *ngIf="!col.items && isReadable(getLabel(col, row))"
                    [innerHTML]="getLabel(col, row)"
                    [ngClass]="{ 'semi-bold': item.semiBold }"></span>
                  <span *ngIf="showItemLabel(col, row, item)" [innerHTML]="getLabel(item, row)" class="p-small"></span>
                  <sb-icon
                    *ngIf="row[item.id]?.iconName || (row[item.id] && item.iconName)"
                    [hidden]="row[item.id]?.value === false"
                    [name]="row[item.id]?.iconName || item.iconName"
                    [ngClass]="item.iconClass"
                    [class.mr-2]="row[item.id] || row[item.id]?.label"
                    [ngbTooltip]="getIconTooltip(col, row, item) | translate"
                    placement="bottom"
                    [overrideStyle]="row[item.id]?.iconName || item.iconName ? 'solid' : 'light'"></sb-icon>
                </div>
                <sb-badge
                  *ngIf="item.cellType === cellTypes.Badge"
                  [message]="getLabel(col, row)"
                  [badgeClass]="getClass(col, row)"
                  [iconName]="row[item.id]?.iconName"
                  [options]="col.options && col.isEditable ? col.options : null"
                  [selectedOptionId]="row[col.id].id || row[col.id]"
                  (selectedOptionIdChange)="requestUpdateValue(col, row, $event)"
                  [tooltipText]="getTooltip(col, row, item)"></sb-badge>
              </div>
            </div>
            <div *ngIf="col.isSpecial" class="justify-center">
              <!-- These could be one sb-button with dynamic properties but might be less clear -->
              <sb-button
                *ngIf="col.id === specialTypes.filter.id"
                iconName="filter"
                buttonClass="link"
                customClasses="btn-auto text-center"
                [tooltipText]="col.name"
                tooltipPlacement="bottom"
                [isDisabled]="isClickDisabled"></sb-button>

              <sb-button
                *ngIf="col.id === specialTypes.isFavourite.id"
                iconName="favourite"
                buttonClass="link"
                customClasses="btn-auto text-center"
                [iconStyle]="row[col.id]?.value ? 'solid' : 'light'"
                iconClasses="text-gold"
                [tooltipText]="row[col.id]?.value ? 'Deselect' : 'SB_Select'"
                tooltipPlacement="bottom"
                [isDisabled]="isClickDisabled"></sb-button>
              <sb-button
                *ngIf="col.id === specialTypes.viewDetail.id"
                [iconName]="col.iconName || 'pageExternal'"
                buttonClass="link"
                customClasses="btn-auto"
                [iconStyle]="col.iconStyle || 'regular'"
                [tooltipText]="col.tooltip || col.name"
                tooltipPlacement="bottom"
                (onClick)="onTableClick(table.id, row[rowKey || 'id'], col.id)"
                [isVisibleFunction]="col.isVisibleFunction"
                [isDisabled]="isClickDisabled"
                [dropdownItems]="col.buttons"></sb-button>
              <sb-button
                *ngIf="col.id === specialTypes.edit.id"
                [iconName]="col.iconName || 'edit'"
                [iconStyle]="col.iconStyle || 'regular'"
                buttonClass="link"
                customClasses="btn-auto"
                [tooltipText]="col.tooltip || col.name"
                tooltipPlacement="bottom"
                [dropdownItems]="col.buttons"
                [isVisibleFunction]="col.isVisibleFunction"
                [dataItem]="row[rowKey || 'id']"></sb-button>
              <sb-avatar
                *ngIf="col.id === specialTypes.avatar.id"
                [fullName]="row.name"
                [firstName]="row.firstName"
                [lastName]="row.lastName"
                [personId]="row.personId"
                [photoUrl]="row.photoUrl"
                [isLoading]="loadingRecordId === row[rowKey || 'id']"></sb-avatar>
              <sb-check-box
                *ngIf="col.id === specialTypes.isSelected.id"
                [isChecked]="isRowSelected(row[rowKey || 'id'])"
                (onClick)="onCheckBoxClicked(row[rowKey || 'id'])"
                [isDisabled]="isClickDisabled || isColumnDisabled(col.id) || row.isDisabled"></sb-check-box>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="showTotalRow()">
        <tr>
          <th
            *ngFor="let col of visibleTableColumns()"
            class="{{ col?.cellClass || '' }}"
            [ngClass]="col?.cellClass || ''">
            <div
              *ngIf="
                (col?.showTotal ||
                  ((col?.cellType === cellTypes.Currency || col.cellType === cellTypes.Numeric) && totalByDefault)) &&
                !col?.items
              "
              class="flex flex-wrap justify-end">
              <span
                [innerHTML]="getTotalLabel(col, tableData)"
                class="{{ col?.cellClass || '' }}"
                [ngClass]="{ 'semi-bold': col.semiBold }"
                [ngbTooltip]="'SB_Total' | translate">
              </span>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</ng-template>
