'use strict';

angular.module('balanceAdmin.services.balanceAdminOldService', [])
    .factory('balanceAdminOldService', [
        '$http',
        '$q',
        'httpCoreApi',
        function ($http, $q, httpCoreApi) {

            var urlBase = '/BalanceAdmin/';
            var urlBaseCore = 'Balance'
            var balanceRepository = {};

            var httpCoreApiSettings = {
                controllerType: httpCoreApi.controllerTypes.Admin
            };

            balanceRepository.getEvents = function (eventTitle) {
              return $http.get(urlBase + 'GetEvents', { params: { eventTitle: eventTitle, sensor: false } })
                  .then(function (response) { return response.data; });
            };

            return balanceRepository;

        }
    ]);
