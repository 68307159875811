'use strict';

angular.module('receptionModule').component('receptionCorporateContacts', {
templateUrl: '/Scripts/app/reception/controllers/status365/reception-corporate-contacts.template.html?v=' , // + window.EveryBuddy.Version,
controller: [
    '$scope', '$state', '$filter', '$uibModal', 'corporateContactsService',
    function ($scope, $state, $filter, $uibModal, corporateContactsService)
    {
        $scope.updatedContact = null;

        $scope.handleContactSelection = function(contact)
        {
            $scope.selectedContact = contact;
            $scope.selectedContactId = contact.id;
        };

        $scope.add = function()
        {
            $scope.selectedContact = { id: 0, name: 'New Contact' };
            $scope.selectedContactId = 0;
            $scope.updatedContact = $scope.selectedContact;
        };

        $scope.handleContactUpdate = function(contact)
        {
            $scope.updatedContact = contact;
        };
    }
]});