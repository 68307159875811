'use strict';

angular.module('shared.components.sbTermPicker', ['ui.bootstrap'])
    .component('sbTermPicker',
        {
            bindings: {
                totalMonths: '<',
                required: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-term-picker.template.html',
            controller: [
                function ()
                {
                    this.months = 0;
                    this.years = 0;

                    this.$onChanges = function(changes)
                    {
                        if (changes.totalMonths && changes.totalMonths.currentValue)
                        {
                            this.years = Math.floor(this.totalMonths / 12);
                            this.months = this.totalMonths - (this.years * 12);
                        }
                    };

                    this.updateTotalMonths = function()
                    {
                        this.totalMonths = +(this.months) + (+(this.years) * 12);

                        if (this.onChange && this.onChange())
                        {
                            this.onChange()(this.totalMonths);
                        }
                    }.bind(this);
                }
            ]

        });