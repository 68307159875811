'use strict';

angular.module('transport.configuration.archiving.transportConfirmDeleteEvents',
    [
        'shared.components.sbModalHeader',
    ])
    .component('transportConfirmDeleteEvents',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/configuration/archiving/transport-confirm-delete-events.template.html',
            controller: ['$translate',
                function transportConfirmDeleteEventsController($translate)
                {
                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            var params = this.resolve.params;
                            this.timetableOperatingDay = params.timetableOperatingDay;
                            this.dayName = $translate.instant(params.timetableOperatingDay.translatedDayName);
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.close('cancel');
                    };

                    this.deleteEvents = function ()
                    {
                        this.modalInstance.close('confirm');
                    }.bind(this);
                }
            ]
        });
