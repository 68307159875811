/* This is not currently being used, as import models seemed
to fix this AngularJS version */

import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'sb-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {

  @Input() reportId: number;
  @Input() action: number;
  @Output() actionChange: number;

}
