'use strict';

angular.module('cca.components.ccaCancelOrDeleteEventsConfirmDialog',
    [])
    .component('ccaCancelOrDeleteEventsConfirmDialog',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/cca/components/cca-cancel-or-delete-events-confirm-dialog.template.html',
            controller: class CcaCancelOrDeleteEventsConfirmDialogCtrl
            {
                $filter: any;
                $timeout: any;
                $uibModal: any;
                $window: any;

                dateTimeService: any;
                diaryService: any;
                signUpRepository: any;
                simpleAlertService: any;

                modalInstance: any;

                eventsToBeCancelledOrDeleted: any[] = [];
                cancelOrDeleteEventsData: any;

                static $inject = ['$window', 'dateTimeService', 'diaryService', 'signUpRepository', 'simpleAlertService'];

                constructor($window, dateTimeService, diaryService, signUpRepository, simpleAlertService)
                {
                    this.$window = $window;

                    this.dateTimeService = dateTimeService;
                    this.diaryService = diaryService;
                    this.signUpRepository = signUpRepository;
                    this.simpleAlertService = simpleAlertService;
                };

                $onChanges(changes)
                {
                    if (changes.resolve)
                    {
                        this.cancelOrDeleteEventsData = changes.resolve.currentValue?.cancelOrDeleteEventsData;

                        this.cancelOrDeleteEventsData.dataItems = this.diaryService.insertMultiDayEvents(this.cancelOrDeleteEventsData.dataItems);

                        this.cancelOrDeleteEventsData.dataItems = this.cancelOrDeleteEventsData.dataItems
                            .sort(function (a, b) { return a.eventDate - b.eventDate; });
                    }
                };

                getDateLabels(date)
                {
                    let dateLabels = this.dateTimeService.getDateLabels(date);
                    return dateLabels;
                };

                isToday(date)
                {
                    let now = moment();
                    let dateMoment = moment(date);
                    return dateMoment.isSame(now, 'd');
                };

                hasDateChangedSinceLastRow(thisIndex)
                {
                    let events = this.cancelOrDeleteEventsData.dataItems;

                    if (!Array.isArray(events))
                    {
                        return false;
                    }

                    if (thisIndex === 0)
                    {
                        return true;
                    }

                    let lastIndex = thisIndex - 1;

                    let date1 = moment(events[thisIndex].eventDate, 'DD-MM-YYYY');
                    let date2 = moment(events[lastIndex].eventDate, 'DD-MM-YYYY');

                    return !date1.isSame(date2, 'day');
                };

                closeModal(responseDto)
                {
                    this.modalInstance.close(responseDto);
                };

                confirm()
                {
                    let response =
                    {
                        status: 'confirm'
                    };
                    this.closeModal(response);
                }

                cancel()
                {
                    let response =
                    {
                        status: 'cancel'
                    };

                    this.closeModal(response);
                };
            }
        });
