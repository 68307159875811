import { UserUi } from 'src/app/shared/models/user-ui';
import { apis, controllerTypes, HttpRequestSettings } from './../models/request-settings';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Injectable } from '@angular/core';
import { MemberTypeId } from '@sb-shared/enums/member.type.enum';

@Injectable({
  providedIn: 'root'
})
export class LouAssistService {

  config: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.User
  }

  constructor(private http: HttpWebApiService) { }

  setup(user: UserUi) {
    // LOU script not loaded.
    if (!window.LOU) {
      return;
    }

    this.http.get('Info/Lou', this.config)
      .subscribe(res => {
        let userAccountRole = res.userAccountRole;

        // Override role with current member type - in case of staff/parent viewing as parent.
        switch (user.currentMemberTypeId) {
          case MemberTypeId.Staff:
            userAccountRole = 'Staff';
            break;
          case MemberTypeId.Parent:
            userAccountRole = 'Parent';
            break;
          case MemberTypeId.Student:
            userAccountRole = 'Student';
        }

        window.LOU.identify(`SB-${res.id}`,
          {
            app: 'SB',
            user_first_name: res.firstName,
            user_last_name: res.lastName,
            user_created_at: res.createdAtUtc,
            user_last_login_at: res.lastLoggedInDateTimeUtc,
            user_account_role: userAccountRole,
            user_lang: res.userLang,
            user_roles: res.userRoles,
            user_year_group: res.userYearGroup,
            user_subjects: res.userSubjects,
            school_name: res.schoolName,
            school_country: res.schoolCountry,
            sb_organisation_id: res.sbOrganisationId,
            sb_site_instance: res.sbSiteInstance,
            email: res.email
          });
      });
  }
}
