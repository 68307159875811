angular.module('tuitionFeesModule')
    .service('invoiceService', class InvoiceService {

        // Dependencies
        $http: any;
        $window: any;
        $filter: any;

        httpCoreApi: any;
        invoiceStatus: any;

        // Variables
        coreApiSettings: {};

        static $inject = ['$http', '$window', 'httpCoreApi'];

        constructor($http, $window, httpCoreApi) {
            this.$http = $http;
            this.$window = $window;
            this.httpCoreApi = httpCoreApi;

            this.invoiceStatus = [{
                id: 0,
                label: 'SB_Sent'
            },
            {
                id: 1,
                label: 'SB_Archived'
            },
            {
                id: 2,
                label: 'SB_Downloaded'
            }];

            this.coreApiSettings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            }
        }

        getInvoiceStatusLabelById(statusId) {
            return this.getInvoiceStatusById(statusId)?.label ?? '';
        }

        getInvoiceStatusById(statusId) {
            return this.invoiceStatus.find(x => x.id == statusId);
        }

        getPayerTypeLabel(isCorporatePayer) {
            return isCorporatePayer ? 'SB_Corporate' : 'SB_Family';
        }

        getInvoices(params) {
            return this.httpCoreApi.get('Invoice/Invoices', {
                ...this.coreApiSettings,
                urlParams: {
                    ...params
                }
            });
        }

        getInvoice(invoiceId) {
            return this.httpCoreApi.get(`Invoice/${invoiceId}`, this.coreApiSettings);
        }

        addNote(invoiceId, data) {
            return this.httpCoreApi.post(`Invoice/${invoiceId}/Note`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Invoice_Note_Added'
            });
        }

        addPayment(invoiceId, data) {
            return this.httpCoreApi.post(`Invoice/${invoiceId}/Payment`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_TuitionFee_Invoice_Payment_Added_Toast'
            });
        }

        addCreditNote(invoiceId, data) {
            return this.httpCoreApi.post(`Invoice/${invoiceId}/CreditNote`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_TuitionFee_Invoice_Credit_Note_Added_Toast'
            });
        }

        sendInvoice(invoiceId, data) {
            return this.httpCoreApi.post(`Invoice/${invoiceId}/Send`, data, {
                ...this.coreApiSettings,
                successMessage: 'SB_Sent'
            });
        }

        sendInvoiceOverduePaymentReminders(invoiceIds: number[]) {

            const data = {
                invoiceIds: invoiceIds
            };

            return this.httpCoreApi.post(`Invoice/SendLatePaymentReminders`, data, {
                ...this.coreApiSettings,
                successMessage: 'Reminders sent'
            });
        }

        getPaymentMethods() {
            return this.httpCoreApi.get(`Invoice/PaymentMethods`, this.coreApiSettings);
        }

        getInvoiceStatuses() {
            return [
                {
                    id: 0,
                    name: 'SB_All',
                },
                {
                    id: 1,
                    name: 'SB_Unpaid',
                },
                {
                    id: 2,
                    name: 'SB_Partial_Paid',
                },
                {
                    id: 3,
                    name: 'SB_Paid',
                },
                {
                    id: 4,
                    name: 'SB_Unpaid_Overdue'
                },
                {
                    id: 5,
                    name: 'SB_Part_Paid_Overdue'
                }
            ];
        }

        getInvoiceStatusEnumLabels() {
            return ['SB_Sent', 'SB_Archived'];
        }
    });
