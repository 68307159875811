'use strict';

angular.module('cca.components.ccaAddAdditionalEventsDialog',
    [
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.controllers.confirmationPopup',
        'shared.services.simpleAlertService',
    ])
    .component('ccaAddAdditionalEventsDialog',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/cca/components/cca-add-additional-events-dialog.template.html',
            controller: [
                '$filter',
                '$timeout',
                '$uibModal',
                'signUpRepository',
                'simpleAlertService',
                function ccaAddAdditionalEventsDialogController(
                    $filter,
                    $timeout,
                    $uibModal,
                    signUpRepository,
                    simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.isEventGenerationEnabled = false;
                    this.signUpGroupsWhereEventsExist = [];
                    this.singleGroupName = null;
                    this.matchedGroups = [];

                    this.initialiseDates = (() =>
                    {
                        this.dateFrom = new Date();

                        this.dateTo = new Date();
                        this.dateTo.setUTCDate(this.dateTo.getUTCDate() +7);

                        this.minDate = new Date();
                    });

                    this.initialiseDates();

                    this.allDays =
                        [
                            {
                                dayofWeek: 1,
                                name: 'SB_Monday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 2,
                                name: 'SB_Tuesday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 3,
                                name: 'SB_Wednesday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 4,
                                name: 'SB_Thursday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 5,
                                name: 'SB_Friday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 6,
                                name: 'SB_Saturday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 0,
                                name: 'SB_Sunday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            }
                        ];

                    this.days =
                        [
                            {
                                dayofWeek: 1,
                                name: 'SB_Monday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 2,
                                name: 'SB_Tuesday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 3,
                                name: 'SB_Wednesday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 4,
                                name: 'SB_Thursday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 5,
                                name: 'SB_Friday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 6,
                                name: 'SB_Saturday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            },
                            {
                                dayofWeek: 0,
                                name: 'SB_Sunday',
                                isSelected: true,
                                groupsInformation: null,
                                showCreateEventsFor: false
                            }
                        ];

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve && changes.resolve.currentValue)
                        {
                            this.selectedGroups = changes.resolve.currentValue.selectedGroups;

                            this.dateRangeChanged();
                        }
                    }.bind(this);

                    this.setUpDaysFromGroups = function ()
                    {
                        if (this.selectedGroups.length > 1)
                        {
                            this.days = this.days.filter((day) =>
                            {
                                let linkedGroups = [];

                                let groupsForDay = this.selectedGroups.filter((group) =>
                                {
                                    if (group.linkedGroups !== 'undefined')
                                    {
                                        group.linkedGroups.forEach((linkedGroup) =>
                                        {
                                            linkedGroup.parentGroupId = group.id;
                                            linkedGroups.push(linkedGroup);
                                        });
                                    }

                                    return group.dayofWeek % 7 == day.dayofWeek;
                                });

                                let linkedGroupsForDay = linkedGroups.filter((linkedGroup) =>
                                {
                                    return linkedGroup.dayofWeek % 7 == day.dayofWeek;
                                });

                                groupsForDay.forEach((groupForDay) =>
                                {
                                    if (day.groupsInformation == null)
                                    {
                                        day.groupsInformation = [
                                            {
                                                id: groupForDay.id,
                                                groupName: groupForDay.title,
                                                cancelledEvents: null,
                                                restoreCancelledEvents: false,
                                                createNewFromCancelledEvents: false
                                            }
                                        ];
                                    }
                                    else
                                    {
                                        let existingGroupInfo = day.groupsInformation.filter((groupInfo) =>
                                        {
                                            return groupInfo.groupName == groupForDay.title;
                                        });

                                        if (existingGroupInfo == null || existingGroupInfo.length == 0)
                                        {
                                            day.groupsInformation.push(
                                                {
                                                    id: groupForDay.id,
                                                    groupName: groupForDay.title,
                                                    cancelledEvents: null,
                                                    restoreCancelledEvents: false,
                                                    createNewFromCancelledEvents: false
                                                });
                                        }
                                    }
                                });

                                linkedGroupsForDay.forEach((linkedGroupForDay) =>
                                {
                                    if (day.groupsInformation == null)
                                    {
                                        day.groupsInformation = [
                                            {
                                                id: linkedGroupForDay.parentGroupId,
                                                groupName: linkedGroupForDay.title,
                                                cancelledEvents: null,
                                                restoreCancelledEvents: false,
                                                createNewFromCancelledEvents: false
                                            }
                                        ];
                                    }
                                    else
                                    {
                                        let existingGroupInfo = day.groupsInformation.filter((groupInfo) =>
                                        {
                                            return groupInfo.groupName == linkedGroupForDay.title;
                                        });

                                        if (existingGroupInfo == null || existingGroupInfo.length == 0)
                                        {
                                            day.groupsInformation.push(
                                                {
                                                    id: linkedGroupForDay.parentGroupId,
                                                    groupName: linkedGroupForDay.title + ':' + linkedGroupForDay.id,
                                                    cancelledEvents: null,
                                                    restoreCancelledEvents: false,
                                                    createNewFromCancelledEvents: false
                                                });
                                        }
                                    }
                                });

                                return groupsForDay.length > 0 || linkedGroupsForDay.length > 0;
                            });
                        }
                        else // Only one group selected in Generate Additional Events screen
                        {
                            let group = this.selectedGroups[0];
                            this.singleGroupName = group.title;

                            let linkedGroups = [];
                            if (group.linkedGroups !== 'undefined')
                            {
                                group.linkedGroups.forEach((linkedGroup) =>
                                {
                                    linkedGroup.parentGroupId = group.id;
                                    linkedGroups.push(linkedGroup);
                                });
                            }

                            this.days = this.days.filter((day) =>
                            {
                                if (group.dayofWeek % 7 == day.dayofWeek)
                                {
                                    day.isSelected = true;
                                    if (day.groupsInformation == null)
                                    {
                                        day.groupsInformation = [
                                            {
                                                id: group.id,
                                                groupName: group.title,
                                                cancelledEvents: null,
                                                restoreCancelledEvents: false,
                                                createNewFromCancelledEvents: false
                                            }
                                        ];
                                    }
                                }
                                else
                                {
                                    day.isSelected = false;
                                }

                                linkedGroups.forEach((linkedGroup) =>
                                {
                                    if (linkedGroup.dayofWeek % 7 == day.dayofWeek)
                                    {
                                        day.isSelected = true;

                                        if (day.groupsInformation == null)
                                        {
                                            day.groupsInformation = [
                                                {
                                                    id: linkedGroup.parentGroupId,
                                                    groupName: linkedGroup.title,
                                                    cancelledEvents: null,
                                                    restoreCancelledEvents: false,
                                                    createNewFromCancelledEvents: false
                                                }
                                            ];
                                        }
                                    }
                                });

                                return day;
                            });
                        }
                    }.bind(this);

                    this.dateRangeChanged = function ()
                    {
                        // Only display days within the selected date range
                        this.filterDaysAccordingToDateRange();

                        this.setUpDaysFromGroups();

                        $timeout(() =>
                        { this.enableOrDisableEventGeneration(), 200 });
                    }

                    this.dateRangeError = function ()
                    {
                        let fromDate = new Date(this.dateFrom);
                        let toDate = new Date(this.dateTo);
                        return fromDate > toDate;
                    }

                    this.filterDaysAccordingToDateRange = function ()
                    {
                        let fromDate = new Date(this.dateFrom);
                        let toDate = new Date(this.dateTo);

                        if (fromDate > toDate)
                        {
                            this.days = [];
                            return;
                        }

                        var differenceInTime = toDate.getTime() - fromDate.getTime();
                        var differenceInDays = differenceInTime / (1000 * 3600 * 24);

                        if (differenceInDays >= 7)
                        {
                            this.days = JSON.parse(JSON.stringify(this.allDays));
                            return;
                        }

                        if (fromDate.getDay() > toDate.getDay())
                        {
                            this.days = this.allDays.filter((day) =>
                            {
                                return day.dayofWeek >= fromDate.getDay() || day.dayofWeek <= toDate.getDay();
                            });

                            return;
                        }

                        this.days = this.allDays.filter((day) =>
                        {
                            return day.dayofWeek >= fromDate.getDay() && day.dayofWeek <= toDate.getDay();
                        });

                    }.bind(this);

                    this.daySelectionChanged = function ()
                    {
                        $timeout(() =>
                        { this.enableOrDisableEventGeneration(), 200});
                    }

                    this.enableOrDisableEventGeneration = function ()
                    {
                        this.isEventGenerationEnabled = false;
                        this.matchedGroups = [];
                        this.matchingGroups = '';

                        let selectedDays = this.days.filter((day) =>
                        {
                            return day.isSelected;
                        });

                        if (selectedDays.length == 0)
                        {
                            this.signUpGroupsWhereEventsExist = [];
                            return;
                        }

                        let groupIds = this.selectedGroups.map(a => a.id);

                        this.selectedGroups.forEach((selectedGroup) =>
                        {
                            if (selectedGroup.linkedGroups !== 'undefined' && selectedGroup.linkedGroups.length > 0)
                            {
                                selectedGroup.linkedGroups.forEach((linkedGroup) =>
                                {
                                    groupIds.push(linkedGroup.id);
                                });
                            }
                        });

                        let selectedDayIds = selectedDays.map(a => a.dayofWeek);

                        // Treat local time zone date (from date picker) as utc
                        let fromDate = moment(this.dateFrom).utc(true).toDate();
                        let toDate = moment(this.dateTo).utc(true).toDate();

                        signUpRepository.doEventsExistForSignUpGroupsDateRangeAndDaysOfWeek(groupIds,
                                                                                            fromDate,
                                                                                            toDate,
                                                                                            selectedDayIds)
                            .then((signUpGroupsWhereEventsExist) =>
                            {
                                this.signUpGroupsWhereEventsExist = signUpGroupsWhereEventsExist;

                                // Show 'Create Events For...' message when user selects a day without events.
                                // Only applicable when a single group has been selected.
                                if (this.selectedGroups.length == 1)
                                {
                                    var daysWithoutEvents = selectedDays.filter(x => !signUpGroupsWhereEventsExist.some(y => y.dayOfWeekId == x.dayofWeek % 7));
                                    daysWithoutEvents.forEach(x => x.showCreateEventsFor = true);
                                }

                                if (signUpGroupsWhereEventsExist.length > 0)
                                {
                                    signUpGroupsWhereEventsExist.forEach((signUpGroupWhereEventsExist) =>
                                    {
                                        if (signUpGroupWhereEventsExist.activeEvents !== 'undefined' &&
                                            signUpGroupWhereEventsExist.activeEvents != null &&
                                            signUpGroupWhereEventsExist.activeEvents.length > 0)
                                        {
                                            let existingMatch = this.matchedGroups.filter((matchedGroup) =>
                                            {
                                                return matchedGroup == signUpGroupWhereEventsExist.nameWithDay;
                                            });

                                            if (existingMatch.length == 0)
                                            {
                                                this.matchedGroups.push(signUpGroupWhereEventsExist.nameWithDay);
                                            }
                                        }

                                        if (signUpGroupWhereEventsExist.cancelledEvents !== 'undefined' &&
                                            signUpGroupWhereEventsExist.cancelledEvents != null &&
                                            signUpGroupWhereEventsExist.cancelledEvents.length > 0)
                                        {
                                            // SignUp group contains cancelled events so set this information against the day in question.
                                            selectedDays.filter((day) =>
                                            {
                                                if (day.dayofWeek % 7 == signUpGroupWhereEventsExist.dayOfWeekId)
                                                {
                                                    if (day.groupsInformation != null)
                                                    {
                                                        day.groupsInformation.filter((info) =>
                                                        {
                                                            if (info.groupName == signUpGroupWhereEventsExist.ccaSignUpGroupName)
                                                            {
                                                                info.cancelledEvents = signUpGroupWhereEventsExist.cancelledEvents;
                                                                info.restoreCancelledEvents = true;
                                                                info.createNewFromCancelledEvents = false;
                                                            }
                                                        });
                                                    }
                                                    else
                                                    {
                                                        day.groupsInformation = [
                                                            {
                                                                groupName: signUpGroupWhereEventsExist.ccaSignUpGroupName,
                                                                cancelledEvents: signUpGroupWhereEventsExist.cancelledEvents,
                                                                restoreCancelledEvents: true,
                                                                createNewFromCancelledEvents: false
                                                            }]
                                                    }
                                                }
                                            });
                                        }
                                    });

                                    this.matchingGroups = this.matchedGroups.join(", ");
                                }

                                this.isEventGenerationEnabled = this.matchedGroups.length == 0;
                            });
                    }.bind(this);

                    this.restoreCancelledEventsChanged = function(groupInformation)
                    {
                        $timeout(() =>
                        {
                            groupInformation.createNewFromCancelledEvents = !groupInformation.restoreCancelledEvents;
                        }, 200);
                    };

                    let closeModal = function(responseDto)
                    {
                        this.modalInstance.close(responseDto);
                    }.bind(this);

                    this.generateEventsSingleGroup = function ()
                    {
                        let group = this.selectedGroups[0];
                        let selectedDays = this.days.filter((day) =>
                        {
                            return day.isSelected;
                        });

                        let daysOfWeekForGroups = [group.dayofWeek];
                        let hasLinkedGroups = Array.isArray(group?.linkedGroups) && group.linkedGroups.length > 0;
                        if (hasLinkedGroups)
                        {
                            group.linkedGroups.forEach((linkedGroup) =>
                            {
                                daysOfWeekForGroups.push(linkedGroup.dayofWeek);
                            });
                        }

                        let isOtherDaysSelectedModalRequired = false;
                        selectedDays.forEach((selectedDay) =>
                        {
                            if (!daysOfWeekForGroups.find(dayofWeek => dayofWeek == selectedDay.dayofWeek % 7))
                            {
                                isOtherDaysSelectedModalRequired = true;
                            }
                        });

                        let isSomeGroupDaysNotSelectedModalRequired = false;
                        if (hasLinkedGroups) {
                            daysOfWeekForGroups.forEach((dayOfWeekForGroup) =>
                            {
                                if (!selectedDays.find(selectedDay => selectedDay.dayofWeek % 7 == dayOfWeekForGroup))
                                {
                                    isSomeGroupDaysNotSelectedModalRequired = true;
                                }
                            });
                        }

                        if (!isSomeGroupDaysNotSelectedModalRequired && !isOtherDaysSelectedModalRequired)
                        {
                            this.generateEvents();
                            return;
                        }

                        // Show a confirmation modal if user has not selected all of the selected group's days.
                        if (isSomeGroupDaysNotSelectedModalRequired)
                        {
                            let confirmModal = simpleAlertService.simpleAlert({
                                title: 'SB_Confirm',
                                message: 'SB_CcaSignUp_Message_Confirm_Create_Events_For_Not_All_Group_Days',
                                okButtonText: 'SB_Confirm',
                                cancelButtonText: 'SB_Cancel',
                                windowSize: 'md',
                                backdrop: 'static', // prevent modal from closing on backdrop click
                            });

                            confirmModal
                                .result
                                .then(() =>
                                {
                                    if (isOtherDaysSelectedModalRequired)
                                    {
                                        this.showOtherDaysSelectedConfirmation();
                                    }
                                    else
                                    {
                                        this.generateEvents();
                                    }
                                },
                                (() =>
                                {
                                    resetSubmitButtons();
                                }));
                        }
                        else // Show a confirmation modal because user has selected days other than the selected group's day.
                        {
                            this.showOtherDaysSelectedConfirmation();
                        }

                    }.bind(this);

                    this.showOtherDaysSelectedConfirmation = (() =>
                    {
                        let confirmModal = simpleAlertService.simpleAlert({
                            title: 'SB_Confirm',
                            message: 'SB_Confirm_Generate_Additional_Events',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md',
                            staticBackdrop: true // prevent modal from closing on backdrop click
                        });

                        confirmModal
                            .result
                            .then(() =>
                            {
                                this.generateEvents();
                            },
                            (() =>
                            {
                                resetSubmitButtons();
                            }));
                    });

                    this.generateEvents = function ()
                    {
                        // Start event generation for selected group(s), day(s) and date range
                        let selectedDays = this.days.filter((day) =>
                        {
                            return day.isSelected;
                        });

                        // Treat local time zone date (from date picker) as utc
                        this.dateFrom = moment(this.dateFrom).utc(true).toDate();
                        this.dateTo = moment(this.dateTo).utc(true).toDate();

                        let generateAdditionalEventsData =
                        {
                            fromDate: this.dateFrom,
                            toDate: this.dateTo,
                            dataItems: []
                        };

                        let applyStrictDaySelection = this.selectedGroups.length > 1;

                        this.selectedGroups.forEach((selectedGroup) =>
                        {
                            selectedDays.forEach((selectedDay) =>
                            {
                                let processDayForCurrentGroup = true;

                                // If multiple groups were selected then we only create additional events for the
                                // specific day of each selected group.
                                if (applyStrictDaySelection && selectedGroup.dayofWeek != selectedDay.dayofWeek)
                                {
                                    const validDays = [ selectedGroup.dayofWeek, ...selectedGroup.linkedGroups.map(linkedGroup => linkedGroup.dayofWeek) ];

                                    processDayForCurrentGroup = validDays.includes(selectedDay.dayofWeek);
                                }

                                if (processDayForCurrentGroup)
                                {
                                    let cancelledEventIdsToRestore = [];
                                    let cancelledEventIdsToCreateNew = [];
                                    let addToDataItems = false;

                                    selectedDay.groupsInformation?.forEach((groupInfo) =>
                                    {
                                        if (groupInfo.id == selectedGroup.id &&
                                            groupInfo.cancelledEvents?.length > 0)
                                        {
                                            if (groupInfo.restoreCancelledEvents)
                                            {
                                                groupInfo.cancelledEvents.forEach((cancelledEvent) =>
                                                {
                                                    cancelledEventIdsToRestore.push(cancelledEvent.id);
                                                });
                                            }
                                            else if (groupInfo.createNewFromCancelledEvents)
                                            {
                                                groupInfo.cancelledEvents.forEach((cancelledEvent) =>
                                                {
                                                    cancelledEventIdsToCreateNew.push(cancelledEvent.id);
                                                });
                                            }
                                        }
                                    });

                                    let sourceGroup = selectedGroup;
                                    if (sourceGroup.dayofWeek != selectedDay.dayofWeek && sourceGroup.linkedGroups.length > 0)
                                    {
                                        sourceGroup = sourceGroup.linkedGroups.find( x => x.dayofWeek == selectedDay.dayofWeek);
                                    }

                                    let dataItem = {
                                        from: this.dateFrom,
                                        to: this.dateTo,
                                        startHourMin: { h: sourceGroup.ccaStartHour, m: sourceGroup.ccaStartMin },
                                        endHourMin: { h: sourceGroup.ccaEndHour, m: sourceGroup.ccaEndMin },
                                        dayOfWeek: selectedDay.dayofWeek,
                                        cancelledEventIdsToRestore: cancelledEventIdsToRestore,
                                        cancelledEventIdsToCreateNew: cancelledEventIdsToCreateNew
                                    };

                                    dataItem.teamId = selectedGroup.id;
                                    dataItem.ccaSignUpId = selectedGroup.ccaSignUpId;

                                    // Just send over the date for the start and end of creation window
                                    dataItem.from = $filter('date')(dataItem.from, 'yyyy-MM-dd');
                                    dataItem.to = $filter('date')(dataItem.to, 'yyyy-MM-dd');

                                    dataItem.startTime = dataItem.startHourMin.h + ':' + dataItem.startHourMin.m;
                                    dataItem.endTime = dataItem.endHourMin.h + ':' + dataItem.endHourMin.m;

                                    generateAdditionalEventsData.dataItems.push(dataItem);
                                }
                            });
                        });

                        signUpRepository.generateAdditionalEventsForActiveGroupsDatesAndDays(generateAdditionalEventsData)
                            .then((result) =>
                            {
                                let confirmationModal = $uibModal.open({
                                    component: 'ccaGeneratingAdditionalEventsDialog',
                                    size: 'sm',
                                    backdrop: 'static', // prevent modal from closing on backdrop click
                                });

                                confirmationModal.result
                                    .then(() =>
                                    {
                                        let response =
                                        {
                                            status: 'confirmed'
                                        };

                                        closeModal(response);
                                    })
                                    .catch(function ()
                                    {
                                    });
                            })
                            .catch((err) =>
                            {
                                simpleAlertService.errorAlert();
                            });
                    }.bind(this);

                    let resetSubmitButtons = function ()
                    {
                        $timeout(() =>
                        {
                            this.isSubmitted = false;
                        }, 200);

                    }.bind(this);

                    this.cancel = function ()
                    {
                        let response =
                        {
                            status: 'cancelled'
                        };

                        closeModal(response);
                    }
                }
            ]
        });
