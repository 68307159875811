export enum KitStyleId {
    Plain = 1,
    Stripes = 2,
    Hoops = 3,
}

export const KitStyles = [
    {
        id: KitStyleId.Plain,
        className: 'plain',
        name: 'Plain'
    },
    {
        id: KitStyleId.Stripes,
        className: 'stripes',
        name: 'Stripes'
    },
    {
        id: KitStyleId.Hoops,
        className: 'hoops',
        name: 'Hoops'
    },
  ]