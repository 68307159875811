'use strict';

angular.module('receptionModule').component('receptionTasks',  {
    templateUrl: '/Scripts/app/reception/controllers/reception-tasks.template.html?v=', // + window.EveryBuddy.Version,
    controller: [
    '$scope',
    '$uibModal',
    '$window',
    '$timeout',
    function ($scope, $uibModal, $window, $timeout) {
        $scope.selectedTaskId = 0;
        $scope.selectedTaskTeamId = 0;
        $scope.selectedTaskTeamName = '';

        $scope.onTaskSelected = function (task) {
            $scope.selectedTaskId = task.id;
            $scope.selectedTaskTeamId = task.teamId;
            $scope.selectedTaskTeamName = task.teamName;
        };

        $scope.onTaskUpdated = function (task) {
            $scope.updatedTask = task;
        };

        $scope.onTasksLoaded = function()
        {
            // a bit hacky (sorry) but this triggers a bit of jquery (eek!) which resizes the message list
            $timeout(function()
            {
                $window.triggerResize();
            });
        };

        $scope.onTaskCreated = function(task)
        {
            $scope.addTaskDialog = $uibModal.open({
                animation: true,
                templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-add-task-popup.template.html',
                controller: 'addTaskPopupController',
                size: "lg",
                scope: $scope,
                resolve: {
                    taskId: function () { return task.id; },
                    teamId: function () { return undefined; },
                    teamName: function () { return undefined; }
                }
            });

            $scope.addTaskDialog.result
                .then(
                    function (newTask) {
                        $scope.updatedTask = newTask;
                    })
                .catch(function () {
                    console.log('dismissed popup');
                });
        };

        $scope.onGotoCaseClicked = function(task)
        {
            $window.location.href = '/Reception/allgroups/' + task.teamId + '/groupdetails';
        };

        $scope.addTask = function () {
            // pop the dialogue

            $scope.addTaskDialog = $uibModal.open({
                animation: true,
                templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-add-task-popup.template.html',
                controller: 'addTaskPopupController',
                size: "lg",
                scope: $scope,
                resolve: {
                    taskId: function () { return 0; },
                    teamId: function () { return null; },
                    teamName: function () { return ''; }
                }
            });

            $scope.addTaskDialog.result
                .then(
                    function (newTask) {
                        $scope.updatedTask = newTask;
                    })
                .catch(function () {
                    console.log('dismissed popup');
                });
        };
    }
]});