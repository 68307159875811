'use strict';

angular
  .module('reception.services.registerFileService', [
    'shared.services.registerStateService',
    'shared.services.fileService',
    'shared.file.constants',
    'shared.components.sbModalHeader',
    'reception.services.receptionService',
    'events.services.eventRegisterTransportService',
  ])
  .factory('registerFileService', [
    '$filter',
    '$uibModal',
    'registerStateService',
    'receptionService',
    'eventRegisterTransportService',
    'fileService',
    'fileTypeEnum',
    function ($filter, $uibModal, registerStateService, receptionService, eventRegisterTransportService, fileService, fileTypeEnum) {
      var service = this;
      service.fileTypeEnum = fileTypeEnum;
      service.showAbsentColumn = receptionService.getBoolSetting('Register_ShowAbsentColumn');

      service.registerStateEnum = registerStateService.getRegisterStateTypeEnum();

      service.formatOtherEvent = function (event, label) {
        // Used when displaying next/previous events
        return (
          (label ? $filter('translate')(label) + ': ' : '') +
          (event.registerStateId ? ' (' + $filter('translate')(registerStateService.getRegisterStateBadgeText(event.registerStateId)) + ') ' : '') +
          $filter('date')(event.eventStartTime, 'HH:mm') +
          ' - ' +
          $filter('date')(event.eventEndTime, 'HH:mm') +
          ' | ' +
          $filter('limitTo')(event.teamName, 180) +
          (event.teamName.length > 180 ? '...' : '') +
          (event.location
            ? ' | ' + $filter('translate')('SB_Location') + ': ' + $filter('limitTo')(event.location, 180) + (event.location.length > 180 ? '...' : '')
            : '') +
          (event.attendanceNote ? ' | ' + $filter('translate')('SB_Attendance_Note') + ': ' + event.attendanceNote : '')
        );
      };

      service.createRegisterCSV = function (registers) {
        // Only show transport columns if transport event or multiple events
        var isTransport = registers.length > 1 || eventRegisterTransportService.isTransportEvent(registers[0]);
        // May want content other than data? So I've set 'content.data' as CSV stuff
        var content = [];
        // Set header row
        content.data = [
          [
            $filter('translate')('SB_Event_name'),
            $filter('translate')('SB_Event_Type'),
            $filter('translate')('SB_Group'),
            $filter('translate')('SB_Organiser'),
            $filter('translate')('SB_Location'),
            $filter('translate')('SB_Start_Time'),
            $filter('translate')('SB_End_Time'),
            $filter('translate')('SB_Meet_Time'),
            $filter('translate')('SB_Name'),
            $filter('translate')('SB_Date_Of_Birth'),
            $filter('translate')('SB_Year'),
            $filter('translate')('SB_Class'),
            $filter('translate')('SB_Attendance'),
          ],
        ];
        if (service.showAbsentColumn) {
          content.data[0].push($filter('translate')('SB_MIS_Absent'));
        }
        var notes = [
          $filter('translate')('SB_Consent_Notes'),
          $filter('translate')('SB_Attendance_Note'),
          $filter('translate')('SB_Exit_note'),
          $filter('translate')('SB_Previous_Event'),
          $filter('translate')('SB_Next_Event'),
        ];
        content.data[0] = content.data[0].concat(notes);
        if (isTransport) {
          content.data[0].push($filter('translate')('SB_Expected'));
          content.data[0].push($filter('translate')('SB_Stop'));
          content.data[0].push($filter('translate')('SB_Stop_Time'));
        }
        // Add data row for each attendee
        registers.forEach(function (register) {
          if (register.attendeeSections) {
            register.attendeeSections.forEach(function (section) {
              section.subsections.forEach(function (subsection) {
                subsection.attendees.forEach(function (att) {
                  var row = [
                    register.eventTitle,
                    register.eventType,
                    register.teamName,
                    register.leadOrganiserName,
                    register.locationName,
                    $filter('date')(register.from, 'dd/MMM/yyyy HH:mm'),
                    $filter('date')(register.to, 'dd/MMM/yyyy HH:mm'),
                    $filter('date')(register.meetingAt, 'dd/MMM/yyyy HH:mm'),
                    att.name,
                    $filter('date')(att.dateOfBirth, 'dd MMM yyyy'),
                    att.schoolYearName,
                    att.className,
                    $filter('translate')(registerStateService.getRegisterStateBadgeText(att.registerState)),
                  ];
                  if (service.showAbsentColumn) {
                    row.push(att.isAbsent);
                  }
                  var notes = [
                    att.consentNote,
                    att.attendanceNote,
                    att.exitNote,
                    att.previousEvent ? service.formatOtherEvent(att.previousEvent) : '',
                    att.nextEvent ? service.formatOtherEvent(att.nextEvent) : '',
                  ];
                  row = row.concat(notes);
                  if (isTransport) {
                    row.push($filter('translate')(section.config.title));
                    row.push(subsection.title ? subsection.title.replace(',', ' -') : '');
                    row.push(subsection.routeStopTime);
                  }
                  content.data.push(row);
                });
              });
            });
          }
        });
        fileService.saveFile(content, service.fileTypeEnum.CSV, $filter('translate')('SB_Register'));
      }.bind(service);

      service.formatRegisters = function (registers) {
        $uibModal.open({
          animation: this.animationsEnabled,
          templateUrl: '/Scripts/app/shared/save-file-modal.template.html',
          controller: 'formatRegisterController',
          size: 'md',
          backdrop: 'static',
          resolve: {
            registers: function () {
              return registers;
            }.bind(this),
            createRegisterPDF: function () {
              return service.createRegisterPDF;
            }.bind(this),
            createRegisterCSV: function () {
              return service.createRegisterCSV;
            }.bind(this),
          },
        });
      }.bind(service);

      service.createRegisterPDF = function (registers, callbackfunction, config) {
        var pages = { list: [] };
        registers.forEach(function (register) {
          if (register.attendeeSections) {
            var page = [];
            page.isTransport = eventRegisterTransportService.isTransportEvent(register);
            // Register info at top of page
            page.headingContent = [
              register.eventTitle + (register.teamName ? ' | ' + register.teamName : ''),
              $filter('date')(register.from, 'dd MMM yyyy'),
              $filter('date')(register.from, 'EEE') +
                ' ' +
                $filter('date')(register.from, 'dd MMM yyyy') +
                ' ' +
                $filter('date')(register.from, 'HH:mm') +
                ' - ' +
                ($filter('date')(register.from, 'dd MMM yyyy') !== $filter('date')(register.to, 'dd MMM yyyy')
                  ? $filter('date')(register.to, 'EEE') + ' ' + $filter('date')(register.to, 'dd MMM yyyy') + ' '
                  : '') +
                $filter('date')(register.to, 'HH:mm') +
                ' (' +
                $filter('translate')('SB_Meeting_At') +
                ' ' +
                $filter('date')(register.meetingAt, 'HH:mm') +
                ')',
            ];
            // Other key info
            page.info = register.leadOrganiserName ? [{ label: $filter('translate')('SB_Organised_By'), text: register.leadOrganiserName }] : [];
            if (register.locationName) {
              page.info.push({
                label: $filter('translate')('SB_Location'),
                text: register.locationName,
              });
            }
            // Set columns widths and headers (based on user's PDF config and isTransport)
            if (page.isTransport) {
              page.tableWidths = config.n.isIncluded ? ['15%', '75%', '10%'] : ['90%', '10%'];
            } else {
              page.tableWidths = ['*', '10%', '10%', '10%', '10%'];
              if (service.showAbsentColumn) {
                page.tableWidths.push('10%');
              }
              if (register.includesExitData) {
                page.tableWidths.push('10%');
              }
            }
            page.data = [[$filter('translate')('SB_Student')]];
            // Notes column if configured and transport event (pushed below for non-transport)
            if (config.n.isIncluded && page.isTransport) {
              page.data[0].push($filter('translate')('SB_Notes'));
            }
            if (page.isTransport) {
              page.data[0].push($filter('translate')('SB_Present_Plain'));
            } else {
              page.data[0].push($filter('translate')('SB_Present_Plain'));
              page.data[0].push($filter('translate')('SB_Absent_Plain'));
              page.data[0].push($filter('translate')('SB_Late'));
              page.data[0].push($filter('translate')('SB_Excused'));
            }
            if (!page.isTransport) {
              // Add MIS Absent column if relevant (via reception service)
              if (service.showAbsentColumn) {
                page.data[0].push($filter('translate')('SB_MIS_Absent'));
              }
              // Add Exit note column if relevant
              if (register.includesExitData) {
                page.data[0].push($filter('translate')('SB_Exited'));
              }
            }
            // Add sections/subsections with titles
            register.attendeeSections.forEach(function (section) {
              if (page.isTransport) {
                var row = [$filter('translate')(section.config.title) + ' (' + $filter('translate')(section.attendeeCount) + ')'];
                row.style = 'sectionTitle';
                page.data.push(row);
              }
              section.subsections.forEach(function (subsection) {
                if (subsection.title) {
                  var row = [
                    (subsection.routeStopTime ? subsection.routeStopTime + ' | ' : '') +
                      subsection.title +
                      (subsection.attendees.length > 0 ? ' (' + subsection.attendees.length + ')' : ''),
                  ];
                  row.style = 'subsectionTitle';
                  page.data.push(row);
                }
                if (subsection.attendees) {
                  subsection.attendees.forEach(function (att) {
                    // Add row for each attendee
                    // Student info - amend layout to use space efficiently
                    var separator = config.n.isIncluded && page.isTransport ? '\n' : '\xa0 \u2013 \xa0';
                    var row = [[att.name + '\xa0\xa0\xa0', att.schoolYearName + (att.className ? separator + att.className : '')]];
                    // Show consent notes based on config
                    if (config.n.isIncluded && page.isTransport) {
                      row.push(
                        $filter('translate')('SB_Consent_Notes') +
                          ': ' +
                          (att.consentNote ? att.consentNote : '') +
                          '\n\n' +
                          $filter('translate')('SB_Attendance_Note') +
                          ': ' +
                          (att.attendanceNote ? att.attendanceNote : '') +
                          '\n\n'
                      );
                    }
                    if (page.isTransport) {
                      // For transport event, show attendance state if it exists, plus MIS absence if true
                      row.push(
                        $filter('translate')(registerStateService.getRegisterStateBadgeText(att.registerState)) +
                          ' ' +
                          (att.isAbsent ? $filter('translate')('MIS_Absent') : '')
                      );
                    } else {
                      // For non-transport, we show the full list of register states to be marked
                      var marking = 'x';
                      row.push(att.registerState == service.registerStateEnum.Here ? marking : '');
                      row.push(att.registerState == service.registerStateEnum.NotHere ? marking : '');
                      row.push(att.registerState == service.registerStateEnum.Late ? marking : '');
                      row.push(att.registerState == service.registerStateEnum.Excused ? marking : '');
                      if (!page.isTransport) {
                        if (service.showAbsentColumn) {
                          row.push(att.isAbsent ? marking : '');
                        }
                        if (register.includesExitData) {
                          row.push(att.setExitDTS === true || att.exitDTS ? marking : '');
                        }
                      }
                    }
                    page.data.push(row);
                    // Show next/previous events based on config
                    if (att.previousEvent && config.e.isIncluded) {
                      row = [service.formatOtherEvent(att.previousEvent, 'SB_Previous_Event')];
                      page.data.push(row);
                    }
                    if (att.nextEvent && config.e.isIncluded) {
                      row = [service.formatOtherEvent(att.nextEvent, 'SB_Next_Event')];
                      page.data.push(row);
                    }
                    // Show notes on seperate line for non-transport
                    if (config.n.isIncluded && !page.isTransport) {
                      row = {
                        content: [
                          $filter('translate')('SB_Consent_Notes') + ': ' + (att.consentNote ? att.consentNote : '') + '\n\n',
                          $filter('translate')('SB_Attendance_Note') + ': ' + (att.attendanceNote ? att.attendanceNote : '') + '\n\n',
                        ],
                        settings: { isNoteColumns: true },
                      };
                      page.data.push(row);
                    }
                    // Marker to show a signing area based on user config (and not transport)
                    if ((config.s.isIncluded || config.t.isIncluded) && !page.isTransport) {
                      page.data.push({ content: 'signingArea' });
                    }
                  });
                }
              });
            });
            pages.list.push(page);
          }
        });
        pages.isLandscape = true;
        fileService.buildWithPdfMake(pages, $filter('translate')('SB_Register'), config).then(() => {
          callbackfunction();
        });
      };

      return service;
    },
  ])
  // Controller for modal where user can select file type and configure PDF (save-file-modal.template.html)
  .controller('formatRegisterController', [
    '$scope',
    '$uibModalInstance',
    'fileTypeEnum',
    'registers',
    'createRegisterPDF',
    'createRegisterCSV',
    function ($scope, $uibModalInstance, fileTypeEnum, registers, createRegisterPDF, createRegisterCSV) {
      $scope.fileTypeEnum = fileTypeEnum;
      $scope.regLength = registers.length;
      $scope.fileTypes = Object.values($scope.fileTypeEnum);

      $scope.isLoadingPDF = false;

      var callbackfunction = function () {
        $scope.isLoadingPDF = false;
        $uibModalInstance.close();
      };

      $scope.isLoadingFile = function (fileType) {
        return fileType === fileTypeEnum.PDF && $scope.isLoadingPDF;
      };

      $scope.download = function (fileType) {
        switch (fileType) {
          case fileTypeEnum.PDF:
            $scope.isLoadingPDF = true;
            createRegisterPDF(registers, callbackfunction, fileType.config);
            break;
          case fileTypeEnum.CSV:
            createRegisterCSV(registers);
            $uibModalInstance.close();
            break;
          default:
          // code block
        }
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ]);
