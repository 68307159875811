'use strict';

angular.module('sysadmin.components.sysAdminConfigSettings', [
    'organisation.components.organisationList'
]).component('sysAdminConfigSettings', {
    templateUrl: '/Scripts/app/sysadmin/components/sysadminconfigsettings.html',
    controller: [
    '$scope', 'sysAdminService', 'simpleAlertService',
    function ($scope, sysAdminService, simpleAlertService) {

        $scope.selectedOrganisationId = 0;
        $scope.selectOrganisationSettings = [];

        $scope.showOrganisation = function (anOrganisation) {
            $scope.selectOrganisationSettings = null;
            $scope.selectedOrganisationId = anOrganisation.organisationId;
            sysAdminService.getOrganisationSettings(anOrganisation.organisationId).then(function (data) {
                $scope.selectOrganisationSettings = data;
            }, function () {
                simpleAlertService.errorAlert();
            });
        };

        $scope.updateSettings = function ()
        {
            var orgSettings = [];

            for (var i = 0; i < $scope.selectOrganisationSettings.length; i++)
            {
                orgSettings.push({ id: $scope.selectOrganisationSettings[i].id, value: $scope.selectOrganisationSettings[i].value });
            }

            sysAdminService.updateOrganisationSettings($scope.selectedOrganisationId, orgSettings)
                .then(function (response)
                {
                    if (response)
                    {
                        $scope.selectOrganisationSettings = response.organisationSettings;

                        if (!response.allUpdatesSuccessful && response.errors.length > 0)
                        {
                            var messages = [];
                            for (var i = 0; i < response.errors.length; i++)
                            {
                                messages.push(response.errors[i]);
                            }

                            simpleAlertService.errorAlert({
                                message: messages.join(', ')
                            });
                        }
                        else
                        {
                            simpleAlertService.simpleAlert({ title: 'Save Complete', message: 'Settings saved' });
                        }
                    }
                });
        };
    }
]});
