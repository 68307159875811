import { Injectable } from '@angular/core';
import { CommonChars } from '@sb-shared/globals/common-chars';
import * as FileSaver from 'file-saver-es';
import { FileTypeId, FileTypes } from './../globals/file-types';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  buildCSV(contentData: any[][]) {
    let csv = '';
    const format = string => {
      return '"' + string.replaceAll('"', '""') + '"';
    }
    contentData.forEach(contentRow => {
      if (Array.isArray(contentRow)) {
        contentRow.forEach((cell, cellIndex) => {
          if (!cell) {
            return;
          }
          if (typeof cell === 'string') {
            contentRow[cellIndex] = format(cell);
          }
          else if (cell.nonData) {
            // Remove decorative content from CSV
            contentRow[cellIndex] = '';
          }
          else if (cell.content) {
            // Extract text
            if (cell.content.nonData) {
              contentRow[cellIndex] = '';
            }
            else {
              contentRow[cellIndex] = format(cell.content);
            }
          }
        })
        // Only create new row if there is content for it
        if (contentRow.some(cell => { return cell !== '' })) {
          const row = contentRow.join(CommonChars.Comma);
          csv = csv + row + '\n';
        }
      }
    });
    return csv;
  }

  saveFile(data: any[][], fileTypeId: number, fileName: string) {
    if (!data || !Array.isArray(data) || !fileTypeId || !fileName) {
      return;
    }
    const fileType = FileTypes.find(type => type.id === fileTypeId);
    if (fileType?.format && fileTypeId === FileTypeId.CSV) {
      const formattedContent = this.buildCSV(data);
      const blob = new Blob([formattedContent], { type: fileType?.mime ? fileType.mime : '' });
      return FileSaver.saveAs(blob, fileName + fileType.format);
    }
  }

}
