angular.module('shared.components.sbAlert', [])
    .component('sbAlert', {
            transclude: true,
            templateUrl: '/Scripts/app/shared/components/sb-alert.template.html',
            bindings: {
                message: '@',
                messageXs: '@',
                messageTranslationParameters: '<',
                alertClass: '@',
                alertTitle: '@',
                note: '@',
                noteTranslationParameters: '<'
            },
            controller: class SbAlertCtrl {

                computedClass: string;
                alertClass: string;
                customClasses: string;

                $onChanges(changes) {
                    this.alertClass = this.alertClass || 'info';
                    if (changes.alertClass?.currentValue || changes.customClasses?.currentValue) {
                        this.computedClass = 'alert alert-' + this.alertClass + ' ' + this.customClasses;
                    }
                }
            }
    });