'use strict';

angular.module('ccasignupModule').component('ccaJoinRequests', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-joinrequests.template.html',
    controller:
    [
        '$state',
        function ($state) {
            this.signUpId = $state.params.signUpId;
        }
    ]});
