

angular.module('balance.components.currentUserTransactionView',
    [
        'balance.services.balanceService',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'shared.components.sbModalHeader',
        'shared.directives.sbCheckBox',
        'shared.services.balanceSharedService',
        'shared.services.simpleAlertService'
    ])
    .component('currentUserTransactionView',
        {
            bindings: {
                accountTypeId: '<'
            },
            templateUrl: '/Scripts/app/balance/components/current-user-transaction-view.template.html',
            controller: class CurrentUserTransactionViewCtrl {
                // Dependencies
                $window: any;
                balanceService: any;
                balanceSharedService: any;
                simpleAlertService: any;

                loading: number;
                accountData: any;
                numberOfTransactions: number;
                showSourceTransactions: boolean;
                fetchedSource: boolean;
                sortType: string;
                sortReverse: boolean;
                search: any;
                currencyDisplaySymbol: string;
                accountTypeId: any;

                static $inject = ['$window', 'balanceService', 'balanceSharedService', 'simpleAlertService'];

                constructor (
                    $window,
                    balanceService,
                    balanceSharedService,
                    simpleAlertService) {
                        this.$window = $window;
                        this.balanceService = balanceService;
                        this.balanceSharedService = balanceSharedService;
                        this.simpleAlertService = simpleAlertService;

                        this.loading = 0;
                        this.accountData = null;

                        this.numberOfTransactions = 0;
                        this.showSourceTransactions = false;
                        this.fetchedSource = false;

                        this.sortType = 'date';
                        this.sortReverse = true;
                        this.search = {
                            text: '',
                            // this could benefit from being an organisation setting?
                            from: moment().add(-3, 'months').toDate(),
                            to: moment().toDate()
                        };

                        this.currencyDisplaySymbol = this.$window.EveryBuddy.Constants.CurrencyDisplaySymbol
                    }

                    fetchSource() {
                        // wrap in a digest just to handle changing the model via the label
                        setTimeout(() => {
                            // Only do this once until it is cleared back down.
                            if (!this.showSourceTransactions || this.fetchedSource) {
                                return;
                            }

                            this.tryAndGetTransactions();
                        }, 0);
                    };

                    tryAndGetTransactions() {

                        // make sure we have everything we need...
                        if (!this.accountTypeId)
                            return;

                        this.loading++;
                        this.numberOfTransactions = 0;
                        this.accountData = {
                            accountHolderId: 0,
                            accountHolderName: '',
                            transactions: []
                        };

                        this.balanceService.getTransactions(this.accountTypeId,
                            this.showSourceTransactions,
                            this.search.from,
                            this.search.to)
                            .then(data => {

                                this.accountData.accountHolderId = data.accountHolderId;
                                this.accountData.accountHolderName = data.accountHolderName;

                                var transactions = this.balanceSharedService
                                    .arrangeTransactionData(data.transactions, data.openingBalance, this.showSourceTransactions, this.accountData.accountHolderId);

                                this.fetchedSource = this.showSourceTransactions;

                                this.balanceSharedService
                                    .copyTransactionsWhichShouldBeVisible(transactions, this.accountData.transactions, this.search.from, this.search.to);

                                this.numberOfTransactions = this.accountData.transactions.length;

                            })
                            .catch(err => {
                                this.simpleAlertService.errorAlert();
                                console.error(err);
                            })
                            .finally(() => {
                                this.loading--;
                            });

                    };

                    customFilter() {
                        return value => {
                            return value.accountOwnerId == this.accountData.accountHolderId &&
                                value.description.toLowerCase().indexOf(this.search.text.toLowerCase()) > -1;
                        }
                    };

                    $onChanges(changes) {

                        if (changes.accountTypeId && changes.accountTypeId.currentValue) {
                            this.tryAndGetTransactions();
                        }
                    };
                }
        });
