// https://github.com/angular/angular.js/issues/734#issuecomment-78202524
angular.module('shared.directives.ngBindHtmlPlaceholder', [])
    .directive('ngBindHtmlPlaceholder', ['$sce','$filter',
        function ($sce, $filter) {
            return {
                restrict: 'A',
                link: function ($scope, element, attrs) {
                    var div: any = document.createElement('div');

                    attrs.$observe('ngBindHtmlPlaceholder', function (value) {
                        div.innerHTML = value;                      
                        element.attr('placeholder', $sce.trustAsHtml(div.textContent));
                    });
                }
            };
    }]);