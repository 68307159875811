import { Component, OnInit } from '@angular/core';
import { AboutService } from '@sb-core/services/about.service';
import { TableColumns } from '@sb-shared/models/UI/table';

@Component({
  selector: 'sb-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  isLoading = true;
  versionItems = {};
  versions = [];

  tableColumns: TableColumns = [
    {
      id: 'name',
      name: 'SB_Name',
      isDefaultSort: true,
      semiBold: true
    },
    {
      id: 'version',
      name: 'SB_Version'
    }
  ];

  constructor(private aboutService: AboutService) {
  }

  ngOnInit(): void {
    this.aboutService.getSiteVersion().subscribe((data: string) => {
      return this.addVersion("SPA", data);
    });

    this.aboutService.getComponentVersions().subscribe(
      data => {
        data.forEach(item => {
          this.addVersion(item.name, item.version);
        });
      }
    );
  }

  private addVersion(subsystem: string, version: string) {
    this.versionItems[subsystem] = version;
    
    this.versions = Object.keys(this.versionItems).map(key =>
      {
        return {
          name: key,
          version: this.versionItems[key]
        }
      }).sort((a, b) => a.name.localeCompare(b.name));

    if (this.versions.length >= 4) {
      this.isLoading = false;
    }
  }
}
