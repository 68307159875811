<ng-multiselect-dropdown *ngIf="computedSettings && data"
                            [settings]="computedSettings"
                            [placeholder]="placeholder | translate"
                            [data]="data"
                            [ngModel]="model"
                            (ngModelChange)="change($event)"
                            (click)="onClick.emit()"
                            [ngClass]="{'multiselect-all-selected' : allSelected()}"
                            [disabled]="disabled"
                            #multiSelect>
</ng-multiselect-dropdown>
