export interface MemberType {
    label: string;
    labelSingle?: string;
    addButtonLabel?: string;
    id: number;
    altId?: string;
    rule?: string;
    iconName?: string;
}

export const MemberTypes: {[key: string]: MemberType} = {
    All: {
        label: 'SB_All',
        labelSingle: 'SB_All',
        addButtonLabel: 'SB_Add_All_Members',
        id: 0
    },
    Student:
    {
        label: 'SB_Pupils',
        labelSingle: 'SB_Student',
        addButtonLabel: 'SB_Add_Students',
        id: 1,
        altId: 'pu',
        rule: 'isStudent',
        iconName: 'userTypeStudent'
    },
    Parent:
    {
        label: 'SB_Parents',
        labelSingle: 'SB_Parent',
        id: 2,
        altId: 'pa',
        addButtonLabel: 'SB_Add_Parents',
        rule: 'isParent',
        iconName: 'userTypeParent'
    },
    Staff:
    {
        label: 'SB_Staff',
        labelSingle: 'SB_Staff',
        id: 3,
        altId: 'st',
        addButtonLabel: 'SB_Add_Staff',
        rule: 'isStaff',
        iconName: 'userTypeStaff'
    },
    ExternalCoach:
    {
        id: 4,
        altId: 'esu',
        label: 'SB_External_Coach',
        labelSingle: 'SB_External_Coach',
        iconName: 'profile',
    },
    TransportBusMonitor:
    {
        id: 5,
        altId: 'tbm',
        label: 'SB_Transport_Bus_Monitor',
        labelSingle: 'SB_Transport_Bus_Monitor',
        iconName: 'transport-bus',
    }
}
