'use strict';

angular.module('shared.components.sbDateRangePicker',
    [
        'angularjs.daterangepicker',
        'pascalprecht.translate',
        'shared.components.sbIcon',
        'shared.services.dateTimeService',
    ]
)
    .component('sbDateRangePicker',
        {
            bindings: {
                customOptions: '<',
                isRequired: '<',
                dateRangeList: '<',
                disabled: '<',
                minDate: '<',
                maxDate: '<',
                startDate: '=',
                endDate: '=',
                singleDatePicker: '<',
                timePicker: '<',
                autoUpdate: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-date-range-picker.template.html',
            controller: [
                '$translate', '$filter', '$window', '$timeout', 'dateTimeService', 'dateRanges',
                function ($translate, $filter, $window, $timeout, dateTimeService, dateRanges)
                {
                    this.dateRanges = dateRanges;

                    this.previousStartDate = null;
                    this.previousEndDate = null;

                    this.$onChanges = function (changes) {
                        // daterangepicker does not like null values for minDate and maxDate
                        // without doing this the prev/next arrows for changing month will not be visible
                        if (changes.minDate && this.minDate === null) {
                            this.minDate = undefined;
                        }
                        if (changes.maxDate && this.maxDate === null) {
                            this.maxDate = undefined;
                        }

                        if (!this.previousStartDate) {
                            this.previousStartDate = this.startDate;
                            this.previousEndDate = this.endDate;
                        }
                    }

                    this.onDateChange = function () {
                        let triggerOnChange = false;

                        if (this.startDate && !this.previousStartDate?.isSame?.(this.startDate)) {
                            this.previousStartDate = this.startDate;
                            triggerOnChange = true;
                        }

                        if (!this.singleDatePicker && this.endDate && !this.previousEndDate?.isSame?.(this.endDate)) {
                            this.previousEndDate = this.endDate;
                            triggerOnChange = true;
                        }

                        if (triggerOnChange) {
                            this.onChange();
                        }
                    }

                    $translate.onReady().then(() => {
                        $timeout(()=> {
                            var months = dateTimeService.shortMonthNames;
                            var days = dateTimeService.shortDayNames;

                            var defaultOptions = {
                                showWeekNumbers: true,
                                locale: {
                                    format: $window.innerWidth > 650 ? 'D MMMM, YYYY' : 'D MMM, YYYY',
                                    monthNames: months,
                                    daysOfWeek: days,
                                    customRangeLabel: $filter('translate')('SB_Custom_Range'),
                                    firstDay: dateTimeService.firstDayOfWeek
                                },
                                minDate: this.minDate,
                                maxDate: this.maxDate
                            };

                            this.customOptions = this.customOptions || [];
                            this.customOptions.autoApply = this.autoUpdate;
                            this.options = {
                                ...defaultOptions,
                                ...this.customOptions,
                                singleDatePicker: this.singleDatePicker,
                                timePicker: this.timePicker,
                                timePicker24Hour: true
                            };
                            this.options.singleDatePicker = this.singleDatePicker;

                            if (this.dateRangeList)
                            {
                                this.options.ranges = {};
                                this.options.showCustomRangeLabel = this.dateRangeList.showCustom ? this.dateRangeList.showCustom : true;
                                this.dateRanges.forEach((range) => {
                                    var dateMatch = (!this.minDate || range.range[0].isSameOrAfter(this.minDate, 'd')) && (!this.maxDate || range.range[1].isSameOrBefore(this.maxDate, 'd'));
                                    if (this.dateRangeList.ranges.includes(range.id) && dateMatch)
                                    {
                                        (this.options.ranges[$filter('translate')(range.label)] = range.range);

                                    }
                                });
                            }
                            else
                            {
                                this.options.showCustomRangeLabel = false;
                            }
                        }, 300);
                    });
                }
            ]
        });
