import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { ForbiddenErrorService } from '@sb-shared/services/forbidden-error.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private forbiddenErrorService: ForbiddenErrorService,
    private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const noAuthHeaderMarkers = ['.html', '.json', '.css', '.svg', '.png', '.jpeg'];

    const requiresAuthentication = !noAuthHeaderMarkers.some(marker => req.url.includes(marker)) &&
      (req.url.startsWith(environment.apiUrl) || req.url.startsWith(environment.oldApiUrl));

    // for API requests requiring authentication, check if token is injected or has expired.
    const request$ = requiresAuthentication
      ? this.auth.getTokenAndRefreshIfExpired().pipe(map(accessToken => {
        // Use provided token (may have been refreshed) in header, overriding any existing token.
        return accessToken
          ? req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}`, 'X-Requested-With': 'XMLHttpRequest' } })
          : req.clone({ setHeaders: { 'X-Requested-With': 'XMLHttpRequest' } })
      }))
      : of(req);

    return request$.pipe(switchMap(request => next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status && (error.status > 400 && error.status < 404)) {
            this.forbiddenErrorService.raiseError(error);
          }

          return throwError(() => error);
        })
      )));
  }
}
