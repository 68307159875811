angular.module('transportModule')
    .component('transportChangeRequestsPage', {
        templateUrl: '/Scripts/app/transport/changeRequests/transport-change-requests.template.html',
        controller: class TransportChangeRequestsPageCtrl {

            $transitions: any;
            subMenuItems: any;
            isLoading: boolean;

            static $inject = ['$transitions'];

            constructor($transitions) {
                this.$transitions = $transitions;
            }

                $onInit() {
                    this.subMenuItems = {
                        items: [{
                            title: "SB_Transport_Pending_Change_Requests",
                            route: ".transportPendingChangeRequests",
                            activeMenu: true
                        },
                        {
                            title: "SB_Transport_Change_Request_UpcomingApprovedRejected",
                            route: ".changeRequestsApprovedOrRejected"
                        }]
                    };
                    this.isLoading = false;

                    this.$transitions.onStart({}, () => {
                        this.isLoading = true;
                    })

                    this.$transitions.onFinish({}, ()=>{
                        this.isLoading = false;
                    })

                }
            }
        }
    );
