'use strict';

angular.module('balanceAdmin.massAdjustmentGroup.components.massAdjustmentGroup',
    [
        'balanceAdmin.massAdjustmentGroup.components.massAdjustmentGroupFilter',
        'balanceAdmin.massAdjustmentGroup.components.massAdjustmentGroupDetail',
    ])
    .component('massAdjustmentGroup',
        {
            bindings: {
            },
            templateUrl: '/Scripts/app/balanceAdmin/massAdjustmentGroup/components/mass-adjustment-group.template.html',
            controller: [
                'balanceAdminService',
                function (balanceAdminService)
                {
                    this.isUserAdvancedFeeAdmin = false;

                    this.$onInit = function ()
                    {
                        balanceAdminService.isAdvancedFeeAdmin()
                            .then(function (isUserAdvancedFeeAdmin)
                            {
                                this.isUserAdvancedFeeAdmin = isUserAdvancedFeeAdmin;
                            }.bind(this));
                    }

                    // Set-up our filters.
                    // Default search window to last three months.
                    this.searchFilters =
                    {
                        eventsFrom: moment().startOf('day').add(-3, 'months'),
                        eventsTo: moment().endOf('day').add(1, 'years')
                    }

                    this.quickAdjust = {};

                    this.showQuickAdjust = function() {
                        if (typeof this.quickAdjust.fn == 'function') {
                            this.quickAdjust.fn();
                        }
                    }.bind(this);
                }
            ]
        });