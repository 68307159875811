<sb-button [message]="message"
           [messageXs]="messageXs"
           [messageTranslationParameters]="messageTranslationParameters"
           [buttonClass]="buttonClass"
           [customClasses]="customClasses"
           [buttonId]="buttonId"
           [iconName]="iconName"
           [xsIconOnly] ="xsIconOnly"
           [isLoading] ="isLoading || isSubmitted"
           (onClick)="onSubmitClick()"
           [isDisabled]="isDisabled || isSubmitted || isLoading"
           buttonType="submit"
           [buttonId]="buttonId"
           [hidden]="!isShowing">
    <ng-content></ng-content>
</sb-button>