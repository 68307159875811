'use strict';

angular.module('transport.services.transportTimetablesService', [])
    .factory('transportTimetablesService', [
        '$http',
        function ($http)
        {
            var urlBase = '/webapi/WebOrganisationTransportTimetable/';
            var archivingUrlBase = '/webapi/WebOrganisationTransportArchiving/';
            var service = {};

            service.createTransportTimetable = function (transportTimetable)
            {
                return $http.post(urlBase + 'CreateTransportTimetable', transportTimetable )
                    .then(function (response) { return response.data; });
            };

            service.getTransportTimetables = function ()
            {
                return $http.get(urlBase + 'GetTransportTimetables')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportTimetable = function (timetableId)
            {
                return $http.get(urlBase + 'GetTransportTimetable',
                    {
                        params:
                        {
                            timetableId: timetableId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportTimetableDataForEdit = function (timetableId)
            {
                return $http.get(urlBase + 'GetTransportTimetableDataForEdit',
                    {
                        params:
                        {
                            timetableId: timetableId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTimetablesForTimeSlot = function (timeSlotId, isForDeletedCalendar = false)
            {
                return $http.get(urlBase + 'GetTimetablesForTimeSlot',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId,
                            isForDeletedCalendar: isForDeletedCalendar
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.editTransportTimetable = function (timetable)
            {
                return $http.put(urlBase + 'EditTimetable', timetable)
                    .then(function (response) { return response.data; });
            };

            service.saveTimetableRoutes = function (saveRequest)
            {
                return $http.put(urlBase + 'UpdateTimetableRoutes', saveRequest)
                    .then(function (response) { return response.data; });
            };

            service.cloneTimetableRoute = function (clonedRoute)
            {
                return $http.post(urlBase + 'CloneTimetableRoute', clonedRoute)
                    .then(function (response) { return response.data; });
            };

            service.getTimeTableGroupedByTimeSlotAndOperatingDay = function (timeSlotId, organisationOperatingDayId)
            {
                return $http.get(urlBase + 'GetTimeTableGroupedByTimeSlotAndOperatingDay',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId,
                            organisationOperatingDayId: organisationOperatingDayId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTimetableForTimeSlotAndEventDateGroupByDayAsync = function (timeSlotId, eventDate, organisationOperatingDayId)
            {
                return $http.get(urlBase + 'GetTimetableForTimeSlotAndEventDateGroupByDay',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId,
                            eventDateLocal: eventDate,
                            organisationOperatingDayId: organisationOperatingDayId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTimetableForTimeSlotCalendarAndEventDateGroupByDayAsync = function (timeSlotId, eventDate, organisationOperatingDayId)
            {
                return $http.get(urlBase + 'GetTimetableForTimeSlotCalendarAndEventDateGroupByDay',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId,
                            eventDateLocal: eventDate,
                            organisationOperatingDayId: organisationOperatingDayId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportTimetableArchivingInfo = function (transportTimetableId)
            {
                return $http.get(archivingUrlBase + 'GetTransportTimetableArchivingInfo', { params: { transportTimetableId: transportTimetableId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.archiveTimetable = function (transportTimetable)
            {
                return $http.put(archivingUrlBase + 'ArchiveTimetable', { Id: transportTimetable.id })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.deleteEventsForDay = function (transportTimetableId, timetableOperatingDayId)
            {
                return $http.put(archivingUrlBase + 'DeleteEventsForDay', { TimetableId: transportTimetableId, timetableOperatingDayId: timetableOperatingDayId })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            return service;
        }
    ]);