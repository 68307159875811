import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sb-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent {

  @Input() label: string;
  @Input() labelIconName: string;
  @Input() fieldName: string;
  @Input() sortType: string;
  @Output() sortTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() sortReverse: boolean;
  @Output() sortReverseChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() toolTip: string;
  // @Output() onClick: EventEmitter<void> = new EventEmitter();

  click() {
    if (this.sortType) {
      this.sortType = this.fieldName;
      this.sortTypeChange.emit(this.sortType);
      this.sortReverse = !this.sortReverse;
      this.sortReverseChange.emit(this.sortReverse);
    }
  }
}
