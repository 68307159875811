'use strict';

angular.module('ccasignupModule').component('ccaSignUpDetailsAAManageEvents', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-detailsaa-manage-events.template.html',
    bindings: {
        selectedSignUpType: '<'
    },
    controller:
        [
            '$scope',
            '$state',
            '$filter',
            '$translate',
            '$interval',
            'calendarEventCreationRuleService',
            'signUpRepository',
            'ccaDataProperties',
            function ($scope,
                $state,
                $filter,
                $translate,
                $interval,
                calendarEventCreationRuleService,
                signUpRepository,
                ccaDataProperties
            ) {
                this.$onChanges = (changes) => {
                    if (changes.selectedSignUpType && changes.selectedSignUpType.currentValue) {
                        $scope.ccaSignUpType = this.selectedSignUpType;
                        if ($scope.refreshSignUp) {
                            $scope.refreshSignUp();
                        }
                    }
                };

                this.$onInit = () => { // Not indented to avoid merge issues
                    $scope.signUpId = $state.params.signUpId;
                    $scope.hidePublishedRules = true;
                    $scope.searchFilters = {};
                    $scope.showPublishColumn = false;

                    $scope.rules = [];

                    $interval(() => {
                        $scope.refreshSignUp();
                    }, ccaDataProperties.DefaultRefreshTimeout);

                    $scope.refreshSignUp = () => {
                        if (!$scope.loading && !$scope.signUpGroups) {
                            $scope.loading = 1;

                            signUpRepository.getSignUp($state.params.signUpId)
                                .then((signUp) => {
                                    $scope.signUp = signUp;
                                    $scope.loading--;

                                    if ($scope.loading === 0) {
                                        if ($scope.$parent) {
                                            // update parent view with any change in sign up state (generating, active etc.)
                                            $scope.$parent.signUp = signUp;
                                        }

                                        if (!$scope.eventsGenerating()) {
                                            $scope.getSignUpDetails();
                                        }
                                    }
                                });
                        }
                    };

                    // Loading signUp
                    $scope.refreshSignUp();

                    $scope.getSignUpDetails = () => {
                        // Only run other requests if signUp is not generating

                        $scope.loading += 3;

                        // Groups and organisers (2 requests)
                        signUpRepository
                            .getSignUpGroups($scope.signUpId)
                            .then((data) => {
                                $scope.signUpGroups = data;

                                let personIds = $scope.signUpGroups.map(x => x.organiserId);

                                signUpRepository
                                    .getOrganisersForStaffPersonIds(personIds)
                                    .then((organisers) => {
                                        $scope.organisers = organisers;
                                    })
                                    .finally(() => {
                                        $scope.loading--;
                                        $scope.refreshFilters();
                                    });
                            });


                        // Event creation rule
                        calendarEventCreationRuleService
                            .getCalendarEventCreationRulesForSignUp($scope.signUpId)
                            .then((data) => {
                                if (!data.isError) {
                                    $scope.rules = data;
                                    $scope.loading--;
                                }
                            });

                        // Locations
                        $translate.onReady().then(() => {
                            signUpRepository
                                .getLocations()
                                .then((locations) => {
                                    $scope.locations = locations;
                                    var location = $filter('translate')('SB_Unspecified_location');
                                    $scope.locations.splice(0, 0, { id: 0, name: location });
                                    $scope.loading--;
                                });
                        });

                    };

                    $scope.selectRule = function (rule) {
                        $state.go('ccaDetailsAA.manageEventsDetail', { selectedItemId: rule.ccaCalendarEventCreationRuleId });
                    };

                    $scope.refreshFilters = function () {
                        if ($scope.loading == 0) {
                            $scope.uniqueTeams = [];

                            var i;
                            var uniqueTeamIdItems = $filter('unique')(this.rules, 'teamId');

                            for (i = 0; i < uniqueTeamIdItems.length; i++) {
                                $scope.uniqueTeams.push({
                                    id: uniqueTeamIdItems[i].teamId, text: $scope.signUpGroups.filter(function (item) { return item.id === uniqueTeamIdItems[i].teamId; })[0].title
                                });
                            }

                            $scope.uniqueTeams.sort($scope.sortByText);
                            $scope.uniqueTeams.splice(0, 0, { id: 0, text: '' });

                            $scope.uniqueLocations = [];

                            var uniqueLocationIdItems = $filter('unique')(this.rules, 'locationId');

                            for (i = 0; i < uniqueLocationIdItems.length; i++) {

                                $scope.uniqueLocations.push({
                                    id: uniqueLocationIdItems[i].locationId, text: $scope.locations.filter(function (item) {
                                        return item.id === uniqueLocationIdItems[i].locationId;
                                    })[0].name
                                });
                            }

                            $scope.uniqueLocations.sort($scope.sortByText);
                            $scope.uniqueLocations.splice(0, 0, { id: -1, text: '' });

                            $scope.uniqueOrganisers = [];

                            var uniqueOrganiserPersonIdItems = $filter('unique')(this.rules, 'organiserPersonId');

                            for (i = 0; i < uniqueOrganiserPersonIdItems.length; i++) {

                                $scope.uniqueOrganisers.push({
                                    id: uniqueOrganiserPersonIdItems[i].organiserPersonId, text: $scope.organisers.filter(function (item) {
                                        return item.id === uniqueOrganiserPersonIdItems[i].organiserPersonId;
                                    })[0].name
                                });
                            }

                            $scope.uniqueOrganisers.sort($scope.sortByText);
                            $scope.uniqueOrganisers.splice(0, 0, { id: 0, text: '' });
                        }

                    };

                    $scope.deleteRule = function (rule) {
                        signUpRepository.postDeleteGroupEventNoReturns($scope.signUp.ccaSignUpId,
                            rule.teamId,
                            rule.ccaCalendarEventCreationRuleId)
                            .then(function () {
                                if (rule === $scope.selectedRule) {
                                    $scope.selectedRule = null;
                                }

                                var index = $scope.rules.indexOf(rule);
                                if (index > -1) {
                                    $scope.rules.splice(index, 1);
                                }
                            });
                    };

                    $scope.copyRule = function (rule) {
                        $state.go('ccaDetailsAA.manageEventsCopy', { copyItemId: rule.ccaCalendarEventCreationRuleId });
                    };

                    $scope.add = function () {
                        $state.go('ccaDetailsAA.manageEventsAdd');
                    };

                    $scope.sortByText = function (a, b) {
                        return a.text.localeCompare(b.text);
                    };

                    $scope.eventsGenerating = () => {
                        return $scope.signUp?.status === 2 || $scope.signUp?.status === 5;
                    };

                    $scope.viewPublishedEvents = () => {
                        $state.go($scope.signUp.isPTC ? 'ccaDetailsAA.existingEventsTimeSlotted' : 'ccaDetailsAA.existingEvents');
                    };
                }

            }
        ]
});
