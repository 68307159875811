import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router, RoutesRecognized } from '@angular/router';
import { MainMenuItemId } from '@sb-events/enums/main-menu-item';
import { AvatarButtonAction } from '@sb-shared/constants/shared.constant';
import { Organisation } from '@sb-shared/models/organisation';
import { Button } from '@sb-shared/models/UI/buttons';
import { MainMenuItem, MenuItem } from '@sb-shared/models/UI/menu-item';
import { UserUi } from '@sb-shared/models/user-ui';
import { DeviceService } from '@sb-shared/services/device.service';
import { LocaleService } from '@sb-shared/services/locale.service';
import { MenuService } from '@sb-shared/services/menu.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { UserService } from '@sb-shared/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, map, pairwise, share } from 'rxjs/operators';
import { Ng1Translate } from 'src/app/ng1-services/ng1-services.module';
import { AuthService } from '../../../auth/services/auth.service';
import { DocTitleService } from './../../services/doc-title.service';

const sideMenuOpenCookieName = 'sideMenuOpen';
@Component({
  selector: 'sb-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  totalMenuBadgeCount$: Observable<number>;
  unreadMsgCount$: Observable<number>;
  currentUser$: Observable<UserUi>;
  currentOrganisation: Organisation;
  mobileMenuIsCollapsed = true;
  scriptDirection$: Observable<string> = null;
  isSideMenuOpen = false;
  isPageSideMenuOpen = false;
  translationsReady = false;
  moduleClass: string;
  avatarDropdownItems: Button[];
  rightMenuItems: MainMenuItem[];
  currentMainMenuItem: MainMenuItem;
  isLoadingPage = true;
  isMobileApp: boolean;
  mainMenuItemId = MainMenuItemId;
  readonly AvatarButtonAction = AvatarButtonAction;

  constructor(
    private menu: MenuService,
    private user: UserService,
    private organisation: OrganisationService,
    private locale: LocaleService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private ng1Translate: Ng1Translate,
    private renderer: Renderer2,
    private cookie: CookieService,
    private device: DeviceService,
    private docTitle: DocTitleService,
    private location: Location,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.unreadMsgCount$ = this.menu.getMainMenuItems()
      .pipe(map((menuItems) => menuItems.find(item => item.menuItemTypeId === MainMenuItemId.Messaging)?.count))
    this.totalMenuBadgeCount$ = this.menu.getMainMenuItems()
      .pipe(map((menuItems) => menuItems.filter(item => [MainMenuItemId.Messaging, MainMenuItemId.Diary].some(id => item.menuItemTypeId == id)).reduce((acc, item) => acc + item.count, 0)))
    this.currentUser$ = this.user.getCurrentUser().pipe(share());
  }

  ngOnInit(): void {
    // Handle mobile app header
    this.route.queryParams.subscribe(params => {
      if (params.logOut) {
        this.logOut();
      }
      if (params.isMobileApp) {
        this.isMobileApp = true;
        this.renderer.addClass(this.document.body, 'mobile-app');
      }
      if (params.isModalOnly) {
        this.renderer.addClass(this.document.body, 'modal-only');
      }
      if (params.showModalType) {
        this.renderer.addClass(this.document.body, 'modal-only');
        this.renderer.addClass(this.document.body, `modal-type-${params.showModalType}`);
      }

      if (params.hideSubMenu) {
        this.menu.setShowSubMenu(false);
      }
    });

    this.ng1Translate.onReady(() => {
      this.docTitle.initialise();

      this.subscriptions.add(forkJoin([
        this.organisation.getCurrentOrganisation(),
        this.menu.getMainMenuItems(),
        this.menu.getAvatarMenuItems(),
      ])
        .subscribe(([
          organisation,
          mainMenuItems,
          avatarMenuItems,
        ]: [
            Organisation,
            MainMenuItem[],
            MenuItem[],
          ]) => {
          this.currentOrganisation = organisation;
          this.rightMenuItems = mainMenuItems
            .filter(menuItem => menuItem.isRightMenu).sort((a, b) => a.sortOrder - b.sortOrder);

          this.avatarDropdownItems = mainMenuItems
            .filter(menuItem => menuItem.isAvatarMenu)
            .map(menuItem => <MenuItem>menuItem)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .concat(avatarMenuItems)
            .map(menuItem => <Button>({
              ...menuItem,
              message: menuItem.label
            }));
          this.scriptDirection$ = this.locale.getScriptDirection();

          this.subscriptions.add(this.menu.currentMainMenuItem.subscribe(mainMenuItem => {
            this.currentMainMenuItem = mainMenuItem;
            this.isPageSideMenuOpen = mainMenuItem?.isAlwaysOpen;
            this.isLoadingPage = false;
          }));

          this.subscriptions.add(this.router.events.pipe(filter(e => (e instanceof ActivationEnd)))
            .subscribe(() => {
              // Close menu when navigating unless XXL device
              if (!this.device.isXxlDevice()) {
                this.isSideMenuOpen = false;
              }
            })
          );
        })
      );
    });

    this.startMobileAppQueryPropagation();

    // Get menu expanded state
    this.isSideMenuOpen = this.device.isXxlDevice() && this.cookie.get(sideMenuOpenCookieName) === '1';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe;
    this.docTitle.destroy();
  }

  logOut() {
    this.auth.logout();
  }

  toggleSideMenu() {
    this.isSideMenuOpen = !this.isSideMenuOpen;
    this.cookie.set(sideMenuOpenCookieName, this.isSideMenuOpen ? '1' : '0');
  }

  onClickContent() {
    // Close sidemenu when clicking on the main page (unless xxl device with room for both)
    if (!this.device.isXxlDevice()) {
      this.isSideMenuOpen = false;
    }
  }


  /**
   * Each time the route is changed this checks the current URL query string to see if configured for mobile app
   * and if the destination URL is missing the required query it will append it.
   */
  private startMobileAppQueryPropagation() {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        // the current URL cannot be retrieved from the events for this, as it will not always include the query string
        const currentUrl = this.location.path();
        const newUrl = events[1].urlAfterRedirects;
        const mobileAppMarker = 'isMobileApp=true';

        if (currentUrl.includes(mobileAppMarker) && !newUrl.includes(mobileAppMarker)) {
          setTimeout(() => {
            this.location.replaceState(this.location.path(), mobileAppMarker)
          })
        }
    });
  }
}
