import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { LocalStorageService } from '@sb-shared/services/local-storage.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class Ng1StateService {

  params: any;
  ng2Params: any;
  current: any;
  currentModule: Subject<string> = new Subject<string>();
  storageName = 'ng1StateParams';
  constructor(
    private router: Router,
    private storage: LocalStorageService
  ) {
    this.setPageInfo();
    this.router.events.pipe(filter(e => (e instanceof ActivationEnd)))
      .subscribe(() => {
        this.setPageInfo();
      });
  }

  public setPageInfo() {
    this.params = { ...this.get(), searchData: this.params?.searchData || {} };
    this.current = this.getCurrent();
  }

  go(routeName: string, parameters, module?: string): Promise<boolean> {

    if (routeName === 'mobile-modal-close') {
      window.location.href = `${environment.oldApiUrl}/mobileapp/loading`;
    }

    this.storage.set(this.storageName, parameters);
    const routes = this.getModuleRoutes(module);
    const currentName = this.getCurrent()?.name;
    const selectedRoute = routes.find(route => {
      const relativeRouteName = route.name.replace(currentName, CommonChars.Blank);
      const getRouteNameString = name => name.replace(CommonChars.Period, CommonChars.Blank);
      // check for match to absolute or relative route
      return getRouteNameString(routeName) === getRouteNameString(route.name) || getRouteNameString(routeName) === getRouteNameString(relativeRouteName);
    });

    if (!selectedRoute) {
      console.warn('Unrecognised route: ', routeName)
      return Promise.resolve(true);
    }

    let url = selectedRoute.module;
    const selectedRouteArray = selectedRoute?.name.split(CommonChars.Period);
    let slashCount = selectedRouteArray.length;
    const slash = '/';
    selectedRouteArray.forEach((item, index) => {
      const currentRoute = routes.find(route => {
        return route.name.split(CommonChars.Period)[index] === item;
      });
      if (!currentRoute) {
        console.error('Current route not found: ', item);
        return;
      }

      const currentUrl = currentRoute.url;
      // Some routes have more than one slash
      currentUrl?.split(slash).forEach((currentUrlSlug, urlSlugIndex) => {
        // Append slash to url slug
        if (urlSlugIndex > 0 && url[url.length - 1] !== slash) {
          url += slash;
        }
        // Update number of slashes (levels in url)
        if (index > 0) {
          slashCount++;
        }
        // loop though any route params which using '-'
        const slugSections = currentUrlSlug.split('-');
        slugSections.forEach((section, sectionIndex) => {
          const slugArray = section.split(':')
          const paramName = slugArray[1];
          if (sectionIndex > 0) {
            // Add back '-'
            url += '-';
          }
          else if (paramName) {
            // Add back slash
            url += slugArray[0];
          }
          if (paramName && parameters && parameters[paramName] !== undefined) {
            // Add route param
            url += parameters[paramName];
          }
          else if (slugArray.length > 1) {
            // Edge case for lack of AngularJs param - check url for current browser param
            const missingNumber = this.router.url.split(slash)[slashCount];
            const missingNumber2 = this.router.url.split(slash)[slashCount + 1];
            if (parseInt(missingNumber)) {
              url += missingNumber;
            }
            // Try next slug along :/
            else if (parseInt(missingNumber2)) {
              url += missingNumber2;
            }
          }
          else {
            url += section;
          }
        })
      });
    });

    return this.router.navigateByUrl(url);
  }

  transitionTo(routeName, parameters) {
    this.go(routeName, parameters);
  }

  includes(parameter: string): boolean {
    return this.get()[parameter] !== undefined;
  }

  reload() {
    window.location.reload();
  }

  getCurrent(): any {
    const urlSeparator = '/';
    const currentUrlArray = this.router.url.split(urlSeparator);
    const routes = this.getModuleRoutes();
    if (Array.isArray(routes)) {
      let current;
      currentUrlArray.forEach((slug, index) => {
        const matchSlug = (includeParamSlugs: boolean): any => {
          let matchedRoute;
          routes.forEach(route => {
            // Check route is matched
            const match = route?.url?.replace(urlSeparator, CommonChars.Blank) === currentUrlArray[index]?.replace(urlSeparator, CommonChars.Blank) || (includeParamSlugs && route?.url?.indexOf(':') > -1);
            if (!match) {
              return;
            }
            // Check if route is top-level
            const routePartsArray = route.name.split(CommonChars.Period);
            const topLevel = routePartsArray.length === 1;
            if (topLevel) {
              matchedRoute = route;
              return;
            }
            // Check route is child of last matched route
            const matchedNames = routePartsArray.filter(routeName => {
              return current?.name.indexOf(routeName) > -1;
            });
            const parentMatch = matchedNames.length === routePartsArray.length - 1
            if (parentMatch) {
              matchedRoute = route;
            }
          })
          return matchedRoute;
        }
        // Prioritise exact match over route parameter
        current = matchSlug(false) || matchSlug(true) || current;
      });
      return current;
    }
    else {
      console.log('No ng1 routes found');
    }
  }

  getModuleRoutes(module?: string): any[] {
    const matchModule = module || this.router.url.split('/')[1].split('?')[0]
    // return (this.storage.get('ng1Routes') || []).filter(route => {
    return (window.EveryBuddy.ng1Routes || []).filter(route => {
      return route.module === matchModule;
    });
  }

  setParam(name: string, value: any, merge?: boolean): void {
    this.params[name] = merge ? { ...this.params[name], value } : value;
    this.storage.set(this.storageName, this.params);
  }

  get() {
    return this.storage.get(this.storageName) || {};
  }

  clearParam(name: string) {
    delete this.params[name];
    this.storage.set(this.storageName, this.params);
  }
}
