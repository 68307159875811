// start:ng42.barrel
import './reception-allgroups-add-task-popup.controller';
import './reception-allgroups-attendance.controller';
import './reception-allgroups-comms.controller';
import './reception-allgroups-details.controller';
import './reception-allgroups-diary.controller';
import './reception-allgroups-formtags.controller';
import './reception-allgroups-guardians.controller';
import './reception-allgroups-joinrequests.controller';
import './reception-allgroups-medical.controller';
import './reception-allgroups-pupils.controller';
import './reception-allgroups-staff.controller';
import './reception-allgroups-tasks.controller';
import './reception-allgroups.controller';
import './reception-document-store.controller';
// end:ng42.barrel

