import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options } from '@sb-shared/models/UI/filter';
import { Option } from './../../models/UI/filter';

@Component({
  selector: 'sb-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() data: Options;
  @Input() singleSelection: boolean;
  @Input() idProp = 'id';
  @Input() labelProp = 'name';
  @Input() allLabel: string;
  @Input() placeholder: string;
  @Input() settings: any;
  // Convert between array of options and single or array of values
  @Input() reformat: boolean;
  @Input() disabled: boolean;
  @Input() doNotTranslateOptions: boolean;
  computedSettings: any;
  isOpened = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onSelectAll = new EventEmitter<any>();
  @Output() onDeselect = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  @ViewChild('multiSelect', { read: ElementRef }) multiSelect: ElementRef;

  ngOnInit() {
    this.updateAllSelectedLabel();
    this.translate.onLangChange.subscribe(() => {
      this.updateAllSelectedLabel();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.computedSettings = {
      ...this.settings,
      singleSelection: this.singleSelection,
      allowSearchFilter: this.data?.length > 5,
      idField: this.idProp,
      textField: this.labelProp,
      selectAllText: this.translate.instant('SB_Select_All'),
      unSelectAllText: this.translate.instant('SB_UNSelect_All'),
      noDataAvailablePlaceholderText: this.translate.instant('SB_No_Results_Found'),
      closeDropDownOnSelection: this.singleSelection
    };
    if (changes.allLabel) {
      this.updateAllSelectedLabel();
    }
    if (changes.model?.currentValue != null && this.reformat) {
      if (this.singleSelection) {
        this.model = this.data.find(option => option.id === this.model);
      } else {
        this.model = this.data.filter(option => this.model.includes(option.id));
      }
    }
    const translateOptions = array => {
      if (!Array.isArray(array)) {
        return array;
      }
      array.forEach(item => {
        item[this.labelProp] = this.doNotTranslateOptions ? item[this.labelProp] : this.translate.instant(item[this.labelProp]);
      });
      return array;
    }
    if (changes.data?.currentValue) {
      this.data = translateOptions(this.data);
    }
    if (changes.model?.currentValue != null) {
      this.model = translateOptions(this.model);
    }
  }

  change(event: Options) {
    if (event === this.model) return;

    let newData;
    if (this.reformat && Array.isArray(event)) {
      if (this.singleSelection) {
        newData = event[0]?.id;
      } else {
        newData = event.map(option => option.id);
      }
    } else {
      newData = event;
      this.model = newData;
    }
    this.modelChange.emit(newData);
    this.updateAllSelectedLabel();
  }

  updateAllSelectedLabel() {
    setTimeout(() => {
      if (!this.allSelected()) {
        return;
      }
      const dropdownButton = this.multiSelect?.nativeElement?.firstChild.firstChild.firstChild;
      if (dropdownButton) {
        dropdownButton.setAttribute('content', this.translate.instant(this.allLabel || 'SB_All'));
      }
    })
  }

  allSelected() {
    return !this.singleSelection && this.model?.length === this.data?.length;
  }

  isFirst(option: Option) {
    return option.id == this.data[0].id;
  }

  clickAllEdit() {
    this.isOpened = true;
  }

  getCount(item) {
    return this.data.find(option => option.id === item.id).count;
  }

}

