<div class="game-summary" *ngIf="getTeams() as teams">
    <ng-container *ngFor="let team of teams; first as isFirst">
        <div class="game-summary__team">
            <div class="game-summary__team-main">
                <div [innerHTML]="(isFirst ? 'SB_Home' : 'SB_Away') | translate"
                     class="game-summary__side"></div>
                <sb-kit [primaryColour]="team.kitPrimaryHexColour || '#ffffff'"
                        [secondaryColour]="team.kitSecondaryHexColour || '#ffffff'"
                        [kitStyleId]="team.kitStyleId"
                        [size]="size"></sb-kit>
                <div *ngIf="team.name"
                     [innerHTML]="team.name"
                     class="game-summary__team-name"
                     [style.background]="colour.colourToHex(team.kitPrimaryHexColour)"
                     [style.color]="colour.colourToHex(team.kitSecondaryHexColour)"></div>
            </div>  
            <div *ngIf="team.score"
                class="game-summary__score"
                [innerHTML]="team.score"></div>
        </div>
        <div *ngIf="isFirst"
             class="game-summary__vs">
            <ng-container *ngIf="team.score !== undefined">
                <div> - </div>
                <div class="game-summary__result">
                    <sb-badge *ngIf="result" 
                              [message]="result.name"
                             [badgeClass]="result.className"
                             [isLarge]="true"></sb-badge>
                    <div *ngIf="resultNote" class="game-summary__result-note" [ngClass]="{'game-summary__result-note--lg' : noScores()}" [innerHTML]="resultNote"></div>
                </div>
            </ng-container>
            <ng-container *ngIf="!team.score === undefined">
                <div>VS</div>
                <sb-icon [name]="'sportIcon' + squad.sport.id"
                         [hasColor]="true"></sb-icon>
            </ng-container>
        </div>
    </ng-container>
</div>