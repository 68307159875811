'use strict';

angular.module('receptionModule').component('receptionAttendance',  {
    templateUrl: '/Scripts/app/reception/controllers/reception-attendance.template.html?v=', // + window.EveryBuddy.Version,
    controller: [
    '$scope', 'receptionService', 'searchFilterTypes', 'dateRangeLists',
    function ($scope, receptionService, searchFilterTypes, dateRangeLists) {

        $scope.searchFilterTypes = searchFilterTypes;
        $scope.dateRangeLists = dateRangeLists;

        $scope.eventsFrom = moment().startOf('day').add(-6, 'months');
        $scope.eventsTo = moment().endOf('day').add(1, 'days');
        $scope.teamNames = {};
        $scope.includeDeleted = false;
        $scope.includeArchived = false;
        $scope.teamId = 0;

        $scope.refresh = function() {
            receptionService.getAttendanceGroups($scope.eventsFrom, $scope.eventsTo, $scope.includeDeleted, $scope.includeArchived).then(
                function(data) {
                    $scope.teams = data;
                    for (var i = $scope.teams.length; i--;)
                    {
                        if (!$scope.teamNames.hasOwnProperty($scope.teams[i].id))
                        {
                            $scope.teamNames[$scope.teams[i].id] = $scope.teams[i].name;
                        }
                    }
                },
                function(error)
                {
                    return error;
                });
        };

        $scope.refresh();

    }
]});







