// start:ng42.barrel
import './invoice-detail-view-add-note.component';
import './invoice-detail-view-items.component';
import './invoice-detail-view-notes.component';
import './invoice-detail-view-send.component';
import './invoice-detail-view.component';
import './invoice-view.component';
import './invoice-detail-view-add-credit-note.component';
import './invoice-detail-view-add-payment.component';
import './invoice-detail-view-credit-notes.component'
import './invoice-detail-view-payments.component'
// end:ng42.barrel

