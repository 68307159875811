'use strict';

angular.module('profile.components.linkedGroups', [])
    .component('linkedGroups', {
        templateUrl: '/Scripts/app/profile/components/linked-groups.template.html',
        bindings: {
            selectedPerson: '<',
            isOrganisationAdmin: '<',
            isStaff: '<'
        },
        controller: ['$window', function ($window) {
            this.getRoleType = function () {
                return this.selectedPerson?.isStaff
                    ? $window.EveryBuddy.Enums.RoleTypes.Admin
                    : $window.EveryBuddy.Enums.RoleTypes.Pupil;
            }
        }]
    });