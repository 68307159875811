'use strict';

angular.module('transport.pupilPreferences.components.pupilPreferenceEdit', [
    'shared.components.sbModalHeader',
    'shared.filters.decoded',
    ])
    .component('pupilPreferenceEdit',
        {
            bindings: {
                modalInstance: '<',
                resolve: '<'
            },
            templateUrl: '/Scripts/app/transport/pupilPreferences/components/pupil-preference-edit.template.html',
            controller: [function () {

                    this.slotId = null;
                    this.methodId = null;
                    this.routeId = null;
                    this.slot = null;
                    this.method = null;
                    this.timeTableGroupedBySlots = [];

                    this.routeStopOnId = 1;
                    this.routeStopOffId = 3;

                    this.setMethod = function () {
                        this.method = this.slot.methods.filter(function (method) {
                            return method.methodId == this.methodId;
                        }.bind(this))[0];
                    }.bind(this);

                    this.setRoute = function () {
                        this.route = this.method.routes.filter(function (route) {
                            return route.routeId == this.routeId;
                        }.bind(this))[0];
                    }.bind(this);

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.slotId = changes.resolve.currentValue.slotId;
                            this.methodId = changes.resolve.currentValue.methodId;
                            this.routeId = changes.resolve.currentValue.routeId;
                            this.timeTableGroupedBySlots = changes.resolve.currentValue.timeTableGroupedBySlots;
                        }

                        if (this.slotId) {
                            this.slot = this.timeTableGroupedBySlots.filter(function (timetableSlot) {
                                return timetableSlot.slotId == this.slotId;
                            }.bind(this))[0];
                        }

                        if (this.methodId && this.slot) {
                            this.setMethod();
                        }

                        if (this.routeId && this.method) {
                            this.setRoute();
                        }
                    }.bind(this);

                    this.routeStops = [
                        { id: 1, name: 'Lancing' },
                        { id: 2, name: 'Shoreham' },
                        { id: 3, name: 'School' }
                    ];

                    this.save = function () {
                        this.modalInstance.close({ adjustOption: this.adjustOption, customValue: this.customValue });
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);
                }]
        });