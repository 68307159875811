'use strict';

angular.module('balanceAdmin.summary.components.balanceAdminSummary', [
    'balanceAdmin.summary.components.eventFeeSummary',
    'balanceAdmin.summary.components.balanceAdminSummaryWidget',
    'shared.constants',
    'shared.components.sbSearchFilter',
    'shared.components.sbSearchFilterItem',
    'angularjs-dropdown-multiselect',
])
    .component('balanceAdminSummary',
        {
            bindings: {},
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/balance-admin-summary.template.html',
            controllerAs: 'ctrl',
            controller: [
                '$filter',
                '$translate',
                'organisationService',
                'balanceAdminService',
                'simpleAlertService',
                'searchFilterTypes',
                'dateRangeLists',
                function (
                    $filter,
                    $translate,
                    organisationService,
                    balanceAdminService,
                    simpleAlertService,
                    searchFilterTypes,
                    dateRangeLists) {

                    this.resetSearchFilters = function () {

                        this.searchFilters = {
                            groups: [],
                            personId: 0,
                            showUnpaidOnly: false,
                            dirty: false
                        };

                        this.selectedPerson = { pupilId: 0 };

                    };

                    this.resetSearchFilters();

                    this.searchFilterTypes = searchFilterTypes;
                    this.dateRangeLists = dateRangeLists;

                    $translate.onReady().then(function () {

                        organisationService.getOrganisation()
                            .then(function (organisation) {

                                this.organisation = organisation;

                                this.groupLabel = organisation.isSchool
                                    ? $filter('translate')('SB_Group')
                                    : $filter('translate')('SB_Team_Header');

                                this.yearLabel = $filter('translate')('SB_Year');

                                if (!organisation.isSchool) {
                                    this.groupNameColumnHeader += '/';
                                }

                                if (!organisation.isSchool) {
                                    this.groupNameColumnHeader += $filter('translate')('MyDiary_Club');
                                }

                            this.refresh();

                            }.bind(this))
                            .catch(function () {
                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_Loading'
                                });
                            });

                   }.bind(this));

                    // Default to events from last three months.
                    this.eventsFrom = moment().startOf('day').subtract(3, 'months');
                    this.eventsTo = moment().endOf('day').add(1, 'years');

                    this.pupils = [];

                    this.filterChanged = function () {
                        this.searchFilters.dirty = true;
                    }.bind(this);

                    this.multiselectEvents = {
                        onSelectionChanged: function () {
                            this.filterChanged();
                        }.bind(this)
                    };

                    this.refresh = function (keepSelectedPerson) {
                        if (!this.eventsFrom || !this.eventsTo) {
                            return;
                        }

                        if (!keepSelectedPerson) {
                            this.selectedPerson = { pupilId: 0 };
                        }

                        balanceAdminService.getGroupSummariesAsync(this.eventsFrom, this.eventsTo, this.selectedPerson.pupilId)
                            .then(function (data) {

                                this.groups = data;
                                this.searchFilters.groups = [];

                                this.groups.forEach(function (group) {
                                    group.type = group.isTeam ? 2 : 1;
                                    group.label = $filter('translate')(group.name);
                                    group.id = group.groupId;
                                    this.searchFilters.groups.push(group);
                                }.bind(this));

                                this.filterChanged();

                                this.groups = data.sort(function (a, b) {
                                    if (a.type < b.type) {
                                        return -1;
                                    }
                                    if (a.type > b.type) {
                                        return 1;
                                    }
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                });

                                this.selectText = $filter('translate')('SB_Select');
                                this.groupSelectSettings = { selectByGroups: [1, 2],
                                                                groupByTextProvider: function(groupValue) {
                                                                    switch (groupValue) { case 1: return this.yearLabel; case 2: return this.groupLabel; }
                                                                }.bind(this)
                                                            };
                            }.bind(this), function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert({ message: err && err.data && err.data.Message ? err.data.Message : err });
                            });

                        balanceAdminService.getPupilSummariesAsync(this.eventsFrom, this.eventsTo)
                            .then(function (data) {
                                this.pupils = data;

                                this.pupils.unshift(
                                    {
                                        pupilId: 0,
                                        name: 'All'
                                    });
                            }.bind(this), function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert({ message: err && err.data && err.data.Message ? err.data.Message : err });
                            });
                    }.bind(this);

                }
            ]
        });