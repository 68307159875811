<div class="counts-grid"
     [style.grid-template-columns]="'repeat(' + counts?.length + ', 1fr)'">
    <sb-text *ngFor="let item of counts"
         customClasses="stats-grid__heading"
         [textClass]="item.className"
         [message]="item.name"></sb-text>
    <sb-text *ngFor="let item of counts"
         customClasses="semi-bold"
         [textClass]="item.className"
         [message]="item.value.toString()"></sb-text>
</div>
