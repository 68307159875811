'use strict';

angular.module('ccasignupModule').component('ccaGroupsAA', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-groupsaa.template.html',
    controller: [
    '$scope',
    '$state',
    '$timeout',
    '$location',
    '$anchorScroll',
    '$uibModal',
    '$translate',
    '$filter',
    '$interval',
    'simpleAlertService',
    'signUpRepository',
    'ccaService',
    'ccaDataProperties',
    function ($scope,
        $state,
        $timeout,
        $location,
        $anchorScroll,
        $uibModal,
        $translate,
        $filter,
        $interval,
        simpleAlertService,
        signUpRepository,
        ccaService,
        ccaDataProperties) {

        $scope.signUp = {};

        $scope.existingGroup = { id: 0 }
        var signUpId = $state.params.signUpId;

        $scope.sortType = 'title'; // set the default sort type
        $scope.sortReverse = false; // set the default sort order

        $scope.pageSize = typeof $state.params.searchData.pageSize == 'number' ? $state.params.searchData.pageSize : 20;
        $scope.pageSizes = [5, 10, 20, 50, 100];
        $scope.duplicateOrganisers = [];
        $scope.searchText = $state.params.searchData.searchText || '';

        $scope.maxNumberOfPages = window.EveryBuddy.PaginationMaxNumberOfPages;

        $scope.signUpRefreshing = false;

        $interval(() => {
            $scope.refreshSignUp();
        }, ccaDataProperties.DefaultRefreshTimeout);

        $scope.eventsGenerating = () => {
            return $scope.signUp.status === 2 || $scope.signUp.status === 5;
        };

        $scope.refreshSignUp = () => {
            // Refresh signup if not yet loaded or events still generating
            if (!$scope.signUp.ccaSignUpId || $scope.eventsGenerating()) {
                $scope.signUpRefreshing = true;
                signUpRepository.getSignUp(signUpId)
                    .then((signUp) => {
                        $scope.signUp = signUp;

                        if ($scope.$parent) {
                            // update parent view with any change in sign up state (generating, active etc.)
                            $scope.$parent.signUp = signUp;
                        }
                    })
                    .finally(() => {
                        $scope.signUpRefreshing = false;
                    });

            }
        };

        $scope.refreshSignUp();

        $scope.multiselectSettings = {enableSearch: true};

        signUpRepository.getSignUpGroups(signUpId)
            .then(function (signUpGroups)
            {
                $scope.groups = signUpGroups;
                for (var i = signUpGroups.length; i--;)
                {
                    signUpGroups[i].availabilitySummary = ccaService.getGroupAvailabilitySummary(signUpGroups[i]);
                }

                $scope.duplicateOrganisers = ccaService.getDuplicateOganisers(signUpGroups);

                if ($state.params.searchData) {
                    $scope.currentPage = typeof $state.params.searchData.currentPage == 'number' ? $state.params.searchData.currentPage : 1;
                    $scope.returnPage = $scope.currentPage;
                }

                var groupOrganisers = $filter('unique')($scope.groups, 'organiserName').map(function (group) {
                    return group.organiserName;
                });

                $scope.organisers = groupOrganisers.map(function (item) {
                    return {label: item, id: item};
                });

                $scope.selectedOrganisers = $scope.selectedOrganisers || $state.params.searchData?.selectedOrganisers || Array.from( $scope.organisers);

                $timeout(function () {
                if ($scope.groups && $state.params?.searchData?.groupId){
                    $location.hash('group-' + $state.params.searchData.groupId);
                    $anchorScroll.yOffset = 100;
                    $anchorScroll();
                }
            });
            });

        $scope.newGroup = function () {
            $state.go('ccaDetailsAA.ccaGroupManage');
        };
        $scope.newBulkGroups = function () {
            $state.go('ccaDetailsAA.bulkCreateGroups');
        };

        // TODO - Incomplete feature to re-use existing groups in instant book containers, needs revisiting and finishing off.
        $scope.addExistingGroup = function () {
            $scope.existingGroupId = { id: 0 }

            signUpRepository.getNonSignUpGroups(signUpId).then(function (signUpGroups) {
                $scope.nonsignupgroups = signUpGroups;


                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'addExistingGroupAAModal.html',
                    controller: 'AddExistingGroupAAModalInstanceCtrl',
                    size: 'md',
                    scope: $scope

                });

                modalInstance.result.then(function () {
                    if ($scope.existingGroup.id > 0) {
                        $scope.groups = null;
                        signUpRepository.postAddExistingGroup(signUpId, $scope.existingGroup.id)
                            .then(function () {
                                signUpRepository.getSignUpGroups(signUpId).then(function (signUpGroups) {
                                    $scope.groups = signUpGroups;
                                    $scope.duplicateOrganisers = ccaService.getDuplicateOganisers(signUpGroups);
                                });
                            });

                    }
                }, function () {
                });
            });
        };

        $scope.deleteGroup = function (signUpGroupId) {

            var modalInstance = simpleAlertService.simpleAlert({
                title: 'SB_Confirm',
                message: 'SB_Please_Confirm_That_You_Wish_To_Delete_This_Group',
                okButtonText: 'SB_Confirm',
                cancelButtonText: 'SB_Cancel',
                windowSize: 'md'
            });

            modalInstance.result.then(function () {
                signUpRepository.postDeleteGroup(signUpId, signUpGroupId)
                    .then(function () {
                        for (var i = 0; i < $scope.groups.length; i++) {
                            if ($scope.groups[i].id === parseInt(signUpGroupId) &&
                                $scope.groups[i].ccaSignUpId === parseInt(signUpId)) {
                                $scope.groups.splice(i, 1);
                                break;
                            }
                        }
                        $scope.duplicateOrganisers = ccaService.getDuplicateOganisers($scope.groups);
                    }).catch(function (){
                        simpleAlertService.errorAlert();
                    });
            }, function () {
            });
        };

        $scope.editGroup = function (signUpGroupId) {
            $state.go('ccaDetailsAA.ccaGroupEdit', {
                signUpGroupId: signUpGroupId,
                searchData: {
                    pageSize: $scope.pageSize,
                    currentPage: $scope.returnPage,
                    selectedOrganisers: $scope.selectedOrganisers,
                    groupId: signUpGroupId
                }
            });
        };

        $scope.copyGroup = function (signUpGroupId)
        {
            $state.go('ccaDetailsAA.ccaGroupCopy', { signUpGroupId: signUpGroupId, copy: true });
        };


        $scope.statusText = function(statusId) {
            switch (statusId) {
                case 0:
                    return 'SB_Not_Started';
                case 1:
                    return 'SB_Partial';
                case 2:
                    return 'SB_Complete';
                case 3:
                    return 'SB_On_Hold';
                case 4:
                    return 'SB_Auto_Complete';
                case 5:
                    return 'SB_Archived';
                case 6:
                    return 'SB_CcaStatus_Active';
                default:
                    return 'SB_Not_Started';
            }
        };

        $scope.onPageChanged = function(page){
            $scope.returnPage = page;
        };

        $translate.onReady().then(function () {
            $scope.selectText = $filter('translate')('SB_Select');
        });

        $scope.filterGroups = function(item) {
            var selectedOrganiserIds = $scope.selectedOrganisers.map(function (item) {return item.id;});
            return selectedOrganiserIds.includes(item.organiserName) &&
                   (item.title.toLowerCase().indexOf($scope.searchText.toLowerCase()) > -1 || $scope.searchText == '');
        };

        $scope.viewPublishedEvents = () => {
            $state.go($scope.signUp.isTimeSlot ?'ccaDetailsAA.existingEventsTimeSlotted' : 'ccaDetailsAA.existingEvents');
        };

    }
]});


angular.module('ccasignupModule').controller('AddExistingGroupAAModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
