/** Define roles dependencies, this variable can be a null array if don't need the dependencies */
export const ROLES_DEPS = [
  {
    roles: [
      'SB_Organisation_Super_Admin',
      'SB_Admin',
      'SB_Fees',
      'SB_Join_Requests_Admin',
      'SB_Communications_Admin',
      'SB_Transport_Admin',
      'SB_New_Registrations',
      'SB_Absentee_Alerts',
      'SB_Transport_Alerts',
      'SB_New_Booking_Alerts',
      'SB_Tuition_Fee_Admin',
      'SB_Sports_Coordinator',
      'SB_Trip_Coordinator'
    ], dep: 'SB_Teacher'
  },
  { roles: ['SB_Advanced_Fee_Admin'], dep: 'SB_Fees' },
  { roles: ['SB_Transport_Advanced_Admin'], dep: 'SB_Transport_Admin' },
] as const;
