'use strict';

angular.module('messaging.services.messagingService',
    [
    'messaging.constants',
    'shared.services.toastService'
]).factory('messagingService',[
    '$http', 'httpCoreApi', '$cacheFactory', 'toastService', 'recipientGroupType',
         function ($http, httpCoreApi, $cacheFactory, toastService, recipientGroupType) {

                var urlBase = '/webapi/WebMessageCenter/';
                var repository = {};
                var repoCache = $cacheFactory('messagingServiceCache');
                var httpCoreApiSettings = {
                    controllerType: httpCoreApi.controllerTypes.SysAdmin
                };
                var smsBaseUrl = 'Organisation/SMS';

                repository.clearCache = function () {
                    repoCache.removeAll();
                };

                repository.sendNewMessage = function(newMessageRequest) {

                    repoCache.removeAll();

                    return $http.post(urlBase + 'SendNewMessage', newMessageRequest)
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError : true, errorData: e };
                        });


                };

                repository.getAllTags = function()
                {
                    return $http.get(urlBase + 'GetTags', { cache: repoCache })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getAllTagsForReference = function () {
                    return $http.get(urlBase + 'GetTagsForReference', { cache: repoCache })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.updateMessageContent = function (messageId, content, attachmentIdsToDelete)
                {
                    var request = { messageId: messageId, content: content, attachmentIdsToDelete: attachmentIdsToDelete };

                    return $http.post(urlBase + 'UpdateMessageContent',
                            request)
                        .then(function(response)
                        {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError : true, errorData: e };
                        });
                };

                repository.removeMessage = function(messageId)
                {
                    return $http.post(urlBase + 'RemoveMessage', messageId)
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getRecipientsAndCreditCount = function(newMessageRequest) {

                    return $http.post(urlBase + 'GetRecipientsAndCreditCount', newMessageRequest)
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError : true, errorData: e };
                        });

                };

                repository.getThisOrganisationsSmsBalance = function() {

                    return $http.get(urlBase + 'GetCurrentOrganisationSMSBalance')
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });

                };

                repository.getAllOrganisationSmsBalances = () => {
                    return httpCoreApi.get(smsBaseUrl, httpCoreApiSettings);
                };

                repository.getAllSMSCountryCodes = () => {
                    return httpCoreApi.get('InternationalMobileNumberCodes', httpCoreApiSettings);
                };

                repository.updateSMSConfig = (organisationSmsRecord) => {
                    return httpCoreApi.put(smsBaseUrl + '/Configuration', {
                        SenderName: organisationSmsRecord.smsSenderName,
                        CountryCode: organisationSmsRecord.mobileDefaultFormatCountryCode
                    }, {
                        ...httpCoreApiSettings,
                        orgId: organisationSmsRecord.organisationId
                    });
                };

                repository.updateSMSSender = (organisationId, name) => {
                    return httpCoreApi.put(smsBaseUrl + '/Sender', {
                        SMSSenderName: name
                    }, {
                        ...httpCoreApiSettings,
                        orgId: organisationId
                    });
                };

                repository.updateSMSCountryCode = (organisationId, countryCode) => {
                    return httpCoreApi.put(smsBaseUrl + '/Country', {
                        SMSCountryCode : countryCode
                    }, {
                        ...httpCoreApiSettings,
                        orgId: organisationId
                    });
                };

                repository.addCreditsToOrganisationSMSBalance = function(organisationId, amount) {
                    return httpCoreApi.post(smsBaseUrl + '/Credits', {
                        amount: amount
                    }, {
                        ...httpCoreApiSettings,
                        orgId: organisationId
                    });

                };

                repository.getMessageHistory = function(currentPage, pageSize) {

                    return $http.get(urlBase + 'GetMessageHistory', { cache: repoCache, params : { currentPage: currentPage, pageSize : pageSize} })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getSettings = function()
                {
                    return $http.get(urlBase + 'GetSettings', { cache: repoCache })
                        .then(function(response)
                        {
                            return response.data;
                        })
                        .catch(function(e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getMessageMediums = function()
                {
                    return $http.get(urlBase + 'GetMessageMediums', { params: { hash: new Date() } })
                        .then(function(response)
                        {
                            return response.data;
                        })
                        .catch(function(e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getPickerRestriction = function()
                {
                    return $http.get(urlBase + 'GetPickerRestriction', { params: { hash: new Date() } })
                        .then(function(response)
                        {
                            return response.data;
                        })
                        .catch(function(e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                repository.sendAccessRequestEmails = function (
                    pupilsToWelcome,
                    pupilsToResetPassword,
                    pupilsToReInvite,
                    parentsToWelcome,
                    parentsToResetPassword,
                    parentsToReInvite,
                    staffToWelcome,
                    staffToResetPassword,
                    staffToReInvite)
                {
                    var ids = {
                        pupilsToWelcome: pupilsToWelcome,
                        pupilsToResetPassword: pupilsToResetPassword,
                        pupilsToReInvite: pupilsToReInvite,
                        parentsToWelcome: parentsToWelcome,
                        parentsToResetPassword: parentsToResetPassword,
                        parentsToReInvite: parentsToReInvite,
                        staffToWelcome: staffToWelcome,
                        staffToResetPassword: staffToResetPassword,
                        staffToReInvite: staffToReInvite
                    };

                    return $http.post(urlBase + 'SendAccessRequestEmails', ids)
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.sendAccessRequestEmailsForOrganisation = function (
                    orgId,
                    recipients)
                {
                    if (recipients) {
                        var ids = {
                            pupilsToWelcome: recipients.pupilsToWelcome,
                            pupilsToResetPassword: recipients.pupilsToResetPassword,
                            pupilsToReInvite: recipients.pupilsToReInvite,
                            parentsToWelcome: recipients.parentsToWelcome,
                            parentsToResetPassword: recipients.parentsToResetPassword,
                            parentsToReInvite: recipients.parentsToReInvite,
                            staffToWelcome: recipients.staffToWelcome,
                            staffToResetPassword: recipients.staffToResetPassword,
                            staffToReInvite: recipients.staffToReInvite
                        };

                        return $http.post(urlBase + 'SendAccessRequestEmailsForOrganisation?organisationId=' + orgId, ids)
                            .then(function (response) {
                                return response.data;
                            })
                            .catch(function (e) {
                                return { isError: true, errorData: e };
                            });
                    }
                };

                repository.sendSingleAccessRequestEmail = function(person, orgId) {
                    var pupilsToWelcome = [];
                    var pupilsToResetPassword = [];
                    var pupilsToReInvite = [];
                    var parentsToWelcome = [];
                    var parentsToResetPassword = [];
                    var parentsToReInvite = [];
                    var staffToWelcome = [];
                    var staffToResetPassword = [];
                    var staffToReInvite = [];

                    var personId = person.id;
                    var personStatus = person.personStatus;

                    if (person.isStaff) {
                        switch(personStatus) {
                            case repository.activationStates.Active:
                                staffToResetPassword.push(personId);
                              break;
                            case repository.activationStates.NotLoggedIn:
                                staffToReInvite.push(personId);
                              break;
                            case repository.activationStates.Inactive:
                                staffToWelcome.push(personId);
                              break;
                            default:
                              // Do nothing
                        }
                    }

                    if (person.isParent) {
                        switch(personStatus) {
                            case repository.activationStates.Active:
                                parentsToResetPassword.push(personId);
                              break;
                            case repository.activationStates.NotLoggedIn:
                                parentsToReInvite.push(personId);
                              break;
                            case repository.activationStates.Inactive:
                                parentsToWelcome.push(personId);
                              break;
                            default:
                              // Do nothing
                        }
                    }

                    if (person.isPupil) {
                        switch(personStatus) {
                            case repository.activationStates.Active:
                                pupilsToResetPassword.push(personId);
                              break;
                            case repository.activationStates.NotLoggedIn:
                                pupilsToReInvite.push(personId);
                              break;
                            case repository.activationStates.Inactive:
                                pupilsToWelcome.push(personId);
                              break;
                            default:
                              // Do nothing
                        }
                    }

                    if (orgId)
                    {
                        var recipients = {
                            pupilsToWelcome,
                            pupilsToResetPassword,
                            pupilsToReInvite,
                            parentsToWelcome,
                            parentsToResetPassword,
                            parentsToReInvite,
                            staffToWelcome,
                            staffToResetPassword,
                            staffToReInvite
                        };

                        return repository.sendAccessRequestEmailsForOrganisation(
                            orgId,
                            recipients
                            )
                            .then(function () {
                                toastService.emailSent();
                            })
                            .catch(function(err) {
                                console.log(err);
                                toastService.error();
                            });
                    }
                    else
                    {
                        return repository.sendAccessRequestEmails(pupilsToWelcome,
                                                                  pupilsToResetPassword,
                                                                  pupilsToReInvite,
                                                                  parentsToWelcome,
                                                                  parentsToResetPassword,
                                                                  parentsToReInvite,
                                                                  staffToWelcome,
                                                                  staffToResetPassword,
                                                                  staffToReInvite
                            )
                            .then(function () {
                                toastService.emailSent();
                            })
                            .catch(function(err) {
                                console.log(err);
                                toastService.error();
                            });
                    }
                };

                repository.welcomeStaff = function(personId, orgId) {
                    const staffToWelcomeWrapper = {
                        staffToWelcome: [personId]
                    };

                    if (orgId) {
                       
                        return repository.sendAccessRequestEmailsForOrganisation(
                            orgId,
                            staffToWelcomeWrapper
                        )
                        .then(function() {
                            toastService.emailSent();
                        })
                        .catch(function(err) {
                            toastService.error('SB_Error_Sending_Message');
                            console.log(err);
                        });
                    }
                    else {
                        return repository.sendAccessRequestEmails(
                            staffToWelcomeWrapper
                        )
                        .then(function() {
                            toastService.emailSent();
                        })
                        .catch(function(err) {
                            toastService.error('SB_Error_Sending_Message');
                            console.log(err);
                        });
                    }
                };

                repository.activationStates = {
                    Active: 'SB_Active',
                    NotLoggedIn: 'SB_Not_logged_in',
                    Inactive: 'SB_Inactive'
                };

                repository.getActivationLabel = function(person) {
                    if (person) {
                        var status = person.personStatus;
                        if (status === repository.activationStates.Active) {
                            return 'SB_Send_Password_Reset';
                        }
                        if (status === repository.activationStates.NotLoggedIn) {
                            return 'SB_Resend_Welcome_Message';
                        }
                        if (status === repository.activationStates.Inactive) {
                            return 'SB_Send_Welcome_Message';
                        }
                    }
                };

                repository.prepareEventGroupDto = function (events, eventGroupedPeople, eventPersons, calendarEventId)
                {
                    if (!events || !Array.isArray(events))
                    {
                        events = [];
                    }

                    let eventGroups = [];
                    if (Array.isArray(eventGroupedPeople)) {
                        eventGroupedPeople.forEach(eventGroup =>
                            eventGroups.push({
                                id: calendarEventId,
                                memberType: eventGroup.memberType
                            })
                        );
                    }

                    let eventPeople = [];
                    if (Array.isArray(eventPersons)) {
                        eventPersons.forEach(eventPerson =>
                            eventPeople.push({
                                id: calendarEventId,
                                memberType: eventPerson.memberType,
                                personId: eventPerson.id
                            })
                        );
                    }

                    return events.concat(eventGroups).concat(eventPeople);
                }

                repository.resetGroupContainers = function ($scope) {
                    if (!$scope.recipients) {
                        $scope.recipients = {};
                    }

                    $scope.recipients.teams = [];
                    $scope.recipients.clubs = [];
                    $scope.recipients.people = [];
                    $scope.recipients.events = [];
                    $scope.recipients.eventPeople = [];
                    $scope.recipients.eventGroupedPeople = [];
                    $scope.recipients.wholeSchoolGroup = [];
                    $scope.recipients.subjectClasses = [];
                }

                repository.prepareRecipientGroups = function (newMessageModel, recipients, calendarEventId) {
                        if (!newMessageModel.recipients) {
                            newMessageModel.recipients = [];
                        }
                        newMessageModel.recipients.push({
                            type: recipientGroupType.Club,
                            recipients: recipients.clubs || []
                        });
                        newMessageModel.recipients.push({
                            type: recipientGroupType.Team,
                            recipients: recipients.teams || []
                        });
                        newMessageModel.recipients.push({
                            type: recipientGroupType.Person,
                            recipients: recipients.people || []
                        });
                        newMessageModel.recipients.push({
                            id: calendarEventId,
                            type: recipientGroupType.Event,
                            recipients: this.prepareEventGroupDto(recipients.events, recipients.eventGroupedPeople, recipients.eventPeople, calendarEventId)
                        });
                        newMessageModel.recipients.push({
                            type: recipientGroupType.WholeSchool,
                            recipients: recipients.wholeSchoolGroup || []
                        });
                        newMessageModel.recipients.push({
                            type: recipientGroupType.SubjectClasses,
                            recipients: recipients.subjectClasses || []
                        });
                 }

                 repository.hasRecipientSelected = function ($scope) {
                     if (!$scope.recipients) {
                         return false;
                     }

                     return ($scope.recipients.teams && $scope.recipients.teams.length > 0) ||
                         ($scope.recipients.clubs && $scope.recipients.clubs.length > 0) ||
                         ($scope.recipients.people && $scope.recipients.people.length > 0) ||
                         ($scope.recipients.events && $scope.recipients.events.length > 0) ||
                         ($scope.recipients.eventGroupedPeople && $scope.recipients.eventGroupedPeople.length > 0) ||
                         ($scope.recipients.eventPeople && $scope.recipients.eventPeople.length > 0) ||
                         ($scope.recipients.wholeSchoolGroup && $scope.recipients.wholeSchoolGroup.length > 0) ||
                         ($scope.recipients.subjectClasses && $scope.recipients.subjectClasses.length > 0);
                 }

                 repository.selectedWholeSchool = function (recipients)
                 {
                     return (recipients.wholeSchoolGroup && recipients.wholeSchoolGroup.length > 0);
                 }

                 repository.validateSendMessage = function ($scope, simpleAlert) {

                     var bodyContent = $scope.htmlBody || $scope.plainBody || $scope.messageText;

                     if (bodyContent.length <= 0) {
                         simpleAlert('SB_Unable_to_send_message', 'SB_Cannot_send_a_message_with_no_message_body');
                         return false;
                     }
                     if (!repository.hasRecipientSelected($scope)) {
                         simpleAlert('SB_Unable_to_send_message', 'SB_Cannot_send_a_message_with_no_recipients');
                         return false;
                     }

                     return true;

                 }.bind(this);


                return repository;
            }
        ]);
