angular.module('shared.constants', [])
    .constant('searchFilterTypes', {
        TextSearch: { id: 'textSearch', format: 'text', defaultPlaceholder: 'SB_Search' },
        TextFilter: { id: 'textFilter', format: 'text', defaultPlaceholder: 'SB_Filter' },
        DateRange: { id: 'dateRange', format: 'date', defaultPlaceholder: 'SB_Select_Dates', defaultLabel: 'SB_Date_Range' },
        DateSingle: { id: 'dateSingle', format: 'date', defaultPlaceholder: 'SB_Select_Date', defaultLabel: 'SB_Date' }
    })
    .constant('dateRanges', [
        { id: 't', label: 'SB_Today', range: [moment(), moment()] },
        { id: 'n1', label: 'SB_Tomorrow', range: [moment().add(1, 'days'), moment().add(1, 'days')] },
        { id: 'l1', label: 'SB_Yesterday', range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')] },
        { id: 'n7', label: 'SB_Next_7_Days', range: [moment(), moment().add(6, 'days')] },
        { id: 'l7', label: 'SB_Last_7_Days', range: [moment().subtract(6, 'days'), moment()] },
        { id: 'l14', label: 'SB_Last_14_Days', range: [moment().subtract(13, 'days'), moment()] },
        { id: 'n30', label: 'SB_Next_30_Days', range: [moment(), moment().add(29, 'days')] },
        { id: 'l30', label: 'SB_Last_30_Days', range: [moment().subtract(29, 'days'), moment()] },
        { id: 'n60', label: 'SB_Next_60_Days', range: [moment(), moment().add(59, 'days')] },
        { id: 'l60', label: 'SB_Last_60_Days', range: [moment().subtract(59, 'days'), moment()] },
        { id: 'tM', label: 'SB_This_Month', range: [moment().startOf('month'), moment().endOf('month')] },
        { id: 'pM', label: 'SB_Previous_Month', range: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
        { id: 'n90', label: 'SB_Next_90_Days', range: [moment(), moment().add(89, 'days')] },
        { id: 'l90', label: 'SB_Last_90_Days', range: [moment().subtract(89, 'days'), moment()] },
        { id: 'l6M', label: 'SB_Last_6_Months', range: [moment().subtract(6, 'months'), moment()] },
        { id: 'nM', label: 'SB_Next_Month', range: [moment(), moment().add(1, 'months')] },
        { id: 'nY', label: 'SB_Next_Year', range: [moment(), moment().add(1, 'years')] },
        { id: 'lY', label: 'SB_Last_Year', range: [moment().subtract(1, 'years'), moment()] },
        { id: 'l3MnY', label: 'SB_Last_3_Months_To_1_Year_Forward', range: [moment().subtract(3, 'months'), moment().add(1, 'years')] },
        { id: 'l3YnY', label: 'SB_Last_3_Years_To_1_Year_Forward', range: [moment().subtract(3, 'years'), moment().add(1, 'years')] }
    ])
    .constant('dateRangeLists', {
        A: { id: 'a', ranges: ['t', 'n1', 'n7', 'n30', 'n60', 'n90'], showCustom: true },
        B: { id: 'b', ranges: ['t', 'n1', 'n7', 'n30', 'n60', 'n90', 'nY', 'l1', 'l7', 'l3MnY'], showCustom: true },
        C: { id: 'c', ranges: ['l7', 'l30', 'l90', 'l6M', 'lY'], showCustom: false },
        D: { id: 'd', ranges: ['l3YnY'], showCustom: true },
        E: { id: 'e', ranges: ['nM', 'nY'], showCustom: true },
        F: { id: 'f', ranges: ['t', 'l1', 'l7', 'l14'], showCustom: true }
    })
    .constant('creditTypeEnum', {
        None: 0,
        Cancellation: 1,
        Correction: 2,
        WriteOff: 3,
        Discount: 50
    })
    .constant('formElements', {
        Text: { id: 1, isText: true, typeLabel: 'text', maxlength: 250 },
        Select: { id: 2 },
        Email: { id: 3, isText: true, typeLabel: 'email' },
        Number: { id: 4, isText: true, typeLabel: 'number', max: 2147483647 },
        Tel: { id: 5, typeLabel: 'tel' },
        Url: { id: 6, isText: true, typeLabel: 'url' },
        Twitter: { id: 7, isText: true, typeLabel: 'text', addon: '@' },
        Country: { id: 8, isVisualSelect: true },
        Colours: { id: 9 },
        SubHeading: { id: 10, isDecorative: true },
        VisualSelect: { id: 11, isVisualSelect: true },
        Content: { id: 12 },
        Switch: { id: 13 },
        CheckboxList: { id: 14 },
        Paragraph: { id: 15, isDecorative: true },
        Data: { id: 16, isDecorative: true },
        Icon: { id: 17, isVisualSelect: true },
        Button: { id: 18, isDecorative: true },
        Hidden: { id: 19, isDecorative: true}
    })
    .constant('tableProperties', {
        DefaultRefreshTimeout: 20000,
        CellTypes: {
            Text: 1,
            Badge: 2,
            Date: 3,
            Button: 4
        },
        Layouts: {
            MasterDetail: 1,
            Collapse: 2,
            ShowHide: 3
        }
    });