'use strict';

angular.module('ccasignupModule').component('ccaBlockDates', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-signup-blockdates.template.html',
    controller:
    [
        '$scope',
        '$state',
        '$uibModal',
        'signUpRepository',
        function ($scope,
            $state,
            $uibModal,
            signUpRepository) {

            $scope.signUp = {};
            $scope.reason = '';

            function resetView() {
                $scope.dtFrom = new Date();
                $scope.dtTo = new Date();
                $scope.reason = '';
                $scope.isEdit = null;
            }

            var signUpId = $state.params.signUpId;

            signUpRepository.getSignUp(signUpId)
                .then(function (signUp) {
                    $scope.signUp = signUp;
                });

            signUpRepository.getOrganisationBlockDates()
                .then(function (organisationblockdates) {
                    $scope.organisationblockdates = organisationblockdates;
                });

            signUpRepository.getSignUpBlockDates(signUpId)
                .then(function (blockdates) {
                    $scope.blockdates = blockdates;
                });

            $scope.newBlock = function () {
                $scope.blockdates = null;
                signUpRepository.postBlockdate(signUpId, 0, $scope.formatDate($scope.dtFrom), $scope.formatDate($scope.dtTo), $scope.reason, false)
                    .then(function (blockdates) {
                        resetView();
                        $scope.blockdates = blockdates;
                    });
            };

            $scope.editBlock = function () {
                $scope.blockdates = null;
                signUpRepository.postBlockdate(signUpId, $scope.entryId, $scope.formatDate($scope.dtFrom), $scope.formatDate($scope.dtTo), $scope.reason, false)
                    .then(function (blockdates) {
                        resetView();
                        $scope.blockdates = blockdates;
                    });
            };

            $scope.formatDate = function (date) {
                return date.format('YYYY-MM-DD');
            }

            $scope.today = function () {
                $scope.dtFrom = new Date();
                $scope.dtTo = new Date();
            };
            $scope.today();

            $scope.clear = function () {
                $scope.dtFrom = null;
                $scope.dtTo = null;
            };

            $scope.toggleMin = function () {
                $scope.minDate = $scope.minDate ? null : new Date();
            };

            $scope.toggleMin();

            $scope.setDate = function (year, month, day) {
                $scope.dt = new Date(year, month, day);
            };

            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            var afterTomorrow = new Date();
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            $scope.events =
                [
                    {
                        date: tomorrow,
                        status: 'full'
                    },
                    {
                        date: afterTomorrow,
                        status: 'partially'
                    }
                ];

            $scope.getDayClass = function (date, mode) {
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < $scope.events.length; i++) {
                        var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return $scope.events[i].status;
                        }
                    }
                }

                return '';
            };


            $scope.cancelEdit = function () {
                resetView();
            };

            $scope.editBlockDate = function (index) {
                $scope.isEdit = true;
                var entry = $scope.blockdates[index];
                $scope.entryId = entry.ccaSignUpExcludedId;
                $scope.reason = entry.reason;

                $scope.dtFrom = moment(entry.excludeFrom);
                $scope.dtTo = moment(entry.excludeTo);
            }
            $scope.deleteBlockDate = function (blockDateId) {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'confirmDeleteBlockDateModal.html',
                    controller: 'ConfirmDeleteBlockModalInstanceCtrl',
                    size: 'sm'

                });

                modalInstance.result.then(function () {
                    $scope.blockdates = null;
                    signUpRepository.postDeleteBlockDate($state.params.signUpId, blockDateId)
                        .then(function () {
                            signUpRepository.getSignUpBlockDates($state.params.signUpId)
                                .then(function (blockdates) {
                                    $scope.blockdates = blockdates;
                                });
                        });
                }, function () {
                });
            };

        }]});

angular.module('ccasignupModule').controller('ConfirmDeleteBlockModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
