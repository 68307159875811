
import { RouterPaths } from "@sb-shared/constants/router-paths.constants";
import * as angular from "angular";
angular.module('settingsModule', [

  'tmh.dynamicLocale',

  'ui.bootstrap',
  'ui.tinymce',

  'duScroll',
  'angular.filter',

  'shared.components.sbIcon',

  'shared.components.sbDateRangePicker',
  'shared.components.sbTitleBar',
  'shared.components.sbColumnHeader',
  'shared.components.sbWizard',
  'shared.components.sbOnOffSwitch',
  'shared.directives.cloakTranslate',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',
  'shared.directives.sbCheckBox',
  'shared.directives.sbCurrencyInput',
  'shared.directives.sbLoading',
  'shared.components.sbSubmitButton',

  'shared.directives.updateLanguage',
  'shared.services.authInterceptor',
  'shared.services.cachedLookupService',
  'shared.services.imagesUploadHandler',
  'shared.services.moduleService',
  'shared.services.toastService',
  'shared.services.tinymceConfigHelper',
  'shared.services.stringService',
  'shared.services.arrayService',
  'shared.services.httpCoreApi',
  'shared.constants',
  'roles.services.rolesService',
  'reception.components.receptionLocationManage',
  'reception.components.receptionLocationEvents',
  'person.services.personPickerService',
  // 'group.controllers.multiPersonPickerPopupController',
  // 'transport.services.pupilTransportPreferenceService',
  // 'transport.services.transportTimeSlotsService',
  'shared.services.simpleAlertService',
  'settingsModule.services.attendanceScanningSettingsService',
  'ng1StateDefinitionModule'
]);
angular.module('settingsModule').config([
    'ng1StateDefinitionProvider',
    function(ng1StateDefinitionProvider){

      const ng1State = ng1StateDefinitionProvider.$get();
      const moduleName = 'Settings';

      ng1State.state(moduleName, 'dashboard',
      {
          url: '/'
      })
      ng1State.state(moduleName, 'dashboard.onboarding', {
          url: 'Onboarding/'
        })
      ng1State.state(moduleName, 'dashboard.onboarding.locations', {
          url: RouterPaths.Locations,
        })
      ng1State.state(moduleName, 'dashboard.onboarding.locations.locationdetails', {
          url: '/LocationDetails',
        })
      ng1State.state(moduleName, 'dashboard.onboarding.locations.locationevents', {
          url: '/LocationEvents'
        })
      }
  ])

  .constant('attendanceScanningEnabledStatusEnum', {
    Disabled: 0,
    EnabledAllEvents: 1,
    EnabledTransportEvents: 2
  })
  .constant('attendanceScanningAccessLevelEnum', {
      None: 0,
      TransportOnly: 1,
      AllEvents: 2
  })
  .constant('attendanceScanningAccessScanningTypeEnum', {
      QRCode: 0,
      RFID: 1
  })

  .component('ng1SettingsOnboardingWelcomeMessage', {
    template: '<onboarding-welcome-message></onboarding-welcome-message>',
  })
  .component('ng1SettingsOnboardingBlockDates', {
    template: '<onboarding-block-dates></onboarding-block-dates>'
  })
  .component('ng1SettingsOnboardingLocations', {
    template: '<reception-locations></reception-locations>',
  })
  .component('ng1SettingsOnboardingOnlinePTC', {
    template: '<online-parent-meetings></online-parent-meetings>',
  })
  .component('ng1SettingsOnboardingAttendanceScanning', {
    template: '<attendance-scanning-settings></attendance-scanning-settings>',
  })
  .component('ng1SettingsStaffRoles', {
    template: '<staff-roles-internal></staff-roles-internal>',
  })
  .component('ng1SettingsFariaOne', {
    template: '<faria-one></faria-one>',
  })
  .component('ng1SettingsPortalLinks', {
    template: '<links-portal-admin></links-portal-admin>',
  })

            //     .state('settings.onboarding.locations.locationdetails',
      //     {
      //       url: '/LocationDetails',
      //       templateUrl: '/assets/angularJs/reception/controllers/reception-location-manage.template.html?v=' +
      //           version,
      //       controller: 'receptionLocationManageController'
      //     })
      //   .state('settings.onboarding.locations.locationevents',
      //       {
      //         url: '/LocationEvents',
      //         templateUrl: '/assets/angularJs/reception/controllers/reception-location-events.template.html?v=' +
      //             version,
      //         controller: 'receptionLocationEventsController'
      //       })
