// https://github.com/angular/angular.js/issues/734#issuecomment-78202524
angular.module('shared.directives.afterRender', [])
    .directive('afterRender', ['$timeout', function ($timeout) {
        var def = {
            restrict: 'A',
            terminal: true,
            transclude: false,
            link: function (scope, element, attrs) {
                if (attrs) { scope.$eval(attrs.afterRender) }
                scope.$emit('onAfterRender')
            }
        };
        return def;
    }]);