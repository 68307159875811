"use strict";

angular.module('diary.directives.teamDiary',
    [
        'person.services',
        'diary.services.diaryService',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'shared.components.sbStackedDate',
        'shared.components.sbSearchFilter',
        'shared.constants'
])
    .directive('teamDiary', [
        'personService',
        'diaryService',
        '$sce',
        '$window',
        'searchFilterTypes',
        function (personService, diaryService, $sce, $window, searchFilterTypes) {

        function link($scope) {
            $scope.$watch('teamId', function (newValue) {
                if (newValue) {
                    $scope.refreshData();
                }
            });
        }

        function controller($scope, diaryService) {

            $scope.searchFilterTypes = searchFilterTypes;
            $scope.initialLoadComplete = false;
            $scope.loading = true;
            $scope.from = moment.utc().startOf('day').toDate();
            $scope.to = moment.utc().endOf('day').add(14, 'days').toDate();
            $scope.events = [];

            $scope.pupilgroupsSortType = "name";
            $scope.pupilgroupssortReverse = false;
            $scope.pupilgroups = null;

            $scope.refreshData = function () {

                $scope.loading = true;

                if (!$scope.from)
                    $scope.from = '';

                if (!$scope.to)
                    $scope.to = '';

                diaryService.getEventsForTeam($scope.teamId, $scope.from, $scope.to)
                    .then(function (data) {

                        var events = diaryService.insertMultiDayEvents(data);

                        $scope.events = events;

                        $scope.initialLoadComplete = true;
                        $scope.loading = false;
                    });
            };

            $scope.sanitizeHtml = function (html) {
                return $sce.trustAsHtml(html);
            };

            $scope.hasDateChangedSinceLastRow = function (thisIndex) {
                if (thisIndex === 0)
                    return true;

                var lastIndex = thisIndex - 1;

                if (moment($scope.events[thisIndex].eventDate).format("YYYYMMDD") ===
                    moment($scope.events[lastIndex].eventDate).format("YYYYMMDD"))
                    return false;

                return true;
            };

            $scope.isActive = function (item) {

                return item.id > 0
                    ? 'profileDiaryTabDetailListItem'
                    : '';
            };

            $scope.getStyle = function (item) {
                return item.id == 0
                    ? 'cursor: default'
                    : '';
            };

            /*
                //// DATE PICKERS
                */

            $scope.dateOptions = {
                formatYear: "yy",
                startingDay: 1
            };

            $scope.formats = ["dd-MMMM-yyyy", "dd.MM.yyyy", "dd/MM/yyyy", "shortDate", "dd-MMM-yyyy", "dd MMM yyyy"];
            $scope.format = "dd MMM yyyy";
            $scope.altInputFormats = ["M!/d!/yyyy"];

            $scope.open = function (picker) {
                picker.opened = true;
            };

            $scope.fromPicker = {
                opened: false
            };

            $scope.toPicker = {
                opened: false
            };

            /*
            \\\\ DATE PICKERS
            */

        }

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/diary/directives/team-diary.template.html?v=' + $window.EveryBuddy.Version,
            scope: {
                teamId: '='
            },
            controller: ['$scope', 'diaryService', controller],
            link: link
        };
    }]);
