<div id="subMenuBar"
     class="submenu sb-bg-dark"
     [ngClass]="{'submenu-page' : pageMenuItems?.length > 1}"
     *ngIf="(showSubMenu$ | async) && visibleSubMenuItems().length > 1">
    <ul class="buddy-nav buddy-nav--compact  js-buddy-nav"
        [ngClass]="{'hidden' : subMenuItems.length < 2}"
        id="subMenuBarInner">
        <li #menuItem
            *ngFor="let item of visibleSubMenuItems() | orderBy: 'sortOrder'; let i = index"
            [ngClass]="{'visible-xs' : pushToMore(i)}"
            [id]="item.elementId"
            class="buddy-nav__item"
            [routerLink]="item.routerLink || item.route"
            #rla="routerLinkActive"
            [queryParams]="item.params"
            [routerLinkActive]="rlaClass"
            [id]="item.elementId"
            [hidden]="item.isHidden">
            <a class="buddy-nav__link js-buddy-nav__link">
                <span class="buddy-nav__label"
                      [innerHTML]="item.label | translate"></span>
                <span class="buddy-nav__icon {{getIcon(item, rla.isActive)}} hidden-hover"></span>
            </a>
        </li>
        <li *ngIf="moreItems().length > 0"
            ngbDropdown
            placement="left-bottom"
            class="buddy-nav__item hidden-xs">
            <a class="buddy-nav__link js-buddy-nav__link p-0"
               ngbDropdownToggle
               id="subMenuDropdown">
                <span class="buddy-nav__label"
                      [innerHTML]="'SB_More' | translate"></span>
                <span class="buddy-nav__icon fal fa-ellipsis-h hidden-hover"></span>
            </a>
            <ul ngbDropdownMenu
                aria-labelledby="subMenuDropdown"
                placement="right-bottom">
                <li *ngFor="let item of moreItems()"
                    [routerLink]="item.route"
                    #rla="routerLinkActive"
                    [routerLinkActive]="rlaClass"
                    ngbDropdownItem>
                    <a class="navbar-text-link"
                       [ngClass]="{'navbar-text-link--active' : rla.isActive}">
                        <i [ngClass]="getIcon(item, rla.isActive)"
                           class="mr-2"></i>
                        <span [innerHTML]="item.label | translate"></span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
