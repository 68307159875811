angular.module('corporateContacts.components.corporateContactEditorPopup',
        [
            'corporateContacts.components.corporateContactEditor',
            'pascalprecht.translate',
            'shared.components.sbModalHeader'
        ])
    .component('corporateContactEditorPopup',
        {
            bindings: {

                // built in props which are passed automatically by uibModal
                modalInstance: '<',
                resolve: '<',

                // our props
                contactId: '<',
                onChange: '&',
                readOnly: '<'

            },
            templateUrl: '/Scripts/app/corporateContacts/components/corporate-contact-editor-popup.template.html',
            controller: [
                function ()
                {
                    this.$onChanges = function(changes)
                    {
                        if (changes.resolve && changes.resolve.currentValue)
                        {
                            for (var prop in this.resolve)
                            {
                                this[prop] = this.resolve[prop];
                            }
                        }
                    };

                    this.ok = function () {
                        this.modalInstance.close();
                    }.bind(this);

                    this.cancel = function () {
                        this.modalInstance.dismiss('cancel');
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss('cancel');
                    }.bind(this);
                }]
        });