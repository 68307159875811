import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { OrganisationService } from "@sb-shared/services/organisation.service";
import { Observable, switchMap, take } from "rxjs";

export class OrganisationTranslateLoaderService implements TranslateLoader {
    constructor(private http: HttpClient, private organisationService: OrganisationService) { }

    getTranslation(lang: string): Observable<any> {
        return this.organisationService.getCurrentOrganisation().pipe(
            take(1),
            switchMap(organisation => {
                return this.http.get(`${window.EveryBuddy.BlobBase}orglabels/org-${organisation.id}_lang_${lang}.json?v=${window.EveryBuddy.Version}`);
            })
        );
    }
}
