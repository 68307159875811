angular.module('personPicker.constants', [])
    .constant('recipientSearchTypeEnum', {
        Clubs: 1,
        Teams: 2,
        People: 3,
        Events: 4,
        EventGroupedPersons: 5,
        EventPersons: 6,
        WholeSchool: 7,
        SubjectClasses: 8,
    }).constant('memberTypes', {
        All: {
            label: 'SB_All',
            labelSingle: 'SB_All',
            buttonLabel: 'SB_Add_All_Members',
            id: 0
        },
        Student:
        {
            label: 'SB_Pupils',
            labelSingle: 'SB_Student',
            buttonLabel: 'SB_Add_Students',
            id: 1,
            shortHand: 'pu',
            iconName: 'userTypeStudent'
        },
        Parent:
        {
            label: 'SB_Parents',
            labelSingle: 'SB_Parent',
            id: 2,
            buttonLabel: 'SB_Add_Parents',
            shortHand: 'pa',
            iconName: 'userTypeParent'
        },
        Staff:
        {
            label: 'SB_Staff',
            labelSingle: 'SB_Staff',
            id: 3,
            buttonLabel: 'SB_Add_Staff',
            shortHand: 'st',
            iconName: 'userTypeStaff'
        }
    });
