'use strict';

angular.module('ccasignupModule').component('ccaGroupDetails', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-group-details.template.html',
    controller: [
    '$scope', '$state', '$filter', 'signUpRepository', function ($scope, $state, $filter, signUpRepository) {
        $scope.signUp = {};

        signUpRepository.getSignUp($state.params.signUpId).then(function (signUp) { $scope.signUp = signUp; });

        signUpRepository
            .getSignUpGroup($state.params.signUpId, $state.params.signUpGroupId)
            .then(function (signUpGroup) {
                $scope.signUpGroup = signUpGroup;
            });


        $scope.getWhenWhereString = function () {
            if ($scope.signUpGroup) {
                var result = $filter('translate')('SB_WeekDay' + $scope.signUpGroup.dayofWeek) + ' ' +
                    ('00' + $scope.signUpGroup.ccaStartHour).slice(-2) +
                    ':' +
                    ('00' + $scope.signUpGroup.ccaStartMin).slice(-2) +
                    ' - ' +
                    ('00' + $scope.signUpGroup.ccaEndHour).slice(-2) +
                    ':' +
                    ('00' + $scope.signUpGroup.ccaEndMin).slice(-2);

                if ($scope.signUpGroup.linkedGroups) {
                    for (var i = 0; i < $scope.signUpGroup.linkedGroups.length; i++) {
                        var linkedGroup = $scope.signUpGroup.linkedGroups[i];

                        result += '; ' +
                            $filter('translate')('SB_WeekDay' + linkedGroup.dayofWeek) + ' ' +
                            ('00' + linkedGroup.ccaStartHour).slice(-2) +
                            ':' +
                            ('00' + linkedGroup.ccaStartMin).slice(-2) +
                            ' - ' +
                            ('00' + linkedGroup.ccaEndHour).slice(-2) +
                            ':' +
                            ('00' + linkedGroup.ccaEndMin).slice(-2);
                    }
                }
            }

            return result;
        };

        $scope.costType = function (costType) {

            switch (costType) {
                case 1:
                    return 'SB_Per_term';
                case 2:
                    return 'SB_Per_session';
                case 3:
                    return 'SB_One_off';
                default:
                    return 'SB_Free';
            }
        };

        $scope.genderText = function (gender) {
            switch (gender) {
                case 1:
                    return 'SB_Female';
                case 2:
                    return 'SB_Male';
                case 3:
                    return 'SB_Mixed';
                default:
                    return '';
            }
        };
        $scope.statusText = function (statusId) {
            switch (statusId) {
                case 0:
                    return 'SB_Not_Started';
                case 1:
                    return 'SB_Partial';
                case 2:
                    return 'SB_Complete';
                case 3:
                    return 'SB_On_Hold';
                case 4:
                    return 'SB_Auto_Complete';
                case 5:
                    return 'SB_Archived';
                case 6:
                    return 'SB_CcaStatus_Active';
                default:
                    return 'SB_Not_Started';
            }
        };

        $scope.update = function (ccaSignUp) {
            signUpRepository.post(ccaSignUp).then(function () {
                $state.go('ccaDetails.groups');
            });
        };
    }
]});