import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MomentOrDate, momentToDate } from '@sb-helpers';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { subYears } from 'date-fns';

@Component({
  selector: 'sb-single-date-picker',
  templateUrl: './single-date-picker.component.html',
  styleUrls: ['./single-date-picker.component.scss'],
})
export class SingleDatePickerComponent {
  @Input() set date(value: MomentOrDate) {
    this.ngbDate = this.toNgbDateStruct(value);
  }
  @Input() set minDate(value: MomentOrDate) {
    this.ngbMinDate = this.toNgbDateStruct(value || subYears(Date.now(), 100));
  }
  @Input() set maxDate(value: MomentOrDate) {
    this.ngbMaxDate = this.toNgbDateStruct(value);
  }
  @Input() disabled = false;
  @Output() onChange: EventEmitter<{ startDate: MomentOrDate }> = new EventEmitter();

  ngbDate: NgbDate;
  ngbMinDate: NgbDate;
  ngbMaxDate: NgbDate;

  constructor(private dateTime: DateTimeService) { }

  private toNgbDateStruct(inputDate: MomentOrDate): NgbDate | null {
    if (inputDate) {
      const input = momentToDate(inputDate);
      return new NgbDate(input.getFullYear(), input.getMonth() + 1, input.getDate());
    }
    return null;
  }

  change(event: NgbDate) {
    const date = this.dateTime.convertNgbDate(event);
    this.onChange.emit({
      startDate: this.dateTime.removeSeconds(date)
    });
  }
}
