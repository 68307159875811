'use strict';

angular.module('sysadmin.components.imports', [
    'sysadmin.services.importsService',
    'shared.services.toastService',
]).component('imports', {
    templateUrl: '/Scripts/app/sysadmin/components/imports.template.html',
    bindings: {
        organisation: '<',
    },
    controller: [
        'importsService',
        '$scope',
        'toastService',
        'importsDataSourceEnum',
        function importsController(importsService, $scope, toastService, importsDataSourceEnum) {

            var self = this;
            this.anyChanges = false;
            this.dataSources = null;
            this.dataProvisioningDto = null;
            this.dataSourceName = ''
            this.showSaveError = false;
            this.saveErrorMessage = '';

            this.gg4lDataModel = null;
            this.originalGg4lDataModel = null;

            this.isamsDataModel = null;
            this.originalIsamsDataModel = null;

            this.manageBacDataModel = null;
            this.originalManageBacDataModel = null;

            this.wondeDataModel = null;
            this.originalWondeDataModel = null;

            this.isReadyToImport = false;
            this.importRequested = false;
            this.queueMessageId = '';
            this.queueMessageInsertionTime = '';
            this.submittedToQueueName = '';

            this.beforeGg4lSave = null;
            this.beforeIsamsSave = null;
            this.beforeManagebacSave = null;

            this.$onChanges = function (changes)
            {
                if (typeof self.organisation !== 'undefined' && self.organisation != null)
                {
                    self.loadDataProvisioning();
                }
            };

            this.selectedDataSourceIdChanged = function ()
            {
                this.changeDataSource();
            }.bind(this);

            this.changeDataSource = function ()
            {
                for (var i = 0; i < self.dataSources.length; i++)
                {
                    if (self.dataSources[i].id == self.dataProvisioningDto.selectedDataSourceId)
                    {
                        self.dataSourceName = self.dataSources[i].name;
                        break;
                    }
                }

                switch (self.dataProvisioningDto.selectedDataSourceId)
                {
                    case importsDataSourceEnum.GG4L:
                        self.gg4lDataModel = self.dataProvisioningDto.gg4lDto;
                        self.originalGg4lDataModel = JSON.parse(JSON.stringify(self.gg4lDataModel));
                        break;

                    case importsDataSourceEnum.ISAMS:
                        self.isamsDataModel = self.dataProvisioningDto.isamsDto;
                        self.originalIsamsDataModel = JSON.parse(JSON.stringify(self.isamsDataModel));
                        break;

                    case importsDataSourceEnum.ManageBac:
                        self.manageBacDataModel = self.dataProvisioningDto.manageBacDto;
                        self.originalManageBacDataModel = JSON.parse(JSON.stringify(self.manageBacDataModel));
                        break;

                    case importsDataSourceEnum.Wonde:
                        self.wondeDataModel = self.dataProvisioningDto.wondeDto;
                        self.originalWondeDataModel = JSON.parse(JSON.stringify(self.wondeDataModel));
                        break;
                }
            };

            this.loadDataProvisioning = function ()
            {
                importsService.getDataProvisioningData(this.organisation.organisationId).then(function (data)
                {
                    self.dataProvisioningDto = data;
                    self.dataSources = self.dataProvisioningDto.dataSources;

                    for (var i = 0; i < self.dataSources.length; i++)
                    {
                        if (self.dataSources[i].id == self.dataProvisioningDto.selectedDataSourceId)
                        {
                            self.dataSourceName = self.dataSources[i].name;
                            break;
                        }
                    }

                    switch (self.dataProvisioningDto.selectedDataSourceId)
                    {
                        case importsDataSourceEnum.GG4L:
                            self.gg4lDataModel = self.dataProvisioningDto.gg4lDto;
                            self.originalGg4lDataModel = JSON.parse(JSON.stringify(self.gg4lDataModel));
                            self.isReadyToImport = self.gg4lDataModel.enabled;
                            break;

                        case importsDataSourceEnum.ISAMS:
                            self.isamsDataModel = self.dataProvisioningDto.isamsDto;
                            self.originalIsamsDataModel = JSON.parse(JSON.stringify(self.isamsDataModel));
                            self.isReadyToImport = self.isamsDataModel.enabled;
                            break;

                        case importsDataSourceEnum.ManageBac:
                            self.manageBacDataModel = self.dataProvisioningDto.manageBacDto;
                            self.originalManageBacDataModel = JSON.parse(JSON.stringify(self.manageBacDataModel));
                            self.isReadyToImport = self.manageBacDataModel.enabled;
                            break;

                        case importsDataSourceEnum.Wonde:
                            self.wondeDataModel = self.dataProvisioningDto.wondeDto;
                            self.originalWondeDataModel = JSON.parse(JSON.stringify(self.wondeDataModel));
                            self.isReadyToImport = self.wondeDataModel.isImportEnabled;
                            break;
                    }

                    self.importRequested = false;
                    self.queueMessageId = '';
                    self.queueMessageInsertionTime = '';
                    self.submittedToQueueName = '';

                }, function () {
                    simpleAlertService.errorAlert();
                });

                self.anyChanges = false;
            };

            this.saveDataProvisioning = function ()
            {
                this.showSaveError = false;

                if (this.dataProvisioningDto.selectedDataSourceId === importsDataSourceEnum.GG4L && this.beforeGg4lSave) {
                    this.beforeGg4lSave();
                } else if (this.dataProvisioningDto.selectedDataSourceId === importsDataSourceEnum.ISAMS && this.beforeIsamsSave) {
                    this.beforeIsamsSave();
                } else if (this.dataProvisioningDto.selectedDataSourceId === importsDataSourceEnum.ManageBac && this.beforeManagebacSave) {
                    this.beforeManagebacSave();
                }

                importsService.saveDataProvisioningData(this.dataProvisioningDto).then(function (response)
                {
                    self.loadDataProvisioning();

                    if (typeof response.data !== 'undefined')
                    {
                        if (response.data.status == 400) // Bad Request
                        {
                            this.showSaveError = true;
                            this.saveErrorMessage = response.data.Message;
                        }
                        else if (typeof response.data !== 'undefined' &&
                            typeof response.data.Message !== 'undefined' &&
                            response.data.Message != '')
                        {
                            this.showSaveError = true;
                            this.saveErrorMessage = response.data.Message;
                        }
                        return;
                    }

                    toastService.saveSuccess();
                }.bind(this));
            }.bind(this);

            this.changesMade = function () {
                self.anyChanges = true;
            }

            this.changesNotified = function (enableSave) {
                self.anyChanges = enableSave;
            };

            this.requestImport = function ()
            {
                var wondeSchoolId = '';
                var isImportEnabled = true;

                if (this.dataProvisioningDto.selectedDataSourceId == importsDataSourceEnum.Wonde)
                {
                    wondeSchoolId = this.dataProvisioningDto.wondeDto.schoolId;
                    isImportEnabled = this.dataProvisioningDto.wondeDto.isImportEnabled;
                }

                if (!isImportEnabled)
                {
                    console.log('Import is not enabled');
                    return;
                }

                var dataProvisioningImportRequestDto =
                {
                    dataSourceName: self.dataSourceName,
                    organisationId: self.organisation.organisationId,
                    wondeSchoolId: wondeSchoolId
                };

                importsService.requestImport(dataProvisioningImportRequestDto).then(function (data)
                {
                    if (data != '')
                    {
                        self.queueMessageId = data.id;
                        self.queueMessageInsertionTime = moment(data.insertionTime).format('DD/MM/YYYY HH:mm:ss');
                        self.importRequested = true;

                        switch (self.dataProvisioningDto.selectedDataSourceId)
                        {
                            case importsDataSourceEnum.GG4L:
                                self.submittedToQueueName = 'schoolmessengerimport';
                                break;

                            case importsDataSourceEnum.ISAMS:
                                self.submittedToQueueName = 'isamsbatchapiimport';
                                break;

                            case importsDataSourceEnum.ManageBac:
                                self.submittedToQueueName = 'managebacimport';
                                break;

                            case importsDataSourceEnum.Wonde:
                                self.submittedToQueueName = 'wondeimport';
                                break;
                        }
                    }
                });
            }.bind(this);
        }
    ]
});
