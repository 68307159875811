import { ToastService } from '@sb-core/services/toast.service';
import { Injectable } from "@angular/core";
import { Toast } from '@sb-shared/models/UI/toast';

@Injectable({
    providedIn: 'root',
  })

  export class Ng1ToastService {

    constructor(private toast: ToastService) {}

    success(toastItem: Toast) {
      this.toast.show({...toastItem, toastClass: 'success'});
    }

    danger(toastItem: Toast) {
      this.toast.show({...toastItem, toastClass: 'danger'});
    }

    warning(toastItem: Toast) {
      this.toast.show({...toastItem, toastClass: 'warning'});
    }

    info(toastItem: Toast) {
      this.toast.show({...toastItem, toastClass: 'info'});
    }

    settings = {};

  }
