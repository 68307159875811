angular.module('shared.components.sbButton', [
    'shared.components.sbIcon'
])
    .component('sbButton', {
            transclude: true,
            templateUrl: '/Scripts/app/shared/components/sb-button.template.html',
            bindings: {
                onClick: '&',
                message: '@',
                messageXs: '@',
                messageTranslationParameters: '<',
                buttonClass: '@', // bootstrap button class e.g. primary, success. Defaults to primary.
                customClasses: '@',
                iconClasses: '@',
                iconStyle: '@',
                buttonId: '<',
                buttonType: '@',
                isDisabled: '<',
                // Removed isShowing (exists in sbSubmitButton)
                iconName: '@',
                xsIconOnly: '<',
                isLoading: '<',
                tooltip: '@',
            },
            controller: class SbButtonCtrl {

                computedClass: string = 'btn';
                buttonClass: string;
                customClasses: string;
                xsIconOnly: boolean;
                buttonType: string = 'button';

                $onInit() {
                    // Comment moved from sbSubmitButton
                    // Note: before I switched to use single computed class attribute, I concatenated
                    // the various classes in the view, this interfered with isShowing, where angular
                    // adds/removes ng-hide, the conflict meant in some scenarios the button would not
                    // hide even when isShowing is false.

                    if (typeof this.buttonClass === 'undefined' && typeof this.customClasses === 'undefined') {
                        // no class provided
                        this.computedClass = 'btn';
                    }
                    else {
                        this.computedClass = 'btn btn-' + this.buttonClass + ' ' + this.customClasses;
                    }
                    if (this.xsIconOnly) {
                        this.computedClass = this.computedClass +' btn-xs-icon';
                    }
                }
            }
    });