import { MenuService } from '@sb-shared/services/menu.service';
import { MenuItems } from '@sb-shared/models/UI/menu-item';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'sb-page-tabs',
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss']
})
export class PageTabsComponent implements OnInit {

  currentPageTabs$: Observable<MenuItems>;

  constructor(private menu: MenuService) { }

  ngOnInit(): void {
    this.currentPageTabs$ = this.menu.getPageTabs();
  }

}
