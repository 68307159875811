import { apis, controllerTypes, HttpRequestSettings } from './../models/request-settings';
import { HttpWebApiService } from './http-web-api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  settings: HttpRequestSettings = {
    controllerType: controllerTypes.SysAdmin,
    api: apis.Core
  }

  constructor(private http: HttpWebApiService) {}

  getCountries() {
    return this.http.get('Country', this.settings);
  }
}
