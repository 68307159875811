angular.module('shared.directives.updateLanguage', [])
    .directive('updateLanguage', function ($rootScope) {
        return {
            link: function (scope, element) {

                var listener = function (event, translationResp) {

                    if ($rootScope.scriptLanguage)
                        element.attr('lang', $rootScope.scriptLanguage);

                    if ($rootScope.scriptDirection)
                        element.attr('dir', $rootScope.scriptDirection);
                };

                $rootScope.$on('$translateChangeEnd', listener);
            }
        };
    });