interface RequestSettings {
    urlParams: any;
    successMessage: string
    errorMessage: string
    showSuccessToast: boolean,
    controllerType: any,
    orgId: number // For controller type of Organisation, overwrite default orgId
    config: any,
    cache: any,
    isMock: boolean
  }

  angular.module('shared.services.httpCoreApi', [
    'shared.services.toastService',
    'shared.services.dateTimeService',
  ])
    .service('httpCoreApi', class HttpCoreApiService {

        $http: any;
        $window: any;
        $translate: any;
        toastService: any;
        dateTimeService: any;
        config: {
            headers: {};
        };
        urlBase: string;
        orgString: string;
        httpMethods: any;
        controllerTypes: any;

        static $inject = ['$http', '$window', '$translate', 'toastService', 'dateTimeService'];

        constructor($http, $window, $translate, toastService, dateTimeService) {
            this.$http = $http;
            this.$window = $window;
            this.$translate = $translate;
            this.toastService = toastService;
            this.dateTimeService = dateTimeService;
            this.urlBase = `${this.$window.EveryBuddy.CoreAPI}/api/`;
            this.orgString = this.$window.EveryBuddy.CurrentOrganisationId;
            this.controllerTypes = {
                SysAdmin: {
                    url: 'SysAdmin',
                    hasOrg: false
                },
                Admin:
                {
                    url: 'Admin',
                    hasOrg: true
                },
                User:
                {
                    url: 'User',
                    hasOrg: true
                }
            };

            this.httpMethods = {
                Get: 1,
                Head: 2,
                Post: 3,
                Put: 4,
                Delete: 5,
                Connect: 6,
                Options: 7,
                Trace: 8,
                Patch: 9
            }
        }

        configObject(settings: RequestSettings) {
            // Controllers without org in URL require org in query string.
            if (!settings.controllerType.hasOrg && settings.orgId) {
                settings.urlParams = settings.urlParams || {};
                settings.urlParams.organisationId = settings.orgId;
            }
            if (settings?.config) {
                return { ...settings.config, params: settings.urlParams, cache: settings?.cache ?? false };
            }
            else {
                return {  params: settings.urlParams, cache: settings?.cache ?? false };
            }
        }

        get(url, settings: RequestSettings) {
            const httpMethod = this.httpMethods.Get;
            return this.$http.get(this.formatUrl(url, settings), this.configObject(settings))
                .then(res => {
                    return this.handleSuccess(httpMethod, res, settings);
                })
                .catch(err => {
                    return this.handleError(httpMethod, err, settings);
                });
        };

        post(url, body, settings: RequestSettings) {
            const httpMethod = this.httpMethods.Post;
            return this.$http.post(this.formatUrl(url, settings), body, this.configObject(settings))
                .then(res => {
                    return this.handleSuccess(httpMethod, res, settings);
                })
                .catch(err => {
                    return this.handleError(httpMethod, err, settings);
                });
        };

        put(url, body, settings: RequestSettings) {
            const httpMethod = this.httpMethods.Put;
            return this.$http.put(this.formatUrl(url, settings), body, this.configObject(settings))
                .then(res => {
                    return this.handleSuccess(httpMethod, res, settings);
                })
                .catch(err => {
                    return this.handleError(httpMethod, err, settings);
                });
        };

        delete(url, body, settings: RequestSettings) {
            const httpMethod = this.httpMethods.Delete;

            return this.$http.delete(this.formatUrl(url, settings), {
                ...this.configObject(settings),
                data: body,
                headers: { ...this.configObject(settings).headers, 'Content-Type': 'application/json;charset=utf-8' } // Different formatting for angularJs delete request
            })
            .then(res => {
                return this.handleSuccess(httpMethod, res, settings);
            })
            .catch(err => {
                return this.handleError(httpMethod, err, settings);
            });
        }

        patch(url, body, settings: RequestSettings) {
            const httpMethod = this.httpMethods.Patch;
            return this.$http.patch(this.formatUrl(url, settings), body, this.configObject(settings))
                .then(res => {
                    return this.handleSuccess(httpMethod, res, settings);
                })
                .catch(err => {
                    return this.handleError(httpMethod, err, settings);
                });
        };

        formatUrl(url: string, settings: RequestSettings) {
            // Add '/Organisation/' folder if that controller type, plus url params.
            // Might want to change how this works when we have more controller types
            const orgString = settings.controllerType.hasOrg
                ? `/${settings.orgId || this.$window.EveryBuddy.CurrentOrganisationId}/`
                : '/';

            const realBase = this.urlBase + settings.controllerType.url + orgString;
            // OutSystems const mockBase = 'https://personal-epztywzu.outsystemscloud.com/SchoolsBuddytestAPI/rest';
            // MockApi.io const mockBase = 'https://6149b68607549f001755a515.mockapi.io';
            /* Mockoon */ const mockBase = 'https://localhost:3000/';

            return (settings.isMock ? mockBase : realBase) + url;
        }

        removeGenericError(message: string) {
            // In case of a generic error message, pass blank value so appropriate default message is shown, eg 'Error loading'
            return message.replace('Request failed: An unhandled exception occurred.', '');
        }

        formatData(data) {
            // Add created/modified age from created/modified UTC times
            var handleObject = (obj) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(item => {
                            this.formatData(item);
                        })
                    }
                    else if (angular.isObject(value)) {
                        handleObject(value);
                    }
                    else if (key === 'createdDateTimeUtc') {
                        obj.createdAge = this.dateTimeService.getAgeLabel(value);
                    }
                    else if (key === 'modifiedDateTimeUtc') {
                        obj.modifiedAge = this.dateTimeService.getAgeLabel(value);
                    }
                });
            }
            if (Array.isArray(data)) {
                data.forEach(item => {
                    this.formatData(item);
                })
            }
            else if (angular.isObject(data)) {
                handleObject(data);
            }
            return data;
        }

        handleSuccess(type: number, response, settings: RequestSettings) {
            // Hide by default for get, show by default for other, unless specified in settings.showSuccessToast
            const getSuccess = type === this.httpMethods.Get && settings?.showSuccessToast;
            const otherSuccess = type !== this.httpMethods.Get && settings?.showSuccessToast !== false;
            if (getSuccess || otherSuccess) {
                switch (type) {
                    case this.httpMethods.Get:
                        this.toastService.getSuccess(settings?.successMessage);
                        break;
                    case this.httpMethods.Post:
                        this.toastService.saveSuccess(settings?.successMessage);
                        break;
                    case this.httpMethods.Put:
                        this.toastService.saveSuccess(settings?.successMessage);
                        break;
                    case this.httpMethods.Delete:
                        this.toastService.deleteSuccess(settings?.successMessage);
                        break;
                    case this.httpMethods.Patch:
                        this.toastService.saveSuccess(settings?.successMessage);
                        break;
                    default:
                }
            }
            const formattedData = this.formatData(response.data.data);
            return { ...response.data, data: formattedData };
        }

        handleError(type: number, err, settings: RequestSettings) {

            let message = '';
            let fields = [];
            if (settings?.errorMessage) {
                message = settings.errorMessage;
            }
            else if (err.data?.Errors?.length > 0) {
                message = this.removeGenericError(err.data.Errors[0]);
                err.data.Errors.forEach(err => {
                    console.error(err);
                })
            }
            else if (err.data) {
                Object.entries(err.data).forEach(([key, value]) => {
                    message += value[0];
                    fields.push({
                        fieldId: key,
                        message: value
                    });
                });
            }
            switch (type) {
                case this.httpMethods.Get:
                    this.toastService.getError(message);
                    break;
                case this.httpMethods.Post:
                    this.toastService.saveError(message);
                    break;
                case this.httpMethods.Put:
                    this.toastService.saveError(message);
                    break;
                case this.httpMethods.Delete:
                    this.toastService.saveError(message);
                    break;
                case this.httpMethods.Patch:
                    this.toastService.saveError(message);
                    break;
                default:
                    this.toastService.error(message)
            }
            return { isError: true, message: message, fields: fields };
        }
    })
