angular.module('shared.directives.sbRadioButton', [])
    .directive('sbRadioButton', [function () {

        function controller($scope)
        {
            var self = this;

            this.check = function()
            {
                if (self.disabled)
                {
                    return;
                }

                self.model = self.trueValue;

                if (self.onClick && typeof(self.onClick()) === 'function')
                {
                    self.onClick()(self.model);
                }
            };

            this.isChecked = function()
            {
                if (typeof (self.checked) !== 'undefined')
                {
                    return self.checked;
                }

                return self.model === self.trueValue;
            };
        };

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/shared/directives/sb-radio-button.template.html',
            scope: {},
            bindToController: {
                model: '=',
                trueValue: '<',
                controlName: '@',
                onClick: '&',
                checked: '=',
                disabled: '='
            },
            controller: ['$scope', controller],
            controllerAs: 'ctrl'
        };
    }]);
