'use strict';

angular.module('calendarEventCreationRule.directives.calendarEventCreationRuleEditor', [
    'ui.tinymce',
    'calendarEventCreationRule.services.calendarEventCreationRuleService',
    'calendarEventRules.constants',
    'ccasignup.constants',
    'ccaSignUp.services.signUpRepository',
    'ccaSignUp.services.organisationRepository',
    'shared.components.sbIcon',
    'shared.controllers.confirmationPopup',
    'shared.directives.sbCheckBox',
    'shared.filters.decoded',
    'shared.services.simpleAlertService',
    'shared.services.tinymceConfigHelper'
]).directive('calendarEventCreationRuleEditor', [
    '$window',
    '$translate',
    '$locale',
    'calendarEventCreationRuleService',
    'calendarEventLocationOption',
    'signUpRepository',
    'organisationRepository',
    'tinymceConfigHelper',
    'simpleAlertService',

    function (
        $window,
        $translate,
        $locale,
        calendarEventCreationRuleService,
        calendarEventLocationOption,
        signUpRepository,
        organisationRepository,
        tinymceConfigHelper,
        simpleAlertService) {

        function link($scope) {

            $scope.calendarEventLocationOption = calendarEventLocationOption;

            $scope.$watch('rule',
                function () {
                    if ($scope.rule) {
                        // set the initial day of week flags
                        for (var i = $scope.daysOfWeek.length; i--;) {
                            $scope.daysOfWeek[i].checked = $scope.isDayOfWeekSelected($scope.daysOfWeek[i].id);
                        }

                        $scope.startHourMin = { h: $scope.rule.startHour, m: $scope.rule.startMin };
                        $scope.endHourMin = { h: $scope.rule.endHour, m: $scope.rule.endMin };

                        $scope.getRuleSignupOpenClose();

                        $scope.tryAndSetCalendarEventType();

                        $scope.setMultipleDays();

                        $scope.calculateSignUpMaxValues();
                    }
                });

            $scope.$watch('rule.parentTeacherConferenceLocationTypeId', function () {
                if (!$scope.rule || !$scope.parentTeacherConferenceLocationTypes || !$scope.createMode()) {
                    return;
                }
                //Can't actually select Both as an option. If at anytime this is passed remove the property so the drop down sets 'please select'
                if ($scope.rule.parentTeacherConferenceLocationTypeId === $scope.parentTeacherConferenceLocationTypes.Both) {
                    delete $scope.rule.parentTeacherConferenceLocationTypeId;
                }
            });

            $scope.$watch('rule.startDate', function () {
                if (!$scope.rule) return;

                // Check for multiple to single day mode transition.
                if ($scope.multipleDays) {
                    $scope.setMultipleDays();
                    $scope.calculateAvailableDays();
                }

                $scope.calculateSignUpMaxValues();
            });

            $scope.$watch('rule.endDate', function () {
                if (!$scope.rule) return;

                // Check for multiple to single day mode transition.
                // Note: we must currently be in multiple day mode in order
                // to have changed end date.
                $scope.setMultipleDays();
                $scope.calculateAvailableDays();
                $scope.calculateSignUpMaxValues();
            });

            $scope.$watch('startHourMin', function () {
                if (!$scope.rule) return;
                $scope.calculateSignUpMaxValues();
            });

            $scope.$watch('endHourMin', function () {

                if (!$scope.rule) return;
                $scope.calculateSignUpMaxValues();
            });

            $scope.$watch('signUpClose', function () {
                if (!$scope.rule) return;
                $scope.calculateSignUpMaxValues();
            });

            $scope.$watch('ccaSignUpType', function () {
                if ($scope.ccaSignUpType) {
                    $scope.parentsEvening =
                        $scope.ccaSignUpType?.supportSlots && !$scope.ccaSignUpType?.isOrganisationAllocated;
                }
            });

            $scope.$watch('rule.ccaCostType', function (newValue) {
                if ($scope.disableInputWatchers)
                    return;

                if ($scope.rule && $scope.rule.ccaCalendarEventCreationRuleId === 0) {
                    if (newValue === 0) {
                        $scope.rule.transactionApplyTypeId = 2;
                        $scope.rule.ccaPrice = 0;
                        $scope.rule.upfrontCostPerPerson = 0;
                    }
                    else if (newValue === 1) {
                        $scope.rule.transactionApplyTypeId = 2;
                        $scope.rule.upfrontCostPerPerson = 100;
                    }
                }
            });

            $scope.$watch('rule.locationId', function () {
                if (!$scope.rule) {
                    return;
                }

                // can't be 0 when it's not in the list stops validaiton and binding working
                if ($scope.rule.locationId === 0) {
                    /// when location is presented as zero and not create more this means the user selected Group
                    delete $scope.rule.locationId;
                }
            });
        }

        function controller($scope, $filter, parentTeacherConferenceLocationTypes) {

            {   //// Lookup Setup ////
                $scope.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: false, hyperlinks: true });
                var i = 0;

                var loading = 2;
                var PARENTS_EVENING_CATEGORY_TYPE = parseInt(8, 10);
                $scope.locations = $scope.locations || [];

                $scope.isLoading = function () { return loading > 0; };
                $scope.parentsEvening = false;
                $scope.disableInputWatchers = false;
                $scope.multipleDays = true;
                $scope.showPricing = function () { return !$scope.rule || $scope.rule.ccaCostType !== 0; };
                $scope.createMode = function () { return !$scope.rule || $scope.rule.ccaCalendarEventCreationRuleId === 0; };
                $scope.statefulSaveButtonText = function () {
                    return $scope.createMode()
                        ? 'SB_Create_Rule'
                        : 'SB_Save_changes';
                };
                $scope.signUpOpenDaysBefore = null;
                $scope.signUpCloseDaysBefore = null;
                $scope.dtf = $locale.DATETIME_FORMATS;
                $scope.parentTeacherConferenceLocationTypes = parentTeacherConferenceLocationTypes;

                $scope.daysOfWeek = [
                    { id: 1, name: 'SB_WeekDay1', idx: 1, enabled: true },
                    { id: 2, name: 'SB_WeekDay2', idx: 2, enabled: true },
                    { id: 4, name: 'SB_WeekDay3', idx: 3, enabled: true },
                    { id: 8, name: 'SB_WeekDay4', idx: 4, enabled: true },
                    { id: 16, name: 'SB_WeekDay5', idx: 5, enabled: true },
                    { id: 32, name: 'SB_WeekDay6', idx: 6, enabled: true },
                    { id: 64, name: 'SB_WeekDay0', idx: 0, enabled: true }
                ];

                $scope.statuses = [
                    { id: '0', name: 'SB_Not_Started' },
                    { id: '1', name: 'SB_CcaStatus_Active' },
                    { id: '2', name: 'SB_Canceled' },
                    { id: '3', name: 'SB_Processing' }
                ];

                $scope.costTypes = [
                    { id: '0', name: 'SB_Free' },
                    { id: '1', name: 'SB_Chargeable' }
                ];

                $scope.applyTypes = [
                    { id: '2', name: 'SB_Immediate_SchoolsBuddy_payments' },
                    { id: '0', name: 'SB_Hide_Ignore_fees' }
                ];

                $scope.allOnlinePTCOptions = [
                    {
                        id: parentTeacherConferenceLocationTypes.InPerson,
                        label: 'SB_CcaSignUp_PTC_Meeting_Format_Option_InPerson_Label'
                    },
                    {
                        id: parentTeacherConferenceLocationTypes.Online,
                        label: 'SB_CcaSignUp_PTC_Meeting_Format_Option_Online_Label'
                    },
                    {
                        id: parentTeacherConferenceLocationTypes.Both,
                        label:'SB_CcaSignUp_PTC_Meeting_Format_Option_Both_Label'
                    }
                ]

                //restrict to the containers configuration.
                $scope.onlinePTCOptions = function () {

                    if (!$scope.ccaSignUp)
                    {
                        return [];
                    }

                    // if Both was set at container level then the event can pick up any of the 3 options
                    if ($scope.ccaSignUp.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.Both){
                        return $scope.allOnlinePTCOptions.filter(x => x.id !== parentTeacherConferenceLocationTypes.Both);
                    }

                    return $scope.allOnlinePTCOptions.filter(x => x.id == $scope.ccaSignUp.parentTeacherConferenceLocationTypeId);
                }

                signUpRepository
                    .getCalendarEventCategoryTypes($scope.ccaSignUp.isPTC)
                    .then(function (calendarEventCategoryTypes) {
                        $scope.calendarEventCategoryTypes = calendarEventCategoryTypes;
                        $scope.tryAndSetCalendarEventType();

                        // for new rules, default to non-block events, over multiple days (unless parents evening, then single day)
                        if ($scope.createMode()) {
                            // only re-set this if it doesn't exist. Might be in copy mode
                            if (!$scope.rule.hasOwnProperty('isBlockEvent')) {
                                $scope.rule.isBlockEvent = false;
                            }
                            $scope.multipleDays = !$scope.parentsEvening;
                        }

                        // for new rules, or existing non-block rules, default to allow join requests, should they
                        // choose the make the rule a block booking.
                        if ($scope.createMode() || !$scope.rule.isBlockEvent) {
                            $scope.rule.allowJoiningOfInFlightBlockEvent = true;
                        }

                        loading--;
                    });

                organisationRepository
                    .get()
                    .then(function (organisation) {
                        $scope.organisation = organisation;
                        loading--;
                    });
            }

            $scope.toggleSelectAllGroups = function (checked) {
                for (var i = $scope.signUpGroups.length; i--;) {
                    $scope.signUpGroups[i].checked = checked;
                }
            };

            $scope.calculateAvailableDays = function () {
                var startDate = moment.utc($scope.rule.startDate);
                var endDate = moment.utc($scope.rule.endDate);
                var range = endDate.diff(startDate, 'days');
                var i = 0;

                // is the date range greater than a week?
                if (range > 7) {
                    for (i = $scope.daysOfWeek.length; i--;) {
                        $scope.daysOfWeek[i].enabled = true;
                    }
                } else {
                    for (i = $scope.daysOfWeek.length; i--;) {
                        $scope.daysOfWeek[i].enabled = false;
                    }

                    // figure out which days we do have available
                    for (var theDate = startDate; !theDate.isAfter(endDate); theDate.add(1, 'd')) {
                        $scope.daysOfWeek[theDate.isoWeekday() - 1].enabled = true;
                    }

                    // make sure we'e not got an ineligible day of the week selected
                    for (i = $scope.daysOfWeek.length; i--;) {
                        if (!$scope.daysOfWeek[i].enabled) {
                            $scope.daysOfWeek[i].checked = false;
                        }
                        else {
                            if (!$scope.multipleDays) {
                                $scope.daysOfWeek[i].checked = true;
                            }
                        }
                    }
                }
            };

            $scope.onlineParentsEveningLabel = function () {
                return $filter('translate')($scope.ccaSignUp.onlineParentsEveningLabel);
            };

            $scope.isOnlineParentsEveningAutoGenerated = function () {
                return $scope.rule?.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.Online;
            };

            $scope.showLocation = function () {
                return !$scope.parentsEvening ||
                    ($scope.rule?.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.InPerson);
            };

            $scope.showOnlineLabel = function () {
                return $scope.parentsEvening &&
                    ($scope.rule?.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.Online);
            };

            // Recalculate max constraint for single event sign up values
            // Close must be before event
            // Open must be before close, if set, otherwise before event
            $scope.calculateSignUpMaxValues = function () {
                if (!$scope.rule || !$scope.rule.startDate) {
                    return;
                }

                $scope.signUpCloseMax = moment.utc($scope.rule.startDate).hour($scope.startHourMin.h).minute($scope.startHourMin.m);
                $scope.signUpOpenMax = !!$scope.signUpClose
                    ? $scope.signUpClose
                    : $scope.signUpCloseMax;
            }

            $scope.tryAndSetCalendarEventType = function () {
                // do we need to select a new event category type id?
                if ($scope.calendarEventCategoryTypes &&
                    $scope.rule &&
                    ($scope.rule.calendarEventCategoryTypeId === -1 || !$scope.rule.calendarEventCategoryTypeId)) {
                    var categorySet = false;
                    if ($scope.parentsEvening)
                    {
                        // see if we can find the parents evening event type (there may not be one defined..)
                        for (var i = $scope.calendarEventCategoryTypes.length; i--;)
                        {
                            if ((parseInt($scope.calendarEventCategoryTypes[i].systemUsageTypeId, 10) & PARENTS_EVENING_CATEGORY_TYPE) === PARENTS_EVENING_CATEGORY_TYPE)
                            {
                                $scope.rule.calendarEventCategoryTypeId =
                                    $scope.calendarEventCategoryTypes[i].calendarEventCategoryTypeId;
                                categorySet = true;
                                break;
                            }
                        }
                    }

                    if (!categorySet) {
                        $scope.rule.calendarEventCategoryTypeId =
                            $scope.calendarEventCategoryTypes[0].calendarEventCategoryTypeId;
                    }
                }
            };

            $scope.multipleDaysSelected = function () {
                // ensure the start and end days are different.
                if ($scope.rule.startDate.toString() === $scope.rule.endDate.toString()) {
                    $scope.rule.endDate = moment.utc($scope.rule.endDate).add(1, 'd').toDate();
                }
            }

            $scope.setMultipleDays = function () {
                if (!$scope.rule.startDate || !$scope.rule.endDate) {
                    return;
                }

                $scope.multipleDays = $scope.rule.startDate.toString() !== $scope.rule.endDate.toString();
            };

            $scope.costTypeChanged = function () {
                if ($scope.rule.ccaCostType === 0) {
                    $scope.rule.ccaPrice = 0;
                    $scope.rule.upfrontCostPerPerson = 0;
                    $scope.rule.transactionApplyTypeId = 2;
                }
            };

            $scope.findClashEventRules = function (teamIds) {
                var n_start = moment.utc($scope.rule.startDate);
                var n_end = moment.utc($scope.rule.endDate);

                if (!$scope.rules)
                    return false;

                var clashEvent = null;
                for (var i = $scope.rules.length; i--;) {
                    if (
                        // we only care if it's a rule for teams we're creating this for
                        teamIds.indexOf($scope.rules[i].teamId) > -1 &&
                        // if we're saving changes, make sure we don't clash against ourself
                        $scope.rules[i].ccaCalendarEventCreationRuleId !==
                        $scope.rule.ccaCalendarEventCreationRuleId
                    ) {
                        var e = $scope.rules[i];
                        var e_start = moment.utc(e.startDate);
                        var e_end = moment.utc(e.endDate);
                        if (!(n_end.isBefore(e_start, 'day') || n_start.isAfter(e_end, 'day'))) {
                            // well, the dates clash- what about the times?

                            var n_startTime = moment();
                            n_startTime.hour($scope.rule.startHour);
                            n_startTime.minute($scope.rule.startMin);

                            var n_endTime = moment();
                            n_endTime.hour($scope.rule.endHour);
                            n_endTime.minute($scope.rule.endMin);

                            var e_startTime = moment();
                            e_startTime.hour(e.startHour);
                            e_startTime.minute(e.startMin);

                            var e_endTime = moment();
                            e_endTime.hour(e.endHour);
                            e_endTime.minute(e.endMin);

                            if (!(n_endTime.isBefore(e_startTime) || n_startTime.isAfter(e_endTime))) {
                                clashEvent = $scope.rules[i];
                                break;
                            }
                        }
                    }
                }

                return clashEvent;
            };

            var ensureStartEndDatesChronological = function () {
                var n_start = moment.utc($scope.rule.startDate);
                var n_end = moment.utc($scope.rule.endDate);
                {
                    if (n_start.isAfter(n_end)) {
                        var temp = $scope.rule.endDate;
                        $scope.rule.endDate = $scope.rule.startDate;
                        $scope.rule.startDate = temp;

                        n_start = moment.utc($scope.rule.startDate);
                        n_end = moment.utc($scope.rule.endDate);
                    }
                }
            };

            $scope.getEventSummary = function (event) {
                var i = 0;
                var summary = '';
                for (i = $scope.signUpGroups.length; i--;) {
                    if ($scope.signUpGroups[i].id === event.teamId) {
                        summary += '<strong>' + $scope.signUpGroups[i].title + '</strong><br/>';
                        break;
                    }
                }
                if ($scope.organisers && $scope.organisers.length > 0) {
                    for (i = $scope.organisers.length; i--;) {
                        if ($scope.organisers[i].id === event.organiserPersonId) {
                            summary += $translate.instant('SB_With') + ' <strong>' + $scope.organisers[i].name + '</strong>';
                        }
                    }
                }

                var location = $translate.instant('SB_Unspecified_location');

                for (i = $scope.locations.length; i--;) {
                    if ($scope.locations[i].id === event.locationId) {
                        location = $scope.locations[i].name;
                    }
                }


                summary += ' ' + $translate.instant('SB_At') + ' <strong>' + location + '</strong>';

                summary += '<br/>' + event.title + '<br/>';
                summary += $filter('date')(event.startDate, 'EEE dd MMM yyyy', 'UTC');

                if (event.startDate !== event.endDate) {
                    summary += ' - ' + $filter('date')(event.endDate, 'EEE dd MMM yyyy', 'UTC');
                }

                summary += '<br/>' +
                    event.startTime.substring(0, 5) +
                    ' - ' +
                    event.endTime.substring(0, 5);

                summary += '<br/>' + calendarEventCreationRuleService.selectedDaysSummary(event.daysOfWeek);

                return summary;
            };

            $scope.cancel = function () {

                if ($scope.onCancel && typeof $scope.onCancel() === 'function') {
                    $scope.onCancel()();
                }
                else {
                    console.log('no cancel callback has been set');
                }

            };

            $scope.save = function (bypassClashWarnings) {

                var savedEvents = [];
                var entity = null;
                var i = 0,
                    j = 0;

                if ($scope.eventTemplateForm.$invalid) {
                    return;
                }

                if (!$scope.multipleDays) {
                    $scope.rule.endDate = $scope.rule.startDate;
                    $scope.calculateAvailableDays();
                }

                $scope.setRuleSignupOpen();
                $scope.setRuleSignupClose();

                ensureStartEndDatesChronological();

                //form validation

                var errorMessages = [];
                var groupsToAddRuleTo = [];
                if ($scope.rule.ccaCalendarEventCreationRuleId === 0) {

                    for (i = $scope.signUpGroups.length; i--;) {
                        if ($scope.signUpGroups[i].checked) {
                            groupsToAddRuleTo.push({
                                teamId: $scope.signUpGroups[i].id,
                                organiserId: $scope.signUpGroups[i].organiserId,
                                defaultLocationId: $scope.signUpGroups[i].defaultLocationId
                            });
                        }
                    }
                    // did nt find a checked item , may we are in copy mode
                    if (groupsToAddRuleTo.length < 1) {
                        if ($scope.rule.teamId) {

                            // find selected team from signup groups
                            var selectedTeam = $scope.signUpGroups.filter(function (group) {
                                return group.id === $scope.rule.teamId;
                            });

                            if (selectedTeam.length > 0) {
                                groupsToAddRuleTo.push({
                                    teamId: selectedTeam[0].id,
                                    organiserId: selectedTeam[0].organiserId,
                                    defaultLocationId: selectedTeam[0].defaultLocationId
                                });
                            }
                        }
                    }

                    if (groupsToAddRuleTo.length < 1) {
                        errorMessages.push('SB_calendar_event_creation_rule_no_groups');
                    }
                }

                //no need for timezone agnostic date as we are
                //still in client mode
                var compareStartDate = new Date();
                compareStartDate.setHours($scope.rule.startDate.getHours());
                compareStartDate.setMinutes($scope.rule.startDate.getMinutes());
                compareStartDate.setSeconds($scope.rule.startDate.getSeconds());
                compareStartDate.setMilliseconds($scope.rule.startDate.getMilliseconds());


                var compareEndDate = new Date();
                compareEndDate.setHours($scope.rule.endDate.getHours());
                compareEndDate.setMinutes($scope.rule.endDate.getMinutes());
                compareEndDate.setSeconds($scope.rule.endDate.getSeconds());
                compareEndDate.setMilliseconds($scope.rule.endDate.getMilliseconds());

                if (compareStartDate > $scope.rule.startDate || compareEndDate > $scope.rule.endDate) {
                    errorMessages.push('SB_calendar_event_creation_rule_startdate_or_enddate_in_the_past');
                }

                // a sign-up open offset of 0 hours doesn't make sense
                if ($scope.rule.signUpOpenHoursBefore == 0) {
                    errorMessages.push('SB_calendar_event_creation_rule_signup_open_cannot_be_zero');
                }
                else if ($scope.rule.signUpCloseHoursBefore !== null &&
                    $scope.rule.signUpOpenHoursBefore !== null &&
                    $scope.rule.signUpCloseHoursBefore >= $scope.rule.signUpOpenHoursBefore) {
                    errorMessages.push('SB_calendar_event_creation_rule_signup_close_not_after_signup_open');
                }

                //show alert if we have errors
                if (0 < errorMessages.length) {
                    simpleAlertService.errorAlert({
                        title: 'SB_Error_Saving',
                        message: '',
                        messageArray: errorMessages,
                        windowSize: 'md'
                    });
                    return;
                }

                if ($scope.multipleDays) {
                    var daysSelected = false;
                    for (j = $scope.daysOfWeek.length; j--;) {
                        if ($scope.daysOfWeek[j].checked) {
                            daysSelected = true;
                            break;
                        }
                    }
                    if (!daysSelected) {
                        simpleAlertService.errorAlert({
                            title: 'SB_No_Days_Selected',
                            message: 'SB_calendar_event_creation_rule_no_days',
                            windowSize: 'md'
                        });
                        return;
                    }
                }

                $scope.rule.startHour = $scope.startHourMin.h;
                $scope.rule.startMin = $scope.startHourMin.m;
                $scope.rule.endHour = $scope.endHourMin.h;
                $scope.rule.endMin = $scope.endHourMin.m;

                if (!bypassClashWarnings) {
                    var groupsToCheck = [$scope.rule.teamId];
                    if ($scope.rule.ccaCalendarEventCreationRuleId === 0) {
                        for (i = groupsToAddRuleTo.length; i--;) {
                            groupsToCheck.push(groupsToAddRuleTo[i].teamId);
                        }
                    }

                    var clashEvent = $scope.findClashEventRules(groupsToCheck);
                    if (clashEvent !== null)
                    // if this comes back with a yes, we should stop the save!
                    {
                        var summary = $scope.getEventSummary(clashEvent);

                        var messageText = $filter('decoded')($translate.instant('SB_Clash_detected_message',
                            {
                                summaryText: summary
                            }));

                        // pop a modal giving the warning-- give the user the option to carry on anyway
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Clash_detected',
                            translateMessage: false,
                            message: messageText,
                            okButtonText: 'SB_Yes_create_rule_anyway',
                            cancelButtonText: 'SB_No_cancel_rule_creation',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {
                                // call save again with param to bypass clash check
                                $scope.save(true);
                            });

                        return;// SCHTOP! Itsh not ready yet
                    }
                }

                var savePrompt = null;

                if ($scope.createMode() && !$scope.rule.teamId) {
                    savePrompt = simpleAlertService.pleaseWaitModal();

                    var saving = groupsToAddRuleTo.length;

                    for (i = groupsToAddRuleTo.length; i--;) {
                        entity = angular.copy($scope.rule);

                        entity.daysOfWeek = 0;

                        for (j = $scope.daysOfWeek.length; j--;) {
                            if ($scope.daysOfWeek[j].checked) {
                                entity.daysOfWeek += $scope.daysOfWeek[j].id;
                            }
                        }

                        entity.teamId = groupsToAddRuleTo[i].teamId;
                        entity.organiserPersonId = groupsToAddRuleTo[i].organiserId;

                        if ($scope.rule.locationOptionId === $scope.calendarEventLocationOption.UseGroup) {
                            entity.locationId = groupsToAddRuleTo[i].defaultLocationId;
                        }
                        else if ($scope.rule.locationOptionId != $scope.calendarEventLocationOption.SpecifyLocation) {
                            entity.locationId = null;
                        }

                        calendarEventCreationRuleService.postGroupEventAndReturnSavedEntity(entity)
                            .then(function (savedEntity) {
                                if ($scope.onSave && typeof $scope.onSave() === 'function') {
                                    saving--;

                                    if (saving === 0) {
                                        savePrompt.close();
                                    }

                                    $scope.onSave()(savedEntity); // pass the array of new events back to the caller
                                }
                            });
                    }
                } else {
                    entity = angular.copy($scope.rule);
                    entity.daysOfWeek = 0;

                    if ($scope.rule.locationOptionId === $scope.calendarEventLocationOption.UseGroup) {

                        let selectedTeam = $scope.signUpGroups.filter(function (group) {
                            return group.id === $scope.rule.teamId;
                        });

                        if (selectedTeam && selectedTeam.length > 0) {
                            entity.locationId = selectedTeam[0].defaultLocationId;
                        }
                    }
                    else if ($scope.rule.locationOptionId != $scope.calendarEventLocationOption.SpecifyLocation) {
                        entity.locationId = null;
                    }

                    for (j = $scope.daysOfWeek.length; j--;) {
                        if ($scope.daysOfWeek[j].checked) {
                            entity.daysOfWeek += $scope.daysOfWeek[j].id;
                        }
                    }

                    savePrompt = simpleAlertService.pleaseWaitModal();

                    calendarEventCreationRuleService.postGroupEventAndReturnSavedEntity(entity)
                        .then(function (savedEntity) {
                            angular.copy(savedEntity, $scope.rule);

                            if ($scope.onSave && typeof $scope.onSave() === 'function') {
                                savePrompt.close();
                                $scope.onSave()(savedEntity); // pass the array of new events back to the caller
                            }
                        });
                }
            };

            $scope.isDayOfWeekSelected = function (dayNumber) {
                var day = parseInt(dayNumber, 10);

                var andresult = parseInt($scope.rule.daysOfWeek, 10) & day;

                return andresult === day;
            };

            $scope.checkDayOfWeek = function (dayNumber) {
                if ($scope.isDayOfWeekSelected(dayNumber)) {
                    $scope.rule.daysOfWeek -= dayNumber;
                } else {
                    $scope.rule.daysOfWeek += dayNumber;
                }
            };

            // Set multiple day rule's total hours for signup open advance by combining form's days and hours.
            $scope.setRuleSignupOpen = function () {
                if (!$scope.multipleDays || ($scope.signUpOpenDaysBefore == null && $scope.signUpOpenHoursBefore == null)) {
                    $scope.rule.signUpOpenHoursBefore = null;
                    return;
                }

                $scope.rule.signUpOpenHoursBefore = 0;

                if (!!$scope.signUpOpenDaysBefore) {
                    $scope.rule.signUpOpenHoursBefore += $scope.signUpOpenDaysBefore * 24;
                }

                if (!!$scope.signUpOpenHoursBefore) {
                    $scope.rule.signUpOpenHoursBefore += $scope.signUpOpenHoursBefore;
                }
            }

            // Set multiple day rule's total hours for signup close advance by combining form's days and hours.
            $scope.setRuleSignupClose = function () {
                if (!$scope.multipleDays || (!$scope.signUpCloseDaysBefore && !$scope.signUpCloseHoursBefore)) {
                    $scope.rule.signUpCloseHoursBefore = null;
                    return;
                }

                $scope.rule.signUpCloseHoursBefore = 0;

                if (!!$scope.signUpCloseDaysBefore) {
                    $scope.rule.signUpCloseHoursBefore += $scope.signUpCloseDaysBefore * 24;
                }

                if (!!$scope.signUpCloseHoursBefore) {
                    $scope.rule.signUpCloseHoursBefore += $scope.signUpCloseHoursBefore;
                }
            }

            // Parse rule's total hours for signup open/close advance into form's days and hours.
            $scope.getRuleSignupOpenClose = function () {
                if (typeof $scope.rule.signUpOpenHoursBefore !== 'undefined') {
                    $scope.signUpOpenDaysBefore = Math.floor($scope.rule.signUpOpenHoursBefore / 24);
                    $scope.signUpOpenHoursBefore = $scope.rule.signUpOpenHoursBefore % 24;
                }

                if (typeof $scope.rule.signUpCloseHoursBefore !== 'undefined') {
                    $scope.signUpCloseDaysBefore = Math.floor($scope.rule.signUpCloseHoursBefore / 24);
                    $scope.signUpCloseHoursBefore = $scope.rule.signUpCloseHoursBefore % 24;
                }
            }

            $scope.getCurrencyStep = () => {
                const currencyExponent = $window.EveryBuddy.Constants?.CurrencyExponent;
                if (currencyExponent == void 0) {
                    return null;
                }
                return 1 / Math.pow(10, currencyExponent);
            }
        }

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/calendarEventCreationRule/directives/calendar-event-creation-rule-editor.template.html',
            scope: {
                ccaSignUp: '=',
                ccaSignUpType: '=',
                signUpGroups: '=',
                locations: '=',
                organisers: '=',
                rule: '=',
                rules: '=',
                onSave: '&',
                onCancel: '&'
            },
            controller: ['$scope', '$filter', 'parentTeacherConferenceLocationTypes', controller],
            link: link
        };
    }
]);
