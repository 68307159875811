import { Component, OnInit } from '@angular/core';
import { Button, Buttons } from '@sb-shared/models/UI/buttons';
import { UserUi } from '@sb-shared/models/user-ui';
import { UserService } from '@sb-shared/services/user.service';
import { MemberTypeId } from '@sb-shared/enums/member.type.enum';

@Component({
  selector: 'sb-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit {
  currentUser: UserUi;
  switcherItems: Buttons = [];
  selectedSwitcherItem: Button;

  constructor(private user: UserService) { }

  ngOnInit(): void {
    this.user.getCurrentUser().subscribe(user => {
      this.currentUser = user;

      if (user.hasStaffAccess) {
        this.switcherItems.push({
          message: 'SB_View_As_Staff',
          messageXs: 'SB_Staff',
          isActive: user.isStaff,
          groupId: MemberTypeId.Staff
        });
      }
      if (user.hasParentAccess) {
        this.switcherItems.push({
          message: 'SB_View_As_Parent',
          messageXs: 'SB_Parent',
          isActive: user.isParent,
          groupId: MemberTypeId.Parent
        });
      }
      if (user.hasStudentAccess) {
        this.switcherItems.push({
          message: 'SB_View_As_Student',
          messageXs: 'SB_Student',
          isActive: user.isStudent,
          groupId: MemberTypeId.Student
        });
      }

      this.selectedSwitcherItem = this.switcherItems.find(item => item.isActive);
    });
  }

  onButtonClick(index?: number) {
    this.selectedSwitcherItem = this.switcherItems[index];
    this.user.updateCurrentUserType(this.selectedSwitcherItem.groupId)
  }
}
