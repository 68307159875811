'use strict';

angular.module('ccasignupModule').component('ccaPupilSummary', {
    templateUrl:
        '/Scripts/app/ccaSignUp/Details/cca-pupilsummary.template.html',
    controller: [
        '$scope',
        '$state',
        '$uibModal',
        'dateTextService',
        'signUpRepository',
        'blobStoragePhotosService',
        function (
            $scope,
            $state,
            $uibModal,
            dateTextService,
            signUpRepository,
            blobStoragePhotosService
        ) {
            $scope.disableSticking = false;

            $scope.setPupilFilter = function () {
                $scope.pupilFilter = {};
                $scope.pupilFilter['lastName'] = $scope.filterName;
            };

            var signUpId = $state.params.signUpId;

            signUpRepository
                .getActiveSessionsSignUp(signUpId)
                .then(function (signUpDates) {
                    $scope.signUpDates = signUpDates;
                });

            $scope.signUp = {};

            $scope.dateText = dateTextService.getLongDayText;

            function refresh() {
                signUpRepository.getSignUp(signUpId).then(function (signUp) {
                    $scope.signUp = signUp;
                    signUpRepository
                        .getPupilSummarySignUp(signUpId)
                        .then(function (pupils) {
                            $scope.pupils = pupils;
                            $scope.addPhotosToStudents();
                        });
                });
            }

            $scope.sortType = 'allocationScore'; // set the default sort type
            $scope.sortReverse = true; // set the default sort order

            $scope.sortType1 = 'dayofWeek'; // set the default sort type
            $scope.sortReverse1 = false; // set the default sort order

            $scope.sortType2 = 'dayofWeek'; // set the default sort type
            $scope.sortReverse2 = false; // set the default sort order

            $scope.timeZone = dateTextService.getOrgTimezoneText();

            refresh();

            $scope.ccaGroupDetails = function (signUpGroupId) {
                $state.go('ccaGroupDetails', {
                    signUpId: $state.params.signUpId,
                    signUpGroupId: signUpGroupId,
                });
            };

            $scope.isLive = function (statusId) {
                return statusId === 3;
            };

            $scope.pupilInformation = function (personId) {
                if ($scope.selectedPupil) {
                    $scope.selectedPupil.firstName = 'Loading';
                    $scope.selectedPupil.lastName = '';
                    $scope.selectedPupil.preferences = null;
                    $scope.selectedPupil.notes = '';
                    $scope.selectedPupil.year = '';
                } else {
                    $scope.selectedPupil = {};
                }

                signUpRepository
                    .getPupilPreferences($state.params.signUpId, personId)
                    .then(function (preferences) {
                        $scope.selectedPupil = preferences;
                    });
            };

            function refreshPupil(thisSignUpId, thisPersonId) {
                signUpRepository
                    .getSinglePupilSummarySignUp(thisSignUpId, thisPersonId)
                    .then(function (pupil) {
                        for (var i = 0; i < $scope.pupils.length; i++) {
                            if ($scope.pupils[i].personId === thisPersonId) {
                                $scope.pupils[i] = pupil;
                            }
                        }
                        $scope.addPhotosToStudents();
                    });

                signUpRepository
                    .getPupilPreferences(thisSignUpId, thisPersonId)
                    .then(function (preferences) {
                        $scope.selectedPupil = preferences;
                    });
            }

            $scope.addAllocation = function (teamId, personId) {
                $scope.selectedPupil.preferences = null;
                signUpRepository
                    .postPupilSummarySelection(
                        $state.params.signUpId,
                        teamId,
                        personId,
                        true
                    )
                    .then(function (baseData) {
                        var pupil = baseData.pupil.data;
                        for (var i = 0; i < $scope.pupils.length; i++) {
                            if ($scope.pupils[i].personId === personId) {
                                $scope.pupils[i] = pupil;
                            }
                        }
                        $scope.selectedPupil = baseData.preferences.data;
                        $scope.addPhotosToStudents();
                    });
            };

            $scope.removeAllocation = function (teamId, personId) {
                $scope.selectedPupil.preferences = null;
                signUpRepository
                    .postPupilSummarySelection(
                        $state.params.signUpId,
                        teamId,
                        personId,
                        false
                    )
                    .then(function (baseData) {
                        var pupil = baseData.pupil.data;
                        for (var i = 0; i < $scope.pupils.length; i++) {
                            if ($scope.pupils[i].personId === personId) {
                                $scope.pupils[i] = pupil;
                            }
                        }
                        $scope.selectedPupil = baseData.preferences.data;
                        $scope.addPhotosToStudents();
                    });
            };

            $scope.addPhotosToStudents = function () {
                blobStoragePhotosService
                    .addStudentPhotoUrlsToArray($scope.pupils)
                    .then(function (array) {
                        $scope.pupils = array;
                    });
            };
        },
    ],
});
