import { NgModule } from '@angular/core';
import { SharedModule } from '@sb-shared/shared.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ForbiddenPopupComponent } from './components/forbidden-popup/forbidden-popup.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProfilePopupComponent } from './components/profile-popup/profile-popup.component';
import { ShellComponent } from './components/shell/shell.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { AboutComponent } from './components/about/about.component';

const components = [
  NotFoundComponent,
  ProfilePopupComponent,
  ForbiddenPopupComponent,
  ShellComponent,
  ToastContainerComponent,
  SidebarComponent,
  SwitcherComponent,
  AboutComponent
]

@NgModule({
  declarations: components,
  imports: [
    SharedModule,
    OAuthModule.forRoot()
  ],
  exports: components
})
export class CoreModule { }
