import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataTableExportPdf } from '../types/data-table-export';
import { ObjectService } from './object.service';
import { HttpWebApiService } from './http-web-api.service';
import { HttpRequestSettings, apis, controllerTypes } from '@sb-shared/models/request-settings';

const dataTableTemplate = 'data-table.handlebars';
const pdfMakeTemplate = 'pdfmake';
@Injectable({
  providedIn: 'root'
})
export class PdfService {

  configSettings: HttpRequestSettings = {
    controllerType: controllerTypes.User,
    api: apis.Core,
    fullResponse: true,
    errorMessage: 'SB_Error_Loading_Data',
    successMessage: 'SB_PDF_Downloaded',
    config: {
      responseType: 'blob'
    }
  };

  constructor(
    private obj: ObjectService,
    private http: HttpWebApiService) { }

  generateTablePdf(data: DataTableExportPdf): Observable<any> {
    const upperCaseObject = this.obj.capitaliseObjectKeys(data);

    return this.http.post('PDF/Download', {
      document: {
        templateFileName: dataTableTemplate,
        modelJson: JSON.stringify(data), pdfFileName: `${data.title}.pdf`
      }
    }, this.configSettings);
  }

  generateTeamSheetPdf(fixtureId: Number): Observable<any> {
   return this.http.get('PDF/DownloadTeamSheet/' + fixtureId, this.configSettings);
  }

  generateWithPdfMake(docDefinition, fileName): Observable<any> {
    return this.http.post('PDF/Download', {
      document: {
        templateFileName: pdfMakeTemplate,
        modelJson: JSON.stringify(docDefinition),
        pdfFileName: `${fileName}.pdf`
      }
    }, this.configSettings);
  }
}
