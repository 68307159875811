import { Renderer2, Inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
 
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
    ) {
      this.renderer = rendererFactory.createRenderer(null, null);
     }
 
  public loadJsScript(srcAttr: string, crossOriginAttr: string = null, integrityAttr: string = null): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = srcAttr;

    if (integrityAttr)
    {
      script.integrity = integrityAttr;
    }
    
    if (crossOriginAttr)
    {
      script.crossOrigin = crossOriginAttr;
    }
    
    this.renderer.appendChild(this.document.body, script);
    return script;
  }
}