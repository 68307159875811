'use strict';

angular.module('sysadmin.services.modulesService', [])
    .factory('modulesService', [
        '$http', 'httpCoreApi',
        function ($http, httpCoreApi)
        {
            var urlBase = '/webapi/WebMainSettings/';
            var urlBaseComms = 'OrganisationCommunicationModule';
            var coreApiSettings = {
                controllerType: httpCoreApi.controllerTypes.SysAdmin
            };
            var service = {};

            service.getModules = function (organisationId)
            {
                return $http.get(urlBase + 'GetModules', { params: { organisationId: organisationId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.saveModules = function (model)
            {
                return $http.post(urlBase + 'SaveModules', { organisationId: model.organisationId, feesModule: model.feesModule, transportModule: model.transportModule, moduleTypes: model.moduleTypes })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.saveCcaModuleTypes = function (model)
            {
                return $http.post(urlBase + 'SaveCcaModuleTypes', model.moduleTypes )
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.addCcaSignupModule = function (ccaSignupModule)
            {
                return $http.post(urlBase + 'AddCcaSignupModule', ccaSignupModule)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getCommunicationsModule = orgId => {
                var settings = coreApiSettings;
                settings.orgId = orgId;
                return httpCoreApi.get(urlBaseComms, settings);
            };

            service.saveCommunicationsModule = (orgId, data) => {
                var settings = coreApiSettings;
                settings.orgId = orgId;
                return httpCoreApi.post(urlBaseComms, data, settings);
            };

            return service;
        }
    ]);