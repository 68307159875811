angular.module('events.services.eventsService', [
    'shared.services.fileService',
    'shared.file.constants'
])
    .factory('eventsService',
        [
            '$http',
            '$q',
            '$cacheFactory',
            'httpCoreApi',
            'fileService',
            'fileTypeEnum',
            function ($http, $q, $cacheFactory, httpCoreApi, fileService, fileTypeEnum)
            {

                var urlBase = '/webapi/WebEvents/';

                var httpCoreApiSettings = {
                    controllerType: httpCoreApi.controllerTypes.Admin
                };

                var service = {};
                var repoCache = $cacheFactory('eventsServiceCache');

                service.clear = function ()
                {
                    repoCache.removeAll();
                };

                service.blockOutEvents = function (eventIds, blockedState)
                {
                    if (typeof (eventIds) === 'number')
                    {
                        eventIds = [eventIds];
                    }

                    var request = {
                        eventIds: eventIds,
                        blockedState: blockedState
                    };

                    return $http.post(urlBase + 'BlockOutEvents', request)
                        .then(function (response)
                        {
                            repoCache.removeAll();
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            repoCache.removeAll();
                            return { isError: true, errorData: e };
                        });
                };

                service.getEventBasics = function (eventId)
                {
                    return $http.get(urlBase + 'GetEventBasics', { params: { eventId: eventId }, cache: repoCache  })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.getEventDetails = function (eventId)
                {
                    return $http.get(urlBase + 'GetDetails', { params: { eventId: eventId } })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.getEventInfo = function (eventId)
                {
                    return $http.get(urlBase + 'GetEventInfo', { params: { eventId: eventId } })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.getConsentEventInvitesData = function (eventId)
                {
                    return $http.get(urlBase + 'GetConsentEventInvitesData', { params: { eventId: eventId } })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.getStudentsForNotifyOnlyEvent = function (eventId)
                {
                    return $http.get(urlBase + 'GetStudentsForNotifyOnlyEvent', { params: { eventId: eventId } })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.getNotifyAudienceTypes = function (excludeNone)
                {
                    return $http.get(urlBase + 'GetNotifyAudienceTypes', { params: { excludeNone } })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.getConsentRequiredFromPersonTypes = function (excludeNone)
                {
                    return $http.get(urlBase + 'GetConsentRequiredFromPersonTypes', { params: { excludeNone } })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.saveConsentEventSettings = function (eventSettingsSaveRequest)
                {
                    return httpCoreApi.post('Events/ConsentEventSettings', eventSettingsSaveRequest, httpCoreApiSettings);
                };

                service.getCalendarEventNotifyOnlyCsvUrl = function (eventId)
                {
                    return urlBase + 'GetCalendarEventNotifyOnlyCsv?eventId=' + eventId;
                };

                service.updateAndNotifyInvitees = function (eventId, invitees, unavailable, resendInvites, notifyAudienceTypeId)
                {
                    var request = {
                        entityId: eventId,
                        invitees: invitees,
                        resendInvites: resendInvites,
                        unavailable: unavailable,
                        notifyAudienceTypeId: notifyAudienceTypeId
                    };

                    return $http.post(urlBase + 'UpdateAndNotifyInvitees', request)
                        .then(function (response)
                        {
                            return response;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.updateAndNotifyReInvitees = function (eventId, reInviteIds, notifyAudienceTypeId)
                {
                    var request = {
                        entityId: eventId,
                        invitees: [],
                        resendInvites: reInviteIds,
                        unavailable: [],
                        notifyAudienceTypeId: notifyAudienceTypeId
                    };

                    return $http.post(urlBase + 'UpdateAndNotifyInvitees', request)
                        .then(function (response)
                        {
                            return response;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.addNewStudentsToConsentEvent = function (eventId, newStudentIds)
                {
                    var request =
                    {
                        eventId: eventId,
                        newStudentIds: newStudentIds,
                    };

                    return $http.post(urlBase + 'AddNewStudentsToConsentEvent', request)
                        .then(function (response)
                        {
                            return response;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.updateEvent = function (dto, suppressNotifications)
                {
                    if (!suppressNotifications)
                    {
                        suppressNotifications = false;
                    }

                    return $http.post(urlBase + 'Update?suppressNotifications=' + suppressNotifications, dto)
                        .then(function (response)
                        {
                            repoCache.removeAll();
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            repoCache.removeAll();
                            return { isError: true, errorData: e };
                        });
                };

                service.updateFutureEvents = function (eventIds, fields, eventDetails)
                {
                    var deferred = $q.defer();

                    var dto = {
                        ids: eventIds,
                        fields: fields,
                        data: eventDetails
                    };

                    return $http.post(urlBase + 'UpdateLinked', dto)
                        .then(function (response)
                        {
                            repoCache.removeAll();
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            repoCache.removeAll();
                            return { isError: true, errorData: e };
                        });
                };

                service.getOtherDeletableEventsInSeries = function (eventId)
                {

                    return $http.post(urlBase + 'OtherDeletableEventsInSeries', eventId)
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                service.deleteEvents = function (eventId)
                {

                    return $http.post(urlBase + 'EventsDelete', eventId)
                        .then(function (response)
                        {
                            repoCache.removeAll();
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            repoCache.removeAll();
                            return { isError: true, errorData: e };
                        });
                };

                service.restoreEvent = function (eventId)
                {

                    return $http.post(urlBase + 'Restore', eventId)
                        .then(function (response)
                        {
                            repoCache.removeAll();
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            repoCache.removeAll();
                            return { isError: true, errorData: e };
                        });
                };

                service.cancelEvent = function (eventId, messageTitle, messageBody, recipients)
                {
                    var request = {
                        eventId: eventId,
                        title: messageTitle,
                        message: messageBody,
                        recipients: recipients
                    };

                    return $http.post(urlBase + 'Cancel', request)
                        .then(function (response)
                        {
                            repoCache.removeAll();
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            repoCache.removeAll();
                            return { isError: true, errorData: e };
                        });
                };

                service.getGuardiansForEvent = function (eventId)
                {
                    return $http.get(urlBase + 'GetGuardiansForEvent?eventId=' + eventId, { cache: repoCache })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });

                };

                service.getGuardiansForEventCsv = function (eventId)
                {
                    $http.get(`${urlBase}GetGuardiansForEventCsv`, { params: { eventId: eventId } })
                        .then(response => {
                            fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `GuardiansForEvent${eventId}`);
                        });
                };

                service.getMedicalDataForEventCsv = function (eventId)
                {
                    $http.get(`${urlBase}GetMedicalDataForEventCsv`, { params: { eventId: eventId } })
                        .then(response => {
                            fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `MedicalDataForEvent${eventId}`);
                        });
                };

                service.getMedicalDataForEvent = function (eventId)
                {
                    return $http.get(urlBase + 'GetMedicalDataForEvent?eventId=' + eventId, { cache: repoCache })
                        .then(function (response)
                        {
                            return response.data;
                        })
                        .catch(function (e)
                        {
                            return { isError: true, errorData: e };
                        });

                };

                service.postPersonChangesToEvent = function (eventId, transportEventId, personStops, roleId)
                {
                    return $http.post(urlBase + 'PersonChangesToEvent',
                        {
                            eventId: eventId,
                            transportEventId: transportEventId,
                            personStops: personStops,
                            roleId: roleId
                        })
                        .then(function (response)
                        {
                            return response.data;
                        });
                };

                service.removePupilFromEvent = function (pupilId, eventId, includeLinkedEvents, refundCreditAction)
                {
                    var removeRequest = {
                        pupilId: pupilId,
                        eventId: eventId,
                        includeLinkedEvents: includeLinkedEvents,
                        refundCreditAction: refundCreditAction
                    };

                    return $http
                        .post(urlBase + 'RemovePupilFromEvent', removeRequest)
                        .then(function (response)
                        {
                            return response.data;
                        });
                };

                service.signUpUserRemovePupilFromEvent = function (pupilId, eventId)
                {

                    var removeRequest = {
                        pupilId: pupilId,
                        eventId: eventId
                    };

                    return $http
                        .post(urlBase + 'SignUpUserRemovePupilFromEvent', removeRequest)
                        .then(function (response)
                        {
                            return response.data;
                        });
                };

                return service;
            }
        ]);
