
<ul class="nav nav-pills nav-stacked p-4">
    <li *ngFor="let opt of menuItems"
        [routerLink]="opt.route"
        routerLinkActive="active">
        <a class="pointer nav-item">
            <span [innerHtml]="opt.label | translate"></span>
        </a>
    </li>
</ul>
