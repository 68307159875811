'use strict';

angular.module('profile.components.profileUserForm', [])
    .component('profileUserForm', {
        templateUrl: '/Scripts/app/profile/components/profile-user-form.template.html',
        bindings: {
            selectedPerson: '<',
            isOrganisationAdmin: '<'
        },
        controller: [
            function () {}
        ]
    });

