import { Injectable } from '@angular/core';
import { formElements } from '@sb-shared/constants/shared.constant';
import { AttendanceScanningType } from '@sb-shared/enums/attendance-scanning-type.enum';
import { RoleId } from '@sb-shared/enums/role.enum';
import { DateFormats } from '@sb-shared/globals/date-formats';
import { PersonGenders } from '@sb-shared/globals/person-genders';
import { Organisation, OrganisationSchoolFieldVisibility } from '@sb-shared/models/organisation';
import { WizardTab } from '@sb-shared/models/UI/wizard-tabs';
import { User } from '@sb-shared/models/user';
import { UserUi } from '@sb-shared/models/user-ui';
import { IconService } from '@sb-shared/services/icon.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { UserService } from '@sb-shared/services/user.service';
import { Observable } from 'rxjs';

const enum UserTypes {
  Staff = 'isStaff',
  Pupil = 'isStudent',
  External = 'isExternal'
}

@Injectable({
  providedIn: 'root'
})
export class ProfileWizardDataService {
  editUserTabs$: Observable<WizardTab[]>;

  constructor(
    private organisationService: OrganisationService,
    private userService: UserService,
    private iconService: IconService
  ) { }

  getWizardTabs(organisation: Organisation, currentUser: UserUi, user: User = null): WizardTab[] {
    const currentOrganisationIsSchool = this.organisationService.isSchool(organisation);
    const schoolYearGroups = [
      {
        id: 0,
        name: 'SB_All'
      },
      ...organisation.schoolYearGroups];
    const showTitle = (user: User) => {
      return !user?.isPupil || !currentOrganisationIsSchool;
    }
    const hideGenderSettings = this.getHideGenderSettings(user, organisation);
    const hideYearSettings = this.getHideSchoolFieldSettings(user, OrganisationSchoolFieldVisibility.ShowYears, organisation);
    const hideHouseSettings = this.getHideSchoolFieldSettings(user, OrganisationSchoolFieldVisibility.ShowHouses, organisation);
    const hideClassSettings = this.getHideSchoolFieldSettings(user, OrganisationSchoolFieldVisibility.ShowClasses, organisation);
    const enableOnlineMeetingId = (user: User) => {
      return user?.id == currentUser.id || this.userService.checkUserRole(currentUser, RoleId.OrganisationSuperAdmin);
    }

    const allTabs: WizardTab[] = [
      {
        id: 1,
        name: 'SB_Type',
        iconName: 'userAdd',
        image: 'personal_info',
        isHidden: user?.personData?.id > 0,
        formGroupName: 'type',
        fields: [
          {
            id: 'userType',
            type: formElements.RecordType,
            cols: 10,
            isFlat: true,
            isRequired: true,
            options: [
              {
                id: 'isStaff',
                name: 'SB_Staff',
                description: 'SB_Staff_Description',
                iconName: 'userTypeStaff'
              },
              {
                id: 'isExternal',
                name: 'SB_External_User',
                description: 'SB_External_User_Description',
                iconName: 'profile'
              },
              {
                id: 'isStudent',
                name: 'SB_Student',
                description: 'SB_Student_Description',
                iconName: 'userTypeStudent',
                isHidden: organisation.isMISManaged
              },
            ]
          },
        ]
      },
      {
        id: 2,
        name: 'SB_Details',
        iconName: 'users',
        image: 'profile',
        formGroupName: 'details',
        fields: [
          {
            label: 'SB_Title',
            type: formElements.Text,
            id: 'personData-title',
            isRequired: false,
            isHidden: !showTitle(user),
            maxlength: 10,
            cols: 2,
          },
          {
            label: 'SB_First_Name',
            type: formElements.Text,
            id: 'personData-firstName',
            isRequired: true,
            maxlength: 50,
            cols: showTitle(user) ? 4 : 5,
          },
          {
            label: 'SB_Last_Name',
            type: formElements.Text,
            id: 'personData-lastName',
            isRequired: true,
            maxlength: 50,
            cols: showTitle(user) ? 4 : 5,
          },
          {
            label: 'SB_Other_Name',
            type: formElements.Text,
            id: 'personData-nickName',
            maxlength: 250,
            cols: 5,
          },
          {
            label: 'SB_Gender',
            type: formElements.Select,
            options: PersonGenders,
            id: 'personData-gender',
            isRequired: true,
            ...hideGenderSettings,
            cols: 5,
          },
          {
            label: 'SB_Date_Of_Birth',
            type: formElements.Date,
            id: 'personData-dateOfBirth',
            isRequired: true,
            isHidden: user?.personData?.id && !user?.isPupil,
            hideIf: [{ property: 'userType', values: [UserTypes.External, UserTypes.Staff] }],
            cols: 5,
          },
          {
            label: 'SB_Online_Meeting_ID',
            tooltip: 'SB_Online_Meeting_ID_Info',
            type: formElements.Url,
            placeholder: 'e.g. https://zoom.us/j/123456789',
            id: 'staffInformation-onlineMeetingId',
            maxlength: 250,
            cols: 5,
            isHidden: user?.personData?.id && !user?.isStaff && !user?.isExternal,
            isDisabled: !enableOnlineMeetingId(user),
            hideIf: [{ property: 'userType', values: [UserTypes.Pupil] }],
            ignoreReadOnly: true
          },
          {
            label: 'SB_Roles',
            type: formElements.CheckboxList,
            id: 'roles',
            cols: 5,
            isFlat: true,
            requiredIf: [{ property: 'userType', values: [UserTypes.External] }],
            options: [
              {
                id: 'isExternalCoachAccess',
                name: 'SB_External_Coach',
              },
              {
                id: 'isTransportBusMonitor',
                name: 'SB_Transport_Bus_Monitor',
                isHidden: !organisation.isTransportModuleActive,
              }
            ],
            isHidden: user?.personData?.id && !user?.isExternal,
            hideIf: [{ property: 'userType', values: [UserTypes.Pupil, UserTypes.Staff] }],
          },
          {
            label: organisation.attendanceScanningType == AttendanceScanningType.RFID ? 'SB_Attendance_Scanning_RFID' : 'SB_Attendance_Scanning_QR_Code',
            type: formElements.Text,
            id: 'pupilInformation-attendanceScanningCode',
            cols: 5,
            isHidden: (user && !user?.isPupil) || !organisation.isAttendanceScanningActive,
            hideIf: [{ property: 'userType', values: [UserTypes.External, UserTypes.Staff] }],
          },
          {
            label: currentOrganisationIsSchool ? 'SB_Year' : 'SB_Region',
            type: formElements.Select,
            id: 'pupilInformation-schoolYearGroupId',
            isRequired: true,
            cols: 5,
            ...hideYearSettings,
            options: schoolYearGroups
          },
          {
            label: 'SB_House',
            type: formElements.Select,
            id: 'pupilInformation-schoolHouseId',
            cols: 5,
            ...hideHouseSettings,
            options: organisation.schoolHouses
          },
          {
            label: 'SB_Class',
            type: formElements.Select,
            id: 'pupilInformation-schoolClassId',
            cols: 5,
            ...hideClassSettings,
            options: organisation.schoolClasses
          },
          {
            label: 'SB_Active_Platforms',
            type: formElements.IconList,
            id: 'platforms',
            defaultValue: 'SB_INACTIVE',
            iconList: this.iconService.getActivePlatformIcons(user?.platforms),
            cols: 5,
            isHidden: !user?.personData?.id
          },
          {
            label: 'SB_ReceptionUser_Label_MultiAccount',
            type: formElements.IconList,
            id: 'personData-isMultiAccountUser',
            cols: 5,
            iconList: [
              {
                name: 'school',
                tooltip: 'SB_ReceptionUser_Label_MultiAccount'
              },
              {
                name: 'school',
                tooltip: 'SB_ReceptionUser_Label_MultiAccount'
              }
            ],
            isHidden: !user?.isMultiAccountUser
          },
          {
            label: 'SB_Last_Access',
            type: formElements.Readonly,
            id: 'lastAccessed',
            dateFormat: DateFormats.DateTime,
            icon: this.iconService.getLastActivePlatformIcon(user?.lastPlatform),
            cols: 5,
            isHidden: !user?.platforms
          },
          {
            label: 'SB_MIS_Id',
            type: formElements.Text,
            id: 'misId',
            maxlength: 50,
            cols: 5,
            isDisabled: user?.personData?.id > 0 && user?.seenInMIS
          },
        ]
      },
      {
        id: 3,
        name: 'SB_Contact',
        iconName: 'contacts',
        image: 'contact_us',
        formGroupName: 'contacts',
        fields: [
          {
            label: 'SB_System_generate_email',
            type: formElements.Switch,
            id: 'systemGenerateEmail',
            isHidden: user?.personData?.id !== undefined,
            cols: 4,
            defaultValue: false
          },
          {
            label: 'SB_Email_Address',
            type: formElements.Email,
            id: 'emailAddress',
            requiredIf: [{ property: 'systemGenerateEmail', values: [false] }],
            enableIf: [{ property: 'systemGenerateEmail', values: [false] }],
            maxlength: 256,
            cols: 5
          },
          {
            label: 'SB_Mobile',
            type: formElements.Text,
            id: 'personData-mobile',
            maxlength: 250,
            cols: 5
          },
          {
            label: 'SB_Telephone',
            type: formElements.Text,
            id: 'personData-telephone',
            maxlength: 250,
            cols: 5
          },
          {
            label: 'SB_Address',
            type: formElements.Text,
            id: 'personData-address',
            maxlength: 250,
            isHidden: currentOrganisationIsSchool && !user?.personData.address,
            cols: 5
          },
          {
            label: 'SB_City',
            type: formElements.Text,
            id: 'personData-town',
            maxlength: 250,
            isHidden: currentOrganisationIsSchool && !user?.personData.town,
            cols: 5
          },
          {
            label: 'SB_County',
            type: formElements.Text,
            id: 'personData-county',
            maxlength: 250,
            isHidden: currentOrganisationIsSchool && !user?.personData.county,
            cols: 5
          },
          {
            label: 'SB_PostCode',
            type: formElements.Text,
            id: 'personData-postCode',
            maxlength: 50,
            isHidden: currentOrganisationIsSchool && !user?.personData.postCode,
            cols: 5
          }
        ]
      }
    ];

    if (user?.personData?.id) {
      // Merge tabs for existing user
      return [{
        ...allTabs[1],
        fields: [...allTabs[1].fields, ...allTabs[2].fields]
      }]
    }
    else {
      // Return tabs for new user
      return allTabs;
    }
  }

  private getHideSchoolFieldSettings(user: User, fieldVisibility: OrganisationSchoolFieldVisibility, organisation: Organisation) {
    const currentOrganisationIsSchool = this.organisationService.isSchool(organisation);

    if (currentOrganisationIsSchool) {
      return {
        hideIf: [{ property: 'userType', values: [UserTypes.External, UserTypes.Staff] }],
        isHidden: user?.personData?.id && !user?.isPupil,
      }
    }

    return this.getFieldsSettingsForOtherOrgs(user, fieldVisibility, organisation);
  }

  private getFieldsSettingsForOtherOrgs(user: User, fieldVisibility: OrganisationSchoolFieldVisibility, organisation: Organisation) {
    const hideForRoles = [];
    const visibility = (organisation.schoolFieldsAvailableFlags & fieldVisibility) === fieldVisibility;
    const isYearsField = fieldVisibility === OrganisationSchoolFieldVisibility.ShowYears;
    const showField = visibility || (isYearsField && user?.isPupil); //!always show years for pupils

    if (!showField) {
      [UserTypes.External, UserTypes.Staff, UserTypes.Pupil].forEach(role => {
        if (isYearsField && role === UserTypes.Pupil) return;

        hideForRoles.push(role);
      });
    }

    return {
      hideIf: [{ property: 'userType', values: hideForRoles }],
      isHidden: user?.personData?.id && !showField,
    }
  }

  private getHideGenderSettings(user, organisation) {
    const hideForRoles = [UserTypes.External];
    const showGender = !user?.isExternal && (user?.isPupil || (user?.isStaff && !organisation.hideStaffGenders));

    // !change show/hide Staff Genders based on organisation settings
    if (organisation.hideStaffGenders && !showGender) {
      hideForRoles.push(UserTypes.Staff);
    }

    return {
      hideIf: [{ property: 'userType', values: hideForRoles }],
      isHidden: user?.personData?.id && !showGender,
    }
  }
}
