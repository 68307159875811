'use strict';

angular.module('reception.components.receptionLocationEvents', [
    'reception.services.receptionService'
])
.component('receptionLocationEvents', {
    templateUrl: '/Scripts/app/reception/components/reception-location-events.template.html',
    bindings: {
        selectedLocation: '<'
    },
    controller: [
        '$scope', '$state', 'receptionService',
        function ($scope, $state, receptionService) {

            this.$onChanges = (changes) => {
                if (changes.selectedLocation && changes.selectedLocation.currentValue) {
                    $scope.selectedLocation = this.selectedLocation;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.sortType = 'name';
            $scope.sortReverse = false;
            $scope.$watch('selectedLocation', function () {
                $scope.loadingEvents = true;
                if ($scope.selectedLocation) {
                    receptionService.getLocationEvents($scope.selectedLocation.id).then(function (data) {
                        $scope.locationEvents = data;
                        $scope.loadingEvents = false;
                    },
                    function (error) {
                        return error;
                    });
                } else {
                    // Redirect to details tab if no selected location when loaded
                    var detailsState = $state.current.name.replace('.locationevents', '.locationdetails');
                    $state.go(detailsState);
                }
            });
        }
        }
    ]});