angular.module('documentStore.components.documentEditor',
    [
        'documentStore.services.documentStoreService',
        'pascalprecht.translate',
        'ui.tinymce',
        'angularFileUpload',
        'shared.services.tinymceConfigHelper',
        'shared.services.simpleAlertService',

    ])
    .component('documentEditor',
        {
            bindings: {
                teamId: '<',
                documentId: '<',
                fileSizeLimit: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/documentStore/components/document-editor.template.html',
            controller: [
                '$window',
                '$uibModal',
                '$translate',
                'documentStoreService',
                'FileUploader',
                'tinymceConfigHelper',
                'simpleAlertService',
                function documentEditorController(
                    $window,
                    $uibModal,
                    $translate,
                    documentStoreService,
                    FileUploader,
                    tinymceConfigHelper,
                    simpleAlertService) {

                    this.loading = 0;
                    this.fileUploaderReady = false;

                    this.document = null;

                    this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                    var self = this;

                    this.isLoading = function () { return self.loading > 0; };
                    this.isEditable = function () { return self.document !== null && self.document.id > 0; }

                    this.$onChanges = function (changes) {
                        if (typeof (changes.documentId) !== 'undefined' && changes.documentId.currentValue !== undefined && changes.documentId.currentValue != null) {
                            if (changes.documentId.currentValue === 0) {
                                this.document = {
                                    id: 0,
                                    description: ''
                                };

                                if (self.teamId && !self.fileUploaderReady) {
                                    self.setupFileUploader();
                                }
                            } else {
                                self.loading++;
                                documentStoreService.getDocumentById(changes.documentId.currentValue, self.teamId)
                                    .then(function (data) {
                                        self.document = data;
                                        self.loading--;
                                    });
                            }
                        }

                        if (changes.teamId && changes.teamId.currentValue) {
                            self.setupFileUploader();
                        }

                        if (changes.fileSizeLimit && changes.fileSizeLimit.currentValue) {
                            self.setupFileUploader();
                        }
                    };

                    this.setupFileUploader = function () {
                        console.log(self.teamId, self.fileSizeLimit);
                        if (!(self.teamId && self.fileSizeLimit)) {
                            return;
                        }

                        self.maxSizeText = Math.floor(self.fileSizeLimit / 1000000) + 'MB';

                        self.fileUploader = new FileUploader({
                            url: '/webapi/WebDocumentStore/Post?tId=' + self.teamId,
                            autoUpload: true,
                            removeAfterUpload: true
                        });
                        self.fileUploader.filters.push({
                            name: 'fileTypeFilter',
                            fn: function (item /*{File|FileLikeObject}*/, options) {
                                switch (item.type) {
                                    case 'image/jpeg':
                                    case 'image/gif':
                                    case 'image/png':
                                    case 'image/tiff':
                                    case 'application/pdf':
                                    case 'application/msword':
                                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                    case 'application/vnd.ms-excel':
                                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                    case 'application/vnd.ms-powerpoint':
                                    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                                        return true;
                                    default:
                                        return false;
                                }
                            }
                        });
                        self.fileUploader.filters.push({
                            name: 'fileSizeFilter',
                            fn: function (item /*{File|FileLikeObject}*/, options) {
                                return item.size <= self.fileSizeLimit; //  5242880;// 5mb //10485760; // 10mb
                            },
                            options: self.maxSizeText
                        });
                        self.fileUploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {

                            switch (filter.name) {
                                case 'fileTypeFilter':
                                    var error =
                                        'Files of this type are not supported.  Please supply only image files or PDF documents.';

                                    simpleAlertService.simpleAlert({ title: 'SB_Unsupported_file_type', message: error });
                                    break;
                                case 'fileSizeFilter':
                                    var error =
                                        'File is larger than the maximum size of ' + filter.options;

                                    simpleAlertService.simpleAlert({ title: 'SB_File_is_too_large', message: error });
                                    break;
                                default:
                                    simpleAlertService.simpleAlert({ title: 'SB_File_unsuitable', message: error });
                                    break;
                            }

                        };
                        self.fileUploader.onSuccessItem = function (fileItem, response, status, headers) {
                            console.info('onSuccessItem', fileItem, response, status, headers);

                            self.document = {
                                id: response[0].id,
                                fileName: response[0].fileName,
                                uploadedDate: response[0].uploadedDate,
                                uploadedByName: response[0].uploadedByName,
                                mimeType: response[0].mimeType,
                                fileSize: response[0].fileSize,
                                description: ''
                            };

                            if (typeof (self.onChange()) === 'function') {
                                self.onChange()(self.document);
                            }
                        };
                        self.fileUploader.onErrorItem = function (fileItem, response, status, headers) {
                            console.info('onErrorItem', fileItem, response, status, headers);
                            simpleAlertService.simpleAlert({ title: 'SB_Unable_to_upload_file', message: 'SB_Unable_to_upload_file' });
                        };
                        self.fileUploaderReady = true;
                    };

                    this.save = function () {
                        self.loading++;
                        documentStoreService.updateDocumentMetaData(self.document, self.teamId)
                            .then(function (data) {
                                if (data && !data.isError) {
                                    if (typeof (self.onChange()) === 'function') {
                                        self.onChange()(self.document);
                                    }
                                }
                                self.loading--;
                            });
                    };

                    this.delete = function () {
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Delete_document',
                            message: 'SB_Confirmation_Delete_document',
                            okButtonText: 'SB_Yes',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {

                                self.loading++;
                                documentStoreService.deleteDocument(self.document.id, self.teamId)
                                    .then(function (data) {
                                        if (data && !data.isError) {
                                            if (typeof (self.onChange()) === 'function') {
                                                self.document.isDeleted = true;
                                                self.onChange()(self.document);
                                            }
                                        }
                                        self.loading--;
                                    });
                            });
                    };

                    this.view = function () {
                        var link = documentStoreService.getDocumentDownloadLink(self.document.id, self.teamId);
                        $window.location = link;
                    };

                    this.getMimeTypeDisplay = function (mimeType) {
                        return documentStoreService.getMimeTypeDisplayValue(mimeType);
                    };
                }]
        });