angular.module('tuitionFeesModule')
    .component('invoiceDetailView', {
        bindings: {
            invoice: '<',
            paymentMethods: '<',
            isCorporatePayer: '<',
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view.template.html',
        controller: class InvoiceDetailViewCtrl {
            // Bindings
            invoice: any;
            paymentMethods: any[];
            isCorporatePayer: boolean;

            // Dependencies
            $window: any;
            invoiceService: any;

            // Variables
            invoiceDetails: any;
            showInvoiceDetails: boolean;
            showAddNote: boolean;
            showAddPayment: boolean;
            showAddCreditNote: boolean;
            activeTabIndex: number;
            tabs: any;
            currencyDisplaySymbol: string;
            currencyExponent: number;
            paymentStatuses: any[] = [];

            static $inject = ['$window', 'invoiceService'];

            constructor($window, invoiceService) {
                this.$window = $window;
                this.invoiceService = invoiceService;
                this.showInvoiceDetails = true;
                this.showAddNote = false;
                this.showAddPayment = false;
                this.showAddCreditNote = false;
                this.activeTabIndex = 0;
                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
                this.paymentStatuses = this.invoiceService.getInvoiceStatuses();
            }

            $onChanges(changes) {
                if (changes.invoice?.currentValue) {
                    this.getInvoiceDetails(changes.invoice.currentValue.id);
                }
            }

            getInvoiceDetails = function (invoiceId) {
                return this.invoiceService.getInvoice(invoiceId).then(res => {
                    if (res.data) {
                        this.invoiceDetails = res.data;
                        // if add payment dialog is open, and no amount due, we close it.
                        if (this.invoiceDetails.due == 0 && this.showAddPayment) {
                            this.backToInvoiceDetails();
                        }
                    }
                });
            }

            backToInvoiceDetails() {
                this.showInvoiceDetails = true;
                this.showAddNote = false;
                this.showAddPayment = false;
                this.showAddCreditNote = false;
            }

            fncAddNoteCallback = function (invoiceId) {
                this.activeTabIndex = 3;
                this.getInvoiceDetails(invoiceId);
                this.backToInvoiceDetails()
            }.bind(this);

            fncAddPaymentCallback = function (invoiceId) {
                this.activeTabIndex = 1;
                this.getInvoiceDetails(invoiceId).then(() => {
                    // after payment, transfer over new values to the object used in the table, so view refreshes.
                    this.invoice.paid = this.invoiceDetails.paid;
                    this.invoice.credit = this.invoiceDetails.credit;
                    this.invoice.due = this.invoiceDetails.due;
                    this.invoice.paymentStatus = this.invoiceDetails.paymentStatus;
                });

                this.backToInvoiceDetails()
            }.bind(this);

            fncAddCreditNoteCallback = function (invoiceId) {
                this.activeTabIndex = 2;
                this.getInvoiceDetails(invoiceId).then(() => {
                    // after credit note, transfer over new values to the object used in the table, so view refreshes.
                    this.invoice.paid = this.invoiceDetails.paid;
                    this.invoice.credit = this.invoiceDetails.credit;
                    this.invoice.due = this.invoiceDetails.due;
                    this.invoice.paymentStatus = this.invoiceDetails.paymentStatus;
                });

                this.backToInvoiceDetails()
            }.bind(this);

            gotoAddNote() {
                this.showInvoiceDetails = false;
                this.showAddPayment = false;
                this.showAddCreditNote = false;
                this.showAddNote = true;
            }

            gotoAddPayment() {
                this.showInvoiceDetails = false;
                this.showAddNote = false;
                this.showAddCreditNote = false;
                this.showAddPayment = true;
            }

            gotoAddCreditNote() {
                this.showInvoiceDetails = false;
                this.showAddNote = false;
                this.showAddPayment = false;
                this.showAddCreditNote = true;
            }

            downloadInvoice() {
                this.$window.open(this.invoiceDetails.originalPdfBlobPath, '_blank');
            }
        }
    });
