'use strict';

angular.module('messaging.directives.smsEditor', [
    'messaging.constants',
    'messaging.services.messagingService',
    'pascalprecht.translate',
    'shared.services.simpleAlertService',
    'shared.controllers.confirmationPopup',
    'shared.directives.sbCheckBox',
    'shared.components.sbIcon'
])
    .directive('smsEditor', [
        '$window',
        '$filter',
        'messagingService',
        'simpleAlertService',
        'messageMediumType',
        function ($window, $filter, messagingService, simpleAlertService, messageMediumType) {

        function link($scope) {
            $scope.$watch('messageText',
                function(newValue) {
                    $scope.updateStats();
                });
        }

        function controller($scope) {

            // http://help.messagebird.com/messagebird-account/getting-started-the-basics/which-characters-count-as-two-characters-in-a-text-message
            var doubleCharacterSymbols = ['\^', '~', '[', ']', '{', '}', '|', '€', '\\', '\n', '\r'];
            var singleCharacterSymbols = [
                '@', 'Δ', ' ', '0', '¡', 'P', '¿', 'p', '£', '_', '!', '1', 'A', 'Q', 'a', 'q',
                '$', 'Φ', '"', '2', 'B', 'R', 'b', 'r', '¥', 'Γ', '#', '3', 'C', 'S', 'c', 's',
                'è', 'Λ', '¤', '4', 'D', 'T', 'd', 't', 'é', 'Ω', '%', '5', 'E', 'U', 'e', 'u',
                'ù', 'Π', '&', '6', 'F', 'V', 'f', 'v', 'ì', 'Ψ', '\'', '7', 'G', 'W', 'g', 'w',
                'ò', 'Σ', '(', '8', 'H', 'X', 'h', 'x', 'Ç', 'Θ', ')', '9', 'I', 'Y', 'i', 'y',
                'Ξ', '*', ':', 'J', 'Z', 'j', 'z', 'Ø', '+', ';', 'K', 'Ä', 'k', 'ä', 'ø', 'Æ',
                ',', '<', 'L', 'Ö', 'l', 'ö', 'æ', '-', '=', 'M', 'Ñ', 'm', 'ñ', 'Å', 'ß',
                '.', '>', 'N', 'Ü', 'n', 'ü', 'å', 'É', '/', '?', 'O', '§', 'o', 'à'];

            if (typeof $scope.messageText === 'undefined' || $scope.messageText === null) {
                $scope.messageText = '';
            }

            if (typeof $scope.smsLimit === 'undefined' || $scope.smsLimit === null) {
                $scope.smsLimit = 1;
            }

            $scope.messagingService = messagingService;            
            $scope.smsBalance = 0;

            $scope.messagingService
                .getThisOrganisationsSmsBalance()
                .then(function (data) {
                    if (!data.isError) {
                        $scope.smsBalance = data;
                    }
                });

            var singleSMSLength = 160;
            var multiSMSLength = 153;

            $scope.messageLabel = 'message';

            $scope.updateStats = function () {

                $scope.characterCount = 0;

                var filteredString = '';
                var stringHasBeenModified = false;

                var currentMaxLength = $scope.messageText.length > singleSMSLength
                    ? $scope.smsLimit * multiSMSLength
                    : $scope.smsLimit * singleSMSLength;

                for (var i = 0; i < $scope.messageText.length; i++) {

                    var character = $scope.messageText[i];

                    if (doubleCharacterSymbols.indexOf(character) !== -1) {

                        if ($scope.characterCount + 2 > currentMaxLength) {
                            stringHasBeenModified = true;
                        } else {
                            $scope.characterCount += 2;
                            filteredString += character;
                        }

                    }
                    else if (singleCharacterSymbols.indexOf(character) !== -1) {

                        if ($scope.characterCount + 1 > currentMaxLength) {
                            stringHasBeenModified = true;
                        } else {
                            $scope.characterCount += 1;
                            filteredString += character;
                        }

                    }
                    else {
                        stringHasBeenModified = true;
                    }
                }

                if (stringHasBeenModified) {
                    $scope.messageText = filteredString; // will trigger this function again
                    return;
                }

                $scope.smsCount = $scope.messageText.length > singleSMSLength
                    ? Math.ceil($scope.characterCount / multiSMSLength)
                    : Math.ceil($scope.characterCount / singleSMSLength);

                $scope.messageLabel = $scope.smsCount === 1
                    ? 'message'
                    : 'messages';
            };

            $scope.updateStats();

            var simpleAlert = function(title, message) {
                simpleAlertService.simpleAlert({
                    title: title,
                    message: message,
                    okButtonText: 'SB_OK',
                    windowSize: 'lg'
                });

            };
            $scope.sending = false;

            $scope.prepopulated = $scope.messagingService.hasRecipientSelected($scope);

            $scope.reset = function() {
                $scope.messageText = '';
            };
            $scope.reset();
            $scope.sendMessage = function () {
                if (!$scope.messagingService.validateSendMessage($scope, simpleAlert)) {
                    return;
                };

                this.messageModel = function ()
                {
                    let newMessageModel = {
                        recipients: [],
                        messageMedium: {
                            mediumId: messageMediumType.SMS.id,
                            plainBody: $scope.messageText
                        }
                    }

                    $scope.messagingService.prepareRecipientGroups(newMessageModel, $scope.recipients, $scope.calendarEventId);

                    return newMessageModel ;

                }.bind(this);

                var newMessageModel = this.messageModel();

                $scope.sending = true;

                $scope.messagingService
                    .getThisOrganisationsSmsBalance()
                    .then(function (data) {
                        $scope.smsBalance = data

                        $scope.messagingService.getRecipientsAndCreditCount(newMessageModel)
                            .then(function (getRecipientsAndCreditCountResponse) {

                                var recipientCount = getRecipientsAndCreditCountResponse.recipients;
                                var creditsRequired = getRecipientsAndCreditCountResponse.credits;

                                // do we have enough credits?
                                if (parseInt($scope.smsBalance) >= parseInt(creditsRequired)) {
                                    var modal = simpleAlertService.simpleAlert({
                                        title: 'Confirm sending of message',
                                        message: 'Are you sure you wish to send this message to ' + recipientCount + ' ' + (recipientCount > 1 ? 'recipients' : 'recipient') + '?  This will use ' + creditsRequired + ' credits.',
                                        okButtonText: 'Send',
                                        cancelButtonText: 'SB_Cancel',
                                        windowSize: 'lg'
                                    });

                                    modal.result.then(function (data) {

                                        if (data) {

                                            $scope.messagingService.sendNewMessage(newMessageModel)
                                                .then(function (data) {
                                                    if (data.isError) {

                                                        $scope.sending = false;

                                                        simpleAlert('SB_Problem_sending_your_message', 'Your message was not sent; ' + data.errorData.data);

                                                    } else {

                                                        $scope.sending = false;

                                                        $scope.messagingService
                                                            .getThisOrganisationsSmsBalance()
                                                            .then(function (data) {
                                                                $scope.smsBalance = data;
                                                            });

                                                        simpleAlert('SB_Communication_Sent', 'Communications have been queued for delivery, this could take a few minutes. Processed messages will show in the list below (once refreshed)');
                                                        if (!$scope.prepopulated) {
                                                            $scope.messagingService.resetGroupContainers($scope);
                                                        }

                                                        $scope.reset();
                                                    }
                                                });
                                        }

                                    }, function () {
                                        $scope.sending = false;
                                    });
                                }
                                else {

                                    simpleAlert('SB_Not_enough_credits', 'To send this message ' + creditsRequired + ' credits are required but only ' + $scope.smsBalance + ' are available.  Please top up your credits before sending.');

                                    $scope.sending = false;
                                }
                            });
                    });
            };
        }

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/messaging/directives/sms-editor.template.html?v=' + $window.EveryBuddy.Version,
            scope: {
                smsLimit: '=',
                recipients: '=',
                calendarEventId: '<'
            },
            controller: ['$scope', controller],
            link: link
        };
    }]);
