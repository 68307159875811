

angular.module('events.components.bookingCountdown',
[
    'pascalprecht.translate'
])
.component('bookingCountdown',
    {
        bindings: {
            minutes: '<',
            seconds: '<',
            timeRemaining: '<',
            onTimeout: '&',
            onUpdateTimeRemaining: '&'
        },
        templateUrl: '/Scripts/app/events/components/booking-countdown.template.html',

        controller: class BookingCountdownComponent {
            $interval: any;
            timer: any;
            hasBookingSelectionTimedOut: boolean;

            minutes: number;
            seconds: number;
            timeRemaining: any;
            onTimeout: any;
            onUpdateTimeRemaining: any;

            static $inject = ['$interval'];

            constructor($interval) {
                this.$interval = $interval;
            }

            $onChanges(changes) {
                if (changes.minutes || changes.seconds) {
                    const minutes = changes.minutes?.currentValue || this.minutes;
                    const seconds = changes.seconds?.currentValue || this.seconds;

                    if (minutes == null || seconds == null) {
                        return;
                    }

                    if (minutes === 0 && seconds === 0) {
                        this.timeout();
                    } else {
                        this.startTimer();
                    }
                }
            }

            $onDestroy() {
                this.cancelInterval();
            }

            resetTimer() {
                this.cancelInterval();

                this.hasBookingSelectionTimedOut = false;

                this.timeRemaining = Date.now();
                this.timeRemaining = moment.utc(this.timeRemaining).set('minute', this.minutes);
                this.timeRemaining = moment.utc(this.timeRemaining).set('second', this.seconds);

                this.updateTimeRemaining();
            }

            startTimer() {
                this.resetTimer();

                this.timer = this.$interval(() => {
                    this.timeRemaining = moment.utc(this.timeRemaining).subtract(1, 'seconds').toDate();
                    this.updateTimeRemaining();

                    this.minutes = moment.utc(this.timeRemaining).get('minutes')
                    this.seconds = moment.utc(this.timeRemaining).get('seconds');

                    if (this.minutes === 0 && this.seconds === 0) {
                        this.timeout();
                    }
                }, 1000);
            }

            timeout() {
                this.cancelInterval();
                this.hasBookingSelectionTimedOut = true;
                this.onTimeout()();
            }

            cancelInterval() {
                if (this.timer) {
                    this.$interval.cancel(this.timer);
                    this.timer = null;
                }
            }

            updateTimeRemaining() {
                if (this.onUpdateTimeRemaining()) {
                    this.onUpdateTimeRemaining()(this.timeRemaining);
                }
            }
        }
    }
);
