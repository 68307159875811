<div [ngClass]="{ 'filter-area rhythm-m-b-1 clearfix': !noClass, 'diary-picker': diaryPicker }" role="alert">
  <div class="filter-area-inner">
    <div class="filter-area-inner__top">
      <div class="filter-area-inner__main">
        <div *ngIf="label && !diaryPicker" class="control-label" [innerHTML]="label | translate"></div>
        <div class="filter-area-inner__controls">
          <div class="filter-area-inner__input" *ngIf="searchFilterType?.format">
            <sb-date-range-picker
              *ngIf="searchFilterType?.format === searchFilterFormats.Date"
              [dateRangeList]="dateRangeList"
              [startDate]="startDate"
              [endDate]="endDate"
              (onChange)="onChangeDate($event)"
              [attr.placeholder]="placeholder | translate"
              [singleDatePicker]="customDateOptions.singleDatePicker"
              [customOptions]="customDateOptions"
              [minDate]="minDate"
              [maxDate]="maxDate"></sb-date-range-picker>
            <div class="filter-area__search-box" *ngIf="searchFilterType.format === searchFilterFormats.Text">
              <input
                placeholder="{{ placeholder | translate }}"
                class="form-control"
                (keyup.enter)="onSubmitText()"
                [(ngModel)]="inputValue"
                (ngModelChange)="onChangeText()"
                maxlength="255" />
              <sb-button
                *ngIf="searchFilterType === searchFilterTypes.TextSearch"
                buttonClass="plain"
                iconName="search"
                customClasses="btn-xs-auto"
                (click)="onSubmitText()"></sb-button>
            </div>
          </div>
          <button
            class="btn collapse-settings-btn no-icon btn-auto ml-3"
            type="button"
            (click)="toggleCollapse()"
            *ngIf="isCollapsible()"
            [disabled]="disableFilter">
            <sb-icon name="{{ filterIcon || 'searchSettings' }}"></sb-icon>
            <span class="hidden-xs"> {{ filterLabel || 'SB_Filter' | translate }}</span>
            <span class="badge ml-3" *ngIf="filterCount > 0 && isCollapsed && !disableFilter">{{ filterCount }}</span>
          </button>
          <ng-template #filterContentTemplate>
            <div #filterContent class="filter-area__inline-filter">
              <ng-content></ng-content>
            </div>
          </ng-template>
          <ng-container *ngIf="isSingleArea">
            <ng-container *ngTemplateOutlet="filterContentTemplate"></ng-container>
            <sb-button
              *ngIf="searchFilterType === searchFilterTypes.FiltersOnlySearch"
              buttonClass="default"
              iconName="search"
              customClasses="btn-xs-auto ml-5"
              (click)="onSubmitFilters()"
              class="align-center"></sb-button>
          </ng-container>
        </div>
      </div>
      <div [hidden]="hidePageSize()" class="filter-area-inner__page-size">
        <div [innerHTML]="'SB_Page_Size' | translate"></div>
        <div>
          <select
            id="pageSize"
            name="pageSize"
            class="form-control"
            [(ngModel)]="pageSize"
            (ngModelChange)="changePageSize($event)">
            <option *ngFor="let option of pageSizes" [value]="option" [innerHTML]="option"></option>
          </select>
        </div>
      </div>
    </div>
    <div class="collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="collapse-inner" [ngClass]="{ 'pl-5': searchFilterType === searchFilterTypes.DateSingle }">
        <ng-container *ngIf="!isSingleArea">
          <ng-container *ngTemplateOutlet="filterContentTemplate"></ng-container>
        </ng-container>
        <button
          class="btn-auto btn-link text-muted no-icon close"
          type="button"
          (click)="toggleCollapse()"
          *ngIf="!showFilters">
          <sb-icon name="close"></sb-icon>
        </button>
        <div [hidden]="hideResetButton">
          <sb-button
            buttonClass="light"
            customClasses="reset-button"
            message="SB_Reset"
            (onClick)="onResetFilter()"
            [isDisabled]="filterCount === 0">
          </sb-button>
        </div>
      </div>
    </div>
    <!-- <div class="filter-date-picker mt-5" *ngIf="dateItems" [hidden]="!isCollapsed">
      <div (click)="onClickArrow(false)" class="date-picker-arrow" [ngClass]="{ disabled: isDatesAtStart }">
        <sb-icon name="chevronLeft"></sb-icon>
      </div>
      <div class="filter-date-picker__inner" id="dateItems" ng-init="onInitDateItems()" #dateItemsElement>
        <div
          *ngFor="let date of dateItems"
          [ngClass]="{
            'filter-date-picker__end-of-week': isEndOfWeek(date.value),
            'filter-date-picker__current-date': selectedDateItemId == date.value,
            'filter-date-picker__empty-date': date.eventCount == 0
          }"
          id="dateItem-{{ date.value | date: 'dd-MMM-yyyy' }}">
          <div (click)="selectDateItem(date)">
            <div [innerHTML]="getDateLabels(date.value).shortDayName"></div>
            <div [innerHTML]="date.value | date: 'dd'"></div>
            <div [innerHTML]="getDateLabels(date.value).shortMonthName"></div>
            <div>
              <div *ngFor="let count of eventCountLabels">
                <ng-container *ngIf="date.eventCount >= count"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div (click)="onClickArrow(true)" class="date-picker-arrow" [ngClass]="{ disabled: isDatesAtEnd }">
        <sb-icon name="chevronRight"></sb-icon>
      </div>
    </div> -->
  </div>
</div>
