// start:ng42.barrel
import './add-team-note-popup.component';
import './cca-team-bulk-creator-by-reggroup.component';
import './cca-team-bulk-creator-by-subject.component';
import './cca-team-bulk-creator-filter.component';
import './team-member-list-popup.component';
import './team-note-editor.component';
import './team-note-list.component';
// end:ng42.barrel

