// start:ng42.barrel
import './cca-add-additional-events-dialog.component';
import './cca-cancel-or-delete-events-confirm-dialog.component';
import './cca-cancel-or-delete-events-dialog.component';
import './cca-cancel-or-delete-events-group-list.component';
import './cca-change-day-time-dialog.component';
import './cca-delete-pupil-confirmation-container-signup-user.component';
import './cca-delete-pupil-confirmation-container.component';
import './cca-generate-additional-events-group-list.component';
import './cca-generating-additional-events-dialog.component';
import './cca-group-archive.component';
import './cca-group-instant-book-selection.component';
import './cca-group-list.component';
import './daily-preference-selector.component';
import './delete-pupil-confirmation-signup-user.component';
import './delete-pupil-confirmation.component';

// end:ng42.barrel

