import { ColoursService } from '@sb-shared/services/colours.service';
import { Colours } from '@sb-shared/models/colours';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstallationSettingsService } from '@sb-shared/services/installation-settings.service';

@Component({
  selector: 'sb-colour-picker',
  templateUrl: './colour-picker.component.html',
  styleUrls: ['./colour-picker.component.scss']
})
export class ColourPickerComponent implements OnInit {

  @Input() isDisabled: boolean;
  @Input() isUi: boolean;
  @Input() colourOptions: Colours
  @Output() colourOptionsChange: EventEmitter<Colours> = new EventEmitter();

  colourService: any;
  docStyle = document.documentElement.style;
    
    constructor (private installationSettings: InstallationSettingsService, private colours: ColoursService) {
    }

    updateColours() {
        if (this.isUi) {
            this.colours.setBrowserColours(this.colourOptions);
        }
        this.colourOptionsChange.emit(this.colourOptions);
    }

    setDefaults() {
        this.installationSettings.getDefaultColours().subscribe(defaultColours => {
            this.colourOptions = defaultColours;
            this.updateColours();
        });
    }

    ngOnInit(): void {
        if (!this.colourOptions) {
            if (this.isUi) {
                this.setDefaults();
            }
            else {
                this.colourOptions = { primaryColour: '#ffffff', secondaryColour: '#ffffff' };
            }
        }
    }

}
