'use strict';

angular.module('ccasignupModule').component('ccaAddPupilAA', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-add-pupil-aa.template.html',
    controller:
    [
        '$scope',
        '$state',
        '$filter',
        'availableRepository',
        'ccaService',
        'EventCategorySystemUsageType',
        'personService',
        'signUpRepository',
        'simpleAlertService',
        function ($scope,
            $state,
            $filter,
            availableRepository,
            ccaService,
            EventCategorySystemUsageType,
            personService,
            signUpRepository,
            simpleAlertService)
        {
            $scope.signUp = {};
            $scope.processing = {};
            $scope.EventCategorySystemUsageType = EventCategorySystemUsageType;
            $scope.eventsData = null;
            $scope.sortType = 'from';
            $scope.sortReverse = false;
            $scope.searchFilters = { 'title': '', 'teamTitle': '', 'fromDay': '' };
            $scope.queryFilters = { showOnlyEligibleEvents: true };
            $scope.unconfirmedBookingCount = 0;

            $scope.currentDate = null;
            $scope.currentDay = null;

            $scope.isSaving = false;
            $scope.isSubmitted = false;

            $scope.timeRemaining;
            $scope.minutesUntilBookingExpiry = 0;

            // the two following watches
            // need to be in this order as they
            // need to fire in a particular order
            $scope.$watch('sortType', function (newValue, oldValue) {
                $scope.currrentSortType = newValue;
            });

            $scope.$watch('sortReverse', function (newValue, oldValue) {
                if ('from' === $scope.currrentSortType) {
                    $scope.fromSortReverse = newValue;
                }
            });

            signUpRepository.getSignUp($state.params.signUpId).then((signUp) => {
                $scope.signUp = signUp;
            });

            // Function used by autocomplete component
            $scope.getPupils = personService.lookupPupilByName;
            $scope.getPupilArgs = [
                false // whether to include deleted pupils. Always false in this view.
            ];

            $scope.onSelect = function ($item, $model, $label) {
                $scope.$item = $item;
                $scope.$model = $model;
                $scope.filterTitle = $label;
                $scope.$label = $label;

                $scope.refreshData();
            };

            $scope.refreshData = function ()
            {
                $scope.eventsData = null;
                $scope.unconfirmedBookingCount = 0;
                signUpRepository
                    .getSignUpEventsForPupil($state.params.signUpId, $scope.$item.id, $scope.queryFilters.showOnlyEligibleEvents)
                    .then(function (eventsData)
                    {
                        $scope.eventsData = eventsData;
                    });
            };

            $scope.totalSelectionCount = function () {

                var selectionCount = 0;

                if ($scope.eventsData) {
                    for (var i = $scope.eventsData.availableEvents.length; i--;) {
                        var availableEvent = $scope.eventsData.availableEvents[i];
                        for (var j = availableEvent.pupils.length; j--;) {
                            if (availableEvent.pupils[j].status !== 2 && // Confirmed
                                availableEvent.pupils[j].status !== 7) { // Processing
                                selectionCount += availableEvent.pupils[j].selectionCount;
                            }
                        }
                    }
                }

                return selectionCount;
            };

            $scope.saveBookingsHandler = function (signUpEvents, pupils, signUpId) {
                return signUpRepository.saveBookings(signUpEvents, pupils, signUpId, false);
            };

            $scope.toggleBookingHandler = function (pupil, anEvent, eventIds)
            {
                return availableRepository.postToggleBooking(pupil, anEvent, eventIds)
                    .then(function (data)
                    {
                        return data;
                    });
            };

            $scope.getSignUpEventsHandler = function ()
            {
                return signUpRepository
                    .getSignUpEventsForPupil($state.params.signUpId, $scope.$item.id, $scope.queryFilters.showOnlyEligibleEvents);
            }

            $scope.confirmBookings = function () {
                signUpRepository.saveBookings($scope.signUpEvents, $scope.pupils, $state.params.signUpId, true)
                    .then(function (response)
                    {
                        if (response && response.hasOwnProperty('isSuccess') && !response.isSuccess)
                        {
                            simpleAlertService.errorAlert({
                                message: response.errorResponse || 'SB_Error_Saving', messageTranslationParameters:
                                {
                                    eventTitle: response.eventTitle || '',
                                    eventOrganiserName: response.eventTitle || '',
                                    eventDateTime: $filter('date')(response.startDateTime, 'HH:mm')
                                }
                            })

                            $scope.saving = false;
                            return;
                        }

                        $scope.getSignUpEventsHandler()
                            .then((eventsData) =>
                            {
                                let doPendingBookingsExist = availableRepository.doPendingBookingsExist(eventsData.availableEvents);

                                if (doPendingBookingsExist)
                                {
                                    let bookingExpiryInfo = ccaService.getBookingExpiryInfo(eventsData.availableEvents);
                                    $scope.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;
                                    $scope.timeRemaining = bookingExpiryInfo.timeRemaining;
                                }

                                $scope.minutesUntilBookingExpiry = $scope.eventsData.minutesUntilBookingExpiry;

                                $state.go('ccaDetailsAA.ccaAddPupilConfirmation',
                                    {
                                        pupilId: $scope.$item.id,
                                        timeRemaining: $scope.timeRemaining,
                                        minutesUntilBookingExpiry: $scope.minutesUntilBookingExpiry
                                    });
                            });
                    });
            };

            $scope.costTypes = [
                { id: '0', name: 'SB_CcaCostType_Free' },
                { id: '1', name: 'SB_CcaCostType_Per_term' },
                { id: '2', name: 'SB_CcaCostType_Per_session' },
                { id: '3', name: 'SB_CcaCostType_One_Off'}
            ];
        }
    ]});

angular.module('ccasignupModule').filter('sumByKey', ['$filter', function ($filter) {
    return function (data, key) {
        if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
            return 0;
        }

        var sum = 0;
        angular.forEach(data, function (obj, objKey) {
            sum += parseFloat(obj[key]);
        });

        return sum;
    };
}]);


