angular.module('shared.components.sbTitleBar', [
    'ui.bootstrap',
]).component('sbTitleBar',
    {
        bindings: {
            barTitle: '<',
            barTitleXs: '<',
            detail: '<',
            buttons: '<',
            showBack: '<',
            description: '<',
            isSecondary: '<',
            transcludeInline: '<',
            linkText: '<',
            linkTextXs: '<',
            linkUrl: '<',
            linkRoute: '<',
            linkRouteParams: '<',
            linkNewWindow: '<', // Requires linkUrl to open in new window
            note: '@',
            noteTranslationParameters: '<',
            numerator: '<',
            denominator: '<',
            onButtonClick: '&'
        },
        transclude: true,
        templateUrl: '/Scripts/app/shared/components/sb-title-bar.template.html',
        controller: class SbTitleBarCtrl {

            // Dependencies
            $window: any;
            // $state: any;

            // Bindings

            linkText: string;
            linkUrl: string;
            linkRoute: string;
            linkRouteParams: any;
            linkNewWindow: boolean;
            buttons: any[];

            // Variables
            hasHistory: boolean = false;

            static $inject = ['$window'];

            constructor ($window) {
                this.$window = $window;
                // this.$state = $state;
                this.hasHistory = this.$window.history.length > 1;
            }

            $onChanges(changes) {
                const buttons = changes.buttons?.currentValue;
                if (buttons) {
                    buttons.forEach(button => {
                        // IF resetTimeout defined: use that
                        // ELSE: For buttons with no submitted state, use default timeout of 100, but when there is a submitted state apply no resetTimeout
                        button.resetTimeout = button.resetTimeout || (button.isSubmitted === undefined ? 100 : null);
                    });
                }
            }

            back() {
                this.$window.history.back();
            }

            openLink(){
                // Never got used, $state breaks hybrid so have removed
                if (this.linkRoute) {
                    // this.$state.go(this.linkRoute, this.linkRouteParams);
                }
                else if(this.linkNewWindow) {
                    this.$window.open(this.linkUrl);
                }
                else {
                    this.$window.location.href = this.linkUrl;
                }
            }

        }
    });
