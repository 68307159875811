'use strict';

angular.module('transport.configuration.transportRoutes',
    [
        'transport.services.transportConfigurationRoutesService',
        'transport.configuration.routes.transportRouteAdd',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportRoutes',
    {
        bindings: {
            },
            templateUrl: '/Scripts/app/transport/configuration/routes/transport-routes.template.html',
            controller: [
                'transportConfigurationRoutesService',
                'simpleAlertService',
                '$uibModal',
                function transportRoutesController(transportConfigurationRoutesService, simpleAlertService, $uibModal) {

                    this.$onInit = function ()
                    {
                        this.loadRoutes();
                    }.bind(this);

                    this.loadRoutes = () => {
                        this.transportRoutes = null;

                        transportConfigurationRoutesService.getRoutes().then(function (response)
                        {
                            this.transportRoutes = response;
                        }.bind(this))
                        .catch(function (error) {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                        }.bind(this));
                    }

                    //Table Sorting
                    this.sortType = 'name';
                    this.sortReverse = false;

                    this.add = function ()
                    {
                        var addModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'transportRouteAdd',
                            size: 'lg',
                            backdrop: 'static'
                        });

                        addModalInstance.result.then(
                            () => this.loadRoutes(),
                            () => {}
                        );
                    }.bind(this);

                    this.editRoute = function (transportRoute)
                    {
                        var editModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'transportRouteEdit',
                            size: 'lg',
                            backdrop: 'static',
                            resolve:
                            {
                                params: function ()
                                {
                                    return { transportRoute: transportRoute };
                                }
                            }
                        });

                        editModalInstance.result.then(
                            () => this.loadRoutes(),
                            () => {}
                        );
                    };

                    this.statusText = function (status)
                    {
                        return status ? 'SB_Yes' : 'SB_No';
                    }
                }
            ]
     });

