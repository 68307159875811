angular.module('shared.components.sbSelect', [
    'shared.constants',
    'shared.components.sbIcon',
    'shared.services.countryService',
    'shared.services.iconService'])
    .component('sbSelect',
        {
            bindings: {
                selectOptions: '<',
                primaryOptionIds: '<',
                model: '<',
                id: '@',
                idProp: '@',
                labelProp: '@',
                selectedLabelProp: '@',
                sbIconProp: '@',
                iconClassProp: '@',
                colourIcons: '<',
                imgProp: '@',
                preset: '<',
                isDisabled: '<',
                isRequired: '<',
                showSearch: '<',
                isGrid: '<',
                onChange: '&',
                onGetOptions: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-select.template.html',
            controller: class SbSelectCtrl {

                // Dependencies
                $timeout: any;
                $filter: any;
                $anchorScroll: any;
                $window: any;
                $document: any;
                formElements: any;
                countryService: any;
                iconService: any;

                // Bindings
                selectOptions: any;
                primaryOptionIds: Array<number>;
                model: any;
                id: any;
                idProp: string;
                labelProp: string;
                selectedLabelProp: string;
                sbIconProp: string;
                iconClassProp: string;
                imgProp: string;
                colourIcons: boolean;
                preset: any;
                isDisabled: boolean;
                isRequired: boolean;
                showSearch: boolean;
                isGrid: boolean;
                onChange: any;
                onGetOptions: any;

                // Variables
                options: any;
                fakeSelectId: string;
                selectId: string;
                selectInnerId: string;
                show: boolean = false;
                hideSelectedLabel: boolean;
                altProp: string;
                _searchText: string;
                _searchTextLowerCase: string;
                searchInputId: string;
                highlightedOptionId: string;
                currentIndex: number;
                isLoading: boolean;
                isReady: boolean;

                static $inject = ['$timeout', '$filter', '$anchorScroll', '$window', '$document', 'formElements', 'countryService', 'iconService'];

                constructor($timeout, $filter, $anchorScroll, $window, $document, formElements, countryService, iconService) {
                    this.$timeout = $timeout;
                    this.$filter = $filter;
                    this.$anchorScroll = $anchorScroll;
                    this.$window = $window;
                    this.$document = $document;
                    this.formElements = formElements;
                    this.countryService = countryService;
                    this.iconService = iconService;
                }

                $onInit() {
                    this.fakeSelectId = 'fakeSelect-' + this.id;
                    this.selectId = 'select-' + this.id;
                    this.selectInnerId = this.selectId + '-inner';

                    // Apply properties and options for presets
                    if (this.preset == this.formElements.Country || this.preset == this.formElements.Tel) {
                        this.primaryOptionIds = [235, 236, 46]
                        this.isLoading = true;
                        this.countryService.getCountries()
                            .then(res => {
                                if (res.isSuccess) {
                                    this.labelProp = this.preset == this.formElements.Country ? 'name' : 'phoneLabel';
                                    this.selectOptions = res.data;
                                    this.selectOptions.forEach(country => {
                                        country.imageUrl = this.$window.EveryBuddy.CdnBase + 'flags/' + country.twoCharCountryCode + '.png?v='  + this.$window.EveryBuddy.Version;
                                    });
                                    if (this.preset == this.formElements.Tel) {
                                        // Add phone codes
                                        this.hideSelectedLabel = true;
                                        this.altProp = 'phoneCountryCode';
                                    }
                                    this.initSelect();
                                }
                                else {
                                    console.log('No options found for select ' +  this.id);
                                }
                                this.isLoading = false;
                            })
                            .catch(err => {
                                console.log(err);
                                this.isLoading = false;
                            })
                    }
                    else if (this.preset === this.formElements.Icon) {
                        this.iconService.getIcons().
                            then(res => {
                                if (res.data) {
                                    this.selectOptions = res.data;
                                    this.isGrid = true;
                                    this.iconClassProp = "fontAwesomeClassName";
                                    this.colourIcons = true;
                                    this.initSelect();
                                }
                                else {
                                    this.isLoading = false;
                                }
                            })
                    }
                    else {
                        this.initSelect();
                    }
                }

                $onChanges(changes) {
                    if ((changes.selectOptions && changes.selectOptions.currentValue) ||
                         changes.idProp && changes.idProp.currentValue ||
                         changes.labelProp && changes.labelProp.currentValue ||
                         changes.iconProp && changes.iconProp.currentValue) {
                        this.initSelect();
                        this.positionInnerElement();
                    }
                    // Set default item properties if not set
                    if (changes.idProp) {
                        // Option id
                        this.idProp = this.idProp || 'id';
                    }
                    if (changes.labelProp) {
                        // Option label
                        this.labelProp = this.labelProp || 'name';
                    }
                    if (changes.sbIconProp) {
                        // Option icon
                        this.sbIconProp = this.sbIconProp || 'iconName';
                    }
                    if (changes.iconClassProp) {
                        // Option icon
                        this.iconClassProp = this.iconClassProp || 'iconClass';
                    }
                    if (changes.imgProp) {
                        // Option icon
                        this.imgProp = this.imgProp || 'imageUrl';
                    }
                    if (changes.selectedLabelProp || changes.labelProp) {
                        // Option label when selected
                        if (changes.selectedLabelProp && changes.selectedLabelProp.currentValue) {
                            // Keep new selectedLabelProp
                        }
                        else if (changes.labelProp && changes.labelProp.currentValue) {
                            this.selectedLabelProp = changes.labelProp.currentValue;
                        }
                        else {
                            this.selectedLabelProp = this.selectedLabelProp || this.labelProp;
                        }
                    }
                }

                initSelect() {
                    if (this.selectOptions) {
                        // Reset local options and recreate with null option based on new data, idProp/labelProp/iconProp
                        this.options = Array.from(this.selectOptions);
                        this.options.forEach((option, index) => {
                            option.index = index;
                            if (this.preset === this.formElements.Tel && this.primaryOptionIds) {
                                this.options.forEach(country => {
                                    country.phoneLabel = this.$filter('translate')(`${country.name} (+${country.phoneCountryCode})`);
                                    country.isPhoneDefault = this.primaryOptionIds.includes(country[this.idProp]);
                                });
                              };
                        });

                        if (this.primaryOptionIds) {
                           const isPrimary = option => {return this.primaryOptionIds.includes(option[this.idProp])};

                           this.options.sort((a, b) => {
                                const isPrimaryA = isPrimary(a);
                                const isPrimaryB = isPrimary(b);

                                if (!isPrimaryA && isPrimaryB) {
                                    return 1;
                                }
                                if (isPrimaryA && !isPrimaryB) {
                                    return -1;
                                }
                                if (isPrimaryA && isPrimaryB) {
                                    return this.primaryOptionIds.indexOf(a.id) > this.primaryOptionIds.indexOf(b.id) ? 1 : -1;
                                }
                                return a.index > b.index ? 1 : -1;
                           });
                        }

                        // Init search
                        this.searchText = '';
                        this.showSearch = this.showSearch || this.options.length > 10;
                        if (this.showSearch) {
                            this.searchInputId = 'selectSearch-' + this.id;
                        }
                        this.isReady = this.options.length > 0;
                        this.onGetOptions({options: this.options});
                        const selectValue = (this.options[0] && typeof this.options[0][this.idProp] === 'number') ? 0 : '';
                        const existingBlankOption = this.options.filter(option => {
                            return option[this.idProp] == selectValue;
                        })[0];
                        if (!existingBlankOption && !this.isRequired && this.preset !== this.formElements.Tel) {
                            const blankOption = {};
                            blankOption[this.labelProp] = 'SB_None';
                            blankOption[this.idProp] = selectValue;
                            this.options.unshift(blankOption);
                            if (!this.model && !this.isRequired) {
                                this.model = selectValue;
                            }
                        }
                        // Reposition dropdown in response to user actions
                        angular.element(this.$window).on('resize', () => {
                            this.positionInnerElement();
                        });
                        angular.element(document).bind("wheel", () => {
                            this.positionInnerElement();
                        });
                        angular.element(document).bind("touchmove", () => {
                            this.positionInnerElement();
                        });
                        this.$document.on('click', e => {
                            // Using .contains(e.target) doesn't seem to work here
                            if (this. show &&
                                e.target.id !== this.searchInputId &&
                                e.target.parentNode?.id !== this.searchInputId &&
                                e.target.id !== this.selectId &&
                                e.target.parentNode?.id !== this.selectId &&
                                e.target.parentNode?.parentNode?.id !== this.selectId &&
                                e.target.id !== this.selectInnerId &&
                                e.target.parentNode?.id !== this.selectInnerId) {
                                    this.$timeout(() => {
                                        this.show = false;
                                        this.searchText = '';
                                    }, 300);
                            }
                        });
                    }
                }

                clickSelect() {
                    // Reposition dropdown before opening
                    this.show = !this.show;
                    this.positionInnerElement();
                    this.$timeout(() => {
                        if (this.show && this.showSearch) {
                            document.getElementById(this.searchInputId).focus();
                            this.highlightedOptionId = '';
                        }
                    });
                }

                onClickItem(value, e) {
                    e.preventDefault();
                    this.selectItem(value);
                }

                selectItem(value) {
                    this.model = value;
                    this.show = false;
                    this.searchText = '';
                    this.onChange({id: this.id, value: this.model});
                }

                selectedItem() {
                    if (this.isReady) {
                        return this.options.find(item => item[this.idProp] === this.model);
                    }
                }

                keyUp(e) {
                    if (this.isReady) {
                        var filteredOptions = this.filteredOptions();
                        var key = e.which;
                        if (this.show) {
                            if (key === 13) {
                                // Enter
                                if (filteredOptions.length === 1) {
                                    let value = filteredOptions[0][this.idProp];
                                    this.selectItem(value);
                                    this.show = false;
                                }
                                else if (this.highlightedOptionId !== undefined) {
                                    let value = this.highlightedOptionId;
                                    this.selectItem(value);
                                    this.show = false;
                                }
                            }
                            else if (key >= 37 && key <= 40) {
                                // Handle directional keys and move between items
                                e.preventDefault();
                                let change;
                                if (this.isGrid) {
                                    // Move around grid
                                    switch(key) {
                                        case 37:
                                            // Left
                                            change = -1;
                                            break;
                                        case 38:
                                            // Up
                                            change = -3;
                                            break;
                                        case 39:
                                            // Right
                                            change = 1;
                                            break;
                                        case 40:
                                            // Down
                                            change = 3;
                                            break;
                                        default:
                                            change = 0;
                                      }
                                } else {
                                    // Move up/down regular list
                                    switch(key) {
                                        case 38:
                                            // Up
                                            change = -1;
                                            break;
                                        case 40:
                                            // Down
                                            change = -1;
                                            break;
                                        default:
                                            change = 0;
                                        }
                                }
                                // If target item is after the end of the list, just jump to the last item
                                if (change > 0 && !filteredOptions[this.highlightedOptionId + change]) {
                                    this.highlightedOptionId = filteredOptions[filteredOptions.length - 1][this.idProp];
                                }
                                // If target item is before the start of the list, just jump to the first item
                                else if (!filteredOptions[this.highlightedOptionId + change]) {
                                    this.highlightedOptionId = filteredOptions[0][this.idProp];
                                }
                                // Otherwise, if an item is currently highlighted, just jump items based on change
                                else if (this.highlightedOptionId !== undefined) {
                                    this.changeHighlightedOption(change);
                                }
                                // If no currently selected item, and moving down/right, just select the first item in the list
                                else if (change > 0) {
                                    this.highlightedOptionId = filteredOptions[0][this.idProp];
                                }
                            }
                        }
                    }
                }

                changeHighlightedOption(change) {
                    this.filteredOptions().forEach((option, index) => {
                        if (option[this.idProp] == this.highlightedOptionId) {
                            this.currentIndex = index;
                        }
                    });
                    var newOption = this.filteredOptions()[this.currentIndex + change];
                    if (newOption) {
                        this.highlightedOptionId = newOption[this.idProp];
                        var highlightedElementId = 'fakeSelect' + '-' + this.id + '-' + this.highlightedOptionId;
                        this.$anchorScroll(highlightedElementId);
                    }
                }

                get searchText(): string {
                    return this._searchText;
                }

                set searchText(searchText: string) {
                    this._searchText = searchText;
                    this._searchTextLowerCase = searchText.toLowerCase();
                }

                filteredOptions() {
                    if (!this._searchText) {
                        return this.options;
                    }

                    return this.options.filter(option => {
                        return option[this.labelProp].toLowerCase().includes(this._searchTextLowerCase) &&
                            (!this.isRequired || option[this.idProp])
                    });
                }

                showOptionDivider(index) {
                    if (this.isGrid || this.searchText) {
                        return false;
                    }

                    const filteredOptions = this.filteredOptions();
                    if (filteredOptions[index + 1] && this.primaryOptionIds) {
                        return this.primaryOptionIds.includes(filteredOptions[index][this.idProp]) &&
                        !this.primaryOptionIds.includes(filteredOptions[index + 1][this.idProp]);
                    }

                    return false;
                }

                positionInnerElement() {
                    // Check if in modal
                    let modalOffsetTop = 0;
                    let modalOffsetLeft = 0;
                    const modalContent =  document.getElementsByClassName('modal-content');
                    if (modalContent && modalContent[0]) {
                        modalOffsetTop = modalContent[0].getBoundingClientRect().top;
                        modalOffsetLeft = modalContent[0].getBoundingClientRect().left;
                    }
                    // Position dropdown on select
                    const select =  document.getElementById(this.selectId);
                    const selectInner = angular.element(document.getElementById(this.selectInnerId));
                    if (select && selectInner) {
                        let selectPositionTop = select.getBoundingClientRect().top - modalOffsetTop;
                        const maxPositionTop = this.$window.innerHeight - 350;
                        // Make sure dropdown is not below window area
                        if (selectPositionTop > maxPositionTop) {
                            selectPositionTop = maxPositionTop;
                        }
                        const selectPositionLeft = select.getBoundingClientRect().left - modalOffsetLeft;
                        const selectHeight = select.offsetHeight;
                        selectInner.css('top', (selectPositionTop + selectHeight + 1).toString() + 'px');
                        selectInner.css('left', selectPositionLeft.toString() + 'px');
                    }
                }

                getFullImgUrl(item: any) {
                    if (this.preset == this.formElements.Country || this.preset == this.formElements.Tel) {
                        return item.imageUrl;
                    }
                    else {
                        return this.$window.EveryBuddy.CdnBase + 'img/icons/' + item[this.imgProp];
                    }
                }
            }
        });
