import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UiClasses } from './../../types/ui-classes';
import { ComponentBase } from '../component-base/component-base';
import { AlertCollapseConfig } from './alert-collapse-config';

@Component({
  selector: 'sb-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends ComponentBase implements OnInit, OnChanges {

  // Text shown in alert
  @Input() message: string;
  // Text shown in for xs layout
  @Input() messageXs: string;
  // Text translation params
  @Input() messageTranslationParameters: string;
  // Should we translate the message passed in
  @Input() isMessageTranslated = true;
  // Alert UI class, such as primary, secondary
  @Input() alertClass: UiClasses;
  // Alert title
  @Input() alertTitle: string;
  // Additional classes for element
  @Input() customClasses: string;
  // Note to show below main message
  @Input() note: string;
  // Note translation params
  @Input() noteTranslationParameters: any;
  /**Whether alert is collapsible, and optional to set show how many lines(default to show 2 lines) */
  @Input() collapseConfig?: AlertCollapseConfig;
  // Whether to hide when 'x' is pressed, or let parent handle
  @Input() hideOnDismiss: boolean;
  // Remove border from alert
  @Input() isBorderless: boolean;
  // Icon to show to right of alert
  @Input() rightIconName: string;
  // Icon to show on top of main icon
  @Input() rightIconOverlayName: string;
  // Overlay icon custom class
  @Input() rightIconOverlayCustomClass: string;
  // Overlay icon tooltip
  @Input() rightIconTooltip: string;
  // Label to show on right of alert
  @Input() rightLabel: string;
  // Small alert layout
  @Input() isSmall: boolean;
  // Just show styled text
  @Input() isTextOnly: boolean;
  // Inverted layout
  @Input() isInverted: boolean;
  // Style alert when sitting at top of page
  @Input() isTopOfPage: boolean;
  // Add router link to icon
  @Input() routerLink: string
  // Handle clicking of 'x' icon
  @Output() onDismiss = new EventEmitter<void>();
  //!have to be the same with line-height of .alert__body__inner--collapsed  in scss file
  readonly lineHeight = 20;

  computedClass: string;
  showDismiss: boolean;
  isCollapsed: boolean;
  isHidden: boolean;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    this.showDismiss = this.onDismiss.observers.length > 0 || this.hideOnDismiss;
    this.collapseConfig = { showLines: 2, ...this.collapseConfig }; //! set showLines default to 2
    this.isCollapsed = this.collapseConfig.isCollapsible;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.alertClass = this.alertClass || 'info';
    if (changes.isTopOfPage?.currentValue) {
      this.customClasses = this.customClasses + ' m-3';
      this.isSmall = true;
      this.hideOnDismiss = true;
    }
    if (changes.alertClass?.currentValue || changes.customClasses?.currentValue || changes.onDismiss || changes.isTopOfPage) {
      this.computedClass = 'alert alert-' + this.alertClass + ' ' + this.customClasses;
    }
  }

  dismiss() {
    this.hideOnDismiss ? this.isHidden = true : this.onDismiss.emit();
  }

  navigate() {
    this.router.navigateByUrl(this.routerLink);
    this.onDismiss.emit();
  }

}
