'use strict';

angular.module('transport.changeRequests.transportPendingRequests', [
    'transport.changeRequests.transportReviewChangeRequest',
    'transport.services.transportTimetablesService',
    'shared.components.sbColumnHeader',
    'shared.components.sbIcon',
    'shared.components.sbTextarea',
    'shared.components.sbAvatar',
    'shared.services.blobStoragePhotosService'
])
    .component('transportPendingRequests', {
        templateUrl: '/Scripts/app/transport/changeRequests/transport-pending-requests.template.html',
        bindings: {
            transportChangeRequests : '<',
            onChangeRequestsUpdated: '<'
        },
        controller: [
            '$uibModal',
            'transportTimetablesService',
            'blobStoragePhotosService',
            function transportPendingRequests($uibModal, transportTimetablesService, blobStoragePhotosService)
            {
                this.$onInit = function ()
                {
                    this.isDisabled = false;

                    if (this.transportChangeRequests && this.transportChangeRequests.length > 0) {
                        blobStoragePhotosService.addStudentPhotoUrlsToArray(this.transportChangeRequests, 'pupilPersonId').then(function (array) {
                            this.transportChangeRequests = array;
                        }.bind(this));
                    }
                }.bind(this);

                this.viewReviewChangeRequest = function (changeRequest) {

                    this.isDisabled = true;
                    var modalInstance = $uibModal.open({
                        animation: true,
                        component: 'transportReviewChangeRequest',
                        size: 'lg',
                        backdrop: 'static',
                        windowClass: 'modal-scroll',
                        resolve:
                        {
                            changeRequest: function () { return changeRequest; }.bind(this),
                            timeSlotTimeTableByOperatingDay: function ()
                            {
                                return transportTimetablesService
                                    .getTimetableForTimeSlotCalendarAndEventDateGroupByDayAsync(changeRequest.transportOptionChangeTo.timeSlotId,
                                                                                                changeRequest.calendarEventStartDateTime,
                                                                                                changeRequest.organisationOperatingDayId);
                            }.bind(this)
                        }
                    });

                    modalInstance.result.then(function () {
                        this.isDisabled = false;

                        this.onChangeRequestsUpdated.emit();
                    }.bind(this), function () { });

                }.bind(this);

                this.viewAddChangeRequest = function () {

                    this.isDisabled = true;

                    var modalInstance = $uibModal.open({
                        animation: true,
                        backdrop: 'static',
                        component: 'studentPickerWithDiaryEvents',
                        size: 'lg',
                        windowClass: 'modal-scroll',
                    });

                    modalInstance.result.then(function () {
                        this.isDisabled = false;

                        this.onChangeRequestsUpdated.emit();

                    }.bind(this), function () { });

                }.bind(this);
            }
        ]
});
