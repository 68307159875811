import { Component, Input, OnInit } from '@angular/core';
import { MainMenuItemId } from '@sb-events/enums/main-menu-item';
import { MenuItems } from '@sb-shared/models/UI/menu-item';
import { UserService } from 'src/app/shared/services/user.service';
import { MainMenuItem, MainMenuItems, SubMenuItems } from '../../models/UI/menu-item';
import { MenuService } from '../../services/menu.service';
import { MenuItem } from './../../models/UI/menu-item';
import { IconService } from './../../services/icon.service';
import { LocaleService } from './../../services/locale.service';

@Component({
  selector: 'sb-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],

})
export class SideMenuComponent implements OnInit {

  private _isOpen: boolean;
  get isOpen(): boolean {
    return this._isOpen;
  }
  @Input() set isOpen(value: boolean) {
    this._isOpen = value;
    this.setModulesClass();
    this.setSubMenuClass();
    this.setCurrentPageMenuClass();
  }

  private _showModules = true;
  get showModules(): boolean {
    return this._showModules;
  }
  set showModules(value: boolean) {
    this._showModules = value;
    this.setModulesClass();
    this.setSubMenuClass();
  }

  private _currentMainMenuItem: MainMenuItem;
  get currentMainMenuItem(): MainMenuItem {
    return this._currentMainMenuItem;
  }
  set currentMainMenuItem(value: MainMenuItem) {
    this._currentMainMenuItem = value;
    this.setModulesClass();
    this.setContainerClass();
  }

  private _expandedMenuItem: MainMenuItem;
  get expandedMenuItem(): MainMenuItem {
    return this._expandedMenuItem;
  }
  set expandedMenuItem(value: MainMenuItem) {
    this._expandedMenuItem = value;
    this.setModulesClass();
    this.setSubMenuClass();
  }

  private _openSubMenu = false;
  get openSubMenu(): boolean {
    return this._openSubMenu;
  }
  set openSubMenu(value: boolean) {
    this._openSubMenu = value;
    this.setSubMenuClass();
  }

  private _showCurrentPageMenuItems: boolean;
  get showCurrentPageMenuItems(): boolean {
    return this._showCurrentPageMenuItems;
  }
  set showCurrentPageMenuItems(value: boolean) {
    this._showCurrentPageMenuItems = value;
    this.setSubMenuClass();
  }

  mainMenuItems: MainMenuItems = [];
  subMenuItems: SubMenuItems = [];
  isActiveElementFound = false;
  isStaff: boolean;
  currentPageMenuItems: MenuItems;
  isRtl: boolean;
  disableTooltips: boolean;
  modulesClass = '';
  subMenuClass = '';
  currentPageMenuClass = '';
  containerClass = '';

  constructor(
    private menu: MenuService,
    private user: UserService,
    private icon: IconService,
    private locale: LocaleService) {
  }

  ngOnInit() {
    this.menu.getMainMenuItems().subscribe(mainMenuItems => {
      this.mainMenuItems = mainMenuItems.filter(item => {
        return !item.isHidden && !item.isAvatarMenu;
      });
    })
    this.menu.getSubMenuItems().subscribe(subMenuItems => {
      this.subMenuItems = subMenuItems;
      this.menu.currentMainMenuItem.subscribe(mainMenuItem => {
        this.currentMainMenuItem = mainMenuItem;
        this.expandedMenuItem = this.currentMainMenuItem;
        this.resetMenu();
      })
    });
    this.menu.currentPageMenuItems.subscribe(items => {
      this.currentPageMenuItems = items;
      this.showCurrentPageMenuItems = this.currentPageMenuItems?.length > 2;
      this.checkModules();
    })
    this.user.isStaff().subscribe(isStaff => {
      this.isStaff = isStaff;
    });
    this.locale.getScriptDirection().subscribe(direction => {
      this.isRtl = direction === 'rtl';
    })
  }

  checkModules() {
    setTimeout(() => {
      this.showModules = (!this.subMenuItems || this.visibleSubMenuItems().length < 2) && !this.showCurrentPageMenuItems;
    })
  }

  hasSubMenuItems(item: MainMenuItem): boolean {
    return this.subMenuItems.some(subMenuItem => subMenuItem.parentId === item.menuItemTypeId);
  }

  expandSubMenuItem($event: MouseEvent, item: MainMenuItem) {
    $event.stopPropagation();
    this.expandedMenuItem = item;
    this.showModules = false;
    this.openSubMenu = true;
    this.disableTooltips = true;
    return false;
  }

  getIcon(item: MenuItem, isActive: boolean, isMain?: boolean): string {
    if (item.iconName) {
      return this.icon.getIcon(item.iconName);
    }
    return this.icon.getStyledIcon(item, isActive, isMain);
  }

  getTooltipPlacement(): string {
    return this.isRtl ? 'left' : 'right';
  }

  setContainerClass() {
    this.containerClass = !this.isOnDashBoard() ? 'non_dashboard_menu' : '';
  }

  setModulesClass() {
    // Dashboard override
    if (!this.currentMainMenuItem?.menuItemTypeId || !this.isOpen && this.isOnDashBoard()) {
      this.modulesClass = 'dashboard__modules';
      return;
    }
    this.modulesClass = (this.showModules ? '' : 'hidden') +
      (this.visibleSubMenuItems()?.length > 1 ? ' buddy-nav-open' : '') +
      (this.isOpen ? ' buddy-nav-open' : '');
  }

  setSubMenuClass() {
    this.subMenuClass = (this.showModules || this.showCurrentPageMenuItems ? 'hidden' : 'visible') +
      (this.openSubMenu || this.expandedMenuItem?.isAlwaysOpen ? ' buddy-nav-open' : '') +
      (this.isOpen ? ' buddy-nav-open' : '')
  }

  setCurrentPageMenuClass() {
    this.currentPageMenuClass = this.isOpen ? ' buddy-nav-open' : '';
  }

  openModules() {
    this.showModules = true;
    this.disableTooltips = true;
    //document.getElementById('subMenuBarInnerModules').focus();
  }

  hideCurrentPageMenuItems() {
    this.showCurrentPageMenuItems = false;
    this.openSubMenu = true;
    this.disableTooltips = true;
    //document.getElementById('subMenuBarInnerModules').focus();
  }

  resetMenu() {
    if (this.currentMainMenuItem && this.subMenuItems) {
      this.openSubMenu = this.currentMainMenuItem.isAlwaysOpen;
      this.expandedMenuItem = this.currentMainMenuItem;
      const menuitem = this.currentMainMenuItem;
      const hasSubMenu = this.subMenuItems.some(subMenuItem => {
        return subMenuItem.parentId === menuitem?.menuItemTypeId;
      });
      this.expandedMenuItem = hasSubMenu ? menuitem : null;
      this.showModules = this.expandedMenuItem === null;
      this.showCurrentPageMenuItems = this.currentPageMenuItems?.length > 1;
      this.disableTooltips = false;
    }
  }

  visibleSubMenuItems(): SubMenuItems {
    return this.subMenuItems.filter(menuItem => {
      return menuItem.parentId === this.expandedMenuItem?.menuItemTypeId;
    })
  }

  showSeparator(items: MenuItems, i: number) {
    return i > 1 && items[i].groupId !== items[i - 1].groupId;
  }

  showMainMenuLink() {
    return this.isStaff || this.currentMainMenuItem?.menuItemTypeId == MainMenuItemId.Profile;
  }

  private isOnDashBoard(): boolean {
    return this.currentMainMenuItem?.menuItemTypeId === MainMenuItemId.Dashboard;
  }

}

