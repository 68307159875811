angular.module('tuitionFeesModule')
    .component('addToCycleStudents', {
        bindings: {
            outModel: '<',
            payerModel: '<',
            payerIsEditing: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/add-to-cycle-students.template.html',
        controller: class AddToCycleStudentsCtrl {

            // Bindings
            outModel: any;
            payerModel: any;
            payerIsEditing: boolean;

            // Dependencies
            $filter: any;
            billingCycleService: any;
            searchResultOptionsCountService: any;
            searchFilterTypes: any;
            payerTypesEnum: any;

            // Variables
            searchParameters: any;
            studentSearchResults: any[];
            selectedStudents: any[];

            studentSearchResultsSortType: string = 'thirdPartyStudentId';
            studentSearchResultsSortReverse: boolean = false;

            selectedStudentsSortType: string = 'thirdPartyStudentId';
            selectedStudentsSortReverse: boolean = false;

            yearSearchFilters: any;
            classSearchFilters: any;
            genderTypeSearchFilters: any;
            installmentPlanSearchFilters: any;
            paymentPlanSearchFilters: any;
            isBoardingSearchFilters: any;
            isFinancialAidSearchFilters: any;
            isChildOfSchoolStaffSearchFilters: any;
            isScholarshipSearchFilters: any;
            tuitionPaidBySearchFilters: any;
            busPaidBySearchFilters: any;
            lunchPaidBySearchFilters: any;
            enrollmentStatusSearchFilters: any;
            multiselectEvents: any;
            dropdownSettings: any;
            dateRangeLists: any;

            selectedYears: any[] = [];
            selectedClasses: any[] = [];
            selectedGenders: any[] = [];
            selectedInstallmentPlans: any[] = [];
            selectedPaymentPlans: any[] = [];
            selectedIsBoarding: any[] = [];
            selectedIsFinancialAid: any[] = [];
            selectedIsChildOfSchoolStaff: any[] = [];
            selectedIsScholarship: any[] = [];
            selectedTuitionPaidBy: any[] = [];
            selectedBusPaidBy: any[] = [];
            selectedLunchPaidBy: any[] = [];
            selectedEnrollmentFromDate: any;
            selectedEnrollmentToDate: any;
            selectedEnrollmentStatus: any[] = [];

            static $inject = ['$filter', 'billingCycleService', 'searchResultOptionsCountService', 'searchFilterTypes', 'payerTypesEnum', 'dateRangeLists'];

            constructor($filter, billingCycleService, searchResultOptionsCountService, searchFilterTypes, payerTypesEnum, dateRangeLists) {
                this.$filter = $filter;
                this.billingCycleService = billingCycleService;
                this.searchResultOptionsCountService = searchResultOptionsCountService;
                this.searchFilterTypes = searchFilterTypes;
                this.payerTypesEnum = payerTypesEnum;

                this.dateRangeLists = dateRangeLists;

                this.dropdownSettings =
                {
                    checkBoxes: true,
                    keyboardControls: true,
                    showUncheckAll: true,
                    showCheckAll: true,
                    closeOnBlur: true,
                    template: "{{option.name}} {{option.countFormatted}}",
                };

                this.multiselectEvents =
                {
                    year: {
                        onSelectionChanged: () => {
                            this.searchParameters.yearIds = this.getIds(this.selectedYears);
                            this.searchPerson();
                        }
                    },
                    class: {
                        onSelectionChanged: () => {
                            this.searchParameters.classIds = this.getIds(this.selectedClasses);
                            this.searchPerson();
                        }
                    },
                    gender: {
                        onSelectionChanged: () => {
                            this.searchParameters.genderTypeIds = this.getIds(this.selectedGenders);
                            this.searchPerson();
                        }
                    },
                    installmentPlan: {
                        onSelectionChanged: () => {
                            this.searchParameters.installmentPlanIds = this.getIds(this.selectedInstallmentPlans);
                            this.searchPerson();
                        }
                    },
                    paymentPlan: {
                        onSelectionChanged: () => {
                            this.searchParameters.paymentPlanIds = this.getIds(this.selectedPaymentPlans);
                            this.searchPerson();
                        }
                    },
                    isBoarding: {
                        onSelectionChanged: () => {
                            this.searchParameters.isBoardingIds = this.getIds(this.selectedIsBoarding);
                            this.searchPerson();
                        }
                    },
                    isFinancialAid: {
                        onSelectionChanged: () => {
                            this.searchParameters.isFinancialAidIds = this.getIds(this.selectedIsFinancialAid);
                            this.searchPerson();
                        }
                    },
                    isChildOfSchoolStaff: {
                        onSelectionChanged: () => {
                            this.searchParameters.isChildOfSchoolStaffIds = this.getIds(this.selectedIsChildOfSchoolStaff);
                            this.searchPerson();
                        }
                    },
                    isScholarship: {
                        onSelectionChanged: () => {
                            this.searchParameters.isScholarshipIds = this.getIds(this.selectedIsScholarship);
                            this.searchPerson();
                        }
                    },
                    tuitionPaidBy: {
                        onSelectionChanged: () => {
                            this.searchParameters.tuitionPaidByIds = this.getIds(this.selectedTuitionPaidBy);
                            this.searchPerson();
                        }
                    },
                    busPaidBy: {
                        onSelectionChanged: () => {
                            this.searchParameters.busPaidByIds = this.getIds(this.selectedBusPaidBy);
                            this.searchPerson();
                        }
                    },
                    lunchPaidBy: {
                        onSelectionChanged: () => {
                            this.searchParameters.lunchPaidByIds = this.getIds(this.selectedLunchPaidBy);
                            this.searchPerson();
                        }
                    },
                    enrollmentStatus: {
                        onSelectionChanged: () => {
                            this.searchParameters.enrollmentStatusIds = this.getIds(this.selectedEnrollmentStatus);
                            this.searchPerson();
                        }
                    }
                }
            }

            $onInit() {
                this.billingCycleService.getStudentSearchFilters().then(res => {
                    if (res.data) {

                        this.yearSearchFilters = this.formatFilters(res.data.years, true);

                        this.classSearchFilters = this.formatFilters(res.data.classes, true);

                        this.genderTypeSearchFilters = this.formatFilters(res.data.genders);

                        this.installmentPlanSearchFilters = this.formatFilters(res.data.installmentPlans, true);

                        this.paymentPlanSearchFilters = this.formatFilters(res.data.paymentPlans, true);

                        this.isBoardingSearchFilters = this.formatFilters(res.data.isBoarding);

                        this.isFinancialAidSearchFilters = this.formatFilters(res.data.isFinancialAid);

                        this.isChildOfSchoolStaffSearchFilters = this.formatFilters(res.data.isChildOfSchoolStaff);

                        this.isScholarshipSearchFilters = this.formatFilters(res.data.isScholarship);

                        this.tuitionPaidBySearchFilters = this.formatFilters(res.data.tuitionPaidBy, true);

                        this.busPaidBySearchFilters = this.formatFilters(res.data.busPaidBy, true);

                        this.lunchPaidBySearchFilters = this.formatFilters(res.data.lunchPaidBy, true);

                        this.enrollmentStatusSearchFilters = this.formatFilters(res.data.enrollmentStatus, true);

                        this.resetSearchParameters();
                        this.searchPerson(true);
                    }
                });


            }

            $onChanges(changes) {
                if (changes.outModel?.currentValue) {
                    this.outModel.selectedStudents = [];
                }
                else if (changes.payerIsEditing?.currentValue !== 'undefined' && !changes.payerIsEditing.currentValue) {
                    this.studentSearchResults = [];
                    this.outModel.selectedStudents = [];
                    this.onReset();
                }
            }

            onEnrollmentDateChange() {
                this.searchParameters.enrollmentFrom = this.formatDate(this.selectedEnrollmentFromDate);
                this.searchParameters.enrollmentTo = this.formatDate(this.selectedEnrollmentToDate, true);
                this.searchPerson();
            }

            formatDate(date, endOfDay = false) {
                return endOfDay ? date.format('YYYY-MM-DDT23:59:59') : date.format('YYYY-MM-DD');
            }

            getIds(source: any[]) {
                return source.map(y => {
                    return y.id;
                });
            }

            resetSearchParameters() {
                this.selectedYears = this.yearSearchFilters?.filterData?.length > 0 ? [...this.yearSearchFilters.filterData] : [];
                this.selectedClasses = this.classSearchFilters?.filterData?.length > 0 ? [...this.classSearchFilters.filterData] : [];
                this.selectedGenders = this.genderTypeSearchFilters?.filterData?.length > 0 ? [...this.genderTypeSearchFilters.filterData] : [];
                this.selectedInstallmentPlans = this.installmentPlanSearchFilters?.filterData?.length > 0 ? [...this.installmentPlanSearchFilters.filterData] : [];
                this.selectedPaymentPlans = this.paymentPlanSearchFilters?.filterData?.length > 0 ? [...this.paymentPlanSearchFilters.filterData] : [];
                this.selectedIsBoarding = this.isBoardingSearchFilters?.filterData?.length > 0 ? [...this.isBoardingSearchFilters.filterData] : [];
                this.selectedIsFinancialAid = this.isFinancialAidSearchFilters?.filterData?.length > 0 ? [...this.isFinancialAidSearchFilters.filterData] : [];
                this.selectedIsChildOfSchoolStaff = this.isChildOfSchoolStaffSearchFilters?.filterData?.length > 0 ? [...this.isChildOfSchoolStaffSearchFilters.filterData] : [];
                this.selectedIsScholarship = this.isScholarshipSearchFilters?.filterData?.length > 0 ? [...this.isScholarshipSearchFilters.filterData] : [];
                this.selectedBusPaidBy = this.busPaidBySearchFilters?.filterData?.length > 0 ? [...this.busPaidBySearchFilters.filterData] : [];
                this.selectedLunchPaidBy = this.lunchPaidBySearchFilters?.filterData?.length > 0 ? [...this.lunchPaidBySearchFilters.filterData] : [];
                this.selectedTuitionPaidBy = this.tuitionPaidBySearchFilters?.filterData?.length > 0 ? [...this.tuitionPaidBySearchFilters.filterData] : [];
                this.selectedEnrollmentStatus = this.enrollmentStatusSearchFilters?.filterData?.length > 0 ? [...this.enrollmentStatusSearchFilters.filterData] : [];

                this.selectedEnrollmentFromDate = moment().startOf('day').add(-12, 'months');
                this.selectedEnrollmentToDate = moment().endOf('day');

                this.searchParameters = {
                    yearIds: this.getIds(this.selectedYears),
                    classIds: this.getIds(this.selectedClasses),
                    genderTypeIds: this.getIds(this.selectedGenders),
                    installmentPlanIds: this.getIds(this.selectedInstallmentPlans),
                    paymentPlanIds: this.getIds(this.selectedPaymentPlans),
                    isBoardingIds: this.getIds(this.selectedIsBoarding),
                    isFinancialAidIds: this.getIds(this.selectedIsFinancialAid),
                    isChildOfSchoolStaffIds: this.getIds(this.selectedIsChildOfSchoolStaff),
                    isScholarshipIds: this.getIds(this.selectedIsScholarship),
                    busPaidByIds: this.getIds(this.selectedBusPaidBy),
                    lunchPaidByIds: this.getIds(this.selectedLunchPaidBy),
                    tuitionPaidByIds: this.getIds(this.selectedTuitionPaidBy),
                    enrollmentStatusIds: this.getIds(this.selectedEnrollmentStatus),
                    search: '',
                    includeDeleted: false,
                    payerTypeId: this.payerModel?.payerTypeId1,
                    enrollmentFrom: this.formatDate(this.selectedEnrollmentFromDate),
                    enrollmentTo: this.formatDate(this.selectedEnrollmentToDate, true),
                    includeNoEnrollmentDate: true
                };
            }

            searchPerson(isInit: boolean = false) {
                this.billingCycleService.studentSearch(this.searchParameters).then(res => {
                    if (res.data) {
                        this.studentSearchResults = res.data.students;
                        if (!this.studentSearchResults) {
                            return;
                        }

                        // splice over any already selected and arrange TCs
                        this.studentSearchResults.forEach(x => {
                            const selectedStudentIndex = this.outModel.selectedStudents.findIndex(p => p.id == x.id);
                            if (selectedStudentIndex > -1) {
                                x.isSelected = true;
                                // copy over any custom values
                                x.payers = this.outModel.selectedStudents[selectedStudentIndex].payers;
                                x.payerId = this.outModel.selectedStudents[selectedStudentIndex].payerId;
                                this.outModel.selectedStudents.splice(selectedStudentIndex, 1, x);
                            }
                        });

                        this.buildResultCountsForAllFiltersSelectedOptions(isInit);
                    }
                });
            }



            formatFilters(filterData: any[], addNotSpecifedOption: boolean = false) {

                if (!filterData) {
                    return null;
                }
                //Translate any filter option names where needed
                filterData.forEach(f => {
                    f.name = this.$filter('translate')(f.name)
                });

                //Add filter option for not specified
                if (addNotSpecifedOption) {
                    let notSpecifiedFilter = {
                        id: 0,
                        name: this.$filter('translate')('SB_Not_Specified')
                    }
                    filterData = [notSpecifiedFilter, ...filterData]
                }

                return {
                    //Show by default, overridden later as needed
                    showFilter: true,
                    filterData: filterData
                }
            }

            buildResultCountsForAllFiltersSelectedOptions(isInit: boolean) {
                let yearCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'yearGroup');
                let classCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'classGroup');
                let genderCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'genderTypeId');
                let installmentCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'installmentPlanId');
                let paymentCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'paymentPlanId');
                let isBoardingCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'isBoarding');
                let isFinancialAidCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'isFinancialAid');
                let isChildOfSchoolStaffCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'isChildOfSchoolStaff');
                let isScholarshipCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'isScholarship');
                let busPaidByCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'busPaymentTypeId');
                let lunchPaidByCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'lunchPaymentTypeId');
                let tuitionPaidByCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'tuitionPaymentTypeId');
                let enrollmentStatusCounts = this.searchResultOptionsCountService.getResultCounts(this.studentSearchResults, 'enrollmentStatus');

                //From total results, creates a count per unique value in each filterable property (year, class, gender) to display next to each corresponding selected option in filter drop-downs.
                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    yearCounts,
                    this.yearSearchFilters?.filterData,
                    this.selectedYears
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    classCounts,
                    this.classSearchFilters?.filterData,
                    this.selectedClasses
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    genderCounts,
                    this.genderTypeSearchFilters?.filterData,
                    this.selectedGenders
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    installmentCounts,
                    this.installmentPlanSearchFilters?.filterData,
                    this.selectedInstallmentPlans
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    paymentCounts,
                    this.paymentPlanSearchFilters?.filterData,
                    this.selectedPaymentPlans
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    isBoardingCounts,
                    this.isBoardingSearchFilters?.filterData,
                    this.selectedIsBoarding
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    isFinancialAidCounts,
                    this.isFinancialAidSearchFilters?.filterData,
                    this.selectedIsFinancialAid
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    isChildOfSchoolStaffCounts,
                    this.isChildOfSchoolStaffSearchFilters?.filterData,
                    this.selectedIsChildOfSchoolStaff
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    isScholarshipCounts,
                    this.isScholarshipSearchFilters?.filterData,
                    this.selectedIsScholarship
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    busPaidByCounts,
                    this.busPaidBySearchFilters?.filterData,
                    this.selectedBusPaidBy
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    lunchPaidByCounts,
                    this.lunchPaidBySearchFilters?.filterData,
                    this.selectedLunchPaidBy
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    tuitionPaidByCounts,
                    this.tuitionPaidBySearchFilters?.filterData,
                    this.selectedTuitionPaidBy
                );

                this.searchResultOptionsCountService.buildResultCountsForSelectedOptions(
                    enrollmentStatusCounts,
                    this.enrollmentStatusSearchFilters?.filterData,
                    this.selectedEnrollmentStatus
                );

                if (isInit) {
                    this.showFilter(this.installmentPlanSearchFilters);
                    this.showFilter(this.paymentPlanSearchFilters);
                    this.showFilter(this.isBoardingSearchFilters);
                    this.showFilter(this.isFinancialAidSearchFilters);
                    this.showFilter(this.isChildOfSchoolStaffSearchFilters);
                    this.showFilter(this.isScholarshipSearchFilters);
                    this.showFilter(this.busPaidBySearchFilters);
                    this.showFilter(this.lunchPaidBySearchFilters);
                    this.showFilter(this.tuitionPaidBySearchFilters);
                    this.showFilter(this.enrollmentStatusSearchFilters);
                }

            }

            showFilter(filter: any) {
                let notSpecifiedOrFalseCount = filter.filterData.find(f => f.id == 0)?.count;
                filter.showFilter = this.studentSearchResults.length == 0 || notSpecifiedOrFalseCount < this.studentSearchResults.length;
            }

            onReset() {
                this.resetSearchParameters();
                this.searchPerson();
            };

            selectAll() {
                this.studentSearchResults.forEach((x) => { this.studentSelected(x); });
            }

            studentSelected(item) {
                if (!item || item.isSelected) {
                    return;
                }

                if (!item.payers) {
                    this.billingCycleService.getStudentPayers(item.id).then(res => {
                        if (res.data) {
                            item.payers = this.payerModel.payerTypeId1 == this.payerTypesEnum.Family.id
                                ? res.data.familyPayers
                                : res.data.corporatePayers;
                            if (item.payers.length > 0) {
                                // Auto-select first billable payer, falling back to first payer.
                                var firstBillingContact = item.payers.find(x => x.isBillingContact);
                                if (firstBillingContact) {
                                    item.payerId = firstBillingContact.id
                                }
                                else {
                                    item.payerId = item.payers[0].id;
                                }
                            }
                        }
                    });
                }

                item.isSelected = true;
                this.outModel.selectedStudents.push(item);
            }

            deleteSelectedStudent(item) {
                const selectedStudentIndex = this.outModel.selectedStudents.findIndex(p => p.id == item.id);
                if (selectedStudentIndex == -1) {
                    return;
                }

                item.isSelected = false;
                this.outModel.selectedStudents.splice(selectedStudentIndex, 1);
            }

            deleteAllSelected() {
                this.outModel.selectedStudents.forEach(x => { x.isSelected = false; });
                this.outModel.selectedStudents = [];
            }
        }
    });
