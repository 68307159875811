angular
    .module('ccaSignUpType.services.ccaSignUpTypeService', [])
    .factory('ccaSignUpTypeService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebOrganisationCcaSignUpType/';

            var service = {};

            service.getCcaSignUpTypesAvailableForTransport = function () {
                return $http.get(urlBase + 'GetCcaSignUpTypesAvailableForTransport').then(function (response) {
                    return response.data;
                });
            };

            return service;
        }
    ]);