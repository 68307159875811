'use strict';

angular.module('balanceAdmin.components.transactionViewUibContainer',
    [
        'balanceAdmin.components.transactionView',
        'shared.components.sbModalHeader'
    ])
    .component('transactionViewUibContainer',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/components/transaction-view-uib-container.template.html',
            controller: function () {

                this.accountTypeId = 0;
                this.accountOwnerId = 0;
                this.showIncludeSource = false;

                this.$onChanges = function (changes) {
                    if (changes.resolve && changes.resolve.currentValue) {
                        this.accountTypeId = changes.resolve.currentValue.accountTypeId;
                        this.accountOwnerId = changes.resolve.currentValue.accountOwnerId;
                        this.showIncludeSource = changes.resolve.currentValue.showIncludeSource;
                    }
                }.bind(this);

                this.close = function () {
                    this.modalInstance.close();
                }.bind(this);
            }
        });