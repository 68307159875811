import { UiClasses } from '@sb-shared/types/ui-classes';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Conditions } from './condition';
import { Options } from "./filter";
import { IconStyle } from '@sb-shared/types/icon-style';
import { Button } from '@sb-shared/models/UI/buttons';

// Handle click of table cell
export interface TableClickEvent {
    // Column id of clicked cell
    columnId?: string;
    // Row index of clicked cell
    rowIndex?: number;
    // Index of the dropdownItem within the row
    dropdownIndex?: number;
}

// Handle updating a value in the table
export interface TableUpdateEvent {
  // Column of cell to update
  columnId?: string;
  // Id of row object to update
  rowId: number;
  // New value to set
  value: any;
}

// Table column defined in component
export interface TableColumn {
    // Column id - related to object property in array
    id?: string;
    // Columns name to show in th and in filter if isFilterable
    name?: string;
    // Not currently used
    dataId?: string;
    // Label to show in filter, else defaults to name
    filterLabel?: string;
    // Whether to include filter for this column
    isFilterable?: boolean;
    // Assign value to this field to identify it is a checkbox filter, set to true will be checked by default, false will be unchecked
    isInclude?: boolean;
    // Whether filter requires a request to endpoint
    doTriggerReload?: boolean;
    // Default icon for values in this column
    iconName?: string;
    // Default icon class for values in this column
    iconClass?: string;
    // Override style of icon
    iconStyle?: IconStyle;
    // Default icon tooltip for values in this column
    iconTooltip?: string;
    // Default icon tooltip prefix for values in this column
    iconTooltipPrefix?: string;
    // Default cell class for cells in this column
    cellClass?: string;
    // Whether this is the default column to sort on
    isDefaultSort?: boolean;
    // Whether to show this column in semi bold
    semiBold?: boolean;
    // Date format for cells in this columns
    dateFormat?: string;
    // Cell type - from tableProperties.CellTypes
    cellType?: number;
    // Whether this column controls row style - eg a UI value of success makes the whole row green
    isRowState?: boolean;
    // Whether column can be sorted/ordered by value
    isSortable?: boolean;
    // Whether the searchbox above the table checks this column for matches
    isSearchable?: boolean;
    // Whether searching will require the exact value to be matched or just that it contains that value
    searchForExactMatch?: boolean;
    // Whether this column should be summed in the total row of the table
    showTotal?: boolean;
    // Label for 'all' option in select
    allLabel?: string;
    // Whether this column does special stuff - ie one of the SpecialColumns below
    isSpecial?: boolean;
    // Whether to group results by this property - eg sport type in sports portal
    isGroupedBy?: boolean;
    // Whether to group tables by this value - eg isFavourite in sports portal
    isTrueGroupedBy?: boolean;
    // Whether to hide this column
    isHidden?: boolean;
    // Hide column if filter value is falsy
    isHiddenIfFalse?: boolean;
    // Set options for column filter select - otherwise generated from results
    options?: Options;
    // Set items for column, used to show text/icon based on item id and result value - also used as options
    items?: TableColumns;
    // Whether to show a false value in cells
    showFalse?: boolean;
    // Tooltip to show in cell
    tooltip?: string;
    //
    isFilterGrouping?: boolean;
    displayIf?: Conditions;
    otherFilterRequirements?: Conditions;
    filterGroups?: any[];
    groupSortOrder?: number;
    isMultiOption?: boolean;
    doNotTranslateOptions?: boolean;
    isMultiValue?: boolean;
    // Whether the field is copied to clipboard on click.
    isCopy?: boolean;
    disableAllOption?: boolean;
    defaultValue?: string | number;
    chartType?: string;
    isHiddenWhenSelected?: boolean;
    dropdownSettings?: IDropdownSettings;
    keepOpen?: boolean;
    isEditable?: boolean;
    isCheckboxList?: boolean;
    areValuesTranslated?: boolean;
    // Used for specialtype edit & viewdetail
    buttons?: Button[];
    // Used as a callback to determine whether a specific row cell should be visible or not (for Special column types with buttons, primarily)
    isVisibleFunction?: (param?:any) => void;
    // For numeric/currency columns: suppress display of zero values
    hideIfZero?: boolean;
    startDate?: Date;
    endDate?: Date;
}

export interface TableCell {
  label: string;
  iconName: string;
  className: UiClasses;
  iconClass: UiClasses;
}

export declare type TableColumns = TableColumn[];
export declare type TableCells = TableCell[];

export const tableProperties = {
  DefaultRefreshTimeout: 20000,
  CellTypes: {
    Text: 1,
    Badge: 2,
    Button: 3,
    Currency: 4,
    Popover: 5,
    Numeric: 6,
    DateRange: 7
  },
  // Going to infer this from properties
  // Layouts: {
  //     MasterDetail: 1,
  //     Collapse: 2,
  //     ShowHide: 3
  // },
  SpecialColumns: {
    filter: {
      id: 'filter',
      name: 'SB_Filter',
      isSpecial: true
    },
    viewDetail: {
      id: 'viewDetail',
      name: 'SB_Details',
      isSpecial: true
    },
    edit: {
      id: 'edit',
      name: 'SB_Edit',
      isSpecial: true
    },
    isFavourite: {
      id: 'isFavourite',
      name: 'Favourites',
      iconName: 'favourite',
      iconClass: 'text-gold',
      isSpecial: true
    },
    avatar: {
      id: 'photoUrl',
      isSpecial: true
    },
    isSelected: {
      id: 'checkbox',
      isSpecial: true
    }
  }
};
