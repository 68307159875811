'use strict';

angular.module('receptionModule').component('receptionJoinRequests', {

    templateUrl: '/Scripts/app/reception/controllers/reception-joinrequests.template.html?v=', // + window.EveryBuddy.Version,
    controller:
    [
        '$scope',
        function ($scope) {

        }
    ]});