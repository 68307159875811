import { Moment, isMoment } from 'moment';
import { isDebug } from './is-debug';

/**
 * Converts the input string to UTC format by appending 'Z' if it doesn't already end with 'Z'.
 * @param input - The input string to be converted to UTC format.
 * @returns The input string in UTC format.
 */
export const asUtc = (input: string): string => {
  if (input && typeof input === 'string' && !input?.endsWith('Z')) {
    input += 'Z';
  }
  return input;
};

/**
 * Retrieves the timezone offset for the current organisation.
 * @returns The timezone offset in the format '±HHMM' (e.g., '+0530' or '-0800').
 */
export const getOrgTimezoneText = (basedOn: Date = new Date()) => {
  const timeZone = window.EveryBuddy.CurrentOrganisationTimeZone;
  const longOffsetFormatter = new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: 'longOffset' });
  const longOffsetString = longOffsetFormatter.format(basedOn); // parse offset with the date based on, result will be something like '2/28/2013, GMT-05:00'
  const gmtOffset = longOffsetString.split('GMT')[1];
  const result = gmtOffset.replace(':', '');

  return result || '+0000';
};

/**
 * Gets a timezone offset in minutes.
 * Taken from https://stackoverflow.com/a/68593283/1053620
 * @param timeZone IANA timezone
 * @param date Date to get the offset for
 * @returns Number of minutes
 */
export const getTimeZoneOffsetMinutes = (timeZone: string, date: Date): number => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));

  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

/**
 * Converts a date and time in the organisation timezone to the UTC equivalent
 * @param orgDate Date and time in the organisation timezone. Any attached timezone information will be ignored.
 * @returns UTC date
 */
export const orgDateTimeToUtc = (orgDate: Moment | Date | string): Date => {
  const localJsDate = momentToDate(orgDate);
  const jsDate = new Date(
    Date.UTC(
      localJsDate.getFullYear(),
      localJsDate.getMonth(),
      localJsDate.getDate(),
      localJsDate.getHours(),
      localJsDate.getMinutes(),
      localJsDate.getSeconds(),
      localJsDate.getMilliseconds()
    )
  );
  const timeZoneOffsetMinutes = getTimeZoneOffsetMinutes(window.EveryBuddy.CurrentOrganisationTimeZone, jsDate);

  jsDate.setMinutes(jsDate.getMinutes() - timeZoneOffsetMinutes);

  return jsDate;
};

/**
 * Converts a Moment object, Date object, or string to a Date object.
 * @param input - The input value to be converted.
 * @returns The converted Date object.
 */
export const momentToDate = (input: Moment | Date | string): Date => {
  if (isMoment(input)) {
    return input.toDate();
  }

  if (typeof input === 'string') {
    const result = new Date(input);

    if (result.toString() === 'Invalid Date') {
      console.warn(`Invalid date string: ${input}`);
    }
    return result;
  }

  return input;
};

/**
 * Converts moment objects to Date objects before invoking the original method.
 * @param target - The target object.
 * @param propertyName - The name of the property.
 * @param descriptor - The property descriptor.
 * @returns The modified property descriptor.
 */
/**
 * Converts moment objects to Date objects in the input parameters of a method.
 *
 * @param target - The target object.
 * @param propertyName - The name of the property.
 * @param descriptor - The property descriptor.
 * @returns The modified property descriptor.
 */
export function momentParamsConverter(target, propertyName: string, descriptor: PropertyDescriptor) {
  // Store Original Method Implemetation
  const originalMethod = descriptor.value;

  // Now, over-write the original method
  descriptor.value = function (...args) {
    isDebug('converterIO') && console.log(`Input params for method ${propertyName}.`, { ...args });

    // Convert moment objects to Date objects
    args = args.map(arg => (isMoment(arg) ? momentToDate(arg) : arg));

    isDebug('converterIO') && console.log(`Output params for method ${propertyName}.`, { ...args });

    // Call original function
    const result = originalMethod.apply(this, args);

    return result;
  };

  return descriptor;
}

/**
 * Represents a type that can be either a Moment object or a Date object.
 * TODO: Should be removed once the moment library is removed.
 */
export type MomentOrDate = Moment | Date;
