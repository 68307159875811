import { formatDate, FormStyle, getLocaleDayNames, getLocaleFirstDayOfWeek, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MomentOrDate, momentToDate } from '@sb-helpers';
import { Time } from '@sb-shared/models/time';
import { isAfter, startOfSecond } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  currentLocale: string;
  dateTimeFormat = 'YYYY-MM-DDThh:mm:ss';

  constructor(private cookie: CookieService) {

    try {
      this.currentLocale = JSON.parse(this.cookie.get('Anonymous_Default_Language')); // Avoid LocaleService circular dependency
    }
    catch (ex) {
      this.currentLocale = 'en-gb';
    }
  }

  getMonthName(date: Date) {
    const monthNames = getLocaleMonthNames(this.currentLocale, FormStyle.Standalone, TranslationWidth.Wide);

    return monthNames[date.getMonth() - 1];
  }

  getShortMonthName(date: Date) {
    const shortMonthNames = getLocaleMonthNames(this.currentLocale, FormStyle.Standalone, TranslationWidth.Abbreviated);

    return shortMonthNames[date.getMonth() - 1];
  }

  getDayName(date: Date) {
    const dayNames = getLocaleDayNames(this.currentLocale, FormStyle.Standalone, TranslationWidth.Wide);

    return dayNames[date.getDay()];
  }

  getShortDayName(date: Date) {
    const shortDayNames = getLocaleDayNames(this.currentLocale, FormStyle.Standalone, TranslationWidth.Abbreviated);

    return shortDayNames[date.getDay()];
  }

  /**
   * Returns an object containing various labels for the given date.
   * @param date - The date for which to generate the labels.
   * @returns An object with the following properties:
   * - monthName: The full name of the month.
   * - shortMonthName: The abbreviated name of the month.
   * - dayName: The full name of the day of the week.
   * - shortDayName: The abbreviated name of the day of the week.
   */
  getDateLabels(date: Date) {
    const result = {
      monthName: this.getMonthName(date),
      shortMonthName: this.getShortMonthName(date),
      dayName: this.getDayName(date),
      shortDayName: this.getShortDayName(date)
    };

    return result;
  }

  /**
   * Checks if the given date is the end of the week.
   * @param date - The date to check.
   * @returns A boolean indicating whether the given date is the end of the week.
   */
  isEndOfWeek(date: Date) {
    const firstDayOfWeek = getLocaleFirstDayOfWeek(this.currentLocale);
    const dayIndex = date.getDay();

    return (firstDayOfWeek === 0 && dayIndex === 6) || dayIndex === firstDayOfWeek - 1;
  }

  secondsToClock(value: number): string {
    return new Date(value * 1000).toISOString().substr(11, 8);
  }

  formatDate(date: Date | string, format: string): string {
    return formatDate(date, format, this.currentLocale);
  }

  getShortDate(date: Date | string): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString(this.currentLocale, { dateStyle: 'short' });
  }

  combineDateAndTime(date: Date, time: Time): Date {
    date.setHours(time.hour);
    date.setMinutes(time.minute);

    return new Date(date);
  }

  sortByDate<T>(array: T[], property?: string): T[];
  sortByDate<T>(array: T[], property?: (el: T) => MomentOrDate | string): T[];
  sortByDate<T>(array: T[], property?: string | ((el: T) => MomentOrDate | string)): T[] {
    if (!property || typeof property === 'string') {
      return this.sortDateByPropertyName(array, property as string);
    }
    return this.sortDateByAccessor(array, property);
  }

  /**
   * Removes the seconds from the given MomentOrDate object and returns a Date object.
   * @param input - The MomentOrDate object from which to remove the seconds.
   * @returns A Date object with the seconds removed.
   */
  removeSeconds(input: MomentOrDate): Date {
    return startOfSecond(momentToDate(input));
  }

  /**
   * Converts an NgbDateStruct object to a Date object.
   * @param date - The NgbDateStruct object to be converted.
   * @returns A Date object representing the same date as the NgbDateStruct.
   */
  convertNgbDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  private sortDateByPropertyName<T>(array: T[], property?: string): T[] {
    return array?.sort((a, b) => {
      if (property) {
        if (a[property] && b[property]) {
          a = a[property];
          b = b[property];
        }
      }
      return isAfter(a?.toString(), b?.toString()) ? 1 : a?.toString() === b?.toString() ? 0 : -1;
    });
  }

  private sortDateByAccessor<T>(array: T[], accessor: (el: T) => MomentOrDate | string): T[] {
    return array.sort((a, b) => {
      const first = momentToDate(accessor(a));
      const second = momentToDate(accessor(b));
      return isAfter(first, second) ? 1 : first.getTime() === second.getTime() ? 0 : -1;
    });
  }
}
