"use strict";

angular.module('shared.components.teamEventMedical', [
    'events.services.eventsService',
    'shared.components.sbIcon',
    'shared.directives.sbPrint',
    'teams.services.teamService'
])
    .component('teamEventMedical',
        {
            bindings: {
                teamId: '<',
                calendarEventId: '<',
                eventTitle: '<',
                showTitleBar: '<'
            },
            templateUrl: '/Scripts/app/shared/components/team-event-medical.template.html',
            controller: [
                'teamService',
                'eventsService',
                function controller(teamService, eventsService) {

                    this.$onChanges = function (changes) {

                        if (changes.teamId && changes.teamId.currentValue) {
                            this.loadMedicalDataForTeam();
                        }

                        if (changes.calendarEventId && changes.calendarEventId.currentValue) {
                            this.loadMedicalDataForEvent();
                        }
                    };

                    this.medicalData = [];
                    this.loading = false;
                    var modes = {
                        None: 0,
                        ByEvent: 1,
                        ByTeam: 2
                    };
                    this.mode = modes.None;

                    var self = this;

                    this.loadMedicalDataForTeam = function () {

                        this.mode = modes.ByTeam;
                        this.loading = true;

                        teamService
                            .getMedicalDataForTeam(this.teamId)
                            .then(function (data) {

                                self.loading = false;

                                if (!data.isError) {
                                    self.medicalData = data;
                                } else {
                                    console.error('Failure in GetMedicalDataForTeam', data.errorData);
                                }

                            });
                    }.bind(this);

                    this.loadMedicalDataForEvent = function () {

                        this.mode = modes.ByEvent;
                        this.loading = true;

                        eventsService
                            .getMedicalDataForEvent(this.calendarEventId)
                            .then(function (data) {

                                self.loading = false;

                                if (!data.isError) {
                                    self.medicalData = data;
                                } else {
                                    console.error('Failure in GetMedicalDataForEvent', data.errorData);
                                }

                            });
                    };

                    this.exportReport = function () {

                        if (this.mode == modes.ByEvent) {
                            eventsService.getMedicalDataForEventCsv(this.calendarEventId);
                        } else {
                            teamService.getMedicalDataForTeamCsv(this.teamId);
                        }
                    };

                    this.printTemplate = '/Scripts/app/shared/components/team-event-medical.print.html';
                }
            ]
        });
