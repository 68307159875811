// start:ng42.barrel
import './balanceAdmin.service';
import './balanceAdminOld.service';
import './components';
import './dashboard';
import './dashboard/components';
import './massAdjustmentGroup';
import './massAdjustmentGroup/components';
import './massAdjustmentStudent';
import './massAdjustmentStudent/components';
import './quickCharge';
import './quickCharge/components';
import './recordPayment';
import './recordPayment/components';
import './reports';
import './reports/components';
import './summary';
import './summary/components';
// end:ng42.barrel

