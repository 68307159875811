'use strict';

angular.module('balanceAdmin.quickCharge.components.quickChargeFilter',
    [])
    .component('quickChargeFilter',
        {
            bindings: {
                searchFilters: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/quickCharge/components/quick-charge-filter.template.html',
            controller:
                [
                    'groupService',
                    function (groupService) {

                        this.$onChanges = function (changes) {
                        }

                        this.$onInit = function () {
                        }

                        this.getGroups = groupService.getGroupsByPartialName;
                        this.getGroupArgs = [
                            false // whether or not to include archived groups. Always off for this view.
                        ];

                        this.onSelectGroup = function ($item) {
                            this.searchFilters.groupId = $item.id;
                            this.searchFilters.groupName = $item.name;
                            this.searchFilters.isTeam = $item.isTeam;
                        }.bind(this);
                    }
                ]

        });