import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@sb-core/services/toast.service';
import { defaultEditorConfig, EditorConfig } from '@sb-shared/models/UI/editor-config';
import { FroalaOptions } from '@sb-shared/models/UI/froala-editor';
import { FroalaImageUploadHttpConfigService } from '@sb-shared/services/froala-image-upload-http-config.service';
import { LocaleService } from '@sb-shared/services/locale.service';
import { environment } from './../../../../environments/environment.test1';

@Component({
  selector: 'sb-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {
  @Input() id: string;
  @Input() rows?= 3;
  @Input() placeholder?= '';
  @Input() height: number;
  @Input() isRequired: boolean;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() model: string;
  @Input() form: UntypedFormGroup;
  @Input() editorConfig: EditorConfig;
  editorOptions: Partial<FroalaOptions> = {
    toolbarButtons: [],
    attribution: false,
    charCounterCount: false
  };
  @Output() modelChange = new EventEmitter<string>();
  genericLanguageString: string;
  
  constructor(
    private locale: LocaleService,
    private toastSvc: ToastService,
    private translateSvc: TranslateService,
    private imageUploadHttpConfigSvc: FroalaImageUploadHttpConfigService
    ) {
    this.locale.getGenericLanguageCode().subscribe(genericLanguageString => {
      this.genericLanguageString = genericLanguageString;
      this.editorOptions.language = this.genericLanguageString;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const updateOptions = (newOptions: Partial<FroalaOptions>) => {
      this.editorOptions = {
        ...this.editorOptions,
        ...newOptions,
      };
    }
    if (changes.editorConfig) {
      // Allow manual setting of Froala options using 'froalaOptions' config property
      this.editorOptions = {...this.editorOptions, ...this.editorConfig?.froalaOptions};
    }
      // Apply froala options via each config property
      const config = { ...defaultEditorConfig, ...this.editorConfig };
      let buttons = [];
      let mobileButtons = [];
      const addButtons = (newButtons: string[], smOnly?: boolean) => {
        buttons = [...buttons, ...newButtons];
        if (!smOnly) {
          mobileButtons = [...mobileButtons, ...newButtons];
        }
      }
      const addMobileButtons = newButtons => mobileButtons = [...mobileButtons, ...newButtons];
      if (config.fontSize) addButtons(['fontSize']);
      if (config.fontFamily) addButtons(['fontFamily']);
      if (config.formatting) addButtons(['bold', 'italic', 'underline', 'strikeThrough', 'quote'], true);
      if (config.mobileFormatting) addMobileButtons(['bold']);
      if (config.lists) addButtons(['formatOL', 'formatUL'], true), updateOptions({ listAdvancedTypes: true });
      if (config.mobileLists) addMobileButtons(['formatOL', 'formatUL']), updateOptions({ listAdvancedTypes: true });
      if (config.justify) addButtons(['align'])
      if (config.tables) addButtons(['insertTable'])
      if (config.hyperlinks) addButtons(['insertLink'])
      addButtons(['|']);
      if (config.imageUpload) addButtons(['insertImage'])
      if (config.videoUpload) addButtons(['insertVideo'])
      if (config.undo) addButtons(['undo', 'redo'])
      if (config.editHtml) addButtons(['html'])
      if (config.fullScreen) addButtons(['fullscreen'])

      // fontSelection: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
      // editHtml: true,

      this.editorOptions = {
        key: environment.froalaLicenceKey,
        linkAutoPrefix: config.forceLinkPrefix ? 'http://' : null,
        attribution: false,
        charCounterCount: this.maxLength !== undefined,
        charCounterMax: this.maxLength,
        height: this.height,
        heightMin: 100,
        heightMax: 400,
        toolbarButtons: this.editorConfig ? buttons : [],
        language: this.genericLanguageString,
        quickInsertTags: [],
        videoUpload: config.videoUpload,
        imageUpload: config.imageUpload,
        imageUploadMethod: 'POST',
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        imageUploadURL: this.imageUploadHttpConfigSvc.getImageUploadApiUrl(),
        // Used for image upload
        requestHeaders: this.imageUploadHttpConfigSvc.getImageUploadRequestHeaders(),
        events: {
          // Error param is not used here as this is Froala's own error and is already handled / displayed before upload attempt by Froala.
          // If we get to this point, upload has been attempted and we are handling the error response from the server.
          'image.error': (error, response) => this.handleImageUploadError(response)
        },
        // toolbarButtonsXS: this.editorConfig ? mobileButtons : []
      }
      
    if (changes.placeholder) {
      updateOptions({ placeholderText: this.placeholder });
    }
    if (changes.maxLength) {
      updateOptions({ charCounterMax: this.maxLength });
    }
  };

  change() {
    if (!this.form) {
      this.modelChange.emit(this.model);
    }
  }

  private handleImageUploadError(response) {
    if (response) {
      const responseParser: any = (response) => {
        try {
          return JSON.parse(response);
        } catch (e) {
          return null;
        }
      };

      const responseObj = responseParser(response);

      let errorMsg = 'SB_Error_Saving';

      if (responseObj?.errors?.length > 0) {
        errorMsg = `${this.translateSvc.instant(responseObj.errors[0], responseObj.messageTranslationParameters)}`;
      }

      this.toastSvc.saveError(errorMsg);
    }
  }

}
