import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CardMenuItem } from '@sb-shared/models/UI/menu-item';

@Component({
  selector: 'sb-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss']
})
export class LinkCardComponent {
  @Input() target?= '_self';
  @Input() link: CardMenuItem;
  @Input() date: Date;
  @Input() isRow: boolean;
  @Input() isWide: boolean;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) { }

  onMainClick() {
    // onClick action defined by parent
    if (this.onClick.observers.length > 0) {
      this.onClick.emit();
    }
    if (this.link) {
      // Single action
      if (this.link.actions?.length > 0) {
        this.link.actions[0].onClick();
      }
      // Navigation within app
      if (this.link.route) {
        this.router.navigateByUrl(this.link.route);
      }
      // External navigation
      if (this.link.externalUrl) {
        window.open(this.link.externalUrl, this.target);
      }
    }
  }

  onActionClick(index: number) {
    this.link.actions[index].onClick()
  }

}
