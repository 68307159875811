'use strict';

angular.module('ccaavailableModule').component('ccaToJoin',  {
    templateUrl: "/Scripts/app/ccaAvailable/Details/cca-tojoin.template.html",
    controller: [
        '$scope', '$state', '$uibModal', '$filter', 'blobStoragePhotosService', 'availableRepository', 'simpleAlertService',
        function ($scope, $state, $uibModal, $filter, blobStoragePhotosService , availableRepository, simpleAlertService) {

            $scope.isCollapsed = false;
            $scope.saving = false;

            $scope.signUp = {};
            $scope.transportPupils = [];

            $scope.hasSelectedOption = false;

            availableRepository.getSignUp($state.params.signUpId).then(function (signUp) { $scope.signUp = signUp; });

            availableRepository.getSignUpGroupsToJoin($state.params.signUpId).then(function (signUpGroups) {
                $scope.groups = signUpGroups.availableGroupToJoinDto;
                $scope.transportPupils = signUpGroups.availablePupilDto;
                blobStoragePhotosService.addStudentPhotoUrlsToNestedArrays($scope.groups).then(function (array) {
                    $scope.groups = array;
                });
            });
            $scope.onChangeJoinStatus = function ()
            {
                $scope.hasSelectedOption = studentsAskedToJoin().length != 0;
            }

            //filters down Pupils to only those that set to selected with "asked to join" and returns an array unique by personId
            var studentsAskedToJoin = function () {
                var pupilsAskedToJoin = [];

                angular.forEach($scope.groups, function (group) {
                    angular.forEach(group, function (pupils) {
                        angular.forEach(pupils, function (pupil) {
                            if (pupil.askToJoin) {
                                pupilsAskedToJoin.push(pupil);
                            }
                        }, pupilsAskedToJoin)
                    }, pupilsAskedToJoin)
                }, pupilsAskedToJoin);

                //unique list of persons signing up who asked to join. (should always get one here, if not report back to UI)
                return $filter('unique')(pupilsAskedToJoin, 'personId', true);
            }.bind(this);

            $scope.sendRequest = function ()
            {
                $scope.saving = true;

                var studentsToJoin = studentsAskedToJoin();

                if (studentsToJoin.length === 0)
                {
                    $scope.hasSelectedOption = false;
                    simpleAlertService.errorAlert({ message: 'SB_Error_Saving' });
                    $scope.saving = false;
                    return;
                }
                //no transport the continue without calling transport modal
                if (!$scope.signUp.hasTransport || !$scope.signUp.transportTimeSlotAndDirection || $scope.signUp.completedTransport) {
                    $scope.saveJoinRequests();
                    return;
                }
                var modalInstanceTransport = $uibModal.open({
                    animation: true,
                    component: 'ccaTimeslot',
                    backdrop: 'static',
                    size: 'lg',
                    resolve:
                    {
                        ccaSignUp: function () {
                            return $scope.signUp;
                        }.bind(this),
                        signUpPersons: function ()
                        {
                            //filter down transport preferences to the unique array of "asked to join";
                            var filterTransportPupilsByAskedTojoin = [];

                            angular.forEach($scope.transportPupils, function (pupil) {

                                if ($filter('filter')(studentsToJoin, { 'personId': pupil.personId}, true).length > 0)
                                {
                                    filterTransportPupilsByAskedTojoin.push(pupil);
                                }

                            }, filterTransportPupilsByAskedTojoin);

                            return filterTransportPupilsByAskedTojoin;

                        }.bind(this),
                        transportTimeSlot: function () {
                            return $scope.signUp.transportTimeSlotAndDirection;
                        }.bind(this),
                        timeSlotTimeTableByOperatingDay: function () {
                            return availableRepository.getTimetableForTimeSlotCalendarGroupByDay(this.signUp.transportTimeSlotAndDirection.id, this.signUp.transportTimetableCalendarId)
                                .catch(function (error) {
                                    console.error('XHR getTimetableForTimeSlotCalendarGroupByDay failure : ' + error.status);
                                    //allow a 404, ccaTimeslot component handles this and will close the modal and T&C's will continue.
                                    if (error.status && error.status == '500') {
                                        simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                                        return;
                                    };
                                })
                        }.bind(this)
                    }
                });
                modalInstanceTransport
                    .result
                    .then(function (transportPupils)
                    {
                        $scope.signUp.completedTransport = true;
                        if (transportPupils)
                        {
                            $scope.transportPupils = transportPupils;
                        }

                        $scope.saveJoinRequests();

                    }, function ()
                    {
                        $scope.saving = false;
                        $scope.signUp.completedTransport = false;
                    });
            }

            $scope.saveJoinRequests = function ()
            {
                availableRepository.postJoinRequests($scope.groups, $scope.transportPupils)
                    .then(function () {
                        $state.go('ccaAvailable');
                    })
            }.bind(this)
        }
    ]});
