'use strict';

angular.module('transport.configuration.transportTimeslotEdit',
    [
        'ccaSignUpType.services.ccaSignUpTypeService',
        'transport.services.transportTimeSlotsService',
        'transport.services.transportDirectionsService',
        'shared.directives.sbCheckBox',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportTimeslotEdit',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/transport/configuration/timeSlots/transport-timeslot-edit.template.html',
            controller: [
                '$timeout',
                '$uibModal',
                'cachedLookupService',
                'ccaSignUpTypeService',
                'transportTimeSlotsService',
                'transportDirectionsService',
                'simpleAlertService',
                function transportTimeslotAddController($timeout,
                                                        $uibModal,
                                                        cachedLookupService,
                                                        ccaSignUpTypeService,
                                                        transportTimeSlotsService,
                                                        transportDirectionsService,
                                                        simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;
                    this.isArchiveOptionAvailable = false;
                    this.transportTimeSlotArchivingInfo = {};
                    this.numberOfTimetablesWhereUsed = 0;

                    this.populateHours = function ()
                    {
                        var i = 0;
                        var hours = [];

                        for (i = 0; i < 24; i++)
                        {
                            // populate hours, padding with leading zero.
                            hours.push({ id: i, label: ('0' + i).slice(-2) })
                        }
                        return hours;
                    }

                    this.populateMinutes = function ()
                    {
                        var i;
                        var minutes = [];

                        for (i = 0; i < 60; i++)
                        {
                            // populate minutes, padding with leading zero.
                            minutes.push({ id: i, label: ('0' + i).slice(-2) })
                        }

                        return minutes;
                    }

                    this.hourIsDisabled = function (hour)
                    {
                        return hour > -1 && (hour < this.minHour || hour > this.maxHour);
                    }

                    this.minuteIsDisabled = function (minute)
                    {
                        return minute > -1 && (minute < this.minMinute || minute > this.maxMinute);
                    }

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportDirections = null;

                    transportDirectionsService.getTransportDirections().then(function (data)
                    {
                        this.transportDirections = data;

                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            var params = this.resolve.params;
                            this.timeSlot = params.timeSlot;

                            transportTimeSlotsService.getTransportTimeSlotArchivingInfo(this.timeSlot.id)
                                .then(function (responseData)
                                {
                                    this.transportTimeSlotArchivingInfo = responseData;

                                    this.transportTimeSlotArchivingInfo.timetableCalendarsWhereUsed.forEach(function (calendar)
                                    {
                                        this.numberOfTimetablesWhereUsed += calendar.timetables.length;
                                    }.bind(this));

                                    cachedLookupService.isOrganisationSuperAdminOrTransportAdvancedAdmin()
                                        .then(function (response)
                                        {
                                            this.isArchiveOptionAvailable = response == 1;
                                        }.bind(this))
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        }.bind(this));

                                }.bind(this))
                                .catch(function (responseData)
                                {
                                    var message = {};

                                    if (responseData && responseData.data && responseData.data.Message)
                                    {
                                        message = { message: responseData.data.Message };
                                    }

                                    alertAndResetOkButton(message);
                                }.bind(this))
                        }
                    }

                    this.$onInit = function ()
                    {
                        this.minutes = this.populateMinutes();
                        this.hours = this.populateHours();
                        this.loadCcaSignUpTypesForTransport();

                        //configure the drop down control
                        this.dropdownSettings =
                            {
                                showUncheckAll: false,
                                showCheckAll: false,
                                checkBoxes: true,
                                smartButtonMaxItems: 3,
                                smartButtonTextConverter: function (itemText) { return itemText; }
                            };

                        this.dropdownEventEdit =
                            {
                                onSelectionChanged: function ()
                                {
                                }.bind(this)
                            }

                    }.bind(this);

                    this.loadCcaSignUpTypesForTransport = function ()
                    {
                        ccaSignUpTypeService.getCcaSignUpTypesAvailableForTransport()
                            .then(function (data)
                            {
                                if (!Array.isArray(data))
                                {
                                    simpleAlertService.errorAlert();
                                    return;
                                }

                                this.ccaSignUpTypes = data;

                            }.bind(this), function (error)
                            {
                                simpleAlertService.errorAlert(error);
                            }.bind(this));

                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    var closeModal = function (responseDto)
                    {
                        this.modalInstance.close(responseDto);
                    }.bind(this);

                    this.editTimeSlot = function ()
                    {
                        transportTimeSlotsService.updateTransportTimeSlot(this.timeSlot)
                        .then(function (data)
                        {
                            simpleAlertService.simpleAlert(
                                {
                                    title: 'SB_Saved',
                                    message: 'SB_Transport_Time_Slot_Changed',
                                    staticBackdrop: true
                                });
                            closeModal();
                        }.bind(this), function (error)
                        {
                            this.isSubmitted = false;
                            alertAndResetOkButton({ message: error.data.Message });
                        }.bind(this));
                    }.bind(this);

                    this.archiveTransportTimeSlot = function ()
                    {
                        var transportItemNameToArchive = this.timeSlot.name;

                        var confirmArchiveModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportConfirmArchiving',
                                size: 'md',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return { transportItemNameToArchive: transportItemNameToArchive };
                                    }
                                }
                            });

                        confirmArchiveModalInstance.result
                            .then(
                                () => {
                                    transportTimeSlotsService.archiveTimeSlot(this.timeSlot)
                                        .then(function (responseData)
                                        {
                                            if (responseData && responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Transport_TimeSlot_Archived',
                                                    message: 'SB_Transport_TimeSlot_Archived',
                                                    staticBackdrop: true
                                                });

                                                closeModal();
                                            }
                                            else if (responseData && !responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Error',
                                                    message: 'SB_Transport_Archive_Operation_Failed',
                                                    staticBackdrop: true
                                                });

                                                closeModal();
                                            }
                                            else
                                            {
                                                alertAndResetOkButton();
                                            }
                                        })
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        });
                                    resetSubmitButtons();
                                },
                                () => resetSubmitButtons()
                            );
                    }.bind(this);
                }
            ]
        });
