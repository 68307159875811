import { ZendeskArticle, ZendeskArticleResponse } from './../models/zendesk-article';
import { MenuService } from '@sb-shared/services/menu.service';
import { LocaleService } from '@sb-shared/services/locale.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  constructor(
    private httpClient: HttpClient,
    private menu: MenuService,
    private locale: LocaleService) { }
  
  getArticle(): Observable<ZendeskArticle> {
    return this.locale.getLanguageCode().pipe(switchMap(language => {
      return this.menu.getCurrentSubMenuItem().pipe(switchMap(menuItem => {
        const articleId = menuItem.helpArticleId;
        if (!articleId) {
          return of(null);
        }
        const url = `https://help.schoolsbuddy.com/api/v2/help_center/${language}/articles/${menuItem.helpArticleId}`;
        return this.httpClient.get(url).pipe(map((res: ZendeskArticleResponse) => res.article));
      }))
    }))
  }
}
