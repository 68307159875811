import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  remove(itemName: string) {
    localStorage.removeItem(itemName);
  }

  set(itemName: string, data: any) {
    if (data === undefined) {
      localStorage.removeItem(itemName);
      return;
    }
    const formattedData = typeof data === 'string' ? data : JSON.stringify(data);
    localStorage.setItem(itemName, formattedData);
  }

  get(itemName: string, asJson = true) {
    const data = localStorage.getItem(itemName);
    if (!data) {
      return null;
    }

    return asJson ? JSON.parse(data) : data;
  }

  clear() {
    localStorage.clear();
  }
}
