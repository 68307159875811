import { OrganisationService } from '@sb-shared/services/organisation.service';
import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sb-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit {

  @Input() isRequired: boolean;
  @Input() isDisabled: boolean;
  @Input() maxDecimalPlaces: number;
  @Input() allowZero: boolean;
  @Input() symbolPrefix: string;
  @Input() id: string;
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter();
  @Input() hidePrefix: boolean;
  @Input() form: UntypedFormGroup;
  @Input() step = 0.01;
  @Output() onBlur: EventEmitter<void> = new EventEmitter();
  min: number;
  lastValidValue: number;
  currencySymbol: string;
  currencyPattern = '';

  constructor(private organisation: OrganisationService) { }

  ngOnInit(): void {
    this.model = this.model || 0;
    this.setMin();
    this.organisation.getOrganisationCurrencySymbol().subscribe(symbol => {
      this.currencySymbol = symbol;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      this.lastValidValue = this.model;
    }
  }

  setMin() {
    this.min = this.allowZero ? 0 : this.step;
  }

  change() {
    setTimeout(() => {
      this.model = parseFloat(this.model.toFixed(2));
      if (this.model < this.min) {
        this.model = this.lastValidValue;
      }
      this.lastValidValue = this.model;
      this.modelChange.emit(this.model);
    })
  }

  blur() {
    this.onBlur.emit();
  }
}
