angular.module("userForm.components.capture",
        [
            'userform.services.userFormService',

            'pascalprecht.translate'
        ])
    .component('userFormCapture',
        {
            bindings: {
                person: '<',
                formId: '<',
                printable: '=',
                hideHeading: '<',
                canSaveForm: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/user-form/components/user-form-capture.template.html',

            controller: [
                '$translate',
                'simpleAlertService',
                'userFormService',
                function userFormCaptureController($translate, simpleAlertService, userFormService) {
                    this.loading = 0;
                    var self = this;

                    this.isLoading = function () { return self.loading > 0; };

                    this.printTemplate = '/Scripts/app/user-form/components/user-form-capture.print.html';

                    this.$onChanges = function(changes)
                    {
                        if (changes.person || changes.formId)
                        {
                            self.loadFormData();
                        }
                    };

                    this.$doCheck = function() {
                        if (self.userForm && self.personFormData && self.personFormData.capturedData) {
                            self.dataChanged(self.userForm.$valid);
                        }
                    }

                    this.loadFormData = function() {
                                        // if both the person and selected form are selected, then we can load the data
                        if (self.person && self.formId && self.person.personData) {

                            for (var i = 0; i < self.person.forms.length; i++) {
                                var aForm = self.person.forms[i];
                                if (aForm.organisationFormId === self.formId) {
                                    self.selectedFormTitle = aForm.title;
                                    self.dataEntry = aForm.description;
                                }
                            }
                            self.personFormData = null;
                            self.loading++;

                            userFormService.getPersonFormData(self.person.personData.id,
                                                              self.formId)
                                .then(function(data) {
                                    self.personFormData = data;

                                    if (self.personFormData.capturedData.length > 0) {
                                        for (var i = 0; i < self.personFormData.fields.length; i++) {
                                            var aField = self.personFormData.fields[i];
                                            var fieldId = aField.organisationFormFieldId;
                                            if (aField.fieldType === 4) {
                                                for (var j = 0; j < self.personFormData.capturedData.length; j++) {
                                                    var captured = self.personFormData.capturedData[j];
                                                    if (fieldId === captured.organisationFormFieldId) {
                                                        captured.userData = (captured.userData === "True");
                                                    }
                                                }
                                            } else if (aField.fieldType === 3) {
                                                for (var j = 0; j < self.personFormData.capturedData.length; j++) {
                                                    var captured = self.personFormData.capturedData[j];
                                                    if (fieldId === captured.organisationFormFieldId && !!captured.userData && captured.userData.length > 10) {
                                                        captured.userData = new Date(captured.userData.substring(0, 10));
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    self.loading--;
                                    self.dataChanged(self.userForm.$valid);
                                });
                        }


                    }

                    this.dataChanged = function(newValue) {
                        if (typeof (self.onChange()) === 'function' )
                        {
                            self.onChange()(newValue, self.personFormData.capturedData);
                        }
                    }


                    this.fieldType = [
                        { id: 0, name: "text" },
                        { id: 1, name: "text" },
                        { id: 2, name: "nmber" },
                        { id: 3, name: "date" },
                        { id: 4, name: "checkbox" },
                        { id: 5, name: "email" },
                        { id: 6, name: "textarea" },
                        { id: 7, name: "select" }
                    ];

                    this.updateFormData = function() {
                        userFormService.saveFormData(self.formId,
                                self.person.personData.id,
                                self.personFormData.capturedData)
                            .then(function(data) {
                                simpleAlertService.simpleAlert({
                                    title: 'Saved',
                                    message: 'Changes saved',
                                    windowSize: 'sm'
                                });
                            });
                    };
                }
            ]
        });
