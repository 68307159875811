<div class="input-group"
     [formGroup]="form">
    <span class="input-group-addon"
          id="{{fieldId}}-addon">
        <sb-select [(model)]="dialCountry"
                   [preset]="formElements.Tel"
                   (onChange)="onDialCountrySelectChanged($event)"
                   (onGetOptions)="setOptions($event)"
                   [isDisabled]="isDisabled || disableDialCountrySelect()"
                   id="{{fieldId}}-code"></sb-select>
    </span>
    <input type="{{formElements.Tel.typeLabel}}"
           maxlength="50"
           class="form-control"
           [(ngModel)]="model"
           [required]="isRequired"
           (change)="onChangeNumber()"
           [disabled]="isDisabled"
           [formControlName]="fieldId"/>
</div>