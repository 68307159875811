'use strict';

angular.module('shared.services.registerStateService', [
    'register.constants'
])
    .service('registerStateService', ['registerStateTypeEnum',
        function (registerStateTypeEnum) {
            var service = this;

            service.registerStateText = function (registerState) {
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return '';
                    case registerStateTypeEnum.Here:
                        return 'SB_Present';
                    case registerStateTypeEnum.NotHere:
                        return 'SB_Absent';
                    case registerStateTypeEnum.Late:
                        return 'SB_Late';
                    case registerStateTypeEnum.Excused:
                        return 'SB_Excused';
                }
            }

            service.registerStateClass = function (registerState) {
                var staticCSS = 'p-1 w-8-rem text-align-center text-1-rem';
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return staticCSS;
                    case registerStateTypeEnum.Here:
                        return staticCSS + ' btn-success';
                    case registerStateTypeEnum.NotHere:
                        return staticCSS + ' btn-danger';
                    case registerStateTypeEnum.Late:
                        return staticCSS + ' btn-warning';
                    case registerStateTypeEnum.Excused:
                        return staticCSS + ' btn-info';
                }
            }

            // The badge text differs from the non-badge text in that it uses SB labels where the (Yes) and (No) text is not included.
            service.getRegisterStateBadgeText = function (registerState) {
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return '';
                    case registerStateTypeEnum.Here:
                        return 'SB_Present_Plain';
                    case registerStateTypeEnum.NotHere:
                        return 'SB_Absent_Plain';
                    case registerStateTypeEnum.Late:
                        return 'SB_Late';
                    case registerStateTypeEnum.Excused:
                        return 'SB_Excused';
                    default: return '';
                }
            }

            service.getRegisterStateBadgeClass = function (registerState) {
                var badgeCSS = 'badge';
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return badgeCSS;
                    case registerStateTypeEnum.Here:
                        return badgeCSS + ' badge-success';
                    case registerStateTypeEnum.NotHere:
                        return badgeCSS + ' badge-danger';
                    case registerStateTypeEnum.Late:
                        return badgeCSS + ' badge-warning';
                    case registerStateTypeEnum.Excused:
                        return badgeCSS + ' badge-info';
                }
            }

            service.getRegisterStateIconName = function (registerState) {
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return '';
                    case registerStateTypeEnum.Here:
                        return 'tick'
                    case registerStateTypeEnum.NotHere:
                        return 'cross'
                    case registerStateTypeEnum.Late:
                        return 'clock'
                    case registerStateTypeEnum.Excused:
                        return 'minus';
                }
            }


            service.getRegisterStateDiaryIconName = function (registerState) {
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return '';
                    case registerStateTypeEnum.Here:
                        return 'tickCircle'
                    case registerStateTypeEnum.NotHere:
                        return 'crossCircle'
                    case registerStateTypeEnum.Late:
                        return 'clock'
                    case registerStateTypeEnum.Excused:
                        return 'minusCircle';
                }
            }

            service.getRegisterStateLabelIconName = function (registerState) {
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return '';
                    case registerStateTypeEnum.Here:
                        return 'checkCircle'
                    case registerStateTypeEnum.NotHere:
                        return 'crossCircle'
                    case registerStateTypeEnum.Late:
                        return 'clock'
                    case registerStateTypeEnum.Excused:
                        return 'minusCircle';
                }
            }

            service.getRegisterStateColorClass = function (registerState) {
                switch (registerState) {
                    case registerStateTypeEnum.None:
                        return 'neutral';
                    case registerStateTypeEnum.Here:
                        return 'success';
                    case registerStateTypeEnum.NotHere:
                        return 'danger';
                    case registerStateTypeEnum.Late:
                        return 'warning';
                    case registerStateTypeEnum.Excused:
                        return 'info';
                }
            }

            service.getRegisterStateTypeEnum = function () {
                return registerStateTypeEnum;
            }
        }
    ]);
