'use strict';

angular.module('cca.components.ccaChangeDayTimeDialog',
    [
        'shared.components.sbModalHeader',
        'shared.controllers.confirmationPopup',
        'shared.services.simpleAlertService',
    ])
    .component('ccaChangeDayTimeDialog',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/cca/components/cca-change-day-time-dialog.template.html',
            controller: class CcaChangeDayTimeDialogCtrl
            {
                $timeout: any;
                $translate: any;
                signUpRepository: any;
                simpleAlertService: any;
                isSubmitted: boolean = false;
                items: any;
                changes: any;
                signUpGroups: any;
                status: any;

                modalInstance: any;

                static $inject = ['$timeout', '$translate', 'signUpRepository', 'simpleAlertService'];

                constructor($timeout, $translate, signUpRepository, simpleAlertService)
                {
                    this.$timeout = $timeout;
                    this.$translate = $translate;
                    this.simpleAlertService = simpleAlertService;
                    this.signUpRepository = signUpRepository;

                    this.items = signUpRepository.getDaysOfWeek(true);
                    this.items.unshift(
                        {
                            id: -1,
                            name: 'SB_No_Change'
                        }
                    );
                }

                $onInit()
                {
                    this.changes = [];
                };

                $onChanges(changes)
                {
                    if (changes.modalInstance)
                    {
                        this.modalInstance = changes.modalInstance.currentValue;
                    }

                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.signUpGroups = []
                        let signUpGroup = changes.resolve.currentValue.signUpGroup;

                        signUpGroup.newCcaStartHour = signUpGroup.ccaStartHour;
                        signUpGroup.newCcaStartMin = signUpGroup.ccaStartMin;
                        signUpGroup.newCcaEndHour = signUpGroup.ccaEndHour;
                        signUpGroup.newCcaEndMin = signUpGroup.ccaEndMin;

                        signUpGroup.ccaStartHourMin = { h: signUpGroup.newCcaStartHour, m: signUpGroup.newCcaStartMin };
                        signUpGroup.ccaEndHourMin = { h: signUpGroup.newCcaEndHour, m: signUpGroup.newCcaEndMin };
                        signUpGroup.newCcaStartHourMin = { h: signUpGroup.newCcaStartHour, m: signUpGroup.newCcaStartMin };
                        signUpGroup.newCcaEndHourMin = { h: signUpGroup.newCcaEndHour, m: signUpGroup.newCcaEndMin };

                        let currentDayOfWeekItem = this.items.filter((item) =>
                        {
                            return item.id == signUpGroup.dayofWeek;
                        });

                        signUpGroup.dayOfWeekName = currentDayOfWeekItem[0].name;
                        signUpGroup.newDayofWeek = -1; // No Change

                        signUpGroup.formattedTimes = ("00" + signUpGroup.ccaStartHour).slice(-2) + ':' + ("00" + signUpGroup.ccaStartMin).slice(-2) + '-' + ("00" + signUpGroup.ccaEndHour).slice(-2) + ':' + ("00" + signUpGroup.ccaEndMin).slice(-2);

                        this.signUpGroups.push(signUpGroup);

                        signUpGroup.linkedGroups.filter((linkedGroup) =>
                        {
                            linkedGroup.title = signUpGroup.title;

                            linkedGroup.newCcaStartHour = linkedGroup.ccaStartHour;
                            linkedGroup.newCcaStartMin = linkedGroup.ccaStartMin;
                            linkedGroup.newCcaEndHour = linkedGroup.ccaEndHour;
                            linkedGroup.newCcaEndMin = linkedGroup.ccaEndMin;

                            linkedGroup.ccaStartHourMin = { h: linkedGroup.newCcaStartHour, m: linkedGroup.newCcaStartMin };
                            linkedGroup.ccaEndHourMin = { h: linkedGroup.newCcaEndHour, m: linkedGroup.newCcaEndMin };
                            linkedGroup.newCcaStartHourMin = { h: linkedGroup.newCcaStartHour, m: linkedGroup.newCcaStartMin };
                            linkedGroup.newCcaEndHourMin = { h: linkedGroup.newCcaEndHour, m: linkedGroup.newCcaEndMin };

                            let currentDayOfWeekItem = this.items.filter((item) =>
                            {
                                return item.id == linkedGroup.dayofWeek;
                            });

                            linkedGroup.dayOfWeekName = currentDayOfWeekItem[0].name;
                            linkedGroup.newDayofWeek = -1; // No Change

                            linkedGroup.formattedTimes = ("00" + linkedGroup.ccaStartHour).slice(-2) + ':' + ("00" + linkedGroup.ccaStartMin).slice(-2) + '-' + ("00" + linkedGroup.ccaEndHour).slice(-2) + ':' + ("00" + linkedGroup.ccaEndMin).slice(-2);

                            this.signUpGroups.push(linkedGroup);
                        });

                        this.status =
                        {
                            isItemOpen: new Array(this.signUpGroups.length),
                            isFirstDisabled: false
                        };
                        this.status.isItemOpen[0] = true;
                    }
                };

                dayOrTimeChanged(signUpGroup)
                {
                    this.$timeout(() =>
                    {
                        if ((signUpGroup.newDayofWeek == -1 || signUpGroup.newDayofWeek == signUpGroup.dayofWeek) &&
                            signUpGroup.ccaStartHourMin.h == signUpGroup.newCcaStartHourMin.h &&
                            signUpGroup.ccaStartHourMin.m == signUpGroup.newCcaStartHourMin.m &&
                            signUpGroup.ccaEndHourMin.h == signUpGroup.newCcaEndHourMin.h &&
                            signUpGroup.ccaEndHourMin.m == signUpGroup.newCcaEndHourMin.m)
                        {
                            return;
                        }

                        let existingChangesForGroup = this.changes.filter((change) =>
                        {
                            return change.id == signUpGroup.id;
                        });

                        let oldDayOfWeekItem = this.items.filter((item) =>
                        {
                            return item.id == signUpGroup.dayofWeek;
                        });

                        let newDayOfWeekItem = this.items.filter((item) =>
                        {
                            return item.id == signUpGroup.newDayofWeek;
                        });

                        let ccaStartMinFormatted = signUpGroup.ccaStartHourMin.m < 10 ? "0" + signUpGroup.ccaStartHourMin.m : signUpGroup.ccaStartHourMin.m;
                        let ccaEndMinFormatted = signUpGroup.ccaEndHourMin.m < 10 ? "0" + signUpGroup.ccaEndHourMin.m : signUpGroup.ccaEndHourMin.m;
                        let newCcaStartMinFormatted = signUpGroup.newCcaStartHourMin.m < 10 ? "0" + signUpGroup.newCcaStartHourMin.m : signUpGroup.newCcaStartHourMin.m;
                        let newCcaEndMinFormatted = signUpGroup.newCcaEndHourMin.m < 10 ? "0" + signUpGroup.newCcaEndHourMin.m : signUpGroup.newCcaEndHourMin.m;

                        if (existingChangesForGroup.length == 0)
                        {
                            this.changes.push(
                                {
                                    id: signUpGroup.id,
                                    oldDayOfWeek: signUpGroup.dayofWeek,
                                    oldDayOfWeekName: this.$translate.instant(oldDayOfWeekItem[0].name),
                                    newDayofWeek: signUpGroup.newDayofWeek,
                                    newDayOfWeekName: signUpGroup.newDayofWeek == -1
                                        ? this.$translate.instant(oldDayOfWeekItem[0].name)
                                        : this.$translate.instant(newDayOfWeekItem[0].name),
                                    oldStartHourMin: signUpGroup.ccaStartHourMin,
                                    newStartHourMin: signUpGroup.newCcaStartHourMin,
                                    oldEndHourMin: signUpGroup.ccaEndHourMin,
                                    newEndHourMin: signUpGroup.newCcaEndHourMin,
                                    oldFormattedTimes: ("00" + signUpGroup.ccaStartHourMin.h).slice(-2) + ':' + ccaStartMinFormatted + '-' + ("00" + signUpGroup.ccaEndHourMin.h).slice(-2) + ':' + ccaEndMinFormatted,
                                    newFormattedTimes: ("00" + signUpGroup.newCcaStartHourMin.h).slice(-2) + ':' + newCcaStartMinFormatted + '-' + ("00" + signUpGroup.newCcaEndHourMin.h).slice(-2) + ':' + newCcaEndMinFormatted
                                });
                        }
                        else
                        {
                            existingChangesForGroup[0].newDayofWeek = signUpGroup.newDayofWeek;
                            existingChangesForGroup[0].newDayOfWeekName = signUpGroup.newDayofWeek == -1
                                ? this.$translate.instant(oldDayOfWeekItem[0].name)
                                : this.$translate.instant(newDayOfWeekItem[0].name),
                                existingChangesForGroup[0].newStartHourMin = signUpGroup.newCcaStartHourMin,
                                existingChangesForGroup[0].newEndHourMin = signUpGroup.newCcaEndHourMin;
                            existingChangesForGroup[0].newFormattedTimes = ("00" + signUpGroup.newCcaStartHourMin.h).slice(-2) + ':' + newCcaStartMinFormatted + '-' + ("00" + signUpGroup.newCcaEndHourMin.h).slice(-2) + ':' + newCcaEndMinFormatted;
                        }

                    }, 200);
                }

                closeModal(responseDto)
                {
                    this.modalInstance.close(responseDto);
                };

                resetSubmitButtons()
                {
                    this.$timeout(() =>
                    {
                        this.isSubmitted = false;
                    }, 200);
                }

                alertAndResetOkButton(message)
                {
                    this.simpleAlertService.errorAlert(message);
                    this.resetSubmitButtons();
                };

                save()
                {
                    this.signUpRepository.updateDayAndTimesForGroups(this.changes)
                        .then((updatesSuccessful) =>
                        {
                            if (updatesSuccessful)
                            {
                                var response =
                                {
                                    status: 'updated'
                                };

                                this.closeModal(response);
                            }
                            else
                            {
                                this.alertAndResetOkButton({
                                    message: 'SB_An_Error_Occurred_Performing_Cca_Group_Updates'
                                });
                            }
                        })
                        .catch((err) =>
                        {
                            this.simpleAlertService.errorAlert();
                        });
                };

                cancel()
                {
                    let response =
                    {
                        status: 'cancelled'
                    };

                    this.closeModal(response);
                }
            }
        });
