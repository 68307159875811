angular.module('settingsModule')
    .service('serviceManager', class ServiceManagerService {
        httpCoreApi: any;
        settings: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            }
        }

        getServiceManager() {
            return this.httpCoreApi.get('KBLProvisioning', this.settings);
        };
    });
