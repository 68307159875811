"use strict";

angular.module('ccasignupModule').component('ccaCreate', {
    templateUrl: '/Scripts/app/ccaSignUp/Create/cca-create.template.html',
    controller: [
        '$scope',
        '$rootScope',
        '$window',
        '$state',
        '$filter',
        '$q',
        'availableRepository',
        'cachedLookupService',
        'groupService',
        'imagesUploadHandler',
        'organisationRepository',
        'signUpRepository',
        'simpleAlertService',
        'tinymceConfigHelper',
        'parentTeacherConferenceLocationTypes',
        function ($scope,
            $rootScope,
            $window,
            $state,
            $filter,
            $q,
            availableRepository,
            cachedLookupService,
            groupService,
            imagesUploadHandler,
            organisationRepository,
            signUpRepository,
            simpleAlertService,
            tinymceConfigHelper,
            parentTeacherConferenceLocationTypes) {

            // Check if user has come from containers page
            $rootScope.$on('$locationChangeStart', function (event, current, previous) {
                var splitUrl = previous.split('/')
                $scope.backToContainers = splitUrl[3] == 'CcaSignup#!' && splitUrl.length == 5;
            });

            var signUpId = $state.params.signUpId;
            var moduleSignUpTypeId = $state.params.moduleSignUpTypeId;

            if (!moduleSignUpTypeId) {
                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                $state.go("ccaSignUp");
                return;
            };

            $scope.ready = false;
            $scope.saving = false;
            $scope.failureMessage = null;
            $scope.selectedType = null;
            $scope.showNonSchoolTextFields = false;
            $scope.signUp = { organisationFormId: null };
            $scope.allTeams = [];
            $scope.ccaCalendarEventCategoryTypes = [];
            $scope.signUp.ccaSignUpTypeId = moduleSignUpTypeId;
            $scope.parentTeacherConferenceLocationTypes = parentTeacherConferenceLocationTypes;

            $scope.tinymceOptions_description = tinymceConfigHelper.getTinyMceConfig(
                {
                    tables: true,
                    hyperlinks: true,
                    imagesUploadHandler: imagesUploadHandler.uploadImage,
                    imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                });

            $scope.tinymceOptions_terms = tinymceConfigHelper.getTinyMceConfig(
                {
                    tables: true,
                    hyperlinks: true
                });

            $scope.SelectionPreferenceType = {
                FCFS: 1,
                Preferences: 2
            };

            $scope.selectionTypes = [
                {
                    id: $scope.SelectionPreferenceType.FCFS,
                    name: "SB_First_come_first_serve"
                },
                {
                    id: $scope.SelectionPreferenceType.Preferences,
                    name: "SB_Preferences"
                }
            ];

            $scope.timeSlotsAvailable = [];
            $scope.timetableCalendars = [];

            $scope.cancellationMinHourMinute = { h: 0, m: 1 };

            $scope.isOnlineParentTeacherConference = function (selectedType) {
                return !selectedType?.isOrganisationAllocated && selectedType?.supportSlots;
            }

            var promises = [];

            promises.push(organisationRepository.getCcaType(moduleSignUpTypeId));
            promises.push(signUpRepository.getTimeSlotsByCcaSignUpType(moduleSignUpTypeId));
            promises.push(cachedLookupService.organisationTypeId());
            promises.push(organisationRepository.get());
            promises.push(groupService.getAllGroups(false));
            promises.push(signUpRepository.getCcaForms());
            promises.push(availableRepository.getLocations());

            $q.all(promises).then(function (responses) {
                if (!responses || responses.length != 7 || !responses[0].data) {
                    $scope.failureMessage = 'SB_Error_loading';
                    return;
                }
                $scope.selectedType = responses[0].data;

                if (Array.isArray(responses[1])) {
                    $scope.timeSlotsAvailable = $filter('filter')(responses[1], { cCaSignUpTypeId: moduleSignUpTypeId }, true)
                }

                if (responses[2] === 2) //its a school
                {
                    $scope.showNonSchoolTextFields = false;
                } else {
                    $scope.showNonSchoolTextFields = true;
                }

                $scope.organisation = responses[3];
                $scope.allTeams = responses[4];
                $scope.allTeams.splice(0, 0, { id: null, name: 'SB_All' });

                $scope.ccaForms = responses[5];
                $scope.ccaForms.splice(0, 0, { id: null, name: '' });

                $scope.locations = [];
                $scope.locations.push({ id: null, title: 'Unspecified' });

                let newItems = [];
                let locationHttpResponse = responses[6];

                for (var key in locationHttpResponse) { newItems.push({ id: key, title: locationHttpResponse[key] }); }
                newItems = $filter('orderBy')(newItems, 'title');
                $scope.locations = $scope.locations.concat(newItems);

                signUpRepository
                    .getCalendarEventCategoryTypes($scope.selectedType.isPTC)
                    .then(data => {
                        $scope.ccaCalendarEventCategoryTypes = data;

                        setUpSignUpContainer();

                        $scope.ready = true;
                    });
            });

            $scope.timeSlotSelected = function (timeSlotId) {
                if (typeof timeSlotId !== 'undefined' && timeSlotId != null) {
                    signUpRepository.getCurrentTransportTimetableCalendarsForTimeSlot(timeSlotId)
                        .then(function (response) {
                            $scope.timetableCalendars = response;
                        }).catch(function () {
                            $scope.timetableCalendars = [];
                            $scope.failureMessage = 'SB_Error_loading';
                        });
                }
            }

            $scope.cancel = function () {
                if ($scope.backToContainers) {
                    $state.go("ccaSignUp.signUpModule", { moduleSignUpTypeId: moduleSignUpTypeId });
                } else {
                    $window.history.back();
                }
            }.bind(this);

            var setUpSignUpContainer = function () {
                if (signUpId) {
                    signUpRepository.getSignUp(signUpId)
                        .then(function (signUp) {
                            $scope.signUp = signUp;
                            $scope.signUp.periodFrom = new Date(signUp.periodFrom);
                            $scope.signUp.periodTo = new Date(signUp.periodTo);
                            $scope.defaultStartHourMin = { h: signUp.defaultStartHour, m: signUp.defaultStartMin }
                            $scope.defaultEndHourMin = { h: signUp.defaultEndHour, m: signUp.defaultEndMin }

                            if (signUp.previewDate.length >= 10) {
                                $scope.signUp.previewDate = new Date(signUp.previewDate.substring(0, 10));
                            }

                            $scope.signUp.openDate = moment.utc(signUp.openDate);
                            $scope.signUp.closeDate = moment.utc(signUp.closeDate);

                            if (signUp.reminderDate.length >= 10) {
                                $scope.signUp.reminderDate = new Date(signUp.reminderDate.substring(0, 10));
                            }

                            $scope.signUp.displayLocationId = $scope.signUp.displayLocationId
                                ? $scope.signUp.displayLocationId.toString()
                                : null;

                            var rhours = 0
                            var rminutes = 0

                            if ($scope.signUp.cancelBookingCutOffMins) {
                                var hours = $scope.signUp.cancelBookingCutOffMins / 60
                                rhours = Math.floor(hours);
                                var minutes = (hours - rhours) * 60;
                                rminutes = Math.round(minutes);
                            };

                            $scope.signUp.cancellationWindow = { h: rhours, m: rminutes };

                            if (typeof signUp.transportTimeSlotId !== 'undefined' && signUp.transportTimeSlotId != null) {
                                $scope.timeSlotSelected(signUp.transportTimeSlotId);
                            }

                        }).catch(function () {
                            $scope.saving = false;
                            $scope.failureMessage = 'SB_Error_loading';
                            $scope.signUp = null;
                        });
                }
                else {
                    $scope.creating = true;
                    $scope.signUp = {
                        selectionPreferenceTypeId: 2,
                        allowedPreferencesCount: 5,
                        ccaSignUpTypeId: moduleSignUpTypeId,
                        ccaSignUpResponsibilityTypeId: 2,
                        previewDate: new Date(),
                        reminderDate: new Date(),
                        periodFrom: new Date(),
                        openDate: moment.utc().startOf('day'),
                        closeDate: moment.utc().endOf('day'),
                        useOrganisationBlockDates: true,
                        maxBookableEventsPerGroup: $scope.selectedType.isPTC ? 1 : 0,
                        showSelectAllButton: false,
                        hideAvailabilityDates: false,
                        contactDetails: '',
                        whoCanBookDescription: '',
                        costDescription: '',
                        displayLocationId: null,
                        minHoursBeforeCancellationCutOff: null,
                        allowCancellation: false,
                        restrictClashes: true,
                        autoAdjustRefund: false,
                        ignoreDayOfWeek: false,
                        acceptVoucherPayments: false,
                        limitVoucherPaymentsToGroup: null,
                        openTimeHour: 0,
                        openTimeMin: 0,
                        closeTimeHour: 23,
                        closeTimeMin: 59,
                        calendarEventCategoryTypeId: null,
                        parentTeacherConferenceLocationTypeId: null,
                        cancelBookingCutOffMins: 0,
                        autoAdjustOnCancel: false,
                        allowJoinRequests: false,
                        showSendNewBookingAlertsOption: $scope.selectedType.isPTC || $scope.selectedType.isInstantBook,
                    };

                    $scope.defaultStartHourMin = { h: 15, m: 0 };
                    $scope.defaultEndHourMin = { h: 17, m: 0 };

                    var periodToDefault = new Date();
                    periodToDefault.setMonth(periodToDefault.getMonth() + 1);

                    $scope.signUp.periodTo = periodToDefault;
                }

                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                var afterTomorrow = new Date();
                afterTomorrow.setDate(tomorrow.getDate() + 1);
                $scope.events =
                    [
                        {
                            date: tomorrow,
                            status: "full"
                        },
                        {
                            date: afterTomorrow,
                            status: "partially"
                        }
                    ];
            }

            $scope.getDayClass = function (date, mode) {
                if (mode === "day") {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < $scope.events.length; i++) {
                        var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return $scope.events[i].status;
                        }
                    }
                }

                return "";
            };

            $scope.update = function (ccaSignUp) {
                $scope.saving = true;
                $scope.failureMessage = null;

                var entity = angular.copy(ccaSignUp);

                if (!entity.periodFrom) {
                    entity.periodFrom = $filter('date')(entity.openDate, 'yyyy-MM-dd');
                } else {
                    entity.periodFrom = $filter('date')(entity.periodFrom, 'yyyy-MM-dd');
                }

                if (!entity.periodTo) {
                    entity.periodTo = $filter('date')(entity.closeDate, 'yyyy-MM-dd');
                } else {
                    entity.periodTo = $filter('date')(entity.periodTo, 'yyyy-MM-dd');
                }

                if ($scope.selectedType.isOrganisationAllocated || !$scope.selectedType.supportSlots) {
                    entity.ParentTeacherConferenceLocationTypeId = null;
                }

                entity.defaultStartHour = $scope.defaultStartHourMin.h;
                entity.defaultStartMin = $scope.defaultStartHourMin.m;
                entity.defaultEndHour = $scope.defaultEndHourMin.h;
                entity.defaultEndMin = $scope.defaultEndHourMin.m;

                if (entity.cancellationWindow) {

                    var hours = entity.cancellationWindow.h,
                        minutes = entity.cancellationWindow.m,
                        timeInminutes = (hours * 60) + minutes;

                    entity.cancelBookingCutOffMins = timeInminutes;
                };

                signUpRepository
                    .post(entity)
                    .then(function (createdSignUpId) {
                        $scope.saving = false;
                        if ($scope.creating === true) {
                            if ($scope.selectedType.isOrganisationAllocated === true) {
                                $state.go("ccaDetails", { signUpId: createdSignUpId, moduleSignUpTypeId: moduleSignUpTypeId });
                            } else {
                                $state.go("ccaDetailsAA", { signUpId: createdSignUpId, moduleSignUpTypeId: moduleSignUpTypeId });
                            }
                        } else {
                            $state.go("ccaSignUp.signUpModule", { moduleSignUpTypeId: moduleSignUpTypeId });
                        }

                    }.bind(this))
                    .catch(function (err) {
                        $scope.saving = false;
                        $scope.failureMessage = 'SB_Error_Saving_Message';
                        const { Message: message } = { ...err.data };
                        simpleAlertService.errorAlert({ message });
                    });
            };

            $scope.onChangeSelectionPreferenceType = () => {
                delete $scope.signUp.allowedPreferencesCount;
            };

            $scope.waitingListOptions = [
                {
                    id: 1,
                    name: 'SB_Cca_Form_No_Waiting_List',
                    iconName: 'cross',
                    iconClass: 'text-warning'
                },
                {
                    id: 2,
                    name: 'SB_Cca_Form_Waiting_List',
                    iconName: 'tick',
                    iconClass: 'text-success'
                }
            ];
        }
    ]
});
