import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private httpClient: HttpClient) {}

  async isImage(src: string) {

    return new Promise((resolve) => { 

      const image = new Image();
      image.onerror = function() {
          resolve(false);
      };
      image.onload = function() {
          resolve(true);
      };
      image.src = src;
    });
  }

  getPhotoBackground(query: string) {
    return this.httpClient.get('https://api.unsplash.com/search/photos', {
      params: {
        query: query.toLowerCase(),
        per_page: 1,
        orientation: 'landscape',
        content_filter: 'high' // Filter safe images
      },
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Client-ID yNPDG7ranAtwG_XKMetRNxAgo4vVl9EU6WwKheO5YBk'
      })
    }).pipe(map((res: any) => {
      return res.results[0]?.urls.regular
      // return res.results.find(result => {
      //   return query.split(' ').some(term => {
      //     result.description?.indexOf(term) > -1 || 
      //     result.alt_description?.indexOf(term) > -1
      //   });
      // })?.urls.regular
    }))
  }
}
