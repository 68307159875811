

angular.module('settingsModule')
    .service('blockDatesService', class BlockDatesService {

        $http: any;
        toastService: any;

        urlBase: string;

        static $inject = ['$http', 'toastService'];

        constructor($http, toastService) {
            this.$http = $http;
            this.toastService = toastService;
            this.urlBase = '/webapi/WebOrganisationBlockDates/';
        }

        getOrganisationBlockDates() {
            return this.$http.get(this.urlBase + 'GetOrganisationBlockDates')
                .then(response => {
                    return response.data;
                })
                .catch((err: any) => {
                    this.toastService.getError();
                    console.log(err);
                });
        };

        saveOrganisationBlockDate(id, excludeFrom, excludeTo, reason) {
            return this.$http.post(this.urlBase + 'SaveOrganisationBlockDate',
                {
                    id: id,
                    excludeFrom: excludeFrom,
                    excludeTo: excludeTo,
                    reason: reason
                })
                .then(response => {
                    this.toastService.saveSuccess();
                    return response.data;
                })
                .catch((err: any) => {
                    this.toastService.saveError();
                    console.log(err);
                });
        };

        deleteOrganisationBlockDate(id) {
            return this.$http.post(this.urlBase + 'DeleteOrganisationBlockDate',
                {
                    id: id
                })
                .then(response => {
                    this.toastService.saveSuccess('SB_Deleted');
                    return response.data;
                })
                .catch((err: any) => {
                    this.toastService.saveError('SB_Error');
                    console.log(err);
                });
        };

    });
