'use strict';

angular.module('shared.services.arrayService', [])
    .service('arrayService',
        function () {

            var service = this;

            // Implementation from https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
            // Better method exists for ES6.
            service.uniqueBy = function (a, key) {
                var seen = {};
                return a.filter(function (item) {
                    var k = key(item);
                    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
                });
            }

            // Implementation from https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
            service.equals = function (a1, a2) {
                // if either array is a falsy value, return
                if (!a1 || !a2) {
                    return false;
                }

                // compare lengths - can save a lot of time
                if (a1.length != a2.length) {
                    return false;
                }

                for (var i = 0, l = a1.length; i < l; i++) {
                    // Check if we have nested arrays
                    if (a1[i] instanceof Array && a2[i] instanceof Array) {
                        // recurse into the nested arrays
                        if (!a1[i].equals(a2[i])) {
                            return false;
                        }
                    }
                    else if (a1[i] != a2[i]) {
                        // Warning - two different object instances will never be equal: {x:20} != {x:20}
                        return false;
                    }
                }
                return true;
            }

            service.updateArrayFromArray = function (sourceArray, destArray, keyField, fieldsToUpdate, childArraySettings) {

                for (var i = sourceArray.length; i--;) {
                    // find the existing entry in our current dataset
                    var newRecord = sourceArray[i];
                    var existingRecord = null;
                    for (var j = destArray.length; j--;) {
                        if (destArray[j][keyField] === newRecord[keyField]) {
                            existingRecord = destArray[j];
                            break;
                        }
                    }

                    if (existingRecord !== null) {
                        // update!
                        for (var k = fieldsToUpdate.length; k--;) {

                            if (Array.isArray(existingRecord[fieldsToUpdate[k]])) {

                                // special case for arrays- look up the settings
                                if (childArraySettings && childArraySettings[fieldsToUpdate[k]]) {
                                    var settings = childArraySettings[fieldsToUpdate[k]];
                                    // we have a handler- else, just blaster over it
                                    service.updateArrayFromArray(newRecord[fieldsToUpdate[k]], existingRecord[fieldsToUpdate[k]], settings.keyField, settings.fieldsToUpdate, settings.childArraySettings);
                                }
                                else {
                                    existingRecord[fieldsToUpdate[k]] = newRecord[fieldsToUpdate[k]];
                                }
                            }
                            else {
                                existingRecord[fieldsToUpdate[k]] = newRecord[fieldsToUpdate[k]];
                            }
                        }
                    }
                }
            };
            service.groupByDate = function(collection, property, reverse, format) {
                var dateFormat = format || 'YYYY-MM-DDTHH:mm:SS';
                // groups items by property, with item.title for each
                var i = 0, val, index,
                    values = [], result = [];
                for (; i < collection.length; i++) {
                    var date = collection[i][property];
                    collection[i].sortProperty = moment(date, dateFormat).utc().startOf('day').format();
                    val = collection[i].sortProperty;
                    index = values.indexOf(val);
                    if (index > -1)
                        result[index].push(collection[i]);
                    else {
                        values.push(val);
                        result.push([collection[i]]);
                    }
                }
                result.forEach(function (item) {
                    item.title = item[0].sortProperty;
                    if (reverse) {
                        item.reverse();
                    }
                });
                result = result.sort(function (a, b) { 
                    if (a.title > b.title) return 1;
                    if (a.title < b.title) return -1;
                    return 0;
                });
                if (reverse) {
                    result.reverse();
                }
                return result;
            };

            service.generateOptions = (array, addAllOption, idProp, nameProp) => {
                if (!Array.isArray(array)) {
                    console.log('Not array');
                    return array;
                }

                // Map option properties from list
                
                if (idProp && nameProp) {
                    const localeSort = (a, b) => a.name.localeCompare(b.name);
                    const newItems = array.map((item) => {
                        return {
                            ...item,
                            id: item[idProp],
                            name: item[nameProp]
                        };
                    });
                    const uniqueNewItems = service.uniqueBy(newItems, (item) => item.id);
                    uniqueNewItems.sort(localeSort);
                    array = uniqueNewItems;
                }
                const allOptionExists = array.find(option => !option.id || option.id === -1);
                const selectOptions = (addAllOption && !allOptionExists) ? [
                    {
                        id: undefined,
                        name: 'SB_All'
                    }
                ] : [];

                if (!array[0] || typeof array[0] !== 'string') {
                    return addAllOption ? [...selectOptions, ...array] : array;
                }

                // Generate options from index and string if string array
                
                return selectOptions.concat(array.map((item, index) => {
                    return {
                        id: index + 1,
                        name: item
                    };
                }));
            };
        });