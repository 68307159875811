angular.module('ccaAvailable.services.availableRepository',
    [
        'cca.constants',
    ])
    .factory('availableRepository', [
        '$http', '$q', 'bookingStatusEnum', 'ccaService',
        function ($http, $q, bookingStatusEnum, ccaService) {

            var urlBase = '/CcaAvailable/';

            var urlWebAPI = '/webapi/WebCcaAvailable/' //New functionality here web

            var availableRepository = {};

            availableRepository.get = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUps' + '?hash_id=' + new Date().getTime())
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getLocations = function () {
                return $http.get(urlBase + 'GetLocations')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            availableRepository.getSignUpTypes = function () {
                return $http.get(urlBase + 'GetSignUpTypes')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            availableRepository.getSignUp = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUp' + '?hash_id=' + (new Date().getTime()), { params: { signUpId: signUpId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getSignUpGroups = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroups', { params: { signUpId: signUpId, hash_id: new Date().getTime() } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getSignUpGroupsForPupil = function (signUpId, personId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroupsForPupil' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId, personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getSignUpGroupsToJoin = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpGroupsToJoin', { params: { signUpId: signUpId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getSignUpReviewGroups = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpReviewGroups' + '?hash_id=' + (new Date().getTime()),
                    { params: { signUpId: signUpId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getSignUpEvents = function (signUpId, pupilId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpEvents' + '?hash_id=' + (new Date().getTime()), { params: { signUpId: signUpId, pupilId: pupilId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.getSignUpBookedEvents = function (signUpId) {
                if (signUpId === null) return null;
                var deferred = $q.defer();
                $http.get(urlBase + 'GetSignUpBookedEvents' + '?hash_id=' + (new Date().getTime()), { params: { signUpId: signUpId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };


            availableRepository.post = function (groups, signUpId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SavePreferences', { dto: groups, signUpId: signUpId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.postJoinRequests = function (groups, transportPupils) {
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveJoinRequests', { dto: groups, availableTransportPupils: transportPupils })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.toggleBookings = function (bookings, pupils, signUpId) {
                var deferred = $q.defer();

                // strip down the dto for network performance (and to avoid json serialisation exception on server-side).
                bookings = ccaService.simplifyBookingsDto(bookings);

                $http.post(urlBase + 'ToggleBookings', { dto: bookings, pupils: pupils, signUpId: signUpId, isConfirmed: false })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.saveBookings = function (bookings, pupils, signUpId, capturedData, formId) {
                var deferred = $q.defer();

                // strip down the dto for network performance (and to avoid json serialisation exception on server-side).
                bookings = ccaService.simplifyBookingsDto(bookings);

                $http.post(urlBase + 'SaveBookings', { dto: bookings, pupils: pupils, signUpId: signUpId, capturedData: capturedData, formId: formId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.postToggleBooking = function (pupil, anEvent) {
                var deferred = $q.defer();
                $http.post(urlBase + 'ToggleBooking',
                    {
                        pupilId: pupil.personId,
                        eventId: anEvent.calendarEventId
                    })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.postToggleBookingLite = function (pupil, anEvent) {
                var deferred = $q.defer();
                $http.post(urlBase + 'ToggleBookingLite', {
                    pupilId: pupil.personId,
                    eventId: anEvent.calendarEventId
                })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.cancelAAConfirmedBooking = function (pupil, event) {
                return $http.post(urlBase + 'CancelBooking',
                    { personId: pupil.personId, eventId: event.calendarEventId })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (err) {
                        return {
                            isError: true
                        };
                    });
            };

            availableRepository.postConfirm = function (signUpId, notes, capturedData, formId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'Confirm', { signUpId: signUpId, notes: notes, capturedData: capturedData, formId: formId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };


            availableRepository.postConfirmMultiple = function (signUpId, notes, capturedData, formId, availableReviewDataDto) {
                var deferred = $q.defer();
                $http.post(urlBase + 'ConfirmMultiple', { signUpId: signUpId, notes: notes, capturedData: capturedData, availableReviewDataDto: availableReviewDataDto, formId: formId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.postUnconfirm = function (signUpId) {
                var deferred = $q.defer();
                $http.post(urlBase + 'Unconfirm', { signUpId: signUpId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            availableRepository.postPaymentAA = function (token, signUpEvents, paymentProvider) {
                var deferred = $q.defer();

                $http.post(urlBase + 'ChargeAAPayments', { token: token, dto: signUpEvents, paymentProviderId: paymentProvider })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });

                return deferred.promise;
            };

            availableRepository.getTimetableForTimeSlotCalendarGroupByDay = function (timeSlotId, transportTimetableCalendarId) {
                return $http.get(urlWebAPI + 'GetTimetableForTimeSlotCalendarGroupByDay',
                    {
                        params:
                        {
                            timeSlotId: timeSlotId,
                            transportTimetableCalendarId: transportTimetableCalendarId
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            availableRepository.doPendingBookingsExist = (availableEvents) => {
                if (!Array.isArray(availableEvents)) {
                    return false;
                }

                let pendingBookings = 0;

                availableEvents.forEach((availableEvent) => {
                    availableEvent.pupils.forEach((student) => {
                        if (student.selectionCount > 0 &&
                            student.status != bookingStatusEnum.Confirmed &&
                            student.status != bookingStatusEnum.Deleted &&
                            student.status != bookingStatusEnum.Cancelled) {
                            pendingBookings++;
                        }
                    });
                });

                return pendingBookings > 0;
            };

            return availableRepository;
        }
    ]);
