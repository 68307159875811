'use strict';

angular.module('shared.services.twitterService', [])
    .service('twitterService', class TwitterService {

        httpCoreApi: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
                this.httpCoreApi = httpCoreApi;
        }

        getTwitterHandles() {
                return this.httpCoreApi.get('organisation/Twitter', {
                        controllerType: this.httpCoreApi.controllerTypes.User
                });
        }

        getTwitterUrl(handle: string) {
                return `https://www.twitter.com/${handle}`;
        }

});
