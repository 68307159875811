import * as angular from "angular";

export const ng1StateDefinitionModule =   
angular.module('ng1StateDefinitionModule', [])
    .service('ng1StateDefinition', class Ng1StateDefinitionService {
        // This service is called from the ng1 modules, defining ng1 routes
        state(module: string, name: string, object: any) {
            const routes = window.EveryBuddy.ng1Routes || [];
            const setRoutes = routes => window.EveryBuddy.ng1Routes = routes;
            if (!routes) {
                setRoutes([]);
            }
            setRoutes([...routes, {
                name: name,
                url: object.url,
                module: module
            }])
        }
        });