'use strict';

angular.module('shared.services.portalTimetableRouteService', [])
    .factory('portalTimetableRouteService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebTransportTimetableRoute/';

            var service = {};

            service.getRouteTimeline = function (timetableId, timetableRouteId) {
                return $http.get(urlBase + 'GetTimetableRouteTimeline',{
                    params: {
                        timetableId: timetableId,
                        timetableRouteId: timetableRouteId
                    }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            return service;
        }
    ]);