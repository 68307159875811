'use strict';

angular.module('subjectClass.directives.subjectClassMemberList', [
    'subjectClass.services.subjectClassService'
]).directive('subjectClassMemberList', [
    'subjectClassService',
    function (subjectClassService) {
        function controller() {
            this.$onChanges = function (changes) {
                if (changes.subjectClassId) {
                    subjectClassService.getPeopleInSubjectClass(this.subjectClassId)
                        .then(function (data) {
                            this.members = data;
                        }.bind(this));
                }
            }.bind(this);
        };

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/subjectClass/directives/subjectclass-member-list.template.html',
            bindToController: {
                subjectClassId: '<'
            },
            scope: {}, // isolated scope
            controller: controller,
            controllerAs: 'ctrl'
        };
    }]);