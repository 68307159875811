'use strict';

angular.module('cca.components.ccaCancelOrDeleteEventsDialog',
    [])
    .component('ccaCancelOrDeleteEventsDialog',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/cca/components/cca-cancel-or-delete-events-dialog.template.html',
            controller: class CcaCancelOrDeleteEventsDialogCtrl
            {
                $filter: any;
                $timeout: any;
                $translate: any;
                $uibModal: any;

                modalInstance: any;

                signUpRepository: any;
                simpleAlertService: any;
                toastService;

                isSubmitted: boolean = false;
                isEventCancellationOrDeletionEnabled: boolean = false;
                signUpGroupsWithEventsForDeletionOrCancellation: any[] = [];
                signUpGroupsWithoutEventsForDeletionOrCancellation: any[] = [];
                eventsToBeCancelledOrDeleted: any[] = [];

                dateFrom: any;
                dateTo: any;
                minDate: any;

                selectedGroups: any[];

                matchedGroupIds: any[];
                groupsWithoutEligibleEvents: any[];

                days: any[] =
                    [
                        {
                            dayofWeek: 1,
                            name: 'SB_Monday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 2,
                            name: 'SB_Tuesday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 3,
                            name: 'SB_Wednesday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 4,
                            name: 'SB_Thursday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 5,
                            name: 'SB_Friday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 6,
                            name: 'SB_Saturday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 0,
                            name: 'SB_Sunday',
                            isSelected: false,
                            groupsInformation: null
                        }
                    ];

                allDays: any[] =
                    [
                        {
                            dayofWeek: 1,
                            name: 'SB_Monday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 2,
                            name: 'SB_Tuesday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 3,
                            name: 'SB_Wednesday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 4,
                            name: 'SB_Thursday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 5,
                            name: 'SB_Friday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 6,
                            name: 'SB_Saturday',
                            isSelected: false,
                            groupsInformation: null
                        },
                        {
                            dayofWeek: 0,
                            name: 'SB_Sunday',
                            isSelected: false,
                            groupsInformation: null
                        }
                    ];

                static $inject = ['$filter', '$timeout', '$translate', '$uibModal', 'signUpRepository', 'simpleAlertService', 'toastService'];

                constructor($filter,
                            $timeout,
                            $translate,
                            $uibModal,
                            signUpRepository,
                            simpleAlertService,
                            toastService)
                {
                    this.$filter = $filter;
                    this.$timeout = $timeout;
                    this.$translate = $translate;
                    this.$uibModal = $uibModal;

                    this.signUpRepository = signUpRepository;
                    this.simpleAlertService = simpleAlertService;
                    this.toastService = toastService;

                    this.initialiseDates();
                };

                initialiseDates()
                {
                    this.dateFrom = new Date();

                    this.dateTo = new Date();
                    this.dateTo.setDate(this.dateTo.getDate() + 7);

                    this.minDate = new Date();
                };

                $onChanges(changes)
                {
                    if (changes.resolve)
                    {
                        this.selectedGroups = changes.resolve.currentValue?.selectedGroups;

                        this.dateRangeChanged();
                    }
                };

                daySelectionChanged()
                {
                    this.$timeout(() =>
                    { this.enableOrDisableEventCancellationOrDeletion(), 200 });
                };

                dateRangeChanged()
                {
                    // Only display days within the selected date range and group(s) days
                    this.filterDaysAccordingToDateRange();

                    this.setUpDaysFromGroups();

                    this.enableOrDisableEventCancellationOrDeletion();
                }

                setUpDaysFromGroups()
                {
                    this.days = this.days.filter((day) =>
                    {
                        let linkedGroups = [];

                        let groupsForDay = this.selectedGroups.filter((group) =>
                        {
                            if (group.linkedGroups !== 'undefined' && Array.isArray(group.linkedGroups))
                            {
                                group.linkedGroups.forEach((linkedGroup) =>
                                {
                                    linkedGroups.push(linkedGroup);
                                });
                            }

                            return group.dayofWeek == day.dayofWeek;
                        });

                        let linkedGroupsForDay = linkedGroups.filter((linkedGroup) =>
                        {
                            return linkedGroup.dayofWeek == day.dayofWeek;
                        });

                        groupsForDay.forEach((groupForDay) =>
                        {
                            if (day.groupsInformation == null)
                            {
                                day.groupsInformation = [
                                    {
                                        ccaSignUpGroupId: groupForDay.id,
                                        groupName: groupForDay.title,
                                        showError: false
                                    }
                                ];
                            }
                            else
                            {
                                let exitingGroupInfo = day.groupsInformation.filter((groupInfo) =>
                                {
                                    return groupInfo.groupName == groupForDay.title;
                                });

                                if (exitingGroupInfo == null || exitingGroupInfo.length == 0)
                                {
                                    day.groupsInformation.push(
                                        {
                                            ccaSignUpGroupId: groupForDay.id,
                                            groupName: groupForDay.title,
                                            showError: false
                                        });
                                }
                            }
                        });

                        linkedGroupsForDay.forEach((linkedGroupForDay) =>
                        {
                            if (day.groupsInformation == null)
                            {
                                day.groupsInformation = [
                                    {
                                        ccaSignUpGroupId: linkedGroupForDay.id,
                                        groupName: linkedGroupForDay.title,
                                        showError: false
                                    }
                                ];
                            }
                            else
                            {
                                let existingGroupInfo = day.groupsInformation.filter((groupInfo) =>
                                {
                                    return groupInfo.groupName == linkedGroupForDay.title;
                                });

                                if (existingGroupInfo == null || existingGroupInfo.length == 0)
                                {
                                    day.groupsInformation.push(
                                        {
                                            ccaSignUpGroupId: linkedGroupForDay.id,
                                            groupName: linkedGroupForDay.title,
                                            showError: false
                                        });
                                }
                            }
                        });

                        return groupsForDay.length > 0 || linkedGroupsForDay.length > 0;
                    });
                }

                dateRangeError()
                {
                    return this.dateFrom > this.dateTo;
                }

                filterDaysAccordingToDateRange()
                {
                    let fromDate = new Date(this.dateFrom);
                    let toDate = new Date(this.dateTo);

                    if (fromDate > toDate)
                    {
                        this.days = [];
                        return;
                    }

                    var differenceInTime = toDate.getTime() - fromDate.getTime();
                    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

                    if (differenceInDays >= 7)
                    {
                        this.days = [...this.allDays]
                        return;
                    }

                    if (fromDate.getDay() > toDate.getDay())
                    {
                        this.days = this.allDays.filter((day) =>
                        {
                            return day.dayofWeek >= fromDate.getDay() || day.dayofWeek <= toDate.getDay();
                        });
                        return;
                    }

                    this.days = this.allDays.filter((day) =>
                    {
                        return day.dayofWeek >= fromDate.getDay() && day.dayofWeek <= toDate.getDay();
                    });
                };

                enableOrDisableEventCancellationOrDeletion()
                {
                    this.isEventCancellationOrDeletionEnabled = false;
                    this.groupsWithoutEligibleEvents = [];
                    this.signUpGroupsWithoutEventsForDeletionOrCancellation = [];
                    this.eventsToBeCancelledOrDeleted = [];

                    this.days.forEach((day) =>
                    {
                        day.groupsInformation.forEach((groupInfo) =>
                        {
                            groupInfo.showError = false;
                        });
                    });

                    let selectedDays = this.days.filter((day) =>
                    {
                        return day.isSelected;
                    });

                    if (selectedDays.length == 0)
                    {
                        this.signUpGroupsWithEventsForDeletionOrCancellation = [];
                        return;
                    }

                    let groupIdsToCheckEventsFor = [];
                    selectedDays.forEach((selectedDay) =>
                    {
                        selectedDay.groupsInformation.forEach((groupInfo) =>
                        {
                            groupIdsToCheckEventsFor.push(groupInfo.ccaSignUpGroupId);
                        });
                    });

                    let selectedDayIds = selectedDays.map(a => a.dayofWeek);

                    let fromDate = new Date(this.dateFrom);
                    let toDate = new Date(this.dateTo);

                    this.signUpRepository.getSignUpGroupsWithEventsForDeletionOrCancellation(groupIdsToCheckEventsFor,
                                                                                            fromDate,
                                                                                            toDate,
                                                                                            selectedDayIds)
                        .then((response) =>
                        {
                            this.signUpGroupsWithEventsForDeletionOrCancellation = response.data.groupsWhereActiveNonCancelledEventsExist;

                            this.matchedGroupIds = [];

                            if (this.signUpGroupsWithEventsForDeletionOrCancellation.length > 0)
                            {
                                this.signUpGroupsWithEventsForDeletionOrCancellation.forEach((signUpGroupWithEvents) =>
                                {
                                    this.matchedGroupIds.push(signUpGroupWithEvents.ccaSignUpGroupId);
                                });
                            }

                            this.isEventCancellationOrDeletionEnabled = response.data.isEventCancellationOrDeletionEnabled;

                            if (this.isEventCancellationOrDeletionEnabled)
                            {
                                this.signUpGroupsWithEventsForDeletionOrCancellation.forEach((signUpGroupWithEvents) =>
                                {
                                    signUpGroupWithEvents.events.forEach((event) =>
                                    {
                                        this.eventsToBeCancelledOrDeleted.push(event);
                                    });
                                });
                            }

                            if (!this.isEventCancellationOrDeletionEnabled)
                            {
                                this.groupsWithoutEligibleEvents = this.selectedGroups.filter((selectedGroup) =>
                                {
                                    return response.data.groupIdsWithoutEligibleEvents.some(item => selectedGroup.id == item);
                                });

                                this.selectedGroups.forEach((selectedGroup) =>
                                {
                                    if (Array.isArray(selectedGroup.linkedGroups))
                                    {
                                        selectedGroup.linkedGroups.forEach((linkedGroup) =>
                                        {
                                            if (response.data.groupIdsWithoutEligibleEvents.some(item => linkedGroup.id == item))
                                            {
                                                this.groupsWithoutEligibleEvents.push(linkedGroup);
                                            }
                                        });
                                    }
                                });

                                // No events eligible for cancellation or deletion were found for one or more groups,
                                // so we need to flag those groups so they show with an error icon.
                                selectedDays.forEach((selectedDay) =>
                                {
                                    selectedDay.groupsInformation.forEach((groupInfo) =>
                                    {
                                        if (response.data.groupIdsWithoutEligibleEvents.some(item => groupInfo.ccaSignUpGroupId == item))
                                        {
                                            groupInfo.showError = true;
                                            this.signUpGroupsWithoutEventsForDeletionOrCancellation.push(
                                                {
                                                    name: groupInfo.groupName.concat(' (', this.$translate.instant(selectedDay.name), ')')
                                                });
                                        }
                                    });
                                });
                            }
                        });
                };

                closeModal(responseDto)
                {
                    this.modalInstance.close(responseDto);
                };

                confirmCancelOrDeleteEvents(action)
                {
                    if (action != 'cancel' && action != 'delete')
                    {
                        return;
                    }

                    // Cancel or Delete events for selected groups, days and date range
                    let cancelOrDeleteEventsData =
                    {
                        cancel: action == 'cancel',
                        delete: action == 'delete',
                        dataItems: this.eventsToBeCancelledOrDeleted
                    };

                    let confirmationModal = this.$uibModal.open({
                        component: 'ccaCancelOrDeleteEventsConfirmDialog',
                        size: 'md',
                        resolve: {
                            cancelOrDeleteEventsData: () => { return cancelOrDeleteEventsData; }
                        },
                    });

                    confirmationModal.result
                        .then((response) =>
                        {
                            if (response.status == 'confirm')
                            {
                                this.proceedToCancelOrDeleteEvents(cancelOrDeleteEventsData);
                            }
                            else
                            {
                                this.resetSubmitButtons();
                            }
                        })
                        .catch(() =>
                        {
                            this.toastService.error();
                            this.resetSubmitButtons();
                        });
                };

                proceedToCancelOrDeleteEvents(cancelOrDeleteEventsData)
                {
                    let cancelOrDeleteRequest =
                    {
                        isDeletion: cancelOrDeleteEventsData.delete,
                        isCancellation: cancelOrDeleteEventsData.cancel,
                        events: cancelOrDeleteEventsData.dataItems
                    };

                    this.signUpRepository.cancelOrDeleteSignUpGroupEvents(cancelOrDeleteRequest)
                        .then((result) =>
                        {
                            if (result.isSuccessful)
                            {
                                let response =
                                {
                                    status: 'confirmed',
                                    isDeletion: cancelOrDeleteEventsData.delete,
                                    isCancellation: cancelOrDeleteEventsData.cancel,
                                    numberOfEventsCancelledOrDeleted: result.numberOfEventsCancelledOrDeleted
                                };

                                // If events are being cancelled we need to tell the user that no automated
                                // messages have been sent to parents or staff.
                                if (cancelOrDeleteRequest.isCancellation)
                                {
                                    let confirmation = this.simpleAlertService.simpleAlert({
                                        title: 'SB_CcaSignUp_CancelEvents_title_SendCommunication',
                                        message: 'SB_CcaSignUp_CancelEvents_message_SendCommunication',
                                        cancelButtonText: '',
                                        windowSize: 'md'
                                    });

                                    confirmation.result
                                        .then(() =>
                                        {
                                            this.closeModal(response);
                                        })
                                        .catch(() =>
                                        {
                                            this.closeModal(response); // Cater for user closing the alert instead of clicking "Ok"
                                        });
                                }
                                else
                                {
                                    this.closeModal(response);
                                }
                            }
                            else
                            {
                                this.toastService.saveError();
                                this.resetSubmitButtons();
                            }
                        })
                        .catch(() =>
                        {
                            this.toastService.saveError();
                            this.resetSubmitButtons();
                        });
                }

                resetSubmitButtons()
                {
                    this.$timeout(() =>
                    {
                        this.isSubmitted = false;
                    }, 200);
                };

                cancel()
                {
                    let response =
                    {
                        status: 'cancelled'
                    };

                    this.closeModal(response);
                };
            }
        });
