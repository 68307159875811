import { Component, Input } from '@angular/core';
import { DataDashboard } from '@sb-shared/models/UI/data-dashboard';
import { Router } from '@angular/router';

@Component({
  selector: 'sb-data-dashboard',
  templateUrl: './data-dashboard.component.html',
  styleUrls: ['./data-dashboard.component.scss']
})
export class DataDashboardComponent {

  constructor(private router: Router) {}

  @Input() dashboard: DataDashboard;

  openRoute(route: string) {
    this.router.navigateByUrl(route);
  }

}
