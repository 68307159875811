angular.module('shared.components.sbSubmitButton', [
    'shared.components.sbButton'
])
    .component('sbSubmitButton', {
            transclude: true,
            templateUrl: '/Scripts/app/shared/components/sb-submit-button.template.html',
            bindings: {
                onSubmit: '&',
                message: '@',
                messageXs: '@',
                messageTranslationParameters: '<',
                buttonClass: '@', // bootstrap button class e.g. primary, success. Defaults to primary.
                customClasses: '@',
                buttonId: '<',
                isDisabled: '<',
                isShowing: '<',
                isSubmitted: '=?',
                iconName: '@',
                formForAction: '<',
                xsIconOnly: '<',
                resetTimeout: '<',
                isLoading: '<'
            },
            controller: class SbSubmitButton {

                $timeout: any;
                $attr: any;

                onSubmit: any;
                message: string;
                messageXs:string;
                messageTranslationParameters: any;
                buttonClass: string; // bootstrap button class e.g. primary, success. Defaults to primary.
                customClasses: string;
                buttonId: string;
                isDisabled: boolean;
                isShowing: boolean;
                isSubmitted: boolean;
                iconName: string;
                formForAction: any;
                xsIconOnly: boolean;
                resetTimeout: number;
                isLoading: boolean;

                computedClass: string = 'btn';

                static $inject = ['$timeout'];

                constructor($timeout) {
                    this.$timeout = $timeout;
                }

                resetSubmitButton() {
                    this.$timeout(() => {
                        this.isSubmitted = false;
                    }, this.resetTimeout);
                };

                $onInit() {
                    if (typeof this.isSubmitted === 'undefined') {
                        // submit bool wasn't passed in, use an internal one instead.
                        this.isSubmitted = false;
                    }

                    if (typeof this.isShowing === 'undefined') {
                        // visibility bool wasn't passed in, default to true
                        this.isShowing = true;
                    }

                    if (typeof this.buttonClass === 'undefined') {
                        // no class provided
                        this.buttonClass = 'primary';
                    }

                    if (typeof this.customClasses === 'undefined') {
                        this.customClasses = '';
                    }
                }

                onSubmitClick(event) {
                    this.isSubmitted = true;
                    if (this.onSubmit && typeof (this.onSubmit) === 'function') {
                        this.onSubmit();
                    }

                    // if there's a form action, we should trigger it now
                    // because we disabled the submit button, it won't happen automatically.
                    // var elem = event.currentTarget;
                    // while (elem != null && elem.nodeName.toUpperCase() != 'FORM') {
                    //     elem = elem.parentElement;
                    // }

                    // if (elem != null && elem.action) {
                    //     elem.submit();
                    // }

                    if (this.formForAction) {
                        if (this.formForAction.$$element && this.formForAction.$$element[0]) {
                            this.formForAction.$$element[0].submit();
                        }
                        else {
                            console.log('Form with action ignored, no form element found');
                        }
                    }

                    if (this.resetTimeout) {
                        this.resetSubmitButton();
                    }


                }
    }
});
