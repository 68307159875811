'use strict';

angular.module('balanceAdmin.summary.components.eventFeeSummary',
    [
        'balance.constants',
        'balanceAdmin.components.newTransactionUibContainer',
        'balanceAdmin.summary.components.sendChaseUibContainer',
        'balanceAdmin.summary.components.refundUibContainer',
        'balanceAdmin.services.balanceAdminService',
        'shared.components.sbIcon',
        'shared.services.balanceSharedService',
        'shared.services.simpleAlertService'
    ])
    .component('eventFeeSummary',
        {
            bindings: {
                from: '<',
                to: '<',
                accountOwnerPersonId: '<',
                searchFilters: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/summary/components/event-fee-summary.template.html',
            controller: [
                '$uibModal',
                'balanceAdminService',
                'balanceSharedService',
                'simpleAlertService',
                'financialAccountTypeEnum',
                'financialTransactionTypeEnum',
                function (
                    $uibModal,
                    balanceAdminService,
                    balanceSharedService,
                    simpleAlertService,
                    financialAccountTypeEnum,
                    financialTransactionTypeEnum) {

                    this.$onChanges = function (changes) {
                        if (changes.from || changes.to || changes.accountOwnerPersonId) {
                            refresh();
                        }
                    };

                    // Fired on digest cycle.
                    this.$doCheck = function () {
                        if (this.searchFilters.dirty) {
                            this.searchFilters.dirty = false;
                            recalculateTotals();
                        }
                    };

                    this.isAdvancedFeeAdmin = false;
                    this.groupNameColumnHeader = 'SB_Group';

                    this.totals = {
                        creditAmount: 0,
                        debitAmount: 0,
                        adjustmentAmount: 0,
                        transferAmount: 0,
                        balance: 0
                    };

                    var resetTotals = function () {
                        this.totals.creditAmount = 0;
                        this.totals.debitAmount = 0;
                        this.totals.adjustmentAmount = 0;
                        this.totals.transferAmount = 0;
                        this.totals.balance = 0;
                    }.bind(this);

                    /// Recalculates the totals taking into account any active filter selections.
                    var recalculateTotals = function () {

                        resetTotals();

                        var calculateForItem = function (item) {
                            if (this.filterUnpaid(item)) {
                                this.totals.adjustmentAmount += item.adjustmentAmount;
                                this.totals.transferAmount += item.transferAmount;
                                this.totals.creditAmount += item.creditAmount;
                                this.totals.debitAmount += item.debitAmount;
                            }
                        }.bind(this);

                        for (var i = this.groupSummaries.length; i--;) { // teams and clubs
                            var thisGroup = this.groupSummaries[i];
                            if (!this.filterGroup(thisGroup)) {
                                continue;
                            }
                            if (!thisGroup.childrenLoaded) {
                                calculateForItem(thisGroup);
                                continue;
                            }
                            for (var j = thisGroup.children.length; j--;) { // events
                                var thisEvent = thisGroup.children[j];
                                if (!thisEvent.childrenLoaded) {
                                    calculateForItem(thisEvent);
                                    continue;
                                }
                                for (var k = thisEvent.children.length; k--;) { // attendees
                                    var thisAttendee = thisEvent.children[k];
                                    if (!thisAttendee.childrenLoaded) {
                                        calculateForItem(thisAttendee);
                                        continue;
                                    }
                                    for (var l = thisAttendee.children.length; l--;) { // charges
                                        calculateForItem(thisAttendee.children[l]);
                                    }
                                }
                            }
                        }

                        this.totals.balance = (this.totals.creditAmount - this.totals.transferAmount) - (this.totals.debitAmount - this.totals.adjustmentAmount);

                    }.bind(this);

                    this.loading = 0;

                    this.sortType = 'minEventDate';

                    this.sortReverse = true;

                    this.groupSummaries = [];

                    this.currencyDisplaySymbol = window.EveryBuddy?.Constants?.CurrencyDisplaySymbol;
                    this.currencyMultiplier = Math.pow(10, window.EveryBuddy?.Constants?.CurrencyExponent ?? 2);

                    this.loading++;

                    balanceAdminService.isAdvancedFeeAdmin()
                        .then(function (data) {
                            this.isAdvancedFeeAdmin = data;
                        }.bind(this))
                        .finally(function (data) {
                            this.loading--;
                        }.bind(this));

                    var refresh = function () {

                        if (!this.from || !this.to) {
                            return;
                        }

                        this.loading++;
                        this.groupSummaries.splice(0, this.groupSummaries.length);

                        balanceAdminService.getGroupFeeSummariesAsync(this.from, this.to, this.accountOwnerPersonId)
                            .then(function (data) {

                                for (var i = data.length; i--;) {
                                    var x = data[i];

                                    x.balance = (x.creditAmount - x.transferAmount) - (x.debitAmount - x.adjustmentAmount);
                                    x.expanded = false;
                                    x.loading = 0;
                                    x.childrenLoaded = false;
                                    x.children = [];

                                    this.groupSummaries.push(x);
                                }

                                recalculateTotals();

                            }.bind(this))
                            .catch(function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert({ message: err });
                            })
                            .finally(function () {
                                this.loading--;
                            }.bind(this));
                    }.bind(this);

                    this.toggleExpandGroup = function (group) {
                        if (group.expanded) {
                            group.expanded = false;
                        }
                        else {
                            this.expandGroup(group);
                        }
                    }.bind(this);

                    this.expandGroup = function (group) {
                        if (group.childrenLoaded) {
                            group.expanded = true;
                            return;
                        }

                        group.loading++;

                        balanceAdminService.getEventSummariesForGroupAsync(group.groupId, group.isTeam, this.from, this.to, this.accountOwnerPersonId)
                            .then(function (data) {

                                for (var i = data.length; i--;) {
                                    var x = data[i];
                                    x.balance = (x.creditAmount - x.transferAmount) - (x.debitAmount - x.adjustmentAmount);
                                    x.expanded = false;
                                    x.loading = 0;
                                    x.childrenLoaded = false;
                                    x.children = [];
                                    group.children.push(x);
                                    group.childrenLoaded = true;
                                }

                            }.bind(this))
                            .catch(function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert();
                            })
                            .finally(function () {
                                group.loading--;
                            }.bind(this));

                        group.expanded = true;
                    }.bind(this);

                    this.toggleExpandEvent = function (event) {
                        if (event.expanded) {
                            event.expanded = false;
                        }
                        else {
                            this.expandEvent(event);
                        }
                    }.bind(this);

                    this.expandEvent = function (event) {
                        // Viewing data for a single attendee, so we skip the attendee level
                        // in the tree. Still add them in as a single node and immediately
                        //  expand to the next tree level (charges).
                        if (!!this.accountOwnerPersonId) {
                            if (!event.childrenLoaded) {
                                // Add in a single attendee node.
                                var attendee = {
                                    balance: event.balance,
                                    expanded: false,
                                    loading: 0,
                                    childrenLoaded: false,
                                    children: [],
                                    personId: this.accountOwnerPersonId
                                }

                                event.children.push(attendee);
                                event.childrenLoaded = true;
                            }
                        }

                        if (event.childrenLoaded) {
                            // If we are not viewing in single attendee mode, expand the event as normal.
                            // If we are viewing in single attendee mode, expand the event unless levels
                            // to expand is zero. This is because in this mode the next level is skipped.
                            event.expanded = true;

                            if (!!this.accountOwnerPersonId) {
                                // In single attendee mode, we still want to expand the attendee level.
                                for (var i = event.children.length; i--;) {
                                    this.expandAttendee(event.children[i], event);
                                }
                            }
                            return;
                        }

                        event.loading++;

                        balanceAdminService.getEventAttendeeSummariesForEventAsync(event.eventId, this.accountOwnerPersonId)
                            .then(function (data) {

                                for (var i = data.length; i--;) {
                                    var x = data[i];
                                    x.balance = (x.creditAmount - x.transferAmount) - (x.debitAmount - x.adjustmentAmount);
                                    x.expanded = false;
                                    x.loading = 0;
                                    x.childrenLoaded = false;
                                    x.children = [];
                                    event.children.push(x);
                                    event.childrenLoaded = true;
                                }
                            }.bind(this))
                            .catch(function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert({ message: err });
                            })
                            .finally(function () {
                                event.loading--;
                            }.bind(this));

                        event.expanded = true;
                    }.bind(this);

                    this.toggleExpandAttendee = function (attendee, event) {
                        if (attendee.expanded) {
                            attendee.expanded = false;
                        }
                        else {
                            this.expandAttendee(attendee, event);
                        }
                    }.bind(this);

                    this.expandAttendee = function (attendee, event) {
                        if (attendee.childrenLoaded) {
                            attendee.expanded = true;
                            return;
                        }

                        attendee.loading++;

                        balanceAdminService.getTransactionsForPersonForSpecificEventAsync(attendee.personId, event.eventId)
                            .then(function (charges) {
                                for (var i = charges.length; i--;) {
                                    attendee.children.push(charges[i]);
                                    attendee.childrenLoaded = true;
                                }
                            }.bind(this))
                            .catch(function (err) {
                                console.error(err);
                                simpleAlertService.errorAlert({ message: err });
                            })
                            .finally(function () {
                                attendee.loading--;
                            }.bind(this));

                        attendee.expanded = true;
                    }.bind(this);

                    this.toggleExpandTransaction = function (transaction) {
                        transaction.expanded = !transaction.expanded;
                    }

                    var resetEventChildren = function (event) {
                        event.expanded = false;
                        event.children.splice(0, event.children.length);
                        event.childrenLoaded = false;
                    };

                    this.raiseCharge = function (event, group) {

                        console.log('balanceAdmin.components.eventFeeSummary/raiseCharge', 'clicked');

                        var chargeModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'newTransactionUibContainer',
                            resolve: {
                                accountTypeId: function () { return financialAccountTypeEnum.Student; },
                                transactionType: function () { return financialTransactionTypeEnum.Debit; },
                                calendarEventId: function () { return event.eventId; },
                                groupId: function () { return group.groupId; },
                                isTeam: function () { return group.isTeam; }
                            },
                            size: 'lg'
                        });

                        chargeModal.result
                            .then(function (newTransaction) {

                                console.log('balanceAdmin.components.eventFeeSummary/raiseCharge', 'dialogue closed with new transaction', newTransaction);

                                if (newTransaction) {
                                    event.debitAmount += newTransaction.debit;
                                    event.balance -= newTransaction.debit;

                                    group.debitAmount += newTransaction.debit;
                                    group.balance -= newTransaction.debit;
                                }

                                resetEventChildren(event);
                                recalculateTotals();
                                this.expandEvent(event);
                            }.bind(this))
                            .catch(function () {
                                console.log('balanceAdmin.components.eventFeeSummary/raiseCharge', 'raise charge popup dismissed');
                            });

                    }.bind(this);

                    this.sendChase = function (event) {

                        console.log('balanceAdmin.components.eventFeeSummary/sendChase', 'clicked');

                        var chargeModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'sendChaseUibContainer',
                            resolve: {
                                calendarEventId: function () { return event.eventId; },
                                eventName: function () { return event.eventTitle; }
                            },
                            size: 'lg'
                        });

                    }.bind(this);

                    this.refund = function (transaction, attendee, event, group) {

                        console.log('balanceAdmin.components.eventFeeSummary/refund', 'clicked');

                        var refundModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'refundUibContainer',
                            resolve: {
                                transaction: function () { return transaction; }
                            },
                            size: 'lg'
                        });

                        refundModal.result
                            .then(function (refund) {

                                console.log('balanceAdmin.components.eventFeeSummary/refund', 'dialogue closed');

                                // todo server update to return refund info (adjustment amount and possible transfer amount)
                                // then intelligently modify values on group, event, attendee and charge using them.
                                // see raise charge for something similar.
                                // What we are doing below is close, but not good enough as doesn't take into account possible transfers.

                                // for now we'll wipe everything out and start again.
                                refresh();
                            }.bind(this))
                            .catch(function () {
                                console.log('balanceAdmin.components.eventFeeSummary/refund', 'refund popup dismissed');
                            });

                    }.bind(this);

                    this.filterGroup = function (item) {
                        var selectedGroupids = this.searchFilters.groups.map(function(group) {
                            return group.id;
                        });
                        return selectedGroupids.includes(item.groupId);
                    }.bind(this);

                    this.filterPerson = function (item) {
                        return this.searchFilters.personId === 0 || this.searchFilters.personId === item.personId;
                    }.bind(this);

                    this.filterUnpaid = function (item) {
                        return !this.searchFilters.showUnpaidOnly || Math.round(item.balance * this.currencyMultiplier) < 0;
                    }.bind(this);

                    this.viewTransactions = function (accountTypeId, accountOwnerId) {
                        $uibModal.open({
                            component: 'transactionViewUibContainer',
                            resolve: {
                                accountTypeId: function () { return accountTypeId; },
                                accountOwnerId: function () { return accountOwnerId; },
                                showIncludeSource: function () { return true; }
                            },
                            size: 'lg'
                        });
                    }

                    this.getLabelTagForCreditType = balanceSharedService.getLabelTagForCreditType;
                }
            ]
        });
