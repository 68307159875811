// Copied from reception/reception-staff-roles.contoller.js
angular.module('settingsModule')
    .component('staffRolesInternalRoles',
        {
        templateUrl: '/Scripts/app/settings/components/staff-roles/staff-roles-internal-roles.template.html',
        controller: class StaffRolesInternalRolesCtrl {

            //Dependencies
            $window: any;
            rolesService: any;
            personPickerService: any;
            simpleAlertService: any;
            deviceService: any;

            //Variables
            keyRoles: any;
            selectedRole: any;
            loadingStaff: boolean;
            staff: any;

            static $inject = ['$window', 'rolesService', 'personPickerService', 'simpleAlertService', 'deviceService'];

            constructor (
                $window,
                rolesService,
                personPickerService,
                simpleAlertService,
                deviceService) {
                    this.$window = $window;
                    this.rolesService = rolesService;
                    this.personPickerService = personPickerService;
                    this.simpleAlertService = simpleAlertService;
                    this.deviceService = deviceService;
                }

                $onInit() {
                    this.rolesService.getOrganisationKeyRoles()
                    .then((data: any) => {
                        this.keyRoles = data;
                        if (!this.deviceService.isXsDevice()) {
                            this.showStaffInRole(this.keyRoles[0]);
                        }
                    }, () => {
                        this.simpleAlertService.errorAlert();
                    });
                }

                showStaffInRole(aRole) {

                    this.selectedRole = aRole;
                    this.loadingStaff = true;

                    this.rolesService.getStaffInRole(aRole.id).then((data: any) => {
                        this.loadingStaff = false;
                        this.staff = data;
                    }, error => {
                        this.loadingStaff = false;
                        this.simpleAlertService.errorAlert();
                        return error;
                    });

                };

                unselectRole() {
                    this.selectedRole = 0;
                }

                addMultiplesToRole() {
                    var selectedIds = [];

                    angular.forEach(this.staff,
                        item => {
                            selectedIds.push(item.id);
                        });

                    var modalInstance = this.personPickerService.openMultiPersonPicker({
                        selectedPersonIds: selectedIds,
                        defaultClubId: 0,
                        defaultTeamId: 0,
                        okButtonText: 'SB_Update',
                        includeStaff: true,
                        includePupils: false,
                        hideGroupFilter: true,
                        hideYearFilter: true,
                        hideGenderFilter: true,
                        hideRemove: false,
                        filterOutExternalStaff: true,
                        shownFromRegister: false,
                        shownFromStaffRoles: true
                    });

                    modalInstance.result
                        .then(
                            selectedIds => {
                                var pleaseWaitModalInstance = this.simpleAlertService.pleaseWaitModal({
                                    message : 'SB_Saving_changes_please_wait'
                                });

                                this.rolesService
                                    .postStaffToRole(this.selectedRole.id, selectedIds)
                                    .then((data: any) => {
                                        pleaseWaitModalInstance.close();
                                        if (!data.isError) {
                                            this.showStaffInRole(this.selectedRole);

                                        } else {
                                            console.error('problem saving team changes');
                                            this.simpleAlertService.errorAlert();
                                        }
                                    });
                            }, function () {
                                console.log('dialog cancelled');
                            });
                };
            }
        });
