// This component finds an ng1 card in the DOM and replaces it with a ngx sb-card-detail
// Menu items are passed in from the parent route

import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Buttons } from '@sb-shared/models/UI/buttons';
import { MenuItems } from '@sb-shared/models/UI/menu-item';

@Component({
  selector: 'sb-ng1-card-detail-wrapper',
  templateUrl: './ng1-card-detail-wrapper.component.html',
  styleUrls: ['./ng1-card-detail-wrapper.component.scss']
})
export class Ng1CardDetailWrapperComponent implements AfterContentChecked {
  @ViewChild('ng1CardDetail') ng1CardDetail: ElementRef;

  @Input() menuItems: MenuItems;
  @Input() isLoadingMenuItems: boolean;
  @Input() actions: Buttons;

  @Output() onClickAction: EventEmitter<number> = new EventEmitter();

  contentMoved: boolean;
  sbDetail: HTMLElement;

  ngAfterContentChecked() {
    this.sbDetail = document.getElementsByTagName('sb-detail')[0] as HTMLElement;
    const cardDetailNg1 = this.sbDetail?.querySelector('.card-detail');
    const cardDetailNgx = this.ng1CardDetail?.nativeElement;

    if (cardDetailNg1 && cardDetailNgx) {
      const headingName = '.card-detail__item-heading';
      const headingNgx = cardDetailNgx?.querySelector(headingName);
      const headingNg1 = cardDetailNg1?.querySelector(headingName);

      // As ngAfterContentChecked is run multiple times, we need to check element hasn't been moved yet
      if (!this.contentMoved) {
        cardDetailNg1.parentNode.append(cardDetailNgx);
        this.contentMoved = true;
      }

      if (headingNgx && headingNg1){
        headingNgx.innerHTML = headingNg1.innerHTML;
        cardDetailNg1.classList.add('hidden');
        cardDetailNgx.classList.remove('hidden');
      }
    }
  }

  clickAction(event) {
    this.onClickAction.emit(event);
  }
}
