import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { MomentOrDate } from '@sb-helpers';
import { DateRangeList } from '@sb-shared/constants/shared.constant';

// This Angular directive will act as an interface to the "upgraded" AngularJS component
@Directive({ selector: 'ng1-sb-date-range-picker' })
export class DateRangePickerUpgrade extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() customOptions: any;
  @Input() isRequired: boolean;
  @Input() dateRangeList: DateRangeList;
  @Input() disabled: boolean;
  @Input() minDate: MomentOrDate;
  @Input() maxDate: MomentOrDate;
  @Input() startDate: MomentOrDate;
  @Input() endDate: MomentOrDate;
  @Input() singleDatePicker: boolean;
  @Input() timePicker: boolean;
  @Input() autoUpdate: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('ng1SbDateRangePicker', elementRef, injector);
  }
}
