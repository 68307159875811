angular.module('diary.components.diaryEventVideo',
    [
        'shared.components.sbTwilioVideo',
    ])
    .component('diaryEventVideo',
        {
            bindings: {
                eventDetails: '<',
                onEndCall: '&'
            },
            templateUrl: '/Scripts/app/diary/components/diary-event-video.template.html',
            controller: class DiaryEventVideoCtrl { }
        });
