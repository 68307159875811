'use strict';

angular.module('sysadmin.components.sysAdminAgeBands', [])
    .component('sysAdminAgeBands', {
        templateUrl: '/Scripts/app/sysadmin/components/sysadminagebands.html',
        controller: [
        '$scope', '$state', '$filter', '$http', 'sysAdminService', 'organisationService',
        function ($scope, $state, $filter, $http, sysAdminService, organisationService) {

            $scope.sortType = 'name';
            $scope.sortReverse = false;

            sysAdminService.getAgeBands().then(function(data) {
                    $scope.agebands = data;
                },
                function(error) {
                    return error;
                });

            organisationService.getOrganisations().then(function(data) {
                    $scope.organisations = data;
                },
                function(error) {
                    return error;
                });

            $scope.addAgeBand = function() {
                $scope.selectAgeBand = {
                    organisationId: 0,
                    name: '',
                    minimumAge: 0,
                    maximumAge: 1,
                    isDeleted: false
                };
            };
            $scope.showAgeBand = function(anAgeBand) {
                $scope.selectAgeBand = null;
                sysAdminService.getAgeBand(anAgeBand.organisationAgeBandId).then(function(data) {
                        $scope.selectAgeBand = data;
                    },
                    function(error) {
                        return error;
                    });

            };
            $scope.updateAgeBand = function() {
                sysAdminService.postAgeBand($scope.selectAgeBand)
                    .then(function(data) {
                        $scope.selectAgeBand = data;
                            $scope.agebands = null;
                        sysAdminService.getAgeBands().then(function (data) {
                            $scope.agebands = data;
                        },
    function (error) {
        return error;
    });
                        }
                    );
            };
            $scope.minAge = function()
            {
                return   $scope.selectAgeBand.minimumAge + 1;
            }
        }
    ]});