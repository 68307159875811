<div class="space-between transitionless-state">
    <div *ngIf="!isEditing && model"
         [innerHTML]="model"></div>
    <div *ngIf="!isEditing && !model"
         [innerHTML]="placeholder | translate"
         class="text-muted"></div>
    <input *ngIf="isEditing"
           [(ngModel)]="model"
           maxlength="{{maxLength}}"
           placeholder="{{placeholder | translate}}"
           class="form-control">
    <button class="btn btn-link btn-auto ml-2"
            ngbTooltip="{{(isEditing ? saveLabel : editLabel) | translate}}"
            (click)="toggleIsEditing()"
            [disabled]="!model && isRequired">
         <sb-icon name="{{isEditing ? 'check' : 'edit'}}"
                  [ngClass]="{'text-success' : isEditing}"
                  [overrideStyle]="isEditing ? 'solid' : null"></sb-icon>
    </button>
    <button *ngIf="isEditing"
            class="btn btn-link btn-auto ml-2"
            ngbTooltip="{{cancelLabel | translate}}"
            (click)="cancel()">
         <sb-icon name="close"
                  class="text-warning"></sb-icon>
    </button>
</div>