'use strict';

angular.module('sysadmin.components.sysAdminPackages', [])
    .component('sysAdminPackages', {
        templateUrl: '/Scripts/app/sysadmin/components/sysadminpackages.html',
        controller: [
        '$scope', '$state', '$filter', '$http', 'sysAdminService', 'organisationService' ,
        function ($scope, $state, $filter, $http, sysAdminService, organisationService) {

            $scope.organisations = [];
            $scope.maxDate = new Date(2199, 12, 31);

            $scope.open1 = function () {
                $scope.popup1.opened = true;
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
            $scope.format = $scope.formats[0];
            $scope.altInputFormats = ['M!/d!/yyyy'];

            $scope.popup1 = {
                opened: false
            };


            $scope.$watch('selectPackage.organisationId', function(newValue, oldValue) {
                if ($scope.organisations.length > 0 && newValue != 'undefined') {
                    var matches = $filter('filter')($scope.organisations, { organisationId: newValue });
                    if (matches.length > 0) {
                        $scope.organisation = matches[0];
                    }
                }
            });

            $scope.$watch('selectPackage.slotCount', function (newValue, oldValue) {
                if (newValue != 'undefined' && $scope.hasOwnProperty('selectPackage') && $scope.selectPackage != null) {
                    var existingSlots = $scope.selectPackage.slots.length;
                    if (newValue >= existingSlots) {
                        for (var i = existingSlots; i < newValue; i++) {
                            $scope.selectPackage.slots.push({ roleTypeId: 16, organisationAgeBandId: 0 });
                        }
                    } else if (newValue < existingSlots) {

                        $scope.selectPackage.slots.splice(existingSlots - (1 + newValue), (existingSlots - newValue));
                    }
                }
            });


            $scope.sortType = 'name';
            $scope.sortReverse = false;

            sysAdminService.getPackages().then(function(data) {
                    $scope.packages = data;
                },
                function(error) {
                    return error;
                });

            organisationService.getOrganisations().then(function(data) {
                    $scope.organisations = data;
                },
                function(error) {
                    return error;
                });

            $scope.addPackage = function() {
                $scope.selectPackage = null;
                sysAdminService.getPackage(0).then(function (data) {
                    $scope.selectPackage = data.details;
                    $scope.selectPackage.expiryDate = new Date($scope.selectPackage.expiryDate.substring(0, 10));
                    $scope.durations = data.durations;
                    $scope.agebands = data.ageBands;
                },
                    function (error) {
                        return error;
                    });
            };
            $scope.showPackage = function(anPackage) {
                $scope.selectPackage = null;
                sysAdminService.getPackage(anPackage.organisationPackageId).then(function (data) {
                        $scope.selectPackage = data.details;
                        $scope.selectPackage.expiryDate = new Date($scope.selectPackage.expiryDate.substring(0, 10));
                        $scope.durations = data.durations;
                        $scope.agebands = data.ageBands;
                    },
                    function(error) {
                        return error;
                    });

            };
            $scope.updatePackage = function() {
                sysAdminService.postPackage($scope.selectPackage)
                    .then(function(data) {
                            $scope.selectPackage = data.details;
                            $scope.selectPackage.expiryDate = new Date($scope.selectPackage.expiryDate.substring(0, 10));
                            $scope.durations = data.durations;
                            $scope.agebands = data.ageBands;
                            $scope.packages = null;
                        sysAdminService.getPackages().then(function (data) {
                            $scope.packages = data;
                        },
    function (error) {
        return error;
    });
                        }
                    );
            };
            $scope.minAge = function()
            {
                return   $scope.selectPackage.minimumAge + 1;
            }
        }
    ]});