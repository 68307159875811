'use strict';

angular.module('shared.directives.sbDetail',
    [
        'shared.components.sbIcon',
    ]
)
    .directive('sbDetail', [function () {

        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                placeholderText: '<',
                placeholderIcon: '@',
                backText: '<',
                selectedValue: '=',
                locationChange: '<',
                isLoading: '<',
                onBack: '&'
            },
            transclude: true,
            templateUrl: '/Scripts/app/shared/directives/sb-detail.template.html',
            controller: ['$rootScope', '$timeout', '$window',
                function ($rootScope, $timeout, $window) {

                    this.back = function () {
                        if (this.onBack()) {
                            this.onBack()();
                        } else {
                            this.selectedValue = null;
                        }
                    };

                    $rootScope.$on('$locationChangeStart', function (event) {
                        /* Prevent app from going back a page on click of button,
                        instead returning user to the master view*/
                        if (this.selectedValue && !this.locationChange) {
                            event.preventDefault();
                            this.back();
                        /* Or if a used on a page with routing, just check contents */
                        }
                    }.bind(this));
                }
            ],
            controllerAs: 'ctrl'
        };
    }]);
