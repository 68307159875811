import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiClasses } from '@sb-shared/types/ui-classes';

@Component({
  selector: 'sb-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent {

  @Input() model;
  @Output() modelChange = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Input() trueValue = true; // Added default value for Angular 12
  @Input() falseValue = false; // Added default value for Angular 12
  @Input() controlName: string;
  @Input() checkClass: UiClasses = 'success';
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() isReversed: boolean;

  toggle() {
    if (this.isDisabled) {
      return;
    }
    if (this.model === this.trueValue) {
      this.model = this.falseValue;
    }
    else {
      this.model = this.trueValue;
    }
    if (this.modelChange.observers.length > 0) {
      setTimeout(() => {
        this.modelChange.emit(this.model);
      }, 0);
    }
    this.onClick.emit();
  }

  isCheckboxChecked() {
    if (this.isChecked !== void 0) {
      return this.isChecked;
    }

    return this.model === this.trueValue;
  }
}
