'use strict';

angular.module('reports.services.reportService', [])
    .factory('reportService', [
        '$http', '$q', '$cacheFactory',
        function ($http, $q, $cacheFactory) {

            var urlBase = '/webapi/WebReports/';
            
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('reportServiceCache');

            var enums = window.EveryBuddy.Enums;

            var stateStore = {};

            repository.clear = function()
            {
                repoCache.removeAll();
            };

            repository.getAvailableReports = function(displayArea)
            {
                return $http.get(urlBase + 'GetAvailableReports',
                        {
                            cache: repoCache,
                            params: {
                                displayArea : displayArea
                            }
                        })
                    .then(function(response) { return response.data; })
                    .catch(function(e) { return { isError: true, error: e }; });
            };

            repository.getReportConfig = function (reportId)
            {
                return $http.get(urlBase + 'GetReport',
                        {
                            params: {
                                'ReportId': reportId
                            }
                        })
                    .then(function (response) { return response.data; })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            return repository;
        }
    ]);