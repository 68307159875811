'use strict';

angular.module('diary.components.diaryEventDetailInfo',
    [
        'ngSanitize',
        'ccasignup.constants',
        'pascalprecht.translate',
        'shared.components.sbIcon',
        'shared.components.sbStackedDate',
        'location.components.locationLite',
        'diary.services.diaryService',
        'diary.transport.diaryTransportChangeRequest',
        'shared.services.portalChangeRequestService',
        'shared.services.registerStateService',
    ])
    .component('diaryEventDetailInfo',
        {
            bindings: {
                eventData: '<',
                onRespondedToInvite: '&',
                onBackButtonClicked: '&',
                onChangeRequestAdded: '&'
            },
            templateUrl: '/Scripts/app/diary/components/diary-event-detail-info.template.html',
            controller: [
                '$uibModal',
                '$location',
                '$window',
                'diaryService',
                'portalChangeRequestService',
                'simpleAlertService',
                'registerStateService',
                'deviceService',
                'parentTeacherConferenceLocationTypes',
                '$sce',
                function ($uibModal, $location, $window, diaryService, portalChangeRequestService, simpleAlertService, registerStateService, deviceService, parentTeacherConferenceLocationTypes, $sce) {
                    this.$location = $location;
                    this.$window = $window;
                    this.deviceService = deviceService;
                    this.eventData = null;
                    this.eventHasHappend = false;
                    this.isMultiDay = false;
                    this.registerStateService = registerStateService;
                    this.parentTeacherConferenceLocationTypes = parentTeacherConferenceLocationTypes;
                    this.changeRequestModals = {
                        Add: 1,
                        Review: 2
                    };
                    this.$sce = $sce;

                    this.$onChanges = function (changes) {
                        if (changes.eventData && changes.eventData.currentValue) {
                            this.eventHasHappened = (this.eventData.from < new Date());
                            this.isMultiDay = !moment(this.eventData.from).isSame(this.eventData.to, 'day');
                            let modalType = deviceService.getShowModalType();
                            if (!modalType) {
                                modalType = parseInt(this.$location.search().modalId);
                            }
                            if (modalType && this.eventData.attendanceData?.transportAttendee) {
                                this.showChangeRequestModal(this.eventData.attendanceData.transportAttendee, modalType);
                            }
                        }
                    };

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                    this.enums = window.EveryBuddy.Enums;

                    this.responseToInvite = function (response) {
                        var dto = {
                            personId: this.eventData.personId,
                            eventId: this.eventData.calendarEventId,
                            calendarEventAttendeeId: this.eventData.attendanceData.calendarEventAttendeeId,
                            decision: response,
                            reason: this.eventData.attendanceData.attendeeReason,
                            consentMessage: this.eventData.attendanceData.parentConsentAdditionalInformation
                        };

                        diaryService.respondToInvite(dto)
                            .then(function (res) {
                                this.onRespondedToInvite();
                            }.bind(this))
                            .catch(function (err) { });

                    }.bind(this);

                    this.getAttendanceStatusTitle = function (event) {
                        if (event.registerState) {
                            return this.registerStateService.getRegisterStateBadgeText(event.registerState);
                        }
                        switch (event.attendeeStatus) {
                            case this.enums.CalendarEventViewAttendeeStatus.InviteesOnly:
                                return 'SB_Invitees_Only';
                            case this.enums.CalendarEventViewAttendeeStatus.NotSelected:
                                return 'SB_Not_Selected';
                            case this.enums.CalendarEventViewAttendeeStatus.Declined:
                                return 'SB_Declined';
                            case this.enums.CalendarEventViewAttendeeStatus.None:
                                return '';
                            case this.enums.CalendarEventViewAttendeeStatus.Selected:
                                return 'SB_Selected';
                            case this.enums.CalendarEventViewAttendeeStatus.Notified:
                                return 'SB_Notified';
                            case this.enums.CalendarEventViewAttendeeStatus.Attending:
                                return 'SB_Attending';
                            case this.enums.CalendarEventViewAttendeeStatus.HelpRequested:
                                return 'SB_Your_Help_Has_Been_Requested';
                            case this.enums.CalendarEventViewAttendeeStatus.AttendanceRequested:
                                return 'SB_Attendance_Requested';
                            case this.enums.CalendarEventViewAttendeeStatus.AvailabilityRequested:
                                return 'SB_Availability_Requested';
                            case this.enums.CalendarEventViewAttendeeStatus.AvailableAwaitingSelection:
                                return 'SB_Available_Awaiting_Selection';
                            case this.enums.CalendarEventViewAttendeeStatus.PendingSelection:
                                return 'SB_Pending_Selection';
                        }
                    }.bind(this);

                    this.getAttendanceStatusColour = function (event) {
                        if (event.registerState) {
                            return 'text-' + this.registerStateService.getRegisterStateColorClass(event.registerState);
                        }
                        switch (event.attendeeTrafficLight) {
                            case this.enums.CalendarEventViewTrafficLight.Wait:
                                return 'text-warning';
                            case this.enums.CalendarEventViewTrafficLight.Go:
                                return 'text-success';
                            case this.enums.CalendarEventViewTrafficLight.WaitingOnOrganisating:
                                return 'text-primary';
                            case this.enums.CalendarEventViewTrafficLight.Stop:
                                return 'text-danger';
                        }
                    }.bind(this);

                    this.showChangeRequestModal = function (transportAttendee, modalType) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            component: modalType === this.changeRequestModals.Add ? 'diaryTransportChangeRequest' : 'diaryTransportChangeRequestDetails',
                            size: 'lg',
                            windowClass: 'modal-scroll',
                            resolve:
                                modalType === this.changeRequestModals.Add ?
                                    {
                                        isSchoolbound: function () { return transportAttendee.isSchoolbound; },
                                        routeStopDateTime: function () { return transportAttendee.routeStopDateTime; },
                                        pupilChangeRequestOptions: function () {
                                            return portalChangeRequestService.getChangeRequestOptions(transportAttendee.id).catch(function (error) {
                                                console.error('XHR diaryTransportChangeRequest failure : ' + error.status);

                                                var message = { message: 'SB_Error_loading' };

                                                if (error && error.data && error.data.Message) {
                                                    message = { message: error.data.Message };
                                                }

                                                simpleAlertService.errorAlert(message);

                                            })
                                        }.bind(this)
                                    } :
                                    {
                                        diaryTransportChangeRequest: function () {
                                            const transportAttendeeId = transportAttendee.hasLinkedChangeRequest ? transportAttendee.linkedCalendarEventTransportAttendeeId : transportAttendee.id;
                                            return portalChangeRequestService.getChangeRequest(transportAttendeeId).catch(function (error) {

                                                console.error('XHR diaryTransportChangeRequest failure : ' + error.status);

                                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                                            })
                                        }.bind(this)
                                    }
                        });

                        modalInstance.result.then(function (updateView) {
                            if (this.deviceService.isMobileApp()) {
                                this.onBackButtonClicked();
                            }
                            if (updateView !== 'cancel') {
                                this.onChangeRequestAdded();
                            }
                        }.bind(this));



                    }.bind(this);

                    this.showLocation = function () {
                        return (!this.eventData.isPTC &&
                            !this.eventData.transportTimetableRouteTimeline) ||
                            this.eventData.parentTeacherConferenceLocationTypeId == this.parentTeacherConferenceLocationTypes.InPerson ||
                            this.eventData.parentTeacherConferenceLocationTypeId == this.parentTeacherConferenceLocationTypes.Both;
                    }

                    this.openMeetingId = () => {
                        this.$window.open(this.eventData.onlineMeetingId, '_blank');
                    }
                }
            ]
        });
