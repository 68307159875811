'use strict';

angular.module('ccasignupModule').component('ccaAddPupilBookingComplete', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-add-pupil-booking-complete.template.html',
    controller: [
    '$scope',
    '$state',
    function ($scope, $state) {
        $scope.return = function () {
            $state.go('ccaDetailsAA.groups',
                { signUpId: $state.params.signUpId });
        };
    }
]});