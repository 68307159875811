angular.module('shared.services.portalService', [])
    .service('portalService', class PortalService {
        httpCoreApi: any;

        urlStringPortalLink: string;
        urlStringPortalContent: string;
        settings: any;
        repoCache: any;

        static $inject = ['$cacheFactory', 'httpCoreApi'];

        constructor($cacheFactory, httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.urlStringPortalLink = 'PortalLinks';
            this.urlStringPortalContent = 'PortalContent';
            this.repoCache = $cacheFactory('portalServiceCache');
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.User,
                cache: this.repoCache
            }
        }

        getLinks() {
            return this.httpCoreApi.get(this.urlStringPortalLink, this.settings);
        }

        getContent(id: number) {
            return this.httpCoreApi.get(`${this.urlStringPortalContent}/${id}`, this.settings)
        }
    });
