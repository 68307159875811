import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from './../../../environments/environment';
import { MemberTypeId } from '@sb-shared/enums/member.type.enum';

@Injectable({
  providedIn: 'root'
})
export class IdpExternalService {

  // Separate from httpWebApi service to keep that service more simple

  urlBase: string = window.EveryBuddy?.IdUrl + "/api/UserExternalProvider";

  constructor(private http: HttpClient, private authService: AuthService) { }

  stripOutRequestResponseData(request: Observable<any>): Observable<any> {
    return request.pipe(map(res => res?.data));
  }

  getUsersSSOProviderOptions(email: string) {
    const request = this.http.get(this.urlBase, {
      withCredentials: true,
      params: {
        ServerId: environment.serverId,
        OrganisationId: window.EveryBuddy.CurrentOrganisationId,
        Email: email
      }
    });
    return this.stripOutRequestResponseData(request);
  }

  removeUserSSOLink(requestDto) {
    const request = this.http.post(this.urlBase + '/RemoveExternalCallback', requestDto, { withCredentials: true });
    return this.stripOutRequestResponseData(request);
  }

  updateUserType(type: MemberTypeId) {
    const accessToken = this.authService.getAccessToken();
    
    return this.http.post(window.EveryBuddy?.IdUrl  + `/api/Account/UserType/${type}`, {}, {
      withCredentials: false,
      observe: 'response',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`
      })
    })
    .pipe(map(res => res.ok));
  }

  hasSingleSignOnEnabled(organisationId: number) {
    if (this.authService.isAppAccessToken()) {
      // Mobile App Web Views > no cookie credentials to complete request.
      // Not interested in value anyway as not showing Profile SSO component in WebViews.
      // So skip this and return false.
      return of(false);
    }

    const request = this.http.get(this.urlBase + '/GetSingleSignOnEnabled', {
      params: {
        ServerId: environment.serverId,
        OrganisationId: organisationId
      },
      withCredentials: true,
    }).pipe(shareReplay(1), catchError(err => {
      // User has been logged-out of identity server in another tab. Force logout and log-in again.
      this.authService.logout();
      throw err;
    }));
    return this.stripOutRequestResponseData(request);
  }

}
