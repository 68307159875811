'use strict';

angular.module('shared.controllers.pleaseWaitPopupController', [])
    .controller('pleaseWaitPopupController', [
        '$scope', 'message',
        function ($scope, message) {

            $scope.message = message;

        }
    ]);
