angular.module('shared.services.blobImageService', [])
.service('blobImageService', class BlobImageService {

    $window: any;
    $sce: any;
    assetUrlBase: string;

    static $inject = ['$window', '$sce'];

    constructor($window, $sce) {
        this.$window = $window;
        this.$sce = $sce;
        this.assetUrlBase =  this.$window.EveryBuddy.CdnBase + 'img';
    }

    getUndrawSvg(name:string) {
       return this.$sce.trustAsResourceUrl(`${this.assetUrlBase}/icons/undraw/${name}.svg`);
    }
})
