import { Component, OnInit} from '@angular/core';
import { MemberTypeId } from '@sb-shared/enums/member.type.enum';
import { UserService } from '@sb-shared/services/user.service';
import { ProfileDataService } from '../../services/profile-data.service';

@Component({
  selector: 'sb-profile-welcome',
  templateUrl: './profile-welcome.component.html',
  styleUrls: ['./profile-welcome.component.scss']
})
export class ProfileWelcomeComponent implements OnInit {

  memberType: MemberTypeId;

  constructor(
    private profileData: ProfileDataService,
    private userSvc: UserService) { }

  ngOnInit(): void {
    this.profileData.getUserInContext().subscribe(user => {
      this.memberType = this.userSvc.getMemberTypeOld(user);
    });
  }

}
