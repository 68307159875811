'use strict';

angular.module('balanceAdmin.quickCharge.components.quickCharge',
    [
        'balanceAdmin.quickCharge.components.quickChargeFilter',
        'balanceAdmin.quickCharge.components.quickChargeGroup',
        'balanceAdmin.quickCharge.components.quickChargeGroupEvents',
    ])
    .component('quickCharge',
        {
            bindings: {
            },
            templateUrl: '/Scripts/app/balanceAdmin/quickCharge/components/quick-charge.template.html',
            controller: [
                function () {

                    // Set-up our filters.
                    this.searchFilters = {};

                    this.loadingCount = 0;

                    this.refreshFnEvents = {};
                    this.refreshFnGroups = {};

                    this.notifyLoaded = function () {
                        this.loadingCount--;
                    }.bind(this);

                    this.notifyRefresh = function () {
                        if (typeof this.refreshFnEvents.fn == 'function') {
                            this.loadingCount++;
                            this.refreshFnEvents.fn();
                        }

                        if (typeof this.refreshFnGroups.fn == 'function') {
                            this.loadingCount++;
                            this.refreshFnGroups.fn();
                        }
                    }.bind(this);
                }
            ]
        });