"use strict";

angular.module('ccasignupModule').component("ccaLocationManage", {

    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-location-manage.template.html',
    controller: [
    "$scope", "$state", "signUpRepository", function ($scope, $state, signUpRepository) {

        $scope.locationTypeText = function (typeId) {
            switch (typeId) {
                case 0:
                    return 'SB_Home';
                case 1:
                    return 'SB_Away';
                default:
                    return 'SB_Home';
            }
        };

        $scope.homeAways = [
            {
                id: '0',
                name: 'SB_Home'
            },
            {
                id: '1',
                name: 'SB_Away'
            }
        ];

        signUpRepository
            .getLocationTypes()
            .then(function (locationTypes) {
                $scope.locationTypes = locationTypes;
            });

        $scope.location = {};

        if ($state.params.locationId && $state.params.locationId != 0) {
            signUpRepository
                .getLocation($state.params.locationId)
                .then(function (location) {
                    $scope.location = location;
                    $scope.$apply;
                });
        }

        $scope.goBack = function () {
            if ($state.params.isAA) {
                $state.go("ccaDetailsAA.locations", { locationId: 0 });
            } else {
                $state.go("ccaDetails.locations", { locationId: 0 });
            }
        };

        $scope.update = function (location) {
            signUpRepository
                .postLocation(location)
                .then(function () {
                    if ($state.params.isAA) {
                        $state.go("ccaDetailsAA.locations", { locationId: 0 });
                    } else {
                        $state.go("ccaDetails.locations", { locationId: 0 });
                    }
                });
        };
    }
]});
