<sb-button *ngIf="languageItems?.length > 1"
           [dropdownItems]="languageItems"
           [message]="selectedLanguage?.languageCode"
           [isMessageTranslated]="false"
           [messageXs]="' '"
           [imgUrl]="selectedLanguage?.imgUrl"
           buttonClass="link"
           customClasses="language-button"
           dropdownClasses="dropdown-sm-fullscreen"
           [tooltipText]="'SB_Languages'"
           tooltipPlacement="bottom"
           (onClick)="onButtonClick($event)"></sb-button>
