angular.module('shared.components.sbIcon', [
    'shared.services.iconService',
    'pascalprecht.translate'
])
    .component('sbIcon',
        {
            bindings: {
                name: '@',
                overrideStyle: '@',
                size: '@',
                disabled: '=',
                altText: '@',
                hasColor: '@',
                fallbackText: '@',
                overlayName: '@',
                overlayCustomClass: '@'
            },
            template: '<i ng-if="!$ctrl.isText" class="{{$ctrl.iconClass}}" title="{{$ctrl.altText | translate}}" style="{{$ctrl.style}}"></i><i ng-if="!$ctrl.isText && $ctrl.overlayIconClass" class="{{$ctrl.overlayIconClass}}"></i><div ng-if="$ctrl.isText">{{$ctrl.fallbackText}}</div>',
            controller: class SbIconCtrl {

                // Dependencies
                iconService: any;

                // Bindings:
                name: string = '';
                hasColor: boolean = false;
                overlayName: string = '';
                overlayCustomClass: string = '';
                overrideStyle: string = '';
                size: string = '';

                // Variables
                iconClass: string = '';
                overlayIconClass: string = '';
                isText: boolean = false;
                iconColors: any[] = [];
                style: string = '';

                static $inject = ['iconService'];

                constructor (iconService: any) {
                    this.iconService = iconService;
                }


                    $onChanges = (changes: any) => {
                        if (changes.name?.currentValue) {
                            var iconClass = this.iconService.getIcon(this.name);
                            if (iconClass) {
                                this.iconClass = iconClass;
                            } else {
                                this.isText = true;
                            }
                            if (this.hasColor) {
                                this.iconColors = this.iconService.getSportIconColors(this.name);
                                if (this.iconClass) {
                                    if (this.iconColors[0]) {
                                        this.style = 'color:' + this.iconColors[0];
                                    }
                                    if (this.iconColors[1]) {
                                        this.style += ';--secondary-color-var:' + this.iconColors[1];
                                    }
                                }
                            }
                        }
                        if (changes.overlayName?.currentValue) {
                            var overlayIconClass = this.iconService.getIcon(this.overlayName);
                            if (overlayIconClass) {
                                this.overlayIconClass = 'icon-overlay ' + overlayIconClass;
                                if (this.overlayCustomClass) {
                                    this.overlayIconClass += ' ' + this.overlayCustomClass;
                                }
                                this.overlayIconClass = this.overlayIconClass.replace('fa ', 'fas ');
                                this.overlayIconClass = this.overlayIconClass.replace('fal ', 'fas ');
                                this.overlayIconClass = this.overlayIconClass.replace('far ', 'fas ');
                                this.overlayIconClass = this.overlayIconClass.replace('fa ', 'fas ');
                            }
                        }

                        if (changes.overrideStyle?.currentValue || (changes.name?.currentValue && this.overrideStyle)) {
                            switch (this.overrideStyle.toLowerCase()) {
                                case 'regular':
                                    this.iconClass = this.iconClass.replace('fa ', 'far ');
                                    this.iconClass = this.iconClass.replace('fal ', 'far ');
                                    this.iconClass = this.iconClass.replace('fas ', 'far ');
                                    this.iconClass = this.iconClass.replace('fa ', 'far ');
                                    break;
                                case 'light':
                                    this.iconClass = this.iconClass.replace('fa ', 'fal ');
                                    this.iconClass = this.iconClass.replace('fas ', 'fal ');
                                    this.iconClass = this.iconClass.replace('far ', 'fal ');
                                    this.iconClass = this.iconClass.replace('fa ', 'fal ');
                                    break;
                                case 'solid':
                                    this.iconClass = this.iconClass.replace('fa ', 'fas ');
                                    this.iconClass = this.iconClass.replace('fal ', 'fas ');
                                    this.iconClass = this.iconClass.replace('far ', 'fas ');
                                    this.iconClass = this.iconClass.replace('fa ', 'fas ');
                                    break;
                                case 'duotone':
                                    this.iconClass = this.iconClass.replace('fa ', 'fad ');
                                    this.iconClass = this.iconClass.replace('fal ', 'fad ');
                                    this.iconClass = this.iconClass.replace('far ', 'fad ');
                                    this.iconClass = this.iconClass.replace('fas ', 'fad ');
                                    break;
                            }
                        }

                        if (changes.size && changes.size.currentValue) {
                            switch (this.size.toLowerCase()) {
                                case 'small':
                                    this.iconClass += ' fa-sm';
                                    break;
                                case 'large':
                                    this.iconClass += ' fa-lg';
                                    break;
                                case 'xl':
                                    this.iconClass += ' fa-xl';
                                    break;
                            }
                        }
                    };
                }
        });
