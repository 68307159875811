import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ExceedCapacityByChangeRequest, RelatedChangeRequest } from './models/transport-capacity-warning.interface';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sb-transport-capacity-warning',
  templateUrl: './transport-capacity-warning.component.html',
  styleUrls: ['./transport-capacity-warning.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltipModule],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportCapacityWarningComponent {
  @Input() exceedCapacityByChangeRequests: ExceedCapacityByChangeRequest[];

  constructor(private datePipe: DatePipe) { }

  getMessagesTranslationParams(exceed: ExceedCapacityByChangeRequest) {
    return {
      capacity: exceed.currentCount + '/' + exceed.capacity,
      transportDate: this.datePipe.transform(exceed.transportDate, 'dd MMM y')
    };
  }

  getDetailTranslationParams(item: RelatedChangeRequest) {
    return {
      referenceNumber: item.referenceNumber,
      studentName: item.studentName,
      reason: item.reason || 'N/A',
      responseReason: item.responseReason || 'N/A',
      transportTime: this.datePipe.transform(item.transportTime, 'shortTime')
    }
  }
}


