'use strict';

angular.module('settingsModule')
    .component('onboardingBlockDatesConfirm',
    {
        templateUrl: '/Scripts/app/settings/components/onboarding/onboarding-blockdates-confirm.template.html',
        bindings: {
            close: '&',
            dismiss: '&'
        },
        controller: class OnboardingBlockDatesConfirmCtrl {

            // Bindings
            close: any;
            dismiss: any;

            constructor(){}

            ok() {
                this.close();
            };

            cancel() {
                this.dismiss({message: 'cancel'});
            };

        }
    });