import { Injectable } from '@angular/core';
import { formatCurrency, formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { DateFormats } from '@sb-shared/globals/date-formats';
import { FilterGroups } from '@sb-shared/models/UI/filter';
import { TableColumn, TableColumns, tableProperties } from '@sb-shared/models/UI/table';
import { UiClasses } from '@sb-shared/types/ui-classes';
import * as FileSaver from 'file-saver-es';
import { Observable, of, switchMap, tap } from 'rxjs';
import { FileTypeId } from './../globals/file-types';
import { Organisation } from './../models/organisation';
import { DataTableExport, DataTableExportPdf, DataTableExportRow } from './../types/data-table-export';
import { CssClassService } from './css-class.service';
import { DateTimeService } from './date-time.service';
import { FileService } from './file.service';
import { OrganisationService } from './organisation.service';
import { PdfService } from './pdf.service';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {

  constructor(
    private cssClassService: CssClassService,
    private organisationService: OrganisationService,
    private dateTimeService: DateTimeService,
    private fileService: FileService,
    private translateService: TranslateService,
    private pdfService: PdfService
  ) { }

  export(dataTableExport: DataTableExport, digitsInfo: string, fileName?: string): Observable<unknown> {
    return this.organisationService.getCurrentOrganisation().pipe(switchMap(org => {
      if (dataTableExport.fileTypeId === FileTypeId.PDF) {
        const exportData: unknown[] = [];
        dataTableExport.data.forEach((row: DataTableExportRow) => {
          const genericClassName = row.className;
          const exportDataRow: DataTableExportRow = {
            className: `${genericClassName} ${this.getRowStateClass(dataTableExport.columns, dataTableExport.rowStateColumnId, row)}`,
            cells: []
          };
          dataTableExport.columns.forEach(col => {
            const textClassSuffix = this.getClass(col, row);
            const textClass = textClassSuffix ? `text-${this.getClass(col, row)}` : '';
            // const genericClassName = row[col.id]?.className || '';
            exportDataRow.cells.push({
              label: this.getPdfLabel(col, row, org, digitsInfo),
              className: `${genericClassName} ${textClass}`,
              photoUrl: col.id === tableProperties.SpecialColumns.avatar.id ? row[col.id] : ''
            });
          });
          exportData.push(exportDataRow);
        })
        const dataTableExportPDF: DataTableExportPdf = {
          title: dataTableExport.title,
          supertitle: dataTableExport.supertitle,
          subtitle: dataTableExport.subtitle,
          descriptionTitle: dataTableExport.descriptionTitle,
          description: dataTableExport.description,
          dateString: this.dateTimeService.formatDate(new Date(), DateFormats.Date),
          currencySymbol: org.currencyDisplaySymbol,
          columns: dataTableExport.columns,
          data: exportData,
          brandColourPrimary: CommonChars.Hash + org.darkColour,
          brandColourSecondary: CommonChars.Hash + org.lightColour,
          logoUrl: org.webLogoPath
        }

        // Server-side PDF generation.
        return this.pdfService.generateTablePdf(dataTableExportPDF).pipe(tap(data => {
          FileSaver.saveAs(data, `${fileName}.pdf`);
        }));
      }
      else {
        const exportData = [dataTableExport.columns.map(col => col.name ? this.translateService.instant(col.name) : '')];
        dataTableExport.data.forEach((row: DataTableExportRow) => {
          const exportDataRow: any[] = [];
          dataTableExport.columns.forEach(col => {
            exportDataRow.push(this.getCsvCell(col, row, org, digitsInfo));
          });
          exportData.push(exportDataRow);
        })
        this.fileService.saveFile(exportData, dataTableExport.fileTypeId, fileName || dataTableExport.title || 'Data');
        return of(null);
      }
    }));
  }

  formatLabel(col: TableColumn, row: any, org: Organisation, digitsInfo: string) {
    const label = this.getLabel(col, row, digitsInfo);
    if (!label) {
      return '';
    }
    if (col.cellType === tableProperties.CellTypes.Currency) {
      return org.currencyDisplaySymbol + label;
    }
    if (col.dateFormat) {
      return this.dateTimeService.formatDate(this.getCellValue(col, row), col.dateFormat);
    }
    return label;
  }

  getColumnOption(column: TableColumn, row: any) {
    return column.options.find(option => option.id == row[column.id]);
  }

  getColumnItem(column: TableColumn, row: any) {
    return column.items.find(item => item.id == row[column.id]);
  }

  getLabel(column: TableColumn, row: any, digitsInfo: string): string {
    // Don't need to show label if content is inside popover
    if (column.cellType === tableProperties.CellTypes.Popover) {
      return null;
    }
    // Boolean items should be shown as icons with tooltips
    if (column.items && typeof row[column.id] == 'boolean') {
      return null;
    }
    // If defined options, return option label
    if (column.options) {
      const option = this.getColumnOption(column, row);
      if (option) {
        return option?.name || '';
      }
    }
    if (column.items) {
      const item = this.getColumnItem(column, row);
      if (item) {
        return item?.name || '';
      }
      else {
        return null;
      }
    }
    const cellValue = this.getCellValue(column, row);
    if (cellValue) {
      if (column.cellType == tableProperties.CellTypes.Currency) {
        if (cellValue.value == 0 && column.hideIfZero) {
          return null;
        }
        return formatCurrency(cellValue, this.dateTimeService.currentLocale, window.EveryBuddy.Constants.CurrencyDisplaySymbol, undefined, digitsInfo);
      }
      if (column.cellType == tableProperties.CellTypes.Numeric) {
        if (cellValue.value == 0 && column.hideIfZero) {
          return null;
        }
        return formatNumber(cellValue, this.dateTimeService.currentLocale);
      }
      return cellValue?.label || cellValue || '';
    }
    return '';
  }

  getTotalLabel(column: TableColumn, tableData: any, digitsInfo: string): string {
    let total = 0;
    tableData.forEach(row => {
      const cellValue = row[column.id];
      if (cellValue && (typeof cellValue.value == 'number' || !isNaN(Number(cellValue.value)))) {
        total += cellValue.value;
      }
    });
    if (column.cellType == tableProperties.CellTypes.Currency) {
      return formatCurrency(total, this.dateTimeService.currentLocale, window.EveryBuddy.Constants.CurrencyDisplaySymbol, undefined, digitsInfo);
    }
    if (column.cellType == tableProperties.CellTypes.Numeric) {
      return formatNumber(total, this.dateTimeService.currentLocale);
    }
    return total.toString();
  }

  getPopoverItemContent(item: any) {
    const tooltip = item.tooltip || item.iconTooltip;
    return `${this.translateService.instant(item.label)} ${tooltip ? `(${this.translateService.instant(tooltip)})` : ''}`;
  }

  getPdfLabel(column: TableColumn, row: any, org: Organisation, digitsInfo: string) {
    const value = this.getCellValue(column, row);
    if (!value) {
      return '';
    }
    if (column.cellType === tableProperties.CellTypes.Popover && Array.isArray(value)) {
      let html = '';
      value.forEach(item => {
        const string = `${this.getPopoverItemContent(item)}<br>`
        html += string;
      });
      return html;
    }
    else return this.formatLabel(column, row, org, digitsInfo);
  }

  getCsvCell(column: TableColumn, row: any, org: Organisation, digitsInfo: string) {
    const value = this.getCellValue(column, row);
    if (column.cellType === tableProperties.CellTypes.Popover && Array.isArray(value)) {
      const array = value.map(item => this.getPopoverItemContent(item));
      return array.toString();
    }
    else return this.formatLabel(column, row, org, digitsInfo);
  }

  getClass(column: TableColumn, row: any): UiClasses {
    // If defined options, return option class
    if (column.options) {
      const option = this.getColumnOption(column, row);
      if (option) {
        return option.class || this.cssClassService.getCssClass(option.classId) || '';
      }
    }
    const cellValue = this.getCellValue(column, row);
    if (cellValue) {
      return cellValue?.class || this.cssClassService.getCssClass(cellValue.classId) || '';
    }
    return null;
  }

  getRowStateClass(columns: TableColumns, rowStateColumnId: string, row: any): string {
    if (!rowStateColumnId) {
      return '';
    }
    const suffix = 'row-';
    const cellValue = row[rowStateColumnId];
    if (cellValue?.class) {
      return suffix + cellValue?.class;
    }
    const options = columns.find(col => col.id === rowStateColumnId).options;
    const optionClass = options.find(option => option.id == (cellValue.id || cellValue))?.class;
    if (optionClass) {
      return suffix + optionClass;
    }
    return '';
  }

  getInitialSearchParams(columns: TableColumns) {
    const searchParams = {};
    columns.filter(col => col.isFilterable).forEach(col => {
      searchParams[col.id] = col.defaultValue || (col.options ? -1 : '');
    });
    return searchParams;
  }

  getCellValue(column: TableColumn, row: any) {
    if (column.id === tableProperties.SpecialColumns.avatar.id) {
      return;
    }

    const columnId = parseInt(column.id) || column.id;

    return row[columnId];
  }

  isFilterOptionSelected(filterGroups: FilterGroups, itemId: string, optionId: string) {
    return filterGroups?.find(group => group.isExpanded)
      ?.filterItems?.find(item => item.id === itemId)?.options
      ?.find(option => option.id === optionId && option.isSelected) ?? false;
  }
}
