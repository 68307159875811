
<div class="input-group mb-4"
        [formGroup]="form"
        [hidden]="!currencySymbol">
        <span class="input-group-addon" [hidden]="hidePrefix">{{symbolPrefix}}{{currencySymbol}}</span>
        <input type="number" class="form-control"
                [disabled]="isDisabled"
                [name]="id"
                [required]="isRequired"
                [ngModel]="model"
                (change)="change()"
                [pattern]="currencyPattern"
                [formControlName]="id"
                [step]="step"
                [min]="min"
                (blur)="blur()"/>
</div>
<sb-loading [hasLoaded]="currencySymbol !== undefined"></sb-loading>