angular.module('tasks.services.taskService', [])
    .factory('taskService',
        [
            '$http', '$cacheFactory',
            function($http, $cacheFactory)
            {
                var urlBase = "/webapi/WebTasks/";
                var repository = {};

                var repoCache = $cacheFactory('taskServiceCache');

                repository.clearCache = function () {
                    repoCache.removeAll();
                };

                repository.getAllTasksForCase = function (caseId, options) {

                    var opts = {};

                    angular.extend(
                        opts, // dest
                        {
                            caseId: caseId,
                            showEveryonesTasks: false,
                            hideCompleted: true,
                            startDate: null,
                            endDate: null
                        }, // defaults
                        options); // passed options

                    return $http.get(urlBase + 'GetAllForCaseId', { params: opts, cache: repoCache })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getAllTasks = function (options) {

                    var opts = {};

                    angular.extend(
                        opts, // dest
                        {
                            showEveryonesTasks: false,
                            hideCompleted : true,
                            startDate: null,
                            endDate: null
                        }, // defaults
                        options); // passed options

                    return $http.get(urlBase + 'GetAllTasks', { cache: repoCache, params: opts })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getAllTasksForPerson = function (personId) {
                    return $http.get(urlBase + 'GetAllForPersonId', { params: { personId: personId }, cache: repoCache })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.getTaskById = function (taskId) {
                    return $http.get(urlBase + 'GetById', { params: { taskId: taskId }, cache: repoCache })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.saveTask = function(task)
                {
                    return $http.post(urlBase + 'Save', task)
                        .then(function (response) {
                            repository.clearCache();

                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.deleteTask = function(taskId)
                {
                    return $http.post(urlBase + 'FlagDeleted', taskId)
                        .then(function (response) {
                            repository.clearCache();

                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, errorData: e };
                        });
                };

                repository.markCompleted = function(task)
                {
                    return $http.post(urlBase + 'MarkCompleted', task)
                        .then(function(response)
                        {
                            repository.clearCache();

                            return response.data;
                        })
                        .catch(function(e)
                        {
                            return { isError: true, errorData: e };
                        });
                };

                return repository;
            }
        ]);