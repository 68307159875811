// start:ng42.barrel
import './onboarding-attendance-scanning-settings.component';
import './onboarding-blockdates-confirm.component';
import './onboarding-blockdates.component';
import './onboarding-checklist.component';
import './onboarding-online-parents-meeting.component';
import './onboarding-welcomemessage.component';
import './onboarding.component';
// end:ng42.barrel

