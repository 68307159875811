import { Component, Input } from '@angular/core';
import { EventFieldIds } from '@sb-events/constants/event-fields-ids';
import { formElements } from '@sb-shared/constants/shared.constant';
import { GameTeam } from '@sb-shared/models/game-team';
import { Option } from '@sb-shared/models/UI/filter';
import { UiItem } from '@sb-shared/models/UI/item';
import { WizardTabs } from '@sb-shared/models/UI/wizard-tabs';
import { ColoursService } from '@sb-shared/services/colours.service';
import { WizardService } from '@sb-shared/services/wizard.service';
import { Sizes } from '@sb-shared/types/sizes';
import { KitService } from './../../../sports-portal/services/kit.service';

@Component({
  selector: 'sb-game-summary',
  templateUrl: './game-summary.component.html',
  styleUrls: ['./game-summary.component.scss']
})
export class GameSummaryComponent {

  @Input() data: any;
  @Input() dataTabs: WizardTabs;
  @Input() squad: any;
  @Input() sport: Option;
  @Input() opponent: GameTeam;
  @Input() isAway: boolean;
  @Input() size: Sizes = 'lg';
  @Input() squadScore: string;
  @Input() opponentScore: string;
  @Input() result: UiItem;
  @Input() resultNote: string;

  team: GameTeam = {
    name: null,
    kitStyleId: 1,
    kitPrimaryHexColour: null,
    kitSecondaryHexColour: null
  };

  constructor(
    public colour: ColoursService,
    private wizard: WizardService,
    private kit: KitService
    ) {}

  ngOnInit() {
    this.kit.getKit().subscribe(kitDetails => {
      this.team = {
        ...this.team,
        kitPrimaryHexColour: kitDetails.homeKitPrimaryHexColour,
        kitSecondaryHexColour: kitDetails.homeKitSecondaryHexColour,
        kitStyleId: kitDetails.homeKitStyleId
      }
    })
  }

  getIsAway() {
    return this.isAway || (this.dataTabs && this.wizard.getAllFields(this.dataTabs)
      .find(field => field.id === EventFieldIds.Location)?.options
      .find(option => option.id === this.data[EventFieldIds.Location])?.groupingId === 2);
  }

  getTeams(): GameTeam[] {
    const squadName = this.squad?.name || this.getSquadName();
    const opponent = this.getOpponent();
    if (!squadName || !opponent) {
      return null;
    }
    const teams = [{
      ...this.team,
      name: squadName,
      score: this.squadScore
    }, {
      ...opponent,
      score: this.opponentScore,
      kitPrimaryHexColour: opponent.kitPrimaryHexColour || opponent.colours?.primaryColour,
      kitSecondaryHexColour: opponent.kitSecondaryHexColour || opponent.colours?.secondaryColour
    }]
    return this.getIsAway() ? teams.reverse() : teams;
  }

  getSquadName() {
    return this.squad || this.wizard.getAllFields(this.dataTabs)
    .find(field => field.id === EventFieldIds.Team)?.options
    .find(option => option.id === this.data[EventFieldIds.Team])?.name;
  }

  getOpponent() {
    const opponentObject = this.opponent;
    if (opponentObject) return opponentObject;
    if (this.dataTabs && this.data) {
      const allFields = this.wizard.getAllFields(this.dataTabs);
      const gameSummaryField = allFields.find(field => field.type === formElements.GameSummary);
      if (gameSummaryField.secondaryIds?.length > 2) {
        return this.wizard.applySecondaryFields(gameSummaryField, this.data, {});
      }
    }
    return null;
  }

  noScores() {
    return this.getTeams().every(team => team.score == '' || !team.score);
  }

}