'use strict';

angular.module('diary.components.icalLinkPopup',
    [
        'angular-clipboard',
        'pascalprecht.translate',
        'shared.components.sbIcon',
        'shared.components.sbModalHeader',
        'diary.services.diaryService'
    ])
    .component('icalLinkPopup',
        {
            bindings: {
                resolve: '<', // the weird way uibModal handles its resolutions- your properties will end up in here?
                close: '&',
                dismiss: '&'
            },
            templateUrl: '/Scripts/app/diary/components/ical-link-popup.template.html',
            controller: [
                '$translate',
                'diaryService',
                function ($translate, diaryService) {
                    this.icalLink = false;
                    this.supportLink = null;
                    this.translatedExplanation = null;
                    this.$onChanges = function (changes) {

                        if (changes.personId && changes.personId.currentValue) {
                            this.load();
                        }

                        if (changes.resolve && changes.resolve.currentValue && changes.resolve.currentValue.personId) {
                            this.personId = changes.resolve.currentValue.personId;
                            this.load();
                        }

                        if (changes.resolve && changes.resolve.currentValue && changes.resolve.currentValue.supportLink) {
                            this.supportLink = changes.resolve.currentValue.supportLink;
                            this.replaceSupportLink();
                        }
                    };

                    this.load = function () {
                        diaryService.getiCalLink(this.personId)
                            .then(function (link) {
                                if (!link.isError) {
                                    this.icalLink = link;
                                } else {
                                    throw (data);
                                }
                            }.bind(this));
                    };

                    this.ok = function () {
                        this.close({ $value: true });
                    }.bind(this);

                    this.cancel = function () {
                        this.dismiss({ $value: 'cancel' });
                    }.bind(this);

                    $translate.onReady().then(function () {
                        this.translatedExplanation = $translate.instant('SB_iCal_explanation_html');
                        this.replaceSupportLink();
                    }.bind(this));

                    this.replaceSupportLink = function(){
                        if (!this.translatedExplanation || !this.supportLink) {
                            // not ready until both these are available.
                            return;
                        }

                        this.translatedExplanation = this.translatedExplanation
                            .replace('##openlink', '<a href="' + this.supportLink + '" target="_blank">')
                            .replace('##closelink', '</a>')
                    }.bind(this);

                }]
        });