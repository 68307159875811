'use strict';

angular.module('receptionModule').component('receptionEventsDetails', {
    templateUrl: '/Scripts/app/reception/controllers/reception-events-details.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        onRefresh: '&'
    },
    controller: [
        '$scope', '$transition$',
        function ($scope, $transition$)
        {
            $scope.eventId = $transition$.params().eventId;

            $scope.eventUpdated = () => {
                this.onRefresh();
            };

            $scope.relatedEventUpdated = function(eventDetail) {

                for (var i = 0; i < $scope.events.length; i++) {
                    var thisEvent = $scope.events[i];

                    if (thisEvent.calendarEventId === eventDetail.id) {
                        thisEvent.from = eventDetail.from;
                        thisEvent.size = eventDetail.size;
                        thisEvent.title = eventDetail.title;
                        thisEvent.isDeleted = eventDetail.isDeleted;
                        thisEvent.isCancelled = eventDetail.isCancelled;
                        thisEvent.blockedOut = eventDetail.blockedOut;
                        break;
                    }
                }
            };
        }
    ]});