'use strict';

angular.module('shared.components.sbLabelList', [])
    .component('sbLabelList', {
        bindings: {
            list: '<',
            separator: '@',
            isTooltip: '<'
        },
        templateUrl: '/Scripts/app/shared/components/sb-label-list.template.html',
        controller: [
            function () {

                this.$onInit = function () {
                    if (!this.separator) {
                        this.separator = ' / ';
                    }
                }.bind(this);

            }
        ]
    });