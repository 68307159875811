angular.module('colours.services.colourService', [
    'shared.services.toastService',
])
.service('colourService', class ColourService {
        httpCoreApi: any;
        $document: any;

        settings: any;
        docStyle: any;

        static $inject = ['$document', 'httpCoreApi'];

        constructor ($document, httpCoreApi) {
            this.$document = $document;
            this.httpCoreApi = httpCoreApi;
            this.docStyle = this.$document[0].documentElement.style;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.SysAdmin
            }
        }

        getColours(orgId) {
            // By org Id, else user org

            return this.httpCoreApi.get('Colour', {...this.settings, orgId: orgId})
            .then(res => {
                return this.convertColours(res.data)
            });
        };

        updateColours(orgId, colours) {

            const data = {
                darkColour: colours.primaryColour,
                lightColour: colours.secondaryColour
            };

            return this.httpCoreApi.put('Colour', data,
            {
                ...this.settings,
                orgId: orgId,
                successMessage: 'SB_Colours_Updated',
                errorMessage: 'SB_Colours_Update_Error'
            });
        };

        setBrowserColours(colours) {
            this.docStyle.setProperty('--brand-primary', colours.primaryColour);
            this.docStyle.setProperty('--brand-secondary', colours.secondaryColour);
        };

        getBrowserColours() {
            return {
                primaryColour: this.docStyle.getPropertyValue('--brand-primary'),
                secondaryColour: this.docStyle.getPropertyValue('--brand-secondary')
            }
        }

        colourToHex(colour) {
            return '#' + colour;
        }

        hexToColour(hex) {
            return hex.replaceAll('#','');
        }

        convertColours(colours) {
            if (!colours) {
                return {}
            }
            return {
                primaryColour: this.colourToHex(colours.darkColour),
                secondaryColour: this.colourToHex(colours.lightColour)
            };
        }
    }
);
