import { Tabs } from '@sb-shared/models/UI/menu-item';
import { IconService } from '@sb-shared/services/icon.service';
import { Component, ElementRef, OnInit, QueryList, ViewChildren, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MenuItems, SubMenuItems } from '../../models/UI/menu-item';
import { MenuService } from '../../services/menu.service';
import { filter, tap, take, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ComponentBase } from '../component-base/component-base';
import { Observable } from 'rxjs';

@Component({
  selector: 'sb-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent extends ComponentBase implements OnInit, OnChanges {

  isActiveElementFound = false;
  currentMainMenuItemId = 0;
  subMenuItems: SubMenuItems = [];
  thirdLevelItems: SubMenuItems = [];
  currentRouteUrl: string;
  currentPageMenuItems: SubMenuItems;
  rlaClass = 'buddy-nav__item--active';
  pageTabs: Tabs;
  showSubMenu$: Observable<boolean>;

  @Input() outlet: string;
  @Input() pageMenuItems: MenuItems = [];
  @Input() isThirdLevel: boolean;
  @ViewChildren('menuItemLi', { read: ElementRef }) subMenuItemElements: QueryList<ElementRef> | undefined;

  constructor(
    private menu: MenuService,
    private icon: IconService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.menu.getSubMenuItems().pipe(take(1))
      .pipe(tap(subMenuItems => {
        this.subMenuItems = subMenuItems.map(subMenuItem => {
          return {
            ...subMenuItem,
            routerLink: subMenuItem.route,
            elementId: `subMenuItem-${subMenuItem.id}`
          }
        })
      }))
      .subscribe(subMenuItems => {
        this.router.events.pipe(filter(e => (e instanceof NavigationEnd)), takeUntil(this.destroyed$))
          .subscribe((val: unknown) => {
            if (this.isThirdLevel) {
              const secondLevelRoute = subMenuItems.find(menuItem => (val as RouterEvent).url.substring(1) === menuItem.route);
              this.thirdLevelItems = secondLevelRoute?.children;
            }
          });
      });

    this.menu.currentMainMenuItem.pipe(takeUntil(this.destroyed$)).subscribe(mainMenuItem => {
      this.currentMainMenuItemId = mainMenuItem?.menuItemTypeId;
      const menuItemArray = this.subMenuItemElements?.toArray();
      const currentMenuElement = menuItemArray?.find(element => {
        return [...element.nativeElement.classList].includes(this.rlaClass);
      });
      if (currentMenuElement) {
        const currentNativeElement = currentMenuElement.nativeElement;
        currentNativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
      this.currentRouteUrl = mainMenuItem?.route;
      this.menu.currentPageMenuItems.pipe(takeUntil(this.destroyed$)).subscribe(items => {
        this.currentPageMenuItems = items
      });
    });

    this.showSubMenu$ = this.menu.showSubMenu$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.pageMenuItems || changes.outlet) && this.pageMenuItems && this.outlet) {
      // Set router outlet for menu
      this.pageMenuItems = this.pageMenuItems
        .map(item => {
          const routerLink = { outlets: {} };
          routerLink.outlets[this.outlet || 'primary'] = item.route;
          return { ...item, routerLink: [routerLink] }
        })
    }
  }

  visibleSubMenuItems(): SubMenuItems {
    let items: SubMenuItems;
    if (this.isThirdLevel && this.thirdLevelItems?.length > 1) {
      items = this.thirdLevelItems;
    }
    if (this.currentPageMenuItems?.length > 1) {
      // Dynamically-set menu items, such as CCA type-specific group menu items
      items = this.currentPageMenuItems;
    }
    this.pageMenuItems?.length > 0 ?
      items = this.pageMenuItems.filter(item => {
        return !item.isHidden;
      }) :
      items = this.subMenuItems.filter(menuItem => {
        return menuItem.parentId === this.currentMainMenuItemId;
      });
    return items.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  getIcon(item: any, isActive: boolean, isMain?: boolean) {
    return this.icon.getStyledIcon(item, isActive, isMain);
  }

  // More button

  pushToMore(index: number) {
    const visibleItems = this.visibleSubMenuItems();
    if (visibleItems[index].isImportant) {
      return false;
    }
    const importantItemsCount = visibleItems.filter(item => item.isImportant).length;
    return index > (9 - importantItemsCount);
  }

  moreItems() {
    return this.visibleSubMenuItems().filter((item, index) => this.pushToMore(index));
  }
}
