'use strict';

angular.module('transport.configuration.transportDirections', [
    'transport.services.transportDirectionsService',
    'shared.filters.decoded',
    'shared.components.sbSubmitButton',
]).component('transportDirections', {
    templateUrl: '/Scripts/app/transport/configuration/transport-directions.template.html',
    bindings: {
    },
    controller: [
        'transportDirectionsService',
        'simpleAlertService',
        '$timeout',
        function transportDirectionsController(transportDirectionsService, simpleAlertService, $timeout)
        {
            this.anyChanges = false;
            this.transportDirections = null;
            this.isSubmitted = false;

            this.$onInit = function ()
            {
                this.loadTransportDirections();
            }.bind(this);

            var resetSubmitButtons = function ()
            {
                $timeout(function ()
                {
                    this.isSubmitted = false;
                }.bind(this), 200);

            }.bind(this);

            this.loadTransportDirections = function ()
            {
                transportDirectionsService.getTransportDirections().then(function (data)
                {
                    this.transportDirections = data;
                    this.anyChanges = false;
                    resetSubmitButtons();

                }.bind(this), function (error)
                {
                    simpleAlertService.errorAlert(error);
                }.bind(this));

            }.bind(this);

            this.saveTransportDirections = function ()
            {
                transportDirectionsService.saveTransportDirections(this.transportDirections).then(function (data)
                {
                    this.loadTransportDirections();
                    resetSubmitButtons();
                }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert({ message: error.data.Message });
                        resetSubmitButtons();
                });
            }.bind(this);

            this.changesMade = function ()
            {
                this.anyChanges = true;
            }
        }
    ]
});