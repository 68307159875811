'use strict';

angular.module('transport.pupilPreferences.components.confirmDeletePreferences', [
])
    .component('confirmDeletePreferences',
        {
            bindings: {
                modalInstance: '<',
                resolve: '<'
            },
            templateUrl: '/Scripts/app/transport/pupilPreferences/components/confirm-delete-preferences.template.html',
            controller: [function ()
            {
                this.pupil = null;
                this.pupilWhoPreferencesBeingDeletedFor = '';
                this.selectionDescription = '';

                this.$onChanges = function (changes)
                {
                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.pupil = changes.resolve.currentValue.pupil;
                        this.pupilWhoPreferencesBeingDeletedFor = this.pupil.pupilFullName;
                        this.selectionDescription = changes.resolve.currentValue.selectionDescription;
                    }
                }.bind(this);

                this.delete = function ()
                {
                    closeModal();
                }.bind(this);

                this.cancel = function ()
                {
                    this.modalInstance.dismiss('cancel');
                }.bind(this);

                var closeModal = function ()
                {
                    this.modalInstance.close();
                }.bind(this);
            }]
        });