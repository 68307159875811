'use strict';

angular.module('events.components.notifyOnlyEvent',
    [
        'events.services.eventsService',
        'shared.services.blobStoragePhotosService',
        'shared.services.simpleAlertService',
    ])
    .component('notifyOnlyEvent', {
        templateUrl: '/Scripts/app/events/components/notify-only-event.template.html',
        bindings: {
            eventId: '<',
            numberOfFutureEventsInSeries: '<',
        },
        controller: [
            '$filter',
            '$translate',
            '$uibModal',
            'blobStoragePhotosService',
            'eventsService',
            'signUpRepository',
            'simpleAlertService',
            function notifyOnlyEventController($filter, $translate, $uibModal, blobStoragePhotosService, eventsService, signUpRepository, simpleAlertService)
            {
                this.numberOfPeopleNotified = 0;
                this.showAllMembersText = false;

                //Table Sorting
                this.sortType = 'name';
                this.sortReverse = false;
                this.students = [];
                this.audienceTypes = [];
                this.selectedNotifyAudienceTypeId = null;
                this.currentNotifyAudienceTypeId = null;
                this.isUpdateAndNotifyEnabled = false;

                this.exportInfo = [];
                this.exportData = [];

                $translate.onReady().then(function ()
                {
                    this.eventLabel = $filter('translate')('SB_Event');

                    this.exportTableHeaders = [
                        $filter('translate')('SB_Name'),
                        $filter('translate')('SB_Diary_And_Register'),
                        $filter('translate')('SB_Notified')
                    ];
                }.bind(this));

                this.$onChanges = function (changes)
                {
                    if (changes.eventId && changes.eventId.currentValue)
                    {
                        this.loadAudienceTypes(this.eventId);
                        this.loadStudents(this.eventId);
                    }
                }.bind(this);

                this.loadStudents = function (eventId)
                {
                    eventsService.getStudentsForNotifyOnlyEvent(eventId)
                        .then(function (response)
                        {
                            this.students = response.invitees;

                            this.currentNotifyAudienceTypeId = response.notifyAudienceTypeId;
                            if (this.currentNotifyAudienceTypeId == null)
                            {
                                this.selectedNotifyAudienceTypeId = 3; // Default to Parent & Student
                            }
                            else
                            {
                                this.selectedNotifyAudienceTypeId = this.currentNotifyAudienceTypeId;
                            }

                            this.numberOfPeopleNotified = this.students.filter(function (student)
                            {
                                return student.isNotified && student.calendarEventAttendeeStatusId == 2;
                            }).length;

                            this.students = this.students.filter(function (student)
                            {
                                if (student.calendarEventAttendeeStatusId == 3)
                                {
                                    student.confirmedTimestamp = student.lastUpdated;
                                }

                                return student;
                            });

                            this.showAllMembersText = this.numberOfPeopleNotified == this.students.length;

                            this.enableOrDisableUpdateAndNotify();

                            blobStoragePhotosService.addStudentPhotoUrlsToArray(this.students)
                                .then((array) =>
                                {
                                    this.students = array;
                                });

                            var yes = $filter('translate')('SB_Yes');
                            var no = $filter('translate')('SB_No');
                            var removed = $filter('translate')('SB_Removed');

                            angular.forEach(this.students, function (student)
                            {
                                var isAttending = student.hasAccepted && student.isNotified && student.calendarEventAttendeeStatusId == 2;
                                var isRemoved = student.calendarEventAttendeeStatusId == 3;
                                var notified = '';
                                if (isAttending)
                                {
                                    notified = $filter('date')(student.confirmedTimestamp, 'dd MMM HH:mm');
                                }
                                else if (isRemoved)
                                {
                                    notified = removed + ': ' + $filter('date')(student.confirmedTimestamp, 'dd MMM HH:mm');
                                }

                                this.exportData.push([
                                    student.name.replace(',', ' '),
                                    isAttending ? yes : no,
                                    notified
                                ]);
                            }.bind(this));

                            eventsService.getEventBasics(eventId)
                                .then(function (response)
                                {
                                    this.exportInfo.push(
                                        {
                                            label: $filter('translate')('SB_Event'),
                                            text: response.title
                                        });

                                    this.pageTitle = this.eventLabel + ': ' + response.title;
                                }.bind(this))
                                .catch(function (error)
                                {
                                    simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                                }.bind(this));

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));

                }.bind(this);

                this.toggleNotifyStudent = function (student)
                {
                    if (student.hasAccepted && student.isNotified)
                    {
                        return;
                    }

                    student.hasAccepted = !student.hasAccepted;

                    this.enableOrDisableUpdateAndNotify();

                }.bind(this);

                this.enableOrDisableUpdateAndNotify = function ()
                {
                    if (this.students.filter(function (student)
                    {
                        return student.hasAccepted && !student.isNotified;
                    }).length > 0)
                    {
                        this.isUpdateAndNotifyEnabled = true;
                    }
                    else
                    {
                        this.isUpdateAndNotifyEnabled = false;
                    }
                }.bind(this);

                this.loadAudienceTypes = function (eventId)
                {
                    eventsService.getNotifyAudienceTypes(false)
                        .then(function (response)
                        {
                            this.audienceTypes = response.notifyAudienceTypes;

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));
                }.bind(this);

                this.toggleSelectAll = function (selectAll)
                {
                    this.students.forEach(function (student)
                    {
                        if (!student.isNotified)
                        {
                            student.hasAccepted = selectAll;
                        }
                    });

                    this.enableOrDisableUpdateAndNotify();

                }.bind(this);

                this.updateAndNotify = function ()
                {
                    var numberToUpdateAndNotify = this.students.filter(function (student)
                    {
                        return student.hasAccepted && !student.isNotified;
                    }).length;

                    if (numberToUpdateAndNotify == 0)
                    {
                        return;
                    }

                    if (typeof this.selectedNotifyAudienceTypeId === 'undefined' || this.selectedNotifyAudienceTypeId == null)
                    {
                        return;
                    }

                    var studentsToUpdateAndNotify = this.students.filter(function (student)
                    {
                        return student.hasAccepted && !student.isNotified;
                    })

                    eventsService.updateAndNotifyInvitees(this.eventId, studentsToUpdateAndNotify, null, null, this.selectedNotifyAudienceTypeId)
                        .then(function (response)
                        {
                            this.loadStudents(this.eventId);

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));

                }.bind(this);

                this.removeFromEvent = function (student)
                {
                    var removeStudentFromEventModal = $uibModal.open({
                        animation: true,
                        component: 'confirmRemoveStudentFromEvent',
                        size: 'sm',
                        backdrop: 'static',
                        resolve:
                        {
                            student: function ()
                            {
                                return student;
                            }
                        }
                    });

                    removeStudentFromEventModal.result
                    .then(function ()
                    {
                        var includeLinkedEvents = false;
                        var refundCreditAction = 0; // Refund Credit Action 0 = Nothing. Not required to be set for Notify Only events

                        eventsService.removePupilFromEvent(student.personId, this.eventId, includeLinkedEvents, refundCreditAction)
                            .then(function ()
                            {
                                this.loadStudents(this.eventId);
                            }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                            }.bind(this));
                    }.bind(this),
                    function ()
                    {
                    });

                }.bind(this);
            }
        ]
    });