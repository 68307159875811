'use strict';

angular.module('location.components.locationLite',
        [
            'pascalprecht.translate',
            'shared.services.installationSettingsService'
        ])
    .component('locationLite',
        {
            bindings: {
                location: '<'
            },
            templateUrl: '/Scripts/app/location/components/location-lite.template.html',
            controller: ['installationSettingsService',
                function (installationSettingsService)
                {
                    this.$onInit = function () {
                        if (this.location && this.location.postCode) {
                            installationSettingsService.getMapsUrl()
                            .then(function (url) {
                                if (url && !url.isBaidu) {
                                    var locationString = '';
                                    if (this.location.name) {
                                        locationString += this.location.name + '+';
                                    }
                                    if (this.location.address) {
                                        locationString += this.location.address + '+';
                                    }
                                    locationString += this.location.postCode;
                                    url.value = url.value.replace('{{locationstring}}', locationString);
                                    this.mapsUrl = url;
                                }
                            }.bind(this));
                        }
                    };
            }]
        });