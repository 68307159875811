'use strict';

angular.module('shared.components.sbStackedDate', [])
    .component('sbStackedDate',
        {
            bindings: {
                date: '<',
                includeTime: '<'
            },
            template: '<div class="summary__media  text-center"><p class="text-uppercase p-small"><strong>{{$ctrl.theDate | date : \'EEE\' }}</strong></p><strong class="h2">{{$ctrl.theDate | date : \'dd\' }}</strong><p class="text-uppercase p-small">{{$ctrl.theDate | date : \'MMMM\' }}</p><p class="p-small" ng-show="$ctrl.includeTime">{{$ctrl.theDate | date : \'HH:mm\' }} </p></div>',
            controller: [
                function () {
                    this.weekDay = null;
                    this.dateDay = 0;
                    this.monthName = null;
                    this.time = null;

                    this.$onChanges = function (changes) {
                        if (changes.date && changes.date.currentValue) {
                            if (changes.date.currentValue instanceof moment) {
                                this.theDate = changes.date.currentValue.toDate();
                            } else {
                                this.theDate = changes.date.currentValue;
                            }
                        }
                    };
                }]
        });
