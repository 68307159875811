angular.module('shared.directives.ngBindHtmlDataLabelAttr', [])
    .directive('ngBindHtmlDataLabelAttr', ['$sce','$filter',
        function ($sce, $filter) {
            return {
                restrict: 'A',
                link: function ($scope, element, attrs) {
                    var div: any = document.createElement('div');

                    attrs.$observe('ngBindHtmlDataLabelAttr', function (value) {
                        div.innerHTML = value;                      
                        element.attr('data-label', $sce.trustAsHtml(div.textContent));
                    });
                }
            };
    }]);