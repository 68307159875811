<div class="title-bar"
     [ngClass]="{
         'pl-2': isSecondary,
         'title-bar--top' : isTop
        }">
    <div class="title-bar__main">
        <div class="title-bar__title-area">
            <button class="btn btn-auto btn-link btn-back"
                    (click)="back()"
                    *ngIf="showBack && hasHistory()">
                <sb-icon name="arrow-left"
                         class="text-muted"></sb-icon>
            </button>
            <h3 class="title-bar__title"
                [ngClass]="isSecondary ? 'p-15' : 'p-large'">
                <ng-content></ng-content>
                <span [innerHTML]="barTitle | translate"
                      [ngClass]="{'hidden-xs' : barTitleXs}"></span>
                <span [innerHTML]="barTitleXs | translate"
                      *ngIf="barTitleXs"
                      class="hidden-lg hidden-md hidden-sm"></span>
                <span *ngIf="denominator"
                      class="ml-3 text-muted normal">
                    <span *ngIf="numerator !== undefined">{{numerator}} / </span>
                    <span [innerHTML]="denominator"></span>
                </span>
            </h3>
        </div>
        <div class="title-bar__utility">
            <sb-button *ngFor="let button of buttons; let i = index"
                              [buttonClass]="button.buttonClass || 'link'"
                              [customClasses]="button.customClasses"
                              [iconName]="button.iconName"
                              [message]="button.message"
                              [xsIconOnly]="button.xsIconOnly"
                              iconStyle="regular"
                              [isDisabled]="button.isDisabled"
                              [id]="button.buttonId"
                              [dropdownItems]="button.dropdownItems"
                              [isLoading]="button.isLoading"
                              (onClick)="buttonClick(i, $event)"></sb-button>
        </div>
        <!-- <ng-content *ngIf="transcludeInline"></ng-content> -->
    </div>
    <div>
        <div class="title-bar__description"
            *ngIf="description"
            [innerHTML]="description | translate">
        </div>
        <!-- <div *ngIf="!transcludeInline" ng-content></div> -->
    </div>
    <div class="title-bar__link"
         *ngIf="(linkUrl || linkRoute) && linkText"
         (click)="openLink()">
         <button class="btn btn-link">
            <span [innerHTML]="linkText | translate"
                  [ngClass]="{'hidden-xs' : linkTextXs}"></span>
            <span *ngIf="linkTextXs"
                  [innerHTML]="linkTextXs | translate"
                  class="visible-xs"></span>
            <sb-icon name="chevronRight"
                     class="ml-1"></sb-icon>
         </button>
    </div>
</div>