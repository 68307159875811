'use strict';

angular.module('transport.pupilPreferences.components.massAssignmentMethodsAndTimetables',
    [
        'transport.services.transportTimetablesService',
        'transport.services.transportTimetableCalendarsService',
        'shared.services.simpleAlertService',
        'shared.directives.sbCheckBox',
        'shared.directives.sbLoading',
    ])
    .component('massAssignmentMethodsAndTimetables',
        {
            bindings: {
                timeSlotId: '<',
                isDeletedCalendars: '<',
                methodId: '=',
                timetable: '=',
                timetableRouteId: '=',
                methodSupportsRoutes: '=',
                enableAddPupilsButton: '=',
                noRoutesConfigured: '=',
                noRouteSelected: '=',
                routeId: '=',
                capacity: '=',
                selectedRoute: '=',
                selectionDescription: '=',
                operatingDays: '=',
                preferencesLoadInProgress: '<',
                preferencesUpdateInProgress: '<',
                onTransportSelectionMade: '&',
                isFromMultiPersonPicker: '<',
                timetableToExcludeId: '<',
                timetableRouteToExcludeId: '<',
                selectedTimetableCalendarName: '=',
                isLoadingComplete: '=',
                isCalendarLoadingMessenger: '&'
            },
            templateUrl: '/Scripts/app/transport/pupilPreferences/components/mass-assignment-methods-and-timetables.template.html',
            controller: [
                '$timeout',
                'transportTimetablesService',
                'transportTimetableCalendarsService',
                'simpleAlertService',
                function ($timeout, transportTimetablesService, transportTimetableCalendarsService, simpleAlertService)
                {
                    this.slot = null;
                    this.searchText = '';
                    this.timetables = null;
                    this.selectedMethodId = null;
                    this.selectedTimetableRouteId = null;
                    this.capacity = null;
                    this.transportTimetableCalendars = [];
                    this.selectedCalendarId = 0;
                    this.loadFailureMessage = null;
                    this.isLoadingComplete = false;

                    this.transportSelection =
                    {
                        timetable: null,
                        operatingDays: [],
                        route: null,
                        timetableRouteId: null,
                        methodId: null,
                        selectionDescription: '',
                        capacity: null
                    };

                    this.noRoutesConfigured = false;
                    this.noRouteSelected = false;

                    this.$onChanges = function (change)
                    {
                        this.loadFailureMessage = null;

                        if (change.timeSlotId || change.isDeletedCalendars)
                        {
                            this.transportTimetableCalendars = [];
                            this.isLoadingComplete = false;
                            this.isCalendarLoadingMessenger({ result: !this.isLoadingComplete });

                            if (this.timeSlotId != null)
                            {
                                this.timetables = null;
                                this.methodId = null;

                                this.transportSelection =
                                {
                                    timetable: null,
                                    operatingDays: [],
                                    route: null,
                                    timetableRouteId: null,
                                    methodId: null,
                                    selectionDescription: '',
                                    capacity: null
                                };

                                this.timetableRouteId = null;
                                this.routeId = null;
                                this.capacity = null;
                                this.selectedRoute = null;
                                this.selectedTimetableRouteId = null;
                                this.enableAddPupilsButton = false;
                                this.selectionDescription = '';
                                this.noRoutesConfigured = false;
                                this.noRouteSelected = false;
                                this.selectedTimetableCalendarName = '';

                                //Regardless of which timeslot is selected, on load, all not deleted calendars are displayed in the tabs. Then when a calendar is selected, it only has a list of timetables if
                                // the calendar is used by timetables that also use the selected timeslot.
                                // When changing the checkbox values to show deleted or not deleted calendars, if change to deleted, the calendars are reloaded to only bring through deleted calendars and vice-versa.
                                // So need to reset the selected calendar id value as it will now be a different set of calendars (either deleted or not deleted) and so non will correspond to the already selected id.

                                if (change.isDeletedCalendars) {
                                    this.selectedCalendarId = 0;
                                }

                                transportTimetableCalendarsService.getTransportTimetableCalendars(this.isDeletedCalendars)
                                    .then(function (calendars)
                                    {
                                        var timetableCalendars = calendars;

                                        transportTimetablesService.getTimetablesForTimeSlot(this.timeSlotId, this.isDeletedCalendars)
                                            .then(function (timetables)
                                            {
                                                this.timetables = timetables;

                                                timetableCalendars.forEach(function (calendar)
                                                {
                                                    var transportTimetableCalendar =
                                                    {
                                                        id: calendar.id,
                                                        name: calendar.name,
                                                        isDefault: calendar.isDefault,
                                                        dateRanges: calendar.dateRanges,
                                                        timetables: [],
                                                        isActiveTab: false,
                                                        isDeleted: calendar.isDeleted,
                                                    };


                                                    this.timetables.forEach(function (timetable)
                                                    {
                                                        if (timetable.transportTimetableCalendarId == calendar.id)
                                                        {
                                                            transportTimetableCalendar.timetables.push(timetable);
                                                        }
                                                    });

                                                    this.transportTimetableCalendars.push(transportTimetableCalendar);

                                                }.bind(this));

                                                const defaultSelectedTimetableCalendar = (this.transportTimetableCalendars.find(c => c.isDefault) ?? this.transportTimetableCalendars[0]);
                                                this.selectedTimetableCalendarName = defaultSelectedTimetableCalendar?.name;

                                                $timeout(function ()
                                                {
                                                    if (this.transportTimetableCalendars.length > 0)
                                                    {
                                                        this.transportTimetableCalendars.forEach(c => {
                                                            c.isActiveTab = false;
                                                        });

                                                        if (this.selectedCalendarId <= 0) {
                                                            this.selectedCalendarId = defaultSelectedTimetableCalendar.id;
                                                    }

                                                        if (this.selectedCalendarId > 0) {
                                                            this.transportTimetableCalendars.find(c => c.id == this.selectedCalendarId).isActiveTab = true;
                                                        }
                                                    }

                                                    this.isLoadingComplete = true;
                                                    this.isCalendarLoadingMessenger({ result: !this.isLoadingComplete });

                                                }.bind(this), 200);

                                            }.bind(this))
                                            .catch(function (error)
                                            {
                                                simpleAlertService.errorAlert({ message: error.data.Message });
                                                this.loadFailureMessage = error.data.Message;
                                                this.isLoadingComplete = true;
                                                this.isCalendarLoadingMessenger({ result: !this.isLoadingComplete });

                                            }.bind(this));

                                    }.bind(this))
                                    .catch(function (error)
                                    {
                                        simpleAlertService.errorAlert({ message: error.data.Message });
                                        this.loadFailureMessage = error.data.Message;
                                        this.isLoadingComplete = true;
                                        this.isCalendarLoadingMessenger({ result: !this.isLoadingComplete });

                                    }.bind(this));
                            }

                            if (change.selectedRoute)
                            {
                                selectedRoute.highestNumberOfStudentPreferencesForAnyDay = change.selectedRoute.highestNumberOfStudentPreferencesForAnyDay;
                            }
                        }
                    }.bind(this);

                    this.selectTimetable = function (timetable)
                    {
                        this.methodId = timetable.methodId;
                        this.selectedMethodId = timetable.methodId;
                        this.methodSupportsRoutes = timetable.methodSupportsRoutes;
                        this.selectedTimetableId = timetable.id;
                        this.timetable = timetable;
                        this.operatingDays = timetable.operatingDays;
                        this.selectionDescription = timetable.methodName;

                        // If a timetable that supports routes has been selected, set a property to enable/disable the Add button
                        this.enableAddPupilsButton = !this.methodSupportsRoutes;

                        this.noRoutesConfigured = false;
                        this.noRouteSelected = false;

                        if (timetable.methodSupportsRoutes &&
                            (timetable.timetableRoutes == null || timetable.timetableRoutes.length == 0))
                        {
                            this.noRoutesConfigured = true;
                        }

                        if (timetable.methodSupportsRoutes &&
                            timetable.timetableRoutes != null &&
                            timetable.timetableRoutes.length > 0)
                        {
                            this.noRouteSelected = true;
                        }

                        this.timetableRouteId = null;
                        this.selectedTimetableRouteId = null;
                        this.routeId = null;
                        this.selectedRoute = null;

                        this.transportSelection =
                        {
                            timetable: this.timetable,
                            operatingDays: this.timetable.operatingDays,
                            route: null,
                            timetableRouteId: null,
                            methodId: this.timetable.methodId,
                            selectionDescription: this.timetable.methodName,
                            capacity: null
                        };

                        if (this.onTransportSelectionMade && typeof (this.onTransportSelectionMade()) === 'function')
                        {
                            this.onTransportSelectionMade()(this.transportSelection);
                        }
                    };

                    this.selectRoute = function (timetable, timetableRoute)
                    {
                        this.methodId = timetable.methodId;
                        this.selectedMethodId = timetable.methodId;
                        this.timetable = timetable;
                        this.methodSupportsRoutes = timetable.methodSupportsRoutes;
                        this.enableAddPupilsButton = true;
                        this.timetableRouteId = timetableRoute.id;
                        this.routeId = timetableRoute.routeId;
                        this.capacity = timetableRoute.capacity;
                        this.selectedRoute = timetableRoute;
                        this.selectedTimetableId = timetable.id;
                        this.selectedTimetableRouteId = timetableRoute.id;
                        this.selectionDescription = timetable.methodName + ' > ' + timetableRoute.routeName + ' (' + timetableRoute.timetableRouteName + ')';
                        this.operatingDays = timetable.operatingDays;
                        this.noRoutesConfigured = false;
                        this.noRouteSelected = false;

                        this.transportSelection =
                        {
                            timetable: this.timetable,
                            operatingDays: this.timetable.operatingDays,
                            route: this.selectedRoute,
                            timetableRouteId: this.selectedRoute.id,
                            methodId: this.timetable.methodId,
                            selectionDescription: this.timetable.methodName + ' > ' + this.selectedRoute.routeName + ' (' + this.selectedRoute.timetableRouteName + ')',
                            capacity: this.selectedRoute.capacity
                        };

                        if (this.onTransportSelectionMade && typeof (this.onTransportSelectionMade()) === 'function')
                        {
                            this.onTransportSelectionMade()(this.transportSelection);
                        }
                    };

                    this.showTimetable = function (timetable)
                    {
                        if (typeof this.isFromMultiPersonPicker !== 'undefined' && this.isFromMultiPersonPicker)
                        {
                            if ((typeof this.timetableRouteToExcludeId === 'undefined' || this.timetableRouteToExcludeId == null || this.timetableRouteToExcludeId == 0) &&
                                typeof this.timetableToExcludeId !== 'undefined' && this.timetableToExcludeId == timetable.id)
                            {
                                return false;
                            }

                            if (typeof this.timetableRouteToExcludeId !== 'undefined' && this.timetableRouteToExcludeId != null && this.timetableRouteToExcludeId > 0 &&
                                typeof this.timetableToExcludeId !== 'undefined' && this.timetableToExcludeId == timetable.id)
                            {
                                var remainingRoutes = timetable.timetableRoutes.filter(function (route)
                                {
                                    return route.id != this.timetableRouteToExcludeId;
                                }.bind(this));

                                if (remainingRoutes.length == 0)
                                {
                                    return false;
                                }
                            }

                            if (timetable.methodSupportsRoutes && timetable.timetableRoutes.length == 0)
                            {
                                return false;
                            }
                        }

                        if (this.searchText == '')
                        {
                            return true;
                        }

                        if (typeof timetable.timetableRoutes !== 'undefined' && timetable.timetableRoutes.length > 0)
                        {
                            var matchingRoutes = timetable.timetableRoutes.filter(function (route)
                            {
                                return route.timetableRouteName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0 ||
                                    route.routeName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0;
                            }.bind(this));

                            if (matchingRoutes.length > 0)
                            {
                                return true;
                            }

                            for (var i = 0; i < timetable.timetableRoutes.length; i++)
                            {
                                var stops = this.isDeletedCalendars ? timetable.timetableRoutes[i].allStopNames : timetable.timetableRoutes[i].activeStopNames;

                                var matchingStops = stops.filter(function (stopName)
                                {
                                    return stopName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0;
                                }.bind(this));

                                if (matchingStops.length > 0)
                                {
                                    return true;
                                }
                            }
                        }

                        return false;
                    }.bind(this);

                    this.showRoute = function (timetableRoute)
                    {
                        if (typeof this.isFromMultiPersonPicker !== 'undefined' && this.isFromMultiPersonPicker &&
                            typeof this.timetableRouteToExcludeId !== 'undefined' && this.timetableRouteToExcludeId == timetableRoute.id)
                        {
                            return false;
                        }

                        return true;
                    }.bind(this);

                    this.tabSelected = function (calendarId)
                    {
                        this.transportTimetableCalendars.forEach(function (calendar)
                        {
                            if (calendar.id == calendarId)
                            {
                                calendar.isActiveTab = true;
                                this.selectedTimetableCalendarName = calendar.name;
                            }
                            else
                            {
                                calendar.isActiveTab = false;
                            }
                        }.bind(this));

                        this.selectedCalendarId = calendarId;
                    }.bind(this);
                }
            ]
        });