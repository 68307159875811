import { FileType } from "@sb-shared/models/file-type";

export enum FileTypeId {
    PDF = 1,
    CSV = 2
}

export const FileTypes: FileType[] = [
    { 
        id: FileTypeId.PDF,
        label: 'PDF',
        mime: 'application/pdf',
        format: '.pdf',
        iconName: 'file-pdf',
        iconClass: 'file-pdf'
    },
    { 
        id: FileTypeId.CSV,
        label: 'CSV',
        mime: 'text/csv;charset=utf-8',
        format: '.csv',
        iconName: 'file-excel',
        iconClass: 'file-excel'
    }
]