<div class="card-detail-container h-100"
     [ngClass]="{'card-detail-container--expanded' : isExpanded}"
     id="cardDetailContainer"
     *ngIf="selectedValue">
    <div class="card-detail h-100">
        <div class="card-detail-image"
             [style.background-image]="(selectedValue.background || backgroundUrl) ? 'url(&quot;' + (selectedValue.background || backgroundUrl) + '&quot;)' : ''">
            <div class="card-detail__cover">
                <div class="card-detail__main">
                    <div class="card-detail__item-heading align-center">
                        <div class="align-center">
                            <sb-button *ngIf="showBack || showBackSm"
                                       buttonClass="link"
                                       customClasses="mr-1 p-large btn-auto"
                                       iconClasses="text-light"
                                       iconName="arrow-left"
                                       (onClick)="back()"
                                       [ngClass]="{'visible-xs visible-sm' : showBackSm && !showBack}"></sb-button>
                            <h2 [innerHTML]="selectedValue.name || selectedValue.title"></h2>
                            <sb-badge *ngIf="selectedValue.badge"
                                      [badgeClass]="selectedValue.badge.class"
                                      [message]="selectedValue.badge.message"></sb-badge>
                        </div>
                    </div>
                    <div class="flex">
                        <sb-button *ngFor="let action of allActions"
                                   buttonClass="link"
                                   iconClasses="text-light p-3 {{action.iconClasses}}"
                                   iconSize="lg"
                                   [customClasses]="action.customClasses"
                                   [iconName]="action.iconName"
                                   [iconStyle]="action.iconStyle"
                                   [tooltipText]="action.tooltipText"
                                   tooltipPlacement="bottom"
                                   [isAutoSize]="true"
                                   [route]="action.route"
                                   (onClick)="clickAction(action)"></sb-button>
                        <sb-button buttonClass="link"
                                   iconClasses="text-light p-3"
                                   iconSize="lg"
                                   [iconName]="isExpanded ? 'close' : 'followLink'"
                                   [tooltipText]="isExpanded ? 'SB_Close' : 'SB_Expand'"
                                   tooltipPlacement="bottom"
                                   [isAutoSize]="true"
                                   (onClick)="toggleWindow()"></sb-button>
                    </div>
                </div>
                <sb-sub-menu *ngIf="selectedValue.id && !isLoadingMenuItems"
                             [pageMenuItems]="bottomMenuItems()"
                             id="cardSubmenu"
                             [isThirdLevel]="true"></sb-sub-menu>
                <sb-loading [hasLoaded]="!isLoadingMenuItems"></sb-loading>
            </div>
        </div>
        <div *ngFor="let item of menuItems"
             [routerLink]="item.route"
             #rla="routerLinkActive"
             class="tabs-hidden"
             routerLinkActive="tabs-visible">
            <sb-tabs *ngIf="rla && item.tabs"
                     [(tabs)]="item.tabs"
                     [isWizard]="item.isWizard"
                     [routePrefix]="item.route"></sb-tabs>
        </div>
        <div [ngClass]="{'p-4' : !isRouted}"
             id="cardContent">
            <ng-content *ngIf="!isRouted"></ng-content>
            <router-outlet *ngIf="isRouted"></router-outlet>
        </div>
    </div>
</div>
