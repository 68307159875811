angular.module('messaging.components.messageCenterDashboard',
    [
        'messaging.components.messageCenterContainer',
        'messaging.components.messageCenterComposerContainer',
        'messaging.services.messageViewingService',
        'shared.components.subMenu',
        'shared.components.sbIcon'
    ])
    .component('messageCenterDashboard',
    {
        bindings: {},
        templateUrl: '/Scripts/app/messaging/components/message-center-dashboard.template.html',
        controller: [
            'messageViewingService',
            function (messageViewingService) {
                this.menuItems = [];

                var self = this;
                messageViewingService.getAllLookups()
                    .then(function (data)
                    {
                        var newMenuItems = [];

                        if (data.isStaff)
                        {
                            newMenuItems.push(
                                {
                                    uisref: '.inbox',
                                    title: 'SB_Your_Messages',
                                    icon: 'fas fa-inbox'
                                });

                            if (data.allowAdmin)
                            {
                                newMenuItems.push(
                                    {
                                        uisref: '.adminNew',
                                        title: 'SB_All_Messages',
                                        icon: 'far fa-inbox'
                                    });
                            }

                            if (data.canExternalStaffSend)
                            {
                                newMenuItems.push(
                                    {
                                        uisref: '.compose',
                                        title: 'SB_Send_Communication',
                                        icon: 'far fa-bullhorn'
                                    });
                            }

                            // Trigger onChanges in subMenu
                            self.menuItems = [].concat(newMenuItems);
                        }

                    });
            }]
    });