import { Injectable } from '@angular/core';
import { KitStyles } from '@sb-shared/globals/kit-styles';
import { formElements } from '@sb-shared/constants/shared.constant';
import { controllerTypes } from '@sb-shared/models/request-settings';
import { WizardTabs } from '@sb-shared/models/UI/wizard-tabs';
import { map, Observable, shareReplay } from 'rxjs';
import { apis, HttpRequestSettings } from './../../shared/models/request-settings';
import { ColoursService } from './../../shared/services/colours.service';
import { HttpWebApiService } from './../../shared/services/http-web-api.service';
import { KitDetails } from './../models/kit-details';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class KitService {
  config: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.User
  };
  configSave: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.Admin
  };
  urlBase = 'OrganisationHomeKit';
  orgKit$: Observable<KitDetails>;

  constructor(
    private http: HttpWebApiService,
    private color: ColoursService,
    private translate: TranslateService
  ) {}

  getKitTabs(): WizardTabs {
    return [
      {
        id: 1,
        name: 'SB_Kit',
        iconName: 'kit',
        image: 'goal',
        formGroupName: 'team',
        skipConfirmation: true,
        fields: [
          {
            id: 'homeKitStyleId',
            label: 'SB_Kit_Style',
            type: formElements.SimpleSelect,
            isRequired: true,
            options: KitStyles,
            cols: 5
          },
          {
            id: 'homeKitColours',
            label: 'SB_Kit_Colors',
            type: formElements.Colours,
            secondaryIds: ['homeKitPrimaryHexColour', 'homeKitSecondaryHexColour'],
            isRequired: true,
            cols: 5
          },
          {
            label: 'SB_Preview',
            type: formElements.Kit,
            secondaryIds: ['', 'homeKitStyleId', 'homeKitColours']
          }
        ]
      }
    ];
  }

  getKit(refresh?: boolean): Observable<KitDetails> {
    if (!this.orgKit$ || refresh) {
      this.orgKit$ = this.http.get(this.urlBase, this.config).pipe(
        map((kit: KitDetails) => {
          return {
            ...kit,
            homeKitPrimaryHexColour: this.color.colourToHex(kit.homeKitPrimaryHexColour),
            homeKitSecondaryHexColour: this.color.colourToHex(kit.homeKitSecondaryHexColour)
          };
        }),
        shareReplay(1)
      );
    }
    return this.orgKit$;
  }

  saveKit(data: KitDetails) {
    let config = { ...this.configSave };
    config.successMessage = this.translate.instant('SB_Kit_Design_Saved');
    return this.http.put(
      this.urlBase,
      {
        homeKitStyleId: data.homeKitStyleId,
        homeKitPrimaryHexColour: this.color.hexToColour(data.homeKitPrimaryHexColour),
        homeKitSecondaryHexColour: this.color.hexToColour(data.homeKitSecondaryHexColour)
      },
      config
    );
  }
}
