angular.module('events.constants', [])
    .constant('eventCategorySystemUsageTypeEnum', {
        None: 0,
        CCA: 1,
        Membership: 2,
        Fee: 4,
        ParentsEvening: 8,
        Task: 16,
        Transport: 32
    })
    .constant('availabilityRequirementsEnum', {
        None: 0,
        ConsentWithManualSelection: 1,
        NotifyOnly: 2,
        AutoNotify: 3,
        InstantBooking: 4,
        ConsentWithAutoSelection: 5
    })
    .constant('transportAttendeeStateEnum', {
        ExpectedPreference: 1,
        ExpectedBooking: 2,
        ExpectedChangeRequest: 3,
        NotExpectedLaterBooking: 4,
        NotExpectedChangeRequest: 5,
        NotExpectedRemoved: 6,
        ExpectedMovedFromNotExpected: 7,
        ExpectedAddedToRegister: 8
    })
    .constant('eventSectionEnum', {
        Sports: 1,
        Trips: 2,
        Signups: 3,
        Other: 4,
        System: 5
    })
    .constant('consentRequirementEnum', {
        ParentOnly: 1,
        StudentOnly: 2,
        ParentOrStudent: 3,
        ParentAndStudent: 4
    })
    .constant('notifyTypesEnum', {
        ParentOnly: 1,
        StudentOnly: 2,
        ParentAndStudent: 3,
    });
