'use strict';

angular.module('ccaavailableModule').component('ccaAvailableSelection', {
    templateUrl:
        '/Scripts/app/ccaAvailable/Details/cca-available-selection.template.html',
    controller: [
        '$scope',
        '$state',
        '$filter',
        '$uibModal',
        'availableRepository',
        'organisationAvailableRepository',
        'simpleAlertService',
        'EventCategorySystemUsageType',
        function (
            $scope,
            $state,
            $filter,
            $uibModal,
            availableRepository,
            organisationAvailableRepository,
            simpleAlertService,
            EventCategorySystemUsageType
        ) {
            $scope.loadingError = false;
            $scope.signUp = {};
            $scope.eventsData = null;
            $scope.isClosed = false;
            $scope.saving = false;
            $scope.EventCategorySystemUsageType = EventCategorySystemUsageType;
            $scope.signUp.completedTransport = false;
            $scope.isSaving = false;
            $scope.isSubmitted = false;

            $scope.saveBookingsHandler = function (
                signUpEvents,
                pupils,
                signUpId
            ) {
                $scope.saving = true;
                return availableRepository
                    .toggleBookings(signUpEvents, pupils, signUpId)
                    .then(function (data) {
                        if (
                            data &&
                            data.hasOwnProperty('isSuccess') &&
                            !data.isSuccess
                        ) {
                            simpleAlertService.errorAlert({
                                message:
                                    data.errorResponse || 'SB_Error_Saving',
                                messageTranslationParameters: {
                                    eventTitle: data.eventTitle || '',
                                    eventOrganiserName:
                                        data.eventOrganiserName || '',
                                    startDateTime: $filter('date')(
                                        data.startDateTime,
                                        'HH:mm'
                                    ),
                                },
                            });

                            $scope.saving = false;
                            return;
                        }
                        return data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            };

            $scope.toggleBookingHandler = function (pupil, anEvent, eventIds) {
                $scope.saving = true;
                return availableRepository
                    .postToggleBooking(pupil, anEvent, eventIds)
                    .then(function (data) {
                        return data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            };

            $scope.getSignUpEventsHandler = function () {
                return availableRepository.getSignUpEvents(
                    $state.params.signUpId,
                    $state.params.pupilId
                );
            };

            organisationAvailableRepository.get().then(function (organisation) {
                $scope.organisation = organisation;
            });

            availableRepository
                .getSignUp($state.params.signUpId)
                .then(function (signUp) {
                    $scope.signUp = signUp;

                    if (!signUp || signUp.length == 0) {
                        $scope.loadingError = true;
                        return;
                    }

                    $scope.isClosed = moment(signUp.closeDate).isBefore();
                });

            availableRepository
                .getSignUpEvents($state.params.signUpId, $state.params.pupilId)
                .then(function (eventsData) {
                    $scope.eventsData = eventsData;
                    $scope.pupils = eventsData.pupils;
                    $scope.signUpEvents = eventsData.availableEvents;
                });

            var confirmBookingsTandC = function () {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl:
                        '/Scripts/app/ccaAvailable/Details/cca-available-terms-aa.template.html',
                    controller: 'ModalTermsInstanceCtrl',
                    backdrop: 'static',
                    size: $scope.signUp.organisationFormId === 0 ? 'sm' : 'lg',
                    resolve: {
                        terms: function () {
                            return $scope.signUp.terms;
                        },
                        termsLink: function () {
                            return $scope.signUp.termsLink;
                        },
                        organisationFormId: function () {
                            return $scope.signUp.organisationFormId;
                        },
                        forPeopleList: function () {
                            var pupilDetails = [];

                            var pupilCount = $scope.pupils.length;

                            for (
                                var i = 0;
                                i < $scope.signUpEvents.length;
                                i++
                            ) {
                                var anEvent = $scope.signUpEvents[i];
                                for (var j = 0; j < pupilCount; j++) {
                                    if (
                                        anEvent.pupils[j].selectionCount === 1
                                    ) {
                                        pupilDetails.push(
                                            $scope.pupils[j].personId
                                        );
                                    }
                                }
                            }

                            return pupilDetails;
                        },
                        ccaSignUpId: function () {
                            return $scope.signUp.ccaSignUpId;
                        },
                    },
                });

                modalInstance.result.then(
                    function (capturedData) {
                        availableRepository
                            .saveBookings(
                                $scope.signUpEvents,
                                $scope.pupils,
                                $state.params.signUpId,
                                capturedData,
                                $scope.signUp.organisationFormId || 0
                            )
                            .then(function (response) {
                                if (
                                    !response ||
                                    (typeof response === 'object' &&
                                        !response.isSuccess)
                                ) {
                                    simpleAlertService.errorAlert({
                                        message: 'SB_Error_Saving',
                                    });
                                    $scope.isSubmitted = false;

                                    const eventSignUpClosedAlert =
                                        simpleAlertService.simpleAlert({
                                            title: response.eventTitle,
                                            message: response.errorResponse,
                                            okButtonText: 'SB_OK',
                                            windowSize: 'md',
                                        });

                                    eventSignUpClosedAlert.result
                                        .then(function () {
                                            window.location.reload();
                                        })
                                        .catch(function () {});

                                    return;
                                }

                                $state.go('ccaConfirmation', {
                                    signUpId: $state.params.signUpId,
                                });
                            })
                            .catch(function (error) {
                                $scope.isSubmitted = false;
                                $scope.signUp.completedTransport = false;
                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_Saving',
                                });
                            });
                    },
                    function () {
                        // re-enable submit buttons
                        $scope.isSubmitted = false;
                        $scope.signUp.completedTransport = false;
                    }
                );
            }.bind(this);

            $scope.confirmBookings = function () {
                if (
                    $scope.signUp.hasTransport &&
                    $scope.signUp.transportTimeSlotAndDirection &&
                    !$scope.signUp.completedTransport
                ) {
                    var modalInstanceTransport = $uibModal.open({
                        animation: true,
                        component: 'ccaTimeslot',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            ccaSignUp: function () {
                                return $scope.signUp;
                            },
                            signUpPersons: () =>
                                $scope.pupils.filter(
                                    (item) => item.selectionCount > 0
                                ),
                            transportTimeSlot: function () {
                                return $scope.signUp
                                    .transportTimeSlotAndDirection;
                            },
                            timeSlotTimeTableByOperatingDay: function () {
                                return availableRepository
                                    .getTimetableForTimeSlotCalendarGroupByDay(
                                        $scope.signUp
                                            .transportTimeSlotAndDirection.id,
                                        $scope.signUp
                                            .transportTimetableCalendarId
                                    )
                                    .catch(
                                        function (error) {
                                            console.error(
                                                'XHR getTimetableForTimeSlotCalendarGroupByDay failure : ' +
                                                    error.status
                                            );
                                            //allow a 404, ccaTimeslot component handles this and will close the modal and T&C's will continue.
                                            if (
                                                error.status &&
                                                error.status == '500'
                                            ) {
                                                simpleAlertService.errorAlert({
                                                    message: 'SB_Error_loading',
                                                });
                                                return;
                                            }
                                        },
                                        function () {
                                            // re-enable submit buttons
                                            $scope.isSubmitted = false;
                                            $scope.signUp.completedTransport = false;
                                        }
                                    );
                            },
                        },
                    });

                    modalInstanceTransport.result.then(
                        function (ccaSignUpPersons) {
                            $scope.signUp.completedTransport = true;
                            $scope.isSubmitted = false;

                            if (ccaSignUpPersons) {
                                $scope.pupils = ccaSignUpPersons;
                            }

                            confirmBookingsTandC();
                        },
                        function () {
                            // re-enable submit buttons
                            $scope.signUp.completedTransport = false;
                            $scope.isSubmitted = false;
                        }
                    );

                    //Don't ask TandC's
                    return;
                }

                confirmBookingsTandC();
            };

            $scope.costTypes = [
                { id: '0', name: 'SB_CcaCostType_Free' },
                { id: '1', name: 'SB_CcaCostType_Per_term' },
                { id: '2', name: 'SB_CcaCostType_Per_session' },
                { id: '3', name: 'SB_CcaCostType_One_Off' },
            ];

            $scope.costText = function (ccaPrice) {
                if (ccaPrice === 0 || ccaPrice === '0' || ccaPrice === '') {
                    return 'SB_Free';
                }

                return $filter('number')(ccaPrice, 2);
            };
            $scope.disableRow = -1;
            $scope.selectedQuantityChanging = false;

            $scope.totalSelectionCount = function () {
                var selectionCount = 0;

                if ($scope.eventsData) {
                    for (
                        var i = $scope.eventsData.availableEvents.length;
                        i--;

                    ) {
                        var availableEvent =
                            $scope.eventsData.availableEvents[i];
                        for (var j = availableEvent.pupils.length; j--; ) {
                            if (
                                availableEvent.pupils[j].status !== 2 && // Confirmed
                                availableEvent.pupils[j].status !== 7
                            ) {
                                // Processing
                                selectionCount +=
                                    availableEvent.pupils[j].selectionCount;
                            }
                        }
                    }
                }

                return selectionCount;
            };

            $scope.currentDate = null;
            $scope.currentDay = null;

            // the two following watches
            // need to be in this order as they
            // need to fire in a particular order
            $scope.$watch('sortType', function (newValue, oldValue) {
                $scope.currrentSortType = newValue;
                //$scope.sortSignUpEvents();
            });

            $scope.$watch('sortReverse', function (newValue, oldValue) {
                if ('from' === $scope.currrentSortType) {
                    $scope.fromSortReverse = newValue;
                }
            });
        },
    ],
});

angular.module('ccaavailableModule').filter('sumByKey', [
    '$filter',
    function ($filter) {
        return function (data, key) {
            if (typeof data === 'undefined' || typeof key === 'undefined') {
                return 0;
            }

            var sum = 0;
            angular.forEach(data, function (obj, objKey) {
                sum += parseFloat(obj[key]);
            });

            return sum;
        };
    },
]);

angular.module('ccaavailableModule').controller('ModalTermsInstanceCtrl', [
    '$scope',
    '$uibModalInstance',
    '$sce',
    'personService',
    'terms',
    'termsLink',
    'organisationFormId',
    'forPeopleList',
    'ccaSignUpId',
    function (
        $scope,
        $uibModalInstance,
        $sce,
        personService,
        terms,
        termsLink,
        organisationFormId,
        forPeopleList,
        ccaSignUpId
    ) {
        $scope.terms = terms;
        $scope.termsLink = termsLink;
        $scope.formId = organisationFormId;
        $scope.userDataIsValid = false;
        $scope.forPeople = forPeopleList;
        $scope.ccaSignUpId = ccaSignUpId;

        personService.getPerson(-1).then(function (data) {
            $scope.person = data;

            if (data.personData.dateOfBirth) {
                $scope.person.personData.dateOfBirth = new Date(
                    data.personData.dateOfBirth
                );
            }
        });

        $scope.userDataChanged = function (isValid, capturedData) {
            $scope.userDataIsValid = isValid;
            $scope.acceptedTerms.capturedData = capturedData;
        };

        $scope.acceptedTerms = {
            checked: false,
            notes: '',
            capturedData: {},
        };

        $scope.ok = function () {
            $uibModalInstance.close($scope.acceptedTerms.capturedData);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');

            if ($scope.signUp) {
                //reset transport process flow reset
                $scope.signUp.completedTransport = false;
            }
        };

        $scope.allValid = function () {
            return (
                $scope.acceptedTerms.checked &&
                ($scope.formId === 0 ||
                    ($scope.formId > 0 && $scope.userDataIsValid))
            );
        };
    },
]);
