'use strict';

angular
    .module('emailTemplates.components.templatePlaceholderList', [
        'emailTemplates.services.emailTemplatesService',
    ])
    .component('templatePlaceholderList', {
        selector: 'template-placeholder-list',
        templateUrl:
            '/Scripts/app/emailTemplates/components/template-placeholder-list.template.html',
        controller: [
            'simpleAlertService',
            'emailTemplatesService',
            function templatePlaceholderListController(
                simpleAlertService,
                emailTemplatesService
            ) {
                var self = this;

                emailTemplatesService.getTemplatePlaceholders().then(
                    function (data) {
                        self.templatePlaceholders = data;
                    },
                    function () {
                        simpleAlertService.errorAlert();
                    }
                );
            },
        ],
    });
