'use strict';

angular.module('sysadmin.components.importsGg4l', [
    'sysadmin.services.importsService',
    'shared.services.guidService',
    'shared.components.sbOnOffSwitch',
]).component('importsGg4l', {
    templateUrl: '/Scripts/app/sysadmin/components/imports-gg4l.template.html',
    bindings: {
        organisation: '<',
        gg4lDataModel: '<',
        originalGg4lDataModel: '<',
        onChangesNotified: '&',
        beforeSave: '=',
    },
    controller: [
        'importsService',
        'guidService',
        '$scope',
        function importsGg4lController(importsService, guidService, $scope) {

            var self = this;
            this.anyChanges = false;
            this.showAddNewSchool = false;
            this.showDuplicateSchoolError = false;
            this.showInvalidGuidError = false;
            this.newSchoolId = '';
            this.newSchoolName = '';
            this.newSchoolContactTypes = '';
            this.schoolExists = false;
            this.showSaveSchoolError = false;
            this.secretObfuscated = true;
            this.schoolChangesMadeDict = {};

            this.$onChanges = function (changes) {

                if (typeof self.organisation !== 'undefined' && self.organisation != null)
                {
                    this.showAddNewSchool = false;
                }

                if (self.originalGg4lDataModel != null)
                {
                    $scope.originalSchools = JSON.parse(JSON.stringify(this.originalGg4lDataModel.schools));
                }
                
                if (changes.gg4lDataModel) {
                    this.reset();
                }
            };

            this.deleteSchool = function (schoolId)
            {
                for (var i = 0; i < this.gg4lDataModel.schools.length; i++)
                {
                    if (this.gg4lDataModel.schools[i].id === schoolId)
                    {
                        this.gg4lDataModel.schools.splice(i, 1);
                    }
                }
                this.changesMade();
            }.bind(this);

            this.showAddNewSchoolInput = function () {
                this.showAddNewSchool = true;
            };

            this.schoolChangesMade = function (school)
            {
                this.schoolChangesMadeDict[school.id] = true;
            };

            this.saveSchoolContactTypes = function (school)
            {
                this.showSaveSchoolError = false;

                importsService.saveSchoolContactTypes(this.organisation.organisationId, school)
                    .then(function (response)
                    {
                        $('#btnSaveSchool_' + school.id).prop('disabled', 'disabled');

                        if (typeof response.data !== 'undefined')
                        {
                            if (response.status == 400)
                            {
                                this.showSaveSchoolError = true;
                                this.saveSchoolErrorMessage = response.data.Message;
                            }
                            else if (typeof response.data !== 'undefined' &&
                                     typeof response.data.Message !== 'undefined' &&
                                     response.data.Message != '')
                            {
                                this.showSaveSchoolError = true;
                                this.saveSchoolErrorMessage = response.data.Message;
                            }
                        }
                    }.bind(this));
            }.bind(this);

            this.cancelAddNewSchool = function () {
                this.showAddNewSchool = false;
                this.newSchoolId = '';
                this.showDuplicateSchoolError = false;
                this.showInvalidGuidError = false;
                this.newSchoolName = '';
                this.newSchoolContactTypes = '';
                this.schoolExists = false;
            };

            this.addNewSchool = function ()
            {
                this.newSchoolId = this.newSchoolId.trim();
                this.showDuplicateSchoolError = false;
                this.showInvalidGuidError = false;
                this.newSchoolName = '';
                this.schoolExists = false;

                if (this.newSchoolId != '')
                {
                    if (!guidService.isValidGuid(this.newSchoolId))
                    {
                        this.showInvalidGuidError = true;
                        return;
                    }

                    this.validateSchoolId(this.newSchoolId)
                    .then(function (isSchoolValid)
                    {
                        if (!isSchoolValid)
                        {
                            return;
                        }

                        if (typeof this.gg4lDataModel.schools !== 'undefined')
                        {
                            for (var i = 0; i < this.gg4lDataModel.schools.length; i++)
                            {
                                if (this.gg4lDataModel.schools[i].id.trim() == this.newSchoolId)
                                {
                                    this.showDuplicateSchoolError = true;
                                    return;
                                }
                            }
                        }

                        if (this.newSchoolName == '')
                        {
                            return;
                        }

                        if (typeof this.gg4lDataModel.schools === 'undefined')
                        {
                            this.gg4lDataModel.schools = [];
                        }

                        this.gg4lDataModel.schools.push(
                            {
                                id: this.newSchoolId,
                                name: this.newSchoolName,
                                contactTypes: this.newSchoolContactTypes
                            });

                        this.changesMade();
                        this.newSchoolId = '';
                        this.showAddNewSchool = false;
                    }.bind(this));
                }
            }.bind(this);

            this.validateSchoolId = function()
            {
                this.showInvalidGuidError = false;

                if (!guidService.isValidGuid(this.newSchoolId.trim()))
                {
                    this.showInvalidGuidError = true;
                    return false;
                }

                const secret = this.secretObfuscated ? '' : this.gg4lDataModel.secret;
                return importsService.getGg4lSchoolName(this.newSchoolId.trim(), this.gg4lDataModel.clientId, secret, this.organisation.organisationId, this.gg4lDataModel.url)
                .then(function (data)
                {
                    this.newSchoolName = data;
                    this.schoolExists = data != '';
                    this.showDuplicateSchoolError = false;
                    this.changesMade();

                    return this.schoolExists;
                }.bind(this));
            }.bind(this);

            this.secretFocused = () => {
                if (this.secretObfuscated) {
                    this.gg4lDataModel.secret = '';
                    this.changesMade();
                    this.secretObfuscated = false;
                }
            }

            this.changesMade = function ()
            {
                var enableSave = this.hasDataChangedFromOriginal() && this.isDataValid();

                if (this.onChangesNotified && typeof (this.onChangesNotified()) === 'function')
                {
                    self.onChangesNotified()(enableSave);
                }
                this.anyChanges = true;
            };

            this.trimWhitespace = function ()
            {
                this.gg4lDataModel.clientId = this.gg4lDataModel.clientId.trim();
                this.gg4lDataModel.secret = this.gg4lDataModel.secret.trim();
                this.gg4lDataModel.url = this.gg4lDataModel.url.trim();

                for (var i = 0; i < this.gg4lDataModel.schools.length; i++)
                {
                    this.gg4lDataModel.schools.keys[i].trim();
                }
            }.bind(this);

            this.hasDataChangedFromOriginal = function ()
            {
                if (this.originalGg4lDataModel == null)
                {
                    // brand new GG4L data so return true = yes, data has changed
                    return true;
                }

                var currentData = this.gg4lDataModel;

                if (typeof currentData.schools != 'undefined' &&
                    currentData.schools.length != this.originalGg4lDataModel.schools.length)
                {
                    return true;
                }

                if (currentData.clientId != this.originalGg4lDataModel.clientId ||
                    currentData.secret != this.originalGg4lDataModel.secret ||
                    currentData.url != this.originalGg4lDataModel.url ||
                    currentData.importEmailAddress != this.originalGg4lDataModel.importEmailAddress ||
                    currentData.contactNameSurnameFirst != this.originalGg4lDataModel.contactNameSurnameFirst ||
                    currentData.minLastSeenDaysBeforeEligibleForDeletion != this.originalGg4lDataModel.minLastSeenDaysBeforeEligibleForDeletion ||
                    currentData.studentDeletionThresholdPercentage != this.originalGg4lDataModel.studentDeletionThresholdPercentage ||
                    currentData.enabled != this.originalGg4lDataModel.enabled)
                {
                    return true;
                }

                if (typeof currentData.schools != 'undefined' &&
                    currentData.schools.length == this.originalGg4lDataModel.schools.length)
                {
                    var currentSchoolIds = [];
                    for (var i = 0; i < currentData.schools.length; i++)
                    {
                        currentSchoolIds.push(currentData.schools[i].id);
                    }

                    for (var i = 0; i < currentData.schools.length; i++)
                    {
                        if (!currentSchoolIds.includes(this.originalGg4lDataModel.schools[i].id))
                        {
                            return true;
                        }
                    }
                }

                return false;
            }.bind(this);

            this.isDataValid = function ()
            {
                if (typeof this.gg4lDataModel.schools === 'undefined' ||
                    typeof this.gg4lDataModel.clientId === 'undefined' ||
                    typeof this.gg4lDataModel.secret === 'undefined' ||
                    typeof this.gg4lDataModel.url === 'undefined' ||
                    typeof this.gg4lDataModel.minLastSeenDaysBeforeEligibleForDeletion === 'undefined' ||
                    typeof this.gg4lDataModel.studentDeletionThresholdPercentage === 'undefined')
                {
                    return false;
                }

                if (typeof this.gg4lDataModel.schools === 'undefined' ||
                    this.gg4lDataModel.schools.length == 0 ||
                    this.gg4lDataModel.clientId.length <= 0 ||
                    this.gg4lDataModel.secret.length <= 0||
                    this.gg4lDataModel.url.length <= 0 ||
                    this.gg4lDataModel.minLastSeenDaysBeforeEligibleForDeletion.length <= 0 ||
                    this.gg4lDataModel.studentDeletionThresholdPercentage.length <= 0)
                {
                    return false;
                }

                if (this.gg4lDataModel.minLastSeenDaysBeforeEligibleForDeletion < 0)
                {
                    return false;
                }

                if (this.gg4lDataModel.studentDeletionThresholdPercentage < 0 ||
                    this.gg4lDataModel.studentDeletionThresholdPercentage > 100)
                {
                    return false;
                }

                if (this.doDuplicateSchoolIdsExist(this.gg4lDataModel.schools.keys))
                {
                    return false;
                }

                return true;
            }.bind(this);

            this.reset = () => {
                this.beforeSave = () => {
                    if (this.secretObfuscated) {
                        this.gg4lDataModel.secret = null;
                    }
                }

                this.secretObfuscated = true;
            }

            this.doDuplicateSchoolIdsExist = function (schoolIds)
            {
                var counts = [];
                for (var i = 0; i <= schoolIds.length; i++)
                {
                    if (counts[schoolIds[i]] === undefined)
                    {
                        counts[schoolIds[i]] = 1;
                    }
                    else
                    {
                        return true;
                    }
                }

                return false;
            }
        }
    ]
});
