angular.module('settingsModule')
    .component('staffRoles',
        {
            templateUrl: '/Scripts/app/settings/components/staff-roles/staff-roles.template.html',
            controller: class StaffRolesCtrl {

                $state: any;
                subMenuItems: any;

                static $inject = ['$state'];

                constructor ($state) {
                    this.$state = $state;
                }

                $onInit(){
                    this.subMenuItems = {
                        items: [
                            {
                                title: 'SB_Roles',
                                route: '.staffRolesInternal',
                                activeMenu: true
                            },
                        /*    {
                                title: 'SB_External_Permissions',
                                route: '.staffRolesExternal',
                            } */
                        ]
                    }
                }
            }
        });
