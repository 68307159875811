<div class="data-dashboard"
     *ngIf="dashboard">
    <div *ngIf="dashboard.mainWidget"
         class="data-dashboard__main">
        <sb-summary-button *ngFor="let item of dashboard.mainWidget.items"
                            [summary]="item"
                            (onClick)="openRoute(item.url)"></sb-summary-button>
    </div>    
    <div class="data-dashboard__widgets">
        <div *ngFor="let widget of dashboard.otherWidgets"
             class="data-dashboard__widget data-dashboard__widget--{{widget.cols}}">
            <div class="data-dashboard__widget__header">
                <h2 [innerHTML]="widget.title"></h2>
                <div>
                    <sb-button *ngIf="widget.url"
                               [isAutoSize]="true"
                               [message]="'SB_View_All' | translate"
                               buttonClass="primary"
                               (onClick)="openRoute(widget.url)"></sb-button>
                </div>
            </div>
            <div *ngIf="widget.items"
                    class="data-dashboard__widget__items">
                <sb-summary-button *ngFor="let item of widget.items"
                                    [summary]="item"
                                    (onClick)="openRoute(item.url)"></sb-summary-button>
            </div>
            <sb-chart *ngIf="widget.chart"
                      [chartOption]="widget.chart"></sb-chart>
            <sb-data-table *ngIf="widget.table"
                            [tableColumns]="widget.table.columns"
                            [tableData]="widget.table.data"
                            [pageSize]="widget.table.pageSize || 10"></sb-data-table>
        </div>
        <div *ngIf="dashboard.links"
             class="data-dashboard__links">
            <h2 [innerHTML]="'SB_More' | translate"></h2>
            <sb-action-menu [isFeatured]="true"
                            [menuItems]="dashboard.links"></sb-action-menu>
        </div>
    </div>
</div>