'use strict';

angular.module('transport.services.transportOperatingDaysService', [])
    .factory('transportOperatingDaysService', [
        '$http', '$cacheFactory',
        function ($http)
        {
            var urlBase = '/webapi/WebOrganisationTransportOperatingDays/';
            var repository = {};

            repository.getTransportOperatingDays = function ()
            {
                return $http.get(urlBase + 'GetTransportOperatingDays')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            repository.saveTransportOperatingDays = function (transportOperatingDays)
            {
                return $http.post(urlBase + 'SaveTransportOperatingDays', { transportOperatingDays: transportOperatingDays})
                    .then(function (response) { return response.data; });
            };

            return repository;
        }
    ]);