'use strict';

angular.module('events.components.eventsRegisterTransportAddStudentSelectStops',
    [
    ])
    .component('eventsRegisterTransportAddStudentSelectStops',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/events/components/events-register-transport-add-student-select-stops.template.html',
            controllerAs: 'ctrl',
            controller: [
                function () {

                    this.students = [];
                    this.routeStops = [];

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.students = changes.resolve.currentValue.students;
                            this.routeStops = changes.resolve.currentValue.routeStops;
                        }
                    }.bind(this);

                    this.save = function () {
                        this.modalInstance.close(this.students);
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);

                }]
        });