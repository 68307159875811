angular.module('userform.services.userFormService', [
        'shared.services.fileService',
        'shared.file.constants'
    ])
    .factory("userFormService", [
        "$http",
        "$cacheFactory",
        'fileService',
        'fileTypeEnum',
        function ($http, $cacheFactory, fileService, fileTypeEnum) {

            var urlBase = "/Reception/"; // should be a form controller
            var urlSignUp = "/ccasignUp/";
            var urlApi = '/webapi/WebUserForm/';
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('userFormServiceCache');

            repository.clearCache = function () {
                repoCache.removeAll();
            };

            repository.getEventFormTagData = function(calendarEventId, formFieldTagId)  {

                return $http.get(urlApi + "GetEventFormTagData", { params: { calendarEventId: calendarEventId, formFieldTagId: formFieldTagId } })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getEventFormTagDataCsv = function(calendarEventId, formFieldTagId)  {
                return $http.get(`${urlApi}GetEventFormTagDataCsv`, { params: { calendarEventId: calendarEventId, formFieldTagId: formFieldTagId } })
                    .then(function (response) {
                        fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `FormTagDataEvent${calendarEventId}Tag${formFieldTagId}`);
                    });
            };


            repository.getTeamFormTagData = function(teamId, formFieldTagId)  {

                return $http.get(urlApi + "GetTeamFormTagData", { cache: repoCache, params: { teamId: teamId, formFieldTagId: formFieldTagId } })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getTeamFormTagDataCsv = function(teamId, formFieldTagId)  {

                return $http.get(urlApi + "GetTeamFormTagDataCsv", { params: { teamId: teamId, formFieldTagId: formFieldTagId } })
                    .then(function (response) {
                        fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `FormTagDataTeam${teamId}Tag${formFieldTagId}`);
                    });

            };


            repository.getFormTags = function()  {

                return $http.get(urlApi + "GetFormTags")
                    .then(function (response) {
                        return response.data;
                    });

            };




            repository.getCcaSignUpFormData = function(ccaSignUpId, formId)  {

                return $http.get(urlSignUp + "GetCcaSignUpFormData", { cache: repoCache, params: { ccaSignUpId: ccaSignUpId, formId: formId } })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getCcaSignUpFormCsv = function(ccaSignUpId, formId)  {

                return $http.get(urlSignUp + "GetCcaSignUpFormCsv", { cache: repoCache, params: { ccaSignUpId: ccaSignUpId, formId: formId } })
                    .then(function (response) {
                        return response.data;
                    });

            };

            repository.getPersonFormData = function(personId, formId)  {

                return $http.get(urlBase + "GetPersonFormData", { cache: repoCache, params: { personId: personId, formId: formId } })
                    .then(function (response) {
                        return response.data;
                    });

            };


            repository.getPeopleFormData = function(people, personId, formId, ccaSignUpId) {

                var dto = {
                    people: people,
                    personId: personId,
                    formId: formId,
                    ccaSignUpId: ccaSignUpId
                };
                return $http.post(urlApi + "GetPeopleFormData", dto)
                    .then(function (response) {
                        return response.data;
                    });


            };

            repository.saveFormData = function (formId, personId, capturedData)
            {
                return $http.post(urlBase + 'SaveFormData', { formId: formId, personId: personId, capturedData: capturedData })
                    .then(function (response) {
                        repository.clearCache();

                        return response.data;
                    });

            };

            return repository;
        }
    ]);
