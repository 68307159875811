import { Injectable } from '@angular/core';
import { RegisterCompleteState } from '@sb-shared/enums/register-complete-state.enum';
import { DiaryEvent } from '../../diary/models/diary-event';
import { RegisterStateType } from './../enums/register-state-type.enum';

@Injectable({
  providedIn: 'root'
})
export class RegisterStateService {

  constructor() { }

  // * Attendee states * //

  getRegisterStateText(registerState: number) {
    switch (registerState) {
      case RegisterStateType.None:
        return '';
      case RegisterStateType.Here:
        return 'SB_Present';
      case RegisterStateType.NotHere:
        return 'SB_Absent';
      case RegisterStateType.Late:
        return 'SB_Late';
      case RegisterStateType.Excused:
        return 'SB_Excused';
      default:
        return ''
    }
  }

  getDiaryRegisterStateText(registerState: number) {
    switch (registerState) {
      case RegisterStateType.NotTaken:
        return 'SB_Register_Not_Taken';
      case RegisterStateType.None:
        return 'SB_None';
      case RegisterStateType.Here:
        return 'SB_Present';
      case RegisterStateType.NotHere:
        return 'SB_Absent';
      case RegisterStateType.Late:
        return 'SB_Late';
      case RegisterStateType.Excused:
        return 'SB_Excused';
      default:
        return ''
    }
  }

  getRegisterStateClass(registerState: number) {
    const staticCSS = 'p-1 w-8-rem text-align-center text-1-rem';
    switch (registerState) {
      case RegisterStateType.None:
        return staticCSS;
      case RegisterStateType.Here:
        return staticCSS + ' btn-success';
      case RegisterStateType.NotHere:
        return staticCSS + ' btn-danger';
      case RegisterStateType.Late:
        return staticCSS + ' btn-warning';
      case RegisterStateType.Excused:
        return staticCSS + ' btn-info';
      default:
        return ''
    }
  }

  // The badge text differs from the non-badge text in that it uses SB labels where the (Yes) and (No) text is not included.
  getRegisterStateBadgeText(registerState: number) {
    switch (registerState) {
      case RegisterStateType.None:
        return '';
      case RegisterStateType.Here:
        return 'SB_Present_Plain';
      case RegisterStateType.NotHere:
        return 'SB_Absent_Plain';
      case RegisterStateType.Late:
        return 'SB_Late';
      case RegisterStateType.Excused:
        return 'SB_Excused';
      default:
        return ''
    }
  }

  getRegisterStateBadgeClass(registerState: number) {
    const badgeCSS = 'badge';
    switch (registerState) {
      case RegisterStateType.None:
        return badgeCSS;
      case RegisterStateType.Here:
        return badgeCSS + ' badge-success';
      case RegisterStateType.NotHere:
        return badgeCSS + ' badge-danger';
      case RegisterStateType.Late:
        return badgeCSS + ' badge-warning';
      case RegisterStateType.Excused:
        return badgeCSS + ' badge-info';
      default:
        return ''
    }
  }

  getRegisterStateIconName(registerState: number) {
    switch (registerState) {
      case RegisterStateType.None:
        return '';
      case RegisterStateType.Here:
        return 'attendeeHere'
      case RegisterStateType.NotHere:
        return 'attendeeAbsent'
      case RegisterStateType.Late:
        return 'attendeeLate'
      case RegisterStateType.Excused:
        return 'attendeeExcused';
      default:
        return ''
    }
  }


  getRegisterStateDiaryIconName(registerState: number) {
    switch (registerState) {
      case RegisterStateType.None:
        return '';
      case RegisterStateType.Here:
        return 'tickCircle'
      case RegisterStateType.NotHere:
        return 'crossCircle'
      case RegisterStateType.Late:
        return 'clock'
      case RegisterStateType.Excused:
        return 'minusCircle';
      default:
        return ''
    }
  }

  getRegisterStateLabelIconName(registerState: number) {
    switch (registerState) {
      case RegisterStateType.None:
        return '';
      case RegisterStateType.Here:
        return 'checkCircle'
      case RegisterStateType.NotHere:
        return 'crossCircle'
      case RegisterStateType.Late:
        return 'clock'
      case RegisterStateType.Excused:
        return 'minusCircle';
      default:
        return ''
    }
  }

  getRegisterStateColorClass(registerState: number) {
    switch (registerState) {
      case RegisterStateType.None:
        return 'text-neutral';
      case RegisterStateType.Here:
        return 'text-success';
      case RegisterStateType.NotHere:
        return 'text-danger';
      case RegisterStateType.Late:
        return 'text-warning';
      case RegisterStateType.Excused:
        return 'text-info';
      default:
        return ''
    }
  }

  // * Register completion states * //

  getCompletionStateEnumValue(event: DiaryEvent) {
    if (!event.registerTaken) {
      return RegisterCompleteState.NotTaken
    }
    if (event.registerIncomplete) {
      return RegisterCompleteState.Incomplete;
    }
    return RegisterCompleteState.Complete;
  }

  getRegisterCompletionStateIconName(event: DiaryEvent) {
    const stateEnumValue = this.getCompletionStateEnumValue(event);
    switch (stateEnumValue) {
      case RegisterCompleteState.NotTaken:
        return 'registerNotStarted';
      case RegisterCompleteState.Incomplete:
        return 'registerIncomplete';
      case RegisterCompleteState.Complete:
        return 'registerComplete';
    }
  }

  getRegisterCompletionStateClass(event: DiaryEvent) {
    const stateEnumValue = this.getCompletionStateEnumValue(event);
    switch (stateEnumValue) {
      case RegisterCompleteState.NotTaken:
        return 'text-danger';
      case RegisterCompleteState.Incomplete:
        return 'text-warning';
      case RegisterCompleteState.Complete:
        return 'text-success';
    }
  }

  getRegisterCompletionStateText(event: DiaryEvent) {
    const stateEnumValue = this.getCompletionStateEnumValue(event);
    switch (stateEnumValue) {
      case RegisterCompleteState.NotTaken:
        return 'SB_Register_Not_Taken';
      case RegisterCompleteState.Incomplete:
        return 'SB_Register_Incomplete';
      case RegisterCompleteState.Complete:
        return 'SB_Register_Taken';
    }
  }

}
