'use strict';

angular.module('teams.components.addTeamNotePopup',
    [
        'shared.components.sbModalHeader',
        'teams.components.teamNoteEditor'
    ])
    .component('addTeamNotePopup', {
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        templateUrl: '/Scripts/app/teams/components/add-team-note-popup.template.html',
        controller: [
            function () {

                this.cancel = function () {
                    this.modalInstance.dismiss('cancel');
                };

                this.changeHandler = function (newNote) {
                    console.log('addTeamNotePopup/changeHandler', newNote);

                    this.modalInstance.close(newNote);
                }.bind(this);
            }]
    });