import * as angular from "angular";
export const ng1BalanceModule = angular.module('balanceModule', [

    'ui.bootstrap',

    'duScroll',
    'angular.filter',

    'balance.components.balanceSummaryWidget',
    'balance.filters.sumByKey',

    'organisation.services.organisationService',

    'payments.constants',
    'payments.components.paymentResult',
    'payments.components.paymentProviderButtons',
    'payments.services.paymentsService',

    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlTitleAttr',
    'shared.directives.sbCheckBox',
    'shared.directives.sbCurrencyInput',
    'shared.components.sbSubmitButton',
    'shared.directives.updateLanguage',
    'shared.directives.cloakTranslate',
    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlPlaceholder',
    'shared.directives.ngBindHtmlTitleAttr',
    'shared.components.sbSearchFilter',
    'shared.components.sbSearchFilterItem',
    'shared.directives.sbLoading',
    'shared.components.sbDatePicker',
    'shared.components.sbColumnHeader',
    'shared.components.sbIcon',
    'shared.components.sbAvatar',
    'shared.components.sbExportButton',
    'shared.components.sbTitleBar',
    'shared.services.authInterceptor',
    'shared.services.balanceSharedService',
    'shared.services.guidService',
    'shared.services.fileService',
    'shared.services.arrayService',
    'shared.services.cachedLookupService',
    'shared.constants',
    'ng1StateDefinitionModule'
])
angular.module('balanceModule').config([
    'ng1StateDefinitionProvider',
    function (ng1StateDefinitionProvider) {

       const ng1State = ng1StateDefinitionProvider.$get();

        ng1State.state('Balance', 'balance', {
            url: ''
        })
        ng1State.state('Balance', 'payment', {
            url: '/payment',
            component: 'payment',
            params: { payments: null, paymentSessionId: null, paymentSignature: null }
        })
        ng1State.state('Balance', 'paymentComplete', {
            url: '/paymentComplete',
            component: 'paymentResult',
            params: {
                isSuccess: null,
                message: null,
                merchantReference: null,
                items: null
            },
            resolve: {
                isSuccess: function ($transition$) {
                    return $transition$.params().isSuccess;
                },
                message: function ($transition$) {
                    return $transition$.params().message;
                },
                merchantReference: function ($transition$) {
                    return $transition$.params().merchantReference;
                },
                items: function ($transition$) {
                    return $transition$.params().items;
                }
            }
        })
}
])
.component('ng1BalanceDashboard', {
      template: '<balance-dashboard></balance-dashboard>',
    })
.component('ng1CurrentUserTransactionView', {
    template: '<current-user-transaction-view account-type-id="$ctrl.accountTypeId"></current-user-transaction-view>',
    bindings: {
        accountTypeId: '<'
        }
    })
.component('ng1Payment', {
      template: '<payment></payment>',
    })
.component('ng1PaymentComplete', {
      template: '<payment-result merchant-reference="$ctrl.merchantReference"></payment-result>',
    })
