<div class="event-list__main"
     [hidden]="isLoading">
    <div class="event-list__main-list"
         [ngClass]="{'visible-xl-min' : eventDetails}">
        <h3 *ngIf="invites?.length">{{organisationLabelTags.Invitations | translate}}</h3>
        <ng-container *ngFor="let event of invites; let i = index">
            <ng-container *ngTemplateOutlet="eventTemplate; context: {event, i, events: invites}"></ng-container>
        </ng-container>
        <h3 *ngIf="invites?.length && events?.length">{{organisationLabelTags.Diary | translate}}</h3>
        <ng-container *ngFor="let event of events; let i = index">
            <ng-container *ngTemplateOutlet="eventTemplate; context: {event, i, events}"></ng-container>
        </ng-container>
        <sb-alert *ngIf="events?.length === 0"
                  alertClass="info"
                  customClasses="mt-3"
                  [isSmall]="true"
                  message="SB_No_Events_Found"></sb-alert>
    </div>
    <div class="event-list__main-content"
         [ngClass]="{'visible-xl-min' : !eventDetails}">
        <sb-detail placeholderText="SB_Select_An_Event_To_View"
                    placeholderIcon="diary"
                    *ngIf="!eventDetails"></sb-detail>
        <div [hidden]="!eventDetails"
             class="event-list__main-event">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-template #eventTemplate let-event="event" let-i="i" let-events="events">
    <div *ngIf="hasDateChangedSinceLastRow(i, events)" class="diary-date">
        <div [innerHTML]="event.eventDate | date: 'EEEE, dd MMM'"></div>
    </div>
    <sb-diary-item [event]="event"
                   [isBorderless]="true"
                   [isSelected]="eventDetails?.calendarEventId === event.id && eventDetails?.personId === event.personId"
                   (onClick)="selectRow(event)"></sb-diary-item>
</ng-template>
