﻿'use strict';

angular
    .module('emailTemplates.components.emailTemplatesClient', [
        'emailTemplates.services.emailTemplatesService',
        'emailTemplates.components.templateTypeList',
        'emailTemplates.components.templatePlaceholderList',
        'organisation.components.organisationList',
        'shared.services.simpleAlertService',
        'shared.services.tinymceConfigHelper',
        'shared.components.sbIcon',
    ])
    .component('emailTemplatesClient', {
        templateUrl:
            '/Scripts/app/emailTemplates/components/email-templates-client.template.html',
        controller: [
            'emailTemplatesService',
            'imagesUploadHandler',
            'simpleAlertService',
            'tinymceConfigHelper',
            function emailTemplatesClientController(
                emailTemplatesService,
                imagesUploadHandler,
                simpleAlertService,
                tinymceConfigHelper
            ) {
                var self = this;

                this.selectedOrganisationId = null;
                this.selectedTemplateTypeId = null;

                this.selectTemplate = null;

                this.saveInProgress = false;

                this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({
                    images: true,
                    editHtml: true,
                    forceLinkPrefix: false,
                    imagesUploadHandler: imagesUploadHandler.uploadImage,
                    imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                });

                this.showTemplate = function (template) {
                    self.selectedTemplateTypeId =
                        template.communicationTemplateItemId;
                    if (self.selectedOrganisationId) {
                        loadTemplate();
                    }
                };

                this.showOrganisation = function (organisation) {
                    self.selectedOrganisationId = organisation.organisationId;
                    if (self.selectedTemplateTypeId) {
                        loadTemplate();
                    }
                };

                var loadTemplate = function () {
                    self.selectTemplate = null;
                    emailTemplatesService
                        .getCommunicationTemplate(
                            self.selectedOrganisationId,
                            self.selectedTemplateTypeId
                        )
                        .then(
                            function (template) {
                                self.selectTemplate = template;
                            },
                            function () {
                                simpleAlertService.errorAlert();
                            }
                        );
                };

                this.saveChanges = function () {
                    if (self.saveInProgress) return;

                    self.saveInProgress = true;

                    var handleSuccess = function () {
                        self.saveInProgress = false;
                        simpleAlertService.simpleAlert({
                            title: 'SB_Email_Templates',
                            message: 'SB_Successfully_Saved',
                            windowSize: 'sm',
                        });
                    };

                    var handleFail = function () {
                        self.saveInProgress = false;
                        simpleAlertService.simpleAlert({
                            title: 'SB_Email_Templates',
                            message: 'SB_Error_Saving_Message',
                            windowSize: 'sm',
                        });
                    };

                    emailTemplatesService
                        .updateCommunicationTemplate(
                            self.selectedOrganisationId,
                            self.selectedTemplateTypeId,
                            self.selectTemplate
                        )
                        .then(handleSuccess, handleFail);
                };
            },
        ],
    });
