import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sb-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
