'use strict';

angular.module('messaging.directives.messageComposer', [
    'ngToast',

    'messaging.constants',
    'messaging.directives.smsEditor',
    'messaging.directives.emailEditor',
    'messaging.controllers.personPickerPopup',
    'messaging.services.messagingService',
    'shared.services.moduleService',
    'shared.controllers.confirmationPopup',
    'shared.directives.sbLoading',
    'shared.directives.sbRadioButton',
    'shared.components.sbIcon',

    'person.directives.personPicker',
    'person.directives.personPickerSummary'
])
    .directive('messageComposer', [
        '$window',
        '$uibModal',
        'messagingService',
        'moduleService',
        'messageMediumType',
        function ($window, $uibModal, messagingService, moduleService, messageMediumType) {

            function controller($scope, $uibModal) {
                $scope.sending = false;
                $scope.contactMediums = [];
                $scope.settings = {};
                $scope.showEvents = false;
                $scope.selectedContactMedium = null;
                $scope.mediumAllowsPersonPicking = false;
                $scope.showAttachToEmail = false;
                $scope.showSubjectClasses = $scope.hasSubjectClasses || false;
                $scope.showWholeSchoolGroup = $scope.showWholeSchoolGroup || false;
                $scope.canComposeCommunication = false;
                $scope.disableClubPicking = $scope.disableClubPicking || false;
                $scope.disablePersonPicking = $scope.disablePersonPicking || false;
                $scope.showEvents = $scope.showEvents || false

                messagingService.getSettings()
                    .then(function(settings)
                    {
                        $scope.settings = settings;
                    });

               moduleService.getCommunicationModule()
                   .then(res => {
                    $scope.mediumAllowsPersonPicking = res.hasSendToIndividuals;
                    $scope.showAttachToEmail = res.emailAttachmentsEnabled;
                    $scope.showSubjectClasses = $scope.hasSubjectClasses && res.hasSubjectClasses;
                    $scope.showWholeSchoolGroup = $scope.showWholeSchoolGroup && res.hasWholeSchool;
                    $scope.canComposeCommunication = res.canComposeCommunication;
                    $scope.disablePersonPicking = $scope.disablePersonPicking || !res.canSendToAnyPerson;
                    $scope.disableClubPicking = $scope.disableClubPicking || !res.hasClubs;
                    $scope.showEvents = res.canSendToEvents && !$scope.calendarEventId;
                    $scope.loading = 1;
                })
                .catch(err => {
                    console.log(err);
                })

                messagingService.getMessageMediums()
                    .then(function(mediums)
                    {
                        for (var i = 0; i < mediums.length; i++)
                        {
                            switch (mediums[i])
                            {
                                case messageMediumType.Email.id:
                                    $scope.contactMediums.push({
                                        mediumId: messageMediumType.Email.id,
                                        name: messageMediumType.Email.label,
                                    });
                                    break;
                                case messageMediumType.SMS.id:
                                    $scope.contactMediums.push({
                                        mediumId: messageMediumType.SMS.id,
                                        name: messageMediumType.SMS.label,
                                    });
                                    break;
                            }
                        }

                        // set up the defaults
                        $scope.toggleMediumSelection($scope.contactMediums[0]);
                    });

                messagingService.resetGroupContainers($scope);

                $scope.toggleMediumSelection = function (medium)
                {
                    var mediumObject = {
                        mediumId: medium.mediumId,
                        name: medium.name
                    };

                    messagingService.resetGroupContainers($scope);

                    $scope.selectedContactMedium = mediumObject;
                    $scope.selectedMessageMediumId = mediumObject.mediumId;

                };

                $scope.openRecipientPicker = function () {

                    var settings = {
                        showEvents: $scope.showEvents,
                        disablePersonPicking: $scope.disablePersonPicking || !$scope.mediumAllowsPersonPicking,
                        useSimpleAddButtonForClubs: false,
                        useSimpleAddButtonForTeams: false,
                        showEventPersons: $scope.showEventPersons ?? false,
                        showEventPersonGroups: $scope.showEventPersonGroups ?? false,
                        eventId: $scope.calendarEventId,
                        disableClubPicking: $scope.disableClubPicking ?? false,
                        disableTeamPicking: $scope.disableTeamPicking ?? false,
                        showWholeSchoolGroup: $scope.showWholeSchoolGroup ?? false,
                        showSubjectClasses: $scope.showSubjectClasses ?? false
                    };

                    $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: '/Scripts/app/messaging/controllers/person-picker-popup.template.html?v=' + $window.EveryBuddy.Version,
                        controller: 'personPickerPopupController',
                        size: 'lg',
                        resolve: {
                            selected: function () {
                                return $scope.recipients;
                            },
                            restrict: function () {
                                return $scope.restrict;
                            },
                            settings: function () {
                                return settings;
                            }
                        }
                    });
                };
            }

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/messaging/directives/message-composer.template.html?v=' + $window.EveryBuddy.Version,
                controller: ['$scope', '$uibModal', controller],
                scope: {
                    restrict: '=',
                    selectedMessageMediumId: '=',
                    showTags: '<',
                    showEventPersons : '<',
                    showEventPersonGroups : '<',
                    calendarEventId: '<',
                    disableClubPicking : '<',
                    disableTeamPicking: '<',
                    disablePersonPicking: '<',
                    messageTitle: '<',
                    showWholeSchoolGroup: '<',
                    hasSubjectClasses: '<',
                }
            };
        }
    ]);
