'use strict';

angular.module('cca.components.ccaDeletePupilConfirmationContainer',
    [
        'cca.components.deletePupilConfirmation',
        'shared.components.sbModalHeader',
        'shared.services.simpleAlertService'
    ])
    .component('ccaDeletePupilConfirmationContainer',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/cca/components/cca-delete-pupil-confirmation-container.template.html',
            controller: [
                function () {

                    this.calendarEventId = null;
                    this.personId = null;

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.calendarEventId = changes.resolve.currentValue.calendarEventId;
                            this.personId = changes.resolve.currentValue.personId;
                            this.isBlock = changes.resolve.currentValue.isBlock;
                            this.seriesId = changes.resolve.currentValue.seriesId;
                            this.isDelete = changes.resolve.currentValue.isDelete;
                        }
                    }.bind(this);

                    this.onComplete = function (data) {
                        this.modalInstance.close(data);
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);

                    this.title = function () {
                        if (!this.isDelete) {
                            return 'SB_Charges';
                        }

                        return this.isBlock
                            ? 'SB_Delete_Block_Booking'
                            : 'SB_Delete_Booking';
                    }

                }]
        });