'use strict';

angular.module('diary.transport.diaryTransportConfirmCancel', [
])
    .component('diaryTransportConfirmCancel',
        {
            bindings: {
                modalInstance: '<',
                resolve: '<'
            },
            templateUrl: '/Scripts/app/diary/transport/diary-transport-confirm-cancel.template.html',
            controller: ['deviceService', function (deviceService)
            {
                this.changeRequest = {};

                this.$onChanges = function (changes)
                {
                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.changeRequest = changes.resolve.currentValue.changeRequest;
                    }
                }.bind(this);

                this.confirmCancel = function ()
                {
                    closeModal();
                    deviceService.triggerModalCloseInMobileApp();
                }.bind(this);

                this.dismiss = function ()
                {
                    this.modalInstance.dismiss('cancel');
                    deviceService.triggerModalCloseInMobileApp();
                }.bind(this);

                var closeModal = function ()
                {
                    this.modalInstance.close();
                    deviceService.triggerModalCloseInMobileApp();
                }.bind(this);
            }]
        });
