angular.module('tuitionFeesModule')
    .component('productView', {
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/product-view.template.html',
        controller: class ProductView {

            // Dependencies
            billingCycleService: any;

            // Bindings
            billingCycleIdFn: () => number;

            // Variables
            isTableProcessing: boolean = false;
            currencyDisplaySymbol: string;
            currencyExponent: number;
            products: any = [];
            totalProducts: number = -1;
            currentPage: number = 1;
            pageSize: number = 10;
            pageSizes = [5, 10, 20, 50, 100, 200];
            searchParameters: any;
            sortType: string = 'code';
            sortReverse: boolean = false;

            static $inject = ['$window', '$state', '$scope', 'billingCycleService'];

            constructor($window, $state, $scope, billingCycleService) {
                this.billingCycleIdFn = () => $state.params.billingCycleId;

                // In case they went straight to this nested page, billing cycle id fn is not available.
                // Go back to parent so this fn is set-up correctly.
                if (!this.billingCycleIdFn()) {
                    $state.go('tuitionFees.billingCycle');
                }

                this.billingCycleService = billingCycleService;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;

                this.resetSearchParameters();

                $scope.$watch(() => this.billingCycleIdFn(), () => {
                    this.getProductsByCycle();
                });
            }

            resetSearchParameters() {
                this.searchParameters = {
                };
                this.setSearchParameters();
            }

            setSearchParameters() {
                this.searchParameters.pageNumber = this.currentPage;
                this.searchParameters.pageSize = this.pageSize;
                this.searchParameters.orderBy = this.sortType;
                this.searchParameters.isOrderDescending = this.sortReverse;
            }

            setPageSize() {
                // reset to page 1
                this.currentPage = 1;
                this.getProductsByCycle();
            }

            $onInit() {
            }

            getProductsByCycle() {
                this.isTableProcessing = true;
                this.setSearchParameters();

                this.billingCycleService.getProductsByCycle(this.billingCycleIdFn(), this.searchParameters)
                    .then(res => {
                        this.isTableProcessing = false;
                        if (!res.isError) {
                            this.products = res.data;
                            this.totalProducts = res.totalRecords;
                    }
                    });
            }
        }
    });
