// start:ng42.barrel
import './emailFooter.service';
import './languagesService';
import './organisationDetail.service';
import './calendarEventTypesService';
import './domainNameService';
import './emailDomainsService';
import './importsService';
import './mainSettingTypesService';
import './modulesService';
import './organisationLabel.service';
import './sysadmin.service';
import './recentQueueRun.service';
// end:ng42.barrel

