angular.module("group.components.groupDetailEditor",
    [
        "pascalprecht.translate",
        "ui.tinymce",
        'corporateContacts.components.corporateContactPickerField',
        "groups.services.groupService",
        "person.services.staffRepository",
        'shared.components.sbIcon',
        "shared.controllers.confirmationPopup",
        "shared.directives.sbCheckBox",
        "shared.services.tinymceConfigHelper",
        'shared.services.simpleAlertService'
    ])
    .component("groupDetailEditor",
        {
            bindings: {
                groupId: "<",
                isOrganisationAdmin: "<",
                displayMode: "<",
                sports: "<",
                locations: "<",
                clubs: "<",
                onChange: "&",
                onLoad: "&",
                onCancel: "&"
            },
            controller: [
                "$window",
                "groupService",
                "staffRepository",
                "tinymceConfigHelper",
                "simpleAlertService",
                function groupDetailEditorController(
                    $window,
                    groupService,
                    staffRepository,
                    tinymceConfigHelper,
                    simpleAlertService) {
                    console.log("groupDetailEditorComponent/group editor loading..");
                    this.loading = 0;
                    this.group = null;
                    this.staff = [];
                    this.allStaff = [];
                    this.allStaffNames = [];
                    this.modes = $window.EveryBuddy.Enums.GroupDetailEditorDisplayMode;
                    this.caseServices = $window.EveryBuddy.Enums.CaseServices;
                    this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });
                    this.genders = [
                        { id: "1", name: "Female" },
                        { id: "2", name: "Male" },
                        { id: "3", name: "Mixed" }
                    ];

                    this.caseStatuses = [];

                    var self = this;


                    this.isLoading = function () { return self.loading > 0; };
                    this.cancelButtonShown = function () { return self.onCancel(); };
                    this.cancel = function () {
                        self.onCancel()();
                    };

                    this.$onChanges = function (changes) {

                        if (changes.groupId && changes.groupId.currentValue >= 0) {
                            self.group = null;
                            self.load();
                        }

                        if (changes.displayMode && changes.displayMode.currentValue) {
                            if (changes.displayMode.currentValue ===
                                window.EveryBuddy.Enums.GroupDetailEditorDisplayMode.Status365) {
                                // do the extra lookups for status 365 specific stuff

                                self.loading++;
                                groupService.getCaseStatuses()
                                    .then(function (data) {
                                        self.caseStatuses = data;
                                        self.loading--;
                                    });

                                self.loading++;
                                staffRepository.getAllStaff()
                                    .then(function (data) {
                                        self.allStaff = data;
                                        for (var i = data.length; i--;) {
                                            self.allStaffNames[data[i].id] = data[i].ln + ", " + data[i].fn;
                                        }
                                        self.loading--;
                                    });
                            }
                        }
                    };

                    this.load = function () {
                        if (self.groupId === 0) {
                            self.group = {
                                "id": 0,
                                "title": "",
                                "caseServices": 0
                            };
                        } else {
                            self.loading++;

                            groupService.getGroupDetails(self.groupId)
                                .then(function (data) {
                                    self.loading--;
                                    self.group = data;
                                    self.allowComms = data.isGroupAdmin;
                                    self.staff = [];

                                    if (self.onLoad()) {
                                        self.onLoad()(data);
                                    }

                                    groupService.getStaffInGroup(self.groupId)
                                        .then(function (data) {
                                            self.staff = data.admins;
                                        })
                                        .catch(function (error) {
                                            console.error("problem loading staff for group", error);
                                        });

                                })
                                .catch(function (error) {
                                    console.error("An error occured loading group detail", error);
                                    return error;
                                });
                        }
                    };

                    this.save = function () {
                        self.loading++;
                        groupService.saveGroupDetails(self.group)
                            .then(function (data) {
                                self.group = data;
                                self.loading--;

                                if (typeof self.onChange() === "function") {
                                    self.onChange()(self.group);
                                }
                            }).catch(err => {
                                const { message } = { ...err.data };

                                simpleAlertService.errorAlert({ message });
                                self.loading--;
                            });
                    };

                    this.archive = function () {

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Archive_Group',
                            message: 'SB_Confirmation_Archive_group',
                            okButtonText: 'SB_Archive',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {
                                self.loading++;
                                groupService.archiveGroup(self.group.id)
                                    .then(function (data) {
                                        self.loading--;
                                        if (data) {
                                            self.group = data;
                                        }

                                        if (typeof self.onChange() === "function") {
                                            self.onChange()(self.group);
                                        }
                                    });
                            })
                            .catch(function () { console.log("dialog dismissed"); });
                    };

                    this.reactivate = function () {

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Reactivate_Group',
                            message: 'SB_Confirmation_Reactivate_Group',
                            okButtonText: 'SB_Reactivate',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {
                                self.loading++;
                                groupService.reactivateGroup(self.group.id)
                                    .then(function (data) {
                                        self.loading--;
                                        if (data) {
                                            self.group = data;
                                        }

                                        if (typeof self.onChange() === "function") {
                                            self.onChange()(self.group);
                                        }
                                    });
                            })
                            .catch(function () { console.log("dialog dismissed"); });
                    };

                    this.markScanned = function () {

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Mark_paperwork_scanned',
                            message: 'SB_Confirmation_mark_paperwork_scanned',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {
                                self.loading++;
                                groupService.markGroupDocumentsScanned(self.group.id)
                                    .then(function (data) {
                                        self.loading--;
                                        if (data) {
                                            self.group = data;
                                        }

                                        if (typeof self.onChange() === "function") {
                                            self.onChange()(self.group);
                                        }
                                    });
                            })
                            .catch(function () { console.log("dialog dismissed"); });
                    };

                    this.markShredded = function () {

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Title_Mark_paperwork_shredded',
                            message: 'SB_Confirmation_mark_paperwork_shredded',
                            okButtonText: 'SB_Confirm',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {
                                self.loading++;
                                groupService.markGroupDocumentsShredded(self.group.id)
                                    .then(function (data) {
                                        self.loading--;
                                        if (data) {
                                            self.group = data;
                                        }

                                        if (typeof self.onChange() === "function") {
                                            self.onChange()(self.group);
                                        }
                                    });
                            })
                            .catch(function () { console.log("dialog dismissed"); });
                    };

                    this.toggleCaseService = function (service) {
                        // toggle this bit
                        self.group.caseServices ^= service;
                    };

                    this.isCaseServiceSelected = function (service) {
                        if (!self.group)
                            return false;

                        return (self.group.caseServices & service) !== 0;
                    };
                }
            ],
            templateUrl: "/Scripts/app/group/components/group-detail-editor.template.html"
        });
