'use strict';

angular
    .module('shared.components.sbOnOffSwitch', ['ui.bootstrap'])
    .component('sbOnOffSwitch', {
        bindings: {
            model: '=',
            show: '<',
            disabled: '<',
            isChecked: '<',
            switchClass: '<',
            controlId: '@',
            onClick: '&',
            onChange: '&',
            trueLabel: '@',
            falseLabel: '@',
            disabledMessage: '@',
            description: '@',
            hideLockIconWhenDisabled: '@',
        },
        templateUrl:
            '/Scripts/app/shared/components/sb-on-off-switch.template.html',
        controller: function () {
            this.onClicked = () => {
                setTimeout(() => this.onClick());
            };

            this.onChanged = () => {
                setTimeout(() => this.onChange());
            };
        },
    });
