'use strict';

angular.module('ccaAvailable.transport.timeslot.ccaTimeslot',
    [
        'shared.components.sbIcon',
        'shared.components.sbOnOffSwitch',
        'shared.components.sbModalHeader',
        'shared.services.iconService'
    ])
    .component('ccaTimeslot',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/ccaAvailable/Transport/timeSlot/cca-timeSlot.template.html',
            controllerAs: 'ctrl',
            controller: [
                '$filter',
                '$translate',
                'iconService',
                function ($filter, $translate, iconService) {

                    this.transportPreference = {}
                    this.ccaSignUp = [];

                    var sbTransportIcons = iconService.getTransportIcons();

                    //Object to manage the time-slots
                    var transportTimeSlot = function (timeSlot, operatingDays) {

                        var timeSlotContainer = {};

                        //Time-slot
                        timeSlotContainer.getTimeSlot = function () {
                            if (!timeSlot) { return {}; };
                            // Does this directionName need a translation? Not sure if this component is used.
                            return { 'name': timeSlot.name, 'directionName': timeSlot.directionName };
                        }.bind(this);

                        //Time-slot(s) operating days
                        timeSlotContainer.getTimeSlotOperatingDays = function () {
                            if (!timeSlot || !operatingDays) { return []; };

                            return operatingDays;
                        }.bind(this);

                        timeSlotContainer.getGroupedMethods = function () {

                            var allMethods = [];

                            angular.forEach(timeSlotContainer.getTimeSlotOperatingDays(), function (operatingDay) {
                                if (operatingDay.methods && operatingDay.methods.length > 0)
                                {
                                    allMethods = allMethods.concat(timeSlotContainer.filterMethods(operatingDay));
                                }
                            }, allMethods);

                            return $filter('unique')(allMethods, 'id');
                        };

                        //for all the days group the routes, filter by method
                        timeSlotContainer.getGroupedRoutes = function (method) {

                            var allRoutes = [];

                            if (!method) { return allRoutes; }

                            angular.forEach(timeSlotContainer.getTimeSlotOperatingDays(), function (operatingDay) {
                                if (operatingDay.methods && operatingDay.methods.length > 0)
                                {
                                    allRoutes = allRoutes.concat(timeSlotContainer.methodRoutesFilterByDay(operatingDay, method.methodId));
                                }
                            }, allRoutes);

                            return $filter('unique')(allRoutes, 'timetableRouteId');
                        };

                        //returns methods supporting routes. Ensure model has routes object array before use
                        timeSlotContainer.filterMethods = function (day, person) {
                            var filterMethods = [];

                            var includeSupportsRoutes = true;

                            if (!day.methods) { return filterMethods; }

                            //when the person has completed step1, filter apply to exclude routes
                            if (person && !person.selectedRoutes && person.stepOneCompleted) {
                                includeSupportsRoutes = false;
                            };

                            if (includeSupportsRoutes) {

                                var methodsWithRoutes = $filter('filter')(day.methods, { 'supportRoutes': true }, true);

                                if (methodsWithRoutes.length > 0) {
                                    angular.forEach(methodsWithRoutes, function (value) {
                                        if (value.routes && value.routes.length > 0) {
                                            this.push(value);
                                        }
                                    }, filterMethods);
                                }
                            };

                            var methodsWithoutRotue = $filter('filter')(day.methods, { 'supportRoutes': false }, true);
                            if (methodsWithoutRotue.length > 0) {
                                filterMethods = filterMethods.concat(methodsWithoutRotue);
                            }

                            return filterMethods;
                        };

                        timeSlotContainer.displayLabelforRoute = function (route) {

                            var routeLabel = route.name;

                            if (route.timetableRouteName) {
                                routeLabel += '- ' + route.timetableRouteName
                            }
                            if (route.code) {
                                routeLabel += ' (' + route.code + ')'
                            }

                            return routeLabel;
                        };

                        timeSlotContainer.displayLabelforStop = function (stop) {

                            var stopLabel = stop.name;

                            if (stop.zip) {
                                stopLabel += ' (' + stop.zip + ')'
                            }

                            return stopLabel;
                        };

                        timeSlotContainer.operatingDayMethodSupportsRoutes = function (methodId, day) {

                            if (!methodId || !day) { return false; };

                            var methodSupportsRoutesSelected = $filter('filter')(day.methods, { 'id': methodId, 'supportRoutes': true }, true)
                            //must have support routes, with at least 1 route
                            if (methodSupportsRoutesSelected.length === 0 || !methodSupportsRoutesSelected[0].routes || methodSupportsRoutesSelected[0].routes.length === 0) {
                                return false;
                            }

                            return true;
                        };

                        timeSlotContainer.methodSupportsRoutes = function (methodId) {

                            if (!methodId) { return false; };

                            var methodRoutes = timeSlotContainer.getGroupedRoutes(methodId);
                            //must have support routes, with at least 1 route
                            if (!methodRoutes || methodRoutes.length === 0 || !methodRoutes[0].routes || methodRoutes[0].routes.length === 0) {
                                return false;
                            };

                            return true;
                        };

                        timeSlotContainer.methodRoutesFilterByDay = function (day, methodId) {

                            var methodSelected = $filter('filter')(day.methods, { 'id': methodId }, true)

                            if (methodSelected.length === 0 || !methodSelected[0].routes) {
                                return [];
                            }

                            return methodSelected[0].routes;
                        };

                        timeSlotContainer.stopsFilterByRoute = function (day, pupilPreference) {

                            var routesSelected = timeSlotContainer.methodRoutesFilterByDay(day, pupilPreference.methodId);

                            if (routesSelected.length === 0) {
                                return [];
                            }

                            var routeSelected = $filter('filter')(routesSelected, { 'timetableRouteId': pupilPreference.timetableRouteId }, true)

                            if (routeSelected.length === 0) {
                                return [];
                            }

                            return routeSelected[0].routeStops;
                        };

                        //Builds a UI list of method names
                        timeSlotContainer.methodsSupportingRoutes = function ()
                        {
                            var methodNames = '';
                            var methodNamesList = [];

                            angular.forEach($filter('filter')(timeSlotContainer.getGroupedMethods(), { 'supportRoutes': true }, true), function (method)
                            {
                                if (method)
                                {
                                    if (methodNames.length > 0)
                                    {
                                        methodNames += ', ';
                                    }
                                    methodNames += method.name;
                                    methodNamesList.push(method.name);
                                }
                            }, methodNames);

                            var or = ' ' + $translate.instant('SB_Or') + ' ';
                            var transportMethods = '';
                            if (methodNamesList.length == 1)
                            {
                                transportMethods += methodNamesList[0];
                            }
                            else
                            {
                                for (var i = 0; i < methodNamesList.length; i++)
                                {
                                    if (transportMethods.length > 0)
                                    {
                                        if (i == methodNamesList.length - 1)
                                        {
                                            transportMethods += or;
                                        }
                                        else
                                        {
                                            transportMethods += ', ';
                                        }
                                    }

                                    transportMethods += methodNamesList[i];
                                }
                            }

                            return transportMethods;
                        };

                        return timeSlotContainer;
                    };

                    //Object to manage in memory Pupil Preferences on the component, will be required for the next Story also
                    //Pupils, a shallow copy is created for the UI interactions. The pupils object is reset onSave.
                    var transportPupilPreference = function (pupils, transportTimeSlot) {

                        var container = {
                            pupilPreferenceUIBound: [],
                            showConfirmButton : false
                        };

                        var operatingDays = transportTimeSlot.getTimeSlotOperatingDays();

                        //returns a pupil record from the by reference object
                        container.getSavedPupil = function (person) {
                            return $filter('filter')(pupils, { 'personId': person.personId }, true);
                        };

                        container.getSavedPupilTransportPreferences = function (person)
                        {
                            var savedPupil = container.getSavedPupil(person);

                             //Pupil should only exist once || //no preference save
                            if (!savedPupil || !savedPupil.length > 0 || !savedPupil[0].pupilTransportPreferences) { return []; }

                            return savedPupil[0].pupilTransportPreferences;

                        }.bind(this);

                        container.getPupilPreferenceUIBound = function () {
                            return container.pupilPreferenceUIBound;
                        }.bind(this);

                        container.usedGroupedControls = function (person) {
                            return person && person.transportTimeSlot && person.transportTimeSlot.method;
                        };

                        //Person already has this option saved on their person preference and is therefore locked and requires a change request
                        container.isMethodRouteLocked = function (person, operatingDay) {

                            //check if person used the drop down controls
                            if (container.usedGroupedControls(person)) {

                                //check to see if this is already set in the controls
                                var methodRouteStop = {
                                    methodId: person.transportTimeSlot.method.id,
                                    timetableId: person.transportTimeSlot.method.timetableId
                                };

                                if (person.transportTimeSlot.route) {
                                    methodRouteStop.timetableRouteId = person.transportTimeSlot.route.timetableRouteId

                                    if (person.transportTimeSlot.routeStop) {
                                        methodRouteStop.routeStopId = person.transportTimeSlot.routeStop.routeStopId;
                                    };

                                    methodRouteStop.isLocked = true;
                                };

                                return methodRouteStop;
                            };

                            var savedTransportPreferences = container.getSavedPupilTransportPreferences(person);
                            if (!savedTransportPreferences || savedTransportPreferences.length === 0) {
                                return false;
                            };

                            var preference = container.filterPersonPreferenceForOperatingDay(person, operatingDay);
                            //check existing model for operating day and method
                            var origninalPersonPreference = $filter('filter')(savedTransportPreferences,
                            {
                                'methodId': preference.methodId,
                                'operatingDayId': preference.operatingDayId
                            }, true);

                            if (!origninalPersonPreference || !origninalPersonPreference.length > 0)
                            {
                                return false;
                            }

                            return origninalPersonPreference[0];

                        }.bind(this);

                        container.resetPersonTimeTablePreference = function (personTimetablePreference) {
                            if (!personTimetablePreference) { return; };

                            delete personTimetablePreference.timetableId;
                            delete personTimetablePreference.routeStopId;
                            delete personTimetablePreference.timetableRouteId;
                            delete personTimetablePreference.isLocked;
                            delete personTimetablePreference.methodId;
                        };


                        //setting a method on the UI.
                        container.setMethod = function (person, operatingDay, methodId) {

                            var personTimetablePreference = container.filterPersonPreferenceForOperatingDay(person, operatingDay);

                            //if overload with methodRouteStop the reset and use the new method
                            if (methodId && !personTimetablePreference.methodId)                            {
                                container.resetPersonTimeTablePreference(personTimetablePreference);
                                personTimetablePreference.methodId = methodId
                            };

                            if (!personTimetablePreference || !personTimetablePreference.methodId) { return; }

                            var timetableMethod = $filter('filter')(operatingDay.methods, { 'id': personTimetablePreference.methodId }, true)

                            if (timetableMethod.length === 1) {

                                var isLocked = container.isMethodRouteLocked(person, operatingDay);

                                //need to set the route and route stop for the selected method from previously saved preference or one that was selected on step1
                                if (isLocked && timetableMethod[0].timetableId == isLocked.timetableId) {
                                    personTimetablePreference.timetableRouteId = isLocked.timetableRouteId;
                                    personTimetablePreference.routeStopId = isLocked.routeStopId;
                                    personTimetablePreference.timetableId = isLocked.timetableId;
                                    personTimetablePreference.isLocked = true;
                                    return;
                                };

                                //Not matched so set model value so first time set
                                personTimetablePreference.timetableId = timetableMethod[0].timetableId;

                                //remove existing properties bound to the model
                                delete personTimetablePreference.routeStopId;
                                delete personTimetablePreference.timetableRouteId;
                                delete personTimetablePreference.isLocked;
                            }
                            else
                            {
                                //remove existing properties bound to the model for operating day - not supported.
                                container.resetPersonTimeTablePreference(personTimetablePreference);
                            };
                        }.bind(this);

                        //any options saved with a route?
                        container.isAnyLocked = function (person) {
                            if (!person || !person.pupilTransportPreferences) { return false;};

                            var savedTransportPreferences = container.getSavedPupilTransportPreferences(person);
                            if (!savedTransportPreferences || savedTransportPreferences.length === 0) {
                                return false;
                            };

                            return $filter('filter')(savedTransportPreferences, { 'timetableRouteId': '!!' }).length > 0;
                        };

                        container.filterPersonPreferenceForOperatingDay = function (person, day) {
                            var filterbyDay = $filter('filter')(person.pupilTransportPreferences, { 'operatingDayId': day.operatingDayId }, true);

                            if (filterbyDay.length == 0) {
                                return;
                            }

                            return filterbyDay[0];
                        };

                        //sets the preferences for the person from the
                        container.setPersonMethod = function (person) {

                            person.usedGroupControls = true;

                            //!person.requiresRoutes - don't set for a person when question is
                            if (!person || !person.transportTimeSlot || !person.selectedRoutes || !person.transportTimeSlot.method) {

                                if (person && !person.selectedRoutes) {
                                    person.stepOneCompleted = true;

                                    container.resetAllPreferences(person);

                                    container.setSavePupilPreferences();
                                }
                                return;
                            };

                            //attempt to set each operating day
                            angular.forEach(transportTimeSlot.getTimeSlotOperatingDays(), function (operatingDay) {
                                container.setMethod(person, operatingDay, person.transportTimeSlot.method.id);
                            });

                            //set a flag to complete step 1,
                            //check if all pupils can now save
                            person.stepOneCompleted = true;
                            container.setSavePupilPreferences();
                        };

                        //Any person with a Step 1 requirement then hide the confirm button
                        container.setSavePupilPreferences = function ()
                        {
                            this.showConfirmButton = true;

                            angular.forEach(container.getPupilPreferenceUIBound(), function (person) {
                                if (!person.stepOneCompleted)
                                {
                                    this.showConfirmButton = false;
                                    return;
                                };
                            }, this);
                        };

                        container.backToGroupedControls = function (person) {
                            if (!person) { return; };

                            person.usedGroupControls = false;
                            person.stepOneCompleted = false;
                            //disable confirm button, choice required
                            this.showConfirmButton = false;
                        };

                        container.resetAllPreferences = function (person) {

                            if (!person) { return; };

                            //clear any previous selections
                            if (person.pupilTransportPreferences) {
                                angular.forEach(person.pupilTransportPreferences, function (preference) {
                                    container.resetPersonTimeTablePreference(preference);
                                });
                            }
                        };

                        container.initPupilPreferenceUIBound = function () {
                            angular.forEach(pupils, function (person) {
                                if (person) {
                                    //must copy by value - this will be reset on save by value
                                    this.push(angular.copy(person));
                                }
                            }, container.pupilPreferenceUIBound);

                            angular.forEach(container.pupilPreferenceUIBound, function (person) {
                                if (person) {
                                    //each person should have support for pupilTransportPreferences
                                    if (!person.pupilTransportPreferences) {
                                        person.pupilTransportPreferences = []
                                    };

                                    //defaults for each person. Step 1 required when person has no existing routes
                                    person.stepOneCompleted = person.pupilTransportPreferences.length > 0 ? true : false;
                                    person.selectedRoutes = person.pupilTransportPreferences.length > 0 ? true : false;
                                    person.usedGroupControls = false;
                                    delete person.stepTwoCompleted; //in-case they cancel and come back in

                                    //each person should have support for the configured operating days
                                    angular.forEach(operatingDays, function (operatingDay) {
                                        //set an operating day if it doesn't exist
                                        if (!container.filterPersonPreferenceForOperatingDay(person, operatingDay)) {
                                            person.pupilTransportPreferences.push({ 'operatingDayId': operatingDay.operatingDayId });
                                        }
                                    }, person);
                                }
                            })

                            //check pupils to see of the save option is available. Not set when any pupil is on step1
                            container.setSavePupilPreferences();
                        }.bind(this);

                        //Initialise
                        container.initPupilPreferenceUIBound();

                        return container;
                    };

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {

                            if (!changes.resolve.currentValue.timeSlotTimeTableByOperatingDay || !changes.resolve.currentValue.ccaSignUp || !changes.resolve.currentValue.transportTimeSlot) {
                                console.error('ccaTimeslot : Required dependencies not met');
                                this.modalInstance.close();
                                return;
                            };

                            this.ccaSignUp = changes.resolve.currentValue.ccaSignUp;

                            this.transportTimeSlot = new transportTimeSlot(changes.resolve.currentValue.transportTimeSlot, changes.resolve.currentValue.timeSlotTimeTableByOperatingDay);

                            this.transportPreference = new transportPupilPreference(changes.resolve.currentValue.signUpPersons, this.transportTimeSlot);
                        }
                    }.bind(this);

                    this.filterIcon = function (method) {
                        var icons = $filter('filter')(sbTransportIcons, { 'iconName': method.sbIconName }, true);

                        if (icons.length > 0) {
                            return {
                                'iconName': icons[0].iconName,
                                'displayName': $filter('translate')(icons[0].displayName),
                                'unicode': icons[0].unicode
                            };
                        };

                        return {
                            'iconName': method.sbIconName,
                            'displayName': $filter('translate')(method.name),
                            'unicode': ''
                        };

                    }.bind(this);

                    this.cancel = function () {

                        this.modalInstance.dismiss('cancel');
                    }.bind(this);

                    this.saveCcaSignUpPersonPreferences = function () {
                       //when complete pass persons
                       this.modalInstance.close(this.transportPreference.getPupilPreferenceUIBound());
                    }.bind(this);

                    this.resetGroupedModel = function (person) {
                        if (!person || !person.transportTimeSlot || !person.transportTimeSlot.method) { return; };

                        delete person.transportTimeSlot;

                    }.bind(this);

                    this.resetGroupedModelRoutes = function (person) {
                        if (!person || !person.transportTimeSlot || !person.transportTimeSlot.route) { return; };

                        delete person.transportTimeSlot.route;

                        if (person.transportTimeSlot.routeStop) {
                            delete person.transportTimeSlot.routeStop;
                        };
                    };

                }
            ]
        });
