'use strict';

angular.module('shared.components.sbRouteTimeline', [])
.component('sbRouteTimeline',
    {
     //   restrict: 'E',
        bindings: {
            route: '<',
            resolve: '<',
            modalInstance: '<',
            isLive: '<',
            onClick: '&'
        },
        templateUrl: '/Scripts/app/shared/components/sb-route-timeline.template.html',
        controller: [
            function () {
                this.$onInit = function () {
                    if (this.route) {
                        this.hasSelectedStop = this.route.stops.some((stop) => {
                            return stop.isSelected;
                        });
                    }
                };
                this.$onChanges = function (changes) {
                    if (changes.resolve && changes.resolve.currentValue) {
                        this.route = changes.resolve.currentValue.route;
                }
                }.bind(this);

                this.close = function () {
                    this.modalInstance.close();
                }.bind(this);

                this.onStopClick = (stopId) => {
                    this.selectedStopId = this.selectedStopId === stopId ? 0 :stopId;
                    if (typeof this.onClick === 'function') {
                        this.onClick({stopId : this.selectedStopId});
                    }
                };

                this.isStopDisabled = stopIndex => {
                    // Do not disable for parents
                    if (!this.route.routeId) {
                        return false;
                    }
                    // Enabled if any students
                    if (this.route.stops[stopIndex].totalStudentsForStop) {
                        return false;
                    }
                    // Always show school stop
                    if (this.route.isSchoolbound) {
                        return stopIndex !== this.route.stops.length -1;
                    }
                    return stopIndex !== 0;
                }

                this.showDepartedOrExpectedInfo = ((stopIndex, stop) => {
                    return stop.isLate &&
                        stop.expectedStopTime &&
                        !this.isStopDisabled(stopIndex) &&
                        !stop.strikeThroughStop;
                });
            }]
    });
