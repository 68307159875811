import { MenuItems } from '@sb-shared/models/UI/menu-item';
import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'sb-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  sideNavItems: MenuItems;

  ngOnInit() {
    this.sideNavItems = []
  }

}
