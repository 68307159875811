'use strict';

angular.module('mortgage.controllers.mortgageStatusPopupController', [
        'mortgage.components.mortgageStatusEditor',
        'shared.components.sbModalHeader'
    ])
    .controller('mortgageStatusPopupController', [
        '$scope',
        '$uibModalInstance',
        'groupId',
        function ($scope, $uibModalInstance, groupId) {

            $scope.groupId = groupId;

            $scope.ok = function () {
                $uibModalInstance.close('ok');
            };

            $scope.cancel = function () {
                $uibModalInstance.close('cancel');
            };

            $scope.close = function () {
                $uibModalInstance.close('cancel');
            };
        }
    ]);