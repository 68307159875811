'use strict';

angular.module('sysadmin.components.importsIsams', [
    'sysadmin.services.importsService',
    'shared.services.guidService',
    'shared.components.sbOnOffSwitch',
]).component('importsIsams', {
    templateUrl: '/Scripts/app/sysadmin/components/imports-isams.template.html',
    bindings: {
        organisation: '<',
        isamsDataModel: '<',
        originalIsamsDataModel: '<',
        onChangesNotified: '&',
        beforeSave: '=',
    },
    controller: [
        'importsService',
        'guidService',
        '$scope',
        function importsIsamsController(importsService, guidService, $scope)
        {
            var self = this;
            this.anyChanges = false;

            this.apiKeyObfuscated = {};

            this.$onChanges = function (changes)
            {
                if (typeof self.organisation !== 'undefined' && self.organisation != null)
                {
                    $scope.model = this.isamsDataModel;
                }

                if (changes.isamsDataModel) {
                    this.reset(changes.isamsDataModel);
                }
            };

            this.changesMade = function ()
            {
                $scope.model = this.isamsDataModel;

                var enableSave = this.isDataValid() && this.hasDataChangedFromOriginal();

                if (this.onChangesNotified && typeof (this.onChangesNotified()) === 'function')
                {
                    self.onChangesNotified()(enableSave);
                }
                this.anyChanges = true;
            }.bind(this);

            this.getGuidValidityStyle = function (callType, guidToValidate)
            {
                if (this.apiKeyObfuscated[callType] || guidService.isValidGuid(guidToValidate) == true)
                {
                    return '';
                }

                return 'alert-danger';
            }.bind(this);

            this.hasDataChangedFromOriginal = function ()
            {
                if (typeof this.isamsDataModel === 'undefined')
                {
                    return false;
                }

                var currentData = this.isamsDataModel;

                if (currentData.baseUrl != this.originalIsamsDataModel.baseUrl || currentData.enabled != this.originalIsamsDataModel.enabled)
                {
                    return true;
                }

                for (var i = 0; i < currentData.isamsBatchApis.length; i++)
                {
                    if (typeof this.originalIsamsDataModel.isamsBatchApis[i].apiKey === 'undefined')
                    {
                        this.originalIsamsDataModel.isamsBatchApis[i].apiKey = '';
                    }

                    if (currentData.isamsBatchApis[i].isIncluded != this.originalIsamsDataModel.isamsBatchApis[i].isIncluded ||
                        currentData.isamsBatchApis[i].importEmailAddress != this.originalIsamsDataModel.isamsBatchApis[i].importEmailAddress ||
                        currentData.isamsBatchApis[i].apiKey != this.originalIsamsDataModel.isamsBatchApis[i].apiKey ||
                        currentData.isamsBatchApis[i].contactTypes != this.originalIsamsDataModel.isamsBatchApis[i].contactTypes)
                    {
                        return true;
                    }
                }

                return false;
            }.bind(this);

            this.onFocusApiKey = (isamsBatchApi) => {
                if (this.apiKeyObfuscated[isamsBatchApi.callType]) {
                    isamsBatchApi.apiKey = '';
                    this.apiKeyObfuscated[isamsBatchApi.callType] = false;
                    this.changesMade();
                }
            }

            this.reset = (isamsDataModelChanges) => {
                this.beforeSave = () => {
                    for (const batchApi of this.isamsDataModel.isamsBatchApis) {
                        if (this.apiKeyObfuscated[batchApi.callType]) {
                            batchApi.apiKey = null;
                        }
                    }
                }

                for (const batchApi of isamsDataModelChanges.currentValue.isamsBatchApis) {
                    this.apiKeyObfuscated[batchApi.callType] = true;
                }
            }

            this.isDataValid = function ()
            {
                if (typeof this.isamsDataModel === 'undefined' ||
                    typeof this.isamsDataModel.baseUrl === 'undefined' ||
                    this.isamsDataModel.baseUrl == '')
                {
                    return false;
                }

                for (var i = 0; i < this.isamsDataModel.isamsBatchApis.length; i++)
                {
                    if (this.apiKeyObfuscated[this.isamsDataModel.isamsBatchApis[i].callType]) {
                        continue; 
                    }

                    if (this.isamsDataModel.isamsBatchApis[i].isIncluded &&
                        (typeof this.isamsDataModel.isamsBatchApis[i].apiKey === 'undefined' ||
                        this.isamsDataModel.isamsBatchApis[i].apiKey == ''))
                    {
                        return false;
                    }

                    if (this.isamsDataModel.isamsBatchApis[i].isIncluded &&
                        !guidService.isValidGuid(this.isamsDataModel.isamsBatchApis[i].apiKey))
                    {
                        return false;
                    }
                }

                return true;
            }.bind(this);
        }
    ]
});
