angular.module('sysadmin.services.organisationDetailService', [])
    .service('organisationDetailService', class OrganisationDetailService {

        $http: any;
        httpCoreApi: any;
        settings: any;
        baseUrlSlug: any = 'Organisation';

        static $inject = ['$http', 'httpCoreApi'];

        constructor($http, httpCoreApi) {
            this.$http = $http;
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.SysAdmin
            }
        }

        getPaymentMethods() {
            return this.httpCoreApi.get('PaymentMethod', { ...this.settings, isProcessor: true });
        }

        getCurrencies() {
            return this.httpCoreApi.get('Currency', this.settings);
        }

        getExternalIntegrations() {
            return this.httpCoreApi.get('ExternalIntegration', this.settings);
        }

        getExternalManagementSystems() {
            return this.httpCoreApi.get('ExternalManagementSystem', this.settings);
        }

        getTimezones() {
            return this.httpCoreApi.get('Timezone', this.settings);
        }

        getFallbackLabelOrganisations() {
            return this.httpCoreApi.get(this.baseUrlSlug + '/FallbackLabels', this.settings);
        }

        getSchoolYearGroups(orgId)
        {
            return this.httpCoreApi.get('SchoolYearGroup', { ...this.settings, orgId: orgId });
        }

        createOrganisation(orgSettings) {
            return this.$http
                .post('/webapi/WebOrganisation/Create', orgSettings)
                .then(response => { return response.data; });
        };

        getOrganisation(orgId) {
            return this.httpCoreApi.get(this.baseUrlSlug, { ...this.settings, orgId: orgId });
        };

        updateOrganisation(orgId, orgSettings) {
            return this.httpCoreApi.put(this.baseUrlSlug, orgSettings, { ...this.settings, orgId: orgId });
        };
    })
