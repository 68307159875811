'use strict';

angular.module('shared.components.sbPageLoader', [
    'organisation.services.organisationService',
])
    .component('sbPageLoader', {
        templateUrl: '/Scripts/app/shared/components/sb-page-loader.template.html',
        controller: ['$window', 'organisationService',
            function ($window, organisationService) {
            // Get organisation logo
            organisationService.getOrganisation()
                .then(function (organisation) {
                    // Check organisation has a logo defined before loading.
                    if (organisation.webLogo) {
                        this.logo = $window.EveryBuddy.BlobBase + $window.EveryBuddy.LogosPath  + organisation.webLogo;
                    }
                    this.orgName = organisation.title;
                }.bind(this));
            }
        ]
    });