'use strict';

angular.module('team.directives.ccaTeamBulkCreatedList',
    [
        'angular.filter',
        'ccasignup.constants',
        'teams.services.teamService',
        'ccaSignUp.services.signUpRepository',
        'person.services.staffRepository',
        'shared.directives.sbCheckBox',
        'shared.component.progressPopupComponent',
        'subjectClass.components.subjectClassMemberListPopupComponent',
        'teams.components.teamMemberListPopupComponent'
    ])
    .directive('ccaTeamBulkCreatedList',
    [
        '$uibModal',
        'signUpRepository',
        'staffRepository',
        '$timeout',
        'simpleAlertService',
        'parentTeacherConferenceLocationTypes',
        function ($uibModal, signUpRepository, staffRepository, $timeout, simpleAlertService, parentTeacherConferenceLocationTypes)
        {
            function controller($scope, $http) {
                var pleaseWaitModalInstance = null;

                this.progress = { current: 0, target: 0 };
                this.selectedCount = 0;
                this.edited = false;
                this.parentTeacherConferenceLocationTypes = parentTeacherConferenceLocationTypes;

                signUpRepository.getLocations()
                    .then(function (locations)
                    {
                        this.locations = locations;
                        this.locations.sort();

                    }.bind(this));

                this.deleteGroupManageProgressCounters = function(groupId)
                {
                    for (var i = this.createdGroups.length; i--;)
                    {
                        if (this.createdGroups[i].id === groupId)
                        {
                            break;
                        }
                    }

                    if (i > -1)
                    {
                        this.createdGroups.splice(i, 1);
                        this.selectedCount--;
                    }

                    this.progress.current++;

                    if (this.progress.current === this.progress.target)
                    {
                        pleaseWaitModalInstance.close();
                        if( this.createdGroups.length < 1 && this.dismissHandler) {
                            //call the dismissHandler bound to the component
                            this.dismissHandler()()();
                        }
                    }
                }.bind(this);

                this.deleteSelectedGroups = function () {
                    this.progress.current = 0;
                    this.progress.target = 0;

                    for (var i = this.createdGroups.length; i--;) {

                        var item = this.createdGroups[i];

                        if (item.selected) {
                            this.progress.target++;
                            signUpRepository.postDeleteGroup(this.ccaSignUp.ccaSignUpId, item.id)
                                .then(function(groupId) {
                                    this.deleteGroupManageProgressCounters(groupId);
                                }.bind(this, item.id))
                                .catch(function (groupId) {
                                    this.deleteGroupManageProgressCounters(groupId);
                                    simpleAlertService.errorAlert();
                                }.bind(this));
                        }
                    }

                    pleaseWaitModalInstance = $uibModal.open({
                        animation: this.animationsEnabled,
                        backdrop: 'static', // prevent modal from closing on backdrop click
                        component: 'progressPopupComponent',
                        size: 'sm',
                        resolve: {
                            message: function () { return 'SB_Removing_Groups'; },
                            progress: function () { return this.progress; }.bind(this)
                        }
                    });
                }.bind(this);

                this.saveChangesSuccess = function (i) {
                    this.progress.current++;
                    this.createdGroups[i].edited = false;

                    if (this.progress.current === this.progress.target) {
                        this.edited = false;
                        pleaseWaitModalInstance.close();

                        if (this.dismissHandler) {
                            //call the dismissHandler bound to the component
                            this.dismissHandler()()();
                        }
                    }
                }.bind(this);

                this.saveChanges = function () {
                    this.progress.current = 0;
                    this.progress.target = 0;

                    var loadDelayInMilliseconds = 500;

                    for (var i = this.createdGroups.length; i--;) {

                            this.progress.target++;

                            (function (i, ctrl, loadDelayInMilliseconds) {
                                $timeout(function () {
                                    if (ctrl.createdGroups[i].edited) {

                                        signUpRepository.postGroup(ctrl.createdGroups[i])
                                            .then(function (i) {
                                                ctrl.saveChangesSuccess(i);
                                            }.bind(this, i));
                                    }
                                    else
                                    {
                                        ctrl.saveChangesSuccess(i);
                                    }
                                }, loadDelayInMilliseconds * i);

                            }(i, this, loadDelayInMilliseconds));
                    }

                    pleaseWaitModalInstance = $uibModal.open({
                        animation: this.animationsEnabled,
                        backdrop: 'static', // prevent modal from closing on backdrop click
                        component: 'progressPopupComponent',
                        size: 'sm',
                        resolve: {
                            message: function () { return 'SB_Updating_Groups'; },
                            progress: function () { return this.progress; }.bind(this)
                        }
                    });
                }.bind(this);

                this.updateSelectionCount = function(isSelected)
                {
                    var increment = isSelected ? 1 : -1;
                    this.selectedCount += increment;
                    // this.generateFn.disabled = this.selectedCount == 0;
                }.bind(this);

                this.toggleAllCreatedGroups = function (isSelected) {
                    for (var i = this.createdGroups.length; i--;) {
                        if (this.createdGroups[i].selected != isSelected) {
                            this.createdGroups[i].selected = isSelected;
                            this.updateSelectionCount(isSelected);
                        }
                    }
                }.bind(this);

                this.anItemIsMissingATeacher = function()
                {
                    for (var i = this.createdGroups.length; i--;) {
                        if(this.createdGroups[i].organiserPersonId == 0)
                            return true;
                    }
                    return false;
                }

                this.showTeamMembers = function (teamId, teamTitle) {
                    $uibModal.open({
                        animation: true,
                        component: 'teamMemberListPopupComponent',
                        size: 'md',
                        resolve: {
                            teamId: function() { return teamId; },
                            teamTitle: function() { return teamTitle; }
                        }
                    });
                }.bind(this);

                this.showClassMembers = function (subjectClassId, subjectClassTitle) {
                    $uibModal.open({
                        animation: true,
                        component: 'subjectClassMemberListPopupComponent',
                        size: 'md',
                        resolve: {
                            subjectClassId: function() { return subjectClassId; },
                            subjectClassTitle: function () { return subjectClassTitle; }
                        }
                    });
                }.bind(this);

                this.addAdminGroup = function (ctrl, item) {
                    $scope.searchType = 3
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: "addAdminToGroupModal.html",
                        controller: "ConfirmModalInstanceCtrl",
                        size: "md",
                        scope: $scope
                    });

                    modalInstance.result.then(function () {
                        item.organiserPersonId = $scope.personItem.id;
                        if(!ctrl.staff.hasOwnProperty(item.organiserPersonId)) {
                            ctrl.staff[item.organiserPersonId] = {
                                organiserId: item.organiserPersonId,
                                name: $scope.personItem.lastName
                            }
                        }
                        item.edited = true;
                        ctrl.teacherProfileClosed($scope.personItem.id);
                    },
                    function () {
                    });
                }

                $scope.getMatches = function (val) {
                    return $http.get("/Reception/GetPeople",
                        {
                            params: {
                                lastName: val,
                                type: $scope.searchType,
                                includeDeleted: false
                            }
                        }).then(function (response) {
                            return response.data;
                        });
                };

                $scope.onSelect = function ($item, $model, $label) {
                    $scope.$item = $item;
                    $scope.personItem = $item;
                    $scope.$model = $model;
                    $scope.$label = $label;
                };

                this.markEdited = function (item) {
                    this.edited = true;
                    item.edited = true;
                }.bind(this);

                this.teacherProfileClosed = function (personId)
                {
                    var staffIds = [personId];
                    staffRepository.getStaffInformationForStaffIds(staffIds)
                        .then(function (data)
                        {
                            if (data.length == 1)
                            {
                                this.staff[personId].onlineMeetingId = data[0].onlineMeetingId;
                            }
                        }.bind(this));
                }.bind(this);

                this.editOnlineMeetingUrl = function (organiserPersonId)
                {
                    if (typeof organiserPersonId === 'undefined' || organiserPersonId <= 0)
                    {
                        return;
                    }

                    let editOnlineMeetingUrlDialog = $uibModal.open({
                        component: 'profileOnlineLocation',
                        size: 'lg',
                        backdrop: 'static', // prevent modal from closing on backdrop click
                        resolve: {
                            staffMemberId: function () { return organiserPersonId; }
                        }
                    });

                    editOnlineMeetingUrlDialog.result
                        .then(function (response)
                        {
                            this.teacherProfileClosed(organiserPersonId);
                        }.bind(this))
                        .catch(function (err)
                        {
                        });
                }

                this.organiserCanHaveOnlineLocation = function (organiserPersonId) {
                    return !this.ccaSignUp.onlineLinkAutoGenerated && organiserPersonId > 0;
                }

            }

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/teams/directives/cca-team-bulk-created-list.template.html',
                bindToController: {
                    ccaSignUp: '=',
                    createdGroups: '=',
                    staff: '=',
                    regGroupNames: '=',
                    dismissHandler: '&'
                },
                controller: ['$scope', '$http', controller],
                controllerAs: 'ctrl',
                scope: false //{}, // isolate scope
            };
        }
    ]);
