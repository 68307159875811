'use strict';

angular.module('shared.directives.sbCurrencyInput', ['ui.bootstrap'])
    .directive('sbCurrencyInput', ['$window', 'guidService',
    function ($window, guidService) {

        function controller() {
            this.currencySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
            this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
            this.currencyPattern = '';
            this.dirty = false;
            this.elementName = 'c' + guidService.generateGuid();

            this.formatNumber = function () {
                if (!this.ngModel && this.ngModel !== 0) {
                    return;
                }

                this.ngModel = parseFloat(Math.round(+this.ngModel * 100) / 100).toFixed(this.maxDecimalPlaces);
            }.bind(this);

            this.$onInit = function()
            {
              this.formatNumber();
            }.bind(this);

            this.$onChanges = function (changes) {
                if (changes.allowNegative || changes.maxDecimalPlaces) {
                    if (!this.maxDecimalPlaces || this.currencyExponent === undefined) {
                        this.currencyExponent = this.currencyExponent === null || this.currencyExponent === undefined ? 2 : this.currencyExponent;
                        this.maxDecimalPlaces = this.currencyExponent;
                    }

                    var signPart = '[-+]?';
                    var intPart = '[0-9]{0,9}';

                    var decimalPart = '';
                    if (this.maxDecimalPlaces > 0) {
                        var decimalSeparator = '\\.';
                        var fractionPart = '[0-9]{1,' + this.maxDecimalPlaces + '}';
                        decimalPart = `(${decimalSeparator}${fractionPart})?`;
                    }

                    this.currencyPattern = `${intPart}${decimalPart}`;

                    // truthy fine here as will default to not allow negatives (allowNegative value is undefined).
                    if (this.allowNegative) {
                        this.currencyPattern = signPart + this.currencyPattern;
                    }
                }

                if (typeof this.ngModelCtrl === 'undefined') {
                    return;
                }

                // just switched from disallow to allow, clear out validity bit
                if (changes.allowZero && changes.allowZero.currentValue) {
                    this.ngModelCtrl.$setValidity('isZero', true);
                }

                if (changes.maxNumber && typeof this.ngModelCtrl !== 'undefined') {
                    // check max provided is a valid number
                    this.ngModelCtrl.$setValidity('invalidMax', !isNaN(+this.maxNumber));
                }

                this.formatNumber();

                if (changes.allowZero || changes.maxNumber) {
                    this.validate();
                }

            }.bind(this);

            this.validate = function () {
                // check for zero validity
                // falsy fine here as will default to not allow zero (allowZero value is undefined)
                if (!this.allowZero) {
                    this.ngModelCtrl.$setValidity('isZero', this.ngModel != 0 || !this.ngModel);
                }

                // check value does not exceed any maximum
                if (typeof this.maxNumber !== 'undefined' && !isNaN(+this.maxNumber)) {
                    this.ngModelCtrl.$setValidity('exceedsMax', this.ngModel <= +this.maxNumber || !this.ngModel);
                }
            }

            this.handleChange = function () {
                this.dirty = true;
                this.ngModelCtrl.$setValidity('isRequired', true);

                this.formatNumber();

                this.validate();

                if (typeof this.onChange === 'function') {
                    this.onChange();
                }
            }.bind(this);
        };

        return {
            bindToController: {
                isRequired: '<',
                isDisabled: '<',
                allowNegative: '<',
                allowZero: '<',
                maxDecimalPlaces: '<',
                maxNumber: '<',
                symbolPrefix: '@',
                onChange: '&',
                ngModel: '=',
                hidePrefix: '<'
            },
            require: 'ngModel',
            templateUrl: '/Scripts/app/shared/directives/sb-currency-input.template.html',
            controller: controller,
            controllerAs: 'ctrl',
            scope: {}, // isolated scope required so we can re-use this directive within same controller
            link: function (scope, element, attr, ngModelCtrl) {
                scope.ctrl.ngModelCtrl = ngModelCtrl;

                // A note on custom validator 'isRequired'.
                // Typically, a form with required fields wants to initially be invalid (e.g. so form submit button can be disabled).
                // In angularjs a form is invalid if any fields have ng-required and are empty. Unfortunately this means
                // those fields have the ng-invalid class and so will have any style we've associated, e.g. red border.
                // We don't want the red border until this input is dirty (edited).
                // The 'isRequired' custom validator allows us to invalidate the form without invalidating this field, so no ng-invalid class.
                // Once the input is dirty, we clear any 'isRequired' error and switch to standard ng-required behaviour.

                // At this point we have our bindings, so know if the initial model value and whether or not a value is required.
                // Our custom validator will be in error state (false) if field is required and model value is empty.
                ngModelCtrl.$setValidity('isRequired', !(scope.ctrl.isRequired && !scope.ctrl.ngModel && scope.ctrl.ngModel !== 0));
                scope.ctrl.validate();

                scope.$watch('ctrl.ngModel', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        scope.ctrl.handleChange();
                    }
                });
            }
        };
    }]);
