<input
    class="form-control"
    name="dp"
    ngbDatepicker
    [(ngModel)]="ngbDate"
    [minDate]="ngbMinDate"
    [maxDate]="ngbMaxDate"
    (dateSelect)="change($event)"
    (focusout)="change(ngbDate)"
    (keyup.enter)="change(ngbDate)"
    [readOnly]="disabled"
    [firstDayOfWeek]="6"
    placement="bottom-end"
    #d="ngbDatepicker"
/>
<div *ngIf="!disabled" class="date-icon-container" (click)="d.toggle()">
    <sb-icon name="date"></sb-icon>
</div>
