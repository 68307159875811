// start:ng42.barrel
import './auto-notify-event.component';
import './cancel-event-button.component';
import './confirm-manual-yes-or-no.component';
import './confirm-remove-student-from-event.component';
import './consent-event-invites.component';
import './consent-event-settings-edit.component';
import './event-detail-editor.component';
import './events-register-transport-add-student-select-stops.component';
import './events-register.component';
import './notify-only-event.component';
import './booking-countdown.component'
// end:ng42.barrel

