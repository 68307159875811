angular
    .module('teams.services.teamService', [
        'shared.services.fileService',
        'shared.file.constants'
    ])
    .factory('teamService', [
        '$http',
        '$cacheFactory',
        'fileService',
        'fileTypeEnum',
        function ($http, $cacheFactory, fileService, fileTypeEnum) {

            var urlBase = '/webapi/WebTeam/';
            var urlBaseOldTeams = '/Teams/';

            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('teamServiceCache');

            repository.clear = function () {
                repoCache.removeAll();
            };

            repository.getPersonIdsInTeam = function (teamId) {

                return $http.get(urlBase + 'GetPersonIdsInTeam', { params: { teamId: teamId }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getPeopleInTeam = function (teamId) {

                return $http.get(urlBase + 'GetPeopleInTeam', { params: { teamId: teamId }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getRegGroupTeamsAndOrganisers = function (teamId) {

                return $http.get(urlBase + 'GetRegGroupTeamsAndOrganisers')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getAttendance = function (teamId, eventsFrom, eventsTo) {

                return $http.get(urlBase + 'GetTeamAttendance', { params: { teamId: teamId, eventsFrom: eventsFrom, eventsTo: eventsTo }, cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getTeamAttendanceReportCsv = function (teamId, eventsFrom, eventsTo) {
                return $http.get(`${urlBase}GetTeamAttendanceReport`, { params: { teamId: teamId, eventsFrom: eventsFrom, eventsTo: eventsTo } })
                    .then(function(response) {
                        fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `Team_Attendance${teamId}`);
                    });
            }

            repository.postPupilChangesToTeam = function (teamId, selectedPersonIds) {
                repoCache.removeAll();

                return $http.post(urlBase + 'PostPupilChangesToTeam', { teamId: teamId, personIds: selectedPersonIds })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.postStaffChangesToTeam = function (teamId, selectedPersonIds) {
                repoCache.removeAll();

                return $http.post(urlBase + 'PostStaffChangesToTeam', { teamId: teamId, personIds: selectedPersonIds })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            repository.addTeam = function (newTeam) {

                return $http.post(urlBaseOldTeams + 'Create', { group: newTeam })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.getAllForLookup = function (includeArchived) {

                return $http.get(urlBase + 'GetAllForSimpleLookup?includeArchived=' + includeArchived, { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };


            repository.getTeamTypeForLookup = function (teamType) {

                return $http.get(urlBase + 'GetTeamTypeForSimpleLookup?teamType=' + teamType, { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };



            repository.getTabVisibility = function () {

                return $http.get(urlBase + 'GetTabVisibility', { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.getMedicalDataForTeam = function (teamId) {

                return $http.get(urlBase + 'GetMedicalDataForTeam?teamId=' + teamId, { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };
            repository.getGuardiansForTeam = function (teamId) {

                return $http.get(urlBase + 'GetGuardiansForTeam?teamId=' + teamId, { cache: repoCache })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.getGuardiansForTeamCsv = function (teamId) {
                $http.get(`${urlBase}GetGuardiansForTeamCsv`, { params: { teamId: teamId } })
                    .then(response => {
                        fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `GuardiansForTeam${teamId}`);
                    });
            };

            repository.getMedicalDataForTeamCsv = function (teamId) {

                $http.get(`${urlBase}GetMedicalDataForTeamCsv`, { params: { teamId: teamId } })
                    .then(response => {
                        fileService.saveFile(response, fileTypeEnum.CSVFromResponse, `MedicalDataForTeam${teamId}`);
                    });
            };

            repository.getJoinRequestsForTeam = function (teamId, includeProcessed) {

                return $http.get(urlBase + 'GetJoinRequestsForTeam', { params: {teamId: teamId, includeProcessed: includeProcessed} })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.getJoinRequestsForCcaSignUp = function (ccaSignUpId, includeProcessed) {

                return $http.get(urlBase + 'GetJoinRequestsForCcaSignUp', { params: {ccaSignUpId: ccaSignUpId, includeProcessed: includeProcessed} })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.getJoinRequestsForOrganisation = function (includeProcessed) {

                return $http.get(urlBase + 'GetJoinRequestsForOrganisation', { params: {includeProcessed: includeProcessed} })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.acceptRequest = function (requestId) {

                repoCache.removeAll();

                return $http.post(urlBase + 'AcceptJoinRequest', requestId)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });

            };

            repository.rejectRequest = function (requestId) {

                repoCache.removeAll();

                return $http.post(urlBase + 'RejectJoinRequest', requestId)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            return repository;
        }
    ]);
