'use strict';

angular.module('calendarEventCreationRule.directives.calendarEventCreationRuleList', [
    'ccasignup.constants',
])
    .directive('calendarEventCreationRuleList',
        [
            '$filter',
            '$timeout',
            '$translate',
            'calendarEventCreationRuleService',
            'parentTeacherConferenceLocationTypes',
            function ($filter, $timeout, $translate, calendarEventCreationRuleService, parentTeacherConferenceLocationTypes) {
                function link($scope) {
                    $scope.$watch('signUp', function (newValue) {
                        if ($scope.signUp) {
                            $scope.signUpOpenDate = moment.utc($scope.signUp.openDate).toDate();
                        }
                    });

                    $scope.$watch('signUpGroups',
                        function (newValue)
                        {
                            if ($scope.signUpGroups)
                            {
                                for (var i = $scope.signUpGroups.length; i--;)
                                {
                                    $scope.teamNames[$scope.signUpGroups[i].id] = $scope.signUpGroups[i].title;
                                }
                            }
                        });

                    $scope.$watch('organisers',
                        function (newValue)
                        {
                            if (newValue)
                            {
                                $scope.organiserNames = {};
                                if (newValue.length > 0)
                                {
                                    newValue.push({ id: 0, name: $translate.instant('SB_All') });

                                    for (var i = newValue.length; i--;)
                                    {
                                        $scope.organiserNames[newValue[i].id] = newValue[i].name;
                                    }
                                }
                            }
                        });

                    $scope.$watch('rules',
                        function (newValue)
                        {
                            if (newValue)
                            {
                                $scope.filteredRules = newValue;

                                $scope.meetingStartDates = [];
                                $scope.ruleStartDates = [];

                                $scope.filteredRules.forEach((filteredRule) =>
                                {
                                    if ($scope.ruleStartDates.indexOf(filteredRule.startDate) < 0)
                                    {
                                        $scope.ruleStartDates.push(filteredRule.startDate);
                                    }
                                });

                                $scope.ruleStartDates = $scope.ruleStartDates.sort(function (a, b)
                                {
                                    return a - b;
                                });


                                $scope.meetingStartDates.push($translate.instant('SB_All'));
                                $scope.ruleStartDates.forEach((startDate) =>
                                {
                                    let meetingStartDate = $filter('date')(startDate, 'EEE dd MMM yyyy', 'UTC');
                                    if ($scope.meetingStartDates.indexOf(meetingStartDate) < 0)
                                    {
                                        $scope.meetingStartDates.push(meetingStartDate);
                                    }
                                });
                            }
                        });
                }

                function controller($scope)
                {
                    $scope.teamNames = {};
                    $scope.organisers = [];
                    $scope.selectedRules = [];
                    $scope.anyRulesSelected = false;
                    $scope.filter = {};

                    $scope.all = $translate.instant('SB_All').toLowerCase();

                    $scope.rulesFilter = function(rule)
                    {
                        if (typeof $scope === 'undefined' || $scope.filter === 'undefined')
                        {
                            return true;
                        }

                        let organisers = $scope.organisers.filter((organiser) =>
                        {
                            return organiser.id == rule.organiserPersonId;
                        });

                        let organiserName = organisers[0].name;

                        if ($scope.filter?.organiserName && organiserName && $scope.filter.organiserName.toLowerCase() != $scope.all && organiserName.toLowerCase().indexOf($scope.filter?.organiserName?.toLowerCase()) === -1)
                        {
                            return false;
                        }

                        let meetingStartDate = $filter('date')(rule.startDate, 'EEE dd MMM yyyy', 'UTC');
                        if ($scope.filter?.startDate && $scope.filter.startDate.toLowerCase() != $scope.all && meetingStartDate.toLowerCase().indexOf($scope.filter.startDate.toLowerCase()) === -1)
                        {
                            return false;
                        }

                        return true;
                    };

                    $scope.filterChanged = function()
                    {
                        $scope.rules.forEach((rule) =>
                        {
                            rule.isSelectedForPublication = false;
                        });

                        $scope.ruleSelectionChanged();

                        let activeFilters = Object.values($scope.filter).filter(val =>
                        {
                            return val !== '' && val !== 0;
                        });

                        $scope.filterCount = activeFilters.length;

                        $scope.filteredRules = [];
                        if ($scope.rules)
                        {
                            $scope.filteredRules = $scope.rules.filter(rule =>
                            {
                                return $scope.rulesFilter(rule);
                            });
                        }
                    }

                    $scope.getLocationName = function(rule)
                    {
                        if (!rule || !$scope.locations) {
                            return '';
                        }

                        let showPhysicalLocationLabel = !$scope.signUp.isPTC || (rule.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.InPerson || rule.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.Both);
                        let showOnlineLabel = $scope.signUp.isPTC && (rule.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.Both || rule.parentTeacherConferenceLocationTypeId === parentTeacherConferenceLocationTypes.Online);
                        let label  = '';

                        if (showPhysicalLocationLabel) {
                            for (var i = $scope.locations.length; i--;)
                            {
                                if ($scope.locations[i].id === rule.locationId) {
                                    label = $scope.locations[i].name;
                                }
                            }
                        }
                        if (!showOnlineLabel && label.length > 0)
                        {
                            return label;
                        }

                        if(label.length > 0)
                        {
                            label += "/";
                        }

                        label += showOnlineLabel ? $filter('translate')($scope.signUp.onlineParentsEveningLabel) : $filter('translate')('SB_Unspecified_location');


                        return label;
                    };

                    $scope.editClick = function(item)
                    {
                        if ($scope.onEditClick && typeof ($scope.onEditClick()) === 'function')
                        {
                            $scope.onEditClick()(item);
                        }
                    };

                    $scope.copyClick = function(item)
                    {
                        if ($scope.onCopyClick && typeof ($scope.onCopyClick()) === 'function')
                        {
                            $scope.onCopyClick()(item);
                        }
                    };

                    $scope.deleteClick = function(item)
                    {
                        if ($scope.onDeleteClick && typeof ($scope.onDeleteClick()) === 'function')
                        {
                            $scope.onDeleteClick()(item);
                        }
                    };

                    $scope.selectedDaysSummary = function(daysOfWeek)
                    {
                        return calendarEventCreationRuleService.selectedDaysSummary(daysOfWeek);
                    };

                    $scope.isOneDay = function(item)
                    {
                        return item.startDate.toString() === item.endDate.toString();
                    };

                    $scope.hasSignUpOpen = function(item)
                    {
                        return ($scope.isOneDay(item) && !!item.signUpOpen) || // is single day and has absolute cut-off
                            (!$scope.isOneDay(item) && !!item.signUpOpenHoursBefore); // is multiple day and has relative cut-off > 0
                    }

                    $scope.hasSignUpClose = function(item)
                    {
                        return ($scope.isOneDay(item) && !!item.signUpClose) || // is single day and has absolute cut-off
                            (!$scope.isOneDay(item) && !!item.signUpCloseHoursBefore); // is multiple day and has relative cut-off > 0
                    }

                    $scope.getDaysFromSignUpHours = function(hours) {
                        return Math.floor(hours / 24);
                    }

                    $scope.seriesRuleName = function (item)
                    {
                        if (item.isBlockEvent) {
                            if (item.allowJoiningOfInFlightBlockEvent) {
                                return 'SB_Block_Booking_allowing_joining_part_way';
                            }
                            else {
                                return 'SB_Block_booking';
                            }
                        }
                        else {
                            return 'SB_Individual_booking';
                        }
                    };

                    $scope.search = function (item) {

                        var matchFound = true;

                        if ($scope.searchFilters) {

                            //locationId is -1 ass wwe have a Unspecifiedd location using 0
                            var lookForTeamId = $scope.searchFilters.team ? $scope.searchFilters.team.id : 0;
                            var lookForLocationId = $scope.searchFilters.location ? $scope.searchFilters.location.id : -1;
                            var lookForOrganiserId = $scope.searchFilters.organiser ? $scope.searchFilters.organiser.id : 0;

                            var matchTeam = lookForTeamId === 0;
                            var matchLocation = lookForLocationId === -1;
                            var matchOrganiser = lookForOrganiserId === 0;

                            if (matchTeam && matchLocation && matchOrganiser) {
                                return true;
                            }

                            matchTeam = (lookForTeamId === 0 || item.teamId === lookForTeamId);

                            matchLocation = (lookForLocationId === -1 || item.locationId === lookForLocationId);

                            matchOrganiser = (lookForOrganiserId === 0 || item.organiserPersonId === lookForOrganiserId);


                            matchFound = matchTeam && matchLocation && matchOrganiser;

                            }

                        return matchFound;
                    };

                    $scope.customOrderBy = function (a, b) {

                        if (!$scope.sortType)
                            return 0;

                        switch ($scope.sortType.toLowerCase()) {
                            case 'teamid':
                                return $scope.teamNames[a.value].localeCompare($scope.teamNames[b.value]);
                            case 'organiserpersonid':
                                return $scope.organiserNames[a.value].localeCompare($scope.organiserNames[b.value]);
                            case 'locationid':
                                return $scope.getLocationName(a.value).localeCompare($scope.getLocationName(b.value));
                            case 'startdate':
                                if (a.type === 'object' && b.type === 'object') {
                                    return new Date(a.value) < new Date(b.value) ? -1 : 1;
                                }
                                else {
                                    return 0;
                                }
                            default:
                                if (a.type === 'number' || b.type === 'number' ) {
                                    return (a.value < b.value) ? -1 : 1;
                                }
                                else if (a.type === 'string' || b.type === 'string') {
                                    return a.value.localeCompare(b.value);
                                }

                                return (a.index < b.index) ? -1 : 1;
                        }
                    };

                    $scope.toggleSelectAll = function (selectAll)
                    {
                        if (selectAll)
                        {
                            $scope.rules.filter(function (rule)
                            {
                                rule.isSelectedForPublication = rule.statusId == 0; // Only select a rule if its status is not "Published".
                            });

                            $scope.ruleSelectionChanged();
                            return;
                        }

                        $scope.rules.filter(function (rule)
                        {
                            rule.isSelectedForPublication = false;
                        });

                        $scope.ruleSelectionChanged();
                    };

                    $scope.ruleSelectionChanged = function ()
                    {
                        $timeout(function ()
                        {
                            $scope.selectedRules = $scope.filteredRules.filter(function (rule)
                            {
                                return rule.isSelectedForPublication;
                            });

                            $scope.anyRulesSelected = $scope.selectedRules.length > 0;

                            if ($scope.onRuleSelect && typeof ($scope.onRuleSelect()) === 'function')
                            {
                                $scope.onRuleSelect()($scope.selectedRules);
                            }
                        }, 100);
                    };
                }

                return {
                    restrict: 'E',
                    templateUrl: '/Scripts/app/calendarEventCreationRule/directives/calendar-event-creation-rule-list.template.html',
                    scope: {
                        signUp: '<',
                        signUpGroups: '=',
                        rules: '=',
                        selectedRuleId: '=',
                        locations: '=',
                        organisers: '=',
                        onRuleSelect: '&',
                        onCopyClick: '&',
                        onEditClick: '&',
                        onDeleteClick: '&',
                        searchFilters: '=',
                        hidePublished: '=',
                        showButtons: '=',
                        showEventCount: '=',
                        showPublishColumn: '=',
                        hidePublishedColumn: '=',
                        showFilter: '='
                    },
                    controller: ['$scope', controller],
                    link: link
                };
            }
        ]);
