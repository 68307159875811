import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForbiddenErrorService {
  public errors: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  raiseError(error: HttpErrorResponse) {
    this.errors.next(error);
  }
}
