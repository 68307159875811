'use strict';

angular.module('ccasignupModule').component('ccaGenerateEvents', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-generate-events.template.html',
    controller: [
    '$scope',
    '$state',
    '$uibModal',
    'dateTextService',
    'signUpRepository',
    'simpleAlertService',
    function (
        $scope,
        $state,
        $uibModal,
        dateTextService,
        signUpRepository,
        simpleAlertService) {

        $scope.loading = {};
        $scope.signUp = {};

        $scope.generate = { agree: false, additionalInformation: '', sendCommunication: true };
        var signUpId = $state.params.signUpId;

        $scope.sortType = 'title'; // set the default sort type
        $scope.sortReverse = false; // set the default sort order

        signUpRepository.getSignUp(signUpId).then(function (signUp) { $scope.signUp = signUp; });

        signUpRepository.getIncompleteSignUpGroups(signUpId).then(function (signUpGroups) {
            $scope.loading = null;
            $scope.groups = signUpGroups.groups;
            $scope.summary = signUpGroups.summary;
        });

        $scope.onHold = function (signUpGroupId) {
            simpleAlertService.simpleAlert({
                title: 'SB_Warning_Allocation_Clear',
                message: 'SB_Warning_Allocation_Clear_Text',
                okButtonText: 'SB_OK',
                cancelButtonText: 'SB_Cancel'
            }).result
                .then(function () {
                    $scope.groups = null;
                    signUpRepository.postGroupStatus($state.params.signUpId, signUpGroupId, 3) // TeamStatus.OnHold
                        .then(function (data) {
                            signUpRepository.getIncompleteSignUpGroups(signUpId)
                                .then(function (signUpGroups) {
                                    $scope.groups = signUpGroups.groups;
                                    $scope.summary = signUpGroups.summary;
                                });
                        });
                });
        };

        $scope.markCompleted = function (signUpGroupId) {
            $scope.groups = null;
            signUpRepository.postGroupStatus($state.params.signUpId, signUpGroupId, 2) // TeamStatus.Complete
                .then(function (data) {
                    signUpRepository.getIncompleteSignUpGroups(signUpId)
                        .then(function (signUpGroups) {
                            $scope.groups = signUpGroups.groups;
                            $scope.summary = signUpGroups.summary;
                        });
                });
        };

        $scope.ccaGroupDetails = function (signUpGroupId) {
            $state.go('ccaGroupDetails', { signUpId: $state.params.signUpId, signUpGroupId: signUpGroupId });
        };

        $scope.dateText = dateTextService.getLongDayText;

        $scope.statusText = function (statusId) {
            switch (statusId) {
                case 0:
                    return 'SB_Not_Started';
                case 1:
                    return 'SB_Partial';
                case 2:
                    return 'SB_Complete';
                case 3:
                    return 'SB_On_Hold';
                case 4:
                    return 'SB_Auto_Complete';
                case 5:
                    return 'SB_Archived';
                case 6:
                    return 'SB_CcaStatus_Active';
                default:
                    return 'SB_Not_Started';
            }
        };

        $scope.generateEvents = function () {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'confirmGenerateModal.html',
                controller: 'GenerateModalInstanceCtrl',
                size: 'sm'
            });

            modalInstance.result.then(function () {
                $scope.groups = null;
                signUpRepository.postGenerateEvents($state.params.signUpId, $scope.generate.additionalInformation, $scope.generate.sendCommunication)
                    .then(function () {
                        $state.go('ccaDetails.groups', { signUpId: $state.params.signUpId });
                    });
            }, function () {
            });
        };

        $scope.createAdditionalEvents = function ()
        {
            $state.go('ccaDetails.generateAdditionalEvents', {
                signUpId: $state.params.signUpId,
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        };

        $scope.cancelOrDeleteEvents = function ()
        {
            $state.go('ccaDetails.ccaCancelOrDeleteEvents', {
                signUpId: $state.params.signUpId,
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        };

        $scope.cancelOrDeleteEvents = function ()
        {
            $state.go('ccaDetails.ccaCancelOrDeleteEvents', {
                signUpId: $state.params.signUpId,
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        };
    }
]});

angular.module('ccasignupModule').controller('GenerateModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
