export enum UiClassId {
    Success = 1,
    Danger = 2,
    Warning = 3,
    Info = 4,
    Muted = 5,
    Primary = 6,
    Secondary = 7,
    Neutral = 8
}

export const UiClassValues = {
    1: 'success',
    2: 'danger',
    3: 'warning',
    4: 'info',
    5: 'muted',
    6: 'primary',
    7: 'secondary',
    8: 'neutral'
}