'use strict';

angular.module('messaging.services.messageViewingService', [])
    .factory('messageViewingService', [
        '$http', '$q', '$cacheFactory', '$httpParamSerializerJQLike',
        function ($http, $q, $cacheFactory, $httpParamSerializerJQLike)
        {

            var urlBase = '/webapi/WebMessageViewer/';
            var repository = {};
            var repoCache = $cacheFactory('messagingViewingServiceCache');

            repository.clearCache = function ()
            {
                repoCache.removeAll();
            };

            repository.getAllMessageCountsForCurrentUser = function ()
            {
                return $http.get(urlBase + 'GetAllMessageCounts')
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getChatSessionsForType = function (sessionType, fromDate, toDate)
            {
                return $http.get(urlBase + 'GetChatSessionsForType',
                    {
                        cache: repoCache,
                        params: {
                            sessionType: sessionType,
                            fromDate: fromDate,
                            toDate: toDate
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getChatFilters = function (isStatus365, isStaff, isParent)
            {
                var returnValue = [1, 2, 5];

                if (isStaff)
                {
                    returnValue.push(7);
                }

                if (isParent || isStaff)
                {
                    returnValue.push(4);
                }

                if (!isStatus365)
                {
                    returnValue.push(3);
                }

                return returnValue;
            };

            repository.getPersonInbox = function (filters)
            {
                return $http.post(urlBase + 'GetPersonInbox', filters)
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.markAllAsRead = function ()
            {
                return $http.post(urlBase + 'MarkAllAsRead')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            repository.getPersonInboxUnfiltered = function (filters)
            {
                return $http.post(urlBase + 'GetPersonInboxUnfiltered', filters)
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getAdminInbox = function (filters)
            {
                return $http.post(urlBase + 'GetAdminInbox', filters)
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getAdminInboxNewOnly = function (filters)
            {
                return $http.post(urlBase + 'GetAdminInboxNewOnly', filters)
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getSpecificMessage = function (messageId)
            {
                return $http.post(urlBase + 'GetSpecificMessage', messageId)
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.starMessage = function (messageId, isStarred)
            {
                return $http.get(urlBase + 'StarMessage',
                    {
                        params: {
                            messageId: messageId,
                            starred: isStarred
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.archiveMessage = function (messageId, isArchived)
            {
                return $http.get(urlBase + 'ArchiveMessage',
                    {
                        params: {
                            messageId: messageId,
                            archived: isArchived
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            };

            repository.getAllLookups = function ()
            {
                return $http.get(urlBase + 'GetAllLookups',
                    {
                        cache: repoCache
                    })
                    .then(function (response)
                    {
                        return response.data;
                    })
                    .catch(function (e)
                    {
                        return { isError: true, errorData: e };
                    });
            }

            return repository;
        }
    ]);