import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxEchartsModule } from 'ngx-echarts';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { Ng1ServicesModule } from './../ng1-services/ng1-services.module';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { AlertComponent } from './components/alert/alert.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BalanceWidgetComponent } from './components/balance-widget/balance-widget.component';
import { BannerComponent } from './components/banner/banner.component';
import { ButtonComponent } from './components/button/button.component';
import { CardDetailComponent } from './components/card-detail/card-detail.component';
import { ChartComponent } from './components/chart/chart.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { ColourPickerComponent } from './components/colour-picker/colour-picker.component';
import { ColumnHeaderComponent } from './components/column-header/column-header.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CountsComponent } from './components/counts/counts.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { DataDashboardComponent } from './components/data-dashboard/data-dashboard.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DateRangePickerUpgrade } from './components/date-range-picker/date-range-picker-upgraded.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateComponent } from './components/date/date.component';
import { DetailComponent } from './components/detail/detail.component';
import { DiaryItemDetailComponent } from './components/diary-item-detail/diary-item-detail.component';
import { DiaryItemComponent } from './components/diary-item/diary-item.component';
import { EditFieldComponent } from './components/edit-field/edit-field.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { GameSummaryComponent } from './components/game-summary/game-summary.component';
import { GreetingComponent } from './components/greeting/greeting.component';
import { IconImgComponent } from './components/icon-img/icon-img.component';
import { IconComponent } from './components/icon/icon.component';
import { KitComponent } from './components/kit/kit.component';
import { LinkCardComponent } from './components/link-card/link-card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LocalePickerComponent } from './components/locale-picker/locale-picker.component';
import { LocationLiteComponent } from './components/location-lite/location-lite.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { Ng1CardDetailWrapperComponent } from './components/ng1-card-detail-wrapper/ng1-card-detail-wrapper.component';
import { MultiPersonPickerUpgrade } from './components/ng1-multi-person-picker/ng1-multi-person-picker-upgrade.component';
import { TeamClubPickerSummaryUpgrade } from './components/ng1-team-club-picker-summary/ng1-team-club-picker-summary-upgrade.component';
import { TeamClubPickerUpgrade } from './components/ng1-team-club-picker/ng1-team-club-picker-upgrade.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { OnOffSwitchComponent } from './components/on-off-switch/on-off-switch.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { PageTabsComponent } from './components/page-tabs/page-tabs.component';
import { PercentageInputComponent } from './components/percentage-input/percentage-input.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RecordTypeSelectorComponent } from './components/record-type-selector/record-type-selector.component';
import { ReportListComponent } from './components/report-list/report-list.component';
import { ReportComponent } from './components/report/report.component';
import { RouteTimelineComponent } from './components/route-timeline/route-timeline.component';
import { SearchFilterItemComponent } from './components/search-filter-item/search-filter-item.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { SelectComponent } from './components/select/select.component';
import { SideMenuNavPillComponent } from './components/side-menu-nav-pill/side-menu-nav-pill.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { StackedDateComponent } from './components/stacked-date/stacked-date.component';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { SummaryButtonComponent } from './components/summary-button/summary-button.component';
import { EventSummaryComponent } from './components/event-summary/event-summary.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TeamClubPickerComponent } from './components/team-club-picker/team-club-picker.component';
import { TelephoneInputComponent } from './components/telephone-input/telephone-input.component';
import { TextComponent } from './components/text/text.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TimeBadgeComponent } from './components/time-badge/time-badge.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { WelcomeMessageComponent } from './components/welcome-message/welcome-message.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { TwitterFeedComponent } from './components/twitter-feed/twitter-feed.component';
import { SingleDatePickerComponent } from './components/single-date-picker/single-date-picker.component';

const components: any[] = [
  GreetingComponent,
  SideMenuComponent,
  SubMenuComponent,
  SideMenuNavPillComponent,
  ActionMenuComponent,
  IconImgComponent,
  IconComponent,
  LoadingComponent,
  Ng1CardDetailWrapperComponent,
  CardDetailComponent,
  TabsComponent,
  PageLoaderComponent,
  AlertComponent,
  ButtonComponent,
  AvatarComponent,
  ColumnHeaderComponent,
  DateRangePickerComponent,
  DateRangePickerUpgrade,
  EditFieldComponent,
  NumberInputComponent,
  OnOffSwitchComponent,
  SelectComponent,
  SubmitButtonComponent,
  PercentageInputComponent,
  TelephoneInputComponent,
  TextareaComponent,
  TitleBarComponent,
  CheckBoxComponent,
  CurrencyInputComponent,
  RadioButtonComponent,
  ModalHeaderComponent,
  SearchFilterComponent,
  SearchFilterItemComponent,
  DetailComponent,
  DataTableComponent,
  BadgeComponent,
  KitComponent,
  ColourPickerComponent,
  WizardComponent,
  MultiPersonPickerUpgrade,
  EventSummaryComponent,
  StackedDateComponent,
  BannerComponent,
  ConfirmDialogComponent,
  ChartComponent,
  RecordTypeSelectorComponent,
  GameSummaryComponent,
  TimeBadgeComponent,
  TeamClubPickerUpgrade,
  TeamClubPickerSummaryUpgrade,
  TeamClubPickerComponent,
  ReportComponent,
  ReportListComponent,
  DataDashboardComponent,
  SummaryButtonComponent,
  PageTabsComponent,
  WelcomeMessageComponent,
  BalanceWidgetComponent,
  DiaryItemComponent,
  LinkCardComponent,
  DateComponent,
  LocalePickerComponent,
  RouteTimelineComponent,
  TextComponent,
  DiaryItemDetailComponent,
  CountsComponent,
  MultiselectDropdownComponent,
  EventListComponent,
  LocationLiteComponent,
  TwitterFeedComponent,
  SingleDatePickerComponent
];

const pipes: any[] = [OrderByPipe, LimitToPipe];

const modules: any[] = [
  NgbModule,
  RouterModule,
  CommonModule,
  Ng1ServicesModule,
  FormsModule,
  ReactiveFormsModule,
  EditorModule,
  TranslateModule,
  InlineSVGModule,
  NgMultiSelectDropDownModule.forRoot(),
  NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
  PowerBIEmbedModule,
  FroalaEditorModule.forRoot(),
  FroalaViewModule.forRoot()
];

// Ng1 Services

export abstract class Ng1CachedLookupService {
  [key: string]: any;
}

export function ng1CachedLookupFactory(i: any) {
  return i.get('cachedLookupService');
}

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules],
  exports: [...components, ...pipes, ...modules],
  providers: [
    ConfirmDialogService,
    {
      provide: Ng1CachedLookupService,
      useFactory: ng1CachedLookupFactory,
      deps: ['$injector']
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class SharedModule {}
