angular.module('settingsModule')
    .component('staffRolesExternal',
        {
            templateUrl: '/Scripts/app/settings/components/staff-roles/staff-roles-external.template.html',
            controller: class StaffRolesExternalCtrl {

                tabs: any;
                mode: any;

                constructor () {}

                    $onInit() {
                        this.tabs = {
                            Users: 1,
                            Roles: 2
                        }

                        this.mode = this.tabs[0];
                    }

                }
            });