"use strict";

/*

AUTO ALLOCATE - USER PICKS THEMSELVES

*/

angular.module('ccasignupModule').component("ccaGroupManageAA", {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-group-manage-aa.template.html',
    controller:
        [
            "$scope",
            "$state",
            '$locale',
            "$q",
            "$window",
            'imagesUploadHandler',
            'signUpRepository',
            'staffRepository',
            '$uibModal',
            'subjectClassService',
            'tinymceConfigHelper',
            'simpleAlertService',
            'parentTeacherConferenceLocationTypes',
            function ($scope,
                $state,
                $locale,
                $q,
                $window,
                imagesUploadHandler,
                signUpRepository,
                staffRepository,
                $uibModal,
                subjectClassService,
                tinymceConfigHelper,
                simpleAlertService,
                parentTeacherConferenceLocationTypes) {
                $scope.tinymceOptions = tinymceConfigHelper.getTinyMceConfig(
                    {
                        tables: false,
                        hyperlinks: true,
                        imagesUploadHandler: imagesUploadHandler.uploadImage,
                        imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                    });

                $scope.saving = false;
                $scope.parentTeacherConferenceLocationTypes = parentTeacherConferenceLocationTypes;
                //locale safe weeekday names
                var dtf = $locale.DATETIME_FORMATS;

                $scope.items = [
                    {
                        id: "1",
                        name: dtf.DAY[1]
                    },
                    {
                        id: "2",
                        name: dtf.DAY[2]
                    },
                    {
                        id: "3",
                        name: dtf.DAY[3]
                    },
                    {
                        id: "4",
                        name: dtf.DAY[4]
                    },
                    {
                        id: "5",
                        name: dtf.DAY[5]
                    },
                    {
                        id: "6",
                        name: dtf.DAY[6]
                    },
                    {
                        id: "7",
                        name: dtf.DAY[0]
                    }
                ];

                $scope.statuses = [
                    {
                        id: "0",
                        name: 'SB_Not_Started'
                    },
                    {
                        id: "1",
                        name: 'SB_Partial'
                    },
                    {
                        id: "2",
                        name: 'SB_Complete'
                    },
                    {
                        id: "3",
                        name: 'SB_On_Hold'
                    },
                    {
                        id: "4",
                        name: 'SB_Auto_Complete'
                    },
                    {
                        id: 5,
                        name: 'SB_Archived'
                    },
                    {
                        id: 6,
                        name: 'SB_CcaStatus_Active'
                    }
                ];

                $scope.costTypes = [
                    {
                        id: "0",
                        name: 'SB_CcaCostType_Free'
                    },
                    {
                        id: "1",
                        name: 'SB_CcaCostType_Per_term'
                    },
                    {
                        id: "2",
                        name: 'SB_CcaCostType_Per_session'
                    },
                    {
                        id: "3",
                        name: 'SB_CcaCostType_One_Off'
                    }
                ];

                $scope.genders = [
                    {
                        id: "1",
                        name: 'SB_Female'
                    },
                    {
                        id: "2",
                        name: 'SB_Male'
                    },
                    {
                        id: "3",
                        name: 'SB_Mixed'
                    }
                ];

                $scope.isOnlinePtc = false;
                $scope.inPerson = true;

                var promises = [];

                promises.push(signUpRepository.getYears());
                promises.push(signUpRepository.getLocations());
                promises.push(signUpRepository.getOrganisers());
                promises.push(signUpRepository.getSports());
                promises.push(subjectClassService.subjectsEnabledForOrganisation());

                if ($state.params.signUpGroupId) {
                    promises.push(signUpRepository.getSignUpGroup($state.params.signUpId, $state.params.signUpGroupId));
                }
                else {
                    promises.push(signUpRepository.getSignUpDto($state.params.signUpId));
                }

                signUpRepository.initialiseLocalRequirementGroups($scope);

                $q.all(promises).then(function (responses) {
                    if (responses && responses.length == 6) {
                        $scope.years = responses[0];
                        $scope.locations = responses[1];
                        $scope.organisers = responses[2]
                        $scope.sports = responses[3];
                        $scope.subjectsEnabled = responses[4];

                        $scope.handleSignUpGroup(responses[5]);

                        var staffIds = [];
                        for (var i = 0; i < $scope.organisers.length; i++) {
                            staffIds.push($scope.organisers[i].id);
                        }

                        staffRepository.getStaffInformationForStaffIds(staffIds)
                            .then(function (staffInformations) {
                                $scope.staffInformations = staffInformations;

                                $scope.leadStaffMemberSelected();

                                for (const staffInformation of $scope.staffInformations) {
                                    if (staffInformation.personId == $scope.signUpGroup.organiserPersonId) {
                                        $scope.selectedOrganiserOnlineMeetingId = staffInformation.onlineMeetingId;
                                        break;
                                    }
                                }

                                $scope.isFormReady = true;
                            });
                    };
                }, function (error) {
                    console.error(error)
                });

                $scope.leadStaffMemberSelected = function () {

                    if ($state.params.searchData && $state.params.searchData.selectedOrganisers) {
                        delete $state.params.searchData.selectedOrganisers;
                    }

                    if (!$scope.isOnlinePtc) {
                        return;
                    }

                    $scope.selectedOrganiserOnlineMeetingId = '';
                    if ($scope.staffInformations) {
                        for (var i = 0; i < $scope.staffInformations.length; i++) {
                            if ($scope.staffInformations[i].personId == $scope.signUpGroup.organiserPersonId) {
                                $scope.selectedOrganiserOnlineMeetingId = $scope.staffInformations[i].onlineMeetingId;
                                break;
                            }
                        }
                    }
                };

                $scope.handleSignUpGroup = function (response) {
                    if ($state.params.signUpGroupId) {
                        $scope.signUpGroup = response;

                        if ($state.params.copy && $state.params.copy === true) {
                            $scope.signUpGroup.id = 0;
                        }

                        $scope.signUpGroup.onlineLinkAutoGenerated = response.onlineLinkAutoGenerated;
                        $scope.setPtcFields(response);
                        $scope.selectedOrganiserOnlineMeetingId = '';
                    }
                    else {
                        $scope.signUpGroup = {};
                        $scope.signUpGroup.ccaTeamClubs = [];
                        $scope.signUpGroup.ccaTeamTeams = [];
                        $scope.signUpGroup.ccaTeamSubjectClasses = [];
                        $scope.signUpGroup.ccaSignUpId = $state.params.signUpId;
                        $scope.signUpGroup.genderTypeId = 3;
                        $scope.signUpGroup.showInSportsPortal = false;
                        $scope.signUpGroup.onlineLinkAutoGenerated = response.onlineLinkAutoGenerated;
                        $scope.signUpGroup.onlineParentsEveningLabel = response.onlineParentsEveningLabel;

                        $scope.signUpGroup.ccaStartHour = response.defaultStartHour;
                        $scope.signUpGroup.ccaStartMin = response.defaultStartMin;
                        $scope.signUpGroup.ccaEndHour = response.defaultEndHour;
                        $scope.signUpGroup.ccaEndMin = response.defaultEndMin;
                        $scope.signUpGroup.allowJoinRequests = response.allowJoinRequests;
                        $scope.signUpGroup.isPTC = response.isPTC;
                        $scope.setPtcFields(response);
                    }

                    signUpRepository.populateRequirementGroupsFromCcaSignup($scope.signUpGroup, $scope.requirementGroups);
                }

                $scope.setPtcFields = function (response) {
                    $scope.isOnlinePtc = response.isPTC && (response.parentTeacherConferenceLocationTypeId == parentTeacherConferenceLocationTypes.Online || response.parentTeacherConferenceLocationTypeId == parentTeacherConferenceLocationTypes.Both);
                    $scope.inPerson = !response.isPTC || response.parentTeacherConferenceLocationTypeId == parentTeacherConferenceLocationTypes.InPerson || response.parentTeacherConferenceLocationTypeId == parentTeacherConferenceLocationTypes.Both;
                    $scope.parentTeacherConferenceLocationTypeId = response.parentTeacherConferenceLocationTypeId;
                }

                subjectClassService.subjectsEnabledForOrganisation()
                    .then(function (data) {
                        $scope.subjectsEnabled = data;
                    });

                $scope.checkItem = function (id) {
                    var index = $scope.signUpGroup.ccaTeamClubs.indexOf(id);
                    if (index > -1) {
                        $scope.signUpGroup.ccaTeamClubs.splice(index, 1);
                    } else {
                        $scope.signUpGroup.ccaTeamClubs.push(id);
                    }
                };

                $scope.update = function (ccaSignUpGroup) {

                    $scope.saving = true;
                    // rebuild the ccateamteam/club/classsubject collections
                    signUpRepository.rebuildCcaLinkedEntities(ccaSignUpGroup, $scope.requirementGroups);

                    // When copying a group the status id of the new group should be zero.
                    if ($state.params.copy && $state.params.copy === true) {
                        ccaSignUpGroup.statusId = 0;
                    }

                    signUpRepository
                        .postGroup(ccaSignUpGroup)
                        .then(function () {
                            $scope.saving = false;
                            $state.go("ccaDetailsAA.groups", { searchData: $state.params.searchData });
                        })
                        .catch(err => {
                            const { data: { message } = {} } = { ...err };
                            $scope.saving = false;
                            simpleAlertService.errorAlert({ message });
                        });
                };

                $scope.back = function () {
                    $state.go('ccaDetailsAA.groups', { searchData: $state.params.searchData });
                };

                $scope.openAvailabilityPicker = function (requirementGroupId) {
                    // get the list of stuff we've already picked
                    var excludedTeamIds = [];
                    var excludedClubIds = [];
                    var excludedSubjectClassIds = [];
                    var j = 0;

                    for (var i = -1; i < 2; i++) {
                        if (i === requirementGroupId)
                            continue;

                        for (j = $scope.requirementGroups[i].ccaTeamTeams.length; j--;) {
                            excludedTeamIds.push($scope.requirementGroups[i].ccaTeamTeams[j]);
                        }

                        for (j = $scope.requirementGroups[i].ccaTeamClubs.length; j--;) {
                            excludedClubIds.push($scope.requirementGroups[i].ccaTeamClubs[j]);
                        }

                        for (j = $scope.requirementGroups[i].ccaTeamSubjectClasses.length; j--;) {
                            excludedSubjectClassIds.push($scope.requirementGroups[i].ccaTeamSubjectClasses[j]);
                        }
                    }

                    var popup = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: '/Scripts/app/shared/controllers/team-club-picker-popup.template.html',
                        controller: 'teamClubPickerPopupController',
                        size: 'lg',
                        resolve: {
                            teamIds: function () {
                                return $scope.requirementGroups[requirementGroupId].ccaTeamTeams;
                            },
                            clubIds: function () {
                                return $scope.requirementGroups[requirementGroupId].ccaTeamClubs;
                            },
                            subjectClassIds: function () {
                                return $scope.requirementGroups[requirementGroupId].ccaTeamSubjectClasses;
                            },
                            showSubjects: function () {
                                return $scope.subjectsEnabled;
                            },
                            excludedTeamIds: function () {
                                return excludedTeamIds;
                            },
                            excludedClubIds: function () {
                                return excludedClubIds;
                            },
                            excludedSubjectClassIds: function () {
                                return excludedSubjectClassIds;
                            }
                        }
                    });
                };

                $scope.teacherProfileClosed = function (personId) {
                    var staffIds = [personId];
                    staffRepository.getStaffInformationForStaffIds(staffIds)
                        .then(function (data) {
                            if (data.length == 1) {
                                // update the in-memory fetched data
                                for (var i = 0; i < $scope.staffInformations.length; i++) {
                                    if ($scope.staffInformations[i].personId == personId) {
                                        $scope.staffInformations[i].onlineMeetingId = data[0].onlineMeetingId;
                                        break;
                                    }
                                }
                                $scope.selectedOrganiserOnlineMeetingId = data[0].onlineMeetingId;
                            }
                        }.bind(this));
                }.bind(this);

                $scope.editOnlineMeetingUrl = function () {
                    if (typeof $scope.signUpGroup.organiserPersonId === 'undefined' || $scope.signUpGroup.organiserPersonId <= 0) {
                        return;
                    }

                    let editOnlineMeetingUrlDialog = $uibModal.open({
                        component: 'profileOnlineLocation',
                        size: 'lg',
                        backdrop: 'static', // prevent modal from closing on backdrop click
                        resolve: {
                            staffMemberId: function () { return $scope.signUpGroup.organiserPersonId; }
                        }
                    });

                    editOnlineMeetingUrlDialog.result
                        .then(function (response) {
                            $scope.teacherProfileClosed($scope.signUpGroup.organiserPersonId);
                        }.bind(this))
                        .catch(function (err) {
                        });
                }

                $scope.openOnlineMeetingId = () => {
                    $window.open($scope.selectedOrganiserOnlineMeetingId, '_blank');
                }
            }
        ]
});
