import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@sb-shared/services/local-storage.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../auth.config';
import { Observable, from, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  appAccessToken: string;
  tokenRefresh$: Observable<string>;

  constructor(
    private oauth: OAuthService,
    private router: Router,
    private storage: LocalStorageService
  ) { }

  init(): Promise<unknown> {
    //this.oauth.events.subscribe(e => console.log(e));

    this.appAccessToken = this.storage.get('appAccessToken', false);

    if (this.appAccessToken) {
      // Injected Access Token clients - bypass standard OAuth.
      window.EveryBuddy.CurrentOrganisationId = this.storage.get('currentOrganisationId');

      return new Promise(resolve => {
        resolve(true);
      });
    }

    this.oauth.configure(authCodeFlowConfig);
    this.oauth.setupAutomaticSilentRefresh();
    return this.oauth.loadDiscoveryDocumentAndLogin().then((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        console.warn("User not logged in");

        return new Promise(resolve => {
          resolve(true);
        });
      }

      return this.oauth.loadUserProfile().then((data: { info: { sb_sod, sb_soid, sb_oids, force_logout } }) => {
        if (data.info.force_logout) {
          console.warn("Forcing logout", data);
          this.logout();
        } else if (!!data.info.sb_sod && location.origin !== data.info.sb_sod) {
          // if the current origin doesn't match the organisation domain, process redirection
          location.href = data.info.sb_sod;
        } else {
          this.storage.set('currentOrganisationId', data.info.sb_soid);
          this.storage.set('canSwitchOrganisation', data.info.sb_oids.split(',').length > 1 || data.info.sb_oids === '*')
          window.EveryBuddy.CurrentOrganisationId = data.info.sb_soid;

          // see if an initially requested path is included in the OAuth additional state
          const redirect_uri = decodeURIComponent(this.oauth.state);
          if (redirect_uri && redirect_uri !== '/') {
            // redirect to initially requested page
            this.router.navigateByUrl(redirect_uri);
          }
        }
      })
    }).catch(err => {
      console.error('Error on log-in, forcing logout', err);
      this.logout();
    });
  }

  getAccessToken() {
    return this.appAccessToken ?? this.oauth.getAccessToken();
  }

  isLoggedIn() {
    return this.appAccessToken || this.oauth.hasValidAccessToken();
  }

  /**
   * Get access token, including a refresh beforehand if expired. May have expired if for some reason the
   * automatic silent refresh has failed, e.g. browser tab or computer went to sleep.
   * @returns Observable on access token. Null if no valid token in first place.
   */
  getTokenAndRefreshIfExpired(): Observable<string> {
    // Mobile app injects its own access token.
    if (this.appAccessToken) {
      return of(this.appAccessToken);
    }

    if (!this.oauth.getAccessToken()) {
      console.warn("auth.service.getTokenAndRefreshIfExpired(): no token found, so cannot refresh");

      return of(null);
    }

    if (this.tokenRefresh$) {
      // Already a pending refresh
      return this.tokenRefresh$;
    }

    // The oauth library function 'hasValidAccessToken' includes an expiry check, but applies a 10 minute skew.
    // This means tokens will be indicated as valid up to 10 minutes after the expiry time.
    // We'll do our own check instead, without skew.
    const timeUntilTokenExpiryMs = this.oauth.getAccessTokenExpiration() - new Date().getTime();
    if (timeUntilTokenExpiryMs > 0) {
      // Token still valid
      return of(this.oauth.getAccessToken());
    }

    // Token has expired
    console.warn(`Auth Token expired ${0 - timeUntilTokenExpiryMs}ms ago, will attempt refresh`)
    this.tokenRefresh$ = from(this.oauth.refreshToken()).pipe(
      map(tokenResponse => tokenResponse.access_token),
      tap(() => this.tokenRefresh$ = null)); // clear observable ready in case it expires again.
    return this.tokenRefresh$;
  }

  refreshToken(): Observable<string> {
    // Mobile app injects its own access token.
    if (this.appAccessToken) {
      return of(this.appAccessToken);
    }

    if (!this.oauth.getAccessToken()) {
      return of(null);
    }

    if (this.tokenRefresh$) {
      // Already a pending refresh
      return this.tokenRefresh$;
    }
    this.tokenRefresh$ = from(this.oauth.refreshToken()).pipe(
      map(tokenResponse => tokenResponse.access_token),
      tap(() => this.tokenRefresh$ = null)); // clear observable ready in case it expires again.
    return this.tokenRefresh$;
  }

  isAppAccessToken() {
    return !!this.appAccessToken;
  }

  logout() {
    if (this.isAppAccessToken()) {
      // Injected Access Token clients - clear local storage value and refresh to redirect to log-in page.
      this.storage.remove('appAccessToken');
      window.location.reload();
      return;
    }

    // Everyone else, standard OAuth logout.
    const orgDomain = `https://${window.location.host}`;
    this.oauth.postLogoutRedirectUri = `${window.EveryBuddy.WebAPI}/Account/BackChannelLogout?orgDomain=${encodeURIComponent(orgDomain)}`;
    this.oauth.revokeTokenAndLogout();
  }
}
