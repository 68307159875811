angular.module('ccasignupModule',
  [
    'ui.calendar',
    'ui.bootstrap',
    'ui.bootstrap.pagination',
    'sticky',
    'ui.tinymce',
    'angular.filter',
    'ngMessages',

    'angularjs-dropdown-multiselect',

    'calendarEventCreationRule.directives.calendarEventCreationRuleEditor',
    'calendarEventCreationRule.directives.calendarEventCreationRuleList',
    'calendarEventCreationRule.services.calendarEventCreationRuleService',

    'cca.components.ccaAddAdditionalEventsDialog',
    'cca.components.ccaCancelOrDeleteEventsDialog',
    'cca.components.ccaCancelOrDeleteEventsConfirmDialog',
    'cca.components.ccaCancelOrDeleteEventsGroupList',
    'cca.components.ccaChangeDayTimeDialog',
    'cca.components.ccaGenerateAdditionalEventsGroupList',
    'cca.components.ccaGeneratingAdditionalEventsDialog',
    'cca.components.ccaGroupArchive',
    'cca.components.ccaGroupInstantBookSelection',
    'cca.components.ccaGroupList',
    'cca.components.ccaDeletePupilConfirmationContainer',
    'cca.components.dailyPreferenceSelector',
    'cca.constants',
    'cca.services.ccaService',

    'ccaAvailable.services.availableRepository',
    'ccaSignUp.services.ccaPupilPreselectionService',
    'ccaSignUp.services.manualBookingsService',
    'ccaSignUp.services.organisationRepository',
    'ccaSignUp.services.organisationMenuService',
    'ccaSignUp.services.signUpRepository',
    'ccaSignUpType.services.ccaSignUpTypeService',

    'transport.services.transportTimeSlotsService',

    'diary.components.diaryEventList',

    'events.components.eventDetailEditor',
    'events.components.bookingCountdown',
    'events.directives.timeSlottedEventPicker',

    'group.controllers.multiPersonPickerPopupController',

    'payments.constants',
    'payments.components.parentBalancePayment',
    'payments.services.paymentsService',

    'person.services',
    'person.services.personPickerService',
    'person.services.staffRepository',

    'profile.components.diary',
    'profile.controllers.profilePopupController',

    'reports.components.reportContainer',

    'shared.components.sbAutoComplete',
    'shared.components.sbColumnHeader',
    'shared.components.sbDateTimePicker',
    'shared.components.sbIcon',
    'shared.components.sbTimePicker',
    'shared.components.sbUpsell',
    'shared.components.sbDateRangePicker',
    'shared.components.sbAvatar',
    'shared.components.sbExportButton',
    'shared.components.sbModalHeader',
    'shared.components.sbSummary',
    'shared.components.sbTitleBar',
    'shared.controllers.pleaseWaitPopupController',
    'shared.controllers.teamClubPickerPopupController',
    'shared.directives.sbCheckBox',
    'shared.directives.sbRadioButton',
    'shared.components.sbSubmitButton',

    'shared.directives.teamClubPicker',
    'shared.directives.updateLanguage',
    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlPlaceholder',
    'shared.directives.ngBindHtmlTitleAttr',
    'shared.directives.sbLoading',
    'shared.components.sbSearchFilter',
    'shared.components.sbSearchFilterItem',
    'shared.directives.sbDetail',

    'shared.filters.decoded',
    'shared.filters.asUtc',

    'shared.services.authInterceptor',
    'shared.services.cachedLookupService',
    'shared.services.guidService',
    'shared.services.iconService',
    'shared.services.imagesUploadHandler',
    'shared.services.simpleAlertService',
    'shared.services.tinymceConfigHelper',
    'shared.services.searchService',
    'shared.services.blobStoragePhotosService',
    'shared.services.arrayService',
    'shared.services.dateTextService',

    'shared.constants',

    'subjectClass.services.subjectClassService',

    'teams.services.teamService',
    'team.components.ccaTeamBulkCreatorByRegGroup',
    'team.components.ccaTeamBulkCreatorBySubject',
    'team.components.ccaTeamBulkCreatorFilter',
    'team.directives.teamJoinRequests',

    'tmh.dynamicLocale',

    'transport.services.pupilTransportPreferenceService',
    'transport.services.transportTimetableCalendarsService',
    'transport.services.transportTimeSlotsService',

    'userForm.components.bulkView',
    'ng1StateDefinitionModule'
  ]);

angular.module('ccasignupModule').constant('EventCategorySystemUsageType', {
  None: 0,
  CCA: 1,
  Membership: 2,
  Fee: 4,
  ParentsEvening: 8,
  Task: 16,
  Transport: 32
});

angular.module('ccasignupModule').config([
  'ng1StateDefinitionProvider',
  function (ng1StateDefinitionProvider) {
    const ng1State = ng1StateDefinitionProvider.$get();
    const moduleName = 'CcaSignup';
    ng1State.state(moduleName, 'ccaSignUp',
      {
        url: '/:moduleSignUpTypeId',
      })
    ng1State.state(moduleName, 'ccaCreate',
      {
        url: '/CcaCreate'
      })
    ng1State.state(moduleName, 'ccaEdit',
      {
        url: '/CcaEdit/:signUpId',
      })
    ng1State.state(moduleName, 'ccaDetails',
      {
        url: '/Details/:signUpId'
      })
    ng1State.state(moduleName, 'ccaDetails.groups',
      {
        url: '/groups'
      })
    ng1State.state(moduleName, 'ccaDetails.duplicategroups',
      {
        url: '/duplicategroups'
      })
    ng1State.state(moduleName, 'ccaDetails.allocation',
      {
        url: '/allocation'
      })
    ng1State.state(moduleName, 'ccaDetails.pupilSelection',
      {
        url: '/pupilselection',
      })
    ng1State.state(moduleName, 'ccaDetails.archive',
      {
        url: '/archive'
      })
    ng1State.state(moduleName, 'ccaDetails.reports',
      {
        url: '/reports'
      })
    ng1State.state(moduleName, 'ccaDetails.generateEvents',
      {
        url: '/generateevents'
      })
    ng1State.state(moduleName, 'ccaDetails.generateFees',
      {
        url: '/generateFees'
      })
    ng1State.state(moduleName, 'ccaDetails.locations',
      {
        url: '/locations'
      })
    ng1State.state(moduleName, 'ccaDetails.joinrequests',
      {
        url: '/joinrequests'
      })
    ng1State.state(moduleName, 'ccaDetails.pupilSummary',
      {
        url: '/pupilsummary'
      })
    ng1State.state(moduleName, 'ccaDetails.blockDates',
      {
        url: '/blockdates'
      })
    ng1State.state(moduleName, 'ccaDetails.ccaGroupManage',
      {
        url: '/CcaGroupManage',
      })
    ng1State.state(moduleName, 'ccaDetails.ccaLocationManage',
      {
        url: '/locations/manage/:locationId'
      })
    // Added these two as they were needed but missing - new feature
    // Have removed the superfluous signUpId as this is in parent route
    ng1State.state(moduleName, 'ccaDetails.generateAdditionalEvents',
      {
        // url: '/generateAdditionalEvents/:signUpId'
        url: '/generateAdditionalEvents'
      })
    ng1State.state(moduleName, 'ccaDetails.ccaCancelOrDeleteEvents',
      {
        // url: '/cancelOrDeleteEvents/:signUpId'
        url: '/cancelOrDeleteEvents'
      })
    ng1State.state(moduleName, 'ccaGroupDetails',
      {
        url: '/Details/:signUpId/CcaGroupDetails/:signUpGroupId',// Amended url for Angular 12
      })
    ng1State.state(moduleName, 'ccaGroupDetails.allocation',
      {
        url: '/allocation', // Added url for Angular 12
      })
    ng1State.state(moduleName, 'ccaDetails.customData',
      {
        url: '/CcaCustomData',
      })
    ng1State.state(moduleName, 'ccaDetails.ccaGroupCopy',
      {
        url: '/CcaGroupCopy',
      })
    ng1State.state(moduleName, 'ccaDetails.ccaGroupEdit',
      {
        url: '/CcaGroupEdit'
      })


    /*------------------------------ AA ------------------------------*/

    ng1State.state(moduleName, 'ccaEditAA',
      {
        url: '/CcaEdit/:signUpId'
      })

    ng1State.state(moduleName, 'ccaDetailsAA',
      {
        url: '/DetailsAA/:signUpId'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.manageEvents',
      {
        url: '/CreateEvents'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.manageEventsDetail',
      {
        url: '/CreateEvents/Details/:selectedItemId'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.manageEventsCopy',
      {
        url: '/CreateEvents/Copy/:copyItemId'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.manageEventsAdd',
      {
        url: '/CreateEvents/Create'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.bulkCreateGroups',
      {
        url: '/BulkCreateGroups'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.groups',
      {
        url: '/Groups'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.generateEvents',
      {
        url: '/PublishEvents'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.blockDates',
      {
        url: '/BlockEvents'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.ccaGroupManage',
      {
        url: '/CcaGroupManageAA'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.ccaGroupCopy',
      {
        url: '/CcaGroupCopy'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.ccaGroupEdit',
      {
        url: '/CcaGroupEdit'

      })
    ng1State.state(moduleName, 'ccaDetailsAA.existingEvents',
      {
        url: '/ExistingEvents'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.existingEvents.attendees',
      {
        url: '/attendees'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.existingEvents.details',
      {
        url: '/details',
      })

    ng1State.state(moduleName, 'ccaDetailsAA.existingEventsTimeSlotted',
      {
        url: '/ExistingEventsTS'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.existingEventsTimeSlotted.attendees',
      {
        url: '/attendees'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.existingEventsTimeSlotted.details',
      {
        url: '/details',
      })
    ng1State.state(moduleName, 'ccaDetailsAA.addPupil',
      {
        url: '/AddPupil',
        templateUrl: '/assets/angularJs/ccasignup/DetailsAA/cca-add-pupil-a-a.template.html',
        controller: 'ccaAddPupilAAController'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.staffSchedule',
      {
        url: '/StaffSchedule',
        component: 'ccaStaffScheduleAA'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.ccaAddPupilConfirmation',
      {
        url: '/AddPupilConfirmation',
        templateUrl: '/assets/angularJs/ccasignup/DetailsAA/cca-addpupil-confirmation.template.html',
        controller: 'ccaAddPupilConfirmationController',
        params: { pupilId: null }
      })
    ng1State.state(moduleName, 'ccaDetailsAA.locations',
      {
        url: '/locations',
        templateUrl: '/assets/angularJs/ccasignup/details/cca-locations.template.html',
        controller: 'ccaLocationsController'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.archive',
      {
        url: '/archive',
        component: 'ccaGroupArchive',
        resolve: {
          signUpId: ['$stateParams', function ($stateParams) { return $stateParams.signUpId; }]
        }
      })
    ng1State.state(moduleName, 'ccaDetailsAA.ccaLocationManage',
      {
        url: '/locations/manage/:locationId',
        templateUrl: '/assets/angularJs/ccasignup/details/cca-location-manage.template.html',
        controller: 'ccaLocationManageController',
        params: { locationId: 0 }
      })
    ng1State.state(moduleName, 'ccaDetailsAA.customData',
      {
        url: '/CcaCustomData',
        templateUrl: '/assets/angularJs/ccasignup/Shared/cca-custom-data.template.html',
        controller: 'ccaCustomDataController'
      })
    ng1State.state(moduleName, 'ccaDetailsAA.addPupilBookingComplete',
      {
        url: '/AddPupilBookingComplete',
      })
    ng1State.state(moduleName, 'ccaSignUp.signUpModule',
      {
        templateUrl: '/assets/angularJs/ccasignup/SignUpModule/cca-signup-module.template.html',
        controller: 'ccaModuleController',
        params: {
          moduleSignUpTypeId: null
        }
      })
    ng1State.state(moduleName, 'ccaSignUp.signUpModuleForInfoOnly',
      {
        templateUrl: '/assets/angularJs/ccasignup/SignUpModule/cca-signup-module-for-info-only.template.html',
        controller: 'ccaSignupModuleForInfoOnlyController',
        params: {
          moduleSignUpTypeId: null
        }
      })
    ng1State.state(moduleName, 'ccaSignUp.signUpNotFound',
      {
        templateUrl: '/assets/angularJs/ccasignup/SignUpModule/cca-signup-module-not-found.template.html',
        controller: 'ccaModuleNotFoundController'
      });
  }
])

  .component('ng1CcaSignUpCreate', {
    template: '<cca-create></cca-create>',
  })
  .component('ng1CcaSignUpEdit', {
    template: '<cca-sign-up-edit></cca-sign-up-edit>',
  })
  .component('ng1CcaSignUpDetails', {
    template: '<cca-details on-sign-up-loaded="$ctrl.onSignUpLoaded(signUp)"></cca-details>',
    bindings: {
      onSignUpLoaded: '&'
    }
  })
  .component('ng1CcaSignUpDetailsGroups', {
    template: '<cca-groups></cca-groups>',
  })
  .component('ng1CcaSignUpDetailsDuplicateGroups', {
    template: '<cca-duplicate-groups></cca-duplicate-groups>',
  })
  .component('ng1CcaSignUpDetailsAllocation', {
    template: '<cca-allocations></cca-allocations>',
  })
  .component('ng1CcaSignUpDetailsPupilSelection', {
    template: '<cca-pupil-selection></cca-pupil-selection>',
  })
  .component('ng1CcaSignUpDetailsArchive', {
    template: '<cca-group-archive sign-up-id="$ctrl.signUpId"></cca-group-archive>',
    controller: class Ng1CcaSignUpDetailsArchiveCtrl {
      $state: any;
      signUpId: number;
      static $inject = ['$state'];
      constructor($state) {
        this.$state = $state;
      }
      $onInit() {
        this.signUpId = this.$state.params.signUpId;
      }
    }
  })
  .component('ng1CcaSignUpDetailsReports', {
    template: '<cca-reports></cca-reports>',
  })
  .component('ng1CcaSignUpDetailsGenerateEvents', {
    template: '<cca-generate-events></cca-generate-events>',
  })
  .component('ng1CcaSignUpDetailsLocations', {
    template: '<cca-locations></cca-locations>',
  })
  .component('ng1CcaSignUpDetailsJoinRequests', {
    template: '<cca-join-requests></cca-join-requests>',
  })
  .component('ng1CcaSignUpDetailsPupilSummary', {
    template: '<cca-pupil-summary></cca-pupil-summary>',
  })
  .component('ng1CcaSignUpDetailsBlockDates', {
    template: '<cca-block-dates></cca-block-dates>',
  })
  .component('ng1CcaSignUpDetailsGroupManage', {
    template: '<cca-group-manage selected-sign-up="$ctrl.selectedSignUp" selected-group="$ctrl.selectedGroup"></cca-group-manage>',
    bindings: {
      selectedSignUp: '<'
    }
  })
  .component('ng1CcaSignUpDetailsLocationManage', {
    template: '<cca-location-manage></cca-location-manage>',
  })
  .component('ng1CcaSignUpGroupDetails', {
    template: '<cca-group-details></cca-group-details>',
  })
  .component('ng1CcaSignUpGroupDetailsAllocation', {
    template: '<cca-group-allocations></cca-group-allocations>',
  })
  .component('ng1CcaSignUpDetailsCustomData', {
    template: '<cca-custom-data selected-sign-up="$ctrl.selectedSignUp"></cca-custom-data>',
  })
  .component('ng1CcaSignUpDetailsGroupCopy', {
    template: '<cca-group-copy></cca-group-copy>',
  })
  .component('ng1CcaSignUpDetailsGroupEdit', {
    template: '<cca-group-edit></cca-group-edit>',
  })
  .component('ng1CcaSignUpDetailsEdit', {
    template: '<cca-edit></cca-edit>',
  })

  /*------------------------------ AA ------------------------------*/

  .component('ng1CcaSignUpAaDetails', {
    template: '<cca-details-a-a on-sign-up-loaded="$ctrl.onSignUpLoaded(signUp)" on-sign-up-type-loaded="$ctrl.onSignUpTypeLoaded(signUpType)"></cca-details-a-a>',
    bindings: {
      onSignUpLoaded: '&',
      onSignUpTypeLoaded: '&',
    }
  })

  .component('ng1CcaSignUpAaDetailsManageEvents', {
    template: '<cca-sign-up-details-a-a-manage-events selected-sign-up-type="$ctrl.selectedSignUpType"></cca-sign-up-details-a-a-manage-events>',
    bindings: {
      selectedSignUpType: '<'
    }
  })
  .component('ng1CcaSignUpAaDetailsManageEventsDetail', {
    template: '<cca-sign-up-details-a-a-manage-events-detail selected-sign-up="$ctrl.selectedSignUp" selected-sign-up-type="$ctrl.selectedSignUpType"></cca-sign-up-details-a-a-manage-events-detail>',
    bindings: {
      selectedSignUp: '<',
      selectedSignUpType: '<'
    }
  })
  .component('ng1CcaSignUpAaBulkCreateGroups', {
    template: '<cca-sign-up-a-a-bulk-create-groups></cca-sign-up-a-a-bulk-create-groups>',
  })
  .component('ng1CcaSignUpAaDetailsBulkCreateGroups', {
    template: '<cca-sign-up-details-a-a-bulk-create-groups></cca-sign-up-details-a-a-bulk-create-groups>',
  })
  .component('ng1CcaSignUpAaDetailsGroups', {
    template: '<cca-groups-a-a></cca-groups-a-a>',
  })
  .component('ng1CcaSignUpAaDetailsGenerateEvents', {
    template: '<cca-generate-events-a-a></cca-generate-events-a-a>',
  })
  .component('ng1CcaSignUpAaDetailsGroupManage', {
    template: '<cca-group-manage-a-a selected-sign-up"$ctrl.selectedSignUp" selected-group"$ctrl.selectedGroup"></cca-group-manage-a-a>',
    bindings: {
      selectedSignUp: '<'
    }
  })
  .component('ng1CcaSignUpAaDetailsExistingEvents', {
    template: '<cca-existing-events-a-a on-select-event="$ctrl.onSelectEvent(event)" on-attendees-updated="$ctrl.onAttendeesUpdated(attendees)"></cca-existing-events-a-a>',
    bindings: {
      onSelectEvent: '&',
      onAttendeesUpdated: '&'
    }
  })
  .component('ng1CcaSignUpAaDetailsExistingEventsAttendees', {
    template: '<cca-existing-events-a-a-attendees selected-event="$ctrl.selectedEvent" attendees="$ctrl.attendees"></cca-existing-events-a-a-attendees>',
    bindings: {
      selectedEvent: '<',
      attendees: '<'
    }
  })
  .component('ng1CcaSignUpAaDetailsExistingEventsDetails', {
    template: '<cca-existing-events-a-a-details selected-event="$ctrl.selectedEvent"></cca-existing-events-a-a-details>',
    bindings: {
      selectedEvent: '<'
    }
  })
  .component('ng1CcaSignUpAaDetailsExistingEventsTimeSlotted', {
    template: '<cca-existing-events-a-a-time-slotted on-select-event="$ctrl.onSelectEvent(event)" on-attendees-updated="$ctrl.onAttendeesUpdated(attendees)"></cca-existing-events-a-a-time-slotted>',
    bindings: {
      onSelectEvent: '&',
      onAttendeesUpdated: '&'
    }
  })
  .component('ng1CcaSignUpAaDetailsAddPupilTimeSlotted', {
    template: '<cca-add-pupil-a-a-time-slotted></cca-add-pupil-a-a-time-slotted>',
  })
  .component('ng1CcaSignUpAaDetailsAddPupil', {
    template: '<cca-add-pupil-a-a></cca-add-pupil-a-a>',
  })
  .component('ng1CcaSignUpAaDetailsStaffSchedule', {
    template: '<cca-staff-schedule-a-a></cca-staff-schedule-a-a>',
  })
  .component('ng1CcaSignUpAaDetailsAddPupilConfirmation', {
    template: '<cca-add-pupil-confirmation organisation="$ctrl.organisation"></cca-add-pupil-confirmation>',
    bindings: {
      organisation: '<'
    }
  })
  .component('ng1CcaSignUpAaDetailsLocations', {
    template: '<cca-locations is-a-a="true"></cca-locations>',
  })
  .component('ng1CcaSignUpAaDetailsArchive', {
    template: '<cca-group-archive></cca-group-archive>',
  })
  .component('ng1CcaSignUpAaDetailsLocationManage', {
    template: '<cca-location-manage></cca-location-manage>',
  })
  .component('ng1CcaSignUpAaDetailsCustomData', {
    template: '<cca-sign-up-details-a-a-custom-data></cca-sign-up-details-a-a-custom-data>',
  })
  .component('ng1CcaSignUpAaDetailsAddPupilBookingComplete', {
    template: '<cca-add-pupil-booking-complete></cca-add-pupil-booking-complete>',
  })
  .component('ng1CcaSignUpModule', {
    template: '<cca-module></cca-module>',
    bindings: {
      onSignUpLoaded: '&'
    }
  })
  .component('ng1CcaSignUpAaDetailsInfoOnly', {
    template: '<cca-sign-up-details-a-a-info-only></cca-sign-up-details-a-a-info-only>',
  })
  .component('ng1CcaSignUpModuleInfoOnly', {
    template: '<cca-sign-up-module-info-only></cca-sign-up-module-info-only>',
  })
  .component('ng1CcaSignUpNotFound', {
    template: '<cca-sign-up-not-found></cca-sign-up-not-found>',
  })
  .component('ng1CcaSignUpDetailsGenerateFees', {
    template: '<cca-generate-fees></cca-generate-fees>',
  })
  .component('ng1CcaSignUpDetailsGenerateAdditional', {
    template: '<cca-generate-additional-events></cca-generate-additional-events>',
  })
  .component('ng1CcaSignUpDetailsCancelDelete', {
    template: '<cca-cancel-or-delete-events></cca-cancel-or-delete-events>',
  })
