'use strict';

angular.module('ccasignupModule').component('ccaDetails', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-details.template.html',
    bindings: {
        onSignUpLoaded: '&'
    },
    controller: [
    '$scope',
    '$location',
    '$state',
    'organisationRepository',
    'signUpRepository',
    'iconService',
    function ($scope, $location, $state, organisationRepository, signUpRepository, iconService) {

        this.$onInit = () => { // Not indented to avoid merge issues

        $scope.selectedTab = 0;
        $scope.signUp = {};


        $scope.hasFinance = false;

        signUpRepository.getSignUp($state.params.signUpId)
            .then((signUp) => {
                $scope.signUp = signUp;
                this.onSignUpLoaded({signUp: $scope.signUp});

                $state.go('.groups', { searchData: $state.params.searchData });

                organisationRepository.getCcaType($scope.signUp.ccaSignUpTypeId).then(function (ccaSignUpType) {
                    $scope.ccaSignUpType = ccaSignUpType.data;
                });
            });

        $scope.update = function (ccaSignUp) {
            signUpRepository.post(ccaSignUp).then(function () {
                $location.path('/');
            });
        };

        $scope.editDetails = function () {
            $state.go('ccaEdit', {
                signUpId: $state.params.signUpId,
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        }.bind(this);

        $scope.allContainers = function() {
            $state.go('ccaSignUp', {
                moduleSignUpTypeId: $scope.signUp.ccaSignUpTypeId
            });
        }

        $scope.ccaStatusText = function (statusId) {
            switch (statusId) {
                case 1:
                    return 'SB_Managing';
                case 2:
                    return 'SB_Generating';
                case 3:
                    return 'SB_CcaStatus_Active';
                case 4:
                    return 'SB_Archived';
                case 5:
                    return 'SB_Processing';
                default:
                    return 'SB_Managing';
            }
        };
    }


    }]});
