import { Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { NotTranslatedService } from "@sb-shared/services/not-translated.service";

@Injectable()
export class SbMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    this.nts.notTranslated(params.key);
    return params.key;
  }

  constructor(private nts: NotTranslatedService) {}
}
