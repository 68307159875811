// start:ng42.barrel
import './balance';
import './balanceAdmin';
import './balance/components';
import './balance/filters';
import './calendarEventCreationRule';
import './cca';
import './ccaAvailable';
import './ccaAvailable/Components';
import './ccaAvailable/Dashboard';
import './ccaAvailable/Details';
import './ccaAvailable/Transport';
import './ccaAvailable/Transport/timeSlot';
import './ccaSignUp';
import './ccaSignUp/Create';
import './ccaSignUp/Dashboard';
import './ccaSignUp/Details';
import './ccaSignUp/DetailsAA';
import './ccaSignUp/Services';
import './ccaSignUp/Shared';
import './ccaSignUp/SignUpModule';
import './clubs';
import './colours';
import './colours/components';
import './contacts';
import './corporateContacts';
import './corporateContacts/components';
import './corporateContacts/controllers';
import './dashboard';
import './dashboard/components';
import './diary';
import './diary/components';
import './diary/directives';
import './diary/transport';
import './documentStore';
import './emailTemplates';
import './events';
import './events/components';
import './events/directives';
import './financialProducts';
import './group';
import './group/components';
import './group/directives';
import './insurance';
import './languagecode';
import './location';
import './mortgage';
import './messaging';
import './messaging/components';
import './messaging/controllers';
import './messaging/directives';
import './organisation';
import './organisation/components';
import './payments';
import './person';
import './person/controllers';
import './person/directives';
import './profile';
import './profile/components';
import './profile/controllers';
import './profile/directives';
import './reception';
import './reception/components';
import './reception/controllers';
import './reception/controllers/groups';
import './reception/controllers/status365';
import './reports';
import './roles';
import './roles/components';
import './settings';
import './settings/components';
import './settings/components/dashboard';
import './settings/components/faria-one';
import './settings/components/links-portal-admin';
import './settings/components/onboarding';
import './settings/components/staff-roles';
import './settings/services';
import './shared';
import './shared/components';
import './shared/controllers';
import './shared/directives';
import './shared/filters';
import './shared/services';
import './shared/services/transport';
import './subjectClass';
import './sysadmin';
import './tasks';
import './teams';
import './teams/components';
import './teams/directives';
import './transport';
import './tuitionFees';
// import './transport/changeRequests';
// import './transport/configuration';
// import './transport/configuration/archiving';
// import './transport/configuration/changeRequests';
// import './transport/configuration/methods';
// import './transport/configuration/routes';
// import './transport/configuration/routeStopTimes';
// import './transport/configuration/stops';
// import './transport/configuration/timeSlots';
// import './transport/configuration/timetable';
// import './transport/configuration/timetableCalendar';
// import './transport/dashboard';
// import './transport/pupilPreferences';
// import './transport/pupilPreferences/components';
// import './transport/reports';
// import './transport/reports/components';
// import './transport/services';
import './user-form';
import './user-form/components';
// end:ng42.barrel

