import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Placement } from '@ng-bootstrap/ng-bootstrap';
import { Button } from '@sb-shared/models/UI/buttons';
import { IconSize } from '@sb-shared/types/icon-size';
import { IconStyle } from '@sb-shared/types/icon-style';
import { ButtonClasses } from '@sb-shared/types/ui-classes';
import { Sizes } from './../../types/sizes';

@Component({
  selector: 'sb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnChanges {

  // Default text
  @Input() message?: string;
  // Text to show on xs device only
  @Input() messageXs?: string;
  // Secondary text to show
  @Input() note?: string;
  // Params for translations
  @Input() messageTranslationParameters?: any;
  // Should we translate the message passed in
  @Input() isMessageTranslated = true;
  // Class from set list of button classes
  @Input() buttonClass?: ButtonClasses;
  // Button size
  @Input() size?: Sizes;
  // Any extra classes
  @Input() customClasses?: string = '';
  // Id for button element
  @Input() buttonId?: string;
  // Type attribute for button element
  @Input() buttonType?: string;
  // Disabled condition
  @Input() isDisabled?: boolean;
  // Can select button
  @Input() isSelectable?: boolean;
  // Selected by default condition
  @Input() isSelected?: boolean;
  // Name of icon to show on left
  @Input() iconName?: string;
  // Class of i element to show on left if no icon name
  @Input() iClass?: string;
  // Classes to add to icon
  @Input() iconClasses?: string;
  // Override style of icon
  @Input() iconStyle?: IconStyle;
  // Size of icon
  @Input() iconSize?: IconSize;
  // Should button show only icon and no text for xs devices
  @Input() xsIconOnly?: boolean;
  // Loading condition
  @Input() isLoading?: boolean;
  // Add .btn-auto class to remove min width
  @Input() isAutoSize?: boolean;
  // Text for tooltip
  @Input() tooltipText?: string;
  // Placement of tooltip
  @Input() tooltipPlacement?: Placement = 'bottom';
  // List of dropdown items. Button onClick props not implicity invoked, use onClick Output.
  @Input() dropdownItems: Button[];
  // Classes for dropdown menu
  @Input() dropdownClasses: string;
  // Placement for dropdown
  @Input() dropdownPlacement: Placement;
  // Route to go to when clicked, onClick is ignored if this is set
  @Input() route?: string;
  // Img to show instead of icon
  @Input() imgUrl?: string;
  // data value to return in the onClick
  @Input() dataItem: string;
  // callback function to call to determine if the button is visible.
  @Input() isVisibleFunction?: (param?:any) => void;

  // Click event handler. If dropdown menu button, provides index of dropdown item.
  @Output() onClick = new EventEmitter<number>();
  //Hover event handler
  @Output() onHover?= new EventEmitter<null>();
  fontSizeStyle: string;

  computedClass: string[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnChanges() {
    // Comment moved from sbSubmitButton
    // Note: before I switched to use single computed class attribute, I concatenated
    // the various classes in the view, this interfered with isShowing, where angular
    // adds/removes ng-hide, the conflict meant in some scenarios the button would not
    // hide even when isShowing is false.

    const classArray = ['btn'];

    if (this.buttonClass) {
      classArray.push('btn-' + this.buttonClass);
    }
    if (this.xsIconOnly) {
      classArray.push('btn-xs-icon');
    }
    if (this.size) {
      classArray.push('btn-' + this.size);
    }
    if (this.isAutoSize) {
      classArray.push('btn-auto');
    }
    if (this.isSelectable) {
      classArray.push('btn-selectable');
      if (this.isSelected) {
        classArray.push('btn-selectable--selected');
      }
      if (this.note) {
        classArray.push('btn-selectable--note');
      }
      const length = this.message?.length;
      const fontSize = 25 / length;
      this.fontSizeStyle = `${fontSize > 1.6 ? 1.6 : fontSize}rem`;
    }
    else {
      this.fontSizeStyle = '';
    }
    if (this.customClasses) {
      classArray.push(this.customClasses);
    }
    this.computedClass = classArray;
  }

  onButtonClick() {
    if (this.route) {
      this.router.navigate([this.route], { relativeTo: this.activatedRoute });

      return;
    }

    this.onClick.emit();
  }

  onDropdownItemClick(dropdownItemIndex: number) {
    const dropdownItem = this.dropdownItems[dropdownItemIndex];
    if (!dropdownItem) {
      return;
    }

    if (dropdownItem.onClick) {
      dropdownItem.onClick({ dataItem: this.dataItem, dropdownItemIndex: dropdownItemIndex });
      return;
    }
    this.onClick.emit(dropdownItemIndex);
  }

  onButtonHover() {
    this.onHover.emit();
  }

}
