import { Component, Input, OnInit } from '@angular/core';
import { ActionMenuItems } from '../../models/UI/menu-item';
import { Router } from '@angular/router';

@Component({
  selector: 'sb-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {

  @Input() menuItems: ActionMenuItems;
  @Input() isFeatured: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClickItem(item): void {
    if (item.linkUrl) {
        window.open(item.linkUrl);
    }
    if (item.route) {
        this.router.navigate([item.route], { queryParams: item.params });
    }
}

}
