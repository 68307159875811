import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { MemberTypeId } from '@sb-shared/enums/member.type.enum';

@Directive({ selector: 'ng1-profile-welcome' })
export class ProfileWelcomeUpgrade extends UpgradeComponent {
  @Input() memberType: MemberTypeId;
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1ProfileWelcome', elementRef, injector);
  }
}
