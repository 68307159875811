export class AccessRequestEmailsRequest {
  pupilsToWelcome?: number[] = [];
  pupilsToResetPassword?: number[] = [];
  pupilsToReInvite?: number[] = [];
  parentsToWelcome?: number[] = [];
  parentsToResetPassword?: number[] = [];
  parentsToReInvite?: number[] = [];
  staffToWelcome?: number[] = [];
  staffToResetPassword?: number[] = [];
  staffToReInvite?: number[] = [];
}
