<div class="card-style link-card"
     [ngClass]="{
          'link-card--wide' : isWide || (!link?.iconImageUrl && !link?.icon),
          'link-card--row' : isRow
     }">
  <div class="link-card__main"
       (click)="onMainClick()">
    <!-- <img *ngIf="link?.iconImageUrl" [src]="link?.iconImageUrl"/> -->
    <sb-icon-img *ngIf="link?.iconImageUrl"
                 [imgUrl]="link?.iconImageUrl"
                 class="link-card__icon"></sb-icon-img>
    <i *ngIf="link?.icon && !link?.iconImageUrl"
       [ngClass]="link?.icon"
       class="link-card__icon"></i>
    <sb-date *ngIf="date"
             [date]="date"></sb-date>
    <div>
      <div class="align-center">
        <div class="bold"
             [innerHTML]="link?.isLabelUntranslated ? link?.label : (link?.label | translate)"></div>
        <sb-badge *ngIf="link?.badgeCount"
                  [message]="link?.badgeCount.toString()"
                  [isMessageTranslated]="false"
                  [isRounded]="true"
                  badgeClass="danger"
                  class="ml-3"></sb-badge>
      </div>
      <div *ngIf="link?.description"
           class="link-card__description"
           [innerHTML]="link?.description | translate:link?.descriptionTranslateParams"></div>
      <div *ngIf="link?.warning"
           class="link-card__description link-card__description--warning"
           [innerHTML]="link?.warning | translate"></div>
    </div>
  </div>
  <div *ngIf="link?.actions?.length > 0"
       class="link-card__actions">
    <sb-button *ngIf="link?.actions?.length === 1"
               buttonClass="link"
               iconClasses="p-15 text-muted"
               [iconName]="link.actionsIcon || 'arrow-right'"
               [iconSize]="link.actionsIconSize"
               [tooltipText]="link?.actions[0].message || 'SB_View'"
               tooltipPlacement="bottom"
               (onClick)="onActionClick(0)"></sb-button>
    <sb-button *ngIf="link?.actions?.length > 1"
               buttonClass="link"
               iconClasses="p-15 text-muted"
               iconName="ellipsis"
               [dropdownItems]="link?.actions"
               (onClick)="onActionClick($event)"></sb-button>
  </div>
  <div class="link-card__external"
       *ngIf="link?.externalUrl">
    <sb-icon name="followLink"></sb-icon>
  </div>
</div>
