
angular.module('shared.services.imagesUploadHandler', [])
    .service('imagesUploadHandler', ['$window', 'ng1AuthService', 'simpleAlertService',
        function ($window, ng1AuthService, simpleAlertService) {
            var service = this;
            this.$window = $window;

            service.uploadImage = function (blobInfo, success, failure) {
                var xhr = new XMLHttpRequest();
                xhr.withCredentials = false;
                xhr.open('POST', `${this.$window.EveryBuddy.CoreAPI}/api/Admin/${this.$window.EveryBuddy.CurrentOrganisationId}/OrganisationStorage/UploadImageFromTinyMce`);

                xhr.onload = function () {
                    var json;

                    if (xhr.status != 200) {
                        var response = JSON.parse(xhr.response);

                        if (response.errors.length == 0) {
                            failure('HTTP Error: ' + xhr.status);
                            return;
                        }

                        // This fires the click event on the Add/Edit Image modal dialog's OK button to close the modal.
                        // We need to do this so that the simple alert dialog will be shown correctly, rather than
                        // being behind the Add/Edit Image modal dialog.
                        $('.mce-widget.mce-btn.mce-primary.mce-abs-layout-item.mce-first.mce-btn-has-text').click();

                        if (typeof response.messageTranslationParameters !== 'undefined' &&
                            response.messageTranslationParameters !== '') {
                            var params = response.messageTranslationParameters;

                            if (typeof params.maximumImageSizeMB !== 'undefined' &&
                                params.maximumImageSizeMB != null) {
                                simpleAlertService.errorAlert(
                                    {
                                        message: response.errors[0],
                                        messageTranslationParameters: { maximumImageSizeMB: params.maximumImageSizeMB },
                                        staticBackdrop: true
                                    });

                                return;
                            }

                            simpleAlertService.errorAlert({
                                message: response.errors[0],
                                messageTranslationParameters:
                                {
                                    configurationSetting: params.configurationSetting,
                                    organisationId: params.organisationId
                                },
                                staticBackdrop: true
                            });

                            return;
                        }

                        simpleAlertService.errorAlert(
                            {
                                message: response.errors[0]
                            });

                        return;
                    }

                    json = JSON.parse(xhr.responseText);

                    if (!json || typeof json.data.location != 'string') {
                        failure('Invalid JSON: ' + xhr.responseText);
                        return;
                    }

                    success(json.data.location);
                };

                xhr.setRequestHeader('Authorization', 'Bearer ' + ng1AuthService.getAccessToken());

                var formData = new FormData();
                formData.append('file', blobInfo.blob(), blobInfo.filename());
                xhr.send(formData);

            }.bind(this);

            return this;
        }]);