<ng-template #modal let-modal>
    <div class="modal-body p-0">
        <div class="http-error-message">
            <h1>{{getErrorTitle() | translate}}</h1>
            <h2>({{error.status}} {{'SB_Error' | translate}})</h2>
            <img src="https://www.schoolsbuddy.com/images/home/event-management.png" alt="More features">
            <p>{{getErrorMessage() | translate}}</p>
            <button class="btn btn-primary btn-lg" (click)="closeModal(modal)">{{'SB_Close' | translate}}</button>
        </div>
    </div>
</ng-template>
