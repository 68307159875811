import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-current-user-transaction-view' })
export class CurrentUserTransactionViewUpgrade extends UpgradeComponent {
  @Input() accountTypeId: number;

  constructor(elementRef: ElementRef, injector: Injector) {
    const rootScope = injector.get('$rootScope');
    const scope = rootScope.$new();

    const scopeInjector = Injector.create({
        providers: [{provide: '$scope', useValue: scope}],
        parent: injector
      }
    );

    super('ng1CurrentUserTransactionView', elementRef, scopeInjector);
  }
}
