import { Injectable } from '@angular/core';
import { Toast } from '@sb-shared/models/UI/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: Toast[] = [];

  successTimeout = 3000;
  errorTimeout = 8000;

  // Position is set in module where toast is used

  getError(customText?: string, title?: string) {
    this.show({
      toastClass: 'danger',
      content: customText || 'SB_Error_Loading_Data',
      showDismissButton: true,
      timeout: this.errorTimeout,
      toastTitle: title
    });
  }

  saveSuccess(customText?: string, title?: string, routerLink?: string) {
    this.show({
      toastClass: 'success',
      content: customText || 'SB_Saved',
      showDismissButton: false,
      timeout: this.successTimeout,
      toastTitle: title,
      routerLink: routerLink
    });
  }

  getSuccess(customText?: string, title?: string, routerLink?: string) {
    this.show({
      toastClass: 'success',
      // content: this.$filter('translate')(customText || 'SB_Saved', params),
      content: customText || 'SB_Success',
      showDismissButton: false,
      timeout: this.successTimeout,
      toastTitle: title,
      routerLink: routerLink
    });
  }

  deleteSuccess(customText?: string, title?: string, routerLink?: string) {
    this.show({
      toastClass: 'success',
      content: customText || 'SB_Deleted',
      showDismissButton: false,
      timeout: this.successTimeout,
      additionalClasses: 'alert-delete',
      toastTitle: title,
      routerLink: routerLink
    });
  }

  saveError(customText?: string, title?: string) {
    this.show({
      toastClass: 'danger',
      content: customText || 'SB_Error_Saving',
      showDismissButton: true,
      timeout: this.errorTimeout,
      toastTitle: title
    });
  }

  error(customText?: string, title?: string) {
    this.show({
      toastClass: 'danger',
      content: customText || 'SB_Error_Generic',
      showDismissButton: true,
      timeout: this.errorTimeout,
      toastTitle: title
    });
  }

  warning(customText: string, title?: string) {
    this.show({
      toastClass: 'warning',
      content: customText || 'SB_Error_Generic',
      showDismissButton: true,
      timeout: this.errorTimeout,
      toastTitle: title
    });
  }

  emailSent(customText?: string) {
    this.show({
      toastClass: 'info',
      content: customText || 'SB_Email_Sent',
      showDismissButton: false,
      timeout: this.successTimeout,
      additionalClasses: 'alert-sent'
    });
  }

  show(toast: Toast) {
    this.toasts.push(toast);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  chat(customText) {
      this.show({
        toastClass: 'info',
        content: customText,
        showDismissButton: false,
        timeout: 3500,
        additionalClasses: 'alert-chat'
      });
  }

}