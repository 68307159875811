"use strict";

angular.module('ccaavailableModule').component('ccaAvailableSelectionParentsEvening',  {
    templateUrl: "/Scripts/app/ccaAvailable/Details/cca-available-selection-parents-evening.template.html",
    controller: [
        '$scope',
        '$state',
        '$filter',
        '$uibModal',
        'availableRepository',
        'simpleAlertService',
        'ccaService',
        function ($scope, $state, $filter, $uibModal, availableRepository, simpleAlertService, ccaService) {

            $scope.signUp = {};

            $scope.yourEvents = [];
            $scope.pupils = [];
            $scope.unconfirmedBookingCount = 0;

            $scope.saving = false;

            var toLoad = 2;

            $scope.isLoading = function()
            {
                return toLoad > 0;
            };

            availableRepository
                .getSignUp($state.params.signUpId)
                .then(function (signUp) {
                    $scope.signUp = signUp;

                    toLoad--;
                });

            $scope.addBookingHandler = function (personId, eventId, containerEvents)
            {
                $scope.selectedQuantityChanging = true;

                return availableRepository.postToggleBookingLite(personId, eventId, containerEvents)
                    .then(responseDto => {

                        $scope.handleToggleBookingLiteResponse(responseDto);

                        // Pass back to child container.
                        return responseDto;
                });
            };

            $scope.removeBookingHandler = function (personId, eventId) {
                return availableRepository.postToggleBookingLite(personId, eventId, [])
                    .then(responseDto => {

                        $scope.handleToggleBookingLiteResponse(responseDto);

                        // Pass back to child container.
                        return responseDto;
                });
            };

            $scope.handleToggleBookingLiteResponse = function(responseDto) {
                // Find the event in scope and update its status and expiry to reflect latest time remaining in countdown logic.
                if ($scope.eventsData && $scope.eventsData.availableEvents) {
                    var calendarEventDto = $scope.eventsData.availableEvents.find(x => x.calendarEventId == responseDto.calendarEventId);
                    if (calendarEventDto && calendarEventDto.pupils) {
                        var pupilDto = calendarEventDto.pupils.find(x => x.personId == responseDto.personId);
                        if (pupilDto) {
                            pupilDto.status = responseDto.bookingStatusId;
                            pupilDto.expiryTimestampUtc = responseDto.expiryTimestampUtc;
                        }
                    }
                }

                $scope.resetTimeRemaining();
            }

            $scope.cancelBookingHandler = function(personId, eventId)
            {
                return availableRepository.cancelAAConfirmedBooking(personId, eventId)
                    .then(function(data)
                    {
                        if (data.isError)
                        {
                            return false;
                        }
                        else
                        {
                            return true;
                        }
                    });
            };

            $scope.getEvents = function(isReload) {
                availableRepository
                    .getSignUpEvents($state.params.signUpId, $state.params.pupilId)
                    .then(function (eventsData)
                    {
                        $scope.eventsData = eventsData;

                        $scope.minutesUntilBookingExpiry = eventsData.minutesUntilBookingExpiry;

                        // Don't affect loading UI if doing reload called by timeSlottedEventPicker child, as that has it's own loading UI
                        if (!isReload)
                        {
                            toLoad--;
                        }

                        $scope.resetTimeRemaining();
                    });
            };

            $scope.getEvents(false);

            $scope.confirmBookings = function ()
            {
                $scope.saving = true;
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "/Scripts/app/ccaAvailable/Details/cca-available-terms-aa.template.html",
                    controller: "ModalTermsInstanceCtrl",
                    size: $scope.signUp.organisationFormId === 0 ? "sm" : "lg",
                    resolve: {
                        terms: function() {
                            return $scope.signUp.terms;
                        },
                        termsLink: function() {
                            return $scope.signUp.termsLink;
                        },
                        organisationFormId: function() {
                            return $scope.signUp.organisationFormId;
                        },
                        ccaSignUpId: function() {
                            return $scope.signUp.ccaSignUpId;
                        },
                        forPeopleList: function() {
                            var pupilDetails = [];
                            for (var i = $scope.yourEvents.length; i--;)
                            {
                                pupilDetails.push($scope.yourEvents[i].personId);
                            }
                            return pupilDetails;
                        }
                    }
                });
                modalInstance
                    .result
                    .then(function (capturedData)
                    {
                        var dto = [];
                        // we want to send back a cut down version of the main selection dto
                        for (var i = $scope.yourEvents.length; i--;)
                        {
                            var selectedDto = null;
                            for (var j = dto.length; j--;)
                            {
                                if (dto[j].calendarEventId === $scope.yourEvents[i].eventId)
                                {
                                    selectedDto = dto[j];
                                    break;
                                }
                            }
                            if (selectedDto === null)
                            {
                                selectedDto = {
                                    calendarEventId: $scope.yourEvents[i].eventId
                                };
                                dto.push(selectedDto);
                            }
                            if (!selectedDto.pupils)
                            {
                                selectedDto.pupils = [];
                            }
                            selectedDto.pupils.push({
                                personId: $scope.yourEvents[i].personId,
                                selectionCount: $scope.yourEvents[i].selectionCount
                            });
                        }

                        availableRepository
                            .saveBookings(dto, $scope.pupils, $state.params.signUpId, capturedData, $scope.signUp.organisationFormId || 0)
                            .then(function(response)
                            {
                                if (response && response.hasOwnProperty('isSuccess') && !response.isSuccess)
                                {
                                    simpleAlertService.errorAlert({
                                        message: response.errorResponse ?? 'SB_Error_Saving', messageTranslationParameters:
                                        {
                                            eventTitle: response.eventTitle ?? '',
                                            eventOrganiserName: response.eventOrganiserName ?? '',
                                            startDateTime: $filter('date')(response.startDateTime, 'HH:mm')
                                        }
                                    })

                                    $scope.saving = false;
                                    return;
                                }

                                $state.go("ccaConfirmation", { signUpId: $state.params.signUpId });
                            });
                    })
                    .catch(function()
                    {
                        $scope.saving = false;
                    });
            };

            $scope.resetTimeRemaining = () =>
            {
                if (typeof $scope.minutesUntilBookingExpiry === 'undefined')
                {
                    $scope.minutesUntilBookingExpiry = $scope.eventsData.minutesUntilBookingExpiry;
                }

                let bookingExpiryInfo = ccaService.getBookingExpiryInfo($scope.eventsData.availableEvents);

                // Oldest Booking Expiry is taken from any calendar event bookings that are not timed-out, removed, cancelled or deleted.
                $scope.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;

                if ($scope.oldestBookingExpiry) {
                    $scope.timeRemainingMins = moment.utc(bookingExpiryInfo.timeRemaining).get('minutes')
                    $scope.timeRemainingSecs = moment.utc(bookingExpiryInfo.timeRemaining).get('seconds');
                } else {
                    let minutesToShowForCountdown = $scope.minutesUntilBookingExpiry > 5
                        ? $scope.minutesUntilBookingExpiry - 5
                        : $scope.minutesUntilBookingExpiry;

                    $scope.timeRemainingMins = minutesToShowForCountdown;
                    $scope.timeRemainingSecs = 0;
                }
            };

            $scope.onTimeoutBooking = () => {
                $scope.timeOutBooking();
                $scope.deselectAllStudents();
            }

            $scope.onUpdateTimeRemaining = (timeRemaining) => {
                $scope.timeRemaining = timeRemaining;
            }

            $scope.timeOutBooking = () =>
            {
                $scope.hasBookingSelectionTimedOut = true;
                $scope.timeRemainingMins = 0;
                $scope.timeRemainingSecs = 0;

                let timeOutBookingsRequest =
                {
                    signUpId: $scope.signUp.ccaSignUpId,
                    timeOutBookingRequests: []
                }

                $scope.eventsData.availableEvents.forEach((signUpEvent) =>
                {
                    let selectedStudentIds = [];

                    signUpEvent.pupils.forEach((student) =>
                    {
                        if (student.status == ccaService.bookingStatusEnum.Reserved)
                        {
                            selectedStudentIds.push(student.personId);
                        }
                    });

                    if (selectedStudentIds.length > 0)
                    {
                        let timeOutBookingRequest =
                        {
                            calendarEventId: signUpEvent.calendarEventId,
                            selectedStudentIds: selectedStudentIds
                        }

                        timeOutBookingsRequest.timeOutBookingRequests.push(timeOutBookingRequest);
                    }
                });

                ccaService.timeOutSelectedStudentBookings(timeOutBookingsRequest);
            }

            // This does not reflect in the time-slotted-event-picker unfortunately, as that no longer works with the same set of data.
            // But it will disable any selections as it is bound to hasBookingSelectionTimedOut.
            $scope.deselectAllStudents = () =>
            {
                let selectedStudents = $scope.eventsData.availableEvents
                    .flatMap(x => x.pupils.filter(x => x.status == ccaService.bookingStatusEnum.Reserved));
                selectedStudents.forEach(y => {
                    y.expiryTimestampUtc = null;
                    y.status = ccaService.bookingStatusEnum.TimedOut;
                    y.selectionCount = 0;
                });
            }
        }
    ]});
