

angular.module('messaging.components.messageList', [
  'angularMoment',
  'pascalprecht.translate',
  'shared.components.sbIcon',
  'shared.services.dateTimeService',
])
  .component('messageList',
    {
      bindings: {
        chatMessages: '<',
        showLoadMore: '<',
        loadingMore: '<',
        errorLoadingMessageList: '<',
        disableMessageFlags: '<',
        onMessageSelected: '&',
        onLoadMoreClicked: '&'
      },
      templateUrl: '/Scripts/app/messaging/components/message-list.template.html',
      controller: class MessageListCtrl {

        static $inject = ['dateTimeService'];
        // Bindings
        chatMessages: any[];
        onMessageSelected: any;
        onLoadMoreClicked: any;
        selectedMessageId: number = 0;
        orgTimeZoneOffset: string = '';

        constructor(private dateTimeService) {  }

        $onInit() {
          this.orgTimeZoneOffset = this.dateTimeService.getOrgTimeZoneOffset();
        }

        $onChanges(changes) {
          if (changes.chatMessages?.currentValue) {
            this.formatDates();
          }
        };

        selectMessage(message) {
          this.onMessageSelected()(message);
          this.selectedMessageId = message.id;
        };

        loadMore() {
          this.onLoadMoreClicked()();
        };

        formatDates() {
          const now = moment(new Date());
          this.chatMessages.forEach(message => {
            // figure out which type of date to render
            const messageDate = moment(message.sa);
            const duration = moment.duration(now.diff(messageDate));
            const humanDateMaxDays = 7;
            message.showHumanDate = duration.asDays() < humanDateMaxDays;
            message.sameYear = (now).isSame(messageDate, 'year');
            /* We format the date inside the template and use angular moment
               for 'x days ago' as doing it here using momentJS caused bugs with
               .format() */
          });
        }
      }
    });
