angular.module('balanceModule')
    .service('balanceRepository', class BalanceRepo {

        $http: any;
        $q: any;
        urlBase: string;
        urlApiBase: string;

        static $inject = ['$http', '$q'];

        constructor ($http, $q) {
            this.$http = $http;
            this.$q = $q;
            this.urlBase = "/Balance/";
            this.urlApiBase = "/api/WebBalance/";
        }

        get(eventsFrom, eventsTo) {
            const deferred = this.$q.defer();
            this.$http.get(this.urlBase + "GetTransactions", { params: { eventsFrom: eventsFrom, eventsTo: eventsTo } })
                .then(function (response) { deferred.resolve(response.data); })
                .catch(function (e) { deferred.reject(e); });
            return deferred.promise;
        };

        post (payments) {
            var deferred = this.$q.defer();
            this.$http.post(this.urlBase + "ValidPayments", { currentPersonId: 0, dtos: payments })
                .then(function (response) { deferred.resolve(response.data); })
                .catch(function (e) { deferred.reject(e); });
            return deferred.promise;
        };

    });
