'use strict';

angular.module('sysadmin.components.importsManageBac', [
    'sysadmin.services.importsService',
    'shared.components.sbOnOffSwitch',
]).component('importsManageBac', {
    templateUrl: '/Scripts/app/sysadmin/components/imports-manageBac.template.html',
    bindings: {
        organisation: '<',
        manageBacDataModel: '<',
        originalManageBacDataModel: '<',
        onChangesNotified: '&',
        beforeSave: '=',
    },
    controller: [
        'importsService',
        '$scope',
        function importsManageBacController(importsService, $scope) {
            var self = this;
            this.anyChanges = false;
            this.apiKeyObfuscated = true;

            this.$onChanges = function (changes) {
                if (typeof self.organisation !== 'undefined' && self.organisation != null) {
                    $scope.model = this.manageBacDataModel;
                }

                if (changes.manageBacDataModel) {
                    this.reset();
                }
            };

            this.changesMade = function () {
                $scope.model = this.manageBacDataModel;

                var enableSave = this.isDataValid() && this.hasDataChangedFromOriginal();

                if (this.onChangesNotified && typeof (this.onChangesNotified()) === 'function') {
                    self.onChangesNotified()(enableSave);
                }
                this.anyChanges = true;
            }.bind(this);

            this.hasDataChangedFromOriginal = function () {
                if (typeof this.manageBacDataModel === 'undefined' ||
                    typeof this.originalManageBacDataModel === 'undefined') {
                    return false;
                }

                var currentData = this.manageBacDataModel;
                var originalData = this.originalManageBacDataModel;

                if (currentData.enabled != originalData.enabled ||
                    currentData.url != originalData.url ||
                    currentData.apiKey != originalData.apiKey ||
                    currentData.amKey != originalData.amKey ||
                    currentData.pmKey != originalData.pmKey ||
                    currentData.importEmailAddress != originalData.importEmailAddress ||
                    currentData.importStudentImages != originalData.importStudentImages ||
                    currentData.defaultGenderType != originalData.defaultGenderType) {
                    return true;
                }

                return false;
            }.bind(this);

            this.isDataValid = function () {
                if (typeof this.manageBacDataModel === 'undefined' ||
                    typeof this.manageBacDataModel.url === 'undefined' ||
                    this.manageBacDataModel.url == '' ||
                    typeof this.manageBacDataModel.apiKey === 'undefined' ||
                    this.manageBacDataModel.apiKey == '' ||
                    typeof this.manageBacDataModel.amKey === 'undefined' ||
                    this.manageBacDataModel.amKey == '' ||
                    typeof this.manageBacDataModel.pmKey === 'undefined' ||
                    this.manageBacDataModel.pmKey == '') {
                    return false;
                }

                return true;
            }.bind(this);

            this.apiKeyFocused = () => {
                if (this.apiKeyObfuscated) {
                    this.manageBacDataModel.apiKey = '';
                    this.changesMade();
                    this.apiKeyObfuscated = false;
                }
            };

            this.reset = () => {
                this.beforeSave = () => {
                    if (this.apiKeyObfuscated) {
                        this.manageBacDataModel.apiKey = null;
                    }
                };

                this.apiKeyObfuscated = true;
            };
        }
    ]
});