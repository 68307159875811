<div class="balance-widget"
     [ngClass]="{'balance-widget--dashboard' : isDashboard}">
    <div *ngFor="let item of userBalanceWidgets"
         class="balance-widget__item"
         [ngClass]="'balance-widget__item--' + item.id"
         (click)="item.balanceClickHandler()">
        <div>
            <h3 [innerHTML]="item.title | translate"></h3>
            <h2 [ngClass]="item.valueClass">
                <span [innerHTML]="currencyDisplaySymbol"></span>
                <span [innerHTML]="item.value | number : '1.2-2'"></span>
                <sb-icon name="warning"
                            [ngbTooltip]="'SB_Balance_Update_Warning' | translate"
                            tooltipPlacement="right"
                            *ngIf="item.value === null"></sb-icon>
            </h2>
        </div>
        <sb-icon [name]="item.iconName" [ngClass]="item.valueClass" overrideStyle="duotone" class="icon"></sb-icon>
    </div>
</div>
