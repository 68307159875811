import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { MenuItem } from '@sb-shared/models/UI/menu-item';
import { MenuService } from '@sb-shared/services/menu.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileDataService } from '../../../profile/services/profile-data.service';

@Component({
  selector: 'sb-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent implements OnInit {

  modalTitle: string;
  menuItems$: Observable<MenuItem[]>;
  changes: MutationObserver;
  showPopup: boolean;
  domChange: EventEmitter<any> = new EventEmitter();
  outletName = 'profilePopupRouter';

  @ViewChild('profilePopupContent') content: ElementRef;
  closeButton: any;

  constructor(
    private menu: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private profileData: ProfileDataService,
    private modal: NgbModal,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.profileData.selectedPerson
      .pipe(map(person => person?.personData))
      .subscribe(personData => {
        if (personData) {
          this.modalTitle = `${this.translate.instant('SB_Profile')}: ${personData.firstName} ${personData.lastName}`;
        }
      })

    const element = document.querySelector('body');
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
    });
    this.changes.observe(element, {
      childList: true
    });

    // Set up routes
    const profilePopupRoutes = [
      'diary',
      'profile',
      'linked',
      'groups',
      'medical',
      'userForms'
    ];

    // Get profile module routes filtered by above list, but remove 'Profile' from url
    const pageName = subMenuItem => subMenuItem.route?.replace('Profile/', CommonChars.Blank)

    this.menuItems$ = this.menu.getSubMenuItems().pipe(map(subMenuItems =>
      subMenuItems
        .filter(subMenuItem => profilePopupRoutes.includes(pageName(subMenuItem)))
        .map(filteredItem => {
          return {
            ...filteredItem,
            route: pageName(filteredItem)
          }
        }
        )
    ));

    // Intercept angularJS profile popup opening, displaying the Angular popup modal over the top.
    this.domChange.pipe(
      map(event => {
        if (event.type != 'childList') {
          return null;
        }

        const nodeArray = [...event.addedNodes];
        if (!Array.isArray(nodeArray)) {
          return null;
        }

        return event.addedNodes[0]?.querySelector('header[person-id]');
      }), filter(modalHeader => modalHeader))
      .subscribe(modalHeader => {
        this.closeButton = modalHeader.querySelector('.ti-close');
        const personId = modalHeader.getAttribute('person-id');
        const initialRoute = modalHeader.getAttribute('initial-route') || 'profile';
        this.profileData.setSelectedPersonById(personId);

        // If more than one angularJS modal is shown (i.e. the profile popup request is coming from another modal)
        // need to close one of the other modals to avoid z-indexing issues.
        const angularJsModalCount = document.querySelectorAll('div.modal').length;
        if (angularJsModalCount > 1) {
          this.closeButton.click();
        }

        this.open();

        setTimeout(() => {
          this.router.navigate([{ outlets: { profile: [initialRoute] } }]);
        }, 500);
      });
  }

  open() {
    this.modal.open(this.content, { ariaLabelledBy: 'profile-popup', size: 'xl' }).result.finally(() => {
      this.closePopup();
    });
  }

  closePopup() {
    this.modal.dismissAll(this.content);
    this.closeButton.click();
    this.router.navigate(
      [
        {
          outlets: {
            profile: null
          }
        }
      ],
      {
        relativeTo: this.route.parent
      }
    );
  }

}
