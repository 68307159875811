angular.module('cca.components.ccaGroupList',
    [
        'pascalprecht.translate',
        'ui.bootstrap',
        'cca.constants',
        'shared.components.sbIcon',
        'shared.services.simpleAlertService'
    ])
    .constant('groupButtonTypesEnum', {
        DELETE_GROUP: 1,
        HOLD_GROUP: 2,
        UNHOLD_GROUP_TO_AVAILABLE: 3,
        UNHOLD_GROUP_TO_ACTIVE: 9,
        ADD_EVENTS: 4,
        COPY_GROUP: 5,
        ALLOCATE_GROUP: 6,
        VIEW_ALLOCATIONS: 7,
        MANAGE_PUPILS: 8
    })
    .component('ccaGroupList',
        {
            templateUrl: '/Scripts/app/cca/components/cca-group-list.template.html',
            bindings: {
                signUp: '<',
                groups: '<',
                pageSize: '<',
                initialPage: '<',
                sortType: '<',
                sortReverse: '<',
                showPagination: '<',
                loading: '<',
                enableAddEvents: '<',
                onGroupDetailsClick: '&',
                onManageGroupClick: '&',
                onEditGroupClick: '&',
                onDuplicateGroupClick: '&',
                onDeleteGroupClick: '&',
                onChangeStatusClick: '&',
                onAddEventToGroupClick: '&',
                onPageChanged: '&'
            },
            controller: [
                'groupButtonTypesEnum',
                'teamStatusEnum',
                'ccaSignUpStatusEnum',
                'dateTextService',
                function (groupButtonTypesEnum, teamStatusEnum, ccaSignUpStatusEnum, dateTextService) {

                    this.groupButtonTypesEnum = groupButtonTypesEnum;

                    this.currentPage = null;

                    this.$onChanges = function(changes) {
                        if (changes.initialPage) {
                            this.currentPage = this.initialPage;
                        }
                    }.bind(this);

                    this.maxNumberOfPages = window.EveryBuddy.PaginationMaxNumberOfPages;

                    this.shouldShowGroupButton = function (signUpGroup, buttonId) {

                        switch (buttonId) {

                            case this.groupButtonTypesEnum.VIEW_ALLOCATIONS:
                            case this.groupButtonTypesEnum.MANAGE_PUPILS:

                                return signUpGroup.statusId === teamStatusEnum.Active;

                            case this.groupButtonTypesEnum.ALLOCATE_GROUP:
                            case this.groupButtonTypesEnum.COPY_GROUP:

                                return signUpGroup.statusId !== teamStatusEnum.Active;


                            case this.groupButtonTypesEnum.DELETE_GROUP:
                                switch (this.signUp.status) {
                                    case ccaSignUpStatusEnum.Active:
                                        return signUpGroup.eventCount < 1 && signUpGroup.pupilTeamCount < 1 && signUpGroup.pupilChoiceCount < 1;
                                    default:
                                        return signUpGroup.pupilChoiceCount < 1 && signUpGroup.pupilSelectedCount < 1;
                                }

                            case this.groupButtonTypesEnum.HOLD_GROUP:
                                if (this.signUp.status !== ccaSignUpStatusEnum.Managing) return false;

                                switch (signUpGroup.statusId) {
                                    case teamStatusEnum.OnHold:
                                    case teamStatusEnum.Archive:
                                    case teamStatusEnum.Active:
                                        return false;
                                    default:
                                        return true;
                                }

                            case this.groupButtonTypesEnum.UNHOLD_GROUP_TO_AVAILABLE:
                                if (this.signUp.status !== ccaSignUpStatusEnum.Managing) return false;

                                switch (signUpGroup.statusId) {
                                    case 3:
                                        return true;
                                    default:
                                        return false;
                                }

                            case this.groupButtonTypesEnum.UNHOLD_GROUP_TO_ACTIVE:
                                if (this.signUp.status !== ccaSignUpStatusEnum.Active) return false;

                                switch (signUpGroup.statusId) {
                                    case 3:
                                        return true;
                                    default:
                                        return false;
                                }

                            case this.groupButtonTypesEnum.ADD_EVENTS:
                                if (this.signUp.status !== ccaSignUpStatusEnum.Active)
                                {
                                    return false;
                                }

                                if (signUpGroup.statusId !== teamStatusEnum.Active)
                                {
                                    return false;
                                }

                                return this.enableAddEvents;

                            default:
                                return false;
                        }
                    }.bind(this);

                    this.dateText = function (dayNumber)
                    {
                        return dateTextService.getShortDayText(dayNumber);
                    }

                    this.statusText = function (statusId) {
                        switch (statusId) {
                            case 0:
                                return 'SB_Available_for_Signup';
                            case 1:
                                return 'SB_Partial';
                            case 2:
                                return 'SB_Complete';
                            case 3:
                                return 'SB_CcaStatus_On_Hold';
                            case 4:
                                return 'SB_CcaStatus_Auto_Complete';
                            case 5:
                                return 'SB_CcaStatus_Archived';
                            case 6:
                                return 'SB_CcaStatus_Active';
                            case 7:
                                return 'SB_CcaStatus_Extending';
                            default:
                                return "";

                        }
                    };
                }
            ]
        });