<div class="diary-item"
     *ngIf="event"
     [ngClass]="{
         'diary-item--cancelled' : event.isCancelled,
         'diary-item--selected' : isSelected,
         'diary-item--invite' : event.pendingInvite
    }"
     [ngbPopover]="onClick.observers.length > 0 ? null : popContent"
     placement="bottom"
     popoverClass="diary-popover"
     (click)="onClickItem()">
    <sb-alert [alertClass]="itemClass"
              customClasses="diary-item__inner"
              [rightIconName]="getIconName()"
              [rightIconOverlayName]="getStateIcon()"
              rightIconOverlayCustomClass="rounded"
              [rightLabel]="getCountLabel()"
              [rightIconTooltip]="getStateLabel()"
              [isBorderless]="isBorderless"
              [note]="getStateLabel()">
        <div class="diary-item__body">
            <sb-avatar *ngIf="event.personId"
                       [personId]="event.personId"
                       [fullName]="event.attendeeName"
                       [photoUrl]="event.photoUrl"
                       [showTooltip]="true"
                       class="p-1"></sb-avatar>
            <div>
                <div class="text-muted"
                     [innerHTML]="timesLabel"></div>
                <div class="diary-item__title diary-item__truncate"
                     [innerHTML]="event.title"></div>
                <div *ngIf="!event.transportAttendee && event.eventType!=='Transport'" class="text-muted">
                    {{event.ownerName}}</div>
                <div *ngIf="event.transportAttendee && event.personId" class="diary-item__transport">
                    <sb-icon name="transport-bus" overrideStyle="regular"></sb-icon>
                    <span [innerHTML]="event.transportAttendee.stopName" class="mr-3 diary-item__truncate"></span>
                </div>
                <ng-container *ngIf="!isDeclined()">                
                    <ng-container *ngIf="event.consentDeadline && deadlineDifference">
                        <ng-container *ngIf="consentDeadlineExpired(); else consentDeadline">
                            <sb-text iconName="exclamationCircle" message="SB_Expired" customClasses="semi-bold" class="text-danger"></sb-text>
                        </ng-container>
                        <ng-template #consentDeadline>
                            <div [ngClass]="getDeadlineClass()">{{ deadlineDifference.days }} day {{ deadlineDifference.hours }} h {{ deadlineDifference.minutes }} min left</div>
                        </ng-template>
                    </ng-container>
                    <div *ngIf="event.emptySlots !== null && event.emptySlots !== undefined">
                        <div [ngClass]="getEventSizeClass()">{{event.emptySlots}} places left</div>
                        <ng-template #emptySlots></ng-template>
                    </div>
                </ng-container>
                <ng-container *ngIf="isDeclined()">
                    <sb-text iconName="exclamationCircle" message="SB_Declined" customClasses="semi-bold" class="text-danger"></sb-text>
                </ng-container>                
            </div>
            <div class="diary-item__status ml-auto">
                <sb-badge *ngIf="event.badgeClass && event.badgeMessage"
                    class="mr-2"
                    [badgeClass]="event.badgeClass"
                    [message]="event.badgeMessage"
                    [customClasses]="'badge-square'"
                    [allCaps]="true"></sb-badge>
                <sb-text class="mr-2"
                    [textClass]="event.transportAttendee.statusClassName"
                    [iconName]="event.transportAttendee.statusIcon"
                    [message]="('SB_Change' | translate) + ': ' + (event.transportAttendee.statusLabel | translate)"
                    *ngIf="event.transportAttendee?.showViewChangeRequest && !event.transportAttendee?.hideProcessingStatus && !event.transportAttendee?.hasLinkedChangeRequest"></sb-text>
                <sb-text class="mr-2"
                    textClass="info"
                    iconName="history"
                    [tooltipText]="event.transportAttendee.parentReason"
                    tooltipPlacement="bottom"
                    message="SB_Changed"
                    *ngIf="event.transportAttendee?.hasLinkedChangeRequest"></sb-text>
            </div>
        </div>
    </sb-alert>
</div>
<ng-template #popContent>
    <sb-diary-item-detail [event]="event"
                          [stateClass]="getStateClass()"
                          [stateIcon]="getStateIcon()"
                          [stateLabel]="getStateLabel()"></sb-diary-item-detail>
</ng-template>
