'use strict';

angular.module('receptionModule').component('receptionAllGroupsMedical', {
    templateUrl:
    '/Scripts/app/reception/controllers/groups/reception-allgroups-medical.template.html?v=', // + window.EveryBuddy.version
    bindings: {
        selectedGroup: '<'
    },
    controller:
    [
        '$scope',
        function ($scope) {

            this.$onChanges = (changes) => {
                if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                    $scope.selectedGroup = this.selectedGroup;
                }
            };

        }
    ]});