'use strict';

angular
    .module('shared.services.dateTimeService', [])
    .service('dateTimeService', [
        '$locale',
        'dateTextService',
        function ($locale, dateTextService) {
            var service = this;

      var dateTimeFormats = $locale.DATETIME_FORMATS;

      service.monthNames = dateTimeFormats.MONTH;
      service.dayNames = dateTimeFormats.DAY;
      service.shortMonthNames = dateTimeFormats.SHORTMONTH;
      service.shortDayNames = dateTimeFormats.SHORTDAY;
      service.firstDayOfWeek = dateTimeFormats.FIRSTDAYOFWEEK;

      service.getMonthName = function (date) {
        return service.monthNames[moment(date).format('M') - 1];
      }.bind(this);

      service.getShortMonthName = function (date) {
        return service.shortMonthNames[moment(date).format('M') - 1];
      }.bind(this);

      service.getDayName = function (date) {
        return service.dayNames[moment(date).format('e')];
      }.bind(this);

      service.getShortDayName = function (date) {
        return service.shortDayNames[moment(date).format('e')];
      }.bind(this);

            service.getDateLabels = function (date) {
                return {
                    monthName: service.getMonthName(date),
                    shortMonthName: service.getShortMonthName(date),
                    dayName: service.getDayName(date),
                    shortDayName: service.getShortDayName(date),
                };
            };

            service.isEndOfWeek = function (date) {
                // Set end of week
                var dayIndex = moment(date).format('e');
                // Start day is Sunday (0) and this day is Saturday (6)
                return (
                    (service.firstDayOfWeek == 0 &&
                        dayIndex == service.dayNames.length - 1) ||
                    // This day is before start day
                    dayIndex == service.firstDayOfWeek - 1
                );
            };

      service.dateTimeFormat = 'YYYY-MM-DDThh:mm:ss';

            service.getAgeLabel = function (dateTime) {
                var getLabel = function (age) {
                    var seconds = Math.round(age.asSeconds());
                    if (seconds < 60) {
                        return {
                            label: seconds === 1 ? 'Second' : 'Seconds',
                            value: seconds,
                        };
                    }
                    var minutes = Math.round(age.asMinutes());
                    if (minutes < 60) {
                        return {
                            label: minutes === 1 ? 'Minute' : 'Minutes',
                            value: minutes,
                        };
                    }
                    var hours = Math.round(age.asHours());
                    if (hours < 24) {
                        return {
                            label: hours === 1 ? 'Hour' : 'Hours',
                            value: hours,
                        };
                    }
                    var days = Math.round(age.asDays());
                    if (days < 7) {
                        return {
                            label: days === 1 ? 'Day' : 'Days',
                            value: days,
                        };
                    }
                    var weeks = Math.round(age.asWeeks());
                    var months = Math.round(age.asMonths());
                    if (age.asMonths() < 1) {
                        return {
                            label: weeks === 1 ? 'Week' : 'Weeks',
                            value: weeks,
                        };
                    }
                    if (months < 12) {
                        return {
                            label: months === 1 ? 'Month' : 'Months',
                            value: months,
                        };
                    }
                    var years = Math.round(age.asYears());
                    return {
                        label: years === 1 ? 'Year' : 'Years',
                        value: years,
                    };
                };
                var nowUtc = moment().utc();
                var dateTimeUtc = moment.utc(dateTime);
                var diff = nowUtc.diff(dateTimeUtc);
                var age = moment.duration(diff, 'milliseconds');
                var ageLabel = getLabel(age);
                ageLabel.label = 'SB_Age_' + ageLabel.label;
                return ageLabel;
            };

      service.secondsToClock = (value) => {
        return new Date(value * 1000).toISOString().substr(11, 8);
      };

            service.getOrgTimeZoneOffset = () =>
                dateTextService.getOrgTimezoneText();

            return service;
        },
    ]);
