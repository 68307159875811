<div *ngIf="selectedPerson"
     class="pb-5">
    <div class="align-center space-between p-4 pl-5 pr-5">
        <div class="align-center">
            <sb-avatar [firstName]="selectedPerson.personData.firstName"
                       [lastName]="selectedPerson.personData.lastName"
                       [actionType]="AvatarButtonAction.None"
                       class="mr-5"
                       [personId]="selectedPerson.personData.id"
                       size="xl"
                       [isSoloPhoto]="true">
            </sb-avatar>
            <div>
                <h2 class="semi-bold">{{selectedPerson.personData.firstName}} {{selectedPerson.personData.lastName}}
                </h2>
                <h3 class="p-large">
                    <span [innerHTML]="subHeadingLabel() | translate"></span>
                </h3>
            </div>
        </div>
        <div class="align-center">
            <div *ngIf="showUserStatus()"
                 [innerHTML]="selectedPerson.personStatus + '_User' | translate"
                 class="semi-bold text-muted p-15">
            </div>
            <div *ngIf="showLocked()"
                 class="ml-3"
                 style="display:flex; align-items: center;">
                <sb-icon name="info"
                         class="mr-5"
                         [ngbTooltip]="'SB_Unlock_Message' | translate"></sb-icon>
                <sb-button buttonClass="primary"
                           (onClick)="isLocked = !isLocked"
                           [message]="isLocked ? 'SB_Unlock' : 'SB_Lock'"></sb-button>
            </div>
        </div>
    </div>
    <sb-wizard [(tabs)]="editUserTabs"
               [parentData]="selectedPerson"
               [hideImages]="true"
               [dangerButton]="deleteUserButton"
               [secondaryButton]="messageUserButton"
               [isSaving]="isSaving"
               (onSave)="saveUser($event)"
               (onDangerButtonClick)="deleteUserButtonClick()"
               (onSecondaryButtonClick)="sendSingleAccessRequestEmail()"
               [readOnly]="isReadOnly()">
        <div class="orgs" *ngIf="selectedPerson.personData.id===currentUser.id">
            <label>{{labels.organisations|translate}}</label>
            <div *ngFor="let org of currentUserOrganisations"> {{org}} </div>
        </div>
    </sb-wizard>
</div>
