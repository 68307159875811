'use strict';

angular.module('profile.components.diary', [])
    .component('diary', {
        templateUrl: '/Scripts/app/profile/components/diary.template.html',
        bindings: {
            selectedPerson: '<'
        },
        controller: [
            '$scope',
            function ($scope) {
                this.$onChanges = (changes) => {
                    if (changes.selectedPerson && changes.selectedPerson.currentValue) {
                        $scope.person = this.selectedPerson;
                    }
                };
            }
        ]
    });