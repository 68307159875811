'use strict';

angular.module('sysadmin.services.emailDomainsService', [])
    .factory('emailDomainsService', [
        '$http', '$cacheFactory',
        function ($http)
        {
            var urlBase = '/webapi/WebMainSettings/';
            var service = {};

            service.getEmailDomains = function (organisationId)
            {
                return $http.get(urlBase + 'GetEmailDomains', { params: { organisationId: organisationId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.saveEmailDomains = function (emailDomainsRequest)
            {
                return $http.post(urlBase + 'SaveEmailDomains', emailDomainsRequest)
                    .then(function (response) { return response.data; });
            };

            return service;
        }
    ]);