<div class="action-menu"
     [ngClass]="{'action-menu-featured' : isFeatured}">
    <div *ngFor="let item of menuItems"
         (click)="onClickItem(item)">
        <div>
            <div class="fad-bichromatic">
                <sb-icon-img *ngIf="item.icon && !item.icon.fontAwesomeClassName"
                             [type]="item.icon.type"
                             [category]="item.icon.category"
                             [name]="item.icon.name"
                             [imgUrl]="item.icon.imgUrl"></sb-icon-img>
                <div *ngIf="item.icon && item.icon.fontAwesomeClassName">
                    <i [ngClass]="item.icon.fontAwesomeClassName"></i>
                </div>
                <sb-icon name="followLink"
                         overrideStyle="solid"
                         *ngIf="item.linkUrl"></sb-icon>
            </div>
            <div>
                <h3 [innerHtml]="item.label | translate"></h3>
                <div [innerHtml]="item.description | translate"></div>
            </div>
        </div>
        <div *ngIf="!isFeatured">
            <sb-icon name="chevronRight"></sb-icon>
        </div>
    </div>
</div>
