angular.module('shared.components.sbActionMenu', [
    'shared.components.sbIconImg',
])
    .component('sbActionMenu',
        {
            bindings: {
                menuItems: '<',
                isFeatured: '<',
                onClick: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-action-menu.template.html',
            controller: class SbActionMenuCtrl {

                // Dependencies
                $state: any;
                $window: any;

                // Bindings
                menuItems: any;
                onClick: any;

                static $inject = ['$state', '$window'];

                constructor($state, $window) {
                    this.$state = $state;
                    this.$window = $window;
                }

                onClickItem(item): void {
                    if (item.linkUrl) {
                        return this.$window.open(item.linkUrl);
                    }
                    if (item.route) {
                        this.$state.go(item.route, item.routeParams);
                    }
                }

            }
        });
