'use strict';

angular.module('profile.directives.linkedProfileEditor', [
    'person.services',
    'shared.components.sbModalHeader'])
    .directive('linkedProfileEditor', [
    'personService', '$uibModal',
    function (personService, $uibModal) {

        function link($scope) {
            $scope.$watch('person', function (newValue) {

                // we don't load person, just wait for it to be set

                if ($scope.person && $scope.person.personData && $scope.person.personData.id) {

                    $scope.loading--;
                    $scope.checkIsParentStatus();

                }
            });
        }

        function controller($scope, personService, $uibModal) {

            $scope.loading = 2;
            $scope.sortType = 'lastName';
            $scope.sortReverse = false;
            $scope.loggedInUserIsParentOfUser = false;
            $scope.registerYouthLink = `${window.EveryBuddy.WebAPI}/Account/RegisterYouth`;

            personService.getCurrentPersonId()
                .then(function (data) {
                    $scope.loggedInPersonId = data;

                    if (!$scope.isEditingSelf()) {
                        // so we're not editing ourself- are we the parent?
                        $scope.checkIsParentStatus();
                    }

                    $scope.loading--;

                });

            $scope.checkIsParentStatus = function () {

                if (!$scope.loggedInPersonId || !$scope.person || !$scope.person.personData)
                    return;

                $scope.loading++;
                personService.isParentOfUser($scope.loggedInPersonId, $scope.person.personData.id)
                    .then(function (innerData) {
                        $scope.loggedInUserIsParentOfUser = innerData;
                        $scope.loading--;
                    });
            };

            $scope.isEditingSelf = function () {
                if (!$scope.person || !$scope.person.personData)
                    return false;

                return $scope.loggedInPersonId === $scope.person.personData.id;
            };

            $scope.canAddLinks = function() {

                if ($scope.isOrganisationAdmin)
                    return true;

                if ($scope.organisationTypeId === window.EveryBuddy.Enums.OrganisationTypes.School)
                    return false;

                if ($scope.person && $scope.person.isPupil === true) {

                    if ($scope.isEditingSelf())
                        return false;

                    if ($scope.loggedInUserIsParentOfUser)
                        return false; //true;
                }

                return false;
            };

            $scope.showAddChildButton = function () {

                if ($scope.organisationTypeId === window.EveryBuddy.Enums.OrganisationTypes.School)
                    return false;

                if ($scope.person && $scope.person.isPupil === true)
                    return false;

                if ($scope.isEditingSelf())
                    return true;

                return false;
            };

            $scope.addNewLink = function (linked) {

                if (!$scope.canAddLinks()) {
                    console.warn('Unauthorised add link request');
                    return;
                }

                $scope.addLink = { firstName: '', lastName: '', emailAddress: '' };
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'addLinkModal.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    size: 'md',
                    scope: $scope
                });

                modalInstance.result.then(function () {
                    var linkedData = {
                        pupilPersonId: $scope.person.personData.id,
                        organisationId: -1,
                        parentName: $scope.addLink.firstName + ' ' + $scope.addLink.lastName,
                        parentEmailAddress: $scope.addLink.emailAddress
                    };
                    personService.saveLink(linkedData)
                        .then(function (data) {
                            personService.getPerson($scope.person.personData.id, window.EveryBuddy.Enums.PersonDataBlocks.LinkedPeople)
                                .then(function(data) {
                                    $scope.person.linkedPeople = data.linkedPeople;
                                });
                        });
                },
                function () {
                });
            };


            $scope.deleteLink = function (linked) {
                $scope.selectedLinked = linked;
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'confirmDeleteLinkModal.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    size: 'sm',
                    scope: $scope

                });

                modalInstance.result.then(function () {
                    personService.removeLink($scope.selectedLinked.personId, $scope.selectedLinked.linkedPersonId)
                        .then(function () {
                            for (var i = 0; i < $scope.person.linkedPeople.length; i++) {
                                if ($scope.person.linkedPeople[i].personId === $scope.selectedLinked.personId &&
                                    $scope.person.linkedPeople[i].linkedPersonId ===
                                    $scope.selectedLinked.linkedPersonId) {
                                    $scope.person.linkedPeople.splice(i, 1);
                                }
                            }
                        });
                },
                function () {
                });
            };

            $scope.showRelated = function(item) {
                $scope.onViewClicked({ item: item });
            }

            $scope.isLoading = function () {
                return $scope.loading > 0;
            };
        }

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/profile/directives/linked-profile-editor.template.html',
            scope: {
                person: '=',
                isOrganisationAdmin: '=',
                enableViewButton: '=',
                organisationTypeId: '=',
                onViewClicked: '&'
            },
            controller: ['$scope', 'personService', '$uibModal', controller],
            link: link
        };
    }]);
