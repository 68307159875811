import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventDetails } from '@sb-events/models/event-detail';
import { differenceInHours } from 'date-fns';
import { DiaryService } from 'src/app/diary/services/diary.service';
import { ButtonClasses } from '../../types/ui-classes';
import { ComponentBase } from '../component-base/component-base';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'sb-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: ['./event-summary.component.scss']
})
export class EventSummaryComponent extends ComponentBase implements OnInit {
  @Input() eventDetails: EventDetails;

  @Input() backText: string; // Text for back buttons
  @Input() actionText: string;
  @Input() actionIcon: string;
  @Input() actionButtonClass: ButtonClasses;
  @Input() actionDisabled: boolean;
  @Input() actionHidden: boolean;
  @Input() isTransparent: boolean;

  @Output() onClickEdit: EventEmitter<void> = new EventEmitter();
  @Output() onClickBack: EventEmitter<void> = new EventEmitter();
  @Output() onClickAction: EventEmitter<void> = new EventEmitter();

  show: boolean;
  showEdit: boolean;
  showBack: boolean;
  showAction: boolean;
  timesLabel: string;
  sanitizedAdditionalInfo: SafeHtml;

  constructor(
    private route: ActivatedRoute,
    private diaryService: DiaryService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    this.show = !this.route.snapshot.queryParams['hideNavButtons'];
    this.showEdit = this.onClickEdit.observers.length > 0;
    this.showAction = (this.onClickAction.observers.length > 0 || this.showEdit) && !this.actionHidden;
    if (this.showEdit) {
      this.actionText = 'SB_Edit';
      this.onClickAction = this.onClickEdit;
      this.actionIcon = 'edit';
      this.actionButtonClass = 'success';
    }
    this.showBack = this.onClickBack.observers.length > 0;
    this.timesLabel = this.getTimesLabel();
    this.sanitizedAdditionalInfo = this.sanitizer.bypassSecurityTrustHtml(this.eventDetails.additionalInfo);
  }

  getTimesLabel(): string {
    if (!this.eventDetails.from) { return null; }

    // If event spans over multiple days the times label wouldn't look right here. For example 25/6/23 06:00:00 - 26/6/23 08:00:00
    // would display as 06:00 - 08:00
    if (differenceInHours(this.eventDetails.to, this.eventDetails.from) > 24) {

      return null;
    }

    return this.diaryService.getTimesLabelFromDates(this.eventDetails.from, this.eventDetails.to);
  }

  back() {
    this.onClickBack.emit();
  }

  edit() {
    this.onClickEdit.emit();
  }

  action() {
    this.onClickAction.emit();
  }

}
