'use strict';

angular.module('sysadmin.components.mainSettingTypes', [
    'sysadmin.services.mainSettingTypesService',
    'shared.services.toastService'
]).component('mainSettingTypes', {
    templateUrl: '/Scripts/app/sysadmin/components/main-setting-types.template.html',
    bindings: {
        organisation: '<',
        selectedSettingTypeId: '<',
        showCalendarEventTypes: '<',
        showDomainName: '<',
        showDataProvisioning: '<',
        showModules: '<',
        showEmailDomains: '<',
        onSelectSettingType: '&',
    },
    controller: ['mainSettingTypesService', 'toastService',
        function mainSettingTypesController(mainSettingTypesService, toastService)
        {
            var self = this;
            this.mainSettingTypes = null;
            this.showCalendarEventTypes = false;
            this.showDomainName = false;
            this.showDataProvisioning = false;
            this.showModules = false;
            this.showEmailDomains = false;
            this.toastService = toastService;

            this.showSelectedSetting = function (settingType)
            {
                this.selectedSettingTypeId = settingType.id;
                this.showCalendarEventTypes = false;
                this.showDomainName = false;
                this.showDataProvisioning = false;
                this.showModules = false;
                this.showEmailDomains = false;
                this.showColours = false;
                this.showLanguages = false;

                if (this.onSelectSettingType && typeof this.onSelectSettingType === 'function')
                {
                    this.onSelectSettingType({settingType: settingType});
                }
            }.bind(this);

            mainSettingTypesService.getMainSettingTypes().then(function (data) {
                self.mainSettingTypes = data;
            }, function () {
                this.toastService.error();
            }.bind(this));

            this.$onChanges = function (changes) {

            }.bind(this);
        }
    ]
});