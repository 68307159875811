import { Component, Input } from '@angular/core';
import { MenuItems } from '../../models/UI/menu-item';

@Component({
  selector: 'sb-side-menu-nav-pill',
  templateUrl: './side-menu-nav-pill.component.html',
  styleUrls: ['./side-menu-nav-pill.component.scss']
})
export class SideMenuNavPillComponent {

  $state: any;
  $transitions: any;
  @Input() menuItems: MenuItems = [];

  constructor () {}

  // TODO: Check reason from previous menuItems.items

}
