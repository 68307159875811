import { Component, OnInit } from '@angular/core';
import { User } from '@sb-shared/models/user';
import { ProfileDataService } from '../../services/profile-data.service';

@Component({
  selector: 'sb-profile-diary',
  templateUrl: './profile-diary.component.html',
  styleUrls: ['./profile-diary.component.scss']
})
export class ProfileDiaryComponent implements OnInit {

  selectedPerson: User;

  constructor(private profileData: ProfileDataService) { }

  ngOnInit(): void {
    this.profileData.getUserInContext().subscribe(user => {
      this.selectedPerson = user;
    });
  }
}
