'use strict';

angular.module('cca.components.deletePupilConfirmationSignupUser',
    [
        'events.services.eventsService',
        'balance.constants',
        'person.services.personPickerService',
        'shared.services.mathService',
        'shared.directives.sbCurrencyInput',
        'shared.directives.sbRadioButton',
        'shared.services.simpleAlertService'
    ])
    .component('deletePupilConfirmationParent',
        {
            bindings: {
                calendarEventId: '<',
                pupilId: '<',
                onCancel: '&',
                onComplete: '&'
            },
            templateUrl: '/Scripts/app/cca/components/delete-pupil-confirmation-signup-user.template.html',
            controller: [
                'eventsService',
                'mathService',
                'simpleAlertService',
                function (
                    eventsService,
                    mathService,
                    simpleAlertService) {

                    this.isLoading = true;

                    this.cancel = function () {
                        this.onCancel()();
                    }.bind(this);

                    this.deletePupil = function () {

                        eventsService.signUpUserRemovePupilFromEvent(
                            this.pupilId,
                            this.calendarEventId)
                            .then(function (data) {

                                console.log(data);

                                var responseMessageLabel = 'SB_Event_Cancelled_Confirmation_Message';

                                if (data && data.autoAdjustOnCancel) {
                                    responseMessageLabel = 'SB_Event_Cancelled_Confirmation_Message_Adjusted'
                                };

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Cancel_booking',
                                    message: responseMessageLabel,
                                    messageTranslationParameters: { eventName: data.eventName},
                                    staticBackdrop: true
                                });

                                this.onComplete()(data);
                            }.bind(this))
                            .catch(function (err) {

                                var message = {};

                                if (err && err.data && err.data.Message) {
                                    message = { message: err.data.Message };
                                }

                                simpleAlertService.errorAlert(message);

                                console.error(err);
                            });

                    }.bind(this);

                }
            ]
        });