angular.module('tuitionFeesModule')
    .component('addToCyclePayerSetup', {
        bindings: {
            outModel: '<',
            showEditWarning: '<',
        },
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/add-to-cycle-payer-setup.template.html',
        controller: class AddToCyclePayerSetupCtrl {

            // Bindings
            outModel: any;
            showEditWarning: boolean;

            // Dependencies
            billingCycleService: any;
            simpleAlertService: any;

            // Variables
            payerTypes: any[];

            static $inject = ['billingCycleService', 'simpleAlertService', 'payerTypesEnum'];

            constructor(billingCycleService, simpleAlertService, payerTypesEnum) {
                this.billingCycleService = billingCycleService;
                this.simpleAlertService = simpleAlertService;
                this.payerTypes = Object.values(payerTypesEnum);
            }

            $onInit() {
            }

            $onChanges(changes) {
            }

            toggleIsEditing() {
                if (this.outModel.isEditing || !this.showEditWarning)
                {
                    this.outModel.isEditing = !this.outModel.isEditing;
                    return;
                }

                var modalInstance = this.simpleAlertService.simpleAlert({
                    title: 'SB_Warning',
                    message: 'SB_PayerSetupEdit_Warning',
                    windowSize: 'sm',
                    okButtonText: 'SB_Confirm',
                    cancelButtonText: 'SB_Cancel'
                });

                modalInstance.result
                    .then(function () {
                        this.outModel.isEditing = true;
                    }.bind(this));
            }
        }
    });
