angular.module('messaging.components.messageCenterContainer', [
    'messaging.components.messageCenterClient',
    'shared.services.simpleAlertService',
    'shared.services.deviceService'
]).component('messageCenterContainer', {
    bindings: {
        adminView: '<',
        selectedMessageId: '<',
        selectedMessageType: '<',
        selectedTeamId: '<',
        selectedClubId: '<',
        limitChatFilters: '<',
        preloadFirstMessage: '<',
    },
    templateUrl: '/Scripts/app/messaging/components/message-center-container.template.html',
    controller: [
        '$location',
        'simpleAlertService',
        'messageViewingService',
        function ($location, simpleAlertService, messageViewingService, deviceService) {
            var self = this;
            self.unreadCount = 0;

            self.hideNavButtons = $location.search().hideNavButtons;

            self.onClientUnreadCountChanged = function (unreadCount) {
                self.unreadCount = unreadCount;
            };

            self.onMarkAllAsRead = function (api) {
                self.updateClientMarkAllAsRead = api;
            };

            self.confirmMarkAllAsRead = function () {
                simpleAlertService.simpleAlert({
                    title: 'SB_Mark_All_As_Read',
                    message: 'SB_MARK_ALL_AS_READ_CONFIRM_TEXT',
                    okButtonText: 'SB_OK',
                    cancelButtonText: 'SB_Cancel'
                }).result
                    .then(function () {
                        messageViewingService.markAllAsRead()
                            .then(function () {
                                self.unreadCount = 0;
                                self.updateClientMarkAllAsRead();
                                if (deviceService.isReactNativeWebview()) {
                                    window.alert('React native webview is found!');
                                    deviceService.postReactNativeWebviewMessage('mark all as read');
                                }
                            }, function () {
                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_occurred_try_again'
                                });
                            });
                        });
            };
        }
    ]
});