<div *ngIf="!loading">
  <sb-shell>
    <router-outlet></router-outlet>
  </sb-shell>
  <sb-profile-popup></sb-profile-popup>
  <sb-toast-container></sb-toast-container>
</div>
<div *ngIf="loading"
     class="loading-container">
  <sb-page-loader [noAuth]="true"></sb-page-loader>
</div>
