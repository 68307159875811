import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { User } from '@sb-shared/models/user';

@Directive({ selector: 'ng1-profile-forms' })
export class ProfileFormsUpgrade extends UpgradeComponent {
  @Input() selectedPerson: User;
  @Input() isOrganisationAdmin: boolean;
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1ProfileForms', elementRef, injector);
  }
}
