'use strict';

angular.module('sysadmin.services.languagesService', [])
.service('languagesService', class LanguagesService {

    httpCoreApi: any;
    settings: any;
    baseUrlSlug: string = 'OrganisationLanguage';

    static $inject = ['httpCoreApi'];

    constructor(httpCoreApi) {
        this.httpCoreApi = httpCoreApi;
        this.settings = {
            controllerType: this.httpCoreApi.controllerTypes.SysAdmin
        }
    }

    getLanguages(organisationId: number) {
        return this.httpCoreApi.get(this.baseUrlSlug, {
            ...this.settings,
            orgId: organisationId
        });
    }

    addLanguage(organisationId: number, languageId: number) {
        return this.httpCoreApi.post(`${this.baseUrlSlug}/${languageId}`, {}, {
            ...this.settings,
            orgId: organisationId
        });
    }

    removeLanguage(organisationId: number, languageId: number) {
        return this.httpCoreApi.delete(`${this.baseUrlSlug}/${languageId}`, {}, {
            ...this.settings,
            orgId: organisationId
        });
    }

    setDefaultLanguage(organisationId: number, languageId: number) {
        return this.httpCoreApi.post(`${this.baseUrlSlug}/${languageId}/default`, {}, {
            ...this.settings,
            orgId: organisationId
        });
    }

});
