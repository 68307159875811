'use strict';

angular.module('balanceAdmin.recordPayment.components.recordPaymentCheckout', [
    'payments.components.parentBalancePayment'
])
    .component('recordPaymentCheckout',
        {
            bindings: {
                filterTitle: '<',
                charges: '<',
                isByEvent: '<', // true if all charges related to event, otherwise all related to student
                accountOwnerId: '<',
                completeFn: '&'
            },
            templateUrl: '/Scripts/app/balanceAdmin/recordPayment/components/record-payment-checkout.template.html',
            controllerAs: 'ctrl',
            controller: [
                '$window',
                '$state',
                'balanceAdminService',
                'simpleAlertService',
                'financialAccountTypeEnum',
                function ($window, $state, balanceAdminService, simpleAlertService, financialAccountTypeEnum) {

                    this.sortType = 'transactionDate';
                    this.sortReverse = false;

                    this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    // fields that apply when charges are all per student
                    this.paymentReference = null;
                    this.paymentMethodId = null;
                    this.isPayingWithParentBalance = false;
                    this.isParentBalanceValid = false;
                    this.paymentMsg = '';
                    this.paymentMsgTranslationParams = null;
                    this.selectedBalanceParentId = null;
                    this.selectedBalanceType = null;

                    this.$onChanges = function (changes) {
                        if (changes.charges && this.charges && this.charges.length) {
                            // event charges will have several students, we don't allow use of parent balance.
                            if (this.isByEvent || !this.accountOwnerId) {
                                return;
                            }

                            balanceAdminService.isParentBalanceAvailable(this.accountOwnerId)
                                .then(function (data) {
                                    this.canUseParentBalance = data;
                                }.bind(this))
                                .catch(function (err) {
                                    simpleAlertService.errorAlert({ message: 'Problem checking parent balance availability: ' + err });
                                });
                        }
                    }.bind(this);

                    balanceAdminService.getVisiblePaymentMethods()
                        .then(function (data) {
                            this.paymentMethods = data;
                        }.bind(this))
                        .catch(function (err) {
                            simpleAlertService.errorAlert({ message: 'Problem getting payment methods: ' + err });
                        });

                    this.togglePayWithParentBalance = function () {
                        this.isPayingWithParentBalance = !this.isPayingWithParentBalance;

                        if (this.isPayingWithParentBalance) {
                            // clear out payment method
                            this.paymentMethodId = null;
                        }
                    }.bind(this);

                    this.pay = function () {

                        if (this.isByEvent) {
                            var manualTransactionByEventRequest = {};
                            manualTransactionByEventRequest.items = this.charges.map(function (charge) {
                                return {
                                    transactionId: charge.id,
                                    amountToPay: charge.paymentBalance,
                                    reference: charge.paymentReference,
                                    paymentMethod: charge.paymentMethodId
                                };
                            });

                            balanceAdminService.recordManualTransactionByEvent(manualTransactionByEventRequest)
                                .then(function (data) {
                                    $state.go('paymentComplete',
                                    {
                                        isSuccess: true,
                                        items: data
                                    });
                                }.bind(this))
                                .catch(function (error) {
                                    simpleAlertService.errorAlert({ message: 'Problem encountered recording payment: ' + error });
                                });

                            return;
                        }

                        if (this.isPayingWithParentBalance) {
                            if (this.selectedBalanceParentId == null || this.selectedBalanceType == null) {
                                console.log('Unexpected error when paying with parent balance, parent id or type is null');
                                simpleAlertService.errorAlert();
                                return;
                            }
                        }

                        var manualTransactionByPupilRequest = {};

                        manualTransactionByPupilRequest.items = this.charges.map(function (charge) {
                            return {
                                transactionId: charge.id,
                                amountToPay: charge.paymentBalance
                            };
                        });

                        manualTransactionByPupilRequest.parentPayDetails = {
                            reference: this.paymentReference,
                            paymentMethod: this.isPayingWithParentBalance ? 0 : this.paymentMethodId,
                            useParentBalance: this.isPayingWithParentBalance,
                            parentId: this.isPayingWithParentBalance ? this.selectedBalanceParentId : 0,
                            isCredit: this.isPayingWithParentBalance ? this.selectedBalanceType == financialAccountTypeEnum.Credit : false
                        }

                        balanceAdminService.recordManualTransactionByPupil(manualTransactionByPupilRequest)
                            .then(function (data) {
                                $state.go('paymentComplete',
                                {
                                    isSuccess: true,
                                    items: data
                                });
                            }.bind(this))
                            .catch(function (error) {
                                simpleAlertService.errorAlert({ message: 'Problem encountered recording payment: ' + error });
                            });
                    }.bind(this);

                    this.totalAmount = function () {
                        var sum = 0;
                        angular.forEach(this.charges, function (charge) {
                            if (charge.paymentBalance) {
                                sum += parseFloat(charge.paymentBalance);
                            }
                        });

                        return sum.toFixed(2);
                    }.bind(this);

                    this.setAllToOutstanding = function () {
                        angular.forEach(this.charges, function (charge) {
                            charge.paymentBalance = charge.chargeOutstanding;
                        });
                    }.bind(this);
                }
            ]
        });
