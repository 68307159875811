import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonChars } from './../../globals/common-chars';
import { KitStyleId, KitStyles } from './../../globals/kit-styles';
import { ColoursService } from './../../services/colours.service';
import { Sizes } from './../../types/sizes';

@Component({
  selector: 'sb-kit',
  templateUrl: './kit.component.html',
  styleUrls: ['./kit.component.scss']
})
export class KitComponent {

  @Input() size: Sizes = 'xs';
  @Input() kitStyleId: number = KitStyleId.Plain;
  @Input() primaryColour: string;
  @Input() secondaryColour: string;

  kitCssSizeString = '0.2px';
  styleCssString: string;

  constructor(private colour: ColoursService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size?.currentValue) {
      switch(this.size) {
        case 'xs':
          this.kitCssSizeString = '0.17px';
          break;
        case 'sm':
          this.kitCssSizeString = '0.275px';
          break;
        case 'md':
          this.kitCssSizeString = '0.65px';
          break;
        case 'lg':
          this.kitCssSizeString = '1px';
          break;
        case 'xl':
          this.kitCssSizeString = '1.4px';
          break;
      }
    }
    if (changes.kitStyleId) {
      this.styleCssString = KitStyles.find(item => item.id == this.kitStyleId)?.className;
    }
    const missingHex = string => string.indexOf(CommonChars.Hash) !== 0;
    if (changes.primaryColour?.currentValue && missingHex(this.primaryColour)) {
      this.primaryColour = this.colour.colourToHex(this.primaryColour);
    }
    if (changes.secondaryColour?.currentValue && missingHex(this.secondaryColour)) {
      this.secondaryColour = this.colour.colourToHex(this.secondaryColour);
    }
  }

}