import { Component, OnDestroy } from "@angular/core";
import { OrganisationLabelTags } from "@sb-shared/constants/organisation-label-tags.constants";
import { Subject } from "rxjs";

@Component({ template: '' })
export class ComponentBase implements OnDestroy {
  protected destroyed$ = new Subject<void>();
  organisationLabelTags = OrganisationLabelTags;

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
