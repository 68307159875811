'use strict';

angular.module('reception.components.confirmDeleteRegGroup', [
])
    .component('confirmDeleteRegGroup',
        {
            bindings: {
                modalInstance: '<',
                resolve: '<'
            },
            templateUrl: '/Scripts/app/reception/components/confirm-delete-reg-group.template.html',
            controller: [function ()
            {
                this.regGroup = null;

                this.$onChanges = function (changes)
                {
                    if (changes.resolve && changes.resolve.currentValue.params)
                    {
                        this.regGroup = changes.resolve.currentValue.params.regGroup;
                    }
                }.bind(this);

                this.delete = function ()
                {
                    this.modalInstance.close('confirm');
                }.bind(this);

                this.cancel = function ()
                {
                    this.modalInstance.dismiss('cancel');
                }.bind(this);

            }]
        });