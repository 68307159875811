'use strict';

angular.module('ccasignupModule').component('ccaPupilSelection', {
    templateUrl:
        '/Scripts/app/ccaSignUp/Details/cca-pupil-selection.template.html',
    controller: [
        '$scope',
        '$state',
        '$uibModal',
        '$filter',
        '$translate',
        'signUpRepository',
        'searchService',
        'dateTextService',
        'blobStoragePhotosService',
        'simpleAlertService',
        function (
            $scope,
            $state,
            $uibModal,
            $filter,
            $translate,
            signUpRepository,
            searchService,
            dateTextService,
            blobStoragePhotosService,
            simpleAlertService
        ) {
            $scope.disableSticking = false;
            $scope.isCollapsed = true;
            $scope.saving = false;
            $scope.hasChanged = false;
            $scope.needsConfirming = false;
            $scope.timeZone = dateTextService.getOrgTimezoneText();

            // Pagination
            $scope.pagination = {
                currentPage: 1,
                pageSize: 10,
                maxNumberOfPages: 10,
            };

            $scope.filteredPupils = [];

            $scope.resetFilters = function () {
                $scope.pupilFilter = {
                    name: '',
                    selectedSchoolYearNames: [],
                    showConfirmed: true,
                    showUnconfirmed: true,
                    showNoSelection: true,
                };
            };

            $scope.resetFilters();

            var signUpId = $state.params.signUpId;

            $translate.onReady().then(function () {
                $scope.pageTitle = $filter('translate')(
                    'SB_CcaTitle_Pupil_Selections'
                );
                $scope.selectYearsText =
                    $filter('translate')('SB_Select_Years');
            });

            signUpRepository
                .getActiveSessionsSignUp(signUpId)
                .then(function (signUpDates) {
                    $scope.signUpDates = signUpDates;
                });

            $scope.signUp = {};

            $scope.containerIsActive = false;

            $scope.compareSchoolYearNames = function (a, b) {
                // Use toUpperCase() to ignore character casing
                var schoolIdA = a.id.toUpperCase();
                var schoolIdB = b.id.toUpperCase();

                var comparison = 0;

                if (schoolIdA > schoolIdB) {
                    comparison = 1;
                } else if (schoolIdA < schoolIdB) {
                    comparison = -1;
                }

                return comparison;
            };

            signUpRepository
                .getPupilSelectionBaseData(signUpId)
                .then(function (baseData) {
                    $scope.signUp = baseData.signUp;
                    var pupilData = baseData.pupilSelections.data;
                    $scope.pupils = Object.keys(pupilData).map(function (e) {
                        return pupilData[e];
                    });

                    $scope.onPupilFilter();

                    var uniqueSchoolYearNameItems = $filter('unique')(
                        $scope.pupils,
                        'year'
                    );

                    $scope.uniqueSchoolYearNames = [];

                    for (var i = 0; i < uniqueSchoolYearNameItems.length; i++) {
                        if (
                            uniqueSchoolYearNameItems[i].year &&
                            uniqueSchoolYearNameItems[i].year.length > 0
                        ) {
                            $scope.uniqueSchoolYearNames.push({
                                label: uniqueSchoolYearNameItems[i].year,
                                id: uniqueSchoolYearNameItems[i].year,
                            });
                        }
                    }

                    $scope.uniqueSchoolYearNames.sort(
                        $scope.compareSchoolYearNames
                    );
                    $scope.summary = baseData.counters.data;

                    $scope.containerIsActive = baseData.signUp.status === 3;

                    blobStoragePhotosService
                        .addStudentPhotoUrlsToArray($scope.pupils)
                        .then(function (array) {
                            $scope.pupils = array;
                        });
                });

            $scope.update = function () {
                $scope.saving = true;

                if (!$scope.selectedPupil.confirmedBy) {
                    updateSelections();
                } else {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'unconfirmModal.html',
                        controller: 'UnconfirmModalInstanceCtrl',
                        size: 'sm',
                    }).result;

                    modalInstance
                        .then((confirmed) => confirmed && updateSelections())
                        .catch(() => ($scope.saving = false));
                }
            };

            const updateSelections = () => {
                signUpRepository
                    .savePreferences(
                        $scope.groups,
                        $state.params.signUpId,
                        $scope.selectedPupil.personId
                    )
                    .then(function (baseData) {
                        loadPupilBaseData(
                            baseData,
                            $scope.selectedPupil.personId
                        );
                        $scope.hasChanged = false;
                        $scope.needsConfirming = true;
                    })
                    .catch(function (err) {
                        $scope.saving = false;
                        console.error(err);
                        simpleAlertService.errorAlert({
                            message: 'SB_Error_Saving_Message',
                        });
                    });
            };

            $scope.confirm = function (signUpGroupId) {
                $scope.saving = true;

                signUpRepository
                    .postTeacherConfirm(
                        $scope.groups,
                        $state.params.signUpId,
                        $scope.selectedPupil.personId,
                        ''
                    )
                    .then(function (baseData) {
                        loadPupilBaseData(
                            baseData,
                            $scope.selectedPupil.personId
                        );
                        $scope.needsConfirming = false;
                    });
            };

            $scope.othersChecked = function (
                personId,
                level,
                dayofWeek,
                currentGroupId
            ) {
                var disable = false;
                angular.forEach($scope.groups, function (group, index) {
                    if (
                        !disable &&
                        group.id !== currentGroupId &&
                        group.dayofWeek === dayofWeek
                    ) {
                        angular.forEach(group.pupils, function (pupil, index2) {
                            if (
                                pupil.personId === personId &&
                                pupil.preferenceLevel === level
                            ) {
                                disable = true;
                            }
                        });
                    }
                });

                return disable;
            };

            $scope.sortType = 'allocationScore'; // set the default sort type
            $scope.sortReverse = true; // set the default sort order

            $scope.sortType1 = 'dayofWeek'; // set the default sort type
            $scope.sortReverse1 = false; // set the default sort order

            $scope.sortType2 = 'dayofWeek'; // set the default sort type
            $scope.sortReverse2 = false; // set the default sort order

            $scope.ccaGroupDetails = function (signUpGroupId) {
                $state.go('ccaGroupDetails', {
                    signUpId: $state.params.signUpId,
                    signUpGroupId: signUpGroupId,
                });
            };

            $scope.pupilInformation = function (personId) {
                if ($scope.selectedPupil) {
                    $scope.selectedPupil.firstName = 'Loading';
                    $scope.selectedPupil.lastName = '';
                    $scope.groups = null;
                    $scope.selectedPupil.notes = '';
                    $scope.selectedPupil.year = '';
                } else {
                    $scope.selectedPupil = {};
                }

                $scope.hasChanged = false;
                $scope.needsConfirming = false;

                signUpRepository
                    .getPupilBaseData(signUpId, personId)
                    .then(function (baseData) {
                        loadPupilBaseData(baseData, personId);
                    });
            };

            $scope.userSelectionUpdatedCallback = function () {
                //toggle state so the buttons are active as the user interacted with the UI
                $scope.hasChanged = true;
                $scope.needsConfirming = false;
            };

            $scope.loadedDataRequiresConfirming = function (
                groups,
                personId,
                ccaSignUpId
            ) {
                var ccaSignUpGroup = $filter('filter')(
                    groups,
                    { ccaSignUpId: parseInt(ccaSignUpId) },
                    true
                );

                if (ccaSignUpGroup.length == 0) {
                    return;
                }
                ccaSignUpGroup.forEach(function (group) {
                    var personGroup = $filter('filter')(
                        group.pupils,
                        {
                            personId: personId,
                            preferenceStatus: 0,
                            preferenceLevel: '!0',
                        },
                        true
                    );

                    if (personGroup.length > 0) {
                        $scope.needsConfirming = true;
                        return;
                    }
                });
            };

            function refreshPupil(thisSignUpId, thisPersonId) {
                signUpRepository
                    .getPupilBaseData(signUpId, thisPersonId)
                    .then(function (baseData) {
                        loadPupilBaseData(baseData, thisPersonId);
                    });
            }

            function loadPupilBaseData(baseData, personId) {
                var pupil = baseData.pupilSelections.data;
                for (var i = 0; i < $scope.pupils.length; i++) {
                    if ($scope.pupils[i].personId === personId) {
                        var activePupil = $scope.pupils[i];

                        activePupil.choiceCount = pupil.choiceCount;
                        activePupil.confirmedBy = pupil.confirmedBy;
                        activePupil.confirmedTimestamp =
                            pupil.confirmedTimestamp;
                        activePupil.firstName = pupil.firstName;
                        activePupil.isTeacher = pupil.isTeacher;
                        activePupil.lastName = pupil.lastName;
                        activePupil.notes = pupil.notes;
                        activePupil.selectionCount = pupil.selectionCount;
                        activePupil.year = pupil.year;
                    }
                }

                $scope.loadedDataRequiresConfirming(
                    baseData.groups,
                    personId,
                    $state.params.signUpId
                );

                $scope.groups = baseData.groups;
                blobStoragePhotosService
                    .addStudentPhotoUrlsToNestedArrays($scope.groups)
                    .then(function (array) {
                        $scope.groups = array;
                    });
                $scope.summary = baseData.counters.data;
                $scope.selectedPupil = baseData.pupilInfo.data;
                $scope.saving = false;
            }

            $scope.isConfirmed = function (item) {
                return item.confirmedTimestamp;
            };

            $scope.isUnconfirmed = function (item) {
                return !item.confirmedTimestamp && item.choiceCount > 0;
            };

            $scope.isNoSelection = function (item) {
                return item.choiceCount == 0;
            };

            $scope.toggleConfirmed = function (item) {
                $scope.pupilFilter.showConfirmed =
                    !$scope.pupilFilter.showConfirmed;
                $scope.onPupilFilter();
            };

            $scope.toggleUnconfirmed = function (item) {
                $scope.pupilFilter.showUnconfirmed =
                    !$scope.pupilFilter.showUnconfirmed;
                $scope.onPupilFilter();
            };

            $scope.toggleNoSelection = function (item) {
                $scope.pupilFilter.showNoSelection =
                    !$scope.pupilFilter.showNoSelection;
                $scope.onPupilFilter();
            };

            $scope.confirmedPercentage = function () {
                var length = $scope.pupils.filter(function (item) {
                    return $scope.isConfirmed(item);
                }).length;
                return $scope.getPercentage(length);
            };

            $scope.unconfirmedPercentage = function () {
                var length = $scope.pupils.filter(function (item) {
                    return $scope.isUnconfirmed(item);
                }).length;
                return $scope.getPercentage(length);
            };

            $scope.noSelectionPercentage = function () {
                var startedPercentage =
                    $scope.confirmedPercentage() +
                    $scope.unconfirmedPercentage();
                var remaining = 100 - startedPercentage;
                return remaining;
            };

            $scope.getPercentage = function (length) {
                return (length / $scope.pupils.length) * 100;
            };

            $scope.search = function (item) {
                if (
                    !$scope.pupilFilter.name &&
                    $scope.pupilFilter.selectedSchoolYearNames.length == 0 &&
                    $scope.pupilFilter.showConfirmed &&
                    $scope.pupilFilter.showUnconfirmed &&
                    $scope.pupilFilter.showNoSelection
                ) {
                    return true;
                }

                var nameMatch = $scope.pupilFilter.name == '';

                var searchTextArray = $scope.pupilFilter.name
                    .toLowerCase()
                    .split(' ');

                angular.forEach(searchTextArray, function (textItem) {
                    if (
                        item.firstName.toLowerCase().indexOf(textItem) !== -1 ||
                        item.lastName.toLowerCase().indexOf(textItem) !== -1 ||
                        item.otherName.toLowerCase().indexOf(textItem) !== -1
                    ) {
                        nameMatch = true;
                    }
                });

                var yearMatch =
                    $scope.pupilFilter.selectedSchoolYearNames.length == 0;

                var selectedYearNames =
                    $scope.pupilFilter.selectedSchoolYearNames;
                var selectedYearIds = Object.keys(selectedYearNames).map(
                    function (e) {
                        return selectedYearNames[e].id;
                    }
                );

                if (selectedYearIds.includes(item.year) || !item.year) {
                    yearMatch = true;
                }

                var statusMatch =
                    $scope.pupilFilter.showConfirmed &&
                    $scope.pupilFilter.showUnconfirmed &&
                    $scope.pupilFilter.showNoSelection;

                if (
                    ($scope.pupilFilter.showConfirmed &&
                        $scope.isConfirmed(item)) ||
                    ($scope.pupilFilter.showUnconfirmed &&
                        $scope.isUnconfirmed(item)) ||
                    ($scope.pupilFilter.showNoSelection &&
                        $scope.isNoSelection(item))
                ) {
                    statusMatch = true;
                }

                return nameMatch && yearMatch && statusMatch;
            };

            $scope.onPupilFilter = function () {
                $scope.exportData = [];
                $scope.filteredPupils = $filter('filter')(
                    $scope.pupils,
                    $scope.search
                );
                $scope.pagination.currentPage = 1;
                $scope.filteredPupils.forEach(function (pupil) {
                    pupil.searchScore ==
                        searchService.getPupilRelevance(
                            pupil,
                            $scope.pupilFilter.name
                        );
                    $scope.exportData.push([
                        pupil.lastName +
                            ', ' +
                            pupil.firstName +
                            (pupil.otherName
                                ? ' (' + pupil.otherName + ')'
                                : ''),
                        pupil.year || '',
                        $filter('date')(
                            pupil.confirmedTimestamp,
                            'dd MMM yy HH:mm'
                        ) || '',
                        pupil.confirmedBy ? pupil.confirmedBy : '',
                        pupil.choiceCount.toString(),
                        pupil.selectionCount.toString(),
                    ]);
                });
                if ($scope.filteredPupils.length == $scope.pupils.length) {
                    $scope.pupilFilterText = '';
                } else {
                    $scope.pupilFilterText =
                        '(' +
                        $scope.filteredPupils.length +
                        '/' +
                        $scope.pupils.length +
                        ')';
                }
                $scope.sortType = 'searchScore';
                $scope.sortReverse = true;
            };

            $scope.exportTableHeaders = [
                $filter('translate')('SB_Name'),
                $filter('translate')('SB_Year'),
                $filter('translate')('SB_Confirmed'),
                $filter('translate')('SB_Confirmed_By'),
                $filter('translate')('SB_Choices'),
                $filter('translate')('SB_Allocated'),
            ];
        },
    ],
});

angular.module('ccasignupModule').controller('ModalInstanceCtrl', [
    '$scope',
    '$uibModalInstance',
    'terms',
    'termsLink',
    function ($scope, $uibModalInstance, terms, termsLink) {
        $scope.terms = terms;
        $scope.termsLink = termsLink;
        $scope.acceptedTerms = { checked: false, notes: '' };

        $scope.ok = function () {
            $uibModalInstance.close($scope.acceptedTerms.notes);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    },
]);

angular.module('ccasignupModule').controller('UnconfirmModalInstanceCtrl', [
    '$scope',
    '$uibModalInstance',
    function ($scope, $uibModalInstance) {
        $scope.ok = function () {
            $uibModalInstance.close(true);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    },
]);
