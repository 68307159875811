'use strict';

angular.module('balanceAdmin.components.newTransaction',
    [
        'balanceAdmin.services.balanceAdminService',
        'balance.constants',
        'person.services.personPickerService',
        'shared.services.simpleAlertService'
    ])
    .component('newTransaction',
        {
            bindings: {
                accountTypeId: '<',
                accountOwnerId: '<',
                transactionType: '<',
                debitTransactionVerb: '<',
                calendarEventId: '<',
                groupId: '<',
                isTeam: '<',
                maximumAmount: '<',
                allowOverdraft: '<',
                onTransactionSaved: '&'
            },
            templateUrl: '/Scripts/app/balanceAdmin/components/new-transaction.template.html',
            controller: [
                'balanceAdminService',
                'simpleAlertService',
                'personPickerService',
                'financialTransactionTypeEnum',
                function (
                    balanceAdminService,
                    simpleAlertService,
                    personPickerService,
                    financialTransactionTypeEnum) {

                    this.tranactionTypeSet = false;

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    this.amountLabel = '';
                    this.createTransactionLabel = '';
                    this.allowSelectAccountOwner = true;

                    this.selectedAccountOwnerId = 0;
                    this.selectedAccountOwnerName = '';

                    this.sendNotification = true;

                    this.transaction = {
                        amount: null,
                        description: '',
                        reference: '',
                        relatedTransactionId: null
                    };

                    // Function used by autocomplete component
                    this.getPupils = function (groupId, pupilName) {
                        return this.isTeam
                            ? personPickerService.getAutoCompletePupilsInTeam(groupId, pupilName)
                            : personPickerService.getAutoCompletePupilsInClub(groupId, pupilName);
                    }.bind(this);

                    this.getPupilArgs = [
                        function () { return this.groupId }.bind(this)
                    ];

                    this.pupilLookup = {
                        loadingPupils: false,
                        noPupils: false,
                        getPupilsAutoComplete: function (val) {

                            this.selectedAccountOwnerId = 0;
                            this.selectedAccountOwnerName = null;

                            if (!this.groupId) {
                                console.error('Cannot perform pupil lookup without setting a groupId');
                                return;
                            }

                            if (this.isTeam) {
                                return personPickerService
                                    .getAutoCompletePupilsInTeam(val, this.groupId)
                                    .then(function (data) {
                                        return data;
                                    });
                            }
                            else {
                                return personPickerService
                                    .getAutoCompletePupilsInClub(val, this.groupId)
                                    .then(function (data) {
                                        return data;
                                    });
                            }
                        }.bind(this),
                        onSelect: function ($item, $model, $label) {
                            console.log('balanceAdmin.components.newTransaction/pupilLookup/onSelect', $item);
                            this.selectedAccountOwnerId = $item.id;
                            this.selectedAccountOwnerName = $item.pupilName;
                        }.bind(this)
                    };

                    this.$onChanges = function (changes) {

                        if (!this.tranactionTypeSet && changes.transactionType && changes.transactionType.currentValue) {
                            switch (changes.transactionType.currentValue) {
                                case financialTransactionTypeEnum.Debit:
                                    this.tranactionTypeSet = true;
                                    this.amountLabel = 'SB_' + this.debitTransactionVerb;
                                    this.createTransactionLabel = 'SB_Raise_' + this.debitTransactionVerb;
                                    break;

                                case financialTransactionTypeEnum.Credit:
                                    this.tranactionTypeSet = true;
                                    this.transaction.paymentMethodId = 0;
                                    this.amountLabel = 'SB_Credit';
                                    this.createTransactionLabel = 'SB_Raise_Credit';
                                    break;
                            }
                        }

                        if (changes.accountTypeId && changes.accountTypeId.currentValue) {
                            this.transaction.accountType = changes.accountTypeId.currentValue;
                        }

                        if (changes.accountOwnerId && changes.accountOwnerId.currentValue) {
                            this.transaction.accountOwnerPersonId = changes.accountOwnerId.currentValue;
                            this.allowSelectAccountOwner = false;
                        }

                        if (changes.calendarEventId && changes.calendarEventId.currentValue) {
                            this.transaction.calendarEventId = changes.calendarEventId.currentValue;
                        }

                        if (changes.groupId && changes.groupId.currentValue) {
                            this.transaction.groupId = changes.groupId.currentValue;
                        }

                        if (changes.isTeam && changes.isTeam.currentValue) {
                            this.transaction.isTeam = changes.isTeam.currentValue;
                        }

                        if  (changes.allowOverdraft && changes.allowOverdraft.currentValue === true) {
                            this.maximumCredit = this.maximumAmount;
                            delete this.maximumAmount;
                        }

                    }.bind(this);

                    this.createTransaction = function () {

                        if (!this.transaction.accountOwnerPersonId && this.selectedAccountOwnerId) {
                            this.transaction.accountOwnerPersonId = this.selectedAccountOwnerId;
                        }

                        var method = null;

                        switch (this.transactionType) {
                            case financialTransactionTypeEnum.Debit:
                                method = balanceAdminService.raiseCharge;
                                break;

                            case financialTransactionTypeEnum.Credit:
                                method = balanceAdminService.raiseCredit;
                                break;

                            default:
                                // should never happen because this setting will probably be baked into the code most of the time
                                simpleAlertService.errorAlert({
                                    message: 'Error adding transaction: Invalid transaction type specified.  Please contact SchoolsBuddy if this persists.'
                                });
                                return;
                        }

                        var pleaseWaitModal = simpleAlertService.pleaseWaitModal();

                        method(this.transaction)
                            .then(function (data) {

                                var showSuccess = function () {
                                    var savedDialog = simpleAlertService.simpleAlert({
                                        title: 'SB_Completed',
                                        message: 'SB_Transaction_Saved',
                                        staticBackdrop: true
                                    });

                                    savedDialog.result.then(function () {
                                        if (typeof this.onTransactionSaved === 'function') {
                                            this.onTransactionSaved()(data);
                                        }
                                    }.bind(this), function () {
                                        // dismissed
                                        if (typeof this.onTransactionSaved === 'function') {
                                            this.onTransactionSaved()(data);
                                        }
                                    }.bind(this));
                                }.bind(this);

                                if (!this.allowSelectAccountOwner || !this.sendNotification) {
                                    showSuccess();
                                    return;
                                }

                                balanceAdminService.sendFeesAllocatedEmailToParentOfPupil(this.selectedAccountOwnerId,
                                    this.transaction.calendarEventId,
                                    data.id)
                                    .then(showSuccess)
                                    .catch(function (err) {
                                        var message = 'Charge was raised but there was an error sending parent notification';
                                        simpleAlertService.errorAlert({ message: err && err.data && err.data.Message ? message + ': ' + err.data.Message : message });
                                        console.error('error during send notification call', err);
                                    });
                            }.bind(this))
                            .catch(function (err) {
                                simpleAlertService.errorAlert({ message: err && err.data && err.data.Message ? err.data.Message : 'Error raising transaction' });
                                console.error('error during raise transaction call', err);
                            })
                            .finally(function () {
                                pleaseWaitModal.close();
                                pleaseWaitModal = undefined;
                            });

                    }.bind(this);

                    this.showOverdraftWarning = function() {
                        return (this.maximumCredit || this.maximumCredit == 0) && this.transaction.amount > this.maximumCredit && this.maximumCredit >= 0;
                    };
                }
            ]
        });
