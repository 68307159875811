import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-team-club-picker-summary' })
export class TeamClubPickerSummaryUpgrade extends UpgradeComponent {
  @Input() teamIds: number[];
  @Input() clubIds: number[];
  @Input() subjectClassIds: number[];
  @Input() showClubs: boolean;
  @Input() showTeams: boolean;
  @Input() showSubjectClasses: boolean;
  @Input() nothingSelectedLabel: string;
  @Output() onChangeModel: EventEmitter<void> = new EventEmitter<void>();
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1SbTeamClubPickerSummary', elementRef, injector);
  }
}