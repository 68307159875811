'use strict';

angular.module('shared.controllers.confirmationPopup', [
        'pascalprecht.translate',
        'shared.components.sbModalHeader',
        ])
    .controller('confirmationPopup', [
        '$scope',
        '$uibModalInstance',
        'title',
        'message',
        'translateMessage',
        'messageArray',
        'messageTranslationParameters',
        'okButtonText',
        'cancelButtonText',
        'discardButtonText',
        'iconClass',
        'headerClass',
        'deviceService',
        function (
            $scope,
            $uibModalInstance,
            title,
            message,
            translateMessage,
            messageArray,
            messageTranslationParameters,
            okButtonText,
            cancelButtonText,
            discardButtonText,
            iconClass,
            headerClass,
            deviceService) {

            $scope.title = title;
            $scope.message = message;
            $scope.translateMessage = translateMessage;
            $scope.messageTranslationParameters = messageTranslationParameters;
            $scope.messageArray = messageArray;
            $scope.okButtonText = okButtonText;
            $scope.cancelButtonText = cancelButtonText;
            $scope.discardButtonText = discardButtonText;
            $scope.iconClass = iconClass;
            $scope.headerClass = headerClass;

            $scope.showCancelButton = cancelButtonText && cancelButtonText.length > 0;
            $scope.showDiscardButton = discardButtonText && discardButtonText.length > 0;

            $scope.ok = function() {
                $uibModalInstance.close(true);
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.close = function() {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.discard = function() {
                $uibModalInstance.dismiss('discard');
            };

        }
    ]);
