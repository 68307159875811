angular.module('receptionModule', [
  'ui.bootstrap',

  'sticky',

  'shared.components.sbAutoComplete',
  'shared.components.sbAvatar',
  'shared.components.sbColumnHeader',
  'shared.components.sbDatePicker',
  'shared.components.sbDateTimePicker',
  'shared.components.sbExportButton',
  'shared.components.sbIcon',
  'shared.components.sbTimePicker',
  'shared.components.teamEventGuardians',
  'shared.components.teamEventMedical',
  'shared.components.sbDateRangePicker',
  'shared.components.sbOnOffSwitch',
  'shared.components.sbModalHeader',
  'shared.components.sbProfileInfo',
  'shared.controllers.confirmationPopup',
  'shared.controllers.pleaseWaitPopupController',
  'shared.directives.cloakTranslate',
  'shared.directives.fixedFirstColumn',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',
  'shared.directives.sbCheckBox',
  'shared.directives.sbRadioButton',
  'shared.components.sbSearchFilter',
  'shared.components.sbSearchFilterItem',

  'shared.directives.updateLanguage',
  'shared.directives.sbDetail',
  'shared.filters.decoded',
  'shared.services.arrayService',
  'shared.services.authInterceptor',
  'shared.services.cachedLookupService',
  'shared.services.dateTimeService',
  'shared.services.guidService',
  'shared.services.httpCoreApi',
  'shared.services.iconService',
  'shared.services.imagesUploadHandler',
  'shared.services.moduleService',
  'shared.services.simpleAlertService',
  'shared.services.toastService',
  'shared.services.tinymceConfigHelper',
  'shared.services.settingService',
  'shared.services.registerStateService',
  'shared.services.blobStoragePhotosService',
  'shared.services.searchService',
  'shared.constants',

  'ccaSignUp.services.signUpRepository',

  'corporateContacts.components.corporateContactEditor',
  'corporateContacts.components.corporateContactList',
  'corporateContacts.services.corporateContactsService',

  'diary.components.diaryEventSendComms',
  'diary.directives.teamDiary',
  'diary.components.diaryEventList',

  'documentStore.components.documentList',
  'documentStore.components.documentEditor',

  'events.components.autoNotifyEvent',
  'events.components.cancelEventButton',
  'events.components.confirmRemoveStudentFromEvent',
  'events.components.consentEventInvites',
  'events.components.consentEventSettingsEdit',
  'events.components.confirmManualYesOrNo',
  'events.components.eventDetailEditor',
  'events.components.eventRegister',
  'events.components.eventsRegisterTransportAddStudentSelectStops',
  'events.components.notifyOnlyEvent',
  'events.constants',
  'events.services.eventsService',
  'events.services.eventRegistersService',

  'group.components.groupDetailEditor',
  'group.controllers.multiPersonPickerPopupController',

  'insurance.components.insuranceContainer',

  'messaging.components.messageCenterContainer',
  'messaging.directives.emailEditor',
  'messaging.directives.messageComposer',
  'messaging.directives.messageHistory',
  'messaging.services.messageViewingService',

  'mortgage.controllers.mortgageStatusPopupController',
  'mortgage.components.mortgageDetailEditor',
  'mortgage.components.mortgageStatusEditor',

  'organisation.services.organisationService',

  'person.services',
  'person.services.personPickerService',
  'person.services.staffRepository',

  'profile.controllers.profilePopupController',

  'personPicker.constants',

  'reports.components.reportContainer',

  'roles.services.rolesService',

  'team.directives.attendance',
  'team.directives.teamJoinRequests',

  'teams.components.teamNoteEditor',
  'teams.components.teamNoteList',
  'teams.services.teamService',

  'reception.services.receptionService',
  'reception.services.registerFileService',
  'reception.controllers.addTaskPopupController',
  'reception.components.receptionLocationManage',
  'reception.components.receptionLocationEvents',
  'reception.components.confirmDeleteRegGroup',
  'reception.components.receptionEventsAutoNotify',
  'reception.components.receptionConsentEventInvites',
  'reception.components.receptionEventsNotifyOnly',
  'reception.components.receptionTeamNotes',
  'reception.components.receptionWhatsNext',

  'tasks.components.taskList',
  'tasks.components.taskEditor',

  'transport.services.transportConfigurationStopsService',
  'transport.services.pupilTransportPreferenceService',
  'transport.services.transportTimeSlotsService',

  'userForm.components.bulkView',

  'ng1StateDefinitionModule'

])
  .config([
    'ng1StateDefinitionProvider',
    function (ng1StateDefinitionProvider) {
      const ng1State = ng1StateDefinitionProvider.$get();
      const moduleName = 'Reception';
      ng1State.state(moduleName, 'dashboard',
        {
          url: '/'
        })
      ng1State.state(moduleName, 'dashboard.events',
        {
          url: 'events'
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent',
        {
          url: '/:eventId',
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.register',
        {
          url: '/register'
          // resolve: {
          //     eventId: ['$stateParams', function($stateParams) {
          //         return $stateParams.eventId;
          //     }],
          //     showTitleBar: function () { return true; },
          //     showAbsentColumn: ['receptionService', function(receptionService)
          //     {
          //         return receptionService.getBoolSetting('Register_ShowAbsentColumn');
          //     }],
          //     onRegisterUpdated: ['$rootScope', function($rootScope)
          //     {
          //         return function()
          //         {
          //             return function(data)
          //             {
          //                 $rootScope.$broadcast('registerUpdated', data);
          //             };
          //         };
          //     }],
          //     onAttendeesAddedToEvent: ['$rootScope', function($rootScope)
          //     {
          //         return function()
          //         {
          //             return function(data)
          //             {
          //                 $rootScope.$broadcast('registerAttendeeAdded', data);
          //             };
          //         };
          //     }],
          //     isOrganisationAdmin: ['cachedLookupService', function (cachedLookupService)
          //     {
          //         return cachedLookupService.isOrganisationAdmin();
          //     }],
          //     isTeacher: ['cachedLookupService', function (cachedLookupService) {
          //         return cachedLookupService.isTeacher();
          //     }],
          // }
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.details',
        {
          // Renamed to avoid confusion with diary/events/details
          url: '/editDetails'
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.autoNotify',
        {
          url: '/autoNotify'
          // resolve: {
          //     numberAutoNotified: ['$q', '$stateParams', 'eventsService', function ($q, $stateParams, eventsService)
          //     {
          //         var deferred = $q.defer();

          //         eventsService.getEventInfo($stateParams.eventId)
          //             .then(function (response)
          //             {
          //                 var returnValue = response.numberAutoNotified;

          //                 deferred.resolve(returnValue);
          //             })
          //             .catch(function (err)
          //             {
          //                 console.error(err);
          //             });

          //         return deferred.promise;
          //     }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.notifyOnly',
        {
          url: '/notifyOnly'
          // resolve: {
          //     eventId: ['$stateParams', function ($stateParams)
          //     {
          //         return $stateParams.eventId;
          //     }],
          //     numberOfFutureEventsInSeries: ['$q', '$stateParams', 'eventsService', function ($q, $stateParams, eventsService)
          //     {
          //         var deferred = $q.defer();

          //         eventsService.getEventInfo($stateParams.eventId)
          //             .then(function (response)
          //             {
          //                 var returnValue = response.numberOfFutureEventsInSeries;

          //                 deferred.resolve(returnValue);
          //             })
          //             .catch(function (err)
          //             {
          //                 console.error(err);
          //             });

          //         return deferred.promise;
          //     }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.consentEventInvites',
        {
          url: '/consentEventInvites'
          // resolve: {
          //     eventId: ['$stateParams', function ($stateParams)
          //     {
          //         return $stateParams.eventId;
          //     }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.guardians',
        {
          url: '/guardians'
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.sendComms',
        {
          url: '/comms'

          /*
          resolve: {
              eventId: ['$stateParams', function ($stateParams) {
                  return $stateParams.eventId;
              }],
              allowComms: function () { return true; }
          }*/
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.medical',
        {
          url: '/medical'
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.formtags',
        {
          url: '/personaldetails'
        })
      ng1State.state(moduleName, 'dashboard.events.specificEvent.formtags',
        {
          url: '/taggedForm'
        })
      ng1State.state(moduleName, 'dashboard.reggroups',
        {
          url: 'reggroups'
        })


      ng1State.state(moduleName, 'dashboard.reggroups.specificGroup',
        {
          url: '/:groupId'
        })
      ng1State.state(moduleName, 'dashboard.reggroups.specificGroup.whatsNext',
        {
          url: '/whatsnext'
          // resolve: {
          //     groupId: ['$stateParams', function ($stateParams) {
          //         return $stateParams.groupId;
          //     }],
          //     isRegGroup: function () {
          //         return true;
          //     }
          // }
        })

      // SOME OF THESE ARE NO LONGER VALID AS WE HAVE SPLIT THEM INTO TABS

      ng1State.state(moduleName, 'dashboard.allgroups',
        {
          url: 'allgroups'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup',
        {
          url: '/:groupId'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.groupdetails',
        {
          url: '/groupdetails'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.mortgage',
        {
          url: '/mortgage'
          // resolve: {
          //     adminView: function () {
          //         return true;
          //     },
          //     groupId: ['$stateParams', function ($stateParams) {
          //         return $stateParams.groupId;
          //     }],
          //     onStatusChange: ['$rootScope', function ($rootScope) {
          //         return function () { // closure for the callback
          //             return function(latestStatus) // the actual callback
          //             {
          //                 $rootScope.$broadcast('mortgage-status-updated', latestStatus);
          //             };
          //         };
          //     }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.insurance',
        {
          url: '/insurance'
          // resolve: {
          //     teamId: ['$stateParams', function ($stateParams) {
          //         return $stateParams.groupId;
          //     }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.messageCenter',
        {
          url: '/messagecenter'
          // resolve: {
          //     adminView: function () {
          //         return true;
          //     },
          //     limitToTeamId: ['$stateParams', function ($stateParams) {
          //         return +($stateParams.groupId);
          //     }],
          //     hideFolders: function () {
          //         return true;
          //     },
          //     limitChatFilters: ['$q', 'receptionService', 'messageViewingService',
          //         function ($q, receptionService, messageViewingService) {
          //             var deferred = $q.defer();

          //             receptionService
          //                 .getLookupData()
          //                 .then(function (data) {
          //                     var returnValue = messageViewingService.getChatFilters(data.isStatus365, data.isStaff, data.isParent);

          //                     deferred.resolve(returnValue);
          //                 })
          //                 .catch(function (err) {
          //                     console.error(err);
          //                 });

          //             return deferred.promise;
          //         }],
          //     showChatSessionName: ['$q', 'receptionService',
          //         function ($q, receptionService) {
          //             var deferred = $q.defer();

          //             receptionService
          //                 .getLookupData()
          //                 .then(function (data) {
          //                     deferred.resolve(data.isStatus365);
          //                 })
          //                 .catch(function (err) {
          //                     console.error(err);
          //                     deferred.resolve(false);
          //                 });

          //             return deferred.promise;
          //         }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.pupils',
        {
          url: '/pupils'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.staff',
        {
          url: '/staff'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.diary',
        {
          url: '/allgroupsdiary'
        })
      // Added to handle existing ng1 redirect with new ng12 routing
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.events',
        {
          url: '/events'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.whatsNext',
        {
          url: '/whatsnext'
          // resolve: {
          //     groupId: ['$stateParams', function ($stateParams) {
          //         return $stateParams.groupId;
          //     }],
          //     isRegGroup: function () {
          //         return false;
          //     }
          // }
        })



      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.comms',
        {
          url: '/comms'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.joinrequests',
        {
          url: '/joinrequests'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.chatsession',
        {
          url: '/chatsession-:id-:type'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.attendance',
        {
          url: '/attendance'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.guardians',
        {
          url: '/guardians'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.medical',
        {
          url: '/medical'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.formtags',
        {
          url: '/personaldetails'
        })

      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.documentStore',
        {
          url: '/documentStore'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.tasks',
        {
          url: '/tasks'
        })
      ng1State.state(moduleName, 'dashboard.allgroups.specificGroup.notes',
        {
          url: '/notes',
          // resolve: {
          //     teamId: ['$stateParams', function ($stateParams) {
          //         return $stateParams.groupId;
          //     }]
          // }
        })
      ng1State.state(moduleName, 'dashboard.attendance',
        {
          url: 'attendance'
        })
      ng1State.state(moduleName, 'dashboard.users',
        {
          url: 'users'
        })
      ng1State.state(moduleName, 'dashboard.users.diary',
        {
          url: '/usersdiary'
        })
      ng1State.state(moduleName, 'dashboard.users.linked',
        {
          url: '/userslinked'
        })
      ng1State.state(moduleName, 'dashboard.users.groups',
        {
          url: '/usersgroups'
        })
      ng1State.state(moduleName, 'dashboard.users.pupilmedical',
        {
          url: '/userspupilmedical'
        })
      ng1State.state(moduleName, 'dashboard.users.pupilmembership',
        {
          url: '/userspupilmembership'
        })
      ng1State.state(moduleName, 'dashboard.users.forms',
        {
          url: '/usersforms',
          // component: 'userForm',
          // resolve: {
          //     person: ['$stateParams', function ($stateParams) {
          //         return $stateParams.person;
          //     }],
          //     isOrganisationAdmin: ['$stateParams', function ($stateParams) {
          //         console.log('PARAMS', $stateParams);
          //         return $stateParams.isOrganisationAdmin;
          //     }],
          //     isOrganisationSuperAdmin: ['$stateParams', function ($stateParams) {
          //         return $stateParams.isOrganisationSuperAdmin;
          //     }]
          // },
          // params: {
          //     person: null,
          //     isOrganisationAdmin: false,
          //     isOrganisationSuperAdmin: false
          // }
        })
      ng1State.state(moduleName, 'dashboard.joinrequests',
        {
          url: 'alljoinrequests',
        })
      ng1State.state(moduleName, 'dashboard.locations',
        {
          url: 'locations',
        })
      ng1State.state(moduleName, 'dashboard.locations.locationdetails',
        {
          url: '/LocationDetails',
        })
      ng1State.state(moduleName, 'dashboard.locations.locationevents',
        {
          url: '/LocationEvents',
        })
      ng1State.state(moduleName, 'dashboard.contacts',
        {
          url: 'contacts',
        })
      ng1State.state(moduleName, 'dashboard.tasks',
        {
          url: 'tasks',
        })
      ng1State.state(moduleName, 'dashboard.reports',
        {
          url: 'reports',
        })
      ng1State.state(moduleName, 'dashboard.messagecenter',
        {
          url: 'messagecenter',
        })
      ng1State.state(moduleName, 'dashboard.staffschedule',
        {
          url: 'staffschedule',
        });

    }
  ])
  .component('ng1ReceptionEvents', {
    template: '<reception-events on-select-event="$ctrl.onSelectEvent(event)" refresh-fn="$ctrl.refreshFn"></reception-events>',
    bindings: {
      onSelectEvent: '&'
    }
  })
  .component('ng1ReceptionEventsRegister', {
    template: `<event-register event-id="$ctrl.eventId"
                                 show-title-bar="true"
                                 show-absent-column="$ctrl.showAbsentColumn"
                                 is-teacher="$ctrl.isTeacher"
                                 is-external="$ctrl.isExternal"
                                 is-external-can-view-profile="$ctrl.isExternalCanViewProfile"
                                 is-organisation-admin="$ctrl.isOrganisationAdmin"
                                 on-attendees-added-to-event="$ctrl.onAttendeesAddedToEventBroadcast(data)"
                                 on-register-updated="$ctrl.onRegisterUpdatedBroadcast(data)"></event-register>`,
    bindings: {
      onRegisterUpdated: '&',
      onAttendeesAddedToEvent: '&'
    },
    controller: class ng1ReceptionEventsRegisterCtrl {
      $state: any;
      $rootScope: any;
      receptionService: any;
      cachedLookupService: any;

      eventId: number;
      showAbsentColumn: boolean;

      isTeacher: boolean;
      isExternal: boolean;
      isOrganisationAdmin: boolean;

      static $inject = ['$state', '$rootScope', 'receptionService', 'cachedLookupService'];

      constructor($state, $rootScope, receptionService, cachedLookupService) {
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.receptionService = receptionService;
        this.cachedLookupService = cachedLookupService;
        this.eventId = this.$state.params.eventId;
        this.showAbsentColumn = receptionService.getBoolSetting('Register_ShowAbsentColumn');

        Promise.all(
          [
            this.cachedLookupService.isTeacher(),
            this.cachedLookupService.isExternal(),
            this.cachedLookupService.isOrganisationAdmin()
          ])
          .then(([isTeacher, isExternal, isOrganisationAdmin]) => {
            this.isTeacher = isTeacher;
            this.isExternal = isExternal
            this.isOrganisationAdmin = isOrganisationAdmin;
          });
      }

      onRegisterUpdatedBroadcast(data) {
        this.$rootScope.$broadcast('registerUpdated', data);
      }

      onAttendeesAddedToEventBroadcast(data) {
        this.$rootScope.$broadcast('registerAttendeeAdded', data);
      }
    }
  })
  .component('ng1ReceptionEventsDetails', {
    template: '<reception-events-details on-refresh="$ctrl.onRefresh()"></reception-events-details>',
    bindings: {
      onRefresh: '&'
    }
  })
  .component('ng1ReceptionEventsAutoNotify', {
    template: '<reception-events-auto-notify number-auto-notified="$ctrl.numberAutoNotified" on-refresh="$ctrl.onRefresh()"></reception-events-auto-notify>',
    bindings: {
      onRefresh: '&'
    }
  })
  .component('ng1ReceptionEventsNotifyOnly', {
    template: `<reception-events-notify-only event-id="$ctrl.eventId"
                                               number-of-future-events-in-series="$ctrl.numberOfFutureEventsInSeries"></reception-events-notify-only>`,
    bindings: {
      eventId: '<',
      numberOfFutureEventsInSeries: '<'
    }
  })
  .component('ng1ReceptionEventsConsentEventInvites', {
    template: '<reception-consent-event-invites event-id="$ctrl.eventId"></reception-consent-event-invites>',
    bindings: {
      eventId: '<'
    }
  })
  .component('ng1ReceptionEventsGuardians', {
    template: '<reception-events-guardians></reception-events-guardians>',
  })
  // .component('ng1ReceptionEventsSendComms', {
  //       template: '<reception-events-send-comms" selected-event="$ctrl.selectedEvent></reception-events-send-comms>',
  //       controller: class ng1ReceptionEventsSendCommsCtrl {
  //         selectedEvent: any;
  //         constructor() {
  //             this.selectedEvent = {
  //                 currentNotifyAudienceTypeId: 3,
  //                 eventId: 130170,
  //                 isAutoNotifyEvent: false,
  //                 isConsentEvent: false,
  //                 isNotifyOnlyEvent: false,
  //                 numberAutoNotified: 0,
  //                 numberOfFutureEventsInSeries: 0}
  //         }
  //       }
  //     })
  .component('ng1ReceptionEventsSendComms', {
    template: `<diary-event-send-comms event-id="$ctrl.eventId" allow-comms="$ctrl.allowComms" on-selected-event-updated="$ctrl.onSelectedEventUpdated()"></diary-event-send-comms>`,
    bindings: {
      eventId: '<',
      allowCommons: '<',
      onSelectedEventUpdated: '&'
    }
  })
  .component('ng1ReceptionEventsMedical', {
    template: '<reception-events-medical></reception-events-medical>',
  })
  .component('ng1ReceptionEventsFormtags', {
    template: '<reception-events-form-tags selected-event="$ctrl.selectedEvent"></reception-events-form-tags>',
    bindings: {
      selectedEvent: '<'
    }
  })
  .component('ng1ReceptionReggroups', {
    template: '<reception-reg-groups></reception-reg-groups>',
  })
  .component('ng1ReceptionReggroupsSpecificGroup', {
    template: '<reception-reg-groups-specific-group></reception-reg-groups-specific-group>',
  })
  .component('ng1ReceptionAllgroups', {
    template: '<reception-all-groups on-select-group="$ctrl.onSelectGroup(group)" on-group-updated="$ctrl.onGroupUpdated()"></reception-all-groups>',
    bindings: {
      onSelectGroup: '&',
      onGroupUpdated: '&'
    }
  })
  .component('ng1ReceptionAllgroupsGroupDetails', {
    template: '<reception-all-groups-details on-group-updated="$ctrl.onGroupUpdated(group)" selected-group="$ctrl.selectedGroup"></reception-all-groups-details>',
    bindings: {
      onGroupUpdated: '&',
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsMortgage', {
    template: '<reception-all-groups-mortgage selected-group="$ctrl.selectedGroup"></reception-all-groups-mortgage>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsInsurance', {
    template: '<reception-all-groups-insurance selected-group="$ctrl.selectedGroup"></reception-all-groups-insurance>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsMessageCenter', {
    template: `<message-center-container admin-view="true"
                                           limit-chat-filters="$ctrl.chatFilters"
                                           hide-folders="true"
                                           selected-team-id="$ctrl.selectedTeamId"></message-center-container>`,
    bindings: {
      chatFilters: '<',
      selectedTeamId: '<'
    }
  })
  .component('ng1ReceptionAllgroupsPupils', {
    template: '<reception-all-groups-pupils selected-group="$ctrl.selectedGroup"></reception-all-groups-pupils>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsStaff', {
    template: '<reception-all-groups-staff selected-group="$ctrl.selectedGroup"></reception-all-groups-staff>',

    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsDiary', {
    template: '<reception-all-groups-diary selected-group="$ctrl.selectedGroup"></reception-all-groups-diary>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsComms', {
    template: '<reception-all-groups-comms selected-group="$ctrl.selectedGroup"></reception-all-groups-comms>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsJoinRequests', {
    template: '<reception-all-groups-join-requests selected-group="$ctrl.selectedGroup"></reception-all-groups-join-requests>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsChatSession', {
    template: '<reception-all-groups-chat-session selected-group="$ctrl.selectedGroup"></reception-all-groups-chat-session>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsAttendance', {
    template: '<reception-all-groups-attendance selected-group="$ctrl.selectedGroup"></reception-all-groups-attendance>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsGuardians', {
    template: '<reception-all-groups-guardians selected-group="$ctrl.selectedGroup"></reception-all-groups-guardians>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsMedical', {
    template: '<reception-all-groups-medical selected-group="$ctrl.selectedGroup"></reception-all-groups-medical>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsFormtags', {
    template: '<reception-all-groups-form-tags selected-group="$ctrl.selectedGroup"></reception-all-groups-form-tags>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsDocumentStore', {
    template: '<reception-all-groups-document-store selected-group="$ctrl.selectedGroup"></reception-all-groups-document-store>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsTasks', {
    template: '<reception-all-groups-tasks selected-group="$ctrl.selectedGroup"></reception-all-groups-tasks>',
    bindings: {
      selectedGroup: '<'
    }
  })
  .component('ng1ReceptionAllgroupsNotes', {
    template: '<reception-team-notes team-id="$state.params.groupId"></reception-team-notes>',
  })
  .component('ng1ReceptionAttendance', {
    template: '<reception-attendance></reception-attendance>',
  })
  .component('ng1ReceptionUsersDiary', {
    template: '<reception-users-diary selected-person="$ctrl.selectedPerson"></reception-users-diary>',
    bindings: {
      selectedPerson: '<'
    }
  })
  .component('ng1ReceptionUsersLinked', {
    template: '<reception-linked-users selected-person="$ctrl.selectedPerson" is-organisation-admin="$ctrl.isOrganisationAdmin" on-person-updated-fn="$ctrl.onPersonUpdatedFn"></reception-linked-users>',
    bindings: {
      selectedPerson: '<'
    }
  })
  .component('ng1ReceptionUsersPupilMembership', {
    template: '<reception-users-pupil-membership selected-person="$ctrl.selectedPerson"></reception-users-pupil-membership>',
    bindings: {
      selectedPerson: '<'
    }
  })
  .component('ng1ReceptionUsersForms', {
    template: '<user-form selected-person="$ctrl.selectedPerson"></user-form>',
    bindings: {
      selectedPerson: '<'
    }
  })
  .component('ng1UsersForms', {
    template: `<user-form-captured person="$ctrl.person"
                       form-Id="$ctrl.formId"
                       printable="false"
                       hide-heading="true"
                       can-save-form="true"
                       on-change="$"></user-form>`,
    bindings: {
      person: '<',
      formId: '<',
      onChange: '&'
    }
  })
  .component('ng1ReceptionUsersPermissionToWalk', {
    template: '<reception-users-permission-to-walk person="$ctrl.selectedPerson"></reception-users-permission-to-walk>',
    bindings: {
      selectedPerson: '<'
    }
  })
  .component('ng1ReceptionJoinRequests', {
    template: '<reception-join-requests></reception-join-requests>',
  })
  .component('ng1ReceptionLocations', {
    template: '<reception-locations></reception-locations>'
  })
  .component('ng1ReceptionLocationsDetails', {
    template: '<reception-location-manage selected-location="$ctrl.selectedLocation" on-selected-location-updated="$ctrl.onSelectedLocationUpdated()"></reception-location-manage>',
    bindings: {
      selectedLocation: '<',
      onSelectedLocationUpdated: '&'
    }
  })
  .component('ng1ReceptionLocationsEvents', {
    template: '<reception-location-events selected-location="$ctrl.selectedLocation"></reception-location-events>',
    bindings: {
      selectedLocation: '<'
    }
  })
  .component('ng1ReceptionContacts', {
    template: '<reception-contacts></reception-contacts>',
  })
  .component('ng1ReceptionTasks', {
    template: '<reception-tasks></reception-tasks>',
  })
  .component('ng1ReceptionReports', {
    template: '<reception-reports></reception-reports>',
  })
  .component('ng1ReceptionMessageCenter', {
    template: '<message-center></message-center>',
  })
  .component('ng1ReceptionStaffSchedule', {
    template: '<reception-staff-schedule></reception-staff-schedule>',
  })
  .component('ng1ReceptionWhatsNext', {
    template: '<reception-whats-next group-id="$ctrl.groupId" is-reg-group="$ctrl.isRegGroup"></reception-whats-next>',
  })
