'use strict';

angular.module('ccasignupModule').component('ccaDuplicateGroups', {

    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-duplicate-groups.template.html',
    controller: [
    '$scope',
    '$location',
    '$state',
    '$uibModal',
    '$filter',
    'signUpRepository',
    'dateTextService', function ($scope, $locale, $state, $uibModal, $filter, signUpRepository, dateTextService)
    {
        signUpRepository.getAvailableToCopy($state.params.signUpId).then(function (signUps) { $scope.signUps = signUps; });

        var signUpId = $state.params.signUpId;

        signUpRepository.getSignUp(signUpId).then(function (signUp) {
            $scope.currentSignUp = signUp;
        });

        $scope.sortType = 'title'; // set the default sort type
        $scope.sortReverse = false; // set the default sort order
        $scope.groupSortType = 'title'; // set the default sort type
        $scope.groupSortReverse = false; // set the default sort order

        $scope.search = function (item) {

            var match = false;

            if ($scope.searchFilters.statusId > 0) {
                match = (item.status === $scope.searchFilters.statusId);
            } else {
                match = true;
            }

            return match;
        };

        $scope.loadGroups = function (localSignUpId, title) {
            $scope.fromCcaSignUpId = localSignUpId;
            $scope.selectedTitle = title;
            $scope.groups = null;
            signUpRepository.getSignUpDuplicateGroups(signUpId, localSignUpId)
                .then(function (signUpGroups) { $scope.groups = signUpGroups; });
        };

        $scope.copyGroups = function () {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'confirmDuplicationSignUpModal.html',
                controller: 'ConfirmDuplicationModalInstanceCtrl',
                size: 'sm'

            });

            modalInstance.result.then(function () {

                var toCopy = $filter('filter')($scope.groups, { checked: true });

                var copyIds = [];

                angular.forEach(toCopy, function (value, key) {
                    copyIds.push({
                        id: value.id,
                        andAllocation: (value.andAllocation === true),
                        withSelectionAll: (value.withSelectionAll === true),
                        withSelectionAllocatedOnly: (value.withSelectionAllocatedOnly === true)
                    });
                });

                signUpRepository.postCopyToSignUp(signUpId, copyIds, $scope.fromCcaSignUpId)
                    .then(function (signUpGroups) { $scope.groups = signUpGroups; });
            }, function () {
            });
        };

        $scope.toggleAll = function () {
            angular.forEach($scope.groups,
                function (value, key) {
                    value.checked = !value.checked;
                });
        };

        $scope.searchFilters = { statusId: 1 };

        $scope.dateText = function (dayNumber)
        {
            return dateTextService.getShortDayText(dayNumber);
        }

        $scope.statusText = function (statusId) {
            switch (statusId) {
                case 0:
                    return 'SB_Not_Started';
                case 1:
                    return 'SB_Partial';
                case 2:
                    return 'SB_Complete';
                case 3:
                    return 'SB_On_Hold';
                case 4:
                    return 'SB_Auto_Complete';
                case 5:
                    return 'SB_Archived';
                case 6:
                    return 'SB_CcaStatus_Active';
                default:
                    return 'SB_Not_Started';
            }
        };

        $scope.copyStatusText = function (statusId) {
            switch (statusId) {
                case 1:
                    return 'SB_Waiting';
                case 2:
                    return 'SB_Processing';
                case 3:
                    return 'SB_Copied';
                default:
                    return '';
            }
        };



        $scope.ccaStatusText = function (statusId) {
            switch (statusId) {
                case 1:
                    return 'SB_Managing';
                case 2:
                    return 'SB_Generating';
                case 3:
                    return 'SB_CcaStatus_Active';
                case 4:
                    return 'SB_Archived';
                case 5:
                    return 'SB_Processing';
                default:
                    return 'SB_Managing';
            }
        };


        $scope.statuses = [
            {
                id: 0,
                name: 'SB_All'
            },
            {
                id: 1,
                name: 'SB_Managing'
            },
            {
                id: 2,
                name: 'SB_Generating'
            },
            {
                id: 3,
                name: 'SB_CcaStatus_Active'
            },
            {
                id: 4,
                name: 'SB_Archived'
            },
            {
                id: 5,
                name: 'SB_Processing'
            }];
    }
]});
angular.module('ccasignupModule').controller('ConfirmDuplicationModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
