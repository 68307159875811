angular.module('shared.services.twilioService', [])
    .service('twilioService', class TwilioService {

        httpCoreApi: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
        }

        async getToken(roomName: string) {
            const requestConfig = {
                urlParams: {
                    twilioRoomName: roomName
                },
                controllerType: this.httpCoreApi.controllerTypes.User
            }
            const tokenRes = await this.httpCoreApi.get('Twilio/Video/Token', requestConfig)
            return tokenRes.data;
        }
    });
