'use strict';

angular.module('balanceAdmin.massAdjustmentStudent.components.massAdjustmentStudent',
    [
        'balanceAdmin.massAdjustmentStudent.components.massAdjustmentStudentFilter',
        'balanceAdmin.massAdjustmentStudent.components.massAdjustmentStudentDetail'
    ])
    .component('massAdjustmentStudent',
        {
            bindings: {
            },
            templateUrl: '/Scripts/app/balanceAdmin/massAdjustmentStudent/components/mass-adjustment-student.template.html',
            controller: [
                'balanceAdminService',
                function (balanceAdminService)
                {
                    this.isUserAdvancedFeeAdmin = false;

                    this.$onInit = function ()
                    {
                        balanceAdminService.isAdvancedFeeAdmin()
                            .then(function (isUserAdvancedFeeAdmin)
                            {
                                this.isUserAdvancedFeeAdmin = isUserAdvancedFeeAdmin;
                            }.bind(this));
                    }

                    // Set-up our filters.
                    // Default search window to last two months.
                    this.searchFilters = {
                        includeDeleted: false
                    };
                }
            ]
        });