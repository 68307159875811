// start:ng42.barrel
import './diary-consent-event-invites.component';
import './diary-event-auto-notify.component';
import './diary-event-detail-info.component';
import './diary-event-detail.component';
import './diary-event-form-tag.component';
import './diary-event-list.component';
import './diary-event-notify-only.component';
import './diary-event-send-comms.component';
import './diary-event-video.component';
import './ical-link-popup.component';
// end:ng42.barrel

