import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Button, Buttons } from '@sb-shared/models/UI/buttons';
import { SubMenuItems } from '@sb-shared/models/UI/menu-item';

@Component({
  selector: 'sb-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent {

  @Input() menuItems: SubMenuItems;
  @Input() selectedValue: any;
  @Input() isRouted: boolean;
  @Input() showBack: boolean;
  @Input() showBackSm: boolean;
  @Input() backgroundUrl: string;
  @Input() actions: Buttons = [];
  @Input() isLoadingMenuItems: boolean;
  @Output() onClickBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClickAction: EventEmitter<number> = new EventEmitter<number>();
  isExpanded: boolean;
  allActions: Buttons;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.actions || changes.menuItems) {
      if (!this.menuItems && this.actions) return this.allActions = this.actions;
      const topMenuItems: Buttons = this.menuItems && this.menuItems.filter(item => item.isTopIcon).map(item => {
        return {
          ...item,
          tooltipText: item.label
        }
      })
      if (!this.actions) return this.allActions = topMenuItems;
      this.allActions = [...topMenuItems, ...this.actions];
    }
    return null;
  }

  ngOnDestroy() {
    document.body.classList.remove('overflow-hidden');
  }

  back() {
    this.onClickBack.emit();
  }

  toggleWindow() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      document.body.classList.add('overflow-hidden');
    }
    else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  bottomMenuItems() {
    if (!this.menuItems) return [];
    return this.menuItems.filter(item => !item.isTopIcon);
  }

  clickAction(action: Button) {
    const actionIndex = this.actions.indexOf(action);
    this.onClickAction.emit(actionIndex);
  }

}
