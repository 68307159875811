import { OrganisationService } from './../../services/organisation.service';
// Used to show dynamically classed text defined by back end

import { UiClasses } from '@sb-shared/types/ui-classes';
import { Component, Input, SimpleChanges } from '@angular/core';
import { Placement } from '@ng-bootstrap/ng-bootstrap';
import { SbIcon } from '@sb-shared/models/UI/sb-icon';

@Component({
  selector: 'sb-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {

  @Input() message = '';
  @Input() textClass: UiClasses;
  @Input() customClasses = '';

  // Basic icon support - name only.
  @Input() iconName: string;

  // More icon support, takes precedence over 'iconName'.
  @Input() icon: SbIcon;
  @Input() tooltipText: string;
  @Input() tooltipPlacement: Placement;
  @Input() isCurrency: boolean;
  currencySymbol: string;
  computedClass?: string;

  constructor(private organisation: OrganisationService) { }

  ngOnInit() {
    this.organisation.getCurrentOrganisation().subscribe(organisation => {
      this.currencySymbol = organisation.currencyDisplaySymbol;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.computedClass = '';
    if (this.customClasses) {
      this.computedClass += ` ${this.customClasses}`;
    }
    if (this.isCurrency) {
      this.computedClass += ' text-primary semi-bold';
    }
    if (this.textClass) {
      this.computedClass += ` text-${this.textClass}`;
    }

    // Icon name overrides icon.
    if (this.iconName) {
      this.icon = {
        name: this.iconName,
        tooltip: this.message
      }
    }
  }
}
