import { EveryBuddy } from '@sb-core/window-properties';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

/**
 * Converts a given date to the specified time zone and applies the provided function.
 * @param fn - The function to apply to the zoned date.
 * @param date - The date to convert to a zoned date. If not provided, the current date will be used.
 * @param options - Additional options to pass to the function.
 * @param tz - The time zone to convert the date to. Defaults to EveryBuddy.CurrentOrganisationTimeZone.
 * @returns The result of applying the function to the zoned date.
 */
export const getZoned = (fn: (...args) => Date, date = null, options = null, tz = EveryBuddy.CurrentOrganisationTimeZone) => {
  const zonedDate = toZonedTime(date, tz);
  const zonedFn = date === null ? fn() : fn(zonedDate, options);

  return fromZonedTime(zonedFn, tz);
}
