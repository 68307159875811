import { apis, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CcaSignUpService {

  config: HttpRequestSettings  = {
    api: apis.CcaSignUp,
    addHashId: true
  };

  constructor(private http: HttpWebApiService) {}


  getActiveCcaTypeMenuItems() {
    return this.http.get('GetActiveCcaTypeMenuItems', this.config);
  }

  getLocationTypes() {
    return this.http.get('GetLocationTypes', this.config);
  }

  getCcaForms() {
    return this.http.get('GetCcaForms', this.config);
  }

  getNonRegistrationForms() {
    return this.http.get('GetNonRegistrationForms', this.config);
  }

  // getSignUp(signUpId: number) {
  //     if (signUpId === null)
  //     {
  //         return null;
  //     }
  //     return this.http.get('GetSignUp', {
  //       api: apis.WebCcaSignUp,
  //       params: { signUpId: signUpId }
  //     })
  // }
}
