
angular.module('tuitionFeesModule')
    .component('invoiceView', {
        bindings: {
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-view.template.html',
        controller: class InvoiceViewCtrl {

            // Dependencies
            invoiceService: any;
            billingCycleService: any;
            searchFilterTypes: any;

            // Variables
            searchParameters: any;
            sortType: string = 'number';
            sortReverse: boolean = false;
            billingCycles: any[] = [];
            invoices: any[] = [];
            paymentMethods: any[] = [];
            totalInvoices: number = -1;
            currentPage: number = 1;
            pageSize: number = 10;
            currentInvoice: any;
            pageSizes = [5, 10, 20, 50, 100, 200];
            isLoading: boolean = true;
            currencyDisplaySymbol: string;
            currencyExponent: number;
            paymentStatuses: any[] = [];
            invoiceStatusEnumLabels: any[] = [];
            isOverdueReminderCheckAll: boolean = false;
            payerTypes = [
                {
                    id: 0,
                    name: 'SB_All',
                    includeFamily: true,
                    includeCorporate: true
                },
                {
                    id: 1,
                    name: 'SB_Family',
                    includeFamily: true,
                    includeCorporate: false
                },
                {
                    id: 2,
                    name: 'SB_Corporate',
                    includeFamily: false,
                    includeCorporate: true
                }
            ];


            selectedPayerTypeId = 0;

            static $inject = ['$window', 'invoiceService', 'billingCycleService', 'searchFilterTypes'];

            constructor($window, invoiceService, billingCycleService, searchFilterTypes) {
                this.invoiceService = invoiceService;
                this.billingCycleService = billingCycleService;
                this.searchFilterTypes = searchFilterTypes;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;

                this.resetSearchParameters();

                this.paymentStatuses = this.invoiceService.getInvoiceStatuses();
                this.invoiceStatusEnumLabels = this.invoiceService.getInvoiceStatusEnumLabels();
            }

            resetSearchParameters() {
                this.selectedPayerTypeId = 0;
                this.searchParameters = {
                    search: '',
                    billingCycleId: null,
                    paymentStatus: 0,
                };
                this.setSearchParameters();
            }

            setSearchParameters() {
                this.searchParameters.includeFamily = this.payerTypes.find(x => x.id == this.selectedPayerTypeId).includeFamily;
                this.searchParameters.includeCorporate = this.payerTypes.find(x => x.id == this.selectedPayerTypeId).includeCorporate;
                this.searchParameters.pageNumber = this.currentPage;
                this.searchParameters.pageSize = this.pageSize;
                this.searchParameters.orderBy = this.sortType;
                this.searchParameters.isOrderDescending = this.sortReverse;
            }

            isOverduefilterSelected() {
                return this.paymentStatuses.some(s => s.id == this.searchParameters.paymentStatus && s.name.includes('_Overdue'));
            }

            overdueReminderToggleAll(override: boolean = false) {

                if (override) {
                    this.isOverdueReminderCheckAll = false;
                }
                else {
                    this.invoices = this.invoices.map(i => {
                        i.sendOverdueReminder = this.isOverdueReminderCheckAll;
                        return i;
                    });
                }
            }

            showInvoice(item)
            {
                this.currentInvoice = item;
            }

            isAnySelectedForOverdueReminder() {
                return this.invoices.some(i => i.sendOverdueReminder == true);
            }

            $onInit() {
                this.getInvoices();

                this.billingCycleService.getCycles().then(res => {
                    if (res.data) {
                        this.billingCycles = res.data.cycles.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0);
                        this.billingCycles.unshift({
                            id: null,
                            name: 'SB_All'
                        });
                    }
                });

                this.invoiceService.getPaymentMethods().then(res => {
                    if (res.data) {
                        this.paymentMethods = res.data;
                    }
                });
            }

            setPageSize() {
                // reset to page 1
                this.currentPage = 1;
                this.getInvoices();
            }

            getInvoices() {
                this.isLoading = true;
                this.setSearchParameters();
                this.invoiceService.getInvoices(this.searchParameters).then(res => {
                    if (res.data) {
                        this.invoices = res.data;
                        this.totalInvoices = res.totalRecords;
                        this.setupOverdueReminderData();
                    }
                    this.isLoading = false;
                });
            }

            sendInvoiceOverduePaymentReminders() {
                const selectedInvoiceIds: number[] = this.invoices.filter(i => i.sendOverdueReminder == true).map(i => i.id);

                if (selectedInvoiceIds.length > 0) {
                    this.isLoading = true;
                    this.invoiceService.sendInvoiceOverduePaymentReminders(selectedInvoiceIds)
                        .finally(() => {
                            this.isLoading = false;
                            this.getInvoices();
                        })
                }
            }

            setupOverdueReminderData() {
                this.invoices = this.invoices.map(i => {
                    i.sendOverdueReminder = false;
                    return i;
                });
                this.isOverdueReminderCheckAll = false;
            }

            onReset() {
                this.resetSearchParameters();
                this.getInvoices();
            };

            close() {
                this.currentInvoice = null;
            }
        }
    });
