angular.module('settingsModule')
    .service('fariaOneService', class FariaOneService {

        httpCoreApi: any;
        baseUrl: string = 'OrganisationSSO';
        settings: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            };
        }

        getSSO() {
            return this.httpCoreApi.get(this.baseUrl, this.settings);
        }

        turnOnSSO(provider: string) {
            return this.httpCoreApi.post(this.baseUrl, {
                ssoSchemeName: provider
            }, this.settings);
        }

        turnOffSSO(provider: string) {
            return this.httpCoreApi.delete(this.baseUrl, {
                ssoSchemeName: provider
            }, this.settings);
        }

    });
