angular.module('shared.filters.decoded', [])
    .filter('decoded', function () {
        "use strict";

        var e = null; //only init as-needed, and keep around
        var cache = [];

        function htmlDecode(input) {
            if (cache[input]) {
                //console.log('using cached', input, cache[input])
                return cache[input];
            }

            if (e === null)
                e = document.createElement('div');

            e.innerHTML = input;
            var result = e.childNodes[0].nodeValue;

            cache[input] = result;
            return result;
        }

        return function (input) {
            return htmlDecode(input);
        }
    });