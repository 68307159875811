import { formatDate } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { asUtc, getOrgTimezoneText } from '@sb-helpers';
import { orgDateTimeToUtc } from 'helpers/date-time';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DateTimeInterceptor implements HttpInterceptor {
  whitelist: { [key: string]: string[] } = {
    // case-sensitive, keep alphabetical please!
    '/Balance/GetTransactions': ['eventsFrom', 'eventsTo', 'eventDateUtc'],
    '/BalanceAdmin/ChaseEvent': ['eventsFrom', 'eventsTo', 'eventDateUtc'],
    '/BalanceAdmin/GetEvents': ['fromUtc'],
    '/BalanceAdmin/GetEventTransactions': ['eventsFrom', 'eventsTo', 'eventDateUtc'],
    '/BalanceAdmin/GetPersonTransactions': ['eventsFrom', 'eventsTo', 'eventDateUtc'],
    '/BalanceAdmin/GetTransactionSummary': ['eventsFrom', 'eventsTo', 'eventDateUtc'],
    '/CcaAvailable/CancelBooking': ['fromUtc', 'toUtc'],
    '/CcaAvailable/GetSignUp': ['openDateUtc', 'closeDateUtc'],
    '/CcaAvailable/GetSignUpBookedEvents': ['fromUtc', 'toUtc', 'remainingDatesUtc'],
    '/CcaAvailable/GetSignUpEvents': ['fromUtc', 'toUtc', 'remainingDatesUtc'],
    '/CcaAvailable/GetSignUps': ['openDateUtc', 'closeDateUtc'],
    '/CcaAvailable/ToggleBooking': ['fromUtc', 'toUtc', 'remainingDatesUtc'],
    '/CcaAvailable/ToggleBookings': ['fromUtc', 'toUtc', 'remainingDatesUtc', 'startDateTimeUtc'],
    '/CcaAvailable/SaveBookings': ['startDateTimeUtc'],
    '/CcaSignUp/GetCcaStaffAttendees': ['fromUtc', 'toUtc'],
    '/CcaSignUp/GetSignUpBookedEvents': ['fromUtc', 'toUtc'],
    '/CcaSignUp/GetSignUpEvents': ['fromUtc', 'toUtc'],
    '/CcaSignUp/GetSignUpEventsForPupil': ['fromUtc', 'toUtc', 'remainingDatesUtc'],
    '/CcaSignUp/GetSignUpEventsTimeSlotted': ['fromUtc', 'toUtc'],
    '/CcaSignUp/GetSignUps': ['signUpsFrom', 'signUpsTo', 'openDateUtc', 'closeDateUtc'],
    '/CcaSignUp/GetSignUpsAvailableToCopy': ['openDateUtc', 'closeDateUtc'],
    '/CcaSignUp/GetStaffWithAllEvents': ['fromUtc', 'toUtc'],
    '/CcaSignUp/GetStaffWithBookedEvents': ['fromUtc', 'toUtc'],
    '/CcaSignUp/PopulateGroupCcaNamesAsync': ['LastEventDateUtc'],
    '/CcaSignUp/SaveAndFetchSignUpGroup': ['LastEventDateUtc'],
    '/CcaSignUp/SaveBookings': ['fromUtc', 'toUtc', 'remainingDatesUtc', 'startDateTimeUtc'],
    '/CcaSignUp/SaveSignUpGroup': ['LastEventDateUtc'],
    '/CcaSignUp/SaveSignUpGroupEventAndReturnSavedEntity': ['signUpOpen', 'signUpClose'],
    '/CcaSignUp/StaffDeletePupilEvent': ['fromUtc', 'toUtc'],
    '/CcaSignUp/GetSignUpGroupsWithEventsForDeletionOrCancellation': ['eventDateUtc', 'eventEndDateUtc'],
    '/Reception/GetAttendanceGroups': ['eventsFrom', 'eventsTo'],
    '/Reception/GetEventSummary': ['eventsFrom', 'eventsTo', 'fromUtc', 'toUtc', 'eventsFromUtc', 'eventsToUtc'],
    '/Reception/GetLocationEvents': ['fromUtc', 'toUtc'],
    '/Reception/GetRegGroupNextEventData': ['fromDate', 'eventTimeUtc'],
    '/Reception/GetNextEventData': ['fromDate', 'eventTime', 'eventTimeUtc'],
    '/webapi/WebBalanceAdministration/GetGroupSummariesAsync': ['from', 'to'],
    '/webapi/WebBalanceAdministration/GetGroupFeeSummariesAsync': ['from', 'to', 'minEventDateUtc'],
    '/webapi/WebBalanceAdministration/GetEventSummariesForGroupAsync': ['from', 'to', 'eventDateUtc'],
    '/webapi/WebBalanceAdministration/GetPupilSummariesAsync': ['from', 'to'],
    '/webapi/WebBalanceAdministration/GetChargeTransactionsForAccount': ['eventFromUtc'],
    '/webapi/WebCalendarEventCreationRule/GetCalendarEventCreationRulesForSignUp': ['signUpOpenUtc', 'signUpCloseUtc'],
    '/webapi/WebCalendarEventCreationRule/GetCalendarEventCreationRulesForSignUpToPublish': [
      'signUpOpenUtc',
      'signUpCloseUtc'
    ],
    '/webapi/WebCalendarEventView/GetEventDetail': [
      'fromUtc',
      'toUtc',
      'meetingAtUtc',
      'pickupAtUtc',
      'signUpOpenUtc',
      'signUpCloseUtc',
      'routeStopDateTimeUtc',
      'consentDeadlineUtc'
    ],
    '/webapi/WebCcaPreferenceTeam/GenerateAdditionalEventsForActiveGroupsDatesAndDays': ['FromDateUtc', 'ToDateUtc'],
    '/webapi/WebCcaSignUp/GetSignUp': ['openDateUtc', 'closeDateUtc'],
    '/webapi/WebCcaSignUp/SaveSignUp': ['openDate', 'closeDate'],
    '/webapi/WebChangeRequest/GetChangeRequest': [
      'calendarEventStartDateTimeUtc',
      'createdDateTimeUtc',
      'stopDateTimeUtc'
    ],
    '/webapi/WebChangeRequest/GetOptions': ['calendarEventStartDateTimeUtc', 'stopDateTimeUtc'],
    '/webapi/WebDiary/GetAll': ['fromDate', 'toDate', 'eventDateUtc', 'eventEndDateUtc', 'routeStopDateTimeUtc'],
    '/webapi/WebDiary/GetInvites': ['eventDateUtc', 'eventEndDateUtc', 'consentDeadlineUtc'],
    '/webapi/WebDiary/GetAllForTeam': ['subFromDate', 'subToDate', 'eventDateUtc', 'eventEndDateUtc'],
    '/webapi/WebEventRegisters/GetRegisterData': [
      'fromUtc',
      'toUtc',
      'meetingAtUtc',
      'transportAttendeeRouteStopTimeUtc',
      'timeStampUtc',
      'absenteeAlertSentUtc'
    ],
    '/webapi/WebEventRegisters/GetOfflineRegistersData': [
      'fromUtc',
      'toUtc',
      'meetingAtUtc',
      'eventStartTimeUtc',
      'eventEndTimeUtc',
      'transportAttendeeRouteStopTimeUtc',
      'absenteeAlertSentUtc'
    ],
    '/webapi/WebEventRegisters/GetRegisterNextEventData': ['eventStartTimeUtc', 'eventEndTimeUtc'],
    '/webapi/WebEventRegisters/GetRegisterPreviousEventData': ['eventStartTimeUtc', 'eventEndTimeUtc'],
    '/webapi/WebEventRegisters/SaveRegisterAttendeeAsync': ['registerUpdatedDateTimeUtc'],
    '/webapi/WebEvents/EventsDelete': ['fromUtc', 'toUtc', 'meetingAtUtc', 'signUpOpenUtc', 'signUpCloseUtc'],
    '/webapi/WebEvents/GetDetails': ['fromUtc', 'toUtc', 'meetingAtUtc', 'signUpOpenUtc', 'signUpCloseUtc'],
    '/webapi/WebEvents/GetEventBasics': ['fromUtc'],
    '/webapi/WebEvents/GetStudentsForNotifyOnlyEvent': ['confirmedTimestampUtc', 'lastUpdatedUtc'],
    '/webapi/WebEvents/OtherDeletableEventsInSeries': ['fromUtc', 'toUtc'],
    '/webapi/WebEvents/Restore': ['fromUtc', 'toUtc', 'meetingAtUtc', 'signUpOpenUtc', 'signUpCloseUtc'],
    '/webapi/WebEvents/Update': [
      'from',
      'to',
      'meetingAt',
      'signUpOpen',
      'signUpClose',
      'fromUtc',
      'toUtc',
      'meetingAtUtc',
      'signUpOpenUtc',
      'signUpCloseUtc'
    ],
    '/webapi/WebEvents/UpdateLinked': [
      'fromUtc',
      'toUtc',
      'meetingAtUtc',
      'signUpOpenUtc',
      'signUpCloseUtc',
      'signUpOpen',
      'signUpClose'
    ],
    '/webapi/WebEvents/getConsentEventInvitesData': ['inviteTimestampUtc', 'lastUpdatedUtc'],
    '/webapi/WebGroup/GetFeeOnlyEventsForGroup': ['createdDateUtc'],
    '/webapi/WebGroup/GetGroupsWithChargeEventsByPartialNameByDateRange': ['eventFrom', 'eventTo'],
    '/webapi/WebGroup/GetChargeEventsByGroup': ['eventDateUtc'],
    '/webapi/WebMessageViewer/GetAdminInbox': ['saUtc'],
    '/webapi/WebMessageViewer/GetAdminInboxNewOnly': ['saUtc'],
    '/webapi/WebMessageViewer/GetSpecificMessage': ['saUtc', 'roUtc', 'soUtc', 'aoUtc', 'coUtc'],
    '/webapi/WebMessageViewer/GetPersonInboxUnfiltered': ['saUtc', 'roUtc', 'soUtc', 'aoUtc', 'coUtc'],
    '/webapi/WebMessageViewer/GetPersonInbox': ['saUtc', 'roUtc', 'soUtc', 'aoUtc', 'coUtc'],
    '/webapi/WebMessageViewer/StarMessage': ['readOnUtc', 'starredOnUtc', 'acknowledgedOnUtc', 'archivedOnUtc'],
    '/webapi/WebMessageViewer/ArchiveMessage': ['readOnUtc', 'starredOnUtc', 'acknowledgedOnUtc', 'archivedOnUtc'],
    '/webapi/WebOrganisationChangeRequest/GetPendingChangeRequestsForApproval': [
      'calendarEventStartDateTimeUtc',
      'createdDateTimeUtc',
      'lastModifiedDateTimeUtc'
    ],
    '/webapi/WebOrganisationChangeRequest/GetFutureChangeRequestsApprovedOrRejected': [
      'calendarEventStartDateTimeUtc',
      'createdDateTimeUtc',
      'lastModifiedDateTimeUtc'
    ],
    '/webapi/WebOrganisationChangeRequest/GetTransportDiaryEventsByPupilName': [
      'eventDateUtc',
      'eventEndDateUtc',
      'routeStopDateTimeUtc'
    ],
    '/webapi/WebOrganisationTransportMethod/GetMethods': ['createDateTimeUtc'],
    '/webapi/WebOrganisationTransportRoutes/GetRoutes': ['createDateTimeUtc'],
    '/webapi/WebOrganisationTransportStops/GetStops': ['createDateTimeUtc'],
    '/webapi/WebOrganisationTransportTimetableCalendar/GetTransportTimetableCalendars': ['fromDateUtc', 'toDateUtc'],
    '/webapi/WebOrganisationTransportTimetableCalendar/GetTransportTimetableCalendar': ['fromDateUtc', 'toDateUtc'],
    '/webapi/WebOrganisationTransportTimetableCalendar/AddTransportTimetableCalendar': ['fromDate', 'toDate'],
    '/webapi/WebOrganisationTransportTimetableCalendar/EditTransportTimetableCalendar': ['fromDate', 'toDate'],
    '/webapi/WebOrganisationTransportTimetableCalendar/ValidateDateRangeForOverlaps': ['fromDate', 'toDate'],
    '/webapi/WebPerson/GetPersonData': ['lastAccessedUtc'],
    '/webapi/WebPersonPicker/GetPeopleWithFilters': ['liUtc'],
    '/webapi/WebTasks/GetAllForCaseId': ['createdUtc', 'deadlineUtc'],
    '/webapi/WebTasks/GetAllTasks': ['startDate', 'endDate', 'createdUtc', 'deadlineUtc'],
    '/webapi/WebTasks/GetById': ['createdUtc', 'deadlineUtc'],
    '/webapi/WebTasks/MarkCompleted': ['created', 'createdUtc', 'deadline', 'deadlineUtc'],
    '/webapi/WebTasks/Save': ['created', 'deadline', 'createdUtc', 'deadlineUtc'],
    '/webapi/WebTeam/GetTeamAttendance': ['eventsFrom', 'eventsTo', 'fromUtc'],
    '/webapi/WebStaff/GetStaffForSchedule': ['start', 'end'],
    '/webapi/WebStaff/GetStaffMemberSchedule': ['start', 'end', 'eventDateUtc', 'eventEndDateUtc']
  };

  regexWhitelist: { rx: RegExp; props: string[] }[] = [
    {
      rx: /\/api\/Admin\/\d+\/Balance\/GetMoniesReceived/,
      props: ['transFrom', 'transTo', 'transFromDateUtc', 'transToDateUtc', 'transactionDateUtc', 'eventDateUtc']
    },
    {
      rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Batches/,
      props: ['createdDateTimeUtc', 'createdDateTime', 'orderBy']
    },
    {
      rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Batch\/\d+/,
      props: ['createdDateTimeUtc']
    },
    {
      rx: /\/api\/Admin\/\d+\/BillingCycle\/Cycle\/\d+\/Invoices/,
      props: ['dueDate']
    },
    {
      rx: /\/api\/Admin\/\d+\/Invoice\/Invoices/,
      props: ['dueDateUtc', 'invoiceDateUtc', 'invoiceDate', 'dueDate', 'orderBy']
    },
    {
      rx: /\/api\/Admin\/\d+\/Invoice/,
      props: ['dateTimeUtc']
    },
    {
      rx: /\/api\/User\/\d+\/Sports\/Fixtures\/\d+\/Details/,
      props: ['eventDateUtc', 'eventEndDateUtc']
    },
    {
      rx: /\/api\/User\/\d+\/Sports\/Fixtures\/\d+\/Report/,
      props: ['lastPublishedUtc']
    },
    {
      rx: /\/api\/User\/\d+\/Sports\/Fixtures/,
      props: ['from', 'to', 'fromUtc']
    },
    {
      rx: /\/api\/Admin\/\d+\/Events\/.+/,
      props: ['fromDate', 'toDate', 'repeatEnd', 'meetingTime', 'pickupTime', 'consentDeadline', 'consentDateTimeUtc']
    }
  ];

  whitelistKeys = Object.keys(this.whitelist);
  timezoneOffset?: string;

  constructor() {
    // Apply whitelist for Angular12 app
    if (window.EveryBuddy.IsAngular12) {
      for (const key in this.whitelist) {
        this.whitelist[window.EveryBuddy.WebAPI + key] = this.whitelist[key];
      }
    }
  }

  stripUrl(url: string) {
    try {
      const uri = new URL(url);
      return uri.pathname;
    } catch {
      if (url.indexOf('?') >= 0) {
        return url.split('?')[0];
      }

      return url;
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    req = this.request(req);

    return next.handle(req).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          event = event.clone(this.response(event));
        }
        return event as HttpEvent<unknown>;
      })
    );
  }

  // If request url is matched exactly or on regex pattern convert designated date properties in request query params
  // or request body from local time to utc.
  request(config: HttpRequest<unknown>): HttpRequest<unknown> {
    // Remove query string (and for core API, origin)
    const url = this.stripUrl(config.url);
    // Handle url params
    const params = {};
    config.params.keys().forEach(key => {
      params[key] = config.params.get(key);
    });

    const updateParams = (whitelist: string[]) => {
      const newParamsObject = this.convertToUTC(whitelist, params);
      const newParams = new HttpParams({ fromObject: newParamsObject as unknown as { [key: string]: string } });
      config = config.clone({
        params: newParams
      });
    };
    const updateBody = (whitelist: string[]) => {
      const newBodyObject = this.convertToUTC(whitelist, config.body as object);
      config = config.clone({
        body: newBodyObject
      });
    };
    // Conversions to query params and request data
    if (this.whitelistKeys.indexOf(url) > -1) {
      const whiteListUrl = this.whitelist[url];
      updateParams(whiteListUrl);
      if (config.body) {
        updateBody(whiteListUrl);
      }
    } else {
      const regexMatch = this.regexWhitelist.find(x => x.rx.test(url));
      if (regexMatch) {
        updateParams(regexMatch.props);
        if (config.body) {
          updateBody(regexMatch.props);
        }
      }
    }
    return config;
  }

  // If url is matched exactly or on regex pattern convert designated date properties on response body from utc date to local date
  // (based on organisation time zone).
  response(response: HttpResponse<unknown>) {
    const url = this.stripUrl(response.url);

    if (this.whitelistKeys.indexOf(url) > -1) {
      this.convertToLocal(this.whitelist[url], response.body as object);
    } else {
      const regexMatch = this.regexWhitelist.find(x => x.rx?.test(url));
      if (regexMatch) {
        this.convertToLocal(regexMatch.props, response.body as object);
      }
    }

    // response.headers.pragma= undefined;

    return response;
  }

  // Convert all properties on object that exist in keys from utc date to local date (based on organisation time zone).
  // Sets the new local date as the original property name minus the "Utc" suffix and preserves the original utc property.
  convertToLocal(keys: string[], input: object) {
    if (!input || typeof input !== 'object') {
      return;
    }

    for (const [key, value] of Object.entries(input)) {
      if (keys.indexOf(key) > -1) {
        // Convert UTC to local time for the current organisation.
        if (input[key] === null) {
          input[key.slice(0, -3)] = null;
          return;
        }

        // handle arrays of dates
        if (Array.isArray(input[key])) {
          input[key.slice(0, -3)] = input[key].map(date => this.utcToOrgTimeString(date));
        } else {
          input[key.slice(0, -3)] = this.utcToOrgTimeString(input[key]);
        }
      } else if (typeof value === 'object') {
        this.convertToLocal(keys, value);
      }
    }
  }

  // Convert all properties on object that exist in keys from local date to utc date.
  // Sets the new utc date as existingPropertyUtc.
  convertToUTC(keys: string[], input: object): object {
    if (!input || typeof input !== 'object') {
      return input;
    }

    for (const [key, value] of Object.entries(input)) {
      if (keys.indexOf(key) > -1) {
        if (key == 'orderBy') {
          // special case for orderBy, do not convert property name to orderByUtc
          // instead, append Utc to property value if that value is in whitelist.
          const orderBy = input['orderBy'];
          if (orderBy && keys.indexOf(orderBy) > -1) {
            input['orderBy'] = orderBy + 'Utc';
          }
          return input;
        }

        // Convert local time to UTC for the current organisation.
        if (value === null) {
          input[key + 'Utc'] = null;
          delete input[key];
          return input;
        }

        let isoDateString = value;
        let utcDate = new Date(isoDateString);
        utcDate = orgDateTimeToUtc(value);

        isoDateString = utcDate.toISOString();

        if (isoDateString.endsWith('Z')) {
          isoDateString = isoDateString.slice(0, -1);
        }

        input[key + 'Utc'] = isoDateString;
        delete input[key];
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        input[key] = this.convertToUTC(keys, value);
      }
    }

    return input;
  }

  private utcToOrgTimeString(inputDate: string, isRevert = false) {
    inputDate = asUtc(inputDate);
    let offset = getOrgTimezoneText(new Date(inputDate));

    if (isRevert) {
      const init = offset[0];
      const sign = init === '-' ? '+' : '-';
      offset = sign + offset.slice(1);
    }

    return formatDate(inputDate, "yyyy-MM-dd'T'HH:mm:ss", 'en-US', offset);
  }
}
