'use strict';

angular.module('ccasignupModule')
    .component("ccaCustomData", {
        templateUrl: '/Scripts/app/ccaSignUp/Shared/cca-custom-data.template.html',
        bindings: {
            selectedSignUp: '<'
        },
        controller: class CcaCustomDataCtrl {
            selectedSignUp: any;
        }
    });
