
'use strict';

angular.module('ccasignupModule')
    .component('ccaGroups', {
        templateUrl: '/Scripts/app/ccaSignUp/Details/cca-signup-groups.template.html',
        controller: [
        '$scope',
        '$state',
        '$uibModal',
        '$timeout',
        '$location',
        '$anchorScroll',
        'cachedLookupService',
        'dateTextService',
        'personPickerService',
        'signUpRepository',
        'simpleAlertService',
        'teamService',
        'ccaService',
        'teamStatusEnum',
        'arrayService',
        'searchFilterTypes',
        function (
            $scope,
            $state,
            $uibModal,
            $timeout,
            $location,
            $anchorScroll,
            cachedLookupService,
            dateTextService,
            personPickerService,
            signUpRepository,
            simpleAlertService,
            teamService,
            ccaService,
            teamStatusEnum,
            arrayService,
            searchFilterTypes)
        {
            $scope.signUp = null;
            $scope.searchFilterTypes = searchFilterTypes;

            var signUpId = $state.params.signUpId;

            $scope.sortType = 'title'; // set the default sort type
            $scope.sortReverse = false; // set the default sort order

            // Variables used in order to restore pagination to previously viewed page
            $scope.initialPage = null; // when controller is loading, jumps to this page.
            $scope.returnPage = null; // page which should be shown on next load of controller.

            $scope.statuses = arrayService.generateOptions([
                { id: 0, name: 'SB_Available_for_Signup' },
                { id: 1, name: 'SB_Partial' },
                { id: 2, name: 'SB_Complete' },
                { id: 3, name: 'SB_CcaStatus_On_Hold' },
                { id: 4, name: 'SB_CcaStatus_Auto_Complete' },
                { id: 5, name: 'SB_CcaStatus_Archived' },
                { id: 6, name: 'SB_CcaStatus_Active' },
                { id: 7, name: 'SB_CcaStatus_Extending' }
            ], true);

            $scope.incompleteGroupsOpen = true;
            $scope.liveGroupsOpen = true;

            $scope.applyFilters = () => {
                $scope.filterCount = Object.values($scope.filter).filter((val) => {
                    return val !== undefined && typeof val === 'number';
                }).length;

                $scope.filteredGroups = $scope.groups.filter(group => $scope.groupsFilter(group));

                $scope.emptyGroups = $scope.filteredGroups.filter(group => group.eventCount === 0);

                $scope.nonEmptyGroups = $scope.filteredGroups.filter(group => group.eventCount > 0);
            };

            $scope.resetFilters = () => {
                $scope.filter = {};
                $scope.applyFilters();
                $state.params.searchData.filter = {};
            };

            $scope.init = () => {

                // Set search filters from previous search when coming from group
                $scope.filter = {...$state.params.searchData.filter};

                /* Need to add this, otherwise if the user clicks another group
                    on the same page they won't be taken back to the page the
                    second time */
                $scope.initialPage = $state.params.searchData.currentPage || 1;
                $scope.returnPage = $scope.initialPage;

            };

            $scope.init();

            $scope.groupsFilter = item => {

                if ($scope.filter.title && item.title.toLowerCase().indexOf($scope.filter.title.toLowerCase()) === -1) {
                    return false;
                }

                if ($scope.filter.organiserId !== undefined && item.organiserId !== $scope.filter.organiserId) {
                    return false;
                }

                if ($scope.filter.dayofWeek !== undefined && item.dayofWeek !== $scope.filter.dayofWeek) {
                    return false;
                }

                if ($scope.filter.homeLocationId !== undefined && item.homeLocationId !== $scope.filter.homeLocationId) {
                    return false;
                }

                if ($scope.filter.statusId !== undefined && item.statusId !== $scope.filter.statusId) {
                    return false;
                }

                return true;
            };

            $scope.onChangeFilter = () => {

                if (JSON.stringify($scope.filter) !== JSON.stringify($state.params.searchData.filter)){
                    //Return to first page if filter changed from previous search
                    $scope.initialPage = $scope.returnPage || 1;
                    $state.params.searchData.filter = [];
                }

                $state.params.searchData.filter = $scope.filter;
                $timeout(() => {
                    $scope.applyFilters();
                });
            };

            $scope.refreshData = () => {
                signUpRepository
                    .getSignUp(signUpId)
                    .then((signUp) => {
                        $scope.signUp = signUp;
                        $scope.titleBarButtons = [
                            {
                                message: 'SB_Copy',
                                buttonClass: 'primary',
                                iconName: 'copy',
                                buttonId: 'post-button',
                                action: $scope.copyGroup,
                                xsIconOnly: true,
                                isDisabled: $scope.signUp && $scope.signUp.status === 3
                            },
                            {
                                message: 'SB_Add',
                                buttonClass: 'primary',
                                iconName: 'plus',
                                buttonId: 'post-button',
                                action: $scope.newGroup,
                                xsIconOnly: true
                            }
                        ];
                    });

                signUpRepository
                    .getSignUpGroups(signUpId)
                    .then((signUpGroups) => {
                        $scope.groups = signUpGroups;
                        $scope.applyFilters();

                        const formattedGroups = $scope.groups.map(group => {
                            return {
                                ...group,
                                organiserFormattedName: group.organiserName.replace('(', '').replace(')', ''),
                                dayofWeekName: dateTextService.getShortDayText(group.dayofWeek)
                            };
                        });

                        $scope.organisers = arrayService.generateOptions(formattedGroups, true, 'organiserId', 'organiserFormattedName');
                        $scope.daysOfWeek = arrayService.generateOptions(formattedGroups, true, 'dayofWeek', 'dayofWeekName');
                        $scope.locations = arrayService.generateOptions(formattedGroups, true, 'homeLocationId', 'location');

                        signUpGroups.forEach((signUpGroup, index) => {
                            signUpGroup.availabilitySummary = ccaService.getGroupAvailabilitySummary(signUpGroup);
                        });

                        $timeout(() => {
                            if ($scope.groups && $state.params.searchData.groupId){
                                $location.hash('group-' + $state.params.searchData.groupId);
                                $anchorScroll.yOffset = 100;
                                $anchorScroll();
                            }
                            // Go to group that's just been viewed/edited
                        });
                    });
            };

            cachedLookupService.getSystemSetting('CCASignUp_DashboardGroups_PageSize')
                .then((data) => {
                    if (data === -1) {
                        $scope.pageSize = 100;
                    }
                    else {
                        $scope.pageSize = data;
                    }
                })
                .catch(() => {
                    $scope.pageSize = 100;
                });

            $scope.onChangePageSize = (value) => {
                $scope.pageSize = value;
                cachedLookupService.saveUserSettings({ 'CCASignUp_DashboardGroups_PageSize': $scope.pageSize });

                //Return to first page
                $scope.initialPage = 1;
                $scope.returnPage = $scope.initialPage;
                $scope.resetFilters();
            };

            $scope.refreshData();

            $scope.newGroup = () => {
                $state.go('ccaDetails.ccaGroupManage');
            };

            $scope.copyGroup = () => {
                $state.go('ccaDetails.duplicategroups', { signUpId: signUpId });
            };

            $scope.manageLiveGroupPupils = (signUpGroup) => {

                // load the current team member ids
                teamService.getPersonIdsInTeam(signUpGroup.id)
                    .then((data) => {

                        var selectedIds = data;

                        var modalInstance = personPickerService.openMultiPersonPicker({
                            selectedPersonIds: selectedIds,
                            defaultClubId: 0,
                            defaultTeamId: 0,
                            okButtonText: 'SB_Save',
                            includeStaff: false,
                            includePupils: true,
                            hideGroupFilter: false,
                            hideYearFilter: false,
                            hideGenderFilter: false,
                            hideRemove: false,
                            filterOutExternalStaff: false,
                            selectedGroupId: signUpGroup.id,
                            titleDetail: signUpGroup.title
                        });

                        modalInstance.result
                            .then(
                                function (selectedIds) {
                                    var pleaseWaitModalInstance = $uibModal.open({
                                        animation: $scope.animationsEnabled,
                                        backdrop: 'static', // prevent modal from closing on backdrop click
                                        templateUrl: '/Scripts/app/shared/controllers/please-wait-popup.template.html',
                                        controller: 'pleaseWaitPopupController',
                                        size: 'sm',
                                        scope: $scope,
                                        resolve: {
                                            message: function () { return 'Saving changes, please wait.'; }
                                        }
                                    });

                                    teamService
                                        .postPupilChangesToTeam(signUpGroup.id, selectedIds)
                                        .then(function (data) {
                                            pleaseWaitModalInstance.close();
                                            if (!data.isError) {

                                                // reload team data
                                                $scope.refreshData();

                                            } else {
                                                console.error('problem saving team changes');
                                            }

                                        });
                                },
                                function () {
                                    console.log('dialog cancelled');
                                });

                    });
            };

            $scope.changeStatus = function (signUpGroup, newStatus) {
                //3 = put on hold
                if (3 === newStatus) {
                    simpleAlertService.simpleAlert({
                        title: 'SB_Warning_Allocation_Clear',
                        message: 'SB_Warning_Allocation_Clear_Text',
                        okButtonText: 'SB_OK',
                        cancelButtonText: 'SB_Cancel'
                    }).result
                        .then(function () {
                            $scope.postGroupStatus(signUpGroup, newStatus);
                        });
                } else {
                    $scope.postGroupStatus(signUpGroup, newStatus);
                }

            };

            $scope.postGroupStatus = function (signUpGroup, newStatus) {
                signUpRepository.postGroupStatus($scope.signUp.ccaSignUpId, signUpGroup.id, newStatus)
                    .then(function (data) {
                        if (data === true) {
                            signUpGroup.statusId = newStatus;
                        }
                    });
            };


            $scope.duplicateGroup = function (signUpGroupId) {
                $state.go('ccaDetails.ccaGroupCopy', { signUpGroupId: signUpGroupId, copy: true });
            };

            $scope.addEventsToGroup = function (signUpGroup) {

                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'addEventsToGroup.html',
                    controller: 'AddEventsToGroupModalCtrl',
                    size: 'md',
                    resolve: {
                        signUpTeam: function () { return signUpGroup; }
                    }
                });

                modalInstance.result
                    .then(function (data) {
                        signUpRepository.createAdditionalEventsForActiveGroup(data)
                            .then(function (result) {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Success',
                                    message: 'SB_Successfully_created_additional_events_for_team'
                                });

                                signUpGroup.statusId = teamStatusEnum.Extending;
                            })
                            .catch(function (err) {
                                simpleAlertService.errorAlert();
                            });
                    })
                    .catch(function () {

                    });
            };

            $scope.deleteGroup = function (signUpGroupId) {

                var modalInstance = simpleAlertService.simpleAlert({
                    title: 'SB_Confirm',
                    message: 'SB_Confirmation_Delete_group',
                    okButtonText: 'SB_OK',
                    cancelButtonText: 'SB_Cancel'
                });

                modalInstance.result.then(function () {
                    $scope.groups = null;
                    signUpRepository.postDeleteGroup(signUpId, signUpGroupId)
                        .then($scope.refreshData)
                        .catch(function () {
                            simpleAlertService.errorAlert();
                        });
                }, function () {
                });
            };

            $scope.editGroup = function (signUpGroupId) {
                $state.go('ccaDetails.ccaGroupEdit', {
                    signUpGroupId: signUpGroupId,
                    searchData: {
                                filter: $scope.filter,
                                pageSize: $scope.pageSize,
                                currentPage: $scope.returnPage,
                                groupId: signUpGroupId
                            }
                        });
                        // Pass search info for when returning from group page
            };

            $scope.ccaGroupDetails = function (signUpGroupId) {
                $state.go('ccaGroupDetails', {
                    signUpId: $state.params.signUpId,
                    signUpGroupId: signUpGroupId,
                    searchData: {
                        filter: $scope.filter,
                        pageSize: $scope.pageSize,
                        currentPage: $scope.returnPage,
                        groupId: signUpGroupId
                    }
                });
            };

            /*Set page to return to (to be passed when navigating to group page)
            without affecting pagination element */
            $scope.setPage = (page) => {
                $scope.returnPage = page;
            };
        }
    ]});

angular.module('ccasignupModule').controller('AddEventsToGroupModalCtrl',
    [
        '$scope',
        '$uibModalInstance',
        '$filter',
        'signUpRepository',
        'signUpTeam',
        function ($scope, $uibModalInstance, $filter, signUpRepository, signUpTeam) {
            var loading = 1;

            $scope.isLoading = function () { return loading > 0; };

            $scope.daysOfWeek = signUpRepository.getDaysOfWeek(true);

            signUpRepository.getTeamsLastEventDate(signUpTeam.id, signUpTeam.ccaSignUpId)
                .then(function (data) {
                    loading--;
                    $scope.minDate = moment.utc(data).startOf('day').toDate()

                    if (moment.utc($scope.minDate).isBefore(moment.utc())) {
                        $scope.extendedEvents.from = new Date();
                    } else {
                        $scope.extendedEvents.from = $scope.minDate;
                    }
                });

            $scope.extendedEvents = {
                from: '',
                to: '',
                startHourMin: { h: signUpTeam.ccaStartHour, m: signUpTeam.ccaStartMin },
                endHourMin: { h: signUpTeam.ccaEndHour, m: signUpTeam.ccaEndMin },
                dayOfWeek: signUpTeam.dayofWeek
            };

            $scope.ok = function () {
                $scope.extendedEvents.teamId = signUpTeam.id;
                $scope.extendedEvents.ccaSignUpId = signUpTeam.ccaSignUpId;

                // Just send over the date for the start and end of creation window
                $scope.extendedEvents.from = $filter('date')($scope.extendedEvents.from, 'yyyy-MM-dd');
                $scope.extendedEvents.to = $filter('date')($scope.extendedEvents.to, 'yyyy-MM-dd');

                $scope.extendedEvents.startTime = $scope.extendedEvents.startHourMin.h + ':' + $scope.extendedEvents.startHourMin.m;
                $scope.extendedEvents.endTime = $scope.extendedEvents.endHourMin.h + ':' + $scope.extendedEvents.endHourMin.m;

                $uibModalInstance.close($scope.extendedEvents);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);