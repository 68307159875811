import { Injectable } from '@angular/core';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { apis, controllerTypes, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import styler from 'stylerjs';
import { Colours, ColoursLegacy } from '../models/colours';
import { CommonChars } from './../globals/common-chars';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root'
})
export class ColoursService {

  settings: HttpRequestSettings = {
    controllerType: controllerTypes.SysAdmin,
    api: apis.Core
  };
  docStyle = document.documentElement.style;

  constructor(
    private http: HttpWebApiService,
    private organisation: OrganisationService
  ) { }

  getOrganisationColours(orgId?: number): Observable<Colours | null> {
    // By org Id, else user org
    return this.http.get('Colour', {...this.settings, orgId: orgId}).pipe(map(data => this.convertColours(data)))
  }

  getCurrentOrganisationColours(): Observable<Colours> {
    return this.organisation.getCurrentOrganisation().pipe(map(org => {
      return {
        primaryColour: `#${org.darkColour}`,
        secondaryColour: `#${org.lightColour}`
      }
    }))
  }

  updateColours(orgId: number, colours: Colours) {

      const data = {
          darkColour: colours.primaryColour,
          lightColour: colours.secondaryColour
      };

      return this.http.put('Colour', data,
      {
          ...this.settings,
          orgId: orgId,
          successMessage: 'SB_Colours_Updated',
          errorMessage: 'SB_Colours_Update_Error'
      });
  }

  setBrowserColours(colours: Colours) {
    this.docStyle.setProperty('--brand-primary', colours.primaryColour);
    this.docStyle.setProperty('--brand-secondary', colours.secondaryColour);
  }

  getBrowserColours(): Colours {
    return {
        primaryColour: this.docStyle.getPropertyValue('--brand-primary'),
        secondaryColour: this.docStyle.getPropertyValue('--brand-secondary')
    }
  }

  colourToHex(colour: string) {
    if (!colour) {
      return '';
    } else if (colour.indexOf(CommonChars.Hash) === 0) {
      return colour;
    }
    return CommonChars.Hash + colour;
  }

  hexToColour(hex: string) {
    // return hex.replaceAll(CommonChars.Hash, CommonChars.Blank);
    return hex.replace(CommonChars.Hash, CommonChars.Blank);
  }

  convertColours(colours: ColoursLegacy) {
      if (!colours) {
          return null
      }
      return {
          primaryColour: this.colourToHex(colours.darkColour),
          secondaryColour: this.colourToHex(colours.lightColour)
      };
  }

  getColourFromClass(className: string) {
    const colour = styler(CommonChars.Period + className).get(['color']).color;
    return colour !== 'rba(255,255,255)' ? colour : null;
  }
}
