angular.module('tuitionFeesModule')
    .component('billingCycle',
        {
            templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/billing-cycle.template.html',
            bindings: {
                onChangeBillingCycleId: '&'
            },
            controller: class BillingCycleCtrl {

                // Dependencies
                $filter: any;
                $state: any;
                $uibModal: any;
                $transitions: any;
                billingCycleService: any;

                // Variables
                subMenuItems: any;
                titleBarButtons: any[];
                billingCycles: any[] = [];
                billingCycle: any;
                billingCycleId: number = 0;
                academicYears: any[] = [];
                currencyDisplaySymbol: string;
                onChangeBillingCycleId: any;
                currencyExponent: number;

                static $inject = ['$filter', '$state', '$uibModal', '$transitions', '$window', 'billingCycleService'];

                constructor($filter, $state, $uibModal, $transitions, $window, billingCycleService) {
                    this.$filter = $filter;
                    this.$state = $state;
                    this.$uibModal = $uibModal;
                    this.$transitions = $transitions;
                    this.billingCycleService = billingCycleService;

                    this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                    this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
                }

                $onInit() {
                    this.loadData();
                }

                loadData() {
                    this.titleBarButtons = [
                        {
                            message: 'SB_Add_Cycle',
                            buttonClass: 'primary',
                            iconName: 'plus',
                            buttonId: 'post-button',
                            action: this.addCycle.bind(this)
                        }
                    ];

                    this.billingCycleService.getAcademicYears().then(res => {
                        if (res.data) {
                            this.academicYears = res.data.years;
                        }
                    })

                    this.loadCycles();
                }

                loadCycles = () => {
                    this.billingCycleService.getCycles().then(res => {
                        if (res.data) {
                            this.billingCycles = res.data.cycles;
                            if (this.billingCycleId > 0) {
                                // clear selection if no longer available (was deleted)
                                this.billingCycle = this.billingCycles.find(x => x.id == this.billingCycleId);
                                if (!this.billingCycle) {
                                    this.billingCycleId = 0;
                                    this.billingCycle = null;
                                }
                            }

                            // default to select first in list
                            if (this.billingCycleId == 0 && this.billingCycles && this.billingCycles.length > 0) {
                                this.billingCycleId = this.billingCycles[0].id;
                                this.billingCycle = this.billingCycles[0];
                            }
                            this.onChangeBillingCycleId({id: this.billingCycleId, refreshFn: this.loadCycles.bind(this)});
                        }
                    });
                }

                addCycle() {
                    this.addEditCycle(false);
                }

                editCycle() {
                    this.addEditCycle(true);
                }

                addEditCycle(isEdit: boolean) {
                    const modalInstance = this.$uibModal.open({
                        animation: true,
                        component: 'addEditBillingCyclePopup',
                        size: 'md',
                        backdrop: 'static',
                        resolve: {
                            isEdit: () => isEdit,
                            billingCycleId: () => isEdit ? this.billingCycleId : 0,
                            academicYears: () => this.academicYears
                        }
                    });

                    modalInstance
                        .result
                        .then((id: number) => {
                            // refresh cycles
                            if (id) {
                                this.billingCycleId = id;
                            }

                            this.loadData();
                        })
                        .catch(() => {
                        });
                }

                onChangeCycle() {
                    this.billingCycle = this.billingCycles.find(x => x.id == this.billingCycleId);

                    // Need to update ng1state with correct cycle id and refresh function.
                    this.onChangeBillingCycleId({id: this.billingCycleId, refreshFn: this.loadCycles.bind(this)});
                }
            }
        });
