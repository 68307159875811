'use strict';

angular.module('balanceAdmin.recordPayment.components.recordPaymentFilter',
    [])
    .component('recordPaymentFilter',
        {
            bindings: {
                filterTitle: '=',
                searchFilters: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/recordPayment/components/record-payment-filter.template.html',
            controllerAs: 'ctrl',
            controller:
                [
                    '$filter',
                    'balanceAdminOldService',
                    'personService',
                    function ($filter, balanceAdminOldService, personService) {

                        this.$onInit = function () {
                        }

                        //this.getPupils = balanceAdminOldService.getPupils;
                        this.getPupils = function (pupilName)
                        {
                            return personService.lookupPupilByName(false, pupilName);
                        }

                        this.getCalendarEvents = function (val) {
                            return balanceAdminOldService.getEvents(val)
                                .then(function (response) {
                                    // format for display in drop-down
                                    return response.map(function (x) {
                                        return {
                                            id: x.id,
                                            title: x.title + ' - ' + $filter('date')(x.from, 'dd MMM yyyy')
                                        };
                                    });
                                });
                        };

                        this.onSelectPupil = function ($item, $model, $label) {
                            this.filterTitle = $label;
                            this.selectedEvent = null; // clear other auto-complete
                            this.searchFilters.eventId = 0;
                            this.searchFilters.personId = $item.id;
                        }.bind(this);

                        this.onSelectEvent = function ($item, $model, $label) {
                            this.filterTitle = $label;
                            this.selectedPupil = null; // clear other auto-complete
                            this.searchFilters.personId = 0;
                            this.searchFilters.eventId = $item.id;
                        }.bind(this);
                    }
                ]
        });