

angular.module('shared.components.sbSummary', [
    'ui.bootstrap',
    'shared.components.sbStackedDate',
    'shared.components.sbButton'
]).component('sbSummary',
    {
        bindings: {
            summaryTitle: '<', // Title text (title property reserved)
            description: '<', // Description text
            backText: '<', // Text for back buttons
            from: '<', // From date
            to: '<', // To date
            status: '<', // Status for badge
            locationName: '<', // Location name
            group: '<', // Group name
            organiser: '<', // Organiser name
            timeString: '<', // Time text to display (string, not a datetime)
            isEvent: '<', // Different styling for event vs container
            isCancelled: '<', // Show alternate badge text/style if is cancelled
            actionText: '<',
            actionIcon: '<',
            actionButtonClass: '<',
            actionDisabled: '<',
            actionHidden: '<',
            onClickAction: '&',
            onClickEdit: '&',
            onClickBack: '&'
        },
        transclude: true,
        templateUrl: '/Scripts/app/shared/components/sb-summary.template.html',
        controller: class SbSummaryCtrl {

            // Dependencies
            $location: any;
            $attrs: any;

            // Bindings
            from: any;
            to: any;
            backText: string;
            isEvent: boolean;
            actionText: string;
            actionIcon: string;
            actionButtonClass: string;
            actionHidden: boolean;
            onClickAction: any;
            onClickEdit: any;
            onClickBack: any;

            // Variables
            show: boolean;
            showAction: boolean;
            showEdit: boolean;
            showBack: boolean;

            static $inject = ['$location', '$attrs'];

            constructor($location, $attrs) {
                this.$location = $location;
                this.$attrs = $attrs;
            }

            $onInit() {
                this.show = !this.$location.search().hideNavButtons;
                this.showEdit = this.$attrs.onClickEdit;
                this.showAction = (this.$attrs.onClickAction || this.showEdit) && !this.actionHidden;
                if (this.showEdit) {
                    this.actionText = 'SB_Edit';
                    this.onClickAction = this.onClickEdit;
                    this.actionIcon = 'edit';
                    this.actionButtonClass = 'success';
                }
                this.showBack = this.$attrs.onClickBack;
                this.actionButtonClass = this.actionButtonClass || 'info';
            }

            fromFormat() {
                let dateFormat =  'EEE dd MMM yyyy';
                if (this.isEvent) {
                    dateFormat += ' HH:mm';
                }
                return dateFormat;
            }

            toFormat() {
                const isMultiDay = !moment(this.from).isSame(this.to, 'day');
                if (isMultiDay) {
                    return this.fromFormat();
                } else {
                    return 'HH:mm';
                }
            }

        }
    });
