'use strict';

angular.module('shared.services.saveChangesPromptService', [
    'shared.services.simpleAlertService'
])
    .service('saveChangesPromptService',
    [
        '$state',
        '$timeout',
        '$window',
        'simpleAlertService',
        function (
            $state,
            $timeout,
            $window,
            simpleAlertService) {
            var service = this;

            var _saveReminderTimeout = null;
            var _isDirtyFlatPointerContainer = undefined;
            var _savePromptMessage = '';
            var _timerPromptMessage = '';
            var _setTimerEnabled = false;
            var _saveChangesFn = null;

            service.initialTimeoutInterval = 10000;
            service.timeoutBackoffFactor = 2;

            service.reset = function () {

                service.initialTimeoutInterval = 10000;
                service.timeoutBackoffFactor = 2;

                if (_saveReminderTimeout) {
                    $timeout.cancel(_saveReminderTimeout);
                    _saveReminderTimeout = null;
                }
            };

            service.enableSaveTimerReminder = function () {
                _setTimerEnabled = true;
            };

            service.disableSaveTimer = function () {
                _setTimerEnabled = false;

                if (_saveReminderTimeout) {
                    $timeout.cancel(_saveReminderTimeout);
                }
            }.bind(this);

            service.toJsonForComparison = function (data) {

                var replacer = function (key, value) {

                    if (this[key] instanceof Date) {
                        return this[key].toUTCString();
                    }
                    else if (!isNaN(this[key]) ) {
                        return this[key].toString();
                    }

                    return value;
                };

                return JSON.stringify(data, replacer);
            };

            service.triggerSaveTimerReminder = function () {
                $timeout.cancel(_saveReminderTimeout);

                if (_setTimerEnabled) {
                    _saveReminderTimeout = $timeout(function ()
                    {
                        if (!_isDirtyFlatPointerContainer.flag) {
                            return;
                        }

                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Save_changes',
                            message: _timerPromptMessage,
                            okButtonText: 'SB_OK',
                            cancelButtonText: 'SB_Cancel'
                        });

                        modalInstance
                            .result
                            .then(function () {
                                if (!!_saveChangesFn) {
                                    _saveChangesFn();
                                }
                            }.bind(this))
                            .catch(function () {
                                service.initialTimeoutInterval = service.initialTimeoutInterval * service.timeoutBackoffFactor;
                                console.log('ignored register save reminder');
                            });
                    }.bind(this), service.initialTimeoutInterval);
                }
            }.bind(this);



            service.setup = function ($transitions, isDirtyFlagPointerContainer, savePromptMessage, timerPromptMessage, saveChangesFn) {
                _isDirtyFlatPointerContainer = isDirtyFlagPointerContainer;
                _savePromptMessage = savePromptMessage;
                _timerPromptMessage = timerPromptMessage;
                _saveChangesFn = saveChangesFn;

                $transitions.onStart({}, function (transition) {

                    console.log('shared.services.saveChangesPromptService/setup/$transitions.onStart');

                    if (_isDirtyFlatPointerContainer.flag) {
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_You_have_unsaved_changes',
                            message: _savePromptMessage,
                            okButtonText: 'SB_Yes',
                            cancelButtonText: 'SB_No'
                        });

                        modalInstance.result
                            .then(function () {
                                _isDirtyFlatPointerContainer.flag = false;
                                $state.go(transition.$to().name, transition.params('to'));
                            }.bind(this))
                            .catch(function () {
                            });

                        return false;
                    }
                }.bind(this));

                try {
                    $window.onbeforeunload = function (event) {
                        if (_isDirtyFlatPointerContainer.flag) {
                            var message = 'Are you sure you wish to leave without saving register data?';

                            if (typeof event === 'undefined') {
                                event = window.event;
                            }
                            if (event) {
                                event.returnValue = message;
                            }

                            return message;
                        }
                    };
                } catch (err) {
                    //should only be Edge
                }


            };

        }]);