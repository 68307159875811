'use strict';

angular.module('sysadmin.services.mainSettingTypesService', [])
    .factory('mainSettingTypesService', [
        '$http', '$cacheFactory',
        function ($http) {

            var urlBase = '/webapi/WebMainSettings/';
            var repository = {};

            repository.getMainSettingTypes = function () {
                return $http.get(urlBase + 'GetMainSettingTypes')
                    .then(function (response) {
                        return response.data;
                    });
            };

            return repository;
        }
    ]);