'use strict';

angular.module('transport.configuration.timetable.transportTimetableList', [
    'transport.services.transportTimetablesService',
    'transport.services.transportTimetableCalendarsService',
    'shared.filters.decoded',
]).component('transportTimetableList', {
    templateUrl: '/Scripts/app/transport/configuration/timetable/transport-timetable-list.template.html',
    bindings: {
    },
    controller: [
        '$timeout',
        '$uibModal',
        'transportTimetablesService',
        'transportTimetableCalendarsService',
        'simpleAlertService',
        '$scope',
        function transportTimetableListController(
            $timeout,
            $uibModal,
            transportTimetablesService,
            transportTimetableCalendarsService,
            simpleAlertService,
            $scope)
        {
            $scope.$on('timetablesUpdated', () => {
                this.loadData();
            });

            this.$onInit = function ()
            {
                this.loadData();
            }.bind(this);

            this.loadData = () => {
                this.transportTimetables = null;
                this.transportTimetableCalendars = [];
                this.selectedCalendarId = 0;

                transportTimetableCalendarsService.getTransportTimetableCalendars()
                    .then(function (calendars)
                    {
                        var timetableCalendars = calendars;

                        transportTimetablesService.getTransportTimetables()
                            .then(function (timetables)
                            {
                                this.transportTimetables = timetables;

                                timetableCalendars.forEach(function (calendar)
                                {
                                    var transportTimetableCalendar =
                                    {
                                        id: calendar.id,
                                        name: calendar.name,
                                        isDefault: calendar.isDefault,
                                        dateRanges: calendar.dateRanges,
                                        timetables: []
                                    };

                                    this.transportTimetables.forEach(function (timetable)
                                    {
                                        if (timetable.transportTimetableCalendarId == calendar.id)
                                        {
                                            transportTimetableCalendar.timetables.push(timetable);
                                        }
                                    });

                                    this.transportTimetableCalendars.push(transportTimetableCalendar);

                                }.bind(this));

                                $timeout(function ()
                                {
                                    if (this.selectedCalendarId == 0 && this.transportTimetableCalendars.length > 0)
                                    {
                                        var calendarId = (this.transportTimetableCalendars.find(c => c.isDefault) ?? this.transportTimetableCalendars[0]).id;
                                        $('#tab_' + calendarId).addClass('active');
                                        $('#tab_pane_' + calendarId).addClass('active in show');

                                        this.selectedCalendarId = calendarId;
                                    }
                                    else if (this.selectedCalendarId > 0 && this.transportTimetableCalendars.length > 0)
                                    {
                                        $('#tab_' + this.selectedCalendarId).addClass('active');
                                        $('#tab_pane_' + this.selectedCalendarId).addClass('active in show');
                                    }
                                }.bind(this), 200);

                            }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: error.data.Message });

                            }.bind(this));

                    }.bind(this))
                    .catch(function (error)
                    {
                        simpleAlertService.errorAlert({ message: error.data.Message });

                    }.bind(this));
            }

            this.tabSelected = function (calendarId)
            {
                this.transportTimetableCalendars.forEach(function (calendar)
                {
                    if (calendar.id == calendarId)
                    {
                        $('#tab_' + calendarId).addClass('active');
                        $('#tab_pane_' + calendarId).addClass('active in show');
                    }
                    else
                    {
                        $('#tab_' + calendar.id).removeClass('active');
                        $('#tab_pane_' + calendar.id).removeClass('active in show');
                    }
                });

                this.selectedCalendarId = calendarId;
            }

            this.editTimetable = function (timetable)
            {
                var timetableModalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimetableEdit',
                    size: 'lg',
                    backdrop: 'static',
                    resolve:
                    {
                        params: function ()
                        {
                            return {
                                timetableId: timetable.id
                            }
                        }
                    }
                });

                timetableModalInstance.result.then(
                    () => this.loadData(),
                    () => {}
                );
            };

            this.editTimetableRoutes = function (timetable)
            {
                var routesModalInstance = $uibModal.open({
                    animation: true,
                    component: 'transportTimetableRoutes',
                    size: 'xl',
                    backdrop: 'static',
                    resolve:
                    {
                        params: function ()
                        {
                            return {
                                responseDto: timetable
                            }
                        }
                    }
                });

                routesModalInstance.result.then(
                    () => this.loadData(),
                    () => {}
                );
            };
        }
    ]
});
