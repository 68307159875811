angular.module('settingsModule')
    .service('linksPortalAdminService', class LinksPortalAdminService {

        httpCoreApi: any;

        urlStringMenuCategory: string;
        urlStringPortalLink: string;
        urlStringPortalContent: string;
        settings: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.urlStringMenuCategory = 'MenuCategory';
            this.urlStringPortalLink = 'PortalLinks';
            this.urlStringPortalContent = 'PortalContent';
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            }
        }

        // Menu Category

        getMenuCategories() {
            return this.httpCoreApi.get(this.urlStringMenuCategory, this.settings);
        }

        createMenuCategory(name, displayOrder) {
            return this.httpCoreApi.post(this.urlStringMenuCategory, {
                name: name,
                isActive: true,
                displayOrder: displayOrder || 1
            }, {
                ...this.settings,
                successMessage: 'SB_Menu_Category_Added'
            })
        }

        updateMenuCategory(cat) {
            return this.httpCoreApi.put(this.urlStringMenuCategory, cat, {
                ...this.settings
            })
        }

        deleteMenuCategory(id: number) {
            return this.httpCoreApi.delete(this.urlStringMenuCategory, {
                menuCategoryId: id
            }, this.settings)
        }

        increaseDisplayOrder(menuCategoryId: number) {
            return this.httpCoreApi.patch(`${this.urlStringMenuCategory}/${menuCategoryId}/IncreaseDisplayOrder`, {}, {
                ...this.settings,
                showSuccessToast: false
            })
        }

        decreaseDisplayOrder(menuCategoryId: number) {
            return this.httpCoreApi.patch(`${this.urlStringMenuCategory}/${menuCategoryId}/DecreaseDisplayOrder`, {}, {
                ...this.settings,
                showSuccessToast: false
            })
        }

        // Link

        getLinks() {
            return this.httpCoreApi.get(`${this.urlStringPortalLink}`, this.settings);
        }

        getLink(linkId: number) {
            return this.httpCoreApi.get(`${this.urlStringPortalLink}/${linkId}`, this.settings)
        }

        createLink(link) {
            return this.httpCoreApi.post(this.urlStringPortalLink, link, {
                ...this.settings,
                successMessage: 'SB_Portal_Link_Saved'
            })
        }

        updateLink(link, isMove: boolean) {
            link.organisationPortalLinksMenuCategoryId = link.menuCategoryId;
            return this.httpCoreApi.put(this.urlStringPortalLink, link, {
                ...this.settings,
                showSuccessToast: !isMove,
                successMessage: 'SB_Portal_Link_Saved'
            })
        }

        deleteLink(id: number) {
            return this.httpCoreApi.delete(this.urlStringPortalLink, {
                portalLinkId: id
            }, this.settings)
        }

        publishLink(id: number) {
            return this.httpCoreApi.patch(`${this.urlStringPortalLink}/Publish/${id}`, {}, this.settings)
        }

        unPublishLink(id: number) {
            return this.httpCoreApi.patch(`${this.urlStringPortalLink}/UnPublish/${id}`, {}, this.settings)
        }

        // Content

        getContents() {
            return this.httpCoreApi.get(`${this.urlStringPortalContent}`, this.settings);
        }

        getContent(id: number) {
            return this.httpCoreApi.get(`${this.urlStringPortalContent}/${id}`, this.settings)
        }

        createContent(content) {
            return this.httpCoreApi.post(this.urlStringPortalContent, content, {
                ...this.settings,
                successMessage: 'SB_Portal_Content_Saved'
            })
        };

        updateContent(content) {
            return this.httpCoreApi.put(this.urlStringPortalContent, content, {
                ...this.settings,
                successMessage: 'SB_Portal_Content_Saved'
            })
        };

        deleteContent(id: number) {
            return this.httpCoreApi.delete(this.urlStringPortalContent, {
                id: id
            }, this.settings)
        }

    });
