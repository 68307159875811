angular.module('documentStore.components.documentList',
        [
            'documentStore.services.documentStoreService',
            'shared.components.sbColumnHeader',
            'pascalprecht.translate'
        ])
    .component('documentList',
        {
            bindings: {
                teamId: '<',
                onSelect: '&',
                updatedDocument: '<',
            },
            templateUrl: '/Scripts/app/documentStore/components/document-list.template.html',
            controller: [
                'documentStoreService',
                function documentListController(documentStoreService)
                {
                    this.loading = 0;

                    this.documents = [];
                    this.selectedDocument = null;

                    this.sortType = 'fileName';

                    var self = this;

                    this.isLoading = function () { return self.loading > 0; };

                    this.$onChanges = function (changes)
                    {
                        if (changes.updatedDocument && changes.updatedDocument.currentValue) {
                            var document = changes.updatedDocument.currentValue;

                            for (var i = self.documents.length; i--;) {
                                if (self.documents[i].id === document.id) {
                                    if (document.isDeleted)
                                    {
                                        self.documents.splice(i, 1);
                                        self.select(self.documents[0]);
                                        return;
                                    } else
                                    {
                                        self.documents[i].fileName = document.fileName;
                                        self.documents[i].mimeType = document.mimeType;
                                        self.documents[i].fileSize = document.fileSize;
                                        self.documents[i].uploadedDate = document.uploadedDate;
                                        self.documents[i].uploadedByName = document.uploadedByName;
                                        self.documents[i].isDeleted = document.isDeleted;
                                        self.select(self.documents[i]);
                                        return;
                                    }
                                }
                            }

                            // this looks like an add- do we have a place holder for this?
                            for (var i = self.documents.length; i--;) {
                                if (self.documents[i].id === 0)
                                {
                                    self.documents[i].id = document.id;
                                    self.documents[i].fileName = document.fileName;
                                    self.documents[i].mimeType = document.mimeType;
                                    self.documents[i].fileSize = document.fileSize;
                                    self.documents[i].uploadedDate = document.uploadedDate;
                                    self.documents[i].uploadedByName = document.uploadedByName;
                                    self.documents[i].isDeleted = document.isDeleted;
                                    self.select(self.documents[i]);
                                    return;
                                }
                            }

                            // no place holder, just add it to the list
                            var newdocument = {
                                id: document.id,
                                fileName: document.fileName,
                                mimeType: document.mimeType,
                                fileSize: document.fileSize,
                                uploadedDate: document.uploadedDate,
                                uploadedByName: document.uploadedByName,
                                isDeleted: document.isDeleted
                            };

                            self.documents.push(newdocument);
                            self.select(newdocument);
                        }

                        if (changes.teamId && changes.teamId.currentValue)
                        {
                            self.loading++;
                            documentStoreService.getDocumentsForTeam(self.teamId)
                                .then(function (data) {
                                    self.documents = data;
                                    self.loading--;
                                });
                        }
                    };

                    this.select = function (document) {
                        self.selectedDocument = document;

                        self.onSelect()(document);
                    };

                    this.getMimeTypeDisplay = function(mimeType)
                    {
                        return documentStoreService.getMimeTypeDisplayValue(mimeType);
                    };
                }
            ]
        });