'use strict';

angular.module('transport.configuration.routes.transportRouteAdd',
    [
        'transport.configuration.routes.transportRouteStops',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
        'transport.services.transportConfigurationStopsService',
        'transport.services.transportDirectionsService',
        'transport.services.transportConfigurationRoutesService',
    ])
    .component('transportRouteAdd',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/transport/configuration/routes/transport-route-add.template.html',
            controller: [
                '$timeout',
                'transportConfigurationMethodService',
                'transportDirectionsService',
                'transportConfigurationRoutesService',
                'simpleAlertService',
                function transportRouteAddController(
                    $timeout,
                    transportConfigurationMethodService,
                    transportDirectionsService,
                    transportConfigurationRoutesService,
                    simpleAlertService)
                {

                    this.isSubmitted = false;
                    this.showIsActiveSwitch = false;

                    this.areAllStopsInactive = function (routeStops)
                    {
                        if (typeof routeStops !== 'undefined' && routeStops.length > 0)
                        {
                            this.showIsActiveSwitch = false;
                            for (var i = 0; i < routeStops.length; i++)
                            {
                                if (routeStops[i].isActive)
                                {
                                    this.showIsActiveSwitch = true;
                                    break;
                                }
                            }

                            if (!this.showIsActiveSwitch)
                            {
                                this.transportRoute.isActive = false;
                            }
                        }
                    }.bind(this);

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportRoute = {};

                    this.$onInit = function ()
                    {

                        this.transportMethods = null;
                        this.transportDirections = null;
                        this.transportRoute = {
                            name: "",
                            isActive: false,
                            code: "",
                            transportDirectionId: null,
                            transportMethodId: null,
                            routeStops: []
                        };

                        transportDirectionsService.getTransportDirections().then(function (data)
                        {
                            this.transportDirections = data;
                        }.bind(this), function (error)
                        {
                            simpleAlertService.errorAlert(error);
                        }.bind(this));

                        transportConfigurationMethodService.getMethodsSupportingRoutes().then(function (data)
                        {
                            this.transportMethods = data;
                        }.bind(this), function (error)
                        {
                            simpleAlertService.errorAlert(error);
                        }.bind(this));

                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.addTransportRoute = function ()
                    {
                        transportConfigurationRoutesService.addRoute(this.transportRoute)
                            .then(function (responseData)
                            {
                                if (responseData && responseData.name)
                                {

                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Saved',
                                        message: 'SB_Transport_Route_Added',
                                        messageTranslationParameters: { transportRoute: responseData.name },
                                        staticBackdrop: true
                                    });

                                    closeModal();
                                }
                                else
                                {
                                    alertAndResetOkButton();
                                }
                            })
                            .catch(function (responseData)
                            {

                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });
                    }.bind(this);

                }
            ]
        });
