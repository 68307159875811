import { Component, Input, SimpleChanges } from '@angular/core';
import { EventLocation } from '@sb-events/models/event-detail';
import { MapsUrl } from '@sb-shared/models/UI/maps-url';
import { InstallationSettingsService } from '@sb-shared/services/installation-settings.service';
import { ComponentBase } from '@sb-shared/components/component-base/component-base';

@Component({
  selector: 'sb-location-lite',
  templateUrl: './location-lite.component.html',
  styleUrls: ['./location-lite.component.scss']
})
export class LocationLiteComponent extends ComponentBase {

  @Input() location: EventLocation;
  mapsUrl: MapsUrl;

  constructor(private installationSettings: InstallationSettingsService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.location?.currentValue && this.location.postCode) {
      this.installationSettings.getMapsUrl()
        .subscribe(url => {
          if (url && !url.isBaidu) {
            let locationString = '';
            if (this.location.name) {
                locationString += this.location.name + '+';
            }
            if (this.location.address) {
                locationString += this.location.address + '+';
            }
            locationString += this.location.postCode;
            url.value = url.value.replace('{{locationstring}}', locationString);
            this.mapsUrl = url;
          }
        });
    }    
  }

  openMap() {
    window.open(this.mapsUrl.value, '_blank');
  }

}
