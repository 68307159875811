'use strict';

angular.module('emailTemplates.services.emailTemplatesService', [])
    .factory("emailTemplatesService", [
        "$http", "$cacheFactory",
        function ($http, $cacheFactory) {

            var urlBase = "/webapi/WebEmailTemplates/";
            var repository = {};
            var repoCache = $cacheFactory('emailTemplatesgServiceCache');

            repository.clearCache = function () {
                repoCache.removeAll();
            };

            repository.getCommunicationTemplateTypes = function () {
                return $http.get(urlBase + 'GetCommunicationTemplateTypes', { cache: repoCache })
                    .then(function (response) { return response.data; });
            };

            repository.getTemplatePlaceholders = function () {
                return $http.get(urlBase + 'GetTemplatePlaceholders', { cache: repoCache })
                    .then(function (response) { return response.data; });
            };

            repository.getCommunicationTemplate = function (organisationId, templateTypeId) {

                var request = { params: { organisationId: organisationId, templateTypeId: templateTypeId } };

                return $http.get(urlBase + 'GetCommunicationTemplate', request, { cache: repoCache })
                    .then(function (response) { return response.data; });
            };

            repository.updateCommunicationTemplate = function (organisationId, templateTypeId, template) {

                var request = { params: { organisationId: organisationId, templateTypeId: templateTypeId } };

                return $http.post(urlBase + 'UpdateCommunicationTemplate', template, request)
                    .then(function (response) { return response.data; });
            };


            return repository;
        }
    ]);