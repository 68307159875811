'use strict';

angular.module('corporateContacts.controllers.corporateContactPickerPopupController', [
        'corporateContacts.components.corporateContactList',
        'shared.components.sbModalHeader'
    ])
    .controller('corporateContactPickerPopupController', [
        '$scope',
        '$uibModalInstance',
        'selectedContactId',
        'contactType',
        function ($scope, $uibModalInstance, selectedContactId, contactType) {

            $scope.contactType = contactType;

            $scope.currentContactId = selectedContactId;

            $scope.onSelect = function(newId)
            {
                $scope.currentContactId = newId.id;
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.currentContactId);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }
    ]);