angular.module('roles.services.rolesService', [
    'shared.services.toastService'
])
    .service('rolesService', class RolesService {
        $http: any;
        $q: any;
        $cacheFactory: any;
        httpCoreApi: any;
        toastService: any;
        settings: any;

        urlRoleBase: string;
        urlPersonBase: string;
        coreApiBase: any;
        repoCache: any;

        static $inject = ['$http', '$q', '$cacheFactory', 'toastService', 'httpCoreApi'];

        constructor ($http, $q, $cacheFactory, toastService, httpCoreApi) {
            this.$http = $http;
            this.$q = $q;
            this.$cacheFactory = $cacheFactory;
            this.toastService = toastService;
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.SysAdmin
            }

            this.urlRoleBase = '/webapi/WebUserRoles/';
            this.urlPersonBase = '/webapi/WebPerson/';
            // cache responses- be sure to clear the cache when doing any write back operations
            this.repoCache = $cacheFactory('rolesServiceCache');
        }

        getOrganisationKeyRoles() {
            return this.$http.get(this.urlRoleBase + 'GetOrganisationKeyRoles', { cache: this.repoCache })
                .then(response => {
                    return response.data;
                });
        };

        getRoleNames(roles) {
            return this.$http
                .get(this.urlRoleBase + 'GetRoleNamesAsync',
                    { params: { roles: roles } })
                .then(response => {
                    return response.data;
                });
        };

        getOrganisationUserRoles(organisationId, roles) {
            return this.$http
                .get(this.urlRoleBase + 'GetUserRolesAsync',
                    { params: { organisationId: organisationId, roles: roles } })
                .then(response => {
                    return response.data;
                });
        };

        getStaffInRole(roleId) {
            return this.$http.get(this.urlRoleBase + 'GetStaffInRole', { params: { roleId: roleId } })
                .then(response => {
                    return response.data;
                });
        };

        getIsCommsAdmin() {
            return this.$http.get(this.urlRoleBase + 'GetIsCommsAdmin')
                .then(response => {
                    return response.data;
                });
        };

        postStaffToRole(roleId, selectedPersonIds) {
            this.repoCache.removeAll();

            return this.$http.post(this.urlRoleBase + 'PostStaffToRole', { roleId: roleId, personIds: selectedPersonIds })
                .then(response => {
                    return response.data;
                })
                .catch(e => {
                    return { isError: true, errorData: e };
                });
        };

        // Core API

        getPersonInOrganisationByEmail(email) {
            return this.httpCoreApi.post('User', { emailAddress: email }, this.settings)
                .then(response => { return response.data; });
        }

        getUserRoles(organisationId, personId) {
            this.repoCache.removeAll();
            return this.httpCoreApi.get(`UserRoles/${personId}/active`, { ...this.settings, orgId: organisationId });
        }

        updateUserRoles(organisationId, personId, rolesToAdd, rolesToRemove) {
            this.repoCache.removeAll();
            return this.httpCoreApi.post('UserRoles',
                {
                    personId: personId,
                    rolesToAdd: rolesToAdd,
                    rolesToRemove: rolesToRemove
                },
                {
                    ...this.settings,
                    orgId: organisationId,
                    successMessage: 'SB_Roles_Updated'
                }
            );
        };

        // Person data: copied from receptionService using same endpoints, will later use new endpoints

        addPerson = function (orgId, personData) {
            var deferred = this.$q.defer();
            this.$http.post('/reception/AddPersonToOrganisation?organisationId=' + orgId, personData )
                .then((response) => {
                    this.toastService.saveSuccess('SB_User_Added');
                    deferred.resolve(response.data);
                })
                .catch(err => {
                    this.toastService.saveError();
                    deferred.reject(err);
                });
            return deferred.promise;
        };

        getPerson(organisationId, personId)
        {
            var deferred = this.$q.defer();

            this.$http.get(this.urlPersonBase + '/GetPersonRoleAndStatusData',
                {
                    params:
                    {
                        organisationId: organisationId,
                        personId: personId
                    }
                })
                .then(function (response) { deferred.resolve(response.data); })
                .catch(function (e) { deferred.reject(e); });

            return deferred.promise;
        };
    }
);
