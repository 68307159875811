'use strict';

angular.module('shared.services.deviceService', [])
    .service('deviceService', ['$window', '$location', '$state',
        class DeviceService {

            $window: any;
            $location: any;
            $state: any;

            static $inject = ['$window', '$location', '$state'];

            constructor ($window, $location, $state) {
                this.$window = $window;
                this.$location = $location;
                this.$state = $state;
            }

            isWindowWidthLargerThan(pixels) {
                return this.$window.innerWidth > pixels;
            };

            isXsDevice() {
                const xsMaxWidth = 767;
                return !this.isWindowWidthLargerThan(xsMaxWidth);
            };

            isMobileApp() {
                return angular.element(document).find('body').hasClass('mobile-app');
            };

            triggerModalCloseInMobileApp() {
                if (this.isMobileApp()) {
                    this.$state.go('mobile-modal-close');
                }
            }

            isReactNativeWebview() {
                return this.$window.ReactNativeWebView !== undefined;
            }

            postReactNativeWebviewMessage(message: string) {
                this.$window.ReactNativeWebView.postMessage(message);
            }

            isModalOnly() {
                return angular.element(document).find('body').hasClass('modal-only');
            }

            getShowModalType() {
                let modalType;
                if (!angular.element(document).find('body').hasClass('modal-only')) {
                    return;
                }
                [ ...Array(10) ].forEach((item, i) => {
                    if (angular.element(document).find('body').hasClass(`modal-type-${i}`)) {
                        modalType = i;
                    }
                });
                return modalType;
            }

            calculateAppHeight() {
                const doc = document.documentElement;
                doc.style.setProperty('--app-height', `${this.$window.innerHeight}px`);
            }
        }
    ]
);
