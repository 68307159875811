// this is the preferred method for creating services as they are put into their own module and are more easily sharable
angular.module('documentStore.services.documentStoreService', [])
    .factory('documentStoreService', [
        '$http', '$cacheFactory',
        function ($http, $cacheFactory) {

            var urlBase = "/webapi/WebDocumentStore/";
            var repository = {};

            var repoCache = $cacheFactory('documentStoreCache');

            repository.clearCache = function () {
                repoCache.removeAll();
            };

            repository.getDocumentsForTeam = function (teamId)
            {
                return $http.get(urlBase + "GetDocumentsForTeam", { cache: repoCache, params: { tId : teamId } })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function(error)
                    {
                        return { isError: true };
                    });
            };

            repository.deleteDocument = function(documentId, teamId)
            {
                repoCache.removeAll();

                return $http.post(urlBase + 'DocumentDelete?dId=' + documentId + '&tId=' + teamId)
                    .then(function(response)
                    {
                        repository.clearCache();

                        return response.data;
                    })
                    .catch(function (error) {
                        return { isError: true };
                    });
            };

            repository.updateDocumentMetaData = function(doc, teamId)
            {
                repoCache.removeAll();

                return $http.post(urlBase + 'UpdateDocumentMetaData?tId=' + teamId, doc)
                    .then(function(response)
                    {
                        repository.clearCache();

                        return response.data;
                    })
                    .catch(function (error) {
                        return { isError: true };
                    });
            };

            repository.getDocumentById = function(docId, teamId)
            {
                return $http.get(urlBase + "GetDocumentById", { cache: repoCache, params: { tId: teamId, docId: docId } })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (error) {
                        return { isError: true };
                    });
            };

            repository.getDocumentDownloadLink = function(docId, teamId)
            {
                return urlBase + "DownloadDocument?tId=" + teamId + "&docId=" + docId;
            }

            repository.getMimeTypeDisplayValue = function(mimeType)
            {
                switch (mimeType)
                {
                    case 'image/jpeg':
                    case 'image/jpg':
                        return 'Image (jpg)';
                    case 'image/png':
                        return 'Image (png)';
                    case 'application/pdf':
                    case 'application/x-pdf':
                        return 'Document (pdf)';
                    default:
                        return mimeType;
                }
            }

            return repository;
        }
    ])
    .filter('bytes', function () {
        return function (bytes, precision) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
            if (typeof precision === 'undefined') precision = 1;
            var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
                number = Math.floor(Math.log(bytes) / Math.log(1024));
            return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
        }
    });;