import { Component, Input } from '@angular/core';
import { ScriptLoaderService } from '@sb-shared/services/script-loader.service';

@Component({
  selector: 'sb-twitter-feed',
  templateUrl: './twitter-feed.component.html',
  styleUrls: ['./twitter-feed.component.scss']
})
export class TwitterFeedComponent {
  
@Input() profile: string;

  constructor(private scriptLoaderService: ScriptLoaderService) {
  }

  ngAfterViewInit()
  {
        this.scriptLoaderService.loadJsScript("https://platform.twitter.com/widgets.js");
  }
}
