'use strict';

angular.module('diary.components.diaryEventAutoNotify',
    [
        'events.components.autoNotifyEvent',
    ]).component('diaryEventAutoNotify', {
        templateUrl: '/Scripts/app/diary/components/diary-event-auto-notify.template.html',
        bindings: {
            numberAutoNotified: '<',
            onRefresh: '&'
        }
    });