"use strict";

angular.module('team.directives.attendance', [
    'teams.services.teamService'
])
    .directive('teamAttendance', [
        '$window',
        '$state',
        '$uibModal',
        '$sce',
        '$templateRequest',
        '$compile',
        '$timeout',
        'teamService',
        'registerStateService',
        function ($window, $state, $uibModal, $sce, $templateRequest, $compile, $timeout, teamService, registerStateService) {

            function link($scope) {
                $scope.$watch('teamId',
                    function (newValue) {
                        if (newValue) {
                            $scope.loadAttendance();
                        }

                    });
                $scope.$watch('eventsFrom',
                    function (newValue) {
                        if (newValue) {
                            $scope.loadAttendance();
                        }

                    });
                $scope.$watch('eventsTo',
                    function (newValue) {
                        if (newValue) {
                            $scope.loadAttendance();
                        }

                    });
            };

            function controller($scope) {

                $scope.attendanceData = [];
                $scope.loadingAttendance = false;

                $scope.receptionRegisterStateService = registerStateService;

                $scope.loadAttendance = function () {

                    if ($scope.teamId <= 0) {
                        return;
                    }

                    $scope.loadingAttendance = true;

                    teamService
                        .getAttendance($scope.teamId, $scope.eventsFrom, $scope.eventsTo)
                        .then(function (data) {
                            $scope.loadingAttendance = false;

                            if (!data.isError) {
                                $scope.attendanceData = data;
                            } else {
                                console.error('Failure in GetTeamAttendance', data.errorData);
                            }
                        });
                };

                $scope.exportAttendance = function () {
                    teamService.getTeamAttendanceReportCsv($scope.teamId, $scope.eventsFrom, $scope.eventsTo);
                };

                $scope.printAttendance = function () {

                    var templateUrl = $sce.getTrustedResourceUrl('/Scripts/app/teams/directives/team-attendance.print.html?v=' + $window.EveryBuddy.Version);

                    $templateRequest(templateUrl).then(function (template) {
                        template = '<div>' + template + '</div>';

                        var compiledPrintView = $compile(template)($scope);
                        $timeout(function () {
                            var myWindow = $window.open('', '', 'width=800, height=600');
                            myWindow.document.write(compiledPrintView[0].innerHTML);
                        }, 1000);  // wait for a short while,Until all scope data is loaded If any complex one
                    }, function () {
                        // An error has occurred
                    });
                };


            };

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/teams/directives/team-attendance.template.html',
                scope: {
                    teamId: '=',
                    teamName: '=',
                    eventsFrom: '=',
                    eventsTo: '='
                },
                controller: ['$scope', controller],
                link: link
            };
        }]);
