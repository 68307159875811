'use strict';

angular.module('ccasignupModule').controller('ccaModuleCloneController', [
    '$scope',
    '$uibModalInstance',
    '$state',
    'signUpRepository',
    'ccaSignUp',
    'simpleAlertService',
    function ($scope, $uibModalInstance, $state, signUpRepository, ccaSignUp, simpleAlertService) {

        var createCloneSignUpDTO = function (ccaSignUp)
        {
            this.cloneCcaSignUpId = ccaSignUp.ccaSignUpId;
            this.title = ccaSignUp.title;

            //default to now
            this.activitiesStartDate = moment.utc().toDate();
            this.activitiesEndDate = moment.utc().add('days', 1).toDate();

            //use selected items start and end times
            this.defaultStartHour = ccaSignUp.defaultStartHour;
            this.defaultStartMin = ccaSignUp.defaultStartMin;
            this.defaultEndHour = ccaSignUp.defaultEndHour;
            this.defaultEndMin = ccaSignUp.defaultEndMin;

            this.suffixGroupTitle = "";
        }

        if (ccaSignUp == null)
        {
            simpleAlertService.errorAlert();
        }

        //Copy object
        $scope.signUp = new createCloneSignUpDTO(ccaSignUp);
        //use selected items start and end times
        $scope.defaultStartHourMin = { h: $scope.signUp.defaultStartHour, m: $scope.signUp.defaultStartMin };
        $scope.defaultEndHourMin = { h: $scope.signUp.defaultEndHour, m: $scope.signUp.defaultEndMin }

        $scope.cloneCcaSignUp = function ()
        {
            //serialise back to sign up object
            $scope.signUp.defaultStartHour = $scope.defaultStartHourMin.h;
            $scope.signUp.defaultStartMin = $scope.defaultStartHourMin.m;
            $scope.signUp.defaultEndHour = $scope.defaultEndHourMin.h;
            $scope.signUp.defaultEndMin = $scope.defaultEndHourMin.m;

            signUpRepository.cloneSignUp($scope.signUp)
                .then(function (responseData) {

                    if (responseData && responseData.data && responseData.data.ccaSignUpId) {

                        simpleAlertService.simpleAlert({
                            title: 'SB_Saved',
                            message: 'SB_Cloned_Sign_Up_Successfully'
                        });

                        $state.go("ccaDetailsAA", { signUpId: responseData.data.ccaSignUpId});
                    }
                    else
                    {
                        simpleAlertService.errorAlert();
                    }
                })
                .catch(function(message){
                    simpleAlertService.errorAlert(message);
                });

            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.close();
        };
}]);