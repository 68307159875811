angular.module('settingsModule')
    .service('onlineMeetingService', class OnlineMeetingService {
        settings: any;
        baseUrl: string = 'OrganisationSettings/DefaultOnlinePTCOption';
        httpCoreApi: any;
        urlBase: string;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.settings = {
                controllerType: this.httpCoreApi.controllerTypes.Admin
            };
        }

        getOrganisationDefaultOnlinePTCOptions()
        {
            return this.httpCoreApi.get(this.baseUrl, this.settings);
        };

        updateOrganisationDefaultOnlinePTCOption(onlinePTCOption) {
            return this.httpCoreApi.post(this.baseUrl, {
                onlinePTCOptionId: onlinePTCOption.onlinePTCOptionId,
                meetingExtensionInMinutes: onlinePTCOption.meetingExtensionInMinutes
            },
            {
                ...this.settings
            })
        };
    });
