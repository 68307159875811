<div class="diary-item-detail__main" *ngIf="event">
    <div class="diary-item-detail__main--inner">
        <sb-date [date]="event.eventDate"></sb-date>
        <div>
            <h2 [innerHTML]="event.title"></h2>
            <div class="flex">
                <sb-badge
                    badgeClass="muted"
                    [message]="event.eventType"
                ></sb-badge>
                <div class="ml-4 align-center">
                    <sb-icon
                        name="clock"
                        overrideStyle="light"
                        class="pr-1"
                    ></sb-icon>
                    <!-- {{dayLabel | translate}} -->
                    <!-- <span class="lowercase"> {{'SB_From' | translate}} </span> -->
                    <span [innerHTML]="timesLabel"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="diary-item-detail__main--kit-container">
        <sb-kit
            *ngIf="eventDetails?.opponent as opponent"
            [primaryColour]="opponent.kitPrimaryHexColour"
            [secondaryColour]="opponent.kitSecondaryHexColour"
            [kitStyleId]="opponent.kitStyleId"
            size="sm"
        ></sb-kit>
    </div>
</div>
<div class="diary-item-detail__other" [hidden]="hideEventDetails()">
    <sb-loading [hasLoaded]="!isLoadingDetails"></sb-loading>
    <div *ngIf="!isLoadingDetails">
        <div *ngIf="eventDetails?.keyInfo">
            <sb-icon name="info" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'SB_Details' | translate"
            ></span>
            <!--<sb-shadow-dom [trustedHtml]="eventDetails?.keyInfo" />-->
        </div>
        <div *ngIf="eventDetails?.eventLocation">
            <sb-icon name="location-pin" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'SB_Location' | translate"
            ></span
            ><span [innerHTML]="eventDetails?.eventLocation.name"></span
            >&nbsp;(<span
                [innerHTML]="
                    (eventDetails?.eventLocation.homeAway
                        ? 'SB_Home'
                        : 'SB_Away'
                    ) | translate
                "
            ></span
            >)
            <sb-button
                *ngIf="eventDetails.postCode"
                buttonClass="link"
                iconName="location-address"
                iconStyle="regular"
                iconClasses="p-15"
                [isAutoSize]="true"
                tooltipText="SB_Open"
                (onClick)="openLocation()"
            ></sb-button>
        </div>
        <div *ngIf="getStopName() as stopName">
            <sb-icon name="location-pin" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'SB_Stop_name' | translate"
            ></span>
            <span [innerHTML]="getStopName()"></span>
        </div>
        <div
            *ngIf="
                eventDetails?.owningEntityName &&
                !event?.personId &&
                !eventDetails.isTransport
            "
        >
            <sb-icon name="groups" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'SB_Attendees' | translate"
            ></span
            >{{ eventDetails?.owningEntityName }}
            <span *ngIf="event?.totalAttendees"
                >({{ event.totalAttendees }})</span
            >
        </div>
        <div *ngIf="eventDetails?.organiserName">
            <sb-icon name="users" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'SB_Organiser' | translate"
            ></span>
            <span [innerHTML]="eventDetails?.organiserName"></span>
        </div>
        <div *ngIf="eventDetails?.teamOrganiserName">
            <sb-icon name="users" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'Lead staff' | translate"
            ></span>
            <span [innerHTML]="eventDetails?.teamOrganiserName"></span>
        </div>
        <div *ngIf="eventDetails?.onlineMeetingFormatLabel">
            <sb-icon name="meetingFormat" overrideStyle="light"></sb-icon>
            <span
                class="semi-bold"
                [innerHTML]="'SB_Meeting_Format' | translate"
            ></span>
            <span
                [innerHTML]="eventDetails?.onlineMeetingFormatLabel | translate"
            ></span>
        </div>
        <div *ngIf="eventDetails?.transportTimetableRouteTimeline">
            <sb-route-timeline
                [route]="eventDetails?.transportTimetableRouteTimeline"
                [hideOtherStops]="true"
            ></sb-route-timeline>
        </div>
    </div>
    <div class="justify-end" [ngClass]="stateClass">
        <sb-icon [name]="stateIcon"></sb-icon>
        <span [innerHTML]="stateLabel | translate" class="ml-1"></span>
    </div>
</div>
<div class="diary-item-detail__actions" *ngIf="buttons?.length > 0">
    <sb-button
        *ngFor="let button of buttons"
        class="diary-item-detail__actions__button"
        [buttonClass]="button.buttonClass"
        [iconName]="button.iconName"
        customClasses="btn-lg w-100"
        [message]="button.message"
        (onClick)="button.onClick()"
    ></sb-button>
</div>
