import { TeamClubSelection } from './../../models/team-club-selection';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sb-team-club-picker',
  templateUrl: './team-club-picker.component.html',
  styleUrls: ['./team-club-picker.component.scss']
})
export class TeamClubPickerComponent {

  clubIds: number[] = [];
  teamIds: number[] = [];
  subjectClassIds: number[] = [];
  excludedTeamIds: number[] = [];
  excludedClubIds: number[] = [];
  excludedSubjectClassIds: number[] = [];
  @Input() showSubjects: boolean;
  @Input() model: TeamClubSelection = new TeamClubSelection();
  @Output() modelChange: EventEmitter<TeamClubSelection> = new EventEmitter<TeamClubSelection>();

  change() {
    this.modelChange.emit(this.model)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && !changes.model.currentValue) {
      // Must always follow TeamClubSelection model
      this.model = new TeamClubSelection();
    }
  }

}
