'use strict';

angular.module('reports.components.reportContainer', [
    'reports.components.reportList',
    'reports.directives.reportEmbed'
])
    .component('reportContainer',
        {
            bindings: {
                displayArea: '<'
            },
            templateUrl: '/Scripts/app/reports/components/report-container.template.html',
            controller: [
                function () {

                    this.selectedReportId = null;
                    this.shouldPerformAction = null;

                    this.onReportSelected = function (id) {
                        this.selectedReportId = id;
                        console.log('report selected', id);
                    }.bind(this);

                    this.printClicked = function () {
                        this.shouldPerformAction = 'print';
                    }.bind(this);

                    this.refreshClicked = function () {
                        this.shouldPerformAction = 'refresh';
                    }.bind(this);

                    this.fullScreenClicked = function () {
                        this.shouldPerformAction = 'fullScreen';
                    }.bind(this);

                }]
        });