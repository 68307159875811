'use strict';

angular.module('receptionModule').component('messageCenter', {
    templateUrl: '/Scripts/app/reception/controllers/message-center.template.html?v=', // + window.EveryBuddy.Version,
    controller: [
        '$scope', '$uibModal', 'ngToast', 'messagingService', 'messageViewingService',
        function ($scope, $uibModal, ngToast, messagingService, messageViewingService)
        {
            $scope.shouldRestrictPeoplePicker = true;
            $scope.showTags = false;

            messagingService.getPickerRestriction()
                .then(function(result)
                {
                    $scope.shouldRestrictPeoplePicker = result;
                })
                .catch(function()
                {
                    $scope.shouldRestrictPeoplePicker = true;
                });

            messageViewingService
                .getAllLookups()
                .then(function (data) {
                    $scope.showTags= data.tagsEnabled;
                })
                .catch(function (err) {
                    console.error(err);
                    $scope.showTags = false;
                });
        }
    ]});