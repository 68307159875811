// Left below as is for, as used by files outside the module

angular.module('balance.constants', [])
    .constant('financialAccountTypeEnum', {
        Student: 1,
        Credit: 2,
        Voucher: 3
    })
    .constant('financialTransactionTypeEnum', {
        Debit: -1,
        Credit: 1
    });