<div #detail class="detail-container">
    <button class="btn btn-link mt-4 mb-4 visible-xs" *ngIf="selectedValue && backText" (click)="back()">
        <sb-icon name="back"></sb-icon> {{backText | translate}}
    </button>
    <div [hidden]="!selectedValue || isLoading">
        <ng-content></ng-content>
    </div>
    <div class="detail-placeholder hidden-xs" [hidden]="selectedValue || isLoading">
        <sb-icon [name]="isLoading ? 'loading' : placeholderIcon"
                 overrideStyle="duotone"></sb-icon>
        <p [innerHTML]="placeholderText  | translate"></p>
    </div>
</div>