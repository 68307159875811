'use strict';

angular.module('diary.components.diaryEventSendComms',
    [
        'pascalprecht.translate',
        'events.components.cancelEventButton',
        'events.services.eventsService',
        'messaging.controllers.personPickerPopup',
        'messaging.directives.smsEditor',
        'messaging.directives.emailEditor',
        'messaging.services.messageViewingService',
        'messaging.services.messagingService',
        'shared.components.sbIcon',
        'shared.controllers.confirmationPopup',
        'shared.directives.sbRadioButton',
        'shared.services.moduleService',
    ])
    .component('diaryEventSendComms',
        {
            bindings: {
                eventId: '<',
                hideTitle: '<',
                onSelectedEventUpdated: '&'
            },
            templateUrl: '/Scripts/app/diary/components/diary-event-send-comms.template.html',
            controller: [
                '$filter',
                'eventsService',
                function ($filter, eventsService) {
                    this.$onChanges = function (changes) {
                        var eventId = changes?.eventId?.currentValue ?? changes.eventId.previousValue;

                        if (!Number.isInteger(eventId)) {
                            eventId = parseInt(eventId);
                        }

                        if (Number.isInteger(eventId)) {
                            this.calendarEventId = eventId;

                            eventsService.getEventBasics(eventId)
                                .then(function (response) {

                                    var fromDate = moment(response.from);
                                    var timeZoneSafeFromDate = fromDate.clone().set({
                                        hour: 12,
                                        minute: 0,
                                        second: 0
                                    });

                                    this.title = `${$filter('translate')('SB_Event_Communication_Title_NoticeFrom')} - ${response.title ?? ''}  ${fromDate.format('HH:mm')} - ${timeZoneSafeFromDate.format('ddd DD MMM YYYY')}`;

                                }.bind(this))
                                .catch(function (err) {
                                    console.error(err);
                                });

                            eventsService.getEventDetails(eventId)
                                .then(resp => {
                                    const { details: { isCancelled } = {} } = resp;

                                    this.isCancelled = isCancelled;
                                }).catch(err => console.error(err));
                        }
                    }.bind(this);
                }]

        });
