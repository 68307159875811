import { Injectable } from '@angular/core';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { apis, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  configSettingsCore: HttpRequestSettings = {
    api: apis.Core
  };
  
  constructor(private http: HttpWebApiService, private siteHttp: HttpClient) { }

  getComponentVersions() {
    return this.http.get("diagnostic/version/component", this.configSettingsCore);
  }

  getSiteVersion() {
    return this.siteHttp.get("build.txt", { responseType: "text" });
  }
}
