import * as angular from "angular";
angular.module('portalModule', [
    'ui.bootstrap',
    'angular.filter',


    'tmh.dynamicLocale',
    'dashboard.components.portal',
    'dashboard.components.portalPage',
    'shared.directives.sbLoading',
    'shared.components.sbTitleBar',
    'shared.services.portalService',
    'shared.components.sbIcon',
    'shared.components.sbIconImg',
    // 'shared.components.sbActionMenu',
    'shared.services.cachedLookupService',
    'shared.services.arrayService',

    'ng1StateDefinitionModule',
])
    .config([
        'ng1StateDefinitionProvider',
        function (ng1StateDefinitionProvider) {


            const ng1State = ng1StateDefinitionProvider.$get();

            const moduleName = 'Portal';

            ng1State.state(moduleName, 'portal',
            {
                url: '/portal'
            })
            ng1State.state(moduleName, 'portalPage',
            {
                url: '/portalPage/:pageId'
            });
        }
    ])
