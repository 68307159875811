"use strict";

angular.module('receptionModule').component("receptionEventsFormTags", {
    templateUrl: '/Scripts/app/reception/controllers/reception-events-formtags.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        selectedEvent: '<',
    },
    controller: [
    "$scope", "userFormService",
    function ($scope, userFormService) {

        this.$onChanges = (changes) => {
            if (changes.selectedEvent && changes.selectedEvent.currentValue) {
                $scope.selectedEvent = this.selectedEvent;
            }
        };

        this.$onInit = () => {
            $scope.selectedFormId = "";

            userFormService.getFormTags().then(function(data) {
                    $scope.forms = data;
                },
                function(error) {
                    return error;
                });
        };

    }
]});
