'use strict';

angular.module('profile.directives.profileMedicalEditor', ['person.services', 'shared.components.sbModalHeader']).directive('profileMedicalEditor', [
  'personService',
  '$uibModal',
  function (personService, $uibModal) {
    function link($scope) {
      $scope.$watch('person', function (newValue) {
        if (!$scope.person || !$scope.person.personData || !$scope.person.personData.id) return;

        personService.getPupilMedical($scope.person.personData.id).then(function (data) {
          $scope.pupilmedical = data;
          $scope.loading = false;
        });
      });
    }

    function controller($scope, personService) {
      $scope.loading = true;
      $scope.addPupilMedical = function () {
        $scope.selectedMedical = { description: '', notes: '', medicalConditionId: 0 };

        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'editPupilMedicalModal.html',
          controller: 'ConfirmModalInstanceCtrl',
          size: 'sm',
          scope: $scope,
        });

        modalInstance.result.then(
          function () {
            personService
              .savePupilMedical(
                $scope.person.personData.id,
                $scope.selectedMedical.description,
                $scope.selectedMedical.notes,
                $scope.selectedMedical.medicalConditionId
              )
              .then(function (data) {
                $scope.pupilmedical = data;
              });
          },
          function () {}
        );
      };
      $scope.editPupilMedical = function (medicalDetails) {
        $scope.selectedMedical = { ...medicalDetails };

        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'editPupilMedicalModal.html',
          controller: 'ConfirmModalInstanceCtrl',
          size: 'sm',
          scope: $scope,
        });

        modalInstance.result.then(
          function () {
            personService
              .savePupilMedical(
                $scope.person.personData.id,
                $scope.selectedMedical.description,
                $scope.selectedMedical.notes,
                $scope.selectedMedical.medicalConditionId
              )
              .then(function (data) {
                $scope.pupilmedical = data;
              });
          },
          function () {}
        );
      };

      $scope.deletePupilMedical = function (medicalDetails) {
        $scope.selectedMedical = medicalDetails;

        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'deletePupilMedicalModal.html',
          controller: 'ConfirmModalInstanceCtrl',
          size: 'sm',
          scope: $scope,
        });

        modalInstance.result.then(
          function () {
            personService.deletePupilMedical($scope.person.personData.id, $scope.selectedMedical.medicalConditionId).then(function (data) {
              $scope.pupilmedical = data;
            });
          },
          function () {}
        );
      };
    }

    return {
      restrict: 'E',
      templateUrl: '/Scripts/app/profile/directives/profile-medical-editor.template.html',
      scope: {
        person: '=',
        misManaged: '=',
        isOrganisationAdmin: '=',
      },
      controller: ['$scope', 'personService', controller],
      link: link,
    };
  },
]);
