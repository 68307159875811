<div class="mode-tabs"
     [ngClass]="{'disabled-area' : isDisabled, 'mode-tabs--top' : isTop}">
    <ng-container *ngFor="let tab of tabs; let i = index">
        <ng-template #tabContent>
            <sb-icon *ngIf="tab.iconName"
                     [name]="tab.iconName"
                     class="mr-2"
                     overrideStyle="duotone"
                     [hasColor]="true"></sb-icon>
            <span [innerHTML]="tab.isUntranslated ? tab.label : (tab.label | translate)"></span>
            <div *ngIf="tab.badge"
                 class="badge"
                 [innerHTML]="tab.badge | translate"></div>
        </ng-template>
        <div *ngIf="isRouted"
             routerLinkActive="active"
             #rla="routerLinkActive"
             [ngClass]="{'pointer' : !rla}"
             [routerLink]="getTabRoute(tab.route)"
             (click)="onClick($event, tab)">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </div>
        <div *ngIf="!isRouted"
             [ngClass]="{'active' : tab.isActive}"
             (click)="click(i)">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </div>
        <sb-icon *ngIf="isWizard && i !== tabs.length - 1"
                 name="arrow-right"></sb-icon>
    </ng-container>
</div>
