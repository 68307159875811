angular.module('shared.services.searchResultOptionsCountService', [])
    .service('searchResultOptionsCountService', class SearchResultOptionsCountService {

        //For given source data array, gets the count of unique values for the given property
        //resulting in an object containing each unique value as a property with the results count as the value of each.
        //E.g. for 31 entries of an array of students with a year property with unique values 1,2,3, may result in object {1: 10, 2: 10, 3: 11}
        //or same array with a gender property with unique values F and M, may result in object {F: 16, M; 15}.
        getResultCounts(sourceData: any[], countPropName: string) {
            //Create computed property from given prop name and use this as a destructuring assignment to reference the property in each entry of the array in reduce.
            let counts = sourceData.reduce((counts, { [countPropName]: countProp }) => {
                let propWithIdCheck = countProp ? countProp.id ? countProp.id : countProp : 0;

                counts[propWithIdCheck] = (counts[propWithIdCheck] || 0) + 1;
                return counts;
            }, {});

            return counts;
        }

        //Resets any existing counts on searchFilters array then adds new counts to any selected searchFilters, based on count and searchFilter having matching id.
        buildResultCountsForSelectedOptions(resultCounts: any, searchFilters: any[], selectedSearchFilterOptions: any[]) {

            this.resetCountPerSelectedOption(searchFilters);
            this.buildResultCountPerSelectedOption(resultCounts, searchFilters, selectedSearchFilterOptions);
        }

        //Assigns the counts to the corresponding selected search filter options, including string count property formatted in square brackets.
        buildResultCountPerSelectedOption(resultCounts: any, searchFilterOptions: any[], selectedSearchFilterOption: any[]) {
            //Loop through the property names
            Object.keys(resultCounts).forEach(resCount => {
                let resCountId = isNaN(parseInt(resCount)) ? resCount == 'true' : resCount;

                //Add count property / value to corresponding (based on id) filter option if selected.
                let filterOption = searchFilterOptions.find(f => f.id == resCountId && selectedSearchFilterOption?.some(s => s.id == f.id));

                if (filterOption) {
                    filterOption.count = resultCounts[resCount];
                    filterOption.countFormatted = `[${resultCounts[resCount]}]`;
                }
            });
        }

        //Clear count values from given searchfilter array entries. 
        resetCountPerSelectedOption(searchfilter: any[]) {
            searchfilter?.forEach(f => {
                f.count = null;
                f.countFormatted = null;
            });
        }
            
    })