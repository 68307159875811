<div [ngClass]="containerClass">
  <div id="sideMenuBar" class="side-menu-bar" (mouseleave)="resetMenu()">
    <!-- Submenu items -->
    <ul class="buddy-nav buddy-nav--compact js-buddy-nav sidemenu-bar" [ngClass]="subMenuClass" id="subMenuBarInner">
      <li class="buddy-nav__item buddy-nav__back mb-2" *ngIf="showMainMenuLink()">
        <div class="buddy-nav__link js-buddy-nav__link pointer" (click)="openModules()">
          <span class="buddy-nav__label ng-binding"><span [innerHTML]="'SB_Main_Menu' | translate"></span></span>
          <span
            class="buddy-nav__icon fas fa-level-up-alt"
            [ngbTooltip]="'SB_Main_Menu' | translate"
            placement="{{ getTooltipPlacement() }}"
            tooltipClass="buddy-nav__tooltip"
            [disableTooltip]="disableTooltips"
            container="body"></span>
        </div>
      </li>
      <ng-container
        *ngFor="let item of visibleSubMenuItems() | orderBy: 'sortOrder' as visibleSubMenuItems; let i = index"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{ item: item, i: i, menuItems: visibleSubMenuItems }"></ng-container>
    </ul>
    <!-- Main menu/module items -->
    <ul
      class="buddy-nav buddy-nav--compact js-buddy-nav sub-menu-bar-inner-modules"
      id="subMenuBarInnerModules"
      [ngClass]="modulesClass"
      tabindex="-1">
      <ng-container
        *ngFor="let item of mainMenuItems | orderBy: 'sortOrder'; let i = index"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{ item: item, i: i, menuItems: mainMenuItems }"></ng-container>
    </ul>
    <ul
      class="buddy-nav buddy-nav--compact js-buddy-nav sub-menu-bar-inner-modules"
      id="subMenuBarInnerPageMenuItems"
      *ngIf="showCurrentPageMenuItems"
      [ngClass]="currentPageMenuClass"
      tabindex="-1">
      <li class="buddy-nav__item buddy-nav__back mb-2" *ngIf="isStaff">
        <div class="buddy-nav__link js-buddy-nav__link pointer" (click)="hideCurrentPageMenuItems()">
          <span class="buddy-nav__label ng-binding"
            ><span [innerHTML]="expandedMenuItem?.label | translate"></span
          ></span>
          <span
            class="buddy-nav__icon fas fa-level-up-alt"
            [ngbTooltip]="'SB_Back' | translate"
            placement="{{ getTooltipPlacement() }}"
            tooltipClass="buddy-nav__tooltip"
            [disableTooltip]="disableTooltips"
            container="body"></span>
        </div>
      </li>
      <ng-container
        *ngFor="let item of currentPageMenuItems | orderBy: 'sortOrder'; let i = index"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{ item: item, i: i, menuItems: currentPageMenuItems }"></ng-container>
    </ul>
  </div>
</div>

<ng-template #menuItem let-item="item" let-i="i" let-menuItems="menuItems">
  <div *ngIf="showSeparator(menuItems, i)" role="separator" class="divider visible-xs"></div>
  <li
    *ngIf="item.route"
    class="buddy-nav__item"
    [hidden]="item.isHidden"
    routerLinkActive="buddy-nav__item--active"
    #rla="routerLinkActive"
    [ngClass]="{ 'visible-xs': item.isXsOnly || item.isRightMenu }">
    <a *ngIf="hasSubMenuItems(item)" [routerLink]="item.route">
      <div class="buddy-nav__link js-buddy-nav__link" (click)="expandSubMenuItem($event, item)">
        <ng-container
          [ngTemplateOutlet]="menuItemContent"
          [ngTemplateOutletContext]="{ item: item, rla: rla }"></ng-container>
      </div>
    </a>
    <a *ngIf="!hasSubMenuItems(item)" class="buddy-nav__link js-buddy-nav__link" [routerLink]="item.route">
      <ng-container
        [ngTemplateOutlet]="menuItemContentWithBadge"
        [ngTemplateOutletContext]="{ item: item, rla: rla }"></ng-container>
    </a>
  </li>
  <li *ngIf="item.externalUrl" class="buddy-nav__item" [ngClass]="{ 'visible-xs': item.isXsOnly || item.isRightMenu }">
    <a class="buddy-nav__link js-buddy-nav__link" [href]="item.externalUrl">
      <ng-container [ngTemplateOutlet]="menuItemContent" [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </a>
  </li>
</ng-template>

<ng-template #menuItemContentWithBadge let-item="item" let-rla="rla">
  <div class="badge-container" *ngIf="item.count">
    <sb-badge [message]="item.count" [isRounded]="true" badgeClass="danger"></sb-badge>
  </div>
  <ng-container
    [ngTemplateOutlet]="menuItemContent"
    [ngTemplateOutletContext]="{ item: item, rla: rla }"></ng-container>
</ng-template>

<ng-template #menuItemContent let-item="item" let-rla="rla">
  <span class="buddy-nav__label">
    <span [innerHTML]="item.label | translate"></span>
    {{ item.params }}
  </span>
  <span
    class="buddy-nav__icon {{ getIcon(item, rla?.isActive, true) }}"
    [innerHTML]="item.iconHtml"
    [ngbTooltip]="item.label | translate"
    placement="{{ getTooltipPlacement() }}"
    tooltipClass="buddy-nav__tooltip"
    [disableTooltip]="disableTooltips"
    container="body"></span>
</ng-template>
