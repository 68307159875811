import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlobImageService {

  assetUrlBase: string;

  constructor() {
      this.assetUrlBase =  environment.cdnBase + 'img';
  }

  getUndrawSvg(name:string) {
     return `${this.assetUrlBase}/icons/undraw/${name}.svg`;
  }
}
