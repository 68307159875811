angular.module('organisation.components.organisationList', [
    'organisation.services.organisationService'
]).component('organisationList', {
    bindings: {
        lastUpdated: '<',
        onSelectOrganisation: '&'
    },
    templateUrl: '/Scripts/app/organisation/components/organisation-list.template.html',
    controller: class OrganisationList {
        toastService: any;
        organisationService: any;

        onSelectOrganisation: any;

        selectedOrganisationId: any;
        organisations: any;

        static $inject = ['toastService', 'organisationService'];

        constructor (toastService, organisationService) {
            this.toastService = toastService;
            this.organisationService = organisationService;
        }

        $onInit() {
            this.selectedOrganisationId = null;
            this.getOrgansisations();
        }

        $onChanges(changes) {
            if (changes.lastUpdated && changes.lastUpdated.currentValue !== undefined) {
                this.getOrgansisations();
            }
        }

        getOrgansisations() {
            this.organisationService.getOrganisations().then(data => {
                this.organisations = data;
            }, () => {
                this.toastService.getError();
            });
        }

        selectOrganisation(organisation) {
            this.selectedOrganisationId = organisation.organisationId;
            if (this.onSelectOrganisation && typeof this.onSelectOrganisation === 'function') {
                this.onSelectOrganisation({organisation: organisation});
            }
        };
    }
});
