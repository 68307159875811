'use strict';

angular.module('transport.configuration.transportMethodAdd',
    [
        'transport.services.transportConfigurationMethodService',
        'shared.components.sbOnOffSwitch',
        'shared.components.sbModalHeader',
        'shared.services.simpleAlertService',
    ])
    .component('transportMethodAdd',
    {
        bindings: {
            resolve: '<',
            modalInstance: '<'
        },
        templateUrl: '/Scripts/app/transport/configuration/methods/transport-method-add.template.html',
        controller: [
            '$timeout',
            'transportConfigurationMethodService',
            'simpleAlertService',
            'iconService',
            function transportMethodAddController($timeout, transportConfigurationMethodService, simpleAlertService, iconService) {

                this.isSubmitted = false;
                this.iconSelectOpen = false;

                var resetSubmitButtons = function () {
                    $timeout(function () {
                        this.isSubmitted = false;
                    }.bind(this), 200);

                }.bind(this);

                this.transportMethod = {};

                this.sbTransportIcons = iconService.getTransportIcons();
                var defaultIcon = iconService.getTransportIcon('bus');

                this.transportMethod = { name: "", active: false , sbIconName: defaultIcon.iconName };

                this.cancel = function () {
                    this.modalInstance.dismiss('cancel');
                };

                var closeModal = function () {
                    this.modalInstance.close();
                }.bind(this);

                var alertAndResetOkButton = function (message) {
                    simpleAlertService.errorAlert(message);
                    resetSubmitButtons();
                };

                this.addTransportMethod = function () {
                    transportConfigurationMethodService.addMethod(this.transportMethod)
                        .then(function (responseData) {
                            if (responseData && responseData.name) {

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Saved',
                                    message: 'SB_Transport_Method_Added',
                                    messageTranslationParameters: { transportMethod: responseData.name },
                                    staticBackdrop: true
                                });

                                closeModal();
                            }
                            else {
                                alertAndResetOkButton();
                            }
                        })
                        .catch(function (responseData) {

                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message) {
                                message = { message: responseData.data.Message };
                            }

                            alertAndResetOkButton(message);
                        });
                }.bind(this);

                this.selectIcon = function(iconName)
                {
                    this.transportMethod.sbIconName = iconName;
                    this.iconSelectOpen = false;
                }.bind(this);

            }
        ]
    });
