'use strict';

angular.module('ccasignupModule').component('ccaSignUpDetailsAAManageEventsDetail', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-detailsaa-manage-events-detail.template.html',
    bindings: {
        selectedSignUp: '<',
        selectedSignUpType: '<',
    },
    controller:
    [
        '$scope',
        '$state',
        '$filter',
        '$q',
        'calendarEventCreationRuleService',
        'signUpRepository',
        function ($scope,
                  $state,
                  $filter,
                  $q,
                  calendarEventCreationRuleService,
                  signUpRepository
                  )
        {

            this.$onChanges = (changes) => {
                if (changes.selectedSignUp?.currentValue) {
                    $scope.signUp = this.selectedSignUp;
                }
                if (changes.selectedSignUpType?.currentValue) {
                    $scope.ccaSignUpType = this.selectedSignUpType;
                }
            }

            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.loaded = false;
            if (!$state.params.signUpId) {
                $scope.loadingError = "SB_Error_loading";
                $scope.loaded = true;
                return;
            }

            $scope.signUpId = $state.params.signUpId;
            $scope.routeCreateNew = $state.params.createNew;
            $scope.routeSelectedRuleId = +$state.params.selectedItemId;
            $scope.routeSelectedCopyId = +$state.params.copyItemId;

            $scope.rules = [];

            var promises = [
                calendarEventCreationRuleService.getCalendarEventCreationRulesForSignUp($scope.signUpId),
                signUpRepository.getSignUpGroups($scope.signUpId),
                signUpRepository.getLocations(),
                signUpRepository.getOrganisers()
            ];

            $q.all(promises).then(function (responses) {
                resolveGetCalendarEventCreationRulesForSignUp(responses[0]);
                $scope.signUpGroups = responses[1];
                $scope.locations = responses[2];
                $scope.organisers = responses[3];
                $scope.loaded = true;
            });

            var resolveGetCalendarEventCreationRulesForSignUp = function (data) {
                if (!data.isError) {
                    $scope.rules = data;

                    var i, rule;

                    if ($scope.routeCreateNew) {
                        $scope.resetForm();
                    }
                    else if ($scope.routeSelectedRuleId > 0) {

                        console.log('ccaSignUpDetailsAAManageEventsController/$scope.routeSelectedRuleId > 0', $scope.routeSelectedRuleId);
                        rule = null;

                        for (i = $scope.rules.length; i--;) {
                            if ($scope.rules[i].ccaCalendarEventCreationRuleId === $scope.routeSelectedRuleId) {
                                rule = $scope.rules[i];
                                break;
                            }
                        }

                        if (rule !== null) {
                            console.log('ccaSignUpDetailsAAManageEventsController/found rule');
                            $scope.selectedRule = rule;
                        }
                        else {
                            console.log('ccaSignUpDetailsAAManageEventsController/did not find rule');
                            $state.go('ccaDetailsAA.manageEventsAdd');
                        }

                    }
                    else if ($scope.routeSelectedCopyId > 0) {

                        console.log('ccaSignUpDetailsAAManageEventsController/$scope.routeSelectedCopyId > 0', $scope.routeSelectedCopyId);

                        rule = null;

                        for (i = $scope.rules.length; i--;) {
                            if ($scope.rules[i].ccaCalendarEventCreationRuleId === $scope.routeSelectedCopyId) {
                                rule = $scope.rules[i];
                                break;
                            }
                        }

                        if (rule !== null) {
                            console.log('ccaSignUpDetailsAAManageEventsController/found rule');
                            var entity = angular.copy(rule);

                            entity.ccaCalendarEventCreationRuleId = 0;
                            entity.statusId = 0;
                            entity.ccaCalendarEventCreationRuleId = 0;
                            $scope.selectedRule = entity;

                        }
                        else {
                            console.log('ccaSignUpDetailsAAManageEventsController/did not find rule');
                            $state.go('ccaDetailsAA.manageEventsAdd');
                        }

                    }
                }
            }

            $scope.handleRuleSave = function (rule, skipRedirect) {
                var updatePerformed = false;

                for (var i = $scope.rules.length; i--;) {
                    if ($scope.rules[i].ccaCalendarEventCreationRuleId == rule.ccaCalendarEventCreationRuleId) {
                        // we have a match- update the record

                        angular.copy(rule, $scope.rules[i]);

                        updatePerformed = true;
                        break;
                    }
                }

                if (!updatePerformed) {
                    $scope.rules.push(rule);
                }

                if (!skipRedirect)
                    $state.go('ccaDetailsAA.manageEvents');
            };

            $scope.handleCancel = function () {
                $state.go('ccaDetailsAA.manageEvents');
            };

            $scope.resetForm = function()
            {
                //create a timezone agnostic date for comparison
                var todaysDate = new Date();
                var compareDate = new Date(todaysDate.getUTCFullYear() + '-' + ('0' + (todaysDate.getUTCMonth()+ 1)).slice(-2) + '-' + ('0' + todaysDate.getUTCDate()).slice(-2));

                var startDate = moment.utc($scope.signUp.periodFrom).startOf('day').toDate();
                var endDate = moment.utc($scope.signUp.periodTo).endOf('day').toDate();

                $scope.selectedRule = {
                    ccaCalendarEventCreationRuleId: 0,
                    title: $filter('translate')('SB_Session'),
                    startDate: compareDate < startDate ? startDate : compareDate ,
                    endDate: compareDate < endDate ? endDate : compareDate,
                    startHour : $scope.signUp.defaultStartHour,
                    startMin : $scope.signUp.defaultStartMin,
                    endHour : $scope.signUp.defaultEndHour,
                    endMin : $scope.signUp.defaultEndMin,
                    ccaSignUpId : $state.params.signUpId,
                    teamId : $state.params.signUpGroupId,
                    organiserPersonId : 0,
                    locationId : 0,
                    ccaCostType : 0,
                    ccaPrice : 0,
                    transactionApplyTypeId : 2,
                    daysOfWeek : 31,
                    eventLength : ($scope.ccaSignUpType.supportSlots == true) ? 10 : 0,
                    statusId : 0,
                    size : ($scope.ccaSignUpType.supportSlots == true) ? 1 : 0,
                    calendarEventCategoryTypeId: -1,
                    lastUpdated: new Date(),
                    parentTeacherConferenceLocationTypeId: $scope.signUp.parentTeacherConferenceLocationTypeId
                };

                if ($scope.ccaSignUpType && $scope.signUp && $scope.signUp.isPTC)
                {
                    $scope.selectedRule.title = $filter('translate')('SB_Meeting');
                    $scope.selectedRule.size = 1;
                    $scope.selectedRule.ccaCostType = 0;
                    $scope.selectedRule.transactionApplyTypeId = 0;
                    $scope.selectedRule.ccaPrice = 0;
                    $scope.selectedRule.upfrontCostPerPerson = 0;
                    $scope.selectedRule.calendarEventCategoryTypeId = -1;
                }
            };

            }
        }
    ]});
