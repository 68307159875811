'use strict';

angular.module('balanceAdmin.components.massAdjustmentQuickButtons',
    ['shared.components.sbModalHeader',])
    .component('massAdjustmentQuickButtons',
        {
            bindings: {
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/components/mass-adjustment-quick-buttons.template.html',
            controller: [
                function () {
                    this.adjustOption = 1;
                    this.customValue = '0.00';

                    this.quickAdjust = function () {
                        this.modalInstance.close({ adjustOption: this.adjustOption, customValue: this.customValue });
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);
                }]
        });