angular.module('shared.components.sbTelephoneInput', [
    'shared.components.sbSelect',
    'shared.services.countryService'])
    .component('sbTelephoneInput',
    {
        bindings: {
            model: '<',
            parentId: '<',
            dialCountry: '<',
            onChange: '&',
            fieldId: '@',
            isRequired: '<',
            isDisabled: '<'
        },
        templateUrl: '/Scripts/app/shared/components/sb-telephone-input.template.html',
        controller: class SbTelephoneInputCtrl {

            // Dependencies
            $timeout: any;
            formElements: any;
            countryService: any;

            // Bindings
            model: any;
            dialCountry: any;
            onChange: any;
            fieldId: string;
            isRequired: boolean;
            isDisabled: boolean;

            // Variables
            phoneCountryCode: string;
            countries: Array<any>;
            internationalPrefix: string;

            static $inject = ['$timeout', 'formElements', 'countryService'];

            constructor ($timeout, formElements, countryService) {
                this.$timeout = $timeout;
                this.formElements = formElements;
                this.countryService = countryService;
                this.internationalPrefix = '+';
            }

            setOptions(options) {
                if (Array.isArray(options) && options.length > 0) {
                    this.countries = options;
                    this.phoneCountryCode = '';

                    this.dialCountry = this.dialCountry || '';

                    this.model = this.model || '';

                    if (!this.dialCountry) {
                        this.dialCountry = options[0].id;
                    }

                    this.onDialCountryChanged();
                    this.onChangeNumber();
                }
            }

            $onInit() {
                this.onChangeNumber()
            }

            $onChanges(changes) {
                if (changes.dialCountry && changes.dialCountry.currentValue !== undefined) {
                    this.onDialCountryChanged();
                }
                if ((changes.parentId && changes.parentId.currentValue !== undefined) && changes.model?.currentValue === undefined && this.countries?.length > 0) {
                    this.dialCountry = this.countries[0].id;
                    this.onDialCountryChanged;
                }
                if (changes.model && changes.model.currentValue !== undefined) {
                    this.onChangeNumber();
                }
            };

            onDialCountrySelectChanged(id, value) {
                this.dialCountry = value;
                this.onDialCountryChanged();
            }

            disableDialCountrySelect() {
                return this.model && this.model.substring(0,1) !== this.internationalPrefix;
            }

            onDialCountryChanged() {

                if (this.disableDialCountrySelect()) {
                    return;
                }

                if (this.countries && this.dialCountry) {

                    this.$timeout(() => {

                        const newPhoneCountries = this.countries.filter(country => {
                            return country.id === this.dialCountry;
                        });

                        if (newPhoneCountries.length === 0) {
                            return;
                        }

                        const newPhoneCountryCode = newPhoneCountries[0].phoneCountryCode;
                        const firstCharacter = this.model.substring(0,1) || '';

                        if (this.model && firstCharacter === this.internationalPrefix) {
                            this.model = this.model.replace(firstCharacter + this.phoneCountryCode, firstCharacter + newPhoneCountryCode);
                        }
                        else {
                            this.model = this.internationalPrefix + newPhoneCountryCode;
                        }

                        this.phoneCountryCode = newPhoneCountryCode;

                    });

                }

            };

            onChangeNumber() {
                if (this.model && this.countries) {
                    this.$timeout(() => {
                        const firstCharacter = this.model.substring(0,1);

                        if (firstCharacter === this.internationalPrefix) {
                            // Check for countries matching inputted dial code
                            const matchedCountries = this.countries.filter(country => {
                                return this.model.replaceAll(this.internationalPrefix, '').indexOf(country.phoneCountryCode) == 0;
                            });
                            // If multiple countries with same code
                            if (matchedCountries.length > 1) {
                                const defaultCountries = matchedCountries.filter(country => {
                                    return country.isPhoneDefault;
                                })
                                // Set the 'isDefault' option automatically if one exists - eg GB over Isle of Man
                                if (defaultCountries.length > 0) {
                                    this.dialCountry = defaultCountries[0].id;
                                // Else just set first matching country
                                } else {
                                    this.dialCountry = matchedCountries[0].id;
                                }
                            }
                            // Else just set only matching country
                            else if (matchedCountries.length == 1) {
                                this.dialCountry = matchedCountries[0].id;
                            }
                        }
                        let model = this.model;
                        // If value is just dial code then don't save any value
                        const phoneCountryCode = this.countries?.filter(country => {
                          return country.id === this.dialCountry;
                        })[0].phoneCountryCode || this.phoneCountryCode;
                        if (model === this.internationalPrefix + phoneCountryCode) {
                            model = null;
                        }
                        this.phoneCountryCode = phoneCountryCode;
                        this.onChange({value: model});
                    });
                }
            };

        }
    });
