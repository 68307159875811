<div class="modal-header"
     [ngClass]="headerClass ? ('modal-header-' + headerClass) : ''">
    <h3 class="p-large">
        <i *ngIf="iClass" class="{{iClass}} mr-3"></i>
        <span [innerHTML]="modalTitle | translate"
              [ngClass]="{'hidden-xs' : modalTitleXs}"></span>
        <span [innerHTML]="modalTitleXs | translate"
              *ngIf="modalTitleXs"
              class="hidden-lg hidden-md hidden-sm"></span>
        <span *ngIf="detail">
            -
            <strong>{{detail | translate}}</strong>
        </span>
        <sb-badge *ngIf="badgeText"
                  [badgeClass]="badgeClass"
                  [iconName]="badgeIcon"
                  [message]="badgeText"
                  class="ml-3"></sb-badge>
    </h3>
    <sb-button *ngIf="!isCloseHidden"
                buttonClass="link"
                [isAutoSize]="true"
                [isDisabled]="isCloseDisabled"
                iconName="close"
                (onClick)="close()">
    </sb-button>
</div>