'use strict';

angular.module('shared.components.sbAutoComplete',
    [
        'shared.services.arrayService'
    ])
    .component('sbAutoComplete',
        {
            bindings: {
                placeHolderText: '@',
                noResultText: '@',
                minLength: '@',
                lookupResultProp: '@',
                lookupFn: '&',
                lookupFnArgs: '<',
                onSelectFn: '&',
                isDisabled: '<',
                disableCaching: '<',
                isRequired: '<',
                modelValue: '=?'
            },
            templateUrl: '/Scripts/app/shared/components/sb-auto-complete.template.html',
            controller:
                [
                    '$filter',
                    'arrayService',
                    function ($filter, arrayService) {

                        this.$onInit = function () {
                            if (!this.lookupFnArgs) {
                                this.lookupFnArgs = [];
                            }

                            if (typeof this.modelValue === 'undefined') {
                                // model value wasn't passed in, use an internal one instead.
                                this.modelValue = null;
                            }
                        }

                        this.selectedValue = null;

                        this.lastMappedArgs = null;
                        this.lastValue = null;
                        this.autoCompleteData = [];
                        this.loadingPromise = null;
                        this.lookupError = false;

                        this.lookup = function (value) {
                            this.selectedValue = null;

                            this.mappedArgs =
                                this.lookupFnArgs.map(function (a) {
                                    return typeof a == 'function'
                                        ? a()
                                        : a;
                                });

                            if (this.disableCaching) {
                                return this.lookupFn()
                                    .apply(null, this.mappedArgs.concat([value]))
                                    .then(function(val) {
                                        this.lookupError = false;
                                        this.isLoading = false;
                                        return val;
                                    }.bind(this))
                                    .catch(function(err) {
                                        console.log('Autocomplete look-up failed: ', err);
                                        this.lookupError = true;
                                        this.isLoading = false;
                                    }.bind(this));
                            }

                            // is this the same or a refinement of the previous query?
                            // e.g. if already searched for 'Tri' then we can use those results for 'Trip'.
                            // Other arguments must match too.
                            if (value.indexOf(this.lastValue) > -1 &&
                                arrayService.equals(this.mappedArgs, this.lastMappedArgs)) {

                                // Has the previous query completed?
                                if (this.loadingPromise == null) {
                                    // Perform in-memory filter of the previous query results.
                                    return $filter('filter')(this.autoCompleteData, value);
                                }

                                return this.loadingPromise.then(function () {
                                    return $filter('filter')(this.autoCompleteData, value);
                                }.bind(this));
                            }

                            // We need to do a fresh lookup

                            this.lastValue = value;
                            this.lastMappedArgs = this.mappedArgs;
                            this.autoCompleteData = [];

                            this.loadingPromise = this.lookupFn()
                                .apply(null, this.mappedArgs.concat([value]))
                                .then(function(val) {
                                    this.lookupError = false;
                                    this.autoCompleteData = val;
                                    this.isLoading = false;
                                    return val;
                                }.bind(this))
                                .catch(function(err) {
                                    console.log('Autocomplete look-up failed: ', err);
                                    this.lookupError = true;
                                    this.isLoading = false;
                                }.bind(this));

                            return this.loadingPromise;
                        }.bind(this);

                        this.onSelectInternal = function ($item, $model, $label)
                        {
                            this.modelValue = $label;
                            this.selectedValue = true;
                            this.onSelectFn()($item, $model, $label);
                        }.bind(this);

                        this.isRequiredInternal = function () {
                            return ;
                        }.bind(this);
                    }
                ]
        });