import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ButtonClasses, UiClasses } from '@sb-shared/types/ui-classes';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EditorConfig } from './editor-config';
import { Options } from './filter';
import { Observable } from 'rxjs';
import { SbIcon } from './sb-icon';

export interface ValidationState {
  iconName?: string;
  textClass?: string;
  label?: string;
}
export interface WizardField {
  // Label to show next to field
  label?: string;
  // Type - eg text, select, textarea, or something more specific such as game summary
  type?: FieldType;
  // Id, which gets applied as a property of wizard data object
  id?: string;
  // Text when switch set to true
  trueLabel?: string;
  // Text when switch set to false
  falseLabel?: string;
  // Input placeholder
  placeholder?: string;
  // Default value of field
  defaultValue?: any;
  // Default to a random value in options list
  isRandomDefault?: boolean;
  // List of values to randomly select as value
  suggestions?: any[];
  // Required by form
  isRequired?: boolean;
  // Number of columns in wizard 10 column flex layout
  cols?: number;
  //** Number of rows in textarea */
  rows?: number;
  // Tooltip text shown inside 'i' circle
  tooltip?: string;
  // Rules for required, eg [ { property: NAME }, values: ['1', '2', '3'], minLength: 1, maxLength: 5 ]
  requiredIf?: FieldRules;
  // Rules for required - but check for match to any rather than all
  requiredIfAny?: FieldRules;
  // Set field as disabled
  isDisabled?: boolean;
  // Rules to enable - same format as requiredIf
  enableIf?: FieldRules;
  // Rules to display - same format as requiredIf
  displayIf?: FieldRules;
  // Rules to hide - same format as requiredIf
  hideIf?: FieldRules;
  // Set field as hidden
  isHidden?: boolean;
  // Show loading spinner until options are added by wizardService.addDataToWizardTab, eg wizardService.addDataToNewTabs
  loadOptions?: boolean;
  // Name of id property in options list
  idProp?: string;
  // Name of label property in options list
  labelProp?: string;
  // Name of icon property in options list
  iconProp?: string;
  // Options for field
  options?: Options;
  // Filtered enabled options in field - controlled by option property disableIf and used by selects to hide disabled options
  enabledOptions?: Options;
  // Height for certain field types
  height?: number;
  // Whether to use colour icons in visual select dropdown
  isColourIcons?: boolean;
  // Class name for field
  className?: string;
  // UI classname such as 'primary', 'danger', to apply to text/badge/alert
  uiClassName?: UiClasses;
  // Description to show next to field
  description?: string;
  // Editor config for Froala in textarea (Content) input
  editorConfig?: EditorConfig;
  // Whether button input is button group
  isGroup?: boolean;
  // HTML elements to show in field area
  elements?: any[];
  // Class for button, such as 'primary'
  buttonClass?: ButtonClasses;
  // Name for icon shown - currently just shown for readonly type.
  icon?: SbIcon;
  // List of icons
  iconList?: SbIcon[];
  // Only show icon in button on smaller devices
  xsIconOnly?: boolean;
  // Whether select is in grid layout
  isGrid?: boolean;
  // Primary option ids in select - options to be grouped together at top of options
  primaryOptionIds?: number[];
  // For when an item in a list should be applied to parent object - not currently applied
  isFlat?: true;
  // String minlength validation
  minlength?: number;
  // String maxlength validation
  maxlength?: number;
  // String pattern validation
  pattern?: string;
  // Number validation for allowing 0 value
  allowZero?: boolean;
  // Number min validation
  min?: number;
  // Number max validation
  max?: number;
  // Allow multiple options to be selected
  isMultiOption?: boolean;
  // Show note below fields
  note?: string;
  // Inline layout
  isInline?: boolean;
  // Show a message with a toggle to show/hide field
  toggleMessage?: string;
  // Set togglable field to be shown
  isToggledOn?: boolean;
  // Settings for sb-multiselect-dropdown
  dropdownSettings?: IDropdownSettings;
  // No padding in layout
  noPadding?: boolean;
  // Currently just used in person picker (Users form element) for member type ids
  subTypeIds?: number[];
  // Show specific message when no options available
  emptyOptionsMessage?: string;
  // Summary of a different field with control to that field
  summaryOf?: string;
  // Message inside a button
  buttonMessage?: string;
  // Item in list to be locked - used by person picker (Users form element)
  lockedItemId?: number;
  // Date format for label
  dateFormat?: string;
  // Ignore wizard readonly value for this field
  ignoreReadOnly?: boolean;

  // Providers - other field to get one of the following from
  maxProvider?: string;
  minProvider?: string;
  optionProvider?: string;
  defaultProvider?: string;
  defaultProviderStart?: string;
  defaultProviderEnd?: string;
  dayProvider?: string;

  // Set defined or other field provided limits on a date range picker field
  dateRangeLimit?: {
    minLimit?: DateRangeLimit,
    maxLimit?: DateRangeLimit,
  }

  // Whether to show field placeholder on confirmation page when value unset, otherwise just shows 'Not set'
  confirmPlaceholder?: boolean;
  // Field to check value matches against in validation
  matchField?: string;
  // Series of regex patterns to match against - eg password needing certain characters
  patternList?: { regexValue: string, label: string }[];
  // Unfinished work to show a table with a series of values based on field value
  isMetaTableColumn?: boolean;
  // Ids of child properties which get used differently by different field types
  secondaryIds?: string[];
  // Content with not HTML encoding but using same Froala component
  isPlainText?: boolean;

  // Used to group together fields into objects on exported data
  nestedField?: string[];

  // Config related to options for multi select
  optionsConfig?: WizardFieldOptionsConfig;
  // Config related to sub wizard
  subWizardConfig?: WizardFieldSubWizardConfig;

  updateValueOnChange?: {
    fieldId: string;
    updateValueFn: (data: any) => any;
  }
}

export declare type WizardFields = WizardField[];

export interface WizardFieldOptionsConfig {
  // Type used to group fields
  type: string;
  // Function used to get options for multi select
  getOptionsFn: (forceRefresh?: boolean) => Observable<any[]>;
}

export interface WizardFieldSubWizardConfig {
  // Type used to group fields
  type: string;
  // Function used to save sub wizard
  saveFn: (data: any) => Observable<any>;
  // Get value to set on field after saving sub wizard
  getSaveResponseValueFn: (response: any) => any;
  // Function to get sub wizard tabs
  getSubWizardTabsFn: () => WizardTabs;
  // Function to load single record for editing
  loadRecordFn: (id: number) => Observable<any>;

  // SubWizard - Ability to add an item for a field using a smaller wizards which pops up over the current one
  subWizardTabs?: WizardTabs;
  subWizardNewTitle: string;
  subWizardEditTitle: string;
  subWizardDiscardTitle: string;
  subWizardDiscardMessage: string;
  subWizardSavingMessage: string;
}

export interface WizardTab {
  // Id for this tab
  id: number;
  // Tab name to show in progress list at top (or above form on small devices)
  name: string;
  // Icon to show in progress list at top
  iconName: string;
  // Fields for this tab
  fields: WizardFields;
  // Hide tab
  isHidden?: boolean;
  // Image name used to get svg
  image?: string;
  // Svg image to show on left on larger devices
  svg?: string;
  // Name for the Angular reactive forms form group
  formGroupName: string;
  // Description to show above form
  description?: string;
  // Tooltip to show at top of form
  descriptionTooltip?: string;
  // Whether data for this tab should be a nested object
  isNest?: boolean;
  // Whether tab has been completed
  isDone?: boolean;
  // Whether tab has been set to hidden
  isTemplateHidden?: boolean;
  // Whether navigating to this tab is disabled - due to invalid fields on preceding tab
  isDisabled?: boolean;
  // Whether this is the confirmation tab
  isConfirmation?: boolean;
  // Whether tab should be skipped from the confirmation page
  skipConfirmation?: boolean;
}

interface FieldType {
  // Id of type
  id?: number;
  // Whether input is text
  isText?: boolean;
  // Type attribute for input
  typeLabel?: string;
  // Default string minlength in validation
  minlength?: number;
  // Default string maxlength in validation
  maxlength?: number;
  // Default string pattern in validation
  pattern?: RegExp;
  // Default string pattern validation error message
  patternErrorMessage?: string;
  // Default number max in validation
  max?: number;
  // Whether field is just for show - won't be validated or included in data
  isDecorative?: boolean;
  // Icon to show next to input, such as '@' for twitter handles
  addon?: string;
  // Whether field type is a kind of select
  isSelect?: boolean;
  // Whether field type is a visual select
  isVisualSelect?: boolean;
  // Whether field type is a kind of date picker
  isDatePicker?: boolean;
  // Whether date picker just handles a single date
  isSingleDate?: boolean;
  // Whether date picker handles a date (as opposed to time)
  hasDate?: boolean;
  // Whether date picker handles a time (as opposed to date)
  hasTime?: boolean;
  // Whether built in Angular reactive forms validation is not used but validation handled by our code
  manualValidation?: boolean;
  // Whether field type is an sb-multiselect-dropdown
  isMultiSelectComponent?: any;
  // If includesLabel set to false then we show a label inside the toggle message area - eg Users form element
  includesLabel?: boolean;
  // Whether to skip field from confirmation page
  skipConfirmation?: boolean;
  // Secondary keys that values get applied to for this field type - can then be used by field.secondaryIds
  secondaryKeys?: string[];
  // Whether or not the above relationship is reversed
  reverseSecondaryKeys?: boolean;
  // Whether field is readonly
  isReadOnly?: boolean;
  mappingFunc?: (data: any) => any;
}

export declare type WizardTabs = WizardTab[];
export interface WizardModalSettings {
  tabs: WizardTabs;
  title: string;
  data: any;
}

export const WizardModalSettings: NgbModalOptions = {
  ariaLabelledBy: 'modal-wizard',
  modalDialogClass: 'modal-wizard',
  backdrop: 'static',
  keyboard: false
}

export enum CrossFieldValidationEnum {
  Min = 1,
  Max = 2,
  MinHoursMinutes = 3,
  MaxHoursMinutes = 4,
  Match = 5
}

export interface FieldRule {
  property: string;
  values?: any[];
  valuesProvider?: string;
  valuesProviderFilterFn?: any;
  minLength?: number;
  maxLength?: number;
}

export interface FieldRuleListItem {
  label: string;
  isComplete: boolean;
}

// Used to set defined or other field provided min or max limits on a date range picker field
export interface DateRangeLimit {
  //Set limit based on another date field value
  providerFieldId?: string,
  //If true uses the selected start value of other field if date range, otherwise uses end date
  useProviderStartValue?: boolean,
  //Set defined limit
  limitValue?: Date
}

export declare type FieldRules = FieldRule[];
export declare type FieldRuleList = FieldRuleListItem[];
