'use strict';

angular.module('receptionModule').controller('receptionDashboardController', [
    '$scope',
    '$state',
    '$filter',
    '$http',
    'receptionService',
    'iconService',
    function ($scope, $state, $filter, $http, receptionService, iconService) {


        var tabDisplayOptionsLoaded = false;

        var tabVisibility = {};

        receptionService.getTabVisibility()
            .then(function(data)
            {
                for (var key in data)
                {
                    tabVisibility[key] = data[key];
                }
                tabDisplayOptionsLoaded = true;
                setupPage();
            });

        var setupPage = function()
        {



            if (!tabDisplayOptionsLoaded)
                return;

            $scope.menuItems = [];

            if (tabVisibility.registersEvents)
                $scope.menuItems.push(
                    {
                        uisref: '.events',
                        title: 'SB_Registers_Slash_Events',
                        icon: iconService.getIcon('events'),
                        sortOrder: $scope.menuitems
                    });

            if (tabVisibility.sendCommunications)
                $scope.menuItems.push(
                    {
                        uisref: '.messagecenter',
                        title: 'SB_Send_Communication',
                        icon: iconService.getIcon('sendCommunications')
                    });

            if (tabVisibility.regGroups)
                $scope.menuItems.push(
                    {
                        uisref: '.reggroups',
                        title: 'SB_Reg_Groups',
                        icon: iconService.getIcon('reggroups')
                    });

            if (tabVisibility.groups)
                $scope.menuItems.push(
                    {
                        uisref: '.allgroups',
                        title: 'SB_Groups',
                        icon: iconService.getIcon('allgroups')
                    });

            if (tabVisibility.users)
                $scope.menuItems.push(
                    {
                        uisref: '.users',
                        title: 'SB_Users',
                        icon: iconService.getIcon('users')
                    });

            if (tabVisibility.locations)
                $scope.menuItems.push(
                    {
                        uisref: '.locations',
                        title: 'SB_Locations',
                        icon: iconService.getIcon('locations')
                    });

            if (tabVisibility.joinRequests)
                $scope.menuItems.push(
                    {
                        uisref: ".joinrequests",
                        title: "SB_Join_Requests",
                        icon: iconService.getIcon("joinrequests")
                    });

            if (tabVisibility.reports)
                $scope.menuItems.push(
                    {
                        uisref: '.reports',
                        title: 'SB_Reports',
                        icon: iconService.getIcon('reports')
                    });

            if (tabVisibility.staffSchedule)
                $scope.menuItems.push(
                    {
                        uisref: '.staffschedule',
                        title: 'SB_Staff_Schedule',
                        icon: iconService.getIcon('staffschedule')
                    });

            if (tabVisibility.attendance)
                $scope.menuItems.push(
                    {
                        uisref: '.attendance',
                        title: 'SB_Atendance',
                        icon: iconService.getIcon('attendance')
                    });

            if (tabVisibility.transport)
                $scope.menuItems.push(
                    {
                        uisref: '.transport',
                        title: 'SB_Transport',
                        icon: iconService.getIcon('transport')
                    });

            receptionService.getLookupData().then(function(data)
            {
                console.log('reception-dashboard-controller/receptionService.getLookupData/data', data);

                $scope.showHouseClass = (data.organisationTypeId === 2);
                $scope.misManaged = data.misManaged;
                $scope.isOrganisationAdmin = data.isOrganisationAdmin;
                $scope.isStaff = data.isStaff;
                $scope.isOrganisationSuperAdmin = data.isOrganisationSuperAdmin;
                $scope.schoolYearGroups = data.schoolYearGroups;
                $scope.schoolHouses = data.schoolHouses;
                $scope.schoolClasses = data.schoolClasses;
                $scope.messageCenterAvailable = data.messageCenterAvailable;
                $scope.currentPersonId = data.currentPersonId;
                $scope.isStatus365 = data.isStatus365;

                if ($scope.isStatus365)
                {
                    $scope.menuItems.push(
                        {
                            uisref: '.contacts',
                            title: 'SB_Contacts',
                            icon: iconService.getIcon('contacts')
                        });
                    $scope.menuItems.push(
                        {
                            uisref: '.tasks',
                            title: 'SB_Tasks',
                            icon: iconService.getIcon('tasks')
                        });
                }
            });
        };

        setupPage();
    }
]);