import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiClasses } from '@sb-shared/types/ui-classes';

@Component({
  selector: 'sb-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent {

  @Input() modalTitle: string;
  @Input() modalTitleXs: string;
  @Input() detail: string;
  @Input() headerClass: UiClasses;
  @Input() badgeText: string;
  @Input() badgeClass: UiClasses;
  @Input() badgeIcon: string;
  @Input() isCloseHidden: boolean;
  @Input() isCloseDisabled: boolean;
  @Input() iClass: string;
  @Input() fullPageOnApp: boolean;
  @Output() onClose = new EventEmitter<void>();
  
  close(): void {
    this.onClose.emit();
  }

}
