"use strict";

angular.module('receptionModule').component("receptionReports",  {
    templateUrl: '/Scripts/app/reception/controllers/receptionreports.html?v=', // + window.EveryBuddy.Version,
    controller: [
    "$scope", function ($scope) {

        $scope.selectedTab = 0;

    }
]});
