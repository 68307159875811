import { Injectable } from '@angular/core';
import { ToastService } from '@sb-core/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor(private toast: ToastService) { }

  copyToClipboard(string: string) {
    if (string) {
      navigator.clipboard.writeText(string);
      this.toast.saveSuccess(string, 'SB_Copied');
    }
    else {
      this.toast.saveError(string, 'SB_Error');
    }
  }
}
