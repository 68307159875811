'use strict';

angular.module('groups.services.groupService', [])
    .factory('groupService', [
        '$http', '$q', '$cacheFactory', '$filter',
        function ($http, $q, $cacheFactory, $filter) {

            var properUrlBase = '/webapi/WebGroup/';
            var urlBase = '/Reception/'; // should move all this to a group controller..
            var webapiUrlBase = '/webapi/WebTeam/'; // should move all this to a group controller..
            var repository = {};

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('groupServiceCache');

            repository.getPersonGroups = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPersonGroups', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getPersonGroupsCsvUrl = function (personId) {
                return urlBase + 'GetPersonGroupsCsv?personId=' + personId;
            };

            repository.getCaseStatuses = function () {
                return $http.get(urlBase + 'GetCaseStatuses', { cache: repoCache })
                    .then(function (response) { return response.data; })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            repository.getPersonAvailableGroups = function (personId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPersonAvailableGroups', { params: { personId: personId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getGroupLookupData = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetGroupLookupData')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.addPersonToGroup = function (teamId, personId, roleId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'AddPersonToGroup', { teamId: teamId, personId: personId, roleId: roleId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getRegGroups = function () {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetRegGroups')
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getAllGroups = function (includeArchived) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetAllGroups', { params: { includeArchived: includeArchived } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getGroupDetails = function (groupId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetGroupDetails', { params: { teamId: groupId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.saveGroupDetails = function (dto) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'SaveGroupDetails', { dto: dto })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };




            repository.markGroupDocumentsScanned = function (groupId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'MarkGroupDocumentsScanned', { teamId: groupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.markGroupDocumentsShredded = function (groupId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'MarkGroupDocumentsShredded', { teamId: groupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };




            repository.archiveGroup = function (groupId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'ArchiveGroup', { teamId: groupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.reactivateGroup = function (groupId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'ReactivateGroup', { teamId: groupId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getPupilsInGroup = function (groupId) {
                var deferred = $q.defer();
                $http.get(urlBase + 'GetPupilsInGroup', { params: { teamId: groupId } })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.isCurrentPersonInGroup = function (groupId) {
                return $http.get(webapiUrlBase + 'IsCurrentPersonInTeam', { params: { teamId: groupId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.areAnyOfCurrentPersonsChildrenInTeam = function (groupId) {
                return $http.get(webapiUrlBase + 'AreAnyOfCurrentPersonsChildrenInTeam', { params: { teamId: groupId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getStaffInGroup = function (groupId, allowCaching) {
                var deferred = $q.defer();

                var httpOptions = { params: { teamId: groupId } };

                if (allowCaching) {
                    httpOptions.cache = repoCache;
                }

                $http.get(urlBase + 'GetStaffInGroup', httpOptions)
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.removePersonGroup = function (teamId, personId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'RemovePersonGroup', { teamId: teamId, personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.removeGroupPerson = function (teamId, personId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'RemoveGroupPerson', { teamId: teamId, personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.removeAdminGroup = function (teamId, personId) {
                repoCache.removeAll();
                var deferred = $q.defer();
                $http.post(urlBase + 'RemoveAdminGroup', { teamId: teamId, personId: personId })
                    .then(function (response) { deferred.resolve(response.data); })
                    .catch(function (e) { deferred.reject(e); });
                return deferred.promise;
            };

            repository.getGroupsByPartialName = function (includeArchived, groupName) {
                var params = {
                    groupName: groupName,
                    includeArchived: includeArchived
                };

                return $http.get(properUrlBase + 'GetGroupsByPartialName', { params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getGroupsWithChargeEventsByPartialNameByDateRange = function (eventFrom, eventTo, groupName) {
                var params = {
                    groupName: groupName,
                    eventFrom: eventFrom,
                    eventTo: eventTo,
                };

                return $http.get(properUrlBase + 'GetGroupsWithChargeEventsByPartialNameByDateRange', { params: params })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getPeopleInGroup = function (groupId, isTeam) {
                return $http.get(properUrlBase + 'GetPeopleInGroup', { params: { groupId: groupId, isTeam: isTeam } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getChargeEventsByGroup = function (groupId, isTeam) {
                return $http
                    .get(properUrlBase + 'GetChargeEventsByGroup', {
                        params:
                        {
                            groupId: groupId,
                            isTeam: isTeam
                        }
                    })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.getFeeOnlyEventsForGroup = function (groupId, isTeam) {
                return $http
                    .get(properUrlBase + 'GetFeeOnlyEventsForGroup', { params: { groupId: groupId, isTeam: isTeam}})
                    .then(function (response) {
                        return response.data;
                    });
            };

            return repository;
        }
    ]);