angular.module('paymentsModule', [

    'ui.bootstrap',

    'angular.filter',

    'pascalprecht.translate',
    'tmh.dynamicLocale',

    'shared.components.sbIcon',
    'shared.directives.sbLoading',

    'shared.directives.cloakTranslate',

    'payments.components.paymentProviderButtons',
    'payments.components.paymentResult',
    'payments.services.paymentsService'
]).config([
    '$httpProvider',
    'ng1StateDefinitionProvider',
    function (
        $httpProvider) {

        $httpProvider.useApplyAsync(true);

        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }
    }
])

.component('ng1Payments', {
        template: '<payment-result></payment-result>',
    })
