"use strict";

angular.module('shared.components.teamEventGuardians', [
    'events.services.eventsService',
    'shared.components.sbIcon',
    'shared.directives.sbPrint',
    'teams.services.teamService'
])
    .component('teamEventGuardians',
        {
            bindings: {
                teamId: '<',
                calendarEventId: '<',
                eventTitle: '<',
                showTitleBar: '<'
            },
            templateUrl: '/Scripts/app/shared/components/team-event-guardians.template.html',
            controller: ['$window',
                'teamService',
                'eventsService',
                function controller($window, teamService, eventsService) {

                    this.$onChanges = function(changes) {

                        if (changes.teamId && changes.teamId.currentValue) {
                            this.loadGuardiansForTeam();
                        }

                        if (changes.calendarEventId && changes.calendarEventId.currentValue) {
                            this.loadGuardiansForEvent();
                        }
                    };

                    this.guardianData = [];
                    this.loading = false;
                    var modes = {
                        None: 0,
                        ByEvent: 1,
                        ByTeam: 2
                    };
                    this.mode = modes.None;

                    var self = this;

                    this.loadGuardiansForTeam = function() {

                        this.mode = modes.ByTeam;
                        this.loading = true;

                        teamService
                            .getGuardiansForTeam(this.teamId)
                            .then(function(data) {

                                self.loading = false;

                                if (!data.isError) {
                                    self.guardianData = data;
                                } else {
                                    console.error('Failure in GetGuardiansForTeam', data.errorData);
                                }

                            });
                    };

                    this.loadGuardiansForEvent = function() {

                        this.mode = modes.ByEvent;
                        this.loading = true;

                        eventsService
                            .getGuardiansForEvent(this.calendarEventId)
                            .then(function(data) {

                                self.loading = false;

                                if (!data.isError) {
                                    self.guardianData = data;
                                } else {
                                    console.error('Failure in GetGuardiansForEvent', data.errorData);
                                }

                            });
                    };

                    this.exportReport = function() {
                        if (this.mode == modes.ByEvent) {
                            eventsService.getGuardiansForEventCsv(this.calendarEventId);
                        } else {
                            teamService.getGuardiansForTeamCsv(this.teamId);
                        }
                    };

                    this.printTemplate = '/Scripts/app/shared/components/team-event-guardians.print.html?v=' + $window.EveryBuddy.Version;
                }
            ]
    });
