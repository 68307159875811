import { Component, Input, DoCheck, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sb-percentage-input',
  templateUrl: './percentage-input.component.html',
  styleUrls: ['./percentage-input.component.scss']
})
export class PercentageInputComponent implements DoCheck {

 @Input() modelNumber: any;
 @Output() modelNumberChange = new EventEmitter<number>();
 @Input() isRequired: boolean;
 previousValue: any = null;


  ngDoCheck(): void {
    if (this.previousValue === null || this.previousValue !== this.modelNumber) {
      if (this.modelNumber && !isNaN(this.modelNumber)) {
          ///this.modelNumber = parseFloat(Math.round(+this.modelNumber * 100) / 100).toFixed(2);
          this.modelNumber = Math.round(parseFloat(this.modelNumber)).toFixed(2); // Will need testing

          this.previousValue = this.modelNumber;
      }
    }
  }

  onChange($event) {
    this.modelNumberChange.emit($event);
  }
}
