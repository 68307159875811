import { Component, OnInit } from '@angular/core';
import { RoleId } from '@sb-shared/enums/role.enum';
import { User } from '@sb-shared/models/user';
import { UserService } from '@sb-shared/services/user.service';
import { ProfileDataService } from '../../services/profile-data.service';

@Component({
  selector: 'sb-profile-groups',
  templateUrl: './profile-groups.component.html',
  styleUrls: ['./profile-groups.component.scss']
})
export class ProfileGroupsComponent implements OnInit {

  selectedPerson: User;
  isOrganisationAdmin: boolean;
  isStaff: boolean;

  constructor(
    private profileData: ProfileDataService,
    private userSvc: UserService) { }

  ngOnInit(): void {
    this.profileData.getUserInContext().subscribe(user => {
      this.selectedPerson = user;
    });

    this.userSvc.getCurrentUser().subscribe(user => {
      this.isOrganisationAdmin = user.userRoleIds.includes(RoleId.Admin);
      this.isStaff = user.isStaff;
    });
  }
}
