import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrganisationService } from './../../services/organisation.service';

@Component({
  selector: 'sb-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent {

  @Input() modelNumber: number;
  @Output() modelNumberChange: EventEmitter<number> = new EventEmitter();
  @Input() required: boolean;
  @Input() min: number; // Added for Angular 12
  @Input() max: number; // Added for Angular 12

  constructor(private organisation: OrganisationService) {}

  onChange($event: number) {
    this.modelNumberChange.emit($event);
  }

}
