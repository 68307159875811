'use strict';

angular.module('ccasignupModule').component('ccaGenerateEventsAA', {

    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-generate-eventsaa.template.html',
    controller: [
    '$scope',
    '$state',
    '$interval',
    '$uibModal',
    'calendarEventCreationRuleService',
    'dateTextService',
    'signUpRepository',
    'simpleAlertService',
    'ccaDataProperties',
    function (
        $scope,
        $state,
        $interval,
        $uibModal,
        calendarEventCreationRuleService,
        dateTextService,
        signUpRepository,
        simpleAlertService,
        ccaDataProperties) {

        $scope.loading = 1;

        $scope.signUp = {};
        $scope.totalEventCount = 0;
        $scope.anyRulesSelected = false;
        $scope.selectedRules = [];
        $scope.showPublishColumn = true;

        $scope.generate = { agree: false, additionalInformation: "", sendCommunication: true };
        var signUpId = $state.params.signUpId;

        $scope.sortType = "title"; // set the default sort type
        $scope.sortReverse = false; // set the default sort order

        $interval(() => {
            $scope.refreshSignUp();
        }, ccaDataProperties.DefaultRefreshTimeout);

        $scope.refreshSignUp = () => {
            if (!$scope.signUpGroups) {
                $scope.signUpRefreshing = true;
                signUpRepository.getSignUp($state.params.signUpId)
                    .then((signUp) => {
                        $scope.signUp = signUp;
                        $scope.loading = 0;
                        if ($scope.$parent) {
                            // update parent view with any change in sign up state (generating, active etc.)
                            $scope.$parent.signUp = signUp;
                        }
                        if (!$scope.eventsGenerating()) {
                            $scope.getSignUpDetails();
                        }
                    })
                    .finally(() => {
                        $scope.signUpRefreshing = false;
                    });
            }
        };

        $scope.refreshSignUp();

        $scope.selectedDays = function (daysOfWeek) {
            var days = "";

            if (parseInt(daysOfWeek, 10) & 1) days = days + "Mo ";
            if (parseInt(daysOfWeek, 10) & 2) days = days + "Tu ";
            if (parseInt(daysOfWeek, 10) & 4) days = days + "We ";
            if (parseInt(daysOfWeek, 10) & 8) days = days + "Th ";
            if (parseInt(daysOfWeek, 10) & 16) days = days + "Fr ";
            if (parseInt(daysOfWeek, 10) & 32) days = days + "Sa ";
            if (parseInt(daysOfWeek, 10) & 64) days = days + "Su ";

            return days;
        };

        $scope.getSignUpDetails = () => {
            $scope.loading += 3;
            calendarEventCreationRuleService
                .getCalendarEventCreationRulesForSignUpToPublish(signUpId, true)
                .then((data) => {
                    if (!data.isError)
                    {
                        $scope.rules = data;

                        if ($scope.rules.length > 0)
                        {
                            $scope.rules.forEach((rule) =>
                            {
                                rule.isSelectedForPublication = false;

                                if (!isNaN(rule.eventCount))
                                {
                                    $scope.totalEventCount += rule.eventCount;
                                }
                            });

                            let publishedRules = $scope.rules.filter((rule) =>
                            {
                                return rule.statusId > 0;
                            });

                            let unPublishedRules = $scope.rules.filter((rule) =>
                            {
                                return rule.statusId == 0;
                            });

                            // If not all event rules are published, only un-published event rules are to be shown
                            // by default on the publish events screen.
                            if (publishedRules.length > 0 && unPublishedRules.length > 0)
                            {
                                $scope.rules = $scope.rules.filter((rule) =>
                                {
                                    return rule.statusId == 0;
                                });
                            }
                        }
                        else
                        {
                            $scope.totalEventCount = 0;
                        }
                    }
                    else
                    {
                        $scope.totalEventCount = 0;
                    }
                })
                .finally(() => {
                    $scope.loading --;
                });
            signUpRepository
                .getSignUpGroups(signUpId)
                .then((data) => {
                    $scope.signUpGroups = data;

                    let personIds = [];
                    $scope.signUpGroups.forEach((group) =>
                    {
                        personIds.push(group.organiserId);
                    });

                    signUpRepository
                        .getOrganisersForStaffPersonIds(personIds)
                        .then((organisers) => {
                            $scope.organisers = organisers;
                        })
                        .finally(() => {
                            $scope.loading--;
                        });

                });
            signUpRepository
                .getLocations()
                .then((locations) => {
                    $scope.locations = locations;
                })
                .finally(() => {
                    $scope.loading--;
                });
        };

        $scope.ccaGroupDetails = function (signUpGroupId) {
            $state.go("ccaGroupDetails", { signUpId: $state.params.signUpId, signUpGroupId: signUpGroupId });
        };

        $scope.dateText = function (dayNumber)
        {
            return dateTextService.getShortDayText(dayNumber);
        }

        $scope.statusText = function (statusId) {
            switch (statusId) {
                case 0:
                    return 'SB_Not_Started';
                case 1:
                    return 'SB_Partial';
                case 2:
                    return 'SB_Complete';
                case 3:
                    return 'SB_On_Hold';
                case 4:
                    return 'SB_Auto_Complete';
                case 5:
                    return 'SB_Archived';
                case 6:
                    return 'SB_CcaStatus_Active';
                default:
                    return 'SB_Not_Started';
            }
        };

        $scope.maxEvents = 25000;

        $scope.generateEvents = function ()
        {
            if ($scope.selectedRules.length == 0)
            {
                return;
            }

            var modalInstance;

            //need to inform that not all
            //rules will be published when at least one
            //has a zero eventCount
            if (0 < $scope.selectedRules.filter(function (selectedRule)
            {
                return selectedRule.eventCount === 0;
            }).length)
            {
                modalInstance = simpleAlertService.errorAlert({
                    title: 'SB_Error_Publishing_Events',
                    message: 'SB_Not_All_Events_Will_Be_Published',
                    okButtonText: 'SB_OK',
                    cancelButtonText: 'SB_Cancel',
                    windowSize: 'md'
                });
            } else
            {
                modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "confirmGenerateAAModal.html",
                    controller: "GenerateAAModalInstanceCtrl",
                    size: "sm"
                });
            }

            modalInstance.result.then(
                function ()
                {
                    $scope.groups = null;

                    let selectedRuleIds = $scope.selectedRules.map(x => x.ccaCalendarEventCreationRuleId);

                    signUpRepository.postGenerateSelectedEvents(
                        $state.params.signUpId,
                        selectedRuleIds,
                        $scope.generate.additionalInformation,
                        $scope.generate.sendCommunication)
                        .then(function ()
                        {
                            $state.go("ccaDetailsAA.groups", { signUpId: $state.params.signUpId });
                        });
                },
                function ()
                {
                });
        };

        $scope.ruleSelected = function (selectedRules)
        {
            $scope.anyRulesSelected = selectedRules.length > 0;
            $scope.selectedRules = selectedRules;

            if (!$scope.anyRulesSelected)
            {
                $scope.generate.agree = false;
            }
        };

        $scope.eventsGenerating = () => {
            return $scope.signUp && ($scope.signUp.status === 2 || $scope.signUp.status === 5);
        };

        $scope.viewPublishedEvents = () => {
            $state.go($scope.signUp.isPTC ?'ccaDetailsAA.existingEventsTimeSlotted' : 'ccaDetailsAA.existingEvents');
        };
    }
]});


angular.module('ccasignupModule').controller("GenerateAAModalInstanceCtrl", [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss("cancel");
    };
}]);
