angular.module('financialProducts.components.feeSummary',
    [
        'pascalprecht.translate',
        'shared.components.sbDatePicker',
        'shared.directives.sbCurrencyInput'
    ])
    .component('feeSummary',
        {
            bindings: {
                feeRecord: '='
            },
            controller: [
                '$window',
                function ($window) {

                    this.currencySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                }],
            templateUrl: '/Scripts/app/financialProducts/components/feeSummary.template.html'
        });
