

angular.module('shared.services.urlInterceptor', [])
    .service('urlInterceptor', class UrlInterceptorService {

        static routePrefixToReplace = ['webapi', 'ccaavailable', 'reception', 'balanceadmin', 'ccasignup', 'balance', 'sysadmin'];

        request(config: any){

            const url = config.url;
            if (url.toLowerCase().indexOf('.html') > -1 || !window['EveryBuddy'].IsAngular12) {
                return config;
            }

            // Handle legacy API requests
            UrlInterceptorService.routePrefixToReplace.some(routePrefix => {
                if (url.toLowerCase().indexOf(routePrefix) == 1) {
                    config.url = window['EveryBuddy'].WebAPI + url;
                    return true; // forces early exit from 'some'.
                }
                return false;
            })

            return config;
        };

        response(response: any){
            return response;
        };

    }
);
