import { downgradeComponent } from "@angular/upgrade/static";
import { ActionMenuComponent } from "@sb-shared/components/action-menu/action-menu.component";
import * as angular from "angular";
declare let $:any;

export const ng1DashboardModule = angular.module('dashboardModule', [
    'ng1App',
    'ui.bootstrap',

    'angular.filter',


    'tmh.dynamicLocale',

    // 'dashboard.components.dashboard',
    // 'dashboard.components.portal',
    // 'dashboard.components.portalPage',

    'person.services',

    'shared.components.sbDatePicker',
    'shared.components.teamEventGuardians',
    'shared.components.teamEventMedical',

    'shared.controllers.confirmationPopup',

    // 'shared.directives.cloakTranslate',
    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlPlaceholder',
    'shared.directives.ngBindHtmlTitleAttr',
    'shared.directives.sbCheckBox',
    'shared.directives.sbRadioButton',
    'shared.directives.updateLanguage',

    'shared.services.cachedLookupService',
    'shared.services.iconService',
    'shared.services.simpleAlertService',

    // From dashboard.components.dashboard

    'angular-clipboard',

    'diary.components.diaryEventList',
    'diary.services.diaryService',
    'profile.components.welcomeMessage',
    'messaging.components.messageCenterCountsWidget',
    'balance.components.balanceSummaryWidget',
    'ccaAvailable.components.ccaAvailableSummary',

    'shared.components.sbIcon',
    'shared.components.sbPageLoader',
    'ng1StateDefinitionModule'
])
    .config([
        'ng1StateDefinitionProvider',
        function (ng1StateDefinitionProvider) {

            const ng1State = ng1StateDefinitionProvider.$get();

            const moduleName = 'Dashboard';

            ng1State.state(moduleName, 'portal',
            {
                url: '/portal',
                component: 'portal',
            })
            ng1State.state(moduleName, 'portalPage',
            {
                url: '/portalPage/:pageId',
                component: 'portalPage'
            });
        }
    ])
    .component('ng1Dashboard', {
        template: '<dashboard current-person-id="$ctrl.currentPersonId" is-staff="$ctrl.isStaff" is-parent="$ctrl.isParen" is-pupil="$ctrl.isPupil" is-organisation-admin="$ctrl.isOrganisationAdmin"></dashboard>',
        bindings: {
            currentPersonId: '<',
            isStaff: '<',
            isParent: '<',
            isPupil: '<',
            isOrganisationAdmin: '<'
        }
    })
    .directive('actionMenu', downgradeComponent({component: ActionMenuComponent}))
    .component('ng1Portal', {
        template: '<portal></portal>'
    })
    .component('ng1PortalPage', {
        template: '<portal-page></portal-page>'
    })
