'use strict';

angular.module('teams.components.teamNoteEditor',
    [
        'messaging.services.messagingService',

        'pascalprecht.translate',

        'shared.components.sbIcon',
        'shared.services.simpleAlertService',
        'shared.services.tinymceConfigHelper',

        'teams.services.teamNotesService'
    ])
    .component('teamNoteEditor', {
        bindings: {
            teamId: '<',
            onCreate: '&'
        },
        templateUrl: '/Scripts/app/teams/components/team-note-editor.template.html',
        controller: [
            'teamNotesService',
            'tinymceConfigHelper',
            'simpleAlertService',
            'messagingService',
            function (teamNotesService, tinymceConfigHelper, simpleAlertService, messagingService) {

                this.loading = 0;
                this.tags = [];

                this.isLoading = function () {
                    return this.loading > 0;
                }.bind(this);

                this.note = {
                    id: 0,
                    teamId: this.teamId,
                    title: '',
                    body: '',
                    tagIds : []
                };



                this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                this.$onChanges = function (changes) {

                    console.log('teamNoteEditor/onChanges', changes);

                    if (changes.teamId && changes.teamId.currentValue) {
                        this.note.teamId = changes.teamId.currentValue;
                    }
                }.bind(this);

                this.loading++;
                messagingService.getAllTagsForReference()
                    .then(function (tags) {

                        for (var tagId in tags) {
                            if (tags.hasOwnProperty(tagId)) {
                                this.tags.push({
                                    id: tagId,
                                    name: tags[tagId],
                                    checked: false
                                });
                            }
                        }

                        this.loading--;
                    }.bind(this))
                    .catch(function (error) {
                        this.loading--;
                        simpleAlertService.errorAlert({
                            message: 'SB_Error_occurred_try_again'
                        });
                        console.error(error);
                    }.bind(this));

                this.save = function () {

                    // but... what about the tags?
                    var tagIds = [];

                    for (var i = this.tags.length; i--;) {
                        if (this.tags[i].checked) {
                            tagIds.push(+this.tags[i].id);
                        }
                    }

                    this.note.tagIds = tagIds;

                    teamNotesService.addNote(this.note)
                        .then(function (data) {
                            this.onCreate()(data);
                        }.bind(this))
                        .catch(function (err) {
                            simpleAlertService.errorAlert({
                                message: 'SB_Error_Saving'
                            });
                            console.error(err);
                        });
                }.bind(this);

            }]
    });