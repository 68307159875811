angular.module('tuitionFeesModule')
    .component('addEditBillingCyclePopup', {
        bindings: {
            resolve: '<', // our props hang off resolve with uibModal
            modalInstance: '<',
            close: '&',
            dismiss: '&',
        },
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/add-edit-billing-cycle-popup.template.html',
        controller: class AddEditBillingCyclePopupCtrl {

            // Dependencies
            billingCycleService: any;
            simpleAlertService: any;
            dateRangeLists: any;

            resolve: any;
            dismiss: any;
            close: any;
            modalInstance: any;

            // through resolve
            billingCycleId: number;
            isEdit: boolean;
            academicYears: any[];

            billingCycle: any = {};
            isSaving: boolean = false;
            isSubmitted: boolean = false;
            dtFrom: any;
            dtTo: any;
            formChanged: boolean = false;
            selectedAcademicYear: any = {};

            static $inject = ['billingCycleService', 'simpleAlertService', 'dateRangeLists'];

            constructor(billingCycleService, simpleAlertService, dateRangeLists) {
                this.billingCycleService = billingCycleService;
                this.simpleAlertService = simpleAlertService;
                this.dateRangeLists = dateRangeLists;
            }

            $onInit(): void {
                this.dtFrom = moment.utc();
                this.dtTo = moment.utc();
                this.billingCycle.startsOn = this.formatDate(this.dtFrom);
                this.billingCycle.endsOn = this.formatDate(this.dtTo);
            }

            // Formats moment object
            formatDate(date) {
                return date.format('YYYY-MM-DD');
            }

            $onChanges(changes): void {
                if (changes?.resolve?.currentValue?.billingCycleId) {
                    this.billingCycleId = changes.resolve.currentValue.billingCycleId;
                    this.billingCycle = {};

                    this.billingCycleService.getCycle(this.billingCycleId).then(res => {
                        if (res.data) {
                            this.billingCycle = res.data;
                            this.dtFrom = moment(this.billingCycle.startsOn);
                            this.dtTo = moment(this.billingCycle.endsOn);
                            this.selectedAcademicYear = this.academicYears.find(x => x.id == this.billingCycle.academicYearId);
                        }
                    });
                }

                if (changes?.resolve?.currentValue?.isEdit) {
                    this.isEdit = changes.resolve.currentValue.isEdit;
                }

                if (changes?.resolve?.currentValue?.academicYears) {
                    this.academicYears = changes.resolve.currentValue.academicYears;
                }
            }

            saveCycle(): void {
                this.isSaving = true;
                if (this.isEdit) {
                    this.billingCycleService.updateCycle(this.billingCycle).then(res => {
                        this.isSaving = false;
                        this.close();
                    });
                }
                else {
                    this.billingCycleService.addCycle(this.billingCycle).then(res => {
                        this.isSaving = false;
                        this.modalInstance.close(res.data?.id);
                    });
                }
            }

            deleteCycle(): void {
                let modalInstance = this.simpleAlertService.simpleAlert({
                    title: 'SB_Delete_Cycle',
                    message: 'SB_Delete_Cycle_Prompt',
                    okButtonText: 'SB_Delete',
                    cancelButtonText: 'SB_Cancel',
                    windowSize: 'sm'
                });

                modalInstance
                    .result
                    .then(() => {
                        this.isSaving = true;
                        this.billingCycleService.deleteCycle(this.billingCycleId).then(res => {
                            this.isSaving = false;
                            this.close();
                        });
                    })
                    .catch(() => {
                        this.isSubmitted = false;
                    });

            }

            dateRangeSelected(): void {
                this.formChanged = true;
                this.billingCycle.startsOn = this.formatDate(this.dtFrom);
                this.billingCycle.endsOn = this.formatDate(this.dtTo);
            }

            onChangeAcademicYear() : void {
                this.formChanged = true;
                this.selectedAcademicYear = this.academicYears.find(x => x.id == this.billingCycle.academicYearId);
            }

            onChangeAcademicTerm() : void {
                this.formChanged = true;
            }

            cancel(): void {
                this.dismiss();
            };
        }
    });
