import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BalanceType } from '@sb-shared/enums/balance-type.enum';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { UserBalances } from 'src/app/balance/interfaces/user-balances';
import { BalanceService } from './../../../balance/balance.service';
import { UserService } from './../../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserTransactionViewComponent } from 'src/app/balance/components/current-user-transaction-view/current-user-transaction-view.component';

@Component({
  selector: 'sb-balance-widget',
  templateUrl: './balance-widget.component.html',
  styleUrls: ['./balance-widget.component.scss']
})
export class BalanceWidgetComponent implements OnInit {

  @Input() type: number;
  @Input() isDashboard: boolean;
  isStaff: boolean;
  currencyDisplaySymbol: string;
  userBalances: UserBalances;
  userBalanceWidgets: { id: string; title: string; value: any; iconName: string; valueClass: string; sortOrder: number; balanceClickHandler: () => void; }[];
  // organisationBalances: OrganisationBalances;

  constructor(
    private user: UserService,
    private organisation: OrganisationService,
    private balance: BalanceService,
    private router: Router,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.user.isStaff().subscribe(isStaff => {
      this.isStaff = isStaff;
      if (this.isStaff) {
        // this.balanceAdminService.getAllBalances().subsribe(data => {
        //   this.balances = data;
        //   this.studentBalanceFormatted = formatAmount(this.balances.studentBalance);
        //   this.creditBalanceFormatted = formatAmount(this.balances.creditBalance);
        //   this.voucherBalanceFormatted = formatAmount(this.balances.voucherBalance);
        //   this.isLoading = false;
        // });
      } else {
        this.balance.getAllBalances().subscribe(balances => {
          this.userBalances = balances;
          this.userBalanceWidgets = this.getUserBalanceWidgets();
        })
      }
    });
    this.organisation.getCurrentOrganisation().subscribe(organisation => {
      this.currencyDisplaySymbol = organisation.currencyDisplaySymbol;
    })
  }

  keyToEnum(key) {
    switch (key) {
      case 'balance':
        return BalanceType.Outstanding;
      case 'studentBalance':
        return BalanceType.Outstanding;
      case 'creditBalance':
        return BalanceType.Credit;
      case 'voucherBalance':
        return BalanceType.Voucher;
      default: return 0;
    }
  }

  getTitle(type: number): string {
    if (this.isStaff) {
      switch (type) {
        case BalanceType.Outstanding:
          return 'SB_Outstanding_Students_Fees';
        case BalanceType.Credit:
          return 'SB_Credit_Account_Total';
        case BalanceType.Voucher:
          return 'SB_Voucher_Account_Total';
      }
    }
    else {
      switch (type) {
        case BalanceType.Outstanding:
          return this.isDashboard ? 'SB_Outstanding_Fees' : 'SB_Outstanding_Students_Fees';
        case BalanceType.Credit:
          return this.isDashboard ? 'SB_My_Wallet' : 'SB_Credit_Account_Title';
        case BalanceType.Voucher:
          return this.isDashboard ? 'SB_Voucher_Balance' : 'SB_Voucher_Account_Title';
      }
    }
    return '';
  }

  // getIconImg(type: number): string {
  //   // Need to refactor this but will add temp url for testing alignment
  //   switch(type) {
  //     case BalanceType.Outstanding:
  //       return 'https://zeroheight-uploads.s3-accelerate.amazonaws.com/7c450eb8afadf6b95058d9?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220512%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220512T142814Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0272866398535b87660be5d46bc35ca4e5a516deccfea66e4a9c22f85d54d3e3'
  //     case BalanceType.Credit:
  //       return 'https://zeroheight-uploads.s3-accelerate.amazonaws.com/e0a6d6967afe6b8691d457?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220513%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220513T162137Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ed6653949634051e92c635827bebeb0fb97746ff5c098d3cf5f53c19c4db15cf';
  //     case BalanceType.Voucher:
  //       return 'https://zeroheight-uploads.s3-accelerate.amazonaws.com/f57933823dc5302f7faaba?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220513%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220513T160201Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5a23312f5aa265a5226d0d685f77ff249ffdb87393a7028b71940fb490b456b8';
  //   }
  //   return '';
  // }

  getIcon(type: number): string {
    switch (type) {
      case BalanceType.Outstanding:
        return 'balanceBalance'
      case BalanceType.Credit:
        return 'balanceCredit';
      case BalanceType.Voucher:
        return 'balanceVouchers';
    }
    return '';
  }

  getClass(type: number): string {
    // Need to refactor this but will add temp url for testing alignment
    switch (type) {
      case BalanceType.Outstanding:
        return 'text-warning'
      case BalanceType.Credit:
        return 'text-success';
      case BalanceType.Voucher:
        return 'text-info';
      default: return '';
    }
  }

  getSortOrder(type: number): number {
    switch (type) {
      case BalanceType.Outstanding:
        return 2;
      case BalanceType.Credit:
        return 1;
      case BalanceType.Voucher:
        return 3;
      default: return 0;
    }
  }

  onClick(type: number) {
    if (this.isDashboard && !this.isStaff) {
      switch (type) {
        case BalanceType.Outstanding:
          this.router.navigate(['Balance']);
          break;
        default: {
          const modalRef = this.modal.open(CurrentUserTransactionViewComponent, { size: 'lg' });
          modalRef.componentInstance.accountTypeId = type;
        }
      }
    }
  }

  getUserBalanceWidgets() {
    if (!this.userBalances) {
      return null;
    }
    return Object.keys(this.userBalances)
      .filter(key => {
        // Return number values and only return voucherBalance if voucherBalanceAvailable
        return key !== 'voucherBalanceAvailable' && (this.userBalances.voucherBalanceAvailable || key !== 'voucherBalance')
      })
      .map(key => {
        const typeInt = this.keyToEnum(key);
        return {
          id: key,
          title: this.getTitle(typeInt),
          value: this.userBalances[key],
          iconName: this.getIcon(typeInt),
          valueClass: this.getClass(typeInt),
          sortOrder: this.getSortOrder(typeInt),
          balanceClickHandler: () => this.onClick(typeInt)
        }
      })
      .sort((a, b) => a.sortOrder - b.sortOrder)
  }

}
