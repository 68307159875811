'use strict';

angular.module('diary.components.diaryEventFormTags',
        [
            'pascalprecht.translate',

            'shared.components.sbIcon',
            'userForm.components.bulkView'
        ])
    .component('diaryEventFormTags',
        {
            bindings: {
                calendarEventId: '<'
            },
            templateUrl: '/Scripts/app/diary/components/diary-event-form-tag.template.html',
            controller: ['userFormService',
                function (userFormService) {

                    console.log("Diary Form Tags");
                    this.selectedFormId = "";

                    userFormService
                        .getFormTags()
                        .then(function (data) {
                            this.forms = data;
                        }.bind(this))
                        .catch(function (err) {
                            console.error(err);
                            this.forms = [];
                        }.bind(this));
                }]
        });