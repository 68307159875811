angular.module('sysadmin.components.sysAdminUserRoles', [
    'organisation.components.organisationList',
    'roles.components.userRolesEditorPopup',
    'roles.services.rolesService'])
    .component('sysAdminUserRoles', {
        templateUrl: '/Scripts/app/sysadmin/components/sysadmin-userroles.template.html',
        controller: class SysAdminUserRoles {

            // Dependencies
            $uibModal: any;
            rolesService: any;
            sysAdminService: any;
            toastService: any;

            // Variables
            selectedOrganisationId: any;
            selectOrganisationUserRoles: Array<any>;
            roles: Array<number>;
            superAdminRoles: Array<number>;
            superAdminMainRole: number;
            roleNames: Array<any>;
            state: any;
            searchText: string;
            tabs: any;
            selectedTab: number;
            selectedOrganisation: any;

            static $inject = ['$uibModal', 'rolesService', 'toastService'];

            constructor($uibModal, rolesService, toastService) {
                this.$uibModal = $uibModal;
                this.rolesService = rolesService;
                this.toastService = toastService;
            }

            $onInit() {
                this.tabs = {
                    AllUsers: 1,
                    SuperAdmins: 2
                }

                this.selectedTab = this.tabs.AllUsers;

                this.selectedOrganisationId = null;
                this.selectOrganisationUserRoles = null;
                this.searchText = '';

                // Set the roles we are interested in.
                this.roles = [1, 23, 27, 31, 32, 33, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44];
                this.superAdminRoles = [1, 23, 27, 31, 35, 37, 40, 41, 42];
                this.superAdminMainRole = 35;
                this.roleNames = [];

                // State that will be passed in to the modal.
                this.state = {
                    personId: 0, // user identifier
                    personName: '', // user name
                    personEmail: '', // user email
                    userRolesFilter: [], // list of roles that can be (un)assigned, with role id, name and user's current value.
                    isNew: false, // whether this is an add or edit.
                    specialRoles: this.superAdminRoles,
                    specialMainRole: this.superAdminMainRole,
                    specialUserButtonLabel: 'SB_Make_Super_Admin'
                };

                // Fetch role names
                this.rolesService.getRoleNames(this.roles).then(roleNames => {
                    this.roleNames = roleNames;
                });
            }

            // Lookup role name from role id.
            getRoleName(roleId: number): Array<string> | string {
                if (this.roleNames.length == 0) {
                    return 'SB_Please_wait';
                }
                return this.roleNames[this.roles.indexOf(roleId)];
            };

            // Fetch and display user roles for an organisation.
            showOrganisation(organisation: any): void {
                if (organisation !== null) {
                    this.selectOrganisationUserRoles = null;
                    this.selectedOrganisationId = organisation.organisationId;
                    this.selectedOrganisation = organisation;
                    this.rolesService.getOrganisationUserRoles(organisation.organisationId, this.roles).then(data => {
                        this.selectOrganisationUserRoles = data;
                    }, () => {
                        this.toastService.error();
                    });
                }
            };

            // Assign roles to a new user.
            add(): void {
                this.state.personId = 0;
                this.state.personName = '';
                this.state.personEmail = '';
                this.state.isNew = true;

                // Initally, all roles are deselected.
                this.state.userRolesFilter = [];
                this.roles.forEach(r => {
                    this.state.userRolesFilter.push({ id: r, name: this.getRoleName(r), originalValue: null, value: false })
                });

                this.showRolesEditorModal();
            };

            // Edit user roles.
            edit(personId: number, personName: string, personEmail: string): void {
                this.state.personId = personId;
                this.state.personName = personName;
                this.state.personEmail = personEmail;
                this.state.isNew = false;

                // Populate user filter with the values for the selected user.
                // i.e. set 'value' to true or false depending on whether the selected user
                // is currently assigned that role.
                this.state.userRolesFilter = [];
                const userRolesRoleList = this.selectOrganisationUserRoles.find(u => { return u.id == personId; }).roles;
                this.roles.forEach(r => {
                    var isAssigned = userRolesRoleList.indexOf(r) != -1;
                    this.state.userRolesFilter.push({ id: r, name: this.getRoleName(r), originalValue: isAssigned, value: isAssigned });
                });

                this.showRolesEditorModal();
            };

            // Show modal to assign user roles.
            showRolesEditorModal(): void {
                const currentScope = this;
                const roleModal = this.$uibModal.open({
                    animation: true,
                    backdrop: 'static', // prevent modal from closing on backdrop click
                    component: 'userRolesEditorPopup',
                    size: 'md',
                    resolve: {
                        organisationId: currentScope.selectedOrganisationId,
                        state: currentScope.state
                    }
                })
                roleModal.result
                    .then(() => {
                        this.showOrganisation(this.selectedOrganisation);
                    })
                    .catch(() => {
                        // Do nothing if modal dismissed
                    })
            };

            search(): any {
                return userRolesItem => {
                    if (userRolesItem.name && userRolesItem.email) {
                        return (userRolesItem.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 ||
                            userRolesItem.email.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 ||
                            this.searchText === '') && (this.selectedTab == this.tabs.AllUsers ||
                                this.isSuperAdmin(userRolesItem));
                    }
                    return false;
                }
            }

            isSuperAdmin(userRoles: any): boolean {
                if (userRoles && userRoles.roles) {
                    return userRoles.roles.includes(this.superAdminMainRole);
                }
                return false;
            }
        }
    });
