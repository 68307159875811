'use strict';

angular
    .module(
        'transport.pupilPreferences.components.preferenceUpdatesFeedback',
        []
    )
    .component('preferenceUpdatesFeedback', {
        bindings: {
            modalInstance: '<',
            resolve: '<',
        },
        templateUrl:
            '/Scripts/app/transport/pupilPreferences/components/preference-updates-feedback.template.html',
        controller: [
            function () {
                this.unprocessedPupils = [];
                this.numberOfPupilsSuccessfullyAdded = 0;
                this.numberOfPupilsNotSuccessfullyAdded = 0;
                this.preferenceUpdatesResponse = null;
                this.timeSlotName = '';
                this.selectedTimetableCalendarName = '';
                this.exceedCapacityByChangeRequest;

                this.$onChanges = function (changes) {
                    if (changes.resolve && changes.resolve.currentValue) {
                        this.preferenceUpdatesResponse =
                            changes.resolve.currentValue.preferenceUpdatesResponse;
                        this.numberOfPupilsSuccessfullyAdded =
                            this.preferenceUpdatesResponse.numberOfPupilsSuccessfullyAdded;
                        this.numberOfPupilsNotSuccessfullyAdded =
                            this.preferenceUpdatesResponse.numberOfPupilsNotSuccessfullyAdded;
                        this.unprocessedPupils =
                            this.preferenceUpdatesResponse.unsuccessfulUpdates;
                        this.timeSlotName =
                            changes.resolve.currentValue.timeSlotName;
                        this.selectedTimetableCalendarName =
                            changes.resolve.currentValue.selectedTimetableCalendarName;
                        this.exceedCapacityByChangeRequest =
                            this.preferenceUpdatesResponse.exceedCapacityByChangeRequest;
                    }
                }.bind(this);

                this.cancel = function () {
                    this.modalInstance.dismiss('cancel');
                }.bind(this);
            },
        ],
    });
