// start:ng42.barrel
import './confirm-delete-reg-group.controller';
import './reception-consent-event-invites.component';
import './reception-events-auto-notify.component';
import './reception-events-notify-only.component';
import './reception-location-events.component';
import './reception-location-manage.component';
import './reception-team-notes.component';
import './reception-users-permission-to-walk.component';
import './reception-whatsnext.component';
// end:ng42.barrel

