
'use strict';

angular.module('profile.directives.singleSignOnEditor', ['profile.services.idpExternalService'])
    .directive('singleSignOnEditor',
        [
            'idpExternalService',
            function (idpExternalService) {


                function link($scope) {
                    $scope.$watch('emailAddress', function (emailAddress) {
                        if (!emailAddress) {
                            return;
                        }

                        $scope.loading--;
                        $scope.getSSOData();
                    });

                    $scope.$watch('hasSso', function (hasSso) {
                        if (typeof hasSso == 'undefined') {
                            return;
                        }

                        $scope.loading--;
                        $scope.getSSOData();
                    });
                };

                function controller($scope, $location) {

                    $scope.loading = 2;
                    $scope.loadFailureMessage = '';

                    $scope.getSSOData = function () {

                        if ($scope.loading > 0 || !$scope.hasSso) { return; }

                        idpExternalService.getUsersSSOProviderOptions($scope.emailAddress).then(function (responseDto) {

                            if (responseDto && responseDto.data && responseDto.isSuccess) {
                                $scope.singleSignOnState = responseDto.data;
                                return;
                            }

                            $scope.loadFailureMessage = 'SB_Error_loading'
                        });
                    };

                    $scope.linkAccount = function (providerName) {

                        var idUrl = `${window.EveryBuddy.IdUrl}/External/ExternalChallenge?provider=${providerName}&` +
                            `serverId=${window.EveryBuddy.ServerId}&` +
                            `organisationId=${window.EveryBuddy.CurrentOrganisationId}&` +
                            `returnUrl=https://${window.location.host}/Profile/singlesignon`;
                        window.location.href = idUrl;
                    }

                    $scope.unlinkAccount = function (providerName) {
                        $scope.response = '';

                        var requestDto = {
                            Provider: providerName
                        };

                        idpExternalService.removeUserSSOLink(requestDto)
                            .then(function (responseDto) {

                                if (responseDto && responseDto.data && responseDto.isSuccess) {
                                    $scope.getSSOData();
                                    $scope.response = 'success_removelink';
                                    return
                                }
                                $scope.response = 'failed_removelink';
                                $scope.loadFailureMessage = 'SB_Error_loading';
                            });
                    };

                    $scope.response = $location.search().response;
                };

                return {
                    restrict: 'E',
                    templateUrl: '/Scripts/app/profile/directives/single-sign-on-editor.template.html',
                    scope: {
                        emailAddress: '<',
                        hasSso: '<'
                    },
                    controller: ['$scope', '$location', controller],
                    link: link
                };
            }
        ]);
