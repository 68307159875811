"use strict";

angular.module("shared.components.sbPercentageInput", ["ui.bootstrap"])
    .component("sbPercentageInput",
        {
            bindings: {
                modelNumber: "=",
                required: "<"
            },
            templateUrl: "/Scripts/app/shared/components/sb-percentage-input.template.html",
            controller: function () {

                var previousValue = null;

                this.$doCheck = function () {

                    if (previousValue === null || previousValue !== this.modelNumber) {

                        if (this.modelNumber && !isNaN(this.modelNumber))
                            this.modelNumber = parseFloat(Math.round(+this.modelNumber * 100) / 100).toFixed(2);

                        previousValue = this.modelNumber;
                    }

                }.bind(this);

            }
        });