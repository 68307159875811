'use strict';

angular.module('balanceAdmin.massAdjustmentGroup.components.massAdjustmentGroupFilter',
    ['shared.components.sbDateRangePicker','shared.constants'])
    .component('massAdjustmentGroupFilter',
        {
            bindings: {
                searchFilters: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/massAdjustmentGroup/components/mass-adjustment-group-filter.template.html',
            controller:
                [
                    '$filter',
                    '$translate',
                    'groupService',
                    'simpleAlertService',
                    'dateRangeLists',
                    function ($filter, $translate, groupService, simpleAlertService, dateRangeLists) {

                        this.dateRangeLists = dateRangeLists;

                        this.$onChanges = function (changes) {
                        }

                        this.$onInit = function () {
                        }

                        this.calEvents = [];

                        this.formatDate = function (d) {
                            if (!d) {
                                return '';
                            }

                            return ' (' + $filter('date')(d, 'yyyy/MM/dd') + ')';
                        }

                        // Function used by autocomplete component
                        this.getGroups = groupService.getGroupsWithChargeEventsByPartialNameByDateRange;
                        this.getGroupArgs = [
                            function () { return this.searchFilters.eventsFrom; }.bind(this),
                            function () { return this.searchFilters.eventsTo; }.bind(this)
                        ];

                        // On change to date filters, reset group and event filters.
                        this.onDateChange = function () {
                            this.searchFilters.groupId = null;
                            this.searchFilters.groupName = null;
                            this.searchFilters.eventObj = null;
                            this.calEvents = [];
                        }.bind(this);

                        this.onSelectGroup = function ($item) {
                            this.searchFilters.groupId = $item.id;
                            this.searchFilters.groupName = $item.name;
                            this.searchFilters.isTeam = $item.isTeam;

                            this.refreshEvents();
                        }.bind(this);

                        this.refreshEvents = function () {
                            if (!this.searchFilters.groupId) {
                                return;
                            }

                            this.calEvents = [];
                            groupService.getChargeEventsByGroup(this.searchFilters.groupId,
                                this.searchFilters.isTeam)
                                .then(function (data) {
                                    this.searchFilters.eventObj = null;
                                    this.calEvents = data;

                                    this.calEvents.unshift({
                                        eventName: $translate.instant('SB_Select_Event')
                                    });
                                }.bind(this))
                                .catch(function (err) {
                                    console.log(err);
                                    simpleAlertService.errorAlert({ message: 'Unable to load group events' });
                                });
                        }.bind(this);
                    }
                ]

        });