angular.module('insurance.services.insuranceService', [])
    .factory("insuranceService", [
        "$http", "$q", "$cacheFactory",
        function ($http, $q, $cacheFactory) {


            var urlBase = "/webapi/WebInsurance/";
            var repository = {};

            var makeInsuranceGreatAgain = function (insuranceData) {

                if (!insuranceData) return insuranceData;

                // need to format the numbers here otherwise the dirty checking won't work

                if (insuranceData.fees) {

                    if (insuranceData.fees.brokerFeeDateReceived) {
                        insuranceData.fees.brokerFeeDateReceived = new Date(insuranceData.fees.brokerFeeDateReceived.replace('Z', ''));
                    }

                    if (insuranceData.fees.commissionDateReceived) {
                        insuranceData.fees.commissionDateReceived = new Date(insuranceData.fees.commissionDateReceived.replace('Z', ''));
                    }

                    if (insuranceData.fees.brokerFeeCharged)
                        insuranceData.fees.brokerFeeCharged = parseFloat(Math.round(+insuranceData.fees.brokerFeeCharged * 100) / 100).toFixed(2);
                    if (insuranceData.fees.brokerFeeReceived)
                        insuranceData.fees.brokerFeeReceived = parseFloat(Math.round(+insuranceData.fees.brokerFeeReceived * 100) / 100).toFixed(2);
                    if (insuranceData.fees.commissionAmountReceived)
                        insuranceData.fees.commissionAmountReceived = parseFloat(Math.round(+insuranceData.fees.commissionAmountReceived * 100) / 100).toFixed(2);
                    if (insuranceData.fees.commissionExpected)
                        insuranceData.fees.commissionExpected = parseFloat(Math.round(+insuranceData.fees.commissionExpected * 100) / 100).toFixed(2);
                }

                if (insuranceData.monthlyPremium)
                    insuranceData.monthlyPremium = parseFloat(Math.round(+insuranceData.monthlyPremium * 100) / 100).toFixed(2);

                if (insuranceData.sumInsured)
                    insuranceData.sumInsured = parseFloat(Math.round(+insuranceData.sumInsured * 100) / 100).toFixed(2);

                return insuranceData;
            };

            // cache responses- be sure to clear the cache when doing any write back operations
            var repoCache = $cacheFactory('insuranceServiceCache');
            var repoLookupCache = $cacheFactory('insuranceServiceLookupCache');

            repository.getInsuranceLookups = function () {
                return $http.get(urlBase + "GetInsuranceLookups", { cache: repoLookupCache })
                    .then(function (response) { return response.data; })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            repository.getAllInsurancesForCase = function (teamId) {
                return $http.get(urlBase + "GetAllCaseInsurances", { params: { teamId: teamId } }, { cache: repoCache })
                    .then(function (response) {

                        for (var i = response.data.length; i--;) {
                            response.data[i] = makeInsuranceGreatAgain(response.data[i]);
                        }
                        return response.data;
                    })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            repository.getInsuranceDetails = function (teamId, insuranceId) {
                return $http.get(urlBase + "GetCaseInsurance", { params: { teamId: teamId, insuranceId: insuranceId } }, { cache: repoCache })
                    .then(function (response) {
                        var data = makeInsuranceGreatAgain(response.data);
                        return data;
                    })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            repository.saveInsuranceDetails = function (dto) {
                repoCache.removeAll();
                
                return $http.post(urlBase + "SaveInsurance", dto)
                    .then(function (response) {
                        var data = makeInsuranceGreatAgain(response.data);
                        return data;
                    })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            repository.deleteInsuranceDetails = function (id) {
                repoCache.removeAll();

                return $http.post(urlBase + "DeleteInsurance", id )
                    .then(function (response) { return response.data; })
                    .catch(function (e) { return { isError: true, error: e }; });
            };

            return repository;
        }
    ]);