'use strict';

angular.module('shared.components.sbTextarea', [
    'shared.components.sbIcon',
    'shared.services.tinymceConfigHelper'
])
    .component('sbTextarea',
        {
            bindings: {
                placeholder: '@',
                id: '@',
                minLength: '<',
                maxLength: '<',
                isRequired: '<',
                rows: '<',
                tinymceConfig: '<',
                ngModel: '=',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/shared/components/sb-textarea.template.html',
            controller: class SbTextAreaCtrl {

                tinymceConfigHelper: any;

                tinymceConfig: any;
                minLength: number;
                maxLength: number;
                ngModel: string;
                onChange: any;

                tinymceOptions: any;

                static $inject = ['tinymceConfigHelper'];

                constructor(tinymceConfigHelper) {
                    this.tinymceConfigHelper = tinymceConfigHelper;
                }

                $onInit() {
                    if (this.tinymceConfig) {
                        this.tinymceOptions = this.tinymceConfigHelper.getTinyMceConfig(this.tinymceConfig);
                    }
                };
            }
        });
