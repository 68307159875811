export enum RoleId {
    Associated = 0,
    Admin = 1,
    Player = 2,
    Captain = 3,
    ViceCaptain = 4,
    Manager = 5,
    Coach = 6,
    TeamAssistant = 7,
    President = 8,
    Chairman = 9,
    Secretary = 10,
    Treasurer = 11,
    CommitteeMember = 12,
    Director = 13,
    NonPlayer = 14,
    Member = 15,
    Supporter = 16,
    Umpire = 17,
    Referee = 18,
    Linesman = 19,
    TouchJudge = 20,
    Driver = 21,
    Refreshments = 22,
    Teacher = 23,
    HeadOfActivity = 24,
    HeadOfYear = 25,
    Pupil = 26,
    AbsenteeAlerts = 27,
    SystemAdmin = 28,
    Historical = 29,
    GroupCallImport = 30,
    Finance = 31,
    CCAAdmin = 32,
    NewRegistrations = 33,
    Membership = 34,
    OrganisationSuperAdmin = 35,
    CommunicationAdmin = 36,
    AdvancedFeeAdmin = 37,
    TransportAdmin = 38,
    TransportAdvancedAdmin = 39,
    TransportAlerts = 40,
    NewBookingAlerts = 41,
    TuitionFeeAdmin = 42,
    SportsCoordinator = 43,
    TripCoordinator = 44,

    ExternalCoachAccess = 100,
    TransportBusMonitor = 101,
}