import { Component, Input } from '@angular/core';
import { Counts } from '@sb-shared/models/count';

@Component({
  selector: 'sb-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.scss']
})
export class CountsComponent {

  @Input() counts: Counts;

}
