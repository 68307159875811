'use strict';

angular.module('transport.configuration.routes.transportRouteEdit',
    [
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
        'transport.configuration.routes.transportRouteStops',
        'transport.services.transportConfigurationRoutesService',
        'transport.services.transportConfigurationStopsService',
        'transport.services.transportDirectionsService',
    ])
    .component('transportRouteEdit',
    {
        bindings: {
            resolve: '<',
            modalInstance: '<'
        },
        templateUrl: '/Scripts/app/transport/configuration/routes/transport-route-edit.template.html',
        controller: [
            '$timeout',
            '$uibModal',
            'cachedLookupService',
            'transportConfigurationMethodService',
            'transportConfigurationRoutesService',
            'transportDirectionsService',
            'transportTimetablesService',
            'simpleAlertService',
            function transportRouteEditController(
                $timeout,
                $uibModal,
                cachedLookupService,
                transportConfigurationMethodService,
                transportConfigurationRoutesService,
                transportDirectionsService,
                transportTimetablesService,
                simpleAlertService)
            {
                this.isSubmitted = false;
                this.enableIsActiveSwitch = false;
                this.isRouteLinkedToAnyPreferences = false;
                this.isRouteLinkedToAnyPendingOrFutureChangeRequests = false;
                this.isArchiveOptionAvailable = false;
                this.transportRouteArchivingInfo = {};
                this.routeIncludesStops = false;
                this.isRouteMethodAvailable = false;

                this.areAllStopsInactive = function (routeStops)
                {
                    this.enableIsActiveSwitch = false;
                    for (var i = 0; i < routeStops.length; i++)
                    {
                        if (routeStops[i].isActive)
                        {
                            this.enableIsActiveSwitch = true;
                            break;
                        }
                    }

                    if (!this.enableIsActiveSwitch &&
                        typeof this.transportRouteArchivingInfo !== 'undefined' &&
                        typeof this.transportRouteArchivingInfo.timetableRoutesWhereUsed !== 'undefined' &&
                        this.transportRouteArchivingInfo.timetableRoutesWhereUsed.length == 0)
                    {
                        this.transportRoute.isActive = false;
                    }
                }.bind(this);

                var resetSubmitButtons = function ()
                {
                    $timeout(function ()
                    {
                        this.isSubmitted = false;
                    }.bind(this), 200);

                }.bind(this);

                this.$onChanges = function (changes)
                {
                    if (changes.resolve)
                    {
                        var params = this.resolve.params;
                        this.loadTransportRoute(params.transportRoute);
                    }
                }.bind(this);

                this.cancel = function () {
                    this.modalInstance.dismiss('cancel');
                };

                var closeModal = function () {
                    this.modalInstance.close();
                }.bind(this);

                var alertAndResetOkButton = function (message) {
                    simpleAlertService.errorAlert(message);
                    resetSubmitButtons();
                };

                this.loadTransportRoute = function (transportRoute)
                {
                    this.transportMethods = null;
                    this.transportDirections = null;

                    transportConfigurationRoutesService.getTransportRouteDataForEdit(transportRoute.id)
                        .then(function (responseData)
                        {
                            this.transportRoute = responseData;
                            this.isRouteLinkedToAnyPreferences = false;
                            this.isRouteLinkedToAnyPendingOrFutureChangeRequests = false;

                            this.transportRoute.routeStops.filter(function (routeStop)
                            {
                                if (routeStop.isActive)
                                {
                                    this.routeIncludesStops = true;
                                }

                                if (routeStop.isLinkedToStudentPreferences)
                                {
                                    this.isRouteLinkedToAnyPreferences = true;
                                }

                                if (routeStop.isLinkedToPendingOrFutureChangeRequests)
                                {
                                    this.isLinkedToPendingOrFutureChangeRequests = true;
                                }
                            }.bind(this));

                            this.transportDirections = responseData.directions;
                            this.transportMethods = responseData.methods;

                            // This is to cater for the situation where a method has been archived since it was linked to the route.
                            // If it's not available, the method dropdown will be enabled and the user forced to select a new one.
                            for (var i = 0; i < this.transportMethods.length; i++)
                            {
                                if (this.transportMethods[i].id == this.transportRoute.transportMethodId)
                                {
                                    this.isRouteMethodAvailable = true;
                                    break;
                                }
                            }

                            if (!this.isRouteMethodAvailable)
                            {
                                this.transportRoute.transportMethodId = null;
                            }

                            transportConfigurationRoutesService.getTransportRouteArchivingInfo(transportRoute.id)
                                .then(function (responseData)
                                {
                                    this.transportRouteArchivingInfo = responseData;

                                    cachedLookupService.isOrganisationSuperAdminOrTransportAdvancedAdmin()
                                        .then(function (response)
                                        {
                                            this.isArchiveOptionAvailable = response == 1;
                                        }.bind(this))
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        }.bind(this));

                                }.bind(this))
                                .catch(function (responseData)
                                {
                                    var message = {};

                                    if (responseData && responseData.data && responseData.data.Message)
                                    {
                                        message = { message: responseData.data.Message };
                                    }

                                    alertAndResetOkButton(message);
                                }.bind(this))

                        }.bind(this))
                        .catch(function (responseData)
                        {
                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            alertAndResetOkButton(message);
                        }.bind(this));
                }.bind(this);

                this.editTransportRoute = function ()
                {
                    transportConfigurationRoutesService.editRoute(this.transportRoute)
                        .then(function (responseData)
                        {
                            if (responseData && responseData.name)
                            {
                                simpleAlertService.simpleAlert(
                                    {
                                        title: 'SB_Saved',
                                        message: 'SB_Transport_Route_Saved',
                                        messageTranslationParameters: { transportRoute: responseData.name },
                                        staticBackdrop: true
                                    });

                                closeModal();
                            }
                            else
                            {
                                alertAndResetOkButton();
                            }
                        })
                        .catch(function (responseData)
                        {

                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            alertAndResetOkButton(message);
                        });
                }.bind(this);

                this.archiveTransportRoute = function ()
                {
                    var transportItemNameToArchive = this.transportRoute.name + ' - ' + this.transportRoute.code;

                    var confirmArchiveModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'transportConfirmArchiving',
                            size: 'md',
                            backdrop: 'static',
                            resolve:
                            {
                                params: function ()
                                {
                                    return { transportItemNameToArchive: transportItemNameToArchive };
                                }
                            }
                        });

                    confirmArchiveModalInstance.result
                        .then(
                            () => {
                                transportConfigurationRoutesService.archiveRoute(this.transportRoute)
                                    .then(function (responseData)
                                    {
                                        if (responseData && responseData.isArchiveSuccess)
                                        {
                                            simpleAlertService.simpleAlert({
                                                title: 'SB_Transport_Route_Archived',
                                                message: 'SB_Transport_Route_Archived',
                                                staticBackdrop: true
                                            });

                                            closeModal();
                                        }
                                        else if (responseData && !responseData.isArchiveSuccess)
                                        {
                                            simpleAlertService.simpleAlert({
                                                title: 'SB_Error',
                                                message: 'SB_Transport_Archive_Operation_Failed',
                                                staticBackdrop: true
                                            });

                                            closeModal();
                                        }
                                        else
                                        {
                                            alertAndResetOkButton();
                                        }
                                    })
                                    .catch(function (responseData)
                                    {
                                        var message = {};

                                        if (responseData && responseData.data && responseData.data.Message)
                                        {
                                            message = { message: responseData.data.Message };
                                        }

                                        alertAndResetOkButton(message);
                                    });

                                resetSubmitButtons();
                            },
                            () => resetSubmitButtons()
                        );
                }.bind(this);

                this.goToTimetableRoutes = function (timetableId)
                {
                    transportTimetablesService.getTransportTimetable(timetableId)
                        .then(function (response)
                        {
                            var timetable = response;

                            var timetableRoutesModalInstance = $uibModal.open({
                                animation: true,
                                component: 'transportTimetableRoutes',
                                size: 'xl',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return {
                                            responseDto: timetable
                                        }
                                    }
                                }
                            });

                            timetableRoutesModalInstance.result
                                .then(function ()
                                {
                                    transportConfigurationRoutesService.getTransportRouteArchivingInfo(this.transportRoute.id)
                                        .then(function (responseData)
                                        {
                                            this.transportRouteArchivingInfo = responseData;

                                        }.bind(this))
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        }.bind(this))
                                }.bind(this));
                        }.bind(this))
                        .catch(function (responseData)
                        {
                            var message = {};

                            if (responseData && responseData.data && responseData.data.Message)
                            {
                                message = { message: responseData.data.Message };
                            }

                            alertAndResetOkButton(message);
                        }.bind(this));
                }.bind(this);
            }
        ]
    });
