import { downgradeComponent } from "@angular/upgrade/static";
import { UserRolesEditorComponent } from "@sb-shared/user-roles-editor/user-roles-editor.component";
import * as angular from "angular";

'use strict';

angular.module('sysAdminModule', [
  'ngFileUpload',
  'ui.grid',
  'ui.grid.edit',
  'ui.bootstrap',

  'shared.services.authInterceptor',
  'shared.services.dateTimeService',
  'shared.services.guidService',
  'shared.services.httpCoreApi',
  'shared.services.imagesUploadHandler',
  'shared.services.simpleAlertService',

  'shared.components.sbIcon',
  'shared.components.sbOnOffSwitch',

  'shared.directives.updateLanguage',
  'shared.directives.cloakTranslate',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',

  'organisation.services.organisationService',
  'roles.services.rolesService',
  'emailTemplates.components.emailTemplatesContainer',

  'sysadmin.components.calendarEventTypes',
  'sysadmin.components.domainName',
  'sysadmin.components.emailDomains',
  'sysadmin.components.imports',
  'sysadmin.components.importsIsams',
  'sysadmin.components.importsGg4l',
  'sysadmin.components.importsManageBac',
  'sysadmin.components.importsWonde',
  'sysadmin.components.mainSettingsContainer',
  'sysadmin.components.mainSettingTypes',
  'sysadmin.components.modules',
  'sysadmin.components.sysAdminUserRoles',
  'sysadmin.components.smsTopUp',
  'sysadmin.components.sysAdminForms',
  'sysadmin.components.sysAdminAgeBands',
  'sysadmin.components.sysAdminPackages',
  'sysadmin.components.sysAdminReferenceData',
  'sysadmin.components.sysAdminImportData',
  'sysadmin.components.sysAdminConfigSettings',
  'sysadmin.constants',

  'pascalprecht.translate',
  'tmh.dynamicLocale'
]).config([
  '$windowProvider',
  '$sceDelegateProvider',
  function (
    $windowProvider,
    $sceDelegateProvider) {

    const $window = $windowProvider.$get();

    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      $window.EveryBuddy.CdnBase + '**'
    ]);
  }
])
  .directive('sbUserRolesEditor', downgradeComponent({
    component: UserRolesEditorComponent,
    inputs: ['userRolesFilter', 'disabeld'],
    outputs: ['selectionChanged']
  }))
  .component('ng1SysadminMainSettings', {
    template: '<main-settings-container></main-settings-container>',
  })
  .component('ng1SysadminForms', {
    template: '<sys-admin-forms></sys-admin-forms>',
  })
  .component('ng1SysadminAgeBands', {
    template: '<sys-admin-age-bands></sys-admin-age-bands>',
  })
  .component('ng1SysadminPackages', {
    template: '<sys-admin-packages></sys-admin-packages>',
  })
  .component('ng1SysadminReferenceData', {
    template: '<sys-admin-reference-data></sys-admin-reference-data>',
  })
  .component('ng1SysadminImportData', {
    template: '<sys-admin-import-data></sys-admin-import-data>',
  })
  .component('ng1SysadminConfigSettings', {
    template: '<sys-admin-config-settings></sys-admin-config-settings>',
  })
  .component('ng1SysadminSms', {
    template: '<sms-top-up></sms-top-up>',
  })
  .component('ng1SysadminEmailTemplates', {
    template: '<email-templates-container></email-templates-container>',
  })
  .component('ng1SysadminUserRoles', {
    template: '<sys-admin-user-roles></sys-admin-user-roles>',
  })
