<div [ngbTooltip]="showTooltip ? (fullName ? fullName : formatName()) : ''"
     placement="bottom"
     container="body">
     <img class="avatar-image"
          [ngClass]="{'avatar-loading' : isLoading, 'cursor-default' : showDefaultCursor(), 'avatar-xl' : size === 'xl'}"
          *ngIf="imgUrlFound && photoUrl"
          [src]="photoUrl"
          (click)="profilePopup()" />
     <div class="avatar-initials"
          [ngClass]="{'avatar-loading' : isLoading,
          'cursor-default' : showDefaultCursor(),
          'avatar-xl' : size === 'xl',
          'avatar-sm' : size === 'sm',
          'avatar-initials-alt' : isOrg}"
          *ngIf="!imgUrlFound || !photoUrl"
          (click)="profilePopup()">
         {{firstName | limitTo : 1}}{{lastName | limitTo : 1}}
     </div>
</div>
