import { MenuItem, SubMenuItem } from '@sb-shared/models/UI/menu-item';
import { MainMenuItemId } from '../../events/enums/main-menu-item';
import { RouterPaths } from './router-paths.constants';
import { OrganisationLabelTags } from './organisation-label-tags.constants';
import { RoleId } from '@sb-shared/enums/role.enum';
import { SubMenuItemId } from '@sb-events/enums/sub-menu-item';

export const AvatarMenuItemConfig: MenuItem[] = [
  {
    label: OrganisationLabelTags.Switch,
    groupId: 1,
    iconName: 'switch',
    isXsOnly: true,
    userKeys: ['canSwitchOrganisation']
  },
  {
    label: 'MenuLogOut',
    route: 'Account/Logout',
    groupId: 1,
    iconName: 'logout',
    isXsOnly: true
  },
];

export const SubMenuItemConfig: SubMenuItem[] = [
  {
    route: "Settings/Onboarding",
    label: "SB_Main_Settings",
    icon: "fal fa-atom",
    parentId: MainMenuItemId.Settings,
    id: 1,
    pageTabs: [
      {
        label: 'SB_Welcome_Message',
        route: 'WelcomeMessage',
      },
      {
        label: 'SB_Organisation_Block_Dates',
        route: 'BlockDates'
      },
      {
        label: 'SB_Locations_Rooms',
        route: RouterPaths.Locations
      },
      {
        label: 'SB_MainSettings_OnlineParentsMeetings_MenuLabel',
        route: 'OnlineParentMeetings'
      },
      {
        label: 'SB_Attendance_Scanning',
        route: 'AttendanceScanning'
      },
      {
        label: 'SB_SportsPortal',
        route: RouterPaths.SportsPortal
      }
    ]
  },
  {
    route: "Settings/StaffRoles",
    label: "SB_Staff_Roles",
    icon: "fal fa-address-card",
    parentId: MainMenuItemId.Settings,
    id: 2
  },
  {
    route: "Settings/PortalLinks",
    label: "SB_Portal_Links",
    icon: "far fa-browser",
    parentId: MainMenuItemId.Settings,
    id: 3
  },
  {
    route: "Settings/FariaOne",
    label: "FariaOne",
    icon: "fas fa-plug",
    parentId: MainMenuItemId.Settings,
    id: 4
  },
  {
    route: "Reception/events",
    label: "SB_Registers_Slash_Events",
    icon: "fal fa-check-square",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.Events,
    helpArticleId: 4405336385677
  },
  {
    route: "Reception/messagecenter",
    label: "SB_Send_Communication",
    icon: "fal fa-bullhorn",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.MessageCenter
  },
  {
    route: "Reception/reggroups",
    label: "SB_Reg_Groups",
    icon: "fal fa-home",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.RegGroups
  },
  {
    route: "Reception/allgroups",
    label: "SB_Groups",
    icon: "fal fa-users",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.AllGroups
  },
  {
    route: "Reception/users",
    label: "SB_Users",
    icon: "fal fa-user",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.Users
  },
  {
    route: "Reception/locations",
    label: "SB_Locations",
    icon: "fal fa-map-signs",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.Locations
  },
  {
    route: "Reception/joinrequests",
    label: "SB_Join_Requests",
    icon: "fal fa-hand-point-up",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.JoinRequests
  },
  {
    route: "Reception/reports",
    label: "SB_Reports",
    icon: "fal fa-cloud-download",
    parentId: MainMenuItemId.Reception,
    requiredRoles: [RoleId.OrganisationSuperAdmin, RoleId.Admin],
    id: SubMenuItemId.Reports
  },
  {
    route: "Reception/staffschedule",
    label: "SB_Staff_Schedule",
    icon: "fal fa-calendar-alt",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.StaffSchedule
  },
  {
    route: "Reception/attendance",
    label: "SB_Atendance",
    icon: "fal fa-clipboard-check",
    parentId: MainMenuItemId.Reception,
    id: SubMenuItemId.Attendance
  },
  {
    route: "BalanceAdmin/balanceAdmin",
    label: "SB_Summary",
    icon: "fal fa-tachometer-alt-slow",
    sortOrder: 1,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 17
  },
  {
    route: "BalanceAdmin/moniesReceived",
    label: "SB_Monies_Received_and_Refunds",
    icon: "fal fa-receipt",
    sortOrder: 3,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 18
  },
  {
    route: "BalanceAdmin/recordPayment",
    label: "SB_Record_Payments",
    icon: "fal fa-money-bill-wave",
    sortOrder: 3,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 19
  },
  {
    route: "BalanceAdmin/balanceReports",
    label: "SB_Reports",
    icon: "fal fa-file-invoice-dollar",
    sortOrder: 4,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 20
  },
  {
    route: "BalanceAdmin/accountSummaries",
    label: "SB_Accounts_Overview",
    icon: "fal fa-id-card",
    sortOrder: 5,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 21
  },
  {
    route: "BalanceAdmin/creditAccountSummaries",
    label: "SB_Credit_Accounts_Overview",
    icon: "fal fa-wallet",
    sortOrder: 6,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 22
  },
  {
    route: "BalanceAdmin/voucherAccountSummaries",
    label: "SB_Voucher_Accounts_Overview",
    icon: "fal fa-ticket",
    sortOrder: 7,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 23
  },
  {
    route: "BalanceAdmin/quickCharge",
    label: "SB_Quick_Charge",
    icon: "fal fa-bolt",
    sortOrder: 8,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 24
  },
  {
    route: "BalanceAdmin/massAdjustmentStudent",
    label: "SB_Mass_Adjustment_Student",
    icon: "fal fa-list-ul",
    sortOrder: 9,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 25
  },
  {
    route: "BalanceAdmin/massAdjustmentGroup",
    label: "SB_Mass_Adjustment_Group",
    icon: "fal fa-list-alt",
    sortOrder: 10,
    parentId: MainMenuItemId.BalanceAdmin,
    id: 26
  },
  {
    route: "SysAdmin/mainsettings",
    label: "SB_Main_Settings",
    icon: "fal fa-atom",
    parentId: MainMenuItemId.SysAdmin,
    id: 31
  },
  {
    route: "SysAdmin/forms",
    label: "SB_Forms",
    icon: "fal fa-clipboard",
    parentId: MainMenuItemId.SysAdmin,
    id: 32
  },
  {
    route: "SysAdmin/referencedata",
    label: "SB_Reference_Data",
    icon: "fal fa-hdd",
    parentId: MainMenuItemId.SysAdmin,
    id: 33
  },
  {
    route: "SysAdmin/agebands",
    label: "SB_Age_Bands",
    icon: "fal fa-sliders-v",
    parentId: MainMenuItemId.SysAdmin,
    id: 34
  },
  {
    route: "SysAdmin/importdata",
    label: "SB_Import_Data",
    icon: "fal fa-file-import",
    parentId: MainMenuItemId.SysAdmin,
    id: 36
  },
  {
    route: "SysAdmin/smstopup",
    label: "SB_SMS",
    icon: "fal fa-comment-dollar",
    parentId: MainMenuItemId.SysAdmin,
    id: 37
  },
  {
    route: "SysAdmin/configsettings",
    label: "SB_Config_Settings",
    icon: "fal fa-sliders-v",
    parentId: MainMenuItemId.SysAdmin,
    id: 38
  },
  {
    route: "SysAdmin/userroles",
    label: "SB_User_Roles",
    icon: "fal fa-user",
    parentId: MainMenuItemId.SysAdmin,
    id: 39
  },
  {
    route: "SysAdmin/emailtemplates",
    label: "SB_Email_Templates",
    icon: "fal fa-envelope-open-text",
    parentId: MainMenuItemId.SysAdmin,
    id: 40
  },
  {
    route: "Profile/profile",
    label: "SB_Profile",
    icon: "fal fa-portrait",
    parentId: MainMenuItemId.Profile,
    id: 42,
    sortOrder: 2
  },
  {
    route: "Profile/diary",
    label: "SB_Diary",
    icon: "fal fa-portrait",
    parentId: MainMenuItemId.None,
    sortOrder: 3
  },
  {
    route: "Profile/linked",
    label: "SB_Linked",
    icon: "fal fa-link",
    parentId: MainMenuItemId.Profile,
    id: 45,
    sortOrder: 4
  },
  {
    route: "Profile/groups",
    label: "SB_Groups",
    icon: "fal fa-users",
    parentId: MainMenuItemId.Profile,
    id: 47,
    sortOrder: 5
  },
  {
    id: 46,
    route: "Profile/medical",
    label: "SB_Medical",
    icon: "fal fa-notes-medical",
    parentId: MainMenuItemId.Profile,
    sortOrder: 6
  },
  {
    route: "Profile/userForms",
    label: "SB_Custom_Data",
    icon: "fal fa-clipboard",
    parentId: MainMenuItemId.Profile,
    id: 48,
    organisationKeys: ['hasUserForms'],
    sortOrder: 7
  },
  {
    route: "Profile/singlesignon",
    label: "SB_Single_Sign_On",
    icon: "fal fa-key",
    parentId: MainMenuItemId.Profile,
    id: 49,
    organisationKeys: ['hasSingleSignOnEnabled'],
    sortOrder: 9
  },
  {
    route: "Profile/welcome",
    label: "SB_Welcome_Message",
    icon: "fal fa-message",
    parentId: MainMenuItemId.Profile,
    id: 50,
    sortOrder: 1
  },
  {
    route: "Profile/permissiontowalk",
    label: "SB_Permission_To_Walk_Tab",
    icon: "fal fa-walking",
    parentId: MainMenuItemId.Profile,
    id: 51,
    organisationKeys: ['isPermissionToWalkAvailable'],
    userKeys: ['hasParentAccess'],
    sortOrder: 8
  },
  {
    route: "TuitionFees/BillingCycle",
    label: "SB_Billing_Cycle",
    icon: "fas fa-circle",
    parentId: MainMenuItemId.TuitionFees,
    id: 53
  },
  {
    route: "TuitionFees/Invoice",
    label: "SB_Invoices",
    icon: "fas fa-file-invoice",
    parentId: MainMenuItemId.TuitionFees,
    id: 54
  },
  {
    route: "Messaging/inbox",
    label: "SB_Your_Messages",
    icon: "fas fa-inbox",
    parentId: MainMenuItemId.Messaging,
    id: 55,
    userKeys: ['isStaff']
  },
  {
    route: "Messaging/adminNew",
    label: "SB_All_Messages",
    icon: "far fa-inboxes",
    parentId: MainMenuItemId.Messaging,
    id: 57,
    userKeys: ['isStaff'],
    requiredRoles: [RoleId.Admin, RoleId.CommunicationAdmin]
  },
  {
    route: "Messaging/compose",
    label: "SB_Send_Communication",
    icon: "far fa-bullhorn",
    parentId: MainMenuItemId.Messaging,
    id: 58,
    userKeys: ['canSendMessages']
  },
  {
    route: "Transport/LiveRouteData",
    label: "SB_Transport_Route_Updates_Title",
    icon: "fas fa-stopwatch",
    sortOrder: 1,
    parentId: MainMenuItemId.Transport,
    id: 27
  },
  {
    route: "Transport/Configuration",
    label: "SB_Config_Settings",
    icon: "fal fa-atom",
    sortOrder: 2,
    parentId: MainMenuItemId.Transport,
    id: 27,
    pageTabs: [
      {
        label: 'SB_Methods',
        route: 'Methods',
      },
      {
        label: 'SB_Operating_Days',
        route: 'OperatingDays'
      },
      {
        label: 'SB_Directions',
        route: 'Directions'
      },
      {
        label: 'SB_Timeslots',
        route: 'Timeslots'
      },
      {
        label: 'SB_Stops',
        route: 'Stops'
      },
      {
        label: 'SB_Routes',
        route: 'Routes'
      },
      {
        label: 'SB_Timetable_Calendars',
        route: 'TimetableCalendars'
      },
      {
        label: 'SB_Timetables',
        route: 'Timetables'
      },
      {
        label: 'SB_Features',
        route: 'Features'
      }
    ]
  },
  {
    route: "Transport/MassPupilTransportAssignment",
    label: "SB_Mass_Pupil_Transport_Assignment",
    icon: "fas fa-users-class",
    sortOrder: 4,
    parentId: MainMenuItemId.Transport,
    id: 28
  },
  {
    route: "Transport/Reports",
    label: "SB_Reports",
    icon: "fal fa-cloud-download",
    sortOrder: 5,
    parentId: MainMenuItemId.Transport,
    id: 29
  },
  {
    route: "Transport/ChangeRequests",
    label: "SB_Change_Requests",
    icon: "fal fa-comment-edit",
    sortOrder: 5,
    parentId: MainMenuItemId.Transport,
    id: 30,
    pageTabs: [{
      label: 'SB_Transport_Pending_Change_Requests',
      route: 'Pending' // Amended route
    },
    {
      label: 'SB_Transport_Change_Request_UpcomingApprovedRejected',
      route: 'ApprovedOrRejected' // Amended route
    }]
  }
]
