angular.module('ccaAvailable.services.ccaActionsService', [])
.service('ccaActionsService', [
    '$state', function ($state) {

        var service = this;

        service.addActionsToSignUps = function (signUps) {
            if (Array.isArray(signUps)) {
                var isManaging = 1;

                signUps.forEach(function (signUp) {
                    signUp.selections = 0;
                    signUp.pupils.forEach(function (pupil) {
                        signUp.selections += pupil.selectionCount;
                    });
                    signUp.actions = [];

                    if (!signUp) {
                        return;
                    }

                    if (!signUp.isOrganisationAllocated) {
                        signUp.actions.push({ function: service.ccaSelection, labels: ['SB_SELECT_BUTTON'], icon: 'check' });
                        return;
                    }

                    //Preference based have different display rules
                    if (signUp.isOrganisationAllocated === true) {

                        //Join button for live preference-based signups which allow join requests
                        if (signUp.allowJoinRequests && signUp.status == 3) {
                            signUp.actions.push({ function: service.requestToJoin, labels: ['SB_REQUEST_TO_JOIN_BUTTON'], icon: 'joinrequests' });
                            return;
                        }

                        //when managing
                        if (signUp.status == isManaging) {
                            //No selections = Tick icon - label ‘Choose’ and Eye icon - label 'Review/Send'
                            if (signUp.selections == 0) {

                                signUp.actions.push({
                                    function: service.ccaDetails,
                                    labels: ['SB_CHOOSE_BUTTON'],
                                    icon: 'check'
                                });

                                signUp.actions.push({
                                    function: service.confirm,
                                    labels: ['SB_REVIEW_BUTTON', 'SB_SEND_BUTTON'],
                                    icon: 'show'
                                });

                                return;
                            }

                            //Selections but not confirmed
                            if (!signUp.isConfirmed) {
                                signUp.actions.push({
                                    function: service.ccaDetails,
                                    labels: ['SB_CHOOSE_BUTTON'],
                                    icon: 'check'
                                });

                                signUp.actions.push({
                                    function: service.confirm,
                                    labels: ['SB_REVIEW_BUTTON', 'SB_SEND_BUTTON'],
                                    icon: 'show'
                                });

                                return;
                            }

                            //Selections confirmed = Eye icon - label 'Review/Choose'  (label appends SB_CHOOSE_BUTTON on  signUp.canUnconfirm )
                            var action =
                            {
                                function: service.confirm,
                                labels: ['SB_REVIEW_BUTTON'],
                                icon: 'show'
                            };
                            if (signUp.canUnconfirm) {
                                action.labels.push('SB_CHOOSE_BUTTON');
                            }
                            signUp.actions.push(action);
                        }
                    }
                });

                return signUps;
            }
        };

        service.triggerPrimaryAction = function (signUp) {
            var actions = signUp.actions;
            if (Array.isArray(actions) && actions.length > 0) {
                actions[0].function(signUp);
            }
        };

        service.ccaDetails = function(signUp) {
            $state.go('ccaDetails', { signUpId: signUp.ccaSignUpId });
        };

        service.confirm = function(signUp) {
            $state.go('ccaReview', { signUpReviewId: signUp.ccaSignUpId });
        };

        service.requestToJoin = function(signUp) {
            $state.go('ccaToJoin', { signUpId: signUp.ccaSignUpId });
        };

        service.ccaSelection = function (signUp)
        {
            if (signUp.isTimeSlot)
            {
                $state.go('ccaSelectionParentsEvening', { signUpId: signUp.ccaSignUpId });
            }
            else
            {
                $state.go('ccaSelection', { signUpId: signUp.ccaSignUpId });
            }

        };

        service.viewAllSignUps = function () {
            $state.go('ccaAvailable');
        };

        return service;
    }
]);
