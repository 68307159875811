angular.module('sysadmin.components.emailFooter', [
    'sysadmin.services.emailFooterService',
]).component('emailFooter', {
    templateUrl: '/Scripts/app/sysadmin/components/email-footer.template.html',
    bindings: {
        organisation: '<',
    },
    controller: class LanguageCtrl {
        // Dependencies
        emailFooterService: any;
        imagesUploadHandler: any;
        tinymceConfigHelper: any;
        //Bindings
        organisation: any;
        // Variables
        isLoading: boolean = false;
        isProcessing: boolean = false;
        footerHtml: string;
        footerHtmlClean: string;
        hasExistingFooter: boolean = false;
        tinymceOptions: any;

        static $inject = ['emailFooterService', 'imagesUploadHandler', 'tinymceConfigHelper'];

        constructor(emailFooterService, imagesUploadHandler, tinymceConfigHelper) {
            this.emailFooterService = emailFooterService;
            this.imagesUploadHandler = imagesUploadHandler;
            this.tinymceConfigHelper = tinymceConfigHelper;
        }

        $onInit() {
            this.tinymceOptions = this.tinymceConfigHelper.getTinyMceConfig(
                {
                    images: true,
                    editHtml: true,
                    forceLinkPrefix: false,
                    imagesUploadHandler: this.imagesUploadHandler.uploadImage,
                    imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                });
        }


        $onChanges(changes)
        {
            if (changes.organisation?.currentValue)
            {
                this.getEmailFooter();
            }
        };

        getEmailFooter() {
            this.isLoading = true;
            this.emailFooterService.getEmailFooter(this.organisation.organisationId)
                .then(data => {
                    const footerHtml = data?.data?.htmlFooterTemplate;
                    this.footerHtmlClean = footerHtml || '';
                    this.applyCleanData();
                })
                .finally(() => {
                    this.isLoading = false;
                })
        }

        save() {
            this.isProcessing = true;
            this.footerHtmlClean ?
                this.emailFooterService.updateEmailFooter(this.organisation.organisationId, this.footerHtml).then(() => {
                    this.footerHtmlClean = this.footerHtml;
                    this.isProcessing = false;
                }) :
                this.emailFooterService.createEmailFooter(this.organisation.organisationId, this.footerHtml).then(() => {
                    this.footerHtmlClean = this.footerHtml;
                    this.isProcessing = false;
                })
        }

        applyCleanData() {
            this.footerHtml = this.footerHtmlClean;
        }
    }
});
