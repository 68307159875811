<span [ngClass]="computedClass"
      [ngbTooltip]="tooltipText"
      [placement]="tooltipPlacement"
      class="align-center">
    <sb-icon *ngIf="icon"
             [name]="icon.name"
             [altText]="icon.tooltip"
             [size]="icon.size"
             class="mr-2"
             [ngClass]="icon.class"></sb-icon>
    <span *ngIf="isCurrency"
          [innerHTML]="currencySymbol"></span>
    <span *ngIf="message"
          [innerHTML]="message | translate"></span>
    <ng-content></ng-content>
</span>
