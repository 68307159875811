'use strict';

angular.module('transport.pupilPreferences.components.massPupilTransportAssignmentMain',
    [
        'shared.directives.sbCheckBox',
        'shared.services.simpleAlertService',
        'transport.services.transportTimeSlotsService',
    ])
    .component('massPupilTransportAssignmentMain',
        {
            bindings: {
            },
            templateUrl: '/Scripts/app/transport/pupilPreferences/components/mass-pupil-transport-assignment-main.template.html',
            controller: [
                'transportTimeSlotsService',
                'simpleAlertService',
                function (transportTimeSlotsService, simpleAlertService)
                {
                    this.slotId = 1;
                    this.timetable = null;
                    this.timetableRouteId = null;
                    this.selectionDescription = '';

                    this.selectedTimeSlotId = null;
                    this.selectedTimeSlotName = '';
                    this.selectedDirectionId = null;

                    this.transportTimeSlots = null;

                    this.preferencesLoadInProgress = false;
                    this.preferencesUpdateInProgress = false;

                    this.selectedTimetableCalendarName = '';

                    this.capacity = null;
                    this.highestNumberOfStudentPreferencesForAnyDay = null;

                    this.isLoadingComplete = false;

                    transportTimeSlotsService.getActiveTransportTimeSlots().then(function (data)
                    {
                        this.transportTimeSlots = data;

                        if (typeof this.transportTimeSlots !== 'undefined' && this.transportTimeSlots.length > 0)
                        {
                            this.selectedTimeSlotId = data[0].id;
                            this.setSelectedTimeSlotName();
                        }
                    }.bind(this), function (error)
                    {
                        simpleAlertService.errorAlert(error);
                    }.bind(this));

                    this.timeSlotChanged = function ()
                    {
                        this.setSelectedTimeSlotName();
                    }

                    this.setSelectedTimeSlotName = function ()
                    {
                        for (var i = 0; i < this.transportTimeSlots.length; i++)
                        {
                            if (this.transportTimeSlots[i].id == this.selectedTimeSlotId)
                            {
                                this.selectedTimeSlotName = this.transportTimeSlots[i].timeslotAndDirectionName;
                                break;
                            }
                        }
                    };
                }
            ]
        });