import { Component, OnInit } from '@angular/core';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { ProfileDataService } from '../../services/profile-data.service';

@Component({
  selector: 'sb-profile-sso',
  templateUrl: './profile-sso.component.html',
  styleUrls: ['./profile-sso.component.scss']
})
export class ProfileSsoComponent implements OnInit {
  emailAddress: string;
  hasSSO: boolean;

  constructor(
    private profileData: ProfileDataService,
    private orgSvc: OrganisationService) { }

  ngOnInit(): void {
    this.profileData.getUserInContext().subscribe(user => {
      this.emailAddress = user.emailAddress;
    });

    this.orgSvc.getCurrentOrganisation().subscribe(org => {
      this.hasSSO = org.hasSingleSignOnEnabled;
    });
  }
}
