angular.module('corporateContacts.components.corporateContactPickerField',
    [
        'corporateContacts.services.corporateContactsService',
        'corporateContacts.controllers.corporateContactPickerPopupController',
        'corporateContacts.components.corporateContactEditorPopup',
        'shared.components.sbIcon',
        'pascalprecht.translate'
    ])
    .component('corporateContactPickerField',
        {
            bindings: {
                model: '=',
                required: '<',
                contactType: '<',
                onChange: '&'
            },
            templateUrl: '/Scripts/app/corporateContacts/components/corporate-contact-picker-field.template.html',
            controller: [
                '$uibModal',
                'corporateContactsService',
                function corporateContactEditorController($uibModal, corporateContactsService)
                {
                    var loading = 0;
                    this.contacts = [];

                    this.loading++;
                    corporateContactsService.getAllContacts()
                        .then(function (data) {
                            for (var i = data.length; i--;) {
                                this.contacts[data[i].id] = data[i].name;
                            }

                            this.loading--;
                        }.bind(this));

                    this.showContactPicker = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Scripts/app/corporateContacts/controllers/corporate-contact-picker-popup.template.html',
                            controller: 'corporateContactPickerPopupController',
                            size: 'md',
                            resolve: {
                                selectedContactId: function()
                                {
                                     return this.model;
                                }.bind(this),
                                contactType: function ()
                                {
                                    return this.contactType;
                                }.bind(this)
                            }
                        });

                        modalInstance
                            .result
                            .then(function (newValue) {
                                this.model = newValue;
                            }.bind(this))
                            .catch(function () { console.log('dialog dismissed'); });
                    }.bind(this);

                    this.showDetails = function()
                    {

                        var modalInstance = $uibModal.open({
                            animation: true,
                            component: 'corporateContactEditorPopup',
                            resolve: {
                                contactId: function () { return this.model }.bind(this),
                                readOnly: function() { return true; }
                            },
                            size: 'md'
                        });

                    }.bind(this);
                }
            ]
        });