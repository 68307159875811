'use strict';

angular.module('ccasignupModule').component('ccaAddPupilAATimeSlotted', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-add-pupil-aa-time-slotted.template.html',
    controller:
        [
            '$scope',
            '$state',
            '$filter',
            'availableRepository',
            'ccaService',
            'EventCategorySystemUsageType',
            'personService',
            'signUpRepository',
            'simpleAlertService',
            'bookingStatusEnum',
            function ($scope,
                $state,
                $filter,
                availableRepository,
                ccaService,
                EventCategorySystemUsageType,
                personService,
                signUpRepository,
                simpleAlertService,
                bookingStatusEnum) {
                $scope.signUp = {};
                $scope.processing = {};
                $scope.EventCategorySystemUsageType = EventCategorySystemUsageType;
                $scope.eventsData = null;
                $scope.sortType = 'from';
                $scope.sortReverse = false;
                $scope.searchFilters = { 'title': '', 'teamTitle': '', 'fromDay': '' };
                $scope.queryFilters = { showOnlyEligibleEvents: true };
                $scope.unconfirmedBookingCount = 0;

                $scope.currentDate = null;
                $scope.currentDay = null;

                $scope.isSaving = false;
                $scope.isSubmitted = false;

                $scope.timeRemaining;
                $scope.timeRemainingMins;
                $scope.timeRemainingSecs;
                $scope.timer = null;
                $scope.hasBookingSelectionTimedOut = false;
                $scope.minutesUntilBookingExpiry = 0;

                // the two following watches
                // need to be in this order as they
                // need to fire in a particular order
                $scope.$watch('sortType', function (newValue, oldValue) {
                    $scope.currrentSortType = newValue;
                });

                $scope.$watch('sortReverse', function (newValue, oldValue) {
                    if ('from' === $scope.currrentSortType) {
                        $scope.fromSortReverse = newValue;
                    }
                });

                signUpRepository.getSignUp($state.params.signUpId).then(function (signUp) {
                    $scope.signUp = signUp;
                });

                // Function used by autocomplete component
                $scope.getPupils = personService.lookupPupilByName;
                $scope.getPupilArgs = [
                    false // whether to include deleted pupils. Always false in this view.
                ];

                $scope.onSelect = function ($item, $model, $label) {
                    $scope.$item = $item;
                    $scope.$model = $model;
                    $scope.filterTitle = $label;
                    $scope.$label = $label;

                    $scope.refreshData();
                };

                $scope.refreshData = function () {
                    $scope.eventsData = null;
                    $scope.unconfirmedBookingCount = 0;

                    signUpRepository
                        .getSignUpEventsForPupil($state.params.signUpId, $scope.$item.id, $scope.queryFilters.showOnlyEligibleEvents)
                        .then(function (eventsData) {
                            $scope.eventsData = eventsData;

                            // Need to reset in case admin has switched from one user with bookings to one without.
                            $scope.resetTimeRemaining();
                        });
                };

                $scope.addBookingHandler = function (pupil, event) {
                    return availableRepository.postToggleBookingLite(pupil, event)
                        .then(responseDto => {

                            $scope.handleToggleBookingLiteResponse(responseDto);

                            // Pass back to child container.
                            return responseDto;
                        });
                };

                $scope.removeBookingHandler = function (pupil, event) {
                    return availableRepository.postToggleBookingLite(pupil, event)
                        .then(responseDto => {

                            $scope.handleToggleBookingLiteResponse(responseDto);

                            // Pass back to child container.
                            return responseDto;
                        });
                };

                $scope.handleToggleBookingLiteResponse = function (responseDto) {
                    // Find the event in scope and update its status and expiry to reflect latest time remaining in countdown logic.
                    if ($scope.eventsData && $scope.eventsData.availableEvents) {
                        var calendarEventDto = $scope.eventsData.availableEvents.find(x => x.calendarEventId == responseDto.calendarEventId);
                        if (calendarEventDto && calendarEventDto.pupils) {
                            var pupilDto = calendarEventDto.pupils.find(x => x.personId == responseDto.personId);
                            if (pupilDto) {
                                pupilDto.status = responseDto.bookingStatusId;
                                pupilDto.expiryTimestampUtc = responseDto.expiryTimestampUtc;
                            }
                        }
                    }

                    $scope.resetTimeRemaining();
                }

                $scope.cancelBookingHandler = function (personId, eventId) {
                    return availableRepository.cancelAAConfirmedBooking(personId, eventId)
                        .then(function (data) {
                            if (data.isError) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        });
                };

                $scope.resetTimeRemaining = () => {
                    if (typeof $scope.minutesUntilBookingExpiry === 'undefined' ||
                        $scope.minutesUntilBookingExpiry == 0) {
                        $scope.minutesUntilBookingExpiry = $scope.eventsData.minutesUntilBookingExpiry;
                    }

                    let bookingExpiryInfo = ccaService.getBookingExpiryInfo($scope.eventsData.availableEvents);

                    // Oldest Booking Expiry is taken from any calendar event bookings that are not timed-out, removed, cancelled or deleted.
                    $scope.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;

                    if ($scope.oldestBookingExpiry) {
                        $scope.timeRemainingMins = moment.utc(bookingExpiryInfo.timeRemaining).get('minutes')
                        $scope.timeRemainingSecs = moment.utc(bookingExpiryInfo.timeRemaining).get('seconds');
                    } else {
                        const minutesToShowForCountdown = $scope.minutesUntilBookingExpiry > 5
                            ? $scope.minutesUntilBookingExpiry - 5
                            : $scope.minutesUntilBookingExpiry;

                        $scope.timeRemainingMins = minutesToShowForCountdown;
                        $scope.timeRemainingSecs = 0;
                    }
                };

                $scope.onTimeoutBooking = () => {
                    $scope.timeOutBooking();
                    $scope.deselectAllStudents();
                }

                $scope.onUpdateTimeRemaining = (timeRemaining) => {
                    $scope.timeRemaining = timeRemaining;
                }

                $scope.timeOutBooking = () => {
                    $scope.hasBookingSelectionTimedOut = true;
                    $scope.timeRemainingMins = 0;
                    $scope.timeRemainingSecs = 0;

                    let timeOutBookingsRequest =
                    {
                        signUpId: $scope.signUp.ccaSignUpId,
                        timeOutBookingRequests: []
                    }

                    $scope.eventsData.availableEvents.forEach((signUpEvent) => {
                        let selectedStudentIds = [];

                        signUpEvent.pupils.forEach((student) => {
                            if (student.status == bookingStatusEnum.Reserved) {
                                selectedStudentIds.push(student.personId);
                            }
                        });

                        if (selectedStudentIds.length > 0) {
                            let timeOutBookingRequest =
                            {
                                calendarEventId: signUpEvent.calendarEventId,
                                selectedStudentIds: selectedStudentIds
                            }

                            timeOutBookingsRequest.timeOutBookingRequests.push(timeOutBookingRequest);
                        }
                    });

                    ccaService.timeOutSelectedStudentBookings(timeOutBookingsRequest);
                }

                // This does not reflect in the time-slotted-event-picker unfortunately, as that no longer works with the same set of data.
                // But it will disable any selections as it is bound to hasBookingSelectionTimedOut.
                $scope.deselectAllStudents = () => {
                    let selectedStudents = $scope.eventsData.availableEvents
                        .flatMap(x => x.pupils.filter(x => x.status == bookingStatusEnum.Reserved));
                    selectedStudents.forEach(y => {
                        y.expiryTimestampUtc = null;
                        y.status = bookingStatusEnum.TimedOut;
                        y.selectionCount = 0;
                    });
                }

                $scope.getSignUpEventsHandler = function () {
                    return signUpRepository
                        .getSignUpEventsForPupil($state.params.signUpId, $scope.$item.id, $scope.queryFilters.showOnlyEligibleEvents);
                }

                $scope.confirmBookings = function () {
                    signUpRepository.saveBookings($scope.signUpEvents, $scope.pupils, $state.params.signUpId, true)
                        .then(function (response) {
                            if (response && response.hasOwnProperty('isSuccess') && !response.isSuccess) {
                                simpleAlertService.errorAlert({
                                    message: response.errorResponse ?? 'SB_Error_Saving', messageTranslationParameters:
                                    {
                                        eventTitle: response.eventTitle ?? '',
                                        eventOrganiserName: response.eventTitle ?? '',
                                        eventDateTime: $filter('date')(response.startDateTime, 'HH:mm')
                                    }
                                })

                                $scope.saving = false;
                                return;
                            }

                            $scope.getSignUpEventsHandler()
                                .then((eventsData) => {
                                    let doPendingBookingsExist = availableRepository.doPendingBookingsExist(eventsData.availableEvents);

                                    if (doPendingBookingsExist) {
                                        $scope.resetTimeRemaining();
                                    }

                                    $state.go('ccaDetailsAA.ccaAddPupilConfirmation',
                                        {
                                            pupilId: $scope.$item.id,
                                            timeRemaining: $scope.timeRemaining,
                                            minutesUntilBookingExpiry: $scope.minutesUntilBookingExpiry
                                        });
                                });
                        });
                };
            }
        ]
});

angular.module('ccasignupModule').filter('sumByKey', ['$filter', function ($filter) {
    return function (data, key) {
        if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
            return 0;
        }

        var sum = 0;
        angular.forEach(data, function (obj, objKey) {
            sum += parseFloat(obj[key]);
        });

        return sum;
    };
}]);
