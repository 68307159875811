'use strict';

angular.module('shared.services.settingService', ['shared.services.cachedLookupService'])
    .service('settingService', [
        '$http',
        'cachedLookupService',
        function ($http, cachedLookupService) {

            var service = this;

            var urlControllerBase = '/webapi/WebSettings/';

            service.getPersonSetting = function (settingName) {

                return $http.get(urlControllerBase + "GetPersonSettingAsync", { params: { settingName: settingName } })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            service.savePersonSetting = function (settingName, settingValue) {

                return $http.post(urlControllerBase + "SetPersonSettingAsync", { settingName: settingName, settingValue: settingValue })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            var eventTypesSetting = 'Reception_Register_Events_Search_Filter';

            service.buildEventTypeSetting = function(data) {
                    return cachedLookupService.isParent().then(function(isParent) {
                    var savedEventTypesConfig = data ? JSON.parse(data) : {};
                    var eventTypesConfig = {};
                    eventTypesConfig.includeParentsEvening = isParent ? true : (savedEventTypesConfig.includeParentsEvening != undefined ? savedEventTypesConfig.includeParentsEvening : true);
                    eventTypesConfig.includeFeeOnlyEvents = savedEventTypesConfig.includeFeeOnlyEvents != undefined ? savedEventTypesConfig.includeFeeOnlyEvents : false;
                    eventTypesConfig.includeTransportEvents = savedEventTypesConfig.includeTransportEvents != undefined ? savedEventTypesConfig.includeTransportEvents : true;
                    if (!data) {
                        eventTypesConfig.unavailable = true;
                    }
                    return eventTypesConfig;
                });
            };

            service.getEventTypesSetting = function () {
                return service.getPersonSetting(eventTypesSetting)
                .then(function (data)
                {
                    return service.buildEventTypeSetting(data);
                })
                .catch(function (e) {
                    console.log(e);
                    return service.buildEventTypeSetting();
                });
            };

            service.saveEventTypesSetting = function (settingValue) {

                return service.savePersonSetting(eventTypesSetting, settingValue);

            };

            return this;
        }]
    );