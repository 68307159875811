// start:ng42.barrel
import './cca-add-pupil-aa.component';
import './cca-add-pupil-aa-time-slotted.component';
import './cca-add-pupil-booking-complete.component';
import './cca-addpupil-confirmation.component';
import './cca-detailsaa.component';
import './cca-group-manage-aa.component';
import './cca-signup-detailsaa-bulk-create-groups.component';
import './cca-signup-detailsaa-manage-events-detail.component';
import './cca-signup-detailsaa-manage-events.component';
import './cca-signup-existingeventsaa-attendees.component';
import './cca-signup-existingeventsaa-details.component';
import './cca-signup-existingeventsaa-timeslotted.component';
import './cca-signup-existingeventsaa.component';
import './cca-signup-generate-eventsaa.component';
import './cca-signup-groupsaa.component';
import './cca-staff-schedule-aa.component';
// end:ng42.barrel

