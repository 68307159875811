angular.module('balanceAdminModule', [
  'tmh.dynamicLocale',
  'ui.bootstrap',

  'balance.constants',

  'balanceAdmin.components.accountSummaryList',
  'balanceAdmin.dashboard.components.feesDashboard',
  'balanceAdmin.massAdjustmentGroup.components.massAdjustmentGroup',
  'balanceAdmin.massAdjustmentStudent.components.massAdjustmentStudent',
  'balanceAdmin.quickCharge.components.quickCharge',
  'balanceAdmin.recordPayment.components.recordPayment',
  'balanceAdmin.reports.components.balanceAdminReports',
  'balanceAdmin.services.balanceAdminOldService',
  'balanceAdmin.services.balanceAdminService',
  'balanceAdmin.summary.components.balanceAdminSummary',

  'diary.components.diaryEventList',

  'organisation.services.organisationService',

  'payments.components.paymentResult',


  'person.services',
  'person.services.personPickerService',

  'profile.controllers.profilePopupController',

  'reports.components.reportContainer',

  'shared.components.sbAutoComplete',
  'shared.components.sbIcon',
  'shared.components.sbDatePicker',
  'shared.components.sbColumnHeader',
  'shared.directives.cloakTranslate',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',
  'shared.directives.sbCheckBox',
  'shared.directives.sbCurrencyInput',
  'shared.components.sbSubmitButton',
  'shared.components.subMenu',
  'shared.directives.updateLanguage',
  'shared.services.arrayService',
  'shared.services.authInterceptor',
  'shared.services.guidService',
  'shared.services.simpleAlertService',

  'groups.services.groupService'
]);
angular.module('balanceAdminModule').config([
  'ng1StateDefinitionProvider',
  function (ng1StateDefinitionProvider) {
    const ng1State = ng1StateDefinitionProvider.$get();
    const moduleName = 'BalanceAdmin';

    ng1State.state(moduleName, 'feesDashboard', {
      url: '/'
    })
    ng1State.state(moduleName, 'paymentAdmin', {
      url: '/paymentAdmin'
    })
    ng1State.state(moduleName, 'paymentComplete', {
      url: '/paymentComplete'
    })
    ng1State.state(moduleName, 'feesDashboard.summary', {
      url: 'balanceAdmin'
    })
    ng1State.state(moduleName, 'feesDashboard.reports', {
      url: 'balanceReports'
    })
    ng1State.state(moduleName, 'feesDashboard.moniesReceived', {
      url: 'moniesReceived'
    })
    ng1State.state(moduleName, 'feesDashboard.recordPayment', {
      url: 'recordPayment'
    })
    ng1State.state(moduleName, 'feesDashboard.massAdjustmentGroup', {
      url: 'massAdjustmentGroup'
    })
    ng1State.state(moduleName, 'feesDashboard.massAdjustmentStudent', {
      url: 'massAdjustmentStudent'
    })
    ng1State.state(moduleName, 'feesDashboard.quickCharge', {
      url: 'quickCharge'
    })
    ng1State.state(moduleName, 'feesDashboard.accountSummaries', {
      url: 'accountSummaries'
    })
    ng1State.state(moduleName, 'feesDashboard.creditAccountSummaries', {
      url: 'creditAccountSummaries'
    })
    ng1State.state(moduleName, 'feesDashboard.voucherAccountSummaries', {
      url: 'voucherAccountSummaries'
    });
  }
])
  .component('ng1PaymentAdmin', {
    template: '<payment-admin></payment-admin>',
  })
  .component('ng1PaymentCompleteAdmin', {
    template: '<payment-result-component></payment-result-component>',
  })
  .component('ng1FeesDashboardSummary', {
    template: '<balance-admin-summary></balance-admin-summary>',
  })
  .component('ng1FeesDashboardReports', {
    template: '<balance-admin-reports></balance-admin-reports>',
  })
  .component('ng1FeesDashboardMoniesReceived', {
    template: '<monies-received></monies-received>',
  })
  .component('ng1FeesDashboardRecordPayment', {
    template: '<record-payment></record-payment>',
  })
  .component('ng1FeesDashboardMassAdjustmentGroup', {
    template: '<mass-adjustment-group></mass-adjustment-group>',
  })
  .component('ng1FeesDashboardMassAdjustmentStudent', {
    template: '<mass-adjustment-student></mass-adjustment-student>',
  })
  .component('ng1FeesDashboardQuickCharge', {
    template: '<quick-charge></quick-charge>',
  })
  .component('ng1FeesDashboardAccountSummaries', {
    template: '<account-summary-list account-type-id="1"></account-summary-list>',
  })
  .component('ng1FeesDashboardCreditAccountSummaries', {
    template: '<account-summary-list account-type-id="2" show-include-source="true"></account-summary-list>',
  })
  .component('ng1FeesDashboardVoucherAccountSummaries', {
    template: '<account-summary-list account-type-id="3" show-include-source="true"></account-summary-list>',
  })
