'use strict';

angular.module('events.directives.timeSlottedEventPicker',
    [
            'shared.services.simpleAlertService',
            'shared.directives.sbCheckBox',
            'shared.components.sbIcon',
            'shared.controllers.confirmationPopup',
            'angular.filter'
        ])
    .directive('timeSlottedEventPicker',
    [
        '$sce',
        '$templateRequest',
        '$compile',
        '$timeout',
        '$window',
        'simpleAlertService',
        function ($sce, $templateRequest, $compile, $timeout, $window, simpleAlertService)
            {
                function link($scope)
                {
                    $scope.$watch('eventsData', function (newValue) {

                        $scope.reset();

                        if ($scope.eventsData)
                        {
                            $scope.processNewEventsData();
                        }
                    });
                }

                function controller($scope, $filter, $q)
                {
                    var STATUS_RESERVED = 1;

                    $scope.showTimeLimitWarning = false;

                    $scope.reset = function()
                    {
                        $scope.groupEvents = {};

                        // $scope.groupEventSelections[teamId][personId] = <count>
                        $scope.groupEventSelections = {};

                        // a 2d array to hold ui state data- (whats selected/ whats expanded)
                        $scope.groupPersonSelections = {};

                        // $scope.staff[staffId] = <formatted_name>;
                        $scope.staff = {};

                        // all the bookings made by current parent
                        $scope.yourEvents = [];

                        // loading locks are used to disable buttons during a save operation
                        // to stop people trying to get around the clash detection, as the system doesn't know an event is
                        // booked until the call is complete

                        // $scope.loadingGroupLocks[teamId] = <array peopleIds, each element holding the lock count>;
                        $scope.loadingGroupLocks = {};

                        // $scope.loadingTimeslotLocks[eventId] = <lock count>;
                        $scope.loadingEventLocks = {};
                    };

                    $scope.reset();

                    $scope.getChildName = function (personId) {
                        for (var i = $scope.pupils.length; i--;) {
                            if ($scope.pupils[i].personId === personId) {
                                return $scope.pupils[i].lastName + ', ' + $scope.pupils[i].firstName;
                            }
                        }

                        return '';
                    };

                    var eventClashCache = [];
                    var doEventsClash = function(eventA, eventB)
                    {
                        // to save caching 2 combinations, we always store in the format [smallestId][largestId]=<clash result>
                        var lowestEventId = Math.min(eventA.eventId, eventB.eventId);
                        var highestEventId = Math.max(eventA.eventId, eventB.eventId);

                        // we shall caching this as it's called a lot and the data will not change
                        if (typeof eventClashCache[lowestEventId] !== 'undefined') {
                            if (typeof eventClashCache[lowestEventId][highestEventId] !== 'undefined') {
                                return eventClashCache[lowestEventId][highestEventId];
                            }
                            else {
                                eventClashCache[lowestEventId][highestEventId] = false;
                            }
                        }
                        else {
                            eventClashCache[lowestEventId] = [];
                            eventClashCache[lowestEventId][highestEventId] = false;
                        }

                        var comparisonResult = (eventA.unixEndTime > eventB.unixStartTime && eventA.unixStartTime < eventB.unixEndTime);

                        eventClashCache[lowestEventId][highestEventId] = comparisonResult;

                        return comparisonResult;
                    };

                    $scope.hasMadeSelectionsForAllTeams = function (personId) {
                        return !Object.values($scope.groupPersonSelections).some(groupPersonSelection => groupPersonSelection?.[personId]?.selectionMade === 0);
                    };

                    $scope.personGroupBookingSummary = function (personId, teamId) {
                        return $scope.yourEvents.find(yourEvent => yourEvent.teamId === teamId && yourEvent.personId === personId)?.dateTime;
                    };

                    $scope.getEventIsUpdating = function (event, personId) {
                        if (event.eligiblePupils[personId]) {
                            if (event.eligiblePupils[personId].updating) {
                                return true;
                            }
                        }
                        return false;
                    };

                    $scope.timeSlotIsDisabled = function (event, teamId, personId) {
                        return (event.eligiblePupils[personId] && (
                            $scope.loadingGroupLocks?.[teamId]?.[personId] > 0
                                || $scope.loadingEventLocks[event.eventId] > 0
                                || event.eligiblePupils[personId].selected
                                || (event.disabled && !event.eligiblePupils[personId].selected)
                        )) || event.spacesLeft < 1 || event.blockedOut
                    }

                    var countPersonGroupBooking = function (teamId, personId) {
                        if (!$scope.groupEventSelections[teamId])
                            $scope.groupEventSelections[teamId] = {};

                        if (!$scope.groupEventSelections[teamId][personId])
                            $scope.groupEventSelections[teamId][personId] = 1;
                        else
                            $scope.groupEventSelections[teamId][personId]++;
                    };

                    var setPupilInfo = function () {
                        // figure out which pupils we should actually contract
                        var peopleWithOutstandingGroupChoices = [];
                        for (var teamId in $scope.groupPersonSelections) {
                            if ($scope.groupPersonSelections.hasOwnProperty(teamId)) {
                                for (var personId in $scope.groupPersonSelections[teamId]) {
                                    if ($scope.groupPersonSelections[teamId].hasOwnProperty(personId)) {
                                        if ($scope.groupPersonSelections[teamId][personId].selectionMade === 0) {
                                            if (peopleWithOutstandingGroupChoices.indexOf(personId) === -1) {
                                                peopleWithOutstandingGroupChoices.push(parseInt(personId));
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        for (var i = $scope.pupils.length; i--;) {
                            if (peopleWithOutstandingGroupChoices.indexOf($scope.pupils[i].personId) > -1) {
                                $scope.pupils[i].expanded = true;
                            } else {
                                $scope.pupils[i].expanded = false;
                            }

                            const pupilId = $scope.pupils[i].personId;
                            $scope.pupils[i].shouldShow = Object.values($scope.groupEvents).some(groupEvent => groupEvent?.events?.[0]?.events?.[0]?.events?.[0]?.eligiblePupils?.[pupilId]?.allowed);
                        }
                    };

                    $scope.processNewEventsData = function()
                    {
                        var eventsData = $scope.eventsData;
                        var i = 0;
                        var j = 0;

                        $scope.pupils = eventsData.pupils;

                        $scope.signUpEvents = eventsData.availableEvents;

                        if (eventsData.staff)
                        {
                            for (i = eventsData.staff.length; i--;)
                            {
                                if (!$scope.staff[eventsData.staff[i].id])
                                {
                                    $scope.staff[eventsData.staff[i].id] = eventsData.staff[i].name;
                                }
                            }
                        }

                        for (const thisEvent of eventsData.availableEvents.sort((a, b) => new Date(a.from) - new Date(b.from))) {
                            // we receive each event as an item, but we wish to artificially group them up by team
                            // to produce a "group -> events" heirarchy

                            if (!$scope.groupEvents[thisEvent.teamId]) {
                                $scope.groupEvents[thisEvent.teamId] = {
                                    name: thisEvent.teamTitle,
                                    teamId: thisEvent.teamId,
                                    organiserId: thisEvent.organiserId,
                                    events: []
                                };

                                $scope.groupPersonSelections[thisEvent.teamId] = {};
                                for (j = $scope.pupils.length; j--;) {
                                    $scope.groupPersonSelections[thisEvent.teamId][$scope.pupils[j].personId] =
                                        {
                                            selectionMade: 0,
                                            expanded: true
                                        };
                                }
                            }

                            var pupils = {};
                            var disabled = false;

                            for (j = thisEvent.pupils.length; j--;) {
                                pupils[thisEvent.pupils[j].personId] = {
                                    selected: false,
                                    allowed: thisEvent.pupils[j].allowed
                                };
                            }

                            // are we already booked or reserved onto this event?
                            for (j = thisEvent.pupils.length; j--;) {
                                if (thisEvent.pupils[j].selectionCount > 0) {
                                    // looks like we are

                                    if (thisEvent.pupils[j].status === STATUS_RESERVED) {
                                        $scope.showTimeLimitWarning = true;
                                        $scope.unconfirmedBookingCount++;
                                    }
                                    countPersonGroupBooking(thisEvent.teamId, thisEvent.pupils[j].personId);

                                    $scope.yourEvents.push({
                                        eventId: thisEvent.calendarEventId,
                                        groupName: thisEvent.teamTitle,
                                        teamId: thisEvent.teamId,
                                        dateTime: thisEvent.from,

                                        unixStartTime: +moment(thisEvent.from).unix(),
                                        unixEndTime: +moment(thisEvent.to).unix(),

                                        date: thisEvent.from.split('T')[0],
                                        personId: thisEvent.pupils[j].personId,
                                        status: thisEvent.pupils[j].status,
                                        selectionCount: thisEvent.pupils[j].selectionCount,
                                        organiserId: thisEvent.organiserId,
                                        isOnlinePTC: thisEvent.isOnlinePTC,
                                        onlineMeetingLabel: thisEvent.onlineMeetingLabel,
                                        locationTypeId: thisEvent.parentTeacherConferenceLocationTypeId
                                    });

                                    //if (thisEvent.pupils[j].status === STATUS_BOOKED) // used to just count bookings, but now reservations also
                                    {
                                        $scope.groupPersonSelections[thisEvent.teamId][thisEvent.pupils[j].personId].expanded = false;
                                        $scope.groupPersonSelections[thisEvent.teamId][thisEvent.pupils[j].personId].selectionMade++;
                                    }

                                    var person = pupils[thisEvent.pupils[j].personId];

                                    person.selected = true;
                                    person.status = thisEvent.pupils[j].status;
                                    person.selectionCount = thisEvent.pupils[j].selectionCount;

                                    disabled = thisEvent.spacesLeft < 1; //true;
                                }
                            }

                            const date = thisEvent.from.split('T')[0];
                            const dateGroup =  $scope.groupEvents[thisEvent.teamId].events.find(eventGroup => eventGroup.date === date);
                            let dateGroupEvents;
                            if (dateGroup) {
                                dateGroupEvents = dateGroup.events;
                            } else {
                                const arrayIndex = $scope.groupEvents[thisEvent.teamId].events.push({
                                    date: date,
                                    events: []
                                }) - 1;
                                dateGroupEvents = $scope.groupEvents[thisEvent.teamId].events[arrayIndex].events;
                            }

                            const ptcGroup = dateGroupEvents.find(eventGroup => eventGroup.isOnlinePTC === thisEvent.isOnlinePTC);
                            let ptcGroupEvents;
                            if (ptcGroup) {
                                ptcGroupEvents = ptcGroup.events;
                            } else {
                                const arrayIndex = dateGroupEvents.push({
                                    isOnlinePTC: thisEvent.isOnlinePTC,
                                    events: []
                                }) - 1;
                                ptcGroupEvents = dateGroupEvents[arrayIndex].events;
                            }

                            // add the finished event to our collection
                            ptcGroupEvents.push({
                                eventId: thisEvent.calendarEventId,
                                date: date,
                                dateTime: thisEvent.from,

                                unixStartTime: +moment(thisEvent.from).unix(),
                                unixEndTime: +moment(thisEvent.to).unix(),

                                spacesLeft: thisEvent.spacesLeft,
                                size: thisEvent.size,
                                eligiblePupils: pupils,
                                disabled: disabled,
                                blockedOut: thisEvent.blockedOut,
                                isOnlinePTC: thisEvent.isOnlinePTC,
                                onlineMeetingLabel: thisEvent.onlineMeetingLabel,
                                locationTypeId: thisEvent.locationTypeId
                            });
                        }

                        // make sure we block out other events at time's we already have booked out elsewhere
                        for (const groupEvent of getAllGroupEvents($scope.groupEvents)) {
                            // does this event exist in our selected events?
                            for (const yourEvent of $scope.yourEvents) {
                                if (
                                    yourEvent.eventId !== groupEvent.eventId &&
                                        doEventsClash(yourEvent, groupEvent)
                                ) {
                                    groupEvent.disabled = true;
                                }
                            }
                        }

                        setPupilInfo();
                    };

                    var getAllGroupEvents = function(groupEvents) {
                        let allEvents = [];

                        for (const property in groupEvents) {
                            allEvents.push(...groupEvents[property].events.reduce((allEvents, dateGroup) => {
                                allEvents.push(...dateGroup.events.reduce((ptcEvents, ptcGroup) => {
                                    ptcEvents.push(...ptcGroup.events);
                                    return ptcEvents;
                                }, []));
                                return allEvents;
                            }, []));
                        }

                        return allEvents;
                    }

                    var loadingLock = function (teamId, personId, event, enable) {

                        event.eligiblePupils[personId].updating = enable;

                        // we now want to disable other buttons before we begin
                        // disable;
                        //     * other events at the same time (loadingEventLocks)
                        //     * other events in this group for this person (loadingGroupLocks)
                        // should be re-enabled as appropriate after the booking call is complete
                        $scope.loadingGroupLocks[teamId] ??= {};
                        $scope.loadingGroupLocks[teamId][personId] ??= 0;

                        if (enable) {
                            $scope.loadingGroupLocks[teamId][personId]++;
                        }
                        else {
                            if ($scope.loadingGroupLocks[teamId][personId] > 0) {
                                $scope.loadingGroupLocks[teamId][personId]--;
                            }
                        }

                        for (const groupEvent of getAllGroupEvents($scope.groupEvents)) {
                            if (
                                // don't clash with this the event we're booking!
                                groupEvent.eventId !== event.eventId &&
                                // check if date times clash
                                doEventsClash(groupEvent, event)
                            ) {
                                // this event does clash
                                $scope.loadingEventLocks[groupEvent.eventId] ??= 0;

                                if (enable) {
                                    $scope.loadingEventLocks[groupEvent.eventId]++;
                                }
                                else {
                                    if ($scope.loadingEventLocks[groupEvent.eventId] > 0) {
                                        $scope.loadingEventLocks[groupEvent.eventId]--;
                                    }
                                }
                            }
                        }
                    };

                    $scope.addBooking = function (event, group, personId)
                    {
                        if (event.eligiblePupils[personId].updating)
                        {
                            return;
                        }

                        if ($scope.loadingGroupLocks?.[group.teamId]?.[personId] > 0 || $scope.loadingEventLocks[event.eventId] > 0) {
                            return;
                        }

                        var messageText = '';
                        var modalInstance = '';

                        if (event.eligiblePupils[personId].selected || event.disabled || event.spacesLeft < 1 || event.blockedOut) {
                            return;
                        }

                        if ($scope.groupEventSelections[group.teamId] &&
                            $scope.groupEventSelections[group.teamId][personId] &&
                            $scope.groupEventSelections[group.teamId][personId] >= $scope.signUp.maxBookableEventsPerGroup)
                        {
                            // is their current booking booked, or reserved?
                            var currentBooking = null;
                            for (var i = $scope.yourEvents.length; i--;)
                            {
                                if ($scope.yourEvents[i].personId === personId && $scope.yourEvents[i].teamId === group.teamId)
                                {
                                    currentBooking = $scope.yourEvents[i];
                                    break;
                                }
                            }

                            if (!currentBooking)
                            {
                                console.error('booking logged against this teamid, however unable to find it');
                                return; // this should never happen...
                            }

                            var newEventName = group.name + ' - ' + $scope.staff[group.organiserId];
                            var oldEventTime = $filter('date')(currentBooking.dateTime, 'HH:mm EEEE d MMM yyyy');
                            var newEventTime = $filter('date')(event.dateTime, 'HH:mm EEEE d MMM yyyy');
                            var childName = $scope.getChildName(personId);

                            if (currentBooking.status === STATUS_RESERVED)
                            {
                                messageText =
                                    'You already have a reservation for ' + newEventName + ' at ' + oldEventTime + ' with your child ' + childName + '.  Would you like to move this booking to ' + newEventTime + '?';

                                modalInstance = simpleAlertService.simpleAlert({
                                    title: 'SB_Booking_already_present',
                                    message: messageText,
                                    okButtonText: 'Yes, Replace existing booking',
                                    cancelButtonText: 'No, Keep current booking',
                                    windowSize: 'md'
                                });

                                modalInstance
                                    .result
                                    .then(function ()
                                    {
                                        $scope.removeBooking(currentBooking)
                                            .then(function ()
                                            {
                                                $scope.addBooking(event, group, personId);
                                            });
                                    });
                                return;
                            }
                            else
                            {
                                messageText =
                                    'You already have a booking for ' + newEventName + ' with your child ' + childName + '.';

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Booking_already_present',
                                    message: messageText,
                                    okButtonText: 'SB_OK',
                                    windowSize: 'md'
                                });

                                return;
                            }
                        }

                        $scope.selectedQuantityChanging = false;

                        loadingLock(group.teamId, personId, event, true);

                        // actually make the call to the server to book this!
                        $scope
                            .addBookingHandler()({ personId: personId }, { calendarEventId: event.eventId })
                            .then(function (eventsData) {

                                // did we actually get the booking?  A weird quirk of the api- if the eventsData statusId is 6 (timed out),
                                // then we didn't get it- we should check for that now and only run the booking code if that wasn't the case
                                if (eventsData.bookingStatusId !== 6) {

                                    var i = 0;
                                    var k = 0;

                                    countPersonGroupBooking(group.teamId, personId);

                                    if (eventsData.bookingStatusId === 1) { // unconfirmed
                                        $scope.unconfirmedBookingCount++;
                                        $scope.showTimeLimitWarning = true;
                                    }

                                    event.attendeeCount = eventsData.attendeeCount;
                                    event.size = eventsData.size;
                                    event.spacesLeft = eventsData.spacesLeft;
                                    event.percentageLeft = eventsData.percentageLeft;

                                    var newEvent = {
                                        eventId: event.eventId,
                                        groupName: group.name,
                                        teamId: group.teamId,
                                        dateTime: event.dateTime,
                                        unixStartTime: event.unixStartTime,
                                        unixEndTime: event.unixEndTime,
                                        date: event.date,
                                        personId: personId,
                                        organiserId: group.organiserId,
                                        status: eventsData.bookingStatusId,
                                        selectionCount: eventsData.pupilSelectionCount,
                                        isOnlinePTC: event.isOnlinePTC,
                                        onlineMeetingLabel: event.onlineMeetingLabel,
                                        locationTypeId: event.parentTeacherConferenceLocationTypeId
                                    };

                                    var person = event.eligiblePupils[eventsData.personId];

                                    person.status = eventsData.bookingStatusId;
                                    person.selectionCount = eventsData.pupilsSelectionCount;

                                    $scope.yourEvents.push(angular.copy(newEvent));
                                    $scope.groupPersonSelections[newEvent.teamId][newEvent.personId].selectionMade++;


                                    event.eligiblePupils[personId].selected = true;

                                    var anotherEventBookedAtThisTime = false;

                                    // we should disable double booking of the same time for **other** events
                                    for (const groupEvent of getAllGroupEvents($scope.groupEvents)) {
                                        if (
                                            // don't clash with this the event we're booking!
                                            groupEvent.eventId !== event.eventId &&
                                            // check if date times clash
                                            doEventsClash(groupEvent, event)
                                        ) {
                                            // disabled regardless of remaining space because this is a scheduling clash
                                            groupEvent.disabled = true;

                                            for (k in groupEvent.eligiblePupils) {
                                                if (groupEvent.eligiblePupils.hasOwnProperty(k)) {
                                                    if (groupEvent.eligiblePupils[k].selected) {
                                                        anotherEventBookedAtThisTime = true;
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    // we did not get the booking - it's been snapped up already!

                                    modalInstance = simpleAlertService.simpleAlert({
                                        title: 'SB_Booking_no_longer_available',
                                        message: 'SB_Booking_no_longer_available_message',
                                        windowSize: 'md'
                                    });
                                }

                                // at this point we want to disable this event if it has run out of spaces
                                // OR if another event has already been booked at this time..
                                event.disabled = event.spacesLeft < 1 || anotherEventBookedAtThisTime; //true;
                                loadingLock(group.teamId, personId, event, false);

                                $scope.selectedQuantityChanging = false;
                            })
                            .catch(function () {
                                loadingLock(group.teamId, personId, event, false);
                            });
                    };

                    var removeFromYourList = function(personId, eventId, teamId, eventsData, originalEvent)
                    {
                        var i = 0;

                        // find and remove the event from the your events list on the right
                        var index = -1;
                        for (i = 0; i < $scope.yourEvents.length; i++) {
                            if ($scope.yourEvents[i].eventId === eventId && $scope.yourEvents[i].personId === personId) {
                                index = i;
                                break;
                            }
                        }

                        if (index > -1) {
                            $scope.yourEvents.splice(index, 1);
                        }

                        $scope.showTimeLimitWarning = ($scope.yourEvents.length > 0);

                        $scope.groupEventSelections[teamId][personId]--;

                        if (originalEvent.status === 1) // unconfirmed
                            $scope.unconfirmedBookingCount--;

                        var thisSlotStillHasOtherBookings = false;

                        // find and update this events data
                        for (const groupEvent of getAllGroupEvents($scope.groupEvents)) {
                            if (groupEvent.eventId === eventId) {
                                groupEvent.eligiblePupils[personId].selected = false;
                                groupEvent.attendeeCount = eventsData.attendeeCount;
                                groupEvent.size = eventsData.size;
                                groupEvent.spacesLeft = eventsData.spacesLeft;
                                groupEvent.percentageLeft = eventsData.percentageLeft;

                                for (var k in groupEvent.eligiblePupils) {
                                    if (groupEvent.eligiblePupils.hasOwnProperty(k)) {
                                        if (groupEvent.eligiblePupils[k].selected) {
                                            thisSlotStillHasOtherBookings = true;
                                            break;
                                        }
                                    }
                                }
                                break;
                            }
                        }

                        // we should reenable events on this time
                        for (const groupEvent of getAllGroupEvents($scope.groupEvents)) {
                            if (
                                doEventsClash(groupEvent, originalEvent)
                            )
                            {
                                groupEvent.disabled =
                                    groupEvent.eventId === eventId
                                        ? groupEvent.spaceLeft < 1
                                        : thisSlotStillHasOtherBookings;
                            }
                        }

                        // update the person group stats
                        $scope.groupPersonSelections[teamId][personId].selectionMade--;
                    };

                    $scope.removeBooking = function (event) {

                        if (event.updating) {
                            return;
                        }

                        var deferred = $q.defer();

                        event.updating = true;

                        $scope.selectedQuantityChanging = true;

                        $scope.removeBookingHandler()({ personId: event.personId }, { calendarEventId: event.eventId })
                            .then(function (eventsData) {

                                removeFromYourList(event.personId, event.eventId, event.teamId, eventsData, event);
                                event.updating = false;
                                $scope.selectedQuantityChanging = false;

                                deferred.resolve();
                            })
                            .catch(function () {
                              event.updating = false;
                              $scope.selectedQuantityChanging = false;
                              deferred.reject();
                            });

                        return deferred.promise;
                    };

                    $scope.toggleExpandAll = function(expand)
                    {
                        for (var i = $scope.pupils.length; i--;)
                        {
                            $scope.pupils[i].expanded = true;
                        }

                        for (var teamId in $scope.groupPersonSelections)
                        {
                            if ($scope.groupPersonSelections.hasOwnProperty(teamId))
                            {
                                for (var personId in $scope.groupPersonSelections[teamId])
                                {
                                    if ($scope.groupPersonSelections[teamId].hasOwnProperty(personId))
                                    {
                                        $scope.groupPersonSelections[teamId][personId].expanded = expand;
                                    }
                                }
                            }
                        }
                    };

                    $scope.print = function()
                    {
                        var templateUrl = $sce.getTrustedResourceUrl('/Scripts/app/events/directives/time-slotted-event-picker.print.html?v=' + $window.EveryBuddy.Version);

                        $templateRequest(templateUrl).then(function (template) {
                            template = '<div>' + template + '</div>';

                            var compiledPrintView = $compile(template)($scope);
                            $timeout(function () {
                                var myWindow = $window.open('', '', 'width=800, height=600');
                                myWindow.document.write(compiledPrintView[0].innerHTML);
                            }, 1000);  // wait for a short while,Until all scope data is loaded If any complex one
                        }, function () {
                            // An error has occurred
                        });
                    };

                    $scope.cancellationAvailable = function(event)
                    {
                        /*
                        if ($scope.signUp.minHoursBeforeCancellationCutOff === null || $scope.signUp.minHoursBeforeCancellationCutOff === '')
                            return true;

                        if (event.status !== 2)
                            return false;

                        // how long is left?
                        var end = moment(event.dateTime);
                        var hoursUntilStart = moment.duration(end.diff(moment())).asHours();

                        return hoursUntilStart > $scope.signUp.minHoursBeforeCancellationCutOff;
                        */

                        if (event.status !== 2)
                            return false;

                        if (!$scope.cancelConfirmedBookingHandler ||
                            typeof $scope.cancelConfirmedBookingHandler() !== 'function')
                        {
                            return false;
                        }

                        return $scope.signUp.allowCancellation;
                    };

                    $scope.cancelBooking = function(event)
                    {
                        if (!$scope.signUp.allowCancellation)
                            return;

                        $scope.selectedQuantityChanging = true;

                        // pop a modal giving the warning-- give the user the option to carry on anyway
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Cancel_booking',
                            message: 'SB_Cancel_this_booking_prompt',
                            okButtonText: 'SB_Cancel_booking',
                            cancelButtonText: 'SB_Keep_booking',
                            windowSize: 'md'
                        });

                        modalInstance
                            .result
                            .then(function () {

                                $scope.cancelConfirmedBookingHandler()({ personId: event.personId }, { calendarEventId: event.eventId })
                                    .then(function (eventsData) {

                                      removeFromYourList(event.personId, event.eventId, event.teamId, eventsData, event);
                                      $scope.selectedQuantityChanging = false;
                                    });

                            });
                    };

                    $scope.refresh = function() {
                        delete $scope.yourEvents;
                        $scope.onReloadEvents();
                    };
                }

                return {
                    restrict: 'E',
                    templateUrl: '/Scripts/app/events/directives/time-slotted-event-picker.template.html',
                    scope: {
                        signUp: '=',
                        eventsData: '=',
                        unconfirmedBookingCount: '=',
                        selectedQuantityChanging: '=?',
                        pupils: '=',
                        yourEvents: '=',

                        addBookingHandler: '&',
                        removeBookingHandler: '&',
                        cancelConfirmedBookingHandler: '&',
                        onReloadEvents: '&',
                        hasBookingSelectionTimedOut: '<',
                    },
                    controller: ['$scope', '$filter', '$q', controller],
                    link: link
                };
            }
        ]);
