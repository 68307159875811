"use strict";

angular.module('shared.components.teamClubPickerSummary', [
    'pascalprecht.translate',
    'teams.services.teamService',
    'clubs.services.clubService',
    'subjectClass.services.subjectClassService',
    'shared.components.sbIcon'
])
    .component('teamClubPickerSummary',
        {

            bindings: {
                teamIds: '<',
                clubIds: '<',
                subjectClassIds: '<',
                showClubs: '<',
                showTeams: '<',
                showSubjectClasses: '<',
                nothingSelectedLabel: '@',
                onChangeModel: '&'
            },
            templateUrl: '/Scripts/app/shared/components/team-club-picker-summary.template.html',
            controller: [
                'teamService',
                'clubService',
                'subjectClassService',
                function (teamService, clubService, subjectClassService) {

                    this.loading = 0;

                    var self = this;

                    this.$onInit = function()
                    {
                        this.nothingSelectedLabel = this.nothingSelectedLabel || 'SB_Not_available_to_anyone';
                        // Added for Angular 12 to init select, as we show years by default
                        this.recipientSearchType = 1;
                    }

                    this.$onChanges = function (changes)
                    {
                        if (changes.showClubs) {
                            self.loadClubs();

                        }
                        if (changes.showTeams) {

                            self.loadTeams();
                        }
                        if (changes.showSubjectClasses) {
                            self.loadSubjectClasses();
                        }

                        if (changes.clubIds && changes.clubIds.currentValue) {
                            self.clubCount = changes.clubIds.currentValue.length;
                        }

                        if (changes.teamIds && changes.teamIds.currentValue)
                        {
                            self.teamCount = changes.teamIds.currentValue.length;
                        }

                        if (changes.subjectClassIds && changes.subjectClassIds.currentValue) {
                            self.subjectClassesCount = changes.subjectClassIds.currentValue.length;
                        }
                    };


                    this.clubs = {};
                    this.teams = {};
                    this.subjectClasses = {};

                    this.clubCount = 0;
                    this.teamCount = 0;
                    this.subjectClassesCount = 0;

                    this.loadClubs = function () {
                        this.loading++;
                        clubService.getAllForLookup()
                            .then(function (data) {
                                self.clubs = {};

                                for (var i = 0; i < data.length; i++) {
                                    self.clubs[data[i].id] = data[i].name;
                                }

                                self.loading--;
                            });

                    }

                    this.loadTeams = function () {
                        this.loading++;
                        teamService.getAllForLookup(true)
                            .then(function (data) {

                                for (var i = 0; i < data.length; i++) {
                                    self.teams[data[i].id] = data[i].name;
                                }
                                self.loading--;
                            });

                    }

                    this.loadSubjectClasses = function () {
                        this.loading++;
                        subjectClassService.getAllForLookupCached()
                            .then(function (data) {

                                self.subjectClasses = {};

                                for (var i = 0; i < data.length; i++) {
                                    var description = '';

                                    if (data[i].schoolYearGroupName)
                                        description += data[i].schoolYearGroupName + ' ';

                                    if (data[i].subjectName)
                                        description += data[i].subjectName + ' ';

                                    description += data[i].title + ' (' + data[i].teacherName + ')';

                                    self.subjectClasses[data[i].id] = description;
                                }

                                self.loading--;
                            })
                            .catch(function (error) {

                                self.loading--;
                            });
                    }

                    this.removeClub = function (item) {
                        var index = this.clubIds.indexOf(item);
                        if (index > -1) {
                            this.clubIds.splice(index, 1);
                        }
                        this.onChangeModel();
                    };

                    this.removeTeam = function (item) {
                        var index = this.teamIds.indexOf(item);
                        if (index > -1) {
                            this.teamIds.splice(index, 1);
                        }
                        this.onChangeModel();
                    };

                    this.removeSubjectClass = function (item) {
                        var index = this.subjectClassIds.indexOf(item);
                        if (index > -1) {
                            this.subjectClassIds.splice(index, 1);
                        }
                        this.onChangeModel();
                    };
                }

            ]

        });
