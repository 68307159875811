'use strict';

angular.module('sysadmin.components.emailDomains', [
    'sysadmin.services.emailDomainsService'
]).component('emailDomains', {
    templateUrl: '/Scripts/app/sysadmin/components/email-domains.template.html',
    bindings: {
        organisation: '<'
    },
    controller: [
        '$scope',
        'emailDomainsService',
        'simpleAlertService',
        function emailDomainsController($scope, emailDomainsService, simpleAlertService)
        {
            this.emailDomains =
                {
                    staffPermittedEmailDomain: '',
                    parentProhibitedEmailDomain: ''
                };

            this.anyChanges = false;

            this.$onChanges = function (changes)
            {
                if (typeof this.organisation !== 'undefined' && this.organisation != null)
                {
                    this.loadEmailDomains();
                }
            }.bind(this);

            this.loadEmailDomains = function ()
            {
                emailDomainsService.getEmailDomains(this.organisation.organisationId)
                    .then(function (data)
                    {
                        this.emailDomains = data;
                        console.log(this.emailDomains);
                    }.bind(this), function ()
                    {
                        simpleAlertService.errorAlert();
                    }.bind(this));

                this.anyChanges = false;
            }.bind(this);

            this.saveEmailDomains = function ()
            {
                var emailDomainsRequest =
                {
                    organisationId: this.organisation.organisationId,
                    staffPermittedEmailDomain: this.emailDomains.staffPermittedEmailDomain,
                    parentProhibitedEmailDomain: this.emailDomains.parentProhibitedEmailDomain
                };

                emailDomainsService.saveEmailDomains(emailDomainsRequest)
                    .then(function (data)
                    {
                        this.loadEmailDomains();
                    }.bind(this), function ()
                    {
                        simpleAlertService.errorAlert();
                    }.bind(this));
            }.bind(this);

            this.changesMade = function()
            {
                this.anyChanges = true;
            }
        }
    ]
});