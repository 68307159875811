import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sb-shadow-dom',
  standalone: true,
  imports: [CommonModule],
  template: `<div [innerHtml]="ds.bypassSecurityTrustHtml(trustedHtml)"></div>`,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ShadowDomComponent {
  @Input() trustedHtml: string;

  constructor(public ds: DomSanitizer) { }
}
