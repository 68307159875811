import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiClasses } from '@sb-shared/types/ui-classes';
import { DiaryEvent } from '../../../diary/models/diary-event';
import { DiaryService } from './../../../diary/services/diary.service';
import { RegisterStateService } from './../../services/register-state.service';
import { DateFormats } from '@sb-shared/globals/date-formats';
import { AttendeeStatusEnum } from '@sb-events/enums/attendee-status';

@Component({
  selector: 'sb-diary-item',
  templateUrl: './diary-item.component.html',
  styleUrls: ['./diary-item.component.scss']
})
export class DiaryItemComponent implements OnChanges {

  @Input() itemClass: UiClasses = 'primary';
  @Input() event: DiaryEvent;
  @Input() isBorderless: boolean;
  @Input() isSelected: boolean;
  isExpanded: boolean;
  isLoadingDetails?: boolean = true;
  timesLabel: string;
  dateTimeFormat = DateFormats.DateTime;
  deadlineDifference: { days: number, hours: number, minutes: number };
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  constructor(
    private registerState: RegisterStateService,
    private translate: TranslateService,
    private diary: DiaryService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      this.timesLabel = this.diary.getTimesLabel(this.event);
      if(changes.event?.currentValue?.consentDeadlineUtc) {
        this.deadlineDifference = this.calculateDateDifference(new Date(changes.event?.currentValue?.consentDeadlineUtc));
      }
    }
  }

  getIconName(): string {

    if (this.event.pendingInvite) {
      return 'diary';
    }

    if (this.event.personId && this.event.registerState) {
      return this.registerState.getRegisterStateIconName(this.event.registerState);
    }
    else if (!this.event.personId) {
      return 'groups';
    }
    return '';
  }

  getStateIcon(): string {
    if(this.isDeclined()) {
      return 'crossCircle';
    }

    if (this.event.pendingInvite) {
      return 'questionCircle';
    }

    if (this.event.isCancelled || this.event.statusId === -1) {
      return '';
    }
    if (this.event.personId) {
      return '';
    }
    else {
      return this.registerState.getRegisterCompletionStateIconName(this.event);
    }
  }

  getStateLabel(): string {
    if(this.isDeclined()) {
      return 'Declined';
    }

    if (this.event.pendingInvite) {
      return 'Awaiting response';
    }

    if (this.event.personId && this.event.registerState) {
      return `${this.event.attendeeName} - ${this.translate.instant(this.registerState.getDiaryRegisterStateText(this.event.registerState))}`;
    }
    else if (!this.event.personId) {
      return this.registerState.getRegisterCompletionStateText(this.event);
    }
    return '';
  }

  getCountLabel(): string {
    if (this.event.personId) {
      return '';
    }
    else {
      return this.event.totalAttendees.toString();
    }
  }

  getStateClass(): string {
    if (this.event.isCancelled || this.event.statusId === -1) {
      return '';
    }
    if (this.event.personId) {
      return this.registerState.getRegisterStateColorClass(this.event.registerState);
    }
    else {
      return this.registerState.getRegisterCompletionStateClass(this.event);
    }
  }

  onClickItem() {
    if (this.onClick.observers.length > 0) {
      this.onClick.emit();
    }
  }

  consentDeadlineExpired() {
    return new Date(this.event.consentDeadlineUtc + 'Z') < new Date(Date.now());
  }

  getEventSizeClass() {
    if(this.event.emptySlots == 0) {
        return "text-danger";
      }
    
    if((this.event.emptySlots
      && this.event.emptySlots > 0
      && this.event.totalAttendees
      && (this.event.emptySlots * 100 / this.event.placeLimit <= 10))) {
        return "text-warning";
      }

      return "";
  }

  getDeadlineClass() {
    if(this.event.consentDeadlineUtc) {
      if(this.deadlineDifference?.days < 2) {
        return "text-warning";
      }
    }

    return "";
  }

  calculateDateDifference(date: Date): { days: number, hours: number, minutes: number } {
    const msInMinute = 60000;
    const msInHour = 3600000;
    const msInDay = 86400000;

    const diffInMs = Math.abs(Date.now() - date.getTime());

    const days = Math.floor(diffInMs / msInDay);
    const hours = Math.floor((diffInMs % msInDay) / msInHour);
    const minutes = Math.floor((diffInMs % msInHour) / msInMinute);

    return { days, hours, minutes };
  }

  isDeclined() {
    return this.event.calendarEventAttendeeStatusId == AttendeeStatusEnum.No;
  }
}
