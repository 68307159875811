import { UiClasses } from './../../types/ui-classes';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sb-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

  @Input() message: string;
  @Input() messageXs: string;
  @Input() messageTranslationParameters: unknown;
  @Input() buttonClass: UiClasses; // bootstrap button class e.g. primary, success. Defaults to primary.
  @Input() customClasses: string;
  @Input() buttonId: string;
  @Input() isDisabled: boolean;
  @Input() isShowing: boolean;
  @Input() isSubmitted: boolean;
  @Input() iconName: string;
  @Input() formForAction: { $$element };
  @Input() xsIconOnly: boolean;
  @Input() resetTimeout: number;
  @Input() isLoading: boolean;

  @Output() clickSubmit = new EventEmitter<void>();

  computedClass = 'btn';
  resetSubmitButton() {
    setTimeout(() => {
      this.isSubmitted = false;
    }, this.resetTimeout);
  }

  ngOnInit() {
    if (typeof this.isSubmitted === 'undefined') {
      // submit bool wasn't passed in, use an internal one instead.
      this.isSubmitted = false;
    }

    if (typeof this.isShowing === 'undefined') {
      // visibility bool wasn't passed in, default to true
      this.isShowing = true;
    }

    if (typeof this.buttonClass === 'undefined') {
      // no class provided
      this.buttonClass = 'primary';
    }

    if (typeof this.customClasses === 'undefined') {
      this.customClasses = '';
    }
  }

  onSubmitClick() {
    // if (this.onSubmit && typeof (this.onSubmit) === 'function') {
    this.clickSubmit.emit();
    // }

    // if there's a form action, we should trigger it now
    // because we disabled the submit button, it won't happen automatically.
    // var elem = event.currentTarget;
    // while (elem != null && elem.nodeName.toUpperCase() != 'FORM') {
    //     elem = elem.parentElement;
    // }

    // if (elem != null && elem.action) {
    //     elem.submit();
    // }

    if (this.formForAction) {
      if (this.formForAction.$$element && this.formForAction.$$element[0]) {
        this.formForAction.$$element[0].submit();
      }
      else {
        console.log('Form with action ignored, no form element found');
      }
    }

    if (this.resetTimeout) {
      this.resetSubmitButton();
    }


  }
}
