
angular.module('settingsModule')
    .component('editLink', {
        templateUrl: '/Scripts/app/settings/components/links-portal-admin/edit-link.template.html',
        bindings: {
            resolve: '<',
            dismiss: '&',
            close: '&',
            onSave: '&'
        },
        controller: class EditLinkCtrl {

            // Dependencies
            $filter: any;
            imagesUploadHandler: any;
            linksPortalAdminService: any;
            simpleAlertService: any;
            dateTimeService: any;
            formElements: any;

            // Bindings
            resolve: any;
            dismiss: any;
            onSave: any;

            // Variables
            linkId: number = 0;
            categoryId: number = 0;
            contentId: number = 0;
            viewerTypes: Array<any> = [];
            isSaving: boolean = false;
            tabs: any[] = [];
            formData: any = {
                link: {},
                content: {}
            };
            currencies: any;
            isDisabled: boolean = false;
            isLoadingLink: boolean = false;;
            isLoadingContent: boolean = false;;
            isError: boolean = false;
            isExisting: boolean = false;;
            categories: Array<any> = [];
            destinationTypes: any;
            destinationTypeOptions: Array<any> = [];
            isContentClone: boolean = false;;

            static $inject = ['$filter', 'imagesUploadHandler', 'linksPortalAdminService', 'simpleAlertService', 'dateTimeService', 'formElements'];

            constructor($filter: any, imagesUploadHandler: any, linksPortalAdminService: any, simpleAlertService: any, dateTimeService: any, formElements: any) {
                this.$filter = $filter;
                this.imagesUploadHandler = imagesUploadHandler;
                this.linksPortalAdminService = linksPortalAdminService;
                this.simpleAlertService = simpleAlertService;
                this.dateTimeService = dateTimeService;
                this.formElements = formElements;
            }

            $onInit() {
                // Set values from resolve
                this.linkId = this.resolve.linkId;
                this.categoryId = this.resolve.categoryId;
                this.contentId = this.resolve.contentId;
                this.viewerTypes = this.resolve.viewerTypes;
                this.isContentClone = this.resolve.isContentClone;
                // If this is editing a content page, grab that data
                if (this.contentId) {
                    this.getContent(this.contentId, this.isContentClone);
                }
                // Else we are editing a link, get latest data
                else if (this.linkId) {
                    this.isLoadingLink = true;
                    this.linksPortalAdminService.getLink(this.linkId).then((res: any) => {
                        this.isLoadingLink = false;
                        if (res.data) {
                            const link = res.data;
                            const contentId = link.organisationPortalContentId;
                            if (contentId && !link.isExternal) {
                                link.destinationType = this.destinationTypes.Existing;
                                this.getContent(contentId, false);
                            }
                            else if (link.linkUrl && link.isExternal) {
                                link.destinationType = this.destinationTypes.External;
                            }
                            this.formData = { ...this.formData, link: link };
                            this.updateTabsFromDestinationType(link);
                        }
                        else {
                            this.dismiss();
                        }
                    });
                }
                this.linksPortalAdminService.getMenuCategories().then((res: any) => {
                    if (res.data) {
                        this.updateTabs(0, 2, res.data);
                    }
                    else {
                        this.isError = true;
                    }
                })
                this.getContents();

                const destinationCustomClass = 'w-100 mb-3 text-left';
                this.destinationTypes = {
                    New: 1,
                    Existing: 2,
                    Clone: 3,
                    External: 4
                }
                this.destinationTypeOptions = [
                    {
                        name: 'SB_New_Portal_Page',
                        value: 1,
                        customClass: destinationCustomClass,
                        icon: 'pageNew',
                    },
                    {
                        name: 'SB_Existing_Portal_Page',
                        value: 2,
                        customClass: destinationCustomClass,
                        icon: 'pageExisting',
                        isDisabled: true
                    },
                    {
                        name: 'SB_Clone_Portal_Page',
                        value: 3,
                        customClass: destinationCustomClass,
                        icon: 'pageClone',
                        isDisabled: true
                    },
                    {
                        name: 'SB_External_Url',
                        value: 4,
                        customClass: destinationCustomClass,
                        icon: 'pageExternal'
                    }
                ]

                this.tabs = [
                    {
                        name: 'SB_Button',
                        iconName: 'expand',
                        image: 'dropdown_menu',
                        fields: [
                            {
                                label: 'SB_Link_Text',
                                type: this.formElements.Text,
                                id: 'link-name',
                                isRequired: true,
                                maxlength: 50,
                                cols: 5
                            },
                            {
                                label: 'SB_Featured',
                                type: this.formElements.Switch,
                                id: 'link-isFeatured',
                                cols: 5
                            },
                            {
                                label: 'SB_Portal_Menu_Category',
                                type: this.formElements.Select,
                                loadOptions: true,
                                id: 'link-menuCategoryId',
                                isRequired: true,
                                cols: 5
                            },
                            {
                                label: 'SB_Link_Icon',
                                type: this.formElements.Icon,
                                isGrid: true,
                                id: 'link-iconId',
                                isRequired: true,
                                cols: 5,
                                primaryOptionIds: [
                                    5886, 5911, 5931,
                                    5937, 6102, 6111,
                                    6145, 6169, 6182,
                                    6209, 6235, 6285,
                                    6340, 6353, 6539,
                                    6560, 6594, 6744,
                                    6745, 6746, 6747,
                                    6748, 6749, 6750,
                                    6751, 6752, 6753
                                ]
                            },
                            {
                                label: 'SB_Link_Visibility',
                                type: this.formElements.CheckboxList,
                                id: 'link-userAccessTypeIds',
                                options: this.viewerTypes,
                                isRequired: true,
                                cols: 10
                            }
                        ]
                    },
                    {
                        name: 'SB_Portal_Link',
                        iconName: 'link',
                        image: 'link_shortener',
                        fields: [
                            {
                                label: 'SB_Link_Destination',
                                type: this.formElements.ButtonGroup,
                                id: 'link-destinationType',
                                options: this.destinationTypeOptions,
                                isRequired: true,
                                cols: 5,
                                isGroup: false
                            },
                            {
                                label: 'SB_Existing_Portal_Page_Select',
                                type: this.formElements.Paragraph,
                                displayIf: [{ property: 'link-destinationType', values: [2] }],
                                elementClass: 'text-muted',
                                cols: 10
                            },
                            {
                                label: 'SB_Duplicate_Portal_Page_Select',
                                type: this.formElements.Paragraph,
                                displayIf: [{ property: 'link-destinationType', values: [3] }],
                                elementClass: 'text-muted',
                                cols: 10
                            },
                            {
                                type: this.formElements.Select,
                                id: 'link-organisationPortalContentId',
                                default: '',
                                loadOptions: true,
                                isRequired: true,
                                displayIf: [{ property: 'link-destinationType', values: [2, 3] }],
                                cols: 6
                            },
                            {
                                type: this.formElements.Url,
                                id: 'link-linkUrl',
                                placeholder: 'https://www.example.com',
                                maxlength: 150,
                                isRequired: true,
                                displayIf: [{ property: 'link-destinationType', values: [4] }],
                                cols: 7
                            }
                        ]
                    },
                    {
                        name: 'SB_Portal_Content',
                        iconName: 'file',
                        image: 'online_reading',
                        isHidden: true,
                        fields: [
                            {
                                id: 'content-isChanged',
                                type: this.formElements.Hidden
                            },
                            {
                                id: 'content-isEditing',
                                type: this.formElements.Hidden
                            },
                            {
                                label: 'SB_Portal_Page_Creating',
                                type: this.formElements.Paragraph,
                                displayIf: [{ property: 'link-destinationType', values: [1, 3] }],
                                elementClass: 'text-muted',
                                cols: 5
                            },
                            {
                                label: 'SB_Edit',
                                description: 'SB_Portal_Page_Save_Unchanged',
                                id: 'portalPageEdit',
                                type: this.formElements.Button,
                                onClick: this.editContent.bind(this),
                                displayIf: [
                                    { property: 'link-destinationType', values: [2] },
                                    { property: 'content-isEditing', values: [false] }
                                ],
                                buttonClass: 'secondary',
                                cols: 5
                            },
                            {
                                label: 'SB_Portal_Page_Revert',
                                description: 'SB_Portal_Page_Editing',
                                id: 'portalPageRevert',
                                type: this.formElements.Button,
                                onClick: this.revertContent.bind(this),
                                displayIf: [
                                    { property: 'link-destinationType', values: [2] },
                                    { property: 'content-isEditing', values: [true] }
                                ],
                                enableIf: [
                                    { property: 'content-isChanged', values: [true] }
                                ],
                                buttonClass: 'danger',
                                cols: 5
                            },
                            {
                                type: this.formElements.Data,
                                displayIf: [{ property: 'link-destinationType', values: [2] }],
                                id: 'content-info',
                                cols: 5,
                                dataCols: 2
                            },
                            {
                                label: 'SB_Title',
                                type: this.formElements.Text,
                                id: 'content-name',
                                displayIf: [{ property: 'link-destinationType', values: [1, 2, 3] }],
                                enableIf: [{ property: 'content-isEditing', values: [true] }],
                                maxlength: 150,
                                isRequired: true,
                                cols: 6
                            },
                            {
                                label: 'SB_Portal_Page_Content',
                                type: this.formElements.Content,
                                id: 'content-content',
                                displayIf: [{ property: 'link-destinationType', values: [1, 2, 3] }],
                                enableIf: [{ property: 'content-isEditing', values: [true] }],
                                rows: 30,
                                isRequired: true,
                                tinymceConfig:
                                {
                                    hyperlink: true,
                                    tables: true,
                                    height: 350,
                                    imagesUploadHandler: this.imagesUploadHandler.uploadImage,
                                    imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                                },
                                cols: 10
                            }
                        ]
                    }
                ]
            }

            onWizardButtonClick(fieldId: string) {
                let clickedField = {
                    onClick: () => {}
                };
                this.tabs.forEach((tab: any) => {
                    clickedField = tab.fields.filter((tab: any) => { return tab.id === fieldId })[0];
                })
                if (clickedField) {
                    clickedField.onClick();
                }
            }

            revertContent() {
                const confirmationModal = this.simpleAlertService.simpleAlert({
                    title: 'SB_Portal_Page_Revert',
                    message: 'SB_Portal_Page_Revert_Confirm',
                    okButtonText: 'SB_Yes',
                    cancelButtonText: 'SB_Cancel'
                });
                confirmationModal.result
                    .then(() => {
                        this.getContent(this.formData.link.organisationPortalContentId, false);
                    })
                    .catch(() => {

                    });
            }

            editContent() {
                this.formData = { ...this.formData, content: { ...this.formData.content, isEditing: true } };
            }

            getContent(id: number, isClone: boolean) {
                this.isLoadingContent = true;
                this.linksPortalAdminService.getContent(id).then((res: any) => {
                    if (res.data) {
                        const content = res.data;
                        if (isClone) {
                            content.name = this.cloneContentName(content.name);
                            content.isEditing = true;
                        }
                        else {
                            content.info = {
                                SB_Author: res.data.author.fullName,
                                SB_Created: this.$filter('translate')(res.data.createdAge.label, { age: res.data.createdAge.value }),
                                SB_Last_Edited: this.$filter('translate')(res.data.modifiedAge.label, { age: res.data.modifiedAge.value })
                            }
                            content.isChanged = false;
                            content.isEditing = false;
                        }
                        this.formData = { ...this.formData, content: content };
                    }
                    this.isLoadingContent = false;
                })
            }

            cloneContentName(name: string) {
                return name + ' ' + this.$filter('translate')('SB_Clone');
            }

            getContents() {
                this.linksPortalAdminService.getContents().then((res: any) => {
                    if (res.data) {
                        if (res.data.length > 0) {
                            // Allow 'existing' and 'clone' link options if contents found
                            const typeOptions = this.tabs[1].fields[0].options;
                            typeOptions[1].isDisabled = false;
                            typeOptions[2].isDisabled = false;
                        }
                        this.updateTabs(1, 3, res.data);
                    }
                    else {
                        this.isError = true;
                    }
                })
            }

            updateTabs(tab: any, field: any, data: any) {
                const newTabs = [...this.tabs];
                newTabs[tab].fields[field].options = data;
                this.tabs = newTabs;
            }

            close() {
                this.dismiss('cancel');
            };

            save() {
                this.isSaving = true;
                const link = this.formData.link;
                const content = this.formData.content;

                const handleContentRes = (res: any) => {
                    if (res.isSuccess) {
                        if (this.contentId) {
                            this.close();
                        }
                        else {
                            this.formData.link.organisationPortalContentId = res.data.id;
                            saveLink();
                        }
                    }
                    else {
                        this.isSaving = false;
                    }
                }

                const handleLinkRes = (res: any) => {
                    if (res.isSuccess) {
                        this.close();
                    }
                    this.isSaving = false;
                }

                const saveLink = () => {
                    link.isExternal = link.destinationType === this.destinationTypes.External;

                    if (link.isExternal) {
                        delete link.organisationPortalContentId;
                    }

                    if (this.linkId) {
                        this.linksPortalAdminService.updateLink(link).then((res: any) => {
                            handleLinkRes(res);
                        })
                    }
                    else {
                        this.linksPortalAdminService.createLink(link).then((res: any) => {
                            handleLinkRes(res);
                        })
                    }
                };

                // Unless external link, or unchanged existing link, save content (includes content only mode)
                if (link.destinationType !== 4 && !(link.destinationType === this.destinationTypes.Existing && !content.isChanged)) {
                    // If existing content or link using existing content, update that content
                    if (this.contentId || (link.destinationType === this.destinationTypes.Existing && link.organisationPortalContentId)) {
                        this.linksPortalAdminService.updateContent(content).then((res: any) => {
                            handleContentRes(res);
                        });
                    }
                    // Else, create new content
                    else {
                        content.portalLinkName = link.name;

                        // When creating new content, this could either be as part of a new link or
                        // new content for an existing link. If it's an existing link, we pass the link id
                        // to the back-end to instruct it not to perform a uniqueness check on the link's name.
                        if (link.id !== 'undefined' && link.id > 0) {
                            content.portalLinkId = link.id;
                        }

                        this.linksPortalAdminService.createContent(content).then((res: any) => {
                            handleContentRes(res);
                        });
                    }
                }
                else {
                    saveLink();
                }
            };

            updateTabsFromDestinationType(link: any) {
                const newTabs = [...this.tabs];
                if (newTabs[2]) {
                    newTabs[2].isHidden = link.destinationType === this.destinationTypes.External ||
                        ((link.destinationType === this.destinationTypes.Existing || link.destinationType === this.destinationTypes.Clone) && !link.organisationPortalContentId);
                    this.tabs = newTabs;
                }
            }


            updateData(newData: any) {
                // Runs when wizard form is edited - does some checks and amends data accordingly
                let formChanges = 0;
                const oldContentId = this.formData.link.organisationPortalContentId;
                const newContentId = newData.link.organisationPortalContentId;
                const isContentIdChanged = (newContentId !== oldContentId);
                const isContentIdAddedRemoved = !oldContentId !== !newContentId;
                const oldDestinationType = this.formData.link.destinationType;
                const newDestinationType = newData.link.destinationType;
                const isDestinationTypeChanged = (oldDestinationType !== newDestinationType);
                const isContentChanged = (newData.content.name !== this.formData.content.name) || (newData.content.content !== this.formData.content.content);
                const isClone = (newData.link.destinationType === this.destinationTypes.Clone);
                const updateData = () => {
                    // Update tabs to show/hide 'Content' when changing destination type
                    if (isContentIdAddedRemoved || isDestinationTypeChanged) {
                        this.updateTabsFromDestinationType(newData.link);
                    }
                    // Add 'Clone' to existing name if existing -> clone
                    const appendClone = (newData.content.name && isClone &&
                        (oldDestinationType === this.destinationTypes.Existing) && !isContentIdChanged);
                    if (appendClone) {
                        newData.content.name = this.cloneContentName(newData.content.name);
                        formChanges += 1;
                    }
                    // Set default content Id as blank
                    if (oldContentId === undefined) {
                        this.formData.link.organisationPortalContentId = '';
                    };
                    if (newContentId === undefined) {
                        newData.link.organisationPortalContentId = '';
                        formChanges += 1;
                    };
                    // Remove content Id if new or external content
                    if ((newDestinationType === this.destinationTypes.New || newDestinationType === this.destinationTypes.External) && newData.link.organisationPortalContentId) {
                        newData.link.organisationPortalContentId = '';
                        formChanges += 1;
                    }
                    // Remove linkUrl if portal content
                    if (newDestinationType !== this.destinationTypes.External && newData.link.linkUrl) {
                        newData.link.linkUrl = '';
                        formChanges += 1;
                    }
                    // Check if content changed manually
                    if (isContentChanged && newDestinationType === oldDestinationType && newData.link.organisationPortalContentId == this.formData.link.organisationPortalContentId && !newData.content.isChanged) {
                        newData.content.isChanged = true;
                        formChanges += 1;
                    }
                    // Set isEditing when changing destination type
                    if (isDestinationTypeChanged) {
                        newData.content.isEditing = (newDestinationType === this.destinationTypes.New || newDestinationType === this.destinationTypes.Clone);
                        formChanges += 1;
                    }
                    // Set isChanged if changes made to content
                    if (isContentChanged && !newData.content.isChanged) {
                        newData.content.isChanged = true;
                        formChanges += 1;
                    }
                    // Update data in this component - won't trigger child onChanges
                    this.formData.link = { ...newData.link };
                    this.formData.content = { ...newData.content };
                    const updateFormContent = () => {
                        if (newDestinationType !== this.destinationTypes.New && newContentId) {
                            // Existing content item selected: Load data
                            this.getContent(newContentId, isClone);
                        }
                        else {
                            // New content item being created: Reset data
                            this.formData = { ...this.formData, content: { isChanged: false, isEditing: true } };
                        }
                    }
                    if (doUpdateFormContent) {
                        updateFormContent();
                    }
                    if (formChanges) {
                        this.formData = { ...this.formData, link: this.formData.link, content: this.formData.content };
                    }
                }
                const cancel = () => {
                    this.formData = { ...this.formData };
                }
                // Handle loss of any changes made
                const loseContent = (isContentIdChanged || (isDestinationTypeChanged && !(newDestinationType === this.destinationTypes.Clone && oldDestinationType === this.destinationTypes.Existing)));
                const loadOtherContent = (newContentId && loseContent) || (newContentId === this.destinationTypes.New);
                let doUpdateFormContent = false;
                if (loseContent && this.formData.content.isChanged) {
                    const confirmationModal = this.simpleAlertService.simpleAlert({
                        title: 'SB_Portal_Content_Changes',
                        message: 'SB_Portal_Content_Changes_Confirmation',
                        okButtonText: 'SB_Save',
                        discardButtonText: 'SB_Discard',
                        cancelButtonText: 'SB_Cancel'
                    });
                    confirmationModal.result
                        .then(() => {
                            // Save content and load another/reset - only pass in content Id if saving an existing page
                            this.saveContent(oldDestinationType === this.destinationTypes.Existing ? oldContentId : '')
                                .then((res: any) => {
                                    if (res.isSuccess) {
                                        if (loadOtherContent) {
                                            doUpdateFormContent = true;
                                            // Refresh list
                                            this.getContents();
                                        }
                                        updateData();
                                    }
                                    else {
                                        cancel();
                                    }
                                })
                        })
                        .catch((message: any) => {
                            if (message === 'discard') {
                                // Discard content and load another/reset
                                doUpdateFormContent = true;
                                updateData();
                            } else {
                                cancel();
                            }
                        });
                } else {
                    doUpdateFormContent = (isContentIdChanged || (isDestinationTypeChanged && (newDestinationType !== this.destinationTypes.External) &&
                        !((oldDestinationType === this.destinationTypes.Existing) && (newDestinationType === this.destinationTypes.Clone))));
                    updateData();
                }
            }

            saveContent(id: number) {
                const content = { ...this.formData.content };
                if (id) {
                    content.id = id;
                    return this.linksPortalAdminService.updateContent(content)
                }
                else {
                    return this.linksPortalAdminService.createContent(content);
                }
            }

        }
    });
