import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Button } from '@sb-shared/models/UI/buttons';

@Component({
  selector: 'sb-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent {

  @Input() barTitle: string;
  @Input() barTitleXs: string;
  @Input() detail: string;
  @Input() buttons: Button[]; // List of buttons, this component takes care of onClick props, including dropdown items.
  @Input() showBack: boolean;
  @Input() description: string;
  @Input() isSecondary:boolean;
  @Input() linkText: string;
  @Input() linkTextXs: string;
  @Input() linkUrl: string;
  @Input() linkRoute: string;
  @Input() linkRouteParams: any;
  @Input() linkNewWindow: any;
  @Input() numerator: string;
  @Input() denominator: string;
  @Input() isTop: boolean;
  @Output() onBackClick = new EventEmitter<number[]>();

  back() {
    if (this.onBackClick.observers.length > 0) {
      this.onBackClick.emit();
    }
    else {
      window.history.back();
    }
  }

  openLink(){
      // Never got used, $state breaks hybrid so have removed
      if (this.linkRoute) {
          // this.$state.go(this.linkRoute, this.linkRouteParams);
      }
      else if(this.linkNewWindow) {
          window.open(this.linkUrl);
      }
      else {
          window.location.href = this.linkUrl;
      }
  }

  buttonClick(index: number, dropdownItemIndex?: number) {
    if (dropdownItemIndex !== undefined) {
      this.buttons[index].dropdownItems[dropdownItemIndex].onClick?.();
    }
    else if (index !== undefined) {
      this.buttons[index].onClick?.();
    }
  }

  hasHistory(){
    return window.history.length > 1;
  }
}
