'use strict';

angular.module('sysadmin.components.importsWonde', [
    'sysadmin.services.importsService',
]).component('importsWonde', {
    templateUrl: '/Scripts/app/sysadmin/components/imports-wonde.template.html',
    bindings: {
        organisation: '<',
        wondeDataModel: '<',
        originalWondeDataModel: '<',
        onChangesNotified: '&',
    },
    controller: [
        'importsService',
        '$scope',
        function importsWondeController(importsService, $scope)
        {
            var self = this;
            this.anyChanges = false;
            this.schoolExists = false;

            this.$onChanges = function (changes)
            {
                if (typeof self.organisation !== 'undefined' && self.organisation != null)
                {
                    $scope.model = this.wondeDataModel;
                }
            };

            this.clearLastImportDate = function ()
            {
                this.wondeDataModel.lastImportTimestamp = null;
            }.bind(this);


            this.changesMade = function ()
            {
                $scope.model = this.wondeDataModel;

                this.schoolExists = typeof this.wondeDataModel.schoolName !== 'undefined' && this.wondeDataModel.schoolName != '';

                var enableSave = this.isDataValid() && this.hasDataChangedFromOriginal();

                if (this.onChangesNotified && typeof (this.onChangesNotified()) === 'function')
                {
                    self.onChangesNotified()(enableSave);
                }

                this.anyChanges = true;
            }.bind(this);

            this.trimWhitespace = function ()
            {
                this.wondeDataModel.schoolId = this.wondeDataModel.schoolId.trim();
            }.bind(this);

            this.validateSchoolId = function ()
            {
                importsService.getWondeSchoolName(this.wondeDataModel.schoolId.trim()).then(function (data)
                {
                    this.wondeDataModel.schoolName = data;
                    this.schoolExists = data != '';
                    this.changesMade();
                }.bind(this));
            }.bind(this);

            this.hasDataChangedFromOriginal = function ()
            {
                if (typeof this.wondeDataModel === 'undefined')
                {
                    return false;
                }

                var currentData = this.wondeDataModel;

                if (currentData.schoolId != this.originalWondeDataModel.schoolId ||
                    currentData.importCourtOrder != this.originalWondeDataModel.importCourtOrder ||
                    currentData.importStudentEmails != this.originalWondeDataModel.importStudentEmails ||
                    currentData.parentMisPrefix != this.originalWondeDataModel.parentMisPrefix ||
                    currentData.studentMisPrefix != this.originalWondeDataModel.studentMisPrefix ||
                    currentData.staffMisPrefix != this.originalWondeDataModel.staffMisPrefix ||
                    currentData.maximumRelationshipPriorityLevel != this.originalWondeDataModel.maximumRelationshipPriorityLevel ||
                    currentData.lastImportTimestamp != this.originalWondeDataModel.lastImportTimestamp ||
                    currentData.isImportEnabled != this.originalWondeDataModel.isImportEnabled ||
                    currentData.isPreAdmissionStudentFeedEnabled != this.originalWondeDataModel.isPreAdmissionStudentFeedEnabled ||
                    currentData.preAdmissionYearGroupName != this.originalWondeDataModel.preAdmissionYearGroupName ||
                    currentData.defaultUrl != this.originalWondeDataModel.defaultUrl)
                {
                    return true;
                }

                return false;
            }.bind(this);

            this.isDataValid = function ()
            {
                if (!this.schoolExists) {
                    return false;
                }

                if (typeof this.wondeDataModel === 'undefined') {
                    return false;
                }

                if (typeof this.wondeDataModel.schoolId === 'undefined' ||
                    typeof this.wondeDataModel.maximumRelationshipPriorityLevel === 'undefined') {
                    return false;
                }

                if (this.wondeDataModel.schoolId == '' ||
                    this.wondeDataModel.maximumRelationshipPriorityLevel == '' ||
                    this.wondeDataModel.maximumRelationshipPriorityLevel < 0)
                {
                    return false;
                }

                if (this.wondeDataModel.isPreAdmissionStudentFeedEnabled &&
                    (typeof this.wondeDataModel.preAdmissionYearGroupName === 'undefined' ||
                    this.wondeDataModel.preAdmissionYearGroupName == ''))
                {
                    return false;
                }

                if (!this.wondeDataModel.isPreAdmissionStudentFeedEnabled)
                {
                    this.wondeDataModel.preAdmissionYearGroupName = null;
                }

                if (this.wondeDataModel.defaultUrl == '')
                {
                    return false;
                }

                try
                {
                    let uri = new URL(this.wondeDataModel.defaultUrl);
                }
                catch
                {
                    return false;
                }

                return true;
            }.bind(this);

            $scope.$watch('model["lastImportTimestamp"]',
                function (newValue, oldValue)
                {
                    if (newValue == null ||
                        typeof newValue === 'undefined')
                    {
                        $scope.model.lastImportTimestamp = null;
                        $scope.model.lastImportTimestampFormatted = '';
                        self.changesMade();
                    }
                }, true);
        }
    ]
});