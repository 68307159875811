'use strict';

angular.module('sysadmin.components.sysAdminReferenceData', [])
    .component('sysAdminReferenceData', {
        templateUrl: '/Scripts/app/sysadmin/components/sysadminreferencedata.html',
        controller: [
        '$scope', '$state', '$filter', '$http', 'sysAdminService', 'organisationService',
        function ($scope, $state, $filter, $http, sysAdminService, organisationService) {

            $scope.gridOptions = {};
            $scope.gridOptions.enableCellEditOnFocus = true;
            $scope.gridOptions.columnDefs = [
                { name: 'title', displayName: 'Title', enableCellEdit: true, width: 200 },
                { name: 'displayOrder', displayName: 'Order', enableCellEdit: true, type: 'number' },
                { name: 'languageCode', displayName: 'Language', enableCellEdit: true, width: 100 },
                {
                    name: 'isDeleted',
                    displayName: 'Deleted',
                    cellFilter: 'mapDeleted',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: 'ui-grid/dropdownEditor',
                    editDropdownValueLabel: 'name',
                    editDropdownOptionsArray: [
                        { id: 0, name: 'No' },
                        { id: 1, name: 'Yes' }
                    ]
                }
            ];


            $scope.sortType = 'title';
            $scope.sortReverse = false;

            sysAdminService.getAllReferenceData().then(function(data) {
                    $scope.referenceData = data;
                },
                function(error) {
                    return error;
                });

            organisationService.getOrganisations().then(function(data) {
                    $scope.organisations = data;
                },
                function(error) {
                    return error;
                });

            $scope.addReferenceData = function() {
                $scope.selectReferenceData = {
                    organisationId: 0,
                    title: '',
                    description: '',
                    maxOccurences: 0,
                    sortedAlphabetically: true,
                    isDeleted: false,
                    allowedUsageFlag: 0
                };

                $scope.gridOptions.data = [];
                $scope.gridOptions.data.push(
                {
                    title: '',
                    languageCode: 'en',
                    organisationReferenceDataId: 0,
                    displayOrder: 1,
                    isDeleted: 0
                });

            };
            $scope.showReferenceData = function(aRefData) {
                $scope.selectReferenceData = null;
                sysAdminService.getReferenceData(aRefData.organisationReferenceTypeId).then(function(data) {
                        $scope.selectReferenceData = data.referenceData;
                        $scope.gridOptions.data = data.referenceDataValues;
                        if (data.referenceDataValues.length === 0) {
                            $scope.gridOptions.data.push(
                            {
                                title: '',
                                languageCode: 'en',
                                organisationReferenceDataId: 0,
                                displayOrder: 1,
                                isDeleted: 0
                            });
                        }
                    },
                    function(error) {
                        return error;
                    });

            };
            $scope.updateReferenceData = function() {
                sysAdminService.postReferenceData($scope.selectReferenceData)
                    .then(function(dataProcessed) {
                            $scope.selectReferenceData = dataProcessed.referenceData.referenceData;
                            $scope.gridOptions.data = dataProcessed.referenceData.referenceDataValues;
                            $scope.referenceData = dataProcessed.allReferenceData;
                        }
                    );
            };

            $scope.saveReferenceDataValues = function () {
                var data = { organisationReferenceTypeId: $scope.selectReferenceData.organisationReferenceTypeId, values: $scope.gridOptions.data }
                sysAdminService.postReferenceDataValues(data)
                    .then(function (dataProcessed) {
                        $scope.selectReferenceData = dataProcessed.referenceData;
                        $scope.gridOptions.data = dataProcessed.values;
                    }
                    );
            };


            $scope.addReferenceDataValue = function () {
                $scope.gridOptions.data.push(
                {
                    title: '',
                    languageCode: 'en',
                    organisationReferenceDataId: 0,
                    displayOrder: $scope.gridOptions.data.length+1,
                    isDeleted: 0
                });
            };
        }
    ]})

    .filter('mapDeleted',
        function() {
            var genderHash = {
                0: 'No',
                1: 'Yes'
            };

            return function(input) {
                if (!input) {
                    return 'No';
                } else {
                    return genderHash[input];
                }
            };
        })
