angular.module('messaging.components.messageCenterCountsWidget',
        [
            'messaging.services.messageViewingService',
            'messaging.components.messageList'
        ])
    .component('messageCenterCountsWidget',
        {
            bindings: {
                messageCount : '<'
            },
            templateUrl: '/Scripts/app/messaging/components/message-center-counts-widget.template.html',
            controller: [
                '$window',
                'messageViewingService',
                function ($window, messageViewingService)
                {
                    var loading = 1;
                    this.starred = 0;
                    this.unread = 0;
                    this.isAngular12 = window.EveryBuddy.IsAngular12;
                    this.chatMessages = [];

                    this.isLoading = function() { return loading > 0; };

                    this.$onChanges = function(changes)
                    {
                        if (changes.messageCount && changes.messageCount.currentValue)
                        {
                            messageViewingService
                                .getPersonInboxUnfiltered({
                                    pageSize: this.messageCount,
                                    currentPage: 1
                                })
                                .then(function(data)
                                {
                                    this.starred = data.starredCount;
                                    this.unread = data.unreadCount;
                                    this.chatMessages = data.messages;
                                    loading--;
                                }.bind(this));
                        }
                    };

                    this.messageSelected = function(message)
                    {
                        $window.location.href = 'Messaging/inbox/' + message.id;
                    };

                    this.openMessages = (type) => {
                        window.location.href = type ? `/Messaging/inbox-${type}` : '/Messaging';
                    };
                }
            ]
        });