angular.module('tuitionFeesModule')
    .component('batchView', {
        templateUrl: '/Scripts/app/tuitionFees/components/billingCycle/batch-view.template.html',
        controller: class BatchViewCtrl {

            // Dependencies
            billingCycleService: any;

            // Bindings
            billingCycleIdFn: () => number;
            billingCycleRefreshFn: () => () => void;

            // Variables
            productsModel: any = {};
            studentsModel: any = {};
            currencyDisplaySymbol: string;
            currencyExponent: number;
            batches: any = [];
            totalBatches: number = -1;
            currentPage: number = 1;
            pageSize: number = 10;
            pageSizes = [5, 10, 20, 50, 100, 200];
            batchDetail: { id: number, isCorporatePayer: false };
            searchParameters: any;
            sortType: string = 'createdDateTime';
            sortReverse: boolean = true;
            sortTypeDetailStudents: string = 'lastName';
            sortReverseDetailStudents: boolean = false;
            sortTypeDetailProducts: string = 'productCode';
            sortReverseDetailProducts: boolean = false;
            isDeleteSubmitted: boolean = false;

            billingCycleBatchStatusEnum = {
                Pending: 0,
                Committed: 1,
                Invoiced: 2,
                Deleted: 3,
                Failed: 4,
                Incomplete: 5
            }

            static $inject = ['$window', '$state', '$scope', 'billingCycleService'];

            constructor($window, $state, $scope, billingCycleService) {
                this.billingCycleIdFn = () => $state.params.billingCycleId;

                // In case they went straight to this nested page, billing cycle id fn is not available.
                // Go back to parent so this fn is set-up correctly.
                if (!this.billingCycleIdFn()) {
                    $state.go('tuitionFees.billingCycle');
                }

                this.billingCycleRefreshFn = () => $state.params.billingCycleRefreshFn;
                this.billingCycleService = billingCycleService;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;

                this.resetSearchParameters();

                $scope.$watch(() => this.billingCycleIdFn(), () => {
                    this.getBatches();
                });
            }

            resetSearchParameters() {
                this.searchParameters = {
                };
                this.setSearchParameters();
            }

            setSearchParameters() {
                this.searchParameters.pageNumber = this.currentPage;
                this.searchParameters.pageSize = this.pageSize;
                this.searchParameters.orderBy = this.sortType;
                this.searchParameters.isOrderDescending = this.sortReverse;
            }

            setPageSize() {
                // reset to page 1
                this.currentPage = 1;
                this.getBatches();
            }

            $onInit() {
            }

            getBatches(returnPromise = false) {
                this.setSearchParameters();
                this.batchDetail = { id: null, isCorporatePayer: false };
                var promise = this.billingCycleService.getBatchesByCycle(this.billingCycleIdFn(), this.searchParameters).then(res => {
                    if (res.data) {
                        this.batches = res.data;
                        this.totalBatches = res.totalRecords;
                    }
                });
                if (returnPromise) {
                    return promise;
                }
            }

            isPending(batch) {
                return !batch.isDeleted && batch.statusId == this.billingCycleBatchStatusEnum.Pending;
            }

            isCommitted(batch) {
                return !batch.isDeleted && batch.statusId == this.billingCycleBatchStatusEnum.Committed;
            }

            isInvoiced(batch) {
                return !batch.isDeleted && batch.statusId == this.billingCycleBatchStatusEnum.Invoiced;
            }

            isFailed(batch) {
                return !batch.isDeleted && batch.statusId == this.billingCycleBatchStatusEnum.Failed;
            }

            isIncomplete(batch) {
                return !batch.isDeleted && batch.statusId == this.billingCycleBatchStatusEnum.Incomplete;
            }

            batchSelected(batchId) {
                this.billingCycleService.getBatchById(this.billingCycleIdFn(), batchId).then(res => {
                    if (res.data) {
                        this.batchDetail = res.data;
                    }
                })
            }

            deleteBatch() {
                this.billingCycleService.deleteBatchById(this.billingCycleIdFn(), this.batchDetail.id).then(res => {
                    this.isDeleteSubmitted = false;
                    this.getBatches();
                    if (typeof this.billingCycleRefreshFn() === 'function') {
                        this.billingCycleRefreshFn()();
                    }
                })
            }

            editBatchStudent(batchStudent) {
                batchStudent.isEditing = true;
                if (!batchStudent.payers) {
                    this.billingCycleService.getStudentPayers(batchStudent.id).then(res => {
                        if (res.data) {
                            batchStudent.payers = this.batchDetail.isCorporatePayer
                                ? res.data.corporatePayers
                                : res.data.familyPayers;
                        }
                    });
                }
            }

            deleteBatchStudent(batchStudent) {
                let batchId = this.batchDetail.id;
                this.billingCycleService.deleteBatchStudent(this.billingCycleIdFn(), batchId, batchStudent.id)
                    .then(res => {
                        this.getBatches(true).then(res => {
                            this.batchSelected(batchId);
                            if (typeof this.billingCycleRefreshFn() === 'function') {
                                this.billingCycleRefreshFn()();
                            }
                        });
                    });
            }

            updateBatchStudent(batchStudent) {
                let batchId = this.batchDetail.id;
                let data = { payerId: batchStudent.payerId, payerIsPersonBillingContact: false };
                let payer = batchStudent.payers.find(x => x.id == batchStudent.payerId);
                if (payer) {
                    data.payerIsPersonBillingContact = payer.isPersonBillingContact;
                }
                this.billingCycleService.updateBatchStudent(this.billingCycleIdFn(), batchId, batchStudent.id, data)
                    .then(res => {
                        this.getBatches(true).then(res => {
                            this.batchSelected(batchId);
                            if (typeof this.billingCycleRefreshFn() === 'function') {
                                this.billingCycleRefreshFn()();
                            }
                        });
                    });
            }
        }
    });
