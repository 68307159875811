
'use strict';

angular.module('shared.services.searchService', ['angular.filter'])
    .service('searchService', ['$filter',
        function ($filter) {
            var service = this;
            service.getPupilRelevance = function(pupil, searchTerm) {
                pupil.searchScore = 0;
                var searchTextArray = searchTerm.toLowerCase().split(' ');
                angular.forEach(searchTextArray, function (textItem) {
                    if (pupil.name) {
                        if (pupil.name.toLowerCase().indexOf(textItem) !== -1) {
                            pupil.searchScore = pupil.searchScore + textItem.length;
                        }
                    } else {
                        if (pupil.firstName.toLowerCase().indexOf(textItem) !== -1 ||
                            pupil.lastName.toLowerCase().indexOf(textItem) !== -1 ||
                            pupil.otherName.toLowerCase().indexOf(textItem) !== -1) {
                            pupil.searchScore = pupil.searchScore + textItem.length;
                        }
                    }
                    });
                    return pupil.searchScore;
                };

            service.getMultiSelectLists = function(list, propId, propLabel, propOptions, selectedPropList) {
                // Eg for event types
                if (!list) {
                    return;
                }
                // Get new events with an event type (ignore events without type)
                var newItemsWithProp = list.filter(function (item) {
                    return item[propId];
                });
                // Filter to list of unique event types
                var newItemsWithPropUniqueId = $filter('unique')(newItemsWithProp, propId);
                // Get list of scope's event type ids
                var currentPropTypeIds = propOptions.map(function (item) {
                    return item.id;
                });
                // Add each new event type to scope's list of event types and included event types
                newItemsWithPropUniqueId.forEach(function (item) {
                    // Only if not already in scope's list of event types
                    if (!currentPropTypeIds.includes(item[propId])) {
                        var propItem = {id: item[propId], label: item[propLabel]};
                        propOptions.push(propItem);
                        selectedPropList.push(propItem);
                    }
                });
                propOptions.forEach(function (propItem) {
                    // Reset label without count
                    propItem.label = propItem.label.split('[')[0];
                    // Disable if no matching events
                    var count = newItemsWithProp.filter(function (item) {
                        return item[propId] == propItem.id;
                    }).length;
                    propItem.disabled = count == 0;
                    if (!propItem.disabled) {
                        // Add new count to label if > 0
                        propItem.label = propItem.label + ' [' + count + ']';
                    }
                });
                return {uniqueList: propOptions, selectedList: selectedPropList};
            };
        }
    ]);