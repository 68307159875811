angular.module('settingsModule')
    .component('onboardingWelcomeMessage',
        {
            templateUrl: '/Scripts/app/settings/components/onboarding/onboarding-welcomemessage.template.html',
            controller: class OnboardingWelcomeMessageCtrl {
                // Dependencies
                $filter: any;
                $window: any;
                $compile: any;
                imagesUploadHandler: any;
                organisationService: any;
                toastService: any;
                tinymceConfigHelper: any;

                // Variables

                loading: number = 0;
                saving: number = 0
                tinymceOptions: any;
                tinymceMobileOptions: any;
                organisationStartPage: boolean = false;
                welcomeItems: any;

                static $inject = ['$filter', '$window', '$compile', 'imagesUploadHandler', 'organisationService', 'toastService', 'tinymceConfigHelper'];

                constructor($filter: any, $window: any, $compile: any, imagesUploadHandler: any, organisationService: any, toastService: any, tinymceConfigHelper: any) {
                    this.$filter = $filter;
                    this.$window = $window;
                    this.$compile = $compile;
                    this.imagesUploadHandler = imagesUploadHandler;
                    this.organisationService = organisationService;
                    this.toastService = toastService;
                    this.tinymceConfigHelper = tinymceConfigHelper;
                }

                $onInit() {
                    this.loading = 1;
                    this.saving = 0;

                    this.tinymceOptions = this.tinymceConfigHelper.getTinyMceConfig(
                        {
                            tables: true,
                            hyperlinks: true,
                            imagesUploadHandler: this.imagesUploadHandler.uploadImage,
                            imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                        });

                    this.tinymceMobileOptions = this.tinymceConfigHelper.getTinyMceConfig(
                        {
                            justify: false,
                            tables: false,
                            hyperlinks: false,
                            mobileFormatting: true,
                            mobileLists: true,
                            formatting: false,
                            lists: false,
                            imagesUploadHandler: this.imagesUploadHandler.uploadImage,
                            imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                        });

                    this.organisationStartPage = false;

                    this.welcomeItems = {
                        pupilWelcome: {
                            isOpen: false,
                            label: 'SB_Pupil',
                            labelWeb: 'SB_Pupil_Welcome_Message',
                            labelApp: 'SB_Pupil_Mobile_App_Welcome_Message',
                            data: this.getEmptyMessage(this.$window.EveryBuddy.Enums.WelcomeMessageAudience.Pupils),
                            iconName: 'userTypeStudent'
                        },
                        parentWelcome: {
                            isOpen: false,
                            label: 'SB_Parent',
                            labelWeb: 'SB_Parent_Welcome_Message',
                            labelApp: 'SB_Parent_Mobile_App_Welcome_Message',
                            data: this.getEmptyMessage(this.$window.EveryBuddy.Enums.WelcomeMessageAudience.Parents),
                            iconName: 'userTypeParent'
                        },
                        staffWelcome: {
                            isOpen: false,
                            label: 'SB_Staff',
                            labelWeb: 'SB_Staff_Welcome_Message',
                            labelApp: 'SB_Staff_Mobile_App_Welcome_Message',
                            data: this.getEmptyMessage(this.$window.EveryBuddy.Enums.WelcomeMessageAudience.Staff),
                            iconName: 'userTypeStaff'
                        }
                    };

                    // get welcome messages for organisation
                    this.organisationService.getAllWelcomeMessagesForCurrentOrganisation()
                        .then((data: any) => {
                            this.processLoadedData(data);
                            this.loading--;
                        });
                }

                processLoadedData(data: any) {
                    for (var i = 0; i < data.length; i++) {
                        switch (data[i].audience) {
                            case this.$window.EveryBuddy.Enums.WelcomeMessageAudience.Pupils:
                                this.welcomeItems.pupilWelcome.data = data[i];
                                break;
                            case this.$window.EveryBuddy.Enums.WelcomeMessageAudience.Parents:
                                this.welcomeItems.parentWelcome.data = data[i];
                                break;
                            case this.$window.EveryBuddy.Enums.WelcomeMessageAudience.Staff:
                                this.welcomeItems.staffWelcome.data = data[i];
                                break;
                        }
                    }
                };

                getEmptyMessage(audience: any) {
                    return {
                        id: 0,
                        audience: audience,
                        messageMarkDown: '',
                        mobileAppMessageMarkdown: '',
                    };
                };

                saveChanges() {

                    if (this.saving > 0)
                        return;

                    this.saving = 1;

                    const showSaved = () => {
                        if (this.saving === 0) {
                            this.toastService.saveSuccess();
                        }
                    };

                    this.organisationService
                        .saveAllWelcomeMessages([this.welcomeItems.pupilWelcome.data, this.welcomeItems.parentWelcome.data, this.welcomeItems.staffWelcome.data])
                        .then((data: any) => {
                            this.processLoadedData(data);
                            this.saving--;
                            showSaved();
                        })
                        .catch((err: any) => {
                            this.toastService.saveError(err.data?.Message);
                            this.saving--;
                        })

                };

                isLoading() {
                    return this.loading > 0;
                };

            }
        });
