'use strict';

angular.module('cca.components.ccaDeletePupilConfirmationContainerSignupUser',
    [
        'cca.components.deletePupilConfirmationSignupUser',
        'shared.services.simpleAlertService'
    ])
    .component('ccaDeletePupilConfirmationContainerSignupUser',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<'
            },
            templateUrl: '/Scripts/app/cca/components/cca-delete-pupil-confirmation-container-signup-user.template.html',
            controller: [
                function () {

                    this.calendarEventId = null;
                    this.personId = null;

                    this.$onChanges = function (changes) {
                        if (changes.resolve && changes.resolve.currentValue) {
                            this.calendarEventId = changes.resolve.currentValue.calendarEventId;
                            this.pupilId = changes.resolve.currentValue.pupilId;
                        }
                    }.bind(this);

                    this.onComplete = function (data) {
                        this.modalInstance.close(data);
                    }.bind(this);

                    this.close = function () {
                        this.modalInstance.dismiss();
                    }.bind(this);

                }]
        });