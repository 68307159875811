'use strict';

angular.module('reception.components.receptionWhatsNext',
    [
        'angular.filter',
        'pascalprecht.translate',
        'shared.services.registerStateService',
        'shared.components.sbIcon',
        'shared.components.sbAvatar',
        'shared.services.blobStoragePhotosService',
        'teams.components.addTeamNotePopup',
        'teams.components.teamNoteEditor',
        'teams.components.teamNoteList',
    ])
    .component('receptionWhatsNext', {
        bindings: {
            groupId: '<',
            isRegGroup: '<'
        },
        templateUrl: '/Scripts/app/reception/components/reception-whatsnext.template.html',
        controller: [
            '$filter', '$window', '$timeout', 'receptionService', 'registerStateService', 'blobStoragePhotosService',
            function ($filter, $window, $timeout, receptionService, registerStateService, blobStoragePhotosService) {

                this.showSearch = false;
                this.showNext = false;
                this.loadingNext = false;
                this.loadingNextDone = false;
                this.isWeekView = true;

                this.days = [0, 1, 2, 3, 4, 5, 6];

                this.receptionRegisterStateService = registerStateService;

                this.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };

                this.formats = ['dd-MMMM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'shortDate', 'dd-MMM-yyyy', 'dd MMM yyyy'];
                this.format = 'dd MMM yyyy';

                this.openFrom = function () {
                    this.popupFrom.opened = true;
                };

                this.navigate = function (interval) {
                    var days = (this.isWeekView) ? 7 : 1;
                    this.groupsFrom = moment(this.groupsFrom).add(interval * days, 'd');
                    this.refresh();

                }.bind(this);

                this.switchView = function () {
                    this.refresh();
                }.bind(this);

                this.popupFrom = {
                    opened: false
                };

                this.addDays = function (date, days) {
                    var result = new Date(date);
                    result.setDate(result.getDate() + days);
                    return result;
                }

                this.inTimeSlot = function (groupsFrom, eventTime, days) {
                    var groupsFromDate = new Date(groupsFrom);
                    groupsFromDate.setDate(groupsFromDate.getDate() + days);

                    var groupsFromDateMoment = moment(groupsFromDate).startOf('day');
                    var eventTimeDate = moment(eventTime).startOf('day');

                    return groupsFromDateMoment.valueOf() === eventTimeDate.valueOf();
                }

                this.groupsFrom = moment.utc().startOf('day').toDate();

                this.$onChanges = function (changes) {

                    if ((changes.groupId && changes.groupId.currentValue)) {
                        this.refresh();
                    }
                }.bind(this);

                this.buildSearchFilters = function () {

                    this.searchFilters = {
                        team: "", schoolYear: "", className: ""
                    };


                    this.uniqueClassNames = [];

                    var uniqueClassNameItems = $filter('unique')(this.members, 'className');

                    for (var i = 0; i < uniqueClassNameItems.length; i++) {
                        if (uniqueClassNameItems[i].className && uniqueClassNameItems[i].className !== "")
                            this.uniqueClassNames.push(uniqueClassNameItems[i].className);
                    }

                    this.uniqueClassNames.sort();
                    this.uniqueClassNames.splice(0, 0, "");

                    this.uniqueTeamTitles = [];

                    var uniqueTeamTitleItems = $filter('unique')(this.members, 'teamName');

                    for (var i = 0; i < uniqueTeamTitleItems.length; i++) {
                        if (uniqueTeamTitleItems[i].teamName && uniqueTeamTitleItems[i].teamName !== "")
                            this.uniqueTeamTitles.push(uniqueTeamTitleItems[i].teamName);
                    }

                    this.uniqueTeamTitles.sort();
                    this.uniqueTeamTitles.splice(0, 0, "");

                    this.uniqueSchoolYearNames = [];

                    var uniqueSchoolYearNameItems = $filter('unique')(this.members, 'schoolYearName');

                    for (var i = 0; i < uniqueSchoolYearNameItems.length; i++) {
                        if (uniqueSchoolYearNameItems[i].schoolYearName && uniqueSchoolYearNameItems[i].schoolYearName !== "")
                            this.uniqueSchoolYearNames.push(uniqueSchoolYearNameItems[i].schoolYearName);
                    }

                    this.uniqueSchoolYearNames.sort();
                    this.uniqueSchoolYearNames.splice(0, 0, "");
                }.bind(this);

                this.refresh = function () {
                    this.getData(true);
                }.bind(this);

                this.getData = function (buildSearchFilters) {
                    this.loadingNext = true;
                    receptionService.getNextEventData(this.groupId, this.groupsFrom, this.isWeekView, this.isRegGroup)
                        .then(function (data) {
                            this.members = data;

                            if (buildSearchFilters)
                                this.buildSearchFilters();

                            this.loadingNextDone = true;
                            this.loadingNext = false;

                            blobStoragePhotosService.addStudentPhotoUrlsToArray (this.members).then(function (array) {
                                this.members = array;
                            }.bind(this));

                        }.bind(this),
                            function (error) {
                                this.loadingNext = false;
                                return error;
                            }.bind(this));
                }.bind(this);

                this.getGroupDetails = function () {
                    receptionService.getGroupDetails(this.groupId)
                        .then(function (data) {
                            this.title = data.title;
                        },
                            function (error) {
                                return error;
                            });
                }.bind(this);

                this.printIt = function () {
                    var table = '<div>' + // <h1>' + this.title + ' ' + $filter('translate')("SB_Registration_Group") + '</h1>
                        '<table  border="1" style="border-collapse:collapse;"  width="100%"><thead><tr><th>' + $filter('translate')("SB_Name") + '</th><th>' + $filter('translate')("SB_Year") + '</th><th>' + $filter('translate')("SB_Class") + '</th><th>' + $filter('translate')("SB_Date_Time") + '</th><th>' + $filter('translate')("SB_Group") + '</th><th>' + $filter('translate')("SB_Location") + '</th><th>' + $filter('translate')("SB_Attendee_Status") + '</th></tr></thead>' +
                        '<tbody>';

                    var tempMembers = this.members.slice(0);

                    //descending sort as we are going to reverse loop through the array
                    tempMembers.sort(function (a, b) { return b.personName.localeCompare(a.personName); });

                    for (var i = tempMembers.length; i--;)
                    {
                        var anAttendant = tempMembers[i];

                        if (anAttendant.isTransportEvent && anAttendant.transportEventDetail != '')
                        {
                            table = table + '<tr> <td>' + anAttendant.personName + '</td ><td>' + anAttendant.schoolYearName + '</td ><td>' + anAttendant.className + '</td > <td>' + $filter('date')(anAttendant.eventTime, "dd MMM HH:mm") + ' ' + anAttendant.transportEventDetail + '</td > <td>' + anAttendant.teamName + '</td> <td>' + anAttendant.location + '</td><td>' + $filter('translate')(this.receptionRegisterStateService.getRegisterStateBadgeText(anAttendant.registerState)) + '</td></tr>';
                            continue;
                        }

                        table = table + '<tr> <td>' + anAttendant.personName + '</td ><td>' + anAttendant.schoolYearName + '</td ><td>' + anAttendant.className + '</td > <td>' + $filter('date')(anAttendant.eventTime, "dd MMM HH:mm") + '</td > <td>' + anAttendant.teamName + '</td> <td>' + anAttendant.location + '</td> <td>' + anAttendant.location + '</td><td>' + $filter('translate')(this.receptionRegisterStateService.getRegisterStateBadgeText(anAttendant.registerState)) + '</td></tr>';
                    }

                    table = table + '</tbody ></table ></div > ';

                    $timeout(function () {
                        var myWindow = $window.open('', '', 'width=800, height=600');
                        myWindow.document.write(table);
                        myWindow.print();
                    }, 300);
                }.bind(this);

                this.exportIt = function () {
                    $window.location = `${window.EveryBuddy.WebAPI}/${receptionService.getRegGroupCsvUrl(this.groupId, this.groupsFrom.toISOString().replace('Z', ''), this.isWeekView, this.isRegGroup)}`;
                }.bind(this);

                this.search = function (item) {

                    var lookForTeam = this.searchFilters.team;
                    var lookForSchoolYear = this.searchFilters.schoolYear;
                    var lookForClassName = this.searchFilters.className;


                    var matchTeam = lookForTeam === "";
                    var matchSchoolYear = lookForSchoolYear === "";
                    var matchClassName = lookForClassName === "";

                    if (matchTeam && matchSchoolYear && matchClassName) {
                        return true;
                    }

                    matchTeam = matchTeam ||
                        (lookForTeam !== "" && item.teamName &&
                            item.teamName.toLowerCase().indexOf(lookForTeam.toLowerCase()) >= 0);

                    matchSchoolYear = matchSchoolYear || (lookForSchoolYear !== "" && item.schoolYearName &&
                        item.schoolYearName.toLowerCase().indexOf(lookForSchoolYear.toLowerCase()) >= 0);

                    matchClassName = matchClassName || (lookForClassName !== "" && item.className &&
                        item.className.toLowerCase().indexOf(lookForClassName.toLowerCase()) >= 0);

                    return matchTeam && matchSchoolYear && matchClassName;
                }.bind(this);

                this.sortType = 'name';
                this.sortReverse = false;
            }
        ]
    });

