'use strict';

angular.module('transport.services.pupilTransportPreferenceService', []).factory('pupilTransportPreferenceService', [
  '$http',
  function ($http) {
    var urlBase = '/webapi/WebPersonTransportPreference/';
    var service = {};

    service.loadPupilPreferences = function (timetableId, timetableRouteId) {
      var params = {
        timetableId: timetableId,
        timetableRouteId: timetableRouteId,
      };

      return $http.post(urlBase + 'LoadPupilPreferences', params).then(function (response) {
        return response.data;
      });
    };

    service.getPreferencesForTimeSlotDirectionAndPupils = function (getPreferencesForTimeSlotDirectionAndPupilsParams) {
      return $http.post(urlBase + 'GetPreferencesForTimeSlotDirectionAndPupils', getPreferencesForTimeSlotDirectionAndPupilsParams).then(function (response) {
        return response.data;
      });
    };

    service.updatePersonTransportPreference = function (personTransportPreference) {
      const isMultiple = Array.isArray(personTransportPreference);
      const endpoint = isMultiple ? 'UpdatePupilPreferenceForMultipleDays' : 'UpdatePupilPreference';

      return $http.post(urlBase + endpoint, personTransportPreference).then(function (response) {
        return response.data;
      });
    };

    service.updatePersonTransportPreferences = function (personTransportPreferences) {
      return $http.post(urlBase + 'UpdatePupilPreferences', personTransportPreferences).then(function (response) {
        return response.data;
      });
    };

    service.deletePersonTransportPreferences = function (timetableId, timetableRouteId, pupilIdsToRemovePreferencesFor) {
      var params = {
        timetableId: timetableId,
        timetableRouteId: timetableRouteId,
        pupilIdsToRemovePreferencesFor: pupilIdsToRemovePreferencesFor,
      };

      return $http.post(urlBase + 'DeletePupilPreferences', params).then(function (response) {
        return response.data;
      });
    };

    return service;
  },
]);
