"use strict";

angular.module("shared.components.sbNumberInput", ["ui.bootstrap"])
    .component("sbNumberInput",
    {
        bindings: {
            modelNumber: "=",
            required: "<",
            currencySymbol: "<"
        },
        templateUrl: "/Scripts/app/shared/components/sb-number-input.template.html"
    });