import { Component, Input, OnInit } from '@angular/core';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sb-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {

  @Input() hasLoaded: boolean;
  @Input() noAuth: boolean;
  imgUrl: string;
  title: string;

  constructor(private organisation: OrganisationService) { }

  ngOnInit(): void {
    if (this.noAuth) {
      this.imgUrl = `${environment.cdnBase}logos/${environment.loaderImg}`;
      this.title = 'Loading...';
      return;
    }

    this.organisation.getCurrentOrganisation().subscribe(organisation => {
      this.imgUrl = organisation.webLogoPath;
      this.title = organisation.name;
    })
  }

}
