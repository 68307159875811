angular.module('balance.components.balanceSummaryWidget',
        [
            'balance.components.currentUserTransactionViewUibContainer',
            'balance.services.balanceService',
            'shared.services.mathService'
        ])
    .component('balanceSummaryWidget',
        {
            bindings: {
                hideTitle: '<',
                isDashboard: '<',
                onLoadStart: '&',
                onLoadEnd: '&'
            },
            templateUrl: '/Scripts/app/balance/components/balance-summary-widget.template.html',
            controller: class BalanceSummaryWidgetCtrl {
                // Dependencies
                $uibModal: any;
                $window: any;
                balanceService: any;
                mathService: any;

                // Variables
                loading: number;
                balance: any; // should we seperate the numeric property from error message?
                voucherBalance: number;
                isVoucherNegative: boolean;
                creditBalance: number;
                showVouchers: boolean;
                currencyDisplaySymbol: string;
                onLoadStart: any;
                onLoadEnd: any;

                static $inject = ['$uibModal', '$window', 'balanceService', 'mathService'];

                constructor (
                    $uibModal,
                    $window,
                    balanceService,
                    mathService)
                    {
                        this.$uibModal = $uibModal;
                        this.$window = $window;
                        this.balanceService = balanceService;
                        this.mathService = mathService;
                    }

                    $onInit() {
                        this.loading = 1;
                        this.onLoadStart();
                        this.balance = 0.0;
                        this.voucherBalance = 0.0;
                        this.creditBalance = 0.0;

                        this.showVouchers = false;

                        this.currencyDisplaySymbol = this.$window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                        this.balanceService.getAllBalances()
                        .then(data =>
                        {
                            if (data.isError)
                            {
                                this.balance = 'Error loading balance';
                            } else
                            {
                                if (data)
                                {
                                    if (typeof data.studentBalance == 'undefined' || data.studentBalance == null) {
                                        this.balance = null;
                                    }
                                    else {
                                        this.balance = this.formatAmount(data.studentBalance);
                                    }

                                    if (typeof data.voucherBalance == 'undefined' || data.voucherBalance == null) {
                                        this.voucherBalance = null;
                                    }
                                    else {
                                        this.isVoucherNegative = data.voucherBalance < 0;
                                        this.voucherBalance = this.formatAmount(data.voucherBalance);
                                    }

                                    if (typeof data.creditBalance == 'undefined' || data.creditBalance == null) {
                                        this.creditBalance = null;
                                    }
                                    else {
                                        this.creditBalance = this.formatAmount(data.creditBalance);
                                    }

                                    this.showVouchers = data.voucherBalanceAvailable;
                                }
                            }
                            this.loading--;
                            this.onLoadEnd();
                        });
                    }

                    isLoading = () => { return this.loading > 0; };

                    formatAmount(amount) {
                        amount = this.mathService.roundNumber(amount, 2).toFixed(2);
                        if (amount < 0) {
                            return amount.toString().replace('-', '-' + this.currencyDisplaySymbol);
                        }

                        return this.currencyDisplaySymbol + amount;
                    };

                    popTransactions(accountTypeId) {

                        this.$uibModal.open({
                            component: 'currentUserTransactionViewUibContainer',
                            resolve: {
                                accountTypeId: () => { return accountTypeId; }
                            },
                            size: 'lg'
                        });

                    };

                    goToBalance() {
                        this.$window.location.href = '/Balance';
                    }
                }
        });
