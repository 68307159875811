import { Injectable } from '@angular/core';
import { isDebug } from '@sb-helpers';

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  constructor() { }

  notTranslated(key: string) {
    if (!key) return;

    if (this.isLocal()) {
      console.error('Missing label: ' + key);
    }
  }

  isLocal() {
    return isDebug();
  }
}
