'use strict';

angular.module('balanceAdmin.massAdjustmentStudent.components.massAdjustmentStudentFilter',
    [])
    .component('massAdjustmentStudentFilter',
        {
            bindings: {
                searchFilters: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/massAdjustmentStudent/components/mass-adjustment-student-filter.template.html',
            controller:
                [
                    'personService',
                    function (personService) {

                        this.$onChanges = function (changes) {
                        }

                        this.$onInit = function () {
                        }

                        // Function used by autocomplete component
                        this.getPupils = personService.lookupPupilByName;
                        this.getPupilArgs = [
                            function() { return this.searchFilters.includeDeleted; }.bind(this)
                        ];

                        this.onSelectStudent = function ($item) {
                            this.searchFilters.studentId = $item.id;
                            this.searchFilters.studentName = $item.name;
                        }.bind(this);
                    }
                ]

        });