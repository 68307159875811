'use strict';

angular.module('sysadmin.services.importsService', [])
    .factory('importsService', [
        '$http', '$cacheFactory',
        function ($http) {

            var urlBase = '/webapi/WebMainSettings/';
            var service = {};

            service.getDataProvisioningData = function (organisationId)
            {
                return $http.get(urlBase + 'GetDataProvisioningData', { params: { organisationId: organisationId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getWondeSchoolName = function (schoolId) {
                return $http.get(urlBase + 'GetWondeSchoolNameAsync', { params: { schoolId: schoolId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.getGg4lSchoolName = function (schoolId, clientId, secret, organisationId, url) {
                return $http.get(urlBase + 'GetGg4lSchoolNameAsync', { params: { schoolId, clientId, secret, organisationId, url } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            service.saveDataProvisioningData = function (dataProvisioningDto)
            {
                return $http.post(urlBase + 'SaveDataProvisioningData', dataProvisioningDto)
                    .then(function (response)
                    {
                        return response.data;
                    }, function (response)
                    {
                            return response;
                    });
            };

            service.requestImport = function (dataProvisioningImportRequestDto)
            {
                return $http.post(urlBase + 'RequestImportAndReturnMessageContent', dataProvisioningImportRequestDto)
                    .then(function (response)
                    {
                        return response.data;
                    }, function (response)
                    {
                        return response;
                    });
            };

            service.saveSchoolContactTypes = function (organisationId, school)
            {
                return $http.post(urlBase + 'SaveSchoolContactTypes', { organisationId: organisationId, school: school })
                    .then(function (response)
                    {
                        return response.data;
                    }, function (response)
                    {
                        return response;
                    });
            };

            return service;
        }
    ]);
