angular.module('events.services.eventRegistersService', [
    'shared.services.toastService'
])
    .factory('eventRegistersService',
        [
            '$http', 'httpCoreApi', '$q', '$cacheFactory', 'toastService',
            function ($http, httpCoreApi, $q, $cacheFactory, toastService)
            {
                var urlBase = '/webapi/WebEventRegisters/';
                var httpCoreApiSettings = {
                    controllerType: httpCoreApi.controllerTypes.Admin
                };
                var transportEventStatusUrl = 'TransportEventStatus/';

                var repo = {};

                repo.getEventRegister = function (eventId) {
                    return $http.get(urlBase + 'GetRegisterData',
                        {
                            params: { eventId: eventId }
                        }, { timeout: 120000 })
                        .then(function (response)
                        {
                            return response.data;
                        });
                };

                repo.getOfflineEventRegisters = function (eventIds, canceller) {
                    var canceller = $q.defer();

                    var cancel = function () {
                        canceller.resolve();
                    };

                    var offlineRegistersRequest =
                    {
                        eventIds: eventIds,
                        timeout: canceller.promise
                    };

                    var promise = $http.post(urlBase + 'GetOfflineRegistersData', offlineRegistersRequest, { timeout: canceller.promise })
                        .then(function (response) {
                            return response.data;
                        });

                    return {
                        promise: promise,
                        cancel: cancel
                    };
                };

                repo.getRegisterNextEvent = function (eventId) {

                    return $http.get(urlBase + 'GetRegisterNextEventData', { params: { eventId: eventId } })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, error: e };
                        });
                };

                repo.getRegisterPreviousEvent = function (eventId) {

                    return $http.get(urlBase + 'GetRegisterPreviousEventData', { params: { eventId: eventId } })
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function (e) {
                            return { isError: true, error: e };
                        });
                };

                repo.getRegisterLocations = (eventId) => {
                    return httpCoreApi.get(`Events/${eventId}/Locations`, httpCoreApiSettings);
                };

                repo.saveAttendeeRegisters = function (eventId, registerData) {
                    httpCoreApiSettings.showSuccessToast = false;

                    const requestPath = `events/${eventId}/registers`;

                    return httpCoreApi.post(requestPath, registerData, {
                        ...httpCoreApiSettings
                    });
                }

                repo.sendNotifyStaffOfAbsentees = function (attendantDto, successCallback, failureCallback) {
                    var saveUrl = urlBase + 'NotifyStaffOfAbsentees';

                    return $http.post(saveUrl, attendantDto)
                        .then(function (response) {
                            successCallback(response);
                            toastService.emailSent('SB_Sent');
                        })
                        .catch(function (e) {
                            failureCallback(e);
                        });
                };

                repo.notifyParentsOfAbsentees = function (attendantDto, successCallback, failureCallback) {
                    var saveUrl = urlBase + 'NotifyParentsOfAbsentees';

                    return $http.post(saveUrl, attendantDto)
                        .then(function (response) {
                            successCallback(response);
                            toastService.emailSent('SB_Sent');
                        })
                        .catch(function (e) {
                            failureCallback(e);
                        });
                }

                repo.moveToExpected = function (calendarEventAttendeeId) {
                    var saveUrl = urlBase + 'MoveToExpected';

                    return $http.post(saveUrl, {
                        calendarEventAttendeeId: calendarEventAttendeeId
                    }).then(function (response) {
                        return response.data;
                    });
                }

                repo.createCalendarEventTransportLogEntry = function (request)
                {
                    httpCoreApiSettings.showSuccessToast = false;

                    return httpCoreApi.post(transportEventStatusUrl, request, {
                        ...httpCoreApiSettings
                    });
                };

                return repo;
            }
        ]);
