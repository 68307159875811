'use strict';

angular.module('ccaavailableModule').component('ccaReview',  {
    templateUrl: "/Scripts/app/ccaAvailable/Details/cca-available-review.template.html",
    controller: [
        '$scope',
        '$state',
        '$uibModal',
        'availableRepository',
        'blobStoragePhotosService',
        'simpleAlertService',

        function ($scope, $state, $uibModal, availableRepository, blobStoragePhotosService, simpleAlertService) {

            $scope.getStudentPhotos = function() {
                blobStoragePhotosService.addStudentPhotoUrlsToArray($scope.review.pupils).then(function (array) {
                    $scope.review.pupils = array;
                });
            };
            function refresh() {
                availableRepository.getSignUpReviewGroups($state.params.signUpReviewId).then(function (reviewData) {
                    $scope.review = reviewData;
                    $scope.getStudentPhotos();
                    $scope.review.minSelectionSatisfied = true;
                    if( $scope.signUp.minimumSelection > 0) {
                        $scope.review.pupils.forEach((pupil) => {
                            var groupCount = $scope.review.groups.filter(x => x.personId == pupil.personId).length;
                            if (groupCount > 0 && groupCount < $scope.signUp.minimumSelection) {
                                $scope.review.minSelectionSatisfied = false;
                            }
                        });
                    }
                });
            }

            $scope.signUp = {};

            availableRepository.getSignUp($state.params.signUpReviewId).then(function (signUp) {
                $scope.signUp = signUp;
                $scope.signUp.completedTransport = false;
                refresh();
            });

            $scope.choose = function () {
                $state.go('ccaDetails', { signUpId: $scope.signUp.ccaSignUpId });
            };
            $scope.requestToJoin = function () {
                $state.go('ccaToJoin', { signUpId: $scope.signUp.ccaSignUpId });
            };
            $scope.back = function () {
                $state.go('ccaAvailable');
            };
            $scope.update = function () {
                availableRepository.post($scope.groups)
                    .then(function () {
                        refresh();
                    });
            };
            var confirmBookingsTandC = function () {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Scripts/app/ccaAvailable/Details/cca-available-terms.template.html',
                    controller: 'CModalInstanceCtrl',
                    size: $scope.signUp.organisationFormId === 0 ? 'sm' : 'lg',
                    resolve: {
                        terms: function () {
                            return $scope.signUp.terms;
                        },
                        termsLink: function () {
                            return $scope.signUp.termsLink;
                        },
                        organisationFormId: function () {
                            return $scope.signUp.organisationFormId;
                        },
                        forPeopleList: function () {

                            var pupilDetails = [];
                            for (var i = 0; i < $scope.review.pupils.length; i++) {

                                var pupilId = $scope.review.pupils[i].personId;
                                var addPupilId = 0;
                                for (var j = 0; j < $scope.review.groups.length; j++) {
                                    if ($scope.review.groups[j].personId === pupilId) {
                                        addPupilId = pupilId;
                                        break;
                                    }
                                }
                                if (addPupilId > 0) {
                                    pupilDetails.push(addPupilId);
                                }
                            }
                            return pupilDetails;
                        },
                        ccaSignUpId: function () {
                            return $scope.signUp.ccaSignUpId;
                        }
                    }
                });
                modalInstance
                    .result
                    .then(function (capturedData, notes) {
                        availableRepository
                            .postConfirmMultiple($state.params.signUpReviewId, notes, capturedData, $scope.signUp.organisationFormId || 0, $scope.review)
                            .then(function (saveResult) {
                                if (saveResult.saveComplete === true) {
                                    $state.go('ccaAvailable');
                                } else {
                                    refresh();
                                }
                            }).catch(function (error) {
                                $scope.signUp.completedTransport = false;
                                simpleAlertService.errorAlert({ message: 'SB_Error_Saving_Message' });
                            });
                    }, function () {
                        // re-enable submit buttons
                        $scope.signUp.completedTransport = false;
                    });

            }.bind(this);

            $scope.confirm = function () {
                const selectedPersonIds = $scope.review.groups.map(
                    (item) => item.personId
                );

                if (
                    !$scope.signUp.hasTransport ||
                    !$scope.signUp.transportTimeSlotAndDirection ||
                    $scope.signUp.completedTransport ||
                    selectedPersonIds == 0
                ) {
                    confirmBookingsTandC();
                    return;
                }
                var modalInstanceTransport = $uibModal.open({
                    animation: true,
                    component: 'ccaTimeslot',
                    backdrop: 'static',
                    size: 'lg',
                    resolve:
                    {
                        ccaSignUp: function () {
                            return $scope.signUp;
                        }.bind(this),
                        signUpPersons: function () {
                            return $scope.review.pupils.filter(item => selectedPersonIds.includes(item.personId));
                        }.bind(this),
                        transportTimeSlot: function () {
                            return $scope.signUp.transportTimeSlotAndDirection;
                        }.bind(this),
                        timeSlotTimeTableByOperatingDay: function () {
                            return availableRepository.getTimetableForTimeSlotCalendarGroupByDay(this.signUp.transportTimeSlotAndDirection.id, this.signUp.transportTimetableCalendarId)
                                .catch(function (error) {
                                    console.error('XHR getTimetableForTimeSlotCalendarGroupByDay failure : ' + error.status);
                                    //allow a 404, ccaTimeslot component handles this and will close the modal and T&C's will continue.
                                    if (error.status && error.status == '500') {
                                        simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                                        return;
                                    };
                                })
                        }.bind(this)
                    }
                });

                modalInstanceTransport
                    .result
                    .then(function (ccaSignUpPersons) {

                        $scope.signUp.completedTransport = true;

                        if (ccaSignUpPersons) {
                            $scope.review.pupils = ccaSignUpPersons;
                            $scope.getStudentPhotos();
                        }

                        confirmBookingsTandC();
                    }, function () {

                        $scope.signUp.completedTransport = false;
                    });
            };


            $scope.unconfirm = function () {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'unconfirmModal.html',
                    controller: 'UnconfirmModalInstanceCtrl',
                    size: 'sm'

                });

                modalInstance.result.then(function () {
                    availableRepository.postUnconfirm($state.params.signUpReviewId)
                        .then(function (saveResult) {
                            if (saveResult.saveComplete === true) {
                                $state.go('ccaAvailable');
                            } else {
                                refresh();
                            }
                        });
                });
            };

            $scope.costType = function (costType) {

                switch (costType) {
                    case 1:
                        return 'SB_Per_term';
                    case 2:
                        return 'SB_Per_session';
                    case 3:
                        return 'SB_One_off';
                    default:
                        return 'SB_Free';
                }
            };

        }
    ]});

    angular.module('ccaavailableModule').controller('UnconfirmModalInstanceCtrl',[
        '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
    angular.module('ccaavailableModule').controller('CModalInstanceCtrl', [
        '$scope',
        '$uibModalInstance',
        '$sce',
        'personService',
        'terms',
        'termsLink',
        'organisationFormId',
        'forPeopleList',
        'ccaSignUpId',
        function ($scope, $uibModalInstance, $sce, personService, terms, termsLink, organisationFormId, forPeopleList, ccaSignUpId) {
            $scope.terms = terms;
            $scope.termsLink = termsLink;
            $scope.formId = organisationFormId;
            $scope.userDataIsValid = false;
            $scope.forPeople = forPeopleList;
            $scope.ccaSignUpId = ccaSignUpId;


            personService.getPerson(-1)
                .then(function (data) {
                    $scope.person = data;

                    if (data.personData.dateOfBirth) {
                        $scope.person.personData.dateOfBirth = new Date(data.personData.dateOfBirth);
                    }
                });
            $scope.userDataChanged = function (isValid, capturedData) {
                $scope.userDataIsValid = isValid;
                $scope.acceptedTerms.capturedData = capturedData;
            };

            $scope.acceptedTerms = {
                checked: false, notes: '', capturedData: {}
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.acceptedTerms.capturedData, $scope.acceptedTerms.notes);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.allValid = function () {
                return ($scope.acceptedTerms.checked &&
                    ($scope.formId === 0 || ($scope.formId > 0 && $scope.userDataIsValid)));
            }
        }]
    );
    angular.module('ccaavailableModule').filter('filterAndMultiple', [
        '$filter', function ($filter) {
            return function (items, keyObj) {
                var filterObj = {
                    data: items,
                    filteredData: [],
                    applyFilter: function (obj, key) {
                        var fData = [];
                        if (this.filteredData.length === 0)
                            this.filteredData = this.data;
                        if (obj) {
                            var fObj = {};
                            if (!angular.isArray(obj)) {
                                fObj[key] = obj;
                                fData = fData.concat($filter('filter')(this.filteredData, fObj));
                            } else if (angular.isArray(obj)) {
                                if (obj.length > 0) {
                                    for (var i = 0; i < obj.length; i++) {
                                        if (angular.isDefined(obj[i])) {
                                            fObj[key] = obj[i];
                                            fData = fData.concat($filter('filter')(this.filteredData, fObj));
                                        }
                                    }
                                }
                            }
                            this.filteredData = fData;
                        }
                    }
                };
                if (keyObj) {
                    angular.forEach(keyObj, function (obj, key) {
                        filterObj.applyFilter(obj, key);
                    });
                }
                return filterObj.filteredData;
            };
        }
    ]);
