'use strict';

angular.module('sysadmin.components.sysAdminImportData', [])
    .component('sysAdminImportData', {
        templateUrl: '/Scripts/app/sysadmin/components/sysadminimportdata.html',
        controller: [
        '$scope', '$timeout', 'organisationService', 'Upload',
        function ($scope, $timeout, organisationService, Upload) {

            $scope.sortType = 'fileName';
            $scope.sortReverse = false;
            $scope.processing = false;

            organisationService.getLatestImportData().then(function(data) {
                    $scope.importData = data;
                },
                function(error) {
                    return error;
                });

            organisationService.getOrganisations().then(function(data) {
                    $scope.organisations = data;
                },
                function(error) {
                    return error;
                });

            $scope.addImportData = function() {
                $scope.selectImportData = {
                    organisationId: null,
                    existingData: false,
                    importStatusId: null
                };
                $scope.fileInfo = null;

            };
            $scope.showImportData = function(anImportData) {
                $scope.selectImportData = {
                    organisationId: anImportData.organisationId,
                    existingData: true,
                    importStatusId: anImportData.importStatusId,
                    organisationUserImportId: anImportData.organisationUserImportId
                };
                organisationService.getImportData(anImportData.organisationUserImportId).then(function(data) {
                        $scope.fileInfo = data;
                    },
                    function(error) {
                        return error;
                    });

            };

            $scope.$watch('selectImportData.organisationId', function (newValue, oldValue) {
                if (newValue != 'undefined' && newValue > 0) {
                    organisationService.getImportLookupData(newValue).then(function (data) {
                            $scope.systemColumns = data.columns;
                            $scope.systemPerson = data.personTypes;
                        },
                        function (error) {
                            return error;
                        });
                }
            });

            $scope.clearColumnData = function (aColumn) {
                aColumn.systemPersonTypeId = null;
                aColumn.systemColumnId = null;
            }

            $scope.processImportData = function () {
                $scope.processing = true;
                organisationService.postProcessImportData($scope.selectImportData.organisationUserImportId)
                    .then(function (data) {
                            $scope.processing = false;
                            $scope.selectImportData = null;
                            $scope.fileInfo = null;
                            $scope.importData = data;
                        }
                    );
            }

            $scope.updateImportData = function () {
                $scope.processing = true;
                var dict = {}
                var duplicates = '';
                $scope.fileInfo.columns.forEach(function (item, index) {
                    var column = $scope.fileInfo.columns[index]


                    if (!column.ignore) {
                        column.systemColumnId = column.selectedSystemColumn.systemColumnId;
                        column.isCustom = column.selectedSystemColumn.isCustom;
                        var hashcode = 'C' + column.systemColumnId + '_' + column.systemPersonTypeId + '_' + column.isCustom;

                        if (dict.hasOwnProperty(hashcode)) {
                            //Its already been added and there is an error

                            duplicates = duplicates + '\n\'' + column.name + '\' ';

                            $scope.systemPerson.forEach(function (item, index) {
                                if (item.systemPersonTypeId === column.systemPersonTypeId) {
                                    duplicates = duplicates + item.name
                                }
                            });

                            $scope.systemColumns.forEach(function (item, index) {
                                if (item.systemColumnId === column.systemColumnId) {
                                    duplicates = duplicates + ' ' + item.name
                                }
                            });

                        } else {
                            dict[hashcode] = '1';
                        }
                    }
                });

                if (duplicates.length > 0) {
                    $scope.processing = false;
                    alert('You have made the following duplicate selections' + duplicates);
                } else {
                    organisationService.postImportData($scope.fileInfo)
                        .then(function (data) {
                                $scope.processing = true;
                                $scope.selectImportData = null;
                                $scope.fileInfo = null;
                                $scope.importData = data;
                            }
                        );
                }
            };

            $scope.uploadFiles = function(file, errFiles) {
                $scope.f = file;
                $scope.errFile = errFiles && errFiles[0];
                if (file) {
                    file.upload = Upload.upload({
                        url: '/webapi/WebOrganisationUserImport/UploadFile',
                        data: { file: file, 'organisationId': $scope.selectImportData.organisationId }
                    });

                    file.upload.then(function(response) {
                            $timeout(function() {
                                $scope.fileInfo = response.data;

                                $scope.fileInfo.columns.forEach(function (item, index) {
                                    var column = $scope.fileInfo.columns[index]
                                    if (!column.ignore) {
                                        if (column.systemColumnId === 0) {
                                            column.systemColumnId = null;
                                        }
                                        if (column.systemPersonTypeId === 0) {
                                            column.systemPersonTypeId = null;
                                        }
                                    }
                                });
                            });
                        },
                        function(response) {
                            if (response.status > 0)
                                $scope.errorMsg = response.status + ': ' + response.data;
                        },
                        function(evt) {
                            file.progress = Math.min(100,
                                parseInt(100.0 *
                                    evt.loaded /
                                    evt.total));
                        });
                }
            };
        }
    ]})

