export interface RequestCache {
  name: string;
  age?: number;
}
export interface HttpRequestSettings {
  params?: any;
  successMessage?: string;
  successTitle?: string;
  successRoute?: string;
  errorMessage?: string;
  showSuccessToast?: boolean;
  controllerType?: any;
  orgId?: number; // For controller type of Organisation, overwrite default orgId
  config?: any;
  cache?: RequestCache;
  api: Api;
  shareReplay?: number;
  storeName?: string;
  doRefresh?: boolean;
  addHashId?: boolean;
  fullResponse?: boolean; // Return full response, not just data
  successCallback?: () => void;
}

export interface Api {
  isCore: boolean;
  baseUrl: string;
}

export const apis = {
  Web: {
    isCore: false,
    baseUrl: 'webapi'
  },
  Reception: {
    isCore: false,
    baseUrl: 'Reception'
  },
  CcaSignUp: {
    isCore: false,
    baseUrl: 'CcaSignUp'
  },
  CcaAvailable: {
    isCore: false,
    baseUrl: 'CcaAvailable'
  },
  Core: {
    isCore: true,
    baseUrl: 'api'
  }
}

export const controllerTypes = {
  SysAdmin: {
    url: 'SysAdmin',
    hasOrg: false
  },
  Admin: {
    url: 'Admin',
    hasOrg: true
  },
  User: {
    url: 'User',
    hasOrg: true
  }
};

export interface CoreApiResponse<T> {
  data: T;
  isSuccess: boolean;
  errors?: string[];
  messageTranslationParameters?: any;
}
