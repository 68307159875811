'use strict';

angular.module('sysadmin.components.smsTopUp', [
    'messaging.services.messagingService',
    'shared.components.sbEditField',
    'shared.components.sbTitleBar',
    'ngToast'
]).component('smsTopUp', {
    bindings: {},
    templateUrl: '/Scripts/app/sysadmin/components/sms-topup.template.html',
    controller: [
        '$scope', 'messagingService',
        function ($scope, messagingService) {

            $scope.organisationSmsRecords = [];
            $scope.loading = true;

            $scope.getData = () => {
                // grab the current data
                $scope.loading = true;
                messagingService.getAllOrganisationSmsBalances()
                    .then((res) => {
                        $scope.organisationSmsRecords = res.data.map(item => {
                            return {
                                ...item,
                                showSetup: false,
                                showCredits: item.smsSenderName && item.mobileDefaultFormatCountryCode
                            }
                        })
                        $scope.loading = false;
                    });
            };

            $scope.addCredits = function (data) {
                messagingService.addCreditsToOrganisationSMSBalance(data.organisationId, data.creditsToAdd)
                    .finally(() => {
                        // Get latest credits
                        $scope.getData();
                    });
            };

            $scope.updateConfig = (organisationSmsRecord) => {
                messagingService.updateSMSConfig(organisationSmsRecord)
                    .then(res => {
                        if (res.data) {
                            organisationSmsRecord.showSetup = false;
                            organisationSmsRecord.showCredits = true;
                        }
                    });
            };

            $scope.updateSender = (name, organisationId) => {
                messagingService.updateSMSSender(organisationId, name);
            };

            $scope.updateCountryCode = (countryCode, organisationId) => {
                messagingService.updateSMSCountryCode(organisationId, countryCode);
            };

            $scope.startSetup = (item) => {
                item.showSetup = true;
                if ($scope.smsCountryCodeOptions) {
                    item.mobileDefaultFormatCountryCode = $scope.smsCountryCodeOptions.find(option => option.toLowerCase() === 'gbr')
                }
            }

            $scope.getData();
            messagingService.getAllSMSCountryCodes()
                .then((res) =>{
                    if (res.data) {
                        $scope.smsCountryCodeOptions = res.data;
                    }
                });

        }
    ]});
