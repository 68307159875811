'use strict';

angular.module('balanceAdmin.quickCharge.components.quickChargeGroupEvents',
    [])
    .component('quickChargeGroupEvents',
        {
            bindings: {
                groupId: '<',
                isTeam: '<',
                notifyLoaded: '&',
                refreshFn: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/quickCharge/components/quick-charge-group-events.template.html',
            controller: [
                'groupService',
                function (
                    groupService) {

                    this.feeEvents = [];
                    this.isLoading = true;
                    this.sortType = 'createdDate';
                    this.sortReverse = false;
                    this.isCollapsed = true;

                    this.$onChanges = function (changes) {
                        if (this.refreshFn) {
                            this.refreshFn.fn = this.refresh.bind(this);
                        }
                    }.bind(this);

                    this.refresh = function () {

                        this.charges = [];
                        this.isLoading = true;

                        // Get group charges
                        groupService.getFeeOnlyEventsForGroup(this.groupId, this.isTeam)
                            .then(function (feeEvents) {
                                this.feeEvents = feeEvents;

                                this.isLoading = false;
                                if (this.notifyLoaded()) {
                                    this.notifyLoaded()();
                                }
                            }.bind(this))
                            .catch(function (err) {
                                console.log('Error retrieving fee only events for group ', err);
                            });
                    }.bind(this);
                }
            ]
        });