<header class="summary header-fixed summary-static"
        [ngClass]="{ 'sb-bg-dark' : !isTransparent }"
        *ngIf="show">
    <div class="summary__body"
        [ngClass]="{'text-light' : !isTransparent}">
    <div class="summary__actions sub-menu-extra"
        *ngIf="showAction || backText">
        <sb-button buttonClass="secondary"
                    customClasses="p-small"
                    (onClick)="back()"
                    [ngClass]="{'invisible' : !showBack}"
                    [message]="backText"
                    iconName="back"></sb-button>
        <sb-button [buttonClass]="actionButtonClass"
                    (onClick)="action()"
                    *ngIf="showAction"
                    [message]="actionText"
                    [iconName]="actionIcon"
                    [isDisabled]="actionDisabled"></sb-button>
    </div>
    <div class="summary__inner">
        <div class="summary__media hidden-xs">
            <sb-date *ngIf="eventDetails.from" [date]="eventDetails.from"></sb-date>
            <sb-icon class="loading-date" *ngIf="!eventDetails.from" name="loading"></sb-icon>
        </div>
        <div>
            <div class="align-center rhythm-m-b-h">
                <h1 [innerHTML]="eventDetails.title | translate"></h1>
                <sb-icon *ngIf="eventDetails.additionalInfo"
                        name="info"
                        class="ml-3 align-center pt-2"
                        [ngbTooltip]="tooltipTemplate"
                        tooltip-placement="right"></sb-icon>
                <ng-template #tooltipTemplate>
                    <span [innerHTML]="sanitizedAdditionalInfo"></span>
                </ng-template>
                <sb-badge *ngIf="eventDetails.isCancelled"
                    [badgeClass]="'danger'"
                    [message]="organisationLabelTags.Cancelled"
                    class="ml-4">
                </sb-badge>
                <sb-badge *ngIf="eventDetails.eventType"
                        [badgeClass]="'neutral'"
                        [message]="eventDetails.eventType"
                        class="ml-4">
                </sb-badge>
            </div>
            <div class="summary__info">
                <div *ngIf="eventDetails.eventLocation?.name">
                    <div *ngIf="eventDetails.eventLocation?.name">
                        <sb-icon name="location-pin" overrideStyle="light"></sb-icon><span [innerHTML]="eventDetails.eventLocation?.name"></span>
                    </div>
                </div>
                <div *ngIf="eventDetails.owningEntityName || eventDetails.organiserName">
                    <div *ngIf="eventDetails.owningEntityName && eventDetails.eventType !== 'Transport'">
                        <sb-icon name="groups"></sb-icon> <span [innerHTML]="eventDetails.owningEntityName"></span>
                    </div>
                    <div *ngIf="eventDetails.organiserName">
                        <sb-icon name="users" override-style="solid"></sb-icon> <span [innerHTML]="eventDetails.organiserName"></span>
                    </div>
                </div>
                <div *ngIf="timesLabel">
                    <div>
                        <sb-icon name="clock" overrideStyle="light"></sb-icon>
                        <span [innerHTML]="timesLabel"></span>
                    </div>
                </div>
                <div ng-content></div>
            </div>
        </div>
    </div>
    </div>
</header>
