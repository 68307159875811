import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@sb-shared/models/user';
import { UserService } from '@sb-shared/services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {

  selectedPerson: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    private user: UserService,
    private router: Router
  ) { }

  setSelectedPersonById(personId?:number) {
    if (!personId) {
      this.selectedPerson.next(null);
      return;
    }

    this.user.getPerson(personId).subscribe((person: User) => {
      this.selectedPerson.next(person);
    })
  }

  isProfilePopup() {
    return this.router.url.indexOf('(profile:') > -1;
  }

  isMyAccount() {
    return this.router.url.startsWith('/Profile');
  }

  isReception() {
    return this.router.url.startsWith('/Reception/users');
  }

  getCurrentPersonData(): Observable<User> {
    return this.user.getCurrentPersonData();
  }

  // Determines user for profile views:
  // - if My Account, without a popup (i.e. not viewing a linked user), it's current logged-in user.
  // - otherwise, use selected person.
  getUserInContext(): Observable<User> {
    if (this.isMyAccount() && !this.isProfilePopup()) {
      return this.getCurrentPersonData();
    }

    return this.selectedPerson;
  }
}
