"use strict";

angular.module('profile.components.userForm', [])
    .component('userForm',
    {
        bindings: {
            person: '<',
            isOrganisationAdmin: '<'
        },
        templateUrl: '/Scripts/app/profile/components/user-form.template.html',
        controller: [
            function () {
                this.formId = null;
                this.ui = {
                    menuOpen: false,
                    formTitle: '',
                    formIcon: ''
                };

                var self = this;

                this.selectForm = function(id)
                {
                    self.formId = id;
                    self.ui.menuOpen = false;

                    for (var i = self.person.forms.length; i--;)
                    {
                        if (self.person.forms[i].organisationFormId === id)
                        {
                            self.ui.formTitle = self.person.forms[i].title;
                            self.ui.formIcon = self.person.forms[i].iconName;
                            break;
                        }
                    }
                };

                this.$onChanges = function(changes)
                {
                    if (changes.person && changes.person.currentValue)
                    {
                        self.selectForm(changes.person.currentValue.forms[0].organisationFormId);

                    }
                };
            }
        ]
    });
