angular.module('sysadmin.constants', [])
    .constant('eventSectionEnum', {
        Sports: 1,
        Trips: 2,
        Signups: 3,
        Other: 4,
        System: 5
    })
    .constant('queueRunStatusEnum', {
        Queued: 1,
        Running: 2,
        Failed: 3,
        Successful: 4
    })
    .constant('importsDataSourceEnum', {
        GG4L: 1,
        ISAMS: 2,
        ManageBac: 3,
        Wonde: 4
    })
