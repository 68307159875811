'use strict';

angular.module('transport.configuration.timetable.transportTimetableEdit',
    [
        'transport.services.transportConfigurationMethodService',
        'transport.services.transportTimeSlotsService',
        'transport.services.transportDirectionsService',
        'transport.services.transportTimetablesService',
        'transport.services.transportTimetableCalendarsService',
        'transport.services.transportConfigurationRoutesService',
        'shared.directives.sbCheckBox',
        'shared.components.sbModalHeader',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportTimetableEdit',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/transport/configuration/timetable/transport-timetable-edit.template.html',
            controller: [
                '$timeout',
                '$translate',
                '$uibModal',
                'cachedLookupService',
                'iconService',
                'personService',
                'transportTimetablesService',
                'simpleAlertService',
                function transportTimetableEditController($timeout,
                                                        $translate,
                                                        $uibModal,
                                                        cachedLookupService,
                                                        iconService,
                                                        personService,
                                                        transportTimetablesService,
                                                        simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;
                    this.methodSupportsRoutes = false;
                    this.transportTimetableCalendars = null;
                    this.isTransportTimetableCalendarLocked = false;
                    this.ready = false;
                    this.isTimetableMethodAvailable = false;
                    this.transportTimetableArchivingInfo = {};
                    this.isArchiveOptionAvailable = false;
                    this.areAllDaysInactive = false;
                    this.timetableCalendarName = null;

                    var closeModal = function ()
                    {
                        this.modalInstance.close();
                    }.bind(this);

                    this.transportTimetable = {};
                    this.transportTimetableResponse = {};

                    this.changesMade = function ()
                    {
                        this.anyChanges = true;
                    };

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.transportMethods = null;
                    this.transportTimeSlots = null;
                    this.transportRoutes = null;
                    this.timeslotDirectionName = null;
                    this.timetableIsLinkedToStudentPreferences = false;
                    this.busMonitors = [];

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            this.ready = false;

                            var params = this.resolve.params;
                            this.timetableId = params.timetableId;

                            transportTimetablesService.getTransportTimetableDataForEdit(this.timetableId)
                                .then(function (response)
                                {
                                    this.transportTimetable = response.timetable;

                                    this.transportTimetable.timetableOperatingDays.forEach(function (day)
                                    {
                                        if (day.isLinkedToEvents)
                                        {
                                            this.isTransportTimetableCalendarLocked = true;
                                        }

                                        if (day.isLinkedToStudentPreferences)
                                        {
                                            this.timetableIsLinkedToStudentPreferences = true;
                                        }
                                    }.bind(this));

                                    this.transportMethods = response.methods;

                                    // This is to cater for the situation where a method has been archived since it was linked to the timetable.
                                    // If it's not available the method dropdown will be enabled and the user forced to select a new one.
                                    for (var i = 0; i < this.transportMethods.length; i++)
                                    {
                                        if (this.transportMethods[i].id == this.transportTimetable.transportMethodId)
                                        {
                                            this.isTimetableMethodAvailable = true;
                                            this.methodSelected();
                                            break;
                                        }
                                    }

                                    if (!this.isTimetableMethodAvailable)
                                    {
                                        this.transportTimetable.transportMethodId = null;
                                    }

                                    this.transportTimeSlots = response.timeSlots;
                                    this.transportTimetableCalendars = response.calendars;
                                    this.transportTimetableArchivingInfo = response.archivingInfo;

                                    this.transportTimetableCalendars.filter(function (calendar)
                                    {
                                        if (calendar.id == this.transportTimetable.transportTimetableCalendarId)
                                        {
                                            this.timetableCalendarName = calendar.name;
                                        }
                                    }.bind(this));

                                    this.areAllDaysInactive = this.transportTimetableArchivingInfo.areAllDaysInactive;

                                    cachedLookupService.isOrganisationSuperAdminOrTransportAdvancedAdmin()
                                        .then(function (response)
                                        {
                                            this.isArchiveOptionAvailable = response == 1;
                                        }.bind(this))
                                        .catch(function (responseData)
                                        {
                                            this.handleException(responseData);
                                        }.bind(this));

                                    personService.getBusMonitors().then(function (data)
                                    {
                                        this.busMonitors = data;

                                        this.ready = true;

                                    }.bind(this), function (error)
                                    {
                                        simpleAlertService.errorAlert(error);
                                    }.bind(this));
                                }.bind(this))
                                .catch(function (responseData)
                                {
                                    this.handleException(responseData);
                                }.bind(this));
                        }
                    }.bind(this);

                    this.handleException = function (responseData)
                    {
                        var message = {};

                        if (responseData && responseData.data && responseData.data.Message)
                        {
                            message = { message: responseData.data.Message };
                        }

                        alertAndResetOkButton(message);
                    }

                    this.methodSelected = function ()
                    {
                        var selectedMethod = this.transportMethods.find(function (method)
                        {
                            return method.id == this.transportTimetable.transportMethodId;
                        }.bind(this));

                        if (typeof selectedMethod !== 'undefined')
                        {
                            this.selectedMethodIconName = iconService.getIcon(selectedMethod.sbIconName);
                            this.methodSupportsRoutes = selectedMethod.supportRoutes;
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.transportTimetable =
                        {
                            transportMethodId: null,
                            transportTimeSlotId: null,
                            isActive: false
                        };

                        this.modalInstance.dismiss('cancel');
                    };

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.dayActiveStateChanged = function ()
                    {
                        var areAllDaysInactive = true;

                        this.transportTimetable.timetableOperatingDays.forEach(function (day)
                        {
                            if (day.isActive)
                            {
                                areAllDaysInactive = false;
                            }
                        }.bind(this));

                        this.areAllDaysInactive = areAllDaysInactive;
                    }

                    this.editTransportTimetable = function ()
                    {
                        transportTimetablesService.editTransportTimetable(this.transportTimetable)
                            .then(function ()
                            {
                                simpleAlertService.simpleAlert(
                                    {
                                        title: 'SB_Saved',
                                        message: 'SB_Transport_Timetable_Changed',
                                        staticBackdrop: true
                                    });

                                closeModal();
                            }.bind(this))
                            .catch(function (responseData)
                            {
                                var message = {};

                                if (responseData && responseData.data && responseData.data.Message)
                                {
                                    message = { message: responseData.data.Message };
                                }

                                alertAndResetOkButton(message);
                            });
                    }.bind(this);

                    this.archiveTransportTimetable = function ()
                    {
                        var directionName = $translate.instant(this.transportTimetable.transportDirectionName);
                        var transportItemNameToArchive = this.transportTimetable.transportMethodName + ' - ' + this.transportTimetable.transportTimeSlotName + ' - ' + directionName;

                        var confirmArchiveModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportConfirmArchiving',
                                size: 'md',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return { transportItemNameToArchive: transportItemNameToArchive };
                                    }
                                }
                            });

                        confirmArchiveModalInstance.result
                            .then(
                                () => {
                                    transportTimetablesService.archiveTimetable(this.transportTimetable)
                                        .then(function (responseData)
                                        {
                                            if (responseData && responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Transport_Timetable_Archived',
                                                    message: 'SB_Transport_Timetable_Archived',
                                                    staticBackdrop: true
                                                });

                                                closeModal();
                                            }
                                            else if (responseData && !responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Error',
                                                    message: 'SB_Transport_Archive_Operation_Failed',
                                                    staticBackdrop: true
                                                });

                                                closeModal();
                                            }
                                            else
                                            {
                                                alertAndResetOkButton();
                                            }
                                        })
                                        .catch(function (responseData)
                                        {
                                            var message = {};

                                            if (responseData && responseData.data && responseData.data.Message)
                                            {
                                                message = { message: responseData.data.Message };
                                            }

                                            alertAndResetOkButton(message);
                                        });

                                resetSubmitButtons();

                            },
                            () => resetSubmitButtons()
                        );
                    }.bind(this);

                    this.deleteEventsForDay = function (timetableOperatingDay)
                    {
                        var confirmDeleteEventsForDayModal = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportConfirmDeleteEvents',
                                size: 'md',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return {
                                            timetableOperatingDay: timetableOperatingDay
                                        }
                                    }
                                }
                            });

                        confirmDeleteEventsForDayModal.result
                            .then(function (modalResponse)
                            {
                                if (modalResponse == 'confirm')
                                {
                                    transportTimetablesService.deleteEventsForDay(this.transportTimetable.id, timetableOperatingDay.id)
                                        .then(function (response)
                                        {
                                            if (response.isDeletionSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Transport_Events_Deleted',
                                                    message: 'SB_Transport_Events_Deleted',
                                                    staticBackdrop: true
                                                });
                                            }

                                            this.transportTimetable = response.timetableResponse;
                                            this.transportTimetableArchivingInfo = response.timetableArchivingResponse;
                                            this.areAllDaysInactive = this.transportTimetableArchivingInfo.areAllDaysInactive;

                                        }.bind(this)
                                            , function (error)
                                            {
                                                var message = {};

                                                if (error && error.data && error.data.Message)
                                                {
                                                    message = { message: error.data.Message };
                                                }

                                                alertAndResetOkButton(message);
                                            });
                                }

                                resetSubmitButtons();

                            }.bind(this));
                    }.bind(this);
                }
            ]
        });
