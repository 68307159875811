import { Component, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { left } from '@popperjs/core';
import { Map, Marker, map, easyButton, tileLayer, DivIcon, latLngBounds } from 'leaflet';
import 'leaflet-easybutton';

export interface IMapMarker {
  id: string;
  lat: number;
  lng: number;
  title?: string;
  tooltip?: string;
  description?: string;
  draggable?: boolean;
  colour?: string;
  labelColour?: string;
  focus?: boolean;
}

export interface IMapCentre {
  lat: number;
  lng: number;
  zoom: number;
}

@Component({
  selector: 'sb-map-display',
  standalone: true,
  templateUrl: './map-display.component.html',
  styleUrl: './map-display.component.scss'
})
export class MapDisplayComponent implements AfterViewInit, OnChanges {
  @Input() home?: IMapCentre;
  @Input() markers: IMapMarker[] = [];
  @Input() allowPinDrop: boolean = false;
  @Input() tileURL: string;
  @Input() attributionString: string;
  @Input() markerColour: string = 'silver';
  @Input() labelColour: string = 'black';
  @Input() autoCenter: boolean = true;
  @Input() height: string;
  @Output() dropPinEvent = new EventEmitter<IMapMarker>();
  @Output() clickPinEvent = new EventEmitter<IMapMarker>();

  private map!: L.Map;
  private mapMarkers: L.Marker[] = [];

  private pinStyles: string = `
    width: 32px;
    height: 32px;
    display: block;
    left: -16px;
    top: -16px;
    position: relative;
    border-radius: 32px 32px 0;
    transform: rotate(45deg);
    border: 2px solid #FFFFFF;
  `;
  private pinLabelStyles: string = `
    transform: rotate(-45deg) translate(-3px, 2px);
    display: block;
    font-weight: bold;
    font-size: medium;
    text-align: center;
    max-width: 64px;
    overflow: hidden;
  `;

  constructor() {
    this.tileURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    this.attributionString = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  }

  ngAfterViewInit() {
    this.initializeMap();
    this.addMarkers();
    this.centerMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes['markers']) {
        const markerCount = this.mapMarkers.length;
        this.removeMarkers();
        this.addMarkers();
        if ((markerCount == 0 && this.mapMarkers.length > 0) || this.autoCenter) {
          this.centerMap();
        }
      }

      if (changes['height']) {
        this.map.getContainer().style.height = this.height;
      }
    }
  }

  private initializeMap() {
    // set up the map
    this.map = map('map');
    //this.map.getContainer().style.height = this.height;
    tileLayer(this.tileURL, { attribution: this.attributionString }).addTo(this.map);

    // add an extra button to re-centre the map
    easyButton('fa-solid fa-crosshairs-simple fa-2xl', () => {
      this.centerMap(true);
    }).addTo(this.map);

    if (this.allowPinDrop) {
      // event handler for dropping a pin on the map
      this.map.on('click', e => {
        let lng = e.latlng.lng;
        while (lng < -180) lng += 360;
        while (lng > 180) lng -= 360;
        const pin: IMapMarker = {
          id: '',
          lat: e.latlng.lat,
          lng: lng,
          colour: this.markerColour,
          labelColour: this.labelColour
        };
        this.dropPinEvent.emit(pin);
      });
    }
  }

  private addMarkers() {
    if (this.markers) {
      this.markers.forEach(marker =>
        this.addMapMarker(
          marker.id,
          marker.lat,
          marker.lng,
          marker.title,
          marker.tooltip,
          marker.description,
          marker.draggable,
          marker.colour,
          marker.labelColour
        )
      );
    }
  }

  private removeMarkers() {
    this.mapMarkers.forEach(pin => {
      pin.removeFrom(this.map);
    });
    this.mapMarkers = [];
  }

  private addMapMarker(
    id: string,
    lat: number,
    lng: number,
    title?: string,
    tooltip?: string,
    description?: string,
    draggable?: boolean,
    colour?: string,
    labelColour?: string
  ) {
    let icon = Marker.prototype.options.icon;
    // if colour specified, build an icon of the correct colour
    if (colour) {
      const label: string = title ? `<span style="${this.pinLabelStyles} color:${labelColour}">${title}</span>` : '';
      icon = new DivIcon({
        iconAnchor: [3, 32],
        popupAnchor: [-1, -36],
        html: `<span style="${this.pinStyles} background-color:${colour}">${label}</span>`});
    }
    // create the marker
    const mapMarker = new Marker([lat, lng], {
      title: tooltip ? tooltip : title,
      draggable: draggable,
      icon: icon,
      bubblingMouseEvents: false
    });

    // add a popup if there is a description to show
    if (description) {
      mapMarker.bindPopup(description).openPopup();
    }

    // marker click event
    mapMarker.on('click', e => {
      const popup = e.target.getPopup();
      if (popup && popup.isOpen()) {
        // don't fire pin click if there is a popup that has just been opened
        return;
      }

      const pin: IMapMarker = {
        id: id,
        lat: lat,
        lng: lng,
        title: title,
        tooltip: tooltip,
        description: description,
        draggable: draggable,
        colour: colour,
        labelColour: labelColour
      }
      this.clickPinEvent.emit(pin);
    });

    // marker is draggable?
    if (draggable) {
      mapMarker.on('dragend', e => {
        const pin: IMapMarker = {
          id: id,
          lat: e.target.getLatLng().lat,
          lng: e.target.getLatLng().lng,
          title: title,
          tooltip: tooltip,
          description: description,
          draggable: draggable,
          colour: colour,
          labelColour: labelColour
        };
        this.dropPinEvent.emit(pin);
      });
    }

    // add marker to map
    mapMarker.addTo(this.map);
    this.mapMarkers.push(mapMarker);
    return mapMarker;
  }

  private centerMap(manual: boolean = false) {
    if (this.mapMarkers.length > 0) {
      // if any marker is set to be focus, center on that
      const focusMarker = this.markers.find(m => m.focus);
      if (focusMarker && !manual) {
        this.map.setView([focusMarker.lat, focusMarker.lng], this.home.zoom);
      } else {
        // fit the map to the bounds of all the markers
        const bounds = latLngBounds(this.mapMarkers.map(marker => marker.getLatLng()));
        if (manual) {
          this.map.flyToBounds(bounds);
        } else {
          this.map.fitBounds(bounds);
          this.map.zoomOut();
        }
      }
    } else if (this.home) {
      // use default centre point and zoom
      this.map.setView([this.home.lat, this.home.lng], this.home.zoom);
    } else {
      // whole world view
      this.map.fitWorld();
    }
  }

}
