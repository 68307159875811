angular.module('tuitionFeesModule')
    .component('invoiceDetailViewCreditNotes', {
        bindings: {
            invoiceCreditNotes : '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-credit-notes.template.html',
        controller: class InvoiceDetailViewCreditNotesCtrl {

            // bindings
            invoiceCreditNotes: any[];

            // dependencies
            $window: any;

            // variables
            currencyDisplaySymbol: string;
            currencyExponent: number;
            showNoResult: boolean;
            sortReverse: boolean = true;
            sortType: string = 'dateIssued';

            static $inject = ['$window'];

            constructor($window)
            {
                this.$window = $window;
                this.showNoResult = false;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
            }

            $onChanges(changes) {
                if (changes.invoiceCreditNotes?.currentValue) {
                    this.showNoResult = !changes.invoiceCreditNotes?.currentValue || changes.invoiceCreditNotes?.currentValue?.length == 0;
                }
            }

            downloadCreditNote(item) {
                this.$window.open(item.pdfBlobPath, '_blank');
            }
        }
    });
