import { Component, OnInit } from '@angular/core';
import { Buttons } from '@sb-shared/models/UI/buttons';
import { Language } from '@sb-shared/models/language';
import { LanguageCodeService } from '@sb-shared/services/language-code.service';
import { LocaleService } from '@sb-shared/services/locale.service';
import { first, forkJoin, takeUntil } from 'rxjs';
import { ComponentBase } from '../component-base/component-base';

@Component({
  selector: 'sb-locale-picker',
  templateUrl: './locale-picker.component.html',
  styleUrls: ['./locale-picker.component.scss']
})
export class LocalePickerComponent extends ComponentBase implements OnInit {
  languages: Language[];
  languageItems: Buttons;
  selectedLanguage: Language;

  constructor(
    private languageCode: LanguageCodeService,
    private locale: LocaleService) {
      super()
    }

  ngOnInit(): void {
    this.getLanguages();
  }

  onButtonClick(index?: number) {
    const language = this.languages[index];
    this.saveLanguage(language);
  }

  private getLanguages() {
    forkJoin([
      this.languageCode.getLanguageCodesForOrganisation(),
      this.locale.getCurrentLanguage()
    ]).subscribe(([languages, currentLanguage]) => {
      this.languages = languages;
      this.getLanguageItems();
      this.updateLanguage(currentLanguage);
    });
  }

  private getLanguageItems() {
    // Set dropdown items
    this.languageItems = this.languages.map(language => {
      return {
        message: language.label,
        imgUrl: language.imgUrl,
        buttonId: language.languageCode,
        isActive: language === this.selectedLanguage
      }
    });
  }

  private saveLanguage(newLanguage: Language) {
    this.locale.update(newLanguage, true)
      .pipe(
        first(),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.updateLanguage(newLanguage);
      });
  }

  private updateLanguage(newLanguage: Language): void {
    this.selectedLanguage = this.languages.find(language => language === newLanguage);
    this.languageItems = this.languageItems.map((item, idx) => ({
      ...item,
      isActive: this.languages[idx] === this.selectedLanguage
    }));
  }
}
