'use strict';

angular.module('sysadmin.components.domainName', [
    'sysadmin.services.domainNameService',
    'shared.filters.decoded',
    'shared.components.sbOnOffSwitch',
]).component('domainName', {
    templateUrl: '/Scripts/app/sysadmin/components/domain-name.template.html',
    bindings: {
        organisation: '<',
        domainName: '<',
    },
    controller: [
        'domainNameService',
        function domainNameController(domainNameService) {

            var self = this;
            this.domainName = '';
            this.originalDomainName = '';
            this.anyChanges = false;
            this.organisationDomainDto = null;

            this.$onChanges = function (changes)
            {
                if (typeof self.organisation !== 'undefined' && self.organisation != null)
                {
                    self.loadDomainName();
                }
            };

            this.loadDomainName = function ()
            {
                domainNameService.getDomainName(this.organisation.organisationId).then(function (data)
                {
                    self.organisationDomainDto = data;
                    self.originalDomainName = self.organisationDomainDto.domainName;
                    self.domainName = self.organisationDomainDto.domainName;
                }, function () {
                    simpleAlertService.errorAlert();
                });

                self.anyChanges = false;
            };

            this.saveDomainName = function ()
            {
                if (typeof this.domainName === 'undefined' ||
                    this.domainName == '')
                {
                    return;
                }

                domainNameService.saveDomainName(this.organisationDomainDto.id, this.organisation.organisationId, self.domainName).then(function (data)
                {
                    self.loadDomainName();
                }, function () {
                    simpleAlertService.errorAlert();
                });
            };

            this.changesMade = function ()
            {
                if (this.domainName != this.originalDomainName)
                {
                    this.anyChanges = true;
                }
            }
        }
    ]
});