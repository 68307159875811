<div class="not-found">
    <img src="https://www.schoolsbuddy.com/images/home/event-management.png" alt="More features">
    <h1>404 Not found</h1>
    <div class="mt-5">
        <sb-loading [hasLoaded]="mainMenuItems !== undefined"></sb-loading>
        <div class="link-container">
            <sb-link-card *ngFor="let item of mainMenuItems"
                            [link]="item"></sb-link-card>
        </div>
    </div>

</div>
