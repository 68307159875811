angular.module('settingsModule')
    .component('staffRolesInternal',
        {
            templateUrl: '/Scripts/app/settings/components/staff-roles/staff-roles-internal.template.html',
            controller: class StaffRolesInternalCtrl {

                modeTabs: any;
                mode: any;

                constructor () {}

                $onInit(){

                    this.modeTabs = {
                        Roles: 1,
                        Users: 2
                    }

                    this.mode = this.modeTabs.Roles;

                }

            }
        });