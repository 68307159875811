'use strict';

angular.module('ccasignupModule').component('ccaGroupManage', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-group-manage.template.html',
    bindings: {
        selectedSignUp: '<',
    },
    controller: [
        '$scope',
        '$state',
        '$uibModal',
        'toastService',
        'imagesUploadHandler',
        'signUpRepository',
        'organisationRepository',
        'personPickerService',
        'subjectClassService',
        'tinymceConfigHelper',
        'simpleAlertService',
        'teamStatusEnum',
        function ($scope,
            $state,
            $uibModal,
            toastService,
            imagesUploadHandler,
            signUpRepository,
            organisationRepository,
            personPickerService,
            subjectClassService,
            tinymceConfigHelper,
            simpleAlertService,
            teamStatusEnum) {

            this.$onChanges = (changes) => {
                if (changes.selectedSignUp && changes.selectedSignUp.currentValue) {
                    $scope.signUp = this.selectedSignUp;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues

                var loading = 0;

                $scope.isLoading = function () { return loading > 0; };

                $scope.tinymceOptions = tinymceConfigHelper.getTinyMceConfig(
                    {
                        tables: false,
                        hyperlinks: true,
                        imagesUploadHandler: imagesUploadHandler.uploadImage,
                        imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                    });

                organisationRepository.get().then(function (organisation) { $scope.organisation = organisation; });

                $scope.items = signUpRepository.getDaysOfWeek(true);

                $scope.saving = false;

                $scope.additionalEventDates = [];

                $scope.statuses = [
                    {
                        id: teamStatusEnum.NotStarted,
                        name: 'SB_CcaStatus_Available_for_Signup'
                    },
                    {
                        id: teamStatusEnum.Partial,
                        name: 'SB_CcaStatus_Partial'
                    },
                    {
                        id: teamStatusEnum.Complete,
                        name: 'SB_CcaStatus_Complete'
                    },
                    {
                        id: teamStatusEnum.OnHold,
                        name: 'SB_CcaStatus_On_Hold'
                    },
                    {
                        id: teamStatusEnum.AutoComplete,
                        name: 'SB_CcaStatus_Auto_Complete'
                    },
                    {
                        id: teamStatusEnum.Archive,
                        name: 'SB_CcaStatus_Archived'
                    },
                    {
                        id: teamStatusEnum.Active,
                        name: 'SB_CcaStatus_Active'
                    },
                    {
                        id: teamStatusEnum.Extending,
                        name: 'SB_CcaStatus_Extending'
                    }
                ];

                $scope.costTypes = [
                    {
                        id: '0',
                        name: 'SB_CcaCostType_Free'
                    },
                    {
                        id: '1',
                        name: 'SB_CcaCostType_Per_term'
                    },
                    {
                        id: '2',
                        name: 'SB_CcaCostType_Per_session'
                    }
                ];

                $scope.genders = [
                    {
                        id: '1',
                        name: 'SB_Female'
                    },
                    {
                        id: '2',
                        name: 'SB_Male'
                    },
                    {
                        id: '3',
                        name: 'SB_Mixed'
                    }
                ];

                $scope.addLink = function () {
                    if (!$scope.signUpGroup.linkedGroups) {
                        $scope.signUpGroup.linkedGroups = [];
                    }

                    $scope.signUpGroup.linkedGroups.push({
                        id: 0,
                        ccaStartHourMin: { h: $scope.ccaStartHourMin.h, m: $scope.ccaStartHourMin.m },
                        ccaEndHourMin: { h: $scope.ccaEndHourMin.h, m: $scope.ccaEndHourMin.m },
                        locationId: $scope.signUpGroup.homeLocationId,
                        organiserId: $scope.signUpGroup.organiserPersonId,
                        staffMembers: $scope.signUpGroup.staffMembers
                    });
                };

                $scope.removeLink = function (index) {
                    $scope.signUpGroup.linkedGroups.splice(index, 1);
                };

                $scope.signUpGroup = {};
                $scope.signUpGroup.id = 0;
                $scope.signUpGroup.ccaTeamClubs = [];
                $scope.signUpGroup.ccaTeamTeams = [];
                $scope.signUpGroup.ccaTeamSubjectClasses = [];
                $scope.signUpGroup.ccaSignUpId = $state.params.signUpId;
                $scope.signUpGroup.genderTypeId = 3;
                $scope.signUpGroup.showInSportsPortal = false;

                if ($scope.signUp.status === 3) // signup status is already active
                {
                    $scope.signUpGroup.statusId = teamStatusEnum.Active; // default the group to active
                }
                else {
                    $scope.signUpGroup.statusId = teamStatusEnum.NotStarted;
                }

                $scope.signUpGroup.hasSelections = false;

                signUpRepository.initialiseLocalRequirementGroups($scope);

                $scope.loadSignUpGroup = function () {
                    signUpRepository
                        .getSignUpGroup($state.params.signUpId, $state.params.signUpGroupId)
                        .then(function (signUpGroup) {
                            $scope.signUpGroup = signUpGroup;
                            $scope.ccaStartHourMin = { h: signUpGroup.ccaStartHour, m: signUpGroup.ccaStartMin };
                            $scope.ccaEndHourMin = { h: signUpGroup.ccaEndHour, m: signUpGroup.ccaEndMin };
                            if ($scope.signUpGroup.linkedGroups) {
                                for (var i = $scope.signUpGroup.linkedGroups.length; i--;) {
                                    var linkedGroup = $scope.signUpGroup.linkedGroups[i];
                                    linkedGroup.ccaStartHourMin = { h: linkedGroup.ccaStartHour, m: linkedGroup.ccaStartMin };
                                    linkedGroup.ccaEndHourMin = { h: linkedGroup.ccaEndHour, m: linkedGroup.ccaEndMin };
                                }
                            }

                            if ($state.params.copy && $state.params.copy === true) {
                                $scope.signUpGroup.id = 0;

                                if ($scope.signUpGroup.linkedGroups) {
                                    for (var i = $scope.signUpGroup.linkedGroups.length; i--;) {
                                        $scope.signUpGroup.linkedGroups[i].id = 0;
                                    }
                                }
                            }

                            if ($scope.signUp.ignoreDayOfWeek) {
                                $scope.signUpGroup.dayofWeek = 1;
                            }

                            $scope.loadLookups();

                            signUpRepository.populateRequirementGroupsFromCcaSignup(signUpGroup, $scope.requirementGroups);

                            loading--;
                        })
                        .catch(function () {
                            loading--;
                        });
                };

                loading++;
                if ($state.params.signUpGroupId) {
                    $scope.loadSignUpGroup();
                }
                else {
                    signUpRepository.getSignUpDto($state.params.signUpId)
                        .then(function (signUp) {
                            $scope.ccaStartHourMin = { h: signUp.defaultStartHour, m: signUp.defaultStartMin };
                            $scope.ccaEndHourMin = { h: signUp.defaultEndHour, m: signUp.defaultEndMin };
                            $scope.signUpGroup.allowJoinRequests = true;
                            $scope.signUpGroup.autoCloseJoinRequests = true;

                            if ($scope.signUp.ignoreDayOfWeek) {
                                $scope.signUpGroup.dayofWeek = 1;
                            }

                            $scope.loadLookups();

                            if (typeof signUp.defaultLeadOrganiser !== 'undefined' &&
                                signUp.defaultLeadOrganiser != null) {
                                $scope.signUpGroup.staffMembers = [signUp.defaultLeadOrganiser];
                                $scope.signUpGroup.organiserPersonId = signUp.defaultLeadOrganiser.personId;
                            }

                            loading--;
                        })
                        .catch(function () {
                            loading--;
                        });
                }

                $scope.loadLookups = function () {
                    signUpRepository
                        .getLocations()
                        .then(function (locations) {
                            $scope.locations = locations;
                        }).catch(function () { });

                    signUpRepository
                        .getSports()
                        .then(function (sports) {
                            $scope.sports = sports;
                        }).catch(function () { });

                    subjectClassService.subjectsEnabledForOrganisation()
                        .then(function (data) {
                            $scope.subjectsEnabled = data;
                        }).catch(function () { });
                };

                $scope.update = function (ccaSignUpGroup) {

                    $scope.saving = true;
                    // rebuild the cca team team/club/class subject collections
                    signUpRepository.rebuildCcaLinkedEntities(ccaSignUpGroup, $scope.requirementGroups);

                    ccaSignUpGroup.ccaStartHour = $scope.ccaStartHourMin.h;
                    ccaSignUpGroup.ccaStartMin = $scope.ccaStartHourMin.m;
                    ccaSignUpGroup.ccaEndHour = $scope.ccaEndHourMin.h;
                    ccaSignUpGroup.ccaEndMin = $scope.ccaEndHourMin.m;

                    if ($scope.signUpGroup.linkedGroups) {
                        for (var i = $scope.signUpGroup.linkedGroups.length; i--;) {
                            var linkedGroup = $scope.signUpGroup.linkedGroups[i];
                            linkedGroup.ccaStartHour = linkedGroup.ccaStartHourMin.h;
                            linkedGroup.ccaStartMin = linkedGroup.ccaStartHourMin.m;
                            linkedGroup.ccaEndHour = linkedGroup.ccaEndHourMin.h;
                            linkedGroup.ccaEndMin = linkedGroup.ccaEndHourMin.m;
                        }
                    }

                    signUpRepository
                        .postAndFetchGroup(ccaSignUpGroup)
                        .then(function (response) {
                            $scope.saving = false;

                            if (ccaSignUpGroup.statusId === teamStatusEnum.Extending || response.statusId === teamStatusEnum.Extending) {
                                toastService.error('SB_CcaGroup_Save_Warning_Events_Generating');
                                return;
                            }

                            if (ccaSignUpGroup.statusId != response.statusId) {
                                toastService.error('SB_CcaGroup_Save_Warning_Events_Status');
                                return;
                            }

                            $state.go('ccaDetails.groups', { searchData: $state.params.searchData });
                        })
                        .catch(err => {
                            const { data: { message } = {} } = { ...err };
                            $scope.saving = false;
                            simpleAlertService.errorAlert({ message });
                        });
                };

                $scope.openAvailabilityPicker = function (requirementGroupId) {
                    // get the list of stuff we've already picked
                    var excludedTeamIds = [];
                    var excludedClubIds = [];
                    var excludedSubjectClassIds = [];
                    var j = 0;

                    for (var i = -1; i < 2; i++) {
                        if (i === requirementGroupId)
                            continue;

                        for (j = $scope.requirementGroups[i].ccaTeamTeams.length; j--;) {
                            excludedTeamIds.push($scope.requirementGroups[i].ccaTeamTeams[j]);
                        }

                        for (j = $scope.requirementGroups[i].ccaTeamClubs.length; j--;) {
                            excludedClubIds.push($scope.requirementGroups[i].ccaTeamClubs[j]);
                        }

                        for (j = $scope.requirementGroups[i].ccaTeamSubjectClasses.length; j--;) {
                            excludedSubjectClassIds.push($scope.requirementGroups[i].ccaTeamSubjectClasses[j]);
                        }
                    }

                    var popup = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: '/Scripts/app/shared/controllers/team-club-picker-popup.template.html',
                        controller: 'teamClubPickerPopupController',
                        size: 'lg',
                        resolve: {
                            teamIds: function () {
                                return $scope.requirementGroups[requirementGroupId].ccaTeamTeams;
                            },
                            clubIds: function () {
                                return $scope.requirementGroups[requirementGroupId].ccaTeamClubs;
                            },
                            subjectClassIds: function () {
                                return $scope.requirementGroups[requirementGroupId].ccaTeamSubjectClasses;
                            },
                            showSubjects: function () {
                                return $scope.subjectsEnabled;
                            },
                            excludedTeamIds: function () {
                                return excludedTeamIds;
                            },
                            excludedClubIds: function () {
                                return excludedClubIds;
                            },
                            excludedSubjectClassIds: function () {
                                return excludedSubjectClassIds;
                            }
                        }
                    });
                };

                $scope.isLive = function (signUpGroup) {
                    return signUpGroup.id > 0 && signUpGroup.statusId === teamStatusEnum.Active;
                };

                $scope.manageStaff = function () {
                    var selectedPersonIds = [];

                    angular.forEach($scope.signUpGroup.staffMembers,
                        function (item) {
                            selectedPersonIds.push(item.personId);
                        });

                    var modalInstance = personPickerService.openMultiPersonPicker({
                        selectedPersonIds: selectedPersonIds,
                        defaultClubId: 0,
                        defaultTeamId: 0,
                        okButtonText: 'SB_Save',
                        includeStaff: true,
                        includePupils: false,
                        hideGroupFilter: true,
                        hideYearFilter: true,
                        hideGenderFilter: false,
                        hideRemove: false,
                        filterOutExternalStaff: false,
                        overridePersonLookupService: null,
                        isMassStudentAssignmentCopyFromMode: false,
                        selectedTimeSlotId: null
                    });

                    modalInstance.result
                        .then(function (selectedPersonIds) {
                            signUpRepository.getSignupGroupStaffMembersFromIds(selectedPersonIds)
                                .then(function (data) {
                                    $scope.signUpGroup.staffMembers = data;
                                    $scope.signUpGroup.linkedGroups?.forEach(linkedGroup => linkedGroup.staffMembers = data);

                                    if (selectedPersonIds.length == 0) {
                                        // No staff are selected so reset organiser person id.
                                        $scope.signUpGroup.organiserPersonId == null;
                                    }
                                    else if (typeof $scope.signUpGroup.organiserPersonId === 'undefined' ||
                                        $scope.signUpGroup.organiserPersonId == null ||
                                        $scope.signUpGroup.organiserPersonId == 0) {
                                        // No current lead organiser so default the lead organiser to the first staff member selected.
                                        $scope.signUpGroup.organiserPersonId = selectedPersonIds[0];
                                    }
                                    else {
                                        var leadOrganiser = selectedPersonIds.filter(function (selectedPersonId) {
                                            return selectedPersonId == $scope.signUpGroup.organiserPersonId;
                                        });

                                        if (leadOrganiser.length == 0) {
                                            // Lead organiser no longer in staff list so reset first person in list.
                                            $scope.signUpGroup.organiserPersonId = selectedPersonIds[0];
                                        }
                                    }
                                }).catch(function () { });
                        }.bind(this));
                };

                $scope.changeDay = function () {
                    $scope.changeDayAndOrTime();
                };

                $scope.changeTime = function () {
                    $scope.changeDayAndOrTime();
                };

                $scope.changeDayAndOrTime = function () {
                    if (!$scope.isLive($scope.signUpGroup)) {
                        return;
                    }

                    var changeDayAndOrTimeDialog = $uibModal.open({
                        component: 'ccaChangeDayTimeDialog',
                        size: 'lg',
                        resolve: {
                            signUpGroup: function () { return $scope.signUpGroup; }
                        }
                    });

                    changeDayAndOrTimeDialog.result
                        .then(function (response) {
                            if (response.status == 'updated') {
                                loading++;
                                $scope.loadSignUpGroup();
                            }
                        })
                        .catch(function () {
                            loading--;
                        });
                };
            }
        }]
});
