'use strict';

angular.module('transport.services.transportTimeSlotsService', [])
    .factory('transportTimeSlotsService', [
        '$http', '$cacheFactory',
        function ($http)
        {
            var urlBase = '/webapi/WebOrganisationTransportTimeSlots/';
            var archivingUrlBase = '/webapi/WebOrganisationTransportArchiving/';
            var service = {};

            service.getTransportTimeSlots = function ()
            {
                return $http.get(urlBase + 'GetTransportTimeSlots')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getActiveTransportTimeSlots = function ()
            {
                return $http.get(urlBase + 'GetActiveTransportTimeSlots')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.createTransportTimeSlot = function (transportTimeSlot)
            {
                return $http.post(urlBase + 'CreateTransportTimeSlot', transportTimeSlot)
                    .then(function (response) { return response.data; });
            };

            service.updateTransportTimeSlot = function (transportTimeSlot)
            {
                return $http.put(urlBase + 'UpdateTransportTimeSlot', transportTimeSlot)
                    .then(function (response) { return response.data; });
            };

            service.getTransportTimeSlotArchivingInfo = function (transportTimeSlotId)
            {
                return $http.get(archivingUrlBase + 'GetTransportTimeSlotArchivingInfo', { params: { transportTimeSlotId: transportTimeSlotId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.archiveTimeSlot = function (transportTimeSlot)
            {
                return $http.put(archivingUrlBase + 'ArchiveTimeSlot', { Id: transportTimeSlot.id })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            return service;
        }
    ]);