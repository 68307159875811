'use strict';

angular.module('ccaSignUp.services.manualBookingsService', [])
    .factory('manualBookingsService',
        [
            '$http',
            function ($http) {

                var urlBase = '/webapi/WebManualBookings/';
                var repo = {};

                repo.submitManualBooking = function (request) {

                    return $http.post(urlBase + 'SubmitManualBooking', request)
                        .then(function (response) {
                            return response.data;
                        });
                };

                return repo;
            }
        ]);