angular.module('shared.services.dateTextService', []).service(
    'dateTextService',
    class DateTextService {
        $window: any;

        static $inject = ['$window'];

        constructor($window) {
            this.$window = $window;
        }

        getShortDayText(dayNumber: number) {
            switch (dayNumber) {
                case 1:
                    return 'SB_WeekShortDay1';
                case 2:
                    return 'SB_WeekShortDay2';
                case 3:
                    return 'SB_WeekShortDay3';
                case 4:
                    return 'SB_WeekShortDay4';
                case 5:
                    return 'SB_WeekShortDay5';
                case 6:
                    return 'SB_WeekShortDay6';
                case 0:
                    return 'SB_WeekShortDay0';
                default:
                    return 'SB_WeekShortDay1';
            }
        }

        getLongDayText = function (dayNumber: number) {
            switch (dayNumber) {
                case 1:
                    return 'SB_WeekDay1';
                case 2:
                    return 'SB_WeekDay2';
                case 3:
                    return 'SB_WeekDay3';
                case 4:
                    return 'SB_WeekDay4';
                case 5:
                    return 'SB_WeekDay5';
                case 6:
                    return 'SB_WeekDay6';
                case 0:
                    return 'SB_WeekDay0';
                default:
                    return 'SB_WeekDay1';
            }
        }.bind(this);

        getOrgTimezoneText = (basedOn: Date = new Date()) => {
            const timeZone = this.$window.EveryBuddy.CurrentOrganisationTimeZone;
            const longOffsetFormatter = new Intl.DateTimeFormat('en-US', {
                timeZone,
                timeZoneName: 'longOffset',
            });
            const longOffsetString = longOffsetFormatter.format(basedOn); // parse offset with the date based on, result will be something like '2/28/2013, GMT-05:00'
            const gmtOffset = longOffsetString.split('GMT')[1];
            const result = gmtOffset.replace(':', '');

            return result || '+0000';
        };
    }
);
