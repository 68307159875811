"use strict";

angular.module('receptionModule').component("receptionAllGroupsStaff", {
    templateUrl:
    '/Scripts/app/reception/controllers/groups/reception-allgroups-staff.template.html?v=', // + window.EveryBuddy.version
    bindings: {
        selectedGroup: '<'
    },
    controller:
    [
        '$scope',
        '$state',
        '$filter',
        '$http',
        '$uibModal',
        'receptionService',
        'teamService',
        'personService',
        'personPickerService',
        function ($scope, $state, $filter, $http, $uibModal, receptionService, teamService, personService, personPickerService) {

            this.$onChanges = (changes) => {
                if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                    $scope.selectedGroup = this.selectedGroup;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.selectedGroup.admins = null;

            $scope.$watch('selectedGroup',
                function () {
                    $scope.refreshData();
                });

            $scope.currentPersonId = null;

            personService.getCurrentPersonId()
                .then(function(data) {
                    $scope.currentPersonId = data;
                });

            }

            $scope.canAdd = function () {

                if ($scope.isOrganisationAdmin)
                    return true;

                if ($scope.currentPersonId !== null)
                {
                    // we know they're a mmeber of staff, lets make sure they're admins of this group
                    if ($scope.selectedGroup && $scope.selectedGroup.admins && $scope.selectedGroup.admins.length > 0) {
                        for (var i = $scope.selectedGroup.admins.length; i--;) {
                            if ($scope.selectedGroup.admins[i].id === $scope.currentPersonId) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            };

            $scope.refreshData = function () {
                if ($scope.selectedGroup && $scope.selectedGroup.id) {
                    $scope.loadingStaff = true;
                    receptionService.getStaffInGroup($scope.selectedGroup.id)
                        .then(function (data) {
                            $scope.isOrganisationAdmin = data.isOrganisationAdmin;
                            $scope.selectedGroup.admins = data.admins;
                            $scope.loadingStaff = false;
                        });
                }
            };

            $scope.addMultipleAdmins = function() {
                var selectedIds = [];

                angular.forEach($scope.selectedGroup.admins,
                    function (item) {
                        selectedIds.push(item.id);
                    });

                var modalInstance = personPickerService.openMultiPersonPicker({
                    selectedPersonIds: selectedIds,
                    defaultClubId: 0,
                    defaultTeamId: 0,
                    okButtonText: 'SB_Save',
                    includeStaff: true,
                    includePupils: false,
                    hideGroupFilter: false,
                    hideYearFilter: false,
                    hideGenderFilter: false,
                    hideRemove: false,
                    filterOutExternalStaff: false
                });

                modalInstance.result
                    .then(
                        function (selectedIds) {
                            var pleaseWaitModalInstance = $uibModal.open({
                                animation: $scope.animationsEnabled,
                                backdrop: 'static', // prevent modal from closing on backdrop click
                                templateUrl: '/Scripts/app/shared/controllers/please-wait-popup.template.html',
                                controller: 'pleaseWaitPopupController',
                                size: 'sm',
                                scope: $scope,
                                resolve: {
                                    message: function () { return 'Saving changes, please wait.'; }
                                }
                            });

                            pleaseWaitModalInstance.rendered
                                .then(teamService.postStaffChangesToTeam($scope.selectedGroup.id, selectedIds)
                                    .then(function (data) {
                                        pleaseWaitModalInstance.close();
                                        if (!data.isError) {

                                            // reload team data
                                            $scope.refreshData();

                                        } else {
                                            console.error('problem saving team changes');
                                        }
                                    })
                                ).catch(function (err) {
                                    pleaseWaitModalInstance.close();
                                });
                        },
                        function () {
                            console.log('dialog cancelled');
                        });

            };

            $scope.addAdminGroup = function () {
                $scope.searchType = 3
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "addAdminToGroupModal.html",
                    controller: "ConfirmModalInstanceCtrl",
                    size: "md",
                    scope: $scope
                });

                modalInstance.result.then(function () {
                    $scope.loadingStaff = true;
                    receptionService.addStaff($scope.selectedGroup.id, $scope.personItem.id, 1)
                        .then(function (data) {
                            $scope.isOrganisationAdmin = data.isOrganisationAdmin;
                            $scope.selectedGroup.admins = data.admins;
                            $scope.loadingStaff = false;
                        });
                },
                    function () {
                    });
            }

            $scope.deleteAdminGroup = function (admin) {
                $scope.selectedPerson = admin;
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: "confirmGroupStaffDeleteModal.html",
                    controller: "ConfirmModalInstanceCtrl",
                    size: "sm",
                    scope: $scope

                });

                modalInstance.result.then(function () {
                    $scope.loadingStaff = true;
                    receptionService.removeAdminGroup($scope.selectedGroup.id, $scope.selectedPerson.id)
                        .then(function (data) {
                            $scope.isOrganisationAdmin = data.isOrganisationAdmin;
                            $scope.selectedGroup.admins = data.admins;
                            $scope.loadingStaff = false;
                        });
                },
                    function () {
                    });
            };




            $scope.getMatches = function (val) {
                return $http.get("/Reception/GetPeople",
                    {
                        params: {
                            lastName: val,
                            type: $scope.searchType,
                            includeDeleted: false
                        }
                    }).then(function (response) {
                        return response.data;

                    });
            };

            $scope.onSelect = function ($item, $model, $label) {

                $scope.$item = $item;
                $scope.personItem = $item;
                $scope.$model = $model;
                $scope.$label = $label;
            };
        }
    ]});