'use strict';

angular.module('cca.components.deletePupilConfirmation',
    [
        'balance.constants',
        'events.services.eventsService',
        'person.services.personPickerService',
        'shared.directives.sbCurrencyInput',
        'shared.directives.sbRadioButton',
        'shared.services.simpleAlertService'
    ])
    .component('deletePupilConfirmation',
        {
            bindings: {
                calendarEventId: '<',
                personId: '<',
                isBlock: '<',
                seriesId: '<',
                isDelete: '<',
                onCancel: '&',
                onComplete: '&'
            },
            templateUrl: '/Scripts/app/cca/components/delete-pupil-confirmation.template.html',
            controller: [
                'balanceAdminService',
                'eventsService',
                'simpleAlertService',
                'cachedLookupService',
                function (
                    balanceAdminService,
                    eventsService,
                    simpleAlertService,
                    cachedLookupService) {

                    this.amountToRefund = 0.0;
                    this.description = '';

                    this.totalAvailableToRefund = null;
                    this.charges = [];

                    this.performRefund = false;

                    this.isLoading = true;

                    cachedLookupService.getSystemSetting('CCASignUp_InstantBookDeleteAttendee_RefundSelectedByDefault')
                        .then(function (data) {
                            this.performRefund = data;
                        }.bind(this));

                    this.$onChanges = function (changes) {
                        if (changes.calendarEventId && changes.calendarEventId.currentValue) {

                            var transactionEvent = changes.isBlock.currentValue
                                ? changes.seriesId.currentValue
                                : changes.calendarEventId.currentValue;

                            balanceAdminService.getTransactionsForPersonForSpecificEventAsync(changes.personId.currentValue, transactionEvent)
                                .then(function (charges) {
                                    this.charges = charges;
                                    this.totalAvailableToRefund = 0;

                                    // Calculate total available to refund across all charges.
                                    for (var i = charges.length; i--;) {
                                        this.totalAvailableToRefund += +charges[i].amountAvailableToRefund;
                                    }

                                    this.isLoading = false;

                                }.bind(this))
                                .catch(function (err) {
                                    console.log(err);
                                });
                        }
                    }.bind(this);

                    this.cancel = function () {
                        this.onCancel()();
                    }.bind(this);

                    this.deletePupil = function () {

                        var refundCreditAction = 0; // Nothing
                        if (this.performRefund) {
                            refundCreditAction = 1; // SB_RefundAction_Move_to_Credit_Account
                        }

                        eventsService.removePupilFromEvent(
                            this.personId,
                            this.calendarEventId,
                            false,
                            refundCreditAction)
                            .then(function (data) {
                                console.log(data);
                                this.onComplete()(data);
                            }.bind(this))
                            .catch(function (err) {
                                simpleAlertService.errorAlert();
                                console.error(err);
                            });

                    }.bind(this);

                }
            ]
        });