import { Component, OnInit } from '@angular/core';
import { MenuService } from '@sb-shared/services/menu.service';
import { MainMenuItems } from '@sb-shared/models/UI/menu-item';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sb-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  mainMenuItems: MainMenuItems;

  constructor(private menu: MenuService) { }

  ngOnInit(): void {
    this.menu.getMainMenuItems().pipe(take(1)).subscribe(mainMenuItems => {
      this.mainMenuItems = mainMenuItems.filter(menuItem => menuItem.showOnDashboard);
    });
  }

}
