angular.module('ng1App')
    .component('ng1TeamEventMedical', {
        template: `<team-event-medical team-id="$ctrl.teamId"
                                       calendar-event-id="$ctrl.calendarEventId"
                                       event-title="$ctrl.eventTitle"
                                       show-title-bar="$ctrl.showTitleBar"></team-event-medical>`
    })
    .component('ng1TeamEventGuardians', {
        template: `<team-event-guardians team-id="$ctrl.teamId"
                                         calendar-event-id="$ctrl.calendarEventId"
                                         event-title="$ctrl.eventTitle"
                                         show-title-bar="$ctrl.showTitleBar"></team-event-medical>`
    })