declare var braintree: any;

angular.module('payments.components.paymentFormBraintree', [])
    .component('paymentFormBraintree', {
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
        templateUrl: '/Scripts/app/payments/components/payment-form-braintree.template.html',
        controller: class PaymentFormBraintreeCtrl {

            // Dependencies
            $timeout: any;

            // Bindings
            resolve: any;
            modalInstance: any;

            // Variables
            config: any;
            dropInInstance: any;
            paymentFormSubmitted: boolean;
            isDisabled: boolean;

            static $inject = ['$timeout'];

            constructor($timeout) {
                this.$timeout = $timeout;
            }

            $onInit() {
                this.config = this.resolve.config;

                this.dropInInstance = null;

                this.enableButtons();

                //initialise BrainTree plugin
                braintree.dropin.create({
                    authorization: this.config.key,
                    selector: '#brainTreePlugin',
                    threeDSecure: this.config.threeDSecureEnabled,
                    locale: this.config.languageLocale,
                    paypal: this.payPal()
                }, (err, dropInInstance) => {
                    if (err) {
                        // An error in the create call is likely due to
                        // incorrect configuration values or network issues.
                        // An appropriate error will be shown in the UI.
                        this.enableButtons();
                        return;
                    }

                    this.dropInInstance = dropInInstance;

                });
            }

            enableButtons() {
                this.$timeout(() => {
                    this.paymentFormSubmitted = false;
                    this.isDisabled = false;
                }, 200);
            };

            //return object literal for PayPal Processing if enabled
            payPal() {
                if (!this.config.enablePayPalMethod) {
                    return null;
                };

                return { flow: 'checkout', amount: this.config.paymentAmount, currency: this.config.isoCurrencyCode };
            };

            //return object literal for 3D Secure Processing when enabled
            threeDSecure() {
                if (!this.config.threeDSecureEnabled) { return {} };

                //literal to send to BrainTree for 3D - can be extended. Amount is the bare minimum
                var threeDSecureProperties = {
                    amount: this.config.paymentAmount
                };

                return { threeDSecure: threeDSecureProperties }
            };

            //send back to our server for tokenised payment
            sendNonceToServer(nonce) {
                this.modalInstance.close(nonce);
            };

            paymentWithBrainTree() {
                this.requestPaymentMethod();
            }

            requestPaymentMethod() {
                this.isDisabled = true;

                if (this.dropInInstance === null) {
                    this.enableButtons();
                    return;
                }

                this.dropInInstance.requestPaymentMethod(
                    this.threeDSecure(),
                    (err, payload) => {
                        if (err) {
                            // No payment method is available.
                            // An appropriate error will be shown in the UI.
                            this.enableButtons();
                            return;
                        };

                        if (this.dropInInstance.isPaymentMethodRequestable() && payload.nonce) {
                            this.sendNonceToServer(payload.nonce);
                        }
                        else {
                            this.enableButtons();
                        };
                    });

            }

            cancel() {
                this.modalInstance.dismiss(this.paymentFormSubmitted);
            }
        }
    });
