// start:ng42.barrel
import './cca-cancel-or-delete-events.component';
import './cca-details.component';
import './cca-duplicate-groups.component';
import './cca-generate-additional-events.component';
import './cca-generate-events.component';
import './cca-generate-fees.component';
import './cca-group-allocations.component';
import './cca-group-details.component';
import './cca-group-manage.component';
import './cca-joinrequests.component';
import './cca-location-manage.component';
import './cca-locations.component';
import './cca-pupil-selection.component';
import './cca-pupilsummary.component';
import './cca-reports.component';
import './cca-signup-allocations.component';
import './cca-signup-blockdates.component';
import './cca-signup-groups.component';

// end:ng42.barrel

