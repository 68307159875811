angular.module('tasks.components.taskList',
    [
        'pascalprecht.translate',
        'person.services.staffRepository',

        'shared.components.sbColumnHeader',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'shared.directives.sbCheckBox',
        'shared.services.cachedLookupService',

        'tasks.services.taskService',
    ])
    .component('taskList',
        {
            bindings: {
                showAllTeams: '<',
                teamId: '<',
                updatedTask: '<',
                onSelect: '&',
                selectedTaskId: '<',
                onLoaded: '&'
            },
            templateUrl: '/Scripts/app/tasks/components/task-list.template.html',
            controller: [
                '$filter',
                '$translate',
                'taskService',
                'staffRepository',
                'teamService',
                'cachedLookupService',
                function taskListController($filter, $translate, taskService, staffRepository, teamService, cachedLookupService) {
                    this.loading = 0;


                    this.tasks = [];

                    this.selectedTask = null;
                    this.staffNames = {};
                    this.teamNames = {};
                    var self = this;

                    // setup defaults for sorting
                    this.sortType = 'deadline';
                    this.sortReverse = false;

                    this.hideCompleted = true;
                    this.showAllTeamsSetting = false;
                    this.filterDateFrom = moment.utc().startOf('day').toDate();
                    this.filterDateTo = moment.utc().add(5, 'days').endOf('day').toDate();
                    this.filterDates = false;
                    this.isOrganisationAdmin = false;
                    this.filterOrganiserId = -1;

                    this.isLoading = function () { return self.loading > 0; };

                    this.loading++;

                    cachedLookupService.isOrganisationAdmin()
                        .then(function (data) {
                            self.isOrganisationAdmin = data;
                        });


                    $translate.onReady().then(function () {

                        self.allStaff = [{
                            id: -1,
                            name: $filter('translate')('SB_Anyone')
                        }];

                        staffRepository.getAllStaff()
                            .then(function (data) {
                                self.loading--;

                                for (var i = data.length; i--;) {
                                    self.staffNames[data[i].id] = data[i].ln + ', ' + data[i].fn;

                                    self.allStaff.push({
                                        id: data[i].id,
                                        name: data[i].ln + ', ' + data[i].fn
                                    });
                                }

                                self.staffNames[0] = 'Unassigned';
                            });
                    });

                    this.refreshData = function ()
                    {
                        console.log('refreshing...');
                        var i = 0;

                        self.loading++;

                        self.tasks = [];

                        taskService.getAllTasks({
                            showEveryonesTasks: self.showAllTeamsSetting,
                            hideCompleted: self.hideCompleted,
                            startDate: self.filterDates ? self.filterDateFrom : null,
                            endDate: self.filterDates ? self.filterDateTo : null
                        })
                            .then(function (data) {


                                if (self.filterOrganiserId > 0)
                                {
                                    self.tasks = $filter('filter')(data, { organiserId : self.filterOrganiserId });
                                } else
                                {
                                    self.tasks = data;
                                }

                                for (i = self.tasks.length; i--;) {
                                    self.tasks[i].teamName = self.teamNames[self.tasks[i].teamId];
                                    self.tasks[i].deadline = new Date(self.tasks[i].deadline);
                                }

                                if (self.selectedTaskId) {
                                    for (i = self.tasks.length; i--;) {
                                        if (self.tasks[i].id === self.selectedTaskId) {
                                            self.selectedTask = self.tasks[i];
                                            break;
                                        }
                                    }
                                }

                                if (typeof (self.onLoaded()) === 'function') {
                                    self.onLoaded()();
                                }

                                self.loading--;
                            });
                    };

                    this.$onChanges = function (changes)
                    {
                        var i = 0;

                        if (changes.showAllTeams && changes.showAllTeams.currentValue) {
                            self.loading++;
                            teamService.getAllForLookup(false)
                                .then(function (data) {
                                    for (var i = data.length; i--;) {
                                        self.teamNames[data[i].id] = data[i].name;
                                    }

                                    self.loading--;

                                    self.refreshData();
                                });
                        }

                        if (changes.teamId && changes.teamId.currentValue) {
                            self.loading++;
                            taskService.getAllTasksForCase(changes.teamId.currentValue)
                                .then(function (data) {
                                    self.tasks = data;

                                    for (var i = self.tasks.length; i--;) {
                                        self.tasks[i].deadline = new Date(self.tasks[i].deadline);
                                    }

                                    if (self.selectedTaskId) {
                                        for (i = self.tasks.length; i--;) {
                                            if (self.tasks[i].id === self.selectedTaskId) {
                                                self.selectedTask = self.tasks[i];

                                                break;
                                            }
                                        }
                                    }

                                    if (typeof (self.onLoaded()) === 'function') {
                                        self.onLoaded()();
                                    }

                                    self.loading--;
                                });
                        }

                        if (changes.selectedTaskId && changes.selectedTaskId.currentValue) {
                            for (i = self.tasks.length; i--;) {
                                if (self.tasks[i].id === changes.selectedTaskId.currentValue) {
                                    self.selectedTask = self.tasks[i];
                                    return;
                                }
                            }
                        }

                        if (changes.updatedTask && changes.updatedTask.currentValue) {
                            var task = changes.updatedTask.currentValue;

                            if (task.isDeleted === true)
                            {
                                for (i = self.tasks.length; i--;) {
                                    if (self.tasks[i].id === task.id) {


                                        self.selectTask(self.tasks[i]);
                                        break;
                                    }
                                }

                                // bin off that elemtn
                                self.tasks.splice(i, 1);
                                self.selectTask(self.tasks[0]);
                                return;
                            }

                            for (i = self.tasks.length; i--;) {
                                if (self.tasks[i].id === task.id) {
                                    self.tasks[i].title = task.title;
                                    self.tasks[i].deadline = task.deadline;
                                    self.tasks[i].organiserId = task.organiserId;
                                    self.tasks[i].teamId = task.teamId;
                                    self.tasks[i].completed = task.completed;
                                    self.tasks[i].teamName = self.teamNames[task.teamId];

                                    self.selectTask(self.tasks[i]);
                                    return;
                                }
                            }

                            // this looks like an add- do we have a place holder for this?
                            for (i = self.tasks.length; i--;) {
                                if (self.tasks[i].id === 0) {
                                    self.tasks[i].id = task.id;
                                    self.tasks[i].title = task.title;
                                    self.tasks[i].deadline = task.deadline;
                                    self.tasks[i].organiserId = task.organiserId;
                                    self.tasks[i].teamId = task.teamId;
                                    self.tasks[i].completed = task.completed;
                                    self.tasks[i].teamName = self.teamNames[task.teamId];

                                    self.selectTask(self.tasks[i]);
                                    return;
                                }
                            }


                            // no place holder, just add it to the list
                            var newTask = {
                                id: task.id,
                                title: task.title,
                                deadline: task.deadline,
                                organiserId: task.organiserId,
                                teamId: task.teamId,
                                completed: task.completed,
                                teamName: self.teamNames[task.teamId]
                            };

                            self.tasks.push(newTask);
                            self.selectTask(newTask);
                        }
                    };

                    this.getTaskColour = function (task) {
                        if (self.selectedTask === task)
                            return 'info';

                        if (task.completed)
                            return 'success';

                        var end = moment(task.deadline);
                        var now = moment();
                        var duration = moment.duration(end.diff(now));
                        var days = duration.asDays();

                        if (days < 1)
                            return 'danger';

                        if (days <= 2)
                            return 'warning';

                        return '';
                    };

                    this.selectTask = function (task) {
                        self.selectedTask = task;

                        if (typeof self.onSelect() === 'function') {
                            self.onSelect()(task);
                        }
                    };
                }
            ]
        });