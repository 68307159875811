import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@sb-core/services/toast.service';
import { momentToDate } from '@sb-helpers';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpRequestSettings, apis } from './../models/request-settings';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpWebApiService {

  config: {
    headers: {
      'Content-Type': 'application/json'
    };
  };
  httpMethods = {
    Get: 1,
    Head: 2,
    Post: 3,
    Put: 4,
    Delete: 5,
    Connect: 6,
    Options: 7,
    Trace: 8,
    Patch: 9
  };

  public get currentOrganisationId(): number {
    return this.storage.get('currentOrganisationId') || window.EveryBuddy?.CurrentOrganisationId;
  }

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private toast: ToastService) {
  }

  configObject(settings: HttpRequestSettings) {
    this.convertPropsToDatetimeString(settings?.params);

    if (settings.api.isCore) {
      if (settings?.config) {
        return { ...settings.config, params: settings?.params };
      }

      return { params: settings?.params };
    }

    return { ...settings.config, params: settings.params };
  }

  convertPropsToDatetimeString(obj: unknown) {
    if (!obj) {
      return;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] instanceof Date){
        const val = momentToDate(obj[key]);
  
        if (val instanceof Date && !isNaN(val.getTime())) {
          obj[key] = formatDate(val, 'yyyy-MM-ddTHH:mm:ss', 'en-US');
        }
      }
    });
  }

  get(url: string, settings: HttpRequestSettings): Observable<any> {
    const httpMethod = this.httpMethods.Get;
    const config = this.configObject(settings);
    // Return the endpoint so component can subscribe
    // Handle success/error as side effect
    const request = () => this.http.get(this.formatUrl(url, settings), config)
      .pipe(
        tap({
          complete: () => this.handleSuccess(httpMethod, settings),
          error: (err) => this.handleError(httpMethod, err, settings)
        })
      );
    return this.handleResponse(request(), settings);
  }

  post(url: string, body: unknown, settings: HttpRequestSettings): Observable<any> {
    const httpMethod = this.httpMethods.Post;
    const config = this.configObject(settings);
    const request = () => this.http.post(this.formatUrl(url, settings), body, config)
      .pipe(
        tap({
          complete: () => this.handleSuccess(httpMethod, settings),
          error: (err) => this.handleError(httpMethod, err, settings)
        })
      );
    return this.handleResponse(request(), settings);
  }

  put(url: string, body: unknown, settings: HttpRequestSettings): Observable<any> {
    const httpMethod = this.httpMethods.Put;
    const config = this.configObject(settings);
    const request = () => this.http.put(this.formatUrl(url, settings), body, config)
      .pipe(
        tap({
          complete: () => this.handleSuccess(httpMethod, settings),
          error: (err) => this.handleError(httpMethod, err, settings)
        })
      );
    return this.handleResponse(request(), settings);
  }

  delete(url: string, body: unknown, settings: HttpRequestSettings) {
    const httpMethod = this.httpMethods.Delete;
    const config = this.configObject(settings);
    return this.http.delete(this.formatUrl(url, settings), {
      ...config,
      data: body,
      headers: { ...config.headers, 'Content-Type': 'application/json;charset=utf-8' } // Different formatting for angularJs delete request
    })
      .pipe(
        tap({
          complete: () => this.handleSuccess(httpMethod, settings),
          error: (err) => this.handleError(httpMethod, err, settings)
        })
      );
  }

  patch(url: string, body: unknown, settings: HttpRequestSettings) {
    const httpMethod = this.httpMethods.Patch;
    const config = this.configObject(settings);
    return this.http.patch(this.formatUrl(url, settings), body, config)
      .pipe(
        tap({
          complete: () => this.handleSuccess(httpMethod, settings),
          error: (err) => this.handleError(httpMethod, err, settings)
        })
      );
  }

  formatUrl(url: string, settings: HttpRequestSettings) {
    // Add '/Organisation/' folder if that controller type, plus url params.
    // Might want to change how this works when we have more controller types
    const orgString = settings.controllerType?.hasOrg
      ? `${settings.orgId || this.currentOrganisationId}/`
      : '';

    if (!settings.controllerType?.hasOrg && settings.orgId) {
      settings.params = settings.params || {};
      settings.params.organisationId = settings.orgId;
    }
    if (settings.addHashId) {
      settings.params = { ...settings.params, hash_id: new Date().getTime() };
    }

    const urlBase =
      `${this.getApiUrl(settings)}/${settings.api.baseUrl}/${this.getControllerTypeUrl(settings)}${orgString}`;

    return `${urlBase}${url}`;
  }

  getApiUrl(settings: HttpRequestSettings): string {
    return settings.api.isCore ?
      environment.apiUrl :
      environment.oldApiUrl;
  }

  getControllerTypeUrl(settings: HttpRequestSettings): string {
    return settings.controllerType ?
      `${settings.controllerType.url}/` :
      '';
  }

  removeGenericError(message: string) {
    // In case of a generic error message, pass blank value so appropriate default message is shown, eg 'Error loading'
    return message.replace('Request failed: An unhandled exception occurred.', CommonChars.Blank);
  }

  formatData(data: unknown) {
    // Add created/modified age from created/modified UTC times
    const handleObject = (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(item => {
            this.formatData(item);
          })
        }
        else if (typeof value === 'object') {
          handleObject(value);
        }
        else if (key === 'createdDateTimeUtc') {
          // obj.createdAge = this.dateTimeService.getAgeLabel(value);
        }
        else if (key === 'modifiedDateTimeUtc') {
          // obj.modifiedAge = this.dateTimeService.getAgeLabel(value);
        }
      });
    }
    if (Array.isArray(data)) {
      data.forEach(item => {
        this.formatData(item);
      })
    }
    else if (typeof data === 'object') {
      handleObject(data);
    }
    return data;
  }

  handleResponse(response: Observable<any>, settings: HttpRequestSettings): Observable<any> {
    // Extract data from response, and get from / store to local storage if specified
    const storeName = settings.storeName;
    const doRefresh = settings.doRefresh;
    const storedData = storeName && this.storage.get(storeName);
    if (storedData && !doRefresh) {
      return of(storedData);
    }
    const data = settings.api.isCore && !settings.fullResponse ? response.pipe(map(res => res?.data)) : response;
    return (settings.shareReplay ? data.pipe(shareReplay(settings.shareReplay)) : data)
      .pipe(
        tap(data => {
          if (storeName) {
            this.storage.set(storeName, data);
          }
        }),
        catchError((err) => { return throwError(() => err) })
      )
  }

  handleSuccess(type: number, settings: HttpRequestSettings): void {
    // Hide by default for get, show by default for other, unless specified in settings.showSuccessToast
    const getSuccess = type === this.httpMethods.Get && settings?.showSuccessToast;
    const otherSuccess = type !== this.httpMethods.Get && settings?.showSuccessToast !== false;
    if (getSuccess || otherSuccess) {
      if (settings.successCallback) {
        settings.successCallback();
      } else {
        switch (type) {
          case this.httpMethods.Get:
            this.toast.getSuccess(settings?.successMessage, settings?.successTitle, settings?.successRoute);
            break;
          case this.httpMethods.Post:
            this.toast.saveSuccess(settings?.successMessage, settings?.successTitle, settings?.successRoute);
            break;
          case this.httpMethods.Put:
            this.toast.saveSuccess(settings?.successMessage, settings?.successTitle, settings?.successRoute);
            break;
          case this.httpMethods.Delete:
            this.toast.deleteSuccess(settings?.successMessage, settings?.successTitle, settings?.successRoute);
            break;
          case this.httpMethods.Patch:
            this.toast.saveSuccess(settings?.successMessage, settings?.successTitle, settings?.successRoute);
            break;
          default:
        }
      }
    }
  }

  handleError(type: number, res: HttpErrorResponse, settings: HttpRequestSettings) {
    let messages: string[] = [];
    const { error } = res;

    if (settings?.errorMessage) {
      messages = [settings.errorMessage];
    }
    else if (error?.ModelState) {
      Object.entries(error.ModelState).forEach(([_, value]) => {
        if (Array.isArray(value)) {
          messages.push(value[0]);
        }
      });
    }
    else if (error?.Message) {
      messages = [error.Message];
    }
    else if (error?.Errors && this.isStringArray(error.Errors)) {
      messages = (error.Errors as Array<string>);
    }
    else if (error) {
      const objectEntries: [string, unknown][] = Object.entries(error);
      if (objectEntries.every(([, value]) => this.isStringArray(value))) {
        messages = objectEntries.flatMap(([, value]) => value as Array<string>);
      }
    }

    switch (type) {
      case this.httpMethods.Get:
        messages.forEach(message => this.toast.getError(message));
        break;
      case this.httpMethods.Post:
      case this.httpMethods.Put:
      case this.httpMethods.Delete:
      case this.httpMethods.Patch:
        messages.forEach(message => this.toast.saveError(message));
        break;
      default:
        messages.forEach(message => this.toast.error(message));
    }
  }

  dummyPostResponse(settings?: Partial<HttpRequestSettings>) {
    return of(null).pipe(
      delay(1000),
      tap(() => this.handleSuccess(this.httpMethods.Post, {
        ...settings,
        api: apis.Core
      }))
    )
  }

  private isStringArray(value: unknown): boolean {
    return Array.isArray(value) && value.every(item => typeof item === 'string');
  }
}
