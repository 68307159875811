
angular.module('cca.components.ccaCancelOrDeleteEventsGroupList',
    [])
    .component('ccaCancelOrDeleteEventsGroupList',
        {
            bindings: {
                signUp: '<',
                groups: '<',
                pageSize: '<',
                initialPage: '<',
                sortType: '<',
                sortReverse: '<',
                showPagination: '<',
                loading: '<',
                onPageChanged: '&'
            },
            templateUrl: '/Scripts/app/cca/components/cca-cancel-or-delete-events-group-list.template.html',
            controller: class CcaCancelOrDeleteEventsGroupListCtrl
            {
                currentPage: any = null;
                signUpGroups: any[] = [];
                anyGroupsSelected: boolean = false;
                selectedGroups: any[] = [];
                maxNumberOfPages: number;

                // Bindings
                signUp: any;
                groups: any[];
                pageSize: any;
                initialPage: any;
                sortType: any;
                sortReverse: any;
                showPagination: any;
                loading: any;
                onPageChanged: any;

                $translate: any;
                $uibModal: any;

                dateTextService: any;
                toastService: any;

                static $inject = ['$uibModal', '$translate', '$window', 'dateTextService', 'toastService'];

                constructor($uibModal, $translate, $window, dateTextService, toastService)
                {
                    this.$translate = $translate;
                    this.$uibModal = $uibModal;
                    this.maxNumberOfPages = $window.EveryBuddy.PaginationMaxNumberOfPages;

                    this.dateTextService = dateTextService;
                    this.toastService = toastService;
                };

                $onChanges(changes)
                {
                    if (changes.initialPage)
                    {
                        this.currentPage = this.initialPage;
                    }

                    if (changes.groups?.currentValue)
                    {
                        this.signUpGroups = [];
                        let groups = changes.groups.currentValue;
                        groups.filter((group) =>
                        {
                            let signUpGroup =
                            {
                                id: group.id,
                                ccaSignUpId: group.ccaSignUpId,
                                title: group.title,
                                description: group.description,
                                statusId: group.statusId,
                                organiserName: group.organiserName,
                                genderTitle: group.genderTitle,
                                availabilitySummary: group.availabilitySummary,
                                dayofWeek: group.dayofWeek,
                                linkedGroups: [],
                                ccaStartHour: group.ccaStartHour,
                                ccaStartMin: group.ccaStartMin,
                                ccaEndHour: group.ccaEndHour,
                                ccaEndMin: group.ccaEndMin,
                                location: group.location,
                                isSelected: false
                            };

                            if (group.linkedGroups !== 'undefined')
                            {
                                group.linkedGroups.forEach((linkedGroup, index) =>
                                {
                                    let linkedGroupFullyLoaded =
                                    {
                                        id: linkedGroup.id,
                                        ccaSignUpId: group.ccaSignUpId,
                                        title: group.title,
                                        description: group.description,
                                        statusId: group.statusId,
                                        dayofWeek: linkedGroup.dayofWeek,
                                        ccaStartHour: linkedGroup.ccaStartHour,
                                        ccaStartMin: linkedGroup.ccaStartMin,
                                        ccaEndHour: linkedGroup.ccaEndHour,
                                        ccaEndMin: linkedGroup.ccaEndMin,
                                        location: linkedGroup.location
                                    };

                                    signUpGroup.linkedGroups.push(linkedGroupFullyLoaded);
                                });
                            }

                            this.signUpGroups.push(signUpGroup);
                        });
                    }
                };

                dateText(dayNumber)
                {
                    return this.dateTextService.getShortDayText(dayNumber);
                };

                toggleSelectAllGroups(selectAll)
                {
                    if (selectAll)
                    {
                        this.signUpGroups.filter((group) =>
                        {
                            // TODO check what rules apply for cancel/delete
                            //group.isSelected = group.statusId != 7; // Only select a group if its status is not "Extending".
                            group.isSelected = true;
                        });

                        this.groupSelectionChanged();
                        return;
                    }

                    this.signUpGroups.filter((group) =>
                    {
                        group.isSelected = false;
                    });

                    this.groupSelectionChanged();
                };

                nextClicked()
                {
                    this.selectedGroups = this.signUpGroups.filter((group) =>
                    {
                        return group.isSelected;
                    });

                    if (this.selectedGroups.length == 0)
                    {
                        return;
                    }

                    let cancelOrDeleteEventsDialog = this.$uibModal.open({
                        component: 'ccaCancelOrDeleteEventsDialog',
                        size: 'lg',
                        backdrop: 'static', // prevent modal from closing on backdrop click
                        resolve: {
                            selectedGroups: function () { return this.selectedGroups; }.bind(this)
                        }
                    });

                    cancelOrDeleteEventsDialog.result
                        .then((response) =>
                        {
                            if (response.status == 'confirmed')
                            {
                                this.selectedGroups.filter((group)  =>
                                {
                                    group.isSelected = false;
                                });

                                this.groupSelectionChanged();

                                let message = ''
                                if (response.isCancellation)
                                {
                                    message = response.numberOfEventsCancelledOrDeleted + ' ' + this.$translate.instant('SB_CcaSignUp_message_Events_Cancelled');
                                }
                                else
                                {
                                    message = response.numberOfEventsCancelledOrDeleted + ' ' + this.$translate.instant('SB_CcaSignUp_message_Events_Deleted');
                                }

                                this.toastService.saveSuccess(message);
                            }
                        })
                        .catch((error) =>
                        {
                            this.toastService.error();
                        });
                };

                groupSelectionChanged()
                {
                    this.selectedGroups = this.signUpGroups.filter((group) =>
                    {
                        return group.isSelected;
                    });

                    this.anyGroupsSelected = this.selectedGroups.length > 0;
                };
            }
        });
