import { Injectable } from '@angular/core';
import { apis } from '@sb-shared/models/request-settings';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// There have been changes to this in AngularJs app which will need to be applied
import { DiaryEvent } from 'src/app/diary/models/diary-event';
import { ArrayService } from './array.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BlobStoragePhotoService {

  urlBase = '/WebBlobStoragePhotos/';

  constructor(
    private user: UserService,
    private organisation: OrganisationService,
    private http: HttpWebApiService,
    private array: ArrayService) { }

  getPhotoUrlsForStudents(studentPersonIds: number[]): Observable<any[]> {
    if (!Array.isArray(studentPersonIds) || studentPersonIds.length < 1) {
      return of([]);
    }

    return this.organisation.getCurrentOrganisationId().pipe(switchMap(orgId => {
      return this.user.getCurrentPersonId().pipe(switchMap(personId => {
        const params = {
          id: Array.from(new Set(studentPersonIds)),
          organisationId: orgId,
          currentPersonId: personId
        };
        // TODO: Add back caching
        return this.http
          .get(this.urlBase + 'GetPhotoUrlsForStudents', { api: apis.Web, params: params })
          .pipe(map(response => response?.photosUrlsForStudents));
      }))
    }));
  }

  addStudentPhotoUrlsToArray(events: DiaryEvent[]): Observable<DiaryEvent[]> {
    const personIds = events.filter(event => event.personId > 0).map(event => event.personId);

    return this.getPhotoUrlsForStudents(personIds).pipe(map(photoUrls => {
      if (photoUrls?.length > 0) {
        // Person as any to allow varying photoUrl property
        events.forEach((event: DiaryEvent) => {
          const photoPerson = photoUrls.find((photo) => photo.personId == event.personId);

          if (photoPerson) {
            event.photoUrl = photoPerson.thumbnail;
          }
        });
      }

      return events;
    }));
  }
}
