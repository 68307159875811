import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { formElements } from './../../constants/shared.constant';

@Component({
  selector: 'sb-telephone-input',
  templateUrl: './telephone-input.component.html',
  styleUrls: ['./telephone-input.component.scss']
})
export class TelephoneInputComponent implements OnInit {

  @Input() model: any;
  @Output() modelChange = new EventEmitter<void>();
  @Input() parentId: string;
  @Input() dialCountry: any;
  @Input() fieldId: string;
  @Input() isRequired: boolean;
  @Input() isDisabled: boolean;
  @Input() form: UntypedFormGroup;

  // Variables
  phoneCountryCode: string;
  countries: Array<any>;
  internationalPrefix: string;
  formElements: any;

  constructor() {
    this.formElements = formElements;
  }

  setOptions(options) {
      if (Array.isArray(options) && options.length > 0) {
          this.countries = options;
          this.phoneCountryCode = '';
  
          this.dialCountry = this.dialCountry || '';

          this.model = this.model || '';

          if (!this.dialCountry) {
              this.dialCountry = options[0].id;
          }

          this.onDialCountryChanged();
          this.onChangeNumber();
      }
  }

  ngOnInit(): void {
      this.onChangeNumber()
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.dialCountry && changes.dialCountry.currentValue !== undefined) {
          this.onDialCountryChanged();
      }
      if ((changes.parentId && changes.parentId.currentValue !== undefined) && changes.model?.currentValue === undefined && this.countries?.length > 0) {
          this.dialCountry = this.countries[0].id;
          this.onDialCountryChanged;
      }
      if (changes.model && changes.model.currentValue !== undefined) {
          this.onChangeNumber();
      }
  }

  onDialCountrySelectChanged($event) {
      this.dialCountry = $event.value;
      this.onDialCountryChanged();
  }

  disableDialCountrySelect() {
      return this.model && this.model.substring(0,1) !== this.internationalPrefix;
  }

  onDialCountryChanged() {

      if (this.disableDialCountrySelect()) {
          return;
      }

      if (this.countries && this.dialCountry) {

          setTimeout(() => {

              const newPhoneCountries = this.countries.filter(country => {
                  return country.id === this.dialCountry; 
              });

              if (newPhoneCountries.length === 0) {
                  return;
              }

              const newPhoneCountryCode = newPhoneCountries[0].phoneCountryCode;
              const firstCharacter = this.model.substring(0,1) || '';

              if (this.model && firstCharacter === this.internationalPrefix) {
                  this.model = this.model.replace(firstCharacter + this.phoneCountryCode, firstCharacter + newPhoneCountryCode);
              }
              else {
                  this.model = this.internationalPrefix + newPhoneCountryCode;
              }

              this.phoneCountryCode = newPhoneCountryCode;

          });

      }

  }

  onChangeNumber() {
      if (this.model) {
        setTimeout(() => {
              const firstCharacter = this.model.substring(0,1);

              if (firstCharacter === this.internationalPrefix) {
                  // Check for countries matching inputted dial code
                  const matchedCountries = this.countries.filter(country => {
                      return this.model.replaceAll(this.internationalPrefix, CommonChars.Blank).indexOf(country.phoneCountryCode) == 0;
                  });
                  // If multiple countries with same code
                  if (matchedCountries.length > 1) {
                      const defaultCountries = matchedCountries.filter(country => {
                          return country.isPhoneDefault;
                      })
                      // Set the 'isDefault' option automatically if one exists - eg GB over Isle of Man
                      if (defaultCountries.length > 0) {
                          this.dialCountry = defaultCountries[0].id;
                      // Else just set first matching country
                      } else {
                          this.dialCountry = matchedCountries[0].id;
                      }
                  }
                  // Else just set only matching country
                  else if (matchedCountries.length == 1) {
                      this.dialCountry = matchedCountries[0].id;
                  }
              }
              let model = this.model;
              // If value is just dial code then don't save any value
              const phoneCountryCode = this.countries?.filter(country => {
                return country.id === this.dialCountry;
              })[0].phoneCountryCode || this.phoneCountryCode;
              if (model === this.internationalPrefix + phoneCountryCode) {
                  model = null;
              }
              this.phoneCountryCode = phoneCountryCode;
              this.modelChange.emit(model);
          });
      }
  }
}