angular.module('shared.directives.sbPrint', [])
    .directive('sbPrint', [
        '$window',
        '$sce',
        '$templateRequest',
        '$compile',
        '$timeout',
        'simpleAlertService',
        function ($window, $sce, $templateRequest, $compile, $timeout, simpleAlertService) {

            function controller($scope) {
                $scope.buttonClass = 'btn btn-primary';

                $scope.print = function () {
                    for (var property in $scope.component) {
                        $scope[property] = $scope.component[property];
                    }

                    var templateUrl = $sce.getTrustedResourceUrl(this.printTemplate + '?v=' + $window.EveryBuddy.Version);

                    $templateRequest(templateUrl).then(function (template) {
                        template = '<div>' + template + '</div>';

                        var compiledPrintView = $compile(template)($scope);

                        $timeout(function () {
                            var printerWindow = $window.open('', '', 'width=800, height=600');
                            if (printerWindow === null || printerWindow.document === null) {

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Pop_Up_Blocked',
                                    message: 'SB_In_Order_To_Print_This_You_Need_To_Allow_Popups',
                                    okButtonText: 'SB_OK',
                                    windowSize: 'md'
                                });

                            } else {
                                printerWindow.document.write(compiledPrintView[0].innerHTML);
                            }

                        }, 1000);  // wait for a short while,Until all scope data is loaded If any complex one

                    }, function () {
                        // An error has occurred
                    });
                };

            };

            return {
                restrict: 'E',
                templateUrl: '/Scripts/app/shared/directives/sb-print.template.html',
                scope: {
                    printTemplate: '=',
                    component: '=',
                    buttonClass: '@',
                    isDisabled: '='
                },
                controller: ['$scope', controller]
            };
        }]);
