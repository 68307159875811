angular.module('cca.constants', [])
    .constant('teamStatusEnum', {
        NotStarted: 0,
        Partial: 1,
        Complete: 2,
        OnHold: 3,
        AutoComplete: 4,
        Archive: 5,
        Active: 6,
        Extending: 7
    })
    .constant('ccaSignUpStatusEnum', {
        None: 0,
        Managing: 1,
        Generating: 2,
        Active: 3,
        Archive: 4,
        Processing: 5
    })
    .constant('ccaSignUpResponsibityEnum', {
        None: 0,
        PupilsOrParents: 1,
        ParentsOnly: 2,
        PupilsOnly: 3
    })
    .constant('ccaSignUpDayOfWeek', {
        All:
            { id: -1, label: 'SB_All' }
    })
    .constant('ccaDataProperties', {
        DefaultRefreshTimeout: 25000
    })
    .constant('bookingStatusEnum', {
        Reserved: 1,
        Confirmed: 2,
        Removed: 3,
        Cancelled: 4,
        Deleted: 5,
        TimedOut: 6,
        Processing: 7,
    });