angular.module('settingsModule')
    .service('onboardingService', class OnboardingService {

        checklistItems: { showOnBoarding?: boolean, title: string, description: string, route: string, icon?: { category?: string, type?: string, name: string }, showOnCheckList: boolean, shouldShowOnboarding?: any }[];

        attendanceScanningSettingsService: any;

        static $inject = ['attendanceScanningSettingsService'];

        constructor(attendanceScanningSettingsService) {
            this.attendanceScanningSettingsService = attendanceScanningSettingsService;

            this.checklistItems = [

            {
                title: 'FariaOne',
                description: 'SB_FariaOne_Description',
                route: 'settings.fariaOne',
                icon: {
                    type: 'brands',
                    name: 'FariaOne'
                },
                showOnCheckList: true
            },
            {
                title: 'SB_Welcome_Message',
                description: 'SB_Welcome_Message_Description',
                route: 'settings.onboarding.welcomeMessage',
                icon: {
                    category: 'other',
                    name: '4.In-Line Editing'
                },
                showOnCheckList: true
            },
            {
                title: 'SB_Organisation_Block_Dates',
                description: 'SB_Organisation_Block_Dates_Description',
                route: 'settings.onboarding.blockDates',
                icon: {
                    category: 'features',
                    name: '2.Calendar'
                },
                showOnCheckList: true
            },
            {
                title: 'SB_Locations_Rooms',
                description: 'SB_Locations_Rooms_Description',
                route: 'settings.onboarding.locations',
                icon: {
                    category: 'global_context',
                    name: '4.Time,-place-and-space'
                },
                showOnCheckList: true
            },
            {
                title: 'SB_Staff_Roles',
                description: 'SB_Staff_Roles_Description',
                route: 'settings.staffRoles.staffRolesInternal',
                icon: {
                    category: 'concepts',
                    name: '5.Connections'
                },
                showOnCheckList: true
            },
            {
                title: 'SB_MainSettings_OnlineParentsMeetings_MenuLabel',
                description: 'SB_MainSettings_OnlineParentsMeetings_Description',
                route: 'settings.onboarding.onlineParentMeetings',
                icon: {
                    category: 'features',
                    name: '21.Remote Learning'
                },
                showOnCheckList : false
            },
            {
                title: 'SB_Attendance_Scanning',
                description: 'SB_Attendance_Scanning_Description',
                route: 'settings.onboarding.attendanceScanning',
                showOnCheckList : false,
                shouldShowOnboarding: () => attendanceScanningSettingsService
                    .getTabVisible()
                    .then(result => result.data)
            },
        ]}

        getCheckListItems() {
            return this.checklistItems.filter(item => item.showOnCheckList == true);
        }

        getOnboardingMenuItems() {
            const items =  this.checklistItems.filter(
                item => {
                    return item.route.indexOf('settings.onboarding') > -1;
                }
            );

            return Promise.all(items.map(item => {
                if (item.shouldShowOnboarding === undefined) {
                    item.showOnBoarding = true;
                    return Promise.resolve(item);
                } else {
                    return item.shouldShowOnboarding()
                        .then(result => {
                            item.showOnBoarding = result;
                            return item;
                        });
                }
            }))
                .then((items: any[]) => {
                    return { items: items.filter(item => item.showOnBoarding) };
                });
        };

    });
