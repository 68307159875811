'use strict';

angular.module('transport.services.transportDirectionsService', [])
    .factory('transportDirectionsService', [
        '$http', '$cacheFactory',
        function ($http)
        {
            var urlBase = '/webapi/WebOrganisationTransportDirections/';
            var repository = {};

            repository.getTransportDirections = function ()
            {
                return $http.get(urlBase + 'GetTransportDirections')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            repository.saveTransportDirections = function (transportDirections)
            {
                return $http.post(urlBase + 'SaveTransportDirections', { transportDirections: transportDirections })
                    .then(function (response) { return response.data; });
            };

            return repository;
        }
    ]);