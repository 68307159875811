'use strict';

angular.module('balanceAdmin.components.transactionView',
    [
        'balanceAdmin.services.balanceAdminService',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'shared.directives.sbCheckBox',
        'shared.services.balanceSharedService',
        'shared.services.simpleAlertService'
    ])
    .component('transactionView',
        {
            bindings: {
                accountTypeId: '<',
                accountOwnerId: '<',
                showIncludeSource: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/components/transaction-view.template.html',
            controller: [
                'balanceAdminService',
                'balanceSharedService',
                'simpleAlertService',
                function (
                    balanceAdminService,
                    balanceSharedService,
                    simpleAlertService) {

                    this.loading = 0;
                    this.accountData = null;

                    this.numberOfTransactions = 0;
                    this.showRelatedTransactions = false;
                    this.showSourceTransactions = false;
                    this.fetchedSource = false;

                    this.sortType = 'date';
                    this.sortReverse = true;
                    this.search = {
                        text: '',
                        // this could benefit from being an organisation setting?
                        from: moment().add(-3, 'months').toDate(),
                        to: moment().toDate()
                    };

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    this.fetchSource = function () {
                        // wrap in a digest just to handle changing the model via the label
                        setTimeout(function () {
                            // Only do this once until it is cleared back down.
                            if (!this.showSourceTransactions || this.fetchedSource) {
                                return;
                            }

                            this.tryAndGetTransactions();
                        }.bind(this), 0);
                    }.bind(this);

                    this.tryAndGetTransactions = function () {

                        // make sure we have everything we need...
                        if (!this.accountTypeId || !this.accountOwnerId)
                            return;

                        this.loading++;
                        this.numberOfTransactions = 0;
                        this.accountData = {
                            accountHolderName: '',
                            transactions : []
                        };

                        balanceAdminService.getTransactions(this.accountTypeId,
                                                            this.accountOwnerId,
                                                            this.showSourceTransactions,
                                                            this.search.from,
                                                            this.search.to)
                            .then(function (data) {

                                this.accountData.accountHolderName = data.accountHolderName;

                                var transactions = balanceSharedService
                                    .arrangeTransactionData(data.transactions, data.openingBalance, this.showSourceTransactions, this.accountOwnerId);

                                this.fetchedSource = this.showSourceTransactions;

                                balanceSharedService
                                    .copyTransactionsWhichShouldBeVisible(transactions, this.accountData.transactions, this.search.from, this.search.to);

                                this.numberOfTransactions = this.accountData.transactions.length;

                            }.bind(this))
                            .catch(function (err) {
                                simpleAlertService.errorAlert();
                                console.error(err);
                            }.bind(this))
                            .finally(function () {
                                this.loading--;
                            }.bind(this));

                    }.bind(this);

                    this.customFilter = function (value, index, array) {

                        return value.accountOwnerId === this.accountOwnerId &&
                            (value.description.toLowerCase().indexOf(this.search.text.toLowerCase()) > -1 || (value.teamName && value.teamName.toLowerCase().indexOf(this.search.text.toLowerCase()) > -1));


                    }.bind(this);

                    this.$onChanges = function (changes) {

                        if ((changes.accountTypeId && changes.accountTypeId.currentValue) || (changes.acountOwnerId && changes.acountOwnerId.currentValue)) {
                            this.tryAndGetTransactions();
                        }
                    }.bind(this);
                }
            ]
        });
