import { ConfirmDialogService } from './../../services/confirm-dialog.service';
import { Component, OnInit } from '@angular/core';
import { ConfirmationMessage } from '@sb-shared/models/UI/confirmation';

@Component({
  selector: 'sb-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {  
  message: ConfirmationMessage;
  checkBoxValue = false;
  constructor(  
      private confirmDialog: ConfirmDialogService
  ) { }  

  ngOnInit(): any {  
      /** 
      *   This function waits for a message from alert service, it gets 
      *   triggered when we call this from any other component 
      */  
      this.confirmDialog.getMessage().subscribe(message => {
          this.message = message;  
      });  
  }  
}