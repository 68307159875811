'use strict';

angular.module('teams.components.teamMemberListPopupComponent', [
    'team.directives.teamMemberList',
    'shared.components.sbModalHeader'
]).component('teamMemberListPopupComponent', {
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    templateUrl: '/Scripts/app/teams/components/team-member-list-popup.template.html',
    controller: [
        function () {

            this.$onChanges = function (changes) {
                if (changes.resolve && changes.resolve.currentValue) {
                    this.teamId = changes.resolve.currentValue.teamId;
                    this.teamTitle = changes.resolve.currentValue.teamTitle;
                }
            }.bind(this);

            this.close = function () {
                this.modalInstance.dismiss('cancel');
            }.bind(this);
        }
    ]
});