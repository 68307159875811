angular.module('dashboard.components.portal', [
    'angular-clipboard',
    'pascalprecht.translate',

    'shared.components.sbIcon',
    'shared.components.sbIconImg',
    'shared.components.sbActionMenu',
    'shared.components.subMenu',
    'shared.directives.sbLoading',

    'shared.services.portalService',
    'shared.services.cachedLookupService',
    'shared.services.arrayService',
])
    .component('portal',
        {
            templateUrl: '/Scripts/app/dashboard/components/portal.template.html',
            bindings: {
                isDashboard: '<',
                maxFeatured: '<',
                onLoadStart: '&',
                onLoadEnd: '&'
            },
            controller: class PortalCtrl {

                // Dependencies
                $state: any;
                portalService: any;
                cachedLookupService: any;
                arrayService: any;
                onLoadStart: any;
                onLoadEnd: any;

                // Bindings

                isDashboard: boolean;
                maxFeatured: number;

                // Variables
                menuCategories: any[] = [];
                links: any[] = [];
                featuredLinks: any[] = [];
                limitedFeaturedLinks: any[] = [];
                selectedPage: number;
                isLoading: boolean = true;
                isStaff: boolean;

                static $inject = ['portalService', 'cachedLookupService', 'arrayService'];

                constructor (portalService, cachedLookupService, arrayService)
                {
                    this.portalService = portalService;
                    this.cachedLookupService = cachedLookupService;
                    this.arrayService = arrayService;
                }

                $onInit() {
                    this.onLoadStart();
                    this.cachedLookupService.isStaff()
                    .then(isStaff => {
                        this.isStaff = isStaff;
                    });
                    this.portalService.getLinks().then(res => {
                        if (res.data) {
                            this.links = res.data.map(link => {
                                return {
                                    ...link,
                                    title: link.name,
                                    route: 'portalPage',
                                    routeParams: {
                                        pageId: link.organisationPortalContentId
                                    },
                                    icon: {
                                        name: link.iconName,
                                        fontAwesomeClassName: link.iconFontAwesomeClassName,
                                        imgUrl: link.iconImageUrl
                                    }
                                }
                            });
                            const menuCategories = res.data.map(link => {
                                return {
                                    id: link.menuCategoryId,
                                    name: link.menuCategoryName,
                                    displayOrder: link.menuDisplayOrder
                                }
                            });
                            this.menuCategories = this.arrayService.uniqueBy(menuCategories, group => {return group.id});
                            this.menuCategories.forEach(cat => {
                                cat.links = this.links
                                    .filter(link => {return link.menuCategoryId === cat.id})
                                    .sort((a, b) => (a.name > b.name) ? 1 : -1);
                            })
                            const sortFeatured = (a, b) => {
                                if (a.catOrder > b.catOrder) return 1;
                                if (a.catOrder < b.catOrder) return -1;
                                if (a.name > b.name) return 1;
                                if (a.name < b.name) return -1;
                                return -1;
                            }
                            this.featuredLinks = this.links
                                .map(link => { return {...link, catOrder: this.menuCategories.find(cat => cat.id === link.menuCategoryId).displayOrder} })
                                .filter(link => {return link.isFeatured})
                                .sort((a, b) => {return sortFeatured(a,b)})
                            this.isLoading = false;
                            this.limitFeaturedLinks();
                            this.onLoadEnd({display: this.featuredLinks.length > 0});
                        }
                        else {
                            this.isLoading = false;
                            this.onLoadEnd({display: false});
                        }
                    })
                }

                $onChanges(changes) {
                    if (changes.maxFeatured?.currentValue) {
                        this.limitFeaturedLinks();
                    }
                }

                limitFeaturedLinks() {
                    this.limitedFeaturedLinks = this.maxFeatured ? this.featuredLinks.slice(0, this.maxFeatured) : this.featuredLinks;
                }
            }
        });
