'use strict';

angular.module('shared.components.sbModalHeader', [
    'ui.bootstrap',
]).component('sbModalHeader',
    {
        bindings: {
            modalTitle: '<',
            modalTitleXs: '<',
            detail: '<',
            headerClass: '@',
            badgeText: '<',
            badgeClass: '<',
            badgeIcon: '<',
            isCloseHidden: '<',
            isCloseDisabled: '<',
            iClass: '<',
            fullPageOnApp: '<',
            onClose: '&'
        },
        templateUrl: '/Scripts/app/shared/components/sb-modal-header.template.html',
    });
