'use strict';

angular.module('shared.components.sbUpsell', [
]).component('sbUpsell', {
    templateUrl: '/Scripts/app/shared/components/sb-upsell.template.html',
    bindings: {
        upsellContent : '<',
    },
    controller: [
        '$sce',
        function sbUpsellController($sce) {

            this.$onInit = function () {

                this.templateContent = '';

                if (this.upsellContent) {
                    this.templateContent = $sce.trustAsHtml(this.upsellContent);
                }
            };
        }
    ]
});