import { Injectable } from '@angular/core';
import { Organisation } from '@sb-shared/models/organisation';
import { UserUi } from '@sb-shared/models/user-ui';
import { forkJoin, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganisationService } from './organisation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {

  constructor(
    private organisationService: OrganisationService,
    private userService: UserService) { }

  verifyRegistrationIncomplete(): Observable<boolean> {
    return forkJoin([
      this.organisationService.getCurrentOrganisation(),
      this.userService.getCurrentUser(true)
    ]).pipe(
      map(([currentOrganisation, currentUser]) => this.isRegistrationIncomplete(currentOrganisation, currentUser)),
    );
  }

  redirectToCompleteRegistration() {
    window.location.href = environment.oldApiUrl + '/Dashboard/CompleteRegistration';
  }

  private isRegistrationIncomplete(organisation: Organisation, user: UserUi) {
    return organisation.allowExternalRegistration && !user.hasParentAccess && !user.hasStaffAccess && !user.hasStudentAccess && !user.isExternal;
  }
}

