import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  createAbsoluteUrl(url: string) : string {
    try {
      new URL(url);
      return url;
    }
    catch (e: unknown) {
      if (e instanceof TypeError) {
        const urlPrefix = 'https://';
        url = `${urlPrefix}${url}`;
        return url;
      }
      return null;
    }
  }
}
