import { Output, EventEmitter } from '@angular/core';
import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-multi-person-picker' })
export class MultiPersonPickerUpgrade extends UpgradeComponent {
  @Input() selectedPersonIds: number[];
  @Output() selectedPersonIdsChange: EventEmitter<void> = new EventEmitter();
  @Input() showOkButton: boolean;
  @Input() includePupils: boolean;
  @Input() includeStaff: boolean;
  @Input() includeParents: boolean;
  @Input() hideGroupFilter: boolean;
  @Input() hideYearFilter: boolean;
  @Input() lockedPersonId: number;
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1SbMultiPersonPicker', elementRef, injector);
  }
}