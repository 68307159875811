// PLEASE KEEP ALPHABETICAL
export const OrganisationLabelTags = {
  ActionRequired: 'SB_Action_Required',
  Cancelled: 'SB_Canceled',
  Deleted: 'SB_Deleted',
  Diary: 'SB_Diary',
  EventCallEnded: 'SB_Event_Call_Ended',
  EventCallJoin: 'SB_Event_Call_Join',
  Hide: 'SB_Hide',
  Invitations: 'SB_Invitations',
  InvitesAndAvailabilityAwaitingResponse: 'SB_You_Have_Invites_And_Availability_Requests_Awaiting_A_Response',
  MeetingTime: 'SB_Meet_Time',
  More: 'SB_More',
  NoEventsToday: 'SB_No_Events_Today',
  None: 'SB_None',
  NotStarted: 'SB_Not_Started',
  Organisations: 'SB_Organisations',
  RequestToJoinAvailable: 'SB_Request_To_Join_Available',
  SignUpCloses: 'SB_Sign_up_closes',
  SignUpClosesX: 'SB_Sign_Up_Closes_X',
  Starts: 'SB_Starts',
  Switch: 'SB_Switch',
  ViewAll: 'SB_View_All',
  ViewAllX: 'SB_View_All_X'
} as const;
