// start:ng42.barrel
import './allMessages.service';
import './components';
import './controllers';
import './directives';
import './messageViewing.service';
import './messaging.constants';
import './messaging.service';
// end:ng42.barrel

