// Copied from AngularJS app - need moving to separate files

import { EventTabId } from '@sb-events/enums/event-tab';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { Colours } from '@sb-shared/models/colours';
import { addDays, addMonths, addYears, endOfMonth, startOfMonth, subDays, subMonths, subYears } from 'date-fns';

export enum searchFilterFormats {
  Text = 1,
  Date = 2
}

export const searchFilterTypes = {
  TextSearch: { id: 'textSearch', format: searchFilterFormats.Text, defaultPlaceholder: 'SB_Search' },
  TextFilter: { id: 'textFilter', format: searchFilterFormats.Text, defaultPlaceholder: 'SB_Search' }, // Amended for Angular 12
  DateRange: { id: 'dateRange', format: searchFilterFormats.Date, defaultPlaceholder: 'SB_Select_Dates', defaultLabel: 'SB_Date_Range' },
  DateSingle: { id: 'dateSingle', format: searchFilterFormats.Date, defaultPlaceholder: 'SB_Select_Date', defaultLabel: 'SB_Date' },
  FiltersOnly: { id: 'filtersOnly' },
  FiltersOnlySearch: { id: 'filtersOnlySearch' }
}

export interface SearchFilterType {
  id: string;
  format: number;
  defaultPlaceholder: string;
  defaultLabel?: string;
}

const timeNow = Date.now();

export const dateRanges = [
  { id: 't', label: 'SB_Today', range: [timeNow, timeNow] },
  { id: 'n1', label: 'SB_Tomorrow', range: [addDays(timeNow, 1), addDays(timeNow, 1)] },
  { id: 'l1', label: 'SB_Yesterday', range: [subDays(timeNow, 1), subDays(timeNow, 1)] },
  { id: 'n7', label: 'SB_Next_7_Days', range: [timeNow, addDays(timeNow, 6)] },
  { id: 'l7', label: 'SB_Last_7_Days', range: [subDays(timeNow, 6), timeNow] },
  { id: 'n30', label: 'SB_Next_30_Days', range: [timeNow, addDays(timeNow, 29)] },
  { id: 'l30', label: 'SB_Last_30_Days', range: [subDays(timeNow, 29), timeNow] },
  { id: 'n60', label: 'SB_Next_60_Days', range: [timeNow, addDays(timeNow, 59)] },
  { id: 'l60', label: 'SB_Last_60_Days', range: [subDays(timeNow, 59), timeNow] },
  { id: 'tM', label: 'SB_This_Month', range: [startOfMonth(timeNow), endOfMonth(timeNow)] },
  { id: 'pM', label: 'SB_Previous_Month', range: [startOfMonth(subMonths(timeNow, 1)), endOfMonth(subMonths(timeNow, 1))] },
  { id: 'n90', label: 'SB_Next_90_Days', range: [timeNow, addDays(timeNow, 89)] },
  { id: 'l90', label: 'SB_Last_90_Days', range: [subDays(timeNow, 89), timeNow] },
  { id: 'l6M', label: 'SB_Last_6_Months', range: [subMonths(timeNow, 6), timeNow] },
  { id: 'nM', label: 'SB_Next_Month', range: [timeNow, addMonths(timeNow, 1)] },
  { id: 'nY', label: 'SB_Next_Year', range: [timeNow, addYears(timeNow, 1)] },
  { id: 'lY', label: 'SB_Last_Year', range: [subYears(timeNow, 1), timeNow] },
  { id: 'l3MnY', label: 'SB_Last_3_Months_To_1_Year_Forward', range: [subMonths(timeNow, 3), addYears(timeNow, 1)] },
  { id: 'l3YnY', label: 'SB_Last_3_Years_To_1_Year_Forward', range: [subYears(timeNow, 3), addYears(timeNow, 1)] }
]

export interface DateRange {
  id: string;
  label: string;
  range: Date[];
}

export const dateRangeLists = {
  NextThreeMonths: { id: 1, ranges: ['t', 'n1', 'n7', 'n30', 'n60', 'n90'], showCustom: true },
  LastThreeMonths: { id: 2, ranges: ['t', 'l1', 'l7', 'l30', 'l60', 'l90'], showCustom: true },
  Long: { id: 3, ranges: ['t', 'n1', 'n7', 'n30', 'n60', 'n90', 'nY', 'l1', 'l7', 'l3MnY'], showCustom: true },
  LastYear: { id: 4, ranges: ['l7', 'l30', 'l90', 'l6M', 'lY'], showCustom: false },
  LastThreeYearsNextYear: { id: 5, ranges: ['l3YnY'], showCustom: true },
  NextYear: { id: 6, ranges: ['nM', 'nY'], showCustom: true },
}

export interface DateRangeList {
  id: number;
  ranges: string[];
  showCustom: boolean;
}

export enum creditTypeEnum {
  None = 0,
  Cancellation = 1,
  Correction = 2,
  WriteOff = 3,
  Discount = 50
}


export const dateRangePickerKeys = ['startDate', 'endDate'];

export const formElements = {
  Text: { id: 1, isText: true, typeLabel: 'text', maxlength: 250 },
  Number: { id: 2, isText: true, typeLabel: 'number', max: 2147483647 },
  Date: { id: 3, isDatePicker: true, isSingleDate: true, hasDate: true, manualValidation: true },
  Switch: { id: 4, manualValidation: true },
  Email: {
    id: 5,
    isText: true,
    typeLabel: 'email',
    // pattern: new RegExp('^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$', 'i'),
    patternErrorMessage: 'This must be a valid email address'
  },
  Content: { id: 6 },
  Select: { id: 7, isMultiSelectComponent: true, manualValidation: true, isSelect: true },
  Tel: { id: 8, typeLabel: 'tel' },
  Url: {
    id: 9,
    isText: true,
    typeLabel: 'url',
    pattern: new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'),
    patternErrorMessage: 'This must be a valid web url'
  },
  Twitter: { id: 10, isText: true, typeLabel: 'text', addon: '@' },
  Country: { id: 11, isVisualSelect: true },
  Colours: {
    id: 12,
    manualValidation: true,
    secondaryKeys: ['primaryColour', 'secondaryColour'],
    mappingFunc: (colours: Colours) => {
      Object.keys(colours).forEach(key => {
        colours[key] = colours[key].replace(CommonChars.Hash, CommonChars.Blank);
      });
      return colours;
    },
  },
  SubHeading: { id: 13, isDecorative: true },
  VisualSelect: { id: 14, isVisualSelect: true, isSelect: true },
  CheckboxList: { id: 15 },
  Paragraph: { id: 16, isDecorative: true },
  Data: { id: 17, isDecorative: true },
  Icon: { id: 18, isVisualSelect: true },
  Button: { id: 19, isDecorative: true },
  Hidden: { id: 29, isDecorative: true },
  ButtonGroup: { id: 21 },
  Users: { id: 22, manualValidation: true, includesLabel: true },
  RecordType: { id: 23 },
  DateRange: { id: 24, isDatePicker: true, hasDate: true, manualValidation: true, secondaryKeys: dateRangePickerKeys },
  DateTime: { id: 25, isDatePicker: true, isSingleDate: true, hasTime: true, hasDate: true, manualValidation: true },
  DateTimeRange: { id: 26, isDatePicker: true, hasTime: true, hasDate: true, manualValidation: true, secondaryKeys: dateRangePickerKeys },
  Time: { id: 27 },
  // timeRange: { id: 25, isDatePicker: true, hasTime: true },
  SearchAdd: { id: 26, manualValidation: true, isMultiSelectComponent: true },
  GameSummary: { id: 27, isDecorative: true, secondaryKeys: ['name', 'kitStyleId', 'colours'], reverseSecondaryKeys: true },
  Currency: { id: 28 },
  UserGroupings: { id: 29, manualValidation: true, secondaryKeys: ["clubIds", "teamIds", "subjectClassIds"] },
  Summary: { id: 30 },
  Password: { id: 31, isText: true, typeLabel: 'password' },
  SimpleSelect: { id: 32, isSelect: true },
  MetaTable: { id: 33 },
  Badge: { id: 34, isReadOnly: true },
  Readonly: { id: 35, isReadOnly: true },
  Alert: { id: 36, isDecorative: true },
  Arrow: { id: 37, isDecorative: true },
  Kit: { id: 38, isDecorative: true },
  IconList: { id: 39 }
};

export const SelectAllValue = -1;

export const ConfirmationTab = {
  id: EventTabId.Confirmation,
  formGroupName: 'confirmation',
  name: 'SB_Confirmation',
  iconName: 'checkCircle',
  isConfirmation: true,
  fields: []
}

export enum AvatarButtonAction {
  None,
  ProfilePopup,
  CustomLink // action handled by container, still shows pointer cursor.
}

/** same with backend regexp used for deleted user */
export const DELETED_DUMMY_REGEX = new RegExp(/_deleted\d*.dummy/i);
