angular.module('shared.services.toastService', [
    'ngToast',
    'shared.components.sbIcon'])
.service('toastService', class ToastService {

    $filter: any;
    ngToast: any;
    successTimeout: number = 5000;
    errorTimeout: number = 8000;

    static $inject = ['$filter', 'ngToast'];

    constructor($filter, ngToast) {
        this.$filter = $filter;
        this.ngToast = ngToast;
    }

    // Position is set in module where toast is used

    getError(customText) {
        this.ngToast.danger({
            content: this.$filter('translate')(customText || 'SB_Error_Loading_Data'),
            dismissButton: true,
            timeout: this.errorTimeout
        });
    }

    saveSuccess(customText, params) {
        this.ngToast.success({
            content: this.$filter('translate')(customText || 'SB_Saved', params),
            dismissButton: false,
            timeout: this.successTimeout
        });
    }

    deleteSuccess(customText) {
        this.ngToast.success({
            content: this.$filter('translate')(customText || 'SB_Deleted'),
            dismissButton: false,
            timeout: this.successTimeout,
            additionalClasses: 'toast-delete'
        });
    }

    saveError(customText) {
        this.ngToast.danger({
            content: this.$filter('translate')(customText || 'SB_Error_Saving'),
            dismissButton: true,
            timeout: this.errorTimeout
        });
    }

    error(customText) {
        this.ngToast.danger({
            content: this.$filter('translate')(customText || 'SB_Error_Generic'),
            dismissButton: true,
            timeout: this.errorTimeout
        });
    }

    emailSent(customText) {
        this.ngToast.info({
            content: this.$filter('translate')(customText || 'SB_Email_Sent'),
            dismissButton: false,
            timeout: this.successTimeout,
            additionalClasses: 'toast-email'
        });
    }

    chat(customText) {
        this.ngToast.info({
            content: this.$filter('translate')(customText),
            dismissButton: false,
            timeout: 3500,
            additionalClasses: 'toast-chat'
        });
    }

    warning(customText: string) {
        this.ngToast.warning({
            content: this.$filter('translate')(customText),
            dismissButton: false,
            timeout: 3500
        });
    }
})
