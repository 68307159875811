angular
    .module('transport.services.transportPermissionToWalkService', ['shared.services.httpCoreApi'])
    .factory('transportPermissionToWalkService', [
        'httpCoreApi',
        function (httpCoreApi) {

            const permissionBase = 'Student/Transport/Permission';
            const permissionConfig = {
                controllerType: httpCoreApi.controllerTypes.User
            };

            var service = {};

            service.getStudentWalkPermissions = () => {
                return httpCoreApi.get(permissionBase, permissionConfig);
            };

            service.updateStudentWalkPermissions = data => {
                return httpCoreApi.post(permissionBase + '/ToWalk', data, permissionConfig);
            };

            service.getStudentWalkHistory = () => {
                return httpCoreApi.get(permissionBase + '/Audit', permissionConfig);
            };

            return service;
        }
    ]);