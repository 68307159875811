"use strict";

angular.module('receptionModule').component("receptionAllGroups", {
    templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        onSelectGroup: '&',
        onGroupUpdated: '&',
    },
    controller:
    [
        '$scope',
        '$state',
        '$window',
        '$compile',
        '$timeout',
        '$uibModal',
        '$q',
        'receptionService',
        'organisationService',
        'teamService',
        'iconService',
        'groupService',
        function ($scope, $state, $window, $compile, $timeout, $uibModal, $q, receptionService, organisationService, teamService,  iconService, groupService) {
            $scope.includeArchived = false;
            $scope.myGroups = true;
            $scope.following = false;
            $scope.showSportsPortal = false;

            $scope.years = null;
            $scope.activities = null;
            $scope.genders = null;
            $scope.allowComms = true;
            $scope.isLoadingGroup = false;
            var self = this;

            $scope.loadGroupDetails = function (groupId) {
                $scope.isLoadingGroup = true;
                return groupService.getGroupDetails(groupId)
                    .then(function (data) {
                        $scope.details = data;
                        $scope.allowComms = data.isGroupAdmin;
                        $scope.isLoadingGroup = false;
                        return data;
                    })
                    .catch(function (error) {
                        console.error("An error occured loading group detail", error);
                        $scope.isLoadingGroup = false;
                        return error;
                    });
            };

            // Timeout for this to work in context of Angular 12 app
            setTimeout(() => {
                if ($state.params.groupId && !$scope.selectedGroup && !$scope.details) {
                    $scope.selectedGroup = {};
                    $scope.details = {};

                    $scope.loadGroupDetails($state.params.groupId);
                }
            });

            $scope.menuItems = [];

            $scope.selectedSubTab = 0;

            $scope.$on('group-updated', function () {
                $scope.refreshGroups();
                self.onGroupUpdated();
            });

            $scope.searchFilters = {
                name: ''
            };

            $scope.groupDisplayMode = $window.EveryBuddy.Enums.GroupDetailEditorDisplayMode.School;

            $scope.$watch('isStatus365', function (newValue, oldValue) {
                $scope.groupDisplayMode = $scope.isStatus365
                    ? $window.EveryBuddy.Enums.GroupDetailEditorDisplayMode.Status365
                    : $window.EveryBuddy.Enums.GroupDetailEditorDisplayMode.School;

                if (newValue === true)
                {
                    $scope.menuItems.push({
                        uisref: '.specificGroup.documentStore',
                        title: 'SB_Documents',
                        icon: iconService.getIcon('documents'),
                        sortOrder: 40
                    });

                    $scope.menuItems.push({
                        uisref: '.specificGroup.tasks',
                        title: 'SB_Tasks',
                        icon: iconService.getIcon('tasks'),
                        sortOrder: 41
                    });
                }
            });

            receptionService.getGroupLookupData().then(function (data) {
                $scope.isOrganisationAdmin = data.isOrganisationAdmin;
                $scope.clubs = data.clubs;
                $scope.sports = data.sports;
                $scope.locations = data.locations;
            });

            teamService.getTabVisibility()
                .then(function (tabVisibility) {
                    $scope.tabVisibility = tabVisibility;

                    if (tabVisibility.WhatsNext) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.whatsNext',
                            title: 'SB_Whats_Next',
                            icon: iconService.getIcon('reggroups'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Pupils) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.pupils',
                            title: 'SB_Pupils',
                            icon: iconService.getIcon('users'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Staff) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.staff',
                            title: 'SB_Staff',
                            icon: iconService.getIcon('staff'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Diary) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.diary',
                            title: 'SB_Diary',
                            icon: iconService.getIcon('diary'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.JoinRequests) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.joinrequests',
                            title: 'SB_Join_Requests',
                            icon: iconService.getIcon('joinrequests'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Attendance) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.attendance',
                            title: 'SB_Atendance',
                            icon: iconService.getIcon('attendance'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.MessageCenter) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.messageCenter',
                            title: 'SB_Message_Centre_Groups',
                            icon: iconService.getIcon('messageCenter'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Guardians) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.guardians',
                            title: 'SB_Guardians',
                            icon: iconService.getIcon('guardians'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Medical) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.medical',
                            title: 'SB_Medical',
                            icon: iconService.getIcon('medical'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.FormTags) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.formtags',
                            title: 'SB_Tagged_Data',
                            icon: iconService.getIcon('formtags'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.Notes) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.notes',
                            title: 'SB_Notes',
                            icon: iconService.getIcon('notes'),
                            sortOrder: $scope.menuItems.length
                        });
                    }

                    if (tabVisibility.SendCommunication && $scope.allowComms)
                    {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.comms',
                            title: 'SB_Send_Communication',
                            icon: iconService.getIcon('sendCommunications'),
                            sortOrder: $scope.menuItems.length
                        });
                    }


                    if (tabVisibility.Details) {
                        $scope.menuItems.push({
                            uisref: '.specificGroup.groupdetails',
                            title: 'SB_Edit',
                            icon: iconService.getIcon('groupdetail'),
                            sortOrder: $scope.menuItems.length
                        });
                    }
                });

            var teamDialogOpen = false;
            var openAddTeamDialog = function () {

                if ($scope.years === null || $scope.activities === null || $scope.genders === null)
                    return;

                if (teamDialogOpen)
                    return;

                teamDialogOpen = true;

                // pop the dialogue
                $scope.addModalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "addTeam.html",
                    controller: "AddTeamModalInstanceCtrl",
                    size: "lg",
                    scope: $scope
                });

                $scope.addModalInstance.result
                    .then(
                    function () {
                        // ok
                        teamDialogOpen = false;
                        console.log('closing popup');

                    },
                    function () {
                        // dismiss
                        teamDialogOpen = false;
                        console.log('dismissed popup');
                    });
            };

            $scope.handleNewGroupAdded = function (data) {
                $scope.addModalInstance.close();

                var newGroup = {
                    id: data.id,
                    name: data.title,
                    clubName: '',
                    schoolYearGroupId: 0,
                    clubId: data.clubId,
                    statusId: data.statusId,
                    isMyGroup: true,
                    dayOfWeek: null,
                    startTime: null,
                    createdDate: data.createDate
                };

                $scope.allGroups.push(newGroup);

                $scope.showGroupDetails(newGroup);

            };

            $scope.addTask = function () {
                // pop the dialogue

                $scope.addTaskDialog = $uibModal.open({
                    animation: true,
                    templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-add-task-popup.template.html',
                    controller: 'addTaskPopupController',
                    size: "lg",
                    scope: $scope,
                    resolve: {
                        taskId: function() { return 0; },
                        teamId: function () { return $scope.selectedGroup.id; },
                        teamName: function() { return $scope.selectedGroup.name; }
                    }
                });

                $scope.addTaskDialog.result
                    .then(
                    function (newTask)
                    {
                        $scope.updatedTask = newTask;
                    })
                    .catch(function () {
                        console.log('dismissed popup');
                    });
            };

            $scope.addTeam = function () {

                if ($scope.years === null) {
                    organisationService
                        .getYears()
                        .then(function (data) {
                            $scope.years = data;
                            openAddTeamDialog();
                        });
                }

                if ($scope.activities === null) {
                    organisationService
                        .getActivities()
                        .then(function (data) {
                            $scope.activities = data;
                            openAddTeamDialog();
                        });
                }

                if ($scope.genders === null) {
                    $scope.genders =
                        organisationService.getGenders();
                    openAddTeamDialog();
                }

                openAddTeamDialog();
            };

            $scope.refreshGroups = function () {

                var deferred = $q.defer();

                receptionService.getAllGroups($scope.includeArchived, $scope.myGroups, $scope.following, $scope.showSportsPortal)
                    .then(function (data) {
                        $scope.allGroups = data;

                            console.log('reception-allgroups-controller/refreshgroups/$state.params.groupId',
                                $state.params.groupId);

                        if ($state.params.groupId) {
                            var groupId = parseInt($state.params.groupId);
                            for (var i = $scope.allGroups.length; i--;) {
                                if ($scope.allGroups[i].id === groupId)
                                {
                                    $scope.showGroupDetails($scope.allGroups[i], true);
                                    break;
                                }
                            }
                        }

                        // a bit hacky (sorry) but this triggers a bit of jquery (eek!) which resizes the message list
                        $timeout(function () {
                            //$window.triggerResize();
                        });

                        deferred.resolve();
                    },
                    function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            };

            $scope.refreshGroups();

            $scope.showGroupDetails = (aGroup, fromCode) =>
            {
                this.onSelectGroup({group: aGroup});

                if (!fromCode) {
                    // Amended for Angular 12
                    $state.go("dashboard.allgroups.specificGroup.events", { groupId: aGroup.id });
                }

                $scope.loadGroupDetails(aGroup.id)
                    .then((data) => {
                        $scope.selectedGroup = aGroup;
                        $scope.selectedGroup.details = data;
                        $scope.selectedGroupId = $scope.selectedGroup.id;
                    });
            };

            $scope.toggleFollow = function () {

                $scope.selectedGroup.details.isFavourite = !$scope.selectedGroup.details.isFavourite;

                receptionService.toggleFollow($scope.selectedGroup.id, $scope.selectedGroup.details.isFavourite ? 1 : 0)
                    .then(function (data) {
                    });

            };

            $scope.setTabSelected = function (tabId) {
                $scope.selectedSubTab = parseInt(tabId);
            };
            $scope.isTabSelected = function (tabId) {
                return $scope.selectedSubTab === parseInt(tabId);
            };

            $scope.printIt = function () {
                var table =
                    "<div><h1>{{selectedRegGroup.name}}</h1>" +
                    "<table  border='1' style='border-collapse:collapse;   width='100%'><thead><tr><th ng-bind-html=\"'SB_Name' | translate\"></th><th ng-bind-html=\"'SB_Time' | translate\"></th><th ng-bind-html=\"'SB_Group' | translate\"></th><th ng-bind-html=\"'SB_Location' | translate\"></th></tr></thead>" +
                    "<tbody><tr ng-repeat='anAttendant in selectedRegGroup.members'><td>{{anAttendant.personName}}</td><td>{{anAttendant.eventTime | date: 'dd/MMM HH:mm'}}</td><td>{{anAttendant.teamName}}</td><td>{{anAttendant.location}}</td></tr></tbody></table></div>";
                var compiledHTML = $compile(table)($scope);
                $timeout(function () {
                    var myWindow = $window.open("", "", "width=800, height=600");
                    myWindow.document.write(compiledHTML[0].innerHTML);
                    myWindow.print();
                },
                    300);
            };

            $scope.sortType = "name";
            $scope.sortReverse = false;
        }
    ]});

angular.module('receptionModule').controller("AddTeamModalInstanceCtrl", [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
    }
]);
