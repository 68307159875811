'use strict';

angular.module('emailTemplates.components.templateTypeList', [
    'emailTemplates.services.emailTemplatesService'
]).component('templateTypeList', {
    bindings: {
        onSelectTemplate: '&'
    },
    templateUrl: '/Scripts/app/emailTemplates/components/template-type-list.template.html',
    controller: [
        'simpleAlertService',
        'emailTemplatesService',
        function templateTypeListController(simpleAlertService, emailTemplatesService) {
            var self = this;

            this.selectedTemplateTypeId = null;

            emailTemplatesService.getCommunicationTemplateTypes().then(function (data) {
                self.communicationTemplateTypes = data;
            }, function () {
                simpleAlertService.errorAlert();
            });

            this.selectTemplate = function (template) {
                self.selectedTemplateTypeId = template.communicationTemplateItemId;
                if (this.onSelectTemplate && typeof (this.onSelectTemplate()) === 'function') {
                    self.onSelectTemplate()(template);
                }
            };
        }
    ]
});