'use strict';

angular.module('profile.components.singleSignOn', [])
    .component('singleSignOn', {
        bindings: {
            emailAddress: '<',
            hasSso: '<',
        },
        templateUrl: '/Scripts/app/profile/components/single-sign-on.template.html',
        controller: [
            function () { }
        ]
    });