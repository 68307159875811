import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sb-current-user-transaction-view',
  templateUrl: './current-user-transaction-view.component.html',
  styleUrls: ['./current-user-transaction-view.component.scss']
})
export class CurrentUserTransactionViewComponent {

  constructor(private modal: NgbModal) {
  
  }
@Input() public accountTypeId: number;

close()
{
  this.modal.dismissAll();
}
}
