'use strict';

angular.module('ccasignupModule').component('ccaAddPupilConfirmation', {
        bindings: {
            organisation: '<'
        },
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-addpupil-confirmation.template.html',
    controller:
    [
        '$scope',
        '$state',
        '$filter',
        '$window',
        'ccaService',
        'signUpRepository',
        'manualBookingsService',
        'balanceAdminService',
        'simpleAlertService',
        'financialAccountTypeEnum',
        'paymentSessionTypeEnum',
        function ($scope,
            $state,
            $filter,
            $window,
            ccaService,
            signUpRepository,
            manualBookingsService,
            balanceAdminService,
            simpleAlertService,
            financialAccountTypeEnum,
            paymentSessionTypeEnum) {
                
            this.$onChanges = (changes) => {
                if (changes.organisation && changes.organisation.currentValue) {
                    $scope.isoCurrency = this.organisation.isoCurrency;
                }
            };

            // Validate state params, as may be null e.g. in case of page refresh
            if ($state.params.pupilId == null) {
                $state.go('ccaDetailsAA.addPupil');
                return;
            }

            $scope.pupilId = $state.params.pupilId;

            $scope.timeRemaining = $state.params.timeRemaining;
            $scope.minutesUntilBookingExpiry = $state.params.minutesUntilBookingExpiry;
            $scope.timer = null;
            $scope.hasBookingTimedOut = false;

            $scope.signUp = {};

            $scope.canUseParentBalance = false
            $scope.isPayingWithParentBalance = false;
            $scope.isParentBalanceValid = false;
            $scope.paymentMsg = '';
            $scope.paymentMsgTranslationParams = null;
            $scope.selectedBalanceParentId = null;
            $scope.selectedBalanceType = null;
            $scope.selectedBalanceCharge = null;
            $scope.showManualBookingConfirmWithFee = false;

            $scope.togglePayWithParentBalance = function () {
                $scope.isPayingWithParentBalance = !$scope.isPayingWithParentBalance;
            }

            $scope.performChargeableManualBooking = function () {

                if (!$scope.isPayingWithParentBalance) {
                    return $scope.confirmBookings();
                }

                if ($scope.selectedBalanceParentId == null || $scope.selectedBalanceType == null || $scope.selectedBalanceCharge == null) {
                    console.log('Unexpected error when paying with parent id, balance type or balance charge is null');
                    simpleAlertService.errorAlert();
                    return;
                }

                var isCredit = $scope.selectedBalanceType == financialAccountTypeEnum.Credit;

                var paymentProviderId = isCredit
                    ? 0
                    : $window.EveryBuddy.Enums.PaymentProvider.Voucher;

                var signatureRequest = {
                    paymentSessionType: paymentSessionTypeEnum.Booking,
                    paymentProvider: paymentProviderId,
                    useCreditBalance: isCredit,
                    creditBalanceAmount: isCredit ? $scope.selectedBalanceCharge : 0,
                    ccaSignUpId: $state.params.signUpId,
                    paymentSessionRequestItems: [],
                    payerPersonId: $scope.selectedBalanceParentId
                };

                for (var i = $scope.signUpEvents.length; i--;) {

                    var thisEvent = $scope.signUpEvents[i];

                    for (var j = thisEvent.pupils.length; j--;) {

                        var thisPupil = thisEvent.pupils[j];

                        if (thisPupil.selectionCount === 1 && thisPupil.status === 1) {

                            signatureRequest.paymentSessionRequestItems.push({
                                calendarEventId: thisEvent.calendarEventId,
                                owningPersonId: thisPupil.personId
                            });
                        }
                    }
                }

                manualBookingsService
                    .submitManualBooking(signatureRequest)
                    .then(function (data) {
                        if (data.success) {
                            $state.go('ccaDetailsAA.addPupilBookingComplete');
                        }
                        else {
                            simpleAlertService.errorAlert({ message: data.message });
                            console.error('Problem generating payment session', data.message);
                        }
                    })
                    .catch(function () {
                        simpleAlertService.errorAlert();
                    });
            };

            balanceAdminService.isParentBalanceAvailable($state.params.pupilId, $state.params.signUpId)
                .then(function (data) {
                    $scope.canUseParentBalance = data;
                })
                .catch(function (err) {
                    simpleAlertService.errorAlert({ message: 'Problem checking parent balance availability: ' + err });
                });

            signUpRepository.getSignUp($state.params.signUpId)
                .then(function (signUp) {
                    $scope.signUp = signUp;
                    $scope.showManualBookingConfirmWithFee = !signUp.isParentTeacherConference;

                });

            signUpRepository
                .getSignUpBookedEvents($state.params.signUpId, $state.params.pupilId)
                .then(function (eventsData) {
                    $scope.pupils = eventsData.pupils;

                    // for booking confirmation, we are only interested in events in reserved state
                    $scope.signUpEvents = eventsData.availableEvents.filter(function (signUpEvent) {
                        return signUpEvent
                            .pupils
                            .filter(function (signUpEventPupil) {
                                return signUpEventPupil.status === 1; // Reserved
                            })
                            .length > 0;
                    });

                    if ($scope.timeRemaining)
                    {
                        $scope.startTimer();
                    }
                });

            $scope.confirmBookings = function () {
                signUpRepository.postPaidBookings($scope.signUpEvents, $scope.pupils, $state.params.signUpId)
                    .then(function () {
                        $state.go('ccaDetailsAA.addPupilBookingComplete');
                    });
            };

            $scope.costText = function (ccaPrice) {

                if (ccaPrice === 0 || ccaPrice === '0' || ccaPrice === '') {
                    return '0.00';
                }

                return $filter('number')(ccaPrice, 2);
            };

            $scope.totalPayment = function () {

                if (!$scope.signUpEvents) {
                    return 0;
                }

                var total = 0;
                for (var i = 0; i < $scope.signUpEvents.length; i++) {
                    var item = $scope.signUpEvents[i];
                    try {
                        var paymentAmount = item.costPerPerson * 1;

                        for (var j = 0; j < item.pupils.length; j++) {
                            if (item.pupils[j].selectionCount === 1 && item.pupils[j].status === 1) {
                                total += paymentAmount;
                            }
                        }
                    } catch (err) {
                        console.error('problem calculating total payment', err);
                    }

                }
                return total.toFixed(2);
            }

            $scope.startTimer = () =>
            {
                $scope.timeRemainingMins = moment.utc($scope.timeRemaining).get('minutes');
                $scope.timeRemainingSecs = moment.utc($scope.timeRemaining).get('seconds');
            };

            $scope.timeOutBooking = () =>
            {
                $scope.hasBookingTimedOut = true;
                $scope.timeRemainingMins = 0;
                $scope.timeRemainingSecs = 0;

                let timeOutBookingsRequest =
                {
                    signUpId: $state.params.signUpId,
                    timeOutBookingRequests: []
                }

                $scope.signUpEvents.forEach((signUpEvent) =>
                {
                    let selectedStudentIds = [];

                    signUpEvent.pupils.forEach((student) =>
                    {
                        if (student.selectionCount == 1)
                        {
                            selectedStudentIds.push(student.personId);
                        }
                    });

                    if (selectedStudentIds.length > 0)
                    {
                        let timeOutBookingRequest =
                        {
                            calendarEventId: signUpEvent.calendarEventId,
                            selectedStudentIds: selectedStudentIds
                        }

                        timeOutBookingsRequest.timeOutBookingRequests.push(timeOutBookingRequest);
                    }
                });

                ccaService.timeOutSelectedStudentBookings(timeOutBookingsRequest);
            }
        }
    ]});

