angular.module('messaging.components.messageCenterClient',
  [
    'messaging.services.messageViewingService',
    'messaging.components.messageList',
    'person.services',
    'shared.services.simpleAlertService',
    'shared.controllers.confirmationPopup',
    'shared.directives.sbCheckBox',
    'shared.services.iconService',
    'shared.services.deviceService',
    'shared.services.dateTimeService',
    'shared.components.sbIcon',
    'shared.components.sbAvatar',
    'shared.directives.sbLoading'
  ])
  .component('messageCenterClient',
    {
      bindings: {
        selectedMessageType: '<', // allow unread/ starred/ archived or inbox to be selected via deep linking
        selectedMessageId: '<', // allow the calling controller to specify current message (primarily for deep linking)
        selectedTeamId: '<',
        selectedClubId: '<',
        adminView: '<',
        limitChatFilters: '<',
        onLoaded: '&',
        onClientUnreadCountChanged: '&',
        onMarkAllAsRead: '&',
        preloadFirstMessage: '<'
      },
      templateUrl: '/Scripts/app/messaging/components/message-center-client.template.html',
      controller: [
        '$timeout',
        '$sce',
        '$filter',
        '$state',
        '$window',
        '$location',
        'messageViewingService',
        'iconService',
        'simpleAlertService',
        'deviceService',
        'dateTimeService',
    function messageCenterClientController($timeout, $sce, $filter, $state, $window, $location, messageViewingService, iconService, simpleAlertService, deviceService, dateTimeService) {

      var unfilteredLabel = 'SB_Filter';

      this.loading = 0;
      this.loadingMore = false;
      this.errorLoadingMessageList = false;
      this.errorLoadingMessage = false;
      this.sessionTypes = [];
      this.chatMessages = null;
      this.selectedMessage = null;
      this.selectedMessageData = null;
      this.selectedMessageFilter = 0;
      this.currentPersonId = 0;
      this.filterStatusTitle = unfilteredLabel;
      this.disableMessageFlags = false;

      // These were bindings before migration to Angular.
      this.showChatSessionName = false; // was only true for status365, now defunct.
      this.showNew = true; // always new now.

      this.totalMessages = 0;
      this.totalStarredMessages = 0;
      this.totalUnreadMessages = 0;

      this.tagsEnabled = false;
      this.isStaff = false;
      this.isPupil = false;
      this.isParent = false;

      this.orgTimeZoneOffset = '';

      var self = this;
      this.lookupsLoaded = false;

      var messageType = {
        All: 0,
        Unread: 1,
        Starred: 2,
        Archived: 3,
        Sent: 4
      };

      this.messageTypeTitle = 'SB_Inbox';

      var currentPage = 1;
      var pageSize = 25;

      this.teamOrClubFilters = {
        None: 0,
        Club: 1,
        Team: 2
      };

      this.icons = iconService.icons();

      this.ui = {
        searchTerms: '',
        filterTeamOrClub: this.teamOrClubFilters.None,
        fromId: 0,
        teamId: 0,
        clubId: 0,
        chatSessionTypeId: 0,
        chatSessionNameLookup: [],
        tagId: 0,
        filterPanelOpen: false,
        teams: [],
        teamNameLookup: [],
        clubs: [],
        clubNameLookup: [],
        staff: [],
        staffNameLookup: {},
        tags: [],
        tagNameLookup: [],
        moreMessagesAvailable: false,

        childNameLookup: {}
      };

      this.filters = {
        searchTerms: '',
        messageType: messageType.All,
        fromId: 0,
        teamId: 0,
        clubId: 0,
        chatSessionTypeId: 0,
        tagId: 0,
        hasAttachments: false
      };

      this.hideNavButtons = $location.search().hideNavButtons;

      var populateChatSessionFilters = function() {
        self.sessionTypes = [];
        self.ui.chatSessionNameLookup = {};

        if (!self.limitChatFilters || self.limitChatFilters.indexOf(1) > -1)
          self.sessionTypes.push({ id: 1, name: 'SB_Notices', iconName: 'notices' });

        if (!self.limitChatFilters || self.limitChatFilters.indexOf(2) > -1)
          self.sessionTypes.push({ id: 2, name: 'SB_News', iconName: 'news' });

        if (!self.limitChatFilters || self.limitChatFilters.indexOf(4) > -1)
          self.sessionTypes.push({ id: 4, name: 'SB_Parent_Notice', iconName: 'parentNotice' });

        if (!self.limitChatFilters || self.limitChatFilters.indexOf(3) > -1)
          self.sessionTypes.push({ id: 3, name: 'SB_Match_Reports', iconName: 'matchReports' });

        if (!self.limitChatFilters || self.limitChatFilters.indexOf(5) > -1)
          self.sessionTypes.push({ id: 5, name: 'SB_Homework', iconName: 'homeWork' });

        if ((!self.lookupsLoaded || self.isStaff) && (!self.limitChatFilters || self.limitChatFilters.indexOf(7) > -1)) {
          self.sessionTypes.push({
            id: 7,
            name: 'SB_Staff_Notice',
            iconName: 'staffNotice'
          });
        }

        for (var i = self.sessionTypes.length; i--;) {
          self.ui.chatSessionNameLookup[self.sessionTypes[i].id] = self.sessionTypes[i].name;
        }
      };


      this.isXsDevice = deviceService.isXsDevice();

      self.updateMarkAllAsRead = function () {
        // change the interface to show everything as being read
        // saves a journey back  to the server
        self.totalUnreadMessages = 0;

        for (var i = 0; i < self.chatMessages.length; i++) {
          self.chatMessages[i].ro = new Date();
        }
      }

      self.$onInit = function () {
        self.onMarkAllAsRead({ api: self.updateMarkAllAsRead });
        this.orgTimeZoneOffset = dateTimeService.getOrgTimeZoneOffset();
      };

      this.$onChanges = function (changes) {
        if (changes.selectedMessageId && changes.selectedMessageId.currentValue) {
          self.selectMessage({ id: +changes.selectedMessageId.currentValue, ro: true });
        }

        if (changes.selectedTeamId && changes.selectedTeamId.currentValue !== undefined) {
          self.filters.teamId = +changes.selectedTeamId.currentValue;
        }

        if (changes.selectedClubId && changes.selectedClubId.currentValue !== undefined) {
          self.filters.clubId = +changes.selectedClubId.currentValue;
        }

        if ((changes.selectedMessageType) && changes.selectedMessageType.currentValue !== undefined) {
          self.updateFilter('messageType', +changes.selectedMessageType.currentValue, true);
        }

        if (changes.adminView && changes.adminView.isFirstChange() && changes.adminView.currentValue !== changes.adminView.previousValue) {
          self.disableMessageFlags = changes.adminView;
          populateChatSessionFilters();
          loadMessages();
        }
      };

      messageViewingService.getAllLookups()
      .then(function (data) {
        self.currentPersonId = data.currentPersonId;
        self.tagsEnabled = data.tagsEnabled;
        self.isStaff = data.isStaff;
        self.isPupil = data.isPupil;
        self.isParent = data.isParent;
        self.ui.teams = data.teams;

        if (!data.isStaff) {
          // just incase someone's trying their luck...
          self.adminView = false;
        }

        for (var i = data.teams.length; i--;)
          self.ui.teamNameLookup[data.teams[i].id] = data.teams[i].name;

        self.ui.clubs = data.clubs;

        for (var i = data.clubs.length; i--;)
          self.ui.clubNameLookup[data.clubs[i].id] = data.clubs[i].name;

        self.ui.staff = data.staff;

        for (var i = data.staff.length; i--;)
          self.ui.staffNameLookup[data.staff[i].id] = data.staff[i].name;

        if (data.tags) {
          self.ui.tags = data.tags;

          for (var i = data.tags.length; i--;)
            self.ui.tagNameLookup[data.tags[i].id] = data.tags[i].name;
        }

        if (data.isParent && data.children) {
          self.ui.children = data.children;

          for (var i = data.children.length; i--;)
            self.ui.childNameLookup[data.children[i].id] = data.children[i].name;
        }

        self.lookupsLoaded = true;

        populateChatSessionFilters();
      });

      var loadMessages = function (append) {
        self.errorLoadingMessageList = false;

        if (!append) {
          self.chatMessages = null;
          if(!self.isXsDevice) {
            self.selectedMessage = null;
            self.selectedMessageData = null;
          }
        }

        var filters = {
          searchTerms: [],
          tagId: self.filters.tagId,
          teamId: self.filters.teamId,
          clubId: self.filters.clubId,
          chatSessionTypeId: self.filters.chatSessionTypeId,
          fromId: self.filters.fromId,
          hasAttachments: self.filters.hasAttachments,
          pageSize: pageSize,
          currentPage: currentPage
        };

        if (self.filters.searchTerms) {
          if (self.filters.searchTerms.indexOf(' ') > -1) {
            var terms = self.filters.searchTerms.split(' ');
            for (var i = terms.length; i--;)
              filters.searchTerms.push(terms[i]);
          }
          else {
            filters.searchTerms.push(self.filters.searchTerms);
          }
        }

        self.disableMessageFlags = self.adminView;

        switch (self.filters.messageType) {
        case messageType.Starred:
          filters.justUnreadMessages = false;
          filters.justStarredMessages = true;
          filters.justArchivedMessages = false;
          break;
        case messageType.Unread:
          filters.justUnreadMessages = true;
          filters.justStarredMessages = false;
          filters.justArchivedMessages = false;
          break;
        case messageType.Archived:
          filters.justUnreadMessages = false;
          filters.justStarredMessages = false;
          filters.justArchivedMessages = true;
          break;
        case messageType.Sent:
          self.disableMessageFlags = true;
          filters.justUnreadMessages = false;
          filters.justStarredMessages = false;
          filters.justArchivedMessages = false;
          break;
        default:
          filters.justUnreadMessages = false;
          filters.justStarredMessages = false;
          filters.justArchivedMessages = false;
          break;
        }

        var processMessageLoadResponse = function(data) {
          if (data.isError) {
            self.errorLoadingMessageList = true;
            self.chatMessages = [];
          } else {
            if (append) {
              self.chatMessages = self.chatMessages.concat(data.messages);
            }
            else {
              self.chatMessages = data.messages;
            }

            self.totalMessages = data.totalCount;
            self.totalStarredMessages = data.starredCount;
            self.totalUnreadMessages = data.unreadCount;

            self.ui.moreMessagesAvailable = data.messages.length == pageSize;
          }
          self.loading--;
          self.loadingMore = false;

          if (typeof (self.onClientUnreadCountChanged()) === 'function') {
            self.onClientUnreadCountChanged()(self.totalUnreadMessages);
          }

          if (self.preloadFirstMessage &&
          self.selectedMessage === null &&
          self.chatMessages &&
          self.chatMessages.length > 0) {
            self.selectMessage(self.chatMessages[0]);
          }
        };

        self.loading++;

        if (self.adminView) {
          if (!self.showNew) {
            messageViewingService.getAdminInbox(filters)
            .then(processMessageLoadResponse);
          }
          else {
            messageViewingService.getAdminInboxNewOnly(filters)
            .then(processMessageLoadResponse);
          }
        }
        else {
          messageViewingService.getPersonInbox(filters)
          .then(processMessageLoadResponse);
        }
      };

      this.updateFilter = function(propName, value, skipLoad) {
        if (propName === 'messageType') {
          if (self.filters.messageType === messageType.Sent && value !== messageType.Sent) {
            // if we're on sent and we're switching away from it, then reset the FromId filter
            self.filters.fromId = 0;
          }

          switch (parseInt(value)) {
          case messageType.Starred:
            pageSize = 25;
            self.filters.clubId = 0;
            self.filters.teamId = 0;
            self.messageTypeTitle = 'SB_Starred';
            break;
          case messageType.Unread:
            pageSize = 50;
            self.filters.clubId = 0;
            self.filters.teamId = 0;
            self.messageTypeTitle = 'SB_Unread';
            break;
          case messageType.Archived:
            pageSize = 50;
            self.filters.clubId = 0;
            self.filters.teamId = 0;
            self.messageTypeTitle = 'SB_Archived';
            break;
          case messageType.Sent:
            pageSize = 50;
            self.filters.clubId = 0;
            self.filters.teamId = 0;
            self.messageTypeTitle = 'SB_Sent';
            self.filters.fromId = self.currentPersonId;
            break;
          default:
            pageSize = 25;
            self.messageTypeTitle = 'SB_Inbox';
            break;
          }
          self.mobileDataSetSelected = true;
        }

        self.filters[propName] = value;

        currentPage = 1;

        if (skipLoad)
          return;

        if (this.isXsDevice && !this.selectedTeamId && !this.selectedClubId) {
          $state.go('dashboard.inboxForType', { messageType: self.filters.messageType, teamId: self.filters.teamId, clubId: self.filters.clubId });
          return;
        }

        loadMessages();
      }.bind(this);

      this.closeMessageType = function () {
        this.mobileDataSetSelected = false;
        if (this.isXsDevice) {
          $window.history.back();
        }
      }.bind(this);

      this.toggleFilterPanel = function () {
        if (!self.ui.filterPanelOpen) {
          self.ui.fromId = self.filters.fromId;
          self.ui.tagId = self.filters.tagId;
          self.ui.chatSessionTypeId = self.filters.chatSessionTypeId;
          self.ui.filterPanelOpen = true;
        }
        else
          self.ui.filterPanelOpen = false;
      };

      this.clearFilters = function () {
        self.ui.fromId = 0;
        self.ui.tagId = 0;
        self.ui.chatSessionTypeId = 0;
        self.ui.hasAttachments = false;
      };

      var isFiltered = function () {
        return self.filters.fromId > 0 ||
        self.filters.tagId > 0 ||
        self.filters.chatSessionTypeId > 0 ||
        self.filters.hasAttachments;
      };

      this.applyFilters = function () {
        var filterSummary = [];

        self.filters.fromId = self.ui.fromId;
        self.filters.tagId = self.ui.tagId;
        self.filters.chatSessionTypeId = self.ui.chatSessionTypeId;
        self.filters.hasAttachments = self.ui.hasAttachments;

        if (isFiltered()) {
          self.filterStatusTitle = 'Filtered';

          if (self.filters.fromId > 0) {
            filterSummary.push('From ' + self.ui.staffNameLookup[self.filters.fromId]);
          }
          if (self.filters.tagId > 0) {
            filterSummary.push('Tagged with ' + self.ui.tagNameLookup[self.filters.tagId]);
          }
          if (self.filters.chatSessionTypeId > 0) {
            filterSummary.push('Just ' + $filter('translate')(self.ui.chatSessionNameLookup[self.filters.chatSessionTypeId]));
          }
          if (self.filters.hasAttachments) {
            filterSummary.push('Has attachments');
          }

          self.filterStatusTitle += ' : ' + filterSummary.join(' | ');
        }
        else {
          self.filterStatusTitle = unfilteredLabel;
        }

        self.ui.filterPanelOpen = false;

        currentPage = 1;
        loadMessages();
      };

      this.filterTeamOrClubChanged = function () {
        $timeout(function () {
          switch (self.ui.filterTeamOrClub) {
          case self.teamOrClubFilters.None:
            self.ui.teamId = 0;
            self.ui.clubId = 0;
            break;
          case self.teamOrClubFilters.Club:
            self.ui.teamId = 0;
            break;
          case self.teamOrClubFilters.Team:
            self.ui.clubId = 0;
            break;
          }
        });
      };

      this.filterOnTeam = function(id) {
        self.filters.teamId = id;
        self.filters.clubId = 0;
        self.ui.clubFilterExpanded = false;
        self.updateFilter('messageType', 0);
      };

      this.filterOnClub = function(id) {
        self.filters.teamId = 0;
        self.filters.clubId = id;
        self.ui.teamFilterExpanded = false;
        self.updateFilter('messageType', 0);
      };

      this.removeTeamClubFilters = function () {
        self.filters.teamId = 0;
        self.filters.clubId = 0;
        self.ui.clubFilterExpanded = false;
        self.ui.teamFilterExpanded = false;
        self.updateFilter('messageType', 0);
      };

      this.loadMore = function () {
        currentPage++;
        self.loadingMore = true;
        loadMessages(true);
      };

      this.refresh = function () {
        loadMessages();
      };

      this.retryLoadMessage = function () {
        self.selectMessage(self.selectedMessage);
      };

      this.selectMessage = function (message) {
        if (this.isXsDevice) {
          $state.transitionTo('dashboard.inboxSpecificMessage', { id: message.id });
        }
        self.selectedMessage = message;
        self.selectedMessageData = null;
        self.errorLoadingMessage = false;

        self.loading++;
        messageViewingService.getSpecificMessage(message.id)
        .then(function (data) {
          if (data.isError) {
            self.errorLoadingMessage = true;
            self.loading--;
            return;
          }

          self.selectedMessageData = data;

          if (!self.selectedMessage.ro && !self.disableMessageFlags) {
            // it was unread, now it will be read- update our totals
            self.totalUnreadMessages--;
          }

          if (typeof (self.onClientUnreadCountChanged()) === 'function') {
            self.onClientUnreadCountChanged()(self.totalUnreadMessages);
          }

          self.selectedMessageData.apids = self.selectedMessage.apids;
          self.selectedMessage.ro = self.selectedMessageData.ro;
          self.selectedMessage.so = self.selectedMessageData.so;
          self.selectedMessage.ao = self.selectedMessageData.ao;
          self.loading--;
        });
      }.bind(this);

      this.clearSelectedMessage = function () {
        this.selectedMessage = null;
        this.selectedMessageData = null;
        if (this.isXsDevice) {
          $window.history.back();
        }
      }.bind(this);

      this.toggleStar = function () {
        var isStarred = true;

        if (!self.selectedMessage.so) {
          isStarred = false;
        }

        self.loading++;
        messageViewingService.starMessage(self.selectedMessage.id, !isStarred)
        .then(function (data) {
          if (data && !data.isError) {
            self.selectedMessage.so = data.starredOn;

            if (!data.starredOn) {
              self.totalStarredMessages--;
            } else {
              self.totalStarredMessages++;
            }
          }
        });
      };

      this.toggleArchive = function () {
        var isArchived = true;

        if (!self.selectedMessage.co)
          isArchived = false;

        self.loading++;
        messageViewingService.archiveMessage(self.selectedMessage.id, !isArchived)
        .then(function (data) {
          if (data && !data.isError) {

            // are we currently on the archive view?
            if (
              (self.filters.messageType !== messageType.Starred) &&
              ((self.filters.messageType !== messageType.Archived && data.archivedOn) ||
              (self.filters.messageType === messageType.Archived && !data.archivedOn))
            ) {
              // remove this message from the list
              var foundIndex = -1;
              for (var i = self.chatMessages.length; i--;) {
                if (self.chatMessages[i] === self.selectedMessage) {
                  foundIndex = i;
                  break;
                }
              }
              if (foundIndex > -1) {
                self.chatMessages.splice(foundIndex, 1);
              }
            }

            self.selectedMessage.co = data.archivedOn;
          }
        });
      };

      this.deleteMessage = function () {
        if (!self.isStaff)
          return;

        var modalInstance = simpleAlertService.simpleAlert({
          title: 'SB_Title_Delete_Message',
          message: 'SB_Confirmation_Delete_Message',
          okButtonText: 'SB_Delete',
          cancelButtonText: 'SB_Cancel',
          windowSize: 'md'
        });

        modalInstance
        .result
        .then(function () {

          // delete a message

        })
        .catch(function () { });
      };

      this.buildChildNameString = function (ids) {
        var nameString = '';

        for (var i = 0; i < ids.length; i++) {
          nameString += self.ui.childNameLookup[ids[i]];

          if (i == ids.length - 3)
            nameString += ', ';
          else if (i == ids.length - 2)
            nameString += ' &amp; ';
        }

        return nameString;
      };

      this.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
      };

      this.messageTypeTitleSuffix = function () {
        var clubTitle = self.ui.clubNameLookup[self.filters.clubId];
        var teamTitle = self.ui.teamNameLookup[self.filters.teamId];
        if (self.filters.clubId && clubTitle) {
          return ' : ' + clubTitle;
        }
        else if (self.filters.teamId && teamTitle) {
          return ' : ' + teamTitle;
        }
      };

      this.getAttachHref = (resourcePath) => {
        return `${window.EveryBuddy.WebAPI}/Chat/GetBlobAttachment?Resource=${resourcePath}&id=${this.selectedMessage.id}`;
      }
    }
    ]
  }).directive('blurOnReturn', function () {
    return function (scope, element, attrs) {
      element.bind('keyup', function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            element[0].blur();
          });
        }
      });
    };
  });;
