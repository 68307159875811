<div [ngClass]="{'flex' : !isUi}">
    <div class="mt-2 mr-5">
        <input type="color"
                id="primaryColour"
                name="primaryColour"
                [(ngModel)]="colourOptions.primaryColour"
                (change)="updateColours()"
                class="mr-3"
                [disabled]="!colourOptions || isDisabled">
        <label for="primaryColour"
               [innerHTML]="'SB_Colour_Primary' | translate"></label>
    </div>
    <div class="mt-2 mr-5">
        <input type="color"
                id="secondaryColour"
                name="secondaryColour"
                [(ngModel)]="colourOptions.secondaryColour"
                (change)="updateColours()"
                class="mr-3"
                [disabled]="!colourOptions || isDisabled">
        <label for="secondaryColour"
               [innerHTML]="'SB_Colour_Secondary' | translate"></label>
    </div>
    <div class="mt-5"
         *ngIf="isUi">
        <button class="btn btn-default"
                [innerHTML]="'SB_Set_To_Default' | translate"
                (click)="setDefaults()"></button>
    </div>
</div>

<ng-container *ngIf="isUi">
    <div class="mt-10">
        <div [innerHTML]="'SB_Custom_Colour_Guide_1' | translate"></div>
    </div>
    
    <div class="mt-5 colour-test colour-test-main">
        <div>
            <sb-icon name="circle"></sb-icon>
            <div [innerHTML]="'SB_Colour_White' | translate"></div>
            <div [innerHTML]="'SB_Colour_Primary' | translate"></div>
        </div>
        <div>
            <sb-icon name="circle"></sb-icon>
            <div [innerHTML]="'SB_Colour_Secondary' | translate"></div>
            <div [innerHTML]="'SB_Colour_Primary' | translate"></div>
        </div>
        <div>
            <sb-icon name="circle"></sb-icon>
            <div [innerHTML]="'SB_Colour_Dark_Grey' | translate"></div>
            <div [innerHTML]="'SB_Colour_Primary' | translate"></div>
        </div>  
        <div>
            <sb-icon name="circle"></sb-icon>
            <div [innerHTML]="'SB_Colour_Black' | translate"></div>
            <div [innerHTML]="'SB_Colour_Secondary' | translate"></div>
        </div>        
    
    </div>
    
    <div class="mt-10">
        <div [innerHTML]="'SB_Custom_Colour_Guide_2' | translate"></div>
    </div>
    
    <div class="mt-5 colour-test colour-test-all">
        <div [innerHTML]="'SB_Colour_Primary' | translate"
                class="bg-primary-solid"></div>
        <div [innerHTML]="'SB_Colour_Secondary' | translate"
                class="bg-secondary-solid"></div>
        <div [innerHTML]="'SB_Colour_Success' | translate"
                class="bg-success-solid"></div>
        <div [innerHTML]="'SB_Colour_Danger' | translate"
                class="bg-danger-solid"></div>
        <div [innerHTML]="'SB_Colour_Warning' | translate"
                class="bg-warning-solid"></div>
        <div [innerHTML]="'SB_Colour_Info' | translate"
                class="bg-info-solid"></div>
    </div>
</ng-container>