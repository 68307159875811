'use strict';

angular.module('transport.changeRequests.studentPickerWithDiaryEvents', [
    'transport.changeRequests.transportChangeRequestAdd',
    'transport.services.changeRequestService',
    'transport.services.transportTimetablesService',
    'shared.components.sbAutoComplete',
    'shared.components.sbStackedDate',
    'shared.components.sbModalHeader',
    'shared.services.portalChangeRequestService',
])
    .component('studentPickerWithDiaryEvents', {
        templateUrl: '/Scripts/app/transport/changeRequests/student-picker-with-diary-events.template.html',
        bindings: {
            modalInstance: '<',
        },
        controller: [
            '$uibModal',
            'changeRequestService',
            'portalChangeRequestService',
            'transportTimetablesService',
            'simpleAlertService',
            function studentPickerWithDiaryEvents($uibModal, changeRequestService, portalChangeRequestService, transportTimetablesService,  simpleAlertService )
            {

                this.$onInit = function () {
                    this.pupil = { event: [] };
                }.bind(this);

                this.showAddChangeRequest = function (calendarEventItem)
                {
                    var addModalInstance = $uibModal.open({
                        animation: true,
                        backdrop: 'static',
                        component: 'transportChangeRequestAdd',
                        size: 'lg',
                        windowClass: 'modal-scroll',
                        resolve:
                        {
                            pupilChangeRequestOptions: function () {

                                return portalChangeRequestService.getChangeRequestOptions(calendarEventItem.id).catch(function (error)
                                {
                                    this.close();

                                    console.error('XHR getChangeRequestOptions failure : ' + error.status);

                                    var message = { message: 'SB_Error_loading' };

                                    if (error && error.data && error.data.Message) {
                                        message = { message: error.data.Message };
                                    }

                                    simpleAlertService.errorAlert(message);

                                }.bind(this))
                            }.bind(this)
                        }
                    });

                    addModalInstance.result.then(function (updateView) {
                        if (updateView && updateView === true)
                        {
                            //may want to reload the UI here in the future, may need a hook into the sb-autocompete to update this.
                            this.close();
                        }
                    }.bind(this), function () { });

                }.bind(this);

                this.viewReviewChangeRequest = function (transportAttendeeId)
                {
                    portalChangeRequestService.getChangeRequest(transportAttendeeId).then(function (responseData)
                    {
                        if (responseData)
                        {
                            var changeRequest = responseData;

                            var modalInstance = $uibModal.open({
                                animation: true,
                                backdrop: 'static',
                                component: 'transportReviewChangeRequest',
                                size: 'lg',
                                windowClass: 'modal-scroll',
                                resolve:
                                {
                                    changeRequest: function ()
                                    {
                                        return changeRequest;
                                    }.bind(this),
                                    timeSlotTimeTableByOperatingDay: function ()
                                    {
                                        return transportTimetablesService
                                            .getTimetableForTimeSlotAndEventDateGroupByDayAsync(changeRequest.transportOptionChangeTo.timeSlotId,
                                                changeRequest.calendarEventStartDateTime,
                                                changeRequest.organisationOperatingDayId);
                                    }.bind(this)
                                }
                            });

                            modalInstance.result.then(function (updateView) {
                                if (!updateView) {
                                    return;
                                }

                                if (updateView === true) {
                                    this.close();
                                }
                            }.bind(this), function () { });
                        }
                        else
                        {
                            simpleAlertService.errorAlert();
                        }
                    }).catch(function (responseData) {
                        simpleAlertService.errorAlert();
                    });


                }.bind(this);

                this.getPupils = changeRequestService.GetTransportDiaryEventsByPupilName;

                this.onSelectStudent = function ($item) {
                    this.pupil = $item
                }.bind(this);

                this.close = function () {
                    this.modalInstance.close();
                }.bind(this);
            }
        ]
    });
