angular
    .module('transport.services.configurationFeaturesService', [])
    .factory('configurationFeaturesService', [
        '$http', 'httpCoreApi',
        function ($http, httpCoreApi)
        {
            const FeaturesUrlBase = 'Transport/Configuration/Features';
            const FeaturesConfig = {
                controllerType: httpCoreApi.controllerTypes.Admin
            };

            var service = {};

            service.getFeaturesConfiguration = () => {
                return httpCoreApi.get(FeaturesUrlBase, FeaturesConfig);
            };

            service.saveFeaturesConfiguration = (data) => {
                return httpCoreApi.put(FeaturesUrlBase, data, FeaturesConfig);
            };

            return service;
        }
    ]);
