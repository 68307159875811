'use strict';

angular.module('ccasignupModule').component('ccaExistingEventsAAAttendees', {
    templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-signup-existingeventsaa-attendees.template.html',
    bindings: {
        selectedEvent: '<',
        attendees: '<'
    },
    controller:
    [
        '$scope',
        '$state',
        '$uibModal',
        'signUpRepository',
        '$compile',
        '$timeout',
        '$window',
        'simpleAlertService',
        '$sce',
        '$templateRequest',
        function($scope,
                $state,
                $uibModal,
                signUpRepository,
                $compile,
                $timeout,
                $window,
                simpleAlertService,
                $sce,
                $templateRequest
        )
        {

            this.$onChanges = (changes) => {
                if (changes.selectedEvent && changes.selectedEvent.currentValue) {
                    $scope.selectedEvent = this.selectedEvent;
                }
                if (changes.attendees && changes.attendees.currentValue) {
                    $scope.attendees = this.attendees;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.sortType = "lastName";
            $scope.sortReverse = false;

            $scope.pupilDelete = function(item)
            {
                var deleteModal = $uibModal.open({
                    backdrop: 'static',
                    component: 'ccaDeletePupilConfirmationContainer',
                    resolve: {
                        calendarEventId: function () { return $scope.selectedEvent.calendarEventId; },
                        personId: function () { return item.personId; },
                        isBlock: function () { return $scope.selectedEvent.isBlockEvent; },
                        seriesId: function () { return $scope.selectedEvent.seriesId; },
                        isDelete: function () { return item.bookingStatusId === 2; } // Confirmed
                    },
                    size: 'lg'
                });

                deleteModal.result
                    .then(function (eventIds) {
                        console.log('ccaExistingEventsAAAttendees/delete', 'delete popup resolved');

                        if (eventIds.indexOf($scope.selectedEvent.calendarEventId) > -1) {
                            item.bookingStatusId = 4; // Cancelled
                        }

                        // go through each event from which attendee has been deleted
                        // (may be multiple in case of a block booking) and update its status
                        // and attendee count.
                        angular.forEach($scope.signUpEvents, function(signUpEvent) {
                            if (eventIds.indexOf(signUpEvent.calendarEventId) > -1) {
                                signUpEvent.bookingStatusId = 4; // Cancelled
                                signUpEvent.attendeeCount--;
                            }
                        });
                    }.bind(this))
                    .catch(function () {
                        console.log('ccaExistingEventsAAAttendees/delete', 'delete popup dismissed');
                    });
            };

            $scope.bookingStatusLabel = function (bookingStatusId) {
                return bookingStatusId === 2 ? 'SB_Confirmed' : 'SB_Canceled';
            };

            $scope.exportExistingAttendees = function ()
            {
                var calendarEventId = $scope.selectedEvent.calendarEventId;
                if (typeof calendarEventId == 'number')
                {
                    window.open(`${$window.EveryBuddy.WebAPI}${signUpRepository.getAttendeesCsvUrl(calendarEventId)}`);
                }
                else
                {
                    simpleAlertService.simpleAlert({
                        title: "SB_Error",
                        message: "SB_Error_loading"
                    })
                }
            }

            $scope.printExistingAttendees = function ()
            {

                var templateUrl = $sce.getTrustedResourceUrl('/Scripts/app/ccaSignUp/DetailsAA/cca-signup-existingeventsaa-attendees.print.html?v=' + $window.EveryBuddy.Version);

                $templateRequest(templateUrl).then(function (template) {
                    template = '<div>' + template + '</div>';

                    var compiledPrintView = $compile(template)($scope);
                    $timeout(function ()
                    {
                        var myWindow = $window.open('', '', 'width=800, height=600');
                        myWindow.document.write(compiledPrintView[0].innerHTML);
                    }, 1000);
                    }, function ()
                    {
                        simpleAlertService.simpleAlert({
                            title: "SB_Error",
                            message: "SB_Error_loading"
                        })
                    });
            }
        }
        }
    ]});
