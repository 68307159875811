angular.module('shared.components.sbDatePicker', [
    'ui.bootstrap',
    'shared.services.cachedLookupService'
]).component('sbDatePicker',
    {
        bindings: {
            // JS Date
            modelDate: '=',
            required: '<',
            minDate: '<',
            maxDate: '<',
            readonly: '<',
            disabled: '<',
            skipFormatSetting: '<',
            name: '<',
            onChange: '&',
            specificTimeZone: '@'
        },
        templateUrl: '/Scripts/app/shared/components/sb-date-picker.template.html',
        controller: [
            'cachedLookupService',
            function (cachedLookupService) {

                var previousDate = undefined;

                this.open = function () {
                    this.opened = true;
                }.bind(this);

                this.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };

                this.modelOptions = {
                };

                this.$onInit = function () {
                    this.format = '';

                    if (!this.skipFormatSetting) {
                        cachedLookupService
                            .getSystemSetting('Organisation_Date_DefaultPickerFormatting')
                            .then(function (value) {
                                this.format = value;
                            }.bind(this));
                    }

                    if (this.modelDate && !(this.modelDate instanceof Date)) {
                        this.modelDate = new Date(this.modelDate.replace('Z', ''));
                    }

                    previousDate = angular.copy(this.modelDate);
                };

                this.$doCheck = function () {
                    if (!angular.equals(this.modelDate, previousDate)) {
                        this.handleDateChange();
                        previousDate = angular.copy(this.modelDate);

                        // Notify parent if date is valid.
                        if (typeof this.onChange() !== 'undefined' && this.modelDate) {
                            this.onChange()();
                        }
                    }
                };

                this.handleDateChange = function () {

                    if (this.modelDate) {
                        // create new date to force date-picker to refresh
                        if (this.modelDate instanceof Date) {
                            this.modelDate = new Date(this.modelDate);
                        }
                        else {
                            this.modelDate = new Date(this.modelDate.replace('Z', ''));
                        }
                    }
                }.bind(this);

                this.$onChanges = function (changes: any) {
                    if (changes.minDate) {
                        if (!changes.minDate.currentValue) {
                            this.minDate = new Date(1900, 1, 1);
                        }
                        else {
                            // make sure this is a js date object..
                            if (!(changes.minDate.currentValue instanceof Date)) {
                                // parse it
                                this.minDate = moment(changes.minDate).toDate();
                            }
                        }
                        this.dateOptions.minDate = this.minDate;
                    }

                    if (changes.maxDate) {
                        if (changes.maxDate.currentValue) {
                            // make sure this is a js date object..
                            if (!(changes.maxDate.currentValue instanceof Date)) {
                                // parse it
                                this.maxDate = moment(changes.maxDate).toDate();
                            }
                            this.dateOptions.maxDate = this.maxDate;
                        }

                        // Pretty sure this is not being used anywhere.
                        // Only reference I can find is in reception block dates, but it references a disabled
                        // function that is undefined.
                        // if (changes.disabled) {
                        //     this.dateOptions.dateDisabled = this.disabled;
                        // }

                        if (changes.specificTimeZone) {
                            this.modelOptions.timezone = changes.specificTimeZone.currentValue;
                        }
                    };

                    this.opened = false;
                }
            }
        ]
    });
