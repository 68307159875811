'use strict';

angular.module('reports.components.reportList', [
    'pascalprecht.translate',

    'reports.services.reportService',

    'shared.components.sbIcon',
    'shared.services.cachedLookupService',
])
    .component('reportList',
        {
            bindings: {
                onReportSelected: '&',
                onPrintClicked: '&',
                onRefreshClicked: '&',
                onFullScreenClicked: '&',
                displayArea: '<'
            },
            templateUrl: '/Scripts/app/reports/components/report-list.template.html',
            controller: ['reportService',
                function (reportService)
                {
                    this.reportList = [];

                    this.$onChanges = function (changes)
                    {
                        console.log('changes', changes);

                        if (changes.displayArea && changes.displayArea.currentValue)
                        {
                            reportService.getAvailableReports(changes.displayArea.currentValue)
                                .then(function (data)
                                {
                                    this.reportList = data;
                                    this.isLoading--;
                                }.bind(this));
                        }
                    };

                    this.isLoading = 1;

                    this.selectedReport = "";

                    this.selectReport = function (id)
                    {
                        this.onReportSelected()(this.selectedReport);
                    }.bind(this);

                    this.print = function ()
                    {
                        this.onPrintClicked()();
                    }.bind(this);

                    this.refresh = function ()
                    {
                        this.onRefreshClicked()();
                    }.bind(this);

                    this.fullScreen = function ()
                    {
                        this.onFullScreenClicked()();
                    }.bind(this);
                }]
        });