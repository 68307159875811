'use strict';

angular.module('events.components.cancelEventButton',
    [
        'pascalprecht.translate',
        'events.services.eventsService',
        'messaging.controllers.personPickerPopup',
        'messaging.services.messagingService',
        'shared.services.simpleAlertService',
        'shared.components.sbIcon',
        'shared.components.sbModalHeader',
        'shared.services.toastService',
        'shared.services.tinymceConfigHelper',
        'person.directives.personPicker',
        'person.directives.personPickerSummary',
    ])
    .component('cancelEventButton',
        {
            bindings: {
                eventId: '<',
                onSelectedEventUpdated: '&',
                onEventCancelled: '&'
            },
            templateUrl: '/Scripts/app/events/components/cancel-event-button.template.html',
            controller: [
                '$window',
                '$uibModal',
                '$filter',
                'eventsService',
                'messagingService',
                'simpleAlertService',
                'toastService',
                'tinymceConfigHelper',
                function ($window, $uibModal, $filter, eventsService, messagingService, simpleAlertService, toastService, tinymceConfigHelper) {
                    this.eventDetails = {};
                    this.eventDetailsLoaded = false;

                    this.$onChanges = function (changes) {
                        if (changes.eventId && changes.eventId.currentValue) {
                            this.eventDetailsLoaded = false;
                            // load the event basics
                            eventsService.getEventBasics(changes.eventId.currentValue)
                                .then(function (data) {
                                    this.eventDetails = data;
                                    this.eventDetailsLoaded = true;
                                }.bind(this));
                        }
                    };

                    var simpleAlert = function (title, message) {
                        simpleAlertService.simpleAlert({
                            title: title,
                            message: message,
                            okButtonText: 'SB_OK',
                            windowSize: 'lg'
                        });
                    };

                    this.cancelEvent = function () {
                        var fromDate = moment(this.eventDetails.from);
                        var timeZoneSafeFromDate = fromDate.clone().set({
                            hour: 12,
                            minute: 0,
                            second: 0
                        });

                        this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                        // set up the Picker
                        this.openRecipientPicker = function () {

                            var settings = {
                                showEvents: false,
                                disablePersonPicking: true,
                                useSimpleAddButtonForClubs: false,
                                useSimpleAddButtonForTeams: false,
                                showEventPersons: true,
                                showEventPersonGroups: true,
                                eventId: this.eventDetails.id,
                                disableClubPicking: true,
                                disableTeamPicking: true,
                                showWholeSchoolGroup: false
                            };

                            $uibModal.open({
                                animation: this.animationsEnabled,
                                templateUrl: '/Scripts/app/messaging/controllers/person-picker-popup.template.html?v=' + $window.EveryBuddy.Version,
                                controller: 'personPickerPopupController',
                                size: 'lg',
                                resolve: {
                                    selected: function () {
                                        return this.cancellation.recipients;
                                    }.bind(this),
                                    restrict: function () {
                                        return this.restrict;
                                    }.bind(this),
                                    settings: function () {
                                        return settings;
                                    }.bind(this)

                                }
                            });
                        }.bind(this);

                        this.cancellation = {
                            title: `${$filter('translate')('SB_Canceled')} - ${this.eventDetails?.title ?? ''} - ${this.eventDetails?.teamName ?? ''} ${$filter('translate')('SB_Event_Cancelled_Communication_Title_WasFrom')} : ${fromDate.format('HH:mm')} - ${timeZoneSafeFromDate.format('ddd DD MMM YYYY')}`,
                            message: '',
                            openRecipientPicker: this.openRecipientPicker,
                            hasRichContent: true,
                        };

                        this.cancellation.hasRecipientSelected = function () {
                            return messagingService.hasRecipientSelected(this);
                        };

                        messagingService.resetGroupContainers(this.cancellation);

                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: 'cancelEventModal.html',
                            controller: 'popupCancelEventController',
                            size: 'lg',
                            resolve: {
                                cancellation: function () { return this.cancellation; }.bind(this),
                                tinymceOptions: function () { return this.tinymceOptions; }.bind(this)
                            }
                        });

                        modalInstance.result
                            .then(function () {
                                this.saving = true;

                                if( this.cancellation.hasRichContent) {
                                    var messageData = {
                                        htmlBody: this.cancellation.message,
                                        recipients: this.cancellation.recipients
                                    };
                                    if (!messagingService.validateSendMessage(messageData, simpleAlert)) {
                                        return;
                                    };
                                } else {
                                    messagingService.resetGroupContainers(this.cancellation);
                                }

                                var newMessageModel = {};
                                messagingService.prepareRecipientGroups(newMessageModel, this.cancellation.recipients, this.eventDetails.id);

                                eventsService.cancelEvent(
                                    this.eventDetails.id,
                                    this.cancellation.title,
                                    this.cancellation.message,
                                    newMessageModel.recipients
                                )
                                    .then(function (data) {
                                        if (data.isError) {
                                            toastService.saveError(data?.errorData?.data?.Message);
                                        } else {
                                            this.eventDetails.isCancelled = true;

                                            if (typeof this.onSelectedEventUpdated === 'function') {
                                                this.onSelectedEventUpdated(this.eventDetails);
                                            }

                                            if (typeof this.onEventCancelled === 'function') {
                                                this.onEventCancelled();
                                            }

                                            toastService.saveSuccess('SB_Event_Cancelled_Confirmation_Success_Message');
                                        }

                                    }.bind(this));
                            }.bind(this))
                            .finally(() => this.saving = false);
                    }.bind(this);
                }
            ]
        })
    .controller('popupCancelEventController',
        [
            '$scope',
            '$uibModalInstance',
            'tinymceOptions',
            'cancellation',
            function ($scope, $uibModalInstance, tinymceOptions, cancellation) {
                $scope.tinymceOptions = tinymceOptions;

                $scope.cancellation = cancellation;

                $scope.ok = function () {
                    $uibModalInstance.close(true);
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };

            }
        ]);
