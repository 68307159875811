angular.module('shared.services.simpleAlertService', [
    'shared.controllers.pleaseWaitPopupController',
    'shared.controllers.confirmationPopup'
])
    .service('simpleAlertService', class simpleAlertService {

        // Dependencies
        $uibModal: any;
        $window: any;

        static $inject = ['$uibModal', '$window'];

        constructor ($uibModal: any, $window: any) {
            this.$uibModal = $uibModal;
            this.$window = $window;
        }

        simpleAlert(options: any) {
            var opts: any = {};

            angular.extend(
                opts, // dest
                {
                    title: '',
                    message: '',
                    translateMessage: true,
                    /*
                        Should contain a js object containing properties and values
                        that match the {{parameters}} in the translation body
                        */
                    messageTranslationParameters: {},
                    messageArray: [],
                    windowSize: 'md',
                    headerClass: '',
                    okButtonText: 'SB_OK',
                    cancelButtonText: '',
                    animate: true,
                    iconClass: ''
                }, // defaults
                options); // passed options

            var modalInstance = this.$uibModal.open({
                animation: opts.animate,
                backdrop: opts.staticBackdrop ? 'static' : true, // prevent modal from closing on backdrop click
                templateUrl: '/Scripts/app/shared/controllers/confirmation-popup.template.html?v=' + this.$window.EveryBuddy.Version,
                controller: 'confirmationPopup',
                size: opts.windowSize,
                resolve: {
                    title: function () {
                        return opts.title;
                    },
                    message: function () {
                        return opts.message;
                    },
                    translateMessage: function () {
                        return opts.translateMessage;
                    },
                    messageTranslationParameters: function () {
                        return opts.messageTranslationParameters;
                    },
                    messageArray: function () {
                        return opts.messageArray;
                    },
                    okButtonText: function () {
                        return opts.okButtonText;
                    },
                    cancelButtonText: function () {
                        return opts.cancelButtonText;
                    },
                    discardButtonText: function () {
                        return opts.discardButtonText;
                    },
                    iconClass: function()
                    {
                            return opts.iconClass;
                    },
                    headerClass: function()
                    {
                            return opts.headerClass;
                    }
                }
            });

            return modalInstance;
        };

        successAlert(options: any) {
            var opts = {};

            angular.extend(
                opts, // dest
                {
                    title: 'SB_Success',
                    message: 'SB_Success',
                    iconClass: 'fas fa-check'
                }, // defaults
                options); // passed options

            return this.simpleAlert(opts);
        }

        errorAlert(options: any) {
            var opts = {};

            angular.extend(
                opts, // dest
                {
                    title: 'SB_Error',
                    message: 'SB_Error_Generic',
                    windowSize: 'md',
                    okButtonText: 'SB_OK',
                    cancelButtonText: '',
                    animate: true,
                    iconClass: 'fas fa-exclamation-triangle'
                }, // defaults
                options); // passed options

            return this.simpleAlert(opts);
        };

        pleaseWaitModal(options: any) {
            var opts: any = {};

            angular.extend(
                opts, // dest
                {
                    message: 'SB_Saving_changes_please_wait',
                    windowSize: 'sm',
                    animate: true
                }, // defaults
                options); // passed options

            var pleaseWaitModalInstance = this.$uibModal.open({
                animation: opts.animate,
                backdrop: 'static', // prevent modal from closing on backdrop click
                templateUrl: '/Scripts/app/shared/controllers/please-wait-popup.template.html',
                controller: 'pleaseWaitPopupController',
                size: opts.windowSize,
                resolve: {
                    message: function () { return opts.message; }
                }
            });

            return pleaseWaitModalInstance;
        };
    });
