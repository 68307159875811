angular.module('messaging.services.allMessagesService', [
        'shared.services.httpCoreApi'
])
    .service('allMessagesService', class AllMessagesService {

        httpCoreApi: any;

        urlBase: string;
        config: any;

        static $inject = ['httpCoreApi'];

        constructor(httpCoreApi) {
            this.httpCoreApi = httpCoreApi;
            this.urlBase = 'Communication/';
            this.config = {
                controllerType: httpCoreApi.controllerTypes.Admin
            };
        }

        getSentMessages(params: any) {
            return this.httpCoreApi.get(this.urlBase + 'AllMessages', {
                ...this.config,
                urlParams: params
            });
        }

        getMessage(id: number) {
            return this.httpCoreApi.get(`${this.urlBase}Message/${id}`, {
                ...this.config
            });
        }

        getRecipients(messageId: number, params: any) {
            return this.httpCoreApi.get(`${this.urlBase}Message/${messageId}/Recipients`, {
                ...this.config,
                urlParams: params
            });
        }

        getSenders(params: any) {
            return this.httpCoreApi.get(`${this.urlBase}MessageSenders`, {
                ...this.config,
                urlParams: params
            });
        }
});
