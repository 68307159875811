'use strict';

angular.module('subjectClass.components.subjectClassMemberListPopupComponent', [
    'shared.components.sbModalHeader',
    'subjectClass.directives.subjectClassMemberList'
]).component('subjectClassMemberListPopupComponent', {
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    templateUrl: '/Scripts/app/subjectClass/components/subjectClass-member-list-popup.template.html',
    controller: [
        function () {

            this.$onChanges = function (changes) {
                if (changes.resolve && changes.resolve.currentValue) {
                    this.subjectClassId = changes.resolve.currentValue.subjectClassId;
                    this.subjectClassTitle = changes.resolve.currentValue.subjectClassTitle;
                }
            }.bind(this);

            this.close = function () {
                this.modalInstance.dismiss('cancel');
            }.bind(this);
        }
    ]
});