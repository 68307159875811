const csvConfig = { label: 'CSV', mime: 'text/csv;charset=utf-8', format: '.csv', iconName: 'file-excel', iconColor: '#217346' };

angular.module('shared.file.constants', [])
    .constant('fileTypeEnum', {
        CSV: { id: 1, ...csvConfig, isExportable: true},
        CSVFromResponse: { id: 2, ...csvConfig, isExportable: false},
        PDF: { id: 3, label: 'PDF', mime: 'application/pdf', format: '.pdf', iconName: 'file-pdf', iconColor: '#fa0f00', isExportable: true,
            config: { e: { label: 'SB_Next_Previous_Events' },
                    n: { label: 'SB_Attendance_Note' },
                    s: { label: 'SB_Signature_Area' },
                    t: { label: 'SB_Time_In_Out_Area' }
                    }}
    })
    .constant('languageFormats', [
       { id: 'zh_CN', isUnicode: true, isRtl: false  },
       { id: 'th_TH', isUnicode: true, isRtl: false  },
       { id: 'en_GB', isUnicode: false, isRtl: false  },
       { id: 'ru_RU', isUnicode: false, isRtl: false  },
       { id: 'es_ES', isUnicode: false, isRtl: false  },
       { id: 'ar_AE', isUnicode: true, isRtl: true  },
       { id: 'ko_KR', isUnicode: true, isRtl: false  },
       { id: 'pt_PT', isUnicode: false, isRtl: false  },
       { id: 'de_DE', isUnicode: false, isRtl: false  }
    ]);
