angular
    .module('transport.services.transportConfigurationMethodService', [])
    .factory('transportConfigurationMethodService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebOrganisationTransportMethod/';
            var archivingUrlBase = '/webapi/WebOrganisationTransportArchiving/';

            var service = {};

            service.getMethods = function () {
                return $http.get(urlBase + 'GetMethods')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getActiveMethods = function () {
                return $http.get(urlBase + 'GetActiveMethods')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportMethod = function (transportMethodId)
            {
                return $http.get(urlBase + 'GetTransportMethod', { params: { transportMethodId: transportMethodId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.editTransportMethod = function (transportMethod)
            {
                return $http.put(urlBase + 'EditTransportMethod', transportMethod)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getMethodsSupportingRoutes = function () {
                return $http.get(urlBase + 'GetMethodsSupportingRoutes')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.addMethod = function (transportMethod) {
                return $http.post(urlBase + 'AddMethod', transportMethod)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportMethodArchivingInfo = function (transportMethodId)
            {
                return $http.get(archivingUrlBase + 'GetTransportMethodArchivingInfo', { params: { transportMethodId: transportMethodId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.archiveMethod = function (transportMethod)
            {
                return $http.put(archivingUrlBase + 'ArchiveMethod', { Id: transportMethod.transportMethodId })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            return service;
        }
    ]);