import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  constructor() { }

  checkBodyForClass(className: string) {
    return document.getElementsByTagName('body')[0].classList.contains(className);
  }

  isWindowWidthLargerThan(pixels: number) {
    return window.innerWidth > pixels;
  }

  isXsDevice() {
      const xsMaxWidth = 767;
      return !this.isWindowWidthLargerThan(xsMaxWidth);
  }

  isXxlDevice() {
    const xxlMinWidth = 1800;
    return this.isWindowWidthLargerThan(xxlMinWidth - 1);
}

  isMobileApp() {
    return this.checkBodyForClass('mobile-app')
      // return document.getElementsByTagName('body')[0].classList.contains('mobile-app');
  }

  isReactNativeWebview() {
      return window.ReactNativeWebView !== undefined;
  }

  postReactNativeWebviewMessage(message: string) {
      window.ReactNativeWebView.postMessage(message);
  }

  isModalOnly() {
    return this.checkBodyForClass('modal-only');
  }

  getShowModalType() {
      let modalType;
      if (!this.checkBodyForClass('modal-only')) {
          return;
      }
      [ ...Array(10) ].forEach((item, i) => {
          if (this.checkBodyForClass(`modal-type-${i}`)) {
              modalType = i;
          }
      });
      return modalType;
  }

  calculateAppHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }
}
