

angular.module('ccasignupModule').component('ccaStaffScheduleAA', {
        templateUrl: '/Scripts/app/ccaSignUp/DetailsAA/cca-staff-schedule-aa.template.html',
        bindings: {
            $parent: '<'
        },
        controller: class CcaStaffScheduleAACtrl {

            $parent: any;

            $state: any;
            $translate: any;
            $filter: any;
            $uibModal: any;
            $window: any;
            $compile: any;
            $timeout: any;
            $sce: any;
            signUpRepository: any;
            arrayService: any;
            simpleAlertService: any;

            signUp: any;
            timeSlotted: boolean;
            signUpEvents: Array<any>;
            selectedEvent: any;
            attendees: Array<any>;

            sortType: string;
            sortReverse: boolean;
            searchFilters: any;

            selectedEventExportTableHeaders: Array<any>;
            bulkExportTableHeaders: Array<any>;
            selectedEventExportData: Array<any>;
            bulkExportData: Array<any>;
            selectedEventExportInfo: Array<any>;
            bulkExportReady: boolean = false;

            static $inject = ['$state', '$uibModal', '$window', '$compile', '$timeout', '$sce', '$filter', '$translate', 'signUpRepository', 'arrayService', 'simpleAlertService'];

            constructor($state, $uibModal, $window, $compile, $timeout, $sce, $filter, $translate, signUpRepository, arrayService, simpleAlertService) {
                this.$state = $state;
                this.$uibModal = $uibModal;
                this.$window = $window;
                this.$compile = $compile;
                this.$timeout = $timeout;
                this.$sce = $sce;
                this.$filter = $filter;
                this.$translate = $translate
                this.signUpRepository = signUpRepository;
                this.arrayService = arrayService;
                this.simpleAlertService = simpleAlertService;
            }

            $onInit() {
                this.signUp = {};
                this.timeSlotted = false;
                this.signUpRepository.getSignUp(this.$state.params.signUpId).then(signUp => {
                    this.signUp = signUp;

                    if (this.$parent) {
                        // update parent view with any change in sign up state (generating, active etc.)
                        this.$parent.signUp = signUp;
                    }

                    if (signUp.isTimeSlot)
                    {
                        this.timeSlotted = true;
                        this.signUpRepository
                            .getStaffWithAllEvents(this.$state.params.signUpId)
                            .then(signUpStaff => {
                                this.signUpEvents = signUpStaff;
                                this.filterBulkExportData();
                            });

                this.$translate.onReady().then(() => {

                    this.selectedEventExportTableHeaders = [
                        {content: this.$filter('translate')('SB_Group'), onlyData: true},
                        {content: this.$filter('translate')('SB_Staff'), onlyData: true},
                        {content: this.$filter('translate')('SB_Event'), onlyData: true, isEventColumn: true},
                        {content: this.$filter('translate')('SB_Date'), onlyData: true},
                        this.$filter('translate')('SB_Time'),
                        this.$filter('translate')('SB_Name'),
                        this.$filter('translate')('SB_Year'),
                    ];

                    if (this.signUp.isPTC) {
                        this.selectedEventExportTableHeaders.push(this.$filter('translate')('SB_Class'))
                    }

                    this.bulkExportTableHeaders = this.selectedEventExportTableHeaders
                        .map(column => {return {content: column.content || column, onlyData: column.onlyData && !column.isEventColumn }});

                });
                    } else {
                        this.signUpRepository
                            .getStaffWithBookedEvents(this.$state.params.signUpId)
                            .then(signUpStaff => {
                                this.signUpEvents = signUpStaff;
                                this.filterBulkExportData();
                            });
                    }
                });

                this.sortType = "eventFor";
                this.sortReverse = false;

                this.searchFilters = { "staffName": "", "teamTitle": "" };
            }


            showAttendees(anEvent) {
                this.selectedEvent = anEvent;
                this.attendees = null;
                this.signUpRepository.getCcaStaffAttendees(anEvent.owningPersonId, anEvent.teamId, anEvent.title, this.timeSlotted)
                    .then(attendees => {
                        for (var i = attendees.length; i--;)
                        {
                            attendees[i].fromDate = moment(attendees[i].from).startOf('day').toISOString();
                        }

                        this.attendees = attendees;
                        this.selectedEventExportData = this.attendeesToExportData(attendees, 0, true);
                        this.selectedEventExportInfo = [{label: 'SB_Staff', text: this.selectedEvent.staffName}]
                    });
            }

            search(item: any) {
                return item => {
                    var matchTeamTitle = this.searchFilters.teamTitle === "";
                    var matchStaffName = this.searchFilters.staffName === "";
                    if (matchTeamTitle && matchStaffName) {
                        return true;
                    }

                    matchTeamTitle = (this.searchFilters.teamTitle !== "" && item.eventFor.toLowerCase().indexOf(this.searchFilters.teamTitle.toLowerCase()) >= 0)
                    matchStaffName = (this.searchFilters.staffName !== "" && item.staffName.toLowerCase().indexOf(this.searchFilters.staffName.toLowerCase()) >= 0)

                    return (matchTeamTitle || matchStaffName);
                }
            };

            pupilStaffDelete(pupilId, eventId) {
                const modalInstance = this.simpleAlertService.simpleAlert({
                    title: 'SB_Confirm',
                    message: 'SB_Please_Confirm_That_You_Wish_To_Remove_This_Pupil_From_The_Event',
                    windowSize: 'sm',
                    okButtonText: 'SB_Delete',
                    cancelButtonText: 'SB_Cancel'
                });

                modalInstance.result.then(() => {
                    this.signUpRepository.postStaffDeletePupilEvent(eventId, pupilId, this.selectedEvent.owningPersonId, this.selectedEvent.teamId, this.selectedEvent.title, this.timeSlotted)
                        .then(attendees => {
                            for (var i = attendees.length; i--;)
                            {
                                attendees[i].fromDate = moment(attendees[i].from).startOf('day').toISOString();
                            }

                            this.attendees = attendees
                        });
                }, () => {
                });
            };

            attendeesToExportData(attendees: Array<any>, bulkEvent: any, addDayHeading: boolean) {
                const newExportData = [];
                attendees.forEach((attendee, index) => {
                    // Need day heading if defined by filterBulkExportData() as new day, or if a new day for this row
                    const newDay = (addDayHeading && index === 0) || (index > 0 && !moment(attendee.from).isSame(moment(attendees[index - 1].from), 'day'));
                    if (newDay) {
                        newExportData.push([{
                            content: [this.$filter('date')(attendee.from, 'EEEE dd/MMM/yyyy')],
                            style: 'tdLabel',
                            nonData: true
                        }]);
                    }
                    // Added PTC vs non-PTC logic before realising this only applies to PTC
                    // Will keep this for future use to allow exporting of non-PTC schedules
                    const attendeeInfoColspan = this.signUp.isPTC ? 3 : 2;
                    const attendeeInfo = attendee.blockedOut ? [{ content: this.$filter('translate')('SB_Blocked'), colspan: attendeeInfoColspan }] : (attendee.personId ? [`${attendee.lastName}, ${attendee.firstName} ${attendee.otherName ? `(${attendee.otherName})` : ''}`, attendee.yearName ] : [{ content: this.$filter('translate')('SB_Available_to_book'), style: 'muted', colspan: attendeeInfoColspan, nonData: true }]);
                    if (this.signUp.isPTC && !attendee.blockedOut && attendee.personId) {
                        attendeeInfo.push(attendee.className);
                    }
                    const row = [
                        {content: bulkEvent.eventFor || this.selectedEvent.eventFor, onlyData: true},
                        {content: bulkEvent.staffName || this.selectedEvent.staffName, onlyData: true},
                        {content: attendee.eventTitle, onlyData: !bulkEvent},
                        {content: this.$filter('date')(attendee.from, 'dd/MM/yyyy'), onlyData: true},
                        this.$filter('date')(attendee.from, 'HH:mm'),
                        ...attendeeInfo
                    ];
                    newExportData.push(row);
                })
                return (newExportData);
            }

            filterBulkExportData() {
                const uniqueGroupEvents = this.arrayService.uniqueBy(this.signUpEvents, event => { return event.eventFor; });
                const groupNames = uniqueGroupEvents.map(event => {return event.eventFor});
                groupNames.sort();
                this.bulkExportData = [];
                let promisesResolved = 0;
                const groups = [];
                groupNames.forEach(group => {
                    const groupEvents = this.signUpEvents.filter(event => {return event.eventFor === group; });
                    groupEvents.sort((a, b) => {
                        return a.date - b.date;
                    });
                    groups.push(groupEvents);
                    groupEvents.forEach(event => {
                        this.signUpRepository.getCcaStaffAttendees(event.owningPersonId, event.teamId, event.title, this.timeSlotted)
                        .then(attendees => {
                            event.attendees = attendees;
                            promisesResolved ++;
                            if (promisesResolved === this.signUpEvents.length) {
                                // Generate PDF once all data has been returned
                                groups.forEach((group, groupIndex) => {
                                    group.forEach((groupEvent, eventIndex) => {
                                        const pushRow = row => {
                                            row.page = groupIndex;
                                            this.bulkExportData.push(row);
                                        }
                                        if (eventIndex === 0) {
                                            pushRow({
                                                data: this.bulkExportTableHeaders,
                                                pageHeadings: [groupEvent.eventFor],
                                                pageInfo: [
                                                    {label: 'SB_Staff', text: groupEvent.staffName}
                                                ]
                                            })
                                        }
                                        const newDay = eventIndex === 0 || !moment(group[eventIndex].from).isSame(group[eventIndex - 1].from, 'day');
                                        const tableData = this.attendeesToExportData(groupEvent.attendees, groupEvent, newDay);
                                        tableData.forEach(row => {
                                            pushRow({content: row});
                                        });
                                    });
                                });
                                this.bulkExportReady = true;
                            }
                        });
                    });
                })
            }
        }
});
