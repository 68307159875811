'use strict';

angular.module('ccasignupModule').component('ccaModule', {
    templateUrl: '/Scripts/app/ccaSignUp/SignUpModule/cca-signup-module.template.html',
    controller: [
    '$scope',
    '$state',
    '$uibModal',
    '$timeout',
    '$q',
    '$document',
    'organisationRepository',
    'signUpRepository',
    'cachedLookupService',
    'simpleAlertService',
    'searchFilterTypes',
    'dateRangeLists',
    function ($scope, $state, $uibModal, $timeout, $q, $document, organisationRepository, signUpRepository, cachedLookupService, simpleAlertService, searchFilterTypes, dateRangeLists) {
        $scope.pageReady = false;
        $scope.moduleSignUpTypeId = $state.params.moduleSignUpTypeId;

        $scope.resetSearchFilters = function () {
            $scope.searchFilters = { statusId: 0, ccaSignUpTypeId: $scope.moduleSignUpTypeId };
        }

        $scope.resetSearchFilters();

        $scope.sortType = 'openDate'; // set the default sort type
        $scope.sortReverse = true; // set the default sort order

        $scope.searchFilterTypes = searchFilterTypes;
        $scope.dateRangeLists = dateRangeLists;

        $scope.currentPage = 1;
        $scope.maxNumberOfPages = window.EveryBuddy.PaginationMaxNumberOfPages;

        $scope.pageSizes = [
            { pageSize: undefined, label: 'SB_All' },
            { pageSize: 10, label: '10' },
            { pageSize: 20, label: '20' },
            { pageSize: 50, label: '50' },
            { pageSize: 100, label: '100' }
        ];

        $scope.textSearch = '';

        $scope.isOrganisationAdmin = false;

        $scope.statuses = [
            {
                id: 0,
                name: 'SB_All'
            },
            {
                id: 1,
                name: 'SB_Managing'
            },
            {
                id: 2,
                name: 'SB_Generating'
            },
            {
                id: 3,
                name: 'SB_CcaStatus_Active'
            },
            {
                id: 4,
                name: 'SB_Archived'
            },
            {
                id: 5,
                name: 'SB_Processing'
            }];

        $scope.deleteSignUp = function (signUpId) {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'confirmDeleteSignUpModal.html',
                controller: 'ConfirmDeleteSignUpModalInstanceCtrl',
                size: 'sm'
            });

            modalInstance.result.then(function () {
                $scope.signUps = null;
                signUpRepository.postDeleteSignUp(signUpId)
                    .then(function () {
                        signUpRepository.getSignUps($scope.signUpsFrom, $scope.signUpsTo, $scope.moduleSignUpTypeId).then(function (signUps) { $scope.signUps = signUps; });
                    });
            }, function () {
            });
        };

        cachedLookupService.getSystemSetting('CCASignUp_DashboardContainers_SelectedCcaSignUpStatusId')
            .then(function (data) {

                if (data === -1)
                    $scope.searchFilters.statusId = 0;
                else
                    $scope.searchFilters.statusId = data;

                $timeout(function () {
                    // now it's loaded add the watcher to save changes
                    $scope.$watch('searchFilters.statusId',
                        function (newValue, oldValue) {
                            if (newValue === oldValue)
                                return;

                            var valueToSave = 0;

                            if (newValue === undefined)
                                valueToSave = -1;
                            else
                                valueToSave = newValue;

                            cachedLookupService.saveUserSettings({ 'CCASignUp_DashboardContainers_SelectedCcaSignUpStatusId': valueToSave });
                        });
                });
            });

        cachedLookupService.getSystemSetting('CCASignUp_DashboardContainers_PageSize')
            .then(function (data) {
                if (data === -1)
                    $scope.pageSize = undefined;
                else
                    $scope.pageSize = data;

                $timeout(function () {
                    // now it's loaded add the watcher to save changes
                    $scope.$watch('pageSize',
                        function (newValue, oldValue) {
                            if (newValue === oldValue)
                                return;

                            var valueToSave = 0;

                            if (newValue === undefined)
                                valueToSave = -1;
                            else
                                valueToSave = newValue;

                            cachedLookupService.saveUserSettings({ 'CCASignUp_DashboardContainers_PageSize': valueToSave });
                        });
                });
            });

            cachedLookupService.getSystemSetting('CCASignUp_DashboardContainers_SignUpsFrom')
            .then(function (data) {
                if (data) {
                    $scope.signUpsFrom = moment(data).startOf('day');
                  }
                  else {
                    $scope.signUpsFrom = moment().startOf('day').subtract(2, 'months');
                  }

                  $scope.refreshGroups();

                $timeout(function () {
                    // now it's loaded add the watcher to save changes
                    $scope.$watch('signUpsFrom',
                        function (newValue, oldValue) {
                            if (newValue === oldValue)
                                return;

                            var valueToSave = 0;

                            if (newValue === undefined)
                                valueToSave = -1;
                            else
                                valueToSave = newValue.format('YYYY-MM-DD');

                            cachedLookupService.saveUserSettings({ 'CCASignUp_DashboardContainers_SignUpsFrom': valueToSave });
                        });
                });
            });

            cachedLookupService.getSystemSetting('CCASignUp_DashboardContainers_SignUpsTo')
            .then(function (data) {
                if (data) {
                    $scope.signUpsTo = moment(data).endOf('day');
                  }
                  else {
                    $scope.signUpsTo = moment().endOf('day');
                  }

                  $scope.refreshGroups();

                $timeout(function () {
                    // now it's loaded add the watcher to save changes
                    $scope.$watch('signUpsTo',
                        function (newValue, oldValue) {
                            if (newValue === oldValue)
                                return;

                            var valueToSave = 0;

                            if (newValue === undefined)
                                valueToSave = -1;
                            else
                                valueToSave = newValue.format('YYYY-MM-DD');

                            cachedLookupService.saveUserSettings({ 'CCASignUp_DashboardContainers_SignUpsTo': valueToSave });
                        });
                });
            });

        var promises = [];
        promises.push(organisationRepository.get());
        promises.push(cachedLookupService.isOrganisationAdmin());

        if ($scope.moduleSignUpTypeId)
        {
            promises.push(organisationRepository.getCcaType($scope.moduleSignUpTypeId));
        }

        $q.all(promises).then(function (responses) {
            if (responses && responses.length) {
                $scope.organisation = responses[0];
                if (responses.length > 0) {
                    $scope.isOrganisationAdmin = responses[1];

                    if (responses.length > 1 && responses[2].isSuccess) {
                        $scope.signUpType = responses[2].data;
                    }
                }
            }
            $scope.pageReady = true;
        });

        $scope.refreshGroups = function () {
            if (!$scope.signUpsFrom || !$scope.signUpsTo) {
                return
            }
            $scope.refreshing = true;
            signUpRepository.getSignUps($scope.signUpsFrom, $scope.signUpsTo, $scope.moduleSignUpTypeId, $scope.textSearch)
                .then(function (signUps) {
                    $scope.refreshing = null;
                    $scope.signUps = signUps;
                })
                .catch(function (error) {
                    $scope.refreshing = null;
                    console.error(error);
                    simpleAlertService.errorAlert({
                        message: 'SB_Error_loading_data_check_search_filters'
                    });
                });
        };

        $scope.addSignUp = function ()
        {
            $state.go('ccaCreate', { moduleSignUpTypeId: $scope.moduleSignUpTypeId});
        }.bind(this);

        $scope.ccaDetails = function (ccaSignUpId) {
            $state.go('ccaDetails', { signUpId: ccaSignUpId, moduleSignUpTypeId: $scope.moduleSignUpTypeId });
        }.bind(this);

        $scope.ccaDetailsAutoAlloc = function (ccaSignUpId) {
            $state.go('ccaDetailsAA', { signUpId: ccaSignUpId, moduleSignUpTypeId: $scope.moduleSignUpTypeId });
        }.bind(this);

        $scope.editDetails = function (ccaSignUpId) {
            $state.go('ccaEdit', { signUpId: ccaSignUpId, moduleSignUpTypeId: $scope.moduleSignUpTypeId });
        }.bind(this);

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'shortDate', 'dd-MMM-yyyy', 'dd MMM yyyy'];
        $scope.format = 'dd MMM yyyy';
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.open1 = function () {
            $scope.popup1.opened = true;
        };

        $scope.popup1 = {
            opened: false
        };

        $scope.openFrom = function () {
            $scope.popupFrom.opened = true;
        };

        $scope.popupFrom = {
            opened: false
        };

        $scope.openTo = function () {
            $scope.popupTo.opened = true;
        };

        $scope.popupTo = {
            opened: false
        };


        $scope.ccaStatusText = function (statusId) {
            switch (statusId) {
                case 1:
                    return 'SB_Managing';
                case 2:
                    return 'SB_Generating';
                case 3:
                    return 'SB_CcaStatus_Active';
                case 4:
                    return 'SB_Archived';
                case 5:
                    return 'SB_Processing';
                default:
                    return 'SB_Managing';
            }
        };

        $scope.range = function (min, max, step) {
            step = step || 1;
            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };

        $scope.search = function (item) {

            var match = false;
            var statusMatch = false;

            if ($scope.searchFilters.statusId > 0) {
                statusMatch = (item.status === $scope.searchFilters.statusId);
            } else {
                statusMatch = true;
            }

            var typeMatch = false;

            if ($scope.searchFilters.ccaSignUpTypeId > 0) {
                typeMatch = (item.ccaSignUpTypeId === $scope.searchFilters.ccaSignUpTypeId);
            } else {
                typeMatch = true;
            }
            return statusMatch && typeMatch;
        };


        $scope.cloneSignUp = function (ccaSignUp)
        {
            var modalInstance = $uibModal.open({
                animation: this.animationsEnabled,
                templateUrl: '/Scripts/app/ccaSignUp/SignUpModule/cca-signup-module-clone.template.html',
                controller: 'ccaModuleCloneController',
                size: 'lg',
                resolve: {
                    ccaSignUp: function () {

                        var deferred = $q.defer();

                        signUpRepository.getSignUp(ccaSignUp.ccaSignUpId)
                            .then(function (response) {
                                deferred.resolve(response);
                            })
                            .catch(function (error) {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                            });

                        return deferred.promise;

                    }.bind(this)
                }
            });

        }.bind(this);

        $scope.getTitle = () => {
            return $document[0].title;
        }
    }
]});

angular.module('ccasignupModule').controller('ConfirmDeleteSignUpModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);

angular.module('ccasignupModule').filter('filterMultiple', [
    '$filter', function ($filter) {
        return function (items, keyObj) {
            var filterObj = {
                data: items,
                filteredData: [],
                applyFilter: function (obj, key, firstLoop) {
                    var fData = [];
                    if (this.filteredData.length === 0 && firstLoop === 1)
                        this.filteredData = this.data;
                    if (obj) {
                        var fObj = {};
                        if (!angular.isArray(obj)) {
                            fObj[key] = obj;
                            fData = fData.concat($filter('filter')(this.filteredData, fObj));
                        } else if (angular.isArray(obj)) {
                            if (obj.length > 0) {
                                for (var i = 0; i < obj.length; i++) {
                                    if (angular.isDefined(obj[i])) {
                                        fObj[key] = obj[i];
                                        fData = fData.concat($filter('filter')(this.filteredData, fObj));
                                    }
                                }

                            }
                        }
                        this.filteredData = fData;
                    }
                }
            };
            if (keyObj) {
                var first = 1;
                angular.forEach(keyObj, function (obj, key) {
                    filterObj.applyFilter(obj, key, first);
                    first = 0;
                });
            }
            return filterObj.filteredData;
        };
    }
]);

angular.module('ccasignupModule').filter('filterAndMultiple', [
    '$filter', function ($filter) {
        return function (items, keyObj) {
            var filterObj = {
                data: items,
                filteredData: [],
                applyFilter: function (obj, key) {
                    var fData = [];
                    if (this.filteredData.length === 0)
                        this.filteredData = this.data;
                    if (obj) {
                        var fObj = {};
                        if (!angular.isArray(obj)) {
                            fObj[key] = obj;
                            fData = fData.concat($filter('filter')(this.filteredData, fObj));
                        } else if (angular.isArray(obj)) {
                            if (obj.length > 0) {
                                for (var i = 0; i < obj.length; i++) {
                                    if (angular.isDefined(obj[i])) {
                                        fObj[key] = obj[i];
                                        fData = fData.concat($filter('filter')(this.filteredData, fObj));
                                    }
                                }

                            }
                        }
                        this.filteredData = fData;
                    }
                }
            };
            if (keyObj) {
                angular.forEach(keyObj, function (obj, key) {
                    filterObj.applyFilter(obj, key);
                });
            }
            return filterObj.filteredData;
        };
    }
]);


angular.module('ccasignupModule').directive('confirmationNeeded', function () {
    return {
        link: function (scope, element, attr) {
            var msg = attr.confirmationNeeded || 'Are you sure?';
            var clickAction = attr.confirmedClick;
            element.bind('click', function (event) {
                if (window.confirm(msg))
                    scope.$eval(clickAction);
                return false;
            });
        }
    };
});

angular.module('ccasignupModule').directive('confirmClick', function ($window) {
    var i = 0;
    return {
        restrict: 'A',
        priority: 1,
        compile: function (tElem, tAttrs) {
            var fn = '$$confirmClick' + i++,
                _ngClick = tAttrs.ngClick;
            tAttrs.ngClick = fn + '($event)';

            return function (scope, elem, attrs) {
                var confirmMsg = attrs.confirmClick || 'Are you sure?';

                scope[fn] = function (event) {
                    if ($window.confirm(confirmMsg)) {
                        scope.$eval(_ngClick, { $event: event });
                    }
                };
            };
        }
    };
});



angular.module('ccasignupModule').controller('ModalInstanceCtrl', [
    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}]);
