angular.module('shared.directives.draggableModal', ['ui.router', 'ui.bootstrap'])
    .directive('draggableModal', function () {
        return {

            /*
            just apply this as an attribute to the div containing your popup and it should do the rest
            (when it's working and finished)
             */

            restrict: 'AC',
            link: function ($scope, element) {

                alert('you are using the unfinished drag and drop directive- needs fixing!');

                var draggableStr = 'draggableModal';
                var header = $('header', element);

                header.on('mousedown', function(mouseDownEvent) {
                    var modalDialog = $(element);
                    var offset = header.offset();

                    modalDialog.addClass(draggableStr).parents('.modal-dialog').on('mousemove', function(mouseMoveEvent) {
                        $('.' + draggableStr, modalDialog.parents()).offset({
                            top: mouseMoveEvent.pageY - (mouseDownEvent.pageY - offset.top),
                            left: mouseMoveEvent.pageX - (mouseDownEvent.pageX - offset.left)
                        });
                    }).on('mouseup', function() {
                        modalDialog.removeClass(draggableStr);
                    });
                });
            }
        }
    });