'use strict';

angular.module('receptionModule').component('receptionStaffSchedule', {
    templateUrl: '/Scripts/app/reception/controllers/reception-staff-schedule.template.html?v=', // + window.EveryBuddy.Version,
    controller:
    [
        '$scope',
        '$filter',
        '$translate',
        '$window',
        '$sce',
        '$templateRequest',
        '$compile',
        '$timeout',
        'staffRepository',
        'personService',
        'eventsService',
        'cachedLookupService',
        'simpleAlertService',
        'dateRangeLists',
        function ($scope, $filter, $translate, $window, $sce, $templateRequest, $compile, $timeout, staffRepository, personService, eventsService, cachedLookupService, simpleAlertService, dateRangeLists)
        {
            $scope.isLoadingStaff = false;
            var loading = 0;
            var scheduleLoading = 0;
            var showBlockOut = false;

            $scope.isLoading = function() { return loading !== 0; };
            $scope.isScheduleLoading = function () { return scheduleLoading !== 0; };
            $scope.currentPersonId = -1;

            $scope.showBlockOutButtons = function() { return showBlockOut; };

            $scope.dateRangeLists = dateRangeLists;

            cachedLookupService.getSystemSetting('Reception_StaffSchedule_ShowBlockOut')
                .then(function (data) {
                    showBlockOut = data
                });

            personService.getCurrentPersonId()
                .then(function (data)
                {
                    $scope.currentPersonId = data;
                })

            $scope.filter = {
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day').add(29, 'days'),
                sortColumn: 'filteredName',
                sortReversed: false
            };

            $translate.onReady().then(function () {
                $scope.selectText = $filter('translate')('SB_Select');
            });

            $scope.staff = [];
            $scope.selectedStaffSchedule = null;
            $scope.selectedStaffMember = null;

            $scope.setSortColumn = function(columnName)
            {
                if ($scope.filter.sortColumn !== columnName)
                {
                    $scope.filter.sortColumn = columnName;
                    $scope.filter.sortReversed = false;
                } else
                {
                    $scope.filter.sortReversed = !$scope.filter.sortReversed;
                }
            };

            $scope.selectStaffMember = function(staffMember)
            {
                $scope.selectedStaffMember = staffMember;

                // load their schedule using the given date range
                scheduleLoading++;
                $scope.selectedStaffSchedule = null;

                staffRepository
                    .getScheduleForStaffMember(staffMember.personId, $scope.filter.startDate, $scope.filter.endDate)
                    .then(function(data)
                    {
                        scheduleLoading--;
                        $scope.selectedStaffSchedule = data;
                        var eventTypesList = [];
                        $scope.selectedStaffSchedule.forEach(function(event) {
                            eventTypesList.push({id: event.categoryName, label: event.categoryName});
                        });
                        $scope.eventTypes = $filter('unique')(eventTypesList, 'id');
                        $scope.resetEventFilter();
                    });

            };

            $scope.refresh = function ()
            {
                $scope.isLoadingStaff = true;

                staffRepository
                    .getStaffForSchedule($scope.filter.startDate, $scope.filter.endDate)
                    .then(function(data)
                    {
                        $scope.staff = data;
                        $scope.isLoadingStaff = false;
                    });

                if ($scope.selectedStaffMember)
                {
                    // someone is selected- make sure we reload their schedule as the dates might be different/ something may have been added
                    $scope.selectStaffMember($scope.selectedStaffMember);
                }
            };

            $scope.blockOutEvent = function(event, blockState)
            {
                var modal = simpleAlertService.simpleAlert({
                    title: 'SB_Title_' + (blockState ? '' : 'Un') + 'Block',
                    message: 'SB_Confirmation_' + (blockState ? '' : 'Un') + 'Block',
                    windowSize: 'md',
                    okButtonText: 'SB_Yes',
                    cancelButtonText: 'SB_Cancel'
                });

                modal.result.then(function()
                {
                    eventsService.blockOutEvents(event.id, blockState)
                        .then(function(data)
                        {
                            if (!data.isError)
                            {
                                event.isBlockedOut = blockState;
                            } else
                            {
                                simpleAlertService.errorAlert({
                                    message: 'SB_Error_Saving_Message'
                                });
                            }
                        });
                });
            };

            $scope.print = function()
            {
                var templateUrl = $sce.getTrustedResourceUrl('/Scripts/app/reception/controllers/reception-staff-schedule.print.html?v=' + $window.EveryBuddy.Version);

                $templateRequest(templateUrl).then(function (template) {
                    template = '<div>' + template + '</div>';

                    var compiledPrintView = $compile(template)($scope);

                    $timeout(function () {
                        var printerWindow = $window.open('', '', 'width=800, height=600');
                        printerWindow.document.write(compiledPrintView[0].innerHTML);
                    }, 1000);  // wait for a short while,Until all scope data is loaded If any complex one

                }, function () {
                    // An error has occurred
                });
            };

            $scope.eventFilter = function(item)
            {
                    if ($scope.eventTypes.length == $scope.selectedEventTypes.length) {
                        return true;
                    }

                    var selectedEventTypeIds = $scope.selectedEventTypes.map(function(type) {
                        return type.id;
                    });

                    return selectedEventTypeIds.includes(item.categoryName);
            };

            $scope.resetEventFilter = function() {
                $scope.selectedEventTypes = Array.from($scope.eventTypes);
            };

            $scope.refresh();

            $scope.blockActions = {
                Block: 1,
                Unblock: 2
            };

            $scope.getBlockAction = function(event) {
                if (event && event.isTimeSlotted && event.attendeeCount < 1) {
                    if (!event.isBlockedOut) {
                        return $scope.blockActions.Block;
                    } else if (event.isTimeSlotted) {
                        return $scope.blockActions.Unblock;
                    }
                } else {
                    return 0;
                }
            };
        }
    ]});