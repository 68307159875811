declare var Stripe: any;
declare var paysafe: any;
declare var Checkout: any;

angular.module('payments.components.paymentProviderButtons', [
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'ui.bootstrap',

    'ccaAvailable.services.availableRepository',
    'balance.services.balanceService',
    'balance.constants',

    'shared.components.sbModalHeader',

    'shared.services.mathService',
    'shared.services.simpleAlertService',
    'shared.services.cachedLookupService',

    'payments.components.paymentForm',
    'payments.components.paymentFormBraintree',

    'payments.services.paymentsService'
])
    .component('paymentProviderButtons',
        {
            bindings: {
                organisationTitle: '<',
                totalAmount: '<',
                paymentEnabled: '<',
                isBooking: '<',
                paymentCompleteCallback: '&',
                paymentCancelledCallback: '&',
                createPaymentSessionCallback: '&',

                paymentProviderlessTransactionCallback: '&',

                bookingTimeOutCallback: '&',

                signature: '=',
                paymentSessionId: '<',
                acceptVoucherPayments: '<',
                totalVoucherAmount: '<',

                hideCardPayments: '<',

                isParentLoading: '<',
                confirmationMessage: '<',
                isDisabled: '<',

                timeRemaining: '<',
                minutesUntilBookingExpiry: '<',
                signUpEvents: '<',
                signUpId: '<',
            },
            templateUrl: '/Scripts/app/payments/components/provider-payment-buttons.template.html',
            controller: class PaymentProviderButtonsCtrl
            {
                // Dependencies
                $uibModal: any;
                $sce: any;
                $window: any;
                $rootScope: any;
                $scope: any;
                $timeout: any;
                $interval: any;
                availableRepository: any;
                ccaService: any;
                mathService: any;
                simpleAlertService: any;
                cachedLookupService: any;
                balanceService: any;
                financialAccountTypeEnum: any;
                paymentsService: any;

                // Bindings
                organisationTitle: string;
                totalAmount: number;
                paymentEnabled: boolean;
                isBooking: boolean;
                paymentCompleteCallback: any;
                paymentCancelledCallback: any;
                createPaymentSessionCallback: any;
                bookingTimeOutCallback: any;
                paymentProviderlessTransactionCallback: any;
                signature: any;
                paymentSessionId: any;
                acceptVoucherPayments: boolean;
                totalVoucherAmount: number;
                hideCardPayments: boolean;
                isParentLoading: boolean;
                confirmationMessage: string;
                isDisabled: boolean;
                disabledMessage: string;
                timeRemaining: any;
                timeRemainingMins: any;
                timeRemainingSecs: any;
                minutesUntilBookingExpiry: any;
                signUpEvents: any;
                signUpId: any;

                // Variables
                loading: number = 0;
                creditBalance: number = null;
                voucherBalance: number = 0;
                acceptedPaymentMethodGraphic: any = '';
                allowVoucherAccountToGoOverdrawn: boolean = false;
                asiaPayDeviceMode: any = '';
                showZeroBalanceNonChargeableBookingButton: boolean = false;
                paymentSubmitted: boolean = false;
                paymentProvidersEnum;
                paymentProviderConfig: any;
                availableProviders: any;
                baseUrl: string;
                cardProviderCount: number;
                useCredits: boolean;
                isPaymentLoading: boolean;
                paymentMethods: any;
                selectedPaymentMethod: any = {};
                enabledPayments: any;
                payDisabled: boolean;
                secureHash: string;
                noAvailableMethods: boolean = false;
                hideInfo: boolean = false;
                timer: any = null;
                hasBookingTimedOut: boolean = false;
                oldestBookingExpiry: any;

                static $inject = ['$uibModal', '$sce', '$window', '$rootScope', '$scope', '$timeout', '$interval', 'availableRepository', 'ccaService', 'mathService', 'simpleAlertService', 'paymentsService', 'cachedLookupService', 'balanceService', 'financialAccountTypeEnum'];

                constructor(
                    $uibModal,
                    $sce,
                    $window,
                    $rootScope,
                    $scope,
                    $timeout,
                    $interval,
                    availableRepository,
                    ccaService,
                    mathService,
                    simpleAlertService,
                    paymentsService,
                    cachedLookupService,
                    balanceService,
                    financialAccountTypeEnum)
                {
                    this.$uibModal = $uibModal;
                    this.$sce = $sce;
                    this.$window = $window;
                    this.$rootScope = $rootScope;
                    this.$scope = $scope;
                    this.$timeout = $timeout;
                    this.$interval = $interval;

                    this.availableRepository = availableRepository;
                    this.ccaService = ccaService;
                    this.mathService = mathService;
                    this.simpleAlertService = simpleAlertService;
                    this.paymentsService = paymentsService;
                    this.cachedLookupService = cachedLookupService;
                    this.balanceService = balanceService;
                    this.financialAccountTypeEnum = financialAccountTypeEnum;
                }

                $onInit()
                {
                    this.$window.onscroll = () =>
                    {
                        this.onScroll();
                    };

                    if (this.totalAmount == null)
                    {
                        // total amount not available yet, wait until we are notified of its value via $onChanges
                        this.loading++;
                    }
                    else
                    {
                        // we have total amount, determine if non-charge booking button is shown
                        this.showZeroBalanceNonChargeableBookingButton = this.isBooking && this.totalAmount == 0;
                    }

                    if (!!this.acceptVoucherPayments && this.totalVoucherAmount == null)
                    {
                        // total voucher amount not available yet, wait until we are notified of its value via $onChanges
                        this.loading++;
                    }


                    this.cachedLookupService.getSystemSettings([
                        'Payments_CardLogos_ImageUrl',
                        'Payments_Vouchers_AllowAccountToBeOverdrawn'
                    ]).then(data =>
                    {
                        this.acceptedPaymentMethodGraphic = data['Payments_CardLogos_ImageUrl'];
                        this.allowVoucherAccountToGoOverdrawn = data['Payments_Vouchers_AllowAccountToBeOverdrawn'];
                        this.paymentsService.getPaymentProvidersConfigForOrganisation()
                        .then(paymentProviderConfig =>
                        {
                            this.paymentProvidersEnum = this.$window.EveryBuddy.Enums.PaymentProvider;
                            this.paymentProviderConfig = paymentProviderConfig;
                            this.availableProviders = paymentProviderConfig.paymentProviders;

                            this.baseUrl = '';

                            // Don't include voucher payment in card provider count
                            this.cardProviderCount =
                                this.availableProviders.filter(x =>
                                {
                                    return x != this.paymentProvidersEnum.voucher;
                                }).length;

                                this.getAccountBalances();

                            if (this.timeRemaining)
                            {
                                this.startTimer();
                            }
                        })
                        .catch(err =>
                            {
                                this.simpleAlertService.errorAlert(err);
                            });;
                    });
                }

                onScroll()
                {
                    if (this.$window.scrollY > 50) {
                        this.hideInfo = true;
                        this.$scope.$apply();
                    }
                }

                showPaymentInfo()
                {
                    this.$window.scrollTo(0, 0);
                    this.hideInfo = false;
                }

                $onChanges(changes)
                {
                    console.log('changes')
                    if (changes.totalAmount && changes.totalAmount.currentValue != null)
                    {
                        // ensure we have at most two dps.
                        this.totalAmount = this.mathService.roundNumber(this.totalAmount, 2);
                        this.showZeroBalanceNonChargeableBookingButton = this.isBooking && this.totalAmount == 0;
                        this.setDefaultMethod();
                        console.log('amount changed')
                        this.loading--;
                    }

                    if (changes.totalVoucherAmount && changes.totalVoucherAmount.currentValue != null)
                    {
                        this.totalVoucherAmount = this.mathService.roundNumber(this.totalVoucherAmount, 2);
                        this.loading--;
                    }
                };

                getAccountBalances()
                {
                    this.creditBalance = null;
                    this.voucherBalance = null;
                    this.loading += 2;

                    this.balanceService.getBalanceForAccountType(this.financialAccountTypeEnum.Credit)
                        .then(data =>
                        {
                            this.creditBalance = this.mathService.roundNumber(data, 2) || 0;
                            this.loading--;

                            this.balanceService.getBalanceForAccountType(this.financialAccountTypeEnum.Voucher)
                                .then(data =>
                                {
                                    this.voucherBalance = this.mathService.roundNumber(data, 2) || 0;
                                    this.loading--;

                                    this.initPayments();
                                });
                        });
                }

                totalAmountPaidByCredits()
                {

                    if (!this.useCredits)
                    {
                        return 0;
                    }

                    if (this.creditBalance >= this.totalAmount)
                    {
                        return this.totalAmount;
                    }
                    else
                    {
                        return this.creditBalance;
                    }
                }

                totalAmountToPayWithProvider()
                {

                    if (this.useCredits)
                    {
                        if (this.creditBalance >= this.totalAmount)
                        {
                            return 0;
                        }
                        else
                        {
                            return this.mathService.roundNumber(this.totalAmount - this.creditBalance, 2);
                        }
                    }

                    return this.totalAmount;
                }

                shouldShowPaymentMethodGraphic()
                {

                    let nonVoucherMethodAvailable = false;
                    if (this.availableProviders)
                    {
                    for (let i = this.availableProviders.length; i--;)
                    {
                        if (this.availableProviders[i] !== this.paymentProvidersEnum.Voucher)
                        {
                            nonVoucherMethodAvailable = true;
                            break;
                        }
                    }
                }

                    return nonVoucherMethodAvailable &&
                        this.acceptedPaymentMethodGraphic &&
                        this.acceptedPaymentMethodGraphic !== '' &&
                        this.totalAmount > 0;
                }

                showVoucherPaymentButton()
                {

                    if (!this.acceptVoucherPayments || this.showZeroBalanceNonChargeableBookingButton)
                    {
                        return false;
                    }

                    var hasVoucherConfigured = this.availableProviders &&
                    this.availableProviders.some(availableProvider =>
                        availableProvider.provider == this.paymentProvidersEnum.Voucher
                    );

                    if (!hasVoucherConfigured)
                    {
                        return false;
                    }

                    if (this.totalVoucherAmount > this.voucherBalance)
                    {
                        return this.allowVoucherAccountToGoOverdrawn;
                    }

                    return true;
                }

                showCreditPayment()
                {

                    if (this.showZeroBalanceNonChargeableBookingButton)
                    {
                        // no charge
                        return false;
                    }

                    return this.creditBalance > 0;
                }

                showProvider(id)
                {

                    if (this.showZeroBalanceNonChargeableBookingButton)
                    {
                        // no charge
                        return false;
                    }

                    if (this.hideCardPayments && id !== this.paymentProvidersEnum.Voucher)
                    {
                        return false;
                    }
                    const providersHasMethod = this.availableProviders?.some(availableProvider => availableProvider.provider == id)
                    return  providersHasMethod;
                }

                nonChargeableBooking()
                {
                    this.confirmBooking('SB_Book').then(confirmed =>
                    {
                        if (confirmed)
                        {
                            this.paymentProviderlessTransactionCallback({ amountToPayWithCredits: this.totalAmountPaidByCredits() })
                                .catch(err =>
                                    {
                                        this.paymentSessionCreationErrorHandler(err);
                                        this.isPaymentLoading = false;
                                    });
                        }
                    });
                }

                confirmBooking(title: string)
                {
                    return new Promise((resolve, reject) => { resolve(true) });
                }

                totalPaymentInBaseUnits()
                {
                    return this.mathService.roundNumber(this.totalAmountToPayWithProvider() * parseInt(this.paymentProviderConfig.currencyBaseUnitsMultiplier), 2);
                };

                cancelPayment(paymentMethod, paymentFormSubmitted)
                {
                    console.log(paymentMethod + ' payment window quit, cancelling payment.');

                    if (paymentFormSubmitted)
                    {
                        console.log('not cancelling as payment form submitted');
                        return;
                    }

                    // re-enable pay button
                    this.isPaymentLoading = false;

                    if (this.paymentCancelledCallback())
                    {
                        this.paymentCancelledCallback();
                    }
                }

                payWithVoucher()
                {
                    this.createPaymentSessionCallback({
                        providerId: this.paymentProvidersEnum.Voucher,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const dialogue = this.simpleAlertService.simpleAlert({
                                title: 'SB_Pay_with_Voucher',
                                message: 'SB_Pay_with_voucher_explanation',
                                okButtonText: 'SB_Continue',
                                cancelButtonText: 'SB_Cancel'
                            });

                            dialogue.result
                                .then(() =>
                                {
                                    this.paymentCompleteCallback({ token: 'VOUCHER', merchantReference: data.sid });
                                })
                                .catch(() =>
                                {
                                    this.cancelPayment('Voucher', false);
                                });
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                };

                paymentSessionCreationErrorHandler(errorData)
                {

                    // re-enable payment buttons
                    this.paymentSubmitted = false;
                    this.isPaymentLoading = false;

                    if (errorData.isError)
                    {
                        // this is one of our errors
                        this.simpleAlertService.errorAlert({
                            message: errorData.message,
                            messageTranslationParameters: errorData.translationParams
                        });
                        console.error('Problem generating payment session', errorData.message);
                    }
                    else
                    {
                        this.simpleAlertService.errorAlert();
                        console.error('Problem generating payment session', errorData);
                    }

                }

                getProvider(providerId)
                {
                    return this.availableProviders?.find(p => p.provider == providerId);
                }

                payWithStripe()
                {
                    const providerStripe = this.getProvider(this.paymentProvidersEnum.Stripe);

                    this.createPaymentSessionCallback({
                        providerId: providerStripe.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const key = providerStripe.publicKey;
                            const stripeAccountNumber = providerStripe.accountNumber;
                            const stripe = Stripe(key, {
                                stripeAccount: stripeAccountNumber // Stripe Connected Account Number
                            });
                            stripe.redirectToCheckout({
                                sessionId: data.providerSessionId
                            }).then(result =>
                            {
                                console.log(result);
                            }).catch(err =>
                            {
                                // If `redirectToCheckout` fails due to a browser or network
                                // error, display the localized error message to your customer
                                // using `result.error.message`.
                                this.simpleAlertService.errorAlert(err);
                                console.error('Problem creating Stripe payment session', err);
                            });
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                };

                payWithPaySafe()
                {
                    const providerPaySafe = this.getProvider(this.paymentProvidersEnum.PaySafe);
                    this.createPaymentSessionCallback({
                        providerId: this.paymentProvidersEnum.PaySafe,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const key = providerPaySafe.publicKey;
                            const env = this.paymentProviderConfig.isTestMode ? 'TEST' : 'LIVE';
                            const custId = parseInt(providerPaySafe.accountNumber);

                            const amount = this.totalPaymentInBaseUnits();
                            const isoCurrency = this.paymentProviderConfig.isoCurrency;
                            const title = this.organisationTitle;

                            //my Base64 encoded single-use-token API key

                            paysafe.checkout.setup(key,
                                {
                                    amount: amount,
                                    currency: isoCurrency,
                                    environment: env,
                                    companyName: title,
                                    accounts: {
                                        CC: custId
                                    },
                                    threeDS: {
                                        useThreeDSecureVersion2: true
                                    },
                                    imageUrl: 'https://www.schoolsbuddy.net/images/noavatar.png'
                                },
                                (instance, error, result) =>
                                {
                                    if (!result)
                                    {
                                        console.error('paysafe result is null', error);
                                    }
                                    else
                                    {
                                        if (result.token)
                                        {
                                            this.paymentCompleteCallback({ token: result.token, merchantReference: data.sid })
                                                .then(data =>
                                                {
                                                    if (data.success)
                                                    {
                                                        instance.showSuccessScreen('Thank you for your payment.');
                                                    }
                                                    else
                                                    {
                                                        // don't show the instance as it allows them to try again.
                                                        // re-tries for the same payment session must be avoided.
                                                        instance.close();
                                                    }
                                                })
                                                .catch(err =>
                                                {
                                                    instance.close();
                                                    console.error(err);
                                                });
                                        }
                                        else
                                        {
                                            instance.close();
                                            console.error(error);
                                        }
                                    }
                                },
                                stage =>
                                {
                                    if (stage !== 'BeforePayment')
                                    {
                                        return;
                                    }

                                    this.cancelPayment('PaySafe', false);
                                }
                            );

                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                };

                payWithPayFort()
                {
                    const providerPayFort = this.getProvider(this.paymentProvidersEnum.PayFort);

                    this.createPaymentSessionCallback({
                        providerId: this.paymentProvidersEnum.PayFort,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            let i = 0;

                            const config = {
                                baseUrl: this.$sce.trustAsResourceUrl(providerPayFort.publicServiceBaseUrl),
                                merchantId: providerPayFort.accountNumber,
                                accessCode: providerPayFort.secretKey,
                                returnUrl: providerPayFort.returnUrl,
                                customerIp: this.paymentProviderConfig.customerIpAddress,
                                expiryMM: '',
                                expiryYY: '',
                                expiryMMoptions: [],
                                expiryYYoptions: [],
                                paymentAmount: this.totalAmountToPayWithProvider(),
                                paymentSessionId: data.sid,
                                signature: data.sig,
                                isoCurrency: this.paymentProviderConfig.isoCurrency,
                                isoCurrencyNumber: this.paymentProviderConfig.isoCurrencyNumber,
                            };

                            for (i = 1; i <= 12; i++)
                                config.expiryMMoptions.push(('00' + i.toString()).substring(i.toString().length));

                            for (i = (new Date()).getFullYear(); i <= (new Date()).getFullYear() + 10; i++)
                                config.expiryYYoptions.push(i.toString().substring(2, 4));

                            let timeLeft = {
                                mins: this.timeRemainingMins,
                                secs: this.timeRemainingSecs
                            };

                            // not fussed about the result of this as we're going to get redirected
                            const payFortPopup = this.$uibModal.open({
                                animation: true,
                                backdrop: 'static', // clicking outside window does nto close
                                keyboard: false, // escape key does not close
                                component: 'paymentForm',
                                resolve: {
                                    provider: () =>
                                    {
                                        return providerPayFort.provider;
                                    },
                                    config: () =>
                                    {
                                        return config;
                                    },
                                    timeLeft: () =>
                                    {
                                        return timeLeft;
                                    },
                                },
                                size: 'md',
                            });

                            payFortPopup.result.catch(paymentFormSubmitted =>
                            {
                                this.cancelPayment('PayFort', paymentFormSubmitted);
                            });

                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                payWithCheckOut()
                {
                    const providerCheckOut = this.getProvider(this.paymentProvidersEnum.CheckOut);

                    this.createPaymentSessionCallback({
                        providerId: providerCheckOut.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then((data) =>
                        {
                            const isoCurrency = this.paymentProviderConfig.isoCurrency;
                            const amount = this.totalPaymentInBaseUnits();
                            const key = providerCheckOut.publicKey;

                            let tokenised = false;

                            Checkout.removeAllEventHandlers(Checkout.Events.CARD_TOKENISED);
                            Checkout.removeAllEventHandlers(Checkout.Events.LIGHTBOX_CANCELLED);
                            Checkout.removeAllEventHandlers(Checkout.Events.LIGHTBOX_DEACTIVATED);

                            Checkout.configure({
                                publicKey: key,
                                value: amount,
                                currency: isoCurrency,
                                paymentMode: 'cards' || 'mixed',
                                cardFormMode: Checkout.CardFormModes.CARD_TOKENISATION,
                                useMobileLightbox: true,
                                cardTokenised: event =>
                                {
                                    tokenised = true;
                                    this.paymentCompleteCallback({ token: event.data.cardToken, merchantReference: data.sid });
                                },
                                lightboxCancelled: () =>
                                {
                                    if (!tokenised)
                                    {
                                        this.cancelPayment('Checkout', false);
                                    }
                                },
                                lightboxDeactivated: () =>
                                {
                                    if (!tokenised)
                                    {
                                        this.cancelPayment('Checkout', false);
                                    }
                                }
                            });

                            Checkout.open();
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                payWithAsiaPay()
                {
                    const providerAsiaPay = this.getProvider(this.paymentProvidersEnum.AsiaPay);

                    this.createPaymentSessionCallback({
                        providerId: providerAsiaPay.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            let i = 0;

                            const baseUrl = this.$sce.trustAsResourceUrl(providerAsiaPay.publicServiceBaseUrl);
                            const merchantId = providerAsiaPay.accountNumber;

                            switch (providerAsiaPay.mobileDeviceMode.toString())
                            {
                                case '1':
                                    this.asiaPayDeviceMode = 'mobile'
                                    break;
                                case '0':
                                    this.asiaPayDeviceMode = ''
                                    break;
                                default:
                                    this.asiaPayDeviceMode = providerAsiaPay.mobileDeviceMode;
                            }

                            const returnUrl = providerAsiaPay.returnUrl;
                            const pMethod = 'VISA';
                            const secureHash = '';

                            const paymentOptions = [
                                { title: 'VISA', value: 'VISA' },
                                { title: 'MasterCard', value: 'Master' },
                                { title: 'Diners', value: 'Diners' },
                                { title: 'JCB', value: 'JCB' },
                                { title: 'American Express', value: 'AMEX' },
                                { title: 'PPS', value: 'PPS' },
                                { title: 'PAYPAL', value: 'PAYPAL' },
                                { title: 'CHINAPAY', value: 'CHINAPAY' },
                                { title: 'ALIPAY', value: 'ALIPAY' },
                                { title: 'WECHAT', value: 'WECHAT' },
                                { title: 'TENPAY', value: 'TENPAY' },
                                { title: '99BILL', value: '99BILL' },
                                { title: 'MEPS', value: 'MEPS' },
                                { title: 'OCTOPUS', value: 'OCTOPUS' },
                                { title: 'NOVAPAY', value: 'NOVAPAY' },
                                { title: 'ENETS', value: 'ENETS' },
                                { title: 'MYCLEAR', value: 'MYCLEAR' },
                                { title: 'POLI', value: 'POLI' },
                                { title: 'UPOP', value: 'UPOP' },
                                { title: 'UPOP-GNETE', value: 'UPOP-GNETE' },
                                { title: 'UPOP-DNA', value: 'UPOP-DNA' },
                                { title: 'FUIOU', value: 'FUIOU' },
                                { title: 'SCB', value: 'SCB' },
                                { title: 'KRUNGSRIONLINE', value: 'KRUNGSRIONLINE' },
                                { title: 'KTB', value: 'KTB' },
                                { title: 'UOB', value: 'UOB' },
                                { title: 'TMB', value: 'TMB' },
                                { title: 'IBANKING', value: 'IBANKING' },
                                { title: 'BPM', value: 'BPM' },
                                { title: 'GCash', value: 'GCash' },
                                { title: 'BancNet', value: 'BancNet' },
                                { title: 'SMARTMONEY', value: 'SMARTMONEY' },
                                { title: 'M2U', value: 'M2U' },
                                { title: 'CIMBCLICK', value: 'CIMBCLICK' },

                                { title: 'ONEPAY', value: 'ONEPAY' },
                                { title: 'VCO', value: 'VCO' },
                                { title: 'WELEND', value: 'WELEND' }
                            ];

                            this.secureHash = '';

                            /* Is this needed?

                            this.$watch('pMethod', function (newValue, oldValue) {
                                // at this point we need to regenerate the hash..
                                this.secureHash = '';

                                // perform the call

                                // store hash and set flag
                            });

                            */

                            const config = {
                                expiryMM: '',
                                expiryYY: '',
                                expiryMMoptions: [],
                                expiryYYoptions: [],
                                paymentAmount: this.totalAmountToPayWithProvider(),
                                paymentSessionId: data.sid,
                                signature: data.sig,
                                baseUrl: baseUrl,
                                merchantId: merchantId,
                                asiaPayDeviceMode: this.asiaPayDeviceMode,
                                returnUrl: returnUrl,
                                isoCurrency: this.paymentProviderConfig.isoCurrency,
                                isoCurrencyNumber: this.paymentProviderConfig.isoCurrencyNumber,
                            }

                            for (i = 1; i <= 12; i++)
                                config.expiryMMoptions.push(('00' + i.toString()).substring(i.toString().length));

                            for (i = (new Date()).getFullYear(); i <= (new Date()).getFullYear() + 10; i++)
                                config.expiryYYoptions.push(i.toString().substring(2, 4));

                            let timeLeft = {
                                mins: this.timeRemainingMins,
                                secs: this.timeRemainingSecs
                            };
                            // not fussed about the result of this as we're going to get redirected
                            const asiaPayPopup = this.$uibModal.open({
                                animation: true,
                                backdrop: 'static', // clicking outside window does nto close
                                keyboard: false, // escape key does not close
                                component: 'paymentForm',
                                resolve: {
                                    provider: () =>
                                    {
                                        return providerAsiaPay.provider;
                                    },
                                    config: () =>
                                    {
                                        return config;
                                    },
                                    timeLeft: () =>
                                    {
                                        return timeLeft;
                                    },
                                },
                                size: 'md',
                            });

                            asiaPayPopup.result.catch(paymentFormSubmitted =>
                            {
                                this.cancelPayment('AsiaPay', paymentFormSubmitted);
                            });
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                payWithCyberSource()
                {
                    const providerCyberSource = this.getProvider(this.paymentProvidersEnum.CyberSource);

                    this.createPaymentSessionCallback({
                        providerId: providerCyberSource.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const config = {
                                baseUrl: this.$sce.trustAsResourceUrl(providerCyberSource.publicServiceBaseUrl + '/pay'),
                                profileId: providerCyberSource.accountNumber,
                                accessKey: providerCyberSource.apiKey,
                                utcDateTime: data.data.utcDateTime,
                                guid: data.data.guid,
                                locale: data.data.locale,
                                paymentAmount: this.totalAmountToPayWithProvider(),
                                paymentSessionId: data.sid,
                                signature: data.sig,
                                isoCurrency: this.paymentProviderConfig.isoCurrency,
                                isoCurrencyNumber: this.paymentProviderConfig.isoCurrencyNumber,
                            }

                            let timeLeft = {
                                mins: this.timeRemainingMins,
                                secs: this.timeRemainingSecs
                            };

                            // not fussed about the result of this as we're going to get redirected
                            const cyberSourcePopup = this.$uibModal.open({
                                animation: true,
                                backdrop: 'static', // clicking outside window does nto close
                                keyboard: false, // escape key does not close
                                component: 'paymentForm',
                                resolve: {
                                    provider: () =>
                                    {
                                        return this.paymentProvidersEnum.CyberSource;
                                    },
                                    config: () =>
                                    {
                                        return config;
                                    },
                                    timeLeft: () =>
                                    {
                                        return timeLeft;
                                    },
                                },
                                size: 'md',
                            });

                            cyberSourcePopup.result.catch(paymentFormSubmitted =>
                            {
                                this.cancelPayment('CyberSource', paymentFormSubmitted);
                            });
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                payWithBrainTree()
                {
                    const providerBrainTree = this.getProvider(this.paymentProvidersEnum.BrainTree);

                    this.createPaymentSessionCallback({
                        providerId: providerBrainTree.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const key = data.sig;
                            //plugin supports on or off. Merchant account dictates 3D-Version
                            const threeDSecureEnabled = () =>
                            {
                                const globalThreeDEnabledvalue = providerBrainTree.threeDSecureVersion;

                                if (!globalThreeDEnabledvalue || globalThreeDEnabledvalue === null || typeof (globalThreeDEnabledvalue) != 'number') { return false; }

                                return (globalThreeDEnabledvalue > 0) ? true : false;
                            };

                            //plugin supports PayPal -
                            const enablePayPalMethod = () =>
                            {
                                const globalEnablePayPalMethod = providerBrainTree.enablePayPalMethod;

                                if (!globalEnablePayPalMethod || globalEnablePayPalMethod === null || typeof (globalEnablePayPalMethod) != 'boolean') { return false; }

                                return globalEnablePayPalMethod;
                            };

                            const config = {
                                key: key,
                                threeDSecureEnabled: threeDSecureEnabled(),
                                isoCurrencyCode: this.paymentProviderConfig.isoCurrency,
                                paymentAmount: this.totalAmountToPayWithProvider(),
                                languageLocale: this.$rootScope.scriptBrowserLanguageCode,
                                enablePayPalMethod: enablePayPalMethod()
                            }

                            const modalInstanceBrainTree = this.$uibModal.open({
                                animation: true,
                                backdrop: 'static',
                                component: 'paymentFormBraintree',
                                size: 'md',
                                keyboard: false, // escape key does not close
                                resolve: {
                                    config: () => { return config },
                                }
                            });

                            modalInstanceBrainTree.result
                                .then(nonce =>
                                {
                                    this.paymentCompleteCallback({ token: nonce, merchantReference: data.sid })
                                })
                                .catch(paymentFormSubmitted =>
                                {
                                    this.cancelPayment('BrainTree', paymentFormSubmitted);
                                });

                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                payWithBlueSnap()
                {

                    const config = {
                        emailAddress: '',
                        fullName: '',
                        blueSnapURI: '',
                        paymentAmount: this.totalAmountToPayWithProvider(),
                        isoCurrency: this.paymentProviderConfig.isoCurrency,
                        isoCurrencyNumber: this.paymentProviderConfig.isoCurrencyNumber,
                    };

                    const supportedLanguages = ["ALBANIAN",
                        "ARABIC", "BRAZILIAN", "BULGARIAN", "CZECH",
                        "DANISH", "DUTCH", "ENGLISH", "FINNISH", "FRENCH",
                        "GERMAN", "HEBREW", "HUNGARIAN", "ITALIAN", "JAPANESE",
                        "KOREAN", "LITHUANIAN", "NORWEGIAN", "POLISH",
                        "PORTUGUESE", "RUSSIAN", "SERBIAN", "SLOVAK", "SPANISH", "SWEDISH", "TURKISH"];

                    let selectedLang = '';
                    const languageIndex = supportedLanguages.indexOf(this.$rootScope.scriptLanguage.toUpperCase());
                    if (supportedLanguages.length > 0)
                    {
                        selectedLang = "&language=" + supportedLanguages[languageIndex]
                    };

                    this.cachedLookupService.getNameEmail().then(data =>
                    {
                        config.emailAddress = data.emailAddress;
                        config.fullName = data.name;
                    });

                    const providerBlueSnap = this.getProvider(this.paymentProvidersEnum.BlueSnap);

                    this.createPaymentSessionCallback({
                        providerId: providerBlueSnap.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const key = data.sig;
                            const paymentId = data.sid;

                            this.signature = data.sig;
                            config.blueSnapURI = this.$sce.trustAsResourceUrl(providerBlueSnap.publicServiceBaseUrl + '/buynow/checkout?enc=' + key +
                                '&merchantid=' + providerBlueSnap.accountNumber +
                                '&currency=' + this.paymentProviderConfig.isoCurrency +
                                '&email=' + config.emailAddress +
                                '&fullname=' + config.fullName +
                                '&zipvisible=N' +
                                '&currencydisable=Y' +
                                '&merchanttransactionid=' + paymentId + selectedLang);

                            let timeLeft = {
                                mins: this.timeRemainingMins,
                                secs: this.timeRemainingSecs
                            };

                            //iframe size
                            const blueSnapModal = this.$uibModal.open({
                                animation: true,
                                backdrop: 'static', // clicking outside window does nto close
                                keyboard: false, // escape key does not close
                                component: 'paymentForm',
                                resolve: {
                                    provider: () =>
                                    {
                                        return providerBlueSnap.provider;
                                    },
                                    config: () =>
                                    {
                                        return config;
                                    },
                                    timeLeft: () =>
                                    {
                                        return timeLeft;
                                    },
                                },
                                size: 'lg',
                            });

                            blueSnapModal.result.catch(paymentFormSubmitted =>
                            {
                                this.cancelPayment('BlueSnap', paymentFormSubmitted);
                            });

                            console.log(data);
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                payWithRedDot()
                {

                    const providerRedDot = this.getProvider(this.paymentProvidersEnum.RedDot);

                    this.createPaymentSessionCallback({
                        providerId: providerRedDot.provider,
                        amountToPayWithCredits: this.totalAmountPaidByCredits()
                    })
                        .then(data =>
                        {
                            const key = data.sig;
                            const paymentId = data.sid;

                            console.log(data);
                            if(data.data?.paymentUrl){

                            const config = {
                                    paymentAmount: this.totalAmountToPayWithProvider(),
                                    paymentSessionId: data.sid,
                                    paymentUrl: this.$sce.trustAsResourceUrl(data.data.paymentUrl),
                                    isoCurrency: this.paymentProviderConfig.isoCurrency,
                                    isoCurrencyNumber: this.paymentProviderConfig.isoCurrencyNumber,
                                }

                                let timeLeft = {
                                    mins: this.timeRemainingMins,
                                    secs: this.timeRemainingSecs
                                };
                                // not fussed about the result of this as we're going to get redirected
                                const redDotPopup = this.$uibModal.open({
                                    animation: true,
                                    backdrop: 'static', // clicking outside window does nto close
                                    keyboard: false, // escape key does not close
                                    component: 'paymentForm',
                                    resolve: {
                                        provider: () =>
                                        {
                                            return providerRedDot.provider;
                                        },
                                        config: () =>
                                        {
                                            return config;
                                        },
                                        timeLeft: () =>
                                        {
                                            return timeLeft;
                                        },
                                    },
                                    size: 'md',
                                });

                                redDotPopup.result.catch(paymentFormSubmitted =>
                                {
                                    this.cancelPayment('RedDot', paymentFormSubmitted);
                                });

                            }
                            this.isPaymentLoading = false;
                        })
                        .catch(err =>
                        {
                            this.paymentSessionCreationErrorHandler(err);
                            this.isPaymentLoading = false;
                        });
                }

                initPayments()
                {
                    const cardIcon = 'payments';
                    const cardLabel = 'SB_Card';

                    this.paymentMethods = [
                        {
                            onSubmit: this.nonChargeableBooking.bind(this),
                            label: 'SB_Credit',
                            icon: 'balanceCredit',
                            isCredit: true
                        },
                        {
                            onSubmit: this.payWithStripe.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_Stripe_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.Stripe
                        },
                        {
                            onSubmit: this.payWithPaySafe.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_PaySafe_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.PaySafe
                        },
                        {
                            onSubmit: this.payWithPayFort.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_PayFort_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.PayFort
                        },
                        {
                            onSubmit: this.payWithCheckOut.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_Checkout_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.CheckOut
                        },
                        {
                            onSubmit: this.payWithAsiaPay.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_AsiaPay_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.AsiaPay
                        },
                        {
                            onSubmit: this.payWithVoucher.bind(this),
                            label: 'SB_Voucher_Balance',
                            icon: 'balanceVouchers',
                            isVoucher: true
                        },
                        {
                            onSubmit: this.payWithCyberSource.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_Cybersource_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.CyberSource
                        },
                        {
                            onSubmit: this.payWithBrainTree.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_BrainTree_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.BrainTree
                        },
                        {
                            onSubmit: this.payWithBlueSnap.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_BlueSnap_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.BlueSnap
                        },
                        {
                            onSubmit: this.payWithRedDot.bind(this),
                            label: this.cardProviderCount > 1 ? 'SB_RedDot_Payment_Provider' : cardLabel,
                            icon: cardIcon,
                            provider: this.paymentProvidersEnum.RedDot
                        }
                    ];
                    this.isPaymentLoading = false;
                    this.setDefaultMethod();
                }

                // Specify default method {
                setDefaultMethod()
                {
                    if (this.isDisabled && this.selectPaymentMethod)
                    {
                        // If payment disabled, leave payment methods as they are
                        return;
                    }
                    if (this.paymentMethods)
                    {
                        this.noAvailableMethods = false;
                        const enabledPayments =
                            this.paymentMethods.filter(method =>
                            {
                                return this.showMethod(method) && !this.disableMethod(method);
                            });
                        const previouslySelectedEnabledPayment = enabledPayments.filter(method =>
                        {
                            return method.provider && method.provider === this.selectedPaymentMethod.provider ||
                                method.isCredit && this.selectedPaymentMethod.isCredit ||
                                method.isVoucher && this.selectedPaymentMethod.isVoucher
                        })[0];
                        if (previouslySelectedEnabledPayment)
                        {
                            // Keep previously selected payment method if still available
                        }
                        else
                        {
                            this.selectedPaymentMethod = null;
                            if (enabledPayments.length === 1)
                            {
                                this.selectedPaymentMethod = enabledPayments[0];
                            }
                            this.noAvailableMethods = enabledPayments.length === 0;
                            this.payDisabled = !this.selectedPaymentMethod;
                            this.useCredits = this.selectedPaymentMethod && this.creditBalance > 0 && !this.selectedPaymentMethod.isVoucher;
                        }
                        this.isPaymentLoading = false;
                    }
                }

                // Rule to show payment method
                showMethod(method)
                {
                    return (method.isCredit && this.showCreditPayment()) ||
                        (method.isVoucher && this.showVoucherPaymentButton()) ||
                        (method.provider && this.showProvider(method.provider));
                }

                // Rule to show payment method + Credit
                showMethodWithCredit(method)
                {
                    return this.showMethod(method) &&
                        this.creditBalance &&
                        (this.totalAmount > this.creditBalance || method.isCredit) &&
                        !method.isVoucher;
                }

                // Rule to disabled payment method
                disableMethod(method)
                {
                    if (this.isDisabled)
                    {
                        return true;
                    }
                    return (method.isCredit && (this.creditBalance < this.totalAmount)) ||
                        (method.provider && !this.paymentEnabled);
                }

                // Function to select a payment method, with or without credit
                selectPaymentMethod(method, useCredits: boolean)
                {
                    this.selectedPaymentMethod = method;
                    this.useCredits = useCredits;
                    this.payDisabled = false;
                }

                // Work out cost with credit used
                payWithCreditAmount()
                {
                    let amount = this.totalAmount - this.creditBalance;
                    if (amount < 0)
                    {
                        amount = 0;
                    }
                    return amount;
                }

                // The amount charged
                chargedAmount()
                {
                    return this.useCredits ? this.payWithCreditAmount() : this.totalAmount;
                }

                // Function to pay using selected Method
                pay()
                {
                    this.confirmBooking('SB_Pay').then(confirmed =>
                    {
                        if (confirmed)
                        {
                            this.isPaymentLoading = true;
                            this.selectedPaymentMethod.onSubmit();
                        }
                    });
                }

                startTimer = () =>
                {
                    if (this.timer == null)
                    {
                        this.timer = this.$interval(() =>
                        {
                            this.timeRemaining = moment.utc(this.timeRemaining).subtract(1, 'seconds').toDate();

                            let secondsRemaining = moment.utc(this.timeRemaining).get('seconds');

                            if ((moment.utc(this.timeRemaining).get('minutes') == 0 &&
                                moment.utc(this.timeRemaining).get('seconds') == 0) ||
                                moment.utc(this.timeRemaining).get('minutes') > this.minutesUntilBookingExpiry)
                            {
                                this.$interval.cancel(this.timer);
                                this.timeOutBooking();
                                return;
                            }

                            // Re-calculate time remaining every 10 seconds in case user puts their computer
                            // to sleep to try to give themselves more time.
                            // Commented out for now as this is an extreme edge case.
                            //if (secondsRemaining % 10 == 0)
                            //{
                            //    this.availableRepository
                            //        .getSignUpEvents(this.signUpId, 0)
                            //        .then((eventsData) =>
                            //        {
                            //            let bookingExpiryInfo = this.ccaService.getBookingExpiryInfo(eventsData.availableEvents);

                            //            // Oldest Booking Expiry is taken from any calendar event bookings that are not timed-out, cancelled or deleted.
                            //            this.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;

                            //            if (this.oldestBookingExpiry != null)
                            //            {
                            //                this.timeRemaining = bookingExpiryInfo.timeRemaining;
                            //            }
                            //        });
                            //}

                            this.timeRemainingMins = moment.utc(this.timeRemaining).get('minutes');
                            this.timeRemainingSecs = moment.utc(this.timeRemaining).get('seconds');
                        }, 1000);
                    }
                };

                timeOutBooking = () =>
                {
                    this.hasBookingTimedOut = true;
                    this.timeRemainingMins = 0;
                    this.timeRemainingSecs = 0;

                    let timeOutBookingsRequest =
                    {
                        signUpId: this.signUpId,
                        timeOutBookingRequests: []
                    }

                    this.signUpEvents.forEach((signUpEvent) =>
                    {
                        let selectedStudentIds = [];

                        signUpEvent.pupils.forEach((student) =>
                        {
                            if (student.selectionCount == 1)
                            {
                                selectedStudentIds.push(student.personId);
                            }
                        });

                        if (selectedStudentIds.length > 0)
                        {
                            let timeOutBookingRequest =
                            {
                                calendarEventId: signUpEvent.calendarEventId,
                                selectedStudentIds: selectedStudentIds
                            }

                            timeOutBookingsRequest.timeOutBookingRequests.push(timeOutBookingRequest);
                        }
                    });

                    this.ccaService.timeOutSelectedStudentBookings(timeOutBookingsRequest);

                    if (this.bookingTimeOutCallback())
                    {
                        this.bookingTimeOutCallback();
                    }
                }
            }
        });
