angular.module('tuitionFeesModule')
    .component('invoiceDetailViewPayments', {
        bindings: {
            invoicePayments: '<',
            paymentMethods: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-payments.template.html',
        controller: class InvoiceDetailViewPaymentsCtrl {

            // bindings
            invoicePayments: any[];
            paymentMethods: any[];

            // dependencies
            $window: any;

            // variables
            currencyDisplaySymbol: string;
            currencyExponent: number;
            showNoResult: boolean;
            sortReverse: boolean = true;
            sortType: string = 'datePaid';

            static $inject = ['$window'];

            constructor($window) {
                this.$window = $window;
                this.showNoResult = false;

                this.currencyDisplaySymbol = $window.EveryBuddy.Constants.CurrencyDisplaySymbol;
                this.currencyExponent = $window.EveryBuddy.Constants.CurrencyExponent;
            }

            $onChanges(changes) {
                if (changes.invoicePayments?.currentValue) {
                    this.showNoResult = !changes.invoicePayments?.currentValue || changes.invoicePayments?.currentValue?.length == 0;
                }
            }

            getPaymentMethodName(paymentMethodId: number) : string {
                var paymentMethod = this.paymentMethods.find(x => x.id == paymentMethodId);
                if (paymentMethod) {
                    return paymentMethod.name;
                }

                return '';
            }

            downloadReceipt(item) {
                this.$window.open(item.pdfBlobPath, '_blank');
            }
        }
    });
