'use strict';

angular.module('balanceAdmin.reports.components.balanceAdminReports', [])
    .component('balanceAdminReports',
        {
            bindings: {},
            templateUrl: '/Scripts/app/balanceAdmin/reports/components/balance-admin-reports.template.html',
            controllerAs: 'ctrl',
            controller: [
                function () { }
            ]
        }
    );