import { ConfirmDialogService } from './../../services/confirm-dialog.service';
import { UiClasses } from './../../types/ui-classes';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sb-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  @Input() position: string;
  @Input() alignment: 'top' | 'bottom';
  @Input() message: string;
  @Input() messageClass: UiClasses;
  @Input() buttonMessage: string;
  @Input() isSubmitted: boolean;
  @Input() confirmationMessage: string;
  @Input() confirmButtonMessage: string;
  @Output() clickBannerButton: EventEmitter<void> = new EventEmitter<void>();

  constructor(private confirmDialog: ConfirmDialogService) { }

  buttonClick() {
    this.isSubmitted = true;
    if (this.confirmationMessage) {
      this.confirmDialog.confirmThis({
        text: this.confirmationMessage,
        title: this.buttonMessage,
        okButtonText: this.confirmButtonMessage
      }, () => {
        this.clickBannerButton.emit();
      }, () => {
        this.isSubmitted = false;
      })
    }
  }

}
