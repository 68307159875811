angular.module('contacts.components.addressEditor',
    [
        'pascalprecht.translate',
        'shared.components.sbIcon'
    ])
    .component('addressEditor',
        {
            bindings: {
                address: '<',
                readOnly: '<'
            },
            templateUrl: '/Scripts/app/contacts/components/address-editor.template.html',
            controller: [
                function corporateContactEditorController() {

                }
            ]
        });