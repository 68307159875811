export enum ChatSessionTypeId {
  Chat = 0,
  Notice = 1, // Pupil notice
  News = 2,
  MatchReport = 3,
  ParentNotice = 4,
  Homework = 5,
  AdHoc = 6,
  StaffNotice = 7
}
