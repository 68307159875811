angular
    .module('transport.services.transportConfigurationRoutesService', [])
    .factory('transportConfigurationRoutesService', [
        '$http',
        function ($http) {

            var urlBase = '/webapi/WebOrganisationTransportRoutes/';
            var archivingUrlBase = '/webapi/WebOrganisationTransportArchiving/';

            var service = {};

            service.getRoutes = function ()
            {
                return $http.get(urlBase + 'GetRoutes')
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportRoute = function (transportRouteId)
            {
                return $http.get(urlBase + 'GetTransportRoute', { params: { transportRouteId: transportRouteId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportRouteDataForEdit = function (transportRouteId)
            {
                return $http.get(urlBase + 'GetTransportRouteDataForEdit', { params: { transportRouteId: transportRouteId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getRouteSummariesByTransportMethodAndTimeslot = function (transportMethodId, transportTimeslotId, timetableId)
            {
                return $http.get(urlBase + 'GetRouteSummariesByTransportMethodAndTimeslot',
                    {
                        params:
                        {
                            transportMethodId: transportMethodId,
                            transportTimeslotId: transportTimeslotId,
                            timetableId: timetableId
                        }
                    })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.addRoute = function (transportRoute)
            {
                return $http.post(urlBase + 'AddRoute', transportRoute)
                    .then(function (response) { return response.data; });
            };

            service.editRoute = function (transportRoute)
            {
                return $http.put(urlBase + 'EditRoute', transportRoute)
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.getTransportRouteArchivingInfo = function (transportRouteId)
            {
                return $http.get(archivingUrlBase + 'GetTransportRouteArchivingInfo', { params: { transportRouteId: transportRouteId } })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            service.archiveRoute = function (transportRoute)
            {
                return $http.put(archivingUrlBase + 'ArchiveRoute', { Id: transportRoute.id })
                    .then(function (response)
                    {
                        return response.data;
                    });
            };

            return service;
        }
    ]);