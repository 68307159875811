angular.module('tuitionFeesModule')
    .component('invoiceDetailViewAddPayment', {
        bindings: {
            invoiceId: '<',
            outstandingBalance: '<',
            fncSuccessAction: '<',
            paymentMethods: '<'
        },
        templateUrl: '/Scripts/app/tuitionFees/components/invoice/invoice-detail-view-add-payment.template.html',
        controller: class InvoiceDetailViewAddPaymentCtrl {
            // Bindings
            invoiceId: number;
            outstandingBalance: string;
            fncSuccessAction: any;
            paymentMethods: any[];

            // Dependencies
            $timeout: any;
            invoiceService: any;

            // Variables
            isSubmitted: boolean;
            datePaid: Date;
            amount: string; // number not working with sb-currency-input at moment.
            reference: string;
            paymentMethodId: number;

            static $inject = ['$timeout', 'invoiceService'];

            constructor($timeout, invoiceService) {
                this.invoiceService = invoiceService;
                this.$timeout = $timeout;

                this.isSubmitted = false;
            }

            $onInit(): void {
            }

            $onChanges(changes) {
                if (changes.invoiceId) {
                    this.resetFields();
                }

                if (changes.outstandingBalance)
                {
                    this.amount = this.outstandingBalance;
                }
            }

            resetFields() {
                this.amount = this.outstandingBalance;
                this.reference = '';
                this.paymentMethodId = null;
                this.datePaid = moment.utc().startOf('day').toDate();
            }

            resetSubmit() {
                this.$timeout(function () {
                    this.isSubmitted = false;
                }.bind(this), 100);
            }

            addPayment() {

                this.isSubmitted = true;

                let payload = {
                    datePaid: this.datePaid,
                    amount: parseFloat(this.amount),
                    paymentMethodId: this.paymentMethodId,
                    reference: this.reference
                };

                this.invoiceService.addPayment(this.invoiceId, payload).then(res => {
                    if (res?.isSuccess && this.fncSuccessAction) {
                        this.$timeout(function () {
                            this.resetFields();
                            this.fncSuccessAction(this.invoiceId);
                        }.bind(this), 100);
                    }

                    this.resetSubmit();
                });
            }
        }
    });
