angular.module('shared.components.sbDataTable', [
    'shared.components.sbIcon',
    'shared.components.sbButton',
    'shared.components.sbEditField',
    'shared.components.sbBadge',
    'shared.components.sbAlert',
    'shared.components.sbSearchFilter',
    'shared.components.sbSearchFilterItem',
    'shared.constants',
    'shared.services.arrayService',
    'shared.services.stringService'
])
    .component('sbDataTable', {
        bindings: {
            tableHeader: '<',
            tableData: '<',
            emptyMessage: '<',
            isError: '<',
            errorMessage: '<',
            searchText: '<',
            sortByDefault: '<',
            filterByDefault: '<',
            searchFilterType: '<',
            isRowClickable: '<',
            isProcessing: '<',
            selectedItem: '=',
            searchFilterLabel: '<',
            rowIdProp: '@',
            onClick: '&',
            onChangeFilters: '&'
        },
        templateUrl: '/Scripts/app/shared/components/sb-data-table.template.html',
        transclude: true,
        controller: class SbDataTableCtrl {

            // Dependencies
            $filter: any;
            tableProperties: any;
            searchFilterTypes: any;
            arrayService: any;
            stringService: any;

            // Bindings
            tableHeader: any;
            tableHeaderColumns: any[];
            tableData: any[];
            sortByDefault: boolean = false;
            filterByDefault: boolean = false;
            searchByDefault: boolean = true;
            filterableTableHeaders: any[];
            onChangeFilters: any;
            searchFilterType: number;
            isRowClickable: boolean = false;
            onClick: any;
            rowIdProp: string;
            isProcessing: boolean = false;
            selectedItem: any;
            searchFilterLabel: string;

            // Values
            tableId: number;
            sortType: string;
            sortReverse: boolean = false;
            rowStateColumnId: string;
            searchFilters: any = {};
            cellTypes: any;
            summaries: any[];
            tableLayouts; any;

            static $inject = ['tableProperties', 'searchFilterTypes', 'arrayService', 'stringService'];

            constructor(tableProperties, searchFilterTypes, arrayService, stringService) {
                this.tableProperties = tableProperties;
                this.searchFilterTypes = searchFilterTypes;
                this.cellTypes = this.tableProperties.CellTypes;
                this.arrayService = arrayService;
                this.stringService = stringService;
                this.tableLayouts = this.tableProperties.Layouts;
            }

            $onInit() {
                this.tableId = Math.floor(Math.random() * 100);
            }

            $onChanges(changes) {
                if (changes.tableHeader?.currentValue) {
                    this.sortType = Object.entries(this.tableHeader).find((item: any) => item[1].isDefault)[0];
                }
                if (changes.tableData?.currentValue) {
                    this.tableData = this.tableData.map(row => {
                        const rowEntries = Object.entries(row).map((rowEntry: any) => {
                            const key = rowEntry[0];
                            let value = rowEntry[1];
                            value = typeof value === 'object' && value != null ? value : { label: value || (typeof value === 'number' ? value.toString() : ' ')};
                            return [key, {
                                ...value,
                                // For filter selects - get option id from data or use label as id
                                id: value.id || this.getCellValue(value)
                            }];
                        });
                        return (<any>Object).fromEntries(rowEntries);
                    })
                }
                if ((changes.tableHeader?.currentValue || changes.tableData?.currentValue) && (this.tableHeader && this.tableData)) {
                    this.tableHeaderColumns = Object.entries(this.tableHeader).map((item: any) => {
                        const th = item[1];
                        const id = item[0];
                        const options = th.options || this.tableData.map(row => {
                            const cell = row[id];
                            return {
                                name: this.getCellValue(cell),
                                id: cell.id || this.getCellValue(cell),
                                class: cell.class,
                                icon: cell.icon
                            }
                        });
                        const uniqueOptionsArray = this.arrayService.uniqueBy(options, (item) => { return item.id; });
                        return {
                            ...th,
                            id: id,
                            isSortable: th.isSortable || (this.sortByDefault && th.isSortable !== false),
                            isFilterable: th.isFilterable || (this.filterByDefault && th.isFilterable !== false),
                            isSearchable: th.isSearchable || (this.searchByDefault && th.isSearchable !== false),
                            options: (this.arrayService.generateOptions(uniqueOptionsArray, true, true))
                                .map(option => {
                                    return {
                                        ...option,
                                        count: this.tableData.filter(row => !option.id || row[id].id == option.id).length
                                    }
                                })
                                .filter(option => option.name !== ' ')
                        }
                    })
                    this.rowStateColumnId = Object.entries(this.tableHeader).find((item: any) => item[1].isRowState)[0];
                    this.summaries = this.tableHeaderColumns
                        .find(column => column.id === this.rowStateColumnId)?.options
                    this.tableHeaderColumns.forEach(th => {
                        this.searchFilters[th.id] = this.searchFilters[th.id] || th.options[0].id;
                    })
                    this.filterableTableHeaders = this.tableHeaderColumns.filter(th => th.isFilterable);
                }
            }

            filteredTableData() {
                const filteredData = this.tableData?.filter(row => {
                    // Check column-based filters
                    const columnMatch = this.filterableTableHeaders?.every(th => {
                        const thId = th.id;
                        const cellValue = row[thId].id;
                        return !this.searchFilters[thId] || cellValue == this.searchFilters[thId];
                    });
                    // Check main filter
                    let mainMatch = false;
                    switch(this.searchFilterType) {
                        case this.searchFilterTypes.TextFilter:
                            mainMatch = !(this.searchFilters.textFilter?.length > 0) || this.tableHeaderColumns?.some((col: any) => {
                                if (!col.isSearchable) {
                                    return false;
                                }
                                const value = row[col.id]?.id || row[col.id];
                                if (!value) {
                                    return false;
                                }
                                return value.toString().toLowerCase().indexOf(this.searchFilters.textFilter.toLowerCase()) > -1;
                            });
                        break
                            // Add other main search checks as needed
                        default:
                            return mainMatch = true;
                        }
                    return columnMatch && mainMatch;
                });
                if (!filteredData) {
                    return null;
                }
                const sortedData = filteredData.sort((a, b) => {
                    const aValue = (a[this.sortType]?.label || a[this.sortType]).toString().toLowerCase();
                    const bValue = (b[this.sortType]?.label || b[this.sortType]).toString().toLowerCase();
                    return aValue.localeCompare(bValue, undefined, {numeric: true, sensitivity: 'base'});
                });

                return this.sortReverse ? sortedData.reverse() : sortedData;
            }

            resetFilters() {
                this.tableHeaderColumns.forEach(th => {
                    this.searchFilters[th.id] = th.options[0].id;
                });
                this.searchFilters.textFilter = '';
                if (this.onChangeFilters) {
                    this.onChangeFilters(null);
                }
            }

            getCellValue(cell){
                return cell?.label || cell?.name || cell;
            }

            onTableClick(row) {
                this.onClick({id: row[this.rowIdProp || 'id']?.id});
              }

            onClickSummary(value: number) {
                this.resetFilters();
                this.searchFilters[this.rowStateColumnId] = value?.toString() || undefined;
                if (this.onChangeFilters) {
                    this.onChangeFilters({searchFilters: this.searchFilters, filteredData: this.filteredTableData()});
                }
            }
        }

    });
