'use strict';

angular.module('events.components.confirmManualYesOrNo', [
])
    .component('confirmManualYesOrNo',
        {
            bindings: {
                modalInstance: '<',
                resolve: '<'
            },
            templateUrl: '/Scripts/app/events/components/confirm-manual-yes-or-no.template.html',
            controller: [
                '$translate',
                function confirmManualYesOrNoController($translate)
            {
                this.student = null;

                this.$onChanges = function (changes)
                {
                    if (changes.resolve && changes.resolve.currentValue)
                    {
                        this.student = changes.resolve.currentValue.student;
                        this.title = changes.resolve.currentValue.title;
                        this.confirmFor = changes.resolve.currentValue.confirmFor;
                    }
                }.bind(this);

                //this.$onInit = function ()
                //{
                //    $translate.onReady().then(function ()
                //    {
                //        this.title = $translate.instant(this.title);
                //        this.confirmFor = $translate.instant(this.confirmFor, { studentName: this.student.name });
                //    }.bind(this));

                //}.bind(this);

                this.confirm = function ()
                {
                    closeModal();
                }.bind(this);

                this.cancel = function ()
                {
                    this.modalInstance.dismiss('cancel');
                }.bind(this);

                var closeModal = function ()
                {
                    this.modalInstance.close();
                }.bind(this);
            }]
        });