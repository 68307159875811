import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sb-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  @Input() placeholderText: string;
  @Input() placeholderIcon: string;
  @Input() backText: string;
  @Input() selectedValue: any;
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isLocationChange: boolean;
  @Input() isLoading: boolean;

  @ViewChild('detail') detailElement: ElementRef;

  @HostListener('window:resize', ['$event']) onResize() {
    this.getScrollableHeight();
  }

  constructor(private location: LocationStrategy) { }

//             $rootScope.$on('$locationChangeStart', function (event) {
//                 /* Prevent app from going back a page on click of button,
//                 instead returning user to the master view*/
//                 if (this.selectedValue && !this.locationChange) {
//                     event.preventDefault();
//                     this.back();
//                 /* Or if a used on a page with routing, just check contents */
//                 } else if (this.locationChange) {
//                     this.getScrollableHeight();
//                 }
//             }.bind(this));
//         }
//     ],
//     controllerAs: 'ctrl'
// };

  ngOnInit(): void {
    this.getScrollableHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getScrollableHeight();
    if (!changes.selectedValue?.previousValue && changes.selectedValue?.currentValue && !this.isLocationChange) {
      history.pushState(null, null, window.location.href);
    }
  }

  // Handle any cards and add scrolling inside content instead of whole detail area
  getScrollableHeight() {

    if (!this.selectedValue) return;

    // setTimeout(() => {

    //     const sbDetailElement = this.detailElement.nativeElement;

    //     if (sbDetailElement?.children) {
    //       const sbDetailHeight = sbDetailElement.offsetHeight;

    //       const cardDetails = sbDetailElement.find('#cardDetails');

    //       const cardDetailsHeight = cardDetails?.offsetHeight || 0;

    //       const cardSubmenu = sbDetailElement.find('cardSubmenu');

    //       const cardSubmenuHeight = cardSubmenu?.offsetHeight || 0;

    //       const cardContent = sbDetailElement.find('cardContent');

    //       if (cardContent) {
    //           cardContent.css('max-height', (sbDetailHeight - (cardDetailsHeight + cardSubmenuHeight)) + 'px');
    //       }
    //     }

    // }, 500);
  }

  back() {
    this.selectedValue = null;
    this.selectedValueChange.emit(this.selectedValue);
    if (this.isLocationChange) {
      this.location.back()
    }
}


}
