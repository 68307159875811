"use strict";

angular.module('receptionModule').component('receptionAllGroupsDetails', {
    templateUrl: '/Scripts/app/reception/controllers/groups/reception-allgroups-details.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        onGroupUpdated: '&',
        selectedGroup: '<'
    },
    controller:
    [
        '$scope',
        '$state',
        '$rootScope',
        'groupService',
        function ($scope,$state, $rootScope, groupService) {

            this.$onChanges = (changes) => {
                if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                    $scope.selectedGroup = this.selectedGroup;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues
                
                groupService.getGroupLookupData().then(lookupData => {
                    $scope.isOrganisationAdmin = lookupData.isOrganisationAdmin;
                    $scope.clubs = lookupData.clubs;
                    $scope.sports = lookupData.sports;
                    $scope.locations = lookupData.locations;
                });

            if (!$scope.selectedGroup)
            {
                $scope.selectedGroup = {};
            }

            if (!$scope.selectedGroupId)
            {
                if (!$state.params.groupId)
                {
                    console.error('reception-allgroups-details-controller/ no group loaded and groupId missing from route parameters');
                } else
                {
                    console.log('reception-allgroups-details-controller/ missing group id so populating from route parameter', $state.params);
                    $scope.selectedGroupId = +$state.params.groupId;
                }
            }

            }

            $scope.handleGroupUpdate = (data) =>
            {
                $scope.selectedGroup.name = data.title;
                $scope.selectedGroup.statusId = data.statusId;

                $scope.selectedGroup.details = data;
                $rootScope.$broadcast('group-updated');
                
                if(typeof this.onGroupUpdated === "function")
                {
                    this.onGroupUpdated({group: data})
                }

            };

            $scope.handleGroupLoad = function(data)
            {
                $scope.allowComms = data.isGroupAdmin;
                $scope.selectedGroup.statusId = data.statusId;
                $scope.selectedGroup.details = data;
                $scope.selectedGroup.name = data.title;
                $scope.selectedGroup.id = data.id;
            };
        }
    ]});