'use strict';

angular.module('receptionModule').component('receptionAllGroupsAttendance', {
    templateUrl:
    '/Scripts/app/reception/controllers/groups/reception-allgroups-attendance.template.html?v=', // + window.EveryBuddy.version
    bindings: {
        selectedGroup: '<'
    },
    controller: [
    '$scope', '$state', '$filter', '$http', '$window', '$compile', '$timeout', 'receptionService', 'searchFilterTypes',
    function ($scope, $state, $filter, $http, $window, $compile, $timeout, receptionService, searchFilterTypes) {

        this.$onChanges = (changes) => {
            if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                $scope.selectedGroup = this.selectedGroup;
            }
        };

        this.$onInit = () => { // Not indented to avoid merge issues

        $scope.searchFilterTypes = searchFilterTypes;

        $scope.eventsFrom = moment().add(-6,'months').toDate();
        $scope.eventsTo = moment().add(1,'days').toDate();

        };

    }
]});






