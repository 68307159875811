'use strict';

angular.module('shared.services.iconService', [
    'shared.services.httpCoreApi'
])
    .service('iconService', ['$filter', 'httpCoreApi',
        function ($filter, httpCoreApi) {
            var service = this;

            var iconDictionary = {

                ///////////////////////////////////
                // Keep this alphabetic please!!!
                ///////////////////////////////////
                'add': 'fas fa-plus-circle',
                'addUser': 'fas fa-user-plus',
                'ageBands': 'fal fa-sliders-v',
                'alert': 'fal fa-exclamation-triangle',
                'allgroups': 'fal fa-users',
                'allocate': 'fal fa-list-alt',
                'android': 'fab fa-android',
                'apple': 'fab fa-apple',
                'archive': 'fal fa-archive',
                'arrow-left': 'fas fa-arrow-left',
                'arrow-right': 'fas fa-arrow-right',
                'attendance': 'fal fa-clipboard-check',
                'auto-allocate': 'fal fa-magic',

                'back': 'fal fa-arrow-to-left',
                'balanceBalance': 'fas fa-money-bill',
                'balanceCredit': 'fas fa-wallet',
                'balanceVouchers': 'fas fa-receipt',
                'basket': 'far fa-shopping-basket',
                'bell': 'fal fa-bell',
                'blankSlateSignUps': 'fad fa-calendar-plus',
                'block': 'fal fa-ban',
                'blockdates': 'fal fa-calendar-times',

                'caretUp': 'fas fa-caret-up',
                'caretDown': 'fas fa-caret-down',
                'changeRequest': 'fal fa-comment-edit',
                'check': 'fal fa-check-square',
                'checkCircle': 'far fa-check-circle',
                'chevronDown': 'fal fa-chevron-down',
                'chevronLeft': 'fal fa-chevron-left',
                'chevronRight': 'fal fa-chevron-right',
                'circle': 'fas fa-circle',
                'clock': 'fas fa-clock',
                'close': 'fal fa-times',
                'cog': 'fas fa-cog',
                'coin': 'fas fa-coin',
                'coins': 'fas fa-coins',
                'collapse': 'fas fa-minus-square',
                'confirm': 'fal fa-check-circle',
                'configSettings': 'fal fa-sliders-v',
                'commentEdit': 'far fa-comment-alt-edit',
                'contacts': 'fal fa-address-book',
                'copy': 'fal fa-copy',
                'corporation': 'fal fa-building',
                'createEvents': 'fal fa-calendar-plus',
                'cross': 'fal fa-times',
                'cross-solid': 'fas fa-times',
                'crossSquare': 'fal fa-times-square',
                'crossCircle': 'fal fa-times-circle',
                'customForms': 'fal fa-table',

                'dashboard': 'fal fa-tachometer-alt-slow',
                'database': 'fal fa-database',
                'date': 'far fa-calendar',
                'delete': 'fal fa-trash-alt',
                'diary': 'fal fa-calendar',
                'documents': 'fal fa-copy',
                'done': 'fal fa-check-circle',
                'download': 'fal fa-download',

                'edit': 'fal fa-edit',
                'ellipsis': 'fas fa-ellipsis-h',
                'emailTemplate': 'fal fa-envelope-open-text',
                'events': 'fal fa-check-square',
                'exclamation': 'fal fa-exclamation',
                'exclamationCircle': 'fal fa-exclamation-circle',
                'expand': 'fas fa-plus-square',
                'expandWindow': 'fas fa-expand-arrows-alt',
                'existingEvents': 'fas fa-th-list',
                'exit': 'fas fa-sign-out',

                'follow': 'ti-direction',
                'followLink': 'fal fa-external-link',
                'forms': 'fal fa-clipboard',
                'formtags': 'fal fa-file-user',
                'forward': 'fal fa-arrow-to-right',
                'file': 'fas fa-file',
                'file-csv': 'fas fa-file-csv',
                'file-excel': 'fas fa-file-excel',
                'file-pdf': 'fas fa-file-pdf',
                'filter': 'fal fa-filter',
                'fullScreen': 'fal fa-expand-wide',

                'garage': 'fas fa-garage',
                'generateEvents': 'fal fa-calendar-edit',
                'generateFees': 'fal fa-usd-circle',
                'globe': 'fas fa-globe-americas',
                'groups': 'fal fa-users',
                'groupdetail': 'fal fa-info-circle',
                'guardians': 'fal fa-shield-alt',

                'hide': 'fal fa-eye-slash',
                'history': 'fal fa-history',
                'homeWork': 'ti ti-briefcase',

                'importData': 'fal fa-file-import',
                'inboxAll': 'fal fa-envelope-open',
                'inboxArchived': 'fal fa-archive',
                'inboxSent': 'fal fa-location-arrow',
                'inboxStarred': 'fal fa-star',
                'inboxUnread': 'fal fa-envelope',
                'info': 'fal fa-info-circle',
                'insurance': 'fal fa-hands-helping',
                'integration': 'fas fa-plug',
                'investment': 'fal fa-piggy-bank',
                'invoice': 'fas fa-file-invoice',

                'joinrequests': 'fal fa-hand-point-up',

                'languageSelect': 'fal fa-language',
                'leaver': 'fas fa-user-slash',
                'lightning': 'fal fa-bolt',
                'link': 'fal fa-link',
                'linkedAccounts': 'fal fa-link',
                'list': 'fal fa-list-ul',
                'listBoxed': 'fal fa-list-alt',
                'liveRouteData': 'fal fa-stopwatch',
                'loading': 'far fa-pulse fa-spinner',
                'locations': 'fal fa-map-signs',
                'location-pin': 'fa fa-map-marker-alt',
                'locked': 'fal fa-lock',

                'mainSettings': 'fal fa-atom',
                'manualBooking': 'fal fa-person-carry',
                'massPupilTransportAssignment': 'fas fa-users-class',
                'matchReports': 'ti ti-headphone-alt',
                'medical': 'fal fa-notes-medical',
                'meeting': 'fas fa-users',
                'membership': 'fal fa-address-card',
                'messageCenter': 'fal fa-envelope',
                'microphone': 'fas fa-microphone',
                'microphone-off': 'fas fa-microphone-slash',
                'minus': 'fal fa-minus',
                'minusCircle': 'fas fa-minus-circle',
                'minusCircleD': 'fad fa-minus-circle',

                'moneyBill': 'fal fa-money-bill-wave',
                'mortgage': 'fal fa-home-heart',

                'news': 'ti ti-announcement',
                'next': 'fal fa-angle-double-right',
                'notes': 'fal fa-sticky-note',
                'notices': 'ti ti-blackboard',

                'ok': 'fal fa-check',
                'onHold': 'fas fa-pause-circle',
                'onSchedule': 'fas fa-calendar-check',

                'pageNew': 'fas fa-file-plus',
                'pageExisting': 'fas fa-file-alt',
                'pageClone': 'fas fa-copy',
                'pageExternal': 'fas fa-external-link-square-alt',
                'parentNotice': 'ti ti-home',
                'payments': 'fas fa-credit-card',
                'pin': 'fas fa-thumbtack',
                'phoneLandline': 'fas fa-phone',
                'phoneMobile': 'fas fa-mobile',
                'play': 'fal fa-play',
                'plus': 'fal fa-plus',
                'plusCircle': 'fas fa-plus-circle',
                'plusCircleD': 'fad fa-plus-circle',
                'plusFolder': 'fas fa-folder-plus',
                'portal': 'far fa-browser',
                'preSelect': 'fal fa-hand-point-right',
                'prev': 'fal fa-angle-double-left',
                'print': 'fal fa-print',
                'profile': 'fal fa-portrait',
                'publishEvents': 'fal fa-calendar-check',
                'pupilAllocationOverview': 'fal fa-id-card',
                'pupilSelections': 'fal fa-check-square',

                'question': 'fa fa-question',
                'question-solid': 'fas fa-question',
                'questionCircle': 'fal fa-question-circle',

                'receipt': 'fal fa-receipt',
                'referenceData': 'fal fa-hdd',
                'refresh': 'fal fa-sync',
                'refund': 'fal fa-money-check-alt',
                'register': 'fal fa-check-square',
                'reggroups': 'fal fa-home',
                'remove': 'fas fa-minus-circle',
                'reply': 'fas fa-reply',
                'reports': 'fal fa-cloud-download',
                'restore': 'fal fa-undo',
                'route': 'fal fa-route',
                'ruler': 'fas fa-horizontal-rule',

                'save': 'fal fa-save',
                'school': 'fas fa-school',
                'schoolDetails': 'fas fa-money-check',
                'search': 'fal fa-search',
                'searchSettings': 'fas fa-sliders-h',
                'send': 'fal fa-share-square',
                'sendCommunications': 'fal fa-bullhorn',
                'show': 'fal fa-eye',
                'singleSignOn': 'fal fa-key',
                'siteMap': 'fas fa-sitemap',
                'smsTopUp': 'fal fa-comment-dollar',
                'sort': 'fas fa-sort',
                'sortUp': 'fas fa-sort-up',
                'sortDown': 'fas fa-sort-down',
                'square-down': 'fa fa-caret-square-down',
                'square-up': 'fa fa-caret-square-up',
                'staff': 'fal fa-id-badge',
                'staffNotice': 'ti ti-clipboard',
                'staffroles': 'fal fa-address-card',
                'staffschedule': 'fal fa-calendar-alt',
                'statement': 'fal fa-file-invoice-dollar',
                'stop': 'fal fa-hand-paper',
                'superAdmin': 'fas fa-star',
                'style': 'fas fa-paint-roller',

                'tasks': 'fal fa-clipboard-list',
                'tick': 'fal fa-check',
                'tick-solid': 'fas fa-check',
                'tickCircle': 'fal fa-check-circle',
                'ticket': 'fal fa-ticket',
                'transactions': 'fal fa-bars',
                'transportAll': 'fal fa-car-bus',

                'video': 'fas fa-video',
                'video-off': 'fas fa-video-slash',

                'unarchive': 'fas fa-archive',
                'unblock': 'fal fa-check-circle',
                'uncheck': 'fal fa-square',
                'unknown-circle': 'fal fa-question-circle',
                'unlocked': 'fal fa-lock-open',
                'unsorted': 'fal fa-sort',
                'users': 'fal fa-user',
                'user-edit': 'fas fa-user-edit',
                'userTypeParent': 'fas fa-user',
                'userTypeStaff': 'fas fa-school',
                'userTypeStudent': 'fas fa-users-class',
                'video': 'fas fa-video',

                'wallet': 'fal fa-wallet',
                'warning': 'fal fa-exclamation-triangle',
                'warning-circle': 'fal fa-exclamation-circle',
                'web': 'fal fa-globe',
                'welcomemessage': 'ti ti-comments'

                ///////////////////////////////////
                // Keep this alphabetic please!!!
                ///////////////////////////////////
            };

            // Transport icons define extra information
            var transportIcons = [
                {
                    iconName: 'transport-bike',
                    displayName: 'SB_Bicycle',
                    unicode: '\uf206',
                    faIcon: 'bicycle',
                    id: 'bike'
                },
                {
                    iconName: 'transport-bus',
                    displayName: 'SB_Bus',
                    unicode: '\uf5dd',
                    faIcon: 'bus-school',
                    id: 'bus'
                },
                {
                    iconName: 'transport-car',
                    displayName: 'SB_Car',
                    unicode: '\uf5e4',
                    faIcon: 'car-side',
                    id: 'car'
                },
                {
                    iconName: 'transport-metro',
                    displayName: 'SB_Metro',
                    unicode: '\uf239',
                    faIcon: 'subway',
                    id: 'metro'
                },
                {
                    iconName: 'transport-minibus',
                    displayName: 'SB_Minibus',
                    unicode: '\uf5b6',
                    faIcon: 'shuttle-van',
                    id: 'minbus'
                },
                {
                    iconName: 'transport-motorbike',
                    displayName: 'SB_Motorcycle',
                    unicode: '\uf21c',
                    faIcon: 'motorcycle',
                    id: 'motorbike'
                },
                {
                    iconName: 'transport-taxi',
                    displayName: 'SB_Taxi',
                    unicode: '\uf1ba',
                    faIcon: 'taxi',
                    id: 'taxi'
                },
                {
                    iconName: 'transport-train',
                    displayName: 'SB_Train',
                    unicode: '\uf238',
                    faIcon: 'train',
                    id: 'train'
                },
                {
                    iconName: 'transport-tram',
                    displayName: 'SB_Tram',
                    unicode: '\uf7da',
                    faIcon: 'tram',
                    id: 'tram'
                },
                {
                    iconName: 'transport-truck',
                    displayName: 'SB_Truck',
                    unicode: '\uf0d1',
                    faIcon: 'truck',
                    id: 'truck'
                },
                {
                    iconName: 'transport-walk',
                    displayName: 'SB_Walk',
                    unicode: '\uf554',
                    faIcon: 'walking',
                    id: 'walk'
                },
                {
                    iconName: 'transport-other',
                    displayName: 'SB_Other',
                    unicode: '\uf85a',
                    faIcon: 'car-bus',
                    id: 'other'
                }
            ];

            service.sportIcons = [

                {
                    id: 55,
                    icon: 'running',
                    color: '#6400a6',
                    secondaryColor: '#a62e00'
                },
                {
                    id: 83,
                    icon: 'football-ball',
                    color: '#CD853F'
                },
                {
                    id: 42,
                    icon: 'paint-brush',
                    color: '#808000'
                },
                {
                    id: 12,
                    icon: 'running',
                    color: '#0052a6',
                    secondaryColor: '#a62e00'
                },
                {
                    id: 16,
                    icon: 'shuttlecock',
                    color: '#818583',
                    secondaryColor: '#ff9354'
                },
                {
                    id: 84,
                    icon: 'baseball',
                    color: '#A76D60'
                },
                {
                    id: 38,
                    icon: 'basketball-ball',
                    color: '#dd763a'
                },
                {
                    id: 48,
                    icon: 'game-board',
                    color: '#601700'
                },
                {
                    id: 19,
                    icon: 'boxing-glove',
                    color: '#d8082d'
                },
                {
                    id: 59,
                    icon: 'broom',
                    color: '#a16005'
                },
                {
                    id: 3,
                    icon: 'cricket',
                    color: '#be7105'
                },
                {
                    id: 39,
                    icon: 'running',
                    color: '#114B5F'
                },
                {
                    id: 20,
                    icon: 'biking',
                    color: '#00008B'
                },
                {
                    id: 37,
                    icon: 'walking',
                    color: '#2bc4ba'
                },
                {
                    id: 43,
                    icon: 'drafting-compass',
                    color: '#456990'
                },
                {
                    id: 41,
                    icon: 'theater-masks',
                    color: '#f0445e'
                },
                {
                    id: 40,
                    icon: 'crown',
                    color: '#456990'
                },
                {
                    id: 50,
                    icon: 'book',
                    color: '##219797'
                },
                {
                    id: 64,
                    icon: 'trees',
                    color: '#191970'
                },
                {
                    id: 61,
                    icon: 'book-reader',
                    color: '#B22222'
                },
                {
                    id: 51,
                    icon: 'film',
                    color: '#c2600a'
                },
                {
                    id: 21,
                    icon: 'fish',
                    color: '#322fff'
                },
                {
                    id: 1,
                    icon: 'futbol',
                    color: '#2C423F'
                },
                {
                    id: 34,
                    icon: 'futbol',
                    color: '#B8860B'
                },
                {
                    id: 73,
                    icon: 'futbol',
                    color: '#A52A2A'
                },
                {
                    id: 6,
                    icon: 'golf-club',
                    color: '#B0C4DE'
                },
                {
                    id: 71,
                    icon: 'running',
                    color: '#6400a6',
                    secondaryColor: '#a62e00'
                },
                {
                    id: 4,
                    icon: 'field-hockey',
                    color: '#cc903c'
                },
                {
                    id: 62,
                    icon: 'book-reader',
                    color: '#48D1CC'
                },
                {
                    id: 49,
                    icon: 'desktop-alt',
                    color: '#736673'
                },
                {
                    id: 23,
                    icon: 'user-shield',
                    color: '#736673'
                },
                {
                    id: 22,
                    icon: 'user-shield',
                    color: '#736673'
                },
                {
                    id: 68,
                    icon: 'field-hockey',
                    color: '#cc903c'
                },
                {
                    id: 45,
                    icon: 'language',
                    color: '#6495ED'
                },
                {
                    id: 52,
                    icon: 'books',
                    color: '#8e841e'
                },

                {
                    id: 24,
                    icon: 'user-shield',
                    color: '#736673'
                },
                {
                    id: 47,
                    icon: 'function',
                    color: '#db5b5b'
                },
                {
                    id: 35,
                    icon: 'saxophone',
                    color: '#FFD700'
                },
                {
                    id: 9,
                    icon: 'volleyball-ball',
                    color: '#929395'
                },
                {
                    id: 25,
                    icon: 'compass',
                    color: '#F4A460'
                },
                {
                    id: 36,
                    icon: 'chart-network',
                    color: '#745fa1'
                },
                {
                    id: 57,
                    icon: 'mountains',
                    color: '#696969'
                },
                {
                    id: 66,
                    icon: 'theater-masks',
                    color: '#C71585'
                },
                {
                    id: 54,
                    icon: 'comment-smile',
                    color: '#FF00FF'
                },
                {
                    id: 53,
                    icon: 'chart-network',
                    color: '#745fa1'
                },
                {
                    id: 67,
                    icon: 'clipboard-list-check',
                    color: '#a76f24'
                },
                {
                    id: 60,
                    icon: 'book-reader',
                    color: '#20B2AA'
                },
                {
                    id: 10,
                    icon: 'cricket',
                    color: '#b51717'
                },

                {
                    id: 26,
                    icon: 'anchor',
                    color: '#b51717'
                },
                {
                    id: 2,
                    icon: 'football-ball',
                    color: '#CD853F'
                },
                {
                    id: 7,
                    icon: 'football-ball',
                    color: '#CD853F'
                },
                {
                    id: 27,
                    icon: 'anchor',
                    color: '#114fdb'
                },
                {
                    id: 46,
                    icon: 'flask',
                    color: '#3185FC'
                },
                {
                    id: 56,
                    icon: 'tennis-ball',
                    color: '#F4989C'
                },
                {
                    id: 18,
                    icon: 'racquet',
                    color: '#FFA07A'
                },

                {
                    id: 44,
                    icon: 'book-reader',
                    color: '#20B2AA'
                },
                {
                    id: 11,
                    icon: 'swimmer',
                    color: '#2a31fb',
                    secondaryColor: '#ff940e'
                },
                {
                    id: 5,
                    icon: 'tennis-ball',
                    color: '#c9f364'
                },
                {
                    id: 63,
                    icon: 'clipboard-list',
                    color: '#a76f24'
                },
                {
                    id: 72,
                    icon: 'volleyball-ball',
                    color: '#3f57fd',
                    secondaryColor: '#ffff00'
                }
            ];

            // Add transport icons to main dictionary
            angular.forEach(transportIcons, function (transportIcon) {
                iconDictionary[transportIcon.iconName] = 'fal fa-' + transportIcon.faIcon;
            });

            // Add sport icons to main dictionary
            angular.forEach(service.sportIcons, function (sportIcon) {
                iconDictionary['sportIcon' + sportIcon.id] = 'fad fa-' + sportIcon.icon;
            });

            service.icons = function () {
                return iconDictionary;
            };

            service.getIcon = function (iconName) {
                return iconDictionary[iconName];
            };

            service.getTransportIcons = function () {
                return transportIcons
            };

            service.getSportIconColors = function (name) {
                var icon = service.sportIcons.filter(function (item) {
                    return 'sportIcon' + item.id == name;
                });
                if (icon.length > 0) {
                    return [icon[0].color, icon[0].secondaryColor];
                }
            };

            service.getTransportIcon = function (iconId) {
                var transportIcon = ($filter('filter')(transportIcons, { id: iconId }, true));
                if (transportIcon.length === 1) {
                    return transportIcon[0];
                }
            };

            service.getIcons = function () {
                return httpCoreApi.get('Icon', { controllerType: httpCoreApi.controllerTypes.User });
            };
        }
    ]);
