angular.module('shared.filters.currencySymbol', [])
    .filter('currencySymbol', function () {
        "use strict";

        var e = null; //only init as-needed, and keep around
        var cache = [];

        function showSymbol(input) {
            switch(input) {
                case 'USD':
                  return '&#36;';
                  break;
                case 'GBP':
                  return '&#163;';
                  break;
                case 'CNY':
                    return '&#165;';
                    break;
                case 'KRW':
                    return '&#8361;';
                    break;
                case 'EUR':
                    return '&#128;';
                    break;
                case 'THB':
                    return '&#3647; &#xE3F;';
                    break;
                case 'AED':
                    return '&#1583; &#1573;';
                    break;
                default:
                  input + ' ';
              } 
        }
        
        return function (input) {
            return showSymbol(input);
        }
    });
