angular.module('mortgage.components.mortgageDetailEditor',
    [
        'pascalprecht.translate',
        'ui.tinymce',

        'contacts.components.addressEditor',
        'corporateContacts.components.corporateContactPickerField',

        'financialProducts.components.feeSummary',

        'mortgage.controllers.mortgageStatusPopupController',
        'mortgage.services.mortgageService',

        'shared.components.sbPercentageInput',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'shared.components.sbTermPicker',
        'shared.controllers.confirmationPopup',
        'shared.directives.sbCheckBox',
        'shared.directives.sbCurrencyInput',
        'shared.services.saveChangesPromptService',
        'shared.services.simpleAlertService',
        'shared.services.tinymceConfigHelper'
    ])
    .component('mortgageDetailEditor',
    {
        bindings: {
            groupId: '<',
            onChange: '&',
            onStatusChange: '&'
        },
        controller: [
            '$uibModal',
            '$window',
            '$transitions',
            '$timeout',
            'mortgageService',
            'tinymceConfigHelper',
            'simpleAlertService',
            'saveChangesPromptService',
            function mortgageDetailEditorController(
                $uibModal,
                $window,
                $transitions,
                $timeout,
                mortgageService,
                tinymceConfigHelper,
                simpleAlertService,
                saveChangesPromptService)
            {
                this.loading = 0;
                this.mortgage = {};
                this.mortgageReference = null;
                this.group = null;

                this.modes = $window.EveryBuddy.Enums.GroupDetailEditorDisplayMode;
                this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({
                    fonts: false,
                    justify: false,
                    tables: false,
                    hyperlinks: false
                });

                this.caseStatuses = [];

                var self = this;

                this.isLoading = function () { return self.loading > 0; };

                var cleanMortgage = 'null';

                this.hasUnsavedChanges = { flag: false };

                saveChangesPromptService.setup(
                    $transitions,
                    this.hasUnsavedChanges,
                    'SB_leave_without_saving_changes',
                    ''); // not using the timeout so don't bother setting this

                this.$doCheck = function () {
                    // has anything actually changed from the db reference copy?
                    var currentMortgageJson = saveChangesPromptService.toJsonForComparison(this.mortgage);

                    if (currentMortgageJson !== cleanMortgage) {
                        console.log('mortgage.components.mortgageDetailEditor/$doCheck/changes found');
                        this.hasUnsavedChanges.flag = true;
                    }
                    else {
                        console.log('mortgage.components.mortgageDetailEditor/$doCheck/no changes');
                        this.hasUnsavedChanges.flag = false;
                    }
                }.bind(this);

                this.$onDestroy = function () {
                    saveChangesPromptService.reset();
                };

                this.$onChanges = function (changes)
                {
                    if (changes.groupId && changes.groupId.currentValue >= 0) {
                        self.mortgage = { };
                        self.load();
                    }

                    if (self.mortgageReference === null) {
                        self.mortgageReference = {};
                        self.loading++;
                        mortgageService.getMortgageReference()
                            .then(function(data)
                            {
                                self.mortgageReference = data;
                                self.loading--;
                            });
                    }

                };

                this.load = function () {

                    saveChangesPromptService.reset();

                    if (self.groupId === 0) {
                        self.mortgage = {
                            "id": 0,
                            "teamId": self.groupId
                        };
                    } else {
                        self.loading++;

                        mortgageService.getMortgageDetails(self.groupId)
                            .then(function (data) {
                                self.loading--;
                                self.mortgage = data;

                                cleanMortgage = saveChangesPromptService.toJsonForComparison(self.mortgage);
                                self.hasUnsavedChanges.flag = false;
                            })
                            .catch(function (error) {
                                console.error('An error occured loading group detail', error);
                                return error;
                            });
                    }
                };

                this.save = function () {
                    self.loading++;

                    mortgageService.saveMortgageDetails(self.mortgage)
                        .then(function (data) {

                            if (!data.isError)
                            {
                                self.mortgage = data;
                                if (typeof (self.onChange()) === 'function') {
                                    self.onChange()(self.mortgage);
                                }

                                cleanMortgage = saveChangesPromptService.toJsonForComparison(self.mortgage);
                                self.hasUnsavedChanges.flag = false;

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Saved',
                                    message: 'SB_Successfully_Saved'
                                });
                            } else
                            {
                                simpleAlertService.simpleAlert({
                                    title: 'SB_Error_Saving',
                                    message: 'SB_Error_Saving_Message'
                                });
                            }
                            self.loading--;
                        });
                };

                this.statusChange = function()
                {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Scripts/app/mortgage/controllers/mortgage-status-popup.template.html',
                        controller: 'mortgageStatusPopupController',
                        size: 'lg',
                        resolve: {
                            groupId: function() {
                                return self.groupId;
                            }
                        }
                    });

                    modalInstance
                        .result
                        .then(function(newValue)
                        {
                            mortgageService.getLatestMortgageStatus(self.groupId)
                                .then(function(data)
                                {
                                    self.mortgage.mortgageStatus = data.mortgageStatus;
                                    self.mortgage.mortgageStatusDTS = data.mortgageStatusDTS;

                                    if (self.onStatusChange && typeof self.onStatusChange() === 'function')
                                    {
                                        self.onStatusChange()(data);
                                    }
                                })
                                .catch(function(error)
                                {
                                    console.error('An error occured loading group detail', error);
                                    return error;
                                });
                        });
                };

                this.toggleERCNotApplicable = function () {
                    self.mortgage.ercEndDateNotApplicable = !self.mortgage.ercEndDateNotApplicable;
                    if (self.mortgage.ercEndDateNotApplicable) {
                        self.mortgage.ercEndDate = null;
                    }
                };

                this.productTermUpdated = function(newValue)
                {
                    this.mortgage.productTerm = newValue;
                }.bind(this);

                this.termUpdated = function (newValue) {
                    this.mortgage.term = newValue;
                }.bind(this);
            }
        ],
        templateUrl: '/Scripts/app/mortgage/components/mortgage-detail-editor.template.html'
    });
