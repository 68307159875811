angular.module('messagingModule', [

    'ui.bootstrap',

    'shared.components.sbOnOffSwitch',
    'shared.controllers.confirmationPopup',

    'shared.directives.cloakTranslate',
    'shared.directives.ngBindHtmlDataLabelAttr',
    'shared.directives.ngBindHtmlPlaceholder',
    'shared.directives.ngBindHtmlTitleAttr',
    'shared.directives.sbCheckBox',
    'shared.directives.sbRadioButton',

    'shared.directives.updateLanguage',

    'shared.services.authInterceptor',
    'shared.services.tinymceConfigHelper',
    'shared.components.sbIcon',
    'shared.services.simpleAlertService',
    'shared.services.toastService',

    'messaging.components.messageCenterComposerContainer',
    'messaging.components.messageCenterContainer',
    'messaging.components.messageCenterDashboard',
    'messaging.components.messageDetails',
    'messaging.components.allMessages',
    'messaging.services.messageViewingService',
    'messaging.services.messagingService',

    'organisation.services.organisationService',
    'reception.services.receptionService',


    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'angularMoment',

    'ng1StateDefinitionModule'

]).config([
    'ng1StateDefinitionProvider',
    function (
        ng1StateDefinitionProvider) {

        const ng1State = ng1StateDefinitionProvider.$get();

        const moduleName = 'Messaging';

        ng1State.state(moduleName, 'dashboard',
                {
                    url: '/',
                    // resolve: {
                    //     adminView: function () {
                    //         return false;
                    //     },
                    //     onLoaded: function () {
                    //         // return doTriggerReload;
                    //     },
                    //     selectedMessageId: function () {
                    //         return null;
                    //     },
                    //     limitChatFilters: ['$q', 'receptionService', 'messageViewingService',
                    //         function ($q, receptionService, messageViewingService) {
                    //             var deferred = $q.defer();

                    //             receptionService
                    //                 .getLookupData()
                    //                 .then(function (data) {
                    //                     var returnValue = messageViewingService.getChatFilters(data.isStatus365, data.isStaff, data.isParent);

                    //                     deferred.resolve(returnValue);
                    //                 })
                    //                 .catch(function (err) {
                    //                     console.error(err);
                    //                 });

                    //             return deferred.promise;
                    //         }],
                    //     preloadFirstMessage: function () {
                    //         // return !$window.mobileMode();
                    //     },
                    //     showChatSessionName: ['$q', 'receptionService',
                    //         function ($q, receptionService) {
                    //             var deferred = $q.defer();

                    //             receptionService
                    //                 .getLookupData()
                    //                 .then(function (data) {
                    //                     deferred.resolve(data.isStatus365);
                    //                 })
                    //                 .catch(function (err) {
                    //                     console.error(err);
                    //                     deferred.resolve(false);
                    //                 });

                    //             return deferred.promise;
                    //         }]
                    // }
                })

            ng1State.state(moduleName, 'dashboard.inboxForTypeSpecificMessage',
                {
                    url: '/inbox/:messageType/:id',
                })
            ng1State.state(moduleName, 'dashboard.inboxForType',
                {
                    url: '/inbox/:messageType/:teamId/:clubId',
                })
            ng1State.state(moduleName, 'dashboard.inboxSpecificMessage',
                {
                    url: '/inbox/:id',
                })
            ng1State.state(moduleName, 'dashboard.inbox',
                {
                    url: '/inbox',
                })

            ng1State.state(moduleName, 'dashboard.admin',
                {
                    url: '/admin'
                })

            ng1State.state(moduleName, 'dashboard.adminNew',
                {
                    url: '/adminNew',
                })

            ng1State.state(moduleName, 'dashboard.adminNew',
            {
                url: '/adminNew',
            });

            ng1State.state(moduleName, 'dashboard.compose',
                {
                    url: '/compose',
            });
    }
])
.component('ng1Messaging', {
      template: `<message-center-container admin-view="$ctrl.adminView"
                                           selected-message-id="$ctrl.selectedMessageId"
                                           selected-message-type="$ctrl.selectedMessageType"
                                           selected-team-id="$ctrl.selectedTeamId"
                                           selected-club-id="$ctrl.selectedClubId"
                                           show-new="$ctrl.showNew"></message-center-container>`,
      bindings: {
        // adminView no longer used due to new all messages page - worth leaving in in case needed again?
        adminView: '<',
        selectedMessageId: '<',
        selectedMessageType: '<',
        selectedTeamId: '<',
        selectedClubId: '<',
        showNew: '<',
      }
    })
.component('ng1AllMessages', {
    template: `<all-messages></all-messages>`,
    })
.component('ng1MessagingComposer', {
      template: '<message-center-composer-container restrict="$ctrl.restrict" show-tags="$ctrl.showTags"></message-center-composer-container>',
      controller: class Ng1MessagingComposer {
        messagingService: any;
        messageViewingService: any;
        restrict: boolean;
        showTags: boolean;

        static $inject = ['messagingService', 'messageViewingService'];
        constructor(messagingService, messageViewingService) {
            this.messagingService = messagingService;
            this.messageViewingService = messageViewingService;
        }

        $onInit() {
            this.messagingService.getPickerRestriction()
            .then((result) => {
                this.restrict = result;
            })
            .catch(() => {
                this.restrict = true;
            });
            this.messageViewingService.getAllLookups()
            .then((data) => {
                this.showTags = data.tagsEnabled;
            })
            .catch((err) => {
                console.error(err);
                this.showTags = false;
            });
        }
      }
    })
.component('ng1MessageDetails', {
  template: '<message-details></message-details>',
})
