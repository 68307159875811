'use strict';

angular.module('transport.configuration.transportStops',
    [
        'transport.services.transportConfigurationStopsService',
        'shared.components.sbOnOffSwitch',
        'shared.services.simpleAlertService',
    ])
    .component('transportStops',
    {
        bindings: {
        },
        templateUrl: '/Scripts/app/transport/configuration/stops/transport-stops.template.html',
        controller: [
            'transportConfigurationStopsService',
            'simpleAlertService',
            '$uibModal',
            function transportStopsController(transportConfigurationStopsService, simpleAlertService, $uibModal) {

                this.$onInit = function () {                    
                    this.loadStops();
                }.bind(this);

                this.loadStops = () => {
                    this.transportStops = null;

                    transportConfigurationStopsService.getStops()
                        .then(function (response)
                        {
                            this.transportStops = response;
                        }.bind(this))
                        .catch(function (error) {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                        }.bind(this));
                }

                //Table Sorting
                this.sortType = 'name';
                this.sortReverse = false;

                this.add = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        component: 'transportStopAdd',
                        size: 'lg',
                        backdrop: 'static'
                    });

                    modalInstance.result.then(
                        () => this.loadStops(),
                        () => {}
                    );
                };

                this.editStop = function (transportStop)
                {
                    var editModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'transportStopEdit',
                            size: 'lg',
                            backdrop: 'static',
                            resolve:
                            {
                                params: function ()
                                {
                                    return { transportStop: transportStop };
                                }
                            }
                        });

                    editModalInstance.result.then(
                        () => this.loadStops(),
                        () => {}
                    );
                };

                this.statusText = function (status)
                {
                    return status ? 'SB_Yes' : 'SB_No';
                }
            }
        ]
    });
