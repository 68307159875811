'use strict';

angular.module('cca.components.ccaGroupInstantBookSelection',
    [
        'balanceAdmin.services.balanceAdminService',
        'cca.components.ccaDeletePupilConfirmationContainerSignupUser',
        'cca.services.ccaService',
        'events.constants',
        'pascalprecht.translate',
        'shared.components.sbAvatar',
        'shared.components.sbIcon',
        'shared.services.arrayService',
        'shared.services.simpleAlertService',
        'shared.services.blobStoragePhotosService',
        'shared.directives.sbCheckBox',
        'shared.components.sbSearchFilter',
        'shared.components.sbSearchFilterItem',
        'shared.constants',
    ])
    .component('ccaGroupInstantBookSelection',
        {
            templateUrl: '/Scripts/app/cca/components/cca-group-instant-book-selection.template.html',
            bindings: {
                signUp: '<',
                eventsData: '<',
                organisation: '<',
                showProfileButtons: '<',
                allowOverbooking: '<',
                isManualBooking: '<',
                isSaving: '=',
                isSubmitted: '=',
                saveBookingsHandler: '&',
                toggleBookingHandler: '&',
                bookButtonHandler: '&',
                getSignUpEventsHandler: '&'
            },
            controller: [
                '$filter',
                '$interval',
                '$uibModal',
                '$state',
                '$scope',
                'availableRepository',
                'simpleAlertService',
                'arrayService',
                'blobStoragePhotosService',
                'ccaService',
                'eventCategorySystemUsageTypeEnum',
                'searchFilterTypes',
                function (
                    $filter,
                    $interval,
                    $uibModal,
                    $state,
                    $scope,
                    availableRepository,
                    simpleAlertService,
                    arrayService,
                    blobStoragePhotosService,
                    ccaService,
                    eventCategorySystemUsageTypeEnum,
                    searchFilterTypes)
                {
                    this.EventCategorySystemUsageType = eventCategorySystemUsageTypeEnum;

                    this.searchFilterTypes = searchFilterTypes;

                    this.EventCategorySystemUsageType = eventCategorySystemUsageTypeEnum;

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    this.signUp = {};
                    this.processing = {};
                    this.isLoadingClashes = true;
                    this.resetSearchFilters = function ()
                    {
                        this.searchFilters = { 'title': '', 'teamTitle': '', 'fromDay': '', 'text': '' };
                    }
                    this.resetSearchFilters();
                    this.sortType = 'from';
                    this.sortReverse = false;
                    this.currrentSortType = 'from';
                    this.fromSortReverse = false;
                    this.fromSortType = ['from', 'to', 'title'];
                    this.isClosed = false;

                    this.showToggleAllButtons = true;

                    this.timeRemaining;
                    this.timeRemainingMins;
                    this.timeRemainingSecs;
                    this.timer = null;
                    this.hasBookingSelectionTimedOut = false;
                    this.oldestBookingExpiry = null;
                    this.minutesUntilBookingExpiry;

                    //default to false if not defined
                    this.$onInit = function ()
                    {
                        if (!this.showProfileButtons)
                        {
                            this.showProfileButtons = false;
                        }
                    }.bind(this);

                    this.$onChanges = function (changes)
                    {
                        if (changes.signUp && changes.signUp.currentValue)
                        {
                            this.isClosed = moment(changes.signUp.currentValue.closeDate).isBefore();
                            this.showToggleAllButtons = changes.signUp.currentValue.maxBookableEventsPerGroup === 0;
                            this.allowCancellation = changes.signUp.currentValue.allowCancellation;
                            this.autoAdjustRefund = changes.signUp.currentValue.autoAdjustRefund;
                        }

                        if (changes.eventsData && changes.eventsData.currentValue)
                        {
                            var eventsData = changes.eventsData.currentValue;

                            this.minutesUntilBookingExpiry = eventsData.minutesUntilBookingExpiry;
                            this.hasBookingSelectionTimedOut = false;

                            this.pupils = eventsData.pupils;
                            this.signUpEvents = eventsData.availableEvents;
                            this.checkClashes();
                            // Add photoUrls to attendees for each event
                            if (this.signUpEvents.length > 0)
                            {
                                blobStoragePhotosService.addStudentPhotoUrlsToNestedArrays(this.signUpEvents).then(array =>
                                {
                                    this.signUpEvents = array;
                                });
                            }

                            var i = 0;
                            var j = 0;

                            this.processing = {};
                            for (i = eventsData.availableEvents.length; i--;)
                            {
                                this.processing['e' + eventsData.availableEvents[i].calendarEventId] = {};
                                var currentEventData = this.processing['e' + eventsData.availableEvents[i].calendarEventId];

                                for (j = eventsData.pupils.length; j--;)
                                {
                                    currentEventData['p' + eventsData.pupils[j].personId] = false;
                                }
                            }

                            this.uniqueTitles = [];

                            var uniqueTitleItems = $filter('unique')(this.signUpEvents, 'title');

                            for (i = 0; i < uniqueTitleItems.length; i++)
                            {
                                this.uniqueTitles.push(uniqueTitleItems[i].title);
                            }

                            this.uniqueTitles.sort();
                            this.uniqueTitles.splice(0, 0, '');

                            this.uniqueTeamTitles = [];

                            var uniqueTeamTitleItems = $filter('unique')(this.signUpEvents, 'teamTitle');

                            for (i = 0; i < uniqueTeamTitleItems.length; i++)
                            {
                                this.uniqueTeamTitles.push(uniqueTeamTitleItems[i].teamTitle);
                            }

                            this.uniqueTeamTitles.sort();
                            this.uniqueTeamTitles.splice(0, 0, '');

                            this.displayedFromDates = [];
                            // set up day filters
                            this.uniqueFromDays = [];

                            addDateGroupingProperties(this.signUpEvents);

                            if (this.signUpEvents)
                            {
                                for (i = 0; i < this.signUpEvents.length; i++)
                                {
                                    if (this.signUpEvents[i].isBookEvent)
                                    {
                                        //loop through all the event dates - must have a remaining dates array
                                        if (this.signUpEvents[i].remainingDates)
                                        {
                                            for (j = 0; j < this.signUpEvents[i].remainingDates.length; j++)
                                            {
                                                var uniqueFromDaysFiltered = this.uniqueFromDays.filter(function (day)
                                                {
                                                    return day.text === $filter('date')(this.signUpEvents[i].remainingDates[j], 'EEE');
                                                }.bind(this));

                                                //only push to the array of day filters if not in place already
                                                if (uniqueFromDaysFiltered && 0 === uniqueFromDaysFiltered.length)
                                                {
                                                    this.uniqueFromDays.push({
                                                        text: $filter('date')(this.signUpEvents[i].remainingDates[j], 'EEE'), dayIdx: new Date(this.signUpEvents[i].remainingDates[j]).getDay()
                                                    });
                                                }
                                            }
                                        }
                                    } else
                                    {
                                        //only push to the array of day filters if not in place already
                                        var uniqueFromDaysFiltered = this.uniqueFromDays.filter(function (day)
                                        {
                                            return day.text === $filter('date')(this.signUpEvents[i].from, 'EEE');
                                        }.bind(this));

                                        if (uniqueFromDaysFiltered && 0 === uniqueFromDaysFiltered.length)
                                        {
                                            this.uniqueFromDays.push({
                                                text: $filter('date')(this.signUpEvents[i].from, 'EEE'), dayIdx: new Date(this.signUpEvents[i].from).getDay()
                                            });
                                        }
                                    }
                                }

                                let doPendingBookingsExist = availableRepository.doPendingBookingsExist(this.signUpEvents);

                                if (doPendingBookingsExist)
                                {
                                    this.startTimer();
                                }
                            }

                            this.uniqueFromDays.sort(function (a, b)
                            {
                                if (a.dayIdx < b.dayIdx) { return -1; }
                                if (a.dayIdx > b.dayIdx) { return 1; }
                                return 0;
                            });

                            this.updateSelectClearButtonStates();
                            this.updateDisabledStatus();
                        }
                    }.bind(this);

                    this.confirmBookings = function ()
                    {
                        this.bookButtonHandler()();
                    }.bind(this);

                    this.costText = function (ccaPrice)
                    {
                        if (ccaPrice === 0 || ccaPrice === '0' || ccaPrice === '')
                        {
                            return 'SB_Free';
                        }

                        return $filter('number')(ccaPrice, 2);
                    };

                    this.totalSelectionCount = function ()
                    {
                        let selectionCount = 0;
                        this.oldestBookingExpiry = null;

                        if (this.signUpEvents)
                        {
                            this.signUpEvents.forEach((signUpEvent) =>
                            {
                                signUpEvent.pupils.forEach((pupil) =>
                                {
                                    if (pupil.status !== 2 && // Confirmed
                                        pupil.status !== 7) // Processing
                                    {
                                        selectionCount += pupil.selectionCount;
                                    }
                                });
                            });
                        }

                        if (selectionCount == 0 && this.timer)
                        {
                            $interval.cancel(this.timer);
                            this.timer = null;
                            this.timeRemainingMins = 0;
                            this.timeRemainingSecs = 0;
                        }

                        return selectionCount;
                    };

                    this.resetTimeRemaining = () =>
                    {
                        if (typeof this.minutesUntilBookingExpiry === 'undefined')
                        {
                            this.minutesUntilBookingExpiry = this.eventsData.minutesUntilBookingExpiry;
                        }

                        let bookingExpiryInfo = ccaService.getBookingExpiryInfo(this.signUpEvents);

                        // Oldest Booking Expiry is taken from any calendar event bookings that are not timed-out, removed, cancelled or deleted.
                        this.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;

                        this.timeRemaining = bookingExpiryInfo.timeRemaining;

                        if (this.oldestBookingExpiry == null)
                        {
                            let minutesToShowForCountdown = this.minutesUntilBookingExpiry > 5
                                ? this.minutesUntilBookingExpiry - 5
                                : this.minutesUntilBookingExpiry;

                            this.timeRemaining = Date.now();
                            this.timeRemaining = moment.utc(this.timeRemaining).set('minute', minutesToShowForCountdown);
                            this.timeRemaining = moment.utc(this.timeRemaining).set('second', 0);
                            this.timeRemainingMins = 0;
                            this.timeRemainingSecs = 0;
                        }

                        if (this.timer)
                        {
                            $interval.cancel(this.timer);
                            this.timer = null;
                        }
                    };

                    this.startTimer = () =>
                    {
                        this.resetTimeRemaining();

                        if (this.timer == null)
                        {
                            this.timer = $interval(() =>
                            {
                                this.timeRemaining = moment.utc(this.timeRemaining).subtract(1, 'seconds').toDate();

                                let secondsRemaining = moment.utc(this.timeRemaining).get('seconds');

                                if ((moment.utc(this.timeRemaining).get('minutes') == 0 &&
                                    secondsRemaining == 0) ||
                                    moment.utc(this.timeRemaining).get('minutes') > this.minutesUntilBookingExpiry)
                                {
                                    $interval.cancel(this.timer);
                                    this.timeOutBooking();
                                    this.deselectAllStudents();

                                    return;
                                }

                                // Re-calculate time remaining every 10 seconds in case user puts their computer
                                // to sleep to try to give themselves more time.
                                // Commented out for now as this is an extreme edge case.
                                //if (secondsRemaining % 10 == 0)
                                //{
                                //    this.getSignUpEventsHandler()()
                                //        .then((eventsData) =>
                                //        {
                                //            let bookingExpiryInfo = ccaService.getBookingExpiryInfo(eventsData.availableEvents);

                                //            // Oldest Booking Expiry is taken from any calendar event bookings that are not timed-out, cancelled or deleted.
                                //            this.oldestBookingExpiry = bookingExpiryInfo.oldestBookingExpiry;

                                //            if (this.oldestBookingExpiry != null)
                                //            {
                                //                this.timeRemaining = bookingExpiryInfo.timeRemaining;
                                //            }
                                //    });
                                //}

                                this.timeRemainingMins = moment.utc(this.timeRemaining).get('minutes');
                                this.timeRemainingSecs = moment.utc(this.timeRemaining).get('seconds');

                            }, 1000);
                        }
                    };

                    this.timeOutBooking = () =>
                    {
                        this.hasBookingSelectionTimedOut = true;
                        this.timeRemainingMins = 0;
                        this.timeRemainingSecs = 0;

                        let timeOutBookingsRequest =
                        {
                            signUpId: this.signUp.ccaSignUpId,
                            timeOutBookingRequests: []
                        }

                        this.signUpEvents.forEach((signUpEvent) =>
                        {
                            let selectedStudentIds = [];

                            signUpEvent.pupils.forEach((student) =>
                            {
                                if (student.selectionCount == 1)
                                {
                                    selectedStudentIds.push(student.personId);
                                }
                            });

                            if (selectedStudentIds.length > 0)
                            {
                                let timeOutBookingRequest =
                                {
                                    calendarEventId: signUpEvent.calendarEventId,
                                    selectedStudentIds: selectedStudentIds
                                }

                                timeOutBookingsRequest.timeOutBookingRequests.push(timeOutBookingRequest);
                            }
                        });

                        ccaService.timeOutSelectedStudentBookings(timeOutBookingsRequest);
                    }

                    this.deselectAllStudents = () =>
                    {
                        this.signUpEvents.forEach((signUpEvent) =>
                        {
                            signUpEvent.pupils.forEach((student) =>
                            {
                                student.selectionCount = 0;
                            });
                        });
                    };

                    this.bookingChecked = (pupil, anEvent) =>
                    {
                        this.processing['e' + anEvent.calendarEventId]['p' + pupil.personId] = true;
                        this.selectedQuantityChanging = true;
                        this.isSaving = true;

                        this.toggleBookingHandler()(pupil, anEvent)
                            .then(eventsData =>
                            {
                                // If any events chosen are outside the signup period then reload
                                for (let l = 0; l < eventsData.availableEvents.length; l++) {
                                    if (eventsData.availableEvents[l].isOutsideSignUpTimes) {
                                        const eventSignUpClosedAlert = simpleAlertService.simpleAlert({
                                            title: 'SB_SignupClosedAlertTitle',
                                            message: 'SB_SignupClosedAlertMessage',
                                            okButtonText: 'SB_OK',
                                            windowSize: 'md'
                                        });

                                        eventSignUpClosedAlert.result
                                            .then(function () {
                                                window.location.reload();
                                            })
                                            .catch(function () { });

                                        return;
                                    }
                                }

                                this.countdown = true;
                                this.disableRow = -1;
                                var selectedIndex = -1;

                                // Deep copy into state the latest values from the toggle booking response.
                                for (let i = 0; i < this.signUpEvents.length; i++)
                                {
                                    if (this.signUpEvents[i].calendarEventId === anEvent.calendarEventId)
                                    {
                                        this.signUpEvents[i].attendeeCount = eventsData.availableEvents[0].attendeeCount;
                                        this.signUpEvents[i].size = eventsData.availableEvents[0].size;
                                        this.signUpEvents[i].spacesLeft = eventsData.availableEvents[0].spacesLeft;
                                        this.signUpEvents[i].percentageLeft = eventsData.availableEvents[0].percentageLeft;

                                        for (let j = 0; j < this.signUpEvents[i].pupils.length; j++)
                                        {
                                            for (let k = 0; k < eventsData.availableEvents[0].pupils.length; k++)
                                            {
                                                if (this.signUpEvents[i].pupils[j].personId ===
                                                    eventsData.availableEvents[0].pupils[k].personId)
                                                {
                                                    this.signUpEvents[i].pupils[j].status =
                                                        eventsData.availableEvents[0].pupils[k].status;

                                                    this.signUpEvents[i].pupils[j].selectionCount = eventsData.availableEvents[0].pupils[k].selectionCount;
                                                    this.signUpEvents[i].pupils[j].expiryTimestampUtc = eventsData.availableEvents[0].pupils[k].expiryTimestampUtc;

                                                    selectedIndex =
                                                        eventsData.availableEvents[0].pupils[k].selectionCount > 0 ? i : -1;
                                                }
                                            }
                                        }

                                        break;
                                    }
                                }

                                this.selectedQuantityChanging = false;

                                this.updateSelectClearButtonStates();
                                this.updateDisabledStatus();
                            })
                            .catch(() =>
                            {
                                simpleAlertService.errorAlert();
                            })
                            .finally(() =>
                            {
                                this.processing['e' + anEvent.calendarEventId]['p' + pupil.personId] = false;
                                this.isSaving = false;
                                this.checkClashes();

                                this.startTimer();
                            });
                    };

                    var addDateGroupingProperties = function (eventArray)
                    {
                        var feeOnlyDate = '1912-06-23T00:00:00';

                        // pop on that extra fake property which is the from date, with the time set to midnight- this is used for filtering
                        for (var i = 0; i < eventArray.length; i++)
                        {
                            // unless this is a feeOnly event, in which case we want to group all these guys
                            // together at the top of the page with an arbitrary date in the past

                            if (eventArray[i].systemUsageTypeId === this.EventCategorySystemUsageType.Fee)
                            {
                                eventArray[i].from = feeOnlyDate;
                            }

                            var eventFromDate = new Date(eventArray[i].from);
                            eventArray[i].dateGrouping = eventFromDate.getFullYear() + '-' + eventFromDate.getMonth() + '-' + eventFromDate.getDate();

                            // group block bookings separately based on start and end date.
                            if (eventArray[i].isBookEvent)
                            {
                                var eventToDate = new Date(eventArray[i].to);
                                eventArray[i].dateGrouping += ':' + eventToDate.getFullYear() + '-' + eventToDate.getMonth() + '-' + eventToDate.getDate();
                            }
                        }
                    }.bind(this);

                    var getVisibleRows = function ()
                    {
                        return $filter('filter')(this.signUpEvents, this.search);
                    }.bind(this);

                    /**
                     * Updates the selection count flag on pupils based on whether they have any bookings.
                     * Used to enable or disble the 'Select All' and 'Clear All' buttons
                     */
                    this.updateSelectClearButtonStates = function ()
                    {
                        const visibleSignUpEvents = getVisibleRows();

                        // Set the selection count flag for all pupils that have a booking
                        this.pupils.forEach(function (pupil) {
                            pupil.selectionCount = 0;

                            visibleSignUpEvents.forEach(function (currentEvent) {
                                if (pupil.selectionCount === 0) {
                                    pupil.selectionCount = currentEvent.pupils.some(eventPupil =>
                                        eventPupil.personId === pupil.personId && eventPupil.selectionCount === 1
                                        && (eventPupil.status === 0 || eventPupil.status === 1)
                                    ) ? 1 : 0;
                                }
                            });
                        });
                    };

                    this.updateDisabledStatus = function ()
                    {
                        var maxSelectionsPerEvent = this.signUp.maxBookableEventsPerGroup;

                        if (maxSelectionsPerEvent > 0)
                        {
                            var peopleSignedUpEvents = [];
                            for (var i2 = 0; i2 < this.pupils.length; i2++)
                            {
                                peopleSignedUpEvents[this.pupils[i2].personId] = [];
                            }

                            // build a dictionary of which people have which events
                            for (var i = this.signUpEvents.length; i--;)
                            {
                                var teamId = this.signUpEvents[i].teamId;
                                for (var j = this.signUpEvents[i].pupils.length; j--;)
                                {
                                    var aPupil = this.signUpEvents[i].pupils[j];
                                    if (aPupil.selectionCount === 1)
                                    {
                                        if (!peopleSignedUpEvents[aPupil.personId][teamId])
                                        {
                                            peopleSignedUpEvents[aPupil.personId][teamId] = 1;
                                        } else
                                        {
                                            peopleSignedUpEvents[aPupil.personId][teamId]++;
                                        }
                                    }
                                }
                            }

                            // now loop through that dictionary disabling checkboxes as needed
                            for (var i1 = this.signUpEvents.length; i1--;)
                            {
                                var teamId1 = this.signUpEvents[i1].teamId;
                                for (var j2 = this.signUpEvents[i1].pupils.length; j2--;)
                                {
                                    var currentPupil = this.signUpEvents[i1].pupils[j2];
                                    if (
                                        // is this an event for this person which has already had it's max number of selections?
                                        peopleSignedUpEvents[currentPupil.personId][teamId1] >= maxSelectionsPerEvent &&
                                        // make sure we don't disable the box thats ticked or it cannot be unticked!
                                        currentPupil.selectionCount !== 1
                                    )
                                    {

                                        currentPupil.disabledByTimeSlot = true;

                                    } else
                                    {
                                        currentPupil.disabledByTimeSlot = false;
                                    }
                                }
                            }
                        }
                    }.bind(this);

                    this.showTimeColumns = function ()
                    {
                        var showTheTimeColumns = false;

                        if (this.signUpEvents)
                        {
                            for (var i1 = this.signUpEvents.length; i1--;)
                            {
                                if (this.signUpEvents[i1].systemUsageTypeId !== this.EventCategorySystemUsageType.Fee)
                                {
                                    showTheTimeColumns = true;
                                }
                            }
                        }

                        return showTheTimeColumns;
                    }.bind(this);

                    this.toggleAll = function (personId, checked)
                    {
                        //only toggle those that are currently visible
                        var visibleSignUpEvents = getVisibleRows();

                        this.selectedQuantityChanging = true;
                        this.isSaving = true;

                        angular.forEach(visibleSignUpEvents,
                            function (anEvent, key)
                            {
                                angular.forEach(anEvent.pupils,
                                    function (pupil, pupilkey)
                                    {
                                        //copy the logic in the view and add check for correct pupil and already booked
                                        if ((pupil.personId === personId && !((anEvent.spacesLeft === 0 && pupil.selectionCount === 0) || pupil.allowed === false)) && (pupil.status !== 2))
                                        {
                                            pupil.selectionCount = checked ? 1 : 0;
                                            this.processing['e' + anEvent.calendarEventId]['p' + pupil.personId] = true;
                                        }
                                    }.bind(this));
                            }.bind(this));

                        this.saveBookingsHandler()(this.signUpEvents, this.pupils, this.signUp.ccaSignUpId)
                            .then(function (eventsData)
                            {

                                arrayService.updateArrayFromArray(
                                    eventsData.pupils,
                                    this.pupils,
                                    'personId',
                                    [
                                        'allowed',
                                        'selectionCount',
                                        'status'
                                    ]);

                                arrayService.updateArrayFromArray(
                                    eventsData.availableEvents,
                                    this.signUpEvents,
                                    'calendarEventId',
                                    [
                                        'attendeeCount',
                                        'blockedOut',
                                        'percentageLeft',
                                        'remainingSessions',
                                        'size',
                                        'spacesLeft',
                                        'pupils',
                                        'remainingDates'
                                    ],
                                    {
                                        'pupils': {
                                            keyField: 'personId',
                                            fieldsToUpdate: [
                                                'allowed',
                                                'selectionCount',
                                                'status',
                                                'expiryTimestampUtc'
                                            ]
                                        }
                                    }
                                );

                                addDateGroupingProperties(this.signUpEvents);

                                this.updateSelectClearButtonStates();

                                this.processing = {};
                                for (var i = eventsData.availableEvents.length; i--;)
                                {

                                    this.processing['e' + eventsData.availableEvents[i].calendarEventId] = {};
                                    var currentEventData = this.processing['e' + eventsData.availableEvents[i].calendarEventId];

                                    for (var j = eventsData.pupils.length; j--;)
                                    {
                                        currentEventData['p' + eventsData.pupils[j].personId] = false;
                                    }
                                }

                                this.countdown = true;
                                this.isSaving = false;

                                this.selectedQuantityChanging = false;

                            }.bind(this))
                            .catch(function (err)
                            {
                                simpleAlertService.errorAlert();
                                console.error(err);
                            })
                            .finally(function ()
                            {
                                angular.forEach(visibleSignUpEvents,
                                    function (value, key)
                                    {
                                        angular.forEach(value.pupils,
                                            function (pupil, pupilkey)
                                            {
                                                if (pupil.personId === personId)
                                                {
                                                    this.processing['e' + value.calendarEventId]['p' + pupil.personId] = false;
                                                }
                                            }.bind(this));
                                    }.bind(this));

                                this.isSaving = false;
                                this.checkClashes();

                                this.startTimer();
                            }.bind(this));
                    }.bind(this);

                    this.search = function (item)
                    {
                        var lookForTitle = this.searchFilters.title;
                        var lookForTeamTitle = this.searchFilters.teamTitle;
                        var matchTeamTitle = lookForTeamTitle === '';
                        var matchTitle = lookForTitle === '';
                        var activeDayFilters = this.uniqueFromDays.filter(selectedDayFilter);
                        var matchNoDayFilter = (0 === activeDayFilters.length);
                        var lookForText = this.searchFilters.text;
                        var matchText = lookForText === '';

                        if (matchTeamTitle && matchTitle && matchNoDayFilter && matchText)
                        {
                            return true;
                        }

                        matchTeamTitle = matchTeamTitle ||
                            (lookForTeamTitle !== '' &&
                                item.teamTitle.toLowerCase() === lookForTeamTitle.toLowerCase());

                        matchTitle = matchTitle || (lookForTitle !== '' &&
                            item.title.toLowerCase() === lookForTitle.toLowerCase());

                        var dayMatchFound = matchNoDayFilter;

                        if (activeDayFilters.length > 0 && item.systemUsageTypeId === this.EventCategorySystemUsageType.Fee)
                        {
                            dayMatchFound = false;
                        }
                        else
                        {
                            var i = 0;

                            if (item.isBookEvent)
                            {
                                if (item.remainingDates)
                                {
                                    for (i = 0; i < activeDayFilters.length; i++)
                                    {
                                        for (var j = 0; j < item.remainingDates.length; j++)
                                        {
                                            if (activeDayFilters[i].text.toLowerCase() === $filter('date')(item.remainingDates[j], 'EEE').toLowerCase())
                                            {
                                                dayMatchFound = true;
                                            }
                                        }
                                    }
                                }
                            } else
                            {
                                for (i = 0; i < activeDayFilters.length; i++)
                                {
                                    if (activeDayFilters[i].text.toLowerCase() === $filter('date')(item.from, 'EEE').toLowerCase())
                                    {
                                        dayMatchFound = true;
                                    }
                                }
                            }
                        }

                        matchText = (item.teamTitle.toLowerCase().includes(lookForText.toLowerCase())) ||
                           (item.title.toLowerCase().includes(lookForText.toLowerCase()))

                        return matchTeamTitle && matchTitle && dayMatchFound && matchText;
                    }.bind(this);

                    var selectedDayFilter = function (dayFilter)
                    {
                        return dayFilter.selected;
                    };

                    this.sortSignUpEvents = function ()
                    {
                        this.signUpEvents = $filter('orderBy')($filter('orderBy')($filter('filter')(this.signUpEventsCopy, this.search), this.sortType, this.sortReverse), 'from', this.fromSortReverse);
                    };

                    this.showToggleAll = function (personId)
                    {
                        //get all visible events
                        var visibleSignUpEvents = $filter('filter')(this.signUpEvents, this.search);

                        var canBook = false;

                        for (var i = 0; i < visibleSignUpEvents.length; i++)
                        {
                            var currentPupil = visibleSignUpEvents[i].pupils.filter(function (pupil)
                            {
                                return pupil.personId === personId;
                            })[0];

                            canBook = visibleSignUpEvents[i].spacesLeft !== 0 &&
                                currentPupil.selectionCount !== 0 &&
                                currentPupil.allowed;
                        }

                        return canBook;
                    };

                    this.cancelBooking = function(calendarEvent, pupil)
                    {
                        var cancelBookingModal = $uibModal.open({
                            backdrop: 'static',
                            component: 'ccaDeletePupilConfirmationContainerSignupUser',
                            resolve: {
                                calendarEventId: function () { return calendarEvent.calendarEventId; }.bind(this),
                                pupilId: function () { return pupil.personId; }.bind(this)
                            },
                            size: 'md'
                        });

                        cancelBookingModal.result
                            .then(function () {
                                //console.log('ccaDeletePupilConfirmationContainerSignupUser/delete', 'delete popup resolved');
                                $state.reload();
                            }.bind(this))
                            .catch(function () {
                                //console.log('ccaDeletePupilConfirmationContainerSignupUser/delete', 'delete popup dismissed');
                            });
                    }.bind(this);

                    this.checkClashes = () => {
                        if (this.isManualBooking) {
                            this.isLoadingClashes = false;
                            return;
                        }
                        this.isLoadingClashes = true;
                        this.signUpEvents = ccaService.applySelectionToBlockEvents(this.signUpEvents);
                        this.signUpEvents.forEach(event => {
                            const newEventPupils = event.pupils.map(pupil => {
                                const checkOverlap = () => {
                                    const checkEventsOverlap = (event1, event2) => {
                                        return ccaService.checkOverlap(event1, pupil, event2, true) ||
                                        (event2.remainingSessionEvents &&
                                            event2.remainingSessionEvents.some(session => {
                                            return ccaService.checkOverlap(event1, pupil, session, true)
                                        }));
                                    }
                                    const overlapEvent = this.signUpEvents.some(signUpEvent => {
                                        return checkEventsOverlap(event, signUpEvent);
                                    });
                                    const overlapSession = event.remainingSessionEvents && event.remainingSessionEvents.some(session => {
                                        return this.signUpEvents.some(signUpEvent => {
                                            return checkEventsOverlap(session, signUpEvent);
                                        });
                                    })
                                    return overlapEvent || overlapSession;
                                }
                                // Pupil has clashes if overlapping events and is selected but not processing/booked
                                return {
                                    ... pupil,
                                    hasClashes: pupil.selectionCount === 1 && pupil.status < 2 && checkOverlap(),
                                    isClashBlocked: this.signUp.restrictClashes && pupil.selectionCount === 0  && checkOverlap()
                                }
                            })
                            event.pupils = newEventPupils;
                            // Check event for any pupil clashes
                            event.hasClashes = event.pupils.some(pupil => {
                                return pupil.hasClashes;
                            });
                        });
                        this.blockClashes = this.signUp.restrictClashes &&
                            this.signUpEvents.some(event => {
                                return event.hasClashes;
                            });
                        this.isLoadingClashes = false;
                    };

                    this.disableSelection = (anEvent, pupil) =>
                    {
                        if (this.hasBookingSelectionTimedOut)
                        {
                            return true;
                        }

                        if (pupil.selectionCount === 1 && !pupil.status)
                        {
                            return false;
                        }

                        return this.isSubmitted ||
                            ((!this.allowOverbooking && anEvent.spacesLeft === 0 && pupil.selectionCount === 0) ||
                                pupil.status === 2 || pupil.status === 7 || anEvent.blockedOut) ||
                            this.processing['e' + anEvent.calendarEventId]['p' + pupil.personId] === true ||
                            pupil.disabledByTimeSlot ||
                            pupil.isClashBlocked ||
                            // Block booking if clashes restricted and currently saving so they can't quickly book a clash
                            (this.signUp.restrictClashes && this.isSaving);
                    };
                }
            ]
        })
    .filter('filterHtmlChars', function ()
    {
        return function (html)
        {
            var filtered = angular.element('<div>').html(html).text();
            return filtered;
        }

    })
    .filter('removeHTMLTags', function ()
    {
        return function (text)
        {
            return text ? String(text).replace(/<[^>]+>/gm, '') : '';
        };
    });
