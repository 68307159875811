import 'angular-clipboard';
import 'angular-dynamic-locale';
import 'angular-file-saver';
import 'angular-file-upload';
import 'angular-filter';
import 'angular-messages';
import 'angular-moment';
import 'angular-sanitize';
import 'angular-scroll';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-ui-bootstrap';
import 'angular-ui-calendar';
import 'angular-ui-grid/ui-grid.js';
import 'angular-ui-tinymce';
import 'angularjs-daterangepicker';
import 'angularjs-dropdown-multiselect';
import 'ng-file-upload';
import 'ng-tags-input';
import 'ng-toast';
import 'tinymce';
import '../../../../../app/sticky.min.js';
