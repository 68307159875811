import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationLabelTags } from '@sb-shared/constants/organisation-label-tags.constants';
import { AvatarButtonAction } from '@sb-shared/constants/shared.constant';
import { RoleId } from '@sb-shared/enums/role.enum';
import { LegacyReceptionPersonCreateUpdate } from '@sb-shared/models/legacy-reception-person-create-update';
import { Organisation } from '@sb-shared/models/organisation';
import { Button } from '@sb-shared/models/UI/buttons';
import { WizardTab } from '@sb-shared/models/UI/wizard-tabs';
import { User } from '@sb-shared/models/user';
import { UserUi } from '@sb-shared/models/user-ui';
import { ConfirmDialogService } from '@sb-shared/services/confirm-dialog.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { UserService } from '@sb-shared/services/user.service';
import { finalize, forkJoin, Subscription } from 'rxjs';
import { MessagingService } from 'src/app/messaging/services/messaging.service';
import { ReceptionService } from 'src/app/reception/services/reception.service';

import { ProfileDataService } from '../../services/profile-data.service';
import { ProfileWizardDataService } from '../../services/profile-wizard-data.service';

@Component({
  selector: 'sb-profile-profile',
  templateUrl: './profile-profile.component.html',
  styleUrls: ['./profile-profile.component.scss']
})
export class ProfileProfileComponent implements OnInit {

  isOrganisationAdmin: boolean;
  isOrganisationSuperAdmin: boolean;
  editUserTabs: WizardTab[];
  selectedPerson: User;
  deleteUserButton: Button;
  messageUserButton: Button;
  isSaving = false;
  isLocked = true;
  currentUser: UserUi;
  currentOrganisation: Organisation;
  currentOrganisationIsSchool: boolean;
  currentUserOrganisations: string[];
  labels = {
    organisations: OrganisationLabelTags.Organisations,
  }

  readonly AvatarButtonAction = AvatarButtonAction;

  @Output() onUserUpdated: EventEmitter<void> = new EventEmitter();
  userInContext$: Subscription;

  constructor(
    private profileDataService: ProfileDataService,
    private profileWizardDataService: ProfileWizardDataService,
    private receptionService: ReceptionService,
    private confirmDialogService: ConfirmDialogService,
    private translateService: TranslateService,
    private messagingService: MessagingService,
    private organisationService: OrganisationService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.organisationService.getCurrentOrganisation(),
      this.userService.getCurrentUser()
    ]).subscribe(([organisation, user]: [Organisation, UserUi]) => {
      this.currentOrganisation = organisation;
      this.currentOrganisationIsSchool = this.organisationService.isSchool(organisation);
      this.isOrganisationAdmin = this.userService.checkUserRole(user, RoleId.Admin);
      this.isOrganisationSuperAdmin = this.userService.checkUserRole(user, RoleId.OrganisationSuperAdmin);
      this.currentUser = user;
      this.currentUserOrganisations = user.organisations;

      this.userInContext$ = this.profileDataService.getUserInContext()
        .subscribe(user => {
          if (!user) {
            return;
          }

          this.selectedPerson = user;

          this.updateButtons();

          this.isLocked = this.showLocked();

          this.editUserTabs = this.profileWizardDataService.getWizardTabs(this.currentOrganisation, this.currentUser, this.selectedPerson);
        });
    });
  }

  ngOnDestroy(): void {
    this.userInContext$.unsubscribe();
  }

  saveUser(user: User) {
    this.isSaving = true;

    const isEditingOtherStaffProfile = this.currentUser.id !== user.id && user.staffInformation;

    if (this.isOrganisationSuperAdmin && isEditingOtherStaffProfile) {
      const { staffInformation: { onlineMeetingId }, id: staffPersonId } = user;

      this.saveMeetingUrlFromSuperAdmins({ onlineMeetingId, staffPersonId });

      return;
    }

    this.receptionService.savePerson(this.getLegacyReceptionPersonCreateUpdate(user))
      .subscribe((data) => {
        setTimeout(() => {
          this.isSaving = false;
          if (data) {
            this.profileDataService.setSelectedPersonById(this.selectedPerson.personData.id);
            this.onUserUpdated.emit();
          }
        });
      });
  }

  deleteUserButtonClick() {
    const selectedPerson = this.selectedPerson;
    const isDeleted = selectedPerson.personStatus === 'SB_Deleted';
    const actionLabel = isDeleted ? 'SB_Restore' : 'SB_Delete';
    this.confirmDialogService.confirmThis({
      title: actionLabel,
      text:
        `${this.translateService.instant('SB_Please_Confirm_That_You_Wish_To_X', { Markup: this.translateService.instant(actionLabel) })}
       ${selectedPerson.personData.firstName} ${selectedPerson.personData.lastName}.`,
      isDanger: true,
      warningText: isDeleted ? 'SB_Restore_User_Links_Info' : ''
    }, () => {
      if (isDeleted) {
        this.receptionService.restorePerson(selectedPerson.personData.id, true)
          .subscribe(() => {
            this.profileDataService.setSelectedPersonById(this.selectedPerson.personData.id);
            this.onUserUpdated.emit();
          })

      }
      else {
        this.receptionService.deletePerson(this.selectedPerson.personData.id)
          .subscribe(() => {
            this.profileDataService.setSelectedPersonById(this.selectedPerson.personData.id);
            this.onUserUpdated.emit();
          })
      }
    }, () => null)
  }

  sendSingleAccessRequestEmail() {
    this.messageUserButton.isLoading = true;
    this.messagingService.sendSingleAccessRequestEmail(this.selectedPerson)
      .subscribe()
      .add(() => {
        this.messageUserButton = {
          isLoading: false,
          isDisabled: true,
          message: 'SB_Sent'
        };
      });
  }

  showLocked() {
    return this.isEditable() && this.selectedPerson.seenInMIS;
  }

  isReadOnly() {
    return !this.isEditable() || this.isLocked;
  }

  // Editable if:
  // - either:
  //    - viewing ourselves, not seen in MIS and is staff
  //    - clubs only, not seen in MIS and either parent viewing themselves or linked student
  //    - current user is super admin and user is student or parent (not staff parent)
  isEditable() {
    if (!this.selectedPerson) {
      return false;
    }

    if (this.isViewingSelf() && // viewing ourselves...
      !this.selectedPerson.seenInMIS && // ...not seen in MIS...
      this.selectedPerson.isStaff) { // ...and is staff
      return true;
    }

    if (!this.currentOrganisationIsSchool && // clubs...
      !this.selectedPerson.seenInMIS && // ...not seen in MIS and either...
      ((this.isViewingSelf() && this.selectedPerson.isParent) || // ...parent viewing themselves or...
        this.isViewingChildLinkedToSelf())) { // ...parent viewing linked student.
      return true;
    }

    return this.isOrganisationSuperAdmin && // super admin can edit...
      (this.selectedPerson.isParent || this.selectedPerson.isPupil && // parents and pupils...
      !this.selectedPerson.isDeleted) && // ...not deleted...
      !this.selectedPerson.isStaff && // but not staff parents...
      !this.selectedPerson.isExternal; // or to be sure, not external.
  }

  getLegacyReceptionPersonCreateUpdate(user: User): LegacyReceptionPersonCreateUpdate {
    return {
      ...user.personData,
      emailAddress: user.emailAddress,
      id: user.id,
      isExternal: user.userType ? user.userType == 'isExternal' : user.isExternal,
      isExternalCoachAccess: user.isExternalCoachAccess,
      isParent: user.isParent,
      isPupil: user.userType ? user.userType == 'isStudent' : user.isPupil,
      isStaff: user.userType ? user.userType == 'isStaff' : user.isStaff,
      isTransportBusMonitor: user.isTransportBusMonitor,
      misId: user.misId,
      attendanceScanningCode: user.pupilInformation?.attendanceScanningCode,
      onlineMeetingId: user.staffInformation?.onlineMeetingId,
      schoolYearGroupId: user.pupilInformation?.schoolYearGroupId,
      schoolClassId: user.pupilInformation?.schoolClassId,
      schoolHouseId: user.pupilInformation?.schoolHouseId
    };
  }

  isViewingSelf() {
    return this.selectedPerson?.id == this.currentUser.id;
  }

  isViewingChildLinkedToSelf() {
    return this.currentUser.children.some(child => child.id == this.selectedPerson?.id);
  }


  subHeadingLabel() {
    if (!this.selectedPerson) {
      return '';
    }

    if (this.isViewingSelf()) {
      return 'SB_Your_Information';
    }

    if (this.selectedPerson.isStaff) {
      return 'SB_Staff_Information';
    }

    if (this.selectedPerson.isExternal) {
      return 'SB_External_User_Information';
    }

    if (this.selectedPerson.isParent) {
      return 'SB_Parent_Information';
    }

    if (this.selectedPerson.isPupil) {
      return 'SB_Pupil_Information';
    }

    return '';
  }

  showUserStatus() {
    return this.selectedPerson?.personStatus === 'SB_Active' ||
      this.selectedPerson?.personStatus === 'SB_Inactive' ||
      this.selectedPerson?.personStatus === 'SB_Not_logged_in' ||
      this.selectedPerson?.personStatus === 'SB_Deleted';
  }

  private updateButtons() {
    if (this.profileDataService.isReception() && this.isOrganisationSuperAdmin && this.selectedPerson.personData?.id > 0 && !this.isViewingSelf()) {
      this.deleteUserButton = {
        message: this.selectedPerson.personStatus === 'SB_Deleted' ? 'SB_Restore' : 'SB_Delete'
      };
    }
    else {
      this.deleteUserButton = null;
    }

    if (this.profileDataService.isReception() && this.isOrganisationAdmin) {
      const activationLabel = this.messagingService.getActivationLabel(this.selectedPerson);
      this.messageUserButton = !activationLabel ? null //!set to null to hide the button, for deleted users
        : { message: activationLabel };
    }
  }

  /**
   * Save online meeting url to user, can ONLY used by a user with super admin role
   *
   * @private
   * @param {User} user
   * @memberof ProfileProfileComponent
   */
  private saveMeetingUrlFromSuperAdmins({ staffPersonId, onlineMeetingId }) {
    if (!this.userService.checkUserRole(this.currentUser, RoleId.OrganisationSuperAdmin)) {
      return;
    }

    this.receptionService.updateOnlineMeetingUrl({ onlineMeetingId, staffPersonId },)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe()
  }
}
