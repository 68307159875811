"use strict";

angular.module('receptionModule').component("receptionEventsGuardians", {
    templateUrl: '/Scripts/app/reception/controllers/reception-events-guardians.template.html?v=', // + window.EveryBuddy.Version,
    controller:
    [
        "$scope", "$state",
        function ($scope, $state) {

        }
    ]});