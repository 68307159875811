'use strict';

angular.module('receptionModule').component('receptionEvents', {
    templateUrl: '/Scripts/app/reception/controllers/reception-events.template.html?v=', // + window.EveryBuddy.Version,
    bindings: {
        onSelectEvent: '&',
        refreshFn: '<'
    },
    controller: [
    '$scope',
    '$state',
    '$timeout',
    '$filter',
    '$translate',
    'consentEventTabsService',
    'receptionService',
    'teamService',
    'iconService',
    'eventsService',
    'personService',
    'settingService',
    'registerFileService',
    'eventRegistersService',
    'eventRegisterTransportService',
    'searchService',
    'searchFilterTypes',
    'dateRangeLists',
    'ngToast',
    function ($scope,
        $state,
        $timeout,
        $filter,
        $translate,
        consentEventTabsService,
        receptionService,
        teamService,
        iconService,
        eventsService,
        personService,
        settingService,
        registerFileService,
        eventRegistersService,
        eventRegisterTransportService,
        searchService,
        searchFilterTypes,
        dateRangeLists,
        ngToast) {

        $scope.eventConfigCollapsed = true;

        $scope.showSearch = false;
        $scope.showNext = false;
        $scope.loadingNext = false;
        $scope.loadingNextDone = false;
        $scope.organisedByMe = true;
        $scope.useNewConsentTabs = false;

        $scope.uniqueEventTypes = [];

        $scope.eventsFrom = moment().startOf('day');

        $scope.eventsTo = moment().endOf('day');

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.resetSearchFilters = function () {

            $scope.searchFilters = {
                eventId: 0,
                teamId: 0,
                personId: 0,
                organiserId: 0,
                clubId: 0,
                includeRegisterStarted: true,
                includeRegisterCompleted: true,
                includeDeleted: false,
                includedEventTypes: [].concat($scope.uniqueEventTypes),
                textFilter: ''
            };

        };

        consentEventTabsService.getDefaultUseNewConsentTabsSetting()
            .then(function (response) {
                $scope.useNewConsentTabs = response.data.value == 'True';
            });

        $scope.resetSearchFilters();

        $scope.searchFilterTypes = searchFilterTypes;
        $scope.dateRangeLists = dateRangeLists;

        this.$onInit = () => {
            settingService.getEventTypesSetting()
                .then((data) => {
                    $scope.eventTypesConfig = data;
                    $scope.refresh();
                });

            this.refreshFn.fn = $scope.refresh;
        };

        $scope.$on('registerUpdated',
            function (event, data) {
                if (!data || !$scope.selectedEvent) {
                    return;
                }

                $scope.selectedEvent.registerTaken = data.registerTaken;
                $scope.selectedEvent.registerIncomplete = data.registerIncomplete;
            });

        personService.getCurrentPersonId()
            .then(function (data) {
                $scope.currentPersonId = data;
            });

        $scope.$on('registerAttendeeAdded',
            function (event, data) {
                teamService.clear();
                eventsService.clear();
            });

        $scope.menuItems = [
            {
                uisref: '.specificEvent.register',
                title: 'SB_Register',
                icon: iconService.getIcon('register'),
                sortOrder: 1
            },
            {
                uisref: '.specificEvent.details',
                title: 'SB_Details',
                icon: iconService.getIcon('info'),
                sortOrder: 2
            },
            {
                uisref: '.specificEvent.autoNotify',
                title: 'SB_Notify',
                icon: iconService.getIcon('bell'),
                sortOrder: 3,
                isHidden: true
            },
            {
                uisref: '.specificEvent.notifyOnly',
                title: 'SB_Notify',
                icon: iconService.getIcon('bell'),
                sortOrder: 3, // same as above as they cannot both be visible at the same time
                isHidden: true
            },
            {
                uisref: '.specificEvent.consentEventInvites',
                title: 'SB_Send_Invites',
                icon: iconService.getIcon('bell'),
                sortOrder: 3, // same as above as they cannot be visible at the same time
                isHidden: true
            },
            {
                uisref: '.specificEvent.guardians',
                title: 'SB_Guardians',
                icon: iconService.getIcon('guardians'),
                sortOrder: 4
            },
            {
                uisref: '.specificEvent.sendComms',
                title: 'SB_Send_Communication',
                icon: iconService.getIcon('sendCommunications'),
                sortOrder: 5
            }];

        teamService.getTabVisibility()
            .then(function (data) {
                $scope.tabVisibility = data;

                if (data.Medical) {
                    $scope.menuItems.push({
                        uisref: '.specificEvent.medical',
                        title: 'SB_Medical',
                        icon: iconService.getIcon('medical'),
                        sortOrder: 6
                    });
                }
                if (data.FormTags) {
                    $scope.menuItems.push({
                        uisref: '.specificEvent.formtags',
                        title: 'SB_Tagged_Data',
                        icon: iconService.getIcon('formtags'),
                        sortOrder: 7
                    });
                }
            });

        //loads data from API
        $scope.refresh = (settings) => {
            // Code to handle load of page with eventId already set
            const currentEventId = $state.params.eventId;
            if (currentEventId && !settings?.resetEventId) {
                const eventToSelect = $scope.events?.find(event => event.Id == currentEventId);
                if (eventToSelect) {
                    $scope.selectedEvent = eventToSelect;
                    this.onSelectEvent({event: $scope.selectedEvent});
                }
                else {
                    eventsService.getEventDetails(currentEventId).then(res => {
                        const event = res?.details;
                        if (event) {
                            // Update date range if it doesn't cover selected event
                            if ($scope.eventsFrom.isAfter(event.from) || $scope.eventsTo.isBefore(event.to)) {
                                $scope.eventsFrom = moment(event.from).startOf('d');
                                $scope.eventsTo = moment(event.to).endOf('d');
                                $scope.refresh();
                            }
                        }
                        else {
                            // Event not found
                        }
                    })
                }
            }

            $scope.eventConfigCollapsed = true;

            $scope.showLoading = true;

            receptionService
                .getEventSummary(
                    $scope.eventsFrom,
                    $scope.eventsTo,
                    $scope.eventTypesConfig.includeParentsEvening,
                    $scope.eventTypesConfig.includeFeeOnlyEvents,
                    $scope.eventTypesConfig.includeTransportEvents)
                .then(
                    (data) => {
                        $scope.showLoading = false;
                        $scope.events = data.events;
                        $scope.teams = data.teams;
                        $scope.clubs = data.clubs;
                        $scope.organisers = data.organisers;

                        if ($state.params.eventId)
                        {
                            var eventId = parseInt($state.params.eventId);
                            for (var i = $scope.events.length; i--;)
                            {
                                if ($scope.events[i].calendarEventId === eventId)
                                {
                                    $scope.selectedEvent = $scope.events[i];
                                    this.onSelectEvent({event: $scope.selectedEvent});
                                    $scope.selectedEvent.isMultiDay = !moment($scope.selectedEvent.from).isSame($scope.selectedEvent.to, 'day') && typeof $scope.selectedEvent.from == typeof $scope.selectedEvent.to;
                                    $scope.showNext = false;
                                    $scope.selectedRegister = null;

                                    eventsService.getEventInfo(eventId)
                                        .then(function (response)
                                        {
                                            var autoNotifyMenuItem = $scope.menuItems.filter(function (menuItem) { return menuItem.uisref == '.specificEvent.autoNotify'; })[0];
                                            var notifyOnlyMenuItem = $scope.menuItems.filter(function (menuItem) { return menuItem.uisref == '.specificEvent.notifyOnly'; })[0];
                                            var consentEventInvitesMenuItem = $scope.menuItems.filter(function (menuItem) { return menuItem.uisref == '.specificEvent.consentEventInvites'; })[0];

                                            autoNotifyMenuItem.isHidden = !response.isAutoNotifyEvent || !$scope.useNewConsentTabs;
                                            notifyOnlyMenuItem.isHidden = !response.isNotifyOnlyEvent || !$scope.useNewConsentTabs;
                                            consentEventInvitesMenuItem.isHidden = !response.isConsentEvent || !$scope.useNewConsentTabs;

                                            $scope.currentNotifyAudienceTypeId = response.currentNotifyAudienceTypeId;
                                        })

                                    break;
                                }
                            }
                        }

                        $scope.getUniqueEventTypes();

                        $timeout(function () {
                            $scope.showLoading = false;
                        });
                    },
                    function (error) {
                        $scope.showLoading = false;
                    });
        };
        //pass false for first time load as pref
        //$scope.refresh(false);

        $scope.getUniqueEventTypes = function() {
            var lists = searchService.getMultiSelectLists($scope.events, 'eventType', 'eventType', $scope.uniqueEventTypes, $scope.searchFilters.includedEventTypes);
            $scope.uniqueEventTypes = lists.uniqueList;
            $scope.searchFilters.includedEventTypes = lists.selectedList;
        };

        $translate.onReady().then(function () {
            $scope.eventTypesText = $filter('translate')('SB_Event_Types');
        });

        $scope.updateEventTypesConfig = function() {
            $scope.uniqueEventTypes = [];
            $scope.searchFilters.includedEventTypes = [];
            settingService.saveEventTypesSetting(JSON.stringify($scope.eventTypesConfig));
            $scope.refresh();
        };

        $scope.showRegister = (anEvent) =>
        {
            eventsService.getEventInfo(anEvent.calendarEventId)
                .then((response) =>
                {
                    var autoNotifyMenuItem = $scope.menuItems.filter(function (menuItem) { return menuItem.uisref == '.specificEvent.autoNotify'; })[0];
                    var notifyOnlyMenuItem = $scope.menuItems.filter(function (menuItem) { return menuItem.uisref == '.specificEvent.notifyOnly'; })[0];
                    var consentEventInvitesMenuItem = $scope.menuItems.filter(function (menuItem) { return menuItem.uisref == '.specificEvent.consentEventInvites'; })[0];

                    autoNotifyMenuItem.isHidden = !response.isAutoNotifyEvent || !$scope.useNewConsentTabs;
                    notifyOnlyMenuItem.isHidden = !response.isNotifyOnlyEvent || !$scope.useNewConsentTabs;
                    consentEventInvitesMenuItem.isHidden = !response.isConsentEvent || !$scope.useNewConsentTabs;

                    $scope.selectedEvent = anEvent;
                    $scope.showNext = false;
                    $scope.selectedRegister = null;

                    $state
                        .go('dashboard.events.specificEvent.register', { eventId: anEvent.calendarEventId })
                        // Angular12 app needs to store event
                        .then(() => this.onSelectEvent({event: $scope.selectedEvent}))
                })
        };

        $scope.sortType = 'from';

        $scope.sortReverse = false;

        $scope.isPartiallyComplete = function(item) {
            return item.registerTaken === 1 && item.registerIncomplete;
        };

        $scope.isComplete = function(item) {
            return item.registerTaken === 1 && !item.registerIncomplete && !item.absenceNotificationSent;
        };

        $scope.isIncomplete = function(item) {
            return item.registerTaken === 1 && !item.registerIncomplete && item.absenceNotificationSent;
        };

        $scope.search = function (item)
        {
            if ($scope.searchFilters.eventId === 0 &&
                $scope.searchFilters.organiserId === 0 &&
                $scope.searchFilters.teamId === 0 &&
                $scope.searchFilters.includeRegisterStarted &&
                $scope.searchFilters.includeRegisterCompleted &&
                $scope.searchFilters.clubId === 0 &&
                $scope.searchFilters.includedEventTypes.length == $scope.uniqueEventTypes.length &&
                $scope.searchFilters.includeDeleted &&
                $scope.searchFilters.textFilter == '') {
                    return true;
                }
                var eventMatch = $scope.searchFilters.eventId === 0;
                if ($scope.searchFilters.eventId > 0 && item.calendarEventId === $scope.searchFilters.eventId) {
                    eventMatch = true;
                }
                var organiserMatch = $scope.searchFilters.organiserId === 0;
                if ($scope.searchFilters.organiserId > 0 && item.organiserId === $scope.searchFilters.organiserId) {
                    organiserMatch = true;
                }
                var teamMatch = $scope.searchFilters.teamId === 0;
                if ($scope.searchFilters.teamId > 0 && item.teamId === $scope.searchFilters.teamId) {
                    teamMatch = true;
                }
                var clubMatch = $scope.searchFilters.clubId === 0;
                if ($scope.searchFilters.clubId > 0 && item.clubId === $scope.searchFilters.clubId) {
                    clubMatch = true;
                }
                // If 'include attendance started' filter on: match any
                var registerStartedMatch = $scope.searchFilters.includeRegisterStarted;
                // If register not started: match regardless of filter
                if (!item.registerTaken) {
                    registerStartedMatch = true;
                }
                // If 'include attendance completed' on: match any
                var registerCompletedMatch = $scope.searchFilters.includeRegisterCompleted;
                // If NOT complete: match regardless of filter
                if (!$scope.isComplete(item)) {
                    registerCompletedMatch = true;
                }
                var eventTypeMatch = $scope.searchFilters.includedEventTypes.length == $scope.uniqueEventTypes.length;
                var includedEventTypeIds = $scope.searchFilters.includedEventTypes.map(function (type){
                    return type.id;
                });
                if (includedEventTypeIds.includes(item.eventType)) {
                    eventTypeMatch = true;
                }
                var deletedMatch = $scope.searchFilters.includeDeleted;
                if (!item.isDeleted) {
                    deletedMatch = true;
                }
                var textMatch = $scope.searchFilters.textFilter == '';
                if (item.title.toLowerCase().indexOf($scope.searchFilters.textFilter.toLowerCase()) != -1 ||
                    item.for.toLowerCase().indexOf($scope.searchFilters.textFilter.toLowerCase()) != -1) {
                    textMatch = true;
                }
                var match = eventMatch && teamMatch && clubMatch && organiserMatch && registerStartedMatch && registerCompletedMatch && eventTypeMatch && deletedMatch && textMatch;
                return match;
        };
        $scope.isLoadingDownload = false;

        $scope.downloadRequest = null;

        $scope.downloadRegisters = function () {
            if ($scope.isLoadingDownload) {
                // cancel HTTP request
                $scope.downloadRequest.cancel();
                $scope.isLoadingDownload = false;
                return;
            }

            var registerDownloadMax = 200;
            var filteredEvents = $scope.events.filter(function (event) {
                return $scope.search(event);
            });

            if (filteredEvents.length > registerDownloadMax) {
                ngToast.danger({
                    content: $filter('translate')('SB_Register_Download_Max', { max: registerDownloadMax }),
                    dismissButton: true,
                    dismissOnTimeout: false
                });

                return;
            }

            $scope.isLoadingDownload = true;
            var eventIds = filteredEvents.map(function (event) {
                return event.calendarEventId;
            });

            $scope.downloadRequest = eventRegistersService.getOfflineEventRegisters(eventIds);
            $scope.downloadRequest.promise
                .then(function (data) {
                    if (!data) {
                        return;
                    }
                    var registerEvents = [];
                    data.events.forEach(function (registerEvent) {
                        var attendees = registerEvent.attendees;
                        registerEvent.attendants = attendees;
                        if (attendees.length > 0) {
                            registerEvent.attendeeSections = eventRegisterTransportService.getAttendeeSections(registerEvent);
                            registerEvents.push(registerEvent);
                        }
                    });
                    if (registerEvents.length > 0) {
                        registerFileService.formatRegisters(registerEvents);
                        $scope.isLoadingDownload = false;
                    } else {
                        ngToast.danger({
                            content: $filter('translate')('SB_No_Registers_Attendants'),
                            dismissButton: true,
                            dismissOnTimeout: false
                        });
                        $scope.isLoadingDownload = false;
                    }
                }, function (error) {
                    console.log(error);
                    ngToast.danger({
                        content: $filter('translate')('SB_Download_Stopped'),
                        dismissButton: false,
                        dismissOnTimeout: true
                    });
                    $scope.isLoadingDownload = false;
                }, function (rejected) {
                    console.log(rejected);
                    ngToast.danger({
                        content: $filter('translate')('SB_Offline_Registers_Timeout_Message'),
                        dismissButton: false,
                        dismissOnTimeout: true
                    });
                    $scope.isLoadingDownload = false;
                });
        };
        $scope.toggleEventConfig = function() {
            $scope.eventConfigCollapsed = !$scope.eventConfigCollapsed;
        };
    }
]});
