'use strict';

angular.module('diary.components.diaryEventDetail',
    [
        'diary.components.diaryEventDetailInfo',
        'diary.services.diaryService',
        'events.services.eventsService',
        'events.components.eventsRegisterTransportAddStudentSelectStops',
        'transport.services.pupilTransportPreferenceService',
        'transport.services.transportTimeSlotsService',
        'pascalprecht.translate',
        'shared.components.sbIcon',
        'shared.components.sbStackedDate',
        'shared.components.sbRouteTimeline',
        'shared.components.sbSummary',
        'shared.components.subMenu',
        'shared.components.sbTitleBar',
        'shared.services.cachedLookupService',
        'shared.services.iconService',
        'shared.services.dateTimeService',
        'shared.services.consentEventTabsService',
    ])
    .component('diaryEventDetail',
        {
            bindings: {
                eventData: '<',
                showTitleBar: '<',
                isStaff: '<',
                isExternal: '<',
                onBackButtonClicked: '&'
            },
            templateUrl: '/Scripts/app/diary/components/diary-event-detail.template.html',
            controller: ['$state',
                '$window',
                '$filter',
                '$rootScope',
                '$interval',
                'iconService',
                'consentEventTabsService',
                'diaryService',
                'eventsService',
                'cachedLookupService',
                'dateTimeService',
                'toastService',
                function ($state, $window, $filter, $rootScope, $interval, iconService, consentEventTabsService, diaryService, eventsService, cachedLookupService, dateTimeService, toastService)
                {
                    var staffEnabled = false;
                    var loading = 0;
                    this.eventData = null;
                    this.multiDay = false;

                    this.useNewConsentTabs = false;

                    consentEventTabsService.getDefaultUseNewConsentTabsSetting()
                        .then(function (response){
                            this.useNewConsentTabs = response.data.value == 'True';
                    }.bind(this));

                    this.isLoading = function () { return loading > 0; };

                    this.transportStatusUpdate = null;

                    $rootScope.$on('$locationChangeSuccess', (event, current, previous) =>
                    {
                        const currentArray = current.split('/');
                        this.hideSummary = currentArray[currentArray.length - 1] === 'video';
                    });

                    this.menuItems = [
                        {
                            uisref: '.info',
                            title: 'SB_Details',
                            icon: iconService.getIcon('info'),
                            sortOrder: 1
                        }
                    ];

                    this.$onChanges = function (changes)
                    {
                        if (changes.eventData && changes.eventData.currentValue)
                        {
                            // is multiday?

                            var start = changes.eventData.currentValue.from instanceof moment
                                ? changes.eventData.currentValue.from
                                : moment(changes.eventData.currentValue.from);

                            var end = changes.eventData.currentValue.to instanceof moment
                                ? changes.eventData.currentValue.to
                                : moment(changes.eventData.currentValue.to);

                            if (!start.isSame(end, 'day'))
                            {
                                this.multiDay = true;
                            }

                            loading--;

                            $state.eventData = this.eventData;
                            this.handleVideo();

                            if (this.isTransport() && this.hasStops() && this.showETAWarnings() && this.isEventToday())
                            {
                                this.getTransportStatusUpdates();

                                diaryService.getTransportStatusUpdatesRefreshSettings()
                                    .then(function (response)
                                    {
                                        let diaryEventDetailRefreshIntervalMilliseconds = response.data.diaryEventDetailRefreshIntervalSeconds * 1000;
                                        if (diaryEventDetailRefreshIntervalMilliseconds == 0)
                                        {
                                            diaryEventDetailRefreshIntervalMilliseconds = 180000;
                                        }

                                        if (this.transportStatusUpdate == null)
                                        {
                                            this.transportStatusUpdate = $interval(() =>
                                            {
                                                this.getTransportStatusUpdates();
                                            }, diaryEventDetailRefreshIntervalMilliseconds); // Refresh transport status at a given interval, typically every minute.
                                        }
                                    }.bind(this));
                            }

                            if (changes.isStaff && typeof (changes.isStaff.currentValue) !== 'undefined')
                            {
                                if (changes.isStaff.currentValue && !this.eventData.attendanceData)
                                {
                                    this.enableStaffMenuItems();

                                    cachedLookupService.getGroupFeature('Medical')
                                        .then(function (data)
                                        {
                                            if (data === true)
                                            {
                                                this.menuItems.push({
                                                    uisref: '.medical',
                                                    title: 'SB_Medical',
                                                    icon: iconService.getIcon('medical'),
                                                    sortOrder: 4
                                                });
                                            }
                                        }.bind(this));

                                    cachedLookupService.getGroupFeature('Guardians')
                                        .then(function (data)
                                        {
                                            if (data === true)
                                            {
                                                this.menuItems.push({
                                                    uisref: '.guardians',
                                                    title: 'SB_Guardians',
                                                    icon: iconService.getIcon('guardians'),
                                                    sortOrder: 5
                                                });
                                            }
                                        }.bind(this));

                                    cachedLookupService.getGroupFeature('SendCommunication')
                                        .then(function (data)
                                        {
                                            if (data === true)
                                            {
                                                this.menuItems.push({
                                                    uisref: '.sendComms',
                                                    title: 'SB_Send_Communication',
                                                    icon: iconService.getIcon('sendCommunications'),
                                                    sortOrder: 6
                                                });
                                            }
                                        }.bind(this));

                                    cachedLookupService.getGroupFeature('FormTags')
                                        .then(function (data)
                                        {
                                            if (data === true)
                                            {
                                                this.menuItems.push({
                                                    uisref: '.formtags',
                                                    title: 'SB_Tagged_Data',
                                                    icon: iconService.getIcon('formtags'),
                                                    sortOrder: 7
                                                });
                                            }
                                        }.bind(this));
                                }
                            }
                        };
                    }

                    this.getTransportStatusUpdates = (() =>
                    {
                        let eventStatusRequests = [];

                        eventStatusRequests.push(
                            {
                                calendarEventId: this.eventData.calendarEventId,
                                studentPersonId: this.eventData.personId,
                            });

                        diaryService.getEventUpdates(eventStatusRequests)
                            .then((response) =>
                            {
                                if (response.data.length == 1)
                                {
                                    let eventStatus = response.data[0];

                                    let studentStop = this.eventData.transportTimetableRouteTimeline.stops.filter((stop) =>
                                    {
                                        return stop.isSelected;
                                    });

                                    if (studentStop.length > 0)
                                    {
                                        let studentStopId = studentStop[0].routeStopId;

                                        let studentStopStatus = eventStatus.stops.filter((stop) =>
                                        {
                                            return stop.isSelected && stop.routeStopId == studentStopId;
                                        });

                                        if (studentStopStatus.length > 0)
                                        {
                                            // If this event is completed then we do not want to add any more stop status info.
                                            if (eventStatus.status != 2) {
                                                studentStop[0].isLate = studentStopStatus[0].isLate;
                                                studentStop[0].isBadgeVisible = studentStopStatus[0].isBadgeVisible;
                                                studentStop[0].numberOfMinutesLate = studentStopStatus[0].numberOfMinutesLate;
                                                studentStop[0].isMinutesLateMessageVisible = studentStopStatus[0].isMinutesLateMessageVisible;
                                                studentStop[0].methodName = eventStatus.methodName;
                                                studentStop[0].badgeClass = eventStatus.badge.class;
                                                studentStop[0].badgeMessage = eventStatus.badge.label;
                                            }
                                        }
                                    }
                                }
                            },
                                function (error)
                                {
                                    toastService.error();
                                });
                    });

                    this.enableStaffMenuItems = function ()
                    {
                        if (staffEnabled)
                        {
                            return;
                        }

                        eventsService.getEventInfo(this.eventData.calendarEventId)
                            .then(function (response)
                            {
                                this.menuItems.push({
                                    uisref: '.autoNotify',
                                    title: 'SB_Notify',
                                    icon: iconService.getIcon('info'),
                                    sortOrder: 2,
                                    isHidden: !response.isAutoNotifyEvent || !this.useNewConsentTabs
                                });

                                this.menuItems.push({
                                    uisref: '.notifyOnly',
                                    title: 'SB_Notify',
                                    icon: iconService.getIcon('info'),
                                    sortOrder: 2,
                                    isHidden: !response.isNotifyOnlyEvent || !this.useNewConsentTabs
                                });

                                this.menuItems.push({
                                    uisref: '.consentEventInvites',
                                    title: 'SB_Send_Invites',
                                    icon: iconService.getIcon('info'),
                                    sortOrder: 2,
                                    isHidden: !response.isConsentEvent || !this.useNewConsentTabs
                                });

                                this.menuItems.push({
                                    uisref: '.register',
                                    title: 'SB_Register',
                                    icon: iconService.getIcon('register'),
                                    sortOrder: 3
                                });

                                if (!this.isExternal)
                                {
                                    this.menuItems.push({
                                        uisref: '.edit',
                                        title: 'SB_Edit',
                                        icon: iconService.getIcon('edit'),
                                        sortOrder: 8
                                    });
                                }

                                staffEnabled = true;
                            }.bind(this));
                    }.bind(this);

                    this.showBackButton = function ()
                    {
                        return typeof (this.onBackButtonClicked) === 'function';
                    };

                    this.back = function ()
                    {
                        this.onBackButtonClicked()();
                    };

                    this.eventUpdated = function (data)
                    {
                        // https://ui-router.github.io/guide/ng1/route-to-component#routed-parentchild-component-communication
                        // this lets the ui-view catch events from the contained component- in this instance, so we can pass updated
                        // event data to the header
                        /*
                        this.eventData.title = data.title || this.eventData.title;
                        this.eventData.eventType = data.calendarEventCategoryName || this.eventData.eventType;
                        this.eventData.owningEntityName = data.owningEntityName || this.eventData.owningEntityName;
                        this.eventData.organiserName = data.organiserName || this.eventData.organiserName;
                        this.eventData.from = data.from || this.eventData.from;
                        this.eventData.to = data.to || this.eventData.to;
                        this.eventData.meetingAt = data.meetingAt || this.eventData.meetingAt;
                        */
                        diaryService.clear();

                        if (this.transportStatusUpdate != null)
                        {
                            $interval.cancel(this.transportStatusUpdate);
                            this.transportStatusUpdate = null;
                        }

                        $state.go('specificEvent.info', {}, { reload: true });
                    }.bind(this);

                    this.registerUpdated = function (data)
                    {
                        console.log('registers changed', data);
                        diaryService.clear();
                    }.bind(this);

                    this.goToDiary = function ()
                    {
                        if (this.transportStatusUpdate != null)
                        {
                            $interval.cancel(this.transportStatusUpdate);
                            this.transportStatusUpdate = null;
                        }
                        $window.location = '/Diary/';
                    }.bind(this);

                    this.hasStops = function ()
                    {
                        return this.eventData.transportTimetableRouteTimeline && this.eventData.transportTimetableRouteTimeline.stops.length > 0;
                    };

                    this.isTransport = function ()
                    {
                        return this.eventData.eventType == 'Transport';
                    };

                    this.showETAWarnings = (() =>
                    {
                        return this.eventData.showETAWarnings;
                    });

                    this.isEventToday = (() =>
                    {
                        let today = moment();
                        let isToday = today.isSame(this.eventData.eventDate, 'day') &&
                            today.isSame(this.eventData.eventDate, 'month') &&
                            today.isSame(this.eventData.eventDate, 'year');
                        return isToday;
                    });

                    this.getTimeString = function ()
                    {
                        if (this.isMultiDay && !this.isTransport())
                        {
                            return;
                        }
                        var timeFormat = function (date) { return $filter('date')(date, 'HH:mm'); };
                        if (this.isTransport())
                        {
                            if (this.hasStops())
                            {
                                var stopTime = timeFormat(this.eventData.attendanceData.transportAttendee.routeStopDateTime);
                                return this.eventData.isSchoolbound ?
                                    stopTime + ' &#8211; ' + timeFormat(this.eventData.to) :
                                    timeFormat(this.eventData.from) + ' &#8211; ' + stopTime;
                            }
                            else
                            {
                                return timeFormat(this.eventData.from);
                            }
                        }
                        else
                        {
                            return timeFormat(this.eventData.from) + ' &#8211; ' + timeFormat(this.eventData.to);
                        }
                    };

                    this.openVideo = () =>
                    {
                        this.isCallExternal ?
                            $window.open(this.eventData.onlineMeetingId) :
                            $state.go('specificEvent.video')
                    };

                    this.handleVideo = () =>
                    {
                        const hasOnlineMeetingId = this.eventData?.onlineMeetingId?.length > 0;
                        const hasRoomName = this.eventData?.onlineRoomName?.length > 0;
                        this.hasVideo = hasOnlineMeetingId || hasRoomName;
                        this.callActionDisabled = false;
                        this.isCallExternal = hasOnlineMeetingId;
                    };

                    this.$onInit = () =>
                    {
                        this.hideSummary = $state.current.name === 'specificEvent.video';

                        const decrementCountdown = () =>
                        {
                            this.eventStartCountdown -= 1;
                            this.eventEndCountdown -= 1;
                            this.callActionDisabled = this.eventStartCountdown > 0 || this.eventEndCountdown < 0;
                            /* Perhaps when moving to Angular 12 we can put all this countdown
                            functionality in a DateTimeService using observables */
                            const countdownString = dateTimeService.secondsToClock(this.eventStartCountdown);
                            this.callActionText =
                                this.eventStartCountdown > 3600 ? 'SB_Not_Started' : (
                                    this.eventEndCountdown < 0 ? 'SB_Event_Call_Ended' :
                                        (this.eventStartCountdown > 0 ? `${$filter('translate')('SB_Starts')} ${countdownString}` : 'SB_Event_Call_Join')
                                );
                        };

                        const startCountDown = () =>
                        {
                            $interval(decrementCountdown, 1000, this.eventStartCountdown);
                        };
                        this.callActionDisabled = true;
                        this.eventStartCountdown = moment.utc(this.eventData.onlineRoomStartDateTimeUtc).diff(moment.utc(), 'seconds');
                        this.eventEndCountdown = moment.utc(this.eventData.onlineRoomEndDateTimeUtc).diff(moment.utc(), 'seconds');
                        startCountDown();
                    };
                }
            ]
        });
