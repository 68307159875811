angular.module('sysadmin.components.userEmailLookup', [
    'roles.services.rolesService'
]).component('userEmailLookup',
    {
        bindings: {
            organisationId: '<',
            personId: '=',
            personName: '=',
            personEmail: '=',
            canAdd: '<',
            onGetPerson: '&',
            onChangeEmail: '&'
        },
        templateUrl: '/Scripts/app/sysadmin/components/user-email-lookup.template.html',
        controller: class UserEmailLookup {

            // Dependencies
            rolesService:any;

            // Bindings
            organisationId: any;
            personId: any;
            personName: any;
            personEmail: any;
            email: string;
            exists: boolean;
            emailLookupForm: any;
            onGetPerson: any;
            isSearching: boolean;
            onChangeEmail: any;

            static $inject = ['rolesService'];

            constructor(rolesService) {
                this.rolesService = rolesService;
            }

            $onInit() {
                this.email = 'me@example.com';
                this.exists = true;
                this.isSearching = false;
            }


            validate() {
                if (!this.emailLookupForm.$valid) {
                    return;
                }

                this.isSearching = true;
                const handleError = (err) => {
                    this.exists = false;
                    console.log(err);
                }
                this.rolesService
                    .getPersonInOrganisationByEmail(this.email)
                    .then(data => {
                        const person = data;
                        if (person) {
                            console.log(person)
                            this.exists = true;
                            this.personId = person.id;
                            this.personName = person.firstName + ' ' + person.lastName;
                            this.personEmail = this.email;
                            this.onGetPerson({personId: person.id});
                        }
                        else {
                            this.onGetPerson();
                            this.exists = false;
                        }
                        if (data.isSuccess == false || data.errors) {
                            handleError(data.errors)
                        }
                        this.isSearching = false;
                    })
                    .catch(err => {
                        handleError(err);
                    });
            }

            onChange() {
                this.onChangeEmail({emailAddress: this.email});
                this.clear();
            }

            clear() {
                this.exists = true;
                this.personId = 0;
                this.personName = '';
                this.personEmail = '';
            }
        }
    });
