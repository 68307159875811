angular.module('payments.components.paymentForm', [])
    .component('paymentForm', {
        bindings: {
            resolve: '<',
            dismiss: '&',
            close: '&',
        },
        templateUrl: '/Scripts/app/payments/components/payment-form.template.html',
        controller: class PaymentFormCtrl {

            $interval: any;
            $window: any;
            dismiss: any;
            resolve: any;

            provider: any;
            config: any;
            timeLeft: any;
            paymentFormSubmitted: boolean;
            paymentProviderEnum: any;

            timeRemaining: any;
            timeRemainingMins: any;
            timeRemainingSecs: any;
            timer: any = null;
            hasBookingTimedOut: boolean = false;

            static $inject = ['$interval', '$window'];

            constructor($interval, $window)
            {
                this.$interval = $interval;
                this.$window = $window;
            }

            $onInit() {
                this.provider = this.resolve.provider;
                this.config = this.resolve.config;

                if (this.resolve.timeLeft)
                {
                    this.timeLeft = this.resolve.timeLeft;

                    this.timeRemaining = Date.now();
                    this.timeRemaining = moment.utc(this.timeRemaining).set('minute', this.timeLeft.mins);
                    this.timeRemaining = moment.utc(this.timeRemaining).set('second', this.timeLeft.secs);
                    this.timeRemainingMins = this.timeLeft.mins;
                    this.timeRemainingSecs = this.timeLeft.secs;

                    this.startTimer();
                }

                this.paymentFormSubmitted = false;

                this.paymentProviderEnum = this.$window.EveryBuddy.Enums.PaymentProvider;
            }

            startTimer = () =>
            {
                if (this.timer == null)
                {
                    this.timer = this.$interval(() =>
                    {
                        this.timeRemaining = moment.utc(this.timeRemaining).subtract(1, 'second').toDate();

                        if (moment.utc(this.timeRemaining).get('minutes') == 0 &&
                            moment.utc(this.timeRemaining).get('seconds') == 0)
                        {
                            this.hasBookingTimedOut = true;
                            this.timeRemainingMins = 0;
                            this.timeRemainingSecs = 0;
                            this.$interval.cancel(this.timer);
                            return;
                        }

                        this.timeRemainingMins = moment.utc(this.timeRemaining).get('minutes');
                        this.timeRemainingSecs = moment.utc(this.timeRemaining).get('seconds');
                    }, 1000);
                }
            };

            cancel = function () {
                this.dismiss({paymentFormSubmitted: this.paymentFormSubmitted});
            }

            close = function () {
                this.close();
            }
    }
});
