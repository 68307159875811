angular.module('tuitionFeesModule', [
  'ui.bootstrap',
  'duScroll',
  'angular.filter',
  'angularjs-dropdown-multiselect',

  'shared.components.sbIcon',
  'shared.components.sbSideMenuNavPill',
  'shared.components.sbDatePicker',
  'shared.components.sbTitleBar',
  'shared.components.sbActionMenu',
  'shared.components.sbColumnHeader',
  'shared.components.sbModalHeader',
  'shared.components.sbOnOffSwitch',
  'shared.components.sbSearchFilter',
  'shared.components.sbSearchFilterItem',
  'shared.components.sbTextarea',
  'shared.components.subMenu',
  'shared.constants',
  'shared.directives.cloakTranslate',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',
  'shared.directives.sbCheckBox',
  'shared.directives.sbCurrencyInput',
  'shared.directives.sbLoading',
  'shared.components.sbSubmitButton',
  'shared.directives.updateLanguage',
  'shared.services.authInterceptor',
  'shared.services.cachedLookupService',
  'shared.services.guidService',
  'shared.services.httpCoreApi',
  'shared.services.moduleService',
  'shared.services.simpleAlertService',
  'shared.services.toastService',
  'shared.services.tinymceConfigHelper',
  'shared.services.searchResultOptionsCountService',
  'ng1StateDefinitionModule',

  'tuitionFeesModule.filters.calculateBilledAmount'
]);
angular.module('tuitionFeesModule').config([
    'ng1StateDefinitionProvider',
    function (ng1StateDefinitionProvider) {

        const ng1State = ng1StateDefinitionProvider.$get();

        const moduleName = 'TuitionFees';

        ng1State.state(moduleName, 'tuitionFees', {
            url: '/',
            component: 'tuitionFeesDashboard',
            redirectTo: 'tuitionFees.billingCycle'
        })
        ng1State.state(moduleName, 'tuitionFees.billingCycle', {
            url: 'BillingCycle',
            component: 'billingCycle'
        })
        ng1State.state(moduleName, 'tuitionFees.billingCycle.addToCycle', {
            url: '/AddToCycle',
            component: 'addToCycle',
            params: {
                billingCycleIdFn: null,
                billingCycleRefreshFn: null
            }
        })
        ng1State.state(moduleName, 'tuitionFees.billingCycle.batchView', {
            url: '/BatchView',
            component: 'batchView',
            params: {
                billingCycleIdFn: null,
                billingCycleRefreshFn: null
            }
        })
        ng1State.state(moduleName, 'tuitionFees.billingCycle.postInvoices', {
            url: '/PostInvoices',
            component: 'postInvoices',
            params: {
                billingCycleIdFn: null,
                billingCycleRefreshFn: null
            }
        })
        ng1State.state(moduleName, 'tuitionFees.invoice', {
            url: 'Invoice',
            component: 'invoiceView'
        })
        ng1State.state(moduleName, 'tuitionFees.invoice.detail', {
            url: 'InvoiceDetail',
            component: 'invoiceDetailView'
        })
        ng1State.state(moduleName, 'tuitionFees.invoice.detail.items', {
            url: 'InvoiceDetailViewItems',
            component: 'invoiceDetailViewItems'
        })
        ng1State.state(moduleName, 'tuitionFees.invoice.detail.notes', {
            url: 'InvoiceDetailViewNotes',
            component: 'invoiceDetailViewNotes'
        })
        ng1State.state(moduleName, 'tuitionFees.invoice.detail.addnote', {
            url: 'InvoiceDetailViewAddNote',
            component: 'invoiceDetailViewAddNote'
        });
    }
])
.component('ng1TuitionFeesBillingCycle', {
      template: '<billing-cycle on-change-billing-cycle-id="$ctrl.onChangeBillingCycleId({id: id, refreshFn: refreshFn})"></billing-cycle>',
      bindings: {
        onChangeBillingCycleId: '&'
      }
    })
.component('ng1TuitionFeesBillingCycleAdd', {
      template: '<add-to-cycle billing-cycle-refresh-fn="$ctrl.billingCycleRefreshFn"></add-to-cycle>',
    })
.component('ng1TuitionFeesBillingCycleProduct', {
      template: '<product-view></product-view>',
    })
.component('ng1TuitionFeesBillingCycleBatch', {
      template: '<batch-view></batch-view>',
    })
.component('ng1TuitionFeesBillingCyclePostInvoice', {
      template: '<post-invoices></post-invoices>',
    })
.component('ng1TuitionFeesInvoice', {
      template: '<invoice-view></invoice-view>',
    })
.component('ng1TuitionFeesInvoiceDetail', {
      template: '<invoice-detail-view></invoice-detail-view>',
    })
.component('ng1TuitionFeesInvoiceDetailItems', {
      template: '<invoice-detail-view-items></invoice-detail-view-items>',
    })
.component('ng1TuitionFeesInvoiceNotes', {
      template: '<invoice-detail-view-notes></invoice-detail-view-notes>',
    })
.component('ng1TuitionFeesInvoiceAddNote', {
      template: '<invoice-detail-view-add-note></invoice-detail-view-add-note>',
    })
