angular.module('balanceModule').component('payment', {
    templateUrl: '/Scripts/app/balance/components/payment.template.html',
    controller: class PaymentCtrl {

    // Dependencies
    $window: any;
    $state: any;
    $q: any;
    organisationService: any;
    paymentsService: any;
    paymentSessionTypeEnum: any;
    mathService: any;
    simpleAlertService: any;

    //Variables
    organisationTitle: string;
    currencyDisplaySymbol: string;
    useCreditBalance: boolean;
    transactions: any;
    paymentSignature: any;
    paymentSessionId: any;
    validation: any;
    paymentProviders: any;
    totalPayment: any;
    saving: boolean;

    static $inject = ['$window', '$state', '$q', 'organisationService', 'paymentsService', 'paymentSessionTypeEnum', 'mathService', 'simpleAlertService'];

    constructor (
        $window,
        $state,
        $q,
        organisationService,
        paymentsService,
        paymentSessionTypeEnum,
        mathService,
        simpleAlertService) {
            this.$window = $window;
            this.$state = $state;
            this.$q = $q;
            this.organisationService = organisationService;
            this.paymentsService = paymentsService;
            this.paymentSessionTypeEnum = paymentSessionTypeEnum;
            this.mathService = mathService;
            this.simpleAlertService = simpleAlertService;
        }

        $onInit() {
            // Validate state params, as may be null e.g. in case of page refresh
            if (this.$state.params.payments == null) {
                this.$state.go('balance');
                return;
            }

            this.organisationService.getCurrentOrganisationTitle()
            .then(data => {
                this.organisationTitle = data;
            });

            this.currencyDisplaySymbol = this.$window.EveryBuddy.Constants.CurrencyDisplaySymbol;

            console.log('receiving transactions : ', this.$state.params.payments);
            this.useCreditBalance = false;
            this.transactions = this.$state.params.payments;
            this.paymentSignature = this.$state.params.paymentSignature;
            this.paymentSessionId = this.$state.params.paymentSessionId;
            angular.forEach(this.transactions, tran => {
                tran.paymentBalance = this.mathService.roundNumber(tran.paymentBalance, 2);
            });

            this.validation = { checkingOut: false };

            this.paymentProviders = this.$window.EveryBuddy.Enums.PaymentProvider;

            this.totalPayment = null;

            this.calculateTotalPayment();
        }

        calculateTotalPayment() {

            var sum = 0;
            angular.forEach(this.transactions, transaction => {
                if (transaction.paymentBalance) {
                    sum += parseFloat(transaction.paymentBalance);
                }
            });

            this.totalPayment = sum;
        };

        recordPaymentFromToken(token, merchantReference) {
            this.validation.checkingOut = true;

            return this.paymentsService.chargeProviderWithToken(token, merchantReference)
                .then(data => {
                    if (!!data.redirectUrl) {
                        // a redirect probably due to a 3D Secure payment.
                        window.location.href = data.redirectUrl;
                        return;
                    }

                    this.$state.go('paymentComplete',
                        {
                            isSuccess: data.success,
                            message: data.message,
                            merchantReference: merchantReference
                        });

                    return data;
                })
                .catch(err => {
                    console.error('problem charging provider with token', err);
                    throw err;
                });
        };

        recordPaymentCancelled() {
            this.validation.checkingOut = false;
            this.paymentsService.cancelPaymentSession(this.paymentSessionId);
        };

        recordPaymentProviderlessTransaction(amountToPayWithCredits) {

            console.log('recordPaymentProviderlessTransaction');

            this.saving = true;

            var signatureRequest = {
                paymentSessionType: this.paymentSessionTypeEnum.Payment,
                paymentProvider: 0,
                useCreditBalance: (amountToPayWithCredits && amountToPayWithCredits > 0),
                creditBalanceAmount: amountToPayWithCredits,
                ccaSignUpId: null,
                paymentSessionRequestItems: []
            };

            var paymentsDue = this.transactions;

            var payments = paymentsDue.map(a => {
                return {
                    calendarEventId: a.calendarEventId,
                    owningPersonId: a.owningPersonId,
                    transactionId: a.transactionId,
                    amountToPay: a.paymentBalance
                };
            });

            signatureRequest.paymentSessionRequestItems = payments;

            return this.paymentsService
                .submitPaymentProviderlessTransaction(signatureRequest)
                .then(data => {
                    this.$state.go('paymentComplete',
                        {
                            isSuccess: data.success,
                            message: data.message,
                            merchantReference: data.merchantReference
                        });
                })
                .catch(err => {
                    this.simpleAlertService.errorAlert();
                });
        };

        createPaymentSession(providerId, amountToPayWithCredits) {
            var deferred = this.$q.defer();

            var signatureRequest = {
                paymentSessionType: this.paymentSessionTypeEnum.Payment,
                paymentProvider: providerId,
                useCreditBalance: (amountToPayWithCredits && amountToPayWithCredits > 0),
                creditBalanceAmount: amountToPayWithCredits,
                ccaSignUpId: null,
                paymentSessionRequestItems: []
            };

            var paymentsDue = this.transactions;

            var payments = paymentsDue.map(a => {
                return {
                    calendarEventId: a.calendarEventId,
                    owningPersonId: a.owningPersonId,
                    transactionId: a.transactionId,
                    amountToPay: a.paymentBalance
                };
            });

            signatureRequest.paymentSessionRequestItems = payments;

            this.paymentsService
                .createPaymentSession(signatureRequest)
                .then(data => {
                    if (!data.error) {
                        this.paymentSessionId = data.sid;
                        this.paymentSignature = data.sig;
                        deferred.resolve(data);
                    }
                    else {
                        deferred.reject({ isError: true, message: data.error });
                    }
                })
                .catch(err => {
                    deferred.reject(err);
                });

            return deferred.promise;
        };
    }
});
