'use strict';

angular.module('sysadmin.services.domainNameService', [])
    .factory('domainNameService', [
        '$http', '$cacheFactory',
        function ($http) {

            var urlBase = '/webapi/WebMainSettings/';
            var repository = {};

            repository.getDomainName = function (organisationId) {
                return $http.get(urlBase + 'GetDomainName', { params: { organisationId: organisationId } })
                    .then(function (response) {
                        return response.data;
                    });
            };

            repository.saveDomainName = function (id, organisationId, domainName) {
                return $http.post(urlBase + 'SaveDomainName', { id: id, organisationId: organisationId, domainName: domainName })
                    .then(function (response) { return response.data; });
            };

            return repository;
        }
    ]);