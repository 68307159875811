
angular.module('receptionModule').component('receptionLinkedUsers', {
    templateUrl: '/Scripts/app/reception/controllers/user.linked.html',
    bindings: {
        selectedPerson: '<',
        isOrganisationAdmin: '<',
        onPersonUpdatedFn: '<',
    },
    controller:
    [
        '$scope', '$uibModal', 'receptionService', 'personService',
        function($scope, $uibModal, receptionService, personService) {

            this.$onChanges = (changes) => {
                if (changes.selectedPerson && changes.selectedPerson.currentValue) {
                    $scope.person = this.selectedPerson;
                }

                if (changes.isOrganisationAdmin && changes.isOrganisationAdmin.currentValue) {
                    $scope.isOrganisationAdmin = this.isOrganisationAdmin;
                }

                if (changes.onPersonUpdatedFn && changes.onPersonUpdatedFn.currentValue) {
                    $scope.onPersonUpdatedFn = this.onPersonUpdatedFn;
                }
            };
            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.loading = false;
            $scope.sortType = 'lastName';
            $scope.sortReverse = false;

            }

            $scope.addNewLink = function(linked) {
                $scope.addLink = { firstName: '', lastName: '', emailAddress: '', linkedEmailAddress: $scope.person.emailAddress };
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'addLinkModal.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    size: 'md',
                    scope: $scope

                });

                modalInstance.result.then(function() {
                        var linkedData = {
                            pupilPersonId: $scope.person.personData.id,
                            organisationId: -1,
                            parentName: $scope.addLink.firstName + ' ' + $scope.addLink.lastName,
                            parentEmailAddress: $scope.addLink.emailAddress
                        };
                        receptionService.saveLink(linkedData)
                            .then(function(data) {
                                $scope.showPerson($scope.person.personData.id);
                            });
                    },
                    function() {
                    });
            };

            $scope.showPerson = function (personId) {

                if (!personId)
                    return;

                $scope.person = undefined;

                personService.getPerson(personId)
                    .then(function (data) {
                        $scope.person = data;

                        if (typeof $scope.onPersonUpdatedFn == 'function') {
                            $scope.onPersonUpdatedFn(personId);
                        }
                    });
            };

            $scope.showRelated = function (linked)
            {
                var newId = ($scope.person.isParent || $scope.person.isStaff)
                    ? linked.linkedPersonId
                    : linked.personId;

                $scope.showPerson(newId);
            };

            $scope.deleteLink = function(linked) {
                $scope.selectedLinked = linked;
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    templateUrl: 'confirmDeleteLinkModal.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    size: 'sm',
                    scope: $scope

                });

                modalInstance.result.then(function() {
                        receptionService.removeLink($scope.selectedLinked.personId, $scope.selectedLinked.linkedPersonId)
                            .then(function() {
                                for (var i = 0; i < $scope.person.linkedPeople.length; i++) {
                                    if ($scope.person.linkedPeople[i].personId === $scope.selectedLinked.personId &&
                                        $scope.person.linkedPeople[i].linkedPersonId ===
                                        $scope.selectedLinked.linkedPersonId) {
                                        $scope.person.linkedPeople.splice(i, 1);
                                    }
                                }
                            });
                    },
                    function() {
                    });
            };


        }
    ]});
