'use strict';

angular.module('balanceAdmin.components.accountSummaryList',
    [
        'balanceAdmin.components.newTransactionUibContainer',
        'balanceAdmin.components.transactionViewUibContainer',
        'balance.constants',
        'balanceAdmin.services.balanceAdminService',
        'shared.components.sbSearchFilter',
        'shared.components.sbIcon',
        'shared.components.sbExportButton',
        'shared.components.sbAvatar',
        'shared.services.simpleAlertService',
        'shared.services.blobStoragePhotosService',
        'shared.constants',
    ])
    .component('accountSummaryList',
        {
            bindings: {
                accountTypeId: '<',
                showIncludeSource: '<'
            },
            templateUrl: '/Scripts/app/balanceAdmin/components/accounts-summary-list.template.html',
            controller: [
                '$uibModal',
                '$filter',
                'balanceAdminService',
                'simpleAlertService',
                'cachedLookupService',
                'blobStoragePhotosService',
                'financialAccountTypeEnum',
                'financialTransactionTypeEnum',
                'searchFilterTypes',
                function (
                    $uibModal,
                    $filter,
                    balanceAdminService,
                    simpleAlertService,
                    cachedLookupService,
                    blobStoragePhotosService,
                    financialAccountTypeEnum,
                    financialTransactionTypeEnum,
                    searchFilterTypes) {
                    this.searchFilterTypes = searchFilterTypes;

                    this.isAdvancedFeeAdmin = false;

                    this.showNickNameConfigured = false;

                    balanceAdminService.isAdvancedFeeAdmin()
                        .then(function (data) {
                            this.isAdvancedFeeAdmin = data;
                        }.bind(this));

                    this.maxNumberOfPages = window.EveryBuddy.PaginationMaxNumberOfPages;
                    this.recordCount = 1;
                    this.pageSize = 50;
                    this.currentPage = 1;
                    this.pageTitle = '';
                    this.chargeWording = 'SB_Charge';

                    this.loading = 0;
                    this.isLoadingExportData = true;
                    this.accounts = null;

                    this.search = '';
                    this.sortType = 'balance';
                    this.sortReverse = false;

                    this.currencyDisplaySymbol = window.EveryBuddy.Constants.CurrencyDisplaySymbol;

                    this.allowRaiseNewTransaction = false;

                    cachedLookupService.getSystemSetting('User_DisplayOtherName').then(function (data) {
                        this.showNickNameConfigured = data;
                    }.bind(this));

                    this.exportTableHeaders =  [
                        '',
                        'SB_Balance',
                        'SB_Last_Transaction_Date',
                        'SB_Leaver'
                    ];

                    this.includeStatusOptions = [
                        {
                            name: 'SB_All',
                            value: 0
                        },
                        {
                            name: 'SB_Current_Students',
                            value: 1
                        },
                        {
                            name: 'SB_Leavers_Outstanding_Balance',
                            value: 2
                        },
                    ];

                    this.resetSearchFilters = function() {
                        this.includeStatus = 0;
                        if (this.accounts) {
                            this.searchClick();
                        }
                    }.bind(this);

                    this.resetSearchFilters();

                    this.$onChanges = function (changes) {

                        if (changes.accountTypeId && changes.accountTypeId.currentValue) {

                            switch (changes.accountTypeId.currentValue) {
                                case financialAccountTypeEnum.Student:
                                    this.pageTitle = 'SB_Accounts_Overview';
                                    this.allowRaiseNewTransaction = false;
                                    break;
                                case financialAccountTypeEnum.Credit:
                                    this.pageTitle = 'SB_Credit_Accounts_Overview';
                                    this.chargeWording = 'SB_Withdrawal';
                                    this.allowRaiseNewTransaction = true;
                                    this.sortReverse = true;
                                    break;
                                case financialAccountTypeEnum.Voucher:
                                    this.pageTitle = 'SB_Voucher_Accounts_Overview';
                                    this.allowRaiseNewTransaction = true;
                                    break;
                                default:
                                    this.loading--;
                                    this.allowRaiseNewTransaction = false;
                                    console.log('balanceAdmin.components.accountSummaryList/onChanges/unsupported account type requested');
                                    return;
                            }

                            this.getAccountData();
                        }
                    };

                    cachedLookupService.getSystemSettings(['Payments_Vouchers_AllowAccountToBeOverdrawn'])
                    .then(function (data) {
                        this.allowVoucherAccountToGoOverdrawn = data['Payments_Vouchers_AllowAccountToBeOverdrawn'];
                    }.bind(this));

                    this.searchClick = function () {
                        this.recordCount = 1;
                        this.currentPage = 1;
                        this.getAccountData();
                    }.bind(this);

                    this.getAccountData = function (keepExport) {
                        this.loading++;
                        this.accounts = null;

                        console.log('accountSummaryList/getAccountData', 'currentPage', this.currentPage);

                        balanceAdminService.getAccounts(
                            this.accountTypeId,
                            this.pageSize,
                            this.currentPage,
                            this.sortType,
                            this.sortReverse,
                            this.search,
                            this.includeStatus != 1,
                            this.includeStatus != 2
                            )
                            .then(function (data) {
                                this.accounts = data;
                                if (data && data[0] && this.recordCount !== data[0].recordCount) {
                                    console.log('accountSummaryList/getAccountData', 'record count has changed', this.recordCount, data[0].recordCount);
                                    this.recordCount = data[0].recordCount;
                                }
                                if (!keepExport) {
                                    this.getExportData();
                                }
                                blobStoragePhotosService.addStudentPhotoUrlsToArray (this.accounts).then(function (array) {
                                    this.accounts = array;
                                }.bind(this));
                            }.bind(this))
                            .catch(function (err) {
                                simpleAlertService.errorAlert();
                                console.error(err);
                            }.bind(this))
                            .finally(function () {
                                this.loading--;
                            }.bind(this));
                        this.lastUpdateAt = moment();
                    }.bind(this);

                    this.getExportData = function() {
                        this.isLoadingExportData = true;
                        // Use very high items per page number to fetch all records for export
                        var allCount = 99999999;
                        balanceAdminService.getAccounts(
                            this.accountTypeId,
                            allCount,
                            this.currentPage,
                            this.sortType,
                            this.sortReverse,
                            this.search,
                            this.includeStatus != 1,
                            this.includeStatus != 2
                            )
                            .then(function (data) {
                                this.exportData = [];
                                data.forEach(function (account) {
                                    this.exportData.push([
                                        account.lastName + ', ' + account.firstName + (account.nickName ? ' (' + account.nickName + ')' : ''),
                                        this.currencyDisplaySymbol + account.balance,
                                        $filter('date')(account.transactionDate, 'dd/MM/yyyy HH:mm') || 'N/A',
                                        account.isLeaver ? $filter('translate')('SB_Leaver') : ''
                                    ]);
                                }.bind(this));
                                this.isLoadingExportData = false;
                            }.bind(this))
                            .catch(function (error) {
                                console.log(error);
                                this.isLoadingExportData = false;
                            }.bind(this));
                    }.bind(this);

                    this.onChangePage = function() {
                        this.getAccountData(true);
                    };

                    this.onViewTransactionsClick = function (account) {
                        $uibModal.open({
                            component: 'transactionViewUibContainer',
                            resolve: {
                                accountTypeId: function () { return this.accountTypeId; }.bind(this),
                                accountOwnerId: function () { return account.personId; },
                                showIncludeSource: function () { return this.showIncludeSource; }.bind(this)
                            },
                            size: 'lg'
                        });
                    }.bind(this);

                    this.onRaiseCreditClick = function (account) {
                        var newTransactionModal = $uibModal.open({
                            component: 'newTransactionUibContainer',
                            resolve: {
                                accountTypeId: function () { return this.accountTypeId; }.bind(this),
                                accountOwnerId: function () { return account.personId; },
                                transactionType: function () { return financialTransactionTypeEnum.Credit; },
                                accountHolderName: function () { return account.lastName + ', ' + account.firstName }
                            },
                            size: 'lg'
                        });

                        newTransactionModal.result.then(function () {
                            this.getAccountData();
                        }.bind(this));

                    }.bind(this);

                    this.onRaiseChargeClick = function (account) {
                        var isVoucher = this.accountTypeId === financialAccountTypeEnum.Voucher;
                        var allowOverdraft = isVoucher && this.allowVoucherAccountToGoOverdrawn;
                        var newTransactionModal = $uibModal.open({
                            component: 'newTransactionUibContainer',
                            resolve: {
                                accountTypeId: function () { return this.accountTypeId; }.bind(this),
                                accountOwnerId: function () { return account.personId; },
                                transactionType: function () { return financialTransactionTypeEnum.Debit; },
                                accountHolderName: function () { return account.lastName + ', ' + account.firstName; },
                                maximumAmount: function () { return account.balance; },
                                allowOverdraft: function () { return allowOverdraft; },
                            },
                            size: 'lg'
                        });

                        newTransactionModal.result.then(function () {
                            this.getAccountData();
                        }.bind(this));
                    }.bind(this);
                }
            ]
        });
