<div class="input-group">
    <input type="number"
           class="form-control"
           [required]="required"
           [ngModel]="modelNumber"
           (ngModelChange)="onChange($event)"
           [min]="min"
           [max]="max">
</div>

