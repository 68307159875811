angular.module('shared.directives.sbCheckBox', [])
    .directive('sbCheckBox', ['$timeout', function ($timeout) {

        function controller()
        {
            var self = this;

            this.toggle = function()
            {
                if (self.disabled)
                    return;

                if (self.model === self.trueValue)
                {
                    self.model = self.falseValue;
                } else
                {
                    self.model = self.trueValue;
                }

                if (self.onClick && typeof (self.onClick()) === 'function') {
                    $timeout(function() {
                        self.onClick()(self.model);
                        },0);
                }
            };

            this.isChecked = function() {
                if (typeof (self.checked) !== 'undefined') {
                    return self.checked;
                }

                return self.model === self.trueValue;
            };

            this.$onInit = function () {
                this.checkClass = this.checkClass || 'success';
            };

        };

        return {
            restrict: 'E',
            templateUrl: '/Scripts/app/shared/directives/sb-check-box.template.html',
            scope: {},
            bindToController: {
                model: '=',
                trueValue: '<',
                falseValue: '<',
                controlName: '@',
                checkClass: '@',
                onClick: '&',
                checked: '=',
                disabled: '='
            },
            controller: controller,
            controllerAs: 'ctrl'
        };
    }]);
