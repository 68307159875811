'use strict';

angular.module('group.controllers.multiPersonPickerPopupController',
    [
        'group.directives.multiPersonPicker',
        'shared.components.sbModalHeader'
])
    .controller('multiPersonPickerPopupController', [
        '$scope',
        '$uibModalInstance',
        'selectedPersonIds',
        'defaultClubId',
        'defaultTeamId',
        'okButtonText',
        'includePupils',
        'includeStaff',
        'hideGroupFilter',
        'hideYearFilter',
        'hideGenderFilter',
        'hideRemove',
        'filterOutExternalStaff',
        'overridePersonLookupService',
        'selectedGroupId',
        'shownFromRegister',
        'shownFromStaffRoles',
        'isMassStudentAssignmentCopyFromMode',
        'selectedTimeSlotId',
        'selectedTimetableId',
        'selectedTimetableRouteId',
        'timetableIdToCopyFrom',
        'timetableRouteIdToCopyFrom',
        'titleDetail',
        function ($scope,
            $uibModalInstance,
            selectedPersonIds,
            defaultClubId,
            defaultTeamId,
            okButtonText,
            includePupils,
            includeStaff,
            hideGroupFilter,
            hideYearFilter,
            hideGenderFilter,
            hideRemove,
            filterOutExternalStaff,
            overridePersonLookupService,
            selectedGroupId,
            shownFromRegister,
            shownFromStaffRoles,
            isMassStudentAssignmentCopyFromMode,
            selectedTimeSlotId,
            selectedTimetableId,
            selectedTimetableRouteId,
            timetableIdToCopyFrom,
            timetableRouteIdToCopyFrom,
            titleDetail)
        {

            $scope.okButtonText = okButtonText;
            $scope.selectedPersonIds = selectedPersonIds;
            $scope.defaultClubId = defaultClubId;
            $scope.defaultTeamId = defaultTeamId;
            $scope.includePupils = includePupils;
            $scope.includeStaff = includeStaff;
            $scope.hideGroupFilter = hideGroupFilter;
            $scope.hideYearFilter = hideYearFilter;
            $scope.hideGenderFilter = hideGenderFilter;
            $scope.hideRemove = hideRemove;
            $scope.filterOutExternalStaff = filterOutExternalStaff;
            $scope.overridePersonLookupService = overridePersonLookupService || false;
            $scope.selectedGroupId = selectedGroupId;
            $scope.shownFromRegister = shownFromRegister;
            $scope.shownFromStaffRoles = shownFromStaffRoles;
            $scope.isMassStudentAssignmentCopyFromMode = isMassStudentAssignmentCopyFromMode;
            $scope.selectedTimeSlotId = selectedTimeSlotId;
            $scope.selectedTimetableId = selectedTimetableId;
            $scope.selectedTimetableRouteId = selectedTimetableRouteId;
            $scope.timetableIdToCopyFrom = timetableIdToCopyFrom;
            $scope.timetableRouteIdToCopyFrom = timetableRouteIdToCopyFrom;
            $scope.titleDetail = titleDetail;

            $scope.loading = false;

            $scope.onLoadingStateChanged = function (loading)
            {
                $scope.loading = loading;
            };

            $scope.ok = function ()
            {
                if (!$scope.isMassStudentAssignmentCopyFromMode)
                {
                    $uibModalInstance.close($scope.selectedPersonIds);
                }

                var result =
                {
                    selectedPersonIds: $scope.selectedPersonIds,
                    timetableIdToCopyFrom: $scope.timetableIdToCopyFrom,
                    timetableRouteIdToCopyFrom: $scope.timetableRouteIdToCopyFrom
                };

                $uibModalInstance.close(result);
            };

            $scope.cancel = function ()
            {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.close = function ()
            {
                $uibModalInstance.dismiss('cancel');
            };
        }
]);