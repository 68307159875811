angular.module('profile.components.welcomeMessage', [
    'person.services',
    'organisation.services.organisationService',
    'shared.services.simpleAlertService',
    'shared.components.sbIcon',
])
    .component('welcomeMessage',
        {
            bindings: {
                memberType: '<',
                isCollapsed: '<',
                onCollapse: '&',
                onLoadStart: '&',
                onLoadEnd: '&',
            },
            templateUrl: '/Scripts/app/profile/components/welcome-message.template.html',
            controller: class WelcomeMessageCtrl {

                // Dependencies
                $sce: any;
                $window: any;
                organisationService: any;

                // Bindings
                onLoadStart: any;
                onLoadEnd: any;
                isCollapsed: boolean;

                // Variables
                isLoading: boolean;
                memberType: boolean;
                message: string;
                dontShowOnLogin: boolean;
                redirectTo: string;
                showClose: boolean;

                static $inject = ['$sce', '$window', 'organisationService'];

                constructor ($sce, $window, organisationService) {
                    this.$sce = $sce;
                    this.$window = $window;
                    this.organisationService = organisationService;
                }

                $onInit() {
                    this.message = undefined;
                    this.dontShowOnLogin = false;
                    this.redirectTo = '/';
                    this.showClose = this.isCollapsed !== undefined;
                }

                $onChanges(changes) {
                    if (changes.memberType && changes.memberType.currentValue !== null && changes.memberType.currentValue !== undefined) {
                        this.loadMessage();
                    }
                }

                loadMessage() {
                    this.onLoadStart();
                    this.isLoading = true;

                    this.organisationService.getWelcomeMessageForCurrentOrgansation(this.memberType)
                        .then(data => {
                            this.message = this.$sce.trustAsHtml(data);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.isLoading = false;
                            this.onLoadEnd();
                        })
                };
            }
        });
