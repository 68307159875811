'use strict';

angular
    .module('transport.pupilPreferences.components.pupilPreferenceSelections', [
        'shared.directives.sbCheckBox',
        'shared.filters.decoded',
        'shared.services.simpleAlertService',
    ])
    .component('pupilPreferenceSelections', {
        bindings: {
            selectionDescription: '<',
            operatingDays: '<',
            methodId: '<',
            methodSupportsRoutes: '<',
            enableAddPupilsButton: '<',
            noRoutesConfigured: '<',
            noRouteSelected: '<',
            timetable: '<',
            timetableRouteId: '<',
            timeSlotName: '<',
            selectedTimetableCalendarName: '<',
            routeId: '<',
            capacity: '<',
            selectedRoute: '=',
            preferencesLoadInProgress: '=',
            preferencesUpdateInProgress: '=',
        },
        templateUrl:
            '/Scripts/app/transport/pupilPreferences/components/pupil-preference-selections.template.html',
        controller: [
            '$filter',
            '$q',
            '$timeout',
            '$translate',
            '$uibModal',
            'cachedLookupService',
            'personPickerService',
            'pupilTransportPreferenceService',
            'simpleAlertService',
            function (
                $filter,
                $q,
                $timeout,
                $translate,
                $uibModal,
                cachedLookupService,
                personPickerService,
                pupilTransportPreferenceService,
                simpleAlertService
            ) {
                this.selectedPupils = null;
                this.isSubmitted = false;
                this.preferencesLoadInProgress = false;
                this.preferencesUpdateInProgress = false;
                this.preferencesResponse = null;
                this.isTableReady = false;
                this.operatingDaysWithAllocations = [];
                this.timetableCalendarChanged = false;
                this.timetableContainsActiveOperatingDays = false;

                this.resetSubmitButtons = function () {
                    $timeout(
                        function () {
                            this.isSubmitted = false;
                        }.bind(this),
                        200
                    );
                }.bind(this);

                cachedLookupService
                    .isOrganisationSuperAdmin()
                    .then(
                        function (isOrganisationSuperAdmin) {
                            this.isCopyFromOptionAvailable =
                                isOrganisationSuperAdmin;

                            if (!isOrganisationSuperAdmin) {
                                cachedLookupService
                                    .isTransportAdvancedAdmin()
                                    .then(
                                        function (data) {
                                            this.isCopyFromOptionAvailable =
                                                data;
                                        }.bind(this)
                                    )
                                    .catch(
                                        function (responseData) {
                                            var message = {};
                                            this.preferencesLoadInProgress = false;

                                            if (
                                                responseData &&
                                                responseData.data &&
                                                responseData.data.Message
                                            ) {
                                                message = {
                                                    message:
                                                        responseData.data
                                                            .Message,
                                                };
                                            }

                                            simpleAlertService.errorAlert(
                                                message
                                            );
                                        }.bind(this)
                                    );
                            }
                        }.bind(this)
                    )
                    .catch(
                        function (responseData) {
                            var message = {};
                            this.preferencesLoadInProgress = false;

                            if (
                                responseData &&
                                responseData.data &&
                                responseData.data.Message
                            ) {
                                message = {
                                    message: responseData.data.Message,
                                };
                            }

                            simpleAlertService.errorAlert(message);
                        }.bind(this)
                    );

                this.$onChanges = function (changes) {
                    if (
                        changes.methodId ||
                        changes.timetable ||
                        changes.timetableRouteId ||
                        changes.capacity ||
                        changes.selectedTimetableCalendarName
                    ) {
                        this.selectedPupils = null;
                        this.timetableCalendarChanged = false;

                        this.timetableContainsActiveOperatingDays =
                            typeof this.operatingDays !== 'undefined' &&
                            this.operatingDays != null &&
                            this.operatingDays.length > 0;

                        if (changes.selectedTimetableCalendarName) {
                            this.timetableCalendarChanged = true;
                            return;
                        }

                        if (this.noRoutesConfigured || this.noRouteSelected) {
                            return;
                        }

                        this.loadPupilPreferences();
                    }
                };

                this.loadPupilPreferences = function () {
                    if (
                        typeof this.timetable !== 'undefined' &&
                        this.timetable != null
                    ) {
                        this.preferencesLoadInProgress = true;
                        pupilTransportPreferenceService
                            .loadPupilPreferences(
                                this.timetable.id,
                                this.timetableRouteId
                            )
                            .then(
                                function (timetablePupilsPreferencesResponse) {
                                    this.isTableReady = false;
                                    this.preferencesResponse =
                                        timetablePupilsPreferencesResponse;
                                    this.selectedPupils =
                                        timetablePupilsPreferencesResponse.timetablePupilPreferences;
                                    this.loadOperatingDaysWithAllocations();
                                    this.preferencesLoadInProgress = false;
                                }.bind(this)
                            )
                            .catch(
                                function (responseData) {
                                    var message = {};
                                    this.preferencesLoadInProgress = false;

                                    if (
                                        responseData &&
                                        responseData.data &&
                                        responseData.data.Message
                                    ) {
                                        message = {
                                            message: responseData.data.Message,
                                        };
                                    }

                                    simpleAlertService.errorAlert(message);
                                }.bind(this)
                            );
                    }
                }.bind(this);

                this.loadOperatingDaysWithAllocations = function () {
                    this.operatingDaysWithAllocations = [];
                    var highestNumberAllocated = 0;

                    if (
                        typeof this.selectedRoute !== 'undefined' &&
                        this.selectedRoute != null
                    ) {
                        this.selectedRoute.showCapacityWarning = false;
                    }

                    for (var i = 0; i < this.operatingDays.length; i++) {
                        var operatingDay = this.operatingDays[i];
                        var numberOfStudentsAllocated = 0;

                        for (var p = 0; p < this.selectedPupils.length; p++) {
                            var pupil = this.selectedPupils[p];
                            for (
                                var od = 0;
                                od < pupil.pupilPreferenceOperatingDays.length;
                                od++
                            ) {
                                var pupilPreferenceOperatingDay =
                                    pupil.pupilPreferenceOperatingDays[od];
                                if (
                                    pupilPreferenceOperatingDay.timetableOperatingDayId ==
                                        operatingDay.id &&
                                    pupilPreferenceOperatingDay.isDaySelected
                                ) {
                                    numberOfStudentsAllocated++;
                                }
                            }
                        }

                        var operatingDayWithAllocation = {
                            id: operatingDay.id,
                            operatingDayId: operatingDay.operatingDayId,
                            isActive: operatingDay.isActive,
                            translatedDayName: operatingDay.translatedDayName,
                            studentsAllocated: numberOfStudentsAllocated,
                            capacityRemaining: 0,
                            showCapacityWarning: false,
                        };

                        if (
                            typeof this.capacity !== 'undefined' &&
                            this.capacity != null
                        ) {
                            operatingDayWithAllocation.capacity =
                                this.capacity - numberOfStudentsAllocated;

                            var tenPercentOfCapacity = this.capacity * 0.1;
                            var capacityRemaining =
                                this.capacity - numberOfStudentsAllocated;
                            capacityRemaining = parseFloat(capacityRemaining);
                            if (capacityRemaining <= tenPercentOfCapacity) {
                                operatingDayWithAllocation.showCapacityWarning = true;

                                if (
                                    typeof this.selectedRoute !== 'undefined' &&
                                    this.selectedRoute != null
                                ) {
                                    this.selectedRoute.showCapacityWarning = true;
                                }
                            }
                        }

                        this.operatingDaysWithAllocations.push(
                            operatingDayWithAllocation
                        );

                        if (
                            typeof this.selectedRoute !== 'undefined' &&
                            this.selectedRoute != null
                        ) {
                            if (
                                numberOfStudentsAllocated >
                                highestNumberAllocated
                            ) {
                                highestNumberAllocated =
                                    numberOfStudentsAllocated;
                                this.selectedRoute.highestNumberOfStudentPreferencesForAnyDay =
                                    numberOfStudentsAllocated;
                            }
                        }
                    }

                    if (
                        typeof this.selectedRoute !== 'undefined' &&
                        this.selectedRoute != null &&
                        highestNumberAllocated == 0
                    ) {
                        this.selectedRoute.highestNumberOfStudentPreferencesForAnyDay = 0;
                    }
                }.bind(this);

                this.lastRowRendered = function () {
                    this.isTableReady = true;
                };

                this.addPupils = function () {
                    var selectedPersonIds = [];

                    angular.forEach(this.selectedPupils, function (item) {
                        selectedPersonIds.push(item.personId);
                    });

                    var modalInstance =
                        personPickerService.openMultiPersonPicker({
                            selectedPersonIds: selectedPersonIds,
                            defaultClubId: 0,
                            defaultTeamId: 0,
                            okButtonText: 'SB_Save',
                            includeStaff: false,
                            includePupils: true,
                            hideGroupFilter: false,
                            hideYearFilter: false,
                            hideGenderFilter: false,
                            hideRemove: false,
                            filterOutExternalStaff: false,
                            overridePersonLookupService: null,
                            isMassStudentAssignmentCopyFromMode: false,
                            selectedTimeSlotId: null,
                        });

                    modalInstance.result.then(
                        function (selectedPersonIds) {
                            this.preferencesLoadInProgress = true;

                            var promises = [];
                            this.processPupilPickerResults(
                                selectedPersonIds,
                                promises,
                                null,
                                null
                            ).then(
                                function () {
                                    $q.all(promises).then(
                                        function () {
                                            this.loadPupilPreferences();
                                        }.bind(this)
                                    );
                                }.bind(this)
                            );
                        }.bind(this)
                    );
                };

                this.copyFrom = function () {
                    var selectedPersonIds = [];
                    var timetableIdToCopyFrom = 0;
                    var timetableRouteIdToCopyFrom = 0;

                    angular.forEach(this.selectedPupils, function (item) {
                        selectedPersonIds.push(item.personId);
                    });

                    var modalInstance =
                        personPickerService.openMultiPersonPicker({
                            selectedPersonIds: selectedPersonIds,
                            defaultClubId: 0,
                            defaultTeamId: 0,
                            okButtonText: 'SB_Save',
                            includeStaff: false,
                            includePupils: true,
                            hideGroupFilter: false,
                            hideYearFilter: false,
                            hideGenderFilter: false,
                            hideRemove: false,
                            filterOutExternalStaff: false,
                            overridePersonLookupService: null,
                            isMassStudentAssignmentCopyFromMode: true,
                            selectedTimeSlotId: null,
                            selectedTimetableId: this.timetable.id,
                            selectedTimetableRouteId: this.timetableRouteId,
                            timetableIdToCopyFrom: timetableIdToCopyFrom,
                            timetableRouteIdToCopyFrom:
                                timetableRouteIdToCopyFrom,
                        });

                    modalInstance.result.then(
                        function (result) {
                            this.preferencesLoadInProgress = true;

                            var promises = [];
                            this.processPupilPickerResults(
                                result.selectedPersonIds,
                                promises,
                                result.timetableIdToCopyFrom,
                                result.timetableRouteIdToCopyFrom
                            ).then(
                                function () {
                                    $q.all(promises).then(
                                        function () {
                                            this.loadPupilPreferences();
                                        }.bind(this)
                                    );
                                }.bind(this)
                            );
                        }.bind(this)
                    );
                };

                this.processPupilPickerResults = function (
                    selectedPersonIds,
                    promises,
                    timetableIdToCopyFrom,
                    timetableRouteIdToCopyFrom
                ) {
                    // Need to delete preferences for any pupils who were previously selected
                    // but who have now been deselected(removed) in the pupil picker.
                    return this.deletePreferencesForRemovedPupils(
                        selectedPersonIds,
                        this.selectedPupils
                    ).then(
                        function (response) {
                            var personIdsToAdd =
                                this.pupilsToAdd(selectedPersonIds);
                            if (personIdsToAdd.length == 0) {
                                return;
                            }

                            var getPreferencesFilterForTimeSlotAndStudents = {
                                timeslotId: this.timetable.timeslotId,
                                directionId: this.timetable.directionId,
                                studentPersonIds: personIdsToAdd,
                                timetableIdToIgnore: this.timetable.id,
                                timetableRouteIdToIgnore: this.timetableRouteId,
                                timetableCalendarIdToIgnore:
                                    this.timetable.transportTimetableCalendarId,
                            };

                            if (selectedPersonIds.length == 0) {
                                this.preferencesUpdateInProgress = false;
                                return true;
                            }

                            if (
                                typeof this.capacity !== 'undefined' &&
                                this.capacity != null &&
                                typeof this.selectedRoute !== 'undefined' &&
                                this.selectedRoute != null &&
                                typeof this.selectedRoute
                                    .highestNumberOfStudentPreferencesForAnyDay !==
                                    'undefined' &&
                                this.selectedRoute
                                    .highestNumberOfStudentPreferencesForAnyDay !=
                                    null &&
                                this.selectedRoute
                                    .highestNumberOfStudentPreferencesForAnyDay +
                                    personIdsToAdd.length >
                                    this.capacity
                            ) {
                                this.showRouteCapacityError(
                                    personIdsToAdd.length
                                );
                                return true;
                            }

                            return pupilTransportPreferenceService
                                .getPreferencesForTimeSlotDirectionAndPupils(
                                    getPreferencesFilterForTimeSlotAndStudents
                                )
                                .then(
                                    function (pupilInfoForTransportResponses) {
                                        var updateRequests = [];
                                        angular.forEach(
                                            pupilInfoForTransportResponses,
                                            function (pupilResponse) {
                                                var dayIdsToDisableSelection =
                                                    pupilResponse.operatingDayIdsWithPreferences;
                                                var selectedOperatingDayIds =
                                                    pupilResponse.selectedOperatingDayIds;
                                                var days = [];
                                                var index = 0;
                                                this.operatingDays.forEach(
                                                    function (operatingDay) {
                                                        var isDaySelectionEnabled = true;
                                                        var isDaySelected =
                                                            selectedOperatingDayIds.includes(
                                                                operatingDay.operatingDayId
                                                            );

                                                        if (
                                                            dayIdsToDisableSelection.includes(
                                                                operatingDay.operatingDayId
                                                            )
                                                        ) {
                                                            isDaySelectionEnabled = false;
                                                        }

                                                        var day = {
                                                            index: index,
                                                            timetableOperatingDayId:
                                                                operatingDay.id,
                                                            isDaySelected:
                                                                isDaySelected,
                                                            isDaySelectionEnabled:
                                                                isDaySelectionEnabled,
                                                            translatedDayName:
                                                                operatingDay.translatedDayName,
                                                        };

                                                        // If a day is not currently selected and is available for selection,
                                                        // update it so that it is selected.
                                                        if (
                                                            !isDaySelected &&
                                                            isDaySelectionEnabled
                                                        ) {
                                                            day.isDaySelected = true;
                                                        }

                                                        days.push(day);

                                                        updateRequests.push({
                                                            personId:
                                                                pupilResponse.personId,
                                                            transportTimetableRouteId:
                                                                this
                                                                    .timetableRouteId,
                                                            transportRouteId:
                                                                this.routeId,
                                                            transportTimetableId:
                                                                this.timetable
                                                                    .id,
                                                            transportTimetableOperatingDayId:
                                                                day.timetableOperatingDayId,
                                                            isDaySelected:
                                                                day.isDaySelected,
                                                            isDefaultPreference: true,
                                                            transportRouteStopId:
                                                                day.selectedStopId,
                                                            timetableIdToCopyFrom:
                                                                timetableIdToCopyFrom,
                                                            timetableRouteIdToCopyFrom:
                                                                timetableRouteIdToCopyFrom,
                                                        });

                                                        index++;
                                                    }.bind(this)
                                                );

                                                this.selectedPupils.push({
                                                    pupilFullName:
                                                        pupilResponse.fullName,
                                                    personId:
                                                        pupilResponse.personId,
                                                    pupilPreferenceOperatingDays:
                                                        days,
                                                    isUsingSameStopForAllDays: true,
                                                    useCheckboxNode: true,
                                                });
                                            }.bind(this)
                                        );

                                        promises.push(
                                            this.updatePupilPreferenceDays(
                                                updateRequests,
                                                true,
                                                promises,
                                                this.timeSlotName,
                                                this
                                                    .selectedTimetableCalendarName
                                            )
                                        );
                                    }.bind(this)
                                );
                        }.bind(this)
                    );
                };

                this.showRouteCapacityError = function (numberOfPuplsToAdd) {
                    var messageText = $filter('decoded')(
                        $translate.instant('SB_Route_Capacity_Error_Message', {
                            numberOfSelectedStudents: numberOfPuplsToAdd,
                            routeCapacity: this.capacity,
                        })
                    );

                    var messageTitle = $filter('decoded')(
                        $translate.instant('SB_Route_Capacity_Error_Title')
                    );

                    simpleAlertService.errorAlert({
                        title: messageTitle,
                        message: messageText,
                    });
                }.bind(this);

                this.deletePupilPreferences = function (
                    pupil,
                    timetableDescription
                ) {
                    var confirmDeletePreferencesModalInstance = $uibModal.open({
                        animation: true,
                        component: 'confirmDeletePreferences',
                        size: 'sm',
                        backdrop: 'static',
                        resolve: {
                            pupil: function () {
                                return pupil;
                            },
                            selectionDescription: function () {
                                return timetableDescription;
                            },
                        },
                    });

                    confirmDeletePreferencesModalInstance.result.then(
                        function () {
                            proceedToDeletePreferencesForPupil(pupil);
                        },
                        function () {
                            this.resetSubmitButtons();
                        }.bind(this)
                    );
                }.bind(this);

                var proceedToDeletePreferencesForPupil = function (pupil) {
                    this.preferencesLoadInProgress = true;

                    var pupilHasSelectedDays = false;
                    for (
                        var i = 0;
                        i < pupil.pupilPreferenceOperatingDays.length;
                        i++
                    ) {
                        if (
                            pupil.pupilPreferenceOperatingDays[i].isDaySelected
                        ) {
                            pupilHasSelectedDays = true;
                            break;
                        }
                    }

                    if (!pupilHasSelectedDays) {
                        // Pupil does not have any days selected, so all we need to do is remove them from the list as there's no underlying data to delete.
                        for (var i = 0; i < this.selectedPupils.length; i++) {
                            if (
                                this.selectedPupils[i].personId ==
                                pupil.personId
                            ) {
                                this.selectedPupils.splice(i, 1);
                                this.preferencesLoadInProgress = false;
                                return;
                            }
                        }
                    }

                    var pupilIdsToRemovePreferencesFor = [pupil.personId];

                    return pupilTransportPreferenceService
                        .deletePersonTransportPreferences(
                            this.timetable.id,
                            this.timetableRouteId,
                            pupilIdsToRemovePreferencesFor
                        )
                        .then(
                            function (response) {
                                for (
                                    var i = 0;
                                    i < this.selectedPupils.length;
                                    i++
                                ) {
                                    if (
                                        this.selectedPupils[i].personId ==
                                        pupil.personId
                                    ) {
                                        this.selectedPupils.splice(i, 1);
                                        break;
                                    }
                                }

                                this.loadOperatingDaysWithAllocations();
                                this.preferencesLoadInProgress = false;
                                this.resetSubmitButtons();
                                return response;
                            }.bind(this)
                        )
                        .catch(
                            function (responseData) {
                                var message = {};

                                if (
                                    responseData &&
                                    responseData.data &&
                                    responseData.data.Message
                                ) {
                                    message = {
                                        message: responseData.data.Message,
                                    };
                                }

                                simpleAlertService.errorAlert(message);
                                this.preferencesLoadInProgress = false;
                                this.resetSubmitButtons();
                            }.bind(this)
                        )
                        .finally(() => {
                            this.loadPupilPreferences();
                        });
                }.bind(this);

                //returns a list of pupils which have been added using the pupil picker and don't exist with a preference
                this.pupilsToAdd = function (pupilIdsFromPersonPicker) {
                    var pupils = [];

                    if (pupilIdsFromPersonPicker == null) {
                        return pupils;
                    }

                    //when no pupils with a preference are found allow them to all be added
                    if (this.selectedPupils.length == 0) {
                        return pupilIdsFromPersonPicker;
                    }

                    //other wise filter out the pupils not found in the assigned list
                    angular.forEach(
                        pupilIdsFromPersonPicker,
                        function (pupilId) {
                            var pupilFound = $filter('filter')(
                                this.selectedPupils,
                                { personId: pupilId },
                                true
                            );
                            if (pupilFound.length === 0) {
                                pupils.push(pupilId);
                            }
                        },
                        this
                    );

                    return pupils;
                }.bind(this);

                this.deletePreferencesForRemovedPupils = function (
                    pupilIdsFromPersonPicker,
                    currentlySelectedPupils
                ) {
                    var pupilIdsToRemovePreferencesFor = [];

                    if (currentlySelectedPupils != null) {
                        for (
                            var i = 0;
                            i < currentlySelectedPupils.length;
                            i++
                        ) {
                            if (
                                !pupilIdsFromPersonPicker.includes(
                                    currentlySelectedPupils[i].personId
                                )
                            ) {
                                pupilIdsToRemovePreferencesFor.push(
                                    currentlySelectedPupils[i].personId
                                );
                            }
                        }
                    }

                    return pupilTransportPreferenceService
                        .deletePersonTransportPreferences(
                            this.timetable.id,
                            this.timetableRouteId,
                            pupilIdsToRemovePreferencesFor
                        )
                        .then(
                            function (response) {
                                if (
                                    typeof this.selectedRoute !== 'undefined' &&
                                    this.selectedRoute != null
                                ) {
                                    this.selectedRoute.highestNumberOfStudentPreferencesForAnyDay -=
                                        pupilIdsToRemovePreferencesFor.length;
                                }

                                return response;
                            }.bind(this)
                        )
                        .catch(function (responseData) {
                            var message = {};

                            if (
                                responseData &&
                                responseData.data &&
                                responseData.data.Message
                            ) {
                                message = {
                                    message: responseData.data.Message,
                                };
                            }

                            simpleAlertService.errorAlert(message);
                        });
                }.bind(this);

                this.updatePupilPreference = function (
                    pupil,
                    day,
                    fromCheckbox,
                    promises
                ) {
                    if (typeof promises === 'undefined') {
                        promises = [];
                    }
                    const updateRequest = this.getRequestBody(
                        pupil,
                        day,
                        fromCheckbox
                    );
                    promises.push(
                        pupilTransportPreferenceService
                            .updatePersonTransportPreference(updateRequest)
                            .then(
                                function (responseDto) {
                                    var numberOfDifferentStops = 0;
                                    var stopId = -1;

                                    if (
                                        typeof pupil.pupilPreferenceOperatingDays !==
                                        'undefined'
                                    ) {
                                        pupil.pupilPreferenceOperatingDays.forEach(
                                            function (day) {
                                                if (
                                                    day.selectedStopId != stopId
                                                ) {
                                                    numberOfDifferentStops++;
                                                    stopId = day.selectedStopId;
                                                }
                                            }
                                        );
                                    } else {
                                        numberOfDifferentStops = 1;
                                    }

                                    if (
                                        typeof this.timetableRouteId ===
                                            'undefined' ||
                                        this.timetableRouteId == null
                                    ) {
                                        pupil.useCheckboxMode = true;
                                    } else {
                                        pupil.isUsingSameStopForAllDays =
                                            numberOfDifferentStops == 1;
                                        pupil.useCheckboxMode =
                                            pupil.isUsingSameStopForAllDays;

                                        if (
                                            pupil.isUsingSameStopForAllDays &&
                                            stopId > 0
                                        ) {
                                            pupil.selectedStopId = stopId;
                                        }
                                    }

                                    this.loadOperatingDaysWithAllocations();

                                    deferred.resolve();
                                }.bind(this)
                            )
                            .catch(function (responseData) {
                                var message = {};

                                if (
                                    responseData &&
                                    responseData.data &&
                                    responseData.data.Message
                                ) {
                                    message = {
                                        message: responseData.data.Message,
                                    };
                                }
                            })
                    );
                }.bind(this);

                this.getRequestBody = (pupil, day, fromCheckbox) => {
                    var selectedRouteStopId = 0;

                    if (!fromCheckbox) {
                        if (day.selectedStopId == 0) {
                            day.isDaySelected = false;
                        } else if (day.selectedStopId > 0) {
                            day.isDaySelected = true;

                            for (var i = 0; i < day.routeStops.length; i++) {
                                if (
                                    day.routeStops[i].stopId ==
                                    day.selectedStopId
                                ) {
                                    selectedRouteStopId =
                                        day.routeStops[i].routeStopId;
                                    break;
                                }
                            }
                        }
                    } else {
                        // Checkbox for a day has been toggled. If day has been deselected, we need to ensure none
                        // of the stops for the day are selected (that is if the current timetable has routes).
                        if (!day.isDaySelected) {
                            day.selectedStopId = 0;
                            if (typeof day.routeStops !== 'undefined') {
                                day.routeStops.forEach(function (routeStop) {
                                    routeStop.isCurrentlySelectedStop = false;
                                });
                            }
                        }
                    }

                    return {
                        personId: pupil.personId,
                        transportTimetableRouteId: this.timetableRouteId,
                        transportRouteId: this.routeId,
                        transportTimetableId: this.timetable.id,
                        transportTimetableOperatingDayId:
                            day.timetableOperatingDayId,
                        isDaySelected: day.isDaySelected,
                        isDefaultPreference: true,
                        transportRouteStopId: selectedRouteStopId,
                    };
                };

                this.updatePupilPreferenceDays = function (
                    updateRequests,
                    fromCheckbox,
                    promises,
                    timeSlotName,
                    selectedTimetableCalendarName
                ) {
                    updateRequests.forEach(function (updateRequest) {
                        if (
                            fromCheckbox &&
                            updateRequest.isDaySelected == false
                        ) {
                            updateRequest.selectedStopId = 0;
                        } // from dropdown list
                        else {
                            if (updateRequest.selectedStopId == 0) {
                                updateRequest.isDaySelected = false;
                            } else if (updateRequest.selectedStopId > 0) {
                                updateRequest.isDaySelected = true;
                            }
                        }
                    });

                    promises.push(
                        pupilTransportPreferenceService
                            .updatePersonTransportPreferences(updateRequests)
                            .then(
                                function (response) {
                                    var preferenceUpdatesFeedbackModalInstance =
                                        $uibModal.open({
                                            animation: true,
                                            component:
                                                'preferenceUpdatesFeedback',
                                            size: 'md',
                                            backdrop: 'static',
                                            resolve: {
                                                preferenceUpdatesResponse:
                                                    function () {
                                                        return response;
                                                    },
                                                timeSlotName: function () {
                                                    return timeSlotName;
                                                },
                                                selectedTimetableCalendarName:
                                                    function () {
                                                        return selectedTimetableCalendarName;
                                                    },
                                            },
                                        });

                                    preferenceUpdatesFeedbackModalInstance.result.then(
                                        function () {},
                                        function () {
                                            this.resetSubmitButtons();
                                        }.bind(this)
                                    );
                                }.bind(this)
                            )
                            .catch(
                                function (responseData) {
                                    var message = {};

                                    if (
                                        responseData &&
                                        responseData.data &&
                                        responseData.data.Message
                                    ) {
                                        if (
                                            responseData.data.Message.indexOf(
                                                'SB_Route_Capacity_Error_Message'
                                            ) >= 0
                                        ) {
                                            // messageParts[1] will contain the number of students trying to be added to a route
                                            var messageParts =
                                                responseData.data.Message.split(
                                                    '|'
                                                );
                                            this.showRouteCapacityError(
                                                messageParts[1]
                                            );
                                        } else {
                                            message = {
                                                message:
                                                    responseData.data.Message,
                                            };
                                            simpleAlertService.errorAlert(
                                                message
                                            );
                                        }

                                        this.resetSubmitButtons();
                                    }
                                }.bind(this)
                            )
                    );
                }.bind(this);

                this.editStopsForDays = function (pupil) {
                    pupil.useCheckboxMode = false;
                };

                this.revertEditStopsForDayMode = function (pupil) {
                    pupil.isUsingSameStopForAllDays = true;
                    pupil.useCheckboxMode = true;
                };

                // This function is called when the selected stop in the stops dropdown list for the pupil has been changed.
                // The selected stop needs to be applied to all of the pupil's days that are enabled for selection.
                this.updateAllPreferencesForPupil = function (pupil) {
                    var requests = pupil.pupilPreferenceOperatingDays.map(
                        (day) => {
                            if (day.isDaySelectionEnabled) {
                                day.selectedStopId = pupil.selectedStopId;
                            }
                            return this.getRequestBody(pupil, day, false);
                        }
                    );
                    pupilTransportPreferenceService
                        .updatePersonTransportPreference(requests)
                        .then(
                            function (responseDto) {
                                var numberOfDifferentStops = 0;
                                var stopId = -1;

                                if (
                                    typeof pupil.pupilPreferenceOperatingDays !==
                                    'undefined'
                                ) {
                                    pupil.pupilPreferenceOperatingDays.forEach(
                                        function (day) {
                                            if (day.selectedStopId != stopId) {
                                                numberOfDifferentStops++;
                                                stopId = day.selectedStopId;
                                            }
                                        }
                                    );
                                } else {
                                    numberOfDifferentStops = 1;
                                }

                                if (
                                    typeof this.timetableRouteId ===
                                        'undefined' ||
                                    this.timetableRouteId == null
                                ) {
                                    pupil.useCheckboxMode = true;
                                } else {
                                    pupil.isUsingSameStopForAllDays =
                                        numberOfDifferentStops == 1;
                                    pupil.useCheckboxMode =
                                        pupil.isUsingSameStopForAllDays;

                                    if (
                                        pupil.isUsingSameStopForAllDays &&
                                        stopId > 0
                                    ) {
                                        pupil.selectedStopId = stopId;
                                    }
                                }

                                this.loadOperatingDaysWithAllocations();

                                deferred.resolve();
                            }.bind(this)
                        )
                        .catch(function (responseData) {
                            var message = {};

                            if (
                                responseData &&
                                responseData.data &&
                                responseData.data.Message
                            ) {
                                message = {
                                    message: responseData.data.Message,
                                };
                            }
                        });
                }.bind(this);
            },
        ],
    });
