'use strict';

angular.module('ccasignupModule').component('ccaGenerateAdditionalEvents', {
    templateUrl: '/Scripts/app/ccaSignUp/Details/cca-generate-additional-events.template.html',
    controller: [
    '$scope',
    '$state',
    '$timeout',
    'cachedLookupService',
    'ccaSignUpDayOfWeek',
    'ccaService',
    'dateTextService',
    'signUpRepository',
    function (
        $scope,
        $state,
        $timeout,
        cachedLookupService,
        ccaSignUpDayOfWeek,
        ccaService,
        dateTextService,
        signUpRepository)
    {
        $scope.loading = {};
        $scope.signUp = {};

        $scope.generate = { agree: false, additionalInformation: '', sendCommunication: true };
        var signUpId = $state.params.signUpId;

        $scope.sortType = 'title'; // set the default sort type
        $scope.sortReverse = false; // set the default sort order

        // Variables used in order to restore pagination to previously viewed page
        $scope.initialPage = null; // when controller is loading, jumps to this page.
        $scope.returnPage = null; // page which should be shown on next load of controller.

        $scope.pageSizes = [
            { pageSize: undefined, label: 'SB_All' },
            { pageSize: 5, label: '5' },
            { pageSize: 10, label: '10' },
            { pageSize: 20, label: '20' },
            { pageSize: 50, label: '50' },
            { pageSize: 100, label: '100' }
        ];

        $scope.statuses = [
            { sortOrder: 0, id: 6, label: 'SB_CcaStatus_Active' }
        ];

        $scope.dateText = function (dayNumber)
        {
            if (dayNumber >= 0)
            {
                return dateTextService.getShortDayText(dayNumber);
            }

            if (dayNumber == ccaSignUpDayOfWeek.All.id)
            {
                return ccaSignUpDayOfWeek.All.label;
            }
        }

        // Set search filters from previous search when coming from group
        $scope.filter = Object.assign({}, $state.params.searchData.filter);

        $scope.initialPage = $state.params.searchData.currentPage || 1;
        $scope.returnPage = $scope.initialPage;
        /* Need to add this, otherwise if the user clicks another group
            on the same page they won't be taken back to the page the
            second time */

        $scope.generateAdditionalEventsGroupsFilter = function (element)
        {
            if (element.statusId != 6 && element.statusId != 7) // Status must be Active or Extending
            {
                return false;
            }

            if ($scope.filter.title && element.title && element.title.toLowerCase().indexOf($scope.filter.title.toLowerCase()) === -1)
            {
                return false;
            }

            if ($scope.filter.organiserName && element.organiserName && element.organiserName.toLowerCase().indexOf($scope.filter.organiserName.toLowerCase()) === -1)
            {
                return false;
            }

            if (typeof $scope.filter.dayofWeek !== 'undefined' &&
                $scope.filter.dayofWeek >= 0) // -1 = All
            {
                let dayMatchesFilter = false;

                if (element.dayofWeek >= 0 &&
                    element.dayofWeek === $scope.filter.dayofWeek)
                {
                    dayMatchesFilter = true;
                }

                if (!dayMatchesFilter &&
                    element.linkedGroups !== 'undefined' &&
                    Array.isArray(element.linkedGroups))
                {
                    element.linkedGroups.forEach((linkedGroup) =>
                    {
                        if (linkedGroup.dayofWeek === $scope.filter.dayofWeek)
                        {
                            dayMatchesFilter = true;
                        }
                    });
                }

                if (!dayMatchesFilter)
                {
                    return false;
                }
            }

            if ($scope.filter.location && element.location && element.location.toLowerCase().indexOf($scope.filter.location.toLowerCase()) === -1)
            {
                return false;
            }

            return true;
        };

        $scope.$watch('filter', function (newValue)
        {
            $scope.generateAdditionalEventsGroupsFilter.title = newValue.title;
            $scope.generateAdditionalEventsGroupsFilter.organiserName = newValue.organiserName;
            $scope.generateAdditionalEventsGroupsFilter.dayofWeek = newValue.dayofWeek;
            $scope.generateAdditionalEventsGroupsFilter.location = newValue.location;

            var activeFilters = Object.values($scope.filter).filter((val) =>
            {
                return val !== '' && val !== 0;
            });

            $scope.filterCount = activeFilters.length;

            if ($scope.filter.dayofWeek == ccaSignUpDayOfWeek.All.id)
            {
                $scope.filterCount--;
            };

            if (JSON.stringify($scope.filter) !== JSON.stringify($state.params.searchData.filter))
            {
                //Return to first page if filter changed from previous search
                $scope.initialPage = $scope.returnPage || 1;
                $state.params.searchData.filter = [];
            }

        }, true);

        $scope.refreshData = function ()
        {
            signUpRepository
                .getSignUp(signUpId)
                .then((signUp) =>
                { $scope.signUp = signUp; });

            signUpRepository
                .getSignUpGroupsForAdditionalEvents(signUpId)
                .then((signUpGroups) =>
                {
                    $scope.groups = signUpGroups.filter((signUpGroup) =>
                    {
                        return signUpGroup.statusId == 6 || signUpGroup.statusId == 7;
                    });

                    $scope.locations = [''];
                    $scope.organiserNames = [''];
                    $scope.daysOfWeek = [];

                    signUpGroups.forEach((signUpGroup) =>
                    {
                        if (signUpGroup.location !== '' && $scope.locations.indexOf(signUpGroup.location) === -1)
                        {
                            $scope.locations.push(signUpGroup.location);
                        }

                        var organiserName = signUpGroup.organiserName.replace('(', '').replace(')', '');
                        if ($scope.organiserNames.indexOf(organiserName) === -1)
                        {
                            $scope.organiserNames.push(organiserName);
                        }

                        if ($scope.daysOfWeek.indexOf(signUpGroup.dayofWeek) === -1)
                        {
                            $scope.daysOfWeek.push(signUpGroup.dayofWeek);
                        }

                        if (Array.isArray(signUpGroup.linkedGroups) && signUpGroup.linkedGroups.length > 0)
                        {
                            signUpGroup.linkedGroups.forEach((linkedGroup) =>
                            {
                                if ($scope.daysOfWeek.indexOf(linkedGroup.dayofWeek) === -1)
                                {
                                    $scope.daysOfWeek.push(linkedGroup.dayofWeek);
                                }
                            });
                        }

                        signUpGroup.availabilitySummary = ccaService.getGroupAvailabilitySummary(signUpGroup);
                    });

                    var localeSort = function (a, b)
                    {
                        return a.localeCompare(b);
                    };

                    $scope.locations.sort(localeSort);
                    $scope.organiserNames.sort(localeSort);

                    $scope.daysOfWeek.push(ccaSignUpDayOfWeek.All.id)
                    $scope.daysOfWeek.sort();
                    $scope.filter.dayofWeek = ccaSignUpDayOfWeek.All.id;
                });
        };

        cachedLookupService.getSystemSetting('CCASignUp_DashboardGroups_PageSize')
            .then((data) =>
            {
                if (data === -1)
                {
                    $scope.pageSize = undefined;
                }
                else
                {
                    $scope.pageSize = data;
                }

                $timeout(() =>
                {
                    // now it's loaded add the watcher to save changes
                    $scope.$watch('pageSize',
                        function (newValue, oldValue)
                        {
                            if (newValue === oldValue)
                            {
                                return;
                            }

                            var valueToSave = newValue === undefined ? -1 : newValue;

                            cachedLookupService.saveUserSettings({ 'CCASignUp_DashboardGroups_PageSize': valueToSave });

                            //Return to first page
                            $scope.initialPage = $scope.returnPage = 1;
                            $state.params.searchData.filter = [];
                        });

                    if ($state.params.searchData.pageSize)
                    {
                        $scope.pageSize = $state.params.searchData.pageSize
                    }
                });
            });

        $scope.refreshData();
    }
]});
